    <div class="mgbt-x-small">
        <div fxLayout="row wrap" class="fxGap-medium">
            <div style="min-width: 100px;">
                <strong>Available {{planType}} plans:</strong> 
            </div>
            <div style="min-width: 150px;" *ngIf="isHCAEditOrAdmin && planType === 'Medical'">
                <checkbox name="showAll" [(ngModel)]="showAll" (change)="showAllChange()">See all plans</checkbox>
            </div>
        </div>
    </div>
    <div *ngIf="planType === 'Vision'" class="mgbt-small">
        Select one vision plan. Before you enroll, call the plan to make sure the provider you want to use accepts the specific plan you choose. If you do not choose a vision plan, you will be enrolled in MetLife Vision.
    </div>
    <generic-grid
        *ngIf="availablePlans"
        [gridColumns]="availablePlanColumns"
        [gridData]="availablePlans"
        (selectedRow)="onPlanSelected($event)"
        (deselectedRow)="onPlanDeSelected($event)"
        [selectBy]="'planId'"
        [selectedKeys]="selectedPlanId"
        [selectable]="!isWaived && !readonly"
        [showSelectionHeader]="true"
    >
    </generic-grid>
    <!--  -->