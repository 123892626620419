import { find, sortBy } from 'lodash';

import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import SelfPayType from 'src/app/models/selfPayType';
import SpecialOpenEnrollmentType from 'src/app/models/specialOpenEnrollmentType';
import { env } from 'src/env/development';

@Component({
  selector: 'self-pay-info',
  templateUrl: 'selfPay.info.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayInfoComponent implements OnInit {
  icons = {
    faAddressCard
  };
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @Output() selectedSpecialOpenEnrollmentType: EventEmitter<SpecialOpenEnrollmentType> = new EventEmitter();
  @Input() requestType: SelfPayType;
  @Input() selectedSpecialOpenEnrollmentTypeId: string;
  @Input() agency: string;
  @Input() specialOpenEnrollmentTypes: SpecialOpenEnrollmentType[];
  oeSelfPayTypes: string[] = ['OEC', 'OER', 'OEU'];
  soeSelfPayTypes: string[] = ['SOEC', 'SOER', 'SOEU'];
  selectedSoeType: SpecialOpenEnrollmentType;
  showCustomSOEDescription = false;
  showCustomSOEDocTypes = false;
  soeSPTypesWithCustomDocs: string[] = ['GLEMO', 'MDP', 'MPNLA', 'NEED'];

  ngOnInit() {
    if (this.selectedSpecialOpenEnrollmentTypeId) {
      this.selectedSoeType = find(this.specialOpenEnrollmentTypes, soet => soet.specialOpenEnrollmentTypeId === this.selectedSpecialOpenEnrollmentTypeId);
    }
  }

  pushContinue() {
    this.continue.emit();
  }

  pushCancel() {
    this.previous.emit();
  }

  selectSoeType(selectedSoeType: SpecialOpenEnrollmentType) {
    this.showCustomSOEDescription = env.soeTypesWithCustomDescriptions.includes(selectedSoeType.specialOpenEnrollmentTypeCode);
    this.showCustomSOEDocTypes = env.soeTypesWithCustomDocTypeInstructions.includes(selectedSoeType.specialOpenEnrollmentTypeCode);

    if (this.selectedSoeType === selectedSoeType) {
      selectedSoeType = null;
    }

    this.selectedSoeType = selectedSoeType;
    this.selectedSpecialOpenEnrollmentType.emit(selectedSoeType);
  }

  soeTypes(): SpecialOpenEnrollmentType[] {
    const excludeNames =
    [
      'HCA OE Correction',
      'Newly Eligible',
      'Supplemental',
      'Change in school district',
      'API Import',
      'Gain or lose eligibility for TRICARE',
      'Registering Domestic Partnership',
      'Change of Address'
    ];

    if (this.agency === env.sebbCode) {
      //SEBB, exclude PEBB specific SOE types
      excludeNames.push('Gain or Lose Eligibility for Medicare, or Enroll or Cancel Enrollment in a Medicare Advantage (MA) Plan, Medicare Advantage-Prescription Drug (MAPD) Plan or Medicare Part D');
      excludeNames.push('Become eligible for state premium assistance subsidy for PEBB health plan Coverage from Medicaid or CHIP');
    }
    else {
      //PEBB, exclude SEBB specific SOE types
      excludeNames.push('Change in school district');
      excludeNames.push('Gain or lose eligibility for Medicare');
      excludeNames.push('Become eligible for state premium assistance subsidy for SEBB health plan coverage from Medicaid or CHIP');
    }

    return sortBy(
      this.specialOpenEnrollmentTypes.filter(soe => excludeNames.indexOf(soe.specialOpenEnrollmentTypeName) === -1),
      'specialOpenEnrollmentTypeName');
  }

  isRetiree(selfPayTypeCode: string): boolean {
    return selfPayTypeCode === 'OER' || selfPayTypeCode === 'R';
  }

  isSoeSelfPay(selfPayTypeCode: string): boolean {
    return this.soeSelfPayTypes.includes(selfPayTypeCode);
  }

  canContinue(requestType: SelfPayType, selectedSoeType: SpecialOpenEnrollmentType): boolean {
    if (this.isSoeSelfPay(requestType.selfPayTypeCode)) {
      return [null, undefined].indexOf(selectedSoeType) === -1;
    }

    return true;
  }

  cancelButtonLabel(selfPayTypeCode: string): string {
    if (this.isSoeSelfPay(selfPayTypeCode)) {
      return 'Cancel';
    }

    return 'Previous';
  }

  continueButtonLabel(selfPayTypeCode: string): string {
      return 'Continue';
  }

  getTitle(selfPayTypeCode: string): string {
    if (!this.oeSelfPayTypes.includes(selfPayTypeCode) && !this.soeSelfPayTypes.includes(selfPayTypeCode)) {
      if (selfPayTypeCode === 'R') {
        return `${this.agency} retiree coverage`;
      } else if (selfPayTypeCode === 'U') {
        return `${this.agency} Continuation Coverage (Unpaid Leave)`;;
      }else {
        return `${this.agency} Continuation Coverage (COBRA)`;
      }
    }

    if (this.soeSelfPayTypes.includes(selfPayTypeCode)) {
      return 'Special Open Enrollment events';
    }

    let program = 'Unpaid Leave';
    if (selfPayTypeCode === 'OEC') {
      program = 'COBRA';
    } else if (selfPayTypeCode === 'OER') {
      program = 'Retiree';
    }

    return `PEBB Continuation Coverage (${program}) Open Enrollment changes`;
  }

  displaySPCustomDocs(soeTypeCode) {
    if (!find(this.soeSPTypesWithCustomDocs,soet=> soet == soeTypeCode)) {
      return false;
    }
    else {
      return true;
    }
  }
}
