export default class Ethnicity {
  ethnicityId: string;
  ethnicityCode: string;
  ethnicityName: string;
  hispanicInd: boolean;
  uhcEthnicityCode: string;
  uhcRaceCode: string;
  obsoleteDate: Date;
  obsoleteFlag: boolean;

  constructor(ethnicity?) {
    if (ethnicity) {
      this.ethnicityId = ethnicity.ethnicityId;
      this.ethnicityCode = ethnicity.ethnicityCode;
      this.uhcEthnicityCode = ethnicity.uhcEthnicityCode;
      this.uhcRaceCode = ethnicity.uhcRaceCode;
      this.ethnicityName = ethnicity.ethnicityName;
      this.hispanicInd = ethnicity.hispanicInd;
      this.obsoleteDate = ethnicity.obsoleteDate ? new Date(ethnicity.obsoleteDate) : null;
      this.obsoleteFlag = ethnicity.obsoleteFlag;
    }
  }

}
