export default class SecurityQuestionAnswer {
    memberId: string;
    questionId: string;
    response: string;

    constructor(answer?) {
      if (answer) {
        this.memberId = answer.memberId;
        this.questionId = answer.questionId;
        this.response = answer.response;
      }
    }
  }
