import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import Question from 'src/app/models/question';
import Response from 'src/app/models/response';
import { find, cloneDeep } from 'lodash';
@Component({
  selector: 'question',
  templateUrl: 'question.component.html',
  styleUrls: [],
  providers: []
})
export class QuestionComponent implements OnInit {
  @Input() question: Question;
  @Input() responseId ?: string;
  @Input() disabled ?: boolean;
  @Input() responses: Response[];
  @Output() answer: EventEmitter<{q: Question, r: Response}> = new EventEmitter<{q: Question, r: Response}>();

  updateAnswer(): void {
    const selectedResponse = cloneDeep(find(this.question.responses , (r => r.responseId === this.responseId)));
    this.answer.emit({ q: this.question, r: selectedResponse });
  }

  ngOnInit(): void {
  }

  public manuallyUpdateResponse(responseId): void {
    this.responseId = responseId;
  }
}
