<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body page-container" fxLayout="column">
        <h1 *ngIf="inHCAAdminState">HCA Admin</h1>
        <h2 class="mobile-text-centered mgtp-small mgbt-medium-sm">Verify self pay requests</h2>
        <div fxLayout="row" fxFlex="60">
          <div fxLayout="row" style="font-weight:bold;">
            <checkbox name="onlyPendingRequests" [(ngModel)]="onlyShowPendingRecords" (change)="updateDataset(null)" label="Show only pending requests"></checkbox>
          </div>
          <div fxLayout="row" style="font-weight:bold;">
            <checkbox name="assignedToMe" [(ngModel)]="onlyShowAssignedRecords" (change)="updateDataset(null)" label="Show items assigned to me"></checkbox>
          </div>
        </div>
        <div class="dashboard-grid-parent mgbt-medium mgtp-medium">
          <selfpay-admin-grid
            [userCanEdit]="userCanEdit"
            #selfpayGrid
            [gridData]="gridData"
            [gridColumns]="gridColumns"
            [lookups]="lookups"
            [state]="state"
            [systemUser]="systemUser"
            [availablePlans]="availablePlans"
            [supplementalPlans]="supplementalPlans"
            [selectedDataItem]="selectedDataItem"
            [hcaUsers]="hcaUsers"
            (pushGetData)="updateDataset($event)"
            (pushSaveSelfpayReview)="updateSelfpay($event)"
            (saveSelfPayElections)="saveSelfPayElections($event)"
            (saveAndContinue)="updateSelfpay($event, true)"
          ></selfpay-admin-grid>
        </div>
      </div>
    </div>
  </div>
</div>