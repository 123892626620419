import { map, filter } from 'lodash';

export default class AvailableEnrollmentAction {
  availableEnrollmentActionId: string;
  availableEnrollmentActionCode: string;
  availableEnrollmentActionName: string;
  allRequirementsMet: boolean;
  allStepsComplete: boolean;

  constructor(availableEnrollmentAction?) {
    if (availableEnrollmentAction) {
      this.availableEnrollmentActionId = availableEnrollmentAction.availableEnrollmentActionId;
      this.availableEnrollmentActionCode = availableEnrollmentAction.availableEnrollmentActionCode;
      this.availableEnrollmentActionName = availableEnrollmentAction.availableEnrollmentActionName;
    }
  }
}
