import Ethnicity from './ethnicity';

export default class MemberEthnicity {
  memberEthnicityId: string;
  memberId: string;
  ethnicityId: string;
  ethnicity: Ethnicity;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;

  constructor(memberEthnicity?) {
    if (memberEthnicity) {
      this.memberEthnicityId = memberEthnicity.memberEthnicityId;
      this.memberId = memberEthnicity.memberId;
      this.ethnicityId = memberEthnicity.ethnicityId;
      this.ethnicity = memberEthnicity.ethnicity ? new Ethnicity(memberEthnicity.ethnicity) : null;
      this.createdDate = memberEthnicity.createdDate;
      this.createdById = memberEthnicity.createdById;
      this.modifiedDate = memberEthnicity.modifiedDate;
      this.modifiedById = memberEthnicity.modifiedById;
      
    }
  }

}
