<form #form="ngForm" #formContainer>
  <div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
    <div class="card-body">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3 fxFlex="100">Address Change Request</h3>
      </div>
      <div fxLayout="column" class="fxGap-medium">
        <p>
            Review the address information that The Health Care Authority has on-file for you and any dependents.  
            Make any necessary changes and submit the request for processing.
        </p>
        <div>
            <h4>{{currentSubscriber?.firstName}} {{currentSubscriber?.lastName}} (Subscriber)</h4>
            <ngb-accordion [destroyOnHide]="false" #subscriberAddress="ngbAccordion">
                <!--Residential Address-->
              <ngb-panel id="subscriberResidentialPanel">
                <ng-template ngbPanelHeader  let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Residential address
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faHome" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <address #residentialAddress view="panel" name="residentialAddress" label="Residential address" 
                  [(ngModel)]="currentSubscriber"></address>
                </ng-template>
              </ngb-panel>
              <!-- Mailing Address -->
              <ngb-panel id="subscriberMailingPanel">
                <ng-template ngbPanelHeader  let-opened="opened">
                  <div fxFlex fxFlexFill fxFlexLayout="row" fxLayoutAlign="space-between" class="fxGap-medium">
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" fxFlex="50">
                        <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                        <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                        Mailing address
                    </button>
                    <div fxFlex="50" class="checkbox-no-form-mg" fxLayoutAlign="center center">
                        <checkbox
                            name="mailingAddressIsSame"
                            (ngModelChange)="mailingAddressSameAsResidentialChange($event, opened)"                            
                            [(ngModel)]="selectedSelfPay.formJson.mailingAddressSameAsResidential"
                            [containerFxLayout]="'row'"
                            [containerfxLayoutAlign]="'end center'">
                            Same as residential address
                        </checkbox>
                    </div>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent *ngIf="!selectedSelfPay.formJson.mailingAddressSameAsResidential">
                  <address #mailingAddress view="panel" name="mailingAddress" label="Mailing address" 
                  [(ngModel)]="currentSubscriber.mailingAddress"></address>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
        </div>
        <div *ngIf="selectedSelfPay.formJson?.dependents?.length>0">
            <h4>Dependents</h4>
            <ngb-accordion [destroyOnHide]="false" #dependentAddress="ngbAccordion">
                <ngb-panel *ngFor="let dep of selectedSelfPay.formJson?.dependents; let index = index" [id]="'depAddressPanel_' + dep.memberId">
                    <ng-template ngbPanelHeader  let-opened="opened">
                      <div fxFlex fxFlexFill fxFlexLayout="row" fxLayoutAlign="space-between" class="fxGap-medium">
                        <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" fxFlex="50">
                            <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                            <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                            {{dep?.firstName}} {{dep?.lastName}}
                        </button>
                        <div fxFlex="50" class="checkbox-no-form-mg" fxLayoutAlign="center center">
                            <checkbox
                                [name]="'depMailingAddressIsSame_' + index"
                                (ngModelChange)="depAddressSameAsSubscriberChange(dep,'depAddressPanel_' +  dep.memberId)"
                                [(ngModel)]="dep.addressSameAsSubscriber"
                                [containerFxLayout]="'row'"
                                [containerfxLayoutAlign]="'end center'">
                                Residential address is the same as subscriber
                            </checkbox>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent *ngIf="!dep.addressSameAsSubscriber">
                      <address view="panel" [name]="'dependentResidentialAddress_' + dep.memberId" label="Residential address" 
                      [ngModel]="dep"></address>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <button (click)="pushCancel()" class="btn btn-secondary">Cancel</button>
          <button [disabled]="checkFormChange(selectedSelfPay?.formJson,origSelfPayForm,currentSubscriber,origCurrentSubscriber)" (click)="submitForm(formContainer)" class="btn btn-primary">Submit</button>
          </div>
      </div>
    </div>
</div>
</form>