import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { cloneDeep, map, find, remove, filter } from 'lodash';
import { faCalendar, faHome, faUndo, faEnvelope, faPhone, faAt, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import SelfPayForm from 'src/app/models/selfPayForm';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import SelfPay from 'src/app/models/selfPay';
import SelfPayFormSubscriber from 'src/app/models/selfPayFormSubscriber';
import Member from 'src/app/models/member';
import MemberAddress from 'src/app/models/memberAddress';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';

@Component({
  selector: 'self-pay-soe-change-of-address-pdf',
  templateUrl: 'selfPay.soe.changeOfAddress.pdf.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPaySOEChangeOfAddressPdfComponent implements OnInit {
    @Input() selfPay: SelfPay;
    icons = {
        faCalendar,
        faHome,
        faUndo,
        faEnvelope,
        faPhone,
        faAt,
        faMobileAlt,
        faPlus,
        faMinus
      };

    constructor(private coreService: CoreService) {}

    ngOnInit(): void {
        
    }
}
