<div fxFlexFill fxLayout="column">
  <h2 class="h3" style="font-weight: 600;">Subscriber / Benefits administrator login</h2>
  <p>Use this log in option if you are a subscriber or a benefits administrator whose agency does not use Active Directory.</p>
  <p>You will be redirected to the SecureAccess Washington log in page first to access Benefits 24/7.</p>
  <form (ngSubmit)="adminLogin()" #loginForm="ngForm" class="fxGap-medium-lg">
    <button id="ext-login-btn" type="submit" class="btn btn-primary">Log in to Benefits 24/7</button>
  </form>
  <br/>
  <br/>
  <!-- <p style="font-weight: 600; margin-top: 100px">Manage PEBB benefits for your organization</p> -->
  <h2 class="h3" style="font-weight: 600; margin-top: 0px;">HCA admin / PEBB benefits administrator</h2>
  <p>Use this log in option if you are an HCA admin or a PEBB benefits administrator whose agency uses Active Directory.</p>
  <form (ngSubmit)="hcaLogin()" #loginForm="ngForm" class="fxGap-medium-lg">
    <button id="ext-login-btn" type="submit" class="btn btn-primary">Log in here</button>
  </form>
</div>

<ng-template #notificationTemplate class="pebb-notification">
    <h3 class="h4" style="text-align: center;">
        Information missing or invalid:
    </h3>
    <span style="text-align: center;">
      Please double check your username and password and try again.
    </span>
    <br/>
    <br/>    
</ng-template>