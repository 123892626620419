import { CoreService } from './../../../../../../services/core.service';
import { Component, ViewEncapsulation, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from 'src/app/services/file.service';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import SystemUser from 'src/app/models/user';
import Organization from 'src/app/models/organization';
import { sortBy, remove, uniqBy, map, upperFirst, startCase, filter } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NgForm } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'organization-details',
  templateUrl: 'organization.details.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationDetailsComponent implements OnInit {
  icons = {
    faCalendar
  };
  @Input() isHCAAdmin: boolean;
  @Input() isHCASystemAdmin: boolean;
  @Input() isReadOnly: boolean;
  @Input() organization: Organization;
  @Input() lookups;
  @Input() addingNew: boolean;
  @Input() sebbOrgTypeId = '';
  @Input() sebbOrgEGTypeId = '';
  @Input() isOrgTypeToDisableEditFor: boolean;
  @ViewChild('districtForm') public districtForm: NgForm;
  

  constructor(
    private route: ActivatedRoute,
    private fileService: FileService,
    private notificationService: NotificationService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.lookups.benefitSetups = this.lookups.benefitSetups.filter(o => o.benefitSetupCode !== 'DO' && o.benefitSetupCode !== 'MD');
    });
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.districtForm);
  }
}
