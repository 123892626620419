  <div fxLayout="column">
    <div class="form-row">
      <h3 class="selfPay-form-small-header">Eligibility information</h3>
    </div>
    <!--row 1-->
    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
      <div class="form-group required" fxFlex="20">
        <label for="homeAgencyId">Home agency/subagency</label>
        <select [disabled]="disabled || selfPayOrigin.memberSelfPayOriginId" required class="form-control" name="homeAgencyId" id="homeAgencyId" 
          [(ngModel)]="selfPayOrigin.organizationId"
          (ngModelChange)="agencyChange(true)">
          <option></option>
          <option *ngFor="let homeAgency of homeAgencies | isObsolete" [ngValue]="homeAgency.organizationId">{{ homeAgency.organizationName }}</option>
        </select>
      </div>
      <div class="form-group required" fxFlex="20">
        <label for="memberTypeId">Eligibility type</label>
        <select [disabled]="disabled || selfPayOrigin.memberSelfPayOriginId || !selfPayOrigin.organizationId" required class="form-control" name="memberTypeId" id="memberTypeId" 
          [(ngModel)]="selfPayOrigin.memberTypeId"
          (ngModelChange)="eligibilityTypeChange()">
          <option></option>
          <option *ngFor="let memberType of selectableMemberTypes" [ngValue]="memberType.memberTypeId">{{ memberType.memberTypeCode + ' - ' + memberType.memberTypeName }}</option>
        </select>
      </div>
      <div class="form-group required" fxFlex="20">
        <label for="eligibilityReasonId">Eligibility Reason</label>
        <select [disabled]="disabled || selfPayOrigin.memberSelfPayOriginId || !selfPayOrigin.organizationId" required class="form-control" id="eligibilityReasonId" name="eligibilityReasonId" [(ngModel)]="selfPayOrigin.eligibilityReasonId">
          <option></option>
          <option *ngFor="let eligibilityReason of eligibilityReasons | isObsolete" [ngValue]="eligibilityReason.reasonId">{{ eligibilityReason.reasonCode }}</option>
        </select>
      </div>
      <div class="form-group required" fxFlex="20">
        <label for="organizationId">Original agency/subagency</label>
        <select [disabled]="disabled || !selfPayOrigin.organizationId" required class="form-control" id="organizationId" name="organizationId" [(ngModel)]="selfPayOrigin.originatingOrganizationId">
          <option></option>
          <option *ngFor="let org of homeAgencyOrganizations" [ngValue]="org.organizationId">{{ org.organizationCode }}</option>
        </select>
      </div>
    </div>
    <!--row 2-->
    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
      <div class="form-group required" fxFlex="20">
        <label for="selfPayPaymentTypeId">Pay method</label>
        <select [disabled]="disabled || selfPayOrigin.memberSelfPayOriginId || !selfPayOrigin.organizationId" required class="form-control" id="selfPayPaymentTypeId" name="selfPayPaymentTypeId" [(ngModel)]="selfPayOrigin.selfPayPaymentTypeId">
          <option></option>
          <option
            [disabled]="disabled || (payType.pmaPaymentTypeCode === 'P' && !selectedHomeAgency?.organizationCode?.startsWith('124'))"
            *ngFor="let payType of lookups.selfPayPaymentTypes | isObsolete"
            [ngValue]="payType.selfPayPaymentTypeId"
          >
            {{ payType.selfPayPaymentTypeName }}
          </option>
        </select>
      </div>
      <div [class.required]="cobraMemberTypeIds.includes(selfPayOrigin.memberTypeId)" class="form-group" fxFlex="20">
        <label for="cobraQualifyReasonId">Qualify reason</label>
        <select [required]="cobraMemberTypeIds.includes(selfPayOrigin.memberTypeId)" class="form-control" id="cobraQualifyReasonId" name="cobraQualifyReasonId" [(ngModel)]="selfPayOrigin.cobraQualifyReasonId">
          <option></option>
          <option *ngFor="let cobraQualifyReason of lookups.cobraQualifyReasons | isObsolete" [ngValue]="cobraQualifyReason.cobraQualifyReasonId">
            {{ cobraQualifyReason.cobraQualifyReasonCode }} - {{ cobraQualifyReason.cobraQualifyReasonName }}
          </option>
        </select>
      </div>
      <div class="form-group" fxFlex="20" [class.required]="!retirementIds.includes(selfPayOrigin.memberTypeId)">
        <date-picker
          #cobraSelfPayEndDate
          name="cobraSelfPayEndDate"
          label="COBRA/self pay end date"
          [required]="!retirementIds.includes(selfPayOrigin.memberTypeId)"
          [disabled]="disabled || !selfPayOrigin.organizationId"
          [(ngModel)]="selfPayOrigin.cobraSelfPayEndDate"></date-picker>
      </div>
      <div class="form-group" fxFlex="20">
        <date-picker
          #electionPeriodEndDate="ngModel"
          name="electionPeriodEndDate"
          label="Election period end date"
          [required]="!selfPayOrigin.memberSelfPayOriginId"
          [disabled]="disabled || (selfPayOrigin.organizationId && !electionPeriodEndEnabled) || !selfPayOrigin.organizationId"
          [min]="minElectionPeriodDate"
          [max]="maxElectionPeriodDate"
          [(ngModel)]="selfPayOrigin.electionPeriodEndDate"></date-picker>
      </div>
    </div>
    <!--row 3-->
    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
      <div class="form-group required" fxFlex="20">
        <label for="originatingSsn" id="originatingSsnLbl">Originating SSN</label>
        <kendo-maskedtextbox
          [mask]="env.ssnMask"
          [pattern]="env.ssnItinRegex"
          [(ngModel)]="selfPayOrigin.originatingSsn"
          [maskOnFocus]="true"
          name="originatingSsn"
          #originatingSsn="ngModel"
          aria-labelledby="originatingSsnLbl"
          id="originatingSsn"
          class="form-control"
          [disabled]="disabled || !selfPayOrigin.organizationId"
          required
          [forbiddenValues]="env.invalidSSNValues"
        ></kendo-maskedtextbox>
        <div *ngIf="originatingSsn.invalid && originatingSsn.touched && (!!originatingSsn.errors.forbiddenValues || !!originatingSsn.errors.pattern)" class="alert alert-danger">This SSN is invalid.</div>
      </div>
      <div class="form-group" fxFlex="20">
        <date-picker
          #agencyEffStart="ngModel"
          name="agencyEffStart"
          label="Agency effective start date"
          readOnly
          [disabled]="disabled || !selfPayOrigin.organizationId"
          [required]="selfPayOrigin.selfPayPaymentTypeId === payMethodDId"
          [(ngModel)]="selfPayOrigin.agencyEffectiveStartDate"></date-picker>
      </div>
      <div class="form-group" fxFlex="20">
        <date-picker
          #retirementDate
          name="retirementDate"
          label="Retirement date"
          [disabled]="disabled || !selfPayOrigin.organizationId"
          onlyAllowFirstOfMonth
          [required]="retirementEndRequired"
          [(ngModel)]="selfPayOrigin.retirementDate"></date-picker>
      </div>
    </div>
  </div>
