import MemberAddress from 'src/app/models/memberAddress';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import AddressType from 'src/app/models/addressType';
import { UserService } from 'src/app/services/user.service';
import { SelfpayAdminGridComponent } from './reviewGrid/selfpay.admin.grid.component';
import SelfPay from 'src/app/models/selfPay';
import SelfPayGrid from 'src/app/models/selfPayGrid';
import SelfPayVerificationStatus from 'src/app/models/selfPayVerificationStatus';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { AccessLevel, CoreService } from '../../../../services/core.service';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { sortBy, filter, findIndex, uniqBy, includes, cloneDeep, map, find } from 'lodash';
import SystemUser from 'src/app/models/user';
import { SoeService } from 'src/app/services/soe.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { SelfPayService } from 'src/app/services/selfPay.service';
import { OrganizationService } from 'src/app/services/organization.service';
import Plan from 'src/app/models/plan';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import Organization from 'src/app/models/organization';
import SelfPayElection from 'src/app/models/selfPayElections';
import { lastValueFrom } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { env } from 'src/env/development';

@UntilDestroy()
@Lookups(LookupType.DocumentType, LookupType.SelfPayVerificationStatus, LookupType.SelfPayProcessStatus, LookupType.SelfPayPaymentType,
         LookupType.SelfPayReason, LookupType.BirthSex, LookupType.GenderIdentity, LookupType.County, LookupType.Country,
         LookupType.AddressType, LookupType.MemberType, LookupType.Reason, LookupType.CobraQualifyReason, LookupType.RelationshipType,
         LookupType.RelationshipQualifyReason, LookupType.Response, LookupType.Question, LookupType.AttestationType)
@Component({
  selector: 'selfpay-admin',
  templateUrl: 'selfpay.admin.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SelfpayAdminComponent implements OnInit {
  systemUser: SystemUser;
  totalSelfpayRequests = 0;
  onlyShowPendingRecords = true;
  onlyShowAssignedRecords = true;
  gridColumns: { [k: string]: string | {} }[] = [
    { field: 'subscriberName', title: 'Subscriber', format: 'string' },
    { field: 'selfPayTypeName', title: 'Request Type', format: 'string' },
    { field: 'program', title: 'Program', format: 'string' },
    { field: 'submittedDate', title: 'Submit Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'simplifiedStatus', title: 'Status', format: 'string' },
    { field: 'coverageEffectiveDate', title: 'Effective Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'reviewDate', title: 'Verify Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'selfPayProcessStatusName', title: 'Processing Status', format: 'string' },
    { field: 'hasDocuments', title: 'Has Documents', format: 'boolean', filter: 'boolean' },
    { field: 'assignedTo', title: 'Assigned To', format: 'string'}
  ];
  @ViewChild('selfpayGrid') public selfpayGrid: SelfpayAdminGridComponent;
    lookups = {
    selfPayVerificationStatus: [],
    documentTypes: [],
    selfPayProcessStatus: [],
    selfPayPaymentTypes: [],
    genderIdentities: [],
    birthSexes: [],
    county: [],
    country: [],
    addressType: [],
    homeAgencies: [],
    memberTypes: [],
    eligibililtyReasons: [],
    enrollmentReasons: [],
    originalAgencies: [],
    cobraQualifyReasons: [],
    relationshipTypes: [],
    relationshipQualifyReasons: [],
    reason: [],
    responses: [],
    questions: [],
    attestationsTypes: []
  };
  availablePlans: Plan[];
  supplementalPlans: Plan[];
  verifyValues: SelfPayVerificationStatus[] = [];
  denyValues: SelfPayVerificationStatus[] = [];
  userCanEdit = false;
  inHCAAdminState = false;
  selectedDataItem: SelfPay;
  hcaUsers: SystemUser[];
  public gridData: GridDataResult;
  state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService,
    private selfpayService: SelfPayService,
    private spinnerService: SpinnerOverlayService,
    private organizationService: OrganizationService,
    private enrollmentService: EnrollmentService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.systemUser = data.user;
        this.userCanEdit =  this.coreService.systemUserHasAccess(AccessLevel.Edit);
        this.inHCAAdminState = data.userType === 'hca';
        this.lookups.selfPayVerificationStatus = sortBy(data.lookups.selfPayVerificationStatus, ['selfPayVerificationStatusName']);
        this.lookups.documentTypes = sortBy(data.lookups.documentTypes, ['documentTypeName']);
        this.lookups.selfPayProcessStatus = sortBy(data.lookups.selfPayProcessStatus, ['selfPayProcessStatusName']);
        this.lookups.selfPayPaymentTypes = data.lookups.selfPayPaymentType;
        this.lookups.genderIdentities = data.lookups.genderIdentity;
        this.lookups.birthSexes = data.lookups.birthSex;
        this.lookups.county = sortBy(data.lookups.county, 'countyName');
        this.lookups.country = sortBy(data.lookups.country, 'countryName');
        this.lookups.addressType = data.lookups.addressType;
        this.lookups.homeAgencies = sortBy(data.lookups.homeAgency, 'homeAgencyName');
        this.lookups.memberTypes = sortBy(filter(data.lookups.memberType, 'managedByHCAInd'), 'memberTypeName');
        this.lookups.eligibililtyReasons = sortBy(map(filter(data.lookups.selfPayReason, 'eligibilityInd'), 'reason'), 'reasonName');
        this.lookups.enrollmentReasons = sortBy(map(filter(data.lookups.selfPayReason, 'enrollmentInd'), 'reason'), 'reasonName');
        this.lookups.cobraQualifyReasons = sortBy(data.lookups.cobraQualifyReason, 'cobraQualifyReasonName');
        this.lookups.relationshipTypes = sortBy(data.lookups.relationshipType, 'relationshipTypeName');
        this.lookups.reason = data.lookups.reason;
        this.lookups.relationshipQualifyReasons = sortBy(data.lookups.relationshipQualifyReason, 'relationshipQualifyReasonName');
        this.lookups.responses = data.lookups.response;
        this.lookups.questions = filter(data.lookups.question, ['questionName', 'Spouse or Domestic Partner']);
        this.lookups.attestationsTypes = data.lookups.attestationType;
        this.organizationService.getOrganizationsForAllAgencies().pipe(untilDestroyed(this)).subscribe((orgs: Organization[]) => {
          this.lookups.originalAgencies = sortBy(orgs, 'organizationName');
        });
        this.enrollmentService.getSelfPayAvailablePlans().pipe(untilDestroyed(this)).subscribe((ap: Plan[]) => {
          this.availablePlans = sortBy(ap, (plan: Plan) => plan.planName);
          this.supplementalPlans = sortBy(
            filter(this.availablePlans, (ap2: Plan) => ap2.planType.planTypeCode === '5'),
            (avp: Plan) => Number(avp.planName.split(' ')[0])
          );
        });
        this.userService.getHCASystemUsers().subscribe(u => {
          this.hcaUsers = sortBy(u, (user)=> user.lastName);
        })
        if (this.inHCAAdminState && !this.gridData?.data?.length) {
          this.getSelfpays(this.onlyShowPendingRecords, this.onlyShowAssignedRecords);
        }
      },
      (err) => console.log(err)
    );
  }

  async getSelfpays(pending: boolean, onlyAssigned: boolean): Promise<void> {
    this.spinnerService.hide();
    this.spinnerService.show();
    var gridQuery = this.coreService.kendoGridStateToQueryString(this.state);
    this.selfpayService.getSelfPaysForHCA(pending, onlyAssigned, gridQuery).pipe(untilDestroyed(this)).subscribe(results => {
      this.gridData = results;
      this.spinnerService.hide();
    });
  }

  updateDataset(newState): void {
    if (newState) {
      this.state = newState;
    }
    this.gridData?.data?.forEach((gd, idx) => this.selfpayGrid.kendoGrid.collapseRow(idx));
    this.selectedDataItem = null;
    this.getSelfpays(this.onlyShowPendingRecords, this.onlyShowAssignedRecords);
  }

  async updateSelfpay(selfpayRec: SelfPay, generateEnrollRec?: boolean): Promise<void> {
    const updatedRecIndex = findIndex(this.gridData, (gd) => gd.selfPayId === selfpayRec.selfPayId);
    this.spinnerService.show();
    const clonedRec = cloneDeep(selfpayRec);
    const clonedRecDocs = cloneDeep(selfpayRec.documents);
    delete clonedRec.documents;
    delete clonedRec.formJson;
    delete clonedRec.member;
    try {
      const updatedRec = await lastValueFrom(this.selfpayService.updateSelfPay(selfpayRec.memberId, clonedRec));
      
      if (env.soeSelfPayTypeCodesFormRequired.includes(selfpayRec.selfPayType.selfPayTypeCode) && 
        selfpayRec.approvedDate) {
        //Submitting change of address form
        var memberAddresses: MemberAddress[] = [];
        var residentialAddressTypeId = this.lookups.addressType.find((o:AddressType)=>o.addressTypeCode === 'R').addressTypeId;
        var mailingAddressTypeId = this.lookups.addressType.find((o:AddressType)=>o.addressTypeCode === 'M').addressTypeId;
        var memberResidentialAddress: MemberAddress = new MemberAddress(selfpayRec.formJson.subscriber);
        memberResidentialAddress.addressTypeId = residentialAddressTypeId;
        memberAddresses.push(memberResidentialAddress);

        var memberMailingAddress: MemberAddress = new MemberAddress(selfpayRec.formJson.subscriber.mailingAddress);
        memberMailingAddress.addressTypeId = mailingAddressTypeId;
        memberMailingAddress.memberId = selfpayRec.memberId;
        if (selfpayRec.formJson.mailingAddressSameAsResidential) {
          memberMailingAddress.effectiveEndDate = new Date();
        }
        memberAddresses.push(memberMailingAddress);

        selfpayRec.formJson?.dependents.forEach((dep:SelfPayFormDependent)=> {
          var depResidentialAddress: MemberAddress = new MemberAddress(dep);
          depResidentialAddress.addressTypeId = residentialAddressTypeId;
          if (dep.addressSameAsSubscriber) {
            depResidentialAddress.effectiveEndDate = new Date();
          }
          memberAddresses.push(depResidentialAddress);
        });

        await lastValueFrom(this.selfpayService.submitChangeOfAddress(selfpayRec.memberId, memberAddresses));
      }

      const updatedGridRecord = await lastValueFrom(this.selfpayService.getSelfPayForHCA(selfpayRec.selfPayId));
      this.selectedDataItem = updatedRec;
      this.selectedDataItem.documents = clonedRecDocs;
      updatedRec.member.soeEnrollmentPeriod = updatedRec.selfPayEnrollmentPeriod;
      this.gridData[updatedRecIndex] = updatedGridRecord;
      setTimeout(() => this.selfpayGrid.kendoGrid.expandRow(updatedRecIndex));
      if (generateEnrollRec) {
        // refetch sub like on expand
        this.selfpayGrid.fetchSubscriberAndSetEP(updatedRec, updatedGridRecord);
        this.selfpayGrid.selfpayReviewFormComponent.navigateToDependents();
      }

      //will need to refetch the current cached subscriber if it is the same as this one
      this.coreService.checkRefetchCurrentSubscriber(updatedRec.member.memberId);

      this.coreService.popMessage('Self pay updated successfully.', 'success', 3000);
    } catch (err) {
      console.log('err', err);
    }
    this.spinnerService.hide();
  }

  async saveSelfPayElections(selfpayElections: SelfPayElection, generateEnrollRec?: boolean): Promise<void> {
    this.spinnerService.show();
    try {
      const savedRecord = await lastValueFrom(this.selfpayService.saveSelfPayElections(selfpayElections.memberId, selfpayElections.selfPayId, selfpayElections));
      this.coreService.popMessage('Self pay elections successfully saved.', 'success', 3000);
      this.spinnerService.hide();
    } catch (err) {
      this.spinnerService.hide();
      console.log('err', err);
    }
  }
}
