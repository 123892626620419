import { ActivatedRoute, Router } from '@angular/router';
/**
 * The root component. All other states are injected based on routing in this components
 * router-outlet. Global componenets (agency select, header, footer) declared here.
 */

// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';

// ext
import { lastValueFrom, Subscription } from 'rxjs';
import { faPlus, faMinus, faCalendar, faTasks } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import Attestation from 'src/app/models/attestation';
import { AttestationService } from './../../../../../../services/attestation.service';
import { cloneDeep, find } from 'lodash';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import DependentComposite from 'src/app/models/dependentComposite';
import Member from 'src/app/models/member';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import AttestationType from 'src/app/models/attestationType';

@UntilDestroy()
@Component({
  selector: 'attest-spousal',
  templateUrl: 'spousal.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class AttestSpousalComponent implements OnInit, OnDestroy {
  icons = {
    faTasks,
    faPlus,
    faMinus,
    faCalendar,
  };
  lookups = {
    questions: [],
    responses: [],
    attestationTypes: [],
    planTypes: []
  };
  effectiveYear: string;
  subscriber: Subscriber;
  currentSpouse: DependentComposite;
  enrollmentPeriod: EnrollmentPeriod;
  spousalAttestationType: AttestationType;
  constructor(private router: Router, private spinnerService: SpinnerOverlayService, private coreService: CoreService, private route: ActivatedRoute, private attestationService: AttestationService) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((d) => {
      this.subscriber = d.subscriber;
      this.lookups.questions = d.lookups.question;
      this.lookups.responses = d.lookups.response;
      this.lookups.attestationTypes = d.lookups.attestationType;
      this.lookups.planTypes = d.lookups.planType;
      this.spousalAttestationType = find(this.lookups.attestationTypes, (at: AttestationType) => at.attestationTypeCode === 'SS');
      this.currentSpouse = this.getCurrentSpouse(this.subscriber);
      this.enrollmentPeriod = find(
        this.subscriber.enrollmentPeriods,
        (ep: EnrollmentPeriod) =>
          (ep.isCurrentlyActive || (dayjs(ep.coverageEffectiveStartDate).isAfter(dayjs()) && dayjs().isAfter(ep.effectiveStartDate))) &&
          ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Open Enrollment'
      );
    });
  }

  ngOnDestroy(): void {}

  updateSpousalAttest(e): void {
    this.currentSpouse.spousalResponseId = e;
    this.currentSpouse = cloneDeep(this.currentSpouse);
  }

  getCurrentSpouse(s: Subscriber): DependentComposite {
    return new DependentComposite(
      find(
        s.members,
        (m: Member) =>
          m.relationshipToSubscriber &&
          m.relationshipToSubscriber.relationshipType &&
          m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S' &&
          !m.relationshipToSubscriber.effectiveEndDate
      )
    );
  }

  async saveAttestation(): Promise<void> {
    const attestation = new Attestation();
    attestation.memberId = this.subscriber.memberId;
    attestation.effectiveStartDate = this.enrollmentPeriod.effectiveStartDate;
    attestation.attestationTypeId = this.spousalAttestationType.attestationTypeId;
    attestation.responseId = this.currentSpouse.spousalResponseId;
    attestation.answers = this.currentSpouse.spousalAnswers;
    attestation.attestationDate = new Date();
    try {
      this.spinnerService.show();
      await lastValueFrom(this.attestationService.createTobaccoAction(attestation, this.enrollmentPeriod.enrollmentPeriodId));
    } catch (err) {
      console.log(err);
    }
    this.subscriber.refetch = true;
    this.coreService.setSubscriber(this.subscriber);
    this.coreService.popMessage('Your spousal attestation has been updated.', 'success', 8000);
    this.spinnerService.hide();
    this.navToDashboard();
  }

  navToDashboard(): void {
    this.router.navigate([`../../`], {relativeTo: this.route});
  }
}
