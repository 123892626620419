export default class NavItem {
  title: string;
  state: string;
  availableTo: string[];
  label: string;

  constructor(title: string, state: string, availableTo?: string[], label?: string) {
    this.title = title;
    this.state = state;
    this.availableTo = availableTo;
    this.label = label;
  }

}
