import { Component, Input, Injector, OnInit, ViewChild, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors, AbstractControl, NgForm } from '@angular/forms';
import { DatePickerComponent as KendoDatePicker, DateTimePickerComponent as KendoDateTimePicker } from '@progress/kendo-angular-dateinputs';
import { Day } from '@progress/kendo-date-math';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { __assign } from 'tslib';
import { lastValueFrom } from 'rxjs';
import { LowerLimitService } from '../../../../services/lowerLimit.service';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { env } from 'src/env/development';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'date-picker',
  templateUrl: 'datePicker.component.html',
  styleUrls: [],
  providers:
  [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DatePickerComponent
    }
  ]
})
export class DatePickerComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @ViewChild('picker') picker: KendoDatePicker;
  @ViewChild('timePicker') timePicker: KendoDateTimePicker;
  @ViewChild('form') form: NgForm;
  @Input() name = 'picker';
  @Input() label: string;
  @Input() required: boolean | string = false;
  @Input() readOnly: boolean | string = false;
  @Input() readOnlyInput: boolean | string = false;
  @Input() labelClass: string;
  @Input() inputClass: string;
  @Input() disabled: boolean | string = false;
  @Input() max: Date | NgbDate;
  @Input() min: Date | NgbDate;
  @Input() placeholder = 'mm/dd/yyyy';
  // ngbDatePicker support
  @Input() markDisabled: ((date: NgbDate, current: {year: number, month: number}) => boolean);
  // regular/new support
  @Input() disabledDates: ((date: Date) => boolean) | Date[] | Day[];
  @Input() labelColon: boolean | string = false;
  @Input() labelFlex: number = undefined;
  @Input() inputFlex: number = undefined;
  @Input() hideIcon: boolean | string = false;
  @Input() useTime: boolean | string = false;
  @Input() calendarType = 'classic';
  @Input() extraMessage: string;
  @Input() coverageEffectiveDate: Date;
  @Input() enforceLowerLimit: boolean | string = false;
  @Input() lowerLimitOffset = false;
  @Input() onlyAllowFirstOfMonth: boolean | string = false;
  @Input() onlyAllowLastOfMonth: boolean | string = false;
  @Input() lockToMonth: Date = null;
  @Input() canBeInFuture = true;
  @Input() agencyCode: string = env.pebbCode;

  innerValue: Date;
  labelVisible = true;
  // twoDigitYearMax = 68;
  innerMax: Date;
  innerMin: Date;
  innerDisabledDates: ((date: Date) => boolean) | Date[] | Day[];
  innerRequired: boolean;
  innerReadonly: boolean;
  innerDisabled: boolean;
  innerHideIcon: boolean;
  innerUseTime: boolean;
  innerReadOnlyInput: boolean;
  innerEnforceLowerLimit: boolean;
  showDebugInfo = env.datePickerDebugInfo;
  debugOpen = false;
  maxDateStatus: string[] = [];
  minDateStatus: string[] = [];
  private warnings = {};
  private onChange = (value: Date) => {};
  private onTouched = () => {};
  private onValidateChange = () => {};

  constructor(private inj: Injector, private lowerLimitService: LowerLimitService, private coreService: CoreService) {}

  ngOnInit(): void {
    // this.twoDigitYearMax = parseInt((new Date().getFullYear() + 2).toString().substring(2));

    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    const that = this;
    outerControl.markAsTouched = (...args: any) => {
        const control = that.getChildControl();
        control?.parent.markAllAsTouched();

        control?.updateValueAndValidity();

        prevMarkAsTouched.bind(outerControl)(...args);
      };
  }

  dblClick(): void {
    if (this.showDebugInfo) {
      this.debugOpen = !this.debugOpen;
    }
  }

  getChildControl(): any {
    let control = null;
    for (const k in this.form?.controls) {
      if (this.form.controls[k]) {
        if (this.innerUseTime && k.endsWith('-time')) {
          control = this.form.controls[k];
          break;
        }

        control = this.form.controls[k];
      }
    }

    return control;
  }

  // Necessary if there is conditional binding on the required attribute
  resetValue(): void {
    const originalValue = this.value;
    this.value = null;
    this.value = originalValue;
    this.picker.blur();
  }

  setMaxDate(maxDate: Date): void {
    if (!this.innerMin) {
      this.innerMax = this.translateDate(maxDate, true);
      return;
    }

    if (maxDate >= this.innerMin) {
      this.innerMax = this.translateDate(maxDate, true);
      return;
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    // Clear any previous validation errors, since we're changing setup parameters
    // for our control.
    setTimeout(() => this.getChildControl()?.setErrors(null), 0);

    if (changes && changes.min) {
      this.minDateStatus.push(`Incoming min supplied '${changes.min.currentValue}'`);
      this.innerMin = this.translateDate(changes.min.currentValue);
    }

    if (changes && changes.max) {
      this.maxDateStatus.push(`Incoming max supplied: '${changes.max.currentValue}'`);
      this.setMaxDate(changes.max.currentValue);
      // this.innerMax = this.translateDate(changes.max.currentValue, true);
    }

    if (changes && (changes.markDisabled || changes.disabledDates)) {
      this.innerDisabledDates = this.generateDisabledDates
        (
          changes.markDisabled?.currentValue ?? this.markDisabled,
          changes.disabledDates?.currentValue ?? this.disabledDates
        );
    }

    if (changes && changes.required) {
      this.innerRequired = this.flagIsTrue(changes.required.currentValue);
    }

    if (changes && changes.readOnly) {
      this.innerReadonly = this.flagIsTrue(changes.readOnly.currentValue);
    }

    if (changes && changes.readOnlyInput) {
      this.innerReadOnlyInput = this.flagIsTrue(changes.readOnlyInput.currentValue);
    }

    if (changes && changes.disabled) {
      this.innerDisabled = this.flagIsTrue(changes.disabled.currentValue);
    }

    if (changes && changes.hideIcon) {
      this.innerHideIcon = this.flagIsTrue(changes.hideIcon.currentValue);
    }

    if (changes && changes.useTime) {
      this.innerUseTime = this.flagIsTrue(changes.useTime.currentValue);
    }

    if (changes && changes.lockToMonth?.currentValue) {
      const startOfMonth = dayjs(changes.lockToMonth.currentValue).startOf('month').toDate();
      const endOfMonth = dayjs(changes.lockToMonth.currentValue).endOf('month').toDate();

      if (!this.innerMax || endOfMonth < this.innerMax) {
        this.maxDateStatus.push(`Lock to month supplied: '${endOfMonth}'`);
        this.setMaxDate(endOfMonth);
        // this.innerMax = this.translateDate(endOfMonth, true);
      }

      if (!this.innerMin || startOfMonth > this.innerMin) {
        this.minDateStatus.push(`Lock to month supplied: '${startOfMonth}'`);
        this.innerMin = this.translateDate(startOfMonth, false);
      }
    }

    if (changes && changes.canBeInFuture?.currentValue === false) {
      const endOfToday = new Date();

      if (!this.innerMax || endOfToday < this.innerMax) {
        this.maxDateStatus.push('Can be in future = false');
        this.setMaxDate(endOfToday);
        // this.innerMax = this.translateDate(endOfToday, true);
      }
    }

    if (changes && (changes.enforceLowerLimit || changes.agencyCode)) {
      if (changes.enforceLowerLimit) {
        this.innerEnforceLowerLimit = this.flagIsTrue(changes.enforceLowerLimit.currentValue);
      }

      if (this.innerEnforceLowerLimit) {
        // const isPerspayAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.Perspay);
        const isHCA = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA) || this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);

        const coverageEffectiveDate = changes?.coverageEffectiveDate?.currentValue ?? this.coverageEffectiveDate ?? new Date();
        const agencyCode = this.agencyCode ?? env.pebbCode;

        const ll = await lastValueFrom(this.lowerLimitService.getLowerLimit(coverageEffectiveDate, false, agencyCode, this.lowerLimitOffset));
        const ul = await lastValueFrom(this.lowerLimitService.getUpperLimit(coverageEffectiveDate, agencyCode));

        if (changes.agencyCode && (changes.agencyCode.previousValue || changes.agencyCode.currentValue === env.sebbCode)) {
          // If we're switching between agencies, see if the max/min were set to the previous upper/lower limit.  If so, clear them
          // out so we ensure the new values for the new agency get set
          const otherLL = await lastValueFrom(this.lowerLimitService.getLowerLimit(coverageEffectiveDate, false, changes.agencyCode.previousValue, this.lowerLimitOffset));
          const otherUL = this.translateDate(await lastValueFrom(this.lowerLimitService.getUpperLimit(coverageEffectiveDate, changes.agencyCode.previousValue)), true);

          if (this.innerMax && dayjs(this.innerMax).isSame(otherUL))
            this.innerMax = null;
          
          if (this.innerMin && dayjs(this.innerMin).isSame(otherLL))
            this.innerMin = null;
        }

        if (!isHCA && (!this.innerMax || ul < this.innerMax)) {
          this.maxDateStatus.push(`Upper limit applied: '${ul}' (${agencyCode})`);
          this.setMaxDate(ul);
          // this.innerMax = this.translateDate(ul, true);
        }

        if (isHCA) {
          this.minDateStatus.push(`Lower limit *NOT* applied (user is HCA; lower limit would have been: '${ll}')`);
        } else {
          if (!this.innerMin || ll > this.innerMin || changes.agencyCode) {
            this.minDateStatus.push(`Lower limit applied '${ll}' (${agencyCode})`);
            this.innerMin = this.translateDate(ll, false);
          }
        }
      }
    }

    const previousDisabledDatesFunction = this.innerDisabledDates;
    if (changes && changes.onlyAllowLastOfMonth) {
      if (this.flagIsTrue(changes.onlyAllowLastOfMonth.currentValue)) {
        this.innerDisabledDates = (d: Date) => {
          const endOfMonth = dayjs(d).endOf('month').date();
          let result = dayjs(d).date() !== endOfMonth;

          if (typeof previousDisabledDatesFunction === 'function') {
            result = result && previousDisabledDatesFunction(d);
          }

          return result;
        };
      } else {
        this.innerDisabledDates = null;
      }
    }

    if (changes && changes.onlyAllowFirstOfMonth) {
      if (this.flagIsTrue(changes.onlyAllowFirstOfMonth.currentValue)) {
        this.innerDisabledDates = (d: Date) => {
          let result = dayjs(d).date() !== 1;

          if (typeof previousDisabledDatesFunction === 'function') {
            result = result && previousDisabledDatesFunction(d);
          }

          return result;
        };
      } else {
        this.innerDisabledDates = null;
      }
    }
  }

  private flagIsTrue(value: string | boolean): boolean {
    return value === true || value === '';
  }

  private warn(area: string, message: string): void {
    if (!this.warnings[area]) {
      this.warnings[area] = message;
      console.log(message);
    }
  }

  innerChange(value: Date): void {
    this.onChange(value);
  }

  generateDisabledDates(markDisabled: ((date: NgbDate, current: {year: number, month: number}) => boolean), disabledDates: ((date: Date) => boolean) | Date[] | Day[]):
    ((date: Date) => boolean) | Date[] | Day[] {
    if (markDisabled) {
      if (disabledDates) {
        this.warn('disabledDates', 'Both markDisabled (legacy) and disabledDates supplied to datePicker; markDisabled is being used.');
      }

      this.warn('markDisabled', 'Legacy markDisabled (ngbDatePicker) used; consider upgrading to disabledDates.');

      // Translate the Kendo datepicker function arguments into the old ngbDatePicker arguments
      const outputFunction = (date: Date) => {
        const d = dayjs(date);
        const ngbDate = new NgbDate(d.year(), d.month() + 1, d.date());
        const current = { year: d.year(), month: d.month() + 1 };

        return markDisabled(ngbDate, current);
      };

      return outputFunction;
    }

    return disabledDates;
  }

  private translateDate(date: Date | NgbDate, translatingMaxDate = false): Date {
    if (date) {
      if (typeof date === 'string')
        date = dayjs(date).toDate();
        
      if (date instanceof Date) {
        if (translatingMaxDate) {
          date = dayjs(date).endOf('day').toDate();
        } else {
          date = dayjs(date).startOf('day').toDate();
        }
      }

      if (date instanceof Date) {
        return date;
      }

      this.warn('minmax', 'Legacy Min/Max NgbDate (ngbDatePicker) used; consider upgrading to vanilla JS Date.');

      let result = dayjs(`${date.year}-${date.month}-${date.day}`).toDate();
      if (translatingMaxDate) {
        result = dayjs(result).endOf('day').toDate();
      } else {
        result = dayjs(result).startOf('day').toDate();
      }
      return result;
    }

    return null;
  }

  // ControlValueAccessor - Write initial value into component.
  writeValue(value: any): void {
    if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?$/)) {
      value = new Date(value);
    }

    if (value !== this.innerValue) {
      if (!this.innerUseTime && value) {
        this.innerValue = dayjs(value).startOf('day').toDate();
      } else {
        this.innerValue = value;
      }

      this.onValidateChange();
    }
  }

  // ngModel two-way binding support (and also downstream value pass-through to kendo-datepicker)
  get value(): Date {
    return this.innerValue;
  }

  // ngModel two-way binding support (and also downstream value pass-through to kendo-datepicker)
  set value(v: Date) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.innerChange(v);
      this.onValidateChange();
    }
  }

  dateChanged(newDate: Date): void {
    if (!this.innerUseTime && newDate) {
     this.innerValue = dayjs(newDate).startOf('day').toDate();
    }
  }

  dateBlurred(): void {
    // if (this.innerValue) {
    //   const date = dayjs(this.innerValue);
    //   if (date.year() < 100) {
    //     let century = 1900;
    //     if (date.year() < this.twoDigitYearMax) {
    //       century = 2000;
    //     }

    //     const newDate = new Date((century + date.year()), date.month(), date.date());
    //     this.innerValue = newDate;
    //   }
    // }

    this.onTouched();
    this.onValidateChange();
  }

  // ControlValueAccessor - Parent form changes disabled state.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // ControlValueAccessor - Parent form registers on change listener (is dirty)
  registerOnChange(fn): void {
    this.onChange = fn;
  }

  // ControlValueAccessor - Parent form registers on touched listener (is touched)
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  // Validator - Parent form registers on validator change listener
  registerOnValidatorChange(fn): void {
    this.onValidateChange = fn;
  }

  // Validator - Parent form detects need to validate.
  validate(control: AbstractControl): ValidationErrors | null {
    const name = 'control';
    const errors: ValidationErrors = this.getChildControl()?.errors;

    if (errors && (errors.maxError || errors.minError)) {
      errors.ngbDate = {};

      if (errors.maxError) {
        errors.ngbDate.maxDate = errors.maxError;
      }

      if (errors.minError) {
        errors.ngbDate.minDate = errors.minError;
      }
    }

    return errors;
  }

  determineLabelVisibility(a): void {
    // Find all child nodes of the label node and filter by text nodes.
    // If the text node is present and has text, a label was provided *OR*
    // child nodes were provided for the label.  If nothing was provided,
    // hide all label HTML so we don't have extra empty nodes floating around.
    const children = Array.prototype.slice.call(a.childNodes)
      .filter(n => n.nodeType !== Node.TEXT_NODE || (n.nodeType === Node.TEXT_NODE && n.textContent && n.textContent.trim()));

    this.labelVisible = children.length > 0;
  }
}
