// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// ext
import { Observable } from 'rxjs';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { catchError, map } from 'rxjs/operators';
// local
import Registration from '../models/registration';
import Claim from '../models/claim';
import SimpleSubscriber from '../models/simpleSubscriber';
import SystemUser from '../models/user';
import SecurityQuestionAnswer from '../models/securityQuestionAnswer';
import { CommonService } from './common.service';
import { env } from '../../env/development';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public isLoggedIn = false;
  redirectUrl: string;
  currentUser: any = null;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  login(): void {
    var ao: AuthOptions = {};
    ao.customParams = {idp: env.idpMFAUrl};
    this.oidcSecurityService.authorize(null, ao);
    this.isLoggedIn = true;
  }

  adminLogin(): void {
    var ao: AuthOptions = {};
    ao.customParams = {idp: env.idpMFAUrl};
    this.oidcSecurityService.authorize(null, ao);
    this.isLoggedIn = true;
  }

  hcaLogin(): void {
    var ao: AuthOptions = {};
    ao.customParams = {idp: env.idpEADUrl};
    this.oidcSecurityService.authorize(null, ao);
    this.isLoggedIn = true;
  }

  logout(): void {
    this.oidcSecurityService.logoff();
    this.isLoggedIn = false;
  }

  submitRegistration(registration: Registration): Observable<boolean> {
    return this.http
      .post<Registration>(`${env.apiUrl}/Registration`, registration)
      .pipe(map(x => this.commonService.createObject(Boolean, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  testEndpoint(): Observable<boolean> {
    return this.http
      .get(`${env.apiUrl}/identity`)
      .pipe(map(x => this.commonService.createObject(Boolean, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  submitClaim(claim: Claim): Observable<SimpleSubscriber[]> {
    return this.http
      .post<Claim>(`${env.apiUrl}/member/accountclaim`, claim)
      .pipe(map(x => this.commonService.createObjects(SimpleSubscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  claimRecord(memberId: string, claim: Claim): Observable<SystemUser> {
    return this.http
    .post(`${env.apiUrl}/systemuser/${memberId}`, claim)
    .pipe(map(x => this.commonService.createObject(SystemUser, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  reClaimRecord(memberId: string, claim: Claim): Observable<SystemUser> {
    return this.http
    .post(`${env.apiUrl}/member/accountreclaim`, claim)
    .pipe(map(x => this.commonService.createObject(SystemUser, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  answerSecurityQuestion(memberId: string, answer: SecurityQuestionAnswer): Observable<SystemUser> {
    return this.http
    .post(`${env.apiUrl}/member/${memberId}/securityQuestion`, answer)
    .pipe(map(x => this.commonService.createObject(SecurityQuestionAnswer, x)))
    .pipe(catchError(this.commonService.handleError));
  }

}
