<div class="sync-error">
  <div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <div class="state">
      <div class="card">
        <div class="card-body">
          <h2 class="mobile-text-centered" style="margin-top: 8px; margin-bottom: 15px">HCA Error Handling</h2>
          <h5>
            This page displays the accounts that currently have unresolved errors from the B24/7 to PAY1 interface file.
          </h5>
          <div class="grid-container">
            <kendo-grid
              #kendoGrid
              [data]="data.l1.clonedGridData"
              class="k-grid-ignore-click"
              [filterable]="'menu'"
              [filter]="data.l1.state.filter"
              [skip]="data.l1.state.skip"
              [sortable]="true"
              [pageable]="true"
              [pageSize]="20"
              
              (dataStateChange)="dataStateChange($event, data.l1)"
              (detailExpand)="getRowDetails($event)"
              (detailCollapse)="data.l1.selectedItem = null"
              (pageChange)="pageChange($event, data.l1)"
            >
              <kendo-grid-column
                class="k-grid-ignore-click"
                [style.flex]="col.flex || 1"
                *ngFor="let col of data.l1.columns"
                [field]="col.field"
                [title]="col.title"
                [width]="col.width"
                [format]="col.format"
                [filter]="col.filter ? col.filter : null"
                [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
              >
                <ng-template kendoGridFilterMenuTemplate
                  let-column="col"
                  let-filter="filter"
                  let-filterService="filterService"
                  *ngIf="col.field.endsWith('Date')"
                  >
                  <date-picker
                    name="dateFilter"
                    [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
                    (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
                    [label]="col.title">
                  </date-picker>
                </ng-template>
              </kendo-grid-column>

              <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="grid-container sync-l2-grid">
                  <kendo-grid
                    [data]="data.l2.clonedGridData"
                    class="k-grid-ignore-click"
                    [filterable]="'menu'"
                    [filter]="data.l2.state.filter"
                    [skip]="data.l2.state.skip"
                    [sortable]="true"
                    [pageable]="true"
                    [pageSize]="20"
                    [kendoGridSelectBy]="data.l2.selectBy"
                    [selectedKeys]="data.l2.selectedKeys"
                    
                    (dataStateChange)="dataStateChange($event, data.l2)"
                    (pageChange)="pageChange($event, data.l2)"
                    (selectionChange)="changeSelection($event, data.l2)"
                  >
                    <kendo-grid-checkbox-column width="45" *ngIf="isHcaSysAdmin">
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
                        <input
                          [disabled]="data.l2.selectedItem"
                          class="k-checkbox k-checkbox-md k-rounded-md"
                          [kendoGridSelectionCheckbox]="rowIndex" />
                      </ng-template>
                    </kendo-grid-checkbox-column>
                    <kendo-grid-column
                      class="k-grid-ignore-click"
                      [style.flex]="col.flex || 1"
                      *ngFor="let col of data.l2.columns"
                      [field]="col.field"
                      [title]="col.title"
                      [width]="col.width"
                      [format]="col.format"
                      [filter]="col.filter ? col.filter : null"
                      [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
                    >
                      <ng-template kendoGridFilterMenuTemplate
                        let-column="col"
                        let-filter="filter"
                        let-filterService="filterService"
                        *ngIf="col.field.endsWith('Date')"
                        >
                        <date-picker
                          name="dateFilter"
                          [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
                          (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
                          [label]="col.title">
                        </date-picker>
                      </ng-template>

                      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <div *ngIf="col.format.date">
                          {{dataItem[col.field] | date: 'MM/dd/yyyy'}}
                        </div>
                        <div *ngIf="!col.format.date && [ 'errorInfo', 'source' ].indexOf(col.field) === -1">
                          {{dataItem[col.field]}}
                        </div>
                        <ul *ngIf="[ 'errorInfo', 'source' ].indexOf(col.field) > -1">
                          <li *ngFor="let item of dataItem[col.field]">{{item}}</li>
                        </ul>
                      </ng-template>
                    </kendo-grid-column>
        
                    <kendo-grid-column
                      class="k-grid-ignore-click"
                      style.flex="1"
                      title=""
                      [width]="450"
                      [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
                    >
                      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <div fxLayout="row">
                          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="gappx" [fxFlex]="dataItem.isBeyondAllowableLimit() ? 33 : 50">
                            <button type="button" [disabled]="data.l2.selectedItem" class="btn btn-secondary" (click)="markAsProcessed(dataItem)">Mark record as processed</button>
                          </div>
                          <div *ngIf="dataItem.isBeyondAllowableLimit()" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="gappx" fxFlex="30" fxFlexOffset="3">
                            <button type="button" [disabled]="data.l2.selectedItem" class="btn btn-primary" (click)="allowSync(dataItem)">Allow Sync to PAY1</button>
                          </div>
                          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="gappx" [fxFlex]="dataItem.isBeyondAllowableLimit() ? 30 : 40" [fxFlexOffset]="dataItem.isBeyondAllowableLimit() ? 3 : 5">
                            <button type="button" [disabled]="data.l2.selectedItem" class="btn btn-primary" (click)="viewRecordL2(dataItem)">View/update record data</button>
                          </div>
                        </div>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </div>

                <div class="grid-container sync-l2-grid" *ngIf="!data.l2.selectedItem" fxLayout="column" fxLayoutAlign="start end" fxFlexFill>
                  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
                    <div class="form-group" fxFlex="20">
                      <button type="button" class="btn btn-primary" (click)="finishManaging()">Finish managing</button>
                    </div>
                    <div class="form-group" fxFlex="20" *ngIf="isHcaSysAdmin">
                      <button [disabled]="!data.l2.selectedKeys?.length" type="button" class="btn btn-primary" (click)="resendRecords(data.l2.selectedKeys)">Resend records</button>
                    </div>
                  </div>
                </div>

                <div class="grid-container sync-l2-grid" *ngIf="data.l2.selectedItem">
                  <kendo-grid
                    [data]="data.l3.clonedGridData"
                    class="k-grid-ignore-click"
                    [sortable]="false"
                    [pageable]="false"
                    
                    (dataStateChange)="dataStateChange($event, data.l3)"
                  >
                    <kendo-grid-column
                      class="k-grid-ignore-click"
                      [style.flex]="col.flex || 1"
                      *ngFor="let col of data.l3.columns"
                      [field]="col.field"
                      [title]="col.title"
                      [width]="col.width"
                      [format]="col.format"
                      [filter]="col.filter ? col.filter : null"
                      [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
                    >
                      <ng-template kendoGridFilterMenuTemplate
                        let-column="col"
                        let-filter="filter"
                        let-filterService="filterService"
                        *ngIf="col.field.endsWith('Date')"
                        >
                        <date-picker
                          name="dateFilter"
                          [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
                          (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
                          [label]="col.title">
                        </date-picker>
                      </ng-template>

                      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <div *ngIf="col.format.date">
                          {{dataItem[col.field] | date: 'MM/dd/yyyy'}}
                        </div>
                        <div *ngIf="!col.format.date && [ 'pay1ErrorInfo', 'sources', 'enrollmentPeriodIds' ].indexOf(col.field) === -1">
                          {{dataItem[col.field]}}
                        </div>
                        <ul *ngIf="[ 'pay1ErrorInfo', 'sources', 'enrollmentPeriodIds' ].indexOf(col.field) > -1">
                          <li *ngFor="let item of dataItem[col.field]">{{item}}</li>
                        </ul>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </div>

                <div class="grid-container sync-l2-grid" *ngIf="data.l2.selectedItem" fxLayout="column" fxLayoutAlign="end" fxFlexFill>
                  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
                    <div class="form-group" fxFlex="20">
                      <button type="button" class="btn btn-primary" (click)="data.l2.selectedItem = null">Save/close</button>
                    </div>
                    <div class="form-group" fxFlex="20">
                      <button type="button" class="btn btn-primary" (click)="viewRecordL3(data.l3.data[0])">Open detailed record</button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <div>
      <button type="button" class="btn btn-primary" (click)="openSyncLocks()">Manage Sync Locks</button>
    </div>
  </div>

  <kendo-dialog
    title="Detailed sync file record correction"
    *ngIf="data.l3.selectedItem"
    (close)="closeWizardDialogue()"
    [minWidth]="700"
    width="95vw"
    minHeight="350px"
    height="350px"
  >
    <div class="grid-container sync-l4-grid" [class.sub]="l4 === 'sub'" [class.dep]="l4 === 'dep'">
      <kendo-grid
        [data]="data.l4.clonedGridData"
        class="k-grid-ignore-click"
        [sortable]="false"
        [pageable]="false"
      >
        <kendo-grid-column
          class="k-grid-ignore-click"
          [style.flex]="col.flex || 1"
          *ngFor="let col of data.l4.columns"
          [field]="col.field"
          [title]="(col.title === 'signature_date_dt') ? 'Enrollment Signature Date' : col.title"
          [width]="col.width"
          [format]="col.format"
          [filter]="col.filter ? col.filter : null"
          [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div *ngIf="rowIndex === 0 || editableFields.indexOf(col.field) === -1">{{dataItem[col.field]}}</div>
            <div *ngIf="rowIndex > 0 && editableFields.indexOf(col.field) > -1">
              <input
                type="text"
                [name]="col.field"
                [(ngModel)]="dataItem[col.field]"/>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>

    <div class="grid-container sync-l4-grid" fxLayout="column" fxLayoutAlign="start end" fxFlexFill>
      <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
        <div class="form-group" fxFlex="20">
          <button type="button" class="btn btn-primary" (click)="closeWizardDialogue()">Cancel</button>
        </div>
        <div class="form-group" fxFlex="20" *ngIf="isHcaSysAdmin">
          <button type="button" class="btn btn-primary" (click)="saveOutput(data.l3.selectedItem.subscriberMemberId, data.l3.selectedItem.pay1SyncHoldId, data.l4.clonedGridData.data[1])">Save/Exit</button>
        </div>
      </div>
    </div>
  </kendo-dialog>

  <kendo-dialog
    title="Sync Locks"
    *ngIf="showSyncLocks"
    (close)="showSyncLocks = false; addLock = false"
    [minWidth]="700"
    width="95vw"
    minHeight="350px"
    maxHeight="95vw"
  >
    <div *ngIf="!addLock">
      <checkbox label="Show active only" [(ngModel)]="showActiveLocksOnly" (change)="openSyncLocks()"></checkbox>
    </div>
    <div class="grid-container sync-locks" *ngIf="!addLock">
      <kendo-grid
        [data]="data.locks.clonedGridData"
        class="k-grid-ignore-click"
        [filterable]="'menu'"
        [filter]="data.locks.state.filter"
        [skip]="data.locks.state.skip"
        [sortable]="true"
        [pageable]="true"
        [pageSize]="20"
        [kendoGridSelectBy]="data.locks.selectBy"
        [selectedKeys]="data.locks.selectedKeys"
        [selectable]="locksSelectable"
        
        (dataStateChange)="dataStateChange($event, data.locks)"
        (pageChange)="pageChange($event, data.locks)"
        (selectionChange)="changeSelection($event, data.locks)"
      >
        <kendo-grid-checkbox-column width="45" *ngIf="isHcaSysAdmin">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
            <input
              class="k-checkbox k-checkbox-md k-rounded-md"
              [kendoGridSelectionCheckbox]="rowIndex" />
          </ng-template>
        </kendo-grid-checkbox-column>
        
        <kendo-grid-column
          class="k-grid-ignore-click"
          [style.flex]="col.flex || 1"
          *ngFor="let col of data.locks.columns"
          [field]="col.field"
          [title]="col.title"
          [width]="col.width"
          [format]="col.format"
          [filter]="col.filter ? col.filter : null"
          [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
        >
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div *ngIf="col.format.date">
              {{dataItem[col.field] | date: 'MM/dd/yyyy'}}
            </div>
            <div *ngIf="!col.format.date">
              {{dataItem[col.field]}}
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>

    <div style="text-align:center;" *ngIf="!addLock">
      <button class="btn btn-primary" (click)="addLock = true">Add Lock</button>
      <button class="btn btn-primary" [disabled]="!data.locks.selectedKeys.length" (click)="endLocks(data.locks.selectedKeys)">End Lock{{data.locks.selectedKeys.length > 1 ? '(s)' : ''}}</button>
    </div>

    <div *ngIf="addLock">
      <div style="font-weight:bold;margin-bottom:10px;">Add Lock</div>
      <div>
        <div fxFlexFill>
            <div class="form-group required">
                <label for="addLockMemberIdOrSsn">Member ID or SSN</label>
                <input type="text" class="form-control" [(ngModel)]="addLockMemberIdOrSsn" name="addLockMemberIdOrSsn" />
            </div>
        </div>
      </div>
      <div style="text-align:center;">
          <button class="btn btn-primary" (click)="searchLock(addLockMemberIdOrSsn)">Search</button>
      </div>

      <div class="grid-container sync-locks" *ngIf="showSearchLocks">
        <kendo-grid
          [data]="data.searchLocks.clonedGridData"
          class="k-grid-ignore-click"
          [filterable]="'menu'"
          [filter]="data.searchLocks.state.filter"
          [skip]="data.searchLocks.state.skip"
          [sortable]="true"
          [pageable]="true"
          [pageSize]="20"
          [kendoGridSelectBy]="data.searchLocks.selectBy"
          [selectedKeys]="data.searchLocks.selectedKeys"
          
          (dataStateChange)="dataStateChange($event, data.searchLocks)"
          (pageChange)="pageChange($event, data.searchLocks)"
          (selectionChange)="changeSelection($event, data.searchLocks)"
        >
          <kendo-grid-checkbox-column width="45" *ngIf="isHcaSysAdmin">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
              <input
                class="k-checkbox k-checkbox-md k-rounded-md"
                [kendoGridSelectionCheckbox]="rowIndex" />
            </ng-template>
          </kendo-grid-checkbox-column>

          <kendo-grid-column
            class="k-grid-ignore-click"
            [style.flex]="col.flex || 1"
            *ngFor="let col of data.searchLocks.columns"
            [field]="col.field"
            [title]="col.title"
            [width]="col.width"
            [format]="col.format"
            [filter]="col.filter ? col.filter : null"
            [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
          >
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <div *ngIf="col.format.date">
                {{dataItem[col.field] | date: 'MM/dd/yyyy'}}
              </div>
              <div *ngIf="!col.format.date">
                {{dataItem[col.field]}}
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>

      <div style="text-align:center;" *ngIf="showSearchLocks">
        <checkbox label="Account-level lock" [(ngModel)]="lockAccountLevel"></checkbox>

        <button class="btn btn-primary" [disabled]="!data.searchLocks.selectedKeys.length" (click)="lockRecords(data.searchLocks.selectedKeys, lockAccountLevel)">Lock Selected Record{{data.searchLocks.selectedKeys.length > 1 ? '(s)' :''}}</button>
      </div>
    </div>
  </kendo-dialog>
</div>