<div style="padding-left: 15px; align-self: flex-end;" class="mgbt-medium mgtp-medium">
  <span><strong class="pad-std-label">Currently managing:</strong></span><span>{{ navMember.firstName }} {{ navMember.lastName }}</span>
</div>
<div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
  <ul fxFlexLayout="row" style="margin-left: 15px" class="nav nav-tabs admin-tab-group">
    <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'subscriber/' + navMember.memberId">Dashboard</strong></li>
    <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'eligibility/' + navMember.memberId">Eligibility</strong></li>
    <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'dependents/' + navMember.memberId">Manage Dependents</strong></li>
    <li *ngIf="!isSelfPay && (!navMember.newlyIncomplete  || !navMember.currentlyNewlyEligible)" class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'soe/' + this.navMember.memberId">Special Open Enrollment</strong></li>
    <li *ngIf="isSelfPay && spPeriodComplete" class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'selfPay/' + navMember.memberId + '/soe'">Special Open Enrollment</strong></li>
    <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'profile/' + navMember.memberId">Profile</strong></li>
    <!-- <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'upload/'+ navMember.memberId">Document Upload</strong></li> -->
    <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'attest/tobacco/' + navMember.memberId">Tobacco Surcharge Attestations</strong></li>
    <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'summary/' + navMember.memberId">Current Coverage</strong></li>
    <li *ngIf="isHCA" class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'spousal/' + navMember.memberId">Spousal Attestations</strong></li>
    <li *ngIf="isHCA" class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'notes/' + navMember.memberId">Notes</strong></li>
    <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'supplemental/' + navMember.memberId"  [queryParams]="{tabmgmt: true}">Supplemental Benefits</strong></li>
  </ul>
</div>
