import { map } from 'lodash';
import ReportType from './reportType';
import ReportParameter from './reportParameter';

export default class Report {
  reportId: string;
  reportName: string;
  reportDescription: string;
  reportType: ReportType;
  reportTypeId: string;
  userRoleId: string;
  userAccessLevelId: string;
  reportParameters: ReportParameter[];
  obsoleteFlag: boolean;
  obsoleteDate: Date;
  agencyIds: string[];

  constructor(report?) {
    if (report) {
      this.reportId = report.reportId;
      this.reportName = report.reportName;
      this.reportDescription = report.reportDescription;
      this.reportType = new ReportType(report.reportType);
      this.reportTypeId = report.reportTypeId;
      this.userRoleId = report.userRoleId;
      this.userAccessLevelId = report.userAccessLevelId;
      this.reportParameters = map(report.parameters, (rp) => new ReportParameter(rp));
      this.obsoleteFlag = report.obsoleteFlag;
      this.obsoleteDate = report.obsoleteDate ? new Date(report.obsoleteDate) : null;
      this.agencyIds = report.agencyIds ?? [];
    }
  }
}
