import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent {
  public message: string ="";
  public accessibilityMessage: string = "";

  constructor() { 
  }
}
