import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import Document from './document';
import SOEForm from './soeForm';
import Member from './member';
import SpecialOpenEnrollmentType from './specialOpenEnrollmentType';
import { some, find, map, cloneDeep } from 'lodash';
import * as dayjs from 'dayjs';
import EnrollmentPeriod from './enrollmentPeriod';
import Subscriber from './subscriber';

export default class SpecialOpenEnrollment {
  specialOpenEnrollmentId: string;
  specialOpenEnrollmentTypeId: string;
  specialOpenEnrollmentType: SpecialOpenEnrollmentType;
  memberId: string;
  member?: Member | Subscriber;
  requestReceivedDate: Date;
  eventDate: Date;
  coverageEffectiveDate: Date;
  documentTypeId: string;
  approvedDate: Date;
  approvedById: string;
  deniedDate: Date;
  deniedById: string;
  simplifiedStatus: string;
  // formJson: SOEForm;
  specialOpenEnrollmentVerificationStatusId: string;
  specialOpenEnrollmentVerificationStatus: SpecialOpenEnrollmentVerificationStatus;
  currentOrganization: string;
  documents: Document[];
  approvedInd: boolean;
  expirationDate?: Date;
  enrollmentPeriod: EnrollmentPeriod;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  hasDocuments: boolean;
  hasDocumentsText: string;
  coverageEffectiveDateOverride: Date;

  constructor(specialOpenEnrollment?, subscriber?) {
    if (specialOpenEnrollment) {
      if (!specialOpenEnrollment.member?.members && subscriber?.members) {
        specialOpenEnrollment.member.members = cloneDeep(subscriber.members);
      }

      this.specialOpenEnrollmentId = specialOpenEnrollment.specialOpenEnrollmentId;
      this.specialOpenEnrollmentTypeId = specialOpenEnrollment.specialOpenEnrollmentTypeId;
      this.specialOpenEnrollmentType = new SpecialOpenEnrollmentType(specialOpenEnrollment.specialOpenEnrollmentType);
      this.specialOpenEnrollmentVerificationStatusId = specialOpenEnrollment.specialOpenEnrollmentVerificationStatusId;
      this.specialOpenEnrollmentVerificationStatus = new SpecialOpenEnrollmentVerificationStatus(specialOpenEnrollment.specialOpenEnrollmentVerificationStatus);
      this.documentTypeId = specialOpenEnrollment.documentTypeId;
      this.memberId = specialOpenEnrollment.memberId;
      this.requestReceivedDate = specialOpenEnrollment.requestReceivedDate ? new Date(specialOpenEnrollment.requestReceivedDate) : null;
      this.eventDate = specialOpenEnrollment.eventDate ? new Date(specialOpenEnrollment.eventDate) : null;
      this.approvedById = specialOpenEnrollment.approvedById;
      this.approvedDate = specialOpenEnrollment.approvedDate ? new Date(specialOpenEnrollment.approvedDate) : null;
      this.deniedDate = specialOpenEnrollment.deniedDate ? new Date(specialOpenEnrollment.deniedDate) : null;
      this.deniedById = specialOpenEnrollment.deniedById;
      this.simplifiedStatus = specialOpenEnrollment.specialOpenEnrollmentVerificationStatus?.approvedInd ? 'Approved' :
      (specialOpenEnrollment.SpecialOpenEnrollmentVerificationStatus?.deniedInd ? 'Denied' : specialOpenEnrollment.specialOpenEnrollmentVerificationStatus?.specialOpenEnrollmentVerificationStatusName);
      this.approvedInd =
        specialOpenEnrollment.approvedDate !== null && (specialOpenEnrollment.deniedDate === null || specialOpenEnrollment.deniedDate < specialOpenEnrollment.approvedDate)
          ? true
          : specialOpenEnrollment.deniedDate !== null
          ? false
          : null;
      this.documents = map(specialOpenEnrollment.documents, (d) => new Document(d));
      this.coverageEffectiveDate = specialOpenEnrollment.coverageEffectiveDate ? new Date(specialOpenEnrollment.coverageEffectiveDate) : null;
      // this.formJson = new SOEForm(specialOpenEnrollment.member, JSON.parse(specialOpenEnrollment.formJson));
      this.expirationDate = dayjs(this.eventDate).add(60, 'day').toDate();
      if (specialOpenEnrollment.member) {
        this.member = new Member(specialOpenEnrollment.member);
      }
      if (specialOpenEnrollment.enrollmentPeriod) {
        this.enrollmentPeriod = new EnrollmentPeriod(specialOpenEnrollment.enrollmentPeriod);
      } else if (subscriber?.enrollmentPeriods &&
        some(subscriber.enrollmentPeriods, ep => ep.enrollmentPeriodId === specialOpenEnrollment.specialOpenEnrollmentId)) {
        this.enrollmentPeriod = new EnrollmentPeriod(find(subscriber.enrollmentPeriods, ep => ep.enrollmentPeriodId === specialOpenEnrollment.specialOpenEnrollmentId));
      }

      this.createdDate = specialOpenEnrollment.createdDate;
      this.createdById = specialOpenEnrollment.createdById;
      this.modifiedDate = specialOpenEnrollment.modifiedDate;
      this.modifiedById = specialOpenEnrollment.modifiedById;

      this.hasDocuments = specialOpenEnrollment.hasDocuments;
      this.hasDocumentsText = specialOpenEnrollment.hasDocumentsText;
    }
  }
}
