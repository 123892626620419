import Subscriber from './subscriber';
import * as dayjs from 'dayjs';
export default class SelfPayTerminationComposite { 
  memberId: string;
  lossOfEligibilityDate: Date;
  lossOfEligibilityReasonId: string;
  terminationDate: Date;
  eligibilityDate: Date;
  birthDate: Date;

  constructor(member? : Subscriber) {
    if (member) {
      this.birthDate = member.birthDate;
      this.memberId = member.memberId;
      this.lossOfEligibilityDate = member.memberSelfPayOrigin?.agencyEffectiveEndDate ? dayjs(new Date(member.memberSelfPayOrigin.agencyEffectiveEndDate)).endOf('month').toDate(): null;
      this.lossOfEligibilityReasonId = member.memberSelfPayOrigin?.lossOfEligibilityReasonId || null;
      this.terminationDate = member.memberSelfPayOrigin?.agencyEffectiveEndDate ? new Date(member.memberSelfPayOrigin.agencyEffectiveEndDate) : null;
      this.eligibilityDate = member.memberSelfPayOrigin?.agencyEffectiveStartDate ? new Date(member.memberSelfPayOrigin.agencyEffectiveStartDate ) : null;
    }
  }
}