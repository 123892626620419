export default class PhoneNumberType {
  phoneNumberTypeId: string;
  phoneNumberTypeCode: string;
  phoneNumberTypeName: string;
  obsoleteDate: Date;
  obsoleteFlag: boolean;

  constructor(phoneNumberType?) {
    if (phoneNumberType) {
      this.phoneNumberTypeId = phoneNumberType.phoneNumberTypeId;
      this.phoneNumberTypeCode = phoneNumberType.phoneNumberTypeCode;
      this.phoneNumberTypeName = phoneNumberType.phoneNumberTypeName;
      this.obsoleteDate = phoneNumberType.obsoleteDate ? new Date(phoneNumberType.obsoleteDate) : null;
      this.obsoleteFlag = phoneNumberType.obsoleteFlag;
    }
  }

}
