<div class="mgbt-medium">
    <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
        <h1>Legal notice</h1>
    </div>
    <div class="h4 info-container" (scroll)="onScroll($event)" style="max-width: 1200px">
      <span *ngIf="!isSebb">
        By submitting this form, I declare that the information I have provided is true, complete, and correct. If it isn't, or if I do not update this information within the timelines in PEBB Program rules, to the extent permitted by federal and state laws, I must repay any claims paid by my health plans or premiums paid on my behalf. My dependents and I may also lose PEBB benefits as of the last day of the month we were eligible. To the extent permitted by law, the PEBB Program or my employer may retroactively terminate coverage for me and my dependents if I intentionally misrepresent eligibility. In addition, I understand that it is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of PEBB insurance benefits.
        <br /><br />
        If adding a state-registered domestic partner (SRDP) to my account, I declare that my domestic partner and I have registered through the Washington Secretary of State's Office or another state. 
        Enrollment is not complete until the PEBB Program verifies the dependent’s eligibility. I understand that if I am applying to add a dependent to my PEBB insurance coverage, I must provide copies of documents that verify the dependent’s eligibility within the PEBB Program’s enrollment timelines, or the dependent will not be enrolled.
        <br /><br />
        Eligible employees must enroll in PEBB dental, basic life, basic accidental death and dismemberment (AD&D), and employer-paid long-term disability (LTD) insurance. Employees will be enrolled in employee-paid LTD insurance unless they decline coverage. 
        Employees who choose to waive PEBB medical coverage (when they become newly eligible, during annual open enrollment, or due to a special open enrollment event) must be enrolled in another employer-based group medical plan, a TRICARE plan, or Medicare. If I waive medical coverage, I understand I can enroll during the annual open enrollment period or no later than 60 days after a special open enrollment event as defined in PEBB Program rules. If I waive medical coverage for myself, I cannot enroll my eligible dependents in medical coverage. 
        I can waive my enrollment in PEBB medical to enroll in school employees benefits board (SEBB) medical only if I am enrolled in SEBB dental and SEBB vision. If I waive enrollment in PEBB medical to enroll in SEBB medical, I must also waive enrollment in PEBB dental. If I waive PEBB medical because I am enrolled in other employer-based group medical, a TRICARE plan, or Medicare, and I am not enrolled in SEBB medical, I understand that I may waive enrollment in PEBB dental only if I am enrolled in both SEBB dental and SEBB vision as an eligible dependent in the SEBB program.
        <br /><br />
        If I am eligible for the employer contribution toward PEBB benefits but do not waive or enroll in PEBB medical coverage, I will be enrolled automatically as a single subscriber in Uniform Medical Plan (UMP) Classic. My dependents will not be enrolled. I will be charged a monthly premium for medical coverage as well as a $25 monthly tobacco use premium surcharge.
        <br /><br />
        I allow my employer to deduct money from my earnings to pay for insurance coverage and any applicable premium surcharges. I understand I am responsible for paying applicable tobacco use premium surcharge and spouse or SRDP coverage premium surcharge in addition to my monthly premium.
        <br /><br />
        If I enroll in a consumer-directed health plan with a health savings account (HSA), I must meet HSA eligibility conditions. I understand that my employer will contribute to an HSA on my behalf based on the information I have provided, and that there are limits to these contributions and my HSA contributions (if any) under federal tax law.
        <br /><br />
        I understand that my enrollment and my dependents' enrollment are subject to my adherence to all applicable deadlines and PEBB rules and policies. Failure to comply with applicable deadlines and PEBB rules and policies may result in my benefits selection being rejected or defaulted.
        This form replaces all enrollment forms previously submitted. Any changes made on Benefits 24/7 or PEBB enrollment or change forms submitted and dated later than this online enrollment will replace this online enrollment.
      </span>
      <span *ngIf="isSebb">
        By submitting this form, I declare that the information I have provided is true, complete, and correct. If it isn't, or if I do not update this information within the timelines in the SEBB Program rules, to the extent permitted by federal and state laws, I must repay any claims paid by my health plans or premiums paid on my behalf.
        <br/><br/>
        My dependents and I may also lose SEBB benefits as of the last day of the month we were eligible. To the extent permitted by law, the SEBB Program or my employer may retroactively terminate coverage for me and my dependents if I intentionally misrepresent eligibility.
        <br/><br/>
        I understand that it is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of SEBB benefits.
        <br/><br/>
        If adding a state-registered domestic partner (SRDP) to my account, I declare that my domestic partner and I have registered through the Washington Secretary of State's Office or another state.
        <br/><br/>
        Enrollment of any dependent is not complete until the SEBB Program verifies the eligibility of my dependents. I understand that if I am applying to add a dependent to my SEBB insurance coverage, I must provide copies of documents that verify the dependent's eligibility within the SEBB Program's enrollment timelines, or the dependent will not be enrolled.
        <br/><br/>
        Eligible employees must enroll in SEBB dental, vision, basic life, basic accidental death and dismemberment (AD&D), and employer-paid long-term (LTD) disability insurance (if eligible). Employees will also be automatically enrolled in employee-paid LTD insurance (if eligible). Employees who choose to waive SEBB medical coverage (when they become newly eligible, during annual open enrollment, or due to a special open enrollment) must be enrolled in another employer-based group medical, a TRICARE plan, or Medicare. If I waive medical coverage, I understand I can enroll during the annual open enrollment period or no later than 60 days after a special open enrollment event as defined in SEBB Program rules. If I waive medical coverage for myself, I understand I can enroll during the annual open enrollment period or no later than 60 days after a special open enrollment event as defined in SEBB Program rules. If I waive medical coverage for myself, I cannot enroll my eligible dependents in medical coverage. If I waive SEBB medical coverage to enroll in public employees benefits board (PEBB) medical coverage, I must enroll in PEBB dental. If I waive enrollment in SEBB medical to enroll in PEBB medical, I also waive enrollment in SEBB dental and SEBB vision.
        <br/><br/>
        If I am eligible for the employer contribution toward SEBB benefits but do not waive or enroll in SEBB medical coverage, I will be enrolled automatically as a single subscriber in Uniform Medical Plan (UMP) Achieve 1. My dependents will not be enrolled. I will be charged a monthly premium for medical coverage as well as a $25 monthly tobacco use premium surcharge.
        <br/><br/>
        I allow my employer to deduct money from my earnings to pay for insurance coverage and applicable premium surcharges. I understand I am responsible for paying applicable tobacco use premium surcharge and spouse or state-registered domestic partner coverage premium surcharge in addition to my monthly medical premium.
        <br/><br/>
        If I enroll in a high-deductible health plan with a health savings account (HSA), I must meet HSA eligibility conditions. I understand that my employer will contribute to an HSA on my behalf based on the information I have provided, and that there are limits to these contributions and my HSA contributions (if any) under federal tax law.
        <br/><br/>
        I understand that my enrollment and my dependents' enrollment are subject to me abiding by all applicable deadlines and SEBB rules and policies. Failure to comply with applicable deadlines and SEBB rules and policies may result in my benefits selection being rejected or defaulted.
        <br/><br/>
        This form replaces all enrollment forms previously submitted. Any changes made in Benefits 24/7 or SEBB enrollment or change forms submitted and dated later than this online enrollment will replace this online enrollment.
      </span>
    </div>
  </div>