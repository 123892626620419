<div class="container-fluid">
  <div fxFlexFill fxLayout="column" class="flex-ie" class="card">
    <div class="card-body">
      <pebb-message [messages]="messages"></pebb-message>
      <h1 class="card-title">Benefits 24/7 Login</h1>

      <div id="intro-block" fxLayout="row">
        <span>
          Log in to Benefits 24/7 to manage benefits for yourself and your dependents, attest to premium surcharges, enroll in PEBB retiree coverage, and get your statement of insurance.
          <br><br>
          If you need help accessing Benefits 24/7, including resetting your security questions and answers:
          <ul>
            <li>Visit the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/help-benefits-24/7">Help with Benefits 24/7</a> webpage.</li>
            <li>Employees: Contact your payroll or benefits office.</li>
            <li>Retirees and continuation coverage subscribers: Contact us through HCA Support. </li>
          </ul> 
          <b>Tip: </b>Use the preferred browser, Google Chrome, for best results.
        </span>
      </div>
      <div fxLayout.lt-md="column" class="flex-ie" fxLayout="row">
        <div fxFlex="35" id="login-block" fxLayout="column" class="flex-ie"><login></login></div>
        <br/>
        <!-- commented per language review US 29068 -->
        <!-- <div fxFlex="65" id="actions-block" fxLayout="column" class="flex-ie">
          <div fxLayout="column" class="flex-ie">
            <h2 class="h3">
              Actions you can take during open enrollment (October 1 - November 15) <br />(some restrictions apply)
            </h2>
            <ul>
              <li>Enroll in PEBB benefits</li>
              <li>Attest to the spouse or state-registered domestic partner coverage and tobacco premium surcharges</li>
              <li>Waive coverage for yourself (employees only)</li>
              <li>Add dependents (you must provide proof of your dependent's eligibility before they can be enrolled).</li>
            </ul>
          </div>
          <div fxLayout="column" class="flex-ie">
            <h2 class="h3">Actions you can take using PEBB My Account year-round</h2>
            <ul>
                <li>View your coverage information (employees and dependents)
                    <ul>
                      <li>View your coverage information (Continuation Coverage - January 1, 2020)</li>
                    </ul>
                  </li>
              <li>View your basic employer-paid life and AD&amp;D insurance information (employees only)</li>
              <li>View your long-term disability insurance information (employees only)</li>
              <li>Download your statement of insurance</li>
              <li>View your premium surcharge attestations (if applicable)</li>
              <li>Make changes to your tobacco use premium surcharge attestation (if applicable)</li>
              <li>Make changes based on qualifying events specified in the PEBB Program rules</li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
