export default class Language {
  languageId: string;
  languageCode: string;
  languageName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(language?) {
    if (language) {
      this.languageId = language.languageId;
      this.languageCode = language.languageCode;
      this.languageName = language.languageName;
      this.obsoleteFlag = language.obsoleteFlag;
      this.obsoleteDate = language.obsoleteDate ? new Date(language.obsoleteDate) : null;
    }
  }

}
