<div fxLayoutAlign="center center" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center"><h2>Dependent review</h2></div>
        <h3 class="h4" style="text-align: center">Please review the information for the dependent you have added. You can make changes by selecting one of the sections above.</h3>
        <span>
        Reminder --
        <br/><br/>
        <ul>
          <li>Your child with a disability will not be enrolled in coverage until the {{subscriber?.agency}} Certification of a Child with a Disability  has been submitted as instructed on the form, received within the timelines, and approved by the {{subscriber?.agency}} Program.</li>
          <li>An extended dependent will not be enrolled until they are approved by the {{subscriber?.agency}} Program.</li> 
        </ul>
        </span>
        <div>
          <div class="hca-pdf-table-content info-container mgbt-medium-sm mgtp-medium-sm">
            <div fxLayout="row" fxLayoutAlign="space-between start" style="padding: 10px">
              <div fxFlex="30">
                <div class="h5" style="text-align: left">{{ dependentDisplay.lastName}}, {{ dependentDisplay.firstName}}</div>
                <div class="h5" style="text-align: left">DOB: {{ dependentDisplay.birthDate | date: 'MM/dd/yyyy' }}</div>
                <div class="h5" style="text-align: left">{{ dependentDisplay.relationshipTypeName }}</div>
              </div>
              <div fxFlex="30">
                <div class="h5" *ngIf="ableToHaveMedical" style="text-align: left">Medical: {{dependentDisplay.enrolledInMedical ? 'Enrolled' : 'Not Enrolled'}}</div>
                <div class="h5" *ngIf="ableToHaveDental" style="text-align: left">Dental: {{dependentDisplay.enrolledInDental ? 'Enrolled' : 'Not Enrolled'}}</div>
                <div class="h5" *ngIf="ableToHaveVision" style="text-align: left">Vision: {{dependentDisplay.enrolledInVision ? 'Enrolled' : 'Not Enrolled'}}</div>
                <div class="h5" *ngIf="isSP" style="text-align: left">Medicare A: {{dependentDisplay.medicarePartAEnrolled ? 'Yes' : 'No'}}</div>
                <div class="h5" *ngIf="isSP" style="text-align: left">Medicare B: {{dependentDisplay.medicarePartBEnrolled ? 'Yes' : 'No'}}</div>
              </div>
              <div fxFlex="30">
                <div class="h5" style="text-align: left">Tobacco use: {{ dependentDisplay.tobaccoUse ? 'Yes' : 'No' }}</div>
                <div class="h5" style="text-align: left">Spousal surcharge: {{ dependentDisplay.isSpouseInd ? (dependentDisplay.spousalSurcharge ? 'Yes': 'No') : 'Not applicable'  }}</div>
              </div>
            </div>
          </div>
        </div>
        <div fxFlexFill fxLayout="row" fxLayoutAlign="end end">
          <button [disabled]="saveDependentSubmitted" fxFlex="30" id="sub-add-submit-btn" (click)="confirmChanges()" class="btn btn-primary">Save and finish this dependent</button>
        </div>
      </div>
    </div>
  </div>
</div>
