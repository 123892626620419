import { Injector } from '@angular/core';
import { SOEModule } from './modules/subscriber/modules/soe/soe.module';
import { PendingModalComponent } from './components/pendingModal/pendingModal.component';
import { SubscriberProfileModule } from './modules/subscriber/modules/profile/profile.module';
import { ConfirmationModule } from './modules/subscriber/modules/confirmation/confirmation.module';
import { ClaimComponent } from './components/claim/claim.component';
import { AgencySelectComponent } from './components/agencySelect/agencySelect.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TopNavComponent } from './components/topnav/topnav.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PEBBAuthModule } from './modules/auth/auth.module';
import { AdminPerspayModule } from './modules/adminPerspay/adminPerspay.module';
import { AdminHCAModule } from './modules/adminHCA/adminHCA.module';
import { ReportsModule } from './modules/reports/reports.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule, NG_VALIDATORS } from '@angular/forms';
import { RoutingModule } from './router.module';
import { RouterModule } from '@angular/router';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
// ng
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, ElementRef } from '@angular/core';

// ext
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule, NOTIFICATION_CONTAINER } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { UploadModule } from '@progress/kendo-angular-upload';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

// local
import { SubscriberModule } from './modules/subscriber/subscriber.module';
import { PEBBUploadModule } from './modules/subscriber/modules/upload/upload.module';
import { DependentsModule } from './modules/subscriber/modules/dependents/dependents.module';
import { SelfPayModule } from './modules/subscriber/modules/selfpay/selfpay.module';
import { SupplementalModule } from './modules/subscriber/modules/supplemental/supplemental.module';
import { AttestationsModule } from './modules/subscriber/modules/attestations/attestations.module';
import { CoverageModule } from './modules/subscriber/modules/coverage/coverage.module';
import { DependentManagementModule } from './modules/subscriber/modules/dependentManagement/dependentManagement.module';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { RouteDeactivateComponent } from 'src/app/components/routeDeactivate/routeDeactivate.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { TermsOfUseComponent } from 'src/app/components/termsOfUse/termsOfUse.component';
import { HealthEquityComponent } from 'src/app/components/healthEquity/healthEquity.component';
import { CoreService } from 'src/app/services/core.service';
import { ResponseInterceptor } from 'src/app/interceptors/response/response.interceptor';
import { AuthInterceptor } from 'src/app/interceptors/auth/auth.interceptor';
import { NoCacheInterceptor } from 'src/app/interceptors/noCache/noCache.interceptor';
import { PageNotFoundComponent } from './components/pageNotFound/pageNotFound.component';
import { GlobalErrorHandler } from './directives/errorHandler/global.error.handler';
import { EndOfMonthDirective } from './directives/endOfMonth/endOfMonth.directive';
import { NativeControlElementDirective } from './directives/nativeControlElement/nativeControlElement.directive';
import { AuthConfigModule } from './auth-config.module';

import { env } from 'src/env/development';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotificationComponent } from './components/notification/notification.component';

// import { setInjector } from 'src/app/decorators/lookups.decorator';

// PEBB - AWS INTERNET TRAINING
function configureTheme(): () => Promise<any> {
  switch (env.environment) {
    case 'dev':
      return () =>
        new Promise((resolve) => {
          document.documentElement.className = 'theme-default';
          resolve(null);
        }) as any;
    case 'PEBB-AWS_INTERNET_DEV':
      return () =>
        new Promise((resolve) => {
          document.documentElement.className = 'theme-default';
          resolve(null);
        }) as any;
    case 'PEBB-AWS_INTERNET_TEST':
      return () =>
        new Promise((resolve) => {
          document.documentElement.className = 'theme-purple';
          resolve(null);
        }) as any;
    case 'PEBB-AWS_INTERNET_TRAINING':
      return () =>
        new Promise((resolve) => {
          document.documentElement.className = 'theme-yellow';
          resolve(null);
        }) as any;
    case 'PEBB-AWS_INTERNET_PROD':
      return () =>
        new Promise((resolve) => {
          document.documentElement.className = 'theme-default';
          resolve(null);
        }) as any;
        case 'PEBB-AWS_INTERNET_UAT':
          return () =>
            new Promise((resolve) => {
              document.documentElement.className = 'theme-default';
              resolve(null);
            }) as any;
    default:
      return () =>
        new Promise((resolve) => {
          document.documentElement.className = 'theme-purple';
          resolve(null);
        }) as any;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    TermsOfUseComponent,
    HealthEquityComponent,
    PageNotFoundComponent,
    TopNavComponent,
    AgencySelectComponent,
    RouteDeactivateComponent,
    ClaimComponent,
    PendingModalComponent,
    NotificationComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    RouterModule,
    RoutingModule,
    NgbModule,
    NgbModalModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    ButtonsModule,
    GridModule,
    DropDownsModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    PDFExportModule,
    ProgressBarModule,
    HttpClientModule,
    ReportsModule,
    AdminHCAModule,
    AdminPerspayModule,
    PEBBAuthModule,
    DashboardModule,
    OverlayModule,
    FontAwesomeModule,
    SubscriberModule,
    AttestationsModule,
    CoverageModule,
    DependentManagementModule,
    SupplementalModule,
    DependentsModule,
    UploadModule,
    DialogsModule,
    PEBBUploadModule,
    ConfirmationModule,
    SubscriberProfileModule,
    SOEModule,
    SelfPayModule,
    AuthConfigModule
  ],
  providers: [
    CoreService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureTheme,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: NoCacheInterceptor, multi: true },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    // { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: NOTIFICATION_CONTAINER,
      useFactory: () => {
        // return the container ElementRef, where the notification will be injected
        return { nativeElement: document.body } as ElementRef;
      },
    },
    {
      provide: NG_VALIDATORS, useExisting: EndOfMonthDirective, multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(i: Injector) {
  //   setInjector(i);
  // }
}
