export default class CertificationType {
  certificationTypeId: string;
  certificationTypeCode: string;
  certificationTypeName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(certificationType?) {
    if (certificationType) {
      this.certificationTypeId = certificationType.certificationTypeId;
      this.certificationTypeCode = certificationType.certificationTypeCode;
      this.certificationTypeName = certificationType.certificationTypeName;
      this.obsoleteFlag = certificationType.obsoleteFlag;
      this.obsoleteDate = certificationType.obsoleteDate ? new Date(certificationType.obsoleteDate) : null;
    }
  }

}
