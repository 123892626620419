import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router, RouterOutlet } from '@angular/router';
import { SubscriberService } from '../services/subscriber.service';
import Subscriber from '../models/subscriber';
import { find, cloneDeep} from 'lodash';
import { CoreService } from '../services/core.service';

@Injectable({
  providedIn: 'root'
})

export class SubscriberResolver {
  constructor(
    private subscriberService: SubscriberService,
    private router: Router,
    private coreService: CoreService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Subscriber | Observable<never>> {
    let id = route.paramMap.get('id');
    const soeid = route.paramMap.get('soeid');
    if(!id) {
      id = route.parent.paramMap.get('id');
    }
    return this.coreService.getSubscriber().pipe(
      take(1),
      mergeMap(s => {
        if ((s.memberId && s.memberId === id && !s.refetch)) {
          if (soeid) {
            s.activeSOE = find(s.specialOpenEnrollments, ['specialOpenEnrollmentId', soeid]);
          } else if (route.url) {
            s.activeSOE = null;
          }
          const subscriberClone = cloneDeep(s);
          return of(subscriberClone);
        } else {
          return this.subscriberService.getSubscriberById(id).pipe(
            take(1),
            map(sub => {
              if (sub) {
                if (soeid) {
                  sub.activeSOE = find(sub.specialOpenEnrollments, ['specialOpenEnrollmentId', soeid]);
                }
                this.coreService.setSubscriber(sub);
                return sub;
              } else {
                // this.router.navigate(['/']);
                return EMPTY;
              }
            })
          );
        }
      })
    );
  }
}
