import { Routes } from '@angular/router';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of, EMPTY, forkJoin } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { find } from 'lodash';
import { LookupService } from 'src/app/services/lookup.service';
import { LookupType } from 'src/app/decorators/lookups.decorator';

import ActionType from 'src/app/models/actionType';
import AddressType from 'src/app/models/addressType';
import Agency from '../models/agency';
import ApplicationSetting from 'src/app/models/applicationSettting';
import AttestationType from 'src/app/models/attestationType';
import BenefitSetup from 'src/app/models/benefitSetup';
import BirthSex from 'src/app/models/birthSex';
import CertificationType from 'src/app/models/certificationType';
import CobraQualifyReason from 'src/app/models/cobraQualifyReason';
import ContactType from 'src/app/models/contactType';
import Country from 'src/app/models/country';
import County from 'src/app/models/county';
import DocumentType from 'src/app/models/documentType';
import Ethnicity from 'src/app/models/ethnicity';
import GenderIdentity from 'src/app/models/genderIdentity';
import Language from 'src/app/models/language';
import LowerLimit from 'src/app/models/lowerLimitCurrentProcessMonth';
import MedicareOption from 'src/app/models/medicareOption';
import MemberType from 'src/app/models/memberType';
import Module from 'src/app/models/module';
import Organization from 'src/app/models/organization';
import OrganizationType from 'src/app/models/organizationType';
import PhoneNumberType from 'src/app/models/phoneNumberType';
import PlanType from 'src/app/models/planType';
import PreferredContactMethod from 'src/app/models/preferredContactMethod';
import Question from 'src/app/models/question';
import RateStructure from 'src/app/models/rateStructure';
import Reason from 'src/app/models/reason';
import RelationshipCertificationStatus from 'src/app/models/relationshipCertificationStatus';
import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import RelationshipType from 'src/app/models/relationshipType';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import ReportType from 'src/app/models/reportType';
import Response from 'src/app/models/response';
import UserAccessLevel from 'src/app/models/userAccessLevel';
import SelfPayPaymentType from 'src/app/models/selfPayPaymentType';
import SelfPayProcessStatus from 'src/app/models/selfPayProcessStatus';
import SelfPayReason from 'src/app/models/selfPayReason';
import SelfPayType from 'src/app/models/selfPayType';
import SelfPayVerificationStatus from 'src/app/models/selfPayVerificationStatus';
import SpecialOpenEnrollmentType from 'src/app/models/specialOpenEnrollmentType';
import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import TermsOfUseActionType from 'src/app/models/termsOfUseActionType';
import UserRole from 'src/app/models/userRole';
import UserType from 'src/app/models/userType';
import SubmissionFileResponseType from '../models/submissionFileResponseType';
import Carrier from '../models/carrier';

const map = {
  [LookupType.ActionType]: ActionType,
  [LookupType.AddressType]: AddressType,
  [LookupType.Agency]: Agency,
  [LookupType.ApplicationSetting]: ApplicationSetting,
  [LookupType.AttestationType]: AttestationType,
  [LookupType.BenefitSetup]: BenefitSetup,
  [LookupType.BirthSex]: BirthSex,
  [LookupType.Carrier]: Carrier,
  [LookupType.Carrier834Carrier]: Carrier,
  [LookupType.CertificationType]: CertificationType,
  [LookupType.CobraQualifyReason]: CobraQualifyReason,
  [LookupType.ContactType]: ContactType,
  [LookupType.Country]: Country,
  [LookupType.County]: County,
  [LookupType.DocumentType]: DocumentType,
  [LookupType.Ethnicity]: Ethnicity,
  [LookupType.GenderIdentity]: GenderIdentity,
  [LookupType.Language]: Language,
  [LookupType.LowerLimit]: LowerLimit,
  [LookupType.MedicareOption]: MedicareOption,
  [LookupType.MemberType]: MemberType,
  [LookupType.Module]: Module,
  [LookupType.Organization]: Organization,
  [LookupType.OrganizationType]: OrganizationType,
  [LookupType.PhoneNumberType]: PhoneNumberType,
  [LookupType.PlanType]: PlanType,
  [LookupType.PreferredContactMethod]: PreferredContactMethod,
  [LookupType.Question]: Question,
  [LookupType.RateStructure]: RateStructure,
  [LookupType.Reason]: Reason,
  [LookupType.RelationshipCertificationStatus]: RelationshipCertificationStatus,
  [LookupType.RelationshipQualifyReason]: RelationshipQualifyReason,
  [LookupType.RelationshipType]: RelationshipType,
  [LookupType.RelationshipVerificationStatus]: RelationshipVerificationStatus,
  [LookupType.ReportType]: ReportType,
  [LookupType.Response]: Response,
  [LookupType.SelfPayProcessStatus]: SelfPayProcessStatus,
  [LookupType.SelfPayReason]: SelfPayReason,
  [LookupType.SelfPayType]: SelfPayType,
  [LookupType.SelfPayPaymentType]: SelfPayPaymentType,
  [LookupType.SelfPayVerificationStatus]: SelfPayVerificationStatus,
  [LookupType.SpecialOpenEnrollmentType]: SpecialOpenEnrollmentType,
  [LookupType.SpecialOpenEnrollmentVerificationStatus]: SpecialOpenEnrollmentVerificationStatus,
  [LookupType.SubmissionFileResponseType]: SubmissionFileResponseType,
  [LookupType.TermsOfUseActionType]: TermsOfUseActionType,
  [LookupType.UserType]: UserType,
  [LookupType.UserRole]: UserRole,
  [LookupType.UserAccessLevel]: UserAccessLevel
};

@Injectable({
  providedIn: 'root'
})
export class LookupsResolver implements Resolve<any> {
  constructor(private lookupService: LookupService, private router: Router) {}

  static addToAllRoutes(myRoutes: any): Routes {
    if (Array.isArray(myRoutes)) {
      myRoutes.forEach(r => {
        if (r.component && r.component.prototype.requestedLookups) {
          if (!r.resolve) {
            r.resolve = {};
          }

          if (!r.resolve.lookups) {
            r.resolve.lookups = LookupsResolver;
          }
        }

        if (Array.isArray(r.children)) {
          LookupsResolver.addToAllRoutes(r.children);
        }
      });
    }

    return myRoutes;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const requestedLookups = route.component[`prototype`][`requestedLookups`] ?? route.data.lookups;
    const resolvedLookups = route.component[`prototype`][`resolvedLookups`];

    // console.log('LookupsResolver', route.component[`name`], requestedLookups);

    if (requestedLookups) {
      return forkJoin(
        requestedLookups.map(lookup => {
          const lutName = lookup?.lutName ?? lookup;

          if (resolvedLookups && resolvedLookups[lutName]) {
            return new Observable(observer => {
              observer.next(resolvedLookups[lutName]);
              observer.complete();
            });
          }

          if (!map[lutName]) {
            console.error('LookupsResolver: Missing mapping for ' + lutName);
          }

          return this.lookupService.getLutValues(lutName, map[lutName]);
        })
      ).pipe(
        take(1),
        mergeMap(lookups => {
          if (lookups) {
            const mappedLookups = this.mapLookupsToTypes(lookups, requestedLookups);

            if (resolvedLookups) {
              for (const key in mappedLookups) {
                if (mappedLookups[key]) {
                  resolvedLookups[key] = mappedLookups[key];
                }
              }
            }

            return of(mappedLookups);
          } else {
            this.router.navigate(['/']);
            return EMPTY;
          }
        })
      );
    } else {
      if (this.router.url !== '/') {
        this.router.navigate(['/']);
      }

      return EMPTY;
    }
  }

  mapLookupsToTypes(resolvedLookups, lookupsArray): {} {
    // loop over eventual resolved lookups, map to object props.
    const out = {};
    lookupsArray.forEach(lut => {
      const name = lut?.lutName ?? lut;

      const output = find(resolvedLookups, resolvedLut => resolvedLut[0] instanceof map[name]);

      out[name] = output;

      if (typeof lut === 'string') {
        // Comes from LookupsDecorator, which doesn't use pluralized lookup
        // names.  Adds a reference from the singular to the plural for
        // all lookups that funnel through here (allowing the component
        // to use either when it pulls from data.lookups; e.g.,
        // data.lookups.applicationSetting or data.lookups.applicationSettings).
        if (lut.endsWith('y')) {
          out[`${name.substr(0, name.length - 1)}ies`] = out[name];
        } else {
          out[`${name}s`] = out[name];
        }
      }
    });
    return out;
  }
}
