// handles enrollment periods AND milestone completion

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Enrollment from '../models/enrollment';
import SubscriberCoverage from '../models/subscriberCoverage';
import Plan from '../models/plan';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  getEnrollmentsbyEnrollmentPeriodId(memberId: string, enrollmentPeriodId: string): Observable<SubscriberCoverage> {
    return this.http.get<SubscriberCoverage>(`${env.apiUrl}/member/${memberId}/enrollment/${enrollmentPeriodId}`)
    .pipe(map(x => this.commonService.createObject(SubscriberCoverage, x)))
    .pipe(catchError(this.commonService.handleError));
  }


  getEnrollmentsbyEnrollmentPeriodIdForSOE(memberId: string, enrollmentPeriodId: string, specialOpenEnrollmentId: string): Observable<SubscriberCoverage> {
    return this.http.get<SubscriberCoverage>(`${env.apiUrl}/member/${memberId}/enrollment/${enrollmentPeriodId}/${specialOpenEnrollmentId}`)
    .pipe(map(x => this.commonService.createObject(SubscriberCoverage, x)))
    .pipe(catchError(this.commonService.handleError));
  }


  getAvailablePlansForMember(memberId: string, enrollmentPeriodId: string, showAll: boolean = false): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${env.apiUrl}/subscriber/${memberId}/availablePlans/${enrollmentPeriodId}?showAll=${showAll}`)
    .pipe(map(x => this.commonService.createObjects(Plan, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getAvailablePlansForMemberWithoutEP(memberId: string): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${env.apiUrl}/subscriber/${memberId}/availablePlans`)
    .pipe(map(x => this.commonService.createObjects(Plan, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getSelfPayAvailablePlans(): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${env.apiUrl}/selfPay/plans`)
    .pipe(map(x => this.commonService.createObjects(Plan, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getMemberSelfPayAvailablePlans(excludeRetiree: boolean, memberId: string, coverageEffectiveDate: Date, showAll: boolean = false, agencyCode?: string): Observable<Plan[]> {
    const dateAppend = coverageEffectiveDate ? `${dayjs(coverageEffectiveDate).format('MM/DD/YYYY')}` : '';
    return this.http.get<Plan[]>(`${env.apiUrl}/selfPay/plans/${memberId}/${excludeRetiree}/${agencyCode}?coverageEffectiveDate=${dateAppend}&showAll=${showAll}`)
    .pipe(map(x => this.commonService.createObjects(Plan, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getAllPlans(): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${env.apiUrl}/plans`)
    .pipe(map(x => this.commonService.createObjects(Plan, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createSubscriberEnrollments(memberId: string, enrollmentPeriodId: string, subscriberCoverage: SubscriberCoverage): Observable<SubscriberCoverage> {
    return this.http.post<SubscriberCoverage>(`${env.apiUrl}/member/${memberId}/enrollment/${enrollmentPeriodId}`, subscriberCoverage)
    .pipe(map(x => this.commonService.createObject(SubscriberCoverage, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createSubscriberEnrollmentsForSOE(memberId: string, enrollmentPeriodId: string, subscriberCoverage: SubscriberCoverage, specialOpenEnrollmentId : string): Observable<SubscriberCoverage> {
    return this.http.post<SubscriberCoverage>(`${env.apiUrl}/member/${memberId}/enrollment/${enrollmentPeriodId}/soe/${specialOpenEnrollmentId}`, subscriberCoverage)
    .pipe(map(x => this.commonService.createObject(SubscriberCoverage, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createSupplementalEnrollmentsOutsideEP(memberId: string, subscriberCoverage: SubscriberCoverage): Observable<SubscriberCoverage> {
    return this.http.post<SubscriberCoverage>(`${env.apiUrl}/member/${memberId}/enrollment/supplemental`, subscriberCoverage)
    .pipe(map(x => this.commonService.createObject(SubscriberCoverage, x)))
    .pipe(catchError(this.commonService.handleError));
  }
}
