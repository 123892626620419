export default class SubscriberContact {
    memberId: string;
    emailAddress: string;
    emailNotificationInd: boolean;
    homePhoneNumber: string;
    workPhoneNumber: string;
    cellPhoneNumber: string;

    constructor(subscriberContact?) {
        if (subscriberContact) {
            this.memberId = subscriberContact.memberId;
            this.emailAddress = subscriberContact.emailAddress;
            this.emailNotificationInd = subscriberContact.emailNotificationInd;
            this.homePhoneNumber = subscriberContact.homePhoneNumber;
            this.workPhoneNumber = subscriberContact.workPhoneNumber;
            this.cellPhoneNumber = subscriberContact.cellPhoneNumber;
        }
    }
}
