import { faCalendar, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { cloneDeep, find, remove, filter, some } from 'lodash';
import { CoreService } from 'src/app/services/core.service';
import SelfPay from 'src/app/models/selfPay';
import Subscriber from 'src/app/models/subscriber';
import SelfPayFormSubscriber from 'src/app/models/selfPayFormSubscriber';
import { env } from 'src/env/development';
import Plan from 'src/app/models/plan';
import Enrollment from 'src/app/models/enrollment';

@Component({
  selector: 'self-pay-subscriber',
  templateUrl: 'selfPay.subscriber.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPaySubscriberComponent implements OnChanges, OnInit {
  icons = {
    faCalendar,
    faExclamationCircle
  };
  @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @Input() selfPay: SelfPay;
  @Input() subscriber: Subscriber;
  @Input() isPerspay = false;
  @Input() agency: string;
  @Input() pebbVisionDate: Date;
  @Input() lookups = {
    selfPayType: [],
    selfPayVerificationStatus: [],
    selfPayPaymentTypes: [],
    documentType: [],
    selfPayPaymentType: [],
    genderIdentities: [],
    birthSexes: [],
    countries: [],
    counties: [],
  };
  @Input() ltdAvailablePlans: Plan[];
  @Input() isRetireeOE = false;
  @Input() isCobraOE = false;
  @ViewChild('selfPaySubscriberForm') public subForm: NgForm;
  requestType: string;
  currentSubscriber: SelfPayFormSubscriber;
  selectedAction;
  mailingAddressSame = false;
  oeSelfPayTypes: string[] = ['OEC', 'OER', 'OEU'];
  isEnrolled = false;
  minVisionDate: boolean;
  supplementalPlans: Plan[];
  selectedPlan: Plan;
  selectedPlanId: string;
  sltdSixtyPlan: Plan;
  sltdFiftyPlan: Plan;
  residentialCounties: string[] = [];
  mailingCounties: string[] = [];
  residentialStates: string[] = [];
  mailingStates: string[] = [];
  currentMaxDate: Date;
  env = env;  
  isCobra: boolean = false;
  constructor(
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.currentMaxDate = new Date();
    if (this.subscriber) {
      this.supplementalPlans = filter(this.ltdAvailablePlans, (ap: Plan) => ap.planType.planTypeCode === '5');
      this.selectedPlan = this.supplementalPlans.find((p) => p.planCode === 'EE60');
      if (!this.selfPay.formJson.ltdPlanName) { this.selfPay.formJson.ltdPlanName = this.selectedPlan?.planName; }
      this.sltdSixtyPlan = find(this.supplementalPlans, (p) => p.planCode === 'EE60');
      this.sltdFiftyPlan = find(this.supplementalPlans, (p) => p.planCode === 'EE50');

      this.currentSubscriber = cloneDeep(this.selfPay.formJson.subscriber);
      if(this.currentSubscriber.tobaccoUseFormInd === null) {
        this.currentSubscriber.tobaccoUseFormInd = this.subscriber?.mostRecentTobaccoAttestation?.response?.responseCode === 'Y' ? 'Yes' : 'No';
      }
      // if(this.isRetireeOE){
      //   this.checkDefaults(this.subscriber);
      // }
    }
    this.minVisionDate = this.selfPay?.coverageEffectiveDate ? dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(this.pebbVisionDate) : dayjs(new Date()).isSameOrAfter(this.pebbVisionDate);
  }

  ngOnChanges(): void {
    if (this.selfPay) {
      this.currentSubscriber = cloneDeep(this.selfPay.formJson.subscriber);
      this.requestType = this.selfPay.selfPayType.selfPayTypeCode;
      if (!this.selfPay.formJson?.subscriber?.memberId) {
        this.selfPay.formJson.subscriber = new SelfPayFormSubscriber(this.subscriber);
      }
      this.isCobra = env.cobraSelfPayTypeCodes.includes(this.requestType);
    }
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.subForm);
  }

  pushContinue(formContainer): void {
    this.markAllControlsAsTouched();

    if (this.currentSubscriber.medicalTerminateCoverageDate &&
      !dayjs(this.currentSubscriber.medicalTerminateCoverageDate).isSame(dayjs(this.currentSubscriber.medicalTerminateCoverageDate).endOf('month'), 'day')) {
      // this.coreService.popMessage('Effective date must be the first of a month.', 'error', 3000);
      if (this.subForm) {
        this.subForm.controls.mtcd.setErrors({endOfMonth: true});
      }
    }

    if (this.currentSubscriber.dentalTerminateCoverageDate &&
      !dayjs(this.currentSubscriber.dentalTerminateCoverageDate).isSame(dayjs(this.currentSubscriber.dentalTerminateCoverageDate).endOf('month'), 'day')) {
      // this.coreService.popMessage('Effective date must be the first of a month.', 'error', 3000);
      if (this.subForm) {
        this.subForm.controls.dtcd.setErrors({endOfMonth: true});
      }
    }

    if (!this.currentSubscriber.medicarePartAInd && this.currentSubscriber.medicarePartBInd) {
      //can't have medicare part B without part A
      this.coreService.popMessage('Medicare Part A must be "Yes" if Medicare Part B is "Yes".', 'error', 4000, this.coreService.getInvalidFields(formContainer));
      return;
    }

    if (this.subForm.valid) {
      this.selfPay.formJson.subscriber = this.currentSubscriber;
      this.saveSelfPay.emit(this.selfPay);
    } else {
      this.coreService.popMessage('Please complete all required fields.', 'error', 4000, this.coreService.getInvalidFields(formContainer));
    }
  }

  cancelChanges(): void {
    this.subForm.resetForm();
    this.currentSubscriber = cloneDeep(this.selfPay.formJson.subscriber);
  }

  updateMedicarePartA(yes: boolean) {
    if(!yes) {
      this.currentSubscriber.medicarePartADate = null;
    }
    this.updateTobaccoBasedOnMedicare();
  }

  updateMedicarePartB(yes: boolean) {
    if(!yes) {
      this.currentSubscriber.medicarePartBDate = null;
    }
    if(yes) {
      this.currentSubscriber.visionEnrollInd = false;
    }
    this.updateTobaccoBasedOnMedicare();
  }

  updateTobaccoBasedOnMedicare(): void {
    if (this.currentSubscriber.medicarePartAInd && this.currentSubscriber.medicarePartBInd){
      this.currentSubscriber.tobaccoUseFormInd = 'NA';
    }
  }

  updateCoverageDefer(e) {
    this.currentSubscriber.medicalEnrollInd =
    this.currentSubscriber.enrollAfterDeferalInd = 
    this.currentSubscriber.visionEnrollInd =
    this.currentSubscriber.dentalEnrollInd = false;
    this.currentSubscriber.enrollAfterDeferalDate = null;
    if(!e) this.currentSubscriber.deferCoverageDate = null;
  }

  updateCoverageContinue(e, key: string) {
    let bool = (e.target as HTMLInputElement)?.value === 'true';
    if(bool) {
      this.currentSubscriber[key] = false;
    }else {
      this.currentSubscriber[key] = true;
    }
  }

  defaultPremiumSurcharge() {
    if(this.currentSubscriber?.memberMedicare?.medicarePartAEnrolledInd && this.currentSubscriber?.memberMedicare?.medicarePartBEnrolledInd) {
      this.currentSubscriber.tobaccoUseFormInd = 'NA';
    }else {
      this.currentSubscriber.tobaccoUseFormInd = '';
    }
  }

  deferringChange() {
    if (this.currentSubscriber.actionsCode === 'deferring') {
      this.currentSubscriber.deferCoverageInd = true;
      this.currentSubscriber.medicalEnrollInd = false;
      this.currentSubscriber.dentalEnrollInd = false;
    }
  }

  terminateAllCoveragesChange(checked) {
    //this.currentSubscriber.terminateAllCoverageInd = checked;
    this.currentSubscriber.medicalEnrollInd = 
    this.currentSubscriber.dentalEnrollInd =
    this.currentSubscriber.visionEnrollInd = 
    this.currentSubscriber.deferQuestionOne =
    this.currentSubscriber.deferQuestionTwo =
    this.currentSubscriber.deferQuestionThree =
    this.currentSubscriber.deferQuestionFour =
    this.currentSubscriber.deferQuestionFive =
    this.currentSubscriber.deferQuestionSix = 
    this.currentSubscriber.deferCoverageInd =
    false;
    this.currentSubscriber.deferCoverageDate = null;
    this.currentSubscriber.enrollAfterDeferalInd = false;
    this.currentSubscriber.enrollAfterDeferalDate = null;
  }

  handleMedicalEnrollChange(checked) {
    if(!this.isRetireeOE) return this.currentSubscriber.terminateAllCoverageInd = false;
    if(checked) {
      this.currentSubscriber.enrollAfterDeferalInd = this.currentSubscriber.deferCoverageInd = this.currentSubscriber.terminateAllCoverageInd = false;
      this.currentSubscriber.deferCoverageDate = null;

    }else if (!checked) {
      this.currentSubscriber.dentalEnrollInd = this.currentSubscriber.visionEnrollInd = this.currentSubscriber.enrollAfterDeferalInd = false;
      this.currentSubscriber.enrollAfterDeferalDate = null;
      this.currentSubscriber.enrollAfterDeferalInd = null;
    }
  }

  handleDentalEnrollChange(checked) {
    if(this.isCobraOE) return this.currentSubscriber.terminateAllCoverageInd = false;
  }

  handleVisionEnrollChange(checked) {
    if(this.isCobraOE) return this.currentSubscriber.terminateAllCoverageInd = false;
  }




  // checkDefaults(subscriber: Subscriber) {
  //   console.log('checkingDefaults', this.currentSubscriber, this.subscriber);
  //   if(this.currentSubscriber.dentalEnrollInd == null && some(subscriber.enrollments, (e: Enrollment) => e?.plan?.planType?.planTypeCode === '2' && e.isActive() )) {
  //     this.currentSubscriber.dentalEnrollInd = true;
  //   }
  //   if(this.currentSubscriber.medicalEnrollInd == null && some(subscriber.enrollments, (e: Enrollment) => e?.plan?.planType?.planTypeCode === '1' && e.isActive() )) {
  //     this.currentSubscriber.medicalEnrollInd = true;
  //   }
  // }

  updateEnrollAfterDefer(e) {
    if(e) this.currentSubscriber.deferCoverageInd = false;
    if(!e) this.currentSubscriber.enrollAfterDeferalDate = null;
  }
}
