import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
/**
 * The root component. All other states are injected based on routing in this components
 * router-outlet. Global componenets (agency select, header, footer) declared here.
 */

// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ext
import Subscriber from 'src/app/models/subscriber';
import { forEach, find, toLower, cloneDeep, startsWith, some } from 'lodash';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

// local
import Plan from 'src/app/models/plan';

import {
  faPlusCircle, faMinusCircle, faUserMd, faArrowAltCircleRight
} from '@fortawesome/free-solid-svg-icons';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import SelfPay from 'src/app/models/selfPay';
import MemberType from 'src/app/models/memberType';
import MemberSummary from 'src/app/models/memberSummary';
import { env } from 'src/env/development';
import ExternalEnrollment from 'src/app/models/externalEnrollment';
import Member from 'src/app/models/member';
import { AdminService } from 'src/app/services/admin.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'plan-type-management',
  templateUrl: 'planTypeManagement.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class PlanTypeManagementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() planType: string;
  @Input() availablePlans: Plan[];
  @Input() selectedPlan: Plan;
  @Input() isWaivable = false;
  @Input() enrollmentPeriod: EnrollmentPeriod;
  @Input() hasDependentsEnrolled = false;
  @Input() readOnly = false;
  @Input() memberType?: MemberType;
  @Input() subscriber?: Subscriber;
  @Input() isSelfPay = false;
  @Input() isSOE = false;
  @Input() soeTypeName: string = "";
  @Input() invalidSPPlan = '';
  @Input() subscriberSummary: SubscriberEffectiveSummary;
  @Input() selfPayWaivingDentalCoverage: boolean;
  @Input() selfPayWaivingMedicalCoverage: boolean;
  @Input() selfPayWaivingVisionCoverage: boolean;
  @Input() selfPayFormDependentsEnrolled = false;
  @Input() isDeferRetiree: boolean;
  @Input() removeMedicalForDE: boolean = false;
  @Input() removeDentalForDE: boolean = false;
  @Input() removeVisionForDE: boolean = false;
  @Input() isSEBB = false;
  @Output() selfPayWaivingDentalCoverageChange = new EventEmitter<boolean>();
  @Output() selfPayWaivingVisionCoverageChange = new EventEmitter<boolean>();
  @Output() planSelected: EventEmitter<Plan> = new EventEmitter<Plan>();
  @Output() planDeSelected: EventEmitter<Plan> = new EventEmitter<Plan>();
  @Output() cobraOrUnpaidWaiving: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() enrollRTL: EventEmitter<boolean> = new EventEmitter();
  @Output() enrollBL: EventEmitter<boolean> = new EventEmitter();
  @Output() getPlans: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deferRetireeChange: EventEmitter<boolean> = new EventEmitter();
  @Output() removeMedicalForDEChange = new EventEmitter<boolean>();
  @Output() removeDentalForDEChange = new EventEmitter<boolean>();
  @Output() removeVisionForDEChange = new EventEmitter<boolean>();
  isRetiree = false;
  isCOBRA = false;
  retireeMemberTypeCodes = env.retireeMemberTypes;
  cobraUnpaidTypeCodes = env.cobraMemberTypes.concat(env.unpaidLeaveMemberTypes);
  cobraRetireeTypeCodes = env.cobraMemberTypes.concat(env.retireeMemberTypes);
  unpaidMemberTypes = env.unpaidLeaveMemberTypes;
  dualEnrollSelected = false;
  cobraOrUnpaidWaivingPlanType: string = null;
  shouldShowPremiums = true;
  subscriberOrDependentsHaveMedicareB = false;

  public startsWith = startsWith;
  icons = {
    faPlusCircle, faMinusCircle, faUserMd, faArrowAltCircleRight
  };
  originalPlan: Plan;
  selectedPlanId: string[] = [];
  familyComp = 'SO';
  DEMessageAccepted = false;
  isEnrolledRetireeTermLife = false;
  medicareDEnabled = false;

  constructor(private adminService: AdminService) { }

  async ngOnInit(): Promise<void> {
    this.medicareDEnabled = await lastValueFrom(this.adminService.getFeatureFlag('FEATURE_ENABLE_MEDICARE_D'));
    
    this.selectedPlanId = this.selectedPlan ? [this.selectedPlan.planId] : [];

    const memberTypeCode = this.subscriberSummary?.memberType?.memberTypeCode;
    this.shouldShowPremiums = !some(env.memberTypesToHidePremiumsFor, (memberType: string) => memberType === memberTypeCode);
    this.isRetiree = some(this.retireeMemberTypeCodes, (mt: string) => mt === memberTypeCode);
    this.isCOBRA = some(env.cobraMemberTypes, (mt: string) => mt === memberTypeCode);

    this.subscriberOrDependentsHaveMedicareB = !!this.subscriber?.memberMedicare?.medicarePartBEnrolledInd ||
      some(this.subscriber?.members, (dep: Member) => {
        if (dep && !dep.isSubscriberInd) {
          return dep.memberMedicare?.medicarePartBEnrolledInd;
        }

        return false;
      });
  }

  ngOnChanges(): void {
    this.selectedPlanId = this.selectedPlan ? [this.selectedPlan.planId] : [];
  }

  ngOnDestroy(): void {

  }

  resolvePlanName(selectedPlan: Plan, medicareDEnabled: boolean, isRetiree: boolean, isCOBRA: boolean): string {
    if (!selectedPlan)
      return null;

    // Customize Medical for Medicare D checks
    if (selectedPlan.planType.planTypeName === env.medicalPlanTypeName) {
      const isRetireeOrCOBRA = isRetiree || isCOBRA;

      if (medicareDEnabled && isRetireeOrCOBRA && selectedPlan.medicareDPlanName)
        return `${selectedPlan.planName} or ${selectedPlan.medicareDPlanName}`;
    }

    return selectedPlan.planName;
  }

  planUpdated(planObject: Plan): void {
    this.selectedPlan = cloneDeep(planObject);
    this.selectedPlanId = this.selectedPlan ? [this.selectedPlan.planId] : [];
    this.planSelected.emit(this.selectedPlan);
    this.setRemoveDE();
  }

  planRemoved(planObject: Plan): void {
    this.planDeSelected.emit(this.selectedPlan);
    if (this.isSelfPay && planObject.planType.planTypeName === env.dentalPlanTypeName) {
      this.selectedPlan = null;
    }
  }

  getPMTPlans(showAll): void {
    this.getPlans.emit(showAll);
  }

  setWaivedPlan(waivedObject: {planType: string, waived: boolean}): void {
    if (waivedObject.waived && this.selectedPlan) {
        this.originalPlan = cloneDeep(this.selectedPlan);
    }
    if (waivedObject.waived) {
      this.setRemoveDE();
    }
    this.selectedPlan = waivedObject.waived ? null : this.originalPlan;
    this.selectedPlanId = waivedObject.waived ? [] : [this.selectedPlan?.planId];
    this.planSelected.emit(this.selectedPlan);
  }

  handleCobraOrUnpaidWaiving(planType: string, waived: boolean, selfPayWaivingMedicalCoverage: boolean): void {
    if (waived) {
      if (this.selectedPlan) {
        this.originalPlan = cloneDeep(this.selectedPlan);
      }
      this.cobraOrUnpaidWaivingPlanType = planType;
      if (!this.hasDependentsEnrolled) {
        this.clearSelectedPlan();
      }
      this.planSelected.emit(this.selectedPlan);
    } else {
      this.cobraOrUnpaidWaivingPlanType = null;
      this.planSelected.emit(this.selectedPlan);
    }
    if (planType === env.medicalPlanTypeName) {
      this.cobraOrUnpaidWaiving.emit(selfPayWaivingMedicalCoverage);
    }
  }

  handleMedicaidDefer(): void {
    // sub is deferring, kiddos are not, need to modify something on composite for a new endpoint.
  }

  disenrollSubscriberDental(e): void {
    this.handleCobraOrUnpaidWaiving(this.planType, e, null);
    this.selfPayWaivingDentalCoverageChange.emit(this.selfPayWaivingDentalCoverage);
  }

  disenrollSubscriberVision(e): void {
    this.handleCobraOrUnpaidWaiving(this.planType, e, null);
    this.selfPayWaivingVisionCoverageChange.emit(this.selfPayWaivingVisionCoverage);
  }

  enrollRTLEmit(a): void {
    this.enrollRTL.emit(a);
  }

  deferRetireeChangeEmit(e): void {
    this.deferRetireeChange.emit(e);
  }

  removeForDEMedical() {
    this.clearSelectedPlan();
    this.removeMedicalForDEChange.emit(this.removeMedicalForDE);
    this.planSelected.emit(this.selectedPlan);
  }

  removeForDEDental() {
    this.clearSelectedPlan();
    this.removeDentalForDEChange.emit(this.removeDentalForDE);
    this.planSelected.emit(this.selectedPlan);
  }

  removeForDEVision() {
    this.clearSelectedPlan();
    this.removeVisionForDEChange.emit(this.removeVisionForDE);
    this.planSelected.emit(this.selectedPlan);
  }

  clearSelectedPlan() {
    this.selectedPlan = null;
    this.selectedPlanId = [];
  }

  setRemoveDE() {
    switch (this.planType) {
      case "Medical":
        this.removeMedicalForDE = false;
        this.removeMedicalForDEChange.emit(this.removeMedicalForDE);
        break;  
      case "Dental":
        this.removeDentalForDE = false;
        this.removeDentalForDEChange.emit(this.removeDentalForDE);
        break;  
      case "Vision":
        this.removeVisionForDE = false;
        this.removeVisionForDEChange.emit(this.removeVisionForDE);
        break;                    
    }
  }
}
