import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { cloneDeep, map, find, remove, filter } from 'lodash';

import SelfPayForm from 'src/app/models/selfPayForm';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import SelfPay from 'src/app/models/selfPay';
import SelfPayFormSubscriber from 'src/app/models/selfPayFormSubscriber';
import Member from 'src/app/models/member';
import MemberAddress from 'src/app/models/memberAddress';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';

@Component({
  selector: 'self-pay-custom-form-pdf',
  templateUrl: 'selfPay.customForm.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayCustomFormComponent implements OnInit {
    @Input() selfPay: SelfPay;

    constructor(private coreService: CoreService) {}

    ngOnInit(): void {
        
    }
}
