export default class Module {
    moduleId: string;
    moduleCode: string;
    moduleName: string;
    obsoleteFlag: boolean;
    obsoleteDate: Date;

    constructor(module?) {
      if (module) {
        this.moduleId = module.moduleId;
        this.moduleCode = module.moduleCode;
        this.moduleName = module.moduleName;
        this.obsoleteFlag = module.obsoleteFlag;
        this.obsoleteDate = module.obsoleteDate ? new Date(module.ObsoleteDate) : null;
      }
    }

  }
