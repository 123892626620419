
export default class PgpKeys {
  password: string;
  privateKey: string;
  publicKey: string;

  constructor(rec?) {
    if (rec) {
      this.password = rec.password ?? rec.privateKeyPassword;
      this.privateKey = rec.privateKey ?? rec.base64EncodedPrivateKey;
      this.publicKey = rec.publicKey ?? rec.base64EncodedPublicKey;
    }
  }
}
