import ApplicationSetting from 'src/app/models/applicationSettting';

import Ethnicity  from 'src/app/models/ethnicity';
import SelfPayProcessStatus from 'src/app/models/selfPayProcessStatus';
import { SelfPayAddComponent } from './components/add/selfPay.add.component';
import SelfPayType from 'src/app/models/selfPayType';
// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, HostListener, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ext
import { cloneDeep, get, find, findIndex, filter, sortBy, remove, includes, some, startsWith } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import Subscriber from 'src/app/models/subscriber';
import SelfPay from 'src/app/models/selfPay';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { SelfPayService } from 'src/app/services/selfPay.service';
import SelfPayVerificationStatus from 'src/app/models/selfPayVerificationStatus';
import Member from 'src/app/models/member';
import Plan from 'src/app/models/plan';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import SubscriberSummary from 'src/app/models/subscriberSummary';
import { PDFComponent } from 'src/app/modules/shared/components/pdf/pdf.component';
import { OEService } from 'src/app/services/oe.service';
import OpenEnrollment from 'src/app/models/openEnrollment';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import Organization from 'src/app/models/organization';
import { env } from 'src/env/development';
import { SelfPayGridComponent } from './components/grid/selfPay.grid.component';
import * as dayjs from 'dayjs';
import { AdminService } from 'src/app/services/admin.service';

@UntilDestroy()
@Lookups(LookupType.DocumentType, LookupType.SelfPayType, LookupType.SelfPayProcessStatus, LookupType.SelfPayVerificationStatus, LookupType.SelfPayPaymentType,
         LookupType.DocumentType, LookupType.GenderIdentity, LookupType.BirthSex, LookupType.County, LookupType.Country,
         LookupType.SpecialOpenEnrollmentType, LookupType.Agency, LookupType.Ethnicity, LookupType.ApplicationSetting)
@Component({
  selector: 'self-pay',
  templateUrl: 'selfpay.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class SelfPayComponent implements OnInit, OnDestroy {
  icons = {};
  _step = 0;
  lookups = {
    selfPayType: [],
    selfPayVerificationStatus: [],
    selfPayPaymentTypes: [],
    documentType: [],
    selfPayPaymentType: [],
    genderIdentities: [],
    birthSexes: [],
    countries: [],
    counties: [],
    specialOpenEnrollmentTypes: [],
    agencies: [],
    selfPayProcessStatus: [],
    applicationSetting: []
  };
  subscriber: Subscriber;
  selectedSelfPay: SelfPay;
  showAdminPrompt: boolean;
  availablePlans: Plan[];
  activePlans: Plan[];
  canAdd = false;
  currentDependents: Member[];
  initialSelfPays: SelfPay[];
  changesHaveBeenSaved = false;
  subscriberSummary: SubscriberSummary;
  proceedThroughWiz;
  isReadOnly = false;
  isOpenEnrollment = false;
  isPerspay = false;
  isPerspayAny = false;
  isHcaEdit = false;
  showVision = false
  showUpload = false;
  showConfirm = false;;
  hcaSoeSelfPayType: SelfPayType;
  currentOrganization: Organization;
  currentOrganizationAgencyCode: string = env.pebbCode;
  isPEBB = true;
  isRetiree = false;
  isPebbLwop: boolean = false;
  pebbVisionDate: Date = null;
  retireeSPTypeId = '';
  isSOE = false;
  selfPayTypeList: SelfPayType[] = [];
  soeCustomForm: boolean = false;
  formHeaderText: string = "'THIS IS A SUMMARY OF YOUR REQUESTED COVERAGE ELECTION CHANGES WITH THE HEALTH CARE AUTHORITY. THIS IS NOT A STATEMENT OF INSURANCE.'";
  pdfContainerClass: string = "";
  continuationCoverageAvailable = false;
  ethnicities: Ethnicity[];
  hasRetiree = false;
  hasLwop = false;
  hasCobra = false;
  hasApprovedRequest = false;
  env = env;

  @ViewChild('selfPayPDF') selfPayPDF: PDFComponent;
  @ViewChild('coveragePDF') coveragePDF: PDFComponent;
  @ViewChild('selfPayGridControl') selfPayGridControl: SelfPayGridComponent;
  @ViewChild('selfPayAdd') selfPayAdd: SelfPayAddComponent;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private selfPayService: SelfPayService,
    private enrollmentService: EnrollmentService,
    private openEnrollmentService: OEService,
    private adminService: AdminService
  ) {}

  async ngOnInit(): Promise<void> {
    this.pebbVisionDate = await lastValueFrom(this.adminService.getFeatureFlagEffectiveDate('FEATURE_SYNC_ENABLE_PEBB_VISION'));

    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.isSOE = !params[`soe`] ? false : true;
    });

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.subscriber = data.subscriber;
      this.lookups.selfPayType = data.lookups.selfPayType;
      this.lookups.selfPayPaymentTypes = data.lookups.selfPayPaymentType;
      this.lookups.selfPayVerificationStatus = data.lookups.selfPayVerificationStatus;
      this.lookups.documentType = data.lookups.documentType;
      this.lookups.selfPayPaymentType = data.lookups.selfPayPaymentType;
      this.lookups.genderIdentities = data.lookups.genderIdentity;
      this.lookups.birthSexes = data.lookups.birthSex;
      this.lookups.countries = data.lookups.country;
      this.lookups.counties = data.lookups.county;
      this.lookups.specialOpenEnrollmentTypes = data.lookups.specialOpenEnrollmentType;
      this.lookups.agencies = data.lookups.agency;
      this.lookups.selfPayProcessStatus = sortBy(data.lookups.selfPayProcessStatus, ['selfPayProcessStatusName']);
      this.lookups.applicationSetting = data.lookups.applicationSetting;
      this.ethnicities = data.lookups.ethnicity;
      this.retireeSPTypeId = this.lookups.selfPayType.find((o: SelfPayType) => o.selfPayTypeCode === 'R').selfPayTypeId;
      this.isPerspay = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.Perspay);
      this.isHcaEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      this.isPerspayAny = (!this.isHcaEdit && (this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.Perspay) || 
      this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay) ||
      this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.Perspay)));
      this.currentOrganization = this.subscriber?.memberSelfPayOrigin?.organization;
      if (!this.currentOrganization) {
        this.currentOrganization = this.subscriber?.organization;
      }
      if (this.currentOrganization) {
        this.currentOrganizationAgencyCode = this.currentOrganization.agency?.agencyCode || 'PEBB';
        this.isPEBB = this.currentOrganization.agency?.agencyCode !== env.sebbCode;
      }
      // coercing value for different display in sub side soe grid here, changing in model builds everything
      this.subscriber.selfPays.forEach((selfPay: SelfPay) => {
        if (selfPay.simplifiedStatus === 'Pending') {
          selfPay.simplifiedStatus = 'Waiting for review';
        }
      });

      this.setCanAdd();
      this.currentDependents = filter(this.subscriber.members, (m) => !m.isSubscriberInd);
      this.initialSelfPays = cloneDeep(data.subscriber.selfPays);
      this.enrollmentService.getSelfPayAvailablePlans().pipe(untilDestroyed(this)).subscribe((ap: Plan[]) => {
        this.availablePlans = sortBy(ap, (plan: Plan) => plan.planName);
        this.activePlans = filter(this.availablePlans, (plan: Plan) => plan.isActive());
      });
      this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);

      this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s) => {
        if (s) {
          this.currentOrganization = s;
          if (this.currentOrganization.agency?.agencyCode === env.sebbCode) {
            this.isPEBB = false;
          } else {
            this.isPEBB = true;
          }
          this.currentOrganizationAgencyCode = this.currentOrganization.agency?.agencyCode || 'PEBB';
        }
      });

    
    });

    this.hasCobra = this.subscriber.availableSelfPayTypes.filter(sp => sp.selfPayTypeCode === 'C').length > 0;
    this.hasLwop = this.subscriber.availableSelfPayTypes.filter(sp => sp.selfPayTypeCode === 'U').length > 0;
    this.hasRetiree = this.subscriber.availableSelfPayTypes.filter(sp => sp.selfPayTypeCode === 'R').length > 0;

    // Remove HCA Correction SOE if applicable.
    this.selfPayTypeList = cloneDeep(this.subscriber.availableSelfPayTypes);
    this.continuationCoverageAvailable = some(this.selfPayTypeList, (s: SelfPayType) => s.selfPayTypeCode.includes('U') || s.selfPayTypeCode.includes('C'));
    if (!this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA) || !this.subscriber.selfPays?.length) {
      this.hcaSoeSelfPayType = find(this.selfPayTypeList, (ast: SelfPayType) => ast.selfPayTypeCode === 'SOE');
      remove(this.selfPayTypeList, (ast: SelfPayType) => ast.selfPayTypeCode === 'SOE');
      remove(this.subscriber.selfPays, (s: SelfPay) => s.selfPayType.selfPayTypeCode === 'SOE');
    }

    if (!this.isSOE) {
      // selfPayTypeCode of SOE === HCA Correction Event


      // Remove SOEs if applicable.
      if (!this.subscriber.selfPays?.length) {
        remove(this.selfPayTypeList, (ast: SelfPayType) => ast.selfPayTypeCode.startsWith('SOE'));
        remove(this.subscriber.selfPays, (s: SelfPay) => s.selfPayType.selfPayTypeCode.startsWith('SOE'));
      }
    }

    if (this.subscriber.selfPays?.length) {
      const approvedRequests = this.subscriber.selfPays.filter(sp => sp.approvedById && sp.approvedDate);

      this.hasApprovedRequest = approvedRequests.length > 0;
    }
    // // Remove OEs if applicable.
    this.openEnrollmentService.getOpenEnrollments().pipe(untilDestroyed(this)).subscribe((oes: OpenEnrollment[]) => {
      this.isOpenEnrollment = some(oes, (oe: OpenEnrollment) => oe?.isActive && oe?.agency?.agencyCode === this.currentOrganizationAgencyCode);
      if (!this.isOpenEnrollment || !some(this.subscriber.selfPays, (sp: SelfPay) => sp.approvedDate)) {
        remove(this.selfPayTypeList, (ast: SelfPayType) => startsWith(ast.selfPayTypeCode, 'OE'));
      }
    });
    this.isRetiree = this.selectedSelfPay?.selfPayType?.selfPayTypeCode === 'R';
   
  }

  public get step() { return this._step; }
  public set step(newValue: number) {
    this._step = newValue;
    this.scrollToTop();
  };

  async addRetiree(): Promise<void> {
    const selfPay = new SelfPay();
    selfPay.selfPayVerificationStatusId =
      get(find(this.lookups.selfPayVerificationStatus, (vs: SelfPayVerificationStatus) => vs.selfPayVerificationStatusName === 'Draft'), 'selfPayVerificationStatusId');
    selfPay.selfPayTypeId = this.subscriber.availableSelfPayTypes.filter(sp => sp.selfPayTypeCode === 'R')[0].selfPayTypeId;

    await this.saveSelfPay(selfPay);
  }

  async addCobra(): Promise<void> {
    const selfPay = new SelfPay();
    selfPay.selfPayVerificationStatusId =
      get(find(this.lookups.selfPayVerificationStatus, (vs: SelfPayVerificationStatus) => vs.selfPayVerificationStatusName === 'Draft'), 'selfPayVerificationStatusId');
    selfPay.selfPayTypeId = this.subscriber.availableSelfPayTypes.filter(sp => sp.selfPayTypeCode === 'C')[0].selfPayTypeId;

    await this.saveSelfPay(selfPay);
  }

  async addLwop(): Promise<void> {
    const selfPay = new SelfPay();
    selfPay.selfPayVerificationStatusId =
      get(find(this.lookups.selfPayVerificationStatus, (vs: SelfPayVerificationStatus) => vs.selfPayVerificationStatusName === 'Draft'), 'selfPayVerificationStatusId');
    selfPay.selfPayTypeId = this.subscriber.availableSelfPayTypes.filter(sp => sp.selfPayTypeCode === 'U')[0].selfPayTypeId;

    await this.saveSelfPay(selfPay);
  }

  // create or update SelfPay
  async saveSelfPay(selfPay: SelfPay | any, submitting?: boolean): Promise<void> {
    this.spinnerService.show();
    const skipAdvance = selfPay?.skipAdvance;
    if (selfPay?.selfPay) {
      selfPay = selfPay.selfPay;
    }
    selfPay.agencyId = (selfPay.selfPayTypeId === this.retireeSPTypeId ? this.lookups.agencies.find(o => o.agencyCode === env.pebbCode).agencyId :
      this.subscriber.memberSelfPayOrigin?.organization?.agencyId || this.subscriber.organization?.agencyId);
    if (submitting) {
      // set default status ids
      selfPay.selfPayVerificationStatusId = get(
        find(this.lookups.selfPayVerificationStatus, (s: SelfPayVerificationStatus) => s.selfPayVerificationStatusCode === 'P'),
        'selfPayVerificationStatusId'
      );
      const newInfoReceivedStatusId = get(
        find(this.lookups.selfPayProcessStatus, (s: SelfPayProcessStatus) => s.selfPayProcessStatusCode === 'NIR'),
        'selfPayProcessStatusId');
      if (!selfPay.selfPayProcessStatusId && selfPay.selfPayProcessStatusId !== newInfoReceivedStatusId) {
        // only set this to new if it has not been set yet.  Workflow could cause this process status to be set by HCA, we don't want to override it
        selfPay.selfPayProcessStatusId = get(
          find(this.lookups.selfPayProcessStatus, (s: SelfPayProcessStatus) => s.selfPayProcessStatusCode === 'N'),
          'selfPayProcessStatusId'
        );
      }
    }

    if (!selfPay?.selfPayId) {
      try {
        // on new default payment option to existing SPO payment method, if exists.
        selfPay.selfPayPaymentTypeId = this.subscriber.memberSelfPayOrigin?.selfPayPaymentTypeId;
        const newSelfPay = await lastValueFrom(this.selfPayService.createSelfPay(this.subscriber.memberId, selfPay));
        this.subscriber.selfPays.push(newSelfPay);
        if (this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA) && !includes(this.subscriber.availableSelfPayTypes, (spt: SelfPayType) => spt.selfPayTypeCode === 'SOE')) {
          this.subscriber.availableSelfPayTypes.push(this.hcaSoeSelfPayType);
        }
        this.selectedSelfPay = newSelfPay;
        this.selectedSelfPay.formJson.origin = this.subscriber.memberSelfPayOrigin || null;
        this.step = 1;
        this.setCustomForm();

        // re-grab sub on nav
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
        if (this.coreService.systemUserHasAccess(AccessLevel.Edit)) {
          this.showAdminPrompt = true;
        }
        this.setCanAdd();
        this.spinnerService.hide();
      } catch (err) {
        console.log('err', err);
        this.spinnerService.hide();
      }
    } else {
      try {
        delete selfPay.member;
        const updatedSelfPay = await lastValueFrom(this.selfPayService.updateSelfPayForm(this.subscriber.memberId, selfPay));
        // re-grab sub on nav
        this.selectedSelfPay = cloneDeep(updatedSelfPay);
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
        if (submitting) {
          // update form still for tacking origin on
          await lastValueFrom(this.selfPayService.updateSelfPayForm(this.subscriber.memberId, selfPay));
          delete selfPay.formJson;
          delete selfPay.documents;
          const submittedSelfPay = await lastValueFrom(this.selfPayService.updateSelfPay(this.subscriber.memberId, selfPay));
          this.step = 0;
          await this.refetchSelfPay(this.selectedSelfPay);
          if (submitting) {
            this.selfPayGridControl.gridData = this.subscriber.selfPays;
            this.selfPayGridControl.setGridData();
          }
        } else {
          if (!skipAdvance) {
            this.step++;
            this.getSelfPayStep();
          }
        }
        this.spinnerService.hide();
      } catch (err) {
        console.log('err', err);
        this.spinnerService.hide();
      }
    }
  }

  setCustomForm() {
    if (env.soeSelfPayTypeCodesFormRequired.includes(this.selectedSelfPay.selfPayType.selfPayTypeCode)) {
      this.soeCustomForm = true;
    }
  }

  getPebbLwop() : boolean {
    return this.isPEBB && (((this.selectedSelfPay?.formJson?.selfPayReason === 'ALWOP' || this.selectedSelfPay?.selfPayType.selfPayTypeCode === 'SOEU') && dayjs(this.selectedSelfPay?.coverageEffectiveDate || Date.now()).isSameOrAfter(this.pebbVisionDate)) || this.selectedSelfPay?.selfPayType.selfPayTypeCode === "OEU");
  }

  async refetchSelfPay(s: SelfPay): Promise<void> {
    let selfPay;
    try {
      selfPay = await lastValueFrom(this.selfPayService.getSelfPayById(s.selfPayId, this.subscriber.memberId));
    } catch (err) {
      console.log(err);
    }
    if (selfPay.simplifiedStatus === 'Pending') {
      selfPay.simplifiedStatus = 'Waiting for review';
    }
    this.selectedSelfPay = selfPay;
    // pebb vision for lwop after 1/1/25  
    this.isPebbLwop = this.getPebbLwop();    
    const idx = findIndex(this.subscriber.selfPays, (oSelfPay: SelfPay) => oSelfPay.selfPayId === selfPay.selfPayId);
    this.subscriber.selfPays.splice(idx, 1, selfPay);
  }

  scrollToTop(): void {
    setTimeout(() => {
      const h = window.document.querySelector('self-pay h2, self-pay h3');
      if (h) h.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  previous(): void {
    this.step--;
    this.getSelfPayStep();
  }

  continue(): void {
    this.step++;
    this.getSelfPayStep();
  }

  getSelfPayStep() {
    this.isPebbLwop = this.getPebbLwop();
    if (this.isPebbLwop) {
      if (this.selectedSelfPay?.selfPayType.selfPayTypeCode === "OEU" || this.selectedSelfPay?.selfPayType.selfPayTypeCode === "SOEU" ) {
        this.showVision = this.step === 7;
        this.showUpload = this.step === 8;
        this.showConfirm = this.step === 9;
      } else {
        this.showVision = this.step === 8;
        this.showUpload = this.step === 9;
        this.showConfirm = this.step === 10;
      }
    }
  }

  setCanAdd() {
    this.canAdd = !this.subscriber.selfPays.some(
      (r) => r.selfPayVerificationStatus.selfPayVerificationStatusName === 'Draft' || r.selfPayVerificationStatus.selfPayVerificationStatusName === 'Pending'
      || r.selfPayVerificationStatus.selfPayVerificationStatusCode === env.selfPayApprovedPendingFirstPaymentCode
    );
  }

  deleteSelfPay(e): void {
    const selfPayToDelete = e.dataItem;
    try {
      const originalSubscriberSelfPayTypes = this.subscriber.availableSelfPayTypes;
      this.spinnerService.show();
      lastValueFrom(this.selfPayService.removeSelfPay(selfPayToDelete));
      remove(this.subscriber.selfPays, (selfPay: SelfPay) => selfPay.selfPayId === selfPayToDelete.selfPayId);
      this.setCanAdd();
      this.subscriber.refetch = true;
      this.coreService.setSubscriber(this.subscriber);
      this.selfPayGridControl.gridData = this.subscriber.selfPays;
      this.selfPayGridControl.setGridData();
      this.proceedThroughWiz = null;
    } catch (err) {
      this.spinnerService.hide();
      console.log(err);
    }
    this.spinnerService.hide();
  }

  async editSelfPay(e): Promise<void> {
    this.continue();
    this.selectedSelfPay = e.dataItem;
    this.setCustomForm();
    // refetch plans in the future for OE Unpaid
    if (this.selectedSelfPay?.selfPayType.selfPayTypeCode === 'OEU') {
        this.enrollmentService.getSelfPayAvailablePlans().pipe(untilDestroyed(this)).subscribe((ap: Plan[]) => {
        this.availablePlans = sortBy(ap, (plan: Plan) => plan.planName);
        this.activePlans = filter(this.availablePlans, (plan: Plan) => plan.isActive(this.selectedSelfPay.coverageEffectiveDate ? this.selectedSelfPay.coverageEffectiveDate : this.pebbVisionDate));
      }); 
    }
    
  }

  ngOnDestroy(): void {}

  async updatePaymentOption(selfPayPaymentOptionCode): Promise<void> {
    this.spinnerService.show();
    const selectedPaymentOption = this.lookups.selfPayPaymentType.find((r) => r.pmaPaymentTypeCode === selfPayPaymentOptionCode);
    try {
      this.selectedSelfPay.selfPayPaymentTypeId = selectedPaymentOption?.selfPayPaymentTypeId;
      const selfPay = cloneDeep(this.selectedSelfPay);
      const updatedSelfPay = await lastValueFrom(this.selfPayService.updateSelfPayForm(this.subscriber.memberId, selfPay));
      delete selfPay.member;
      delete selfPay.formJson;
      delete selfPay.documents;
      const submittedSelfPay = await lastValueFrom(this.selfPayService.updateSelfPay(this.subscriber.memberId, selfPay));
      await this.refetchSelfPay(selfPay);
      this.subscriber.refetch = true;
      this.coreService.setSubscriber(this.subscriber);

      this.step++;
      this.spinnerService.hide();
    } catch {
      this.spinnerService.hide();
    }
  }

  downloadSelfPayPDF(e): void {
    if (e) {
      this.selectedSelfPay = e;
      if (env.soeSelfPayTypeCodesFormRequired.includes(this.selectedSelfPay.selfPayType.selfPayTypeCode)) {
        this.soeCustomForm = true;
        this.formHeaderText = "";
        this.pdfContainerClass = "custom-pdf-form";
      }
    }
    // ngif digest cycle
    setTimeout(() => {
      this.selfPayPDF.pdfElement.saveAs('SelfPay.pdf');
    });
  }

  downloadCoveragePDF(): void {
    this.subscriberSummary = new SubscriberSummary(this.subscriber);
    this.selfPayPDF.pdfElement.saveAs('Elections.pdf');
  }

  navToDashboard(): void {
    this.router.navigate([(this.isSOE ? '../' : '') + `../../subscriber/${this.subscriber.memberId}`], { relativeTo: this.route });
  }

  proceed(e: boolean): void {
    if (e) {
      this.step = 0;
      // pers/hca user opts out - submit soe
      this.saveSelfPay(this.selectedSelfPay, true);
      // reset bool
      this.proceedThroughWiz = null;
    }
    this.showAdminPrompt = false;

  }

  // capitilize every first letter of a word (for titles)
  capitalize(s: string): string {
    return s.replace(/(\b[a-z](?!\s))/g, m => m.toUpperCase());
  }


}
