export default class EmployeeRepresentedStatus {
    employeeRepresentedStatusId: string;
    employmentId: string;
    representedInd = false;
    effectiveStartDate: Date = new Date();
    effectiveEndDate: Date;

    constructor(ers?) {
      if (ers) {
        this.employeeRepresentedStatusId = ers.employeeRepresentedStatusId;
        this.employmentId = ers.employmentId;
        this.representedInd = ers.representedInd;
        this.effectiveStartDate = ers.effectiveStartDate ? new Date(ers.effectiveStartDate) : null;
        this.effectiveEndDate = ers.effectiveEndDate ? new Date(ers.effectiveEndDate) : null;
      }
    }

  }
