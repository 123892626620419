// import * as dayjs from 'dayjs';
// import * as isBetween from 'dayjs/plugin/isBetween';
import { some } from 'lodash';
import ExternalEnrollment from './externalEnrollment';
import Organization from './organization';

export default class DualEnrollment {
  currentAgency: string;
  otherAgency: string;
  hasOtherAgencyAtAll = false;
  hasCurrentAgencyDependentDental = false;
  hasCurrentAgencyDependentMedical = false;
  hasCurrentAgencyDependentVision = false;
  hasCurrentAgencyDental = false;
  hasCurrentAgencyMedical = false;
  hasCurrentAgencyVision = false;  
  isSebb: boolean;
  deTypes = [];

  constructor(organization: Organization, externalEnrollments: ExternalEnrollment[], dateToUse?: Date) {
    dateToUse = dateToUse ?? new Date();

    this.currentAgency = organization?.agency?.agencyCode;
    this.otherAgency = this.currentAgency === 'PEBB' ? 'SEBB' : 'PEBB';

    this.hasOtherAgencyAtAll = some(externalEnrollments, (e: ExternalEnrollment) => e.representsExternalEnrollmentForAgency(dateToUse, this.otherAgency));
    this.hasCurrentAgencyDependentDental = some(externalEnrollments,
      (r: ExternalEnrollment) => r.representsExternalEnrollmentAsDependentForTypeAndForAgency(dateToUse, this.currentAgency, '2'));
    this.hasCurrentAgencyDependentMedical = some(externalEnrollments,
      (r: ExternalEnrollment) => r.representsExternalEnrollmentAsDependentForTypeAndForAgency(dateToUse, this.currentAgency, '1'));
    this.hasCurrentAgencyDependentVision = some(externalEnrollments,
      (r: ExternalEnrollment) => r.representsExternalEnrollmentAsDependentForTypeAndForAgency(dateToUse, this.currentAgency, '6'));
    this.hasCurrentAgencyDental = some(externalEnrollments,
      (r: ExternalEnrollment) => r.representsExternalEnrollmentForAgencyAndType(dateToUse, this.currentAgency, '2'));
    this.hasCurrentAgencyMedical = some(externalEnrollments,
      (r: ExternalEnrollment) => r.representsExternalEnrollmentForAgencyAndType(dateToUse, this.currentAgency, '1'));
    this.hasCurrentAgencyVision = some(externalEnrollments,
      (r: ExternalEnrollment) => r.representsExternalEnrollmentForAgencyAndType(dateToUse, this.currentAgency, '6'));
    
    if (this.hasCurrentAgencyDependentMedical) { this.deTypes.push('medical'); }
    if (this.hasCurrentAgencyDependentDental) { this.deTypes.push('dental'); }
    if (this.hasCurrentAgencyDependentVision) { this.deTypes.push('vision'); }

    this.isSebb = this.currentAgency === 'SEBB';
  }
}
