import { some } from 'lodash';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import SelfPay from 'src/app/models/selfPay';
import SelfPayType from 'src/app/models/selfPayType';
import Plan from 'src/app/models/plan';
import { env } from 'src/env/development';
@Component({
  selector: 'self-pay-confirm',
  templateUrl: 'selfPay.confirm.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayConfirmComponent implements OnInit {
  @Input() requestType: SelfPayType;
  @Input() agency: string;
  @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @Output() downloadPDF: EventEmitter<void> = new EventEmitter();
  @ViewChild('selfPayConfirmForm') public changeTypeForm: NgForm;
  @Input() selfPay: SelfPay;
  icons = {
      faCalendar
  }
  isMedicareAdvantagePlan = false;
  isCobra: boolean = false;
  
  ngOnInit() {
    this.isMedicareAdvantagePlan = some(this.selfPay?.formJson?.medicalPlans, (p: Plan) => env.medicareAdvantagePlanCodes.includes(p.planCode));
    this.isCobra = env.cobraSelfPayTypeCodes.includes(this.selfPay.selfPayType.selfPayTypeCode);
  }

  emitSelfPay(e) {
    this.saveSelfPay.emit(e);
  }


}
