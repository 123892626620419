import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { SelectableSettings, DataStateChangeEvent, GridComponent, PageChangeEvent, DetailExpandEvent } from '@progress/kendo-angular-grid';

import { Pay1SyncService } from './../../../../services/pay1Sync.service';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SystemUser from 'src/app/models/user';
import { orderBy, forEach, filter, isNull, startCase, cloneDeep, map, sortBy, some, remove, mapValues, keyBy, reverse, includes } from 'lodash';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import Pay1SyncHoldErrorOverview from 'src/app/models/pay1SyncHoldErrorOverview';
import { State, process, filterBy, SortDescriptor, FilterDescriptor, DataResult } from '@progress/kendo-data-query';
import * as dayjs from 'dayjs';
import { lastValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Pay1SyncHold from 'src/app/models/pay1SyncHold';
import Pay1SyncLock from 'src/app/models/pay1SyncLock';
import Pay1SyncHoldOverview from 'src/app/models/pay1SyncHoldOverview';
import Member from 'src/app/models/member';

class LevelInfo<T> {
  data: T[];
  clonedGridData: DataResult;
  state: DataStateChangeEvent | State = {
    skip: 0
  };
  skip = 0;
  selectedItem: T;
  selectBy: string;
  selectedKeys: string[] = [];
  columns: { [k: string]: string | {} }[];
  sort: SortDescriptor[] = [];
  filter: FilterDescriptor;

  constructor(cols?: { [k: string]: string | {} }[], selectBy?: string) {
    this.columns = cols;
    this.selectBy = selectBy;
  }
}

@UntilDestroy()
@Component({
  selector: 'sync-err-handling',
  templateUrl: 'sync.error.handling.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class HCASyncErrorHandlingComponent implements OnInit {
  icons = {
    faPlus,
    faMinus,
  };
  systemUser: SystemUser;
  data: {
    l1: LevelInfo<Pay1SyncHoldErrorOverview>,
    l2: LevelInfo<Pay1SyncHoldOverview>,
    l3: LevelInfo<Pay1SyncHold>,
    l4: LevelInfo<any>,
    locks: LevelInfo<Pay1SyncLock>,
    searchLocks: LevelInfo<Member>,
  } = {
    l1: new LevelInfo<Pay1SyncHoldErrorOverview>([
      { field: 'lastName', title: 'Last name', format: 'string' },
      { field: 'firstName', title: 'First name', format: 'string' },
      { field: 'errorDate', title: 'Error date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'program', title: 'Program', format: 'string' },
      { field: 'transactionType', title: 'Transaction type', format: 'string' },
      { field: 'agency', title: 'Agency', format: 'string' }
    ]),
    l2: new LevelInfo<Pay1SyncHoldOverview>([
      { field: 'transactionId', title: 'Transaction ID', format: 'number' },
      { field: 'sequenceId', title: 'Sequence', format: 'number' },
      { field: 'lastName', title: 'Last name', format: 'string' },
      { field: 'firstName', title: 'First name', format: 'string' },
      { field: 'memberType', title: 'Member type', format: 'string' },
      { field: 'errorInfo', title: 'Error info', format: 'string' },
      { field: 'transactionTypeCode', title: 'Transaction type', format: 'string' },
      { field: 'source', title: 'Source', format: 'string' },
      { field: 'sentDate', title: 'Sent date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'errorDate', title: 'Error date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'createdDate', title: 'Create date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    ], 'pay1SyncHoldId'),
    l3: new LevelInfo<Pay1SyncHold>([
      { field: 'pay1SyncHoldId', title: 'ID', format: 'string' },
      { field: 'subscriberMemberId', title: 'Sub member ID', format: 'string' },
      { field: 'memberId', title: 'Member ID', format: 'string' },
      { field: 'transactionTypeCode', title: 'Transaction type', format: 'string' },
      { field: 'sequenceId', title: 'Sequence', format: 'number' },
      { field: 'tranId', title: 'Tran ID', format: 'number' },
      { field: 'asOfDate', title: 'As of date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'sources', title: 'Sources', format: 'string' },
      { field: 'output', title: 'Output', format: 'string', width: 400 },
      { field: 'enrollmentPeriodIds', title: 'Enrollment periods', format: 'string' },
      { field: 'sentToPay1Date', title: 'Sent to PAY1', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'processedByPay1Date', title: 'Processed by PAY1', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'pay1ErrorDate', title: 'PAY1 error date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'pay1ErrorInfo', title: 'PAY1 error', format: 'string' },
      { field: 'createdDate', title: 'Created date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    ]),
    l4: new LevelInfo<any>(),
    locks: new LevelInfo<Pay1SyncLock>([
      { field: 'subscriberMemberId', title: 'Sub GUID', format: 'string' },
      { field: 'subscriberName', title: 'Sub name', format: 'string' },
      { field: 'subscriberSsn', title: 'Sub SSN', format: 'string' },
      { field: 'memberName', title: 'Member name', format: 'string' },
      { field: 'memberSsn', title: 'Member SSN', format: 'string' },
      { field: 'effectiveStartDate', title: 'Start date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'effectiveEndDate', title: 'End date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    ], 'pay1SyncLockId'),
    searchLocks: new LevelInfo<Member>([
      { field: 'memberId', title: 'Member ID', format: 'string' },
      { field: 'firstName', title: 'First name', format: 'string' },
      { field: 'lastName', title: 'Last name', format: 'string' },
      { field: 'socialSecurityNumber', title: 'SSN', format: 'string' },
      { field: 'birthDate', title: 'DOB', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
      { field: 'isSubscriberInd', title: 'Is sub?', format: 'boolean' },
    ], 'memberId')
  };
  l4: string;
  savedRecords: string[] = [];
  now = dayjs().format('MMDDYYYY');
  editableFields = [
      'elig_type', 'elig_rsn_cd', 'elig_eff_dt', 'elig_apply_dt', 'regain_elig_dt',
      'hlth_enr_cd', 'hlth_enr_rsn_cd', 'hlth_eff_dt', 'hlth_end_dt',
      'hlth_tsa', 'hlth_tsa_eff_dt',
      'dntl_enr_cd', 'dntl_enr_rsn_cd', 'dntl_eff_dt', 'dntl_end_dt',
      'visn_enr_cd', 'visn_enr_rsn_cd', 'visn_eff_dt', 'visn_end_dt',
      'basic_life_enr', 'basic_life_eff_dt',
      'ltd_basic_enr', 'ltd_basic_eff_dt',
      'ltd_opt_enr', 'ltd_opt_eff_dt', 'ltd_opt_elim_period', 'ltd_opt_percent_sebb',

      'dep_spousal_surcharge_ssa_ind', 'dep_spousal_surcharge_ssa_eff_dt',
      'dep_60day_term_ind',
      'dep_health_tobacco_tsa_ind', 'dep_health_tobacco_tsa_eff_dt',
      'dep_health_enrollment_cd', 'dep_health_enrollment_reason_cd', 'dep_health_eff_dt', 'dep_health_end_dt',
      'dep_dental_enrollment_cd', 'dep_dental_enrollment_reason_cd', 'dep_dental_eff_dt', 'dep_dental_end_dt',
      'dep_vision_enrollment_cd', 'dep_vision_enrollment_reason_cd', 'dep_vision_eff_dt', 'dep_vision_end_dt',

      'home_agy', 'subscriber_home_agy', 'pay_method', 'marital_sts', 'marriage_dt', 'divorce_dt', 'deceased_dt', 'hlth_carr',
      'medicare_a_ind', 'medicare_a_dt', 'medicare_b_ind', 'medicare_b_dt', 'dntl_carr', 'visn_carr',
      'dep_medicare_a_ind', 'dep_medicare_a_dt', 'dep_medicare_b_ind', 'dep_medicare_b_dt', 'signature_date_dt',
      'medicare_d_ind', 'medicare_d_dt', 'signature_dis_date_dt', 'dep_medicare_d_ind', 'dep_medicare_d_dt', 'dep_signature_dis_date_dt'
    ];
  isHcaSysAdmin = false;
  showActiveLocksOnly = true;
  showSyncLocks = false;
  showSearchLocks = false;
  addLockMemberIdOrSsn: string;
  addLock = false;
  locksSelectable: SelectableSettings =  { mode: 'multiple'};
  lockAccountLevel = true;
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;

  constructor(
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService,
    private coreService: CoreService,
    private pay1SyncService: Pay1SyncService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.refresh();

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;

      this.isHcaSysAdmin = this.coreService.systemUserHasAccess(AccessLevel.SystemAdmin, UserTypeCode.HCA);
    });
  }

  async refresh(): Promise<void> {
    this.spinnerOverlayService.show();

    const transactions = await lastValueFrom(this.pay1SyncService.fetchErroredTransactions());

    this.data.l1.data = sortBy(transactions, t => t.errorDate);
    this.reSetDataForGrid(this.data.l1);

    this.spinnerOverlayService.hide();
  }

  outputField(dataItem, col): any {
    const val = dataItem[col.field];

    if (typeof col.format === 'function') {
      return col.format(val);
    }

    if (val && col.format?.date) {
      return dayjs(val).format(col.format.date.toUpperCase());
    }

    return val;
  }

  public dataStateChange<T>(state: DataStateChangeEvent | State, info: LevelInfo<T>): void {
    info.state = state;
    info.clonedGridData = process(info.data, state);
  }

  public pageChange<T>(event: PageChangeEvent, info: LevelInfo<T>): void {
    info.skip = event.skip;
    this.loadItems(info);
  }

  private loadItems<T>(info: LevelInfo<T>): void {
    info.clonedGridData = {
      data: orderBy(filterBy(cloneDeep(info.data), info.filter), info.sort).slice(info.skip, info.skip + 20),
      total: info.data.length,
    };
  }

  finishManaging(): void {
    if (this.data.l1.selectedItem) {
      // Check for unsent changes in L2 table
      const unsentItems = this.data.l2.data.filter(l2 => this.savedRecords.indexOf(l2.pay1SyncHoldId) > -1);

      if (unsentItems.length) {
        this.coreService.popMessage('There are unsent changes to existing records.', 'warning', 2000);
      }
    }

    this.collapseL1GridSelection();
    this.data.l1.selectedItem = null;
    this.data.l2.selectedItem = null;
    this.data.l3.selectedItem = null;
    this.data.l4.selectedItem = null;
  }

  collapseL1GridSelection(): void {
    this.data.l1.data?.forEach((gd, idx) => this.kendoGrid.collapseRow(idx));
  }

  async getRowDetails(event: DetailExpandEvent): Promise<void> {
    // Something's already selected, we cannot do anything until they finish managing it
    if (this.data.l1.selectedItem) {
      event.preventDefault();
      return;
    }

    this.data.l4.selectedItem = null;
    this.data.l3.selectedItem = null;
    this.data.l2.selectedItem = null;

    this.collapseL1GridSelection();
    this.data.l1.selectedItem = event.dataItem;
    event.preventDefault();

    this.spinnerOverlayService.show();
    this.data.l2.data = sortBy(await lastValueFrom(this.pay1SyncService.fetchErrorsForSubscriber(event.dataItem.memberId)), t => t.errorDate);
    this.reSetDataForGrid(this.data.l2);
    this.spinnerOverlayService.hide();

    this.kendoGrid.expandRow(event.index);
  }

  async resendRecords(pay1SyncHoldIds: string[]): Promise<void> {
    this.spinnerOverlayService.show();

    await lastValueFrom(this.pay1SyncService.resendRecords(this.data.l1.selectedItem.memberId, pay1SyncHoldIds));

    this.coreService.popMessage('Records will be sent again at next sync file generation', 'info', 2000);

    const e = new DetailExpandEvent(null);
    e.dataItem = this.data.l1.selectedItem;
    e.index = this.data.l1.data.indexOf(e.dataItem);
    await this.getRowDetails(e);

    if (this.data.l2.data.length === 0) {
      // This was the last error record, so take it out of the parent list
      this.data.l1.data.splice(e.index, 1);
      this.data.l1.selectedItem = null;
      this.reSetDataForGrid(this.data.l1);
    }

    this.spinnerOverlayService.hide();
  }

  async markAsProcessed(row: Pay1SyncHoldOverview): Promise<void> {
    if (confirm('Are you sure you want to mark this record as processed?')) {
      this.data.l4.selectedItem = null;
      this.data.l3.selectedItem = null;
      this.data.l2.selectedItem = null;
      this.data.l1.selectedItem = null;

      this.spinnerOverlayService.show();
      await lastValueFrom(this.pay1SyncService.markAsProcessed(row.memberId, row.pay1SyncHoldId));
      await this.refresh();
      this.collapseL1GridSelection();
      this.spinnerOverlayService.hide();
    }
  }

  async allowSync(row: Pay1SyncHoldOverview): Promise<void> {
    if (confirm('Are you sure you want to allow this record to send to PAY1?')) {
      this.data.l4.selectedItem = null;
      this.data.l3.selectedItem = null;
      this.data.l2.selectedItem = null;
      this.data.l1.selectedItem = null;

      this.spinnerOverlayService.show();
      await lastValueFrom(this.pay1SyncService.allowSync(row.memberId, row.pay1SyncHoldId));
      await this.refresh();
      this.collapseL1GridSelection();
      this.spinnerOverlayService.hide();
    }
  }

  async viewRecordL2(row: Pay1SyncHoldOverview): Promise<void> {
    this.data.l4.selectedItem = null;
    this.data.l3.selectedItem = null;
    this.data.l2.selectedItem = row;

    this.spinnerOverlayService.show();
    this.data.l3.data = sortBy(await lastValueFrom(this.pay1SyncService.fetchRecord(row.memberId, row.pay1SyncHoldId)), t => t.errorDate);
    this.reSetDataForGrid(this.data.l3);
    this.spinnerOverlayService.hide();
  }

  async viewRecordL3(row: Pay1SyncHold): Promise<void> {
    this.data.l4.selectedItem = null;
    // Modal is triggered off this being set, so clear it out while we set things up and set it at the end.
    this.data.l3.selectedItem = null;

    this.spinnerOverlayService.show();
    this.data.l4.data = sortBy(await lastValueFrom(this.pay1SyncService.fetchDetail(row.subscriberMemberId, row.pay1SyncHoldId)), t => t.errorDate);

    this.data.l4.columns = [];
    const data = this.data.l4.data[0];
    if (data) {
      for (const field in data) {
        if (field.includes('Date')) {
          map(data, (val, rd) => {
            data[rd][field] = val[field] && val[field] !== '0001-01-01T00:00:00-08:00' ? new Date(val[field]) : null;
          });
        }

        this.data.l4.columns.push({
          field: field,
          title: field,
          format: (field.startsWith('date') || field.includes('Date')) ? (field.includes('Time') ? '{0: MM/dd/yyyy HH:mm:ss}' : { date: 'mm/dd/yyyy' }) : 'string',
          filter: (field.startsWith('date') || field.includes('Date')) ? 'date' : null,
          width: 200
        });
      }
    }

    if (this.data.l4.columns.length > 75) {
      this.l4 = 'sub';
    } else {
      this.l4 = 'dep';
    }

    // Replicate the record; this is the "editable" version
    this.data.l4.data.push(cloneDeep(data));

    this.data.l3.selectedItem = row;

    this.reSetDataForGrid(this.data.l4);
    this.spinnerOverlayService.hide();
  }

  reSetDataForGrid<T>(info: LevelInfo<T>, index?): void {
    this.loadItems(info);
  }

  public changeSelection<T>(e, info: LevelInfo<T>): void {
    info.selectedKeys = e.selectedRows.map(r => r.dataItem[info.selectBy]);
  }

  closeWizardDialogue(): void {
    this.data.l3.selectedItem = null;
  }

  async saveOutput(subscriberMemberId: string, pay1SyncHoldId: string, record: any): Promise<void> {
    this.spinnerOverlayService.show();

    await lastValueFrom(this.pay1SyncService.updateOutput(subscriberMemberId, pay1SyncHoldId, record));
    this.savedRecords.push(pay1SyncHoldId);

    this.closeWizardDialogue();

    this.spinnerOverlayService.hide();
    this.coreService.popMessage('Updated record', 'success', 2000);
  }

  async openSyncLocks(): Promise<void> {
    this.spinnerOverlayService.show();

    this.data.locks.data = sortBy(await lastValueFrom(this.pay1SyncService.getLocks(this.showActiveLocksOnly)), t => t.effectiveStartDate);
    this.reSetDataForGrid(this.data.locks);

    this.showSyncLocks = true;

    this.spinnerOverlayService.hide();
  }

  async searchLock(addLockMemberIdOrSsn: string): Promise<void> {
    this.spinnerOverlayService.show();
    
    this.data.searchLocks.data = sortBy(await lastValueFrom(this.pay1SyncService.searchLock(addLockMemberIdOrSsn)), t => t.fullName);
    this.reSetDataForGrid(this.data.searchLocks);

    this.showSearchLocks = true;

    this.spinnerOverlayService.hide();
  }

  async lockRecords(memberIds: string[], lockAccountLevel: boolean): Promise<void> {
    this.spinnerOverlayService.show();
    
    const members = this.data.searchLocks.data.filter(m => memberIds.indexOf(m.memberId) > -1);

    for(let i = 0, m; m = members[i]; i++)
      await lastValueFrom(this.pay1SyncService.addLock(m, lockAccountLevel));

    this.showSearchLocks = false;
    this.addLock = false;
    await this.openSyncLocks();

    this.spinnerOverlayService.hide();
  }

  async endLocks(pay1SyncLockIds: string[]): Promise<void> {
    this.spinnerOverlayService.show();
    
    for(let i = 0, id; id = pay1SyncLockIds[i]; i++)
      await lastValueFrom(this.pay1SyncService.unlock(id));

    await this.openSyncLocks();

    this.spinnerOverlayService.hide();
  }
}
