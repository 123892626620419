// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import MemberAddress from '../models/memberAddress';

@Injectable({
  providedIn: 'root'
})

export class AddressService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createMemberAddress(address: MemberAddress): Observable<MemberAddress> {
    return this.http
      .post<MemberAddress>(`${env.apiUrl}/Member/${address?.memberId}/address`, address)
      .pipe(map(x => this.commonService.createObject(MemberAddress, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMemberAddress(address: MemberAddress): Observable<MemberAddress> {
    return this.http
      .put<MemberAddress>(`${env.apiUrl}/Member/${address?.memberId}/address/${address?.memberAddressId}`, address)
      .pipe(map(x => this.commonService.createObject(MemberAddress, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteMemberAddress(address: MemberAddress): Observable<MemberAddress> {
    return this.http
      .delete<MemberAddress>(`${env.apiUrl}/Member/${address?.memberId}/address/${address?.memberAddressId}`)
      .pipe(res => res)
      .pipe(catchError(this.commonService.handleError));
  }

}
