import { CoverageComponent } from './../modules/subscriber/modules/coverage/coverage.component';
import { env } from 'src/env/development';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import { first, last, find, filter, head, reverse, sortBy, map, some, maxBy, includes, cloneDeep } from 'lodash';
import MemberSummary from './memberSummary';
import Subscriber from './subscriber';
import Enrollment from './enrollment';
import Attestation from './attestation';
import MemberAddress from './memberAddress';
import OpenEnrollment from './openEnrollment';
import MemberWaiver from './memberWaiver';
import PlanType from './planType';
import Member from './member';
import MemberType from './memberType';
import SpecialOpenEnrollment from './specialOpenEnrollment';
import EnrollmentPeriod from './enrollmentPeriod';
import ExternalEnrollment from './externalEnrollment';
import MemberMedicare from './memberMedicare';
import Plan from './plan';

export default class SubscriberEffectiveSummary {
  memberId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  emailNotificationsInd: boolean;
  countyOfResidence: string;
  effectiveMedicalPlan: string;
  medicalPremium: number;
  tobaccoUseSurcharge: number;
  spousalCoverageSurcharge: number;
  totalCost: number;
  effectiveDentalPlan: string;
  dentalPremium: number;
  effectiveVisionPlan: string;
  visionPremium: number;
  effectiveADDPlan: string;
  addPremium: number;
  effectiveLifePlan: string;
  lifePremium: number;
  lifeBenefitAmount: number;
  addBenefitAmount: number;
  spousalCoverageSurchargeInd: boolean;
  employmentAddress: MemberAddress;
  residentialAddress: MemberAddress;
  effectiveMedical: Enrollment;
  effectiveDental: Enrollment;
  effectiveVision: Enrollment;
  effectiveLife: Enrollment;
  effectiveADD: Enrollment;
  effectiveSupplementalLTD: Enrollment;
  accountEffectiveMedical: Enrollment;
  accountEffectiveDental: Enrollment;
  accountEffectiveVision: Enrollment;
  accountEffectiveLife: Enrollment;
  accountEffectiveADD: Enrollment;
  accountEffectiveSupplementalLTD: Enrollment;
  memberSummary: MemberSummary[];
  memberWaivers: MemberWaiver[];
  electablePlanTypes: PlanType[];
  waiveablePlanTypes: PlanType[];
  effectiveMedicalPlanId: string;
  effectiveDentalPlanId: string;
  effectiveVisionPlanId: string;
  effectiveLifePlanId: string;
  effectiveADDPlanId: string;
  effectiveSupplementalLTDPlan: string;
  futureSupplementalLTD: Enrollment;
  members: Member[];
  memberType: MemberType;
  familyCompositionCode: string;
  currentEmployer: string;
  effectiveEnrollments: Enrollment[];
  accountLevelEnrollments: Enrollment[];
  currentlyEffectiveEnrollmentPeriod: EnrollmentPeriod;
  dependentAgencyDualEnrollMedical: boolean;
  dependentOtherAgencyDualEnrollMedical: boolean;
  dependentDEMedical: boolean;
  deMedicalAny: boolean;
  subscriberDualProgramEnrolled: boolean;
  subscriberDualProgramEnrolledMedical: boolean;
  DEMessageAccepted = null;
  retireeTermLifeEnrolledInd = false;
  basicLifeEnrolledInd = false;
  agencyCode? : string;
  otherAgencyCode?: string;
  memberMedicare: MemberMedicare;
  isSelfPay: boolean = false;
  isLWOP = false;
  medicareDate: Date;
  hasMedicareAorB: boolean = false;
  subscriberOrDependentsHaveMedicareB = false;
  medicareDEnabled = false;
  private notEligibleTxt: string = "Not Eligible";
  
  constructor(member: Subscriber, enrollmentPeriod?: EnrollmentPeriod, useCurrent = false, isSOE = false, soeTypeName = '', useLatestAttestationWhenNotFound = true, medicareDEnabled: boolean = false) {
    this.medicareDEnabled = medicareDEnabled;
    this.currentlyEffectiveEnrollmentPeriod = find(member.enrollmentPeriods, (e: EnrollmentPeriod) => e.isCurrentlyActive);
    const tempEnrollmentPeriod = new EnrollmentPeriod();
    tempEnrollmentPeriod.coverageEffectiveStartDate = new Date();
    this.memberMedicare = member.memberMedicare;
    this.isSelfPay = member.isSelfPay;
    this.isLWOP = member.isLWOP;

    if (useCurrent) {
      const enrollmentsNotInFuture = member.allEnrollmentPeriods.filter(ep => dayjs().isAfter(ep.coverageEffectiveStartDate));
      const mostRecentEnrollmentNotInFuture = last(sortBy(enrollmentsNotInFuture, 'coverageEffectiveStartDate'));
      enrollmentPeriod = this.currentlyEffectiveEnrollmentPeriod = mostRecentEnrollmentNotInFuture ?? this.currentlyEffectiveEnrollmentPeriod ?? tempEnrollmentPeriod;
      enrollmentPeriod.coverageEffectiveStartDate = tempEnrollmentPeriod.coverageEffectiveStartDate;
    } else {
      enrollmentPeriod = enrollmentPeriod ?? this.currentlyEffectiveEnrollmentPeriod ?? tempEnrollmentPeriod;
    }
    this.currentlyEffectiveEnrollmentPeriod  = enrollmentPeriod;

    if (member && enrollmentPeriod) {
      this.memberId = member.memberId;
      this.firstName = member.firstName;
      this.middleName = member.middleName;
      this.lastName = member.lastName;
      this.emailAddress = member.emailAddress;
      this.emailNotificationsInd = member.emailNotificationInd;
      this.memberType = enrollmentPeriod.memberType;
      this.residentialAddress = member.residentialAddress;
      this.agencyCode = member.agencyCode;
      this.countyOfResidence = member.residentialAddress && member.residentialAddress.county ? member.residentialAddress.county.countyName : null;
      this.members = member.members;

      // See if the sub or any dependents have Medicare B (used for Medicare D plan name swapouts)
      const hasMedBAndMedical = (m: Member): boolean => {
        const hasMedicareB = !!m.memberMedicare?.medicarePartBEnrolledInd;

        if (!hasMedicareB)
          return false;

        const hasMedical = some(m.enrollments, (e: Enrollment) => 
          e.isActive(this.currentlyEffectiveEnrollmentPeriod.coverageEffectiveStartDate) && e.plan.planType.planTypeCode === '1'
        );

        return hasMedical;
    };

      this.subscriberOrDependentsHaveMedicareB = hasMedBAndMedical(member) ||
      some(this.members, (dep: Member) => {
        if (dep && !dep.isSubscriberInd) {
          return hasMedBAndMedical(dep);
        }

        return false;
      });

      this.effectiveEnrollments =
        filter(member.enrollments, (e: Enrollment) => dayjs(e.effectiveStartDate).isSameOrBefore(enrollmentPeriod.coverageEffectiveStartDate, 'day') && !e.effectiveEndDate) || null;

      this.accountLevelEnrollments =
        filter(member.accountLevelEnrollments, (e: Enrollment) => dayjs(e.effectiveStartDate).isSameOrBefore(enrollmentPeriod.coverageEffectiveStartDate, 'day') && !e.effectiveEndDate) || null;
      this.effectiveMedical = member.getCoverageByPlanTypeAndDate('Medical', enrollmentPeriod.coverageEffectiveStartDate);

      this.effectiveDental = member.getCoverageByPlanTypeAndDate('Dental', enrollmentPeriod.coverageEffectiveStartDate);

      this.effectiveVision = member.getCoverageByPlanTypeAndDate('Vision', enrollmentPeriod.coverageEffectiveStartDate);

      this.effectiveLife = member.getCoverageByPlanTypeAndDate('Basic Life and AD&D', enrollmentPeriod.coverageEffectiveStartDate);

      this.effectiveADD  = member.getCoverageByPlanTypeAndDate('Basic Life and AD&D', enrollmentPeriod.coverageEffectiveStartDate);

      this.accountEffectiveMedical = member.getCoverageByPlanTypeAndDate('Medical', enrollmentPeriod.coverageEffectiveStartDate);

      this.accountEffectiveDental = member.getCoverageByPlanTypeAndDate('Dental', enrollmentPeriod.coverageEffectiveStartDate);

      this.accountEffectiveVision = member.getCoverageByPlanTypeAndDate('Vision', enrollmentPeriod.coverageEffectiveStartDate);

      this.accountEffectiveLife = member.getCoverageByPlanTypeAndDate('Basic Life and AD&D', enrollmentPeriod.coverageEffectiveStartDate);

      this.accountEffectiveADD  = member.getCoverageByPlanTypeAndDate('Basic Life and AD&D', enrollmentPeriod.coverageEffectiveStartDate);

      this.effectiveSupplementalLTD = member.getSupplementalEnrollment(enrollmentPeriod, false, null, (useCurrent || isSOE));
  
      // because perspay can add supplemental coverage for any time but within OE state - need some indicator.
      this.futureSupplementalLTD =
        find(
          member.enrollments,
          (e: Enrollment) => e.plan.planType.planTypeCode === '5' && dayjs(e.effectiveStartDate).isSameOrAfter(enrollmentPeriod.coverageEffectiveStartDate, 'day') && !e.effectiveEndDate
        ) || null;

      this.effectiveMedicalPlan = this.getSummaryEffectivePlan(member, enrollmentPeriod, useCurrent, this.accountEffectiveMedical, this.effectiveMedical, this.subscriberOrDependentsHaveMedicareB);
      this.effectiveDentalPlan = this.getSummaryEffectivePlan(member, enrollmentPeriod, useCurrent, this.accountEffectiveDental, this.effectiveDental)
      this.effectiveVisionPlan = this.getSummaryEffectivePlan(member, enrollmentPeriod, useCurrent, this.accountEffectiveVision, this.effectiveVision);
      this.effectiveLifePlan = this.effectiveLife ? 'Employee Basic Life' : this.getWaivedOrNotEnrolled(this.effectiveLife, member, enrollmentPeriod);
      this.effectiveADDPlan = this.effectiveADD ? 'Employee Basic AD&D' : this.getWaivedOrNotEnrolled(this.effectiveADD, member, enrollmentPeriod);
      this.effectiveSupplementalLTDPlan = this.effectiveSupplementalLTD ? this.effectiveSupplementalLTD.plan.planName : null;

      if (member.isSelfPay && (this.effectiveMedicalPlan === this.notEligibleTxt || this.effectiveDentalPlan === this.notEligibleTxt)) {
        //this is a self pay.  Check to see if there are dependents with coverage, i.e. subscriber may have waived but elected to enroll dependents
        this.members.filter(o=>!o.isSubscriberInd).forEach(m=>{
          var dependentMedicalEnrollment: Enrollment = m.getCoverageByPlanTypeAndDate('Medical', enrollmentPeriod.coverageEffectiveStartDate);
          if (dependentMedicalEnrollment && this.effectiveMedicalPlan === this.notEligibleTxt) {
            this.effectiveMedicalPlan = dependentMedicalEnrollment.plan.planName;
          }
          var dependentDentalEnrollment: Enrollment = m.getCoverageByPlanTypeAndDate('Dental', enrollmentPeriod.coverageEffectiveStartDate);
          if (dependentDentalEnrollment && this.effectiveDentalPlan === this.notEligibleTxt) {
            this.effectiveDentalPlan = dependentDentalEnrollment.plan.planName;
          }
        });
      }

      this.medicalPremium = this.effectiveMedical
        ? this.effectiveMedical.plan.rateForSpecificDate(
            enrollmentPeriod.coverageEffectiveStartDate,
            member.getFamilyCompositionForDate(enrollmentPeriod.coverageEffectiveStartDate, this.accountEffectiveMedical.plan.planTypeId)
          )
        : null;
      this.dentalPremium = this.effectiveDental
        ? this.effectiveDental.plan.rateForSpecificDate(
            enrollmentPeriod.coverageEffectiveStartDate,
            member.getFamilyCompositionForDate(enrollmentPeriod.coverageEffectiveStartDate, this.accountEffectiveDental.plan.planTypeId)
          )
        : null;
        this.visionPremium = this.effectiveVision
        ? this.effectiveVision.plan.rateForSpecificDate(
            enrollmentPeriod.coverageEffectiveStartDate,
            member.getFamilyCompositionForDate(enrollmentPeriod.coverageEffectiveStartDate, this.accountEffectiveVision.plan.planTypeId)
          )
        : null;        
      this.lifePremium = this.effectiveLife
        ? this.effectiveLife.plan.rateForSpecificDate(
            enrollmentPeriod.coverageEffectiveStartDate,
            member.getFamilyCompositionForDate(enrollmentPeriod.coverageEffectiveStartDate, this.effectiveLife.plan.planTypeId)
          )
        : null;
      this.addPremium = this.effectiveADD
        ? this.effectiveADD.plan.rateForSpecificDate(
            enrollmentPeriod.coverageEffectiveStartDate,
            member.getFamilyCompositionForDate(enrollmentPeriod.coverageEffectiveStartDate, this.effectiveADD.plan.planTypeId)
          )
        : null;
      this.lifeBenefitAmount = this.effectiveLife ? 35000 : null;
      this.addBenefitAmount = this.effectiveADD ? 5000 : null;

      const currentSpouse =
        find(
          member.members,
          (m: Member) =>
            m.relationshipToSubscriber &&
            m.relationshipToSubscriber.relationshipType &&
            m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S' &&
            new Date() > m.relationshipToSubscriber.effectiveStartDate &&
            (new Date() < m.relationshipToSubscriber.effectiveEndDate || !m.relationshipToSubscriber.effectiveEndDate)
        ) || {};
      const spousalCoverageAttestations: Attestation[] = filter(currentSpouse.attestations, (a: Attestation) => a.attestationType.attestationTypeName === 'Spousal Surcharge' && a.isActive(enrollmentPeriod.coverageEffectiveStartDate));
      const lastSpousalAttestation: Attestation = head(reverse(sortBy(spousalCoverageAttestations, ['attestationDate'])));
      const spouse: Member = find(member.members, (m: Member) => m.relationshipToSubscriber.relationshipType && m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S');
      this.spousalCoverageSurchargeInd =
        lastSpousalAttestation &&
        lastSpousalAttestation.response.responseName === 'Yes' &&
        spouse.getCoverageByPlanTypeAndDate('Medical', this.currentlyEffectiveEnrollmentPeriod.coverageEffectiveStartDate)
          ? true
          : false;

      //check for active medicare a or b - if active self pay and active medicare, tobacco and spousal attestations are $0.
      this.medicareDate = this.currentlyEffectiveEnrollmentPeriod.coverageEffectiveStartDate ? this.currentlyEffectiveEnrollmentPeriod.coverageEffectiveStartDate : new Date();
      this.hasMedicareAorB = this.memberMedicare && 
      ((this.memberMedicare.medicarePartAEffectiveDate && this.memberMedicare.medicarePartAEffectiveDate <= this.medicareDate
        && (!this.memberMedicare.medicarePartAEndDate || this.memberMedicare.medicarePartAEndDate>this.medicareDate)) || 
      (this.memberMedicare.medicarePartBEffectiveDate && this.memberMedicare.medicarePartBEffectiveDate <= this.medicareDate
        && (!this.memberMedicare.medicarePartBEndDate || this.memberMedicare.medicarePartBEndDate>this.medicareDate)))
      ? true : false;

      // check for default if sub has a spouse;
      const subHasValidSpouse = this.subHasValidActiveSpouse(member);
      this.spousalCoverageSurcharge = this.hasMedicareAorB && this.isSelfPay ? 0 : 
        this.spousalCoverageSurchargeInd ? 50 : subHasValidSpouse && !lastSpousalAttestation && 
          spouse.getCoverageByPlanTypeAndDate('Medical', this.currentlyEffectiveEnrollmentPeriod.coverageEffectiveStartDate) ? 50 : 0;
      this.memberSummary = map(member.members, (m: Member) => {
        var depEnrollmentPeriod: EnrollmentPeriod = cloneDeep(enrollmentPeriod);
        if (isSOE && m.birthDate>depEnrollmentPeriod.coverageEffectiveStartDate && soeTypeName === 'Birth or adoption' && m.relationship.isPendingRelationship) {
          depEnrollmentPeriod.coverageEffectiveStartDate = m.birthDate; 
        }
        return new MemberSummary(m, this.memberId, depEnrollmentPeriod);
      });
      this.waiveablePlanTypes = member.waivablePlanTypes;
      this.electablePlanTypes = enrollmentPeriod.electablePlanTypes;
      this.effectiveMedicalPlanId = this.effectiveMedical ? this.effectiveMedical.planId : null;
      this.effectiveDentalPlanId = this.effectiveDental ? this.effectiveDental.planId : null;
      this.effectiveVisionPlanId = this.effectiveVision ? this.effectiveVision.planId : null;      
      this.effectiveLifePlanId = this.effectiveLife ? this.effectiveLife.planId : null;
      this.effectiveADDPlanId = this.effectiveADD ? this.effectiveADD.planId : null;
      this.tobaccoUseSurcharge = this.getSubTobaccoSurcharge(useLatestAttestationWhenNotFound);
      this.totalCost = (this.medicalPremium || 0) + (this.dentalPremium || 0) + this.spousalCoverageSurcharge + (this.lifePremium || 0) + (this.addPremium || 0) + (this.tobaccoUseSurcharge || 0);
      this.currentEmployer = member.organization ? member.organization.organizationName : '';

      // dual enrollment
      this.dependentAgencyDualEnrollMedical = some(
        member.externalEnrollments,
        (ee: ExternalEnrollment) => 
          ee.planType.planTypeName === 'Medical' &&
          !ee.isSubscriberInd &&
          ee.agency?.agencyName === member.organization?.agency?.agencyCode &&
          dayjs(enrollmentPeriod.coverageEffectiveStartDate).isBetween(ee.effectiveStartDate, (ee.effectiveEndDate || enrollmentPeriod.coverageEffectiveStartDate), null, '[]')
      );

      this.dependentOtherAgencyDualEnrollMedical = some(
        member.externalEnrollments,
        (ee: ExternalEnrollment) => 
          ee.planType.planTypeName === 'Medical' &&
          !ee.isSubscriberInd &&
          ee.agency?.agencyName !== member.organization?.agency?.agencyCode &&
          dayjs(enrollmentPeriod.coverageEffectiveStartDate).isBetween(ee.effectiveStartDate, (ee.effectiveEndDate || enrollmentPeriod.coverageEffectiveStartDate), null, '[]')
      );

      this.dependentDEMedical = this.dependentAgencyDualEnrollMedical || this.dependentOtherAgencyDualEnrollMedical;

      this.subscriberDualProgramEnrolled = some(
        member.externalEnrollments,
        (ee: ExternalEnrollment) => 
          ee.isSubscriberInd &&
          ee.agency?.agencyName != member.organization?.agency?.agencyCode &&
          dayjs(enrollmentPeriod.coverageEffectiveStartDate).isBetween(ee.effectiveStartDate, (ee.effectiveEndDate || enrollmentPeriod.coverageEffectiveStartDate), null, '[]')
      );

      this.subscriberDualProgramEnrolledMedical = some(
        member.externalEnrollments,
        (ee: ExternalEnrollment) => 
          ee.planType.planTypeName === 'Medical' &&
          ee.isSubscriberInd &&
          ee.agency?.agencyName != member.organization?.agency?.agencyCode &&
          dayjs(enrollmentPeriod.coverageEffectiveStartDate).isBetween(ee.effectiveStartDate, (ee.effectiveEndDate || enrollmentPeriod.coverageEffectiveStartDate), null, '[]')
      );

      if (member.dualEnrolledInOtherAgency) {
        this.otherAgencyCode = this.agencyCode === env.pebbCode ? env.sebbCode : env.pebbCode;
      }

      if (this.dependentAgencyDualEnrollMedical || this.dependentOtherAgencyDualEnrollMedical || this.subscriberDualProgramEnrolledMedical) {
        this.DEMessageAccepted = false;
        this.deMedicalAny = true;
      }

      this.retireeTermLifeEnrolledInd = some(this.effectiveEnrollments,
        (e: Enrollment) =>
          e.plan?.planType?.planTypeCode === '10' &&
          dayjs(enrollmentPeriod.coverageEffectiveStartDate).isSameOrAfter(e.effectiveStartDate) &&
          (!e.effectiveEndDate || dayjs(e.effectiveEndDate).isAfter(dayjs()))
        );

      this.basicLifeEnrolledInd = !!this.effectiveLifePlanId;

      if (member.lossOfEligibilityDate && useCurrent) {
        //termed subscriber viewing current medical coverage, set premiums to 0.
        this.medicalPremium = 0;
        this.tobaccoUseSurcharge = member.isSelfPay && this.tobaccoUseSurcharge > 0 ? 25 : 0; //self pay and has attestation, show surcharge
        this.spousalCoverageSurcharge = 0;
        this.dentalPremium = 0;
        this.visionPremium = 0;
        this.totalCost =  member.isSelfPay && this.tobaccoUseSurcharge > 0 ? 25 : 0; //self pay and has attestation, show surcharge
      } 
    }
  }

  getWaivedOrNotEnrolled(enrollment: Enrollment, member: Subscriber, oe): string {
    if (member.lossOfEligibilityDate && dayjs(member.lossOfEligibilityDate).isSameOrBefore(dayjs(oe.coverageEffectiveStartDate), 'day')) {
      return this.notEligibleTxt;
    } else {
      return 'Waived';
    }
  }

  subHasValidActiveSpouse(member: Subscriber): boolean {
    const now = new Date();
    return some(
      member.members,
      (m: Member) =>
        m.relationshipToSubscriber &&
        m.relationshipToSubscriber.relationshipType &&
        m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S' &&
        now > m.relationshipToSubscriber.effectiveStartDate &&
        (now < m.relationshipToSubscriber.effectiveEndDate || !m.relationshipToSubscriber.effectiveEndDate)
    );
  }

  anyDependentsUseTobacco(): boolean {
    return some(
      filter(this.members, (m: Member) => !m.isSubscriberInd),
      (m: Member) => m.usesTobaccoForEffectiveDate(this.currentlyEffectiveEnrollmentPeriod.coverageEffectiveStartDate)
    );
  }

  getSubTobaccoSurcharge(useLatestAttestationWhenNotFound = true): number {
    // subscriber has medical coverage for date, first of all:
    if (this.effectiveMedical && (!this.hasMedicareAorB || !this.isSelfPay)) {
      // if any member uses tobacco
      if (some(this.members, (m: Member) => m.usesTobaccoForEffectiveDate(this.currentlyEffectiveEnrollmentPeriod.coverageEffectiveStartDate, useLatestAttestationWhenNotFound))) {
        return 25;
      }
    }
    return 0;
  }

  getSummaryEffectivePlan(member: Subscriber, enrollmentPeriod: EnrollmentPeriod, useCurrent, accountEnrollment: Enrollment, effectiveEnrollment: Enrollment, subscriberOrDependentsHaveMedicareB: boolean = false) {
    if (useCurrent && ((member.isSelfPay && member.memberSelfPayOrigin?.agencyEffectiveEndDate!=null && member.memberSelfPayOrigin?.agencyEffectiveEndDate<enrollmentPeriod.coverageEffectiveStartDate) || 
      (member.isSelfPay==false && member.lossOfEligibilityDate<enrollmentPeriod.coverageEffectiveStartDate))) {
      return "waived/not currently enrolled";    
    }
    else {
      const getPlanName = p => ((this.medicareDEnabled && subscriberOrDependentsHaveMedicareB && this.isSelfPay && !this.isLWOP && p?.medicareDPlanName) ? p?.medicareDPlanName : p?.planName) ?? p?.planName;
      const plan: Plan = accountEnrollment?.plan;
      const planName = getPlanName(plan);

      return planName ?? this.getWaivedOrNotEnrolled(effectiveEnrollment, member, enrollmentPeriod);
    }
    
  }
}
