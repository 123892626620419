import Attestation from './attestation';

export default class Answer {
  answerId: string;
  memberId: string;
  questionId: string;
  responseId: string;
  answerDate: Date;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  attestationId?: string;
  attestation: Attestation;

  constructor(answer?) {
    if (answer) {
      this.answerId = answer.answerId;
      this.memberId = answer.memberId;
      this.questionId = answer.questionId;
      this.responseId = answer.responseId;
      this.answerDate = answer.answerDate ? new Date (answer.answerDate) : null;
      this.createdDate = answer.createdDate || null;
      this.createdById = answer.createdById || null;
      this.modifiedDate = answer.modifiedDate || null;
      this.modifiedById = answer.modifiedById || null;
      this.attestationId = answer.attestationId || null;
      this.attestation = answer.attestation ? new Attestation(answer.attestation) : null;
    }
  }
}
