<form (ngSubmit)="submitDependent()" *ngIf="dependent" #memberForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
  <div fxFlexFill class="fxGap-large" fxLayout="column">
    <h2 style="text-align: center">Benefits elections</h2>
    <ngb-alert type="danger" [dismissible]="false" *ngIf="dentalOpportunityOnly"
      >You cannot add or remove medical coverage for this dependent unless you have a special open enrollment event. Dental {{visionAvailable ? ' and vision ' : ''}}coverage can be added to existing dependents for this event. You can find
      more information on special open enrollment events
      <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/change-your-coverage#Special-open-enrollment" target="_blank">here</a>.</ngb-alert
    >
    <ngb-alert type="danger" [dismissible]="false" *ngIf="DEOther">
      The dependent currently has coverage in our {{otherAgency}} program and cannot be included in the {{currentAgency}} program again as a dependent. Correct dependent demographics to move forward.
    </ngb-alert>
    <ngb-alert type="danger" [dismissible]="false" *ngIf="DECurrentMedDen">
      The dependent currently exists in the {{currentAgency}} program with medical and dental coverages and cannot be included in the {{currentAgency}} program again as a dependent. Correct dependent demographics to move
      forward.
    </ngb-alert>
    <ngb-alert type="danger" [dismissible]="false" *ngIf="DECurrentMed">
      The dependent currently exists in the {{currentAgency}} program with medical coverage and can only elect for dental coverage. Update dependent elections or correct their demographics to move forward.
    </ngb-alert>
    <ngb-alert type="danger" [dismissible]="false" *ngIf="DECurrentDen">
      The dependent currently exists in the {{currentAgency}} program with dental coverage and can only elect for medical coverage. Update dependent elections or correct their demographics to move forward.
    </ngb-alert>  
    <ngb-alert type="danger" [dismissible]="false" *ngIf="DEPEBBMedMedOnly">
      The dependent currently exists in the {{currentAgency}} program with medical coverage and cannot be included in the {{currentAgency}} program again as a dependent. Correct dependent demographics to move forward.
    </ngb-alert>
    <ngb-alert type="info" [dismissible]="false" *ngIf="hasMed && subscriberCurrentlyWaivedMedical && !isSelfPay">
      Medical coverage for this subscriber is currently waived. If medical coverage is elected for a dependent, the subscriber will be assigned the default plan to enroll in medical coverage.  This default plan assignment can be changed in the coverage section to any available plan.
    </ngb-alert>
    <div fxLayout="row" fxFlexFill style="width: 400px" fxLayoutAlign="space-around" class="info-container">
      <h3 class="h4 no-mg">Which benefits would you like to enroll this dependent in?</h3>
      <div style="width: 400px;" class="mgtp-x-small" fxLayoutAlign="space-around center" fxLayout="row">
        <div fxLayoutAlign="space-between center" *ngFor="let planType of electablePlanTypes">
          <div fxLayoutAlign="space-between center" class="k-form-field bigger mgbt-small mgtp-small">
            <checkbox
              [disabled]="(planType.planTypeName === 'Medical' && dentalOpportunityOnly) || allPlanTypesDisabled"
              bold
              [name]="planType.planTypeName"
              [(ngModel)]="planTypesSelected[planType.planTypeId]"
              (ngModelChange)="updatePlanTypes(planType.planTypeId)"
              [label]="planType.planTypeName"></checkbox>
          </div>
        </div>
      </div>
    </div>
    <ngb-alert type="danger" [dismissible]="false" *ngIf="selfPayMissingCoverage">
      No plan has been selected associated with this coverage type.
      <ul>
        <li *ngIf="subscriberCurrentlyWaivedMedical && hasMed && !hasAccountLevelMed">Medical coverage</li>
        <li *ngIf="subscriberCurrentlyWaivedDental && hasDental && !hasAccountLevelDental">Dental coverage</li>
        <li *ngIf="subscriberCurrentlyWaivedVision && hasVision && !hasAccountLevelVision">Vision coverage</li>
      </ul>
      To enroll this dependent, go back to the coverage section, select a valid plan, and then elect coverage for this dependent
    </ngb-alert>
    <!-- We are in an soe -->
    <div *ngIf="soe || isSelfPay">
      <!-- and adding coverage -->
      <div *ngIf="enrollAdded">
        <span *ngIf="soe && soe?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName !== 'Birth or adoption' && dependent.relationshipTypeId == childRelationshipTypeId"
          >Enrollment effective date based on effective date entered for event: <b>{{ soe?.coverageEffectiveDate ? (soe?.coverageEffectiveDate | date: 'MM/dd/yyyy') : '' }}</b></span
        >
        <div fxLayout="column" fxLayoutAlign="" *ngIf="((isSelfPay && isSPSOE) || (soe?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName === 'Birth or adoption' &&
          dependent.relationshipTypeId == childRelationshipTypeId)) && dependent.relationshipTypeId !== spousalRelationshipTypeId">
          <div *ngIf="isSelfPay">
            <checkbox name="isNewborn" bold [(ngModel)]="isNewborn">Newborn dependent</checkbox>
          </div>
          <div *ngIf="dependent.relationshipTypeId == childRelationshipTypeId">
            <checkbox name="isAdopted" bold [(ngModel)]="isAdopted">Adopted dependent</checkbox>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <span fxFlex="45">Based on the date of birth entered for this dependent, it is assumed they are a newborn and coverage will be effective on their date of birth.  If this is an adopted dependent please check the "Adopted dependent" checkbox and coverage will be effective on the event date entered. </span>
            <!-- <div fxFlex="45">
              <form #adoptionDateForm="ngForm">
                <div class="form-group">
                  <date-picker
                    #adoptionDate="ngModel"
                    name="adoptionDate"
                    label="Adoption Date"
                    required
                    [lockToMonth]="isSelfPay ? enrollmentPeriod.coverageEffectiveStartDate : soe.eventDate"
                    [(ngModel)]="dependent.adoptionDate"></date-picker>
                  <div *ngIf="adoptionDateForm.controls.adoptionDate?.errors?.ngbDate?.minDate" class="alert alert-danger">Adoption date must be within the month of the event.</div>
                  <div *ngIf="adoptionDateForm.controls.adoptionDate?.errors?.ngbDate?.maxDate" class="alert alert-danger">Adoption date must be within the month of the event.</div>
                </div>
              </form>
            </div> -->
          </div>
        </div>
      </div>
      <div *ngIf="enrollRemoved && effectiveEnd && dependent.memberId" fxLayout="column">
        <span>
          Coverage end date based on effective date entered for event: <b>{{ effectiveEnd | date: 'MM/dd/yyyy' }}</b>
        </span>
        <div class="mgtp-medium" fxFill fxLayout="row" fxLayoutAlign="end" *ngIf="soe?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName === 'HCA OE Correction' || isSelfPay">
          <label fxFlex="20" for="removalReasonId">Removal reason:</label>
          <select fxFlex="60" required class="form-control" #removalReason="ngModel" name="removalReasonId" [(ngModel)]="dependent.terminationReasonId" (ngModelChange)="removalReasonChange()">
            <option></option>
            <option *ngFor="let reason of lookups.removalReasons | isObsolete" [ngValue]="reason.reasonId">
              {{ reason.reasonName }}
            </option>
          </select>
        </div>
        <div class="mgtp-medium" *ngIf="dependent.terminationReasonId === divorceId || dependent.terminationReasonId === deathId">
          No coverage can remain intact based on the reason selected.  All coverages for this dependent will be ended if you proceed.
        </div>
      </div>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="space-around">
      <button *ngIf="!dependent?.memberId" fxFlex="30" id="sub-add-cancel-btn" class="btn btn-secondary" (click)="cancelDependentAdd.emit()">Cancel adding dependent</button>
      <button fxFlex="30" id="sub-add-submit-btn" type="submit" [disabled]="selfPayMissingCoverage" class="btn btn-primary">{{ nextText }}</button>
    </div>
  </div>
</form>
