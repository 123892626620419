export const env = {
    production: true,
    hmr: false,
    version: '#{Octopus.Release.Number}',
    apiUrl: '#{WebAPIUrl}',
    idpUrl: '#{IdpUrl}',
    idpMFAUrl: '#{IdpMFAUrl}',
    environment: '#{Octopus.Environment.Name}',
    idpEADUrl: '#{IdpEADUrl}',
    stateCodes: ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA',
    'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC',
    'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD',
    'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'AB', 'BC', 'MB', 'NB', 'NL',
    'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT', 'AA', 'AE', 'AP'],
    caProvinceCodes: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT', 'AA', 'AE', 'AP'],
    accessLists: {
      read: ['Read Only', 'Edit', 'Admin', 'System Admin'],
      edit: ['Edit', 'Admin', 'System Admin'],
      admin: ['Admin', 'System Admin'],
      sysAdmin: ['System Admin']
    },
    oeOpenForAdminsOnly: false,
    lowerLimitActiveForUpdates: true,
    milestoneMap: {
      Dependents: {
        icon: 'faUsers',
        state: '/dependents/',
      },
      Coverage: {
        icon: 'faUmbrella',
        state: '/coverage/',
      },
      Upload: {
        icon: 'faUpload',
        state: '/upload/',
      },
      Attestations: {
        icon: 'faLungs',
        state: '/attest/',
      },
      'Supplemental Benefits': {
        icon: 'faStaffSnake',
        state: '/supplemental/',
      },
      Confirmation: {
        icon: 'faCheck',
        state: '/confirmation/',
      },
    },
    rtToQrMap: {
      S: ['M', 'P'],
      C: ['-'],
      P: ['-'],
      F: ['D'],
    },
    rtToQrMap26: {
      S: ['M', 'P'],
      C: ['A'],
      P: ['A'],
      F: ['A'],
    },
    carrier834TransactionTypes: [
      {Code: 'A',Name: 'Add'},
      {Code: 'C',Name: 'Change'},
      {Code: 'P',Name: 'Plan Change'},
      {Code: 'T',Name: 'Term'},
      {Code: 'X',Name: 'Never Eligible'},
    ],
    medicalPlanTypeCode: '1',
    dentalPlanTypeCode: '2',
    visionPlanTypeCode: '6',
    emailRegex: '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$',
    basicLifePlanTypeCode: '3',
    basicLTDPlanTypeCode: '4',
    basicSLTPPlanTypeCode: '5',
    planTypesToDisplay: ['1', '2', '6'],
    codesOnSecondPage: ['3', '4', '5'],
    memberTypesToHidePremiumsFor: ['X'],
    orgTypesToDisableEditFor: ['Higher Education'],
    subscriberUserTypeCode: 'M',
    newFullBenefitsEnrollmentPeriodType: 'TE',
    dentalWaivePlanCode: 'DEWD',
    cobraMemberTypes: ['G', 'D', 'C', 'T', 'ST', 'SC', 'SB'],
    unpaidLeaveMemberTypes: ['S', 'E', 'P', 'SS', 'SE'],
    retireeMemberTypes: ['R', 'K', 'Q', 'H'],
    pebbEligbileMemberTypes: ['X', 'Y'],
    facultySeasonalReasonCode: '12',
    cobraReasons: ['31', '32', '36'],
    unpaidReasons: ['33', '34', '38', '53', '50', '51', '32'],
    termSebbReasonCodes: ['31','33','34','35','36','38','51', '99', 'NE'],
    termReasonsManagedByHca: [ '37' ],
    termReasonsUnavailableForSelfPay: [ '37' ],
    portCommissionerOrgCodes: [
      '900 D30',
      '900 B87',
      '931',
      '900 A04',
      '916',
      '955',
      '900 B13',
      '934',
      '906 ',
      '900 B52',
      '923',
      '953',
      '988',
      '930',
      '950',
      '949',
      '900 B32',
      '900 C91',
      '985',
      '900 C51',
      '900 D41',
      '900 D41',
      '951',
      '970',
      '939',
      '900 B17',
      '961',
      '900 B85',
    ],
    notEnrolledText: 'NOT ENROLLED',
    defaultMedicalPlanCode: 'Z',
    defaultDentalPlanCode: '9',
    defaultVisionPlanCode: 'TZ',
    medicalPlanTypeName: 'Medical',
    dentalPlanTypeName: 'Dental',
    visionPlanTypeName: 'Vision',    
    invalidSSNValues: ['111111111', '222222222', '333333333', '444444444', '555555555', '666666666', '777777777', '888888888', '999999999', '123456789', '00000000'],
    ssnItinRegex: /^((?!219[- ]?09[- ]?9999|078[- ]?05[- ]?1120)(?!666|000|9\d{2})\d{3}[- ]?(?!00)\d{2}[- ]?(?!0{4})\d{4})$|^(?:(?!900|999)(?:9\d{2})[- ]?\d{2}[- ]?(?:\d{4}))$|^900[- ]?[7-9]\d[- ]?\d{4}$|^999[- ]?(?!89)[0-8]\d[- ]?\d{4}$/,
    availableBSCodes: ['MO', 'FB'],
    ssnMask: '000-00-0000',
    deltaCareExternal: 'https://www.deltadentalwa.com/tools-and-resources/find-a-dentist',
    uniformExternal: 'https://www.deltadentalwa.com/tools-and-resources/find-a-dentist#plans=PPO&mode=1',
    willametteExternal: 'https://locations.willamettedental.com/',
    selfpayExemptReasonCodes: ['39', '46', '45' ],
    sendTimeZoneHeaders: '#{SendTimeZoneHeaders}',
    eligibilityReasonCodes: {
      pebb: ['01', '03', '04', '05', '10', '12', '13'],
      sebb: ['01', '05', '13']
    },
    visionSelectionMessage: 'You have selected vision coverage. Please make sure that your preferred vision provider is in your selected plan’s network. If you have selected this vision plan in error and do not make a change by <end date of OE or NE as applicable in the wizard>, you will not be eligible to change your vision plan until the next annual open enrollment period or as allowed due to a special open enrollment event.',
    pebbName: "Public Employees Benefits Board",
    sebbName: "School employee benefits board",
    pebbCode: "PEBB",
    sebbCode: "SEBB",
    availableActionEnrollDependentsCode: 'ED',
    availableActionWaiveCode: 'W',
    availableActionReturnFromWaivedCode: 'RFW',
    availableActionElectMedicalCode: 'CMP',
    availableActionElectDentalCode: 'CDP',
    availableActionElectVisionCode: 'CVP',
    cobraSelfPayTypeCodes: ['C','OEC','SOEC'],
    lwopSelfPayTypeCodes: ['U','OEU', 'SOEU'],
    selfPayRetireeRequestCodes: ['R', 'OER', 'SOER'],
    soeSelfPayTypeCodes: ['SOE', 'SOEU', 'SOER', 'SOEC', 'SOEA'],
    oeSelfpayTypeCodes: ['OER','OEC','OEU'],
    soeSelfPayTypeCodesFormRequired: ['SOEA'],
    selfPayApprovedPendingFirstPaymentCode: 'APF',
    soeVerificationStatusPendingCode: 'R',
    sebbMemberTypeCodes: ['Z', 'W', 'SC', 'SS', 'SE', 'P', 'ST'],
    mailingAddressTypeCode: 'M',
    billingAddressTypeCode: 'B',
    shippingAddressTypeCode: 'S',
    residentialAddressTypeCode: 'R',
    physicalAddressTypeCode: 'P',
    datePickerDebugInfo: '#{DatePicker_EnableDebug}',
    soeTypesWithCustomDescriptions: ['COC','MDP'],
    soeTypesWithCustomDocTypeInstructions: ['GLEMC','MDP','NEED'],
    retirementPlans: [
      "Public Employees' Retirement System Plan (PERS) 1 ",
      "Public Employees' Retirement System Plan (PERS) 2 ",
      "Public Employees' Retirement System Plan (PERS) 3 ",
      "Public Safety Employees' Retirement System (PSERS) 2",
      "Teachers Retirement System (TRS) 1",
      "Teachers Retirement System (TRS) 2",
      "Teachers Retirement System (TRS) 3",
      "Washington Higher Education Retirement Plan (HERP) (for example, TIAA-CREF)",
      "School Employees' Retirement System (SERS) 2",
      "School Employees' Retirement System (SERS) 3",
      "Law Enforcement Officers' and Fire Fighters' Retirement System (LEOFF) 1",
      "Law Enforcement Officers' and Fire Fighters' Retirement System (LEOFF) 2",
      "Washington State Patrol Retirement System (WSPRS) 1",
      "Washington State Patrol Retirement System (WSPRS) 2",
      "State Judges/Judicial Retirement System",
      "Civil Service Retirement System and Federal Employees' Retirement System (for Washington State University Extension employees covered under PEBB benefits at the time of retirement) "
    ],
    medicareAdvantagePlanCodes: ['D', 'CMED', 'MB', 'MC'],
    emptyGUIDString: '00000000-0000-0000-0000-000000000000',
    disableLogoutTimeout: '#{DisableLogoutTimeout}',
    accountCorrectionAccessList: ['Any'],
    allowFutureDeceasedDates: true
  };
