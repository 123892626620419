import { Component, ViewEncapsulation, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'lookups-search',
  templateUrl: 'lookups.search.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class LookupsSearchComponent implements OnInit {
  @Input() filterString: string;
  @Output() filterStringChange: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  ngOnInit() {
  }

  change(newValue) {
    this.filterString = newValue;
    this.filterStringChange.emit(newValue);
  }
}
