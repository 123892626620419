<div fxLayout="column" fxLayoutAlign="start start">
  <!-- SUB NAME/SSN -->
  <div fxLayout="row" fxLayoutAlign="end end" fxFlexFill>
    <div fxLayout="column" fxFlex="60" fxLayoutAlign="end end" class="fxGap-medium">
      <div fxLayoutAlign="space-between" fxFlexFill>
        <div fxFlex="50">
          <div><b>Subscriber:</b></div>
        </div>
        <div fxFlex="50">
          <span> {{ selfPay?.formJson?.subscriber?.lastName }}, {{ selfPay?.formJson?.subscriber?.firstName }} </span>
        </div>
      </div>
      <div fxLayoutAlign="space-between" fxFlexFill>
        <div fxFlex="50">
          <b>SSN:</b>
        </div>
        <div fxFlex="50">
          <span> {{ selfPay?.formJson?.subscriber.socialSecurityNumber }} </span>
        </div>
      </div>
    </div>
  </div>
  <!-- Request DETAILS -->
  <div fxLayout="row" fxFlexFill class="mgbt-medium">
    <div fxLayout="column" fxFlex="70">
      <h5 style="text-decoration: underline; font-weight: 600">Request Details</h5>
      <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="30">
        <div fxLayoutAlign="space-between">
          <b fxFlex="30">Event:</b>
          <span fxFlex="50"> {{ selfPay?.selfPayType?.selfPayTypeName }} </span>
        </div>
        <div fxLayoutAlign="space-between">
          <b fxFlex="30">Date Submitted:</b>
          <span fxFlex="50"> {{ selfPay?.submittedDate | date: 'MM/dd/yyyy' }}</span>
        </div>
        <div fxLayoutAlign="space-between" *ngIf="selfPay.specialOpenEnrollmentTypeId !== null">
          <b fxFlex="30">Special Open Enrollment Type:</b>
          <span fxFlex="50"> {{ selfPay?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName }}</span>
        </div>
      </div>
    </div>
  </div>
  <!--PAYMENT OPTIONS-->
  <div fxLayout="row" fxFlexFill class="mgbt-medium">
    <div fxLayout="column">
      <h5 style="text-decoration: underline; font-weight: 600">Payment Options</h5>
      <div fxLayout="row wrap" fxLayoutAlign="space-around">
        <div fxFlex="50" *ngFor="let option of selfPayPaymentTypes">
          <span *ngIf="!(option.pmaPaymentTypeCode==='U' && option.pmaPaymentTypeCode !== selfPay.selfPayPaymentType?.pmaPaymentTypeCode)">
            <checkbox
              disabled readOnly bold
              [name]="'po_' + option.pmaPaymentTypeCode"
              [checked]="(option.pmaPaymentTypeCode === selfPay.selfPayPaymentType?.pmaPaymentTypeCode)"
              [label]="option.selfPayPaymentTypeName"></checkbox>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!--Qualify reason-->
  <div fxFlex="40" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'U'">
    <h5 style="text-decoration: underline; font-weight: 600">Qualifying event (checked on form)</h5>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'U'" fxFlexFill  class="mgbt-medium">
    <div fxFlex="30">
      <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'ADR'">Applying for disability retirement</checkbox>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'L'">Layoff</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'R'">Reversion</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'ALWOP'">Approved Leave Without Pay (LWOP)</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'WC'">Workers' compensation</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'AEL'">Approved educational leave</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'FBPE'">Faculty between periods of eligibility</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'SEOS'">Seasonal employee off-season</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'EADA'">Employee appealing a dismissal action</checkbox>
      </div>
    </div>
    <div fxFlex="30">
      <div>
        <checkbox disabled [checked]="selfPay.formJson.selfPayReason == 'USERRA'">USERRA (military) leave</checkbox>
      </div>
      <div>
        <span>Date called to duty in the uniformed services: {{ selfPay.formJson.dateOfService | date: 'MM/dd/yyyy' }}</span>
      </div>
    </div>
  </div>
  <!-- REQUESTED CHANGES   -->
  <div fxLayout="row" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'" fxFlexFill class="mgbt-medium">
    <div fxLayout="column" fxFlexFill>
      <h5 style="text-decoration: underline; font-weight: 600">Actions selected</h5>
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill class="fxGap-column-medium">
        <div fxFlex="40">
          <checkbox disabled readonly bold name="actionEnrolling" [checked]="selfPay.formJson.subscriber.actionsCode === 'enrolling'">Enrolling</checkbox>
        </div>
        <div fxFlex="40">
          <checkbox disabled readonly bold name="actionDeferring" [checked]="selfPay.formJson.subscriber.actionsCode === 'deferring'">Deferring</checkbox>
        </div>
        <div fxFlex="40">
          <checkbox disabled readonly bold name="actionAfter" [checked]="selfPay.formJson.subscriber.actionsCode === 'enrollAfterDefer'">
            Enroll after deferring, qualifying coverage ended {{ selfPay.formJson?.subscriber.enrollAfterDeferDateInitial | date: 'MM/dd/yyyy' }}
          </checkbox>
        </div>
        <div fxFlex="40">
          <checkbox disabled readonly bold name="actionSeparating" [checked]="selfPay.formJson.subscriber.actionsCode === 'seperating'">
            Separating as of {{ selfPay.formJson?.subscriber.seperatingDate | date: 'MM/dd/yyyy' }}
          </checkbox>
        </div>
      </div>
      <div fxFlex="100" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
          <b fxFlex="25">Retirement Plan:</b>
          <span fxFlex="70"> {{ selfPay?.formJson?.subscriber?.retirementPlan }}</span>
        </div>    
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
          <b fxFlex="25">Retirment Date:</b>
          <span fxFlex="70"> {{ selfPay?.formJson?.subscriber?.retirementDate | date: 'MM/dd/yyyy'  }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
          <b fxFlex="25">Requested coverage effective date:</b>
          <span fxFlex="70"> {{ selfPay?.formJson?.subscriber?.coverageEffectiveDate | date: 'MM/dd/yyyy'  }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="page-break"></div>
  <!--subscriber info form-->
  <div fxLayout="column" fxFlexFill class="mgbt-medium fxGap-small">
    <div fxFlex="40">
      <h5 style="text-decoration: underline; font-weight: 600">Subscriber Information from Form</h5>
    </div>
    <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">Name:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.lastName }}, {{ selfPay?.formJson?.subscriber?.firstName }} </span>
      </div>
      <div fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">Home Phone:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber.homePhoneNumber }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around" fxFlexFill>
      <div fxLayoutAlign="space-between" fxFlex="50">
        <div fxFlex="40"><b>Represented?</b></div>
        <div fxFlex="60"><checkbox disabled readonly name="represented" [checked]="selfPay.formJson.subscriber.isRepresentedInd"></checkbox></div>
      </div>
      <div fxFlex="50">
        <div fxLayoutAlign="space-between" fxFill>
          <div fxFlex="40"><b>Effective Date:</b></div>
          <div fxFlex="60"><span> {{ selfPay.formJson.subscriber?.representedDate | date: 'MM/dd/yyyy' }}</span></div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">SSN:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.socialSecurityNumber }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">Work Phone:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber.workPhoneNumber }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">Middle Name:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.middleName }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">DOB:</b>
        <span fxFlex="60"> {{ selfPay.formJson.subscriber?.birthDate | date: 'MM/dd/yyyy' }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">Suffix:</b>
        <span fxFlex="60"> {{ selfPay.formJson.subscriber?.suffix }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="60">Sex assigned at birth:</b>
        <span fxFlex="40"> {{ birthSex?.birthSexName }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="60">Gender Identity:</b>
        <span fxFlex="40"> {{ genderIdentity?.genderIdentityName }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="column" fxFlexFill fxLayoutAlign="start start" class="mgtp-medium">
        <h5 style="text-decoration: underline; font-weight: 600">Subscriber Address Information</h5>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill class="fxGap-large">
      <div fxFlexfill fxLayoutAlign="center center">
        <span><b>Residential address</b></span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <b fxFlex="40">Street address:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.residentialAddress?.addressLineOne }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <b fxFlex="40">Address line 2:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.residentialAddress?.addressLineTwo }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
        <b fxFlex="40">City:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.residentialAddress?.city }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
        <b fxFlex="40">State:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.residentialAddress?.state }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
        <b fxFlex="40">Zip Code:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.residentialAddress?.zipcodeNumber }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill  class="fxGap-large">
      <div fxFlexfill fxLayoutAlign="center center">
        <span><b>Mailing address</b></span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">Street address:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.mailingAddress?.addressLineOne }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
        <b fxFlex="40">Address line 2:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.mailingAddress?.addressLineTwo }}</span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
        <b fxFlex="40">City:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.mailingAddress?.city }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
        <b fxFlex="40">State:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.mailingAddress?.state }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
        <b fxFlex="40">Zip Code:</b>
        <span fxFlex="60"> {{ selfPay?.formJson?.subscriber?.mailingAddress?.zipcodeNumber }}</span>
      </div>
    </div>
  </div>
  <div class="page-break"></div>
  <div fxLayout="column" fxFlexFill class="mgbt-medium">
    <div fxFlex="40">
      <h5 style="text-decoration: underline; font-weight: 600">Plan(s) selected</h5>
    </div>
    <!--Medical plan-->
    <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
      <b fxFlex="15">Medical:</b>
      <span fxFlex="70"> {{ selectedMedicalPlans }}</span>
    </div>
    <!--Dental plan-->
    <div fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
      <b fxFlex="15">Dental:</b>
      <span fxFlex="70"> {{ selectedDentalPlan?.planName }}</span>
    </div>
    <!--vision plan-->
    <div *ngIf="selectedVisionPlan && (selfPay?.agency?.agencyCode === 'SEBB' || isPebbLwopVision || selfPay.selfPayType.selfPayTypeCode === 'OER' || selfPay.selfPayType.selfPayTypeCode === 'OEC' || selfPay.selfPayType.selfPayTypeCode === 'C' || selfPay.selfPayType.selfPayTypeCode === 'R')" fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
      <b fxFlex="15">Vision:</b>
      <span fxFlex="70"> {{ selectedVisionPlan?.planName }}</span>
    </div>

    <div fxLayout="column" fxFlexFill fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'U'"  class="mgtp-medium">
      <h5 style="text-decoration: underline; font-weight: 600">Medicare</h5>
    </div>
    <!--Medicare-->
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'U'">
      <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="50">
          <checkbox disabled readonly bold name="medicarePartA" [checked]="selfPay.formJson.subscriber.medicarePartAInd">Part A (hospital)</checkbox>
        </div>
        <div fxFlex="50">
          <b>Effective date: {{ selfPay.formJson?.subscriber.medicarePartADate | date: 'MM/dd/yyyy' }}</b>
        </div>
      </div>
      <div fxFlex="40" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="50">
          <checkbox disabled readonly bold name="medicarePartD" [checked]="selfPay.formJson.subscriber.medicarePartDInd">Part D</checkbox>
        </div>
        <div fxFlex="50">
          <b>Effective date: {{ selfPay.formJson?.subscriber.medicarePartDDate | date: 'MM/dd/yyyy' }}</b>
        </div>
      </div>
      <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="50">
          <checkbox disabled readonly bold name="medicarePartB" [checked]="selfPay.formJson.subscriber.medicarePartBInd">Part B (medical)</checkbox>
        </div>
        <div fxFlex="50">
          <b>Effective date: {{ selfPay.formJson?.subscriber.medicarePartBDate | date: 'MM/dd/yyyy' }}</b>  
        </div>
      </div>
      <div fxFlex="40" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="50">
          <checkbox disabled readonly bold name="medicarePartDD" [checked]="selfPay.formJson.subscriber.medicaidMedicarePartDInd">Medicare with Part D</checkbox>
        </div>
        <div fxFlex="50">
          <b>Effective date: {{ selfPay.formJson?.subscriber.medicaidMedicarePartDDate | date: 'MM/dd/yyyy' }}</b>
        </div>
      </div>
      <div fxFlex="40" *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'U'" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="50">
          <b>Medicare Number: {{ selfPay.formJson?.subscriber.medicareNumber }}</b>
        </div>
      </div>
    </div>
    <!--other coverage-->
    <div fxLayout="column" fxFlexFill fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'R' && !isOE" class="mgtp-medium">
      <h5 style="text-decoration: underline; font-weight: 600">Other Coverage</h5>
    </div>
    <div *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'R' && !isOE" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="start start">
        <span fxFlex="50">Are you or any eligible dependents enrolled in PEBB insurance coverage under another account?</span>
        <div fxFlex="10" fxFlexOffset="10">
          <checkbox disabled readonly bold name="coveredByOtherPEBBAccountIndYes" [checked]="selfPay.formJson.subscriber.coveredByOtherPEBBAccountInd">Yes</checkbox>
        </div>
        <div fxFlex="10">
          <checkbox disabled readonly bold name="coveredByOtherPEBBAccountIndNo" [checked]="!selfPay.formJson.subscriber.coveredByOtherPEBBAccountInd">No</checkbox>
        </div>
      </div>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'C'">
        <span fxFlex="50">Are you covered by another group medical plan?</span>
        <div fxFlex="0" fxFlexOffset="10">
          <checkbox disabled readonly bold name="coveredByOtherMedicalIndYes" [checked]="selfPay.formJson.subscriber.coveredByOtherMedicalInd">Yes</checkbox>
        </div>
        <div fxFlex="10">
          <checkbox disabled readonly bold name="coveredByOtherMedicalIndNo" [checked]="!selfPay.formJson.subscriber.coveredByOtherMedicalInd">No</checkbox>
        </div>
        <b fxFlex="20">Effective date: {{ selfPay.formJson?.subscriber.coveredByOtherMedicalDate | date: 'MM/dd/yyyy' }}</b>
      </div>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'C'">
        <span fxFlex="50">Are you covered by another group dental plan?</span>
        <div fxFlex="10" fxFlexOffset="10">
          <checkbox disabled readonly bold name="coveredByOtherDentalIndYes" [checked]="selfPay.formJson.subscriber.coveredByOtherDentalInd">Yes</checkbox>
        </div>
        <div fxFlex="10">
          <checkbox disabled readonly bold name="coveredByOtherDentalIndNo" [checked]="!selfPay.formJson.subscriber.coveredByOtherDentalInd">No</checkbox>
        </div>
        <b fxFlex="20">Effective date: {{ selfPay.formJson?.subscriber.coveredByOtherDentalDate | date: 'MM/dd/yyyy' }}</b>
      </div>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'C' && selfPay?.agency?.agencyCode === 'SEBB'">
        <span fxFlex="50">Are you covered by another group vision plan?</span>
        <div fxFlex="10" fxFlexOffset="10">
          <checkbox disabled readonly bold name="coveredByOtherVisionIndYes" [checked]="selfPay.formJson.subscriber.coveredByOtherVisionInd">Yes</checkbox>
        </div>
        <div fxFlex="10">
          <checkbox disabled readonly bold name="coveredByOtherVisionIndNo" [checked]="!selfPay.formJson.subscriber.coveredByOtherVisionInd">No</checkbox>
        </div>
        <b fxFlex="20">Effective date: {{ selfPay.formJson?.subscriber.coveredByOtherVisionDate | date: 'MM/dd/yyyy' }}</b>
      </div>      
      <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'C'">
        <span fxFlex="50">Do you receive Social Security Disability?</span>
        <div fxFlex="10" fxFlexOffset="10">
          <checkbox disabled readonly bold name="coveredByOtherSSDIndYes" [checked]="selfPay.formJson.subscriber.coveredByOtherSSDInd">Yes</checkbox>
        </div>
        <div fxFlex="10">
          <checkbox disabled readonly bold name="coveredByOtherSSDIndNo" [checked]="!selfPay.formJson.subscriber.coveredByOtherSSDInd">No</checkbox>
        </div>
        <b fxFlex="20">Effective date: {{ selfPay.formJson?.subscriber.coveredByOtherSSDDate | date: 'MM/dd/yyyy' }}</b>
      </div>
    </div>
    <!--subscriber elections/attestation section-->
    <div fxLayout="column" fxFlexFill fxLayoutAlign="start start" class="mgtp-medium">
      <h5 style="text-decoration: underline; font-weight: 600">Subscriber enrollment/attestation</h5>
    </div>
    <!--elections (RETIREE)-->
    <!--elections (COBRA)-->
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
        <checkbox disabled readonly bold name="medicalEnrolled" [checked]="selfPay.formJson.subscriber.medicalEnrollInd">Medical</checkbox>
      </div>
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
        <checkbox disabled readonly bold name="dentalEnrolled" [checked]="selfPay.formJson.subscriber.dentalEnrollInd">Dental</checkbox>
      </div>
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start"  *ngIf="selfPay?.agency?.agencyCode === 'SEBB'">
        <checkbox disabled readonly bold name="visionEnrolled" [checked]="selfPay.formJson.subscriber.dentalEnrollInd">Vision</checkbox>
      </div>
      <div fxFlex="20" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'" fxLayout="row" fxLayoutAlign="start start">
        <checkbox 
          disabled readonly bold 
          name="retireeTermLifeInd" 
          [checked]="selfPay.formJson.subscriber.retireeTermLifeInd"
          [containerFxLayout]="'row'"
          [containerfxLayoutAlign]="'start start'"
          label="Retiree term life insurance">
        </checkbox>
      </div>
    </div>

    <!--
      medicalContinueCoverageInd: boolean;
    medicalDoNotContinueCoverage: boolean;
    medicalTerminateCoverage: boolean;
    medicalTerminateCoverageDate: Date;
    medicalTerminateCoverageReason: string;
    dentalContinueCoverageInd: boolean;
    dentalDoNotContinueCoverage: boolean;
    dentalTerminateCoverage: boolean;
    dentalTerminateCoverageDate: Date;
    dentalTerminateCoverageReason: string;
    -->

    <div fxLayout="column" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'R'">
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
        <span fxFlex="20">Medical</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="coverMed" [checked]="selfPay.formJson.subscriber.medicalContinueCoverageInd || selfPay.formJson.subscriber.medicalEnrollInd">Cover</checkbox>
        </div>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="terminateMed" [checked]="selfPay.formJson.subscriber.medicalTerminateCoverage ? true : !selfPay.formJson.subscriber.medicalEnrollInd">Terminate</checkbox>
        </div>
        <span *ngIf="!isOE" fxFlex="20">Effective date: {{ selfPay.formJson?.subscriber.medicalTerminateCoverageDate | date: 'MM/dd/yyyy' }}</span>
        <span *ngIf="!isOE" fxFlex="20">Reason: {{ selfPay.formJson?.subscriber.medicalTerminateCoverageReason }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'R'">
        <span fxFlex="20">Dental</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="coverDental" [checked]="selfPay.formJson.subscriber.dentalContinueCoverageInd || selfPay.formJson.subscriber.dentalEnrollInd">Cover</checkbox>
        </div>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="terminateDental" [checked]="selfPay.formJson.subscriber.dentalTerminateCoverage ? true : !selfPay.formJson.subscriber.dentalEnrollInd">Terminate</checkbox>
        </div>
        <span *ngIf="!isOE"  fxFlex="20">Effective date: {{ (selfPay.formJson?.subscriber.dentalTerminateCoverageDate || selfPay.formJson?.subscriber.medicalTerminateCoverageDate) | date: 'MM/dd/yyyy' }}</span>
        <span *ngIf="!isOE" fxFlex="20">Reason: {{ selfPay.formJson?.subscriber.dentalTerminateCoverageReason || selfPay.formJson?.subscriber.medicalTerminateCoverageReason }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="(selfPay.selfPayType.selfPayTypeCode !== 'R' && selfPay?.agency?.agencyCode === 'SEBB') || isPebbLwopVision || selfPay.selfPayType.selfPayTypeCode === 'OER' || selfPay.selfPayType.selfPayTypeCode === 'OEC'">
        <span fxFlex="20">Vision</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="coverVision" [checked]="selfPay.formJson.subscriber.visionContinueCoverageInd || selfPay.formJson.subscriber.visionEnrollInd">Cover</checkbox>
        </div>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="terminateVision" [checked]="selfPay.formJson.subscriber.visionTerminateCoverage ? true : !selfPay.formJson.subscriber.visionEnrollInd">Terminate</checkbox>
        </div>
        <span *ngIf="!isOE" fxFlex="20">Effective date: {{ (selfPay.formJson?.subscriber.visionTerminateCoverageDate || selfPay.formJson?.subscriber.medicalTerminateCoverageDate) | date: 'MM/dd/yyyy' }}</span>
        <span *ngIf="!isOE" fxFlex="20">Reason: {{ selfPay.formJson?.subscriber.visionTerminateCoverageReason || selfPay.formJson?.subscriber.medicalTerminateCoverageReason}}</span>
      </div>      
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode === 'U'">
        <span fxFlex="20">Life AD&D</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="coverLife" [checked]="selfPay.formJson.subscriber.lifeAndADDContinueCoverageInd">Cover</checkbox>
        </div>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="terminateLife" [checked]="selfPay.formJson.subscriber.terminateLifeAndADDCoverageInd">Terminate</checkbox>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode === 'U'">
        <span fxFlex="20">Long term disability</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly bold labelFirst name="coverLTD" [checked]="selfPay.formJson.subscriber.longTermDisabilityContinueCoverageInd">Cover</checkbox>
        </div>
      </div>

      <!--AD&D-->
      <div *ngIf="selfPay.selfPayType.selfPayTypeCode === 'U'" fxLayout="column" fxFlexFill>
        <span><b>Life and accidential death and dismemberment (AD&D) insurance</b></span>
        <div fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px" class="fxGap-medium-sm">
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <checkbox disabled name="lifeAndADDAcknowlegeInd" 
              [checked]="selfPay.formJson.subscriber.lifeAndADDAcknowlegeInd"
              [containerFxLayout]="'row'"
              [containerfxLayoutAlign]="'start start'">
              Yes, I wish to continue the life and AD&D insurance I had as an employee. I understand I will need to pay MetLife for basic life insurance and basic AD&D Insurance in addition to any
              supplemental life and AD&D insurance I have while on PEBB Continuation Coverage (Unpaid Leave). If you wish to decrease your supplemental life and/or AD&D insurance amounts while on PEBB
              Continuation Coverage (Unpaid Leave), please call MetLife at 1-866-548-7139.
            </checkbox>
          </div>
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <checkbox disabled name="lifeAndADDAcknowlegeIndNo" 
              [checked]="!selfPay.formJson.subscriber.lifeAndADDAcknowlegeInd"
              [containerFxLayout]="'row'"
              [containerfxLayoutAlign]="'start start'">
              No, I do not wish to continue the life and AD&D insurance I had as an employee. I under stand I must reapply for supplemental life insurance and AD&D insurance when I regain eligibility
              and I must submit evidence of insurability to MetLife for supplemental life insurance. I understand that MetLife must receive my MetLife Enrollment/Change form through MetLife’s MyBenefits online
              portal at
              <a href="mybenefits.metlife.com/wapebb" target="_blank">mybenefits.metlife.com/wapebb</a>
              no later than 31 days after the date I regain eligibility.
            </checkbox>
          </div>
        </div>
      </div>
      <!-- Long term disability -->
      <div *ngIf="selfPay.selfPayType.selfPayTypeCode === 'U' || selfPay.selfPayType?.selfPayTypeCode === 'OEU'">
        <div class="page-break"></div>
        <span><b>Long-term disability (LTD) insurance</b></span>
        <p>
          This section applies only to employees on approved educational leave, or who are called to active duty in the uniformed services as defined under Uniformed Services Employment and
          Reemployment Rights Act (USERRA).
        </p>
        <div fxFlexFill fxLayout="column" fxLayoutAlign="start start" style="margin-bottom: 10px;">
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
              <b fxFlex="40">Selected Options:</b>
              <span fxFlex="50">{{selfPay.formJson.ltdPlanName}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--deferral-->
    <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <div fxFlex="50">
        <checkbox disabled readonly bold name="deferCoverageInd" [checked]="selfPay.formJson.subscriber.deferCoverageInd">Defer</checkbox>
      </div>
      <div fxFlex="50">
        <b>Deferral date: {{ selfPay.formJson?.subscriber.deferCoverageDate | date: 'MM/dd/yyyy' }}</b>
      </div>
    </div>
    <!--enroll after deferring-->
    <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <div fxFlex="50">
        <checkbox disabled readonly bold name="enrollAfterDeferalInd" [checked]="selfPay.formJson.subscriber.enrollAfterDeferalInd">Enroll after deferring</checkbox>
      </div>
      <div fxFlex="50">
        <b>Date qualifying coverage ended: {{ selfPay.formJson?.subscriber.enrollAfterDeferalDate | date: 'MM/dd/yyyy' }}</b>
      </div>
    </div>
    <div class="page-break" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'"></div>
    <!--deferral questions-->
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <span>
        <b>If deferring or enrolling after deferring, check all the boxes below that apply to you.</b>
      </span>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <checkbox disabled readonly name="deferQuestionOne" 
        [(ngModel)]="selfPay.formJson?.subscriber.deferQuestionOne"
        [containerFxLayout]="'row'"
        [containerfxLayoutAlign]="'start start'">
        Enrolled as a dependent in a health plan sponsored by the PEBB Program, a Washington State educational service district, or the School Employees Benefits Board (SEBB) Program. This includes
        coverage under COBRA or continuation coverage.
      </checkbox>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <checkbox disabled readonly name="deferQuestionTwo" 
        [(ngModel)]="selfPay.formJson?.subscriber.deferQuestionTwo"
        [containerFxLayout]="'row'"
        [containerfxLayoutAlign]="'start start'">
        Enrolled in employer-based group medical as an employee or employee's dependent, including medical insurance continued under COBRA or continuation coverage. This does not include an employer's
        retiree coverage.
      </checkbox>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <checkbox disabled readonly name="deferQuestionThree" 
        [(ngModel)]="selfPay.formJson?.subscriber.deferQuestionThree"
        [containerFxLayout]="'row'"
        [containerfxLayoutAlign]="'start start'">
        Enrolled in medical coverage as a retiree or dependent of a retiree in a TRICARE plan or the Federal Employees Health Benefits Program. You have a one-time opportunity to enroll in a PEBB
        retiree health plan.
      </checkbox>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <checkbox disabled readonly name="deferQuestionFour" 
        [(ngModel)]="selfPay.formJson?.subscriber.deferQuestionFour"
        [containerFxLayout]="'row'"
        [containerfxLayoutAlign]="'start start'">
        Enrolled in a Medicaid program that provides creditable coverage and in Medicare Part A and Part B. You may continue to cover eligible dependents who are not eligible for creditable coverage
        under Medicaid.
      </checkbox>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <checkbox disabled readonly name="deferQuestionFive" 
        [(ngModel)]="selfPay.formJson?.subscriber.deferQuestionFive"
        [containerFxLayout]="'row'"
        [containerfxLayoutAlign]="'start start'">
        Enrolled in the Civilian Health and Medical Program of the Department of Veterans Affairs (CHAMPVA). You have a one-time opportunity to enroll in a PEBB retiree health plan.
      </checkbox>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
      <checkbox disabled readonly name="deferQuestionSix" 
        [(ngModel)]="selfPay.formJson?.subscriber.deferQuestionSix"
        [containerFxLayout]="'row'"
        [containerfxLayoutAlign]="'start start'">
        Non-Medicare subscribers only: Enrolled in qualified health plan coverage through a health benefit exchange established under the Affordable Care Act. This does not include Medicaid (called
        Apple Health in Washington State). You have a one-time opportunity to enroll or reenroll in a PEBB retiree health plan.
      </checkbox>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R' || selfPay.selfPayType.selfPayTypeCode === 'OER' || selfPay.selfPayType.selfPayTypeCode === 'OEC'">
      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="15">
        <b>Tobacco attestation:</b>
      </div>
      <div fxFlex="15" fxLayout="row" fxLayoutAlign="start start">
        <checkbox disabled readonly name="tobaccoYes" [checked]="selfPay.formJson?.subscriber.tobaccoUseFormInd === 'Yes'">Yes</checkbox>
      </div>
      <div fxFlex="15" fxLayout="row" fxLayoutAlign="start start">
        <checkbox disabled readonly name="tobaccoNo" [checked]="selfPay.formJson?.subscriber.tobaccoUseFormInd === 'No'">No</checkbox>
      </div>
      <div fxFlex="25" fxLayout="row" fxLayoutAlign="start start">
        <checkbox disabled readonly name="tobaccoNA" [checked]="selfPay.formJson?.subscriber.tobaccoUseFormInd === 'NA'">Does not apply (Medicare)</checkbox>
      </div>
    </div>
    <!--dependent loops-->
    <div fxFlexFill fxLayout="column" fxLayoutAlign="start start" *ngFor="let dependent of selfPay.formJson?.dependents" class="fxGap-x-small">
      <div class="page-break"></div>
      <div fxLayout="column" fxFlexFill fxLayoutAlign="start start">
        <h5 style="text-decoration: underline; font-weight: 600">Dependent Information</h5>
      </div>
      <!--name-->
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="40">
          <b fxFlex="40">Dependent:</b>
          <span fxFlex="50"> {{ dependent.lastName }}, {{ dependent.firstName }} </span>
        </div>
        <div fxLayoutAlign="start start" fxFlex="40">
          <b fxFlex="40">Middle Name:</b>
          <span fxFlex="50"> {{ dependent.middleName }}</span>
        </div>
      </div>
      <!--social, bday-->
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="40">
          <b fxFlex="40">SSN:</b>
          <span fxFlex="50"> {{ dependent.socialSecurityNumber }} </span>
        </div>
        <div fxLayoutAlign="start start" fxFlex="40">
          <b fxFlex="40">DOB:</b>
          <span fxFlex="50"> {{ dependent.birthDate | date: 'MM/dd/yyyy' }}</span>
        </div>
      </div>
      <!--relationship, suffix-->
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="40">
          <b fxFlex="40">Relationship:</b>
          <span fxFlex="50"> {{ dependent.relationshipTypeName }} </span>
        </div>
        <div fxLayoutAlign="start start" fxFlex="40">
          <b fxFlex="40">Suffix:</b>
          <span fxFlex="50"> {{ dependent.suffix }}</span>
        </div>
      </div>
      <!--partnership start date, Sex assigned at birth, gender identity-->
      <div *ngIf="dependent.isSpouse" fxLayout="row" fxFlexFill fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
          <b fxFlex="40">Partnership start date:</b>
          <span fxFlex="50">
            {{
              dependent.spouseForm && (dependent.spouseForm.dateOfMarriage || dependent.spouseForm.dateOfRegistered || dependent.partnershipStartDate || dependent.spouseForm.partnershipStartDate)
                ? (dependent.spouseForm.dateOfMarriage || dependent.spouseForm.dateOfRegistered || dependent.partnershipStartDate || dependent.spouseForm.partnershipStartDate | date: 'MM/dd/yyyy')
                : ''
            }}
          </span>
        </div>
      </div>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
        <!--sex@birth, genders-->
        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="100">
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <b fxFlex="40">Sex assigned at birth:</b>
            <span fxFlex="50"> {{ getBirthSexName(dependent.birthSex) }}</span>
          </div>
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <b fxFlex="40">Gender identity:</b>
            <span fxFlex="50"> {{ dependent.genderIdentity ? getGenderIdentityName(dependent.genderIdentity) : '' }}</span>
          </div>
        </div>
      </div>
      <!--Residential title-->
      <div fxFlexfill fxLayout="column" fxLayoutAlign="center center">
        <span><b>Residential address</b></span>
        <span *ngIf="!dependent.residentialAddress?.addressLineOne"><i>Same as subscriber</i></span>
      </div>
      <!--Street address-->
      <div fxFlexFill fxLayout="column">
        <div *ngIf="dependent.residentialAddress?.addressLineOne" fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
          <b fxFlex="40">Street address:</b>
          <span fxFlex="60"> {{ dependent.residentialAddress?.addressLineOne }}</span>
        </div>
        <!--Address Line Two-->
        <div *ngIf="dependent.residentialAddress?.addressLineOne" fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
          <b fxFlex="40">Address line 2:</b>
          <span fxFlex="60"> {{ dependent.residentialAddress?.addressLineTwo }}</span>
        </div>
        <!--City State Zip-->
        <div *ngIf="dependent.residentialAddress?.addressLineOne" fxLayout="row" fxLayoutAlign="start start" fxFlex="50">
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
            <b fxFlex="20">City:</b>
            <span fxFlex="70"> {{ dependent.residentialAddress?.city }}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
            <b fxFlex="20">State:</b>
            <span fxFlex="70"> {{ dependent.residentialAddress?.state }}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="30">
            <b fxFlex="35">Zip Code:</b>
            <span fxFlex="70"> {{ dependent.residentialAddress?.zipcodeNumber }}</span>
          </div>
        </div>
      </div>

      <br />
      <!--Medicare-->
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'U'">
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start">
          <div fxFlex="50">
            <checkbox disabled readonly bold [checked]="dependent.medicarePartAInd">Part A (hospital)</checkbox>
          </div>
          <div fxFlex="50">
            <b>Effective date: {{dependent.medicarePartADate | date: 'MM/dd/yyyy' }}</b>
          </div>
        </div>
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
          <div fxFlex="50">
            <checkbox disabled readonly bold [checked]="dependent.memberMedicare.medicarePartDEnrolledInd">Part D</checkbox>
          </div>
          <div fxFlex="50">
            <b>Effective date: {{ dependent.medicarePartDEffectiveDate | date: 'MM/dd/yyyy' }}</b>
          </div>
        </div>
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start">
          <div fxFlex="50">
            <checkbox disabled readonly bold [checked]="dependent.medicarePartBInd">Part B (medical)</checkbox>
          </div>
          <div fxFlex="50">
            <b>Effective date: {{ dependent.medicarePartBDate | date: 'MM/dd/yyyy' }}</b>
          </div>
        </div>
        <div fxFlex="40" fxLayout="row" fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
          <div fxFlex="50">
            <checkbox disabled readonly bold [checked]="dependent.memberMedicare.medicaidMedicarePartDEnrolledInd">Medicare with Part D</checkbox>
          </div>
          <div fxFlex="50">
            <b>Effective date: {{ dependent.memberMedicare.medicaidMedicarePartDEffectiveDate | date: 'MM/dd/yyyy' }}</b>
          </div>
        </div>
        <div fxFlex="70" fxLayout="row" fxLayoutAlign="start start" *ngIf="dependent.memberMedicare.medicareNumber">
          <div fxLayout="row" fxFlexFill fxLayoutAlign="start start">
            <b fxFlex="50">Medicare Number (HICN):</b>
            <span fxFlex="50"> {{ dependent.medicareNumber }}</span>
          </div>
        </div>
      </div>
      <!--other coverage (COBRA)-->
      <div fxLayout="column" fxFlexFill fxLayoutAlign="start start" *ngIf="selfPay.selfPayType.selfPayTypeCode === 'C'">
        <h5 style="text-decoration: underline; font-weight: 600">Other coverage</h5>
      </div>
      <div *ngIf="selfPay.selfPayType.selfPayTypeCode === 'C'" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start start">
          <span fxFlex="50">Are you enrolled in PEBB insurance coverage under another account?</span>
          <div fxFlex="10" fxFlexOffset="10">
            <checkbox disabled readonly bold [checked]="dependent.coveredByOtherPEBBAccountInd">Yes</checkbox>
          </div>
          <div fxFlex="10">
            <checkbox disabled readonly bold [checked]="!dependent.coveredByOtherPEBBAccountInd">No</checkbox>
          </div>
        </div>
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start start">
          <span fxFlex="50">Are you covered by another group medical plan?</span>
          <div fxFlex="10" fxFlexOffset="10">
            <checkbox disabled readonly bold [checked]="dependent.coveredByOtherMedicalInd">Yes</checkbox>
          </div>
          <div fxFlex="10">
            <checkbox disabled readonly bold [checked]="!dependent.coveredByOtherMedicalInd">No</checkbox>
          </div>
          <b fxFlex="20">Effective date: {{ dependent.coveredByOtherMedicalDate | date: 'MM/dd/yyyy' }}</b>
        </div>
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start start">
          <span fxFlex="50">Are you covered by another group dental plan?</span>
          <div fxFlex="10" fxFlexOffset="10">
            <checkbox disabled readonly bold [checked]="dependent.coveredByOtherDentalInd">Yes</checkbox>
          </div>
          <div fxFlex="10">
            <checkbox disabled readonly bold [checked]="!dependent.coveredByOtherDentalInd">No</checkbox>
          </div>
          <b fxFlex="20">Effective date: {{ dependent.coveredByOtherDentalDate | date: 'MM/dd/yyyy' }}</b>
        </div>
        <div *ngIf="selfPay?.agency?.agencyCode === 'SEBB'" fxFlexFill fxLayout="row" fxLayoutAlign="start start">
          <span fxFlex="50">Are you covered by another group vision plan?</span>
          <div fxFlex="10" fxFlexOffset="10">
            <checkbox disabled readonly bold [checked]="dependent.coveredByOtherVisionInd">Yes</checkbox>
          </div>
          <div fxFlex="10">
            <checkbox disabled readonly bold [checked]="!dependent.coveredByOtherVisionInd">No</checkbox>
          </div>
          <b fxFlex="20">Effective date: {{ dependent.coveredByOtherVisionDate | date: 'MM/dd/yyyy' }}</b>
        </div>
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start start">
          <span fxFlex="50">Do you receive Social Security Disability?</span>
          <div fxFlex="10" fxFlexOffset="10">
            <checkbox disabled readonly bold [checked]="dependent.coveredByOtherSSDInd">Yes</checkbox>
          </div>
          <div fxFlex="10">
            <checkbox disabled readonly bold [checked]="!dependent.coveredByOtherSSDInd">No</checkbox>
          </div>
          <b fxFlex="20">Effective date: {{ dependent.coveredByOtherSSDDate | date: 'MM/dd/yyyy' }}</b>
        </div>
      </div>

      <div fxLayout="column" fxFlexFill fxLayoutAlign="start start" class="mgtp-medium">
        <h5 style="text-decoration: underline; font-weight: 600">Dependent enrollment/attestation</h5>
      </div>
      <!--elections-->
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start">
          <span fxFlex="25">Medical</span>
          <checkbox disabled readonly bold [checked]="dependent.medicalCoverInd">Enrolled</checkbox>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode === 'R'">
        <div fxFlex="50" fxLayout="row" fxLayoutAlign="start start">
          <span fxFlex="25">Dental</span>
          <checkbox disabled readonly bold [checked]="dependent.dentalCoverInd">Enrolled</checkbox>
        </div>
      </div>
      
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'R'">

        <span fxFlex="20">Medical</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled bold labelFirst [checked]="dependent.medicalContinueCoverageInd || dependent.medicalCoverInd">Cover</checkbox>
        </div>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled labelFirst [checked]="dependent.medicalTerminateCoverage || dependent.medicalDoNotContinueCoverage">Terminate</checkbox>
        </div>
        <span *ngIf="!isOE" fxFlex="20">Effective date: {{ dependent.medicalTerminateCoverageDate | date: 'MM/dd/yyyy' }}</span>
        <span *ngIf="!isOE" fxFlex="20">Reason: {{ dependent.medicalTerminateCoverageReason }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="selfPay.selfPayType.selfPayTypeCode !== 'R'">
        <span fxFlex="20">Dental</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled labelFirst [checked]="dependent.dentalContinueCoverageInd || dependent.dentalCoverInd">Cover</checkbox>
        </div>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled labelFirst [checked]="dependent.dentalTerminateCoverage || dependent.dentalDoNotContinueCoverage">Terminate</checkbox>
        </div>
        <span *ngIf="!isOE" fxFlex="20">Effective date: {{ dependent.dentalTerminateCoverageDate | date: 'MM/dd/yyyy' }}</span>
        <span *ngIf="!isOE" fxFlex="20">Reason: {{ dependent.dentalTerminateCoverageReason }}</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start" fxFlexFill *ngIf="(selfPay.selfPayType.selfPayTypeCode !== 'R' && selfPay?.agency?.agencyCode === 'SEBB') || isPebbLwopVision || selfPay.selfPayType.selfPayTypeCode === 'OER' || selfPay.selfPayType.selfPayTypeCode === 'OEC'">
        <span fxFlex="20">Vision</span>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled labelFirst [checked]="dependent.visionContinueCoverageInd || dependent.visionCoverInd">Cover</checkbox>
        </div>
        <div fxFlex="20" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled labelFirst [checked]="dependent.visionTerminateCoverage || dependent.visionDoNotContinueCoverage">Terminate</checkbox>
        </div>
        <span *ngIf="!isOE" fxFlex="20">Effective date: {{ dependent.visionTerminateCoverageDate | date: 'MM/dd/yyyy' }}</span>
        <span *ngIf="!isOE" fxFlex="20">Reason: {{ dependent.visionTerminateCoverageReason }}</span>
      </div>      
      <!--attestations-->
      <div fxFlexFill fxLayout="row" fxLayoutAlign="start start">
        <div fxFlexFill *ngIf="dependent.relationshipTypeName === 'Spouse/state-registered domestic partner'">
          <div fxLayout="row" fxLayoutAlign="start start" fxFlex="45">
            <b>Spousal attestation:</b>
          </div>
          <div fxFlex="15" fxLayout="row" fxLayoutAlign="start start">
            <checkbox disabled readonly [checked]="dependent.spouseForm?.spouseSurchargeTriState === 'Y' || dependent.spouseSurchargeTriState === 'Y'">Yes</checkbox>
          </div>
          <div fxFlex="15" fxLayout="row" fxLayoutAlign="start start">
            <checkbox disabled readonly [checked]="dependent.spouseForm?.spouseSurchargeTriState === 'N' || dependent.spouseSurchargeTriState === 'N'">No</checkbox>
          </div>
        </div>
      </div>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="15">
          <b>Tobacco attestation:</b>
        </div>
        <div fxFlex="15" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly [checked]="dependent.tobaccoUseInd">Yes</checkbox>
        </div>
        <div fxFlex="15" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly [checked]="!dependent.tobaccoUseInd">No</checkbox>
        </div>
        <div fxFlex="25" fxLayout="row" fxLayoutAlign="start start">
          <checkbox disabled readonly [checked]="dependent.surchargeNA">Does not apply (Medicare)</checkbox>
        </div>
      </div>
      <div *ngIf="dependent.spouseForm.spouseSurchargeTriState" fxFlexFill fxLayout="row" fxLayoutAlign="start start" fxFlex="45">
        <checkbox disabled readOnly [name]="'questionTwo' + idx" [(ngModel)]="dependent.spouseForm.questionTwoInd" label="Q2"></checkbox>
        <checkbox disabled readOnly [name]="'questionThree' + idx" [(ngModel)]="dependent.spouseForm.questionThreeInd" label="Q3"></checkbox>
        <checkbox disabled readOnly [name]="'questionFour' + idx" [(ngModel)]="dependent.spouseForm.questionFourInd" label="Q4"></checkbox>
        <checkbox disabled readOnly [name]="'questionFive' + idx" [(ngModel)]="dependent.spouseForm.questionFiveInd" label="Q5"></checkbox>
        <checkbox disabled readOnly [name]="'questionSix' + idx" [(ngModel)]="dependent.spouseForm.questionSixInd" label="Q6"></checkbox>
      </div>
      <div *ngIf="dependent.spouseForm.spouseSurchargeTriState" fxLayout="row" fxLayoutAlign="start start">
        <checkbox disabled readonly [checked]="dependent.spouseForm.spouseSurchargeTriState === 'H'">Employer to determine if premium surcharge applies</checkbox>
      </div>
    </div>
  </div>
  <!-- MEDICARE ADVANTAGE PLAN RACE/ETHNICITY -->
    <div fxFlexFill style="padding-top: 100px; width: 100%; max-width: 100%" *ngIf="displayRaceEthnicity" fxLayout="column">
      <div fxFlex="100" fxLayout="column" class="card">
        <div class="card-body">
          <h3>Subscriber</h3>
          <race-ethnicity (updateMemberObject)="updateFormJson($event)" [suppressSave]="true" [ethnicities]="ethnicities" [member]="this.selfPay.formJson?.subscriber"></race-ethnicity>
        </div>
      </div>
      <div fxFlex="100" *ngIf="this.selfPay?.formJson?.dependents?.length"fxLayout="column" class="card">
        <div class="card-body">
          <h3>Spouse or SRDP</h3>
          <race-ethnicity [suppressSave]="true" [ethnicities]="ethnicities" [member]="this.selfPay.formJson?.dependents[0]"></race-ethnicity>
        </div>
      </div>
    </div>
</div>
