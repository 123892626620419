<div *ngIf="!isPebbRetireeOE && !isCobraOE" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body" fxFlexFill>
    <h2 class="selfPay-form-small-header">Vision plan selection</h2>
    <span>Choose one vision plan. Before you enroll, make sure the provider you want to use accepts the specific plan you choose</span>
    <br /><br />
    <form fxLayoutAlign="space-between" fxFlexFill fxLayout="row" #electVisionForm="ngForm" (ngSubmit)="emitSelfPay(selfPay)">
      <div *ngIf="agency?.agencyCode === 'SEBB' || selfPay?.selfPayType.selfpayTypeCode === 'OER' || isPebbLwop" fxLayout="column" fxFlex="50">
        <div fxLayout="column">
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of visionPlans; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'visionPlan_' + index" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selfPay.formJson.visionPlan.planId">
                  {{ plan.planName }}
                  <a target="_blank" [href]="!isPebbLwop ? 'https://davisvision.com/hcasebb/' : 'https://davisvision.com/'" *ngIf="plan.planName === 'Davis Vision'"> by MetLife, underwritten by Metropolitan Life Insurance Company (“MetLife”) </a>
                  <a target="_blank" [href]="!isPebbLwop ? 'https://member.eyemedvisioncare.com/hcasebb/en' : 'https://member.eyemedvisioncare.com/'" *ngIf="plan.planName === 'EyeMed Vision Care'">, underwritten by Fidelity Security Life Insurance Company  </a>
                  <a target="_blank" [href]="!isPebbLwop ? 'https://www.metlife.com/wshca-sebb/vision-insurance/' : 'https://www.metlife.com/'" *ngIf="plan.planName === 'MetLife Vision'">, underwritten by Metropolitan Life Insurance Company “MetLife"</a>

                </radio>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div fxFlex="45">
        <div class="h5">
          <div>
            <h3 class="h5">Helpful Links:</h3>
            <div fxLayoutAlign="start start" fxLayout="column">
              <span *ngIf="!isPebbLwop">
                <a target="_blank" [href]="agency.agencyCode != 'SEBB' || !isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/school-employees/compare-vision-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/compare-vision-plans'">Compare Vision plans</a><br/>
                <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/find-sebb-plan-provider">Find a provider</a>. Make sure you have the correct provider network selected before searching for providers. </span><br/>
                <span><a href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/contact-plans" target="_blank">Plan contact information.</a></span><br/>
              </span>
              <span *ngIf="isPebbLwop">
                <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/medical-plans-and-benefits-including-vision">Compare Vision plans</a></span><br/>
                <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/find-pebb-plan-provider">Find a provider</a>. Make sure you have the correct provider network selected before searching for providers. </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>


<!-- RETIREE OE NO SELECTIONS MADE -->

<div *ngIf="noVisionSelectionMade && (isPebbRetireeOE || isCobraOE)" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body">
    <h2>Vision plan selection</h2>
    <div class="h5 mgbt-medium">
      Vision coverage has not been selected for any members on this account so no Vision plan election applies.
      If that is incorrect, please go back and select Vision coverage. Otherwise you can proceed to defer your coverage
      and submit your request.
      <br/>
      <br/>
      If you have questions or need assistance you can contact The Health Care Authority (HCA) by calling 1-800-200-1004 (toll-free)
      Monday through Friday 8a.m. to 4:30p.m.
      <br/>
      <br/>
      Contact infomration for The Health Care Authority can be found here: <a targe="_blank" href="https://www.hca.wa.gov/about-hca/contact-hca#retirees">Contact HCA</a>     
    </div>
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>


<div *ngIf="(isPebbRetireeOE || isCobraOE) && !noVisionSelectionMade" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body" fxFlexFill>
    <h2 class="selfPay-form-small-header">Vision plan selection</h2>
    <span>Choose one vision plan. Before you enroll, make sure the provider you want to use accepts the specific plan you choose</span>
    <br /><br />
    <div>
      <div class="k-form-field bigger" fxFlex="100">
        <checkbox name="visionEnrollInd" [(ngModel)]="selfPay.formJson.continueCurrentSelectedVision" (ngModelChange)="continueCurrentSelected($event)">Continue with my current vision plan</checkbox>
      </div>
    </div>    
    <form fxLayoutAlign="space-between" fxFlexFill fxLayout="row" #electVisionForm="ngForm" (ngSubmit)="emitSelfPay(selfPay)">
      <div *ngIf="isPEBBOE" fxLayout="column" fxFlex="50">
        <div fxLayout="column">
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of visionPlans; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'visionPlan_' + index" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selfPay.formJson.visionPlan.planId">
                  {{ plan.planName }}
                  <a target="_blank" [href]="!isPEBBOE ? 'https://davisvision.com/hcasebb/' : 'https://davisvision.com/'" *ngIf="plan.planName === 'Davis Vision'"> by MetLife, underwritten by Metropolitan Life Insurance Company (“MetLife”) </a>
                  <a target="_blank" [href]="!isPEBBOE ? 'https://member.eyemedvisioncare.com/hcasebb/en' : 'https://member.eyemedvisioncare.com/'" *ngIf="plan.planName === 'EyeMed Vision Care'">, underwritten by Fidelity Security Life Insurance Company  </a>
                  <a target="_blank" [href]="!isPEBBOE ? 'https://www.metlife.com/wshca-sebb/vision-insurance/' : 'https://www.metlife.com/'" *ngIf="plan.planName === 'MetLife Vision'">, underwritten by Metropolitan Life Insurance Company “MetLife"</a>

                </radio>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div fxFlex="45">
        <div class="h5">
          <div>
            <h3 class="h5">Helpful Links:</h3>
            <div fxLayoutAlign="start start" fxLayout="column">
              <span *ngIf="!isPebbLwop">
                <a target="_blank" [href]="agency.agencyCode != 'SEBB' || !isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/school-employees/compare-vision-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/compare-vision-plans'">Compare Vision plans</a><br/>
                <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/find-sebb-plan-provider">Find a provider</a>. Make sure you have the correct provider network selected before searching for providers. </span><br/>
                <span><a href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/contact-plans" target="_blank">Plan contact information.</a></span><br/>
              </span>
              <span *ngIf="isPebbLwop">
                <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/medical-plans-and-benefits-including-vision">Compare Vision plans</a></span><br/>
                <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/find-pebb-plan-provider">Find a provider</a>. Make sure you have the correct provider network selected before searching for providers. </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>
