import Milestone from 'src/app/models/milestone';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Enrollment from 'src/app/models/enrollment';
// ng
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// ext
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { some, find, cloneDeep } from 'lodash';

// local
import Organization from '../models/organization';
import SpecialOpenEnrollment from '../models/specialOpenEnrollment';
import SystemUser from '../models/user';
import Subscriber from '../models/subscriber';
import OpenEnrollment from '../models/openEnrollment';
import { SpinnerOverlayService } from './spinnerOverlay.service';
import { SubscriberService } from './subscriber.service';
import { SoeService } from './soe.service';
import { OEService } from './oe.service';
import { env } from 'src/env/development';
import { NotificationService, NotificationSettings, NotificationRef } from '@progress/kendo-angular-notification';
import UserOrganizationRole from '../models/userOrganizationRole';
import * as dayjs from 'dayjs';
import { MultiViewCalendarLocalizedMessagesDirective } from '@progress/kendo-angular-dateinputs';
import { NotificationComponent } from '../components/notification/notification.component';
import { CompositeFilterDescriptor, FilterDescriptor, State, toDataSourceRequestString } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';

export enum UserTypeCode {
  HCA = 'HCA',
  Perspay = 'Perspay'
}

export enum AccessLevel {
  ReadOnly = 'read',
  Edit = 'edit',
  Admin = 'admin',
  SystemAdmin = 'sysAdmin'
}

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private selectedOrganization = new BehaviorSubject<Organization>(new Organization());
  private selectedSOE = new BehaviorSubject<SpecialOpenEnrollment>(new SpecialOpenEnrollment());
  private selectedOpenEnrollment = new BehaviorSubject<OpenEnrollment>(new OpenEnrollment());
  private selectedEnrollmentPeriod = new BehaviorSubject<EnrollmentPeriod>(new EnrollmentPeriod());
  private openEnrollments = new BehaviorSubject<OpenEnrollment[]>([]);
  private currentOrWithinLowerLimitOE = new BehaviorSubject<OpenEnrollment>(new OpenEnrollment());
  private selectedSubscriber = new BehaviorSubject<Subscriber>(new Subscriber());
  private selectedMilestone = new BehaviorSubject<Milestone>(new Milestone());
  private systemUser = new BehaviorSubject<SystemUser>(new SystemUser());
  private forceRefresh = new BehaviorSubject<void>(null);
  private tokenLastRefreshed = Date.now();
  private apiVersion = '';

  constructor(
    public soeService: SoeService,
    public oeService: OEService,
    private router: Router,
    private spinnerService: SpinnerOverlayService,
    private notificationService: NotificationService,
    private subscriberService: SubscriberService) { }

  organizationSelected = this.selectedOrganization.asObservable();
  soeSelected = this.selectedSOE.asObservable();
  openEnrollmentSelected = this.selectedOpenEnrollment.asObservable();
  subscriberSelected = this.selectedSubscriber.asObservable();
  enrollmentPeriodSelected = this.selectedEnrollmentPeriod.asObservable();
  milestoneSelected = this.selectedMilestone.asObservable();
  refreshForced = this.forceRefresh.asObservable();
  // ORGANIZATION

  setOrganization(organization: Organization): void {
    this.selectedOrganization.next(organization);
  }

  getOrganization(): Observable<Organization> {
    return this.organizationSelected;
  }

  getOrganizationValue(): Organization {
    return this.selectedOrganization.value;
  }

  setOrganizationFromRole(userOrganizationRole: UserOrganizationRole): void {
    const org = new Organization();
    org.organizationId = userOrganizationRole.organizationId;
    org.organizationName = userOrganizationRole.organizationName;
    org.ospiDistrictNumber = userOrganizationRole.ospiDistrictNumber;
    this.selectedOrganization.next(org);
  }

  // SPECIAL OPEN ENROLLMENT

  getSelectedSOE(): BehaviorSubject<SpecialOpenEnrollment> {
    return this.selectedSOE;
  }

  async updateSpecialOpenEnrollment(soeId, memberId): Promise<void> {
    try {
      const updatedSoe = await lastValueFrom(this.soeService.getSpecialOpenEnrollmentById(soeId, memberId));
      this.selectedSOE.next(updatedSoe);
      this.spinnerService.hide();
    } catch (err) {
      this.spinnerService.hide();
    }
  }

  setSelectedSOE(soe: SpecialOpenEnrollment): void {
    this.selectedSOE.next(soe);
  }

  // OPEN ENROLLMENT STEPS

  getOpenEnrollment(): BehaviorSubject<OpenEnrollment> {
    return this.selectedOpenEnrollment;
  }

  async updateSubscriberOpenEnrollment(subscriberId): Promise<void> {
    const updatedOE = await lastValueFrom(this.oeService.getOpenEnrollmentStepsForSubscriber(subscriberId));
    this.selectedOpenEnrollment.next(updatedOE);
  }

  // OPEN ENROLLMENTS

  getOpenEnrollments(): BehaviorSubject<OpenEnrollment[]> {
    return this.openEnrollments;
  }

  setOpenEnrollments(oes: OpenEnrollment[]): void {
    const last15 = dayjs().subtract(1, 'month').startOf('month').add(14, 'd');
    const curr15 = dayjs().startOf('month').add(14, 'd');
    const lowerLimitdayjs = dayjs().isBetween(last15, curr15) ? curr15.subtract(3, 'month').endOf('month') : curr15.subtract(2, 'month').endOf('month');
    const currentOrWithinLowerLimitOE = find(oes, (oe: OpenEnrollment) => {
      if (this.systemUserHasAccess(AccessLevel.ReadOnly)) {
        // admins
        return dayjs().isSameOrAfter(oe.effectiveStartDate) && lowerLimitdayjs.isBefore(oe.coverageEffectiveStartDate);
      } else {
        // subscribers
        return dayjs().isBetween(oe.effectiveStartDate, oe.effectiveEndDate);
      }
    });
    if (currentOrWithinLowerLimitOE) {
      this.currentOrWithinLowerLimitOE.next(currentOrWithinLowerLimitOE);
    }
    this.openEnrollments.next(oes);
  }

  getCurrentOrWithinLowerLimitOE(): BehaviorSubject<OpenEnrollment> {
    return this.currentOrWithinLowerLimitOE;
  }
  // SYSTEM USER

  getSystemUser(): BehaviorSubject<SystemUser> {
    return this.systemUser;
  }

  setSystemUser(su: SystemUser): void {
    if (su.systemUserId && !su.termsOfUseAcceptedInd) {
      this.router.navigate([`/termsofuse/${su.systemUserId}`]);
    }
    this.systemUser.next(su);
  }

  initialAuthNavigate(su: SystemUser): void {
    if (!su.termsOfUseAcceptedInd) {
      return;
    }
    if (!su.memberId && su.userType.userTypeCode === 'M') {
      this.router.navigate(['/claim']);
    }
    if (su.userOrganizationRoles.some(r => r.userRoleName === 'HCA')) {
      this.router.navigate([`/dashboard/hca/${su.systemUserId}`]);
    } else if (su.userOrganizationRoles.some(r => r.userRoleName === 'Perspay')) {
      this.router.navigate([`/dashboard/perspay/${su.systemUserId}`]);
    } else if (su.userOrganizationRoles.some(r => r.userRoleName === 'Verifier')) {
      this.router.navigate([`/relationshipVerification/verifier/${su.systemUserId}`]);
    } else if (su.userOrganizationRoles.some(r => r.userAccessLevelCode === 'AM')) {
      this.router.navigate([`/subscriberManagement/${su.systemUserId}`]);
    } else {
      this.router.navigate([`/subscriber/${su.memberId}`]);
    }
  }

  systemUserHasAccess(accessLevelRequired: AccessLevel | string, userTypeCode?: UserTypeCode | string): boolean {
    const su = this.systemUser.value;
    const org = this.selectedOrganization.value;
    const userTypeCodeMatches = userTypeCode ? userTypeCode === su.userTypeCode : true;
    const acceptableAccessLevels = env.accessLists[accessLevelRequired];
    return (
      userTypeCodeMatches &&
      some(
        su.userOrganizationRoles,
        (r: UserOrganizationRole) => r.isActive && (r.userRoleName === 'HCA' || r.organizationId === org.organizationId) && acceptableAccessLevels.includes(r.userAccessLevelName)
      )
    );
  }

  systemUserIsSubscriber(subscriberId: string): boolean {
    return this.systemUser.value.memberId === subscriberId;
  }

  checkIfAdminWithinLowerLimit(lowerLimitDate: Date): void {
    if (some(this.systemUser.value.userOrganizationRoles, (ur: UserOrganizationRole) => env.accessLists.edit.includes(ur.userAccessLevelName))) {
      return (
        lowerLimitDate &&
        env.lowerLimitActiveForUpdates &&
        this.selectedOpenEnrollment.value &&
        dayjs(this.selectedOpenEnrollment.value.coverageEffectiveStartDate).isSameOrAfter(dayjs(lowerLimitDate), 'day') &&
        some(
          this.selectedSubscriber.value.enrollments,
          (e: Enrollment) => e.effectiveStartDate && dayjs(e.effectiveStartDate).isSame(this.selectedOpenEnrollment.value.coverageEffectiveStartDate, 'day')
        )
      );
    }
  }
  // SUBSCRIBER

  getSubscriber(): BehaviorSubject<Subscriber> {
    return this.selectedSubscriber;
  }

  getSubscriberValue(): Subscriber {
    return this.selectedSubscriber.value;
  }

  setSubscriber(sub: Subscriber): void {
    this.selectedSubscriber.next(sub);
  }

  async refetchSubscriber(memberId: string) {
    const sub = await lastValueFrom(this.subscriberService.getSubscriberById(memberId));
    this.setSubscriber(sub);
  }

  async refetchSubscriberWithSOE(memberId: string, soeId: string) {
    const sub = await lastValueFrom(this.subscriberService.getSubscriberWithSOE(memberId, soeId));
    this.setSubscriber(sub);
  }

  //checks to see if the current subscriber that is cached matches the member Id parameter, if so, then refetch the subscriber
  checkRefetchCurrentSubscriber(memberId: string) {
    var cachedSubscriber = this.getSubscriberValue();
    if (cachedSubscriber && cachedSubscriber?.memberId === memberId) {
      this.refetchSubscriber(memberId);
    }
  }

  // AUTH

  getTokenLastRefreshed(): number {
    return this.tokenLastRefreshed;
  }

  setTokenLastRefreshed(epochMs: number): void {
    this.tokenLastRefreshed = epochMs;
  }

  popMessage(message: string, type: 'none' | 'success' | 'warning' | 'info' | 'error', duration?: number, accessibilityMessage: string = ''): void {
    const notification: NotificationSettings = {
      content: NotificationComponent,
      animation: { type: 'slide', duration: 300 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: type, icon: false },
    };
    if (duration) {
      notification.hideAfter = duration;
    } else {
      notification.closable = true;
    }
    const notificationRef: NotificationRef = this.notificationService.show(notification);
    if (notificationRef) {
      notificationRef.content.instance.message = message;
      notificationRef.content.instance.accessibilityMessage = accessibilityMessage;
    }
  }

  // get set api version

  setAPIVersion(e: string): void {
    this.apiVersion = e;
  }

  getAPIVersion(): string {
    return this.apiVersion;
  }

  // enrollment period

  getEnrollmentPeriod(): BehaviorSubject<EnrollmentPeriod> {
    return this.selectedEnrollmentPeriod;
  }

  remapMilestoneSortOrder(ep: EnrollmentPeriod): EnrollmentPeriod {
    const selfPayRemap = [ 'Coverage', 'Dependents', 'Upload', 'Attestations', 'Supplemental Benefits', 'Confirmation' ];

    if (ep.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE') {
      // Self Pay adjusts Coverage to first item
      ep.milestones.forEach((m: Milestone) => m.sortOrder = selfPayRemap.indexOf(m.milestoneName) + 1);
    }

    return ep;
  }

  setEnrollmentPeriod(ep: EnrollmentPeriod): void {
    if (ep) {
      ep = this.remapMilestoneSortOrder(ep);
    }

    this.selectedEnrollmentPeriod.next(ep);
  }

  // milestone - for explicitly updating in wizard
  getCurrentMilestone(): BehaviorSubject<Milestone> {
    return this.selectedMilestone;
  }

  setCurrentMilestone(m: Milestone): void {
    this.selectedMilestone.next(m);
  }

  markFormControlsAsTouched(ngFormObj): void {
    if (!ngFormObj || !ngFormObj.controls)
      return;

    const keys = Object.keys(ngFormObj?.controls);
    for(let i = 0, key; key = keys[i]; i++) {
      const control = ngFormObj.controls[key];
      if (control) {
        control.markAsTouched();

        control?.updateValueAndValidity();

        if(control.invalid) {
                  //nativeElement.querySelector('[formcontrolname="' + key + '"]');
          if(control.nativeElement) {
            control.nativeElement.focus();
          }
          break;
        }
      }
    }
  }

  getInvalidFields(formContainer): string {
    let invalidFieldsList = '';
    try {
      const arrInvalidFieldsList = [];
      const arrIgnoreTags = ['KENDO-DATEPICKER', 'KENDO-DATETIMEPICKER'];
      formContainer.querySelectorAll('.ng-invalid').forEach(formElement => {
        if (formElement.localName !== 'form' && arrIgnoreTags.filter(o => o === formElement.tagName).length === 0) {
          if (formElement.tagName === 'DATE-PICKER' || formElement.tagName === 'KENDO-MASKEDTEXTBOX') {
            formElement = formElement.querySelector('input');
          }
          const formElementId = (formElement.id && formElement.id !== '' && formElement.type !== 'radio' ? formElement.id :
            (formElement.name === '' || formElement.name === undefined ? formElement.getAttribute('ng-reflect-name') : formElement.name)
          );

          let formElementLabel = formContainer.querySelector(`label[for='${formElementId}'],kendo-label[ng-reflect-for='${formElementId}']`);
          if (formElement.type === 'radio') {
            try {
              const formElementGroupLabel = formContainer.querySelector(`#${formElementId}_label`);
              formElementLabel = !formElementGroupLabel ?  formElementLabel : formElementGroupLabel;
            } catch (err) {
              console.log(err);
            }
          }
          const labelText = formElementLabel === null || !formElementLabel.textContent ? (formElement.name === '' ? formElement.getAttribute('ng-reflect-name') : formElement.name ) :
            (!formElementLabel.ariaLabel ? formElementLabel.textContent : formElementLabel.ariaLabel);
          if (arrInvalidFieldsList.filter(o => o === labelText).length === 0) {
            if (arrInvalidFieldsList.length === 0) {
              formElement.focus();
            }
            arrInvalidFieldsList.push(labelText);
          }
        }
      });
      arrInvalidFieldsList.forEach(invalidField => {
        if (invalidFieldsList === '') {
          invalidFieldsList = 'The following fields are invalid: ';
        } else {
          invalidFieldsList += ', ';
        }
        invalidFieldsList += invalidField;
      });
    }
    catch (err) {
      console.log(err);
    }
    return invalidFieldsList;
  }

  kendoGridStateToQueryString(gridState: State): string {
    const filterGridState = cloneDeep(gridState);
    if (filterGridState.filter?.filters.length > 0) {
      filterGridState.filter.filters.forEach( (f: CompositeFilterDescriptor) => {
        f.filters.forEach((cf: FilterDescriptor) => {
          const strFieldArr = cf.field.toString().split('.');
          let strField = '';
          strFieldArr.forEach(fieldItem => {
            strField = strField + (strField === '' ? '' : '.') + fieldItem.replace(/^./, str => str.toUpperCase());
          });
          cf.field = strField;
        });
      });
    }
    return `${toDataSourceRequestString(filterGridState)}`;
  }

  forceDataRefresh() {
    this.forceRefresh.next();
  }
}
