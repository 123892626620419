export default class RateStructure {
  rateStructureId: string;
  rateStructureCode: string;
  rateStructureName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(rateStructure?) {
    if (rateStructure) {
      this.rateStructureId = rateStructure.rateStructureId;
      this.rateStructureCode = rateStructure.rateStructureCode;
      this.rateStructureName = rateStructure.rateStructureName;
      this.obsoleteFlag = rateStructure.obsoleteFlag;
      this.obsoleteDate = rateStructure.obsoleteDate ? new Date(rateStructure.obsoleteDate) : null;
    }
  }
}
