<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state" role="group">
    <div class="card fxGap-medium-sm">
      <div *ngIf="step === 0" class="card-body">
        <soe-instructions [subscriber]="subscriber"></soe-instructions>
      </div>
      <div *ngIf="step === 0" class="card-body">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="mgbt-small">
          <h2 role="heading" class="mobile-text-centered mgtp-x-small mgbt-medium-sm">Account changes</h2>
        </div>
        <div>
          <p>
            This page also allows you to report other changes and information. For example, you can report a death of a dependent, a divorce, or a change of address at any time.
          </p>
        </div>
      </div>
      <div *ngIf="step === 0" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="h4">Select the check-box to the left to manage the event and/or view requests</h3>
        <div class="pad-std-container">
          <soe-grid 
            (clearSelectedSOE)="clearSelectedSOE()" 
            [isReadOnly]="isReadOnly" 
            (downloadCoveragePDF)="downloadCoveragePDF($event)" 
            (cancelSOE)="cancelSOE($event)" 
            (editSOE)="editSOE($event)" 
            [SOEs]="subscriber?.specialOpenEnrollments" 
            [subscriber]="subscriber"
            [isHCAEdit]="isHCAEdit">
          </soe-grid>
        </div>

        <div *ngIf="subscriberHasActiveSoe" style="margin:0 20px;">
          <div *ngIf="subscriberHasActiveSoe">
            <h3 class="h4">
              Only one event can be in pending, draft, or submitted status at any given time.  You cannot create a new event until
              the current event is verified, denied, or cancelled.  You can cancel an event using the "cancel" button from the list above.
            </h3>
          </div>
        </div>
        <div *ngIf="!subscriberHasActiveSoe" style="margin:0 20px;">
          <h3 class="h4">Select the Special Open Enrollment from the drop-down list below</h3>
          <soe-add
            class="mgtp-medium-lg mgbt-medium-lg"
            [subscriber]="subscriber"
            [soeVerificationStatus]="lookups.specialOpenEnrollmentVerificationStatus"
            (saveSOE)="saveSOE($event)"
            [canOverride]="canOverride"
            [isHCA]="isHCA"
            [specialOpenEnrollmentTypes]="lookups.specialOpenEnrollmentTypes"></soe-add>

          <h3 class="h4">Select other event or account change from the drop-down list below</h3>
          <soe-add
            style="width:100%;"
            class="mgtp-medium-lg mgbt-medium-lg"
            [subscriber]="subscriber"
            [soeVerificationStatus]="lookups.specialOpenEnrollmentVerificationStatus"
            [dateMessageMap]="otherDateMap"
            (saveSOE)="saveSOE($event)"
            [canOverride]="canOverride"
            [isHCA]="isHCA"
            [specialOpenEnrollmentTypes]="lookups.otherSpecialOpenEnrollmentTypes"></soe-add>
        </div>
      </div>
      <div fxLayout="column" class="hca-standard-font" *ngIf="subscriber?.soeEnrollmentPeriod">
        <div class="pad-std-container">
          <wiz-dual-enroll *ngIf="isDualEnrolledForPeriod" 
            [hideBackToDash]="true" 
            [subscriber]="subscriber"
            [dualEnrollment]="dualEnrollment" 
            (waive)="waivePlansDueToDualEnrollment()" 
            (continue)="continueDualEnroll()">
          </wiz-dual-enroll>
        </div>
        <router-outlet style="margin-bottom: 25px" fxLayoutAlign="center center" #wizardOutlet id="wizardOutlet" *ngIf="!isDualEnrolledForPeriod && selectedSpecialOpenEnrollment?.coverageEffectiveDate && subscriber?.soeEnrollmentPeriod">
          <pebb-wizard 
            [isSOE]="false" 
            *ngIf="selectedSpecialOpenEnrollment?.coverageEffectiveDate && subscriber?.soeEnrollmentPeriod && !isDeathOrDivorceSoe" 
            [subscriber]="subscriber" 
            [enabledMilestoneSteps]="enabledMilestoneSteps" 
            [enrollmentPeriod]="subscriber?.soeEnrollmentPeriod">
          </pebb-wizard>
        </router-outlet>
      </div>  
      <div *ngIf="step === 0 && !isDeathOrDivorceSoe" fxLayout="row" class="pad-std-container">
        <div fxFlex="100">
          <p>
            For information regarding special open enrollment events (eligibility, what changes can be made, etc) please see
            <a *ngIf="subscriber?.agency == 'PEBB'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/change-your-coverage#Special-open-enrollment.">PEBB special open enrollment.</a>
            <a *ngIf="subscriber?.agency == 'SEBB'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/special-open-enrollment">SEBB special open enrollment.</a>
          </p>
        </div>
      </div>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
        <button style="margin: 30px" (click)="navToDashboard()" class="btn btn-secondary">Back to dashboard</button>
      </div>
    </div>
    <pdf *ngIf="subscriberSummary" #coveragePDF [documentTitleText]="documentTitleText" [documentHeaderText]="documentHeaderText">
      <coverage-summary
        [forPdf]="true"
        [documentTitleText]="documentTitleText"
        [documentHeaderText]="documentHeaderText"
        [enrolledHeader]="enrolledHeader"
        [subscriber]="subscriber"
        [summaryObject]="subscriberSummary"
      ></coverage-summary>
    </pdf>
  </div>
</div>
