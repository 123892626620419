import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core';
import { DataStateChangeEvent, DetailExpandEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, orderBy, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { cloneDeep, filter, forEach, findIndex, find } from 'lodash';
import { GridComponent } from '@progress/kendo-angular-grid';
import SystemUser from 'src/app/models/user';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import { CoreService } from 'src/app/services/core.service';
import { PDFComponent } from 'src/app/modules/shared/components/pdf/pdf.component';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { lastValueFrom } from 'rxjs';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';

@Component({
  selector: 'soereview-admin-grid',
  templateUrl: 'soeReview.admin.grid.component.html',
  styleUrls: [],
  providers: [],
})
export class SoeReviewAdminGridComponent implements OnInit, OnChanges {
  @Input() gridData: GridDataResult;
  @Input() state: State;
  @Input() gridColumns;
  @Input() selectable?: boolean;
  @Input() filterable = false;
  @Input() systemUser: SystemUser;
  @Input() lookups = {
    specialOpenEnrollmentVerificationStatus: [],
    documentTypes: [],
  };
  @Input() isHCAAdmin: boolean;
  verifyValues: SpecialOpenEnrollmentVerificationStatus[] = [];
  denyValues: SpecialOpenEnrollmentVerificationStatus[] = [];
  expandedDetailKeys = [];
  sort: SortDescriptor[] = [];
  filter: FilterDescriptor;
  soeForPdf: SpecialOpenEnrollment;
  selectedKeys = [];
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;
  @Output() pushGetData: EventEmitter<State> = new EventEmitter<State>();
  @Output() soeSelected: EventEmitter<GridDataResult> = new EventEmitter<GridDataResult>();
  @ViewChild('soePDF') soePDF: PDFComponent;

  constructor(
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
  }

  reSetDataForGrid(): void {
    this.pushGetData.emit(this.state);
  }

  public dataStateChange(state: DataStateChangeEvent | State): void {
    this.state = state;
    this.pushGetData.emit(this.state);
  }

  /// necessary because having it in the actual detail row was borking the grid
  downloadSOEPDF(soeForPdf): void {
    this.soeForPdf = soeForPdf;
    // need to park and revisit this for now - but may be achieveable -
      // opens fine in modal
      // needs size changes so scrollable / non blocking
      // needs draggable
    // const modalRef = this.modalService.open(SOEPDFComponent, {
    //   size: 'xl',
    //   backdrop: false,
    //   windowClass: 'soe-modal-window'
    // });
    // modalRef.componentInstance.soe = this.dataItem;

    // set timeout because of ngif and digest cycle
    setTimeout(() => {
      this.soePDF.pdfElement.saveAs('SpecialOpenEnrollment.pdf');
    });
  }

  public changeSelection(e): void {
    let item = null;
    if (e.selectedRows.length) {
      item = e.selectedRows[0].dataItem;
    }

    this.soeSelected.emit(item);
  }

  collapseAllRows() {
    for(let i=0;i<this.gridData?.data?.length;i++ ) {
      this.kendoGrid.collapseRow(i);
    }
  }
}
