// ng
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// ext
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// local
import { SelfPayComponent } from './selfpay.component';
import { SelfPayGridComponent } from './components/grid/selfPay.grid.component';
import { SelfPayInfoComponent } from './components/info/selfPay.info.component';
import { SelfPayPaymentComponent } from './components/payment/selfPay.payment.component';
import { SelfPayAddComponent } from './components/add/selfPay.add.component';
import { SelfPayUploadComponent } from './components/upload/selfPay.upload.component';
import { SelfPayDependentComponent } from './components/dependent/selfPay.dependent.component';
import { SelfPayExistingDependentComponent } from './components/dependent/existingDependent/selfPay.existing.dependent.component';
import { SelfPayNewDependentComponent } from './components/dependent/newDependent/selfPay.new.dependent.component';
import { SelfPayAddSpouseComponent } from './components/dependent/addSpouse/selfPay.addSpouse.component';
import { SelfPayElectMedicalComponent } from './components/electMedical/selfPay.electMedical.component';
import { SelfPayElectDentalComponent } from './components/electDental/selfPay.electDental.component';
import { SelfPayElectVisionComponent } from './components/electVision/selfPay.electVision.component';
import { SelfPayConfirmComponent } from './components/confirm/selfPay.confirm.component';
import { SelfPaySubscriberComponent } from './components/subscriber/selfPay.subscriber.component';
import { SelfPayReasonComponent } from './components/reason/selfPay.reason.component';
import { SelfPayChangeOfAddressComponent } from './components/customForm/changeOfAddress/selfpay.changeofaddress.component'

@NgModule({
  declarations: [
    SelfPayComponent,
    SelfPayGridComponent,
    SelfPayInfoComponent,
    SelfPayPaymentComponent,
    SelfPayAddComponent,
    SelfPayUploadComponent,
    SelfPayDependentComponent,
    SelfPayExistingDependentComponent,
    SelfPayNewDependentComponent,
    SelfPayAddSpouseComponent,
    SelfPayElectMedicalComponent,
    SelfPayElectDentalComponent,
    SelfPayElectVisionComponent,
    SelfPayConfirmComponent,
    SelfPaySubscriberComponent,
    SelfPayReasonComponent,
    SelfPayChangeOfAddressComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    NgbModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    PDFExportModule,
    ProgressBarModule,
    UploadModule,
    OverlayModule,
    FontAwesomeModule

  ],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
  bootstrap: [],
  exports: [ SelfPayPaymentComponent ]
})
export class SelfPayModule {}