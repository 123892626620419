export default class ReportType {
  reportTypeId: string;
  reportTypeCode: string;
  reportTypeName: String;

  constructor(reportType?) {
    if (reportType) {
      this.reportTypeId = reportType.reportTypeId;
      this.reportTypeCode = reportType.reportTypeCode;
      this.reportTypeName = reportType.reportTypeName;
    }
  }
}
