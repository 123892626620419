import SystemUser from './user';

export default class MemberNote {
  memberNoteId: string;
  memberId: string;
  noteText: string;
  createdById: string;
  createdBy: SystemUser;
  createdDate: Date;
  createdByName: string;
  constructor(memberNote?) {
    if (memberNote) {
      this.memberNoteId = memberNote.memberNoteId;
      this.memberId = memberNote.memberId;
      this.noteText = memberNote.noteText;
      this.createdById = memberNote.createdById;
      this.createdBy = memberNote.createdBy ? new SystemUser(memberNote.createdBy) : null;
      this.createdDate = memberNote.createdDate ? new Date(memberNote.createdDate) : null;
      this.createdByName = `${this.createdBy?.firstName || ''} ${this.createdBy?.lastName || ''}`;
    }
  }

}
