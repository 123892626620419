<div fxFlexLayout="column" fxFlexLayoutGap="30px" class="mgbt-medium">
  <h3 class="h5" fxFlexOffset="1">
    Welcome to the Report Tool. This tool allows you to select your report, define your search criteria, and select the specific fields you wish 
    to see displayed in the Results Grid.
  </h3>
</div>
<div fxLayout="row" *ngIf="showAgencyPicker" class="col-md-5">
  <program-filter *ngIf="!selfPayOnly" [agencyFilter]="agencyFilter" (agencyFilterChange)="agencyFilterChange($event)"></program-filter>
</div>
<div fxLayout="row">
  <div class="form-group col-md-5 required">
    <label for="selectedReport">Select a report</label>
    <select required class="form-control" name="selectedReport" id="selectedReport" [(ngModel)]="selectedReport" (change)="updateSelectedReport()">
      <option [ngValue]="null" ></option>
      <option *ngFor="let report of visibleReports" [ngValue]="report">{{report.reportName}}</option>
    </select>
  </div>
  <div *ngIf="selectedReport" class="form-group col-md-5">
    <p>{{selectedReport.reportDescription}}</p>
  </div>
</div>
  <form #reportsForm="ngForm">
  <div fxLayout="row wrap" *ngIf="showParameters">
    <div *ngFor="let param of reportParameters; let i = index;" class="form-group col-md-5" [class.required]="param.required">
      <label [for]="param.parameterField">{{param.parameterName}}</label>
      <select [required]="param.required" *ngIf="param.lookupTableName" class="form-control" [name]="param.parameterField" [id]="param.parameterField" [(ngModel)]="reportFilters[param.parameterField]">
        <option *ngIf="param.lookupTableName === 'contactType'" [ngValue]="'A'" >All</option>
        <option *ngIf="!param.required" [ngValue]="''">All</option>
        <option *ngFor="let p of lookups[param.lookupTableName]" [ngValue]="p[param.parameterField]">{{p[param.lookupField]}}</option>
      </select>
      <select required *ngIf="param.dataType === 'tripleState' && param.parameterField === 'subscriberAndOrDependents'" class="form-control" [name]="param.parameterField" [id]="param.parameterField" [(ngModel)]="reportFilters[param.parameterField]">
        <option [ngValue]="'All'" selected>All</option>
        <option [ngValue]="'Subscribers Only'">Subscribers Only</option>
        <option [ngValue]="'Dependents Only'">Dependents Only</option>
      </select>
      <input [required]="param.required" *ngIf="param.dataType === 'text'" class="form-control" type="text" [(ngModel)]="reportFilters[param.parameterField]" [name]="param.parameterField" [id]="param.parameterField">
      <input [required]="param.required" *ngIf="param.dataType === 'year'" class="form-control" type="text" minlength="4" maxLength="4" pattern="\d{4}" [(ngModel)]="reportFilters[param.parameterField]" [name]="param.parameterField" [id]="param.parameterField" (change)="yearChanged(param.parameterField)">
      <div *ngIf="param.dataType === 'date'" class="input-group">
        <date-picker
          #paramDate="ngModel"
          [name]="param.parameterField"
          [required]="param.required"
          [(ngModel)]="reportFilters[param.parameterField]"></date-picker>
      </div>
      <select [required]="param.required" *ngIf="param.dataType === 'tripleState' && param.parameterField === 'represented'" class="form-control" [name]="param.parameterField" [id]="param.parameterField" [(ngModel)]="reportFilters[param.parameterField]">
        <option *ngFor="let p of tripleStateValues" [ngValue]="p">{{p}}</option>
      </select>
      <select [required]="param.required" *ngIf="param.dataType === 'tripleState' && param.parameterField === 'active'" class="form-control" [name]="param.parameterField" [id]="param.parameterField" [(ngModel)]="reportFilters[param.parameterField]">
        <option *ngFor="let p of tripleStateValues" [ngValue]="p">{{p}}</option>
      </select>
    </div>
  </div>
</form>