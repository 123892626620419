<form nativeControlElement (ngSubmit)="submitDependent(formContainer)" *ngIf="dependent" #memberForm="ngForm" fxLayout="column" class="row-spacing-container"
  fxLayoutAlign="start start" fxFlexFill #formContainer>
  <h2 class="mgbt-large" style="text-align: center; align-self: center;">Dependent information</h2>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <span>
      You may enroll your legal spouse, state-registered domestic partner, or your children. If your dependent is eligible to enroll in both the PEBB and SEBB Programs, they are limited to a single enrollment in either PEBB or SEBB health plans. 
      <br/><br/>
      State-registered domestic partner is defined in WAC <span *ngIf="!isSebb">182-12-109</span><a target="_blank" href="https://apps.leg.wa.gov/wac/default.aspx?cite=182-31-020" *ngIf="isSebb">182-31-020</a>. Individuals in state-registered domestic partnerships are treated the same as legal spouses except when in conflict with federal law.
      <br><br/>
      Children must be eligible under {{subscriber?.agencyCode}} Program rules. This includes children through the month of their 26th birthday, regardless of marital status, student status or eligibility for coverage under another plan, and children age 26 or older with a disability. <a [href]="isSEBB ? 'https://www.hca.wa.gov/employee-retiree-benefits/school-employees/are-my-dependents-eligible' : 'https://www.hca.wa.gov/employee-retiree-benefits/public-employees/are-my-dependents-eligible'" target="_blank">Learn more about eligible dependents</a>.
      <br/><br/>
      When adding dependents, you must provide proof of their eligibility within the {{subscriber?.agencyCode}} Program's enrollment timelines or they will not be enrolled. Dependent children with a disability who are over the age of 26  must be certified by the {{subscriber?.agencyCode}} Program before they can be enrolled in coverage.  Timelines and a list of documents we will accept to verify eligibility are available on HCA’s website under <a [href]="isSEBB ? 'https://www.hca.wa.gov/employee-retiree-benefits/school-employees/verify-and-enroll-my-dependents' : 'https://www.hca.wa.gov/employee-retiree-benefits/public-employees/verify-and-enroll-my-dependents'" target="_blank">Verify and enroll my dependents</a>.
      <br/><br/>
    </span>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group required" fxFlex="25">
      <label for="firstName">First name</label>
      <input maxlength="15" pattern="^(?!\s)([a-zA-Z \-]){1,}$" required type="text" class="form-control" 
      id="firstName" name="firstName" [(ngModel)]="dependent.firstName" />
    </div>
    <div class="form-group required" fxFlex="30">
      <label for="lastName">Last name</label>
      <input maxlength="20" pattern="^(?!\s)([a-zA-Z \-]){1,}$" required type="text" class="form-control" 
      id="lastName" name="lastName" [(ngModel)]="dependent.lastName" />
    </div>
    <div class="form-group" fxFlex="25">
      <label for="middleName">Middle name</label>
      <input type="text" pattern="^(?!\s)([a-zA-Z \-]){1,}$" maxLength="15" class="form-control" 
      id="middleName" name="middleName" [(ngModel)]="dependent.middleName" />
    </div>
    <div class="form-group" fxFlex="10">
      <label for="suffix">Suffix</label>
      <input pattern="[a-zA-Z]*" type="text" class="form-control" 
        id="suffix" name="suffix" [(ngModel)]="dependent.suffix" placeholder="JR, SR" />
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlex="100">
    <div class="form-group" fxFlex="20">
      <div class="input-group">
        <date-picker
          #birthDate="ngModel"
          name="birthDate"
          label="Birth date"
          [min]="minBirthDate"
          [max]="dependent.relationshipTypeId === spousalRelationshipTypeId ? maxBirthdateForSpouse : now"
          [required]="true"
          [disabled]="dependent.memberId && !this.isPending && !dependent.member?.relationshipToSubscriber?.isPendingRelationship"
          [(ngModel)]="dependent.birthDate"
          (ngModelChange)="updateBirthDate($event)"></date-picker>
      </div>
      <div *ngIf="birthDate.invalid && (birthDate.touched) && dependent.relationshipTypeId !== spousalRelationshipTypeId" class="alert alert-warning">
        Birth date is required and cannot be in the future or more than 110 years in the past.
      </div>
      <div *ngIf="birthDate.invalid && (birthDate.touched) && dependent.relationshipTypeId === spousalRelationshipTypeId" class="alert alert-warning">
        Spouse's birth date is required and must be greater than 14 years in the past and less than 110 years.
      </div>
    </div>
    <div class="form-group required" fxFlex="25">
      <label for="birthSexId">Sex assigned at birth</label>
      <select required class="form-control" id="birthSexId" name="birthSexId" [(ngModel)]="dependent.birthSexId">
        <option></option>
        <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
      </select>
    </div>
    <div class="form-group required" fxFlex="25">
      <label for="genderIdentityId">Gender Identity</label>
      <select class="form-control" id="genderIdentityId" name="genderIdentityId" 
        [(ngModel)]="dependent.genderIdentityId"
        required>
        <option></option>
        <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
      </select>
      <div class="mgtp-x-small">
        Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law. To learn more, visit 
        <span *ngIf="isSebb">the <a target="_blank" href="https://www.hca.wa.gov/about-hca/gender-identity-information">Gender X webpage</a> on HCA's website.</span>
        <span *ngIf="!isSebb"><a target="_blank" href="https://www.hca.wa.gov/about-hca/gender-identity-information">HCA's Gender X webpage</a>.</span>
      </div>
    </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group required" fxFlex="25">
      <label for="relationshipTypeId">Relation</label>
      <select [disabled]="dependent.memberId && !this.isPending && !dependent.member?.relationshipToSubscriber?.isPendingRelationship" 
        [(ngModel)]="dependent.relationshipTypeId" (change)="changeRelationshipType($event)" 
        required class="form-control" 
        name="relationshipTypeId"
        id="relationshipTypeId">
        <option></option>
        <option *ngFor="let rt of lookups.relationshipTypes | isObsolete" [ngValue]="rt.relationshipTypeId">{{ rt.relationshipTypeName }}</option>
      </select>
    </div>
    <div *ngIf="dependent.relationshipTypeId && dependent.relationshipTypeId === spousalRelationshipTypeId" class="form-group" fxFlex="20">
      <date-picker
        #psd="ngModel"
        id="psd"
        name="psd"
        label="Partnership start date"
        required
        [disabled]="dependent.memberId && !isPending"
        [min]="dependent.birthDate"
        [canBeInFuture]="false"
        [(ngModel)]="dependent.partnershipStartDate"></date-picker>
    </div>
    <div class="form-group required" fxFlex="25">
      <label for="relationshipQualifyReasonId">Qualify reason</label>
      <select [disabled]="!selectedRelationshipType || (dependent.memberId && !this.isPending)|| selectedRelationshipType.relationshipTypeCode !== 'S'" 
        required class="form-control" #relationshipQualifyReason="ngModel" 
        name="relationshipQualifyReasonId" 
        id="relationshipQualifyReasonId" 
        [(ngModel)]="dependent.relationshipQualifyReasonId">
        <option></option>
        <option                                                                                                                                                                                                                                             
          *ngFor="                      
            let qr of lookups.relationshipQualifyReasons
              | isObsolete
              | allowedValues
                : allowedRelationshipQualifyReasons
                : 'relationshipQualifyReasonCode'
          "
          [ngValue]="qr.relationshipQualifyReasonId"                                         
        >
          {{ qr.relationshipQualifyReasonName }}
        </option>
      </select>
    <div *ngIf="relationshipQualifyReason.invalid && (relationshipQualifyReason.touched || relationshipQualifyReason.dirty)" class="alert alert-danger">
        Qualify reason missing or may be invalid based on relationship type and/or birthdate entered, please re-enter a valid qualify reason.
    </div>
    </div>
    <div class="form-group required" fxFlex="20">
      <label id="socialSecurityNumberLbl" for="socialSecurityNumber">SSN</label>
      <kendo-maskedtextbox
        [mask]="env.ssnMask"
        [pattern]="env.ssnItinRegex"
        [ngModel]="(!noSSN ? dependent.socialSecurityNumber : null)"
        [maskOnFocus]="true"
        required
        name="socialSecurityNumber"
        id="socialSecurityNumber"
        aria-labelledby="socialSecurityNumberLbl"
        #ssn="ngModel"
        class="form-control"
        [forbiddenValues]="env.invalidSSNValues"
        [disabled]="noSSN"
        (ngModelChange)="dependent.socialSecurityNumber = $event"
      ></kendo-maskedtextbox>
      <div *ngIf="ssn.invalid && ssn.touched && (!!ssn.errors.forbiddenValues || !!ssn.errors.pattern)" class="alert alert-danger">This SSN is invalid.</div>
      <div *ngIf="ssn?.errors?.existsOnSubscriber" class="alert alert-danger">The dependent is already associated to this subscriber account. Correct dependent demographics or cancel adding this dependent.</div>
      <div class="k-form-field mgtp-x-small">
        <checkbox (change)="handleNoSSN($event)" name="noSSN" [(ngModel)]="noSSN" bold>This person currently has no Social Security number</checkbox>
      </div>
      <div *ngIf="noSSN" class="form-group" fxFill="40">
        <br />
        <strong>You will be required to update this information once available.</strong>
      </div>
    </div>
  </div>

    <br />
    <div class="mgbt-medium" *ngIf="dependent.relationshipQualifyReasonId && extendedOrDisabledRelationshipQualifyIds.includes(dependent.relationshipQualifyReasonId)">
      <span *ngIf="!isSebb">
        <span>
          If enrolling a dependent child with a disability age 26 or older,  you must also complete the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=50-0142&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_be">PEBB Certification of a Dependent with a Disability</a>
          and return it as instructed on the form or your child will not be enrolled in coverage. Refer to the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=50-0500&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_be">PEBB Employee Enrollment Guide</a> for eligibility information.
        </span>
<        <br />
        <br />
        <span>If enrolling an extended dependent that is also a child with a disability who is age 26 or older you must submit both a PEBB Certification of a Child with a Disability and a PEBB Extended Dependent Certification.</span>
        <br />
        <br />
        <span>If enrolling an extended dependent also upload a <a href="https://www.hca.wa.gov/assets/pebb/50-0500-pebb-extended-dependent-certification-2022.pdf" target="_blank">PEBB Extended Dependent Certification Form</a>.</span>
        <br />
        <br />
      </span>
      <span *ngIf="isSebb">
        <span>
          If enrolling a dependent child with a disability age 26 or older,  you must also complete the <a target="_blank" href="https://www.hca.wa.gov/assets/pebb/20-0061-sebb-certification-child-with-disability.pdf">SEBB Certification of a Dependent with a Disability</a>
          and return it as instructed on the form or your child will not be enrolled in coverage. Refer to the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=20-0049&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_be">School Employee Enrollment Guide</a> for eligibility information.
        </span>
<        <br />
        <br />
        <span>If enrolling an extended dependent that is also a child with a disability who is age 26 or older you must submit both a PEBB Certification of a Child with a Disability and a SEBB Extended Dependent Certification.</span>
        <br />
        <br />
        <span>If enrolling an extended dependent also upload a <a href="https://www.hca.wa.gov/assets/pebb/20-0084-sebb-extended-dependent-certification.pdf" target="_blank">SEBB Extended Dependent Certification Form</a>.</span>
        <br />
        <br />
      </span>
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" style="width:100%;">
    <checkbox name="addressIsSameAsSubscriberInd" bold [(ngModel)]="dependent.addressIsSameAsSubscriberInd">Residential address is the same as subscriber</checkbox>
  </div>
  <!-- ADDRESS -->
  <div fxFlexFill *ngIf="!dependent.addressIsSameAsSubscriberInd">
    <address #residentialAddress name="residentialAddress" label="Residential address" hideCounty [(ngModel)]="dependent"></address>
  </div>
  <!-- MEDICARE -->
  <span *ngIf="selfPay" class="soe-form-small-header">Medicare information</span>
  <medicare *ngIf="selfPay" #medicareComponent fxFlexFill [medicareOptions]="lookups.medicareOptions" 
    [simpleMember]="dependent"  
    [(memberMedicare)]="dependent.memberMedicare" [enrollmentSignatureMinDate]="formCSignatureMinDate">
  </medicare>
  <br />
  <div fxFlexFill fxLayout="row" fxLayoutAlign="end end">
    <button fxFlex="30" id="sub-add-submit-btn" type="submit" class="btn btn-primary">Let's add coverage to your dependent</button>
    <!-- <button fxFlex="30" id="sub-add-cancel-btn" (click)="cancelAdd.emit()" class="btn btn-light">Cancel</button> -->
  </div>
</form>
