import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';


@Component({
  selector: 'upload-confirmation',
  templateUrl: 'upload.confirmation.component.html',
})

export class UploadConfirmationComponent implements OnInit {
  inAdminState: boolean;
  @Input() label = 'Proceed to selecting coverage';
  @Output() confirmation: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
  }
}
