import Document from './document';
import SOEForm from './soeForm';
import Member from './member';
import SpecialOpenEnrollmentType from './specialOpenEnrollmentType';
import { map, cloneDeep } from 'lodash';
import SpecialOpenEnrollmentVerificationStatus from './specialOpenEnrollmentVerificationStatus';
import * as dayjs from 'dayjs';
import EnrollmentPeriod from './enrollmentPeriod';
import Subscriber from './subscriber';

export default class SpecialOpenEnrollmentActionLog {
  specialOpenEnrollmentId: string;
  memberId: string;
  member: Member;
  isSubscriberInd: boolean;
  message: string;

  constructor(log) {
    if (log) {
      this.specialOpenEnrollmentId = log.specialOpenEnrollmentId;
      this.memberId = log.memberId;
      this.member = log.member;
      this.isSubscriberInd = log.isSubscriberInd;
      this.message = log.message;
    }
  }
}
