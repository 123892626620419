<div class="card">
  <div class="card-body">
    <h2 class="mobile-text-centered" style="margin-top: 8px; margin-bottom: 15px;">Members Associated with this Account</h2>
    <div style="margin-bottom: 10px;">
      <ngb-accordion (panelChange)="updatePanelsExpanded($event)" [activeIds]="activePanels" [destroyOnHide]="false" #memberAccord="ngbAccordion" fxFlex>
        <ngb-panel [id]="member.memberId" *ngFor="let member of members; let index = index">
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
              <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
              <span *ngIf="member.lastName && member.firstName">{{ member.lastName }}, {{ member.firstName }}</span>
              <span class="float-right" *ngIf="member.isSubscriberInd">Subscriber</span>
              <span class="float-right" *ngIf="!member.isSubscriberInd">Dependent</span>
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <div fxLayout="column" fxFlex="100">
              <div class="form-row hca-row-margin" fxLayoutAlign="start center">
                <div class="form-group" fxFlex="100" fxFlex.gt-sm="30">
                  <label for="memberSocialSecurityNumber{{ index }}" style="font-weight: bold;">SSN:</label>
                  <input type="text" readonly class="form-control-plaintext" id="memberSocialSecurityNumber{{ index }}" value="{{ member.socialSecurityNumber }}" />
                </div>
                <div class="form-group" fxFlex="100" fxFlex.gt-sm="30">
                  <label for="memberName{{ index }}" style="font-weight: bold;">Name:</label>
                  <input type="text" readonly class="form-control-plaintext" id="memberName{{ index }}" value="{{ member.lastName }}, {{ member.firstName }}" />
                </div>
                <div fxFlex></div>
                <div class="form-group" fxFlex="100" fxFlex.gt-sm="30" *ngIf="member.isSubscriberInd && currentEmployment">
                  <label for="memberSubagency{{ index }}" style="font-weight: bold;">SEBB Org:</label>
                  <input
                    type="text"
                    readonly
                    class="form-control-plaintext"
                    id="memberSubagency{{ index }}"
                    value="{{ currentEmployment.subagency ? currentEmployment.subagency.subagencyName : '' }}"
                  />
                </div>
              </div>
              <!-- <div class="form-row hca-row-margin" fxLayoutAlign="end center" fxFlex="25">
                <div style="display: block;" fxLayoutGap="5px" fxLayoutAlign="flex-end">
                  <button *ngIf="member.isSubscriberInd" (click)="deactivateAccount()" class="btn hca-green-background">
                    Deactivate Account
                  </button>
                </div>
              </div> -->
              <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false" (navChange)="setTab($event)" [activeId]="activeTab" type="pills">
                <li [ngbNavItem]="1" [id]="subscriber.memberId + 'enrollments'" title="Enrollments">
                  <a ngbNavLink>Enrollments</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-enrollment
                      [enrollments]="member.enrollments"
                      [index]="index"
                      [editable]="true"
                      [lookups]="lookups"
                      [availablePlans]="availablePlans"
                      [memberId]="member.memberId"
                      [subscriberId]="subscriber.memberId"
                      [subscriber]="subscriber"
                      [isSubscriber]="member.isSubscriberInd"
                      (pushSaveEnrollments)="saveEnrollmentChanges($event, index)"
                      (pushRemoveRecord)="removeEnrollment($event, index)"
                      (updatePanelsExpanded)="updateCoveragePanelsExpanded($event)"
                      [activePanels]="activeCoveragePanels"
                      [supplementalPlan]="supplementalPlan"
                      [basicPlan]="basicPlan"
                      [lifePlan]="lifePlan"
                      [adPlan]="adPlan"
                      [enrollmentPeriods]="enrollmentPeriods"
                    ></account-correction-edit-enrollment>
                  </ng-template>
                </li>
                <!-- <li [ngbNavItem]="2" [id]="subscriber.memberId + 'employment'" title="Employment" *ngIf="member.isSubscriberInd">
                  <a ngbNavLink>Employment</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-employment
                      #editEmployment
                      [subscriberId]="subscriber.memberId"
                      [editable]="editable"
                      [employments]="member.employments"
                      [subagencies]="lookups.subagencies"
                      (employmentDataChange)="updateEmployment($event)"
                      (employmentRemove)="removeEmployment($event)"
                    ></account-correction-edit-employment>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3" [id]="subscriber.memberId + 'eligiblility'" title="Eligibility" *ngIf="member.isSubscriberInd">
                  <a ngbNavLink>Eligibility</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-eligibility
                      [memberId]="subscriber.memberId"
                      [editable]="editable"
                      [memberMemberTypes]="subscriber.memberMemberTypes"
                      [lookups]="lookups"
                      (pushUpdateEligibility)="updateEligibility($event)"
                      (removeEligibility)="removeEligibility($event)"
                    ></account-correction-edit-eligibility>
                  </ng-template>
                </li>
                <li [ngbNavItem]="4" [id]="subscriber.memberId + 'relationships'" title="Relationships">
                  <a ngbNavLink>Relationship</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-relationship
                      [memberId]="member.memberId"
                      [editable]="editable"
                      [secondaryRelationships]="member.secondaryRelationships"
                      [primaryRelationships]="member.primaryRelationships"
                      [lookups]="lookups"
                      (pushUpdateRelationship)="updateRelationship($event)"
                      (removeRelationship)="removeRelationship($event)"
                    >
                    </account-correction-edit-relationship>
                  </ng-template>
                </li>
                <li [ngbNavItem]="5" [id]="subscriber.memberId + 'addresses'" title="Addresses">
                  <a ngbNavLink>Addresses</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-addresses
                      [addresses]="member.memberAddresses"
                      [editable]="editable"
                      [lookups]="lookups"
                      [memberId]="member.memberId"
                      (pushUpdateAddress)="updateAddresses($event)"
                      (removeAddress)="removeAddress($event)"
                    ></account-correction-edit-addresses>
                  </ng-template>
                </li>
                <li [ngbNavItem]="6" [id]="subscriber.memberId + 'marriage'" title="Marriage" *ngIf="member.isSubscriberInd">
                  <a ngbNavLink>Marriage</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-maritalstatus
                      [memberId]="member.memberId"
                      [editable]="editable"
                      [maritalStatuses]="member.memberMaritalStatuses"
                      (pushUpdateMaritalStatus)="updateMarriages($event)"
                      (removeMaritalStatus)="removeMarriage($event)"
                    ></account-correction-edit-maritalstatus>
                  </ng-template>
                </li>
                <li [ngbNavItem]="7" [id]="subscriber.memberId + 'attestation'" title="Attestation">
                  <a ngbNavLink>Attestation</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-attestation
                      [isSubscriber]="member.isSubscriberInd"
                      [editable]="editable"
                      [memberId]="member.memberId"
                      [(attestations)]="member.attestations"
                      [responses]="lookups.responses"
                      [attestationTypeLookup]="lookups.attestationTypes"
                      (pushUpdateAttestation)="updateAttestations($event)"
                      (removeAttestation)="removeAttestation($event)"
                    ></account-correction-edit-attestation>
                  </ng-template>
                </li> -->
                <li [ngbNavItem]="8" [id]="subscriber.memberId + 'notes'" title="Notes">
                  <a ngbNavLink>Subscriber Notes</a>
                  <ng-template ngbNavContent>
                    <account-correction-edit-notes
                      #editNote
                      [editable]="editable"
                      [index]="index"
                      [memberId]="member.memberId"
                      [memberNotes]="subscriber.memberNotes"
                      [systemUser]="systemUser"
                      [subscriber]="subscriber"
                      (noteSave)="saveNote($event, index)"
                    ></account-correction-edit-notes>
                  </ng-template>
                </li>
                <li [ngbNavItem]="9" [id]="subscriber.memberId + 'enrollmentPeriods'" title="Enrollment Periods">
                  <a ngbNavLink>Enrollment Periods</a>
                  <ng-template ngbNavContent>
                    <div fxLayout="column" class="pad-std-container">
                      <kendo-grid
                        [data]="enrollmentPeriods"
                        [pageable]="false"
                        [selectable]="false"
                      >
                        <kendo-grid-column title="Period Type">
                          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                              {{dataItem.enrollmentPeriodType.enrollmentPeriodTypeName}}
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Start Date">
                          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                              {{dataItem.effectiveStartDate | date: 'MM/dd/yyyy'}}
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="End Date">
                          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                              {{dataItem.effectiveEndDate | date: 'MM/dd/yyyy'}}
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Organization">
                          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                              {{dataItem.organization?.organizationName}}
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Term Reason">
                          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                              {{dataItem.lossOfEligibilityReason?.reasonName}}
                          </ng-template>
                        </kendo-grid-column>
                        </kendo-grid>
                    </div>
                  </ng-template> 
                </li>
              </ul>
              <div [ngbNavOutlet]="nav"></div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div>
      <button type="button" class="btn btn-primary" (click)="saveAllEnrollmentChanges()">Save All Changes</button>
    </div>
  </div>
</div>

<kendo-dialog
  title="Account Correction Validation Errors"
  *ngIf="errorsList?.length>0"
  (close)="closeACErrors()"
  [minWidth]="400"
  width="45vw"
  minHeight="550px"
  maxHeight="45vh"
  class="date-picker-info"
>
  <div fxFill fxLayout="column">
    <div class="mgbt-medium">Account corrections were not saved.  The following errors occurred:</div>
    <ul>
      <li *ngFor="let acError of errorsList">{{acError}}</li>
    </ul>    
  </div>
  <kendo-dialog-actions>
    <button class="btn btn-primary" (click)="closeACErrors()" themeColor="primary">Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
