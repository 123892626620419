import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { pdf, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import { Observable, of, from, firstValueFrom, lastValueFrom, first } from 'rxjs';
import { orderBy, uniq, find, sortBy, map, forEach, groupBy, filter } from 'lodash';

import { AdminService } from 'src/app/services/admin.service';
import { AuditHistoryService } from 'src/app/services/auditHistory.service';
import { LookupService } from 'src/app/services/lookup.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';

import SimpleSubscriber from 'src/app/models/simpleSubscriber';
import SystemUser from 'src/app/models/user';
import Eligibility from 'src/app/models/eligibility';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import MemberAddress from 'src/app/models/memberAddress';
import Enrollment from 'src/app/models/enrollment';
import AuditHistoryWrapper from 'src/app/models/AuditHistoryWrapper';

import SpecialOpenEnrollmentType from 'src/app/models/specialOpenEnrollmentType';
import AddressType from 'src/app/models/addressType';
import Country from 'src/app/models/country';
import County from 'src/app/models/county';
import Reason from 'src/app/models/reason';
import Attestation from 'src/app/models/attestation';
import AttestationType from 'src/app/models/attestationType';
import Document from 'src/app/models/document';
import Response from 'src/app/models/response';
import DocumentType from 'src/app/models/documentType';
import LoginHistory from 'src/app/models/loginHistory';
import MemberNote from 'src/app/models/memberNote';
import MemberMaritalStatus from 'src/app/models/memberMaritalStatus';
import RelationshipType from 'src/app/models/relationshipType';
import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import BirthSex from 'src/app/models/birthSex';
import GenderIdentity from 'src/app/models/genderIdentity';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import DependentComposite from 'src/app/models/dependentComposite';
import MemberType from 'src/app/models/memberType';
import Plan from 'src/app/models/plan';
import { PDFComponent } from 'src/app/modules/shared/components/pdf/pdf.component';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import { SubscriberService } from 'src/app/services/subscriber.service';
import SelfPay from 'src/app/models/selfPay';
import { AccountCorrectionsService } from 'src/app/services/accountCorrections.service';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { env } from 'src/env/development';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Member from 'src/app/models/member';
@UntilDestroy()
@Lookups(LookupType.Reason, LookupType.PlanType, LookupType.Agency)
@Component({
  selector: 'account-corrections',
  templateUrl: 'account.corrections.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class AccountCorrectionsComponent implements OnInit {
  @ViewChild('pdf') pdf: PDFComponent;
  @ViewChild('accordion') accordion: NgbAccordion;
  
  subscriberSearchResults: SimpleSubscriber[] = [];
  selectedSubscriber: SimpleSubscriber;
  searchResultColumns: { [k: string]: string | {} }[] = [
    { field: 'firstName', title: 'First name', format: 'string' },
    { field: 'middleName', title: 'Middle name', format: 'string' },
    { field: 'lastName', title: 'Last name', format: 'string' },
    { field: 'SSN', title: 'SSN', format: 'string' },
    { field: 'birthDate', title: 'Birth date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'memberType', title: 'Member type', format: 'string' },
    { field: 'employerName', title: 'Employer name', format: 'string' },
  ];
  activeCoveragePanels = [];
  activeTab;
  icons = {
    faPlus,
    faMinus,
  };
  systemUser: SystemUser;
  debounceTimer = {};
  lookups: { [k: string]: any[] } = {};
  loaded = false;
  printing = false;
  isHCA = false;
  fullSubscriber: Subscriber = null;
  plans: Plan[];
  showOnlyCorrectionStatus = false;
  // SOEs
  soes: Promise<AuditHistoryWrapper<SpecialOpenEnrollment>[]> = null;
  soeColumns: { [k: string]: string | {} }[] = [
    { field: 'specialOpenEnrollmentTypeId', lookupName: 'specialOpenEnrollmentType', lookupType: SpecialOpenEnrollmentType, title: 'Type', format: 'string' },
    { field: 'requestReceivedDate', title: 'Request Received Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'eventDate', title: 'Event Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'coverageEffectiveDate', title: 'Coverage Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'soeEndDate', display: r => dayjs(r.createdDate).add(30, 'day').toDate(), title: 'SOE Ended Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'verification', display: r => r.specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName, title: 'Verification', format: 'string' },
    { field: 'verificationDate', display: r => r.approvedDate ?? r.deniedDate, title: 'Verification Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'verifiedBy', display: r => r.approvedByID ?? r.deniedByID, title: 'Verified By', format: 'string' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // SelfPays
  selfpays: Promise<AuditHistoryWrapper<SelfPay>[]> = null;
  selfpayColumns: { [k: string]: string | {} }[] = [
    { field: 'selfPayType', display: (r: SelfPay) => r.selfPayType.selfPayTypeName, title: 'Type', format: 'string' },
    { field: 'submittedDate', title: 'Submit Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'coverageEffectiveDate', title: 'Coverage Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'selfPayVerificationStatus', display: (r: SelfPay) => r.selfPayVerificationStatus.selfPayVerificationStatusName, title: 'Status', format: 'string' },
    { field: 'verificationDate', display: r => (r.approvedDate ? r.approvedDate : (r.approvedPendingDate ? r.approvedPendingDate : r.deniedDate)), title: 'Verification Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'verifiedBy', display: r => this.getUser(r.approvedById ? r.approvedById : (r.approvedPendingById ? r.approvedPendingById : r.deniedById)), title: 'Verified By', format: 'string' },
    { field: 'selfPayProcessStatus', display: (r: SelfPay) => r.selfPayProcessStatus.selfPayProcessStatusName, title: 'Processing Status', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'modifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];


  // Addresses
  addresses: Promise<AuditHistoryWrapper<MemberAddress>[]> = null;
  addressColumns: { [k: string]: string | {} }[] = [
    { field: 'addressTypeId', lookupName: 'addressType', lookupType: AddressType, title: 'Address Type', format: 'string' },
    { field: 'addressLineOne', title: 'Address Line 1', format: 'string' },
    { field: 'addressLineTwo', title: 'Address Line 2', format: 'string' },
    { field: 'city', title: 'City', format: 'string' },
    { field: 'state', title: 'State', format: 'string' },
    { field: 'zipcodeNumber', title: 'Postal Code', format: 'string' },
    { field: 'countryId', lookupName: 'country', lookupType: Country, title: 'Country', format: 'string' },
    { field: 'countyId', lookupName: 'county', lookupType: County, title: 'County', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'Address End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // Enrollment
  enrollments: Enrollment[] = null;
  enrollmentColumns: { [k: string]: string | {} }[] = [
    { field: 'name', display: e => this.getName(e.memberId), title: 'First Name, Last Name', format: 'string' },
    { field: 'planName', display: e => this.getPlan(e.planId), title: 'Plan Name', format: 'string' },
    { field: 'effectiveStartDate', title: 'Coverage Eff Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'Coverage Eff End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'enrollmentReasonName', display: (e: Enrollment) => this.getReason(e.enrollmentReasonId), title: 'Enrollment Reason', format: 'string' },
    { field: 'terminationReasonName', display: (e: Enrollment) => this.getReason(e.terminationReasonId), title: 'Termination reason', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  Attestations
  subscriberAttestations: Promise<AuditHistoryWrapper<Attestation>[]> = null;
  subscriberAttestationColumns: { [k: string]: string | {} }[] = [
    { field: 'attestationTypeId', lookupName: 'attestationType', lookupType: AttestationType, title: 'Type', format: 'string' },
    { displayOnly: true, field: 'responseId', lookupName: 'response', lookupType: Response, title: 'Response', format: 'string' },
    { field: 'effectiveStartDate', title: 'Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'attestationDate', title: 'Date Attestation Made', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];
  dependentAttestations: Promise<AuditHistoryWrapper<Attestation>[]> = null;
  dependentAttestationColumns: { [k: string]: string | {} }[] = [
    { field: 'name', display: e => this.getName(e.memberId), title: 'Dependent First Name Last Name', format: 'string' },
    { displayOnly: true, field: 'responseId', lookupName: 'response', lookupType: Response, title: 'Response', format: 'string' },
    { field: 'effectiveStartDate', title: 'Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'attestationDate', title: 'Date Attestation Made', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // Login History
  loginHistory: Promise<AuditHistoryWrapper<LoginHistory>[]> = null;
  loginHistoryColumns: { [k: string]: string | {} }[] = [
    { field: 'loginDate', title: 'Log in Date & Time', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' }
  ];

  // Notes
  notes: Promise<AuditHistoryWrapper<MemberNote>[]> = null;
  noteColumns: { [k: string]: string | {} }[] = [
    { field: 'createdDate', title: 'Date & Time of note entry', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'noteText', title: 'Note', format: 'string' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' }
  ];

  // Marital Status Records
  maritalStatuses: Promise<AuditHistoryWrapper<MemberMaritalStatus>[]> = null;
  maritalStatusColumns: { [k: string]: string | {} }[] = [
    { field: 'startDate', title: 'Marriage Start Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'endDate', title: 'Marriage end Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' },
    { field: 'createdModifiedDate', display: r => r.modifiedDate ?? r.createdDate, title: 'Created by/modified by Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' }
  ];

  // Dependents
  dependents: Promise<AuditHistoryWrapper<DependentComposite>[]> = null;
  dependentColumns: { [k: string]: string | {} }[] = [
    { field: 'relationshipTypeId', lookupName: 'relationshipType', lookupType: RelationshipType, title: 'Relationship', format: 'string' },
    { field: 'relationshipQualifyReasonId', lookupName: 'relationshipQualifyReason', lookupType: RelationshipQualifyReason, title: 'Qualify Reason', format: 'string' },
    { field: 'firstName', title: 'First Name', format: 'string' },
    { field: 'lastName', title: 'Last Name', format: 'string' },
    { field: 'middleName', title: 'Middle Name', format: 'string' },
    { field: 'socialSecurityNumber', title: 'SSN', format: 'string' },
    { field: 'birthDate', title: 'DOB', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'birthSexId', lookupName: 'birthSex', lookupType: BirthSex, title: 'Birth Sex', format: 'string' },
    { field: 'genderIdentityId', lookupName: 'genderIdentity', lookupType: GenderIdentity, title: 'Gender', format: 'string' },
    { field: 'relationshipVerificationStatusId', lookupName: 'relationshipVerificationStatus', lookupType: RelationshipVerificationStatus, title: 'Verification Status', format: 'string' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' },
    { field: 'createdModifiedDate', display: r => r.modifiedDate ?? r.createdDate, title: 'Created/Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' }
  ];

  // Documents
  documents: Promise<AuditHistoryWrapper<Document>[]> = null;
  documentColumns: { [k: string]: string | {} }[] = [
    { field: 'attributedTo', title: 'Document Attributed To', format: 'string' },
    { field: 'documentTypeId', lookupName: 'documentType', lookupType: DocumentType, title: 'Document Type', format: 'string' },
    { field: 'documentName', title: 'Document title', format: 'string' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' },
    { field: 'createdModifiedDate', display: r => r.modifiedDate ?? r.createdDate, title: 'Created/Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' }
  ];

  enrollmentPeriods: EnrollmentPeriod[] = null;

  plansByType = {};
  constructor(
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService,
    private adminService: AdminService,
    private lookupService: LookupService,
    private auditHistoryService: AuditHistoryService,
    private subscriberService: SubscriberService,
    private coreService: CoreService,
    private accountCorrectionsService: AccountCorrectionsService,
    private enrollmentService: EnrollmentService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
      this.lookups.reason = sortBy(data.lookups.reason,
        'reasonName'
      );
      this.lookups.subscriberEnrollmentReasons = filter(data.lookups.reasons, (r: Reason) => r.enrollmentReasonInd);
      this.lookups.dependentEnrollmentReasons = filter(data.lookups.reasons, (r: Reason) => r.enrollmentReasonInd);
      this.lookups.subscriberTerminationReasons = filter(data.lookups.reasons, (r: Reason) => (r.terminationReasonInd && r.subscriberInd) || r.reasonCode == '54');
      this.lookups.dependentTermintationReasons = filter(data.lookups.reasons, (r: Reason) => r.terminationReasonInd && r.dependentInd);
      this.lookups.subscriberEligibilityReasons = filter(data.lookups.reasons, (r: Reason) => r.eligibilityReasonInd && r.subscriberInd);
      this.lookups.agencies = data.lookups.agencies;
    });
    this.enrollmentService.getAllPlans().pipe(first()).pipe(untilDestroyed(this)).subscribe((plans) => {
      plans.forEach( p =>{
        const planAgency = find(this.lookups.agencies, a => a.agencyId == p.agencyId);
        p.planName = (!planAgency ? "" : "(" + planAgency.agencyCode + ") ") + p.planName;
      });
      this.plans = sortBy(plans,"planName");
      this.plansByType = groupBy(this.plans, p => p.planType.planTypeName)
    });

    this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.HCA);
    const idKey = 'id';
    const sortColumnsKey = 'sortColumns';
    const sortOrdersKey = 'sortOrders';

    this.soeColumns[idKey] = 'specialOpenEnrollmentId';
    this.selfpayColumns[idKey] = 'selfPayId';
    this.addressColumns[idKey] = 'memberAddressId';
    this.enrollmentColumns[idKey] = 'enrollmentId';
    this.subscriberAttestationColumns[idKey] = 'attestationId';
    this.loginHistoryColumns[idKey] = 'loginHistoryId';
    this.noteColumns[idKey] = 'memberNoteId';
    this.maritalStatusColumns[idKey] = 'memberMaritalStatusId';
    this.dependentColumns[idKey] = 'memberId';
    this.documentColumns[idKey] = 'documentId';
    this.dependentAttestationColumns[idKey] = 'attestationId';

    this.dependentColumns[sortColumnsKey] = [ 'lastName', 'firstName', 'id', 'timestamp' ];
    this.dependentColumns[sortOrdersKey] = [ 'asc', 'asc', 'asc', 'desc' ];
  }

  getRowCount(items: any[]): string {
    if (Array.isArray(items) && items.length) {
      if (items.length === 1) {
        return '1 Record';
      }

      return `${items.length} Records`;
    }

    return '0 Records';
  }

  getDebouncedList(listKey: string, id: string, getData: (ids: string[]) => Observable<any[]>, callback: any): Promise<any> {
    this.debounceTimer[listKey] = this.debounceTimer[listKey] ?? { timer: null, resolvers: [] };

    clearTimeout(this.debounceTimer[listKey].timer);

    this.debounceTimer[listKey].timer =
      setTimeout
      (
        () => {
          const timer = this.debounceTimer[listKey];
          delete this.debounceTimer[listKey];

          const ids = timer.resolvers.map(r => r.id);
          return firstValueFrom(getData(ids))
            .then(users => {
              const resolvers = timer.resolvers;

              while (resolvers.length) {
                const resolver = resolvers.pop();
                callback(users, resolver.id, resolver.resolve);
              }
            });
        },
        100
      );

    return new Promise(resolve => {
      this.debounceTimer[listKey].resolvers.push({ resolve, id });
    });
  }

  getUser(userId: string): Promise<string> {
    if (userId) {
      return this.getDebouncedList(
        'getUser',
        userId,
        this.auditHistoryService.getUsersByIdList.bind(this.auditHistoryService),
        (users, id, resolve) => {
          const user = users.filter(u => u.systemUserId === id)[0];
          const name = user?.emailAddress ?? user?.firstName;

          resolve(name);
        });
    }
    else {
      return null;
    }
  }



  getPlan(planId: string): string {
    const plan = find(this.plans, p => p.planId == planId);
    return plan.planName;
  }


  get filename(): string {
    const now = dayjs().format('MMDDYYYY');
    return `History_${this.selectedSubscriber.firstName}${this.selectedSubscriber.lastName}_Download_Date${now}.pdf`;
  }

  download(): void {
    const panelIds = this.accordion.activeIds.slice();

    this.spinnerOverlayService.show();
    this.printing = true;
    this.accordion.expandAll();

    setTimeout(() => {
      this.pdf.pdfElement.export().then(group => {
        const options = {  } as pdf.PDFOptions;
        exportPDF(group, options).then(data => {
          saveAs(data, this.filename);

          setTimeout(() => {
            this.accordion.collapseAll();
            (panelIds as string[]).forEach(id => this.accordion.expand(id));
            this.printing = false;
            this.spinnerOverlayService.hide();
          }, 1000);
        });
      });
    }, 0);
  }

  getName(memberId: string): string {
    const member = find(this.fullSubscriber.members, m => m.memberId === memberId);
    return member ? member.firstName + ' ' + member.lastName : 'Account Level';
  }

  getReason(reasonId: string): string {
    const reason = find(this.lookups.reason, (r: Reason) => r.reasonId == reasonId);
    return reason?.reasonName;
  }



  // tslint:disable-next-line: callable-types
  getLookups<T>(lutName: string, type: { new (...args: any[]): T }): Promise<T[]> {
    return firstValueFrom(this.lookupService.getLutValues(lutName, type));
  }

  onSearchSubmitted(queryString: string): void {
    this.spinnerOverlayService.show();
    this.adminService.searchSubscribers(queryString, true, this.coreService.getOrganizationValue()?.organizationId, null, false, true).pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.spinnerOverlayService.hide();
        this.subscriberSearchResults = [];
        this.subscriberSearchResults = data;
      },
      (err) => {
        console.log(err);
        this.spinnerOverlayService.hide();
      }
    );
  }

  refetchMember(e: SimpleSubscriber) {
    this.onSubscriberSelected(e);
  }

  async onSubscriberSelected(e: SimpleSubscriber): Promise<void> {
    if (this.selectedSubscriber) {
      this.onSubscriberDeselected();
    }

    // this is a dependent.  Get their subscriber's record
    this.spinnerOverlayService.show();
    this.fullSubscriber = await lastValueFrom(this.subscriberService.getSubscriberById(e.subscriberMemberId));
    e = new SimpleSubscriber(this.fullSubscriber);
    this.spinnerOverlayService.hide();
    this.selectedSubscriber = e;

    const accountCorrectionsAccount = await lastValueFrom(this.accountCorrectionsService.getAccount(this.selectedSubscriber.memberId));
    this.enrollments = accountCorrectionsAccount.enrollments;
    this.enrollmentPeriods = sortBy(accountCorrectionsAccount.enrollmentPeriods,"effectiveStartDate");
    this.enrollments = forEach(this.enrollments, (e: Enrollment) => {
      e['name'] = this.getName(e.memberId);
      e['planName'] = this.getPlan(e.planId);
      e['enrollmentReasonName'] = this.getReason(e.enrollmentReasonId);
      e['terminationReasonName'] = this.getReason(e.terminationReasonId);
    })
    this.enrollments = sortBy(this.enrollments, (e) => e.effectiveStartDate);

    this.fullSubscriber.members.forEach((m:Member) => {
      m.enrollments.forEach((e:Enrollment) => {
        const matchingEnrollment:Enrollment = find(this.enrollments,en=> en.enrollmentId == e.enrollmentId);
        e.enrollmentPeriod = matchingEnrollment.enrollmentPeriod;
      });
    });
  }

  onSubscriberDeselected(): void {
    this.selectedSubscriber =

      this.soes =
      this.selfpayColumns =
      this.addresses =
      this.loginHistory =
      this.enrollments =
      this.subscriberAttestations =
      this.notes =
      this.maritalStatuses =
      this.dependents =
      this.documents =
      this.dependentAttestations =
      null;

    this.loaded = false;
  }


  acAvailableToUser() {
    return env.accountCorrectionAccessList.includes(this.systemUser?.emailAddress) || env.accountCorrectionAccessList[0] == 'Any';
  }

  
}
