<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center"><h1>Dependent review</h1></div>
  <div *ngIf="!isDeathOrDivorceSoe">
    <h2 class="h4" style="text-align: center">Please review the information below for accuracy. Click "Edit dependent" if you need to make changes.</h2>
    <ngb-alert [dismissible]="false" role="alert" class="alert alert-danger" *ngIf="hasNonDisabledDependentOver26">
      Unable to enroll non-disabled dependent age 26 or older.
      You must provide certification if you wish to enroll them as a disabled dependent.
    </ngb-alert>
    <div *ngIf="soe" class="hca-pdf-table-content info-container mgbt-medium-sm mgtp-medium-sm">
      <h4 >If your SOE event allows for removing dependents from coverage, click “Edit Dependent” next to the dependent you wish to take action on. For more information on changes you can make and when your SOE event allows for enrolling dependents or removing them from coverage, 
        please see 
        <a *ngIf="subscriber.isSebb" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/what-special-open-enrollment"> SEBB Special Open Enrollment</a>
        <a *ngIf="!subscriber.isSebb" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/what-special-open-enrollment"> PEBB Special Open Enrollment</a>
      </h4>
    </div>
  </div>
  <div *ngIf="isDeathOrDivorceSoe">
    <h2 class="h4">
      Use this section to report a divorce or the death of a dependent.
    </h2>
  </div>
  <div>
    <h3 style="font-weight: bold">Dependent Information:</h3>
    <div *ngFor="let dependent of dependentDisplays; let index = index">
      <div class="hca-pdf-table-content info-container mgbt-medium-sm mgtp-medium-sm h5">
        <div fxLayout="row" fxLayoutAlign="space-between start" style="padding: 10px">
          <div fxFlex="25">
            <div style="text-align: left">{{ dependent.lastName}}, {{ dependent.firstName}}</div>
            <div style="text-align: left">DOB: {{ dependent.birthDate | date: 'MM/dd/yyyy' }}</div>
            <div style="text-align: left">{{ dependent.relationshipTypeName }}</div>
          </div>
          <div fxFlex="10">
            <div *ngIf="ableToHaveMedical" style="text-align: start"><b>Medical:</b></div>
            <div *ngIf="ableToHaveDental" style="text-align: start"><b>Dental:</b></div>
            <div *ngIf="ableToHaveVision" style="text-align: start"><b>Vision:</b></div>
            <div *ngIf="isSP" style="text-align: left">Medicare A:</div>
            <div *ngIf="isSP" style="text-align: left">Medicare B:</div>
          </div>
          <div fxFlex="15">
            <div *ngIf="ableToHaveMedical" style="text-align: start">{{ dependent.enrolledInMedical ? 'Enrolled' : 'Not Enrolled' }}</div>
            <div *ngIf="ableToHaveDental" style="text-align: start">{{ dependent.enrolledInDental ? 'Enrolled' : 'Not Enrolled' }}</div>
            <div *ngIf="ableToHaveVision" style="text-align: start">{{ dependent.enrolledInVision ? 'Enrolled' : 'Not Enrolled' }}</div>
            <div *ngIf="isSP" style="text-align: left">{{dependent.medicarePartAEnrolled ? 'Yes' : 'No'}}</div>
            <div *ngIf="isSP" style="text-align: left">{{dependent.medicarePartBEnrolled ? 'Yes' : 'No'}}</div>
          </div>
          <div fxFlex="15">
            <div style="text-align: start"><b>Tobacco use:</b></div>
            <div style="text-align: start"><b>Spousal surcharge:</b></div>
          </div>
          <div fxFlex="15">
            <div style="text-align: start">{{ dependent.tobaccoUse ? 'Yes' : 'No' }}</div>
            <div style="text-align: start">{{ dependent.isSpouseInd ? (dependent.spousalSurcharge ? 'Yes' : 'No') : 'Not applicable' }}</div>
          </div>
          <div fxLayout="column" fxFlex="20" fxLayoutAlign="space-between end">
            <button *ngIf="canEdit" [disabled]="false" (click)="editDependent(dependent)" class="btn btn-link">Edit dependent</button>
            <button [hidden]="true" class="btn btn-link">Delete dependent</button>
          </div>
        </div>
        
        <div *ngIf="isDeathOrDivorceSoe" style="padding: 10px">
          <div fxLayout="row" *ngIf="dependent.isSpouseInd || dependent.isStepChild || isDivorce !== true" fxLayoutAlign="space-between start">
            <div fxFlex="68">
              <div fxLayout="row" style="text-align: start">
                <div fxFlex="66">
                  Select the event you are reporting for this dependent:
                </div>
                <div fxFlex="17" style="font-size:.8em;">
                  <radio
                    [name]="'death' + index"
                    [value]="deathReasonId"
                    label="Death"
                    [disabled]="dependent.lossOfEligibilityReasonId === dependentLosesEligibilityReasonId"
                    [(ngModel)]="dependent.lossOfEligibilityReasonId"
                    (ngModelChange)="deathOrDivorce(dependent, $event, true)"
                    radioAsCheckbox
                    canDeselectRadio
                    ></radio>
                </div>
                <div fxFlex="17" style="font-size:.8em;">
                  <radio
                    *ngIf="dependent.isSpouseInd"
                    [name]="'divorce' + index"
                    [value]="divorceReasonId"
                    label="Divorce"
                    [disabled]="!dependent.isSpouseInd"
                    [(ngModel)]="dependent.lossOfEligibilityReasonId"
                    (ngModelChange)="deathOrDivorce(dependent, $event, false)"
                    radioAsCheckbox
                    canDeselectRadio
                    ></radio>
                </div>
              </div>
            </div>
            <div fxFlex="30">
              <div fxLayout="row" *ngIf="dependent.lossOfEligibilityReasonId && (dependent.isSpouseInd || dependent.lossOfEligibilityReasonId === deathReasonId)">
                <div fxFlex="50">{{dependent.lossOfEligibilityReasonId === deathReasonId ? 'Death' : 'Divorce'}} date:</div><div fxFlex="50">{{ eventDate | date: 'MM/dd/yyyy' }}</div>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div fxFlex="68">
              <div style="text-align: start" *ngIf="dependent.lossOfEligibilityReasonId && (!dependent.isStepChild || isDivorce === null)">
                If the {{dependent.lossOfEligibilityReasonId === deathReasonId ? 'death' : 'divorce'}} date is incorrect, cancel this action, delete the event, and create an event with the correct event date.
              </div>
              <div class="alert alert-warning" style="text-align: start" *ngIf="dependent.lossOfEligibilityReasonId && dependent.isStepChild && isDivorce !== null">
                Coverage will be removed for this dependent due to event being reported for the enrolled spouse/state
                registered partner on the account.
              </div>
              <div class="alert alert-warning" style="text-align: start" *ngIf="!dependent.isSpouseInd && !dependent.isStepChild && isDivorce === true">
                No changes can be made to this dependent due to divorce being selected for the spouse/state registered partner.
              </div>
            </div>
            <div fxFlex="30">
              <div fxLayout="row" *ngIf="dependent.lossOfEligibilityReasonId">
                <div fxFlex="50">Coverage end date:</div><div fxFlex="50">{{ coverageEndDate | date: 'MM/dd/yyyy' }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="info-container">
      <h4 class="mgtp-x-small">Provide proof:</h4>
      <div *ngIf="!isDeathOrDivorceSoe">
        <h5>
          <span *ngIf="!subscriber.isSebb">
            For each dependent added, you must provide proof of dependent's eligibility within the PEBB Program's enrollment timelines or your dependent will not be enrolled. See
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/verify-and-enroll-my-dependents">Verify and enroll my dependents</a> for a list of acceptable documents.
            You can upload your documents in the next section.
          </span>
          <span *ngIf="subscriber.isSebb">
            For each dependent added, you must provide proof of dependent's eligibility within the SEBB program's enrollment timelines or your dependent will not be 
            enrolled. See <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/dependent-verification" target="_blank">Verify and enroll my dependents</a>
            for a list of acceptable documents. You can upload your documents in the next section.
          </span>
        </h5>
        <h4>Tobacco and/or Spousal Attestation Confirm</h4>
        <div fxLayout="column" fxLayoutAlign="center center">
          <span *ngIf="!subscriber.isSebb">
            <h4 class="mgtp-x-small">Legal Notice</h4>
            <p>By selecting the Submit button below:</p>
            <ul>
              <li>I declare that the information I have provided is true, complete, and correct. If it isn't, or if I do not provide timely, updated information, the subscriber will be charged premium surcharge(s).</li>
              <li>I declare that one (or more) of the circumstances described above occurred that requires the subscriber to change their attestation to the tobacco use and/or spouse or state-registered domestic partner coverage premium surcharge, and that I'm reporting it within the PEBB Program's deadlines.</li>
              <li>I am replacing all Premium Surcharge Attestation Change forms, and electronic surcharge attestations previously submitted.</li>
              <li>A change that results in a premium surcharge will begin the first day of the month following the status change (the date you or your dependents started using tobacco products). If that day is the first of the month, the change to the surcharge begins on that day.</li>
              <li>A change that results in removing the premium surcharge (you or your dependents stopped using tobacco products, enrolled in your PEBB medical plan's tobacco cessation program if age 18 or older, or have accessed information and resources at Smokefree Teen if age 13 to 17) will begin the first day of the month following receipt of the attestation. If that day is the first day of the month, the change to the surcharge begins on that day.</li>
            </ul>
            <p>Exception:  If you are required to attest to the premium surcharges during the PEBB Program’s annual open enrollment, any changes will become effective January 1 of the following year.</p>
            <br/>
            <p>HCA's privacy notice: We will keep your information private as allowed by law. See our 
              <a href="https://www.hca.wa.gov/about-hca/notice-privacy-practices" target="_blank">privacy notice</a>.
            </p>
          </span>
          <span *ngIf="subscriber.isSebb">
            <h4 class="mgtp-x-small">Legal Notice</h4>
            <p>By selecting the Submit button below:</p>
            <ul>
              <li>I declare that the information I have provided is true, complete, and correct. If it isn't, or if I do not provide timely, updated information, the subscriber will be charged premium surcharge(s).</li>
              <li>I declare that one (or more) of the circumstances described above occurred that requires the subscriber to change their attestation to the tobacco use and/or spouse or state-registered domestic partner coverage premium surcharge, and that I'm reporting it within the SEBB Program's deadlines.</li>
              <li>I am replacing all Premium Surcharge Attestation Change forms, and electronic surcharge attestations previously submitted.</li>
              <li>A change that results in a premium surcharge will begin the first day of the month following the status change (the date you or your dependents started using tobacco products). If that day is the first of the month, the change to the surcharge begins on that day.</li>
              <li>A change that results in removing the premium surcharge (you or your dependents stopped using tobacco products, enrolled in your SEBB medical plan's tobacco cessation program if age 18 or older, or have accessed information and resources at Smokefree Teen if age 13 to 17) will begin the first day of the month following receipt of the attestation. If that day is the first day of the month, the change to the surcharge begins on that day.</li>
            </ul>
            <p>Exception:  If you are required to attest to the premium surcharges during the SEBB Program’s annual open enrollment, any changes will become effective January 1 of the following year.</p>
            <br/>
            <p>HCA's privacy notice: We will keep your information private as allowed by law. See our 
              <a href="https://www.hca.wa.gov/about-hca/notice-privacy-practices" target="_blank">privacy notice</a>.
            </p>
          </span>
        </div>      
      </div>
      <div *ngIf="isDeathOrDivorceSoe">
        <span *ngIf="!subscriber.isSebb">
          <p>You will need to provide proof of a death or divorce:</p>
          <ul>
              <li>Petition for Dissolution of marriage (divorce); or</li>
              <li>Petition for Dissolution of state registered domestic partnership; or</li>
              <li>Copy of a death certificate.</li>
          </ul>
        </span>
      </div>
    </div>
  </div>
</div>
