<div fxLayout="row" fxLayoutAlign="center center">
  <hca-prompt
    [fxFlex]="step == 0 || step == 2 ? 60 : 100"
    [conditionalNextMet]="(!isDeathOrDivorceSoe && hasDependentsToAdd !== undefined) || (isDeathOrDivorceSoe && deathOrDivorceSoeSelection.length > 0)"
    [conditionalNextIdx]="isDeathOrDivorceSoe ? 3 : 0"
    [confirmIndex]="lastStep"
    [icon]="icons.faUsers"
    (previous)="updateStep($event)"
    (next)="updateStep($event)"
    (cancel)="cancelSoe()"
    [showCancel]="isDeathOrDivorceSoe"
    [milestone]="dependentMilestone"
    [confirmButtonText]="confirmButtonText"
    [hideButtonsOnSteps]="stepsToHideButtonsOn"
    [alwaysShowConfirmButtonOnLastStep]="isDeathOrDivorceSoe"
    [idx]="step"
    [hideBackToDash]="isSOE"
    (putPostObject)="nextModule()"
  >
    <ngb-alert *ngIf="step == 3 && subscriberIsNewlyFull" [dismissible]="false" [type]="'success'">
      Your new employment includes eligibility for dental {{visionAvailable ? ' and vision ' : ''}}coverage for you and your existing, eligible dependents. To add dental {{visionAvailable ? ' and/or vision ' : ''}}coverage to an existing dependent please click the "edit dependent" link for the applicable dependent.  New dependents cannot be added to your account here and medical coverage cannot be changed.
    </ngb-alert> 
    <dep-initial (outputDependent)="loadSPDependent($event)" *ngIf="step === 0" [subscriber]="subscriber" [hasDependentsToAdd]="hasDependentsToAdd" [dependents]="dependents" (dependentsQuestionAnswered)="updateHasDependentsToAdd($event)" [isSOE]="false" [soe]="currentSOE" [isSP]="isSelfPay" [selfPay]="currentSP"></dep-initial>
    <div *ngIf="step === 1" fxFlexFill fxLayout="row" class="fxGap-medium-lg">
      <ngb-accordion (panelChange)="panelChange($event)" fxFlex="79" activeIds="hca0" [destroyOnHide]="false" [closeOthers]="true" 
        class="mgtp-medium-lg" #acc="ngbAccordion">
        <ngb-panel
          [id]="'hca' + index"
          [disabled]="
            maxIdxCompleted < subsection.idx ||
            (subsection.name === 'Spouse/State-registered partner attestation' && (!isSpouse || !hasMed || subscriberIsNewlyFull)) ||
            (subsection.name === 'Tobacco attestations' && (!hasMed || underThirteen) ||
            (subsection.name !== 'Auto verify dependent' && autoVerifyActive))
          "
          *ngFor="let subsection of visiblePanes(dependentWizardSections); let index = index"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
              <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
              <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
              {{ subsection.name }}
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <dep-demo
              *ngIf="subsection.name === 'Demographics'"
              [selfPay]="currentSP"
              (moveToNextSubsection)="moveToNextSubsection(subsection.idx)"
              [enrollmentPeriod]="enrollmentPeriod"
              [lookups]="lookups"
              [subscriber]="subscriber"
              [(dependent)]="dependent"
              [isSebb]="subscriber.isSebb"
              [isHcaEdit]="isHcaEdit"></dep-demo>
              
            <dep-coverage
              (moveToNextSubsection)="moveToNextSubsection(subsection.idx)"
              (updateSelectedPlans)="updateSelectedPlans($event)"
              (cancelDependentAdd)="cancelDependentAdd()"
              (notifiedBeforeSOEEnded)="divorceWithin60Days = $event"
              [lookups]="lookups"
              [dependent]="dependent"
              [lookups]="lookups"
              [thirteenDate]="thirteenDate"
              [dentalOpportunityOnly]="subscriberIsNewlyFull"
              [electablePlanTypes]="electablePlanTypes"
              [soe]="currentSOE"
              [isSelfPay]="isSelfPay"
              [currentSP]="currentSP"
              [enrollmentPeriod]="enrollmentPeriod"
              [subscriber]="subscriber"
              *ngIf="subsection.name == 'Enrollments'"
            ></dep-coverage>
            <dep-tobacco
              (updateTobaccoAttest)="updateTobaccoAttest($event)"
              (moveToNextSubsection)="moveToNextSubsection(subsection.idx)"
              [lookups]="lookups"
              [dependent]="dependent"
              [isSebb]="subscriber.isSebb"
              *ngIf="subsection.name == 'Tobacco attestations'"
            ></dep-tobacco>
            <dep-spousal
              #spousal
              [effectiveYear]="effectiveYear"
              (updateSpousalAttest)="updateSpousalAttest($event)"
              (moveToNextSubsection)="moveToNextSubsection(subsection.idx)"
              [lookups]="lookups"
              [dependent]="dependent"
              [enrollmentPeriod]="enrollmentPeriod"
              [isSebb]="subscriber.isSebb"
              *ngIf="subsection.name == 'Spouse/State-registered partner attestation'"
            ></dep-spousal>
            <dep-review
              (updateStep)="updateStep()"
              (saveAndContinue)="saveDependent()"
              [selectedPlanIds]="selectedPlanIds"
              [lookups]="lookups"
              [isSP]="isSelfPay"
              [isSebb]="subscriber.isSebb"
              [dependent]="dependent"
              [electablePlanTypes]="electablePlanTypes"
              [saveDependentSubmitted]="saveDependentSubmitted"
              [subscriber]="subscriber"
              *ngIf="subsection.name == 'Dependent review'"
            ></dep-review>
            <div *ngIf="subsection.name === 'Auto verify dependent'">
              <div class="card">
                <div class="card-body" style="margin-left:12px;" fxFlexLayout="column" *ngIf="dependent.relationship">
                  <verification-summary
                    [dataItem]="dependent.relationship"
                  ></verification-summary>
                  <verification-form
                    *ngIf="coreService.systemUserHasAccess('edit')"
                    [isDisabled]="dependent.relationship?.relationshipQualifyReason?.managedByHCAInd && !isHcaAdmin"
                    [dataItem]="dependent.relationship" 
                    [verifyValues]="relationshipVerifyValues" 
                    [denyValues]="relationshipDenyValues"
                    (pushSaveVerificationResponse)="saveRelationshipVerification(dependent.relationship)"
                  ></verification-form>
                  <certification
                    *ngIf="dependent.relationship?.relationshipQualifyReason?.needsCertificationInd"
                    [certifications]="dependent.relationship.relationshipCertifications" 
                    [relationship]="dependent.relationship" 
                    [certificationTypes]="lookups.certificationTypes"
                    [certificationStatuses]="lookups.relationshipCertificationStatus"
                    [isHCA]="inHcaAdminState"
                    [isPerspay]="!inHcaAdminState"
                    [isSubscriber]="false"
                    [isReadOnly]="!isHcaAdmin"
                    (pushSaveCertification)="saveCertification($event)"
                    (pushSaveCertificationStatus)="saveCertificationStatus($event)"
                    (pushDeleteCertification)="deleteCertification($event)"
                  ></certification>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <div fxFlex="20">
        <div class="h5">
          <span *ngIf="!subscriber.isSebb">
            List eligible dependents you wish to cover or remove from coverage including children as defined in WAC 182-12-260(3).
            <br />
            <br />
            Dependents cannot be enrolled in two PEBB medical or dental accounts at the same time. If adding a dependent, you must provide proof of dependent eligibility for each dependent within the
            PEBB Program's enrollment timelines or the dependent will not be enrolled.
            <br />
            <br />
            For a list of all qualified dependents click
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/how-determine-eligibility#dependent-eligibility">here</a>
            <br />
            <br />
            Dependents can only enroll in medical coverage if the subscriber is also enrolled. See the
            <a target="_blank" href="https://www.hca.wa.gov/assets/pebb/50-0100-pebb-employee-enrollment-guide-2024.pdf">2024 PEBB Employee Enrollment Guide</a> for more information.
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="step === 2">
      <dep-additional
        [isSOE]="false"
        [isSelfPay]="isSelfPay"
        [soe]="currentSOE"
        [subscriber]="subscriber"
        (outputDependent)="loadSPDependent($event)"
        [dependent]="dependent"
        [selfPay]="currentSP"
        (additionalQuestionAnswered)="additionalQuestionAnswered($event)"
      ></dep-additional>
    </div>
    <div *ngIf="step === 3">
      <dep-review-all
        [isSelfPay]="isSelfPay"
        [dependents]="dependents"
        [subscriber]="subscriber"
        [lookups]="lookups"
        [dependentLosesEligibilityReasonId]="dependentLosesEligibilityReasonId"
        [electablePlanTypes]="electablePlanTypes"
        [coverageEffectiveStartDate]="enrollmentPeriod.coverageEffectiveStartDate"
        [eventDate]="enrollmentPeriod.eventDate"
        [soe]="currentSOE"
        (emitEditDependent)="editDependent($event)"
        (emitBlockNext)="blockNext($event, '3')"
        (deathOrDivorceSoeSelected)="deathOrDivorceSoeSelection = $event"
      ></dep-review-all>
    </div>
  </hca-prompt>
</div>
