// ng
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import { HCAErrorHandlingComponent } from './../modules/adminHCA/components/errorHandling/error.handling.component';
import { HCASyncErrorHandlingComponent } from './../modules/adminHCA/components/syncErrorHandling/sync.error.handling.component';
import { HCAAdminRootComponent } from './../modules/adminHCA/components/hcaAdminRoot/hca.admin.root.component';
import { CarrierTransactionsComponent} from './../modules/adminHCA/components/carrierTransactions/carrier.transactions.component';
import { SubscriberHistoryComponent } from './../modules/adminHCA/components/subscriberHistory/subscriberHistory.component';
import { VerificationAdminComponent } from './../modules/adminPerspay/components/verification/verification.admin.component';
import { AdminAccessComponent } from './../modules/adminHCA/components/adminAccess/adminAccess.component';
import { AppSettingsComponent } from './../modules/adminHCA/components/appSettings/app.settings.component';
import { HCADashboardComponent } from './../modules/dashboard/components/hca/hca.dashboard.component';
import { SoeReviewAdminComponent } from '../modules/adminPerspay/components/soeReview/soeReview.admin.component';
import { DependentsComponent } from '../modules/subscriber/modules/dependents/dependents.component';
import { UploadComponent } from '../modules/subscriber/modules/upload/upload.component';
import { CoverageComponent } from '../modules/subscriber/modules/coverage/coverage.component';
import { AttestTobaccoComponent } from '../modules/subscriber/modules/attestations/components/tobacco/tobacco.component';
import { SupplementalComponent } from '../modules/subscriber/modules/supplemental/supplemental.component';
import { ConfirmationComponent } from '../modules/subscriber/modules/confirmation/confirmation.component';
import { SelfpayAdminComponent } from '../modules/adminHCA/components/selfpay/selfpay.admin.component';
import { UwSyncFileComponent } from '../modules/adminHCA/components/uwSyncFile/uwSyncFile.component';
import { AccountCorrectionsComponent } from '../modules/adminHCA/components/accountCorrections/account.corrections.component';
// guards
import { MasterGuard } from './../guards/master/master.guard';
// resolvers
import { UserResolver } from './../resolvers/user.resolver';
import { LookupsResolver } from './../resolvers/lookups.resolver';
import { EnrollmentPeriodResolver } from '../resolvers/enrollmentPeriod.resolver';
import { SubscriberResolver } from '../resolvers/subscriber.resolver';
import { TOUResolver } from '../resolvers/tou.resolver';
import { HCASelfPayDashboardComponent } from '../modules/dashboard/components/hca/components/selfpay/hca.selfpay.dashboard.component';

const routes: Routes = [
  {
    path: 'dashboard/hca/:id',
    component: HCADashboardComponent,
    resolve: {
      user: UserResolver,
    },
    data: {
      guards: ['auth'],
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/selfpaydashboard/:id',
    component: HCASelfPayDashboardComponent,
    resolve: {
      user: UserResolver,
    },
    data: {
      guards: ['auth'],
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },  
  {
    path: 'admin/appsettings/:id',
    component: AppSettingsComponent,
    data: {
      guards: ['auth'],

    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/uw',
    component: UwSyncFileComponent,
    data: {
    },
    resolve: {
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/errorHandling/:id',
    component: HCAErrorHandlingComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/syncErrorHandling/:id',
    component: HCASyncErrorHandlingComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/hcaAdminRoot/:id',
    component: HCAAdminRootComponent,
    data: {
      guards: ['auth'],
    },
    canActivate: [MasterGuard],
    resolve: {
      user: UserResolver,
    },
    runGuardsAndResolvers: 'always',
    children: [  
      {
        path: 'syncFileErrors',
        component: HCASyncErrorHandlingComponent,
        resolve: {
          user: UserResolver,
        },
        data: {
          guards: ['auth'],
        },
        canActivate: [MasterGuard],
        runGuardsAndResolvers: 'always',
      },    
      {
        path: 'carrierTransactions',
        component: CarrierTransactionsComponent,
        data: {
          guards: ['auth'],
        },
        canActivate: [MasterGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'accountCorrections',
        component: AccountCorrectionsComponent,
        data: {
          guards: ['auth'],
        },
        canActivate: [MasterGuard],
        runGuardsAndResolvers: 'always',
      },
    ]
  },
  
  {
    path: 'admin/subscriberHistory/:id',
    component: SubscriberHistoryComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/hca/access/:id',
    component: AdminAccessComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
  },
  {
    path: 'admin/relationshipVerification/hca/:id',
    component: VerificationAdminComponent,
    data: {
      guards: ['auth'],
      userType: 'hca'
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'admin/specialOpenEnrollment/review/hca/:id',
    component: SoeReviewAdminComponent,
    data: {
      guards: ['auth'],
      userType: 'hca',
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'dependents/:epid',
            component: DependentsComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'upload/:epid',
            component: UploadComponent,
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'coverage/:epid',
            component: CoverageComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'attest/:epid',
            component: AttestTobaccoComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'supplemental/:epid',
            component: SupplementalComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'confirmation/:epid',
            component: ConfirmationComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'admin/selfpay/review/hca/:id',
    component: SelfpayAdminComponent,
    data: {
      guards: ['auth'],
      userType: 'hca'
    },
    resolve: {
      user: UserResolver
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'dependents/:epid',
            component: DependentsComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'upload/:epid',
            component: UploadComponent,
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'coverage/:epid',
            component: CoverageComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'attest/:epid',
            component: AttestTobaccoComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'supplemental/:epid',
            component: SupplementalComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'confirmation/:epid',
            component: ConfirmationComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
              termsOfUse: TOUResolver,
            },
            canActivate: [MasterGuard],
          },
        ],
      },
    ],
  },
];

export const HcaGeneralRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(LookupsResolver.addToAllRoutes(routes), {onSameUrlNavigation: 'reload'});
