import UserAccessLevel from 'src/app/models/userAccessLevel';
import { faUsers, faPlusCircle, faLaptopCode, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { flatMap, forEach, camelCase, startCase, includes, find, remove, endsWith, trim,
filter, replace, keys, isEqual, cloneDeep, pickBy, some, sortBy, every, maxBy, get, findIndex} from 'lodash';
import { AccessManagementGridComponent } from './components/accessManagementGrid.component';
import { UserService } from 'src/app/services/user.service';
import Organization from 'src/app/models/organization';
import UserOrganizationRole from 'src/app/models/userOrganizationRole';
import SystemUser from 'src/app/models/user';
import { lastValueFrom, Observable } from 'rxjs';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import * as dayjs from 'dayjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.UserType, LookupType.UserAccessLevel, LookupType.UserRole)
@Component({
  selector: 'access-management',
  templateUrl: 'accessManagement.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class AccessManagementComponent implements OnInit {
  systemUser: SystemUser;
  currentOrganization: Organization;
  currentUsers: SystemUser[] = [];
  lookups = {
    userAccessLevels: [],
    userType: [],
    userRole: []
  };
  isHCAAdmin = false;
  isPerspayAdmin = false;
  isPerspayEdit = false;
  gridColumns = [];
  gridData = [];  
  gridDataInactive = [];
  gridDataAPI = [];
  apiUsers: SystemUser[];
  icons = {
    faUsers,
    faPlus,
    faMinus,
    faPlusCircle,
    faLaptopCode,
  };
  @ViewChild('accessGrid') public accessGrid: AccessManagementGridComponent;
  @ViewChild('inactiveUsersGrid') public inactiveUsersGrid: AccessManagementGridComponent;
  userRoleName = 'Perspay';
  userTypeName = 'Local Admin';
  apiAccessId;
  alreadyExistingSystemUser: SystemUser;
  pendingUserAdd: SystemUser;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe(s => {
      if (s) {
        this.currentOrganization = s;
        this.updateDataBasedOnCurrentlySelectedOrganization(this.currentOrganization);
      }
    });
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      data => {
        this.systemUser = data.user;
        this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
        this.lookups.userAccessLevels = filter(data.lookups.userAccessLevel, al => al.userAccessLevelCode !== 'API' && al.userAccessLevelCode !== 'SA' && al.userAccessLevelCode !== 'SA' && al.userAccessLevelCode !== 'AM' && al.userAccessLevelCode !== 'F');
        if (!this.isHCAAdmin) {
          this.lookups.userAccessLevels = filter(this.lookups.userAccessLevels, al => al.UserAccessLevelCode !== 'A');
        }
        this.lookups.userRole = data.lookups.userRole;
        this.lookups.userType = data.lookups.userType;
        this.gridColumns = this.getGridColumns();
        this.currentOrganization = this.currentOrganization || this.systemUser.userOrganizationRoles[0].organization;
        this.updateDataBasedOnCurrentlySelectedOrganization(this.currentOrganization);
      },
      err => console.log(err)
    );
  }

  getGridColumns(): any {
    const gridColumns = [
      {
        field: 'firstName',
        title: 'First name',
        format: 'string',
        width: 95
      },
      {
        field: 'lastName',
        title: 'Last name',
        format: 'string',
        width: 95,
      },
      {
        field: 'emailAddress',
        title: 'Email',
        format: 'string',
        width: 175,
      },
      {
        field: 'userAccessLevelId',
        title: 'Role',
        format: 'select',
        width: 100,
      },
      {
        field: 'effectiveStartDate',
        title: 'Access start date',
        format: 'date',
        width: 140
      },
      {
        field: 'effectiveEndDate',
        title: 'Access end date',
        format: 'date',
        width: 140
      }
    ];
    return gridColumns;
  }

  addNewSystemUser(): void {
    const newUser = new SystemUser();
    newUser.userTypeId = find(this.lookups.userType, ut => ut.userTypeName === this.userTypeName).userTypeId;
    this.gridData.push(newUser);
    this.accessGrid.resetDataForGrid();
  }

  updateDataBasedOnCurrentlySelectedOrganization(organization?: Organization): void {
    this.isPerspayAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.Perspay);
    this.isPerspayEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay);
    const agencyToSet = organization || this.currentOrganization;
    this.spinnerService.hide();
    this.spinnerService.show();
    this.userService.getUsersForOrganization(agencyToSet.organizationId).pipe(untilDestroyed(this)).subscribe(users => {
      this.currentUsers = users;
      const newGridData = [];
      const newAPIGridData = [];
      forEach(users, (u: SystemUser) => {
        forEach(u.userOrganizationRoles, (r: UserOrganizationRole) => {
          if(r.organizationId === this.currentOrganization.organizationId) {
            const newPerspay = this.generateGridDataRow(u, r);
            newGridData.push(newPerspay);
          }
        });
      });
      const now = new Date();
      this.gridData = newGridData.filter((s) => !s.effectiveEndDate || s.effectiveEndDate > now);
      this.gridDataInactive = newGridData.filter((s) => s.effectiveEndDate && s.effectiveEndDate < now);
      this.spinnerService.hide();
    });
  }

  generateGridDataRow(systemUser: SystemUser, r: UserOrganizationRole): any {
    const newPerspay = {
      systemUserId: systemUser.systemUserId,
      userTypeId: systemUser.userTypeId,
      userAccessLevelId: r.userAccessLevelId,
      firstName: systemUser.firstName,
      lastName: systemUser.lastName,
      emailAddress: systemUser.emailAddress,
      isClaimed: systemUser.isClaimed,
      effectiveStartDate: r.effectiveStartDate,
      effectiveEndDate: r.effectiveEndDate,
      userOrganizationRole: r,
    };
    return newPerspay;
  }

  async updateAccess(rowData, addToExistingUser = false): Promise<void> {
    let updatedSU;
    let updatedRole;
    if(!addToExistingUser && !rowData[1]?.systemUserId) {
      const matchingSystemUsers = await lastValueFrom(this.userService.validateEmail(rowData[1].emailAddress));
      if(matchingSystemUsers.length) {
        if(matchingSystemUsers.length === 1){
          this.pendingUserAdd = rowData;
          this.alreadyExistingSystemUser = matchingSystemUsers[0];
        }
        return this.coreService.popMessage('A system user already exists with this email address.', 'error', 4000);
      }
    }else if (addToExistingUser) {
      rowData[1].systemUserId = this.alreadyExistingSystemUser.systemUserId;
    }
    try {
      this.spinnerService.show();
      const index = rowData[0];
      const dataItem = rowData[1];
      // create or update SU information
      if (dataItem.systemUserId) {
        if(!addToExistingUser){
          updatedSU = await lastValueFrom(this.userService.updateUser(dataItem));
        }
      } else {
        updatedSU = await lastValueFrom(this.userService.createUser(dataItem));
      }
      // create or update role
      if (dataItem.userOrganizationRole && !addToExistingUser) {
        dataItem.userOrganizationRole.effectiveStartDate = dataItem.effectiveStartDate;
        dataItem.userOrganizationRole.effectiveEndDate = dataItem.effectiveEndDate;
        dataItem.userOrganizationRole.userAccessLevelId = dataItem.userAccessLevelId;

        updatedRole = await lastValueFrom(this.userService.updateUserRole(updatedSU.systemUserId, dataItem.userOrganizationRole));
      } else {
        const newRole = new UserOrganizationRole();
        newRole.organizationId = this.currentOrganization.organizationId;
        newRole.userRoleId = find(this.lookups.userRole, ur => ur.userRoleName === this.userRoleName).userRoleId;
        newRole.userAccessLevelId = dataItem.userAccessLevelId;
        newRole.effectiveStartDate = dataItem.effectiveStartDate;
        newRole.effectiveEndDate = dataItem.effectiveEndDate;
        updatedRole = await lastValueFrom(this.userService.createUserRole(updatedSU?.systemUserId || dataItem.systemUserId, newRole));
        if(addToExistingUser){
        }else {
          const updatedRow = this.generateGridDataRow(updatedSU, updatedRole);
          this.gridData.splice(index, 1, updatedRow);
          this.accessGrid.resetDataForGrid();
        }
      }
      this.updateDataBasedOnCurrentlySelectedOrganization(this.currentOrganization);
      this.accessGrid.resetDataForGrid();
      this.spinnerService.hide();
      // todo - update user
    } catch (err)  {
      console.log(err);
      this.spinnerService.hide();
      this.coreService.popMessage('something went wrong with updating this user. Please try again, or contact your HCA contact', 'error', 3000);
    }
  }

  resetTheCurrentSystemUserRecord(currentSystemUserId: string, gridIndex: number): void {
    this.accessGrid.resetDataForGrid();
  }

  async disassociate(s: SystemUser): Promise<void> {
    let releasedUser;
    try {
      this.spinnerService.show();
      releasedUser = await lastValueFrom(this.userService.disassociateAdminUser(s.systemUserId, this.currentOrganization.organizationId));
      const updatedRow = this.generateGridDataRow(releasedUser, releasedUser.userOrganizationRoles[0]);
      const index = findIndex(this.gridDataInactive, gd => gd.systemUserId === releasedUser.systemUserId)
      this.gridDataInactive.splice(index, 1, updatedRow);
      this.inactiveUsersGrid.resetDataForGrid();
      this.spinnerService.hide();
    } catch (err) {
      this.spinnerService.hide();
      console.log('error', err);
    }
  }


  async deleteSystemUser(s: SystemUser): Promise<void> {
    try {
      this.spinnerService.show();
      await lastValueFrom(this.userService.deleteUser(s.systemUserId, this.currentOrganization.organizationId));
      this.updateDataBasedOnCurrentlySelectedOrganization(this.currentOrganization);
      this.accessGrid.resetDataForGrid();
      this.spinnerService.hide();
    } catch (err) {
      this.spinnerService.hide();
      console.log('error', err);
    }
  }
}
