export default class DependentCoverageElection {
  subscriberMemberId: string;
  memberId: string;
  planTypeIds: string[];
  enrollmentPeriodId: string;
  changeReasonId: string;
  divorceWithin60Days: boolean;
  divorceOrDeathDate: Date;

  constructor(dependentCoverageElection) {
    if (dependentCoverageElection) {
      this.subscriberMemberId = dependentCoverageElection.subscriberMemberId;
      this.memberId = dependentCoverageElection.memberId;
      this.planTypeIds = dependentCoverageElection.planTypeIds;
      this.enrollmentPeriodId = dependentCoverageElection.enrollmentPeriodId;
      this.changeReasonId = dependentCoverageElection.changeReasonId;
      this.divorceWithin60Days = dependentCoverageElection.divorceWithin60Days;
      this.divorceOrDeathDate = dependentCoverageElection.divorceOrDeathDate;
    }
  }
}
