<div *ngIf="!isPebbRetireeVision && !isCobraOE">
<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
  <div class="card-body" fxLayout="column">
    <div class="container-fluid">
      <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
        <div class="form-group" fxFlex="25">
          <label [for]="'socialSecurityNumber' + idx">SSN</label>
          <kendo-maskedtextbox [mask]="env.ssnMask" [pattern]="env.ssnItinRegex"
            [(ngModel)]="currentSpouse.socialSecurityNumber" [maskOnFocus]="true" [name]="'socialSecurityNumber' + idx"
            #ssn="ngModel" class="form-control" [forbiddenValues]="env.invalidSSNValues"></kendo-maskedtextbox>
        </div>
        <div class="form-group" fxFlex="25">
          <date-picker #birthDate="ngModel" [name]="'birthDate' + idx" label="Birth date" required
            [(ngModel)]="currentSpouse.birthDate"></date-picker>
        </div>
        <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
          <label class="k-label">Sex assigned at birth<sup>1</sup></label>
          <radio-list [name]="'birthSex' + idx" [(ngModel)]="currentSpouse.birthSex" required
            [items]="{ Male: 'M', Female: 'F' }"></radio-list>
        </kendo-formfield>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
        <div class="form-group required" fxFlex="50">
          <label [for]="'lastName' + idx">Last Name</label>
          <input type="text" class="form-control" [id]="'lastName' + idx" [name]="'lastName' + idx"
            [(ngModel)]="currentSpouse.lastName" pattern="^(?!\s)([a-zA-Z \-]){1,}$" required />
        </div>
        <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
          <label class="k-label required">Gender Identity<sup>2</sup></label>
          <radio-list [name]="'genderIdentity' + idx" [(ngModel)]="currentSpouse.genderIdentity"
            [items]="{ Male: 'M', Female: 'F', X: 'X' }" required></radio-list>
        </kendo-formfield>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
        <div class="form-group required" fxFlex="50">
          <label [for]="'firstName' + idx">First Name</label>
          <input type="text" class="form-control" [id]="'firstName' + idx" [name]="'firstName' + idx"
            [(ngModel)]="currentSpouse.firstName" pattern="^(?!\s)([a-zA-Z \-]){1,}$" required />
        </div>
        <div class="form-group" fxFlex="15">
          <label [for]="'middleInitial' + idx">Middle Initial</label>
          <input type="text" class="form-control" [id]="'middleInitial' + idx" [name]="'middleInitial' + idx"
            [(ngModel)]="currentSpouse.middleName" pattern="^(?!\s)([a-zA-Z \-]){1,}$" />
        </div>
        <div class="form-group" fxFlex="15">
          <label [for]="'suffix' + idx">Suffix</label>
          <input type="text" class="form-control" [id]="'suffix' + idx" [name]="'suffix' + idx"
            [(ngModel)]="currentSpouse.suffix" />
        </div>
      </div>
      <checkbox bold name="addressIsSameAsSubscriberInd{{index}}"
          [(ngModel)]="currentSpouse.addressIsSameAsSubscriberInd">
          Residential address is the same as subscriber
     </checkbox>
      <div class="form-row"  *ngIf="!currentSpouse.addressIsSameAsSubscriberInd">
        <address #residentialAddress view="inline" name="residentialAddress" [(ngModel)]="currentSpouse"></address>
      </div>
      <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
        <div class="form-group" fxFlex="25">
          <date-picker #dateOfMarriage="ngModel" [name]="'dateOfMarriage' + idx" label="Date of marriage"
            [(ngModel)]="currentSpouse.spouseForm.dateOfMarriage"></date-picker>
        </div>
      </div>

      <div class="form-row" fxFlexFill>
        <div fxLayout="column" fxFlex="100">
          <div class="hca-selfPay-divider" fxFlex="45"></div>
          <span class="hca-selfPay-small-note" fxFlex="100"> 1. This field is required for health care services. </span>
          <span class="hca-selfPay-small-note" fxFlex="100">
            2. Gender X means a gender that is not exclusively male or female. This field will be kept private to the
            extent allowable by law.
          </span>
        </div>
      </div>
      <div *ngIf="requestType.selfPayTypeCode === 'C'">
        <label class="k-label">Is this person coverage by another group medical plan?</label>
        <yes-no [name]="'enrolledInAnotherGroupMedical' + idx" style="width: auto;" orientation="horizontal-list"
          [stretch]="false" [(ngModel)]="currentSpouse.enrolledInAnotherGroupMedical"></yes-no>
        <div class="form-group row">
          <date-picker #medicarePartDEffectiveDate="ngModel" [name]="'enrolledInAnotherGroupMedicalEffectiveDate' + idx"
            [required]="currentSpouse.enrolledInAnotherGroupMedical"
            [(ngModel)]="currentSpousee.enrolledInAnotherGroupMedicalEffectiveDate">
            <span class="col-form-label">If yes, effective date</span>
          </date-picker>
        </div>

        <label class="k-label">Is this person coverage by another group dental plan?</label>
        <yes-no [name]="'enrolledInAnotherGroupDental' + idx" style="width: auto;" orientation="horizontal-list"
          [stretch]="false" [(ngModel)]="currentSpouse.enrolledInAnotherGroupDental"></yes-no>
        <div class="form-group row">
          <date-picker #medicarePartDEffectiveDate="ngModel" [name]="'enrolledInAnotherGroupDentalEffectiveDate' + idx"
            [required]="currentSpouse.enrolledInAnotherGroupDental"
            [(ngModel)]="currentSpouse.enrolledInAnotherGroupDentalEffectiveDate">
            <span class="col-form-label">If yes, effective date</span>
          </date-picker>
        </div>
        <label class="k-label">Does this person receive social security disability?</label>
        <yes-no [name]="'socialSecurityDisability' + idx" style="width: auto;" orientation="horizontal-list"
          [stretch]="false" [(ngModel)]="currentSpouse.socialSecurityDisability"></yes-no>
        <div class="form-group row">
          <date-picker #medicarePartDEffectiveDate="ngModel" [name]="'socialSecurityDisabilityEffectiveDate' + idx"
            [required]="currentSpouse.socialSecurityDisability"
            [(ngModel)]="currentSpouse.socialSecurityDisabilityEffectiveDate">
            <span class="col-form-label">If yes, effective date and attach a copy of their Social Security Disability
              Award letter. Write your last name and last four digits of your Social Security number on the copy. You
              and your enrolled dependents may be eligible for additional months of coverage.
            </span>
          </date-picker>
        </div>
      </div>
      <div
        *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OER'"
        class="form-row" fxFlexFill style="margin-bottom: 20px">
        <span class="soe-form-small-header">Medicare Information</span>
        <span style="width: 100%; margin-bottom: 15px"><b>Are you enrolled in Medicare Part A or Part B?</b></span>
        <div fxLayout="row" fxFlexFill>
          <kendo-formfield [orientation]="'horizontal'" fxFlex="20">
            <label class="k-label">Part A (hospital)</label>
            <yes-no [name]="'medicarePartAEnrolledInd' + idx" style="width: auto;" orientation="horizontal-list"
              [stretch]="false" [(ngModel)]="currentSpouse.memberMedicare.medicarePartAEnrolledInd"></yes-no>
          </kendo-formfield>
          <div class="form-group row">
            <date-picker #medicarePartAEffectiveDate="ngModel" [name]="'medicarePartAEffectiveDate' + idx"
              [min]="currentSpouse.birthDate" [required]="currentSpouse.memberMedicare.medicarePartAEnrolledInd"
              [(ngModel)]="currentSpouse.memberMedicare.medicarePartAEffectiveDate">
              <span class="col-form-label">If yes, enter the effective date shown on your Medicare card</span>
            </date-picker>
          </div>
        </div>
        <div fxLayout="row" fxFlexFill>
          <kendo-formfield [orientation]="'horizontal'" fxFlex="20" style="margin-top: 15px">
            <label class="k-label">Part B (medical)</label>
            <yes-no [name]="'medicarePartBEnrolledInd' + idx" style="width: auto;" orientation="horizontal-list"
              [stretch]="false" [(ngModel)]="currentSpouse.memberMedicare.medicarePartBEnrolledInd"></yes-no>
          </kendo-formfield>
          <div class="form-group row">
            <date-picker #medicarePartBEffectiveDate="ngModel" [name]="'medicarePartBEffectiveDate' + idx"
              [min]="currentSpouse.birthDate" [required]="currentSpouse.memberMedicare.medicarePartBEnrolledInd"
              [(ngModel)]="currentSpouse.memberMedicare.medicarePartBEffectiveDate">
              <span class="col-form-label">If yes, enter the effective date shown on your Medicare card</span>
            </date-picker>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
          <div class="form-group required" fxFlex="25" style="margin-top: 15px">
            <label for="medicareNumber">Medicare number (HICN)</label>
            <input pattern="[a-zA-Z0-9 -]*"
              [required]="currentSpouse.memberMedicare.medicarePartBEnrolledInd || currentSpouse.memberMedicare.medicarePartAEnrolledInd ? 'required' : null"
              type="text" class="form-control" [name]="'medicareNumber' + idx"
              [(ngModel)]="currentSpouse.memberMedicare.medicareNumber" />
          </div>
        </div>
        <p style="margin-top: 15px" *ngIf="!isCobra || agency != 'SEBB'">
          If Yes, proof is required. Upload or mail a copy of all pages of their entire Medicare benefit verification
          letter or a copy of their Medicare card if we don't already have a copy. If mailing, write your last name and
          the last four digits of your Social Security Number on the copy so we can identify the account. If your
          dependent is eligible for Medicare, they must enroll and stay enrolled in both Medicare Part A
          and Part B to keep {{agency}} retiree health plan coverage.
        </p>
        <span style="width: 100%; margin-bottom: 15px"><b>Are you enrolled in Medicare Part D (prescription drug
            coverage)?</b></span>
        <div fxLayout="row" fxFlexFill>
          <kendo-formfield [orientation]="'horizontal'" fxFlex="20">
            <yes-no [name]="'medicarePartDEnrolledInd' + idx" style="width: auto;" orientation="horizontal-list"
              [stretch]="false" [(ngModel)]="currentSpouse.memberMedicare.medicarePartDEnrolledInd"></yes-no>
          </kendo-formfield>
          <div class="form-group row">
            <date-picker #medicarePartDEffectiveDate="ngModel" [name]="'medicarePartDEffectiveDate' + idx"
              [required]="currentSpouse.memberMedicare.medicarePartDEnrolledInd"
              [(ngModel)]="currentSpouse.memberMedicare.medicarePartDEffectiveDate">
              <span class="col-form-label">If yes, effective date</span>
            </date-picker>
          </div>
        </div>
        <span style="width: 100%; margin-bottom: 15px"><b>Are you enrolled in Medicaid with Medicare Part D?</b></span>
        <div fxLayout="row" fxFlexFill>
          <kendo-formfield [orientation]="'horizontal'" fxFlex="20">
            <yes-no [name]="'medicaidMedicarePartDEnrolledInd' + idx" style="width: auto;" orientation="horizontal-list"
              [stretch]="false" [(ngModel)]="currentSpouse.memberMedicare.medicaidMedicarePartDEnrolledInd"></yes-no>
          </kendo-formfield>
          <div class="form-group row">
            <date-picker #medicaidMedicarePartDEffectiveDate="ngModel"
              [name]="'medicaidMedicarePartDEffectiveDate' + idx"
              [required]="currentSpouse.memberMedicare.medicaidMedicarePartDEnrolledInd"
              [(ngModel)]="currentSpouse.memberMedicare.medicaidMedicarePartDEffectiveDate">
              <span class="col-form-label">If yes, effective date</span>
            </date-picker>
          </div>
        </div>
        <p style="margin-top: 20px" *ngIf="!isCobra || agency != 'SEBB'">
          If Yes, they may enroll only in Premera Blue Cross Medicare Supplement Plan G. Plan F enrollees may stay
          in the plan. If they want to enroll in any other PEBB medical plan, they must disenroll from the Part D plan.
        </p>
      </div>
      <div
        *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OER'"
        class="form-row" style="padding-top: 20px" fxFlexFill>
        <span class="selfPay-form-small-header">I want to enroll this dependent in</span>
        <span *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfpayTypeCode === 'OER'">
          (By choosing to enroll into coverage for yourself, your dependent(s) are automatically enrolled in the same
          plan as you.)
        </span>
      </div>
      <div
        *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OER'"
        class="form-row" fxFlexFill style="margin-bottom: 20px">
        <div fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between">
          <div class="k-form-field bigger" style="margin-bottom: 15px">
            <checkbox [name]="'medicalCover' + idx"
              [(ngModel)]="currentSpouse.medicalCoverInd">Medical</checkbox>
          </div>
        </div>
        <div
          *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OER'"
          fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between">
          <div class="k-form-field bigger" style="margin-bottom: 15px">
            <checkbox [name]="'dentalCover' + idx"
              [(ngModel)]="currentSpouse.dentalCoverInd">Dental</checkbox>
          </div>
        </div>
        <div
          *ngIf="(agency === 'SEBB' && (requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OER'))"
          fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between">
          <div class="k-form-field bigger" style="margin-bottom: 15px">
            <checkbox [name]="'visionCover' + idx" [(ngModel)]="currentSpouse.visionCoverInd">Vision</checkbox>
          </div>
        </div>
      </div>
      <div
        *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OER'"
        fxLayoutAlign="row">
        <fa-icon fxFlex="5" [icon]="icons.faExclamationCircle" color="primary" size="1x"></fa-icon><span>Premium
          surcharges do not apply if subscriber is enrolled in Medicare Part A and Part B</span>
      </div>

      <div *ngIf="requestType.selfPayTypeCode !== 'R'" class="form-row" style="padding-top: 20px" fxFlexFill>
        <h3 class="mobile-text-centered">Choose one box for each type of coverage</h3>
      </div>
      <div *ngIf="requestType.selfPayTypeCode !== 'R'" class="form-row" fxFlexFill style="margin-bottom: 20px">
        <div fxLayout="column" fxFlex="33" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between">
          <span class="selfPay-form-small-header">Medical coverage</span>
          <checkbox-list orientation="horizontal" [name]="'medicalCover' + idx" [multi]="false"
            [(ngModel)]="currentSpouse.medicalCoverInd"
            [items]="{ Cover: true, 'Remove from coverage': false }"></checkbox-list>
        </div>
        <div fxLayout="column" fxFlex="33" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between">
          <span class="selfPay-form-small-header">Dental coverage</span>
          <checkbox-list orientation="horizontal" [name]="'dentalCover' + idx" [multi]="false"
            [(ngModel)]="currentSpouse.dentalCoverInd"
            [items]="{ Cover: true, 'Remove from coverage': false }"></checkbox-list>
        </div>
        <div *ngIf="agency === 'SEBB' || isPebbLwopVision" fxLayout="column" fxFlex="33" fxFlex.fxFlex.lt-md="100"
          fxLayoutAlign="space-between">
          <span class="selfPay-form-small-header">Vision coverage</span>
          <checkbox-list orientation="horizontal" [name]="'visionCover' + idx" [multi]="false"
            [(ngModel)]="currentSpouse.visionCoverInd"
            [items]="{ Cover: true, 'Remove from coverage': false }"></checkbox-list>
        </div>
      </div>
      <div class="form-row" fxFlexFill style="margin-bottom: 20px">
        <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between">
          <span class="selfPay-form-small-header">Tobacco use premium surcharge</span>
          <span style="padding-bottom: 10px">
            Response required if you are enrolling your spouse or state-registered domestic partner (SRDP) in medical
            coverage. If you check Yes or do not check any boxes below, you will be charged
            the $25-per-account premium surcharge in addition to your monthly medical premium.
            <br />
            <br />
            <b>Does the tobacco use premium surcharge apply to you?</b> Check one:
          </span>
          <div
            *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OER' || requestType.selfPayTypeCode === 'SOER'"
            class="k-form-field bigger" style="margin-bottom: 15px">
            <checkbox [name]="'tobaccoNA' + idx" [(ngModel)]="currentSpouse.surchargeNA" (change)="updateTobacco()">
              Subscriber is enrolled in Medicare Part A and Part B. Premium surcharge does not apply.
            </checkbox>
          </div>
          <div class="k-form-field bigger" style="margin-bottom: 15px">
            <radio [name]="'tobaccoYes' + idx" [disabled]="currentSpouse.surchargeNA" radioAsCheckbox [value]="true"
              [(ngModel)]="currentSpouse.tobaccoUseInd">
              <b>Yes,</b> I am subject to the $25 premium surcharge. This dependent has used tobacco products in the
              past two months.
            </radio>
          </div>
          <div class="k-form-field bigger" style="margin-bottom: 20px">
            <radio [name]="'tobaccoNo' + idx" [disabled]="currentSpouse.surchargeNA" radioAsCheckbox [value]="false"
              [(ngModel)]="currentSpouse.tobaccoUseInd">
              <b>No,</b> I am not subject to the $25 premium surcharge. This person has not used tobacco products in the
              past two months, or has enrolled in or accessed one of the tobacco cessation
              resources noted.
            </radio>
          </div>
        </div>
      </div>

      <div class="form-row" fxFlexFill style="margin-bottom: 20px">
        <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between">
          <span class="selfPay-form-small-header">Spouse or state-registered domestic partner (SRDP) coverage premium
            surcharge </span>
          <span style="padding-bottom: 10px">
            Response required if you are enrolling your spouse or SRDP in medical coverage. The {{agency}} Program
            requires a $50 premium surcharge in addition to your monthly medical premium if you are
            enrolling your spouse or SRDP in {{agency}} medical and they have chosen not to enroll in another
            Employer based - Group medical that is comparable to {{agency}}'s Uniform Medical Plan Classic.
            <br />
            <br />
            <div fxLayout="column">
              <ol class="hca-ordered-list">
                <li><yes-no
                name="spouseAttestQ1"
                label="Are you covering your spouse or SRDP in a PEBB medical plan under your account in 2024?"
                [stretch]="false"
                style="width: auto;"
                orientation="horizontal-list"
                [(ngModel)]="currentSpouse.spouseAttestQ1"
                >
              </yes-no></li>
              <li><yes-no
                name="spouseAttestQ2"
                label="Will they be eligible for medical coverage through their employer in 2024? (If they will not be employed in 2024, answer No.)"
                [stretch]="false"
                style="width: auto;"
                orientation="horizontal-list"
                [(ngModel)]="currentSpouse.spouseAttestQ2"
                >
              </yes-no></li>
              <li><yes-no
                name="spouseAttestQ3"
                label="Will their employer offer at least one medical plan that serves their county of residence in 2024?"
                [stretch]="false"
                style="width: auto;"
                orientation="horizontal-list"
                [(ngModel)]="currentSpouse.spouseAttestQ3"
                >
              </yes-no></li>
              <li><yes-no
                name="spouseAttestQ4"
                label="Have they chosen not to enroll in their employer's medical (including SEBB) coverage in 2024?"
                [stretch]="false"
                style="width: auto;"
                orientation="horizontal-list"
                [(ngModel)]="currentSpouse.spouseAttestQ4"
                >
              </yes-no></li>
              <li><yes-no
                name="spouseAttestQ5"
                label="Will the coverage offered by their employer in 2024 not be through the PEBB Program or a TRICARE plan?
                Answer Yes if their employer does not offer PEBB medical coverage or a TRICARE plan. Answer No if their
                employer offers PEBB medical coverage or a TRICARE plan."
                [stretch]="false"
                style="width: auto;"
                orientation="horizontal-list"
                [(ngModel)]="currentSpouse.spouseAttestQ5"
                >
              </yes-no></li>
              <li><yes-no
                name="spouseAttestQ6"
                label="Will their share of the medical premium through their employer be less than $117.81 per month in 2024?"
                [stretch]="false"
                style="width: auto;"
                orientation="horizontal-list"
                [(ngModel)]="currentSpouse.spouseAttestQ6"
                >
              </yes-no> </li> 
              </ol>
            </div>                                                       
            If you answered No to any of these questions, check no below. You will not be charged the surcharge.
            If you answered Yes to all of these questions:
            <br />
            <br />
            <ol>
              <li>Ask your spouse or SRDP for the Summary of Benefits and Coverage (SBC) for all medical plans that:
                <ol type="a">
                  <li>Serve their county of residence.</li>
                  <li>Have a monthly premium of less than $117.81 per month for the employee.</li>
                </ol>

              </li>
              <li>Use the SBC information to answer the questions in the {{agency}} Spousal Plan Calculator online tool. You
                will get a Yes or No response from the calculator. Enter your response below.</li>
            </ol>
            <br />
            <b>Does the spouse or SRDP coverage premium surcharge apply to you?</b> Check one:
          </span>
          <div fxlayout="row" fxFlex="100" fxLayoutAlign="space-between">
            <div class="k-form-field bigger" style="margin-bottom: 15px" fxFlex="50">
              <radio [name]="'spousalYes' + idx" radioAsCheckbox [disabled]="currentSpouse.surchargeNA" value="Y"
                [(ngModel)]="currentSpouse.spouseForm.spouseSurchargeTriState">
                <b>Yes,</b> I am subject to the $50 premium surcharge. I completed the
                <a [href]="agency === 'SEBB' && isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/spousal-plan-calculator' : 'https://www.hca.wa.gov/employee-retiree-benefits/spousal-plan-calculator'"
                  target="_blank">{{agency}} Spousal Plan Calculator</a> online.
              </radio>
            </div>
            <span *ngIf="!currentSpouse.surchargeNA" class="selfPay-form-small-header" fxFlex="40">
              <fa-icon class="hca-fontawesome-icon" [icon]="icons.faExclamationCircle"></fa-icon>
              If you check <b>Yes</b> or do not check any boxes below, you will be charged the $50 spouse or SRDP
              coverage premium surcharge.
            </span>
          </div>
          <div class="k-form-field bigger" style="margin-bottom: 20px">
            <radio [name]="'spousalNo' + idx" radioAsCheckbox [disabled]="currentSpouse.surchargeNA" value="N"
              [(ngModel)]="currentSpouse.spouseForm.spouseSurchargeTriState">
              <b>No,</b> I am not subject to the $50 premium surcharge. I completed the {{agency}} Spousal Plan
              Calculator online.
            </radio>
          </div>
          <div class="k-form-field bigger" style="margin-bottom: 20px">
            <radio [name]="'spousalHelp' + idx" radioAsCheckbox [disabled]="currentSpouse.surchargeNA" value="H"
              [(ngModel)]="currentSpouse.spouseForm.spouseSurchargeTriState">
              The {{agency}} Program to help determine if premium surcharge applies. I am completing and submitting a
              printed {{agency}} Spousal Plan
              Calculator.
            </radio>
          </div>
        </div>
      </div>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
        <button fxFlex="30" (click)="cancelChanges()" class="btn btn-light">Cancel</button>
        <button fxFlex="30" (click)="emitSubmitDependent()" class="btn btn-primary">Save dependent</button>
      </div>
    </div>
  </div>
</div>
</div>

<!-- RETIREE OE 2025 -->

<form nativeControlElement #dependentForm="ngForm" #formContainer *ngIf="isPebbRetireeVision || isCobraOE">
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group" fxFlex="25">
      <label [for]="'socialSecurityNumber' + idx">SSN</label>
      <input #ssn="ngModel" type="text" class="form-control" minlength="11" maxlength="11"
        [ngModel]="(!noSSN ? currentSpouse.socialSecurityNumber : null) | ssn"
        (ngModelChange)="currentSpouse.socialSecurityNumber = $event?.split('-').join('')"
        [name]="'socialSecurityNumber' + idx" [id]="'socialSecurityNumber' + idx" />
    </div>
    <div class="form-group" fxFlex="25">
      <date-picker #birthDate="ngModel" [name]="'birthDate' + idx" label="Birth date"
        [(ngModel)]="currentSpouse.birthDate"></date-picker>
    </div>
    <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
      <label class="k-label">Sex assigned at birth<sup>1</sup></label>
      <radio-list [name]="'birthSex' + idx" [(ngModel)]="currentSpouse.birthSex"
        [items]="{ Male: 'M', Female: 'F' }"></radio-list>
    </kendo-formfield>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group" fxFlex="50">
      <label [for]="'lastName' + idx">Last Name</label>
      <input type="text" class="form-control" [name]="'lastName' + idx" [id]="'lastName' + idx"
        [(ngModel)]="currentSpouse.lastName" />
    </div>
    <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
      <label class="k-label">Gender Identity<sup>2</sup></label>
      <radio-list [name]="'genderIdentity' + idx" [(ngModel)]="currentSpouse.genderIdentity"
        [items]="{ Male: 'M', Female: 'F', X: 'X' }"></radio-list>
    </kendo-formfield>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group" fxFlex="50">
      <label [for]="'firstName' + idx">First Name</label>
      <input type="text" class="form-control" [id]="'firstName' + idx" [name]="'firstName' + idx"
        [(ngModel)]="currentSpouse.firstName" />
    </div>
    <div class="form-group" fxFlex="15">
      <label [for]="'middleInitial' + idx">Middle Initial</label>
      <input type="text" class="form-control" [id]="'middleInitial' + idx" [name]="'middleInitial' + idx"
        [(ngModel)]="currentSpouse.middleName" />
    </div>
    <div class="form-group" fxFlex="15">
      <label [for]="'suffix' + idx">Suffix</label>
      <input type="text" class="form-control" [id]="'suffix' + idx" [name]="'suffix' + idx"
        [(ngModel)]="currentSpouse.suffix" />
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
    *ngIf="currentSpouse.isSpouse">
    <div class="form-group" fxFlex="25">
      <date-picker #dateOfMarriage="ngModel" [name]="'dateOfMarriage' + idx" label="Date of marriage"
        [(ngModel)]="currentSpouse.partnershipStartDate"></date-picker>
    </div>
  </div>
  <checkbox bold name="addressSameAsSubscriber{{index}}"
    [(ngModel)]="currentSpouse.addressSameAsSubscriber">
    Residential address is the same as subscriber
  </checkbox>
  <div class="form-row" *ngIf="!currentSpouse.addressSameAsSubscriber">
    <address #residentialAddress view="inline" name="residentialAddress" [(ngModel)]="currentSpouse.residentialAddress"></address>
    <div style="margin-bottom: 20px;">
      Enrollment in Medicare Advantage Plans (MAPD) or Medicare Part D has specific requirements associated with
      residential addresses.
      PO boxes and out-of-country addresses may not be eligible for MAPD/Part D enrollment and will need to be reviewed
      by HCA for eligibility.
      Proof of residence may need to be provided.
    </div>
  </div>
  <div class="form-row" fxFlexFill>
    <div fxLayout="column" fxFlex="100">
      <div class="hca-selfPay-divider" fxFlex="45"></div>
      <span class="hca-selfPay-small-note" fxFlex="100"> 1. This field is required for health care services. </span>
      <span class="hca-selfPay-small-note" fxFlex="100">
        2. Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent
        allowable by law.
      </span>
    </div>
  </div>
  <!-- COVERAGE -->
  <div>
    <div class="form-row" style="padding-top: 20px" fxFlexFill>
      <h4>Coverage options</h4>
      <span class="soe-form-small-header">Enroll dependent in (or remain enrolled)</span>
    </div>
    <div class="form-row" fxFlexFill>
      <div class="form-row" fxFlexFill style="margin-bottom: 20px">
        <div fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between" class="fxGap-small">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" style="margin-bottom: 20px"
            class="fxGap-medium-sm">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox name="medicalEnrollInd" [(ngModel)]="currentSpouse.medicalCoverInd"
              (ngModelChange)="handleCoverageChange($event)"
               >Medical</checkbox>
            </div>
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox name="dentalEnrollInd" [(ngModel)]="currentSpouse.dentalCoverInd"
              (ngModelChange)="handleCoverageChange($event)"
                >Dental
              </checkbox>
            </div>
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox name="visionEnrollInd" [(ngModel)]="currentSpouse.visionCoverInd"
                (ngModelChange)="handleCoverageChange($event)"
                [disabled]="currentSpouse.medicarePartBInd">
                Vision</checkbox>
            </div>
          </div>
        </div>
        <div *ngIf="isPebbRetireeVision" fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between" class="fxGap-small">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" style="margin-bottom: 20px">
            <span>
            Dependents can only be enrolled in coverages that are elected for the subscriber.  The exception(s) are:
              <br />
              <ol>
                <li>The standalone vision option is available for non-Medicare enrolled dependents if subscriber is enrolled in Medicare</li>
                <li>If subscriber is deferring due to Medicare/Medicaid the dependent can enroll in coverages the subscriber isn’t also enrolled in. </li>
              </ol>
              <br />
              Coverage that is terminated will end at the end of the current calendar/plan year and could require additional forms to be submitted if enrolled in a Medicare Advantage (MA) or Medicare Advantage Prescription Drug Plan (MAPD). 
              <br />
              <a target="_blank" href="https://www.hca.wa.gov/assets/pebb/51-0556-medicare-advantage-disenrollment-form-d-2024.pdf">Form D</a>
            </span>
          </div>
        </div>
        <div *ngIf="isCobraOE" fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between" class="fxGap-small">
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" style="margin-bottom: 20px">
            <span>
              Elect the coverage(s) that you wish to enroll this dependent in (or remain enrolled in).  You can end an individual coverage by deselecting that coverage and it will be ended at the end of the current plan/calendar year.  
              <br/>
              <br/>
              Termination of coverage from a Medicare Advantage plan (MA plan) may require an additional form.
              <br/>
              <a target="_blank" href="https://www.hca.wa.gov/assets/pebb/51-0556-medicare-advantage-disenrollment-form-d-2024.pdf">Form D</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
   <!-- MEDICARE -->
   <div  class="form-row" fxFlexFill style="margin-bottom: 20px">
    <span class="soe-form-small-header">Medicare information</span>
    <span style="width: 100%; margin-bottom: 15px"><b>Are you enrolled in Medicare Part A or Part B?</b></span>
    <div fxLayout="row" fxFlexFill class="fxGap-large">
      <div fxLayout="column">
        <div>
          <label for="medicarePartA" class="k-label">Part A (hospital)</label>  
        </div>
        <yes-no
        name="medicarePartA"
        style="width: auto;"
        orientation="horizontal-list"
        [stretch]="false"
        [required]="requestType === 'R' || requestType === 'C'"
        [(ngModel)]="currentSpouse.medicarePartAInd"
        (ngModelChange)="updateMedicarePartA($event)"
        [disabled]="disableFormForNoCoverage"
        ></yes-no>
      </div>
      <div class="form-inline">
        <div class="form-group">
          <date-picker
            #medicarePartADate="ngModel"
            name="medicarePartADate"
            labelFlex="70"
            inputFlex="30"
            [min]="currentSpouse.birthDate"
            [required]="currentSpouse.medicarePartAInd"
            [disabled]="disableFormForNoCoverage"
            [(ngModel)]="currentSpouse.medicarePartADate">
            <span style="white-space: nowrap; margin-right: 20px">If yes, enter the effective date shown on the Medicare card</span>
          </date-picker>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxFlexFill style="margin-top: 15px"  class="fxGap-large">
      <div fxLayout="column">
        <div>
          <label for="medicarePartB" class="k-label">Part B (medical)</label>
        </div>
        <yes-no
          name="medicarePartB"
          style="width: auto;"
          orientation="horizontal-list"
          [stretch]="false"
          [required]="requestType === 'R' || requestType === 'C'"
          [(ngModel)]="currentSpouse.medicarePartBInd"
          [disabled]="disableFormForNoCoverage"
          (ngModelChange)="updateMedicarePartB($event)"
          ></yes-no>
      </div>
      <div class="form-inline">
        <date-picker
          #medicarePartBDate="ngModel"
          name="medicarePartBDate"
          labelFlex="70"
          inputFlex="30"
          [min]="currentSpouse.birthDate"
          [required]="currentSpouse.medicarePartBInd"
          [disabled]="disableFormForNoCoverage"
          [(ngModel)]="currentSpouse.medicarePartBDate">
          <span style="white-space: nowrap; margin-right: 20px">If yes, enter the effective date shown on the Medicare card</span>
        </date-picker>
      </div>
    </div>
    <div class="form-group" fxFill>
      <label for="suffix">Medicare number (11 digits)</label> <b>      Please verify the accuracy of your Medicare Beneficiary Number (MBI)</b>
      <input [disabled]="disableFormForNoCoverage" pattern="[a-zA-Z0-9]*" type="text" class="form-control" id="medicareNumber" name="medicareNumber" [(ngModel)]="currentSpouse.medicareNumber" minlength="11" maxlength="11" />
    </div>          
    <p *ngIf="requestType === 'OER'">
      If Yes, proof is required. Upload or mail a copy of all pages of your Medicare benefit verification letter or a copy of your Medicare card if we don't already have a copy. If mailing, write your last name and
      the last four digits of your Social Security Number on the copy so we can identify your account. If you are eligible for Medicare, you must enroll and stay enrolled in both Medicare Part A
      and Part B to keep PEBB retiree health plan coverage.
    </p>
  </div>

  <div *ngIf="requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OER'" fxLayoutAlign="row">
    <fa-icon fxFlex="5" [icon]="icons.faExclamationCircle" color="primary" size="1x"></fa-icon><span>Premium surcharges
      do not apply if subscriber is enrolled in Medicare Part A and Part B</span>
  </div>

  <div class="form-row" fxFlexFill style="margin-bottom: 20px">
    <div *ngIf="currentSpouse.birthDate < thirteenYearsDate" fxLayout="column" fxFlex="100"
      fxLayoutAlign="space-between">
      <span class="selfPay-form-small-header">Tobacco use premium surcharge</span>
      <span style="padding-bottom: 10px">
        <span *ngIf="currentSpouse.relationshipTypeName === 'Spouse/state-registered domestic partner'">
          Response required if you are enrolling your spouse or state-registered domestic partner (SRDP) in medical
          coverage. If you check Yes or do not check any boxes below, you will be charged the
          $25-per-account premium surcharge in addition to your monthly medical premium.
        </span>
        <span *ngIf="currentSpouse.relationshipTypeName !== 'Spouse/state-registered domestic partner'">
          Response required if you are enrolling dependents age 13 and older in medical coverage. If you check Yes or do
          not check any boxes below, you will be charged the $25-per-account premium
          surcharge in addition to your monthly medical premium.
        </span>
        <br />
        <br />
        <b>Does the tobacco use premium surcharge apply to you?</b> Check one:
      </span>
      <div
        *ngIf="requestType.selfPayTypeCode !== 'U' && requestType.selfPayTypeCode !== 'OEU' && requestType.selfPayTypeCode !== 'SOEU' && (agency != 'SEBB' || !isCobra)"
        class="k-form-field bigger" style="margin-bottom: 15px">
        <checkbox [name]="'tobaccoNA' + idx" [(ngModel)]="currentSpouse.surchargeNA" [disabled]="disableFormForNoCoverage" (change)="updateTobacco()">
          Subscriber is enrolled in Medicare Part A and Part B. Premium surcharge does not apply.
        </checkbox>
      </div>
      <div class="k-form-field bigger" style="margin-bottom: 15px">
        <radio [name]="'tobaccoYes' + idx" [disabled]="currentSpouse.surchargeNA || disableFormForNoCoverage" radioAsCheckbox [value]="true"
          [(ngModel)]="currentSpouse.tobaccoUseInd">
          <b>Yes,</b> I am subject to the $25 premium surcharge. This dependent has used tobacco products in the past
          two months.
        </radio>
      </div>
      <div class="k-form-field bigger" style="margin-bottom: 20px">
        <radio [name]="'tobaccoNo' + idx" [disabled]="currentSpouse.surchargeNA || disableFormForNoCoverage" radioAsCheckbox [value]="false"
          [(ngModel)]="currentSpouse.tobaccoUseInd">
          <b>No,</b> I am not subject to the $25 premium surcharge. This dependent has not used tobacco products in the
          past two months, or has enrolled in or accessed one of the tobacco cessation
          resources noted.
        </radio>
      </div>
    </div>
  </div>

  <div *ngIf="currentSpouse.relationshipTypeName === 'Spouse/state-registered domestic partner'" class="form-row"
    fxFlexFill style="margin-bottom: 20px">
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between">
      <span class="selfPay-form-small-header">Spouse or state-registered domestic partner (SRDP) coverage premium
        surcharge </span>
      <span style="padding-bottom: 10px">
        Response required if you are enrolling your spouse or SRDP in medical coverage. The {{agency}} Program requires
        a $50 premium surcharge in addition to your monthly medical premium if you are
        enrolling your spouse or SRDP in {{agency}} medical and they have chosen not to enroll in another Employer based
        - Group medical that is comparable to {{agency}}'s Uniform Medical Plan Classic.
        <br />
        <br />
        <div fxLayout="column">
          <ol class="hca-ordered-list">
            <li><yes-no name="spouseAttestQ1"
                label="Are you covering your spouse or SRDP in a PEBB medical plan under your account in 2024?"
                [stretch]="false" style="width: auto;" orientation="horizontal-list"
                [disabled]="disableFormForNoCoverage"
                [(ngModel)]="currentSpouse.spouseAttestQ1">
              </yes-no></li>
            <li><yes-no name="spouseAttestQ2"
                label="Will they be eligible for medical coverage through their employer in 2024? (If they will not be employed in 2024, answer No.)"
                [stretch]="false" style="width: auto;" orientation="horizontal-list"
                [disabled]="disableFormForNoCoverage"
                [(ngModel)]="currentSpouse.spouseAttestQ2">
              </yes-no></li>
            <li><yes-no name="spouseAttestQ3"
                label="Will their employer offer at least one medical plan that serves their county of residence in 2024?"
                [stretch]="false" style="width: auto;" orientation="horizontal-list"
                [disabled]="disableFormForNoCoverage"
                [(ngModel)]="currentSpouse.spouseAttestQ3">
              </yes-no></li>
            <li><yes-no name="spouseAttestQ4"
                label="Have they chosen not to enroll in their employer's medical (including SEBB) coverage in 2024?"
                [stretch]="false" style="width: auto;" orientation="horizontal-list"
                [disabled]="disableFormForNoCoverage"
                [(ngModel)]="currentSpouse.spouseAttestQ4">
              </yes-no></li>
            <li><yes-no name="spouseAttestQ5" label="Will the coverage offered by their employer in 2024 not be through the PEBB Program or a TRICARE plan?
            Answer Yes if their employer does not offer PEBB medical coverage or a TRICARE plan. Answer No if their
            employer Ooffers PEBB medical coverage or a TRICARE plan." [stretch]="false" style="width: auto;"
              [disabled]="disableFormForNoCoverage"
                orientation="horizontal-list" [(ngModel)]="currentSpouse.spouseAttestQ5">
              </yes-no></li>
            <li><yes-no name="spouseAttestQ6"
                label="Will their share of the medical premium through their employer be less than $117.81 per month in 2024?"
                [stretch]="false" style="width: auto;" orientation="horizontal-list"
                [disabled]="disableFormForNoCoverage"
                [(ngModel)]="currentSpouse.spouseAttestQ6">
              </yes-no></li>
          </ol>
        </div>
        If you answered No to any of these questions, check no below. You will not be charged the surcharge.
        If you answered Yes to all of these questions:
        <br />
        <br />
        <ol>
          <li>Ask your spouse or SRDP for the Summary of Benefits and Coverage (SBC) for all medical plans that:
            <ol type="a">
              <li>Serve their county of residence.</li>
              <li>Have a monthly premium of less than $117.81 per month for the employee.</li>
            </ol>

          </li>
          <li>Use the SBC information to answer the questions in the {{agency}} Spousal Plan Calculator online tool. You
            will get a Yes or No response from the calculator. Enter your response below.</li>
        </ol>
        <br />
        <b>Does the spouse or SRDP coverage premium surcharge apply to you?</b> Check one:
      </span>
      <div fxlayout="row" fxFlex="100" fxLayoutAlign="space-between">
        <radio [name]="'spousalYes' + idx" radioAsCheckbox [disabled]="currentSpouse.surchargeNA || disableFormForNoCoverage" value="Y"
          [(ngModel)]="currentSpouse.spouseSurchargeTriState">
          <b>Yes,</b> I am subject to the $50 premium surcharge. I completed the 2024
          <a [href]="agency === 'SEBB' && isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/spousal-plan-calculator' : 'https://www.hca.wa.gov/employee-retiree-benefits/spousal-plan-calculator'"
            target="_blank">{{agency}} Spousal Plan Calculator</a> online.
        </radio>
        <span class="selfPay-form-small-header" fxFlex="40">
          <fa-icon class="hca-fontawesome-icon" [icon]="icons.faExclamationCircle"></fa-icon>
          If you check <b>Yes</b> or do not check any boxes below, you will be charged the $50 spouse or SRDP coverage
          premium surcharge.
        </span>
      </div>
      <radio [name]="'spousalNo' + idx" radioAsCheckbox [disabled]="currentSpouse.surchargeNA || disableFormForNoCoverage" value="N"
        [(ngModel)]="currentSpouse.spouseSurchargeTriState">
        <b>No,</b> I am not subject to the $50 premium surcharge. I completed the {{agency}} Spousal Plan Calculator
        online.
      </radio>
      <radio [name]="'spousalHelp' + idx" radioAsCheckbox [disabled]="currentSpouse.surchargeNA || disableFormForNoCoverage" value="H"
        [(ngModel)]="currentSpouse.spouseSurchargeTriState">
        The {{agency}} Program to help determine if premium surcharge applies. I am completing and submitting a printed
        {{agency}} Spousal Plan
        Calculator.
      </radio>
    </div>
  </div>

  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between" style="margin-bottom: 10px;">
    <button fxFlex="30" (click)="cancelChanges()" class="btn btn-light">Cancel</button>
    <button fxFlex="30" (click)="emitSubmitDependent(formContainer)" class="btn btn-primary">Save and Continue</button>
  </div>
</form>