<div class="grid-container">
  <form  #accessForm="ngForm" #formContainer>
    <kendo-grid #accessGrid [data]="clonedGridData" scrollable="none">
      <kendo-grid-column
        *ngFor="let col of gridColumns"
        title="{{col.title}}"
        [headerStyle]="{ 'font-weight': 'bold' }"
        width="{{col.width}}"
      > 
        <ng-template kendoGridFilterMenuTemplate
          let-column="col"
          let-filter="filter"
          let-filterService="filterService"
          *ngIf="col.field.endsWith('Date')"
          >
          <date-picker
            name="dateFilter"
            [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
            (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
            [label]="col.title">
          </date-picker>
        </ng-template>
        <ng-template kendoGridCellTemplate *ngIf="col.format === 'select'" let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div fxFill class="form-group required" fxFlex.lt-md="95">
            <select [disabled]="!canEdit || (dataItem.userAccessLevelId === adminAccessLevelId && !isHCAAdmin)"  required class="form-control" [name]="'role' + rowIndex" [(ngModel)]="dataItem[col.field]">
              <option [disabled]="!canEdit || (role.userAccessLevelId === adminAccessLevelId && !isHCAAdmin)" *ngFor="let role of roles" [ngValue]="role.userAccessLevelId">{{ role.userAccessLevelName }}</option>
            </select>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate *ngIf="col.format === 'string'" let-dataItem="dataItem" let-rowIndex="rowIndex">
          <input
          [type]="col.field === 'emailAddress' ? 'email' : 'text'" 
          [pattern]="col.field === 'emailAddress' ? '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$' : null"
          class="form-control" 
          required  
          [name]="col.field + rowIndex"
          [(ngModel)]="dataItem[col.field]"
          />
        </ng-template>
        <ng-template kendoGridCellTemplate  *ngIf="col.format === 'date'" let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div fxFill *ngIf="col.field === 'effectiveStartDate'" class="form-group">
            <date-picker
                #effectiveStartDate="ngModel"
                [disabled]="dataItem.effectiveEndDate != null || !canEdit || (dataItem.userAccessLevelId === adminAccessLevelId && !isHCAAdmin)"
                [name]="'effectiveStartDate' + rowIndex"
                required
                [(ngModel)]="dataItem[col.field]"></date-picker>
          </div>
          <div fxFill *ngIf="col.field === 'effectiveEndDate'" class="form-group">
            <date-picker
                #effectiveEndDate="ngModel"
                [name]="'effectiveEndDate' + rowIndex"
                [min]="dataItem.effectiveStartDate"
                [disabled]="!canEdit || !dataItem.effectiveStartDate || (dataItem.userAccessLevelId === adminAccessLevelId && !isHCAAdmin)"
                [(ngModel)]="dataItem[col.field]"></date-picker>
          </div>        
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column *ngIf="canEdit || isPerspayEdit" title="Manage" width="150">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <button [disabled]="!canEdit || (dataItem.userAccessLevelId === adminAccessLevelId && !isHCAAdmin)" class="btn btn-primary" (click)="saveHandler(dataItem, rowIndex, formContainer)" type="button">Save</button>
            <button *ngIf="dataItem.systemUserId && dataItem.isClaimed" [disabled]="!isPerspayAdmin && !isHCAAdmin && !isPerspayEdit" class="btn btn-secondary" (click)="disassociate.emit(dataItem)" type="button">Release account</button>
            <button *ngIf="isInactiveGrid && dataItem.systemUserId && !dataItem.isClaimed" [disabled]="!canEdit || (dataItem.userAccessLevelId === adminAccessLevelId && !isHCAAdmin) || (!isPerspayAdmin && !isHCAAdmin)" class="btn btn-secondary" (click)="remove.emit(dataItem)" type="button">Remove user</button>
          </ng-template>
      </kendo-grid-command-column>    
    </kendo-grid>
  </form>
</div>