import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import TermsOfUse from '../models/termsOfUse';
import TermsOfUseAction from '../models/termsOfUseAction';

@Injectable({
  providedIn: 'root'
})
export class TOUService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getTermsOfUse(): Observable<TermsOfUse[]> {
    return this.http
      .get<TermsOfUse>(`${env.apiUrl}/termsofuse`)
      .pipe(map(x => this.commonService.createObjects(TermsOfUse, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createTermsOfUse(termsOfUse: TermsOfUse): Observable<TermsOfUse> {
    return this.http
      .post<TermsOfUse>(`${env.apiUrl}/termsofuse`, termsOfUse)
      .pipe(map(x => this.commonService.createObject(TermsOfUse, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateTermsOfUse(termsOfUse: TermsOfUse): Observable<TermsOfUse> {
    return this.http
      .put<TermsOfUse>(`${env.apiUrl}/termsofuse/${termsOfUse.termsOfUseId}`, termsOfUse)
      .pipe(map(x => this.commonService.createObject(TermsOfUse, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // terms of use actions

  createTermsOfUseAction(touAction: TermsOfUseAction, systemUserId: string): Observable<TermsOfUseAction> {
    return this.http
    .post<TermsOfUseAction>(`${env.apiUrl}/systemuser/${systemUserId}/termsofuse`, touAction)
    .pipe(map(x => this.commonService.createObject(TermsOfUseAction, x)))
    .pipe(catchError(this.commonService.handleError));
  }

}
