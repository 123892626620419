// This interceptor disables caching to handle a bug with IE cacheing API calls.

import { HttpHandler, HttpProgressEvent, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpUserEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const nextReq = req.clone({
      headers: req.headers.set('Cache-Control', 'no-store, no-cache, must-revalidate'),
    });
    return next.handle(nextReq);
  }
}
