import { some } from 'lodash';
import { DataStateChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import Subscriber from 'src/app/models/subscriber';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Milestone from 'src/app/models/milestone';

@Component({
  selector: 'soe-grid',
  templateUrl: 'soe.grid.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SOEGridComponent implements OnInit, OnChanges {
  @Output() soeSelected: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Output() cancelSOE: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Input() SOEs;
  @Input() subscriber: Subscriber;
  @Input() isReadOnly = false;
  @Input() isHCAEdit = false;
  @Output() editSOE: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Output() downloadCoveragePDF: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Output() downloadSOEPDF: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Output() clearSelectedSOE: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  gridData: any;
  state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  selectableSettings = {
    checkboxOnly: true,
    mode: 'single',
  };
  gridColumns: { [k: string]: string | {} }[] = [
    {field: 'specialOpenEnrollmentType.specialOpenEnrollmentTypeName', title: 'Event', format: 'string'},
    {field: 'eventDate', title: 'Event Date', format: { date: 'mm/dd/yyyy' }, filter: 'date'},
    {field: 'specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName', title: 'Status', format: 'string'},
    {field: 'expirationDate', title: 'Review Deadline', format: { date: 'mm/dd/yyyy' }, filter: 'date'},
    {field: 'simplifiedStatus', title: 'Verification Reason', format: 'string'},
  ];
  selectedSOE: SpecialOpenEnrollment;

  ngOnInit(): void {
    // because process returns a wrapper type specific to the grid
    this.gridData = this.SOEs;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.gridData = this.SOEs;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.SOEs, state);
  }

  setSOESelected(e: SelectionEvent): void {
    if(e.selectedRows.length && e.selectedRows[0]?.dataItem) {
      this.selectedSOE = e.selectedRows[0]?.dataItem;
    }else {
      this.selectedSOE = null;
    }
    this.clearSelectedSOE.emit();
  }

  hasCompleteEP(soe: SpecialOpenEnrollment): boolean {
    return some(this.subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) => ep.enrollmentPeriodId === soe.specialOpenEnrollmentId && some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && mi.isComplete));
  }

  cancelSOEItem(dataItem) {
    this.selectedSOE = null;
    this.cancelSOE.emit(dataItem);
  }


}
