<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid" *ngIf="currentOrganization" #formContainer>
  <form #form="ngForm">
    <div class="state">
      <div class="card" *ngIf="systemUser">
        <div class="card-body">
          <h2 class="mobile-text-centered mgbt-x-small mgtp-small">Organization profile</h2>
          <h3 class="h5 mgbt-medium mgtp-x-small">
            You can use this section to update organization profile information to include contacts. Please keep contact information current to ensure HCA can 
            reach appropriate personnel to disseminate information and resolve any issues.  
          </h3>
          <div class="form-group required mgbt-medium-lg" style="max-width: 350px">
            <label for="organizationTypeId">Organization type</label>
            <select required 
              #organizationTypeId="ngModel"
              class="form-control" 
              name="organizationTypeId"
              id="organizationTypeId"
              [(ngModel)]="currentOrganization.organizationTypeId"
              [disabled]="currentOrganization.organizationId && !isHCASystemAdmin"
              (ngModelChange)="orgTypeChange($event)"
            >
              <option></option>
              <option *ngFor="let organizationType of lookups.organizationTypes" [ngValue]="organizationType.organizationTypeId">{{ organizationType.organizationTypeName }}</option>
            </select>
          </div>
          <div *ngIf="currentOrganization?.organizationTypeId">
            <organization-details 
              [isReadOnly]="isReadOnly" 
              [addingNew]="addingNewOrganization" 
              #organizationDetailsComponent 
              [lookups]="lookups" 
              [isHCAAdmin]="isHCAAdmin"
              [isHCASystemAdmin]="isHCASystemAdmin" 
              [organization]="currentOrganization" 
              [sebbOrgTypeId]="sebbOrgTypeId"
              [sebbOrgEGTypeId]="sebbOrgEGTypeId"
              [isOrgTypeToDisableEditFor]="isOrgTypeToDisableEditFor">
            </organization-details>
            <div class="container-fluid mgtp-small">
              <address
                #address
                *ngIf="physicalAddress"
                name="organizationAddress"
                [labelPrefix]="physicalAddress.addressType.addressTypeName"
                [disabled]="!isHCAAdmin"
                [(ngModel)]="physicalAddress"
              ></address>
              <div class="k-form-field mgtp-x-small mgbt-medium-sm" style="font-weight: bold;">
                <checkbox name="addressSameAsPhysicalInd" 
                  [disabled]="!hasEditAccess" 
                  [(ngModel)]="currentOrganization.addressIsSameAsPhysicalInd"
                  (ngModelChange)="addressIsSameAsPhysicalIndChange()">
                    Same mailing/shipping/billing address
                  </checkbox>
              </div>

              <div style="padding-top: 4px;" *ngIf="!currentOrganization.addressIsSameAsPhysicalInd">
                <ngb-accordion [destroyOnHide]="false" *ngIf="!currentOrganization.addressIsSameAsPhysicalInd" [activeIds]="'panel_' + env.mailingAddressTypeCode + ',panel_' + env.billingAddressTypeCode + ',panel_' + env.shippingAddressTypeCode">
                  <ngb-panel *ngFor="let extraAddress of extraAddresses; let index = index" [id]="'panel_' + extraAddress.addressType.addressTypeCode">
                      <ng-template ngbPanelHeader  let-opened="opened">
                          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                            <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                            <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                            {{extraAddress.addressType.addressTypeName}} address - {{extraAddress.addressType.addressTypeDescription}}
                            <span class="float-right">
                                <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" *ngIf="extraAddress.addressType.addressTypeCode === env.residentialAddressTypeCode" [icon]="icons.faHome" size="2x"></fa-icon>
                                <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" *ngIf="extraAddress.addressType.addressTypeCode === env.mailingAddressTypeCode" [icon]="icons.faEnvelope" size="2x"></fa-icon>
                                <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" *ngIf="extraAddress.addressType.addressTypeCode === env.billingAddressTypeCode" [icon]="icons.faFileInvoiceDollar" size="2x"></fa-icon>    
                            </span>
                          </button>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div fxLayout="column" class="fxGap-medium">
                            <checkbox 
                              *ngIf="extraAddress.addressType.addressTypeCode === env.mailingAddressTypeCode"
                              [name]="'SameAsPhysicalAddress_' + extraAddress.addressType.addressTypeName"
                              label="Same as physical address"
                              [(ngModel)]="currentOrganization.mailingIsSameAsPhysicalInd"
                              (ngModelChange)="addressSameAsPhsyicalChange(index)">
                            </checkbox>
                            <checkbox 
                              *ngIf="extraAddress.addressType.addressTypeCode === env.billingAddressTypeCode"
                              [name]="'SameAsPhysicalAddress_' + extraAddress.addressType.addressTypeName"
                              label="Same as physical address"
                              [(ngModel)]="currentOrganization.billingIsSameAsPhysicalInd"
                              (ngModelChange)="addressSameAsPhsyicalChange(index)">
                            </checkbox>
                            <checkbox 
                              *ngIf="extraAddress.addressType.addressTypeCode === env.shippingAddressTypeCode"
                              [name]="'SameAsPhysicalAddress_' + extraAddress.addressType.addressTypeName"
                              label="Same as physical address"
                              [(ngModel)]="currentOrganization.shippingIsSameAsPhysicalInd"
                              (ngModelChange)="addressSameAsPhsyicalChange(index)">
                            </checkbox>
                            <div *ngIf="(extraAddress.addressType.addressTypeCode === env.mailingAddressTypeCode && currentOrganization.mailingIsSameAsPhysicalInd === false) || 
                              (extraAddress.addressType.addressTypeCode === env.billingAddressTypeCode && currentOrganization.billingIsSameAsPhysicalInd === false) || 
                              (extraAddress.addressType.addressTypeCode === env.shippingAddressTypeCode && currentOrganization.shippingIsSameAsPhysicalInd === false)">
                              <div fxFlex="0" fxFlex.gt.sm="10"></div>
                              <div fxFlexLayout="column" fxFlex="100" fxFlex.gt-sm="75" fxFlexLayoutAlign="center center">
                                <address
                                  #otherAddress
                                  *ngIf="extraAddress"
                                  [name]="'organizationAddress-' + index"
                                  [labelPrefix]="extraAddress.addressType.addressTypeName"
                                  [disabled]="!hasEditAccess"
                                  [(ngModel)]="extraAddresses[index]"
                                ></address>
                              </div>
                              <div fxFlex="0" fxFlex.gt.sm="10"></div>
                            </div>
                        </div>
                      </ng-template>
                    </ngb-panel>
                  </ngb-accordion>
              </div>
            </div>
            <div class="mgtp-medium mgbt-medium">
              <label for="organizationNotes">Notes:</label>
              <div fxFlexFill>
                  <textarea [disabled]="!coreService.systemUserHasAccess('edit', 'HCA')" class="form-control"
                      name="organizationNotes" [(ngModel)]="currentOrganization.notes" fxFlexFill></textarea>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between">
              <h3 class="h4" style="padding-bottom: 5px;">Contacts</h3>
              <button type="button" *ngIf="coreService.systemUserHasAccess('edit')" style="height: 50px;" class="btn btn-primary" (click)="addContact()"><fa-icon [icon]="icons.faPlusCircle" size="1x"></fa-icon> Add contact </button>
            </div>
            <div *ngFor="let ct of currentOrganization.organizationContacts">
              <organization-contact [isHCAAdmin]="coreService.systemUserHasAccess('edit')" (removeContact)="onRemoveContact($event)" [contactTypes]="lookups.contactTypes" [agencyPhysicalAddress]="physicalAddress" #organizationContactsComponent [contact]="ct"></organization-contact>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="!coreService.systemUserHasAccess('edit')" fxLayout="row" class="mgtp-medium mgbt-medium" fxLayoutAlign="space-around" 
      *ngIf="currentOrganization?.organizationTypeId">
      <button type="button" class="btn btn-primary" style="margin-right:20px;" (click)="saveOrganizationInfo(formContainer)">
        Submit changes
      </button>
      <button class="btn" (click)="clearChanges()">
          <span class="k-icon k-i-reset"></span> 
        Clear changes
      </button>
    </div>
  </form>
</div>
