// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import AccountCorrectionsAccount from 'src/app/models/accountCorrectionsAccount'
@Injectable({
  providedIn: 'root'
})

export class AccountCorrectionsService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getAccount(subscriberMemberId: string): Observable<AccountCorrectionsAccount> {
    return this.http
      .get<AccountCorrectionsAccount>(`${env.apiUrl}/accountCorrections/${subscriberMemberId}`)
      .pipe(map(x => this.commonService.createObject(AccountCorrectionsAccount, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  save(subscriberMemberId: string, data: any): Observable<void> {
    return this.http
      .post(`${env.apiUrl}/accountCorrections/${subscriberMemberId}`, data)
      .pipe(catchError(this.handleACError));
  }

  public handleACError(error: any, sentObj?: any): Observable<any> {
    return throwError(error);
  }
}
