import { filter } from 'lodash';
// ng
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// ext
import { sortBy, some } from 'lodash';
import * as dayjs from 'dayjs';
// local
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
import SystemUser from 'src/app/models/user';
import Subscriber from 'src/app/models/subscriber';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { CoreService } from 'src/app/services/core.service';
import { AuthService } from 'src/app/services/auth.service';
import Milestone from 'src/app/models/milestone';

@Component({
  selector: 'reattest-banner',
  templateUrl: 'reattest.banner.component.html',
  styleUrls: [],
  providers: [],
})
export class ReattestBannerComponent implements OnInit {
  @Input() subscriber: Subscriber;
  router: Router;
  authService: AuthService;
  systemUser: SystemUser;
  newlyIncomplete = false;
  selectedPeriod: EnrollmentPeriod;
  nextYear: Date;
  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
    this.nextYear = dayjs().add(1, 'year').toDate();
  }

  getSortedFilteredEnrollmentPeriods(s: Subscriber): EnrollmentPeriod[] {
    return sortBy(
      filter(s.enrollmentPeriods, (ep: EnrollmentPeriod) => ep.isCurrentlyActive),
      (ep: EnrollmentPeriod) => ep.coverageEffectiveStartDate
    );
  }


}
