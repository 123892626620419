import { DependentSpousalComponent } from './components/spousal/dep.spousal.component';
import { RelationshipService } from 'src/app/services/relationship.service';
import { RelationshipCertificationService } from 'src/app/services/relationshipCertification.service';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { lastValueFrom, Observable } from 'rxjs';
import { ComponentCanDeactivate } from './../../../../guards/pendingChanges/pendingChanges.guard';
import AttestationType from 'src/app/models/attestationType';
import Attestation from 'src/app/models/attestation';
import { AttestationService } from './../../../../services/attestation.service';
import DependentCoverageElection from 'src/app/models/dependentCoverageElection';
import { SubscriberService } from './../../../../services/subscriber.service';
import Milestone from 'src/app/models/milestone';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Reason from 'src/app/models/reason';
import Subscriber from 'src/app/models/subscriber';
import DependentComposite from 'src/app/models/dependentComposite';
import { ActivatedRoute, Router } from '@angular/router';
// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, Output, HostListener } from '@angular/core';

// ext
import { faUsers, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { findIndex, find, isEqual, sortBy, filter, pickBy, keys, cloneDeep, maxBy, forEach, map, get, some } from 'lodash';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
// local
import { env } from 'src/env/development';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
import { DependentService } from 'src/app/services/dependent.service';

import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import Relationship from 'src/app/models/relationship';
import RelationshipCertification from 'src/app/models/relationshipCertification';
import Question from 'src/app/models/question';
import Member from 'src/app/models/member';
import PlanType from 'src/app/models/planType';
import SOEFormDependent from 'src/app/models/soeFormDependent';
import RelationshipType from 'src/app/models/relationshipType';
import SelfPay from 'src/app/models/selfPay';
import MemberMedicare from 'src/app/models/memberMedicare';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import MedicareOption from 'src/app/models/medicareOption';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.GenderIdentity, LookupType.RelationshipQualifyReason, LookupType.RelationshipVerificationStatus,
         LookupType.RelationshipCertificationStatus, LookupType.MemberType, LookupType.RelationshipType, LookupType.BirthSex,
         LookupType.County, LookupType.Country, LookupType.AddressType, LookupType.PhoneNumberType, LookupType.PreferredContactMethod,
         LookupType.Language, LookupType.Reason, LookupType.PlanType, LookupType.Response, LookupType.Question, LookupType.AttestationType,
         LookupType.CertificationType, LookupType.MedicareOption)
@Component({
  selector: 'dependents',
  templateUrl: 'dependents.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentsComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  @ViewChild('acc') acc: NgbAccordion;
  @ViewChild('spousal') spousalComponent: DependentSpousalComponent;
  dependent: DependentComposite;
  hasDependentsToAdd: boolean;
  subscriber: Subscriber;
  enrollmentPeriod: EnrollmentPeriod;
  dependentMilestone: Milestone;
  selectedPlanIds: string[] = [];
  changesHaveBeenSaved = false;
  stepsToHideButtonsOn: string[] = ['1'];
  step = 0;
  nextStep = 0;
  icons = {
    faUsers,
    faMinus,
    faPlus,
  };
  hasMed = true;
  isSpouse = true;
  underThirteen = false;
  maxIdxCompleted = 0;
  subscriberCurrentlyEnrolledInMedical = false;
  lastStep = -1;
  dependentWizardSections = [
    {
      name: 'Demographics',
      idx: 0,
    },
    {
      name: 'Enrollments',
      idx: 1,
    },
    {
      name: 'Tobacco attestations',
      idx: 2,
    },
    {
      name: 'Spouse/State-registered partner attestation',
      idx: 3,
    },
    {
      name: 'Dependent review',
      idx: 4,
    },
    {
      name: 'Auto verify dependent',
      idx: 5,
      visible: this.showAdminVerification.bind(this)
    }
  ];
  lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    phoneNumberTypes: [],
    preferredContactMethods: [],
    languages: [],
    organizations: [],
    eligibilityReasons: [],
    terminationReasons: [],
    planTypes: [],
    responses: [],
    questions: [],
    attestationTypes: [],
    relationshipCertificationStatus: [],
    certificationTypes: [],
    medicareOptions: [],
    removalReasons: [],
    allReasons: []
  };
  relationshipVerifyValues: RelationshipVerificationStatus[] = [];
  relationshipDenyValues: RelationshipVerificationStatus[] = [];
  autoVerifyDependent = false;
  electablePlanTypes: PlanType[];
  effectiveYear: string;
  thirteenDate: Date;
  subMedicareABEnrolled = false;
  dependents: DependentComposite[];
  initialDependents: DependentComposite[];
  tobaccoAttestationType: AttestationType;
  spousalAttestationType: AttestationType;
  addAnother = false;
  initialDependent: DependentComposite;
  subscriberIsNewlyFull = false;
  neFullCode: string;
  isSOE = false;
  isSelfPay = false;
  currentSOE: SpecialOpenEnrollment;
  currentSP: SelfPay;
  spousalRelationshipTypeId: string;
  divorceWithin60Days: false;
  proceedLabelOverride: string = null;
  isHcaAdmin = false;
  isHcaEdit = false;
  inHcaAdminState = false;
  isDeathOrDivorceSoe = false;
  dependentLosesEligibilityReasonId: string;
  deathOrDivorceSoeSelection: DependentComposite[] = [];
  saveDependentSubmitted = false;
  autoVerifyActive = false;
  visionAvailable = false;

  constructor(
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private enrollmentPeriodService: EnrollmentPeriodService,
    private dependentService: DependentService,
    private route: ActivatedRoute,
    private router: Router,
    private attestationService: AttestationService,
    private subscriberService: SubscriberService,
    private relationshipService: RelationshipService,
    private relationshipCertificationService: RelationshipCertificationService,
  ) {}

  ngOnInit(): void {
    this.isHcaAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
    this.isHcaEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);

    // for subscribers tranferring from med only to full benefits
    this.neFullCode = env.newFullBenefitsEnrollmentPeriodType;
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.inHcaAdminState = data.userType === 'hca';
      this.subscriber = data.subscriber;
      this.enrollmentPeriod = data.enrollmentPeriod;
      this.subMedicareABEnrolled = this.subscriber?.memberMedicare?.medicarePartAEnrolledInd && this.subscriber?.memberMedicare?.medicarePartBEnrolledInd;
      this.subscriberIsNewlyFull = this.enrollmentPeriod.enrollmentPeriodType?.enrollmentPeriodTypeCode === this.neFullCode;
      this.electablePlanTypes = filter(data.enrollmentPeriod.electablePlanTypes, (p: PlanType) => some(env.planTypesToDisplay, (pt: string) => p.planTypeCode === pt));
      this.dependentMilestone = find(this.enrollmentPeriod.milestones, (mi: Milestone) => mi.milestoneName === 'Dependents');
      this.lookups.county = sortBy(data.lookups.county, 'countyName');
      this.lookups.responses = data.lookups.response;
      this.lookups.addressType = data.lookups.addressType;
      this.lookups.country = sortBy(data.lookups.country, 'countryName');
      this.lookups.certificationTypes = sortBy(data.lookups.certificationType, 'certificationTypeName');
      this.lookups.relationshipCertificationStatus = sortBy(data.lookups.relationshipCertificationStatus, 'relationshipCertificationStatusName');
      this.lookups.birthSexes = sortBy(data.lookups.birthSex, 'birthSexName');
      this.lookups.organizations = sortBy(data.lookups.organization, 'memberTypeName');
      this.lookups.genderIdentities = sortBy(data.lookups.genderIdentity, 'genderIdentityName');
      this.lookups.preferredContactMethods = sortBy(data.lookups.preferredContactMethod, 'preferredContactMethodName');
      this.lookups.languages = sortBy(data.lookups.language, 'languageName');
      this.lookups.relationshipQualifyReasons = sortBy(data.lookups.relationshipQualifyReason, 'relationshipQualifyReasonName');
      this.lookups.relationshipTypes = sortBy(data.lookups.relationshipType, 'relationshipTypeName');
      this.lookups.memberType = sortBy(data.lookups.memberType, 'memberTypeName');
      this.lookups.allReasons = data.lookups.reason;
      this.lookups.terminationReasons = sortBy(
        data.lookups.reason.filter((r: Reason) => r.terminationReasonInd && r.dependentInd),
        'reasonName'
      );
      this.lookups.removalReasons = sortBy(
        data.lookups.reason.filter((r: Reason) => r.reasonCode === '40' || r.reasonCode === '41'),
        'reasonName'
      );
      this.dependentLosesEligibilityReasonId = find(data.lookups.reason, (r: Reason) => r.reasonCode === '41').reasonId;
      this.lookups.phoneNumberTypes = sortBy(
        filter(data.lookups.phoneNumberTypes, (p) => p.phoneNumberTypeName !== 'Cell'),
        'phoneNumberTypeName'
      );
      this.lookups.attestationTypes = data.lookups.attestationType;
      this.lookups.relationshipVerificationStatus = sortBy(data.lookups.relationshipVerificationStatus, 'relationshipVerificationStatusName');
      this.lookups.relationshipTypes = sortBy(
        filter(data.lookups.relationshipType, (r) => r.relationshipTypeName !== 'SubscriberFor'),
        'relationshipTypeName'
      );
      this.lookups.planTypes = filter(data.lookups.planType, (p) => p.planTypeCode === '1' || p.planTypeCode === '2' || p.planTypeCode === '6');
      this.lookups.questions = this.mapQuestionsForYear(filter(data.lookups.question, ['questionName', 'Spouse or Domestic Partner']));

      this.lookups.questions.forEach(q => {
        q.question = q.question
          .replace(new RegExp((this.subscriber.isSebb ? env.pebbName : env.sebbName), 'g'), (this.subscriber.isSebb ? env.sebbName : env.pebbName))
          .replace(new RegExp((this.subscriber.isSebb ? env.pebbCode : env.sebbCode), 'g'), (this.subscriber.isSebb ? env.sebbCode : env.pebbCode));
      });
      this.lookups.medicareOptions = data.lookups.medicareOption;

      this.relationshipVerifyValues = filter(this.lookups.relationshipVerificationStatus, (rvs) => rvs.approvedInd);
      this.relationshipDenyValues = filter(this.lookups.relationshipVerificationStatus, (rvs) => rvs.deniedInd);

      this.effectiveYear = dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).format('YYYY');
      this.thirteenDate = dayjs().subtract(13, 'year').toDate();

      this.dependents = map(
        filter(this.subscriber.members, (m: Member) => !m.isSubscriberInd),
        (m: Member) => new DependentComposite(m, this.enrollmentPeriod)
      );

      this.initialDependents = cloneDeep(this.dependents);

      this.tobaccoAttestationType = find(this.lookups.attestationTypes, (at: AttestationType) => at.attestationTypeCode === 'TS');
      this.spousalAttestationType = find(this.lookups.attestationTypes, (at: AttestationType) => at.attestationTypeCode === 'SS');
      // check if this EP is an SOE
      if (this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SOE') {
        this.isSOE = true;
      }
      if (this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SPE') {
        this.isSelfPay = true;
      }
      // grab the associated soe from subscriber obj
      if (this.isSOE) {
        this.currentSOE = find(this.subscriber.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => soe.specialOpenEnrollmentId === this.enrollmentPeriod.enrollmentPeriodId);

        this.isDeathOrDivorceSoe = this.currentSOE.specialOpenEnrollmentType.specialOpenEnrollmentTypeName === 'Death or Divorce';
        if (this.isDeathOrDivorceSoe) {
          this.step = this.lastStep = 3;
        }
      }
      if (this.isSelfPay) {
        this.currentSP = find(this.subscriber.selfPays, (sp: SelfPay) => sp.selfPayId === this.enrollmentPeriod.enrollmentPeriodId);
      }

      const visionPlanTypeId = get(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.visionPlanTypeCode), 'planTypeId');
      this.visionAvailable = !!this.electablePlanTypes.filter(pt => pt.planTypeId === visionPlanTypeId).length;
    });
  }

  ngOnDestroy(): void {}

  updateHasDependentsToAdd(e: boolean): void {
    this.hasDependentsToAdd = e;
    this.lastStep = this.hasDependentsToAdd === undefined || this.hasDependentsToAdd || (this.dependents && this.dependents.length) ? 3 : 0;
  }

  paneVisible(pane): boolean {
    if (pane && typeof pane.visible === 'function') {
      return pane.visible();
    }

    return true;
  }

  visiblePanes(dependentWizardSections): any {
    return dependentWizardSections.filter(a => this.paneVisible(a));
  }

  showAdminVerification(): boolean {
    const twelveMonthsAgo = dayjs().subtract(12, 'month').toDate();

    const show = this.isHcaAdmin && this.enrollmentPeriod.effectiveStartDate < twelveMonthsAgo;

    if (show) {
      this.proceedLabelOverride = 'Proceed to auto verify dependent';
    }

    return show;
  }

  cancelSoe(): void {
    this.router.navigate([ '../../' ], { relativeTo: this.route });
  }

  updateStep(s: number): void {
    if (s !== 3 && this.isDeathOrDivorceSoe) {
      this.step = 3;
    } else if (s === 2 && this.step === 3) {
      this.step = 0;
    } else if (this.addAnother || (s === 1 && this.hasDependentsToAdd && (!this.dependent || !this.dependent.memberId))) {
      this.step = 1;
      this.dependent = new DependentComposite(null, this.enrollmentPeriod);

      if (this.isSelfPay) {
        this.dependent.memberMedicare = new MemberMedicare();
      }

      this.initialDependent = cloneDeep(this.dependent);
      this.dependent.subscriberMemberId = this.subscriber.memberId;
      this.dependent.planTypeIds = [];
    } else if (s === 1 && this.hasDependentsToAdd && this.dependent && this.dependent.memberId) {
      this.step = 1;
      return;
      // TODO - figure out toggle single review
    } else if (s === 2 && this.hasDependentsToAdd) {
      this.dependent = cloneDeep(new DependentComposite(null, this.enrollmentPeriod));
      if (this.isSelfPay) {
        this.dependent.memberMedicare = new MemberMedicare();
      }
      this.initialDependent = cloneDeep(this.dependent);
      this.updateStep(1);
      this.maxIdxCompleted = 4;
      // if they say no to more dependents and do have dependents, or they just finished adding one.
    } else if (!this.hasDependentsToAdd && ((this.dependents && this.dependents.length) || this.dependent.birthDate)) {
      try {
        this.spinnerService.show();
        if (!this.isSOE) {
          this.subscriberService.getSubscriberById(this.subscriber.memberId).pipe(untilDestroyed(this)).subscribe((updatedSub: Subscriber) => {
            this.dependents = map(
              filter(updatedSub.members, (m: Member) => !m.isSubscriberInd),
              (m: Member) => new DependentComposite(m, this.enrollmentPeriod)
            );
            this.step = 3;
            this.spinnerService.hide();
          });
        } else {
          this.subscriberService.getSubscriberWithSOE(this.subscriber.memberId, this.currentSOE.specialOpenEnrollmentId).pipe(untilDestroyed(this)).subscribe((updatedSub: Subscriber) => {
            this.dependents = map(
              filter(updatedSub.members, (m: Member) => !m.isSubscriberInd),
              (m: Member) => new DependentComposite(m, this.enrollmentPeriod)
            );
            this.step = 3;
            this.spinnerService.hide();
          });
        }
      } catch (err) {
        this.spinnerService.hide();
        console.log(err);
      }
    } else if (!this.hasDependentsToAdd || s >= 4) {
      this.nextModule();
    } else {
      this.step = s;
    }

    if (this.step == 1) {
      this.autoVerifyActive = false;
    }
  }

  async saveDeathOrDivorceSoeDetails(): Promise<void> {
    const dependentsToModify: Promise<void>[] = [];
    this.deathOrDivorceSoeSelection.forEach((dependent: DependentComposite) => {
      if (dependent.terminationReasonId) {
        dependentsToModify.push(lastValueFrom(
          this.dependentService.removeDependentForDeathOrDivorce(this.subscriber.organization.organizationId, dependent, this.currentSOE.specialOpenEnrollmentId, dependent.terminationReasonId)
        ));
      }
    });

    // await Promise.all(dependentsToModify);
    dependentsToModify.forEach(async p => await p);
    await this.refreshSubscriber();
  }

  async nextModule(): Promise<void> {
    this.spinnerService.show();

    const e = await lastValueFrom(this.enrollmentPeriodService.createMilestoneCompletion(this.subscriber.memberId, this.dependentMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId));

    if ((this.dependents && this.dependents.length) || this.isSOE) {
      if (this.isDeathOrDivorceSoe) {
        await this.saveDeathOrDivorceSoeDetails();
      }

      this.router.navigate([`../../upload/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
    } else {
      if (this.isSelfPay) {
        this.router.navigate([`../../attest/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
      } else {
        this.router.navigate([`../../coverage/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
      }
    }
    this.spinnerService.hide();
  }

  blockNext(block: boolean, step: string): void {
    if (block && !this.stepsToHideButtonsOn.includes(step)) {
      this.stepsToHideButtonsOn.push(step);
    } else if (!block && this.stepsToHideButtonsOn.includes(step)) {
      this.stepsToHideButtonsOn.splice(this.stepsToHideButtonsOn.indexOf(step), 1);
    }
  }

  updateTobaccoAttest(e): void {
    this.dependent.tobaccoResponseId = e;
    this.dependent = cloneDeep(this.dependent);
  }

  updateSpousalAttest(e): void {
    this.dependent.spousalResponseId = e;
    this.dependent = cloneDeep(this.dependent);
  }

  updateSelectedPlans(selectedPlans): void {
    this.selectedPlanIds = keys(pickBy(selectedPlans, (v, k) => v));
    this.dependent.planTypeIds = this.selectedPlanIds;
  }

  enrollmentsChanged(dependent: DependentComposite): boolean {
    const initial = find(this.initialDependents, dep => dep.memberId === dependent.memberId);

    if (!initial) {
      // New dependent, definitely a change
      return true;
    }

    const planTypeIds = JSON.stringify(dependent.planTypeIds);
    const initialPlanTypeIds = JSON.stringify(initial.planTypeIds);

    return planTypeIds !== initialPlanTypeIds;
  }

  async saveDependent(): Promise<void> {
    this.saveDependentSubmitted = true;
    await this.processSaveDependent();
    this.saveDependentSubmitted = false;
  }

  async processSaveDependent(): Promise<void> {
    this.spinnerService.show();
    this.spousalRelationshipTypeId = find(this.lookups.relationshipTypes, (r: RelationshipType) => r.relationshipTypeCode === 'S').relationshipTypeId;
    // update dependent
    if (this.dependent.memberId) {
      const divorceId = get(find(this.lookups.terminationReasons, (r: Reason) => r.reasonName === 'Divorce/Dissolution'), 'reasonId');

      // new up plan change puts
      const dependentCoverageElection = new DependentCoverageElection({});
      dependentCoverageElection.subscriberMemberId = this.dependent.subscriberMemberId;
      dependentCoverageElection.memberId = this.dependent.memberId;
      dependentCoverageElection.planTypeIds = this.dependent.planTypeIds;
      dependentCoverageElection.enrollmentPeriodId = this.enrollmentPeriod.enrollmentPeriodId;
      if (this.dependent.terminationReasonId) {
        dependentCoverageElection.changeReasonId = this.dependent.terminationReasonId;
        dependentCoverageElection.divorceOrDeathDate = this.dependent.terminationDate;

        if (this.dependent.terminationReasonId === divorceId) {
          dependentCoverageElection.divorceWithin60Days = this.divorceWithin60Days;
        }
      }
      // save demo
      try {
        // medicare handled by update demo
        // this introduced a problem b/c of how dependent terms are being handled above vs how updateDep endpoint handles them
        // TODO: rewrite to call actual demographics endpoint only and make sure that handles medicare
        if (this.demographicsUpdated(this.initialDependent, this.dependent)) {
          if (this.isSOE) {
            await lastValueFrom(this.dependentService.updateDemographicsForSOE(this.dependent, this.enrollmentPeriod.enrollmentPeriodId, this.currentSOE?.specialOpenEnrollmentId));
          } else {
            await lastValueFrom(this.dependentService.updateDemographics(this.dependent, this.enrollmentPeriod.enrollmentPeriodId));
          }
        }
        // save attestations
        // tobacco
        if (this.dependent.tobaccoResponseId) {
          if (this.dependent.terminationReasonId) {
            // const attestation = find(this.dependent[`attestations`], att => att.attestationType.attestationTypeCode = 'TS' && att.isActive(this.enrollmentPeriod.coverageEffectiveStartDate));

            // if (attestation) {
            //   attestation.effectiveEndDate = dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).add(-1, 'day').toDate();
            //   attestation.terminationReasonId = this.dependent.terminationReasonId;
            //   if (this.dependent.terminationReasonId === divorceId) {
            //     attestation.divorceWithin60Days = this.divorceWithin60Days;
            //   }

            //   await lastValueFrom(this.attestationService.updateTobaccoAction(attestation, this.subscriber.memberId));
            // }
          } else {
            const attestation = new Attestation();
            attestation.memberId = this.dependent.memberId;
            attestation.effectiveStartDate = this.enrollmentPeriod.effectiveStartDate;
            attestation.attestationTypeId = this.tobaccoAttestationType.attestationTypeId;
            attestation.responseId = this.dependent.tobaccoResponseId;
            attestation.attestationDate = new Date();
            if (this.isSOE) {
              await lastValueFrom(this.attestationService.createTobaccoActionForSOE(
                attestation, this.enrollmentPeriod.enrollmentPeriodId, this.subscriber.memberId, this.currentSOE.specialOpenEnrollmentId));
            } else {
              await lastValueFrom(this.attestationService.createTobaccoAction(attestation, this.enrollmentPeriod.enrollmentPeriodId, this.subscriber.memberId));
            }
          }
        }

        // save enrollments
        if (this.enrollmentsChanged(this.dependent)) {
          if (this.isSOE) {
            await lastValueFrom(this.dependentService.updateEnrollmentsForSOE(dependentCoverageElection, this.enrollmentPeriod.enrollmentPeriodId, this.currentSOE.specialOpenEnrollmentId));
          } else {
            await lastValueFrom(this.dependentService.updateEnrollments(dependentCoverageElection, this.enrollmentPeriod.enrollmentPeriodId));
          }
          const initialDep = find(this.initialDependents, dep => dep.memberId === dependentCoverageElection.memberId);
          if (initialDep) {
            // reset initial dependent's plan Ids, otherwise another change will not be detected
            initialDep.planTypeIds = dependentCoverageElection.planTypeIds;
          }
        }

        // spousal
        if (this.dependent.relationshipTypeId === this.spousalRelationshipTypeId && (this.dependent.spousalResponseId !== this.initialDependent.spousalResponseId || this.initialDependent?.spousal?.effectiveEndDate)) {
          const attestation = new Attestation();
          attestation.memberId = this.dependent.memberId;
          attestation.effectiveStartDate = this.enrollmentPeriod.effectiveStartDate;
          attestation.attestationTypeId = this.spousalAttestationType.attestationTypeId;
          attestation.responseId = this.dependent.spousalResponseId;
          attestation.answers = this.dependent.spousalAnswers;
          attestation.attestationDate = new Date();

          attestation.terminationReasonId = this.dependent.terminationReasonId;
          if (this.dependent.terminationReasonId === divorceId) {
            attestation.divorceWithin60Days = this.divorceWithin60Days;
          }
          if(this.isSOE){
            await lastValueFrom(this.attestationService.createSpousalAction(attestation, this.enrollmentPeriod.enrollmentPeriodId, this.subscriber.memberId, this.currentSOE?.specialOpenEnrollmentId));
          }else {
            await lastValueFrom(this.attestationService.createSpousalAction(attestation, this.enrollmentPeriod.enrollmentPeriodId, this.subscriber.memberId, this.enrollmentPeriod.enrollmentPeriodId));            
          }
        }

        await this.refreshSubscriber();
      } catch (err) {
        console.log(err);
        this.spinnerService.hide();
      }
      this.spinnerService.hide();
      this.coreService.popMessage(`Great! Your dependent has been successfully updated.`, 'success', 8000);
      this.step = 2;
    } else {
      try {
        // api yells
        delete this.dependent.memberId;
        // picks these up because of obj.Assign in constructor - that needs a refactor but not sure what is using it
        delete (this.dependent as any).genderIdentity;
        delete (this.dependent as any).birthSex;
        if (this.dependent.relationshipTypeId !== this.spousalRelationshipTypeId) {
          delete this.dependent.spousalResponseId;
        }
        let dep;
        if (this.isSOE) {
          dep = await lastValueFrom(this.dependentService.createDependentForSOE(this.dependent, this.enrollmentPeriod.enrollmentPeriodId, this.currentSOE?.specialOpenEnrollmentId));
        }else {
          dep = await lastValueFrom(this.dependentService.createDependent(this.dependent, this.enrollmentPeriod.enrollmentPeriodId));
        }
        // hook call for medicare if exists
        if (this.dependent?.memberMedicare?.medicarePartAEnrolledInd || this.dependent?.memberMedicare?.medicarePartBEnrolledInd) {
          this.dependent.memberId = dep.memberId;
          await lastValueFrom(this.dependentService.updateDemographics(this.dependent));
        }

        await this.refreshSubscriber();

        // auto verify
        if (this.autoVerifyDependent) {
          this.autoVerifyDependent = false;
        }

        this.coreService.popMessage('Great! Your dependent has been successfully added.', 'success', 8000);
        this.spinnerService.hide();

        if (this.showAdminVerification()) {
          this.dependent.relationship = find(this.dependents, d => d.memberId === dep.memberId).relationship;
          this.dependent.relationship.relationshipQualifyReason = find(this.lookups.relationshipQualifyReasons,
            rqr => rqr.relationshipQualifyReasonId === this.dependent.relationship?.relationshipQualifyReasonId);

          this.moveToNextSubsection(find(this.dependentWizardSections, s => s.name === 'Auto verify dependent').idx);
          this.autoVerifyActive = true;
        } else {
          this.step = 2;
          this.maxIdxCompleted = 0;
        }
      } catch (err) {
        console.log(err);
        this.spinnerService.hide();
      }
    }
    this.changesHaveBeenSaved = true;
  }

  async refreshSubscriber(): Promise<void> {
    if (!this.isSOE) {
      const sub = await lastValueFrom(this.subscriberService.getSubscriberById(this.subscriber.memberId));
      this.subscriber = cloneDeep(sub);
    } else {
      const sub = await lastValueFrom(this.subscriberService.getSubscriberWithSOE(this.subscriber.memberId, this.currentSOE.specialOpenEnrollmentId));
      this.subscriber = cloneDeep(sub);
    }

    this.coreService.setSubscriber(this.subscriber);
    this.dependents = map(
      filter(this.subscriber.members, (m: Member) => !m.isSubscriberInd),
      (m: Member) => new DependentComposite(m, this.enrollmentPeriod)
    );
  }

  moveToNextSubsection(idx): void {
    const medPlanType = find(this.lookups.planTypes, (pt) => pt.planTypeCode === '1');
    this.hasMed = this.dependent.planTypeIds.includes(medPlanType.planTypeId);
    this.isSpouse = this.dependent.relationshipTypeId === find(this.lookups.relationshipTypes, (rqr) => rqr.relationshipTypeCode === 'S').relationshipTypeId;
    this.underThirteen = dayjs().subtract(13, 'year').isBefore(dayjs(this.dependent.birthDate));
    this.dependent = cloneDeep(this.dependent);
    if (!this.hasMed || (this.hasMed && this.underThirteen) || this.hasMed && this.subMedicareABEnrolled) {
      this.maxIdxCompleted += 3;
    } else {
      this.maxIdxCompleted++;
    }

    if (idx === 3 && !this.showAdminVerification()) {
      this.maxIdxCompleted++;
    }

    this.acc.toggle('hca' + idx);
    if (idx === 5) {
      return;
    }
    setTimeout(() => {
      if (!Array.from(this.acc.panels)[idx + 1]?.disabled) {
        this.acc.toggle('hca' + (idx + 1));
      } else if (!Array.from(this.acc.panels)[idx + 2]?.disabled) {
        this.acc.toggle('hca' + (idx + 2));
        this.maxIdxCompleted++;
      } else if (!Array.from(this.acc.panels)[idx + 3]?.disabled) {
        this.acc.toggle('hca' + (idx + 3));
        this.maxIdxCompleted += 2;
      }
    }, 100);
    // wipe confirm in spousal
    if (this.spousalComponent) {
      this.spousalComponent.displayConfirmation = false;
    }
  }

  additionalQuestionAnswered(e): void {
    this.addAnother = e;
  }

  mapQuestionsForYear(questions: Question[]): Question[] {
    let mappedQuestions = [];
    mappedQuestions = forEach(questions, (q: Question) => {
      q.question = q.question.replace(/{year}/gi, dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).format('YYYY'));
    });
    return mappedQuestions;
  }

  editDependent(d: DependentComposite): void {
    this.dependent = cloneDeep(d);
    // sp member medicare
    if (this.isSelfPay && !this.dependent.memberMedicare) {
      this.dependent.memberMedicare = new MemberMedicare();
    }
    this.initialDependent = cloneDeep(this.dependent);
    // intentionally not using update step
    this.hasDependentsToAdd = true;
    this.updateStep(1);
  }

  panelChange(e: NgbPanelChangeEvent): void {
    return map(
      filter(this.subscriber.members, (m: Member) => !m.isSubscriberInd),
      (m: Member) => new DependentComposite(m, this.enrollmentPeriod)
    );
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.changesHaveBeenSaved || isEqual(this.initialDependent, this.dependent);
  }

  get confirmButtonText(): string {
    if (this.isSOE) {
      if (this.isDeathOrDivorceSoe) {
        return 'Upload documents';
      }

      return `Let's upload eligibility documents for your dependent(s)`;
    }

    if (!this.dependents || !this.dependents.length) {
      if (this.isSelfPay) {
        return 'Proceed to attestations';
      } else {
        return 'Proceed to elect coverage';
      }
    } else {
      return `Let's upload eligibility documents for your dependent(s)`;
    }
  }

  loadSPDependent(dep: SelfPayFormDependent): void {
    this.dependent = new DependentComposite(dep, this.enrollmentPeriod);
    this.dependent.planTypeIds = [];

    // set dependent's properties based on json form values
    const affirmativeResponseId = this.lookups.responses.find(r => r.responseCode === 'Y').responseId;
    const negativeResponseId = this.lookups.responses.find(r => r.responseCode === 'N').responseId;
    if (dep.birthSex && !this.dependent.birthSexId) {
      this.dependent.birthSexId = this.lookups.birthSexes.find(o => o.birthSexCode === dep.birthSex)?.birthSexId;
    }
    if (!this.dependent.memberMedicare) {
      this.dependent.memberMedicare = new MemberMedicare();
    }
    else {
      // medicare options set from the form
      const medicareEnrolledOptionId = find(this.lookups.medicareOptions, (o: MedicareOption) => o.medicareOptionName === 'Enrolled').medicareOptionId;
      if (dep.memberMedicare.medicarePartAEnrolledInd) {
        this.dependent.memberMedicare.medicarePartAMedicareOptionId = medicareEnrolledOptionId;
      }
      if (dep.memberMedicare.medicarePartBEnrolledInd) {
        this.dependent.memberMedicare.medicarePartBMedicareOptionId = medicareEnrolledOptionId;
      }
    }
    if (dep.medicalCoverInd) {
      const medicalPlanTypeId = this.lookups.planTypes.find(o => o.planTypeCode === env.medicalPlanTypeCode)?.planTypeId;
      if (medicalPlanTypeId){ this.dependent.planTypeIds.push(medicalPlanTypeId); }
    }
    if (dep.dentalCoverInd) {
      const dentalPlanTypeId = this.lookups.planTypes.find(o => o.planTypeCode === env.dentalPlanTypeCode)?.planTypeId;
      if (dentalPlanTypeId){ this.dependent.planTypeIds.push(dentalPlanTypeId); }
    }
    if (dep.visionCoverInd) {
      const visionPlanTypeId = this.lookups.planTypes.find(o => o.planTypeCode === env.visionPlanTypeCode)?.planTypeId;
      if (visionPlanTypeId) { this.dependent.planTypeIds.push(visionPlanTypeId); }
    }
    if (dep.surchargeNA !== true && dep.tobaccoUseInd) {
      this.dependent.tobaccoResponseId = affirmativeResponseId;
    } else if (dep.surchargeNA === true || dep.tobaccoUseInd === false){
      this.dependent.tobaccoResponseId = negativeResponseId;
    }
    if (dep.spouseForm?.spouseSurchargeTriState !== null) {
      if (dep.spouseForm.spouseSurchargeTriState === 'Y') {
        this.dependent.spousalResponseId = affirmativeResponseId;
      } else {
        this.dependent.spousalResponseId = negativeResponseId;
      }
    }
    if (dep.spouseForm?.dateOfMarriage !== null) {
      this.dependent.partnershipStartDate = dep.spouseForm?.dateOfMarriage;
    }

    this.dependent.relationshipTypeId = get(
      find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt.relationshipTypeName === this.dependent?.relationshipTypeName),
      'relationshipTypeId'
    );
    if (!this.dependent.relationshipTypeId && dep.relationshipTypeName === 'Child with a disability age 26 or older') {
      this.dependent.relationshipTypeId = get(
        find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt.relationshipTypeCode === 'C'),
        'relationshipTypeId'
      );
    } else if (!this.dependent.relationshipTypeId && dep.relationshipTypeName === 'Stepchild (not legally adopted)') {
      this.dependent.relationshipTypeId = get(
        find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt.relationshipTypeCode === 'P'),
        'relationshipTypeId'
      );
    } else if (!this.dependent.relationshipTypeId && dep.relationshipTypeName === 'Extended dependent (attach a copy of court order)') {
      this.dependent.relationshipTypeId = get(
        find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt.relationshipTypeCode === 'F'),
        'relationshipTypeId'
      );
    }
    this.step = 1;
    this.dependent.subscriberMemberId = this.subscriber.memberId;
  }

  cancelDependentAdd(): void {
    this.step = 0;
  }

  demographicsUpdated(initialDependent: DependentComposite, dependent: DependentComposite): boolean {
    const initialDep = cloneDeep(initialDependent);
    const updatedDep = cloneDeep(dependent);
    delete initialDep.enrollments;
    delete initialDep.attestations;
    delete updatedDep.enrollments;
    delete updatedDep.attestations;
    return !isEqual(initialDep, updatedDep);
  }

  async saveRelationshipVerification(relationshipRec: Relationship): Promise<void> {
    this.spinnerService.show();

    const dependentRelationshipRec = await this.relationshipService.upsertRelationship(relationshipRec);
    this.dependent.relationship = dependentRelationshipRec;

    this.step = 2;
    this.maxIdxCompleted = 0;
    this.autoVerifyActive = false;

    this.spinnerService.hide();
  }

  async saveCertification(certification: RelationshipCertification): Promise<void> {
    this.spinnerService.show();
    const relationship = this.dependent.relationship;

    try {
      const newCertification = await this.relationshipCertificationService.upsertCertification(relationship.subscriberMemberId, certification);

      if (certification.relationshipCertificationId) {
        const certificationIndex = findIndex(
          relationship.relationshipCertifications,
          (c) => c.relationshipCertificationId === certification.relationshipCertificationId
          );

        relationship.relationshipCertifications.splice(certificationIndex, 1, newCertification);
      } else {
        relationship.relationshipCertifications.push(newCertification);
      }

      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      console.log(err);
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong creating the certfications.', 'error', 5000);
    }
  }

  async deleteCertification(certification: RelationshipCertification): Promise<void> {
    const relationship = this.dependent.relationship;
    this.spinnerService.show();
    try {
      await lastValueFrom(
        this.relationshipCertificationService.removeRelationshipCertification(relationship.subscriberMemberId, certification.relationshipId, certification.relationshipCertificationId)
        );
      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong creating the certfications.', 'error', 5000);
    }
  }

  async saveCertificationStatus(relationship: Relationship): Promise<void> {
    this.spinnerService.show();
    // update dependent relationship only
    const dependentRelationshipRec = await this.relationshipService.upsertRelationship(relationship);

    this.dependent.relationship = dependentRelationshipRec;

    this.spinnerService.hide();
  }
}
