import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import SystemUser from 'src/app/models/user';
import Organization from 'src/app/models/organization';

@Component({
  selector: 'organization-management-nav',
  templateUrl: 'organization.nav.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class OrganizationManagementNavComponent implements OnInit {
  @Input() user: SystemUser;
  @Input() organization: Organization;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService
  ) {}

  ngOnInit() {

  }

}
