import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { some } from 'lodash';

@Directive({
  selector: '[mustContainSome]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MustContainSomeDirective, multi: true }],
})
export class MustContainSomeDirective implements Validator {
  @Input() mustContainSome: string[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.mustContainSome ? mustContainSomeValidator(this.mustContainSome)(control) : null;
  }
}

export function mustContainSomeValidator(mustContainSome: string[]): ValidatorFn {
  const validSpecialCharacters = '!@#$%^&*()-_+?/~';
  const validUppers = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const validNumerals = '1234567890';
  const validLowers = 'abcdefghijklmnopqrstuvwxyz';
  return (control: AbstractControl): { [key: string]: any } | null => {
    let validCount = 0;
    if (!mustContainSome || !mustContainSome.length) {
      return null;
    }
    mustContainSome.forEach((requirement) => {
      switch (requirement) {
        case 'special':
          if (some(validSpecialCharacters.split(''), (c) => control.value && control.value.includes(c))) {
            validCount++;
          }
          break;
        case '-':
          if (some(['-'], (c) => control.value && control.value.includes(c))) {
            validCount++;
          }
          break;
        case 'number':
          if (some(validNumerals.split(''), (c) => control.value && control.value.includes(c))) {
            validCount++;
          }
          break;
        case 'upper':
          if (some(validUppers.split(''), (c) => control.value && control.value.includes(c))) {
            validCount++;
          }
          break;
        case 'lower':
          if (some(validLowers.split(''), (c) => control.value && control.value.includes(c))) {
            validCount++;
          }
          break;
        default:
          break;
      }
    });
    const forbidden = validCount < mustContainSome?.length;
    return forbidden ? { mustContainSome: { value: control.value } } : null;
  };
}
