import * as dayjs from 'dayjs';
import Country from 'src/app/models/country';
import MemberAddress from 'src/app/models/memberAddress';
import Attestation from 'src/app/models/attestation';
import PlanType from 'src/app/models/planType';
// Flat model for new calls to create member
import Answer from './answer';
import Member from './member';
import { cloneDeep, map, find, filter } from 'lodash';
import Enrollment from './enrollment';
import EnrollmentPeriod from './enrollmentPeriod';
import { env } from 'src/env/development';
import RelationshipCertification from './relationshipCertification';
import Relationship from './relationship';
import MemberMedicare from './memberMedicare';
import { compileNgModuleDeclarationExpression } from '@angular/compiler/src/render3/r3_module_compiler';

export default class BaseDependent {
  memberId: string;
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  birthDate: Date;
  relationshipTypeId: string;
  relationshipQualifyReasonId: string;
  relationshipCertifications: RelationshipCertification[];
  needsCertification: boolean;
  relationship: Relationship;

  enrollmentPeriod: EnrollmentPeriod;

  constructor(member?, enrollmentPeriod?: EnrollmentPeriod) {
    this.enrollmentPeriod = enrollmentPeriod;

    if (member) {
      this.memberId = member.memberId || null;
      this.firstName = member.firstName || null;
      this.middleName = member.middleName || null;
      this.lastName = member.lastName || null;
      this.suffix = member.suffix || null;
      this.birthDate = member.birthDate ? new Date(member.birthDate) : null;

      if (member.relationshipToSubscriber) {
        this.relationshipTypeId = member.relationshipToSubscriber.relationshipTypeId;
        this.relationshipQualifyReasonId = member.relationshipToSubscriber.relationshipQualifyReasonId;
        this.relationshipCertifications = member.relationshipToSubscriber.relationshipCertifications;
        this.relationship = member.relationshipToSubscriber;
        this.needsCertification = member.relationshipToSubscriber.relationshipQualifyReason.needsCertificationInd;
      }
    }
  }

  dependentIsUnderThirteen(birthDate: Date): boolean {
    return dayjs().subtract(13, 'year').isBefore(dayjs(birthDate));
  }
}
