import * as dayjs from 'dayjs';

export default class LowerLimit {
  lowerLimitId: string;
  beginDate: Date;
  endDate: Date;
  lowerLimitDate: Date;
  upperLimitDate: Date;
  upperLimitEffectiveDate: Date;
  upperLimitEndDate: Date;
  lowerLimitMinEligibilityDate: Date;

  constructor(lowerLimit?) {
    if (lowerLimit) {
      this.lowerLimitId = lowerLimit.lowerLimitId;
      this.beginDate = lowerLimit.beginDate ? new Date(lowerLimit.beginDate) : null;
      this.endDate = lowerLimit.endDate ? new Date(lowerLimit.endDate) : null;
      this.lowerLimitDate = lowerLimit.lowerLimitDate ? new Date(lowerLimit.lowerLimitDate) : null;
      this.upperLimitEffectiveDate = lowerLimit.upperLimitEffectiveDate ? new Date(lowerLimit.upperLimitEffectiveDate) : null;
      this.upperLimitEndDate = lowerLimit.upperLimitEndDate ? new Date(lowerLimit.upperLimitEndDate) : null;
      this.lowerLimitMinEligibilityDate = lowerLimit.lowerLimitMinEligibilityDate ? new Date(lowerLimit.lowerLimitMinEligibilityDate) : null;

      this.upperLimitDate = dayjs(this.lowerLimitDate).add(3, 'month').startOf('month').add(1, 'day').toDate();
    }
  }
}
