<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid ie-height-mobile-l">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body page-container">
        <h1>HCA Selfpay Dashboard</h1>
        <h2 class="h4">Welcome{{systemUser.firstName ? ', ' + systemUser.firstName : ''}} {{systemUser.lastName}}!</h2>
        <div class="ie-height-mobile-l hca-dashboard-card-container" fxLayout="row wrap">
          <div class="hca-dashboard-card" fxFlex.xs="100%" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/subscriberManagement/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUser" size="2x"></fa-icon></span>
                  <h3>Manage subscribers</h3>
                  <h4 class="h6">Search, view, add, or update subscribers</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/relationshipVerification/hca/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Dependent Verification</h3>
                  <h3>You have {{totalRelationshipVerifcationsPending}} verification request(s)</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/specialOpenEnrollment/review/hca/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Special Enrollment Event Verification</h3>
                  <h4>You have {{totalSpecialOpenEnrollmentPending}} Special enrollment event request(s)</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/selfpay/review/hca/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Continuation coverage/retiree requests</h3>
                  <h4 class="h6">Manage self-pay/retiree enrollment requests</h4>
                  <h4 class="h5">You have {{totalSelfpayPending}} pending request(s)</h4>
                </div>
              </div>
            </div>
          </div>            
          <div class="hca-dashboard-card" fxFlex.xs="100%" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/reports/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faChartBar" size="2x"></fa-icon></span>
                  <h3>Reports</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>