import { DependentsModule } from './../subscriber/modules/dependents/dependents.module';
import { SubscriberModule } from './../subscriber/subscriber.module';
import { SoeEffectiveDateComponent } from './components/soeReview/components/reviewGrid/components/effectiveDate/soe.effectiveDate.component';
import { SoeReviewSummaryComponent } from './components/soeReview/components/reviewGrid/components/reviewSummary/soeReview.summary.component';
import { SoeReviewFormComponent } from './components/soeReview/components/reviewGrid/components/reviewForm/soeReview.form.component';
import { SoeReviewAdminComponent } from './components/soeReview/soeReview.admin.component';
import { SubscriberManagementEmploymentComponent } from './components/subscriberManagement/components/employment/subscriberManagement.employment.component';
import { VerificationSummaryComponent } from './components/verification/components/adminGrid/components/verificationSummary/verification.summary.component';
import { VerificationFormComponent } from './components/verification/components/adminGrid/components/verificationForm/verification.form.component';
import { VerificationAdminGridComponent } from './components/verification/components/adminGrid/verification.admin.grid.component';
import { VerificationAdminComponent } from './components/verification/verification.admin.component';
import { AddSubscriberComponent } from './components/subscriberManagement/components/add/components/addSubscriber/addSubscriber.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { RelationshipService } from '../../services/relationship.service';
import { UserService } from '../../services/user.service';

import { SubscriberManagementComponent } from './components/subscriberManagement/subscriberManagement.component';
import { SubscriberManagementNavComponent } from './components/subscriberManagement/components/nav/subscriberManagement.nav.component';
import { SubscriberManagementAddComponent } from './components/subscriberManagement/components/add/subscriberManagement.add.component';
import { SubscriberManagementAddSearchComponent } from './components/subscriberManagement/components/add/components/search/subscriberManagement.add.search.component';
import { TransferFoundComponent } from './components/subscriberManagement/components/add/components/transferFound/transferFound.component';
import { NeedsToBeAdjustedComponent } from './components/subscriberManagement/components/add/components/needsToBeAdjusted/needsToBeAdjusted.component';
import { SubscriberManagementEmploymentTermComponent } from './components/subscriberManagement/components/employment/components/term/subscriberManagement.employment.term.component';
import { SubscriberManagementNotesComponent } from './components/subscriberManagement/components/notes/subscriberManagement.notes.component';
import { SubscriberManagementSpousalComponent } from './components/subscriberManagement/components/spousal/subscriberManagement.spousal.component';

import { AddSelfPaySubscriberComponent } from './components/subscriberManagement/components/add/components/addSelfPay/addSelfPaySubscriber.component';

import { AccessManagementComponent } from './components/accessManagement/accessManagement.component';
import { AccessManagementGridComponent } from './components/accessManagement/components/accessManagementGrid.component';

import { OrganizationProfileComponent } from './components/organizationProfile/organizationProfile.component';
import { OrganizationDetailsComponent } from './components/organizationProfile/components/details/organization.details.component';
import { OrganizationContactComponent } from './components/organizationProfile/components/contact/organization.contact.component';
import { SoeReviewAdminGridComponent } from './components/soeReview/components/reviewGrid/soeReview.admin.grid.component';
import { DataDepotComponent } from './components/dataDepot/dataDepot.component';
import { BillingFileComponent } from './components/billingFile/billingFile.component';
import { EligibilityFileComponent } from './components/eligibilityFile/eligibilityFile.component';
import { EligibilityFileErrorReviewComponent } from './components/eligibilityFile/components/errorReview/errorReview.component';
import { SOEModule } from '../subscriber/modules/soe/soe.module';
import { AdminHCAModule } from '../adminHCA/adminHCA.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    DialogsModule,
    FormsModule,
    InputsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    GridModule,
    NgbModule,
    SwitchModule,
    SharedModule,
    SOEModule,
    SubscriberModule,
    AdminHCAModule,
    DependentsModule
  ],
  declarations: [
    SubscriberManagementComponent,
    SubscriberManagementNavComponent,
    SubscriberManagementAddComponent,
    SubscriberManagementAddSearchComponent,
    SubscriberManagementEmploymentComponent,
    SubscriberManagementEmploymentTermComponent,
    AddSelfPaySubscriberComponent,
    TransferFoundComponent,
    NeedsToBeAdjustedComponent,
    AccessManagementComponent,
    AccessManagementGridComponent,
    OrganizationProfileComponent,
    OrganizationDetailsComponent,
    OrganizationContactComponent,
    AddSubscriberComponent,
    VerificationAdminComponent,
    VerificationAdminGridComponent,
    SoeReviewAdminComponent,
    SoeReviewFormComponent,
    SoeReviewSummaryComponent,
    SoeReviewAdminGridComponent,
    SoeEffectiveDateComponent,
    SubscriberManagementNotesComponent,
    SubscriberManagementSpousalComponent,
    DataDepotComponent,
    BillingFileComponent,
    EligibilityFileComponent,
    EligibilityFileErrorReviewComponent
  ],
  providers: [RelationshipService, UserService, {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
  exports: [
    SubscriberManagementComponent,
    SubscriberManagementNavComponent,
    SubscriberManagementAddComponent,
    SubscriberManagementAddSearchComponent,
    AddSelfPaySubscriberComponent,
    TransferFoundComponent,
    NeedsToBeAdjustedComponent,
    AccessManagementComponent,
    AccessManagementGridComponent,
    OrganizationProfileComponent,
    OrganizationDetailsComponent,
    OrganizationContactComponent,
    AddSubscriberComponent,
    SoeReviewAdminComponent,
    SoeReviewFormComponent,
    SoeReviewSummaryComponent,
    SoeReviewAdminGridComponent,
    DataDepotComponent,
    BillingFileComponent,
    EligibilityFileComponent,
    EligibilityFileErrorReviewComponent
  ],
  bootstrap: [
    SubscriberManagementComponent,
    SubscriberManagementNavComponent,
    SubscriberManagementAddComponent,
    SubscriberManagementAddSearchComponent,
    AddSelfPaySubscriberComponent,
    TransferFoundComponent,
    NeedsToBeAdjustedComponent,
    AccessManagementComponent,
    AccessManagementGridComponent,
    OrganizationProfileComponent,
    OrganizationDetailsComponent,
    OrganizationContactComponent,
    AddSubscriberComponent,
    DataDepotComponent,
    BillingFileComponent,
    EligibilityFileComponent,
    EligibilityFileErrorReviewComponent
  ]
})
export class AdminPerspayModule {}
