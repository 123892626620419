import { map } from 'lodash';
import DependentCoverageElection from './dependentCoverageElection';
import Enrollment from './enrollment';
import Plan from './plan';
import PlanType from './planType';

export default class SubscriberCoverage {
  subscriberMemberId: string;
  enrollmentPeriodId: string;
  electedPlans: Plan[];
  enrollments: Enrollment[];
  dependentCoverageElections: DependentCoverageElection[];
  waivedPlanTypes: string[];
  changeReasonId: string;
  planTypeIds: string[];
  terminatePreviousPebbCoverage = false;
  selfPayWaivingMedicalCoverage = false;
  selfPayWaivingDentalCoverage = false;
  selfPayWaivingVisionCoverage = false;
  isTransferEvent = false;
  removeMedicalForDE: boolean = false;
  removeDentalForDE: boolean = false;
  removeVisionForDE: boolean = false;
  removeForDEPlanTypes: string[];
  // for supplemental 
  effectiveStartDate?: Date;
  constructor(subscriberCoverage?) {
    if (subscriberCoverage) {
      this.subscriberMemberId = subscriberCoverage.subscriberMemberId;
      this.enrollmentPeriodId = subscriberCoverage.enrollmentPeriodId;
      this.electedPlans = map(subscriberCoverage.electedPlans, (ep: Plan) => new Plan(ep));
      this.enrollments = map(subscriberCoverage.enrollments, (e: Enrollment) => new Enrollment(e));
      this.dependentCoverageElections = map(subscriberCoverage.dependentCoverageElections, (dce: DependentCoverageElection) => new DependentCoverageElection(dce));
      this.waivedPlanTypes = subscriberCoverage.waivedPlanTypes;
      this.planTypeIds = subscriberCoverage.planTypeIds;
      this.selfPayWaivingMedicalCoverage = subscriberCoverage.selfPayWaivingMedicalCoverage;
      this.selfPayWaivingDentalCoverage = subscriberCoverage.selfPayWaivingDentalCoverage;
      this.selfPayWaivingVisionCoverage = subscriberCoverage.selfPayWaivingVisionCoverage;
      this.isTransferEvent = subscriberCoverage.isTransferEvent;
      this.removeForDEPlanTypes = subscriberCoverage.removeForDEPlanTypes;
    }

  }
}
