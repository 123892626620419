// ng
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

// ext
import { NotificationService } from '@progress/kendo-angular-notification';

// local
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'pebb-header',
  templateUrl: 'header.component.html',
  styleUrls: [],
  providers: []
})
export class HeaderComponent {
  @Input() displayLogo: boolean;
  @Input() isPersPay: boolean = false;
  router: Router;
  authService: AuthService;
  idx = 0;
  themes: string[] = ['default', 'green', 'blue', 'red', 'orange', 'ochre', 'bluegray', 'yellow', 'darkgreen', 'cobalt', 'dark'];
  // tslint:disable-next-line: variable-name
  constructor(private _router: Router, private _authService: AuthService, private notificationService: NotificationService) {
    this.router = _router;
    this.authService = _authService;
  }

  logOut(): void {
    this.notificationService.show({
      content: 'You are being logged out of PEBB My Account as well as Secure Access Washington (SAW). To use other state services with SAW, you will need to log back in to SAW.',
      animation: { type: 'slide', duration: 200 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'warning', icon: true },
    });
    setTimeout(() => this.authService.logout(), 4000);
  }

  changeTheme(e): void {
    e.preventDefault();
    document.documentElement.className = `theme-${this.themes[this.idx++]}`;
    if (this.idx > this.themes.length) {
      this.idx = 0;
    }
  }
}
