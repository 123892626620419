import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './components/login.component';
import { UnauthorizedComponent } from './components/unauthorized.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { RegistrationComponent } from './components/registration.component';
import { RouterModule } from '@angular/router';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { NgbTooltipModule, NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';

// got errored by TS when trying to stick this on sharedModule and import that - needs figuring out if we want to reuse.
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ForbiddenComponent } from './components/forbidden.component';
import { NotManagedInSystemComponent } from './components/notManagedInSystem.component';

@NgModule({
  imports: [RouterModule, BrowserModule, FormsModule, FlexLayoutModule, SharedModule, InputsModule, DatePickerModule, NgbTooltipModule, NotificationModule, FontAwesomeModule],
  declarations: [AuthComponent, LoginComponent, RegistrationComponent, UnauthorizedComponent, ForbiddenComponent, NotManagedInSystemComponent],
  exports: [AuthComponent, LoginComponent, RegistrationComponent, UnauthorizedComponent, ForbiddenComponent],
  bootstrap: [AuthComponent],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class PEBBAuthModule {}
