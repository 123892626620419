<!-- NOT VISION/MED D RETIREE -->
<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column" *ngIf="currentSubscriber && !isRetireeOE && !isCobraOE && (requestType === 'SOER' ? !minVisionDate : true)">
  <div class="card-body">
    <h3>Subscriber</h3>
    <form nativeControlElement #selfPaySubscriberForm="ngForm" #formContainer>
      <fieldset [disabled]="isPerspay">
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div class="form-group" fxFlex="30">
            <label for="socialSecurityNumber" id="ssnLabel">Social Security number</label>
            <kendo-maskedtextbox
              [mask]="env.ssnMask"
              [pattern]="env.ssnItinRegex"
              [(ngModel)]="currentSubscriber.socialSecurityNumber"
              [maskOnFocus]="true"
              name="socialSecurityNumber"
              aria-labelledby="ssnLabel"
              #ssn="ngModel"
              class="form-control"
              [forbiddenValues]="env.invalidSSNValues"
            ></kendo-maskedtextbox>
          </div>
          <div class="form-group" fxFlex="25">
            <date-picker
              #birthDate="ngModel"
              name="birthDate"
              label="Date of birth"
              required
              [(ngModel)]="currentSubscriber.birthDate"></date-picker>
          </div>
          <div class="form-group" fxFlex="25">
            <label for="birthSexId">Sex assigned at birth</label>
            <select class="form-control" name="birthSexId" id="birthSexId" [(ngModel)]="currentSubscriber.birthSexId" required>
              <option></option>
              <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
            </select>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div class="form-group" fxFlex="70">
            <label for="lastName">Last name</label>
            <input required type="text" class="form-control" name="lastName" id="lastName" [(ngModel)]="currentSubscriber.lastName" />
          </div>
          <div class="form-group required" fxFlex="20">
            <label for="genderIdentityId">Gender Identity</label>
            <select class="form-control" id="genderIdentityId" name="genderIdentityId" [(ngModel)]="currentSubscriber.genderIdentityId" required>
              <option></option>
              <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
            </select>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div class="form-group" fxFlex="40">
            <label for="firstName">First name</label>
            <input required type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="currentSubscriber.firstName" />
          </div>
          <div class="form-group" fxFlex="30">
            <label for="middleName">Middle name</label>
            <input type="text" class="form-control" maxLength="15" id="middleName" name="middleName" [(ngModel)]="currentSubscriber.middleName" />
          </div>
          <div class="form-group" fxFlex="20">
            <span class="hca-soe-small-note" fxFlex="100">
              Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law.
            </span>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.lt-md="column">
          <div fxLayout="column" fxFlex="30">            
          </div>
          <div [hidden]="!currentSubscriber.isRepresentedInd" class="form-group" fxFlex="25">
            <date-picker
              #representationDate="ngModel"
              name="representationDate"
              label="Representation date"
              [required]="currentSubscriber.isRepresentedInd"
              [(ngModel)]="currentSubscriber.representedDate"></date-picker>
          </div>
          <div class="form-group" fxFlex="10">
            <label for="suffix">Suffix</label>
            <input pattern="[a-zA-Z]*" type="text" class="form-control" id="suffix" name="suffix" [(ngModel)]="currentSubscriber.suffix" placeholder="JR, SR" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div fxFlex="40" class="form-group">
            <label for="homePhoneNumber">Phone number</label>
            <input pattern="[0-9]*" type="text" class="form-control" id="homePhoneNumber" name="homePhoneNumber" [(ngModel)]="currentSubscriber.homePhoneNumber" />
          </div>
          <div fxFlex="40" class="form-group">
            <label for="workPhoneNumber">Alternate phone number</label>
            <input type="text" class="form-control" id="workPhoneNumber" name="workPhoneNumber" [(ngModel)]="currentSubscriber.workPhoneNumber" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxFlexFill>
          <h4 style="margin-bottom: 0px">Residential address</h4>
        </div>
        <div class="form-row" fxLayout="row" fxFlexFill>
          <h4>
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faExclamationCircle"></fa-icon>
            <span class="h4" *ngIf="agency !== 'SEBB'">If you move, you</span><span *ngIf="agency === 'SEBB'">You</span>
             must report your new address to the {{agency}} Program <b>no later than 60 days</b> after you move.
          </h4>
        </div>

        <address #residentialAddress view="inline" name="residentialAddress" [(ngModel)]="currentSubscriber.formResidentialAddress"></address>

        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" style="margin-bottom: 20px">
          <checkbox name="mailingSame" bold [(ngModel)]="selfPay.formJson.mailingAddressSameAsResidential">Mailing address same as residential</checkbox>
        </div>
        <div *ngIf="!selfPay.formJson.mailingAddressSameAsResidential">
          <h4>Mailing Address</h4>
          <address #mailingAddress view="inline" name="mailingAddress" [(ngModel)]="currentSubscriber.mailingAddress"></address>
        </div>

        <!-- Current coverage check -->
        <div *ngIf="requestType === 'C' || requestType === 'U' || requestType === 'OEU' || requestType === 'OEC'" class="form-row" fxFlexFill>
          <div fxLayout="row" fxFlexFill style="margin-bottom: 20px">
            <kendo-formfield [orientation]="'horizontal'" fxFlex="100">
              <label class="k-label">Are you or any eligible dependents enrolled in {{agency}} insurance coverage under another account?</label>
              <yes-no
                name="coveredByOtherPEBBAccountInd"
                style="width: auto;"
                orientation="horizontal-list"
                [stretch]="false"
                [required]="requestType === 'C' || requestType === 'U'  || requestType === 'OEU' || requestType === 'OEC'"
                [(ngModel)]="currentSubscriber.coveredByOtherPEBBAccountInd"
                ></yes-no>
            </kendo-formfield>
          </div>
        </div>
        <!-- Continue coverage section -->
        <div *ngIf="requestType === 'U' || requestType === 'OEU' || requestType === 'SOEU'" class="form-row" fxFlexFill>
          <div class="form-row" fxFlexFill style="margin-bottom: 20px">
            <div fxLayout="column" fxFlexFill>
              <span class="soe-form-small-header">Continue coverage (Select all that apply)</span>
              <p>You may continue coverage you were enrolled in on the day your PEBB health plan coverage ended. If you are enrolled in a Medical Flexible Spending Arrangement (FSA) or Limited Purpose FSA and would like to continue it, call Navia Benefit Solutions at 1-800-669-3539 no later than 60 days after the mailing date on Navia’s COBRA election notice.</p>
              <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between" style="margin-bottom: 20px">
                <div class="k-form-field bigger">
                  <checkbox name="medicalContinueCoverageInd" [(ngModel)]="currentSubscriber.medicalContinueCoverageInd">Medical</checkbox>
                </div>
                <div class="k-form-field bigger">
                  <checkbox name="dentalContinueCoverageInd" [(ngModel)]="currentSubscriber.dentalContinueCoverageInd">Dental</checkbox>
                </div>
                <div class="k-form-field bigger" *ngIf="agency === 'SEBB'">
                  <checkbox name="visionContinueCoverageInd" [(ngModel)]="currentSubscriber.visionContinueCoverageInd">Vision</checkbox>
                </div>
                <!-- SELF PAY PEBB VISION FOR LWOP -->
                <div class="k-form-field bigger" *ngIf="(((selfPay.formJson.selfPayReason === 'ALWOP' || requestType === 'SOEU')  && minVisionDate) || requestType === 'OEU') && agency !== 'SEBB'">
                  <checkbox name="visionContinueCoverageInd" [(ngModel)]="currentSubscriber.visionContinueCoverageInd">Vision</checkbox>
                </div>
                <div class="k-form-field bigger" *ngIf="requestType !== 'OEU' && agency !== 'SEBB'">
                  <checkbox name="longTermDisabilityContinueCoverageInd" [(ngModel)]="currentSubscriber.longTermDisabilityContinueCoverageInd">
                    Long-term disability insurance (only if on educational or USERRA military leave)
                  </checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Terminate coverage  -->
        <div *ngIf="requestType === 'U' || requestType === 'OEU' || requestType === 'SOEU'" class="form-row" fxFlexFill>
          <div class="form-row" fxFlexFill style="margin-bottom: 20px">
            <div fxLayout="column" fxFlexFill>
              <span class="soe-form-small-header">Terminate coverage (Select all that apply)</span>
              <p>If you terminate all coverage, you will not be eligible to enroll again in PEBB Continuation Coverage unless you regain eligibility.</p>   
              <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between" style="margin-bottom: 20px">
                <div class="k-form-field bigger">
                  <checkbox name="medicalTerminateCoverage" [(ngModel)]="currentSubscriber.medicalTerminateCoverage">Medical</checkbox>
                </div>
                <div class="k-form-field bigger">
                  <checkbox name="dentalTerminateCoverage" [(ngModel)]="currentSubscriber.dentalTerminateCoverage">Dental</checkbox>
                </div>
                <div class="k-form-field bigger" *ngIf="agency === 'SEBB'">
                  <checkbox name="visionTerminateCoverage" [(ngModel)]="currentSubscriber.visionTerminateCoverage">Vision</checkbox>
                </div>
                <!-- SELF PAY PEBB VISION FOR LWOP -->
                <div class="k-form-field bigger" *ngIf="(((selfPay.formJson.selfPayReason === 'ALWOP' || requestType === 'SOEU')  && minVisionDate) || requestType === 'OEU') && agency !== 'SEBB'">
                  <checkbox name="visionTerminateCoverage" [(ngModel)]="currentSubscriber.visionTerminateCoverage">Vision</checkbox>
                </div>
                <div class="k-form-field bigger">
                  <checkbox name="terminateLifeAndADDCoverageInd" [(ngModel)]="currentSubscriber.terminateLifeAndADDCoverageInd">
                    Life and accidential death and dismemberment (AD&D) insurance
                  </checkbox>
                </div>
              </div>
              <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between" style="margin-bottom: 20px">
                <div class="form-group" fxFlex="25">
                  <date-picker
                    #terminationDate="ngModel"
                    name="terminationDate"
                    label="Termination date"
                    [required]="currentSubscriber.medicalTerminateCoverage || currentSubscriber.dentalTerminateCoverage || currentSubscriber.terminateLifeAndADDCoverageInd"
                    onlyAllowLastOfMonth
                    [(ngModel)]="currentSubscriber.medicalTerminateCoverageDate"></date-picker>
                </div>
                <div class="form-group" fxFlex="70">
                  <label for="ultermreason">Termination reason</label>
                  <input
                    [required]="currentSubscriber.medicalTerminateCoverage || currentSubscriber.dentalTerminateCoverage || currentSubscriber.terminateLifeAndADDCoverageInd"
                    type="text"
                    class="form-control"
                    name="ultermreason"
                    id="ultermreason"
                    [(ngModel)]="currentSubscriber.medicalTerminateCoverageReason"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Life and accidential death and dismemberment -->
        <div *ngIf="requestType === 'U'" class="form-row" fxFlexFill>
          <span class="soe-form-small-header">Life and accidental death and dismemberment (AD&D) insurance</span>
          <div class="hca-self-pay-checklist fxGap-medium-sm" fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px">
            <radio
              name="lifeAndAddAcknowledgeInd"
              [required]="currentSubscriber.lifeAndADDContinueCoverageInd"
              [value]="true"
              [(ngModel)]="currentSubscriber.lifeAndADDAcknowlegeInd">
              Yes, I wish to continue the life and AD&D insurance I had as an employee. I understand I will need to pay MetLife directly for basic life insurance and basic AD&D Insurance in addition to any
              supplemental life and supplemental AD&D insurance I have while on {{agency}} Continuation Coverage (Unpaid Leave). If you wish to decrease your supplemental life and/or AD&D insurance amounts while on {{agency}}
              Continuation Coverage (Unpaid Leave), please call MetLife at 1-833-854-9624.
            </radio>
            <radio
              name="lifeAndAddAcknowledgeInd"
              [required]="currentSubscriber.lifeAndADDContinueCoverageInd"
              [value]="false"
              [(ngModel)]="currentSubscriber.lifeAndADDAcknowlegeInd">
                No, I do not wish to continue the life and AD&D insurance I had as an employee. I understand I must reapply for supplemental life insurance and AD&D insurance when I regain eligibility
                and I must submit evidence of insurability to MetLife for supplemental life insurance. I understand that MetLife must receive my MetLife Enrollment/Change form through MetLife’s 
                <a *ngIf="agency === 'SEBB'" href="mybenefits.metlife.com/wasebb" target="_blank"> MyBenefits</a>
                <a *ngIf="agency === 'PEBB'" href="https://mybenefits.metlife.com/wapebb" target="_blank"> MyBenefits</a>                              
                no later than 31 days after the date I regain eligibility.
            </radio>
          </div>
        </div>
        <!-- Long term disability -->
        <div *ngIf="(requestType === 'U' || requestType === 'OEU') && agency !== 'SEBB'" class="form-row" fxFlexFill>
          <span class="soe-form-small-header">Long-term disability (LTD) insurance</span>
          <p>
            This section applies only to employees on approved educational leave, or who are called to active duty in the uniformed services as defined under Uniformed Services Employment and
            Reemployment Rights Act (USERRA).
          </p>
          <div class="hca-self-pay-checklist fxGap-medium-sm" fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px" fxFlex="90">
            <radio-list
              name="sltdSelPlan"
              orientation="vertical"
              [(ngModel)]="selfPay.formJson.ltdPlanName"
              [items]="{
                '60% coverage level': sltdSixtyPlan.planName,
                '50% coverage level': sltdFiftyPlan.planName,
                'Decline employee-paid LTD': 'Decline employee-paid LTD'
              }"></radio-list>
          </div>
        </div>
        <!-- Coverage section -->
        <div *ngIf="requestType === 'C' || requestType === 'OEC' || requestType === 'SOE' || requestType === 'SOEC'" class="form-row" fxFlexFill>
          <div class="form-row" fxFlexFill style="margin-bottom: 20px">
            <div>
              You may choose to continue coverage and then terminate coverage at a future date by selecting to both continue and terminate coverage and providing a termination date and reason below.
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between" class="fxGap-small">
              <span class="soe-form-small-header">Medical coverage (Select all that apply)</span>
              <div fxLayout="column">
                <div fxLayout="column" fxFlex="50">
                  <div class="k-form-field bigger" style="margin-bottom: 15px">
                    <checkbox (change)="updateCoverageContinue($event, 'medicalDoNotContinueCoverage')" name="continueMedical" [(ngModel)]="currentSubscriber.medicalContinueCoverageInd">Continue coverage</checkbox>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="50">
                  <div class="k-form-field bigger">
                    <checkbox (change)="updateCoverageContinue($event, 'medicalContinueCoverageInd')" name="doNotContinueMedical" [(ngModel)]="currentSubscriber.medicalDoNotContinueCoverage">Do not continue coverage</checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between" class="fxGap-small">
              <span class="soe-form-small-header">Dental coverage</span>
              <div fxLayout="column">
                <div fxLayout="column" fxFlex="50">
                  <div class="k-form-field bigger" style="margin-bottom: 15px">
                    <checkbox (change)="updateCoverageContinue($event, 'dentalDoNotContinueCoverage')" name="continueDental" [(ngModel)]="currentSubscriber.dentalContinueCoverageInd">Continue coverage</checkbox>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="50">
                  <div class="k-form-field bigger">
                    <checkbox (change)="updateCoverageContinue($event, 'dentalContinueCoverageInd')" name="doNotContinueDental" [(ngModel)]="currentSubscriber.dentalDoNotContinueCoverage">Do not continue coverage</checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- VISION -->
          <div *ngIf="agency === 'SEBB'" fxLayout="column" fxFlex="100" fxFlex.fxFlex.lt-md="100" fxLayoutAlign="space-between" class="fxGap-small">
            <span class="soe-form-small-header">Vision coverage (Select all that apply)</span>
            <div fxLayout="column">
              <div fxLayout="column" fxFlex="50">
                <div class="k-form-field bigger" style="margin-bottom: 15px">
                  <checkbox (change)="updateCoverageContinue($event, 'visionDoNotContinueCoverage')"  name="continueVision" [(ngModel)]="currentSubscriber.visionContinueCoverageInd">Continue coverage</checkbox>
                </div>
              </div>
              <div fxLayout="column" fxFlex="50">
                <div class="k-form-field bigger">
                  <checkbox (change)="updateCoverageContinue($event, 'visionContinueCoverageInd')" name="doNotContinueVision" [(ngModel)]="currentSubscriber.visionDoNotContinueCoverage">Do not continue coverage</checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- TERMINATE HERE -->
          <div *ngIf="requestType === 'C' || requestType === 'OEC' || requestType === 'SOE' || requestType === 'SOEC'" class="form-row" fxFlexFill>
            <div class="form-row" fxFlexFill style="margin-bottom: 20px">
              <div fxLayout="column" fxFlexFill>
                <span class="soe-form-small-header">Terminate coverage (Select all that apply)</span>
                <div>
                  You may choose to continue coverage and then terminate it at a future date by selecting to both continue and terminate coverage and providing a termination date and reason below.
                </div>
                <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between" style="margin-bottom: 20px">
                  <div class="k-form-field bigger">
                    <checkbox name="medicalTerminateCoverage" [(ngModel)]="currentSubscriber.medicalTerminateCoverage">Medical</checkbox>
                  </div>
                  <div class="k-form-field bigger">
                    <checkbox name="dentalTerminateCoverage" [(ngModel)]="currentSubscriber.dentalTerminateCoverage">Dental</checkbox>
                  </div>
                  <div class="k-form-field bigger" *ngIf="agency === 'SEBB'">
                    <checkbox name="visionTerminateCoverage" [(ngModel)]="currentSubscriber.visionTerminateCoverage">Vision</checkbox>
                  </div>                
                </div>
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between" style="margin-bottom: 20px">
                  <div class="form-group" fxFlex="25">
                    <date-picker
                      #terminationDate="ngModel"
                      name="terminationDate"
                      label="Termination date"
                      [required]="currentSubscriber.medicalTerminateCoverage || currentSubscriber.dentalTerminateCoverage || currentSubscriber.terminateLifeAndADDCoverageInd"
                      onlyAllowLastOfMonth
                      [(ngModel)]="currentSubscriber.medicalTerminateCoverageDate"></date-picker>
                  </div>
                  <div class="form-group" fxFlex="70">
                    <label for="ultermreason">Term Reason</label>
                    <input
                      [required]="currentSubscriber.medicalTerminateCoverage || currentSubscriber.dentalTerminateCoverage || currentSubscriber.terminateLifeAndADDCoverageInd"
                      type="text"
                      class="form-control"
                      name="ultermreason"
                      id="ultermreason"
                      [(ngModel)]="currentSubscriber.medicalTerminateCoverageReason"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4>
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faExclamationCircle"></fa-icon>
            If you terminate all coverage, you will not be eligible to enroll again in {{agency}} Continuation Coverage unless you regain eligibility.
          </h4>
          <h4 *ngIf="!oeSelfPayTypes.includes(requestType)">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faExclamationCircle"></fa-icon>
            You may choose to continue coverage you were enrolled in on the day your {{agency}} health plan coverage ended. If you have {{agency}} life insurance you wish to port or convert, call MetLife at
            1-866-548-7139. If you are enrolled in a Medical Flexible Spending Arrangement (FSA) or limited purpose FSA and would like to continue it, call Navia Benefit Solutions at 1-800-669-3539. Navia must receive your
            request <b>no later than 60 days</b> from the date your {{agency}} health plan coverage ended, or from the postmark date on Navia’s COBRA election notice sent to you, whichever is later.
          </h4>
          <!-- SOCIAL SECURITY/OTHER COVERAGE SECTION -->
          <div fxLayout="row" fxFlexFill *ngIf="!oeSelfPayTypes.includes(requestType)">

            <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
              <label class="k-label">Are you covered by another group medical plan?</label>
              <yes-no
                name="coveredByOtherMedical"
                style="width: auto;"
                orientation="horizontal-list"
                [stretch]="false"
                [(ngModel)]="currentSubscriber.coveredByOtherMedicalInd"
                ></yes-no>
            </kendo-formfield>
            <div class="form-inline">
              <div class="form-group">
                <date-picker
                  #coveredByOtherMedicalDate="ngModel"
                  name="coveredByOtherMedicalDate"
                  labelFlex="50"
                  inputFlex="50"
                  [required]="currentSubscriber.coveredByOtherMedicalInd"
                  [(ngModel)]="currentSubscriber.coveredByOtherMedicalDate">
                  <span style="white-space: nowrap; margin-right: 20px">If yes, effective date</span>
                </date-picker>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxFlexFill *ngIf="!oeSelfPayTypes.includes(requestType)">
            <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
              <label class="k-label">Are you covered by another group dental plan?</label>
              <yes-no
                name="coveredByOtherDental"
                style="width: auto;"
                orientation="horizontal-list"
                [stretch]="false"
                [(ngModel)]="currentSubscriber.coveredByOtherDentalInd"
                ></yes-no>
            </kendo-formfield>
            <div class="form-inline">
              <div class="form-group">
                <div class="input-group">
                  <date-picker
                    #coveredByOtherDentalDate="ngModel"
                    name="coveredByOtherDentalDate"
                    labelFlex="50"
                    inputFlex="50"
                    [required]="currentSubscriber.coveredByOtherDentalInd"
                    [(ngModel)]="currentSubscriber.coveredByOtherDentalDate">
                  <span style="white-space: nowrap; margin-right: 20px">If yes, effective date</span>
                </date-picker>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxFlexFill *ngIf="!oeSelfPayTypes.includes(requestType) && agency === 'SEBB'">
            <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
              <label class="k-label">Are you covered by another group vision plan?</label>
              <yes-no
                name="coveredByOtherVision"
                style="width: auto;"
                orientation="horizontal-list"
                [stretch]="false"
                [(ngModel)]="currentSubscriber.coveredByOtherVisionInd"
                ></yes-no>
            </kendo-formfield>
            <div class="form-inline">
              <div class="form-group">
                <div class="input-group">
                  <date-picker
                    #coveredByOtherVisionDate="ngModel"
                    name="coveredByOtherVisionDate"
                    labelFlex="50"
                    inputFlex="50"
                    [required]="currentSubscriber.coveredByOtherVisionInd"
                    [(ngModel)]="currentSubscriber.coveredByOtherVisionDate">
                  <span style="white-space: nowrap; margin-right: 20px">If yes, effective date</span>
                </date-picker>
                </div>
              </div>
            </div>
          </div>          
          <div fxLayout="row" fxFlexFill *ngIf="!oeSelfPayTypes.includes(requestType)">
            <kendo-formfield [orientation]="'horizontal'" fxFlex="40">
              <label class="k-label">Do you receive Social Security Disability?</label>
              <yes-no
                name="coveredByOtherSSD"
                style="width: auto;"
                orientation="horizontal-list"
                [stretch]="false"
                [(ngModel)]="currentSubscriber.coveredByOtherSSDInd"
                ></yes-no>
            </kendo-formfield>
            <div class="form-inline" >
              <div class="form-group">
                <date-picker
                  #coveredByOtherSSDDate="ngModel"
                  name="coveredByOtherSSDDate"
                  labelFlex="50"
                  inputFlex="50"
                  [required]="currentSubscriber.coveredByOtherSSDInd"
                  [(ngModel)]="currentSubscriber.coveredByOtherSSDDate">
                  <span style="white-space: nowrap; margin-right: 20px">If yes, effective date</span>
                </date-picker>
              </div>
            </div>
          </div>
          <h4>
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faExclamationCircle"></fa-icon>
            If you answered Yes to receiving Social Security Disability, you must upload or send a copy of your Social Security Disability Award letter. If mailing, write your last name and the last four digits of
            your Social Security number on the copy so we can identify your account. You and your enrolled dependents may be eligible for additional months of coverage.
          </h4>
        </div>
        <!-- Actions section -->
        <div *ngIf="requestType === 'R'" class="form-row" fxFlexFill>
          <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between">
            <span class="soe-form-small-header">Actions(check one)</span>
            <div class="hca-self-pay-checklist fxGap-medium-sm" fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px">
              <radio name="enrolling" [required]="requestType === 'R'" value="enrolling" [(ngModel)]="currentSubscriber.actionsCode">
                <b>Enrolling: </b>I am a new retiree or a surviving dependent requesting to enroll in coverage
              </radio>
              <radio name="deferring" [required]="requestType === 'R'" value="deferring" [(ngModel)]="currentSubscriber.actionsCode" (ngModelChange)="deferringChange()">
                <b>Deferring: </b> I am a new retiree or a surviving dependent deferring (postponing) my coverage. See the <a href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=51-0205&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_be" target="_blank">PEBB Retiree Enrollment Guide</a> or
                <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/what-deferring-and-why-would-i">What is deferring and why would I?</a> for details about deferring.
              </radio>
              <radio name="enrollAfterDefer" [required]="requestType === 'R'" value="enrollAfterDefer" [(ngModel)]="currentSubscriber.actionsCode">
                <b>Enrolling after deferring: </b> I am a retiree or a surviving dependent who is enrolling after having previously deferred. (You will need to provide proof of continuous enrollment in one or more qualifying coverages, with start and end dates.) 
              </radio>
              <div *ngIf="currentSubscriber.actionsCode === 'enrollAfterDefer'" fxLayout="row" fxLayoutAlign="start space-between">
                <date-picker
                  #ead="ngModel"
                  name="ead"
                  labelFlex="50"
                  inputFlex="50"
                  [required]="currentSubscriber.actionsCode === 'enrollAfterDefer'"
                  [(ngModel)]="currentSubscriber.enrollAfterDeferDateInitial">
                  <span aria-hidden="true">Date other qualifying coverage ended </span>
                </date-picker>
              </div>
              <radio name="seperating" [required]="requestType === 'R'" value="seperating" [(ngModel)]="currentSubscriber.actionsCode">
                <b>Separating: </b>I am separating from service and am eligible for retirement as a separated employee under Plan 2, Plan 3 or a higher-education retirement plan. 
              </radio>
              <div *ngIf="currentSubscriber.actionsCode === 'seperating'" fxLayout="row" fxLayoutAlign="start space-between">
                <date-picker
                  #sd="ngModel"
                  name="sd"
                  [required]="currentSubscriber.actionsCode === 'seperating'"
                  [(ngModel)]="currentSubscriber.seperatingDate">
                  <span aria-hidden="true">Separating as of date </span>
                </date-picker>
              </div>
              <!-- US 26977 -->
              <div fxLayout="row" fxFlex="60" fxLayoutAlign="start space-between" *ngIf="currentSubscriber.actionsCode !== 'deferring'">
                <date-picker
                  #covdt="ngModel"
                  name="covdt"
                  label="Requested coverage effective date"
                  labelFlex="50"
                  inputFlex="50"
                  [(ngModel)]="currentSubscriber.coverageEffectiveDate">
                </date-picker>
              </div>
              <div class="form-group" fxFlexFill fxFlex="100" *ngIf="currentSubscriber.actionsCode !== 'deferring'" fxLayout="row" fxLayoutAlign="start space-between">
                <div fxFlex="20">
                  <label style="margin-left: 0px;" for="retirementPlan">Washington State Retirement plan</label>
                </div>
                <div fxFlex="35">
                  <span>Not sure which plan you have? 
                    <br/>
                    Contact Department of Retirement Systems (DRS) or your higher education institution.
                  </span>
                </div>
                <div fxFlex="40" >
                  <select required class="form-control" name="retirementPlan" id="retirementPlan" [(ngModel)]="currentSubscriber.retirementPlan">
                    <option></option>
                    <option *ngFor="let retPlan of env.retirementPlans" [ngValue]="retPlan">{{ retPlan }}</option>
                  </select>
                </div>
              </div>     
              <div fxLayout="row" fxFlex="60" fxLayoutAlign="start space-between" *ngIf="currentSubscriber.actionsCode !== 'deferring'">
                <date-picker
                  #rtd="ngModel"
                  name="rtd"
                  label="Retirement date (or separation date for Plan 2 or 3, or higher-education retirement plans)"
                  labelFlex="50"
                  inputFlex="50"
                  [required]="true"
                  [(ngModel)]="currentSubscriber.retirementDate">
                </date-picker>
              </div>                       
            </div>
          </div>
        </div>
        <!-- Medicare section -->
        <div *ngIf="requestType !== 'U' && requestType !== 'OEU' && currentSubscriber.actionsCode !== 'deferring'"  class="form-row" fxFlexFill style="margin-bottom: 20px">
          <span class="soe-form-small-header">Medicare information</span>
          <span style="width: 100%; margin-bottom: 15px"><b>Are you enrolled in Medicare Part A or Part B?</b></span>
          <div fxLayout="row" fxFlexFill class="fxGap-large">
            <div fxLayout="column">
              <div>
                <label for="medicarePartA" class="k-label">Part A (hospital)</label>  
              </div>
              <yes-no
              name="medicarePartA"
              style="width: auto;"
              orientation="horizontal-list"
              [stretch]="false"
              [required]="requestType === 'R' || requestType === 'C'"
              [(ngModel)]="currentSubscriber.medicarePartAInd"
              (change)="updateTobaccoBasedOnMedicare()"
              ></yes-no>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <date-picker
                  #medicarePartADate="ngModel"
                  name="medicarePartADate"
                  labelFlex="70"
                  inputFlex="30"
                  [min]="currentSubscriber.birthDate"
                  [required]="currentSubscriber.medicarePartAInd"
                  [(ngModel)]="currentSubscriber.medicarePartADate">
                  <span style="white-space: nowrap; margin-right: 20px">If yes, enter the effective date shown on the Medicare card</span>
                </date-picker>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxFlexFill style="margin-top: 15px"  class="fxGap-large">
            <div fxLayout="column">
              <div>
                <label for="medicarePartB" class="k-label">Part B (medical)</label>
              </div>
              <yes-no
                name="medicarePartB"
                style="width: auto;"
                orientation="horizontal-list"
                [stretch]="false"
                [required]="requestType === 'R' || requestType === 'C'"
                [(ngModel)]="currentSubscriber.medicarePartBInd"
                (change)="updateTobaccoBasedOnMedicare()"
                ></yes-no>
            </div>
            <div class="form-inline">
              <date-picker
                #medicarePartBDate="ngModel"
                name="medicarePartBDate"
                labelFlex="70"
                inputFlex="30"
                [min]="currentSubscriber.birthDate"
                [required]="currentSubscriber.medicarePartBInd"
                [(ngModel)]="currentSubscriber.medicarePartBDate">
                <span style="white-space: nowrap; margin-right: 20px">If yes, enter the effective date shown on the Medicare card</span>
              </date-picker>
            </div>
          </div>
          <div class="form-group" fxFill>
            <label for="suffix">Medicare number (11 digits)</label>
            <input pattern="[a-zA-Z0-9]*" type="text" class="form-control" id="medicareNumber" name="medicareNumber" [(ngModel)]="currentSubscriber.medicareNumber" minlength="11" maxlength="11" />
          </div>          
          <p *ngIf="requestType === 'R'">
            If Yes, proof is required. Upload or mail a copy of all pages of your Medicare benefit verification letter or a copy of your Medicare card if we don't already have a copy. If mailing, write your last name and
            the last four digits of your Social Security Number on the copy so we can identify your account. If you are eligible for Medicare, you must enroll and stay enrolled in both Medicare Part A
            and Part B to keep PEBB retiree health plan coverage.
          </p>
          <div>
            <span>
              I am in the process of enrolling in Medicare Part A and Part B. I will submit proof after I receive my Medicare benefit verification letter or Medicare card. 
              <b>You will not be enrolled until your proof of Medicare is received</b>.
            </span>
            <yes-no
            name="enrollingInMedicare"
            style="width: auto;"
            orientation="horizontal-list"
            [stretch]="false"
            [(ngModel)]="currentSubscriber.enrollingInMedicare"
            ></yes-no>
          </div>
          <div *ngIf="requestType === 'R'" fxFlexFill style="margin-bottom: 20px">
            <div><b>Are you enrolled in Medicare Part D (prescription drug coverage)?</b></div>
            <div fxLayout="row" style="margin-top: 20px" class="fxGap-large">
              <div>
                <yes-no
                  name="medicarePartD"
                  style="width: auto;"
                  orientation="horizontal-list"
                  [stretch]="false"
                  [required]="requestType === 'R' || requestType === 'C'"
                  [(ngModel)]="currentSubscriber.medicarePartDInd"
                  ></yes-no>
              </div>
              <div>
                <date-picker
                  #medicarePartDDate="ngModel"
                  name="medicarePartDDate"
                  [required]="currentSubscriber.medicarePartDInd"
                  [(ngModel)]="currentSubscriber.medicarePartDDate">
                  <span class="col-form-label">If yes, effective date</span>
                </date-picker>
              </div>
            </div>
            <p style="margin-top: 20px">
              If Yes, you may enroll only in Premera Blue Cross Medicare Supplement Plan G. Plan F enrollees may
              stay in the plan.  If you want to enroll in any other PEBB medical plan, you must disenroll from your Part D plan.
            </p>
            <div><b>Are you enrolled in Medicaid with Medicare Part D?</b></div>
            <div fxLayout="row" style="margin-top: 20px" class="fxGap-large">
              <div>
                <yes-no
                  name="medicaidMedicarePartDInd"
                  style="width: auto;"
                  orientation="horizontal-list"
                  [stretch]="false"
                  [required]="requestType === 'R' || requestType === 'C'"
                  [(ngModel)]="currentSubscriber.medicaidMedicarePartDInd"
                ></yes-no>
              </div>
              <div>
                <date-picker
                  #medicarePartDDateMedicaid="ngModel"
                  name="medicarePartDDateMedicaid"
                  [required]="currentSubscriber.medicaidMedicarePartDInd"
                  [(ngModel)]="currentSubscriber.medicaidMedicarePartDDate">
                  <span class="col-form-label">If yes, effective date</span>
                </date-picker>
              </div>
            </div>
          </div>
        </div>
        <!-- I want to section -->
        <div *ngIf="requestType === 'R' || requestType === 'OER'" class="form-row" fxFlexFill style="margin-bottom: 20px">
          <span class="soe-form-small-header">I want to</span>
          <span style="margin-bottom: 20px" *ngIf="currentSubscriber.actionsCode !== 'deferring'"><b>Enroll in</b></span>
          <div fxLayout="row" fxFlex="100" *ngIf="currentSubscriber.actionsCode !== 'deferring'" fxLayoutAlign="start center" style="margin-bottom: 20px" 
            class="fxGap-medium-sm">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox
                name="medicalEnrollInd"
                [(ngModel)]="currentSubscriber.medicalEnrollInd"
                [disabled]="currentSubscriber.actionsCode === 'deferring'"
                (ngModelChange)="currentSubscriber.enrollAfterDeferalInd = currentSubscriber.deferCoverageInd = false">Medical</checkbox>
            </div>
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox name="dentalEnrollInd" 
              [(ngModel)]="currentSubscriber.dentalEnrollInd"
              [disabled]="currentSubscriber.actionsCode === 'deferring'">Medical and dental</checkbox>
            </div>
            <div class="k-form-field bigger" *ngIf="requestType !== 'OER'" fxFlex="40">
              <checkbox name="retireeTermLifeInd" [(ngModel)]="currentSubscriber.retireeTermLifeInd">Retiree term life insurance</checkbox>
            </div>
          </div>
          <span style="margin-bottom: 20px"><b>Defer</b> Defer (postpone) my coverage. Except as stated below, this defers coverage for all eligible dependents </span>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" class="fxGap-medium-sm" style="margin-bottom: 20px">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox
                name="deferCoverageInd"
                [disabled]="currentSubscriber.actionsCode === 'deferring'"
                [(ngModel)]="currentSubscriber.deferCoverageInd"
                (ngModelChange)="updateCoverageDefer($event)">Defer</checkbox>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <date-picker
                  #deferCoverageDate="ngModel"
                  name="deferCoverageDate"
                  [required]="currentSubscriber.deferCoverageInd"
                  [(ngModel)]="currentSubscriber.deferCoverageDate">
                  <span style="white-space: nowrap; margin-right: 20px">Deferral date</span>
                </date-picker>
              </div>
            </div>
          </div>
          <div>Select your reason for deferring coverage, below.</div>
          <span style="margin-bottom: 20px" *ngIf="currentSubscriber.actionsCode !== 'deferring'"
            ><b>Enroll after deferring coverage:</b> When you later enroll, you must provide proof of continuous enrollment in one or more qualifying coverages (with start and end dates). A gap of 31 days or less is allowed between the date PEBB retiree coverage is deferred and the start date of a qualifying coverage, and between each qualifying coverage during the deferral period. 
          </span>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" class="fxGap-medium-sm" style="margin-bottom: 20px"
            *ngIf="currentSubscriber.actionsCode !== 'deferring'">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox
                name="enrollAfterDeferalInd"
                [(ngModel)]="currentSubscriber.enrollAfterDeferalInd"
                [disabled]="currentSubscriber.deferCoverageInd || !currentSubscriber.medicalEnrollInd"
                (ngModelChange)="updateEnrollAfterDefer($event)">Enroll after deferring</checkbox>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <date-picker
                  #enrollAfterDeferralDate="ngModel"
                  name="enrollAfterDeferralDate"
                  [required]="currentSubscriber.enrollAfterDeferalInd"
                  [(ngModel)]="currentSubscriber.enrollAfterDeferalDate">
                  <span style="white-space: nowrap; margin-right: 20px">Date other qualifying coverage ended:</span>
                </date-picker>
              </div>
            </div>
          </div>
          <span style="margin-bottom: 20px">
            <b>If deferring or enrolling after deferring, check all the boxes below that apply to you.</b>
          </span>
          <div class="hca-self-pay-checklist fxGap-medium-sm" fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px">
            <div class="k-form-field bigger">
              <checkbox name="deferQuestionOne" [(ngModel)]="currentSubscriber.deferQuestionOne">
                Enrolled as a dependent in a health plan sponsored by the PEBB Program or the School Employees Benefits Board (SEBB) Program. This
                includes coverage under COBRA or continuation coverage.
              </checkbox>
            </div>
            <div class="k-form-field bigger">
              <checkbox name="deferQuestionTwo" [(ngModel)]="currentSubscriber.deferQuestionTwo">
                Enrolled in employer-based group medical as an employee or employee's dependent, including medical insurance continued under COBRA or continuation coverage. <b>This does not include an
                employer's retiree coverage</b>.
              </checkbox>
            </div>
            <div class="k-form-field bigger">
              <checkbox name="deferQuestionThree" [(ngModel)]="currentSubscriber.deferQuestionThree">
                Enrolled in medical coverage as a retiree or dependent of a retiree in a TRICARE plan or the Federal Employees Health Benefits Program. You have a one-time opportunity to enroll in a
                PEBB retiree health plan.
              </checkbox>
            </div>
            <div class="k-form-field bigger">
              <checkbox name="deferQuestionFour" [(ngModel)]="currentSubscriber.deferQuestionFour">
                Enrolled in a Medicaid program that provides creditable coverage and in Medicare Part A and Part B. You may continue to cover eligible dependents who are not eligible for creditable
                coverage under Medicaid.
              </checkbox>
            </div>
            <div class="k-form-field bigger">
              <checkbox name="deferQuestionFive" [(ngModel)]="currentSubscriber.deferQuestionFive">
                Enrolled in the Civilian Health and Medical Program of the Department of Veterans Affairs (CHAMPVA). You have a one-time opportunity to enroll in a PEBB retiree health plan.
              </checkbox>
            </div>
            <div class="k-form-field bigger">
              <checkbox name="deferQuestionSix" [(ngModel)]="currentSubscriber.deferQuestionSix">
                <b>Non-Medicare subscribers only:</b> Enrolled in qualified health plan coverage through a health benefit exchange established under the Affordable Care Act. This does not include Medicaid
                (called Apple Health in Washington State). You have a one-time opportunity to enroll or reenroll in a PEBB retiree health plan.
              </checkbox>
            </div>
          </div>
        </div>
        <!-- Retiree Life Insurance section -->
        <div *ngIf="requestType === 'R'" class="form-row" fxFlexFill>
          <span class="soe-form-small-header">Retiree term life insurance</span>
          <p>
            Retiree term life insurance is available only if you received PEBB life insurance as an employee or SEBB life insurance as a school employee. You are not eligible 
            for the retiree term life insurance plan if you qualify for a waiver of premium benefit under the PEBB employee life insurance or SEBB employee life insurance 
            plans until that waiver of premium benefit ends. To apply for retiree term life insurance, upload the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=MetLife+Retiree+Enrollment%2FChange+form&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_bef_combine=name_ASC">PEBB MetLife Enrollment/Change Form for Retiree Plan</a> (including beneficiary designation). 
          </p>
          <div fxFlexFill *ngIf="requestType !== 'OER'" class="k-form-field bigger">
            <checkbox name="retireeTermLifeAcknowledgeInd" [(ngModel)]="currentSubscriber.retireeTermLifeAcknowledgeInd">
              I acknowledge that I will upload the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=MetLife+Retiree+Enrollment%2FChange+form&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_bef_combine=name_ASC">PEBB MetLife Enrollment/Change form for Retirees</a>
            </checkbox>
          </div>
        </div>
        <!-- Premium Surcharge section -->
        <div class="form-row" fxLayout="row" fxFlexFill *ngIf="currentSubscriber.actionsCode !== 'deferring'">
          <span class="soe-form-small-header">Tobacco use premium surcharge</span>
          <p>
            Response required if you are enrolling in medical coverage. The {{agency}} Program requires a $25-per-account premium surcharge in addition to your monthly medical premium if you or an enrolled
            dependent (age 13 and older) <span *ngIf="agency === 'SEBB'">enrolled on your SEBB medical coverage </span>
            uses a tobacco product. Tobacco use is defined as any use of tobacco products within the past two months except for religious or ceremonial use.
          </p>
          <p>
            Tobacco products are any product made with or derived from tobacco that is intended for human consumption, including any component, part, or accessory of a tobacco product. This includes, but is not limited to, cigars, cigarettes, pipe tobacco, chewing tobacco, snuff, and other tobacco products. Tobacco products do not include e-cigarettes or United States Food and Drug Administration (FDA) approved quit aids, such as all over-the-counter nicotine replacement products if recommended by a doctor, or prescription nicotine replacement products. 
          </p>
          <p *ngIf="agency === 'PEBB'">
            If a provider finds that ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information in
            the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-rules-and-policies">{{agency}} Program Administrative Policy 91-1</a>.
          </p>
          <p *ngIf="agency == 'SEBB'">
            If a provider finds that ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information in
            the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-rules-and-policies">{{agency}} Program Administrative Policy 91-1</a>.
          </p>          
          <p>
            The premium surcharge will not apply if you and any enrolled dependents who use tobacco products meet these requirements: If you check No, enrolled in the free tobacco cessation program through your PEBB medical plan (age 18 and older), or accessed resources for teens on Smokefree Teen’s website.
          </p>
          <p>
            <b>If you check Yes or leave this section blank, you will be charged the $25 premium surcharge. </b>
            <a *ngIf="agency === 'PEBB'" target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/tobacco-use-surcharge' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/tobacco-use-surcharge'">Learn more about this surcharge before you attest.</a>
            <a *ngIf="agency === 'SEBB'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/surcharges">Learn more about this surcharge before you attest.</a>
          </p>
          <span style="margin-bottom: 20px"> <b>Does the tobacco use premium surcharge apply to you?</b> Check one: </span>
          <div class="form-row" fxFlexFill>
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between">
              <div class="hca-self-pay-checklist fxGap-medium-sm" fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px">
                <radio *ngIf="requestType !== 'U' && requestType !== 'OEU' && requestType != 'SOEU' && (!isCobra || agency != 'SEBB')" name="tobaccoUseFormInd" value="NA" [(ngModel)]="currentSubscriber.tobaccoUseFormInd">
                  No, I am enrolled in Medicare Part A and Part B. The premium surcharge does not apply. 
                </radio>
                <radio name="tobaccoUseFormIndYes" value="Yes" [(ngModel)]="currentSubscriber.tobaccoUseFormInd" [disabled]="currentSubscriber.medicarePartAInd && currentSubscriber.medicarePartBInd">
                  Yes, I am subject to the $25 premium surcharge. I have used tobacco products in the past two months. 
                </radio>
                <radio name="tobaccoUseFormIndNo" value="No" [(ngModel)]="currentSubscriber.tobaccoUseFormInd" [disabled]="currentSubscriber.medicarePartAInd && currentSubscriber.medicarePartBInd">
                 No, I am not subject to the $25 premium surcharge. I have not used tobacco products in the past two months, or I have enrolled in my {{agency}} medical plan’s <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/living-tobacco-free-pebb">tobacco cessation program</a>.
                </radio>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around">
          <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
          <button (click)="pushContinue(formContainer)" class="btn btn-primary">Proceed to dependent form</button>
        </div>
      </fieldset>
    </form>
  </div>
</div>

<!-- VISION/MED D RETIREE & COBRA-->

<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column" *ngIf="currentSubscriber && (isRetireeOE || isCobraOE || (requestType === 'SOER' && minVisionDate))">
  <div class="card-body">
    <h3>Subscriber</h3>
    <form nativeControlElement #selfPaySubscriberForm="ngForm" #formContainer>
      <fieldset [disabled]="isPerspay">
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div class="form-group" fxFlex="30">
            <label for="socialSecurityNumber" id="ssnLabel">Social Security number</label>
            <kendo-maskedtextbox
              [mask]="env.ssnMask"
              [pattern]="env.ssnItinRegex"
              [(ngModel)]="currentSubscriber.socialSecurityNumber"
              [maskOnFocus]="true"
              name="socialSecurityNumber"
              aria-labelledby="ssnLabel"
              #ssn="ngModel"
              class="form-control"
              [forbiddenValues]="env.invalidSSNValues"
            ></kendo-maskedtextbox>
          </div>
          <div class="form-group" fxFlex="25">
            <date-picker
              #birthDate="ngModel"
              name="birthDate"
              label="Date of birth"
              required
              [(ngModel)]="currentSubscriber.birthDate"></date-picker>
          </div>
          <div class="form-group" fxFlex="25">
            <label for="birthSexId">Sex assigned at birth</label>
            <select class="form-control" name="birthSexId" id="birthSexId" [(ngModel)]="currentSubscriber.birthSexId" required>
              <option></option>
              <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
            </select>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div class="form-group" fxFlex="70">
            <label for="lastName">Last name</label>
            <input required type="text" class="form-control" name="lastName" id="lastName" [(ngModel)]="currentSubscriber.lastName" />
          </div>
          <div class="form-group required" fxFlex="20">
            <label for="genderIdentityId">Gender Identity</label>
            <select class="form-control" id="genderIdentityId" name="genderIdentityId" [(ngModel)]="currentSubscriber.genderIdentityId" required>
              <option></option>
              <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
            </select>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div class="form-group" fxFlex="40">
            <label for="firstName">First name</label>
            <input required type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="currentSubscriber.firstName" />
          </div>
          <div class="form-group" fxFlex="30">
            <label for="middleName">Middle name</label>
            <input type="text" class="form-control" maxLength="15" id="middleName" name="middleName" [(ngModel)]="currentSubscriber.middleName" />
          </div>
          <div class="form-group" fxFlex="20">
            <span class="hca-soe-small-note" fxFlex="100">
              Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law.
            </span>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayout.lt-md="column">
          <div fxLayout="column" fxFlex="30">            
          </div>
          <div [hidden]="!currentSubscriber.isRepresentedInd" class="form-group" fxFlex="25">
            <date-picker
              #representationDate="ngModel"
              name="representationDate"
              label="Representation date"
              [required]="currentSubscriber.isRepresentedInd"
              [(ngModel)]="currentSubscriber.representedDate"></date-picker>
          </div>
          <div class="form-group" fxFlex="10">
            <label for="suffix">Suffix</label>
            <input pattern="[a-zA-Z]*" type="text" class="form-control" id="suffix" name="suffix" [(ngModel)]="currentSubscriber.suffix" placeholder="JR, SR" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div fxFlex="40" class="form-group">
            <label for="homePhoneNumber">Phone number</label>
            <input pattern="[0-9]*" type="text" class="form-control" id="homePhoneNumber" name="homePhoneNumber" [(ngModel)]="currentSubscriber.homePhoneNumber" />
          </div>
          <div fxFlex="40" class="form-group">
            <label for="workPhoneNumber">Alternate phone number</label>
            <input type="text" class="form-control" id="workPhoneNumber" name="workPhoneNumber" [(ngModel)]="currentSubscriber.workPhoneNumber" />
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxFlexFill>
          <h4 style="margin-bottom: 0px">Residential address</h4>
        </div>
        <div class="form-row" fxLayout="row" fxFlexFill>
          <h4>
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faExclamationCircle"></fa-icon>
            <span class="h4" *ngIf="agency !== 'SEBB'">If you move, you</span><span *ngIf="agency === 'SEBB'">You</span>
             must report your new address to the {{agency}} Program <b>no later than 60 days</b> after you move.
          </h4>
        </div>

        <address #residentialAddress view="inline" name="residentialAddress" [(ngModel)]="currentSubscriber.formResidentialAddress"></address>
        <div>
          Enrollment in Medicare Advantage Plans (MAPD) or Medicare Part D has specific requirements associated with residential addresses. 
          PO boxes and out-of-country addresses may not be eligible for MAPD/Part D enrollment and will need to be reviewed by HCA for eligibility. 
          Proof of residence may need to be provided.
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" style="margin-bottom: 20px">
          <checkbox name="mailingSame" bold [(ngModel)]="selfPay.formJson.mailingAddressSameAsResidential">Mailing address same as residential</checkbox>
        </div>
        <div *ngIf="!selfPay.formJson.mailingAddressSameAsResidential">
          <h4>Mailing Address</h4>
          <address #mailingAddress view="inline" name="mailingAddress" [(ngModel)]="currentSubscriber.mailingAddress"></address>
        </div>
        <!-- Medicare section -->
        <div *ngIf="requestType !== 'U' && requestType !== 'OEU' && currentSubscriber.actionsCode !== 'deferring'"  class="form-row" fxFlexFill style="margin-bottom: 20px">
          <span class="soe-form-small-header">Medicare information</span>
          <span style="width: 100%; margin-bottom: 15px"><b>Are you enrolled in Medicare Part A or Part B?</b></span>
          <div fxLayout="row" fxFlexFill class="fxGap-large">
            <div fxLayout="column">
              <div>
                <label for="medicarePartA" class="k-label">Part A (hospital)</label>  
              </div>
              <yes-no
              name="medicarePartA"
              style="width: auto;"
              orientation="horizontal-list"
              [stretch]="false"
              [required]="requestType === 'R' || requestType === 'C'"
              [(ngModel)]="currentSubscriber.medicarePartAInd"
              (ngModelChange)="updateMedicarePartA($event)"
              ></yes-no>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <date-picker
                  #medicarePartADate="ngModel"
                  name="medicarePartADate"
                  labelFlex="70"
                  inputFlex="30"
                  [min]="currentSubscriber.birthDate"
                  [required]="currentSubscriber.medicarePartAInd"
                  [(ngModel)]="currentSubscriber.medicarePartADate">
                  <span style="white-space: nowrap; margin-right: 20px">If yes, enter the effective date shown on the Medicare card</span>
                </date-picker>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxFlexFill style="margin-top: 15px"  class="fxGap-large">
            <div fxLayout="column">
              <div>
                <label for="medicarePartB" class="k-label">Part B (medical)</label>
              </div>
              <yes-no
                name="medicarePartB"
                style="width: auto;"
                orientation="horizontal-list"
                [stretch]="false"
                [required]="requestType === 'R' || requestType === 'C'"
                [(ngModel)]="currentSubscriber.medicarePartBInd"
                (ngModelChange)="updateMedicarePartB($event)"
                ></yes-no>
            </div>
            <div class="form-inline">
              <date-picker
                #medicarePartBDate="ngModel"
                name="medicarePartBDate"
                labelFlex="70"
                inputFlex="30"
                [min]="currentSubscriber.birthDate"
                [required]="currentSubscriber.medicarePartBInd"
                [(ngModel)]="currentSubscriber.medicarePartBDate">
                <span style="white-space: nowrap; margin-right: 20px">If yes, enter the effective date shown on the Medicare card</span>
              </date-picker>
            </div>
          </div>
          <div class="form-group" fxFill>
            <label for="suffix">Medicare number (11 digits)</label> <b>      Please verify the accuracy of your Medicare Beneficiary Number (MBI)</b>
            <input pattern="[a-zA-Z0-9]*" type="text" class="form-control" id="medicareNumber" name="medicareNumber" [(ngModel)]="currentSubscriber.medicareNumber" minlength="11" maxlength="11" />
          </div>          
          <p>
            If Yes, proof is required. Upload or mail a copy of all pages of your Medicare benefit verification letter or a copy of your Medicare card if we don't already have a copy. If mailing, write your last name and
            the last four digits of your Social Security Number on the copy so we can identify your account. If you are eligible for Medicare, you must enroll and stay enrolled in both Medicare Part A
            and Part B to keep PEBB retiree health plan coverage.
          </p>
        </div>
        <!-- I want to section -->
        <div class="form-row" fxFlexFill style="margin-bottom: 20px">
          <span class="soe-form-small-header">I want to</span>
          <span style="margin-bottom: 20px" *ngIf="currentSubscriber.actionsCode !== 'deferring'"><b>Enroll in (or remain enrolled)</b></span>
          <div fxLayout="row" fxFlex="100" *ngIf="currentSubscriber.actionsCode !== 'deferring'" fxLayoutAlign="start center" style="margin-bottom: 20px" 
            class="fxGap-medium-sm">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox
                name="medicalEnrollInd"
                [(ngModel)]="currentSubscriber.medicalEnrollInd"
                (ngModelChange)="handleMedicalEnrollChange($event)">Medical</checkbox>
            </div>
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox name="dentalEnrollInd" 
              [(ngModel)]="currentSubscriber.dentalEnrollInd"
              (ngModelChange)="handleDentalEnrollChange($event)"
              [disabled]="currentSubscriber.actionsCode === 'deferring' || (!currentSubscriber.medicalEnrollInd && isRetireeOE)">Dental</checkbox>
            </div>
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox name="visionEnrollInd" 
              [(ngModel)]="currentSubscriber.visionEnrollInd"
              (ngModelChange)="handleVisionEnrollChange($event)"
              [disabled]="currentSubscriber.actionsCode === 'deferring' || (!currentSubscriber.medicalEnrollInd && isRetireeOE) || currentSubscriber.medicarePartBInd">Vision</checkbox>
            </div>            
          </div>
          <div style="margin-bottom: 20px" >
            <b *ngIf="isRetireeOE">Enrollment in medical coverage is required to add dental or vision coverage. Members who indicated they are enrolled in Medicare Parts A and B cannot request vision coverage as this is included in Medicare benefits. Change your Medicare A or B responses to request enrollment in vision coverage if entered incorrectly. You can remove your vision/dental coverage enrollment/request by uncehcking the correct benefit.</b>
            <b *ngIf="isCobraOE">Members who indicated they are enrolled in Medicare Parts A and B cannot request vision coverage as this is included in Medicare Health benefits.  Change your Medicare A or B responses to request enrollment in vision coverage if entered incorrectly.  Elect the coverage(s) you with to enroll in or remain enrolled in.  If you wish to terminate an existing coverage, deselect that coverage and it will be ended at the end of the current plan/calendar year</b>
          </div>
          <ng-container *ngIf="isRetireeOE">

          <span style="margin-bottom: 20px"><b>Defer</b> Defer (postpone) my coverage. Except as stated below, this defers coverage for all eligible dependents </span>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" class="fxGap-medium-sm" style="margin-bottom: 20px">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox
                name="deferCoverageInd"
                [disabled]="currentSubscriber.actionsCode === 'deferring'"
                [(ngModel)]="currentSubscriber.deferCoverageInd"
                (ngModelChange)="updateCoverageDefer()">Defer</checkbox>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <date-picker
                  #deferCoverageDate="ngModel"
                  name="deferCoverageDate"
                  [required]="currentSubscriber.deferCoverageInd"
                  [(ngModel)]="currentSubscriber.deferCoverageDate">
                  <span style="white-space: nowrap; margin-right: 20px">Deferral date</span>
                </date-picker>
              </div>
            </div>
          </div>
          <div>Select your reason for deferring coverage, below.</div>
          <span style="margin-bottom: 20px" *ngIf="currentSubscriber.actionsCode !== 'deferring'"
            ><b>Enroll after deferring coverage:</b> When you later enroll, you must provide proof of continuous enrollment in one or more qualifying coverages (with start and end dates). A gap of 31 days or less is allowed between the date PEBB retiree coverage is deferred and the start date of a qualifying coverage, and between each qualifying coverage during the deferral period. 
          </span>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" class="fxGap-medium-sm" style="margin-bottom: 20px"
            *ngIf="currentSubscriber.actionsCode !== 'deferring'">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox
                name="enrollAfterDeferalInd"
                [(ngModel)]="currentSubscriber.enrollAfterDeferalInd"
                [disabled]="currentSubscriber.deferCoverageInd || !currentSubscriber.medicalEnrollInd"
                (ngModelChange)="updateEnrollAfterDefer($event)">Enroll after deferring</checkbox>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <date-picker
                  #enrollAfterDeferralDate="ngModel"
                  name="enrollAfterDeferralDate"
                  [required]="currentSubscriber.enrollAfterDeferalInd"
                  [disabled]="currentSubscriber.deferCoverageInd || !currentSubscriber.medicalEnrollInd"
                  [(ngModel)]="currentSubscriber.enrollAfterDeferalDate">
                  <span style="white-space: nowrap; margin-right: 20px">Date other qualifying coverage ended:</span>
                </date-picker>
              </div>
            </div>
          </div>
          </ng-container>
          <div fxLayout="row" fxFlex="100" fxLayoutAlign="start center" class="fxGap-medium-sm" style="margin-bottom: 20px">
            <div class="k-form-field bigger" fxFlex="30">
              <checkbox
                name="terminateCoverageInd"
                [disabled]="currentSubscriber.actionsCode === 'deferring'"
                [(ngModel)]="currentSubscriber.terminateAllCoverageInd"
                (ngModelChange)="terminateAllCoveragesChange($event)">Terminate all coverage(s)</checkbox>
            </div>
            <div class="form-inline">
              If selecting to terminate all coverage(s), your coverage will terminate at the end of the current calendar/plan year.
              Termination from a Medicare Advantage plan (MA plan) or Medicare Advantage Prescription Drug plan (MAPD plan) may require and additional form:
              <a target="_blank" href="https://www.hca.wa.gov/assets/pebb/51-0556-medicare-advantage-disenrollment-form-d-2024.pdf">Form D</a>
            </div>
          </div>
          <ng-container *ngIf="isRetireeOE">
            <span style="margin-bottom: 20px">
              <b>If deferring or enrolling after deferring, check all the boxes below that apply to you.</b>
            </span>
            <div class="hca-self-pay-checklist fxGap-medium-sm" fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px">
              <div class="k-form-field bigger">
                <checkbox name="deferQuestionOne" [disabled]="currentSubscriber.terminateAllCoverageInd" [(ngModel)]="currentSubscriber.deferQuestionOne">
                  Enrolled as a dependent in a health plan sponsored by the PEBB Program or the School Employees Benefits Board (SEBB) Program. This
                  includes coverage under COBRA or continuation coverage.
                </checkbox>
              </div>
              <div class="k-form-field bigger">
                <checkbox name="deferQuestionTwo" [disabled]="currentSubscriber.terminateAllCoverageInd"  [(ngModel)]="currentSubscriber.deferQuestionTwo">
                  Enrolled in employer-based group medical as an employee or employee's dependent, including medical insurance continued under COBRA or continuation coverage. <b>This does not include an
                  employer's retiree coverage</b>.
                </checkbox>
              </div>
              <div class="k-form-field bigger">
                <checkbox name="deferQuestionThree" [disabled]="currentSubscriber.terminateAllCoverageInd"  [(ngModel)]="currentSubscriber.deferQuestionThree">
                  Enrolled in medical coverage as a retiree or dependent of a retiree in a TRICARE plan or the Federal Employees Health Benefits Program. You have a one-time opportunity to enroll in a
                  PEBB retiree health plan.
                </checkbox>
              </div>
              <div class="k-form-field bigger">
                <checkbox name="deferQuestionFour" [disabled]="currentSubscriber.terminateAllCoverageInd"  [(ngModel)]="currentSubscriber.deferQuestionFour">
                  Enrolled in a Medicaid program that provides creditable coverage and in Medicare Part A and Part B. You may continue to cover eligible dependents who are not eligible for creditable
                  coverage under Medicaid.
                </checkbox>
              </div>
              <div class="k-form-field bigger">
                <checkbox name="deferQuestionFive" [disabled]="currentSubscriber.terminateAllCoverageInd"  [(ngModel)]="currentSubscriber.deferQuestionFive">
                  Enrolled in the Civilian Health and Medical Program of the Department of Veterans Affairs (CHAMPVA). You have a one-time opportunity to enroll in a PEBB retiree health plan.
                </checkbox>
              </div>
              <div class="k-form-field bigger">
                <checkbox name="deferQuestionSix" [disabled]="currentSubscriber.terminateAllCoverageInd" [(ngModel)]="currentSubscriber.deferQuestionSix">
                  <b>Non-Medicare subscribers only:</b> Enrolled in qualified health plan coverage through a health benefit exchange established under the Affordable Care Act. This does not include Medicaid
                  (called Apple Health in Washington State). You have a one-time opportunity to enroll or reenroll in a PEBB retiree health plan.
                </checkbox>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Retiree Life Insurance section -->
        <!-- <div *ngIf="requestType === 'R'" class="form-row" fxFlexFill>
          <span class="soe-form-small-header">Retiree term life insurance</span>
          <p>
            Retiree term life insurance is available only if you received PEBB life insurance as an employee or SEBB life insurance as a school employee. You are not eligible 
            for the retiree term life insurance plan if you qualify for a waiver of premium benefit under the PEBB employee life insurance or SEBB employee life insurance 
            plans until that waiver of premium benefit ends. To apply for retiree term life insurance, upload the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=MetLife+Retiree+Enrollment%2FChange+form&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_bef_combine=name_ASC">PEBB MetLife Enrollment/Change Form for Retiree Plan</a> (including beneficiary designation). 
          </p>
          <div fxFlexFill *ngIf="requestType !== 'OER'" class="k-form-field bigger">
            <checkbox name="retireeTermLifeAcknowledgeInd" [(ngModel)]="currentSubscriber.retireeTermLifeAcknowledgeInd">
              I acknowledge that I will upload the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=MetLife+Retiree+Enrollment%2FChange+form&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_bef_combine=name_ASC">PEBB MetLife Enrollment/Change form for Retirees</a>
            </checkbox>
          </div>
        </div> -->
        <!-- Premium Surcharge section -->
        <div class="form-row" fxLayout="row" fxFlexFill *ngIf="currentSubscriber.actionsCode !== 'deferring'">
          <span class="soe-form-small-header">Tobacco use premium surcharge</span>
          <p>
            Response required if you are enrolling in medical coverage. The {{agency}} Program requires a $25-per-account premium surcharge in addition to your monthly medical premium if you or an enrolled
            dependent (age 13 and older) <span *ngIf="agency === 'SEBB'">enrolled on your SEBB medical coverage </span>
            uses a tobacco product. Tobacco use is defined as any use of tobacco products within the past two months except for religious or ceremonial use.
          </p>
          <p>
            Tobacco products are any product made with or derived from tobacco that is intended for human consumption, including any component, part, or accessory of a tobacco product. This includes, but is not limited to, cigars, cigarettes, pipe tobacco, chewing tobacco, snuff, and other tobacco products. Tobacco products do not include e-cigarettes or United States Food and Drug Administration (FDA) approved quit aids, such as all over-the-counter nicotine replacement products if recommended by a doctor, or prescription nicotine replacement products. 
          </p>
          <p *ngIf="agency === 'PEBB'">
            If a provider finds that ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information in
            the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-rules-and-policies">{{agency}} Program Administrative Policy 91-1</a>.
          </p>
          <p *ngIf="agency == 'SEBB'">
            If a provider finds that ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information in
            the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-rules-and-policies">{{agency}} Program Administrative Policy 91-1</a>.
          </p>          
          <p>
            The premium surcharge will not apply if you and any enrolled dependents who use tobacco products meet these requirements: If you check No, enrolled in the free tobacco cessation program through your PEBB medical plan (age 18 and older), or accessed resources for teens on Smokefree Teen’s website.
          </p>
          <p>
            <b>If you check Yes or leave this section blank, you will be charged the $25 premium surcharge. </b>
            <a *ngIf="agency === 'PEBB'" target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/tobacco-use-surcharge' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/tobacco-use-surcharge'">Learn more about this surcharge before you attest.</a>
            <a *ngIf="agency === 'SEBB'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/surcharges">Learn more about this surcharge before you attest.</a>
          </p>
          <span style="margin-bottom: 20px"> <b>Does the tobacco use premium surcharge apply to you?</b> Check one: </span>
          <div class="form-row" fxFlexFill>
            <div fxLayout="column" fxFlex="100" fxLayoutAlign="space-between">
              <div class="hca-self-pay-checklist fxGap-medium-sm" fxLayout="column" fxLayoutAlign="space-between start" style="margin-bottom: 20px">
                <radio *ngIf="requestType !== 'U' && requestType !== 'OEU' && requestType != 'SOEU' && (!isCobra || agency != 'SEBB')" name="tobaccoUseFormInd" value="NA" [(ngModel)]="currentSubscriber.tobaccoUseFormInd">
                  No, I am enrolled in Medicare Part A and Part B. The premium surcharge does not apply. 
                </radio>
                <radio name="tobaccoUseFormIndYes" value="Yes" [(ngModel)]="currentSubscriber.tobaccoUseFormInd" [disabled]="currentSubscriber.medicarePartAInd && currentSubscriber.medicarePartBInd">
                  Yes, I am subject to the $25 premium surcharge. I have used tobacco products in the past two months. 
                </radio>
                <radio name="tobaccoUseFormIndNo" value="No" [(ngModel)]="currentSubscriber.tobaccoUseFormInd" [disabled]="currentSubscriber.medicarePartAInd && currentSubscriber.medicarePartBInd">
                 No, I am not subject to the $25 premium surcharge. I have not used tobacco products in the past two months, or I have enrolled in my {{agency}} medical plan’s <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/living-tobacco-free-pebb">tobacco cessation program</a>.
                </radio>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around">
          <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
          <button (click)="pushContinue(formContainer)" class="btn btn-primary">Proceed to dependent form</button>
        </div>
      </fieldset>
    </form>
  </div>
</div>

