<div [style]="style(visible)" [class]="containerClass">
  <kendo-pdf-export #pdfElement [paperSize]="paperSize" [landscape]="landscape" [margin]="margin" [scale]=".8" forcePageBreak=".page-break">
    <div fxLayout="column">
      <ng-template kendoPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <div class="hca-pdf-header" *ngIf="showHeader">
          <div fxLayout="row" fxLayoutAlign="space-between end">
            <img [src]="healthCareAuthorityImage" style="width: 211px; height: 45px;" class="hca-pdf-export-logo" />
            <div fxFlex></div>
            <div fxFlex="noshrink"><h5 class="hca-pdf-export-title">{{documentTitleText}}</h5></div>
          </div>
          <div style="margin: 10px 45px 0px 0px; padding-right: 120px;" class="hca-pdf-table-subheader pad-sm-container">
            {{documentHeaderText}}
          </div>
        </div>
        <div class="hca-pdf-footer" style="margin: 10px 45px 0px 0px; width: 100%; padding-right: 140px;" *ngIf="showFooter">
          <div class="mgbt-medium" style="padding-right: 110px;">
              {{documentFooterText}}
          </div>
          <div style="text-align: right; width: 100%">
            <div>Page {{ pageNum }} of {{ totalPages }}</div>
          </div>
        </div>
      </ng-template>
      <ng-content></ng-content>
    </div>
  </kendo-pdf-export>
</div>
