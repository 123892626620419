import DependentComposite from 'src/app/models/dependentComposite';

// ng
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

// ext
import { find, get } from 'lodash';
import Response from 'src/app/models/response';
// local

@Component({
  selector: 'dep-tobacco',
  templateUrl: 'dep.tobacco.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentTobaccoComponent implements OnInit, OnDestroy {
  @Input() dependent: DependentComposite;
  icons = {
  };
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    subagencies: [],
    phoneNumberTypes: [],
    eligibilityReasons: [],
    terminationReasons: [],
    planTypes: [],
    responses: [],
  };
  yesTobacco: boolean;
  affirmativeResponseId: string;
  negativeResponseId: string;
  @Input() isSebb: boolean = false;
  @Output() updateTobaccoAttest: EventEmitter<string> = new EventEmitter();
  @Output() moveToNextSubsection: EventEmitter<number> = new EventEmitter();

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.affirmativeResponseId = get(find(this.lookups.responses, (r: Response) => r.responseCode === 'Y'), 'responseId');
    this.negativeResponseId = get(find(this.lookups.responses, (r: Response) => r.responseCode === 'N'), 'responseId');
    this.yesTobacco = this.dependent.tobaccoResponseId ? this.dependent.tobaccoResponseId === this.affirmativeResponseId : null;
  }

  ngOnDestroy(): void {

  }

  submitDependent(): void {
    this.moveToNextSubsection.emit();
  }

  updateTobacco(): void {
   if (this.yesTobacco) {
    this.dependent.tobaccoResponseId = this.affirmativeResponseId;
    this.updateTobaccoAttest.emit(this.affirmativeResponseId);
   }else {
    this.dependent.tobaccoResponseId = this.negativeResponseId;
    this.updateTobaccoAttest.emit(this.negativeResponseId);
   }
  }
}
