<div *ngIf="gridData?.length === 0">
  <div fxFlexLayout="column" fxFlexLayoutGap="30px" style="margin: 20px 20px">
    No results were found for this report.
  </div>
</div>
<div *ngIf="gridData?.length > 0">
  <div fxFlexLayout="column" fxFlexLayoutGap="30px" class="mgbt-medium">
    <h3 class="h5" fxFlexOffset="1">
      Select the fields to display in the Results Grid.
    </h3>
  </div>
  <div fxLayout="row">
    <div class="form-group col-md-5">
      <checkbox 
        name="selectAllCheck"
        bold
        [(ngModel)]="selectAllCheck"
        (change)="selectAll(selectAllCheck)">
        Select All
      </checkbox>
    </div>
  </div>
  <div fxLayout="row" *ngFor="let field of reportColumnsToInclude">
    <div class="form-group col-md-5">
      <checkbox 
        [name]="field.fieldName"
        bold
        [(ngModel)]="field.includeInResults"
        (change)="updatedValueManually()">
        {{field.displayName}}
      </checkbox>
    </div>
  </div>
</div>
