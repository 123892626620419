import { LTDGridComponent } from './components/ltdGrid/ltd.grid.component';
import { SupplementalCalculatorComponent } from './components/supplementalCalculator/supplementalCalculator.component';
// ng
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// ext
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// local
import { SupplementalComponent } from './supplemental.component';

@NgModule({
  declarations: [
    SupplementalComponent,
    SupplementalCalculatorComponent,
    LTDGridComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    RouterModule,
    NgbModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    PDFExportModule,
    ProgressBarModule,
    UploadModule,
    OverlayModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [],
})
export class SupplementalModule {}
