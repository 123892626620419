import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { DataStateChangeEvent, GridComponent, PageChangeEvent, DetailExpandEvent } from '@progress/kendo-angular-grid';
import { Export834TransactionSetService } from './../../../../services/export834TransactionSet.service';
import { OEService } from '../../../../services/oe.service';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SystemUser from 'src/app/models/user';
import County from 'src/app/models/county';
import AddressType from 'src/app/models/addressType';
import BirthSex from 'src/app/models/birthSex';
import GenderIdentity from 'src/app/models/genderIdentity';
import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import RelationshipType from 'src/app/models/relationshipType';
import MemberType from 'src/app/models/memberType';
import PhoneNumberType from 'src/app/models/phoneNumberType';
import PreferredContactMethod from 'src/app/models/preferredContactMethod';
import Language from 'src/app/models/language';
import { sortBy, endsWith, forEach, find, cloneDeep, findIndex, orderBy, remove } from 'lodash';
import AttestationType from 'src/app/models/attestationType';
import DocumentType from 'src/app/models/documentType';
import PlanType from 'src/app/models/planType';
import Response from 'src/app/models/response';
import Status from 'src/app/models/Status';
import UserType from 'src/app/models/userType';
import OpenEnrollment from 'src/app/models/openEnrollment';
import Message from 'src/app/models/message';
import { MessageService } from 'src/app/services/message.service';
import { LookupService } from 'src/app/services/lookup.service';
import ApplicationSetting from 'src/app/models/applicationSettting';
import GenericMessageType from 'src/app/models/genericMessageType';
import { CoreService } from 'src/app/services/core.service';
import Module from 'src/app/models/module';
import Export834TransactionSet from 'src/app/models/export834TransactionSet';
import { State, process, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import * as dayjs from 'dayjs';
import { lastValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'err-handling',
  templateUrl: 'error.handling.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class HCAErrorHandlingComponent implements OnInit {
  icons = {
    faPlus,
    faMinus,
  };
  systemUser: SystemUser;
  lookupsCollapsed = true;
  oeCollapsed = true;
  messagesCollapsed = true;
  systemWideCollapsed = true;
  openEnrollments: { openEnrollment: OpenEnrollment[] };
  applicationSettings: { applicationSettings: ApplicationSetting[] };
  messages: { systemMessages: Message[] };
  liveWork: boolean;
  clonedGridData;
  transactions: Export834TransactionSet[];
  state: State = {
    skip: 0,
  };
  skip = 0;
  sort: SortDescriptor[] = [];
  filter: FilterDescriptor;
  selectedDataItem: Export834TransactionSet;
  retrigger = true;
  showReallyDelete = false;
  dayjs = dayjs;
  gridColumns: { [k: string]: string | {} }[] = [
    { field: 'referenceNumber', title: 'Ref Num', format: 'string' },
    { field: 'member.lastName', title: 'Last Name', format: 'string' },
    { field: 'member.firstName', title: 'First Name', format: 'string' },
    { field: 'member.socialSecurityNumber', title: 'SSN', format: 'string' },
    { field: 'member.subscriberMemberId', title: 'Sub GUID', format: 'string' },             
    { field: 'errorObject', title: 'Error', format: 'string' },
    { field: 'createdDate', title: 'Process Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'transactionSetType.transactionSetTypeName', title: 'Trans. Set Type', format: 'string'},
    { field: 'receivedInd', title: 'Recd', format: 'string' },
    { field: 'processedInd', title: 'Processed', format: 'string' },
    { field: 'errorInd', title: 'Errored', format: 'string' },    
    { field: 'dependentTransactionInd', title: 'Depend Transact', format: 'string'},
    { field: 'organizationCode', title: 'org', format: 'string' },
  ];
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;
  public lookups:
    | {
        addressTypes: AddressType[];
        attestationTypes: AttestationType[];
        birthSexes: BirthSex[];
        counties: County[];
        documentTypes: DocumentType[];
        genderIdentities: GenderIdentity[];
        languages: Language[];
        memberTypes: MemberType[];
        phoneNumberTypes: PhoneNumberType[];
        planTypes: PlanType[];
        preferredContactMethods: PreferredContactMethod[];
        relationshipTypes: RelationshipType[];
        relationshipQualifyReasons: RelationshipQualifyReason[];
        relationshipVerificationStatuses: RelationshipVerificationStatus[];
        responses: Response[];
        statuses: Status[];
        userType: UserType[];
        genericMessageTypes: GenericMessageType[];
      }
    | {} = {};
    
  constructor(
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService,
    private coreService: CoreService,
    private export834TransactionSetService: Export834TransactionSetService
  ) {}

  ngOnInit(): void {
    this.export834TransactionSetService.fetchErroredTransactions().pipe(untilDestroyed(this)).subscribe((transactions) => {
      this.transactions = sortBy(transactions, t => t.createdDate);
      this.reSetDataForGrid();
    })
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
    });

  }

  ngOnChanges(): void {
    if (this.transactions) {
      this.reSetDataForGrid();
    }
  }

  outputField(dataItem, col): any {
    const val = dataItem[col.field];

    if (typeof col.format === 'function') {
      return col.format(val);
    }

    if (val && col.format?.date) {
      return dayjs(val).format(col.format.date.toUpperCase());
    }

    return val;
  }

  public dataStateChange(state: DataStateChangeEvent | State): void {
    this.state = state;
    this.clonedGridData = process(this.transactions, state);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  private loadItems(): void {
    this.clonedGridData = {
      data: orderBy(filterBy(this.transactions, this.filter), this.sort).slice(this.skip, this.skip + 20),
      total: this.transactions.length,
    };
  }

  getRowDetails(event: DetailExpandEvent): void {
    this.selectedDataItem = event.dataItem;
    event.preventDefault();
    this.transactions?.forEach((gd, idx) => this.kendoGrid.collapseRow(idx));
    this.kendoGrid.expandRow(event.index);
  }

  reSetDataForGrid(index?): void {
    this.clonedGridData = cloneDeep(this.transactions);
    this.transactions?.forEach((gd, idx) => this.kendoGrid.collapseRow(idx));
    this.dataStateChange(this.state);
    this.loadItems();
  }

  retriggerChecked() {
    this.retrigger = !this.retrigger;
  }

  async saveTransaction(e: Export834TransactionSet) {
    try {
      this.spinnerOverlayService
      this.transactions = await lastValueFrom(this.export834TransactionSetService.updateTransaction(e, this.retrigger));
      this.transactions = sortBy(this.transactions, t => t.createdDate);
      this.reSetDataForGrid();
      this.coreService.popMessage('transaction successfully updated', 'success', 3000);
      
    }catch(err) {
      this.coreService.popMessage('an error occurred updating transaction(s)', 'error', 3000);
    }
  }

  async deleteTransaction(e: Export834TransactionSet) {
    try {
      this.spinnerOverlayService
      await lastValueFrom(this.export834TransactionSetService.deleteTransaction(e));
      remove(this.transactions, (t: Export834TransactionSet) => t.export834TransactionSetId === e.export834TransactionSetId)
      this.reSetDataForGrid();
      this.coreService.popMessage('transaction successfully updated', 'success', 3000);
      this.showReallyDelete = false;
    }catch(err) {
      this.coreService.popMessage('an error occurred updating transaction(s)', 'error', 3000);
    }
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      // @ts-ignore - this and the bind in template works just fine, ignored b/c ts yelling.
      data: process(this.gd, {}).data,
    };
    return result;
  }

}
