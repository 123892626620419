<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
  <div class="card-body">
    <h3>Qualifying event (please select one)</h3>
    <form #reasonForm="ngForm" name="reasonForm" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start start">
      <checkbox-list
        name="selfPayReason"
        [multi]="false"
        orientation="vertical"
        [(ngModel)]="selfPay.formJson.selfPayReason"
        [items]="reasonItems"></checkbox-list>
      <date-picker
        #dateOfService="ngModel"
        name="dateOfService"
        label="Date called to duty in the uniformed services"
        [(ngModel)]="selfPay.formJson.dateOfService"></date-picker>
    </form>
    <div fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="pushCancel()" class="btn btn-secondary">Previous</button>
      <button (click)="pushContinue()" class="btn btn-primary">Proceed to enrollment {{requestType.selfPayTypeCode === 'R' ? '/deferral' : ''}} form</button>
    </div>
  </div>
</div>
