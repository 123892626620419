export default class ContactType {
  contactTypeId: string;
  contactTypeCode: string;
  contactTypeName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(contactType?) {
    if (contactType) {
      this.contactTypeId = contactType.contactTypeId;
      this.contactTypeCode = contactType.contactTypeCode;
      this.contactTypeName = contactType.contactTypeName;
      this.obsoleteFlag = contactType.obsoleteFlag;
      this.obsoleteDate = contactType.obsoleteDate ? new Date(contactType.ObsoleteDate) : null;
    }
  }

}
