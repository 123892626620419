import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { some } from 'lodash';

@Directive({
  selector: '[forbiddenValues]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ForbiddenValidatorDirective, multi: true }],
})
export class ForbiddenValidatorDirective implements Validator {
  @Input() forbiddenValues: string[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.forbiddenValues ? forbiddenValuesValidator(this.forbiddenValues)(control) : null;
  }
}

export function forbiddenValuesValidator(forbiddenValues: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = some(forbiddenValues, (fv) => fv !== '' && control.value && control.value.includes(fv));
    return forbidden ? { forbiddenValues: { value: control.value } } : null;
  };
}
