import { filter } from 'lodash';
import Message from 'src/app/models/message';
import { MessageService } from './../../services/message.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Subscription} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CoreService } from 'src/app/services/core.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'auth',
  templateUrl: 'auth.component.html',
  styleUrls: [],
  providers: [UserService]
})
export class AuthComponent implements OnInit {
  isAuthorizedSubscription: Subscription;
  messages: Message[];
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
    private userService: UserService,
    private coreService: CoreService,
    private messageService: MessageService
    ) {
  }

  ngOnInit(): void {
    this.messageService.getMessages().pipe(untilDestroyed(this)).subscribe((messages: Message[]) => {
      this.messages = filter(messages, (message: Message) => message.isActive && message.moduleObject['Login Page']);
    });
    this.isAuthorizedSubscription = this.oidcSecurityService.checkAuth().pipe(untilDestroyed(this)).subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
      if (isAuthenticated) {
        this.userService.getUser().pipe(untilDestroyed(this)).subscribe(systemUser => {
          this.coreService.setSystemUser(systemUser);
          this.coreService.initialAuthNavigate(systemUser);
        }, (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.router.navigate(['/claim']);
          } else {
            console.log(err);
          }
        });
      }
    });
  }
}
