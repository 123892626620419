<div class="mgtp-large">
    <h3>Address Change Request</h3>
    <h4>{{selfPay.formJson.subscriber?.firstName}} {{selfPay.formJson.subscriber?.lastName}} (Subscriber)</h4>
</div>
<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
    <div class="card-body">
        <div fxLayout="column" class="fxGap-medium">
            <div>
                <h5>Residential address</h5>
                <address #residentialAddress [controlView]="'text'" [view]="'card'" name="residentialAddress" label="Residential address" 
                [(ngModel)]="selfPay.formJson.subscriber"></address>
                <div class="hca-pdf-table-content">
                    <checkbox
                        name="mailingAddressIsSame"
                        [(ngModel)]="selfPay.formJson.mailingAddressSameAsResidential"
                        [containerFxLayout]="'row'"
                        [containerfxLayoutAlign]="'start center'">
                        Mailing address same as residential address
                    </checkbox>
                </div>
                <div *ngIf="!selfPay.formJson.mailingAddressSameAsResidential">
                    <h5>Mailing address</h5>
                    <address #mailingAddress [controlView]="'text'" [view]="'card'" name="mailingAddress" label="Mailing address" 
                    [(ngModel)]="selfPay.formJson.subscriber.mailingAddress"></address>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page-break"></div>
<div class="mgtp-large">
    <h4>Dependents</h4>
</div>
<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
    <div class="card-body">
        <div fxLayout="column" class="fxGap-medium">
            <div *ngIf="selfPay.formJson?.dependents">
                <div *ngFor="let dep of selfPay.formJson?.dependents; let index = index">
                    <h5>{{dep.firstName}} {{dep.lastName}}</h5>
                    <div class="hca-pdf-table-content">
                        <checkbox
                            [name]="'depMailingAddressIsSame_' + index"
                            [(ngModel)]="dep.addressSameAsSubscriber"
                            [containerFxLayout]="'row'"
                            [containerfxLayoutAlign]="'start center'">
                            Residential address is the same as subscriber
                        </checkbox>
                    </div>
                    <address *ngIf="!dep.addressSameAsSubscriber"
                    [controlView]="'text'" [view]="'card'" name="dependentResidentialAddress" label="Residential address" [ngModel]="dep"></address>
                </div>
            </div>
        </div>
    </div>
</div>
  