
<ngb-accordion [destroyOnHide]="false" #contactsAccord="ngbAccordion">
    <ngb-panel>
      <ng-template ngbPanelHeader let-opened="opened">
        <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
          <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
          <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
            <h5 fxFlex="50" style="font-weight: bold;" fxLayout.xs="column" fxLayoutAlign="space-between">
              <span style="text-decoration: none;" *ngIf="contact">{{ contact.firstName && contact.lastName ? contact.firstName + ' ' + contact.lastName : 'New contact' }}
              </span>
            </h5>
            <h4 style="margin: 0px" fxFlex="50" fxLayout="row">
              <span *ngFor="let contactType of (contact.contactTypes | isObsolete)" style="text-decoration: none; margin-right: 5px; text-align: center; align-items: center; justify-content: center; background-color: #8cc63f" class="badge badge-pill badge-primary">
                {{contactType.contactTypeName}}
              </span>
            </h4>
        </button>
      </ng-template>
      <ng-template ngbPanelContent>
          <div style="padding-top: 10px;">
              <div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
                <form #contactForm="ngForm">
                  <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
                    <div class="form-group required" fxFlex="30">
                      <label for="firstName">First name</label>
                      <input
                        [disabled]="!isHCAAdmin"
                        pattern="[a-zA-Z0-9 -]*"
                        required
                        type="text"
                        class="form-control"
                        name="firstName" 
                        id="firstName" 
                        [(ngModel)]="contact.firstName"
                      />
                    </div>
                    <div class="form-group" fxFlex="30">
                      <label for="middleName">Middle initial</label>
                      <input
                        [disabled]="!isHCAAdmin"
                        type="text"
                        minlength="1"
                        maxlength="1"
                        pattern="^(?!\s)([a-zA-Z \-]){1,}$"
                        class="form-control"
                        name="middleName" 
                        id="middleName" 
                        [(ngModel)]="contact.middleInitial"
                      />
                    </div>
                    <div class="form-group required" fxFlex="30">
                      <label for="lastName">Last name</label>
                      <input
                        [disabled]="!isHCAAdmin"
                        pattern="[a-zA-Z0-9 -]*"
                        required
                        type="text"
                        class="form-control"
                        name="lastName" 
                        id="lastName" 
                        [(ngModel)]="contact.lastName"
                      />
                    </div>
                  </div>
                  <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
                    <div class="form-group required" fxFlex="30">
                      <label for="pebbEmailInput">Email address</label>
                      <input
                        [disabled]="!isHCAAdmin"
                        name="pebbEmailInput"
                        email
                        [(ngModel)]="contact.emailAddress"
                        required
                        maxlength="50"
                        type="email"
                        class="form-control"
                        id="pebbEmailInput" 
                        placeholder="Email Address"
                      />
                    </div>
                    <div class="form-group required" fxFlex="30">
                      <label for="contactPhone">Phone</label>
                      <input
                        [disabled]="!isHCAAdmin"
                        name="contactPhone" 
                        pattern="[0-9]*"
                        [(ngModel)]="contact.phoneNumber"
                        required
                        type="text"
                        class="form-control"
                        id="contactPhone"
                        placeholder="Phone Number"
                        minlength="10"  
                        maxlength="10"
                      />
                    </div>
                    <div class="form-group" fxFlex="30">
                        <label for="contactFax">Fax</label>
                        <input
                          [disabled]="!isHCAAdmin"
                          name="contactFax" 
                          pattern="[0-9]*"
                          [(ngModel)]="contact.faxNumber"
                          type="text"
                          class="form-control"
                          id="contactFax"
                          placeholder="Phone Number"
                          minlength="10"
                          maxlength="10"
                        />
                      </div>
                  </div>
                  <div class="form-row mgbt-medium" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
                    <div class="k-form-field" fxFlex="100" style="font-weight: bold;">
                      <checkbox
                        [name]="'addressIsSameAsPhysicalAddressInd' + contact.organizationContactId"
                        [disabled]="!isHCAAdmin"
                        [(ngModel)]="contact.addressIsSameAsPhysicalAddressInd"
                        (ngModelChange)="updateAddressToMatchOrganization($event)">
                        Address same as organization physical address
                      </checkbox>
                    </div>
                  </div>

                  <address #mailingAddress name="mailingAddress" view="inline" labelPrefix="Mailing" [(ngModel)]="contact" hideCounty="true"></address>

                  <h4>Contact types:</h4>
                  <checkbox-list
                    style="font-weight: bold;"
                    orientation="horizontal"
                    [name]="contact.organizationContactId"
                    [disabled]="!isHCAAdmin"
                    [items]="contactTypes | isObsolete"
                    [ngModel]="selectedContactTypeIds"
                    (ngModelChange)="contactTypesUpdated($event)"
                  ></checkbox-list>
                  <br />
                  <div class="form-row" style="padding-bottom: 5px;" fxLayout="row" fxLayoutAlign="end">
                    <div class="mgbt-medium-sm">
                        <button [disabled]="!isHCAAdmin" type="button" class="btn" (click)="removeContact.emit(contact)">
                          <fa-icon [icon]="icons.faBan" size="1x"></fa-icon> Remove contact
                        </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
