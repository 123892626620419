<form nativeControlElement (ngSubmit)="submitSubscriber(formContainer)" #formContainer *ngIf="subscriber" #memberForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group required" fxFlex="30">
      <label for="lastName">Last name</label>
      <input 
        minlength="1"
        maxlength="20"
        pattern="^(?!\s)(?!.*\s$)([a-zA-Z \-]){1,}$" 
        required type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="subscriber.lastName" />
    </div>
    <div class="form-group required" fxFlex="30">
      <label for="firstName">First name</label>
      <input 
        minlength="1"
        maxlength="15"
        pattern="^(?!\s)(?!.*\s$)([a-zA-Z \-]){1,}$" 
        required type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="subscriber.firstName" />
    </div>
    <div class="form-group" fxFlex="15">
      <label for="middleName">Middle name</label>
      <input type="text" pattern="^(?!\s)([a-zA-Z \-]){1,}$" class="form-control" maxLength="15" id="middleName" name="middleName" [(ngModel)]="subscriber.middleName" />
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="socialSecurityNumber" id="ssnLabel">SSN</label>
      <kendo-maskedtextbox
        [mask]="env.ssnMask"
        [pattern]="env.ssnItinRegex"
        [(ngModel)]="subscriber.socialSecurityNumber"
        [maskOnFocus]="true"
        required
        name="socialSecurityNumber"
        aria-labelledby="ssnLabel"
        #ssn="ngModel"
        class="form-control"
        [disabled]="true"
        [forbiddenValues]="env.invalidSSNValues"
      ></kendo-maskedtextbox>
      <div *ngIf="ssn.invalid && ssn.touched && (!!ssn.errors.forbiddenValues || !!ssn.errors.pattern)" class="alert alert-danger">This SSN is invalid.</div>
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlex="100">
    <div class="form-group" fxFlex="10">
      <label for="suffix">Suffix</label>
      <input pattern="[a-zA-Z]*" type="text" class="form-control" id="suffix" name="suffix" [(ngModel)]="subscriber.suffix" placeholder="JR, SR" />
    </div>
    <div class="form-group" fxFlex="15" fxLayout="column">
      <date-picker
        #birthDate="ngModel"
        name="birthDate"
        label="Birth date"
        required
        [canBeInFuture]="false"
        [min]="minBirthDate"
        [(ngModel)]="subscriber.birthDate"></date-picker>
        <div *ngIf="birthDate?.errors?.ngbDate?.minDate" class="alert alert-danger">Subscriber's date of birth is older than 110 years.</div>
        <div *ngIf="birthDate.value > tenYearsDate" class="alert alert-warning">Warning - Birthdate may be in error. Please verify</div>
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="birthSexId">Sex assigned at birth</label>
      <select required class="form-control" id="birthSexId" name="birthSexId" [(ngModel)]="subscriber.birthSexId">
        <option></option>
        <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
      </select>
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="genderIdentityId">Gender Identity</label>
      <select required class="form-control" id="genderIdentityId" name="genderIdentityId" [(ngModel)]="subscriber.genderIdentityId">
        <option></option>
        <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
      </select>
    </div>
    <div class="form-group" fxFlex="25">
      <span>
        Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law. To learn more, visit HCA's website at
        <a target="_blank" href="https://www.hca.wa.gov/about-hca/gender-identity-information">hca.wa.gov/gender-x.</a>
      </span>
    </div>
  </div>
  <!-- PHONE -->
  <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between" fxLayout.lt-md="column">
    <div fxFlexFill><b>Phone numbers are used by HCA and health plan carriers to contact subscribers to resolve issues and provide customer support.</b></div>
    <br />
    <br />
    <div fxFlex="40" class="form-group">
      <label for="homePhoneNumber">Home phone number</label>
      <input pattern="[0-9]*" minlength="10" maxlength="10" type="text" class="form-control" id="homePhoneNumber" name="homePhoneNumber" [(ngModel)]="subscriber.homePhoneNumber" />
    </div>
    <div fxFlex="40" class="form-group">
      <label for="workPhoneNumber">Work phone number</label>
      <input pattern="[0-9]*" minlength="10" maxlength="10" type="text" class="form-control" id="workPhoneNumber" name="workPhoneNumber" [(ngModel)]="subscriber.workPhoneNumber" />
    </div>
  </div>
  <!-- ELIGIBILITY -->
  <div class="form-row" fxFlexFill fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
    <div class="form-group required" fxFlex="25" fxFlex.lt-md="95">
      <label for="eligibilityReason">Eligibility reason</label>
      <select [disabled]="isTransfer && !isHCAWithEdit" required class="form-control" id="eligibilityReason" name="eligibilityReason" [(ngModel)]="subscriber.eligibilityReasonId" (change)="eligibilityReasonChange()">
        <option *ngFor="let reason of lookups.eligibilityReasons" [ngValue]="reason.reasonId">{{ reason.reasonName }}</option>
      </select>
    </div>
    <div class="form-group" fxFlex="25" fxFlex.lt-md="95">
      <date-picker
        #eligibilityDate="ngModel"
        name="eligibilityDate"
        label="Date of Eligibility"
        required
        enforceLowerLimit
        disabled
        [(ngModel)]="subscriber.eligibilityDate"></date-picker>
      <div *ngIf="memberForm.controls.eligibilityDate?.errors?.ngbDate?.maxDate" class="alert alert-danger">Cannot enter an eligibility date that is greater than one month in the future.</div>
      <div *ngIf="eligibilityDate.invalid && (eligibilityDate.dirty) && eligibilityDate.errors?.ngbDate?.minDate" class="alert alert-danger">
        The date entered is less than the current lower limit date.  Do not proceed with adding the subscriber using an alternative date and contact Outreach and Training for assistance.
      </div>
      <br/>
      <div *ngIf="isTransfer && !isHCAWithEdit">
        <span>
          Contact Outreach and Training if a correction is needed to the Eligibility reason or Date of Eligibility.  These values cannot be changed for an employee who has transferred in from another organization.
        </span>
      </div>
    </div>
    <div fxFlex="20" *ngIf="isTransfer">
      <span>
        The date of eligibility will reflect the most recent date that the subscriber became eligible for PEBB benefits.  Claiming a subscriber as a transfer or re-enrolling them after a termination in error will reflect the subscriber’s original eligibility date for PEBB benefits.
      </span>
    </div>
    <div class="form-group" fxFlex="25" fxFlex.lt-md="95">
      <date-picker
        name="coverageEffectiveDate"
        label="Coverage Effective Date"
        disabled
        [(ngModel)]="coverageEffectiveDate"></date-picker>
    </div>
  </div>
  <!-- REPRESENTED STATUS  -->
  <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
    <div class="form-group required" fxFlex="20">
      <label for="representedInd">Is this employee represented?</label>
      <select required class="form-control" id="representedInd" name="representedInd" [(ngModel)]="subscriber.isRepresentedInd">
        <option [ngValue]="true">Yes</option>
        <option [ngValue]="false">No</option>
      </select>
    </div>
    <div class="form-group" *ngIf="subscriber.isRepresentedInd === true" fxFlex="20">
      <date-picker
        #effectiveStartDate="ngModel"
        name="effectiveStartDate"
        label="Effective start date"
        required
        [canBeInFuture]="false"
        [(ngModel)]="subscriber.representedDate"></date-picker>
    </div>
    <div class="form-group required" fxFlex="20" *ngIf="!isSebb">
      <label for="LTDEligible">Employee eligible for LTD</label>
      <select required class="form-control" id="LTDEligible" name="LTDEligible" [(ngModel)]="subscriber.isEligibleForLTD">
        <option [ngValue]="true">Yes</option>
        <option [ngValue]="false">No</option>
      </select>
    </div>
    <div class="form-group required" fxFlex="20" *ngIf="isSebb">
      <yes-no
        name="sebbLocallyEligible"
        [disabled]="!organization.localEligibleBenefits"
        label="Is this employee locally eligible?"
        [stretch]="false"
        [(ngModel)]="subscriber.memberTypeId"
        [yesValue]="sebbLocallyEligible.memberTypeId"
        [noValue]="sebbEmployee.memberTypeId"
        (ngModelChange)="sebbLocallyEligibleChanged($event)"
        >
      </yes-no>
    </div>
    <div class="form-group" [class.required]="!isSebb && organization.benefitSetupId === fullBenefitSetupId" fxFlex="35" fxFlex.lt-md="95">
      <label for="monthlySalary">Employee monthly gross salary</label>
      <kendo-numerictextbox
        format="c2"
        [spinners]="false"
        [(ngModel)]="subscriber.salary"
        [required]="!isSebb && organization.benefitSetupId === fullBenefitSetupId"
        name="monthlySalary"
        id="monthlySalary"
        (blur)="salaryChange(subscriber.salary)"
        [decimals]="2"
        [maxlength]="7"
        class="kendo-fld-required"
        [min]="0">
      </kendo-numerictextbox>
    </div>
  </div>
  <!-- RESIDENTIAL ADDRESS -->
  <address #residentialAddress name="residentialAddress" label="Residential address" [(ngModel)]="subscriber.residentialAddress"></address>

  <div class="k-form-field mgbt-medium mgtp-x-small" style="width: 100%;">
    <checkbox
      bold
      name="mailingAddressIsSame"
      (ngModelChange)="updateMailingAddress($event)" 
      [(ngModel)]="mailingAddressIsSame">
      Same mailing address
    </checkbox>
  </div>
  <!-- MAILING ADDRESS -->
  <address #mailingAddress *ngIf="subscriber.mailingAddress" name="mailingAddress" label="Mailing address" [labelIcon]="icons.faEnvelope" [(ngModel)]="subscriber.mailingAddress"></address>

  <br/>
  <br/>
  <div *ngIf="showWarningMessage" fxFlexFill fxLayout="column" fxLayoutAlign="space-between">
    <h4 class="hca-error">{{messageToShow}}</h4>
    <div *ngIf="isDE" class="k-form-field" style="padding-top: 2px; padding-bottom: 5px;">
      <checkbox
        name="acceptDEMessage"
        bold
        [(ngModel)]="DEMessageAccepted">
        I have read and understand the information provided above.
    </checkbox>
    </div>
  </div>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
    <button [disabled]="!readyToSubmit || (isDE && !DEMessageAccepted) || formSubmitted" fxFlex="30" id="sub-add-submit-btn" type="button" 
      (click)="submitSubscriber(formContainer)" class="btn btn-primary">Submit</button>
    <button type="button" fxFlex="30" id="sub-add-cancel-btn" (click)="cancelAdd.emit()" class="btn btn-light">Cancel</button>
  </div>
</form>
