<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state border-top-radius-sm">
    <div *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId" id="newly-eligible-banner" class="card bg-secondary" style="padding: 15px">
      <div fxLayout="column" fxLayoutAlign="center center" class="fxGap-medium">
        <h2 class="h4 card-title">Welcome to Benefits 24/7.</h2>
        <p *ngIf="!this.isInOrgWithNoElections" id="banner-text" class="card-text">
          This is where you can manage your enrollment in {{subscriber?.agency}} benefits.
          <span *ngIf="subscriber.currentlyNewlyEligible && !subscriber.lossOfEligibilityDate">
            You are currently within your initial eligibility period and have 31 days from your date of eligibility to make benefit elections.
          </span>
          <span *ngIf="(!subscriber.currentlyNewlyEligible || !subscriber.newlyIncomplete) && subscriber.lossOfEligibilityDate && subscriber.lossOfEligibilityReason?.reasonCode !== '99' && subscriber.lossOfEligibilityReason?.reasonCode !== '35' && !selfPayWindowHasPassed">
            The enrollments for this account have been terminated.
          </span>          
        </p>
      </div>
    </div>
    <div *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId && isOpenEnrollment && subscriber?.memberSelfPayOrigin && !this.isInOrgWithNoElections" id="newly-eligible-banner" class="card bg-primary mgtp-small" style="padding: 15px;">
      <div fxLayout="column" fxLayoutAlign="center center" class="fxGap-medium">
        <h2 class="h4 card-title">PEBB Annual Open Enrollment is here</h2>
        <p id="banner-text" class="card-text">
          Annual open enrollment is live right now. To request a change to your current coverage click the link below to select and submit an Open Enrollment change request.
          <br/>
          <a [routerLink]="'../../selfPay/' + subscriber.memberId" >Open enrollment change for self-pay/retirees</a>     
        </p>
      </div>
    </div>
    <div class="card" *ngIf="subscriber">
      <div class="card-body">
        <div *ngIf="!this.isInOrgWithNoElections">
          <pebb-message [messages]="messages"></pebb-message>
          <ngb-alert *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId && !subscriber.emailAddress" [dismissible]="false" [type]="'success'">
              To stay up-to-date on the latest HCA communications, be sure to keep your email address current in your account <a [routerLink]="'/profile/' + subscriber.memberId" style="cursor:pointer;">profile.</a>
            </ngb-alert>
            <ngb-alert *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId && !isHCA && subscriberIsNewlyFull" [dismissible]="false" [type]="'success'">
              Your new employment includes eligibility for dental {{visionAvailable ? ' and vision ' : ''}}coverage for you and your existing eligible dependents.  No other changes can be made unless you have a special open enrollment.  More information on special open enrollments can be found <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/change-your-coverage#Special-open-enrollment" target="_blank">here</a>.
            </ngb-alert>          
          </div>
          <h2 *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId" class="mobile-text-centered mgtp-medium mgbt-medium-sm">{{ subscriber?.organization?.organizationName }} Subscriber Dashboard</h2>
          <h3 class="h4" *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId">Welcome, {{ subscriber.firstName }} {{ subscriber.lastName }}!</h3>
          <div *ngIf="this.isInOrgWithNoElections" style="color:black; font-size: 1.1rem; text-align: center;">
            <ngb-alert [dismissible]="false" [type]="'light'">
              Your employer uses Workday to manage your benefits while you’re an employee. Contact your payroll or benefits office for help.
            </ngb-alert>  
          </div>
          <div *ngIf="!this.isInOrgWithNoElections">
          <enrollment-periods *ngIf="!subscriber?.lossOfEligibilityDate && !subscriber.isSelfPay" (enrollmentPeriodSelected)="enrollmentPeriodSelected($event)" [subscriber]="subscriber" [enrollmentPeriods]="subscriber.enrollmentPeriods"> </enrollment-periods>
          <wiz-dual-enroll *ngIf="isDualEnrolledForPeriod" [dualEnrollment]="dualEnrollment" [subscriber]="subscriber" (backToDash)="cancelDE()" (waive)="waivePlansDueToDualEnrollment()" (continue)="continueDualEnroll()" ></wiz-dual-enroll>
          <router-outlet class="mgbt-medium-lg" fxLayoutAlign="center center" #wizardOutlet id="wizardOutlet">
            <pebb-wizard [isCompleteOrOptedOut]="isAdmin" [enabledMilestoneSteps]="enabledMilestoneSteps" [subscriber]="subscriber" [enrollmentPeriod]="selectedEnrollmentPeriod" *ngIf="selectedEnrollmentPeriod && selectedEnrollmentPeriod.enrollmentPeriodId && !isDualEnrolledForPeriod"></pebb-wizard>
          </router-outlet>
          <reattest-banner *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId && !subscriber.newlyIncomplete && mustReattest && !this.isInOrgWithNoElections">
          </reattest-banner>
        </div>
        <div *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId" fxLayoutAlign="space-between" fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-l hca-dashboard-card-container">
          <div class="hca-dashboard-card" *ngIf="!selectedEnrollmentPeriod?.enrollmentPeriodId && !this.isInOrgWithNoElections" fxFlex.xs="100%" [routerLink]="'../../profile/' + subscriber.memberId">
            <div class="card-body">
              <div fxLayout="row wrap">
                <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faCircleUser" size="2x"></fa-icon></span>
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <h3>Profile</h3>
                  <h4 class="h6">View and manage your contact information</h4>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!this.isInOrgWithNoElections" class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'../../summary/' + subscriber.memberId">
            <div class="card-body">
              <div fxLayout="row wrap">
                <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faUmbrella" size="2x"></fa-icon></span>
                <div fxFlexLayout="column" fxLayoutAlign.xs="end end">
                  <h3>Current Coverage</h3>
                  <h4 class="h6">View or print your current coverage</h4>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="(!subscriber.isSelfPay && !this.isInOrgWithNoElections && (!subscriber.newlyIncomplete || !subscriber.currentlyNewlyEligible)) || 
            (subscriber.isSelfPay && subscriber.hasSelfPayPeriod())" class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="soeLink">
            <div class="card-body">
              <div fxLayout="row wrap">
                <span><img class="svg-inline--fa fa-3x hca-fontawesome-icon hca-color-img-secondary" type="image/svg+xml" height="45" width="45" src="assets/arrows-split-up-and-left-duotone.svg" alt="Special open enrollment and account changes" /></span>
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <h3 *ngIf="!subscriber.isSelfPay">Special open enrollment and account changes</h3>
                  <h3 *ngIf="subscriber.isSelfPay">Account changes</h3>
                  <h4 class="h6">Request a change due to a qualifying event</h4>
                  <ul>
                    <li *ngIf="!subscriber.isSelfPay">Special open enrollment</li>
                    <li *ngIf="subscriber.isSelfPay">Events</li>
                    <li>Address change requests</li>
                    <li>Report a death or divorce</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="(!subscriber.newlyIncomplete || !subscriber.currentlyNewlyEligible) && !this.isInOrgWithNoElections" class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'../../dependents/' + subscriber.memberId">
            <div class="card-body">
              <div fxLayout="row wrap">
                <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUsers" size="2x"></fa-icon></span>
                <div fxFlexLayout="column" fxLayoutAlign.xs="end end">
                  <h3 *ngIf="!subscriber.isSelfPay" >Manage Dependents</h3>
                  <h3  *ngIf="subscriber.isSelfPay">Dependents</h3>
                  <h4 class="h6">Manage your dependent's information and coverage</h4>
                </div>
              </div>
            </div>
          </div>      
          <div *ngIf="(subscriber.hasSupplementalLTD && !subscriber.isSelfPay) && !this.isInOrgWithNoElections" class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'../../supplemental/' + subscriber.memberId">
            <div class="card-body">
              <div fxLayout="row wrap">
                <span><img class="svg-inline--fa fa-3x hca-fontawesome-icon hca-color-img-secondary" type="image/svg+xml" height="45" width="45" src="assets/staff-snake-duotone.svg" alt="Supplemental coverage" /></span>
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <h3 >Supplemental coverage</h3>
                  <h4 class="h6">Life, AD&D, LTD, HSA, FSAs, DCAP, SmartHealth</h4>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="(!subscriber.currentlyNewlyEligible || subscriber?.lossOfEligibilityDate) && !this.isInOrgWithNoElections" class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'../../attest/tobacco/' + subscriber.memberId">
            <div class="card-body">
              <div fxLayout="row wrap">
                <span><img class="svg-inline--fa fa-3x hca-fontawesome-icon hca-color-img-secondary" type="image/svg+xml" height="45" width="45" src="assets/lungs-duotone.svg" alt="Tobacco attestations" /></span>
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <h3 >Tobacco Attestations</h3>
                  <h4 class="h6">Update your tobacco attestations</h4>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isHCA && !subscriber.currentlyNewlyEligible && mustReattest  && !this.isInOrgWithNoElections" class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'../../spousal/' + subscriber.memberId">
            <div class="card-body">
              <div fxLayout="row">
                <span fxFlex="20"><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faTasks" size="2x"></fa-icon></span>
                <div fxFlex="70" fxLayout="column" fxLayoutAlign.xs="end end">
                  <h4 >Spouse or state-registered domestic partner coverage premium surcharge attestation</h4>
                  <h4 class="h6">Update your spousal attestation</h4>
                </div>
              </div>
            </div>
          </div>  
          <div *ngIf="(!subscriber.currentlyNewlyEligible || !subscriber.newlyIncomplete || this.isInOrgWithNoElections) && !this.isPerspay" class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="continuationLink">
            <div class="card-body" style="padding-left: 20px; padding-right: 20px;">
              <div fxLayout="row wrap">
                <span>
                  <!-- <fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faTasks" size="2x"></fa-icon> -->
                  <img class="svg-inline--fa fa-3x hca-fontawesome-icon hca-color-img-secondary" type="image/svg+xml" height="45" width="45" src="assets/hand-holding-seedling-solid.svg" alt="Retiree and continuation coverage" />
                </span>
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <h3 >Retiree and continuation coverage</h3>
                  <h4 class="h6">Request enrollment in PEBB retiree insurance coverage, COBRA, or Unpaid Leave</h4>
                </div>
              </div>
            </div>
          </div>          
          <div class="hca-dashboard-card-empty" fxFlex.xs="100%">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</div>
