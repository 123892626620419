// ng
import { Component, ViewEncapsulation, OnInit, Output, EventEmitter, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
// ext
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { find, sortBy, cloneDeep, filter } from 'lodash';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import Organization from 'src/app/models/organization';
import Report from 'src/app/models/report';
import ReportParameter from 'src/app/models/reportParameter';
import ReportType from 'src/app/models/reportType';
import SystemUser from 'src/app/models/user';

// local

@Component({
  selector: 'report-search',
  templateUrl: 'report.search.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class ReportSearchComponent implements OnInit, OnChanges {
  icons = {
    faCalendar,
  };
  @Input() selfPayOnly = false;
  @Input() showParameters = false;
  @Input() reportTypes: ReportType[];
  @Input() availableReports: Report[];
  @Input() selectedReport: Report;
  @Input() reportParameters: ReportParameter[];
  @Input() lookups: any = {};
  @Input() reportFilters: {[k: string]: string | {}} = {};
  @Input() showAgencyPicker = true;
  @Input() systemUser: SystemUser;
  @Output() updateReportSelected: EventEmitter<Report> = new EventEmitter<Report>();
  @Output() okToSendToNext: EventEmitter<void> = new EventEmitter<void>();
  @Output() agencySelected: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('reportsForm') reportsForm: NgForm;
  visibleReports: Report[];
  filteredReports: Report[] = [];
  selectedReportCategory: string;
  tripleStateValues = ['Yes', 'No', 'Both'];
  agencyFilter = '';
  @Input() currentAgency: string;
  constructor() {}

  ngOnInit(): void {
    // console.log(this.currentAgency)
    // if(this.currentAgency) {
    //   this.agencyFilterChange(this.currentAgency);
    // }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.reportParameters && changes?.reportParameters.currentValue) {
      const subOrDepParameter = find(changes.reportParameters.currentValue, (param: ReportParameter) => param.parameterField === 'subscriberAndOrDependents');
      if (subOrDepParameter) {
        this.reportFilters[subOrDepParameter.parameterField] = 'All';
      }
    }

    this.determineVisibleReports();
  }

  determineVisibleReports(): void {
    const agencyId = this.agencyFilter ? find(this.lookups.agency, a => a.agencyCode === this.agencyFilter)?.agencyId : null;

    this.agencySelected.emit(agencyId);

    if (this.availableReports) {
      this.visibleReports = sortBy(this.availableReports.filter((r: Report) => !agencyId || r.agencyIds.indexOf(agencyId) > -1), ['reportName']);
    }
  }

  agencyFilterChange(agencyFilter: string): void {
    this.agencyFilter = agencyFilter;

    this.determineVisibleReports();
  }

  updateSelectedReport(): void {
    this.updateReportSelected.emit(this.selectedReport);
  }

  continueToNext(): void {
    this.okToSendToNext.emit();
  }

  yearChanged(field: string): void {
    const newValue = this.reportFilters[field];
    if (newValue && newValue.toString().length > 4) {
      this.reportFilters[field] = newValue.toString().substring(0, 4);
    }
  }
}
