import MemberType from './memberType';

export default class OrganizationType {
  organizationTypeId: string;
  organizationTypeCode: string;
  organizationTypeName: string;
  memberTypeId: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;
  memberType: MemberType;

  constructor(organizationType?) {
    if (organizationType) {
      this.organizationTypeId = organizationType.organizationTypeId;
      this.organizationTypeCode = organizationType.organizationTypeCode;
      this.organizationTypeName = organizationType.organizationTypeName;
      this.memberTypeId = organizationType.memberTypeId;
      this.obsoleteFlag = organizationType.obsoleteFlag;
      this.obsoleteDate = organizationType.obsoleteDate ? new Date(organizationType.obsoleteDate) : null;
      this.memberType = organizationType.memberType ? new MemberType(organizationType.memberType) : null;
    }
  }
}
