import { SpinnerOverlayService } from './../../../../../../../../services/spinnerOverlay.service';
import { ActionService } from './../../../../../../../../services/action.service';
import { CoreService } from 'src/app/services/core.service';
import Country from 'src/app/models/country';
import { faCalendar, faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewChild, ViewEncapsulation, Input, OnInit, Output, EventEmitter, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { sortBy, find, get, remove, some, flatMap } from 'lodash';
import { NgForm } from '@angular/forms';
import * as dayjs from 'dayjs';

import { Router, ActivatedRoute } from '@angular/router';
import SubscriberComposite from 'src/app/models/subscriberComposite';
import { env } from 'src/env/development';
import MemberAddress from 'src/app/models/memberAddress';
import Organization from 'src/app/models/organization';
import BenefitSetup from 'src/app/models/benefitSetup';
import { MemberService } from 'src/app/services/member.service';
import SubscriberTransfer from 'src/app/models/subscriberTransfer';
import ExternalEnrollment from 'src/app/models/externalEnrollment';
import DualEnrollment from 'src/app/models/dualEnrollment';
import SubscriberTransferSearch from 'src/app/models/subscriberTransferSearch';
import { lastValueFrom } from 'rxjs';
import MemberType from 'src/app/models/memberType';
import TransferSearchResult from 'src/app/models/transferSearchResult';

@Component({
  selector: 'add-subscriber',
  templateUrl: 'addSubscriber.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class AddSubscriberComponent implements OnInit {
  icons = {
    faCalendar,
    faHome,
    faEnvelope,
  };
  @Input() organization: Organization;
  @Input() subscriber: SubscriberComposite;
  @Input() isTransfer = false;
  @Input() isHCAWithEdit = false;
  @Input() showWarningMessage = false;
  @Input() subscriberTransferSearch: SubscriberTransferSearch;
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    subagencies: [],
    phoneNumberTypes: [],
    eligibilityReasons: [],
    terminationReasons: [],
    benefitSetup: []
  };
  @Input() formSubmitted = false;
  @Input() transferMemberResult: TransferSearchResult;
  @Output() saveSubscriber: EventEmitter<{}> = new EventEmitter();
  @Output() cancelAdd: EventEmitter<void> = new EventEmitter();
  @ViewChild('memberForm') public memberForm: NgForm;
  isSebb = false;
  sebbEmployee: MemberType;
  sebbLocallyEligible: MemberType;
  requestRemoveInd = false;
  isSubscriber = false;
  pendingChanges = false;
  inAddState: boolean;
  inManagementState: boolean;
  showPartnershipDate = false;
  inSOEState = false;
  minBirthDate: Date;
  rtToQrMap: any;
  rtToQrMap26: any;
  twentySixDate: Date;
  mailingAddressIsSame = false;
  now: Date = new Date();
  marriageDateInFuture = false;
  showMemberReactivate = false;
  // because ssn is model bound - for ignoring on ssn collisions check.
  originalSSN: string;
  sameMailingAddress = true;
  fullBenefitSetupId: string;
  messageToShow: string;
  readyToSubmit = false;
  DEMessageAccepted = false;
  isDE = false;
  coverageEffectiveDate: Date;
  coverageEffectiveDateOriginal: Date;
  newlyEligibleReasonId: string;
  retireeRehireReasonId: string;
  returnFromLWOPReasonId: string;
  // isPortCommissioner = false;
  env = env;
  tenYearsDate;
  constructor(
    actionService: ActionService,
    private coreService: CoreService,
    private memberService: MemberService,
    private router: Router,
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService
    ) {}

  ngOnInit(): void {
    const nowMoment = dayjs();
    const orgEffMoment = dayjs(this.organization.effectiveDate);
    this.minBirthDate = dayjs().subtract(110, 'year').toDate();
    this.tenYearsDate = dayjs(this.now).subtract(10, 'year').toDate();
    this.fullBenefitSetupId = get(find(this.lookups.benefitSetup, (b: BenefitSetup) => b.benefitSetupCode === 'FB'), 'benefitSetupId');

    this.subscriber.residentialAddress = new MemberAddress(this.subscriber.residentialAddress);

    if (!this.subscriber.mailingAddress?.memberId) {
      this.updateMailingAddress(false);
    }

    let eligibilityReasonCodes = env.eligibilityReasonCodes.pebb;

    if (this.organization.agency.agencyCode === 'SEBB') {
      this.isSebb = true;


      eligibilityReasonCodes = env.eligibilityReasonCodes.sebb;

      this.sebbEmployee = find(this.lookups.memberType, mt => mt.memberTypeCode === 'Z');
      this.sebbLocallyEligible = find(this.lookups.memberType, mt => mt.memberTypeCode === 'W');

      this.subscriber.memberTypeId = this.sebbEmployee.memberTypeId;
      this.subscriber.isEligibleForLTD = true;
    }

    this.lookups.eligibilityReasons = sortBy(
      this.lookups.eligibilityReasons.filter(r => r.eligibilityReasonInd && eligibilityReasonCodes.includes(r.reasonCode)),
      'reasonCode'
    );

    this.newlyEligibleReasonId = this.lookups.eligibilityReasons.find(o => o.reasonCode === '01')?.reasonId;
    this.returnFromLWOPReasonId = this.lookups.eligibilityReasons.find(o => o.reasonCode === '05')?.reasonId;
    this.retireeRehireReasonId = this.lookups.eligibilityReasons.find(o => o.reasonCode === '03')?.reasonId;

    if (this.isTransfer && this.subscriberTransferSearch) {
      this.subscriber.eligibilityDate = this.subscriberTransferSearch.eligibilityDate;

      if (this.organization.agency.agencyCode === 'SEBB') {
        // this.subscriber.eligibilityReasonId = null;
        this.subscriber.memberTypeId = this.sebbEmployee.memberTypeId;
      }
    }

    this.spinnerOverlayService.show();
    this.checkForDualEnrollment().then(() =>
    {
      this.readyToSubmit = true;
      this.spinnerOverlayService.hide();
    });
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.memberForm);
  }

  sebbLocallyEligibleChanged(memberTypeId: string): void {
    if (memberTypeId === this.sebbLocallyEligible.memberTypeId) {
      // Locally Eligible = YES, then LTD = NO
      this.subscriber.isEligibleForLTD = false;
    } else {
      this.subscriber.isEligibleForLTD = true;
    }
  }

  async submitSubscriber(formContainer): Promise<void> {
    this.formSubmitted = true;
    // existing member
    let isValid = true;
    this.messageToShow = null;

    this.markAllControlsAsTouched();
    const nowMoment = dayjs(new Date());
    const minBirthDate = new Date(nowMoment.year() - 110, nowMoment.month() + 1, nowMoment.date());

    if (this.subscriber.birthDate > nowMoment.endOf('day').toDate() || this.subscriber.birthDate < minBirthDate) {
      const message = 'The birth date cannot be in the future or more than 110 years in the past.';
      this.messageToShow = !this.messageToShow ? message : `${this.messageToShow} ${message}`;
      this.showWarningMessage = true;
      isValid = false;
      this.formSubmitted = false;
    }

    if (this.subscriber.eligibilityDate < this.organization.effectiveDate) {
      isValid = false;
      const message = `Eligibility date for subscriber cannot be prior to your organization\'s effective date with the ${this.organization?.agency?.agencyCode || 'PEBB'} Program.`;
      this.messageToShow = !this.messageToShow ? message : `${this.messageToShow} ${message}`;
      this.showWarningMessage = true;
      this.memberForm.controls.eligibilityDate.setErrors({ invalid: true });
      this.formSubmitted = false;
    }

    this.subscriber.residentialAddress.memberId = this.subscriber.residentialAddress.memberId ?? this.subscriber.memberId;
    if (this.subscriber.mailingAddress) {
      this.subscriber.mailingAddress.memberId = this.subscriber.mailingAddress.memberId ?? this.subscriber.memberId;
    }

    isValid = isValid ? this.memberForm.valid : false;
    if (isValid) {
      const isDEAndAccepted = this.isDE && this.DEMessageAccepted;
      // this.saveSubscriber.emit({subscriber: this.subscriber, isDEAndAccepted, isPortCommissioner: this.isPortCommissioner});
      this.saveSubscriber.emit({subscriber: this.subscriber, isDEAndAccepted });
    } else {
      const message = 'Please enter all required fields and re-submit.';
      this.formSubmitted = false;
      if (this.isDE) {
        this.messageToShow = !this.messageToShow ? message : `${this.messageToShow} ${message}`;
        this.showWarningMessage = true;
      } else {
        this.coreService.popMessage(message, 'error', 3000, this.coreService.getInvalidFields(formContainer));
      }
    }
  }

  async checkForDualEnrollment(): Promise<void> {
    this.coverageEffectiveDate = this.transferMemberResult.coverageEffectiveDate;
    this.coverageEffectiveDateOriginal = this.transferMemberResult.coverageEffectiveDate;
    if (this.transferMemberResult.transferSearchResponses.length > 0) {
      const dateToUse = this.transferMemberResult.transferSearchResponses[0].dateUsedForCalculation;
      const externalEnrollments = flatMap(this.transferMemberResult.transferSearchResponses, (t: SubscriberTransfer) => t.externalEnrollments);
      const isFullBenefits = this.organization.benefitSetupId === this.fullBenefitSetupId;
      const dualEnrollment = new DualEnrollment(this.organization, externalEnrollments, dateToUse);

      const deWAC = (dualEnrollment.isSebb ? '182-31-070' : '182-12-123');

      if (dualEnrollment.hasOtherAgencyAtAll) {
        this.isDE = true;
        this.showWarningMessage = true;
        const otherProgram = dualEnrollment.otherAgency === 'PEBB' ? 'Public' : 'School';
        this.messageToShow = `This subscriber has enrollment(s) in the ${otherProgram} Employees Benefit Program.  Dual enrollment between programs is not ` +
          `allowed.  Subscriber can waive medical/dental benefits in ${dualEnrollment.currentAgency} to remain in ${dualEnrollment.otherAgency} or they can enroll ` +
          `in ${dualEnrollment.currentAgency} benefits and their ${dualEnrollment.otherAgency} benefits will be ended.`;
      } else if ((dualEnrollment.isSebb || isFullBenefits) && dualEnrollment.deTypes.length > 1) {
        this.isDE = true;
        this.showWarningMessage = true;
        const lastDEType = dualEnrollment.deTypes.pop();
        const coverageTypes = dualEnrollment.deTypes.join(', ') + ' and ' + lastDEType;
        this.messageToShow = `The subscriber has ${dualEnrollment.currentAgency} dependent enrollments for ${coverageTypes} and cannot be dual enrolled per ` +
          `WAC ${deWAC}.  The dependent ${coverageTypes.replace('medical, ', '').replace('medical and', '')} coverage will be terminated on the date defined in the WAC shown above (Generally, the last day of the month prior ` +
          `to subscriber benefits beginning).  Medical coverage will be terminated if subscriber elects to enroll in medical coverage (or defaults due to not electing.)`;
      } else if ((dualEnrollment.isSebb || isFullBenefits) && (dualEnrollment.hasCurrentAgencyDependentDental || dualEnrollment.hasCurrentAgencyDependentVision)) {
        this.showWarningMessage = true;
        this.isDE = true;
        const coverageType = (dualEnrollment.hasCurrentAgencyDependentDental ? 'dental' : 'vision');
        this.messageToShow = `The subscriber has ${dualEnrollment.currentAgency} dependent enrollments for ${coverageType} and cannot be dual enrolled per WAC ${deWAC}.  ` +
          `The dependent ${coverageType} coverage will be terminated on the date defined in the WAC shown above (Generally, the last day of the month prior to subscriber ` +
          `benefits beginning).`;
      } else if (dualEnrollment.hasCurrentAgencyDependentMedical) {
        this.showWarningMessage = true;
        this.isDE = true;
        this.messageToShow = `The subscriber has ${dualEnrollment.currentAgency} dependent enrollments for medical and cannot be dual enrolled per WAC ${deWAC}.  ` +
          `Medical coverage will be terminated if subscriber elects to enroll in medical coverage (or defaults due to not electing.)`;
      }
    }
  }

  updateMailingAddress(e: boolean): void {
    if (!e) {
      this.subscriber.mailingAddress = new MemberAddress();
      this.subscriber.mailingAddress.memberId = this.subscriber.memberId;
    } else {
      delete this.subscriber.mailingAddress;
    }
  }

  eligibilityReasonChange(): void {
    if (!this.isDE && this.coverageEffectiveDateOriginal) {
      if (this.subscriber.eligibilityReasonId === this.newlyEligibleReasonId || this.subscriber.eligibilityReasonId === this.retireeRehireReasonId || (this.isSebb && this.subscriber.eligibilityReasonId === this.returnFromLWOPReasonId)) {
        this.coverageEffectiveDate = this.coverageEffectiveDateOriginal;
      } else {
        this.coverageEffectiveDate = new Date(this.subscriber.eligibilityDate.getFullYear(), this.subscriber.eligibilityDate.getMonth(), 1);
      }
    }
  }

  salaryChange(e) {
    if(e > 99999.99) {
      Math.trunc(e)
      let eStr: string = '' + e;
      eStr = eStr.substring(0, eStr.length -2) + '.' + eStr.substring(eStr.length -2);
      this.subscriber.salary = Number(eStr);
    }
  }
}
