import { SimpleChanges, OnChanges } from '@angular/core';
// ng
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// ext
import { every, sortBy, some, cloneDeep, filter } from 'lodash';

// local
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
import SystemUser from 'src/app/models/user';
import Subscriber from 'src/app/models/subscriber';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { AuthService } from 'src/app/services/auth.service';
import Milestone from 'src/app/models/milestone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LowerLimitService } from 'src/app/services/lowerLimit.service';
import { lastValueFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'enrollment-periods',
  templateUrl: 'enrollmentPeriods.component.html',
  styleUrls: [],
  providers: [],
})
export class EnrollmentPeriodsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() subscriber: Subscriber;
  @Input() enrollmentPeriods: EnrollmentPeriod[];
  @Output() enrollmentPeriodSelected: EventEmitter<EnrollmentPeriod> = new EventEmitter<EnrollmentPeriod>();
  lowerLimitDate: Date;
  authService: AuthService;
  systemUser: SystemUser;
  newlyIncomplete = false;
  isHCA = false;
  isHcaAdmin = false;
  isReadOnly = false;
  isPersWithEdit = false;
  isSubscriber = false;
  selectedPeriod: EnrollmentPeriod;

  constructor(
    private coreService: CoreService,
    private lowerLimitService: LowerLimitService,
    private enrollmentPeriodService: EnrollmentPeriodService,
    private router: Router,
    private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
    this.isHcaAdmin = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
    this.isPersWithEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit);
    this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);
    this.isSubscriber = !(this.isHCA || this.isHcaAdmin || this.isPersWithEdit || this.isReadOnly); 
    this.lowerLimitDate = await lastValueFrom(this.lowerLimitService.getLowerLimit(null, true, this.subscriber.agencyCode));

    this.coreService.getEnrollmentPeriod().pipe(untilDestroyed(this)).subscribe((ep) => {
      if (ep && ep.enrollmentPeriodId && this.selectedPeriod && this.selectedPeriod.enrollmentPeriodId !== ep.enrollmentPeriodId) {
        this.selectedPeriod = cloneDeep(ep);
      } else if (!ep || !ep.enrollmentPeriodId) {
        this.selectedPeriod = null;
      }
    });
    this.enrollmentPeriods = this.getSortedFilteredEnrollmentPeriods(this.subscriber);
    // subscribers newly eligible EP has a confirmation milestone incomplete.
    this.setNewlyIncomplete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.subscriber) {
      this.enrollmentPeriods = this.getSortedFilteredEnrollmentPeriods(this.subscriber);
    }
    this.setNewlyIncomplete();
  }

  setNewlyIncomplete() {
    this.newlyIncomplete = some(
      this.enrollmentPeriods,
      (ep: EnrollmentPeriod) =>
        (
          ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible' || 
          ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Transfer Event'
        ) &&
        some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && !mi.isComplete)
    );
  }

  getSortedFilteredEnrollmentPeriods(s: Subscriber): EnrollmentPeriod[] {
    let results = s.allEnrollmentPeriods;

    if (this.isHcaAdmin) {
      results = filter(results,
        (ep: EnrollmentPeriod) => ep.effectiveStartDate < new Date() &&
          ep.enrollmentPeriodType?.enrollmentPeriodTypeCode !== 'SOE' &&
          ep.enrollmentPeriodType?.enrollmentPeriodTypeCode !== 'SPE');
    } else if (this.lowerLimitDate && !this.isSubscriber) {
      // is perspay so lower limit is set
      results = filter(results,
        (ep: EnrollmentPeriod) => ep.effectiveStartDate < new Date() &&
          ep.enrollmentPeriodType?.enrollmentPeriodTypeCode !== 'SOE' &&
          ep.enrollmentPeriodType?.enrollmentPeriodTypeCode !== 'SPE' &&
          ep.coverageEffectiveStartDate >= this.lowerLimitDate);
    } else {
      results = filter(results,
        (ep: EnrollmentPeriod) => ep.isCurrentlyActive && ep.enrollmentPeriodType?.enrollmentPeriodTypeCode !== 'SOE');
    }

    return sortBy(s.getEffectiveEnrollmentPeriods(results), 'coverageEffectiveStartDate');
  }

  updatePeriod(period: EnrollmentPeriod, index: number): void {
    if (this.isReadOnly && !this.periodCompleted(period)) {
      return this.coreService.popMessage('Enrollment event must be completed by subscriber to review.', 'error', 4000);
    } else {
      this.selectedPeriod = period;
      this.enrollmentPeriodSelected.emit(period);
      this.coreService.setEnrollmentPeriod(period);
    }
  }

  periodDisabled(isHCA: boolean, period: EnrollmentPeriod, isPersWithEdit: boolean, newlyIncomplete: boolean, index: number): boolean {
    return (newlyIncomplete && period.enrollmentPeriodType.enrollmentPeriodTypeName === 'Open Enrollment') ||
      (!isHCA && !period.isCurrentlyActive && !isPersWithEdit);
  }

  periodCompleted(period: EnrollmentPeriod): boolean {
    return some(period.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && mi.isComplete);
  }

  periodStarted(period: EnrollmentPeriod): boolean {
    return some(period.milestones, (mi: Milestone) => mi.isComplete);
  }

  ngOnDestroy(): void {}
}
