import Answer from './answer';
import DependentComposite from './dependentComposite';
import MemberMedicare from './memberMedicare';
import BaseAddressWithCountry from './baseAddressWithCountry';

export default class SelfPayDependentElections extends BaseAddressWithCountry {
    memberId: string;
    subscriberMemberId: string;
    socialSecurityNumber: string;
    firstName: string;
    lastName: string;
    middleName: string;
    suffix: string;
    birthDate: Date;
    birthSexId: string;
    genderIdentityId: string;

    addressIsSameAsSubscriberInd = false;

    relationshipTypeId: string;
    relationshipQualifyReasonId: string;
    partnershipStartDate?: Date;

    // just for passing to enrollment update composite in case of terming for soe
    terminationReasonId ?: string;
    adoptionDate ?: Date;
    relationshipTypeName?: string;
    memberMedicare: MemberMedicare;
    medicalInd: boolean;
    medicalReasonId: string;
    dentalInd: boolean;
    dentalReasonId: string;
    tobaccoResponseId: string;
    tobaccoUseInd: boolean;
    tobaccoUseEffectiveDate: Date;
    spousalEffectiveDate: Date;
    spousalResponseId: string;
    spousalAnswers: Answer[];

    constructor(e?) {
        super(e);

        if (e) {
            this.memberId = e.memberId;
            this.subscriberMemberId = e.subscriberMemberId;
            this.socialSecurityNumber = e.socialSecurityNumber;
            this.firstName = e.firstName;
            this.lastName = e.lastName;
            this.middleName = e.middleName;
            this.suffix = e.suffix;
            this.birthDate = e.birthDate ? new Date(e.birthDate) : null;
            this.birthSexId = e.birthSexId;
            this.genderIdentityId = e.genderIdentityId;
            this.addressIsSameAsSubscriberInd = e.addressIsSameAsSubscriberInd;
            this.relationshipTypeId = e.relationshipTypeId;
            this.relationshipQualifyReasonId = e.relationshipQualifyReasonId;
            this.partnershipStartDate = e.partnershipStartDate ? new Date(e.partnershipStartDate) : null;
            this.terminationReasonId = e.terminationReasonId;
            this.adoptionDate = e.adoptionDate ? new Date(e.adoptionDate) : null;
            this.relationshipTypeName = e.relationshipTypeName;
            this.memberMedicare = e.memberMedicare ? new MemberMedicare(e.memberMedicare) : new MemberMedicare();
            this.medicalInd = e.medicalInd;
            this.medicalReasonId = e.medicalReasonId;
            this.dentalInd = e.dentalInd;
            this.dentalReasonId = e.dentalReasonId;
            this.tobaccoResponseId = e.tobaccoResponseId;
            this.spousalResponseId = e.spousalResponseId;
            this.spousalAnswers = e.spousalAnswers ? e.spousalAnswers.map((s) => new Answer(s)) : [];
        }
    }
}
