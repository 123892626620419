<form #form="ngForm" *ngIf="innerAddress">
  <ng-template #addressLineOneControl>
    <label [for]="name + '-addressLineOne'">{{labels.addressLineOne}}</label>
    <input
      *ngIf="controlView === 'input'"
      #addressLineOne
      maxlength="30"
      pattern="[a-zA-Z0-9#.\- ]*"
      [required]="innerRequired"
      type="text"
      class="form-control"
      [id]="name + '-addressLineOne'"
      [name]="name + '-addressLineOne'"
      [(ngModel)]="innerAddress.addressLineOne"
      placeholder="Street #, Street"
      [disabled]="innerDisabled"
      [readonly]="innerReadonly"
      required
      (blur)="controlBlurred()"
    />
    <div *ngIf="controlView === 'text'" style="font-weight: bold">
      {{innerAddress?.addressLineOne}}
    </div>
    <div *ngIf="form?.addressLineOne?.invalid && (form?.addressLineOne?.dirty || form?.addressLineOne?.touched) && !!form?.addressLineOne?.errors?.pattern" class="alert alert-danger">
      Must contain only letters, numbers, spaces or the following special characters: "#" "." and "-".
    </div>
  </ng-template>
  
  <ng-template #addressLineTwoControl>
    <label [for]="name + '-addressLineTwo'">{{labels.addressLineTwo}}</label>
    <input
      *ngIf="controlView === 'input'"
      #addressLineTwo
      maxlength="30"
      pattern="[a-zA-Z0-9#.\- ]*"
      type="text"
      class="form-control"
      [id]="name + '-addressLineTwo'"
      [name]="name + '-addressLineTwo'"
      [(ngModel)]="innerAddress.addressLineTwo"
      [disabled]="innerDisabled"
      [readonly]="innerReadonly"
      placeholder="Unit #, Suite #"
      (blur)="controlBlurred()"
    />
    <div *ngIf="controlView === 'text'" style="font-weight: bold">
      {{innerAddress?.addressLineTwo}}
    </div>
    <div *ngIf="form?.addressLineTwo?.invalid && (form?.addressLineTwo?.dirty || form?.addressLineTwo?.touched) && !!form?.addressLineTwo?.errors?.pattern" class="alert alert-danger">
      Must contain only letters, numbers, spaces or the following special characters: "#" "." and "-".
    </div>
  </ng-template>
  
  <ng-template #cityControl>
    <label [for]="name + '-city'">{{labels.city}}</label>
    <input
      *ngIf="controlView === 'input'"
      #city
      maxlength="20"
      pattern="[a-zA-Z0-9#.\- ]*"
      [required]="innerRequired"
      type="text"
      class="form-control"
      [id]="name + '-city'"
      [name]="name + '-city'"
      [disabled]="innerDisabled"
      [readonly]="innerReadonly"
      [(ngModel)]="innerAddress.city"
      (blur)="controlBlurred()"
    />
    <div *ngIf="controlView === 'text'" style="font-weight: bold">
      {{innerAddress?.city}}
    </div>
    <div *ngIf="form?.controls && form.controls[name + '-city']?.invalid && (form.controls[name + '-city']?.dirty || form.controls[name + '-city']?.touched) && !!form.controls[name + '-city'].errors?.pattern" class="alert alert-danger">
      Must contain only letters, numbers, spaces or the following special characters: "#" "." and "-".
    </div>
  </ng-template>
  
  <ng-template #stateControl>
    <label [for]="name + '-state'">{{labels.state}}</label>
    <select
      *ngIf="controlView === 'input'"
      #state
      [(ngModel)]="innerAddress.state"
      (ngModelChange)="filteredCounties = stateChange(innerAddress, filteredCounties)"
      [disabled]="innerReadonly || innerDisabled || ((innerAddress | hasCountry) && innerAddress?.countryId != unitedStates?.countryId && innerAddress?.countryId != canada?.countryId)"
      class="form-control"
      [id]="name + '-state'"
      [name]="name + '-state'"
      [required]="innerRequired"
      (blur)="controlBlurred()"
    >
      <option></option>
      <option *ngFor="let stateCode of filteredStates" [ngValue]="stateCode">{{ stateCode }}</option>
    </select>
    <div *ngIf="controlView === 'text'" style="font-weight: bold">
      {{innerAddress?.state}}
    </div>
    <ng-template #stateTooltip>
      <span> Standard US state abbreviation or Canadian Province Code. NOTE: If address is outside US or Canada, populate this field with ZZ. </span>
    </ng-template>
  </ng-template>
  
  <ng-template #countyControl>
    <label *ngIf="!innerHideCounty" [for]="name + '-countyId'">{{labels.county}}</label>
    <select
      #county
      *ngIf="!innerHideCounty && controlView === 'input'"
      [required]="innerRequired"
      class="form-control"
      [id]="name + '-countyId'"
      [name]="name + '-countyId'"
      [(ngModel)]="innerAddress.countyId"
      [disabled]="innerReadonly || innerDisabled || !filteredCounties?.length"
      (blur)="controlBlurred()"
    >
      <option></option>
      <option *ngFor="let county of filteredCounties" [ngValue]="county.countyId">{{ county.countyName }}</option>
    </select>
    <div *ngIf="controlView === 'text' && !innerHideCounty" style="font-weight: bold">
      {{getCountyName(innerAddress?.countyId)}}
    </div>
  </ng-template>
  
  <ng-template #zipcodeControl>
    <ng-template #zipTooltip>
      <span>Must be between 5 and 10 characters long, please include (-) if including +4 US Zip</span>
    </ng-template>
    <label [for]="name + '-zipcodeNumber'">{{labels.zipCode}}</label>
    <input
      *ngIf="controlView === 'input'"
      #zipcodeNumber
      [required]="innerRequired"
      [pattern]="zipPattern"
      [minlength]="zipMin"
      maxlength="10"
      type="text"
      class="form-control"
      [id]="name + '-zipcodeNumber'"
      [name]="name + '-zipcodeNumber'"
      [(ngModel)]="innerAddress.zipcodeNumber"
      [disabled]="innerDisabled"
      [readonly]="innerReadonly"
      [ngbTooltip]="isUS && zipTooltip"
      (blur)="controlBlurred()"
    />
    <div *ngIf="controlView === 'text'" style="font-weight: bold">
      {{innerAddress?.zipcodeNumber}}
    </div>
  </ng-template>
  
  <ng-template #countryControl>
    <label *ngIf="(innerAddress | hasCountry)" [for]="name + '-countryId'">{{labels.country}}</label>
    <select
      #country
      *ngIf="(innerAddress | hasCountry) && controlView === 'input'"
      [required]="innerRequired"
      class="form-control"
      [id]="name + '-countryId'"
      [name]="name + '-countryId'"
      [(ngModel)]="innerAddress.countryId" 
      [disabled]="innerReadonly || innerDisabled"
      (ngModelChange)="handleCountryChange(innerAddress, true)"
      (blur)="controlBlurred()"
    >
      <option *ngFor="let country of countries" [ngValue]="country.countryId">{{ country.countryName }}</option>
    </select>
    <div *ngIf="(innerAddress | hasCountry) && controlView === 'text'" style="font-weight: bold">
      {{getCountryName(innerAddress?.countryId)}}
    </div>
  </ng-template>
  
  <div class="card pad-std-container" fxFlexFill *ngIf="view === 'card'">
    <div class="card-header hca-selectable-header" *ngIf="headerLabel">
      <h3 class="h5">
        <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px" [icon]="labelIcon" size="2x"></fa-icon>
        {{headerLabel}}
      </h3>
    </div>
    <div fxFlex="95">
      <div class="form-row">
        <div fxFlexFill class="form-group" [class.required]="innerRequired">
          <ng-container *ngTemplateOutlet="addressLineOneControl"></ng-container>
        </div>
      </div>
      <div class="form-row">
        <div fxFlexFill class="form-group">
          <ng-container *ngTemplateOutlet="addressLineTwoControl"></ng-container>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex="95" fxLayout.lt-md="column">
      <div class="form-group" [class.required]="innerRequired" [fxFlex]="innerHideCounty ? 50 : 25" fxFlex.lt-md="95">
        <ng-container *ngTemplateOutlet="cityControl"></ng-container>
      </div>
      <div class="form-group" [class.required]="innerRequired" fxFlex="20" fxFlex.lt-md="95">
        <ng-container *ngTemplateOutlet="stateControl"></ng-container>
      </div>
      <div *ngIf="!innerHideCounty" class="form-group" [class.required]="innerRequired" fxFlex="25" fxFlex.lt-md="95">
        <ng-container *ngTemplateOutlet="countyControl"></ng-container>
      </div>
      <div class="form-group" [class.required]="innerRequired" fxFlex="15" fxFlex.lt-md="95">
        <ng-container *ngTemplateOutlet="zipcodeControl"></ng-container>
      </div>
    </div>
    <div class="form-row" fxLayout="row" fxFlex="40" fxLayoutAlign="space-between" fxFlex.lt-md="95">
      <div class="form-group" [class.required]="innerRequired">
        <ng-container *ngTemplateOutlet="countryControl"></ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="view === 'panel'">
    <div fxFlex="0" fxFlex.gt.sm="10"></div>
      <div fxFlexLayout="column" fxFlex="100" fxFlex.gt-sm="75" fxFlexLayoutAlign="center center">
        <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly">
          <div class="form-group" [class.required]="innerRequired" fxFlexFill>
            <ng-container *ngTemplateOutlet="addressLineOneControl"></ng-container>
          </div>
          <div class="form-group" fxFlexFill>
            <ng-container *ngTemplateOutlet="addressLineTwoControl"></ng-container>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
          <div class="form-group" [class.required]="innerRequired" [fxFlex]="innerHideCounty ? 50 : 25" fxFlex.lt-md="95">
            <ng-container *ngTemplateOutlet="cityControl"></ng-container>
          </div>
          <div class="form-group" [class.required]="innerRequired" fxFlex="30" fxFlex.lt-md="95">
            <ng-container *ngTemplateOutlet="stateControl"></ng-container>
          </div>
          <div *ngIf="!innerHideCounty" class="form-group" [class.required]="innerRequired" fxFlex="25" fxFlex.lt-md="95">
            <ng-container *ngTemplateOutlet="countyControl"></ng-container>
          </div>
          <div class="form-group" [class.required]="innerRequired" fxFlex="15" fxFlex.lt-md="95">
            <ng-container *ngTemplateOutlet="zipcodeControl"></ng-container>
          </div>
        </div>
        <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
          <div class="form-group" [class.required]="innerRequired" fxFlex="40" fxFlex.lt-md="95">
            <ng-container *ngTemplateOutlet="countryControl"></ng-container>
          </div>
        </div>
      </div>
      <div fxFlex="0" fxFlex.gt.sm="10"></div>
  </div>

  <div *ngIf="view === 'inline-dependent' || view === 'inline'">
    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
      <div class="form-group" [class.required]="innerRequired" fxFlex="100">
        <ng-container *ngTemplateOutlet="addressLineOneControl"></ng-container>
      </div>
    </div>
    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
      <div class="form-group" [class.required]="innerRequired" fxFlex="100">
        <ng-container *ngTemplateOutlet="addressLineTwoControl"></ng-container>
      </div>
    </div>
    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
      <div class="form-group" [class.required]="innerRequired" fxFlex="70">
        <ng-container *ngTemplateOutlet="cityControl"></ng-container>
      </div>
      <div class="form-group" [class.required]="innerRequired" fxFlex="20">
        <ng-container *ngTemplateOutlet="stateControl"></ng-container>
      </div>
    </div>
    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
      <div class="form-group" [class.required]="innerRequired" fxFlex="30">
        <ng-container *ngTemplateOutlet="zipcodeControl"></ng-container>
      </div>
      <div class="form-group" [class.required]="innerRequired" fxFlex="60">
        <ng-container *ngTemplateOutlet="countyControl"></ng-container>
      </div>
      <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
        <div class="form-group" [class.required]="innerRequired" fxFlex="40" fxFlex.lt-md="95">
          <ng-container *ngTemplateOutlet="countryControl"></ng-container>
        </div>
      </div>
    </div>
  </div>
</form>