import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ObservableLike } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Organization from '../models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getOrganizations(): Observable<Organization[]> {
    return this.http
      .get<Organization[]>(`${env.apiUrl}/organization`)
      .pipe(map(x => this.commonService.createObjects(Organization, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getOrganizationById(id: string): Observable<Organization> {
    return this.http
      .get(`${env.apiUrl}/organization/${id}`)
      .pipe(map(x => this.commonService.createObject(Organization, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createOrganization(organization: Organization): Observable<Organization> {
    return this.http
      .post<Organization>(`${env.apiUrl}/organization`, organization)
      .pipe(map(x => this.commonService.createObject(Organization, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http
      .put<Organization>(`${env.apiUrl}/organization/${organization?.organizationId}`, organization)
      .pipe(map(x => this.commonService.createObject(Organization, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getOrganizationsForAllAgencies(): Observable<Organization[]> {
    return this.http
      .get<Organization[]>(`${env.apiUrl}/organization/agencies/all`)
      .pipe(map(x => this.commonService.createObjects(Organization, x)))
      .pipe(catchError(this.commonService.handleError));
  }

}
