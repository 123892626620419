/**
 * The root component. All other states are injected based on routing in this components
 * router-outlet. Global componenets (agency select, header, footer) declared here.
 */

// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';

// ext
import { lastValueFrom, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import { faCalendar, faHome, faUndo, faEnvelope, faPhone, faAt, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { find, includes, isEqual, cloneDeep, sortBy, maxBy, endsWith, some, forEach, remove, filter } from 'lodash';
import { env } from 'src/env/development';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import SystemUser from 'src/app/models/user';
import Subscriber from 'src/app/models/subscriber';
import { ActivatedRoute, Router } from '@angular/router';
import Member from 'src/app/models/member';
import MemberAddress from 'src/app/models/memberAddress';
import SubscriberContact from 'src/app/models/subscriberContact';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import Ethnicity from 'src/app/models/ethnicity';
import { MemberService } from 'src/app/services/member.service';
import Enrollment from 'src/app/models/enrollment';

@UntilDestroy()
@Lookups(LookupType.GenderIdentity, LookupType.RelationshipQualifyReason, LookupType.RelationshipVerificationStatus,
         LookupType.MemberType, LookupType.RelationshipType, LookupType.BirthSex, LookupType.County, LookupType.Country,
         LookupType.AddressType, LookupType.PhoneNumberType, LookupType.PreferredContactMethod, LookupType.Language, LookupType.Ethnicity, LookupType.ApplicationSetting)
@Component({
  selector: 'app-root',
  templateUrl: 'profile.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit, OnDestroy {
  icons = {
    faCalendar,
    faHome,
    faUndo,
    faEnvelope,
    faPhone,
    faAt,
    faMobileAlt,
    faPlus,
    faMinus
  };
  subscriber: Subscriber;
  member: Member;
  lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    phoneNumberTypes: [],
    preferredContactMethods: [],
    languages: [],
    applicationSetting: []
  };
  systemUser: SystemUser;
  isAdmin = false;
  isHCAAdmin = false;
  subscriberContact = new SubscriberContact();
  showTermsAndConditions = false;
  optedInToEmail = false;
  isRead = false;
  optInLabel: string = "";
  agencyCode: string = "";
  ethnicities: Ethnicity[];
  displayRaceEthnicity = false;
  isSubscriber = true;
  constructor(
    private spinnerService: SpinnerOverlayService,
    private coreService: CoreService,
    private subscriberService: SubscriberService,
    private route: ActivatedRoute, private router: Router,
    private memberService: MemberService
  ) {
  }

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.systemUser = data.user;
      this.coreService.getSystemUser().pipe(untilDestroyed(this)).subscribe((r: SystemUser) => {
        this.systemUser = r;
        this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
        this.isRead = this.systemUser && this.systemUser.systemUserId && !this.systemUser.memberId && !this.coreService.systemUserHasAccess(AccessLevel.Edit);
      });
      this.ethnicities = data.lookups.ethnicity;
      this.lookups.county = sortBy(data.lookups.county, 'countyName');
      this.lookups.addressType = data.lookups.addressType;
      this.lookups.country = sortBy(data.lookups.country, 'countryName');
      this.lookups.birthSexes = sortBy(data.lookups.birthSex, 'birthSexName');
      this.lookups.genderIdentities = sortBy(data.lookups.genderIdentity, 'genderIdentityName');
      this.lookups.preferredContactMethods = sortBy(data.lookups.preferredContactMethod, 'preferredContactMethodName');
      this.lookups.languages = sortBy(data.lookups.language, 'languageName');
      this.lookups.relationshipQualifyReasons = sortBy(data.lookups.relationshipQualifyReason, 'relationshipQualifyReasonName');
      this.lookups.memberType = sortBy(data.lookups.memberType, 'memberTypeName');
      this.lookups.applicationSetting = data.lookups.applicationSetting;
      this.lookups.phoneNumberTypes = sortBy(
        filter(data.lookups.phoneNumberTypes, (p) => p.phoneNumberTypeName !== 'Cell'),
        'phoneNumberTypeName'
      );
      this.lookups.relationshipVerificationStatus = sortBy(data.lookups.relationshipVerificationStatus, 'relationshipVerificationStatusName');
      this.lookups.relationshipTypes = sortBy(
        filter(data.lookups.relationshipTypes, (r) => r.relationshipTypeName !== 'SubscriberFor'),
        'relationshipTypeName'
        );
        this.subscriber = data.subscriber;
        this.displayRaceEthnicity = this.subscriberOrDependentsAreMedicareEnrolled() && some(this.subscriber.enrollments, (e: Enrollment) => env.medicareAdvantagePlanCodes.includes(e.plan?.planCode)) && some(this.lookups?.applicationSetting, (as) => as.applictionSettingCode === 'MAPD' && as.response?.responseCode == 'Y');
        this.subscriberContact.memberId = data.subscriber.memberId;
        this.subscriberContact.emailNotificationInd = data.subscriber.emailNotificationInd;
        this.subscriberContact.emailAddress = data.subscriber.emailAddress;
        this.subscriberContact.homePhoneNumber = data.subscriber.homePhoneNumber;
        this.subscriberContact.workPhoneNumber = data.subscriber.workPhoneNumber;
        this.subscriberContact.cellPhoneNumber = data.subscriber.cellPhoneNumber;
        this.optedInToEmail = this.subscriberContact.emailNotificationInd;
        if (!data.subscriber.mailingAddress) {
          data.subscriber.mailingAddress = new MemberAddress();
        }
       // this.isSubscriber = this.coreService.systemUserIsSubscriber(this.subscriber.memberId);
        this.agencyCode = this.subscriber.organization?.agency?.agencyCode;
        this.optInLabel="Opt-in to receive email notifications " + (this.agencyCode ? "from " + this.agencyCode : "") + 
          " for newsletters, general communications, and any urgent/critical communications related to emergency situations (fires, pandemics, etc.).";
          
    });
  }

  ngOnDestroy(): void {

  }

  emailSubscriptionChanged(): void {
    if (this.optedInToEmail === false) {
      this.showTermsAndConditions = false;
      this.subscriberContact.emailNotificationInd = false;
    } else {
      this.showTermsAndConditions = true;
    }
  }

  respondToTermsAndConditions(accepted: boolean): void {
    this.showTermsAndConditions = false;
    this.subscriberContact.emailNotificationInd = accepted;
    this.optedInToEmail = accepted;
  }

  refreshSubscriberContact(): void {
    this.subscriberContact.memberId = this.subscriber.memberId;
    this.subscriberContact.emailNotificationInd = this.subscriber.emailNotificationInd;
    this.subscriberContact.emailAddress = this.subscriber.emailAddress;
    this.subscriberContact.homePhoneNumber = this.subscriber.homePhoneNumber;
    this.subscriberContact.workPhoneNumber = this.subscriber.workPhoneNumber;
    this.subscriberContact.cellPhoneNumber = this.subscriber.cellPhoneNumber;
  }

  refreshSubscriberModel(): void {
    this.subscriber.emailNotificationInd = this.subscriberContact.emailNotificationInd;
    this.subscriber.emailAddress = this.subscriberContact.emailAddress;
    this.subscriber.homePhoneNumber = this.subscriberContact.homePhoneNumber;
    this.subscriber.workPhoneNumber = this.subscriberContact.workPhoneNumber;
    this.subscriber.cellPhoneNumber = this.subscriberContact.cellPhoneNumber;
  }

  async submitSubscriberContact(): Promise<void> {
    if ((!this.subscriberContact.emailAddress || !this.subscriberContact.emailAddress.length) && this.optedInToEmail) {
      this.coreService.popMessage('A valid email address is required to opt-in', 'error', 5000);
    } else {
      this.spinnerService.show();
      try {
        this.subscriberContact = await lastValueFrom(this.subscriberService.updateSubscriberContact(this.subscriberContact));
        this.refreshSubscriberModel();
        this.coreService.setSubscriber(this.subscriber);
        this.spinnerService.hide();
        this.coreService.popMessage('You have successfully updated your contact information!', 'success', 5000);
      } catch (err) {
        this.spinnerService.hide();
        this.coreService.popMessage('Something went wrong updating your contact information! Please try again', 'error', 5000);
        console.log(err);
      }
    }
  }

  async saveMemberEthnicities(e: string[]) {
    try {
      this.spinnerService.show();
      await lastValueFrom(this.memberService.createMemberEthnicities(this.subscriber.memberId, e));
      this.spinnerService.hide();
    }catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong updating your contact information! Please try again', 'error', 5000);
      console.log(err);
    }
  }

  async saveMemberLanguage(e: string[]) {
    try {
      this.spinnerService.show();
      await lastValueFrom(this.memberService.createMemberEthnicities(this.subscriber.memberId, e));
      this.spinnerService.hide();
    }catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong updating your contact information! Please try again', 'error', 5000);
      console.log(err);
    }
  }

  subscriberOrDependentsAreMedicareEnrolled(): boolean {
    return this.subscriber?.memberMedicare?.medicarePartAEffectiveDate != null || some(this.subscriber?.members, (d: Member) => d.memberMedicare?.medicarePartAEffectiveDate);
  }
}
