<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid" *ngIf="subscriber">
  <div class="state">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body">
        <h2 class="mobile-text-centered mgtp-x-small mgbt-medium-sm">{{ inAdminState ? 'Subscriber\'s' : 'My'}} Current Coverage</h2>
        <h3 class="h4 mobile-text-centered">
            <b>This page displays coverage effective as of today.</b>
        </h3>
        <div fxLayout="column">
          <div class="h6" role="heading" aria-level="4">Use this page to perform the following actions:</div>
          <ul>
            <li>Review current account information and coverage selections</li>
            <li>View/print Statement of Insurance</li>
            <li>Subscribe or unsubscribe from email notifications</li>
          </ul>
        </div>
        <div id="soi-button-container" fxLayout="row wrap" style="padding-bottom:20px;">
          <h3 class="h4" fxFlexFill>
            Select the <i>Statement of Insurance</i> button to get a PDF statement showing all insurance coverages, except supplemental life and accidental death and dismemberment insurance, as of today.
            Go to the <a target="_blank" href="https://online.metlife.com/edge/web/public/benefits">MetLife MyBenefits portal</a> to view supplemental life and accidental death and dismemberment insurance.
          </h3>
          <button id="soi-button" type="button" class="btn btn-primary" (click)="exportStatementOfInsurance()">
            <span class="k-icon k-i-print"></span> Statement of Insurance
          </button>
        </div>
        <div class="card mgbt-medium">
          <div class="mobile-text-centered card-header"><h4 class="h5">Section A - Subscriber account information</h4></div>
          <summary-overview
            [inAdminState]="inAdminState"
            [subscriber]="subscriber"
            [subscriberModel]="subscriberModel"
            [subscriberContact]="subscriberContact"
            [isFullBenefits]="isFullBenefits"
            (emailSubscriptionUpdated)="updateEmailIndicator($event)"
          ></summary-overview>
        </div>
        <div class="card">
          <div class="mobile-text-centered card-header"><h4 class="h5">Section B - Subscriber and/or dependent coverage information</h4></div>
          <summary-coverage
            [inAdminState]="inAdminState"
            [memberSummary]="subscriber.memberSummary"
            [electablePlanTypesForCurrentPeriod]="electablePlanTypesForCurrentPeriod"
            [spousalCoverageSurcharge]="subscriber.spousalCoverageSurchargeInd"
          ></summary-coverage>
        </div>
      </div>
    </div>
    <pdf #pdf [documentTitleText]="documentTitleText" [documentHeaderText]="documentHeaderText" [documentFooterText]="documentFooterText" aria-hidden="true">
      <coverage-summary [forPdf]="true" [documentTitleText]="documentTitleText" [documentHeaderText]="documentHeaderText" [enrolledHeader]="enrolledHeader" documentFooterText="documentFooterText" [subscriber]="subscriberModel" [summaryObject]="subscriber"></coverage-summary>
    </pdf>
  </div>
</div>
