import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core';
import { DataStateChangeEvent, DetailExpandEvent, GridDataResult, PageChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State, orderBy, filterBy, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { cloneDeep, filter, forEach, findIndex, find, some } from 'lodash';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { DocumentService } from 'src/app/services/document.service';
import Document from 'src/app/models/document';
import SystemUser from 'src/app/models/user';
import SelfPay from 'src/app/models/selfPay';
import SelfPayGrid from 'src/app/models/selfPayGrid';
import SelfpayVerificationStatus from 'src/app/models/selfPayVerificationStatus';
import { SelfPayService } from 'src/app/services/selfPay.service';
import { CoreService } from 'src/app/services/core.service';
import { PDFComponent } from 'src/app/modules/shared/components/pdf/pdf.component';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { SelfpayFormComponent } from './components/reviewForm/selfpay.form.component';
import { SubscriberService } from 'src/app/services/subscriber.service';
import Plan from 'src/app/models/plan';
import SelfPayElection from 'src/app/models/selfPayElections';
import * as dayjs from 'dayjs';
import { lastValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { env } from 'src/env/development';

@UntilDestroy()
@Component({
  selector: 'selfpay-admin-grid',
  templateUrl: 'selfpay.admin.grid.component.html',
  styleUrls: [],
  providers: [],
})
export class SelfpayAdminGridComponent implements OnInit, OnChanges {
  @Input() gridData: GridDataResult;
  @Input() gridColumns;
  @Input() systemUser: SystemUser;
  @Input() lookups = {
    selfPayVerificationStatus: [],
    documentTypes: [],
    selfPayProcessStatus: [],
    selfPayPaymentTypes: [],
    genderIdentities: [],
    birthSexes: [],
    county: [],
    country: [],
    addressType: [],
    homeAgencies: [],
    memberTypes: [],
    eligibililtyReasons: [],
    enrollmentReasons: [],
    originalAgencies: [],
    cobraQualifyReasons: [],
    relationshipTypes: [],
    relationshipQualifyReasons: [],
    reason: [],
    responses: [],
    questions: [],
    attestationsTypes: []
  };
  @Input() availablePlans: Plan[];
  @Input() supplementalPlans: Plan[];
  @Input() hcaUsers: SystemUser[];
  @Input() userCanEdit: boolean;

  @Input() state: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  pageable = true;
  expandedDetailKeys = [];
  @Input() selectedDataItem: SelfPay;
  downloading = false;
  dayjs = dayjs;
  currentSelfpayEnrollment: SelfPayElection;
  selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'multiple',
    drag: true,
  };
  selectedRows: SelfPayGrid[] = [];
  showSaveChanges = false;
  showAssignToPanel = false;
  assignToUser;
  userData;
  soeCustomForm: boolean = false;
  formTitle: string = "Employee Enrollment/Change Request";
  formHeaderText: string = "'THIS IS A SUMMARY OF YOUR REQUESTED COVERAGE ELECTION CHANGES WITH THE HEALTH CARE AUTHORITY. THIS IS NOT A STATEMENT OF INSURANCE.'";
  pdfContainerClass: string = "";
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;
  @ViewChild('review') public selfpayReviewFormComponent: SelfpayFormComponent;
  @ViewChild('selfPayPDF') selfPayPDF: PDFComponent;
  @Output() pushSaveSelfpayReview: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() saveAndContinue: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() saveSelfPayElections: EventEmitter<SelfPayElection> = new EventEmitter<SelfPayElection>();
  @Output() pushGetData: EventEmitter<State> = new EventEmitter<State>();
  // @ViewChild('selfpayPDF') soePDF: PDFComponent;

  constructor(
    private documentService: DocumentService,
    private coreService: CoreService,
    private subscriberService: SubscriberService,
    private spinnerService: SpinnerOverlayService,
    private selfpayService: SelfPayService
    ) {}

  ngOnInit(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: 'multiple',
      drag: true,
    };
  }

  ngOnChanges(): void {
    this.userData = this.hcaUsers?.slice();
  }

  reSetDataForGrid(): void {
    this.pushGetData.emit(this.state);
  }

  outputField(dataItem, col): any {
    const val = dataItem[col.field];

    if (typeof col.format === 'function') {
      return col.format(val);
    }

    if (val && col.format?.date) {
      return dayjs(val.replace("Z","")).format(col.format.date.toUpperCase());
    }

    if (col.format === "boolean") {
      return (val ? 'Yes' : 'No');
    }

    return val;
  }

  async getRowDetails(event: DetailExpandEvent): Promise<void> {
    this.spinnerService.show();
    // because full object on fetch by id and overwrites
    const name = event.dataItem.subscriberName;
    const type = event.dataItem.selfPayTypeName;
    this.selectedDataItem = await lastValueFrom(this.selfpayService.getSelfPayById(event.dataItem.selfPayId, event.dataItem.memberId));
    this.selfpayService.logSelfPayView(event.dataItem.selfPayId, event.dataItem.memberId).pipe(untilDestroyed(this)).subscribe();
    this.selectedDataItem.selfPayTypeName = type;
    this.selectedDataItem.subscriberName = name;
    if (env.soeSelfPayTypeCodesFormRequired.includes(this.selectedDataItem.selfPayType.selfPayTypeCode)) {
      this.soeCustomForm = true;
      this.formTitle = "Change of Address";
      this.formHeaderText = "";
      this.pdfContainerClass = "custom-pdf-form";
    }

    const documents: Document[] = this.selectedDataItem.documents || [];
    event.preventDefault();
    this.gridData?.data?.forEach((gd, idx) => this.kendoGrid.collapseRow(idx));
    this.kendoGrid.expandRow(event.index);
    // fetch all the associated doc content
    forEach(documents, (document: Document) => {
      this.documentService.getMemberDocumentById(this.selectedDataItem?.memberId, document.documentId, document).pipe(untilDestroyed(this)).subscribe((doc) => {
        document = doc;
        const originalRecIndex = findIndex(this.gridData, (gd) => {
          return !!find(gd.documents, (d) => d.documentId === document.documentId);
        });
        const docIndex = findIndex(this.selectedDataItem.documents, (d) => d.documentId === document.documentId);
        this.selectedDataItem.documents[docIndex] = doc;
      });
    });

    this.spinnerService.hide();
    this.fetchSubscriberAndSetEP(this.selectedDataItem);
  }

  public dataStateChange(state: DataStateChangeEvent | State): void {
    this.state = state;
    this.pushGetData.emit(this.state);
  }

  public fetchSubscriberAndSetEP(selfpay: SelfPay, selfpayGrid: SelfPayGrid = null): void {
    this.currentSelfpayEnrollment = new SelfPayElection(selfpay.member);
    selfpay.member.soeEnrollmentPeriod = find(selfpay.member.allEnrollmentPeriods, (ep: EnrollmentPeriod) => ep.enrollmentPeriodId === selfpay.selfPayId);
    const index  = findIndex(this.gridData, (gd: SelfPay) => {
      return gd.selfPayId === selfpay.selfPayId;
    });
    this.gridData[index] = cloneDeep((selfpayGrid==null ? selfpay : selfpayGrid));
  }

  pushSaveVerification(dataItem: SelfPay, index): void {
    this.pushSaveSelfpayReview.emit(dataItem);
  }

  /// necessary because having it in the actual detail row was borking the grid
  downloadSelfpayPDF(dataItem: SelfPay): void {
    this.downloading = true;

    // need to park and revisit this for now - but may be achieveable -
      // opens fine in modal
      // needs size changes so scrollable / non blocking
      // needs draggable
    // const modalRef = this.modalService.open(SOEPDFComponent, {
    //   size: 'xl',
    //   backdrop: false,
    //   windowClass: 'soe-modal-window'
    // });
    // modalRef.componentInstance.soe = this.dataItem;

    // set timeout because of ngif and digest cycle
    setTimeout(() => {
      this.selfPayPDF.pdfElement.saveAs('SelfPay.pdf');
    });
  }

  public onExcelExport(e: any): void {
    const rows = e.workbook.sheets[0].rows;
    var colIndex = [];

    //on export uses raw grid data not grid column formatting and grid column formatting is not applying for dates
    //get the columns that are dates and manually format them
    rows.forEach(row => {
      // Store the order date column index
      if (row.type === 'header') {
        row.cells.forEach((cell, index) => {
          if (cell.value.endsWith('Date')) {
            colIndex.push(index);
            return;
          }
        });
      }

      // Use the column index to format the date cell value
      if (row.type === 'data') {
        row.cells.forEach((cell, index) => {
          if (colIndex.length>0 && cell.value!=null && colIndex.find(o=>o===index)!=null) {
            cell.value = dayjs(cell.value).format('MM/DD/YYYY');
          }
        });
      }
    });
  }

  selectionChange(e) {
    if(e.selectedRows?.length) {
      e.selectedRows.forEach((sr) => {
       const itm = find(this.selectedRows, s => s.selfPayId == sr.dataItem.selfPayId);
       if(!itm) {
        this.selectedRows.push(sr.dataItem);
       }
      })
    }
    if(e.deselectedRows?.length) {
      e.deselectedRows.forEach((dsr) => {
        const idx = findIndex(this.selectedRows, (r) => r.selfPayId === dsr.dataItem.selfPayId);
        if(idx !== -1) {
          this.selectedRows.splice(idx,1);
        }
      })
    }
  }

  selectedRowsContainsAssigned(systemUserId?: string) {
    return systemUserId ?  some(this.selectedRows, s => s.assignedToId === systemUserId) : some(this.selectedRows, s => s.assignedTo && s.assignedTo !== '');
  }

  assignSelectedRowsToSelf() {
    this.selectedRows.forEach(r => {
      r.assignedById = this.systemUser.systemUserId;
      r.assignedToId = this.systemUser.systemUserId;
      r.assignedDate = new Date();
    })
    this.showSaveChanges = true;
  }

  setAssignedToUser(systemUser) {
    this.selectedRows.forEach(r => {
      r.assignedById = this.systemUser.systemUserId;
      r.assignedToId = systemUser?.systemUserId;
      r.assignedDate = new Date();
    })
    this.showSaveChanges = true;
    this.showAssignToPanel = false;
  }


  unAssignSelectedRows() {
    this.selectedRows.forEach(r => {
      r.assignedById = null;
      r.assignedToId = null;
      r.assignedDate = new Date();
    })
    this.showSaveChanges = true;
  }


  async saveAllAssignmentChanges() {
    try {
      this.spinnerService.show();
      await lastValueFrom(this.selfpayService.saveAssignmentChanges(this.selectedRows));
      this.spinnerService.hide();
      this.reSetDataForGrid();
      this.showSaveChanges = false;
      this.selectedRows = [];
    }catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('An error occured', 'error', 3000);
      console.log(err)
    }
    
  }

  handleFilter(value) {
    this.userData = this.hcaUsers.filter(
      (s) => s.fullName?.toLowerCase()?.indexOf(value.toLowerCase()) !== -1
    );
  }
}
