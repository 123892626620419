import { CoreService } from './../../../../../../services/core.service';
// ng
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnChanges, OnDestroy, SimpleChanges, Input, EventEmitter, Output, HostListener, OnInit } from '@angular/core';

// ext
import { orderBy, find, forEach, map } from 'lodash';

// local
import DependentComposite from 'src/app/models/dependentComposite';
import RelationshipType from 'src/app/models/relationshipType';
import DependentDisplay from 'src/app/models/dependentDisplay';
import PlanType from 'src/app/models/planType';
import Response from 'src/app/models/response';
import { env } from 'src/env/development';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import Reason from 'src/app/models/reason';
import * as dayjs from 'dayjs';
import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import Enrollment from 'src/app/models/enrollment';
import Subscriber from 'src/app/models/subscriber';

@Component({
  selector: 'dep-review-all',
  templateUrl: 'dep.reviewAll.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentReviewAllComponent implements OnChanges, OnInit {
  @Input() dependents: DependentComposite[];
  @Input() electablePlanTypes: PlanType[];
  @Input() soe: SpecialOpenEnrollment;
  @Input() isSelfPay = false;
  @Input() subscriber: Subscriber;
  @Input() coverageEffectiveStartDate: Date;
  @Input() eventDate: Date;
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    subagencies: [],
    phoneNumberTypes: [],
    eligibilityReasons: [],
    terminationReasons: [],
    planTypes: [],
    responses: []
  };
  @Input() dependentLosesEligibilityReasonId: string;
  @Output() emitEditDependent: EventEmitter<DependentComposite> = new EventEmitter<DependentComposite>();
  @Output() okayToContinue: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveAndContinue: EventEmitter<DependentComposite> = new EventEmitter<DependentComposite>();
  @Output() emitBlockNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deathOrDivorceSoeSelected: EventEmitter<DependentComposite[]> = new EventEmitter<DependentComposite[]>();

  dependentDisplays: DependentDisplay[] = [];
  ableToHaveMedical = false;
  ableToHaveDental = false;
  ableToHaveVision = false;
  hasNonDisabledDependentOver26 = false;
  isDeathOrDivorceSoe = false;
  canEdit = false;
  env = env;
  deathReasonId: string;
  divorceReasonId: string;
  coverageEndDate: Date;
  isDivorce: boolean = null;

  @HostListener('scroll', ['$event.target'])
  onscroll(elem): void {
    const target = elem.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.okayToContinue.emit();
    }
  }

  constructor(private route: ActivatedRoute, private coreService: CoreService) {}

  ngOnInit(): void {
    this.dependentDisplays = this.reloadDependents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dependentDisplays = this.reloadDependents();
    const availablePlans = map(this.electablePlanTypes, 'planTypeCode');
    this.ableToHaveDental = availablePlans.includes('2');
    this.ableToHaveMedical = availablePlans.includes('1');
    this.ableToHaveVision = availablePlans.includes('6');

    this.isDeathOrDivorceSoe = this.soe?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName === 'Death or Divorce';

    this.canEdit = !this.soe || (this.soe && this.soe?.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionEnrollDependentsCode));
    if (this.isDeathOrDivorceSoe) {
      // Special override for special SOE
      this.canEdit = false;
    }

    this.deathReasonId = find(this.lookups.terminationReasons, (r: Reason) => r.reasonCode === '35')?.reasonId;
    this.divorceReasonId = find(this.lookups.terminationReasons, (r: Reason) => r.reasonCode === '42')?.reasonId;

    this.coverageEndDate = dayjs(this.coverageEffectiveStartDate).subtract(1, 'day').toDate();
  }

  reloadDependents(): DependentDisplay[] {
    this.hasNonDisabledDependentOver26 = false;
    this.emitBlockNext.emit(false);

    const newDependentDisplayArray: DependentDisplay[] = [];
    forEach(this.dependents, (dependent: DependentComposite) => {
      const newDependent = new DependentDisplay();
      const relationshipType: RelationshipType = find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt.relationshipTypeId === dependent.relationshipTypeId);
      if (this.soe?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName === 'Birth or adoption' && dependent.birthDate>this.coverageEffectiveStartDate &&
        dependent.member.enrollments && dependent.relationship.isPendingRelationship) {
        //newborn added in this SOE birth or adoption and birth date is more than the coverage effective start date of the enrollment period.  
        //Reset planTypeIds so the new born will show as enrolled.
        dependent.planTypeIds = map(dependent.member.getAllCoverageByDate(dependent.birthDate), (e: Enrollment) => e.plan.planTypeId);
      }

      newDependent.firstName = dependent.firstName;
      newDependent.lastName = dependent.lastName;
      newDependent.birthDate = dependent.birthDate;
      newDependent.relationshipTypeName = relationshipType ? relationshipType.relationshipTypeName : '';
      newDependent.enrolledInMedical = dependent.planTypeIds.includes(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.medicalPlanTypeCode).planTypeId);
      newDependent.enrolledInDental = dependent.planTypeIds.includes(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.dentalPlanTypeCode).planTypeId);
      newDependent.enrolledInVision = dependent.planTypeIds.includes(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.visionPlanTypeCode).planTypeId);
      newDependent.tobaccoUse = dependent.tobaccoResponseId === find(this.lookups.responses, (r: Response) => r.responseCode === 'Y').responseId;
      newDependent.spousalSurcharge = dependent.spousalResponseId === find(this.lookups.responses, (r: Response) => r.responseCode === 'Y').responseId;
      newDependent.memberId = dependent.memberId;
      newDependent.isSpouseInd = relationshipType ? relationshipType.relationshipTypeCode === 'S' : false ;
      newDependent.isStepChild = relationshipType ? relationshipType.relationshipTypeCode === 'P' : false ;
      newDependent.medicarePartAEnrolled = dependent?.memberMedicare?.medicarePartAEnrolledInd;
      newDependent.medicarePartBEnrolled = dependent?.memberMedicare?.medicarePartBEnrolledInd;
      newDependent.relationshipEffectiveStartDate = dependent?.relationship?.effectiveStartDate;

      if (this.isDeathOrDivorceSoe) {
        newDependent.lossOfEligibilityReasonId = dependent.terminationReasonId;

        if (dependent.terminationReasonId) {
          dependent.terminationDate = this.coverageEndDate;

          if (newDependent.isSpouseInd) {
            if (dependent.terminationReasonId === this.deathReasonId) {
              this.isDivorce = false;
            } else {
              this.isDivorce = true;
            }
          }
        }
      }

      // Check if the dependent is going to age out when this enrollment period coverage becomes effective, if
      // they are, list them as not enrolled.  They will need to be edited and provide certification information.
      if (!dependent.currentRelationshipQualifyReasonValidForDate(this.lookups.relationshipTypes, this.lookups.relationshipQualifyReasons, this.coverageEffectiveStartDate)) {
        this.hasNonDisabledDependentOver26 = true;
        newDependent.enrolledInDental = newDependent.enrolledInMedical = false;
      }

      newDependentDisplayArray.push(newDependent);
    });

    const deps = this.dependents.filter(d => d.terminationReasonId);
    setTimeout(() => this.deathOrDivorceSoeSelected.emit(deps), 0);

    return orderBy(newDependentDisplayArray, [ 'isSpouseInd' ], [ 'desc' ]);
  }

  confirmChanges(): void{
    this.saveAndContinue.emit();
  }

  editDependent(d: DependentDisplay): void {
    const correspondingDependentComposite = find(this.dependents, (dc: DependentComposite) => dc.memberId === d.memberId );
    this.emitEditDependent.emit(correspondingDependentComposite);
  }

  deathOrDivorce(dependent: DependentDisplay, terminationReasonId: string, isDeath: boolean): void {
    if (dependent.isSpouseInd) {
      var eventDate = new Date(this.soe.eventDate);
      var relationshipDate = new Date(dependent.relationshipEffectiveStartDate);
      if (dependent.lossOfEligibilityReasonId && eventDate<=relationshipDate) {
        dependent.lossOfEligibilityReasonId = null;
        this.coreService.popMessage('Death or divorce date cannot be less than or equal to the date the relationship was added.', 'error', 8000);
        return;
      }

      this.dependentDisplays.forEach((dep: DependentDisplay) => {
        if (dep.isStepChild) {
          if (terminationReasonId) {
            dep.lossOfEligibilityReasonId = this.dependentLosesEligibilityReasonId;
          } else {
            dep.lossOfEligibilityReasonId = null;
          }
        } else if (dep !== dependent && isDeath === false) {
          // Cannot set death for any other dependents, only step-children can be adjusted if spouse divorce selected
          dep.lossOfEligibilityReasonId = null;
        }
      });

      if (terminationReasonId) {
        this.isDivorce = !isDeath;
      } else {
        this.isDivorce = null;
      }
    }

    forEach(this.dependents, (dep: DependentComposite) => {
      const displayDep = find(this.dependentDisplays, (d: DependentDisplay) => d.memberId === dep.memberId);
      dep.terminationReasonId = displayDep.lossOfEligibilityReasonId;
      dep.terminationDate = displayDep.lossOfEligibilityReasonId ? this.coverageEndDate : null;
    });

    this.deathOrDivorceSoeSelected.emit(this.dependents.filter(d => d.terminationReasonId));
  }
}
