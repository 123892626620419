// ng
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// ext
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// local
import { CoverageComponent } from './coverage.component';
import { PlanSelectionComponent } from './components/planSelection/planSelection.component';
import { WaiveComponent } from './components/waive/waive.component';
import { CoverageReviewComponent } from './components/review/coveragereview.component';
import {PlanTypeManagementComponent} from './components/planTypeManagement/planTypeManagement.component';

@NgModule({
  declarations: [
  CoverageComponent,
  PlanSelectionComponent,
  PlanTypeManagementComponent,
  WaiveComponent,
  CoverageReviewComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    NgbModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    PDFExportModule,
    ProgressBarModule,
    UploadModule,
    OverlayModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [],
})
export class CoverageModule {}
