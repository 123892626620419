<div fxLayoutAlign="center center" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center"><h1>Confirm selections</h1></div>
        <div *ngIf="hasMedical && medicalChange?.planMessage && medicalChange?.planMessage !== ''">
          <h2 style="text-align: center">Medical Selection Message</h2>
          <div fxFlexFill class="info-container" style="overflow: auto; height: 300px">
            <span [innerHTML]="(isSelfPay && medicalChange?.planMessageSelfPay ? medicalChange?.planMessageSelfPay : medicalChange?.planMessage)"></span>
          </div>
        </div>
        <div *ngIf="hasDental && dentalChange?.planMessage && dentalChange?.planMessage !== ''">
          <h2 style="text-align: center">Dental Selection Message</h2>
          <div fxFlexFill class="info-container" style="overflow: auto; height: 300px">
            <span [innerHTML]="(isSelfPay && dentalChange?.planMessageSelfPay ? dentalChange?.planMessageSelfPay : dentalChange?.planMessage)"></span>
          </div>
        </div>
        <div *ngIf="hasVision">
          <h2 style="text-align: center">Vision Selection Message</h2>
          <div fxFlexFill class="info-container" style="overflow: auto; height: 300px">
            <span [innerHTML]="(isSelfPay && visionChange?.planMessageSelfPay ? visionChange?.planMessageSelfPay : visionChange?.planMessage)"></span>
          </div>
        </div>
        <div>
          <h2 style="text-align: center" class="mobile-text-centered">Please review the information below</h2>
          <h3 class="h4" style="text-align: center">If correct, select Confirm. To make a change, select previous.</h3>
        </div>
        <div fxLayoutAlign="center">
          <div fxFlexLayout="column">
            <ul class="hca-ordered-list">
              <li *ngIf="hasMedical && subscriberSummary.effectiveMedicalPlan !== subscriberMedicalPlanName">
                You requested to change {{ inAdminState ? "this subscriber's" : 'your' }} medical plan from {{ subscriberSummary.effectiveMedicalPlan }} to {{ subscriberMedicalPlanName }}.
              </li>
              <li *ngIf="hasMedical && subscriberSummary.effectiveMedicalPlan == subscriberMedicalPlanName">
                You have not made changes to {{ inAdminState ? "this subscriber's" : 'your' }} medical plan.
              </li>
              <li *ngIf="hasDental && subscriberSummary.effectiveDentalPlan !== subscriberDentalPlanName">
                You requested to change {{ inAdminState ? "this subscriber's" : 'your' }} dental plan from {{ subscriberSummary.effectiveDentalPlan }} to {{ subscriberDentalPlanName }}.
              </li>
              <li *ngIf="hasDental && subscriberSummary.effectiveDentalPlan == subscriberDentalPlanName">You have not made changes to {{ inAdminState ? "this subscriber's" : 'your' }} dental plan.</li>

              <li *ngIf="hasVision && subscriberSummary.effectiveVisionPlan !== subscriberVisionPlanName">
                You requested to change {{ inAdminState ? "this subscriber's" : 'your' }} vision plan from {{ subscriberSummary.effectiveVisionPlan }} to {{ subscriberVisionPlanName }}.
              </li>
              <li *ngIf="hasVision && subscriberSummary.effectiveVisionPlan == subscriberVisionPlanName">You have not made changes to {{ inAdminState ? "this subscriber's" : 'your' }} vision plan.</li>              

              <div *ngFor="let waiver of waiverMessages">
                <li *ngIf="(waiver.planTypeName === 'Dental' && hasDental) || (waiver.planTypeName === 'Medical' && hasMedical) || (waiver.planTypeName === 'Vision' && hasVision)">
                  You are choosing to waive {{ waiver.planTypeName }} coverage for yourself and all dependents. Please be aware of the following PEBB Program rules:
                  <ol>
                    <li>You must be enrolled in employer-based group medical, Medicare or a TRICARE plan to waive your enrollment in PEBB medical.</li>
                    <li>
                      Your next opportunity to enroll in PEBB {{waiver.planTypeName}} coverage is during the next annual open enrollment unless you or your dependent experience a special open
                      enrollment event that allows you to enroll in medical.
                    </li>
                  </ol>
                </li>
              </div>
            </ul>
            <div *ngIf="subscriberSummary?.subscriberDualProgramEnrolled">
              <h3 fxFlexFill class="hca-error-msgbox">
                <b>Please note</b>, the subscriber is currently enrolled in the {{subscriberSummary?.agencyCode == 'SEBB' ? 'PEBB' : 'SEBB'}} program. 
                Dual enrollment between programs is not allowed. Submitting this enrollment will terminate the coverage in {{subscriberSummary?.agencyCode == 'SEBB' ? 'PEBB' : 'SEBB'}}
                for subscriber and any enrolled dependents effective {{dayjs(enrollmentPeriod.coverageEffectiveStartDate).subtract(1, 'day').toDate() | date:'MM/dd/YYYY' }}
              </h3>
            </div>
          </div>
        </div>
        <div>
          <div class="hca-pdf-table-content info-container mgtp-medium-sm mgbt-medium-sm">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="20"><h4 style="text-align: center">Subscriber:</h4></div>
              <div fxFlex="20"><h4 style="text-align: center">Coverage effective date:</h4></div>
              <div *ngIf="hasMedical" [fxFlex]="hasMedical && hasDental ? 20 : 40">
                <h4 style="text-align: center">Medical Plan:</h4>
              </div>
              <div *ngIf="hasDental" [fxFlex]="hasMedical && hasDental ? 20 : 40">
                <h4 style="text-align: center">Dental Plan:</h4>
              </div>
              <div *ngIf="hasVision" [fxFlex]="20">
                <h4 style="text-align: center">Vision Plan:</h4>
              </div>
            </div>
            <div *ngFor="let member of subscriberSummary.memberSummary; let i = index; let isOdd=odd; let isEven=even"
              fxLayout="row" fxLayoutAlign="space-between end" [class.tableOdd]="isOdd" [class.tableEven]="isEven"
              class="force-standard-font"
              style="padding-bottom: 5px; padding-top: 5px;"
              >
              <div fxFlex="20">
                <h5 style="text-align: center">{{ member.lastName }}, {{ member.firstName }}</h5>
                <h5 style="text-align: center">DOB - {{ member.birthDate | date: 'MM/dd/yyyy' }}</h5>
              </div>
              <div fxFlex="20">
                <h5 style="text-align: center">{{ getEffectiveCoverageDate(member, i) | date: 'MM/dd/yyyy' }}</h5>
              </div>
              <div *ngIf="hasMedical" [fxFlex]="hasMedical && hasDental ? 20 : 40">
                <h5 style="text-align: center">{{ memberMedicalPlan(member) }}</h5>
              </div>
              <div *ngIf="hasDental" [fxFlex]="hasMedical && hasDental ? 20 : 40">
                <h5 style="text-align: center">{{ memberDentalPlan(member) }}</h5>
              </div>
              <div *ngIf="hasVision" [fxFlex]="20">
                <h5 style="text-align: center">{{ memberVisionPlan(member) }}</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="center center" class="mgtp-medium">
          <div class="pad-std-container" style="width: 800px; overflow-x: hidden; overflow-y: scroll" (scroll)="onscroll($event)" #coveragereviewbody id="coveragereviewbody">
            <span *ngIf="!isSebb">
              Enrollment is not complete until PEBB verifies the dependent's eligibility. I understand that if I'm applying to add a dependent to my PEBB insurance coverage, I must provide copies of
              documents that verify the dependent's eligibility within the PEBB Program's enrollment timelines, or the dependent will not be enrolled.
              <br /><br />
              Employees must enroll in PEBB dental, basic life, basic accidental death and dismemberment, and basic long-term disability insurance. Employees that elect to waive PEBB medical when they
              become newly eligible or during the annual open enrollment, must be enrolled in other employer-based group medical, a TRICARE plan, or Medicare. Employees that elect to waive PEBB medical
              due to a special open enrollment event must be enrolled in other employer-based group medical, a TRICARE plan, Medicare, Medicaid, or a state Children's Health Insurance Program (CHIP). If
              I waive medical, I understand I can enroll during the annual open enrollment period or no later than 60 days after a special open enrollment event as defined in PEBB Program rules. If I
              waive medical for myself, I cannot enroll my eligible dependents in medical.
              <br /><br />
              If I am eligible for the employer contribution toward PEBB benefits but do not waive or enroll in PEBB Program medical coverage, I will be enrolled by default as a single subscriber in
              Uniform Medical Plan (UMP) Classic. My dependents will not be enrolled. I will be charged a monthly $104 premium for medical coverage as well as a $25-per-account monthly tobacco use
              premium surcharge.
              <br /><br />
              I allow my employer to deduct money from my earnings to pay for insurance coverage and any applicable premium surcharges. If I am enrolling in a consumer-directed health plan with a health
              savings account (HSA), I must meet HSA eligibility conditions. I understand that my employer will contribute to an HSA on my behalf based on the information I have provided, and that there
              are limits to these contributions and my HSA contributions (if any) under federal tax law.
              <br /><br />
              This form replaces all Employee Enrollment/Change forms previously submitted.
            </span>
            <span *ngIf="isSebb">
              By submitting this form, I declare that the information I have provided is true, complete, and correct. If it isn't, or if I do not update this information within the timelines in the SEBB Program rules, to the extent permitted by federal and state laws, I must repay any claims paid by my health plans or premiums paid on my behalf.
              <br /><br />
              My dependents and I may also lose SEBB benefits as of the last day of the month we were eligible. To the extent permitted by law, the SEBB Program or my employer may retroactively terminate coverage for me and my dependents if I intentionally misrepresent eligibility.
              <br /><br />
              In addition, I understand that it is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.
              <br /><br />
              If adding a state-registered domestic partner to my account, I declare that my domestic partner and I have registered through the Washington Secretary of State's Office or another state.
              <br /><br />
              Enrollment of any dependent is not complete until the SEBB Program verifies the eligibility of my dependents. I understand that if I am applying to add a dependent to my SEBB health plan coverage, I must provide copies of documents that verify the dependent's eligibility within the SEBB Program's enrollment timelines, or the dependent will not be enrolled.
              <br /><br />
              Eligible employees must enroll in SEBB dental, vision, basic life, basic accidental death and dismemberment, and employer-paid long-term disability (LTD) insurance. Employees will be automatically enrolled in employee-paid LTD insurance. Employees that elect to waive SEBB medical coverage must be enrolled in other employer-based group medical, a TRICARE plan, or Medicare. If I waive medical coverage, I understand I can enroll during the annual open enrollment period or no later than 60 days after a special open enrollment event as defined in the SEBB Program rules. If I waive medical coverage for myself, I cannot enroll my eligible dependents in medical coverage.
              <br /><br />
              If I am eligible for the employer contribution toward SEBB benefits but do not waive or enroll in SEBB Program medical coverage, I will be enrolled automatically as a single subscriber in Uniform Medical Plan (UMP) Achieve 1. My dependents will not be enrolled. I will be charged a monthly $37 premium for medical coverage as well as a $25 monthly tobacco use premium surcharge. Other automatic plan enrollment for if I do not make elections include Uniform Dental Plan, MetLife Vision, basic life insurance, basic accidental death and dismemberment (AD&D) insurance, and, if applicable, employer-paid and employee-paid long-term disability (LTD) insurance.
              <br /><br />
              I allow my employer to deduct money from my earnings to pay for insurance coverage and applicable premium surcharges. I understand I am responsible for paying applicable tobacco use premium surcharge and spouse or state-registered domestic partner coverage premium surcharge in addition to my monthly medical premium.
              <br /><br />
              If I enroll in a high-deductible health plan with a health savings account (HSA), I must meet HSA eligibility conditions. I understand that my employer will contribute to an HSA on my behalf based on the information I have provided, and that there are limits to these contributions and my HSA contributions (if any) under federal tax law.
              <br /><br />
              I understand that my enrollment and my dependents' enrollment are subject to me abiding by all applicable deadlines and SEBB Program rules and policies. Failure to comply with applicable deadlines and SEBB Program rules and policies may result in my benefits selection being rejected or defaulted.
              <br /><br />
              This form replaces all enrollment forms previously submitted. Any changes made in SEBB My Account or SEBB enrollment or change forms submitted and dated later than this online enrollment will replace this online enrollment.
            </span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
