<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
    <div class="card-body">
      <p *ngIf="!isSOE">
          You can only have one draft/pending event at a time. You can edit a request that is in draft/pending status by clicking "edit" on the request.
          If you need to create a different type of request select the "delete" button on the existing draft/pending request and create a new request.
          COBRA requests can only be created if you are no longer eligibile for {{currentOrganizationAgencyCode}} 
          benefits based on employment status. If you are no longer eligible based on employment status and you cannot make a request, contact your previous employer.
          They may need to take action to end your employment status. Select the appropriate request type.
      </p>
      <form *ngIf="canAdd" #selfPayAddForm="ngForm" (ngSubmit)="emitSelfPay(selfPay)">
        <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
          <div class="form-group required" fxFlex="35">
            <label *ngIf="!isSOE" for="selfPayType">Select the appropriate request type.</label>
            <label *ngIf="isSOE" for="selfPayType">Select the applicable special open enrollment event</label>
            <select [(ngModel)]="selfPay.selfPayTypeId" required class="form-control" name="selfPayType" id="selfPayType">
              <option></option>
              <option *ngFor="let selfPayType of selfPayTypes" [ngValue]="selfPayType?.selfPayTypeId">{{ selfPayType?.selfPayTypeName }}</option>
            </select>
          </div>
          <div *ngIf="selfPay.selfPayTypeId" fxLayout="column" class="form-group" fxLayoutAlign="center center">
            <button typ="submit" class="btn btn-primary">
              <span *ngIf="!isSOE">Create request</span>
              <span *ngIf="isSOE">Add new event</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  