import { map } from 'lodash';
import MemberAccountCorrection from './memberAccountCorrection';

export default class SubscriberAccountCorrection extends MemberAccountCorrection {
  members: MemberAccountCorrection[];

  constructor(subscriberAccountCorrection?) {
    super(subscriberAccountCorrection);
    this.members = map(subscriberAccountCorrection.members || [], m => new MemberAccountCorrection(m));
  }

}
