import { OEService } from './../../../../services/oe.service';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SystemUser from 'src/app/models/user';
import County from 'src/app/models/county';
import AddressType from 'src/app/models/addressType';
import BirthSex from 'src/app/models/birthSex';
import GenderIdentity from 'src/app/models/genderIdentity';
import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import RelationshipType from 'src/app/models/relationshipType';
import MemberType from 'src/app/models/memberType';
import PhoneNumberType from 'src/app/models/phoneNumberType';
import PreferredContactMethod from 'src/app/models/preferredContactMethod';
import Language from 'src/app/models/language';
import { sortBy, endsWith, forEach, find, cloneDeep, findIndex, remove } from 'lodash';
import AttestationType from 'src/app/models/attestationType';
import DocumentType from 'src/app/models/documentType';
import PlanType from 'src/app/models/planType';
import Response from 'src/app/models/response';
import Status from 'src/app/models/Status';
import UserType from 'src/app/models/userType';
import OpenEnrollment from 'src/app/models/openEnrollment';
import Message from 'src/app/models/message';
import { MessageService } from 'src/app/services/message.service';
import { LookupService } from 'src/app/services/lookup.service';
import ApplicationSetting from 'src/app/models/applicationSettting';
import GenericMessageType from 'src/app/models/genericMessageType';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import Module from 'src/app/models/module';
import * as dayjs from 'dayjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.ApplicationSetting, LookupType.Module, LookupType.Agency)
@Component({
  selector: 'app-settings',
  templateUrl: 'app.settings.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class AppSettingsComponent implements OnInit {
  icons = {
    faPlus,
    faMinus,
  };
  systemUser: SystemUser;
  lookupsCollapsed = true;
  oeCollapsed = true;
  messagesCollapsed = true;
  systemWideCollapsed = true;
  openEnrollments: { openEnrollment: OpenEnrollment[] };
  applicationSettings: { applicationSetting: ApplicationSetting[] };
  messages: { systemMessages: Message[] };
  liveWork: boolean;
  isHcaSysAdmin = false;
  public lookups:
    | {
        addressTypes: AddressType[];
        attestationTypes: AttestationType[];
        birthSexes: BirthSex[];
        counties: County[];
        documentTypes: DocumentType[];
        genderIdentities: GenderIdentity[];
        languages: Language[];
        memberTypes: MemberType[];
        phoneNumberTypes: PhoneNumberType[];
        planTypes: PlanType[];
        preferredContactMethods: PreferredContactMethod[];
        relationshipTypes: RelationshipType[];
        relationshipQualifyReasons: RelationshipQualifyReason[];
        relationshipVerificationStatuses: RelationshipVerificationStatus[];
        responses: Response[];
        statuses: Status[];
        userType: UserType[];
        genericMessageTypes: GenericMessageType[];
      }
    | {} = {};

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private lookupService: LookupService,
    private coreService: CoreService,
    private router: Router,
    private openEnrollmentService: OEService
  ) {}

  ngOnInit(): void {
    this.messageService.getMessages().pipe(untilDestroyed(this)).subscribe(
      (m) => {
        this.messages = { systemMessages: m };
      },
      (e) => console.log(e)
    );

    // coming future sprint
    this.openEnrollmentService.getOpenEnrollments().pipe(untilDestroyed(this)).subscribe((oes) => {
      this.openEnrollments = { openEnrollment: oes };
    });

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
      this.isHcaSysAdmin = this.coreService.systemUserHasAccess(AccessLevel.SystemAdmin, UserTypeCode.HCA);

      this.lookups[`agencies`] = data.lookups.agency?.filter(a => a.agencyCode !== 'PEBB-SP');
      this.applicationSettings = { applicationSetting: data.lookups.applicationSettings ?? [] };
      this.lookups[`modules`] = data.lookups.module;
      this.lookups[`addressTypes`] = data.lookups.addressTypes;
      this.lookups[`attestationTypes`] = data.lookups.addressTypes;
      this.lookups[`birthSexes`] = sortBy(data.lookups.birthSexes, 'birthSexName');
      this.lookups[`counties`] = sortBy(data.lookups.counties, 'countyName');
      this.lookups[`documentTypes`] = sortBy(data.lookups.documentTypes, 'documentTypeName');
      this.lookups[`genderIdentities`] = sortBy(data.lookups.genderIdentities, 'genderIdentityName');
      this.lookups[`languages`] = sortBy(data.lookups.languages, 'languageName');
      this.lookups[`memberTypes`] = sortBy(data.lookups.memberTypes, 'memberTypeName');
      this.lookups[`phoneNumberTypes`] = sortBy(data.lookups.phoneNumberTypes, 'phoneNumberTypeName');
      this.lookups[`planTypes`] = sortBy(data.lookups.planTypes, 'planTypeName');
      this.lookups[`preferredContactMethods`] = sortBy(data.lookups.preferredContactMethods, 'preferredContactMethodName');
      this.lookups[`genericMessageTypes`] = sortBy(data.lookups.genericMessageTypes, 'genericMessageTypeName');
      this.lookups[`relationshipQualifyReasons`] = sortBy(data.lookups.relationshipQualifyReasons, 'relationshipQualifyReasonName');
      this.lookups[`relationshipVerificationStatuses`] = sortBy(data.lookups.relationshipVerificationStatus, 'relationshipVerificationStatusName');
      this.lookups[`relationshipTypes`] = sortBy(data.lookups.relationshipTypes, 'relationshipTypeName');
      this.lookups[`responses`] = sortBy(data.lookups.responses, 'responseName');
    });
  }

  updateTableValue(e): void {
    let tableName = e.tableName;
    const payload = cloneDeep(e);
    if (tableName === 'systemMessages') {
      tableName = 'genericMessage';
      payload.modules = [];
      forEach(e.moduleObject, (val, key) => {
        if (val) {
          payload.modules.push(find(this.lookups[`modules`], (m: Module) => m.moduleName === key));
        }
      });
      delete payload.moduleObject;
      delete payload.tableName;
    }
    this.lookupService.putLutValue(tableName, this.getPrimaryId(payload), payload).pipe(untilDestroyed(this)).subscribe(
      (s) => {
        if (tableName === 'genericMessage') {
          const idx = findIndex(this.messages.systemMessages, ['genericMessageId', s.genericMessageId]);
          this.messages.systemMessages.splice(idx, 1, new Message(s));
          this.messages = cloneDeep(this.messages);
        } else if (tableName === 'applicationSetting') {
          const idx = findIndex(this.applicationSettings.applicationSetting, ['applicationSettingId', s.applicationSettingId]);
          this.applicationSettings?.applicationSetting.splice(idx, 1, new ApplicationSetting(s));
          this.applicationSettings = cloneDeep(this.applicationSettings);
        } else if (tableName === 'openEnrollment') {
          const idx = findIndex(this.openEnrollments.openEnrollment, ['openEnrollmentId', s.openEnrollmentId]);
          this.openEnrollments?.openEnrollment.splice(idx, 1, new OpenEnrollment(s));
          this.openEnrollments = cloneDeep(this.openEnrollments);
        }
        this.coreService.popMessage('Values successfully updated!', 'success', 2000);
      },
      (err) => {
        this.coreService.popMessage('Something went wrong updating this record, please try again or contact a system admin.', 'error', 2000);
        console.log(err);
      }
    );
  }

  createTableValue(e): void {
    let tableName = e.tableName;
    delete e.tableName;
    const payload = cloneDeep(e);
    if (tableName === 'openEnrollment') {
      delete payload.openEnrollmentId;
    }
    if (tableName === 'systemMessages') {
      tableName = 'genericMessage';
      forEach(e.moduleObject, (val, key) => {
        if (val) {
          payload.modules.push(find(this.lookups[`modules`], (m: Module) => m.moduleName === key));
        }
      });
      delete payload.moduleObject;
      delete payload.genericMessageId;
    }
    this.lookupService.postLutValue(tableName, payload).pipe(untilDestroyed(this)).subscribe(
      (s) => {
        if (tableName === 'genericMessage') {
          this.messages.systemMessages.push(new Message(s));
          this.messages = cloneDeep(this.messages);
        } else if (tableName === 'openEnrollment') {
          remove(this.openEnrollments?.openEnrollment,(o:OpenEnrollment)=> !o.openEnrollmentId); 
          this.openEnrollments?.openEnrollment.push(new OpenEnrollment(s));
          this.openEnrollments = cloneDeep(this.openEnrollments);
        }
        this.coreService.popMessage('Record successfully created', 'success', 2000);
      },
      (err) => {
        this.coreService.popMessage('Something went wrong creating this record, please try again or contact a system admin.', 'error', 2000);
        console.log(err);
      }
    );
  }

  getPrimaryId(o: any): string {
    for (const k in o) {
      if (endsWith(k, 'Id')) {
        return o[k];
      }
    }
  }

  disableInvalidMessageDates(date): boolean {
    const message: Message = this as any as Message;
    if (message.genericMessageId) {
      return dayjs(message.activeDate).subtract(1, 'day').endOf('day').isAfter(date);
    }

    return dayjs().subtract(1, 'day').endOf('day').isAfter(date);
  }

  gotoTestingPage(): void {
    this.router.navigate(['admin/uw']);
  }
}
