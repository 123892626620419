import SubscriberTransfer from './subscriberTransfer';

export default class TransferSearchResult {
  coverageEffectiveDate: Date;
  transferSearchResponses: SubscriberTransfer[] = [];

  constructor(transferSearchResult?) {
    if (transferSearchResult) {
        this.coverageEffectiveDate = (transferSearchResult.coverageEffectiveDate ? new Date(transferSearchResult.coverageEffectiveDate.replace(":00Z","")) : null);
        this.transferSearchResponses = transferSearchResult.transferSearchResponses.map(obj => new SubscriberTransfer(obj));
    }
  }
}
