import Attestation from 'src/app/models/attestation';
import { QuestionComponent } from './../../../../../shared/components/question/question.component';
import PlanType from 'src/app/models/planType';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Question from 'src/app/models/question';
import Answer from 'src/app/models/answer';
import DependentComposite from 'src/app/models/dependentComposite';

// ng
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output, OnChanges, ViewChildren, QueryList, AfterViewInit } from '@angular/core';

// ext
import { find, sortBy, maxBy, some, remove, map, forEach, get } from 'lodash';
import Response from 'src/app/models/response';
// local

@Component({
  selector: 'dep-spousal',
  templateUrl: 'dep.spousal.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentSpousalComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() dependent: DependentComposite;
  @Input() reattesting = false;
  @Input() proceedLabelOverride: string = null;
  icons = {};
  @Input() effectiveYear;
  @Input() disabled = false;
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    subagencies: [],
    phoneNumberTypes: [],
    eligibilityReasons: [],
    terminationReasons: [],
    planTypes: [],
    responses: [],
    questions: [],
  };
  yesSpousal: boolean;
  affirmativeResponseId: string;
  negativeResponseId: string;
  questions: Question[];
  inAdminState = false;
  responseAffirmativeIndex = 0;
  someNegativeResponse = false;
  medicalPlanTypeId: string;
  showCalculator = false;
  displayConfirmation = false;
  currentSpousal: string;
  medicalEnrolled: boolean;
  spousalQuestionResponses: { [k: string]: string } = {};
  @Input() isSebb: boolean = false;
  @Input() spousalOutsideWizard = false;
  @Input() spousalAttestation: Attestation = null;
  @Input() enrollmentPeriod: EnrollmentPeriod;
  @Output() saveReattest: EventEmitter<void> = new EventEmitter();
  @Output() updateSpousalAttest: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToNextSubsection: EventEmitter<number> = new EventEmitter();
  @ViewChildren(QuestionComponent) questionComponents: QueryList<QuestionComponent>;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (this.spousalOutsideWizard) {
      this.inAdminState = true;
      this.medicalEnrolled = true;
      this.currentSpousal = this.spousalAttestation.responseId;
      forEach(this.spousalAttestation.answers, (answer: Answer) => {
        this.spousalQuestionResponses[answer.questionId] = answer.responseId;
      });
      this.questions = sortBy(this.lookups.questions, (q: Question) => q.sortOrder);
      forEach(
        this.questions,
        (q: Question) => (q.responses = [find(this.lookups.responses, (r: Response) => r.responseCode === 'Y'), find(this.lookups.responses, (r: Response) => r.responseCode === 'N')])
      );

      this.affirmativeResponseId = get(
        find(this.lookups.responses, (r: Response) => r.responseCode === 'Y'),
        'responseId'
      );
      this.negativeResponseId = get(
        find(this.lookups.responses, (r: Response) => r.responseCode === 'N'),
        'responseId'
      );
      this.medicalPlanTypeId = find(this.lookups.planTypes, (p: PlanType) => p.planTypeCode === '1').planTypeId;
      //this.medicalEnrolled = this.dependent.planTypeIds?.includes(this.medicalPlanTypeId);
      this.onSpousalQuestionAnswered({
        q: this.questions[0],
        r: new Response({ responseId: this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId }),
      });
      if (this.currentSpousal) {
        this.spousalAttestation.responseId = this.currentSpousal;
      }

      if (this.questionComponents) {
        // O^n^2 but who cares for now.
        forEach(this.spousalAttestation.answers, (a: Answer) => {
          forEach(Array.from(this.questionComponents), (qc: QuestionComponent) => {
            if (a.questionId === qc.question.questionId) {
              qc.manuallyUpdateResponse(a.responseId);
            }
          });
        });
        this.questionComponents.first.manuallyUpdateResponse(this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId);
      }
      this.yesSpousal = this.currentSpousal ? this.currentSpousal === this.affirmativeResponseId : null;
    } else {
      this.currentSpousal = this.dependent.spousalResponseId;
      forEach(this.dependent.spousalAnswers, (answer: Answer) => {
        this.spousalQuestionResponses[answer.questionId] = answer.responseId;
      });
      this.questions = sortBy(this.lookups.questions, (q: Question) => q.sortOrder);
      forEach(
        this.questions,
        (q: Question) => (q.responses = [find(this.lookups.responses, (r: Response) => r.responseCode === 'Y'), find(this.lookups.responses, (r: Response) => r.responseCode === 'N')])
      );

      this.affirmativeResponseId = get(
        find(this.lookups.responses, (r: Response) => r.responseCode === 'Y'),
        'responseId'
      );
      this.negativeResponseId = get(
        find(this.lookups.responses, (r: Response) => r.responseCode === 'N'),
        'responseId'
      );
      this.medicalPlanTypeId = find(this.lookups.planTypes, (p: PlanType) => p.planTypeCode === '1').planTypeId;
      this.medicalEnrolled = this.dependent.planTypeIds?.includes(this.medicalPlanTypeId);
      this.onSpousalQuestionAnswered({
        q: this.questions[0],
        r: new Response({ responseId: this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId }),
      });
      if (this.currentSpousal) {
        this.dependent.spousalResponseId = this.currentSpousal;
      }

      if (this.questionComponents) {
        // O^n^2 but who cares for now.
        forEach(this.dependent.spousalAnswers, (a: Answer) => {
          forEach(Array.from(this.questionComponents), (qc: QuestionComponent) => {
            if (a.questionId === qc.question.questionId) {
              qc.manuallyUpdateResponse(a.responseId);
            }
          });
        });
        this.questionComponents.first.manuallyUpdateResponse(this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId);
      }
      this.yesSpousal = this.currentSpousal ? this.currentSpousal === this.affirmativeResponseId : null;
    }
  }

  ngAfterViewInit(): void {
    if (this.spousalOutsideWizard && this.questionComponents) {
      //sets the first question to be yes when adding new attestation out of band, have to use AfterViewInit or ViewChild question components are not defined
      this.questionComponents.first.manuallyUpdateResponse(this.affirmativeResponseId);
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    if (this.questions && this.questions.length && this.medicalPlanTypeId) {
      const responseId = this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId;
      // update if answer does not already exist that would be the same
      if (this.spousalOutsideWizard) {
        if (!some(this.spousalAttestation.answers, (a: Answer) => a.questionId === this.questions[0].questionId && a.responseId === responseId)) {
          this.onSpousalQuestionAnswered({
            q: this.questions ? this.questions[0] : null,
            r: new Response({ responseId: this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId }),
          });
          this.updateSpousal(this.medicalEnrolled && (!this.currentSpousal || this.currentSpousal === this.affirmativeResponseId) ? true : false);
          if (this.questionComponents) {
            this.questionComponents.first.manuallyUpdateResponse(this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId);
          }
        }
      } else {
        this.medicalEnrolled = this.dependent.planTypeIds?.includes(this.medicalPlanTypeId);
        if (!some(this.dependent.spousalAnswers, (a: Answer) => a.questionId === this.questions[0].questionId && a.responseId === responseId)) {
          this.onSpousalQuestionAnswered({
            q: this.questions ? this.questions[0] : null,
            r: new Response({ responseId: this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId }),
          });
          this.updateSpousal(this.medicalEnrolled && (!this.currentSpousal || this.currentSpousal === this.affirmativeResponseId) ? true : false);
          if (this.questionComponents) {
            this.questionComponents.first.manuallyUpdateResponse(this.medicalEnrolled ? this.affirmativeResponseId : this.negativeResponseId);
          }
        }
      }
    }
  }

  submitDependent(): void {
    this.moveToNextSubsection.emit();
  }

  getResponseByQuestion(questionId: string): string {
    let foundAnswer;
    if (this.spousalOutsideWizard) {
      foundAnswer = find(this.spousalAttestation, ['questionId', questionId]);
    } else {
      foundAnswer = find(this.dependent.spousalAnswers, ['questionId', questionId]);
    }
    return foundAnswer ? foundAnswer.responseId : null;
  }

  onSpousalQuestionAnswered(e: { q: Question; r: Response }): void {
    const answer = new Answer();
    answer.questionId = e.q.questionId;
    answer.responseId = e.r.responseId;
    answer.answerDate = new Date();
    if (this.spousalOutsideWizard) {
      this.spousalAttestation.responseId = e.r.responseId;
      if (e.q.sortOrder === 5) {
        this.showCalculator = true;
      } else {
        this.showCalculator = false;
      }
      // just push if they haven't answered this one.
      if (!some(this.spousalAttestation.answers, (a: Answer) => a.questionId === e.q.questionId)) {
        this.spousalAttestation.answers.push(answer);
        // else if answer but the response is different, remove and replace
      } else if (some(this.spousalAttestation.answers, (a: Answer) => a.questionId === e.q.questionId && a.responseId !== e.r.responseId)) {
        remove(this.spousalAttestation.answers, (a: Answer) => a.questionId === e.q.questionId);
        this.spousalAttestation.answers.push(answer);
      } else {
      }
      this.someNegativeResponse = some(this.spousalAttestation.answers, (sa: Answer) => sa.responseId === this.negativeResponseId);
      this.responseAffirmativeIndex = this.getHighestAffirmativeResponseIdx() ? this.getHighestAffirmativeResponseIdx().sortOrder + 1 : 0;
    } else {
      this.dependent.spousalResponseId = e.r.responseId;
      if (e.q.sortOrder === 5) {
        this.showCalculator = true;
      } else {
        this.showCalculator = false;
      }
      // just push if they haven't answered this one.
      if (!some(this.dependent.spousalAnswers, (a: Answer) => a.questionId === e.q.questionId)) {
        this.dependent.spousalAnswers.push(answer);
        // else if answer but the response is different, remove and replace
      } else if (some(this.dependent.spousalAnswers, (a: Answer) => a.questionId === e.q.questionId && a.responseId !== e.r.responseId)) {
        remove(this.dependent.spousalAnswers, (a: Answer) => a.questionId === e.q.questionId);
        this.dependent.spousalAnswers.push(answer);
      } else {
      }
      this.someNegativeResponse = some(this.dependent.spousalAnswers, (sa: Answer) => sa.responseId === this.negativeResponseId);
      this.responseAffirmativeIndex = this.getHighestAffirmativeResponseIdx() ? this.getHighestAffirmativeResponseIdx().sortOrder + 1 : 0;
    }
  }

  getHighestAffirmativeResponseIdx(): Question {
    let allAffirmativeAnswers;
    if (this.spousalOutsideWizard) {
      allAffirmativeAnswers = this.spousalAttestation.answers.filter((a: Answer) => a.responseId === this.affirmativeResponseId);
    } else {
      allAffirmativeAnswers = this.dependent.spousalAnswers.filter((a: Answer) => a.responseId === this.affirmativeResponseId);
    }
    const associatedQuestions = map(allAffirmativeAnswers, (a: Answer) => find(this.questions, (q) => q.questionId === a.questionId));
    const maxQuestion = maxBy(associatedQuestions, 'sortOrder');
    return maxQuestion;
  }

  updateSpousal(e?: boolean): void {
    if (typeof e === 'boolean') {
      this.yesSpousal = e;
      if (this.spousalOutsideWizard) {
        if (this.yesSpousal) {
          this.spousalAttestation.responseId = this.affirmativeResponseId;
        } else {
          this.spousalAttestation.responseId = this.negativeResponseId;
        }

        if (this.currentSpousal !== this.spousalAttestation.responseId) {
          this.currentSpousal = this.spousalAttestation.responseId;
          this.updateSpousalAttest.emit(this.spousalAttestation.responseId);
        }
      } else {
        if (this.yesSpousal) {
          this.dependent.spousalResponseId = this.affirmativeResponseId;
        } else {
          this.dependent.spousalResponseId = this.negativeResponseId;
        }
      }

      if (this.currentSpousal !== this.dependent.spousalResponseId) {
        this.currentSpousal = this.dependent.spousalResponseId;
        this.updateSpousalAttest.emit(this.dependent.spousalResponseId);
      }
    }
  }
}
