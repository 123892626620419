export default class UserType {
  userTypeId: string;
  userTypeCode: string;
  userTypeName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(userType?) {
    if (userType) {
      this.userTypeId = userType.userTypeId;
      this.userTypeCode = userType.userTypeCode;
      this.userTypeName = userType.userTypeName;
      this.obsoleteFlag = userType.obsoleteFlag;
      this.obsoleteDate = userType.obsoleteDate ? new Date(userType.ObsoleteDate) : null;
    }
  }
}
