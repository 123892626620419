import MemberType from './memberType';
import Reason from './reason';

export default class MemberMemberType {
  memberMemberTypeId: string;
  memberId: string;
  memberTypeId: string;
  eligibilityReasonId: string;
  lossOfEligibilityReasonId: string;
  lossOfEligibilityReason: Reason;
  eligibilityReason: Reason;
  memberType: MemberType;
  eligibilityDate: Date;
  lossOfEligibilityDate: Date;
  benefitTerminationDate: Date;
  eligibilityTypeEffectiveDate: Date;
  effectiveStartDate: Date;
  effectiveEndDate: Date;

  constructor(memberMemberType?) {
    if (memberMemberType) {
      this.memberMemberTypeId = memberMemberType.memberMemberTypeId;
      this.memberId = memberMemberType.memberId;
      this.memberTypeId = memberMemberType.memberTypeId;
      this.memberType = new MemberType(memberMemberType.memberType);
      this.eligibilityReasonId = memberMemberType.eligibilityReasonId;
      this.eligibilityReason = memberMemberType.eligibilityReason ? new Reason(memberMemberType.eligibilityReason) : null;
      this.eligibilityDate = memberMemberType.eligibilityDate ? new Date(memberMemberType.eligibilityDate) : null;
      this.lossOfEligibilityReason = memberMemberType.lossOfEligibilityReason ? new Reason(memberMemberType.lossOfEligibilityReason) : null;
      this.lossOfEligibilityReasonId = memberMemberType.lossOfEligibilityReasonId;
      this.lossOfEligibilityDate = memberMemberType.lossOfEligibilityDate ? new Date(memberMemberType.LossOfEligibilityDate) : null;
      this.benefitTerminationDate = memberMemberType.benefitTerminationDate ? new Date(memberMemberType.benefitTerminationDate) : null;
      this.effectiveStartDate = memberMemberType.effectiveStartDate ? new Date(memberMemberType.effectiveStartDate) : null;
      this.effectiveEndDate = memberMemberType.effectiveEndDate ? new Date(memberMemberType.effectiveEndDate) : null;
      this.eligibilityTypeEffectiveDate = memberMemberType.eligibilityTypeEffectiveDate ? new Date(memberMemberType.eligibilityTypeEffectiveDate) : null;
    }
  }

}
