export default class BirthSex {
  birthSexId: string;
  birthSexCode: string;
  birthSexName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(birthSex?) {
    if (birthSex) {
      this.birthSexId = birthSex.birthSexId;
      this.birthSexCode = birthSex.birthSexCode;
      this.birthSexName = birthSex.birthSexName;
      this.obsoleteFlag = birthSex.obsoleteFlag;
      this.obsoleteDate = birthSex.obsoleteDate ? new Date(birthSex.obsoleteDate) : null;
    }
  }
}
