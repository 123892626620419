<div class="grid-container">
    <kendo-grid
      #kendoGrid
      [data]="gridData"
      class="k-grid-ignore-click"
      [filterable]="filterable ? 'menu' : false"
      [filter]="state.filter"
      [skip]="state.skip"
      [sortable]="filterable"
      [pageable]="pageable"
      [pageSize]="state.take"
      [sort]="state.sort"
      (dataStateChange)="dataStateChange($event)"
      (detailExpand)="getRowDocuments($event)"
    >
      <kendo-grid-checkbox-column width="20%" *ngIf="selectable"> </kendo-grid-checkbox-column>
      <kendo-grid-column
        class="k-grid-ignore-click"
        [style.flex]="col.flex || 1"
        *ngFor="let col of gridColumns"
        field="{{col.field}}"
        title="{{col.title}}"
        width="{{col.width}}"
        [format]="col.format"
        [sortable]="col.sortable"
        [filter]="col.filter ? col.filter : null"
        [headerStyle]="{ 'font-weight': 'bold' }"
      >
      <ng-template kendoGridFilterMenuTemplate
        let-column="col"
        let-filter="filter"
        let-filterService="filterService"
        *ngIf="col.field.endsWith('Date')"
        >
        <date-picker
          name="dateFilter"
          [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
          (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
          [label]="col.title">
        </date-picker>
      </ng-template>

      <ng-template *ngIf="col.field==='hasDocuments'" kendoGridCellTemplate let-dataItem="dataItem">
        {{(dataItem.hasDocuments ? 'Yes' : 'No')}}
      </ng-template>
      </kendo-grid-column>
      <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="card">
          <div class="card-body" style="margin-left:12px;" fxFlexLayout="column" *ngIf="dataItem">
            <verification-summary
              [dataItem]="dataItem"
            ></verification-summary>
            <verification-form
              *ngIf="coreService.systemUserHasAccess('edit')"
              [isDisabled]="!isHCAAdmin && (dataItem.relationshipQualifyReason.managedByHCAInd || dataItem.effectiveStartDate < lowerLimitDate)"
              [dataItem]="dataItem" 
              [verifyValues]="verifyValues" 
              [denyValues]="denyValues"
              [rowIndex]="rowIndex"
              (resetData)="reSetDataForGrid()" 
              (pushSaveVerificationResponse)="pushSaveVerification(dataItem, rowIndex)"
              (closeDetail)="closeDetail($event)"
            ></verification-form>
            <certification
              *ngIf="dataItem.relationshipQualifyReason.needsCertificationInd"
              [certifications]="dataItem.relationshipCertifications" 
              [relationship]="dataItem" 
              [certificationTypes]="lookups.certificationTypes"
              [certificationStatuses]="lookups.relationshipCertificationStatus"
              [isHCA]="inHCAAdminState"
              [isPerspay]="!inHCAAdminState"
              [isSubscriber]="false"
              [isReadOnly]="!isHCAAdmin"
              (pushSaveCertification)="saveCertification(rowIndex, $event)"
              (pushSaveCertificationStatus)="saveCertificationStatus(rowIndex, $event)"
              (pushDeleteCertification)="deleteCertification(rowIndex, $event)"
            ></certification>
          </div>
        </div>
      </ng-template>
    </kendo-grid>
</div>
