import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  private cachedLookups: { [lutName: string]: any[] } = {};

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getLutValues<T>(lutName: string, type: { new (...args: any[]): T }): Observable<T[]> {
    if (this.cachedLookups[lutName]) {
      return new Observable(observer => {
        observer.next(this.cachedLookups[lutName]);
        observer.complete();
      });
    }

    return this.http
      .get<T[]>(`${env.apiUrl}/${lutName}`)
      .pipe(map(x => this.commonService.createObjects(type, x)))
      .pipe(map((lookups: T[]) => {
        if (!this.cachedLookups[lutName]) {
          this.cachedLookups[lutName] = lookups;
        }

        return lookups;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  postLutValue<T>(lutName: string, payload): Observable<any> {
    if (this.cachedLookups[lutName]) {
      // Invalidate the cache if it's present.
      delete this.cachedLookups[lutName];
    }

    return this.http
      .post<T[]>(`${env.apiUrl}/${lutName}`, payload)
      .pipe(map(x => x))
      .pipe(catchError(this.commonService.handleError));
  }

  putLutValue<T>(lutName: string, primaryId: string, payload): Observable<any > {
    if (this.cachedLookups[lutName]) {
      // Invalidate the cache if it's present.
      delete this.cachedLookups[lutName];
    }

    return this.http
      .put<T[]>(`${env.apiUrl}/${lutName}/${primaryId}`, payload)
      .pipe(map(x => x))
      .pipe(catchError(this.commonService.handleError));
  }
}
