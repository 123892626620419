<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
  <div class="card-body">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h3 style="text-align: center;" fxFlex="100">{{getTitle(requestType.selfPayTypeCode)}}</h3>
    </div>
    <div *ngIf="isRetiree(requestType.selfPayTypeCode)" class="container-fluid">
      <p *ngIf="requestType.selfPayTypeCode === 'R'">
        If you are a retiring employee, the PEBB Program must receive your retiree enrollment or deferral request and any other required documents 
        no later than 60 days after your employer-paid, COBRA, or continuation coverage ends.  
      </p>
      <p>
        For additional information and timelines regarding retiree enrollment please see
        <a href="https://www.hca.wa.gov/employee-retiree-benefits/preparing-retirement" target="_blank">Preparing for retirement</a> 
      </p>
      <p *ngIf="requestType.selfPayTypeCode === 'R'">
        Note: If you are enrolling in PEBB retiree coverage we cannot enroll you until we receive your first payment of monthly premiums and applicable premium 
        surcharges.  You must make your first payment no later than 45 days after your 60-day election period ends.  
      </p>
      <p *ngIf="requestType.selfPayTypeCode !== 'R'">
        Use the links below to determine what your medical and dental premiums would be for your plan(s) based on family size and Medicare enrollment.  
        After reviewing premium amounts proceed to payment information/options.
      </p>
    </div>
    <div *ngIf="!isSoeSelfPay(requestType.selfPayTypeCode) && requestType.selfPayTypeCode !== 'R'" class="container-fluid">
      <p *ngIf="requestType.selfPayTypeCode !== 'OEC' && requestType.selfPayTypeCode !== 'OEU'">
        A request for {{agency}} Continuation Coverage {{requestType.selfPayTypeCode === 'C' ? '(COBRA)' : ''}} {{requestType.selfPayTypeCode === 'U' ? '(Unpaid Leave)': ''}} 
        must be submitted no later than 60 days after the date your {{agency}} health plan coverage ends, or from the postmark date on the election notices sent by the {{agency}} Program, whichever is later. 
      </p>
      <p *ngIf="requestType.selfPayTypeCode !== 'OEC' && requestType.selfPayTypeCode !== 'OEU'">
        Your first premium payment and applicable premium surcharges are due to HCA no later than 45 days after your 
        60-day election period ends. HCA only accepts cash, check, or money order for payment. We will not enroll you in coverage until we receive your first payment. If HCA does not receive your first 
        payment during this 45-day timeframe, you will not be enrolled, and you will lose your rights for {{agency}} Continuation Coverage 
        {{requestType.selfPayTypeCode === 'C' ? '(COBRA)' : '(unpaid leave)'}}. 
        Premiums and applicable premium surcharges are due from the date your other coverage ended. 
          See  <a target="_blank" [href]="agency === 'SEBB' ? 'https://www.hca.wa.gov/employee-retiree-benefits/SEBB-continuation-coverage/paying-benefits' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/paying-benefits'">Paying for benefits</a> for more information.
      </p>
      <p *ngIf="requestType.selfPayTypeCode === 'OEC' || requestType.selfPayTypeCode === 'OEU' || requestType.selfPayTypeCode === 'OER'">Changes that are made during Open Enrollment may result in changes to premium payments and applicable surcharges.  These payments are due to the Health Care Authority (HCA) no later than 45 days after the effective date of the changes.</p>
      <!-- <p>
        Use the links below to determine what your medical and dental (if applicable) premiums would be for your plans(s) based on family size 
        {{requestType.selfPayTypeCode === 'C' ? 'and Medicare enrollment.' : '.'}}
      </p> -->
    </div>
    <!-- <ul *ngIf="!isSoeSelfPay(requestType.selfPayTypeCode)">
      <li *ngIf="requestType.selfPayTypeCode === 'R'"><a href="https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2021.pdf" target="_blank">Current retiree premiums (Medicare and non-Medicare)</a></li>
      <li *ngIf="requestType.selfPayTypeCode !== 'R' && agency === 'PEBB'"><a href="https://www.hca.wa.gov/assets/pebb/50-0300-pebb-continuation-coverage-premiums-2021.pdf" target="_blank">Current PEBB continuation coverage premiums (non-Medicare)</a></li>
      <li *ngIf="requestType.selfPayTypeCode === 'C' && agency === 'PEBB'"><a href="https://www.hca.wa.gov/employee-retiree-benefits/continuation-coverage/continuation-coverage-premiums-0" target="_blank">Current PEBB continuation coverage premiums (Medicare)</a></li>
      <li *ngIf="requestType.selfPayTypeCode === 'C' && agency === 'SEBB'"><a href="https://www.hca.wa.gov/employee-retiree-benefits/continuation-coverage/sebb-continuation-coverage-premiums" target="_blank">Current SEBB continuation coverage premiums (Medicare)</a></li>
      <li><a [href]="'https://www.hca.wa.gov/employee-retiree-benefits/' + agency + '-continuation-coverage/paying-benefits'" target="_blank">Paying for benefits</a></li>
    </ul> -->

    <div *ngIf="isSoeSelfPay(requestType.selfPayTypeCode)" class="container-fluid">

      <p>
        Certain life events let you change your benefits outside of annual open enrollment. For example, you move to a new county or become eligible for Medicare. We call these “special open enrollment” events. See <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/what-special-open-enrollment">What is special open enrollment?</a> for more information. 
        <br />
        <br />
        The PEBB Program only allows changes outside of annual open enrollment when an event creates a special open enrollment. The change must be allowed under the Internal Revenue Code and Treasury regulations and correspond to and be consistent with a special open enrollment event for you, your dependent, or both. 
        <br />
        <br />
        You are required to provide proof of the event. The PEBB Program must receive this request and proof of the event no later than 60 days after the event.
        <br/><br/>
        When enrolling a newly eligible state-registered domestic partner, children of the state-registered domestic partner, a newly eligible extended dependent, or other 
        non-qualified tax dependent outside of open enrollment, you must submit a PEBB Declaration of Tax Status form. You may not change your health plan during a special 
        open enrollment if your state-registered domestic partner or their child is not a tax dependent.
        <br/>
        <br/>
       </p>
      <p>
        Select the applicable event from the list below (check one)
      </p>
      <ul style="list-style:none;">
        <li *ngFor="let soeType of soeTypes(); let index = index" class="mgbt-medium-lg">
          <div>
            <checkbox
              [name]="'soeType-' + index"
              [checked]="selectedSoeType?.specialOpenEnrollmentTypeId === soeType.specialOpenEnrollmentTypeId"
              (click)="selectSoeType(soeType)"
              [label]="soeType.specialOpenEnrollmentTypeName"
            ></checkbox>
            <div>
              <ng-container *ngIf="showCustomSOEDescription">
                <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'COC'">
                  You or your dependent experience a disruption of care for active and ongoing treatment that could function as a reduction in benefits. 
                  Requires approval by the {{subscriber?.agencyCode}} Program.<br /><br />
                </ng-container>
                <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'MDP'">
                  As defined by <a href="https://apps.leg.wa.gov/wac/default.aspx?cite=182-31-020" target="_blank">Washington Administrative Code (WAC) 182-30-020</a>.<br /><br />
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!showCustomSOEDescription && selectedSOEType?.specialOpenEnrollmentTypeDescription">
                {{selectedSOEType?.specialOpenEnrollmentTypeDescription}}
                <br /><br />
              </ng-container>
              <span>Documents to submit:</span>
              <ul>
                <ng-container *ngIf="displaySPCustomDocs(soeType.specialOpenEnrollmentTypeCode)">
                  <ng-container *ngIf="soeType.specialOpenEnrollmentTypeCode === 'GLEMO'">
                    <li>Medicare benefit verification letter</li>
                    <li>Medicare card</li>
                    <li>Letter/Notice of Denial of Medicare coverage</li>
                    <li>Social Security denial letter</li>
                    <li>Medicare Entitlement or Cessation of Disability form</li>
                    <li>Letter confirming enrollment or cancellation of Medicare Part D enrollment</li>
                    <li>Letter of declination of Medicare Part D coverage</li>
                    <li>Letter confirming enrollment or cancellation of MA or MAPD</li>
                    <li>Letter of declination of MA or MAPD</li>
                  </ng-container>
                  <ng-container  *ngIf="soeType.specialOpenEnrollmentTypeCode === 'CA'">
                    <li>
                      <li>Proof of former and current residence (e.g. utility bill)</li>
                      <li>Certificate of creditable coverage</li>
                  </ng-container>
                  <ng-container *ngIf="soeType.specialOpenEnrollmentTypeCode === 'MDP'">
                    <li>Marriage certificate</li>
                    <li>Certificate of state-registered domestic partnership or legal union & PEBB Declaration of Tax Status form</li>
                  </ng-container>
                  <ng-container *ngIf="soeType.specialOpenEnrollmentTypeCode === 'MPNLA'">
                    <li>Cancellation letter from the High Deductible health plan</li>
                    <li>Copy of current tax return claiming you as a dependent</li>
                    <li>Coverage confirmation in a new health plan</li>
                    <li>Medicare benefit verification letter</li>
                  </ng-container> 
                  <ng-container *ngIf="soeType.specialOpenEnrollmentTypeCode === 'NEED'">
                    <li>Court order showing legal custody, guardianship, or temporary guardianship; and</li>
                    <li>Extended Dependent Certification Form; and</li>
                    <li>PEBB Declaration of Tax Status form</li>
                  </ng-container>
                  <ng-container *ngIf="soeType.specialOpenEnrollmentTypeCode === 'LOC'">
                    <li>Certificate of creditable coverage</li>
                    <li>Letter of termination of coverage from health plan</li>
                    <li>COBRA election notice if it is personalized to the person who lost coverage and includes the date coverage was lost</li>
                  </ng-container>
                  <ng-container *ngIf="soeType.specialOpenEnrollmentTypeCode === 'CES'">
                    <li>Employee hire letter from employer that contains information about benefits eligibility</li>
                    <li>Employment contract</li>
                    <li>Letter of resignation</li>
                    <li>Statement of insurance</li>
                    <li>Termination letter</li>
                  </ng-container>
                  <ng-container *ngIf="soeType.specialOpenEnrollmentTypeCode === 'DCES'">
                    <li>Certificate of coverage</li>
                    <li>Employee hire letter from employer that contains information about benefits eligibility</li>
                    <li>Employment contract</li>
                    <li>Letter of resignation</li>
                    <li>Statement of insurance</li>
                    <li>Termination letter</li>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!displaySPCustomDocs(soeType.specialOpenEnrollmentTypeCode)">
                  <ng-container *ngIf="showCustomSOEDocTypes">
                    <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'GLEMC'">
                      <li>Enrollment or termination letter from Medicaid or CHIP reflecting the date the employee or employee's dependent enrolled in Medicaid or CHIP or the date 
                        at which they lost eligibility for Medicaid or CHIP</li>
                    </ng-container>
                    <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'MDP'">
                      <li>Marriage certificate</li>
                      <li>Certificate of state-registered domestic partnership or legal union and the 
                        <a href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=Declaration+of+Tax+Status&field_customer_type_tid=19011&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC" target="_blank">SEBB Declaration of Tax Status</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'NEED'">
                      <li>Valid court order showing legal custody, guardianship, or temporary guardianship, signed by a judge or officer of the court, a signed 
                        <a href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=extended+dependent+certification&field_customer_type_tid=19011&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC" target="_blank">{{subscriber?.agencyCode || 'PEBB'}} Extended Dependent Certification</a>
                        , and <a href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=Declaration+of+Tax+Status&field_customer_type_tid=19011&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC" target="_blank">{{subscriber?.agencyCode || 'PEBB'}} Declaration of Tax Status</a>.
                      </li>
                    </ng-container>            
                  </ng-container>
                  <ng-container *ngIf="!showCustomSOEDocTypes">
                    <li *ngFor="let docType of soeType?.documentTypes">{{docType.documentTypeName}}</li>
                  </ng-container>
                    
                  
                </ng-container>                
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around">
      <!-- <button (click)="pushCancel()" class="btn btn-secondary">{{cancelButtonLabel(requestType.selfPayTypeCode)}}</button> -->
      <button (click)="pushContinue()" [disabled]="!canContinue(requestType, selectedSoeType)" class="btn btn-primary">{{continueButtonLabel(requestType.selfPayTypeCode)}}</button>
    </div>
  </div>
</div>
