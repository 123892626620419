<kendo-stepper
  [steps]="formattedMilestones"
  [stepType]="'full'"
  [(currentStep)]="current"
  [linear]="false"
  [style.whiteSpace]="'normal'"
  [style.width.px]="900"
  (activate)="activateStep($event)"
>
  <ng-template kendoStepperStepTemplate let-step>
    <div
      [ngClass]="{ 'step-disabled': step.disabled }"
      fxLayout="column"
      fxLayoutAlign="center center"
      routerLinkActive="k-step-current"
      [routerLink]="isSOE ? './' + subscriber.memberId + '/' + step.state : './' + step.state"
    >
      <div class="k-step-indicator" routerLinkActive="active-wizard-link">
        <span class="k-step-indicator-icon"><fa-icon [icon]="icons[step.icon]" size="1x"></fa-icon></span>
      </div>
      <span class="k-step-label">
        <span class="k-step-text">{{ step.label }}</span>
      </span>
    </div>
  </ng-template>
</kendo-stepper>
