
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges, AfterViewInit } from '@angular/core';
import { faCalendar, faHome, faUndo, faEnvelope, faPhone, faAt, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep, merge, isEqual, map, find, remove, filter } from 'lodash';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

import SelfPayForm from 'src/app/models/selfPayForm';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import SelfPay from 'src/app/models/selfPay';
import SelfPayFormSubscriber from 'src/app/models/selfPayFormSubscriber';
import Member from 'src/app/models/member';
import MemberAddress from 'src/app/models/memberAddress';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';

@Component({
  selector: 'self-pay-change-of-address',
  templateUrl: 'selfPay.changeofaddress.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayChangeOfAddressComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() subscriber: Subscriber;
    @Input() selectedSelfPay: SelfPay;
    @Input() currentDependents: Member[];
    @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
    @Output() previous: EventEmitter<void> = new EventEmitter();
    currentSubscriber: SelfPayFormSubscriber;
    origCurrentSubscriber: SelfPayFormSubscriber;
    origSelfPayForm: SelfPayForm;
    icons = {
        faCalendar,
        faHome,
        faUndo,
        faEnvelope,
        faPhone,
        faAt,
        faMobileAlt,
        faPlus,
        faMinus
      };
    @ViewChild('form') public form: NgForm;
    @ViewChild('subscriberAddress') subscriberAddress: NgbAccordion;
    @ViewChild('dependentAddress') dependentAddress: NgbAccordion;

    constructor(private coreService: CoreService) {}

    ngOnInit(): void {
        this.currentSubscriber = cloneDeep(this.selectedSelfPay.formJson.subscriber);
        this.origCurrentSubscriber = cloneDeep(this.currentSubscriber);
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectedSelfPay) {
            if (!this.selectedSelfPay.formJson?.subscriber?.memberId) {
              this.selectedSelfPay.formJson.subscriber = new SelfPayFormSubscriber(this.subscriber);
              this.selectedSelfPay.formJson.mailingAddressSameAsResidential = this.subscriber.mailingAddress?.addressLineOne == null;
            }

            if (!this.selectedSelfPay.formJson.dependents) {
                if (this.currentDependents.length > 0) {
                    this.selectedSelfPay.formJson.dependents = [];
                    this.currentDependents.forEach((d:Member)=>{
                        var depAddress = d.addresses?.find((o:MemberAddress)=> o.effectiveEndDate == null);
                        var dep: SelfPayFormDependent;
                        if (depAddress) {
                            var depMerged = merge(d, depAddress);
                            dep = new SelfPayFormDependent(depMerged);
                        }
                        else {
                            dep = new SelfPayFormDependent(d);
                        }
                        dep.addressSameAsSubscriber = depAddress == null;
                        this.selectedSelfPay.formJson.dependents.push(dep);
                    });
                } else {
                    this.selectedSelfPay.formJson.dependents = [];
                }
            }
            this.origSelfPayForm = cloneDeep(this.selectedSelfPay.formJson);
        }
    }

    ngAfterViewInit() {
        this.setDefaultExpanded();
    }

    setDefaultExpanded() {
        if (this.subscriberAddress) {
            this.subscriberAddress.expand("subscriberResidentialPanel");
            if (this.currentSubscriber.mailingAddress?.addressLineOne) {
                this.subscriberAddress.expand("subscriberMailingPanel");
            }
            if (this.selectedSelfPay.formJson?.dependents) {
                this.selectedSelfPay.formJson?.dependents.forEach((dep:SelfPayFormDependent)=>{
                    if (dep.addressLineOne) {
                        this.dependentAddress.expand('depAddressPanel_' + dep.memberId);
                    }
                });
            }
        }
    }

    submitForm(formContainer) {
        this.coreService.markFormControlsAsTouched(this.form);
        if (this.form.valid) {
            this.selectedSelfPay.formJson.subscriber = this.currentSubscriber;
            this.saveSelfPay.emit(this.selectedSelfPay);
        } else {
            this.coreService.popMessage('Please complete all required fields.', 'error', 4000, this.coreService.getInvalidFields(formContainer));
        }
    }

    mailingAddressSameAsResidentialChange() {
        if (!this.selectedSelfPay.formJson.mailingAddressSameAsResidential) {
            if (this.origSelfPayForm.mailingAddressSameAsResidential) {
                this.resetEmptyFields(this.origCurrentSubscriber.mailingAddress);
                if (!this.origCurrentSubscriber.mailingAddress.countyId) {
                    this.origCurrentSubscriber.mailingAddress.countyId = null;
                    this.origCurrentSubscriber.mailingAddress.county = null;
                    this.origCurrentSubscriber.mailingAddress.state = null;
                }

                this.origCurrentSubscriber.mailingAddress.country = this.currentSubscriber.mailingAddress.country;
                this.origCurrentSubscriber.mailingAddress.countryId = this.currentSubscriber.mailingAddress.countryId;
                this.origSelfPayForm.subscriber = this.origCurrentSubscriber;
                this.currentSubscriber.mailingAddress = cloneDeep(this.origCurrentSubscriber.mailingAddress);
            }
            else {
                this.resetAddressFields(this.currentSubscriber.mailingAddress);
            }
            this.subscriberAddress.collapse("subscriberMailingPanel");
        }
        else {
            this.subscriberAddress.expand("subscriberMailingPanel");
        }
    }

    depAddressSameAsSubscriberChange(dep: SelfPayFormDependent,panelIndex) {
        if (!dep.addressSameAsSubscriber) {
            var dependentInfo = this.selectedSelfPay.formJson.dependents.find(o=>o.memberId === dep.memberId);
            var origDependentInfo = this.origSelfPayForm.dependents.find(o=>o.memberId === dep.memberId);
            if (origDependentInfo.addressSameAsSubscriber) {
                if (!origDependentInfo.countyId) {
                    origDependentInfo.countyId = null;
                    origDependentInfo.county = null;
                }
                dependentInfo = cloneDeep(origDependentInfo);
            }
            else {
                this.resetAddressFields(dependentInfo);
            }
            this.dependentAddress.collapse(panelIndex);
        }
        else {
            this.dependentAddress.expand(panelIndex);
        }
    }

    pushCancel() {
        this.selectedSelfPay.formJson = this.origSelfPayForm;
        this.previous.emit();
    }

    checkFormChange(currentForm, origForm, currentSubscriber, origCurrentSubscriber) {
        return isEqual(currentForm,origForm) && isEqual(currentSubscriber,origCurrentSubscriber);
    }

    resetAddressFields(addr) {
        addr.addressLineOne = null;
        addr.addressLineTwo = null;
        addr.city = null;
        addr.state = null;
        addr.county = null;
        addr.countyId = null;
        addr.zipcodeNumber = null;
        addr.countryId = null;
        addr.country = null;
    }

    resetEmptyFields(addr) {
        Object.entries(addr).forEach(([key, value]) => {
            if (!value || value == '') {
                value = null;
            }
        });
    }
}
