<div fxFill class="card container-fluid" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
  <div class="card-body">
    <h3 class="mobile-text-centered">Document upload</h3>
    <form #selfPayDocForm="ngForm">
      <div style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
        <div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
          <div *ngIf="isRetiree">
            <h3>Submit your supporting documents</h3>
            Upload your supporting documents like Retiree Term Life Election/Change form, proof of Medicare, documents to prove dependent eligibility, and other documents the PEBB Program may need.
            <h3>Why do I need to prove dependent eligibility?</h3>
          </div>
          <div>
            Verifying (proving) dependent eligibility helps us make sure we cover only people who qualify for {{agency}} health plan coverage. You provide this proof by submitting official documents. We will not
            enroll a dependent if we cannot prove their eligibility by the required deadline. We reserve the right to check a dependent’s eligibility at any time.
            <br /><br />
            Submit the documents in English. Documents written in another language must be accompanied by a translated copy produced by a professional translator and certified with a notary public seal. <span *ngIf="!isRetiree">You can upload your documents below or give them to your payroll or benefits office. </span> 
            <br/><br/>
            You can upload your documents below or mail them to the PEBB Program.
            <div *ngIf="!isRetiree"><br/><br/></div>
            <h3 *ngIf="isRetiree">What are accepted dependent verification documents?</h3>
            Accepted dependent verification documents:                    
          </div>
       
          <h3>To enroll a spouse:</h3>
          <ul>
            <li><b>The most recent year's federal tax return (black out financial information), either:</b>
              <ul>
                <li>A single return that lists your spouse if you filed jointly.</li>
                <li>Each return for you and your spouse if filed separately.</li>
              </ul>
            </li>
            <li><b>A marriage certificate and proof that the marriage is still valid</b> (you do not have to live together), such as a utility bill, life insurance beneficiary 
              document, or bank statement, dated within the last six months showing both your and your spouse's names (black out any financial information). If within 
              six months of marriage, only the marriage certificate is required. If using a utility bill as proof, separate bills with the same address are allowed.</li>
            <li><b>Petition for dissolution, petition for legal separation, or petition to invalidate (annul) marriage.</b> Must be filed within the last six months.</li>
            <li><b>Defense Enrollment Eligibility Reporting System (DEERS)</b> registration</li>
            <li><b>Valid J-1 or J-2 visa</b> issued by the U.S. Government</li>
          </ul>

          <h3>To enroll a state-registered domestic partner:</h3>
          <br />
          <div>
            In addition to one of the following, also upload the
            <a
              target="_blank"
              [href]="agency != 'SEBB' || !isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=50-0704&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_be' : 'https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=20-0087&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_bef_combine=name_ASC'"
              >{{agency}} Declaration of Tax Status</a
            >
            (to indicate whether they qualify as a dependent for tax purposes). Provide a copy of (choose one):
          </div>
          <br />
          <ul>
            <li>
              <b>Certificate/card of a state-registered domestic partnership or a legal union and proof the partnership is still valid </b>(you do not have to live together), such as a utility bill,
              life insurance beneficiary document, or bank statement dated within the last six months showing both your and your partner's names (black out any financial 
              information). If within six months of state registration, only the certificate/card is required.  If using a utility bill, separate bills with the same address are allowed.
            </li>
            <li><b>Petition to invalidate (annul) (recently filed, within the last six months) </b>a state-registered domestic partnership.</li>
          </ul>
          If you are enrolling a partner of a legal union also provide:<br/><br/>
          <ul>
            <li>Proof of Washington State residency for both you and your partner.</li>
          </ul>
          <div>
            Additional dependent verification documents will be required within one year of the partner's enrollment for them to remain enrolled. More information can be found in
            <a target="_blank" [href]="agency != 'SEBB' || !isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-rules-and-policies#dependent-eligibility' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-rules-and-policies '">{{agency}} Program Administrative Policy 33-1</a>.
          </div>
          <h3>To enroll children:</h3>
          <br/>
          <div>Provide a copy of a (choose one):</div>
          <br/>
          <ul>
            <li>
              <b>The most recent year's federal tax return</b> that includes the child as a dependent (black out financial information) You can submit one copy of your tax return if it includes all
              family members that require verification.
            </li>
            <li>
              <b>Birth certificate</b> (or hospital certificate with the child's footprints on it) showing the name of the parent who is the subscriber, the subscriber's spouse, or the subscriber's
              state-registered domestic partner. If the dependent is the subscriber's stepchild, the subscriber must also verify the spouse or state-registered domestic partner in order to enroll the
              child, even if not enrolling the spouse or state-registered domestic partner in PEBB insurance coverage.
            </li>
            <li><b>Certificate or decree of adoption</b> showing the name of the parent who is the subscriber, the subscriber's spouse, or the subscriber's state-registered domestic partner</li>
            <li><b>Court-ordered parenting plan</b></li>
            <li><b>National Medical Support Notice</b></li>
            <li><b>Defense Enrollment Eligibility Reporting System (DEERS)</b> registration</li>
            <li><b>Valid J-2 visa</b> issued by the U.S. government</li>
          </ul>
          <br />
          <br />
          <span>A few exceptions apply to the dependent verification process:</span>
          <br />
          <br />
          <ul>
            <li>Extended dependents are reviewed through a separate process.</li>
            <li>
              Previous dependent verification data verified by the School Employees Benefits Board (SEBB) Program may be used when a subscriber moves from SEBB Program coverage to PEBB Program
              coverage and is requesting to enroll an eligible dependent who has been previously verified under the SEBB Program.
            </li>
          </ul>
          <br />
          <br />
          <b>Upload documents and select document type</b>
          <generic-file-upload
            #fileUpload
            [autoUpload]="false"
            [type]="'document'"
            [allowedFileTypes]="allowedFileTypes"
            [systemUser]="systemUser"
            (selectEventHandler)="handleFilesSelected($event)"
            (removeEventHandler)="handleFilesRemoved($event)"
          >
          </generic-file-upload>
          <br />
          <div *ngFor="let document of documents" fxLayout="row" fxLayoutAlign="space-between" class="fxGap-medium-lg">
            <div fxFlexFill id="selfPayUploadDoctypeContainer" fxLayout="row" fxLayoutAlign="space-between start">
              <div fxFlex="40" fxLayout="column">
                <div fxLayout="row" class="hca-document-header" fxFlexFill>
                  <h5>{{ document.documentName }}</h5>
                </div>
              </div>
              <div fxFlex="50" fxLayout="column">
                <div fxFlexFill>
                  <label [for]="'documentType' + document.documentId">Document type</label>
                  <select required class="form-control required" [name]="'documentType' + document.documentId" [(ngModel)]="document.documentTypeId">
                    <option *ngFor="let docType of documentTypes | isObsolete" [(ngValue)]="docType.documentTypeId">{{ docType.documentTypeName }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="pendingChanges" style="margin-bottom: 30px" fxLayoutAlign="space-around">
            <button class="btn btn-light" (click)="clearSelections()">Clear</button>
            <button [disabled]="submitted" class="btn btn-primary" (click)="submitFileChanges()">Upload / Submit changes</button>
          </div>
          <div fxFill fxLayoutAlign="space-around">
            <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
            <button (click)="emitSelfPay(selfPay)" class="btn btn-primary">Continue</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
