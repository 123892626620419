import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import SystemUser from '../models/user';
import UserOrganizationRole from '../models/userOrganizationRole';
import ApiUser from '../models/apiUser';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getUsers(): Observable<SystemUser[]> {
    return this.http
      .get<SystemUser[]>(`${env.apiUrl}/systemUser`)
      .pipe(map(x => this.commonService.createObjects(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getUser(): Observable<SystemUser> {
    const timeZoneOffset = (new Date().getTimezoneOffset() / 60).toString();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userAgent = navigator.userAgent;

    return this.http
      .get<SystemUser[]>(`${env.apiUrl}/systemUser?tz=${encodeURIComponent(timeZone)}&tzo=${encodeURIComponent(timeZoneOffset)}&ua=${encodeURIComponent(userAgent)}`)
      .pipe(map(x => this.commonService.createObject(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getUserById(id: string): Observable<SystemUser> {
    return this.http
      .get(`${env.apiUrl}/systemUser/${id}`)
      .pipe(map(x => this.commonService.createObject(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getUsersForOrganization(organizationId: string): Observable<SystemUser[]> {
    return this.http
      .get<SystemUser[]>(`${env.apiUrl}/systemUser/organization/${organizationId}`)
      .pipe(map(x => this.commonService.createObjects(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createUser(user: SystemUser): Observable<SystemUser> {
    return this.http
      .post<SystemUser>(`${env.apiUrl}/systemUser`, user)
      .pipe(map(x => this.commonService.createObject(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateUser(user: SystemUser): Observable<SystemUser> {
    return this.http
      .put<SystemUser>(`${env.apiUrl}/systemUser/${user?.systemUserId}`, user)
      .pipe(map(x => this.commonService.createObject(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createUserRole(systemUserId: string, userOrganizationRole: UserOrganizationRole): Observable<UserOrganizationRole> {
    return this.http
      .post<UserOrganizationRole>(`${env.apiUrl}/systemUser/${systemUserId}/roles`, userOrganizationRole)
      .pipe(map(x => this.commonService.createObject(UserOrganizationRole, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateUserRole(systemUserId: string, userOrganizationRole: UserOrganizationRole): Observable<UserOrganizationRole> {
    return this.http
      .put<UserOrganizationRole>(`${env.apiUrl}/systemUser/${systemUserId}/roles/${userOrganizationRole?.systemUserAccessId}`, userOrganizationRole)
      .pipe(map(x => this.commonService.createObject(UserOrganizationRole, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  terminateUserRole(systemUserId: string, userOrganizationRoleId: string): Observable<UserOrganizationRole> {
    return this.http.delete<UserOrganizationRole>(`${env.apiUrl}/systemUser/${systemUserId}/roles/${userOrganizationRoleId}`)
    .pipe(res => res)
    .pipe(catchError(this.commonService.handleError));
  }

  getSystemUserWithOrganization(organizationId: string, systemUserId: string): Observable<SystemUser> {
    return this.http.get<SystemUser>(`${env.apiUrl}/systemUser/${systemUserId}/organization/${organizationId}`)
    .pipe(map(x => this.commonService.createObject(SystemUser, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getSystemUsersByRoleAndAccessLevel(role: string, accessLevel: string, inactive: boolean): Observable<SystemUser[]> {
    return this.http.get<SystemUser[]>(`${env.apiUrl}/systemUser/role/${role}/access/${accessLevel}/?inactiveInd=${inactive}`)
      .pipe(map(x => this.commonService.createObjects(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getSystemUsersByUserType(userTypeId: string, agencyFilter: string, searchFilter: string, gridQuery: string): Observable<GridDataResult> {
    var orgFilter = "";
    var orgFilterTxt = "OrganizationOrganizationString";
    var orgFilterTxtStart = gridQuery.indexOf(orgFilterTxt);
    if (orgFilterTxtStart>0) { 
      var orgFilterFilter = "";
      if (gridQuery.indexOf("(" + orgFilterTxt)>0) {
        //filtering by multiple simplified status
        const orgFilterMultiFilterStart = gridQuery.indexOf('(' + orgFilterTxt);
        const orgFilterMultiFilterEnd = gridQuery.indexOf(")",orgFilterMultiFilterStart);
        orgFilterFilter = gridQuery.substring(orgFilterMultiFilterStart,orgFilterMultiFilterEnd + 1);
      }
      else {
        var orgFilterFilterStart = gridQuery.indexOf("'",orgFilterTxtStart) + 1
        var orgFilterFilterEnd = gridQuery.indexOf("'",orgFilterFilterStart);
        orgFilterFilter= gridQuery.substring(orgFilterTxtStart, orgFilterFilterEnd + 1);
        orgFilter = gridQuery.substring(orgFilterFilterStart,orgFilterFilterEnd);
      }
      gridQuery = gridQuery.replace("~and~" + orgFilterFilter, "");
      gridQuery = gridQuery.replace(orgFilterFilter + "~and~", "");
      gridQuery = gridQuery.replace(orgFilterFilter, "");
    }

    return this.http.get<GridDataResult>(`${env.apiUrl}/systemUser/userTypes/${userTypeId}/?agencyFilter=${agencyFilter}&searchFilter=${searchFilter}&${gridQuery}&orgFilter=${orgFilter}`)
      .pipe(map(x => {
          x.data = this.commonService.createObjects(SystemUser, x.data);
          return x;
        }
       ))
      .pipe(catchError(this.commonService.handleError));
  }

  validateEmail(email: string): Observable<SystemUser[]> {
    return this.http.get<SystemUser[]>(`${env.apiUrl}/systemUser/validateEmail?emailAddress=${email}`)
      .pipe(map(x => this.commonService.createObjects(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  disassociateUser(memberId: string, systemUserId: string): Observable<SystemUser> {
    return this.http.put<SystemUser>(`${env.apiUrl}/systemUser/${systemUserId}/member/${memberId}`, {})
      .pipe(catchError(this.commonService.handleError));
  }

  disassociateAdminUser(systemUserId: string, orgId: string): Observable<SystemUser> {
    return this.http.put<SystemUser>(`${env.apiUrl}/systemUser/${systemUserId}/release/${orgId}`, {})
      .pipe(map(x => this.commonService.createObject(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteUser(systemUserId: string, orgId: string): Observable<SystemUser> {
    return this.http.delete<SystemUser>(`${env.apiUrl}/systemUser/${systemUserId}/remove/${orgId}`, {})
      .pipe(catchError(this.commonService.handleError));
  }

  getHCASystemUsers(): Observable<SystemUser[]> {
    return this.http.get<SystemUser[]>(`${env.apiUrl}/systemUser/users/hca`)
      .pipe(map(x => this.commonService.createObjects(SystemUser, x)))
      .pipe(catchError(this.commonService.handleError));
  }

}
