// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Carrier834Hold from 'src/app/models/carrier834Hold';

@Injectable({
  providedIn: 'root'
})

export class Carrier834HoldService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getCarrier834HoldForSubscriber(subscriberMemberId: string): Observable<Carrier834Hold[]> {
    return this.http
      .get<Carrier834Hold>(`${env.apiUrl}/CarrierTransaction/getBySubscriber/${subscriberMemberId}`)
      .pipe(map(x => this.commonService.createObjects(Carrier834Hold, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  resendTransactions(transactionList: Carrier834Hold[], subscriberMemberId: string): Observable<Carrier834Hold[]> {
    return this.http
      .post<Carrier834Hold>(`${env.apiUrl}/CarrierTransaction/resend/${subscriberMemberId}`,transactionList)
      .pipe(map(x => this.commonService.createObjects(Carrier834Hold, x)))
      .pipe(catchError(this.commonService.handleError));
  }
}
