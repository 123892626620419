import { CoreService } from 'src/app/services/core.service';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NgForm } from '@angular/forms';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { orderBy, cloneDeep, forEach, reverse, sortBy, find, get, filter, findIndex, maxBy, first } from 'lodash';
import { faClipboard, faPlus, faMinus, faCalendar } from '@fortawesome/free-solid-svg-icons';
import RelationshipCertification from 'src/app/models/relationshipCertification';
import CertificationType from 'src/app/models/certificationType';
import Relationship from 'src/app/models/relationship';
import RelationshipCertificationStatus from 'src/app/models/relationshipCertificationStatus';
import * as dayjs from 'dayjs';

@Component({
  selector: 'certification',
  templateUrl: 'certification.component.html',
  styleUrls: [],
  providers: [NgbNavConfig],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificationComponent implements OnChanges {
  icons = {
    faClipboard,
    faPlus,
    faMinus,
    faCalendar,
  };
  activeTab;
  tempCertTypeId: string;
  @Input() relationship: Relationship;
  @Input() certifications: RelationshipCertification[] = [];
  @Input() certificationTypes: CertificationType[] = [];
  @Input() certificationStatuses: RelationshipCertificationStatus[];
  @Input() isHCA = false;
  @Input() isPerspay = false;
  @Input() isSubscriber = true;
  @Input() isReadOnly = true;
  private editedRowIndex: number;
  private editedDataItem: any;
  minEffectiveStartDate: Date[] = [];
  clonedGridData;
  relationshipClone: Relationship;
  gridColumns = [
    { field: 'certificationTypeId', title: 'Certification Type', format: 'string', required: true },
    { field: 'effectiveStartDate', title: 'Start Date', format: 'date', required: true },
    { field: 'effectiveEndDate', title: 'End Date', format: 'date', required: true },
  ];
  state: State = {
    skip: 0,
  };
  mostRecentCertification: RelationshipCertification;
  @Output() pushSaveCertification: EventEmitter<RelationshipCertification> = new EventEmitter<RelationshipCertification>();
  @Output() pushDeleteCertification: EventEmitter<RelationshipCertification> = new EventEmitter<RelationshipCertification>();
  @Output() pushSaveCertificationStatus: EventEmitter<Relationship> = new EventEmitter<Relationship>();
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;
  @ViewChild('employmentForm') public userForm: NgForm;
  constructor(private tabConfig: NgbNavConfig, private coreService: CoreService) {}

  ngOnChanges(): void {
    if (this.certifications) {
      if (Array.isArray(this.certifications)) {
        this.minEffectiveStartDate = [];

        this.certifications.forEach(cert => {
          const previousCerts = filter(this.certifications, c => c !== cert && dayjs(c.effectiveStartDate).toDate() < dayjs(cert.effectiveStartDate).toDate());
          const previousCert = first(orderBy(previousCerts, ['effectiveStartDate'], ['desc']));

          let min = previousCert?.effectiveEndDate;
    
          this.minEffectiveStartDate.push(min);
        });
      }

      this.resetDataForGrid();
      this.relationshipClone = cloneDeep(this.relationship);
      this.mostRecentCertification = this.getMostRecentCertificationRecord();
      this.tempCertTypeId = get(
        find(this.certificationTypes, (ct: CertificationType) => ct.certificationTypeCode === 'T'),
        'certificationTypeId'
      );
    }
    if (this.relationship?.relationshipType?.relationshipTypeCode === 'F') {
      this.certificationTypes = filter(this.certificationTypes, (ct: CertificationType) => ct.certificationTypeId === this.tempCertTypeId);
    }
  }

  resetDataForGrid(): void {
    this.clonedGridData = sortBy(cloneDeep(this.certifications), 'effectiveStartDate');
  }

  getMostRecentCertificationRecord(): RelationshipCertification {
    const cert: RelationshipCertification = new RelationshipCertification();
    const orderedList = reverse(sortBy(this.certifications, 'effectiveStartDate'));
    return orderedList.length > 0 ? orderedList[0] : cert;
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.clonedGridData = process(this.certifications, state);
  }

  saveCertification(dataItem: RelationshipCertification, rowIndex, formContainer, ef: NgForm): void {
    this.coreService.markFormControlsAsTouched(ef);
    if (dataItem.effectiveStartDate && !dayjs(dataItem.effectiveStartDate).isSame(dayjs(dataItem.effectiveStartDate).startOf('month'), 'day') 
      && !dayjs(dataItem.effectiveStartDate).isSame(dayjs(this.relationship?.effectiveStartDate))) {
      ef.controls['effectiveStartDate' + rowIndex].setErrors({firstOfMonth: true});
      this.coreService.popMessage('Effective start date must be the first of a month.', 'error', 3000);
    } else if (dataItem.effectiveEndDate && !dayjs(dataItem.effectiveEndDate).isSame(dayjs(dataItem.effectiveEndDate).endOf('month'), 'day')) {
      ef.controls['effectiveStartDate' + rowIndex].setErrors({endOfMonth: true});
      this.coreService.popMessage('Effective end date must be the end of a month.', 'error', 3000);
    } else {
      if (ef.valid) {
        this.pushSaveCertification.emit(dataItem);
      } else {
        if (dataItem.effectiveStartDate < maxBy(filter(this.certifications,(c:RelationshipCertification)=>c.relationshipCertificationId!=dataItem.relationshipCertificationId),
          'effectiveEndDate')?.effectiveEndDate) {
          this.coreService.popMessage('Certification Start date must be after latest certification end date', 'error', 3000);
        } else {
          this.coreService.popMessage('Fields missing or invalid', 'error', 3000, this.coreService.getInvalidFields(formContainer));
        }
      }
    }
  }

  public addHandler({ sender }): void {
    this.closeEditor(sender);
    sender.addRow(this.createNewBlankRecord());
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex, cancel?: boolean): void {
    grid.closeRow(rowIndex);
    if (cancel) {
      this.clonedGridData[rowIndex] = this.editedDataItem;
    }
    this.editedRowIndex = undefined;
    this.editedDataItem = undefined;
  }

  createNewBlankRecord(): RelationshipCertification {
    const dataItem = new RelationshipCertification({});
    forEach(dataItem, (v, k) => {
      dataItem[k] = null;
    });

    dataItem.relationshipId = this.relationship.relationshipId;

    const previousCert = first(orderBy(this.certifications, ['effectiveStartDate'], ['desc']));
    this.minEffectiveStartDate[-1] = previousCert?.effectiveEndDate;

    return dataItem;
  }

  deleteCertification(dataItem: RelationshipCertification, rowIndex: number): void {
    this.closeEditor(this.kendoGrid, rowIndex, true);
    if (dataItem.relationshipCertificationId) {
      this.pushDeleteCertification.emit(dataItem);
      this.certifications.splice(rowIndex, 1);
      this.resetDataForGrid();
    }
  }

  submitCertificationStatus(): void {
    this.pushSaveCertificationStatus.emit(this.relationshipClone);
  }

  cancelCertificationStatusChanges(): void {
    this.relationshipClone = cloneDeep(this.relationship);
  }
}
