<div fxFlexFill fxLayout="column" fxLayoutAlign="center center" class="fxGap-small container-fluid body-container">
  <top-nav fxFlexFill></top-nav>
  <div *ngIf="selfPayOnly" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
    <ul fxFlexLayout="row" style="margin-left: 15px" class="nav nav-tabs admin-tab-group">
      <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'/admin/selfpaydashboard/' + systemUser?.systemUserId">Selfpay Dashboard</strong></li>
      <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/subscriberManagement/' + systemUser?.systemUserId">Manage Subscribers</strong></li>
      <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/relationshipVerification/hca/' + systemUser?.systemUserId">Dependent Verification</strong></li>
      <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/specialOpenEnrollment/review/hca/' + systemUser?.systemUserId">Special Enrollment Verification</strong></li>
      <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'/admin/selfpay/review/hca/' + systemUser?.systemUserId">Continuation/Retiree</strong></li>
      <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [queryParams]="{ selfPayOnly: true}" [queryParams]="{ selfPayOnly: true}" [routerLink]="'/admin/reports/' + systemUser.systemUserId">Reports</strong></li>
    </ul>
  </div>
  <agency-select fxFlexFill></agency-select>
  <div fxFlexFill><router-outlet #outlet id="outlet-main"></router-outlet></div>
  <pebb-footer fxFlexFill [apiVersion]="apiVersion" [version]="version"></pebb-footer>
</div>
