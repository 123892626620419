import Member from './member';
import { some, find, filter, reverse, sortBy, head, get, forEach } from 'lodash';
import Relationship from './relationship';
import Enrollment from './enrollment';
import Attestation from './attestation';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import Subscriber from './subscriber';
import EnrollmentPeriod from './enrollmentPeriod';
import RelationshipType from './relationshipType';
import PlanType from './planType';
import { env } from '../../env/development';

export default class MemberSummary {
  memberId: string;
  subscriberMemberId: string;
  subscriberInd: boolean;
  spouseInd: boolean;
  firstName: string;
  middleName: string;
  lastName: string;
  memberName: string;
  effectiveEnrollments: Enrollment;
  currentMedical: Enrollment;
  currentDental: Enrollment;
  currentVision: Enrollment;
  medicalEffectiveDates: string;
  dentalEffectiveDates: string;
  visionEffectiveDates: string;
  relationshipType: RelationshipType;
  tobaccoUseInd: string;
  sortOrder: number;
  birthDate: Date;
  adoptionDate: Date;
  effectiveEnrollmentsByPlanType: {[k: string]: Enrollment} = {};
  member: Member;
  isVerified = false;
  constructor(member: Member, subscriberMemberId: string, enrollmentPeriod?: EnrollmentPeriod) {
    if (!enrollmentPeriod) {
      enrollmentPeriod = new EnrollmentPeriod();
      enrollmentPeriod.coverageEffectiveStartDate = new Date();
    }
    this.member = member;
    this.birthDate = member.birthDate;
    this.adoptionDate = member.adoptionDate;
    this.memberId = member.memberId;
    this.subscriberMemberId = subscriberMemberId;
    this.subscriberInd = member.isSubscriberInd;
    this.firstName = member.firstName;
    this.middleName = member.middleName;
    this.lastName = member.lastName;
    this.sortOrder = get(member, 'relationshipToSubscriber.relationshipType.sortOrder', 0);
    this.relationshipType = member.relationshipToSubscriber.relationshipType;
    this.memberName =
      (member.firstName !== undefined ? member.firstName : '') +
      ' ' +
      (member.lastName !== undefined ? member.lastName : '');
    this.isVerified = member?.isSubscriberInd || member?.relationshipToSubscriber?.approvalDate != null;
    this.spouseInd = some(
      member.primaryRelationships,
      (pr: Relationship) =>
        pr.dependentMemberId === subscriberMemberId &&
        pr.relationshipType.relationshipTypeName === 'IsSpouseOf' &&
        new Date(pr.effectiveStartDate.getFullYear(), pr.effectiveStartDate.getMonth(), 1) <= new Date() &&
        (!pr.effectiveEndDate || pr.effectiveEndDate > new Date())
    );

    this.effectiveEnrollments = filter(
      member.enrollments,
      (e: Enrollment) =>
        (
          (
            //transfer event
            enrollmentPeriod.enrollmentPeriodType && 
            enrollmentPeriod.enrollmentPeriodType?.enrollmentPeriodTypeCode === env.newFullBenefitsEnrollmentPeriodType &&
            new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1) > enrollmentPeriod.coverageEffectiveStartDate &&
            new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1) <= enrollmentPeriod.effectiveEndDate
          ) || 
          new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1) <= enrollmentPeriod.coverageEffectiveStartDate
        ) &&
        (!e.effectiveEndDate || e.effectiveEndDate > enrollmentPeriod.coverageEffectiveStartDate) &&
        e.plan?.planCode !== env.dentalWaivePlanCode
    );

    forEach(enrollmentPeriod.electablePlanTypes,
       (ept: PlanType) => {
         this.effectiveEnrollmentsByPlanType[ept.planTypeCode] = find(this.effectiveEnrollments, (e: Enrollment) => e.plan.planTypeId === ept.planTypeId);
       });

    this.currentMedical = find(
      member.enrollments,
      (e: Enrollment) =>
        e.plan.planType.planTypeName === 'Medical' &&
        new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1) <= new Date() &&
        (!e.effectiveEndDate || e.effectiveEndDate > new Date())
    );
    this.medicalEffectiveDates = this.currentMedical
    ? `${dayjs(this.currentMedical.effectiveStartDate).format('MM/DD/YYYY')}
    ${this.currentMedical.effectiveEndDate ? ' - ' + dayjs(this.currentMedical.effectiveEndDate).format('MM/DD/YYYY') : ' - Current' }` : 'Not Enrolled';

    this.currentDental = find(
      member.enrollments,
      (e: Enrollment) =>
        e.plan.planType.planTypeName === 'Dental' &&
        new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1) <= new Date() &&
        (!e.effectiveEndDate || e.effectiveEndDate > new Date()) &&
        e.plan?.planCode !== env.dentalWaivePlanCode
    );

    this.currentVision = find(
      member.enrollments,
      (e: Enrollment) =>
        e.plan.planType.planTypeName === 'Vision' &&
        new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1) <= new Date() &&
        (!e.effectiveEndDate || e.effectiveEndDate > new Date())
    );
    
    this.dentalEffectiveDates = this.currentDental ? `${dayjs(this.currentDental.effectiveStartDate).format('MM/DD/YYYY')}
    ${this.currentDental.effectiveEndDate ? ' - ' + dayjs(this.currentDental.effectiveEndDate).format('MM/DD/YYYY') : ' - Current' }` : 'Not Enrolled';

    this.visionEffectiveDates = this.currentVision ? `${dayjs(this.currentVision.effectiveStartDate).format('MM/DD/YYYY')}
    ${this.currentVision.effectiveEndDate ? ' - ' + dayjs(this.currentVision.effectiveEndDate).format('MM/DD/YYYY') : ' - Current' }` : 'Not Enrolled';


    const tobaccoAttestations = filter(
      member.attestations,
      (a: Attestation) => a.attestationType.attestationTypeName === 'Tobacco Surcharge'
    );
    this.tobaccoUseInd = this.getTobaccoIndicator(member, enrollmentPeriod);
  }

  memberHasEffectiveMedicalCoverageForDate(member: Member, coverageStartDate: Date): boolean {
    return member &&
      member.enrollments &&
      some(member.enrollments, (e: Enrollment) =>
        dayjs(new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1)).isSameOrBefore(coverageStartDate, 'day') &&
        e.plan.planType.planTypeCode === '1'
        );
  }

  getTobaccoIndicator(m: Member, enrollmentPeriod: EnrollmentPeriod): string {
    if (
      // has medical coverage for period
          this.memberHasEffectiveMedicalCoverageForDate(m, enrollmentPeriod.coverageEffectiveStartDate) &&
          // and is not denied
          // either - has some attestation before the period of the right type and indicated 'YES'
          (some(
            m.attestations,
            (a: Attestation) =>
              dayjs(new Date(a.effectiveStartDate.getFullYear(), a.effectiveStartDate.getMonth(), 1)).isSameOrBefore(enrollmentPeriod.coverageEffectiveStartDate)
              && (!a.effectiveEndDate || dayjs(a.effectiveEndDate).isSameOrAfter(enrollmentPeriod.coverageEffectiveStartDate))
              && a.attestationType.attestationTypeCode === 'TS'
              && a.response.responseName === 'Yes'
          ) ||
          // or is over 13
          (!m.isUnderThirteen &&
          // and has no attestation before the period of the right type at all (default)
            !some(m.attestations, (a: Attestation) =>
              dayjs(new Date(a.effectiveStartDate.getFullYear(), a.effectiveStartDate.getMonth(), 1)).isSameOrBefore(enrollmentPeriod.coverageEffectiveStartDate) &&
              a.attestationType.attestationTypeCode === 'TS')))
      ) {
        return 'Yes';
      }
    return 'No';
  }


}
