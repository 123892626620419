import { SpinnerOverlayService } from './../../../../../../services/spinnerOverlay.service';
import { CoreService } from './../../../../../../services/core.service';
import { filter, flatten, forEach, cloneDeep, includes, map, uniqBy, groupBy, some, sortBy } from 'lodash';
import { DocumentService } from './../../../../../../services/document.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericFileUploadComponent } from './../../../../../shared/components/genericFileUpload/genericFileUpload.component';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import SelfPay from 'src/app/models/selfPay';
import SystemUser from 'src/app/models/user';
import Subscriber from 'src/app/models/subscriber';
import Document from 'src/app/models/document';
import DocumentType from 'src/app/models/documentType';
import Member from 'src/app/models/member';
import { SelfPayService } from 'src/app/services/selfPay.service';
import { lastValueFrom } from 'rxjs';
import { env } from 'src/env/development';

@Component({
  selector: 'self-pay-upload',
  templateUrl: 'selfPay.upload.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class SelfPayUploadComponent implements OnInit, OnChanges {
  @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() refetchSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @ViewChild('selfPayDocForm') public docForm: NgForm;
  @Input() selfPay: SelfPay;
  @Input() agency: string;
  @Input() subscriber: Subscriber;
  @Input() isRetiree: boolean = false;
  initialDocuments: Document[];
  icons = {
    faCalendar,
  };
  inAdminState: boolean;
  allowedFileTypes: string[] = ['pdf', 'jpg', 'jpeg', 'png'];
  systemUser: SystemUser;
  @Input() documentTypes: DocumentType[];
  documents: Document[] = [];
  submitted = false;
  changesHaveBeenSaved = false;
  @ViewChild('fileUpload') fileUpload: GenericFileUploadComponent;
  isCobra: boolean = false;  
  public showConfirmation = false;
  env = env;

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    public coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private selfPayService: SelfPayService
  ) {}

  ngOnInit(): void {
    this.documents = this.selfPay.documents;
    this.initialDocuments = cloneDeep(this.selfPay.documents);
    this.documentTypes = sortBy(this.documentTypes, (dt: DocumentType) => dt?.documentTypeName);
    this.isCobra = env.cobraSelfPayTypeCodes.includes(this.selfPay.selfPayType.selfPayTypeCode);
  }

  ngOnChanges(changesObject: SimpleChanges) {
    if(!changesObject?.selfPay?.firstChange) {
      this.selfPay = cloneDeep(changesObject.selfPay.currentValue);
      this.documents = this.selfPay.documents;
      this.initialDocuments = cloneDeep(this.selfPay.documents);
      this.isCobra = env.cobraSelfPayTypeCodes.includes(this.selfPay.selfPayType.selfPayTypeCode);
    }
  }

  handleFilesSelected(e): void {
    e.files.forEach((file) => {
      const document = new Document();
      document.documentName = file.name;
      document.documentBlob = file.rawFile;
      document.documentId = 'NEW' + file.name;
      document.documentTypeId = null;
      this.documents.push(document);
      this.documents = cloneDeep(this.documents);
    });
  }

  handleFilesRemoved(e): void {
    const removedFileNames = map(e.files, 'name');
    this.documents = filter(this.documents, (d) => !removedFileNames.includes(d.documentName));
  }

  async submitFileChanges(): Promise<void> {
    // SelfPay
    this.markAllControlsAsTouched();
    if (!this.docForm.valid) {
      return this.coreService.popMessage('You must enter a document type for each document uploaded', 'error', 5000);
    }
    const allInitialSelfPayDocuments = this.initialDocuments;
    const allModifiedSelfPayDocuments = this.documents;
    // newly uploaded (POST multipart content)
    const newSelfPayDocuments = filter(allModifiedSelfPayDocuments, (d) => d?.documentId.includes('NEW'));
    // doctype change (PUT to docID)
    const docTypeChangesSelfPay = filter(allModifiedSelfPayDocuments, (d) => some(allInitialSelfPayDocuments, (id) => id?.documentId === d?.documentId && id.documentTypeId !== d?.documentTypeId));
    // member added (POST w/o payload)
    const selfPayChanges = filter(
      allModifiedSelfPayDocuments,
      (doc) => !doc.documentId.includes('NEW') && !some(allInitialSelfPayDocuments, (id) => id.selfPayId === doc.selfPayId && id.documentId === doc.documentId)
    );
    // member removed (DELETE w/ selfPayid & documentId)
    const docsRemovedSelfPay = filter(
      allInitialSelfPayDocuments,
      (doc) => !some(allModifiedSelfPayDocuments, (md) => md.selfPayId === doc.selfPayId && md.documentId === doc.documentId)
    );
    // // if new documents
    const someChangeSelfPay = newSelfPayDocuments.length || docTypeChangesSelfPay.length || selfPayChanges.length || docsRemovedSelfPay.length;
    if (someChangeSelfPay) {
      try {
        this.spinnerService.show();
        const newDocPromises: Promise<Document>[] = [];
        const docTypePromises: Promise<Document>[] = [];
        const selfPayPromises: Promise<SelfPay>[] = [];
        for (const doc of newSelfPayDocuments) {
          const newDoc = await lastValueFrom(this.documentService.createDocumentForSelfPay(this.selfPay.selfPayId, this.subscriber.memberId, doc.documentTypeId, doc));
          // newDocPromises.push(this.documentService.addDocumentToSelfPay(doc.selfPayId, newDoc[0])));
        }
        for (const doc of docTypeChangesSelfPay) {
          docTypePromises.push(lastValueFrom(this.documentService.updateSelfPayDocumentType(doc.selfPayId, this.subscriber.memberId, doc)));
        }
        await Promise.all(newDocPromises);
        await Promise.all(docTypePromises);
        await Promise.all(selfPayPromises);
        this.spinnerService.hide();
        this.submitted = false;
        this.showConfirmation = true;
        this.spinnerService.hide();
        this.changesHaveBeenSaved = true;
      } catch (err) {
        this.spinnerService.hide();
        this.submitted = false;
        console.log(err);
        this.coreService.popMessage(err.error, 'error', 5000);
      }
      this.spinnerService.hide();
      this.coreService.popMessage(`You have successfully uploaded your document(s).`, 'success', 5000);
      this.refetchSelfPay.emit(this.selfPay);
    }
  }

  clearSelections(): void {
    this.fileUpload.clearSelections(this.documents);
    this.documents = filter(this.selfPay.documents, (d) => d.documentId !== 'NEW');
    this.documents = cloneDeep(this.initialDocuments);
  }

  emitSelfPay(e) {
    if(this.pendingChanges) {
      return this.coreService.popMessage('You must submit or clear changes before continuing.','error', 3000);
    }else {
      this.saveSelfPay.emit(e);
    }
  }

  markAllControlsAsTouched() {
    this.coreService.markFormControlsAsTouched(this.docForm);
  }

  get pendingChanges(): boolean {
    const allInitialSelfPayDocuments = this.initialDocuments;
    const allModifiedSelfPayDocuments = this.documents;
    // newly uploaded (POST multipart content)
    const newSelfPayDocuments = filter(allModifiedSelfPayDocuments, (d) => d?.documentId.includes('NEW'));
    // doctype change (PUT to docID)
    const docTypeChangesSelfPay = filter(allModifiedSelfPayDocuments, (d) => some(allInitialSelfPayDocuments, (id) => id?.documentId === d?.documentId && id.documentTypeId !== d?.documentTypeId));
    // // if new documents
    return newSelfPayDocuments.length || docTypeChangesSelfPay.length;
  }

  emitSave(selfPay: SelfPay) {
    this.saveSelfPay.emit(selfPay);

  }
}
