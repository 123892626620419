<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <div class="state">
      <div class="card">
        <div class="card-body">
            <h1>{{currentOrganization?.organizationName}}</h1>
            <h2 class="mobile-text-centered subhead-mg">Data Depot</h2>
            <div *ngIf="!isHCAEditOrAdmin && dataDepotLastUploadDate">
                <div class="h6 mgtp-x-small">Last file uploaded on {{dataDepotLastUploadDate | date: 'MM/dd/yyyy'}}</div>
            </div>
            <div fxLayout="column" class="fxGap-medium" *ngIf="isHCAEditOrAdmin">
                <div>
                    <generic-file-upload
                    #fileUpload
                    [autoUpload]="false"
                    [type]="'document'"
                    [maxfilesize]="31457280"
                    [allowedFileTypes]="allowedFileTypes"
                    [systemUser]="systemUser"
                    (selectEventHandler)="handleFilesSelected($event)"
                    (removeEventHandler)="handleFilesRemoved($event)"
                    >
                    </generic-file-upload>
                </div>
                <div *ngIf="newDocuments.length>0" fxLayout="row" fxLayoutAlign="space-between">
                    <button class="btn btn-primary" style="width: 30%;" (click)="submitFileChanges()">Upload Files</button>
                    <button class="btn btn-light" style="width: 30%;" (click)="clearSelections()">Clear</button>
                </div>
            </div>
            <div class="kg-container-noscroll">
                <h3>Previously uploaded files</h3>
                <form novalidate #myForm="ngForm" class="grid-container">
                    <kendo-grid
                      [data]="gridDataResult"
                      [pageable]="false"
                      [sortable]="true"
                      [selectable]="false"
                      (edit)="editHandler($event)"
                      (cancel)="cancelHandler($event)"
                      (save)="saveHandler($event)"
                      [sortable]="{
                        allowUnsort: true,
                        mode: 'single'
                      }"
                      [sort]="sort"
                      (sortChange)="sortChange($event)"
                      (remove)="removeHandler($event)"
                    >
                        <kendo-grid-column title="" width="60" aria-label="File Icon Column">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                                <div fxFlexFill fxLayout="column" fxLayoutAlign="center center">
                                    <div role="button" aria-pressed="false" 
                                        [attr.aria-label]="'download ' + dataItem.documentName + ' file'" class="clickable" (click)="downloadFile(dataItem)">
                                        <span>
                                            <fa-icon class="hca-fontawesome-icon" [icon]="getIcon(dataItem.fileExtension)" size="2x"></fa-icon>
                                        </span>
                                    </div>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="documentName" title="File" class="kg-column-min-size">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                                <div fxFlexFill fxLayoutAlign="space-around" fxLayout="column">
                                    <div>
                                        {{dataItem.documentName}}
                                    </div>
                                    <div *ngIf="dataItem.documentDescription" class="h6">
                                        {{dataItem.documentDescription}}
                                    </div>
                                </div>
                            </ng-template>  
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <div fxFlexFill fxLayoutAlign="space-around" fxLayout="column">
                                    <div fxFlexOffset="2">
                                        {{dataItem.documentName}}
                                    </div>
                                    <div fxFlexOffset="2">
                                        <div class="form-group">
                                            <label for="documentDescription"  aria-label="Document Description"><b>Description</b></label>
                                            <textarea style="width: 100%" height="40px"
                                                [(ngModel)]="dataItem.documentDescription"
                                                kendoGridFocusable
                                                name="documentDescription"
                                                id="documentDescription"
                                                class="k-textbox k-input k-rounded-md"
                                            ></textarea>
                                    </div>
                                    </div>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="createdDate" title="Date Added" width="200">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                                {{dataItem.createdDate | date: 'MM/dd/yyyy'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-command-column title="" width="175" *ngIf="isHCAEditOrAdmin">
                            <ng-template kendoGridCellTemplate>
                                <div fxFlexFill fxLayoutAlign="space-around" fxLayout="row">
                                    <button kendoGridEditCommand type="button">
                                        <span class="pma-icon-button" aria-label="Edit Document"><fa-icon [icon]="icons.faPencil"></fa-icon></span>
                                    </button>
                                    <button kendoGridRemoveCommand type="button" class="pma-delete-button" aria-label="Remove Document">Remove</button>
                                    <button kendoGridSaveCommand type="button" [disabled]="myForm.invalid || myForm.pristine"  aria-label="Update Document">Update</button>
                                    <button kendoGridCancelCommand type="button" aria-label="Cancel Edit Document">Cancel</button>
                                </div>                            
                            </ng-template>
                        </kendo-grid-command-column>
                    </kendo-grid>
                  </form>
            </div>
        </div>
    </div>
</div>