import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import Registration from 'src/app/models/registration';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'registration',
  templateUrl: 'registration.component.html',
  styleUrls: [],
  providers: []
})
export class RegistrationComponent implements OnInit {
  public emailAddress;
  public password;
  public successfulSubmit = false;
  public registration: Registration;
  public passwordConfirmation: string;
  public waTechQuestions: string[];
  public maxDate = new Date();
  @ViewChild('notificationTemplate', { read: TemplateRef, static: true }) public notificationTemplate: TemplateRef<any>;
  @ViewChild('registrationForm') public registrationForm: NgForm;

  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
  }

  submitRegistration() {
    for (const i in this.registrationForm.controls) {
      if (this.registrationForm.controls[i]) {
        this.registrationForm.controls[i].markAsTouched();
      }
    }
    if (this.registrationForm.form.invalid || this.passwordConfirmation !== this.registration.password) {
      this.notificationService.show({
        content: this.notificationTemplate,
        animation: { type: 'slide', duration: 300 },
        position: { horizontal: 'center', vertical: 'top' },
        type: { style: 'warning', icon: true },
        hideAfter: 3000
      });
    } else {
      this.authService.submitRegistration(this.registration).pipe(untilDestroyed(this)).subscribe(success => {
        this.successfulSubmit = true;
      },
      err => console.log(err));
    }
  }

  ngOnInit() {
    this.waTechQuestions = [
      'What was your first teacher\'s last name?',
      'What city were you born in?',
      'What city did your first airplane ride take you to?',
      'Who was your best childhood friend?',
      'What was the name of your first permanent employer?',
      'By what nickname did your friends call you when you were a child?',
      'What was the mascot of your high school football team?'
    ];
    this.registration = new Registration();
  }

filterQuestions(questions: string[], idx: number): string[] {
    return questions.filter(q => q !== this.registration.secretQuestions[idx].question);
}

onlyNumbers(e): void {
    if (e.which < 48 || e.which > 57) {
        e.preventDefault();
        e.stopPropagation();
    }
}

}
