<div class="card">
    <div class="card-body">
      <generic-grid
        [exportable]="true"
        [pageable]="true"
        [pageSize]="15"
        style="height: 400px; overflow-y: auto;"
        [gridColumns]="gridColumns"
        [gridData]="errorResponses"
        [filterable]=true
        [resizable]=true
      ></generic-grid>
    </div>
  </div>
  