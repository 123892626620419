import { DocumentService } from './../../../../services/document.service';
import { OnInit, Component, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import Organization from 'src/app/models/organization';
import SystemUser from 'src/app/models/user';
import { filter, some, remove, map } from 'lodash';
import Document from 'src/app/models/document';
import { GenericFileUploadComponent } from 'src/app/modules/shared/components/genericFileUpload/genericFileUpload.component';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { faFileLines, faPencil, faFileExcel, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { env } from 'src/env/development';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.DocumentType)
@Component({
    selector: 'data-depot',
    templateUrl: 'dataDepot.component.html',
    styleUrls: []
  })

export class DataDepotComponent implements OnInit, OnDestroy {
  systemUser: SystemUser;
  isHCA = false;
  isHCAEditOrAdmin = false;
  isHCAAdmin = false;
  currentOrganization: Organization;
  allowedFileTypes: string[] = ['txt', 'csv', 'xls', 'xlsx'];
  env = env;
  dataDepotDocTypeId: string="";
  newDocuments: Document[] = [];
  documents: Document[] = [];
  dataDepotLastUploadDate: Date;
  @ViewChild('fileUpload') fileUpload: GenericFileUploadComponent;
  orgSubscription: any;
  routeSubscription: any;
  public gridDataResult: GridDataResult = {
    data: this.documents,
    total: this.documents.length,
  };
  public sort: SortDescriptor[] = [
    {
      field: "createdDate",
      dir: "desc",
    },
  ];
  private editedRowIndex: number;
  private editedDocument: Document;
  icons = {
    faFileLines,
    faPencil,
    faFileExcel, 
    faFileCsv
  };
  
  constructor(
    private route: ActivatedRoute,
    private spinnerService: SpinnerOverlayService,
    private coreService: CoreService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
      this.isHCA = some(this.systemUser.userOrganizationRoles, r => r.userRoleName === 'HCA');
      this.isHCAEditOrAdmin = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      this.dataDepotDocTypeId = data.lookups.documentType.filter(o=>o.documentTypeCode=='DD')[0].documentTypeId;
      this.currentOrganization = this.coreService.getOrganizationValue();

      if (this.currentOrganization) {
        this.getOrganizationDocuments();
      }

      if (!this.isHCA) {
        //add subscripbion for when the selected org changes for non-admins who have access to multiple organizations
        this.orgSubscription = this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s) => {
          if (s && (!this.currentOrganization || this.currentOrganization.organizationId!==s.organizationId))
          {
            this.currentOrganization = s;
            if (this.currentOrganization && !this.isHCA) {
              this.getOrganizationDocuments();
            }
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  handleFilesSelected(e): void {
    e.files.forEach((file) => {
      const document = new Document();
      document.documentName = file.name;
      document.documentBlob = file.rawFile;
      document.documentTypeId = this.dataDepotDocTypeId;
      this.newDocuments.push(document);
    });
  }

  async getOrganizationDocuments() {
    this.spinnerService.hide();
    this.spinnerService.show();

    this.dataDepotLastUploadDate = null;
    this.documentService.getOrganizationDocuments(this.currentOrganization.organizationId,this.dataDepotDocTypeId).pipe(untilDestroyed(this)).subscribe(docs=>{
      this.documents = docs;
      this.loadDocuments();
      if (this.documents.length>0) {
        this.dataDepotLastUploadDate = this.documents.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))[0].createdDate;
      }
      this.spinnerService.hide();
    });
  }
  
  public editHandler({ sender, rowIndex, dataItem }) {
    if (this.editedRowIndex>-1) {
      this.closeEditor(sender, this.editedRowIndex, false);
    }
    this.closeEditor(sender, rowIndex, false);
    this.editedRowIndex = rowIndex;
    this.editedDocument = Object.assign({}, dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex, true);
  }

  public async saveHandler({ sender, rowIndex, dataItem }) {
    this.spinnerService.show();
    this.documentService.updateDataDepotDoc(this.currentOrganization.organizationId, dataItem).pipe(untilDestroyed(this)).subscribe(res=> {
      sender.closeRow(rowIndex);
      this.editedRowIndex = undefined;
      this.editedDocument = undefined;
      this.spinnerService.hide();
      this.coreService.popMessage("File updated","success",3000);
    });
  }

  public async removeHandler({ dataItem }) {
    this.spinnerService.show();
    this.documentService.removeDataDepotDoc(this.currentOrganization.organizationId,dataItem).pipe(untilDestroyed(this)).subscribe(res=>{
      remove(this.documents, (d) => d.documentId === dataItem.documentId);
      this.loadDocuments();
      this.coreService.popMessage("File removed","success",3000);
      this.spinnerService.hide();
    });
  }

  public async submitFileChanges() {
    var documentsUploaded = 0;
    this.spinnerService.show();
    this.newDocuments.forEach(d=>{
      this.documentService.createDataDepotDoc(this.currentOrganization.organizationId,this.dataDepotDocTypeId,d).pipe(untilDestroyed(this)).subscribe(newDoc=>{
        documentsUploaded+=1;
        this.documents.push(newDoc);
        if (documentsUploaded==this.newDocuments.length) {
          this.clearSelections();
          this.loadDocuments();
          this.spinnerService.hide();
          this.coreService.popMessage("File(s) added","success",3000);
        }
      });
    })
  }
  
  handleFilesRemoved(e): void {
    const removedFileNames = map(e.files, 'name');
    this.newDocuments = filter(this.newDocuments, (d) => !removedFileNames.includes(d.documentName));
  }

  public clearSelections() {
    this.newDocuments = [];
    this.fileUpload.clearSelections(null);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadDocuments();
  }

  private loadDocuments(): void {
    this.gridDataResult = {
      data: orderBy(this.documents, this.sort),
      total: this.documents.length,
    };
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex, cancel: boolean) {
    grid.closeRow(rowIndex);
    if (cancel) {
      var docIndex = this.documents.indexOf(this.documents.find(o=>o.documentId==this.editedDocument.documentId));
      this.documents[docIndex] = this.editedDocument;
      this.loadDocuments();
    }
    this.editedRowIndex = undefined;
    this.editedDocument = undefined;
  }

  downloadFile(dataItem) {
    this.documentService.viewOrganizationDocument(this.currentOrganization.organizationId,dataItem.documentId).pipe(untilDestroyed(this)).subscribe(res=>{
      this.documentService.downloadDocument(res,dataItem.documentName);
    });
  }

  getIcon(fileExtension){
    var fileIcon = null;
    switch(fileExtension) {
      case "csv":
        fileIcon = this.icons.faFileCsv;
        break;
      case "xls":
      case "xlsx":
        fileIcon = this.icons.faFileExcel;
        break;
      default:
        fileIcon = this.icons.faFileLines;
        break;
    }
    return fileIcon;
  }
}
