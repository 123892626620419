export default class MemberMaritalStatus {
  memberMaritalStatusId: string;
  memberId: string;
  startDate: Date;
  endDate: Date;
  dependentMemberId: string;

  createdById: string;
  createdDate: Date;
  modifiedById: string;
  modifiedDate: Date;

  constructor(memberMaritalStatus?) {
    if (memberMaritalStatus) {
      this.memberMaritalStatusId = memberMaritalStatus.memberMaritalStatusId;
      this.memberId = memberMaritalStatus.memberId;
      this.startDate = memberMaritalStatus.startDate ? new Date(memberMaritalStatus.startDate) : null;
      this.endDate = memberMaritalStatus.endDate ? new Date(memberMaritalStatus.endDate) : null;
      this.dependentMemberId = memberMaritalStatus.dependentMemberId;

      this.createdById = memberMaritalStatus.createdById;
      this.createdDate = memberMaritalStatus.createdDate ? new Date(memberMaritalStatus.createdDate) : null;
      this.modifiedById = memberMaritalStatus.modifiedById;
      this.modifiedDate = memberMaritalStatus.modifiedDate ? new Date(memberMaritalStatus.modifiedDate) : null;
    }
  }

}
