export default class TermsOfUse {
  termsOfUseId: string;
  termsOfUseCode: string;
  termsOfUseName: string;
  termsOfUseText: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;

  constructor(termsOfUse?) {
    if (termsOfUse) {
      this.termsOfUseId = termsOfUse.termsOfUseId;
      this.termsOfUseCode = termsOfUse.termsOfUseCode;
      this.termsOfUseName = termsOfUse.termsOfUseName;
      this.termsOfUseText = termsOfUse.termsOfUseText;
      this.effectiveStartDate = termsOfUse.effectiveStartDate ? new Date(termsOfUse.effectiveStartDate) : null;
      this.effectiveEndDate = termsOfUse.effectiveEndDate ? new Date(termsOfUse.effectiveEndDate) : null;
    }
  }
}
