

export default class StepConfig {
  idx: number;
  conditionalNextMet ? = true;
  peviousButtonText = 'previous';
  nextButtonText = 'next';
  isConfirmationStep = false;
  hideButtons = false;
  constructor(step?) {
    if (step) {
        Object.assign(this, step);
    }
  }
}
