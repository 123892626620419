// ng
import { Routes, RouterModule } from '@angular/router';

// components
import { TermsOfUseComponent } from 'src/app/components/termsOfUse/termsOfUse.component';
import { PageNotFoundComponent } from './../components/pageNotFound/pageNotFound.component';
import { ForbiddenComponent } from './../modules/auth/components/forbidden.component';
import { UnauthorizedComponent } from './../modules/auth/components/unauthorized.component';
import { RegistrationComponent } from './../modules/auth/components/registration.component';
import { ClaimComponent } from './../components/claim/claim.component';
import { AuthComponent } from './../modules/auth/auth.component';

// guards
import { MasterGuard } from './../guards/master/master.guard';
// resolvers
import { TOUResolver } from './../resolvers/tou.resolver';
import { LookupsResolver } from './../resolvers/lookups.resolver';
import { NotManagedInSystemComponent } from '../modules/auth/components/notManagedInSystem.component';

const routes: Routes = [
  {
    path: 'termsofuse/:id',
    component: TermsOfUseComponent,
    resolve: {
      termsOfUse: TOUResolver,
    },
    data: {
      guards: ['auth'],
    },
    canActivate: [MasterGuard],
  },
  { path: 'auth', component: AuthComponent },
  {
    path: 'claim',
    component: ClaimComponent,
    canActivate: [MasterGuard],
    data: {
      guards: ['auth'],
    },
  },
  { path: 'auth/register', component: RegistrationComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'notManaged', component: NotManagedInSystemComponent },
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

export const MainRoutes = RouterModule.forRoot(LookupsResolver.addToAllRoutes(routes), {onSameUrlNavigation: 'reload'});
