import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';



@Component({
  selector: 'subscriber-search',
  templateUrl: 'subscriberManagement.search.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class SubscriberManagementSearchComponent implements OnInit {
  @Input() public queryString: string;
  @Output() searchSubmitted: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
  }


  searchSubscribers() {
      this.searchSubmitted.emit(this.queryString);
  }
}
