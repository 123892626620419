import Reason from './reason';

export default class SelfPayReason {
  selfPayReasonId: string;
  reasonId: string;
  eligibilityInd: boolean;
  enrollmentInd: boolean;
  disenrollInd: boolean;
  terminationInd;
  reason: Reason;

  constructor(selfPayReason?) {
    if (selfPayReason) {
      this.selfPayReasonId = selfPayReason.selfPayReasonId;
      this.reasonId = selfPayReason.reasonId;
      this.eligibilityInd = selfPayReason.eligibilityInd || null;
      this.enrollmentInd = selfPayReason.enrollmentInd || null;
      this.disenrollInd = selfPayReason.disenrollInd || null;
      this.terminationInd = selfPayReason.terminationInd || null;
      this.reason = selfPayReason.reason ? new Reason(selfPayReason.reason) : null;
    }
  }


}
