import { Component, ElementRef, Input, Injector, OnInit, ViewChild, OnChanges, SimpleChanges, AfterViewInit, ViewContainerRef, Host, Optional, SkipSelf } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors, AbstractControl, NgForm, FormControl, ControlContainer } from '@angular/forms';
import { __assign } from 'tslib';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'checkbox, radio',
  templateUrl: 'checkbox-radio.component.html',
  styleUrls: [],
  providers:
  [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxRadioComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CheckboxRadioComponent
    }
  ]
})
export class CheckboxRadioComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor, Validator {
  @ViewChild('form') form: NgForm;
  @ViewChild('labelEl') labelEl: ElementRef;
  @Input() name: string;
  @Input() label: string;
  @Input() value: string | boolean;
  @Input() checked: boolean;
  @Input() required: boolean | string = false;
  @Input() readOnly: boolean | string = false;
  @Input() disabled: boolean | string = false;
  @Input() bold: boolean | string = false;
  @Input() labelFirst: boolean | string = false;
  @Input() labelColon: boolean | string = false;
  @Input() radioAsCheckbox: boolean | string = false;
  @Input() labelFlex: number = undefined;
  @Input() inputFlex: number = undefined;
  @Input() partOfList: boolean | string = false;
  @Input() labelInheritMaxWidth = false;
  @Input() canDeselectRadio: boolean | string = false;
  @Input() containerFxLayout: string = undefined;
  @Input() containerfxLayoutAlign: string = undefined;

  type: string;
  model: boolean | string;
  labelVisible = true;
  innerRequired: boolean;
  innerReadonly: boolean;
  innerDisabled: boolean;
  innerBold = false;
  innerLabelFirst: boolean;
  innerRadioAsCheckbox = false;
  innerPartOfList = false;
  innerCanDeselectRadio = false;
  private onChange = (value: boolean | string) => {};
  private onTouched = () => {};
  private onValidateChange = () => {};

  constructor(
    private coreService: CoreService,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    elem: ElementRef) {
    if (elem.nativeElement.tagName.toLowerCase() === 'checkbox') {
      this.type = 'checkbox';
      this.value = true;
    } else {
      this.type = 'radio';
    }

    if (!this.name) {
      this.name = this.type;
    }
  }

  ngOnInit(): void {
    this.innerRequired = this.required === true || this.required === '';
    this.innerReadonly = this.readOnly === true || this.readOnly === '';
    this.innerDisabled = this.disabled === true || this.disabled === '';
    this.innerBold = this.bold === true || this.bold === '';
    this.innerPartOfList = this.partOfList === true || this.partOfList === '';
    this.innerRadioAsCheckbox = this.radioAsCheckbox === true || this.radioAsCheckbox === '';
    if (this.checked) {
      this.model = this.checked;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.required) {
      this.innerRequired = changes.required.currentValue === true || changes.required.currentValue === '';
    }

    if (changes && changes.readOnly) {
      this.innerReadonly = changes.readOnly.currentValue === true || changes.readOnly.currentValue === '';
    }

    if (changes && changes.disabled) {
      this.innerDisabled = changes.disabled.currentValue === true || changes.disabled.currentValue === '';
    }

    if (changes && changes.bold) {
      this.innerBold = changes.bold.currentValue === true || changes.bold.currentValue === '';
    }

    if (changes && changes.partOfList) {
      this.innerPartOfList = changes.partOfList.currentValue === true || changes.partOfList.currentValue === '';
    }

    if (changes && changes.labelFirst) {
      this.innerLabelFirst = changes.labelFirst.currentValue === true || changes.labelFirst.currentValue === '';
    }

    if (changes && changes.radioAsCheckbox) {
      this.innerRadioAsCheckbox = changes.radioAsCheckbox.currentValue === true || changes.radioAsCheckbox.currentValue === '';
    }

    if (changes && changes.canDeselectRadio) {
      this.innerCanDeselectRadio = changes.canDeselectRadio.currentValue === true || changes.canDeselectRadio.currentValue === '';
    }
  }

  ngAfterViewInit(): void {
    // Find all child nodes of the label node and filter by text nodes.
    // If the text node is present and has text, a label was provided *OR*
    // child nodes were provided for the label.  If nothing was provided,
    // hide all label HTML so we don't have extra empty nodes floating around.
    const children = Array.prototype.slice.call(this.labelEl.nativeElement.childNodes)
      .filter(n => n.nodeType !== Node.TEXT_NODE || (n.nodeType === Node.TEXT_NODE && n.textContent && n.textContent.trim()));

    setTimeout(() => this.labelVisible = children.length > 0, 0);

    if (this.controlContainer?.control) {
      const prevMarkAsTouched = this.controlContainer.control.markAsTouched;
      const that = this;
      this.controlContainer.control.markAsTouched = (...args: any) => {
        that.coreService.markFormControlsAsTouched(that.form);
        prevMarkAsTouched.bind(this.controlContainer.control)(...args);
        that.onValidateChange();
      };
    }
  }

  innerChange(): void {
    this.onChange(this.model);
  }

  // ControlValueAccessor - Write initial value into component.
  writeValue(value: any): void {
    if (value !== this.model) {
      this.model = value;
      this.onValidateChange();
    }
  }

  blurred(): void {
    this.onTouched();
    this.onValidateChange();
  }

  clicked(): void {
    if ((this.innerRadioAsCheckbox || this.type === 'radio') && this.model === this.value && this.innerCanDeselectRadio) {
      this.writeValue(null);
      this.onChange(this.model);
    }
  }

  // ControlValueAccessor - Parent form changes disabled state.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // ControlValueAccessor - Parent form registers on change listener (is dirty)
  registerOnChange(fn): void {
    this.onChange = fn;
  }

  // ControlValueAccessor - Parent form registers on touched listener (is touched)
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  // Validator - Parent form registers on validator change listener
  registerOnValidatorChange(fn): void {
    this.onValidateChange = fn;
  }

  // Validator - Parent form detects need to validate.
  validate(control: AbstractControl): ValidationErrors | null {
    let errors: ValidationErrors = null;

    // if (this.initialized) {
    //   this.coreService.markFormControlsAsTouched(this.form);
    // }

    for (const k in this.form?.controls) {
      if (this.form.controls[k]) {
        const c = this.form.controls[k];
        if (c.errors) {
          errors = errors ?? {};

          for (const ck in c.errors) {
            if (c.errors[ck]) {
              if (!errors[ck]) {
                errors[ck] = c.errors[ck];
              }
            }
          }
        }
      }
    }

    return errors;
  }
}
