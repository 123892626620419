export default class GenderIdentity {
  genderIdentityId: string;
  genderIdentityCode: string;
  genderIdentityName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(genderIdentity?) {
    if (genderIdentity) {
      this.genderIdentityId = genderIdentity.genderIdentityId;
      this.genderIdentityCode = genderIdentity.genderIdentityCode;
      this.genderIdentityName = genderIdentity.genderIdentityName;
      this.obsoleteFlag = genderIdentity.obsoleteFlag;
      this.obsoleteDate = genderIdentity.obsoleteDate ? new Date(genderIdentity.ObsoleteDate) : null;
    }
  }

}
