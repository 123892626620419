import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { OrganizationService } from '../services/organization.service';
import Organization from '../models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationResolver implements Resolve<Organization[]> {
  constructor(private organizationService: OrganizationService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organization[]> {
    return this.organizationService.getOrganizations().pipe(
      take(1),
      mergeMap(sub => {
        if (sub) {
          return of(sub);
        } else {
          this.router.navigate(['/']);
          return EMPTY;
        }
      })
    );
  }

}
