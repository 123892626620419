import Ethnicity from 'src/app/models/ethnicity';
import { OnChanges, SimpleChanges } from '@angular/core';
import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { find, filter, cloneDeep, remove, some, get, map } from 'lodash';
import * as dayjs from 'dayjs';
import Member from 'src/app/models/member';

/**
 * Used to display/edit race/ethnicity fields for selfpay forms and wizard
 */




@Component({
  selector: 'race-ethnicity',
  templateUrl: 'race-ethnicity.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class RaceEthnicityComponent implements OnInit {
  @Input() ethnicities: Ethnicity[];
  @Input() member: Member;
  @Input() selfPayForm = false;
  @Output() saveMemberEthnicities: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() updateMemberObject: EventEmitter<string[]> = new EventEmitter<string[]>();
  hispanicEthnicities: Ethnicity[]
  races: Ethnicity[];
  selectedLanguage: string;
  otherLanguageEnabled = false;
  selectedEthnicities: string[] = [];
  @Input() suppressSave = false;
  noAnswerEthId = '';
  noAnswerRaceId = '';
  constructor() {}

  ngOnInit() {
    this.noAnswerEthId = get(find(this.ethnicities, (e) => e.ethnicityCode == 'CNA' && e.uhcEthnicityCode != null), 'ethnicityId');
    this.noAnswerRaceId = get(find(this.ethnicities, (e) => e.ethnicityCode == 'CNAR' && e.uhcRaceCode != null), 'ethnicityId');
    this.hispanicEthnicities = filter(this.ethnicities, (e => e.uhcEthnicityCode != null && e.uhcEthnicityCode != '7'));
    this.races = filter(this.ethnicities, (e => e.uhcRaceCode != null && e.uhcRaceCode != '7'));
    this.selectedEthnicities = map(this.member?.memberEthnicities, 'ethnicityId');
  }

  updateEthnicities(ethnicityId: string, raceOrEth: string) {

    if(ethnicityId == this.noAnswerEthId) {
      remove(this.selectedEthnicities, (s: string) => some(this.hispanicEthnicities, (e: Ethnicity) => e.ethnicityId === s));
      this.selectedEthnicities.push(this.noAnswerEthId)
    }else if(ethnicityId == this.noAnswerRaceId) {
      remove(this.selectedEthnicities, (s: string) => some(this.races, (e: Ethnicity) => e.ethnicityId === s));
      this.selectedEthnicities.push(this.noAnswerRaceId)
    } else {
      if(!some(this.selectedEthnicities, (e) => e == ethnicityId)) {
        this.selectedEthnicities.push(ethnicityId);
        remove(this.selectedEthnicities, (e) => e == this.noAnswerEthId);
        if(some(this.selectedEthnicities, (s: string) => some(this.races, (e: Ethnicity) => e.ethnicityId === s))) {
          remove(this.selectedEthnicities, (e) => e == this.noAnswerRaceId);
        }else if(some(this.selectedEthnicities, (s: string) => some(this.hispanicEthnicities, (e: Ethnicity) => e.ethnicityId === s))) {
          remove(this.selectedEthnicities, (e) => e == this.noAnswerEthId)
        }
      }else {
        remove(this.selectedEthnicities, (e) => e == ethnicityId);
      }
    }
    (this.member.memberEthnicities as any) = this.selectedEthnicities.map(e => { return {ethnicityId: e}})
    this.updateMemberObject.emit(this.selectedEthnicities);
  }

  save() {
    this.saveMemberEthnicities.emit(this.selectedEthnicities);
  }
}

