<div fxLayoutAlign="center center" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body" fxLayout="column">
        <h2 style="margin-top: 8px; text-align: center;" class="mobile-text-centered">Health Equity Information</h2>
        <h3>Providing the following information is voluntary.</h3>
          <div style="width:75%;">
            <div style="flex-flow: column; padding: 5px 25px 25px 25px;" *ngFor="let member of memberEthnicityComposites">
              <h4><b>{{member.lastName}}/ {{member.firstName}}</b></h4>
              <div class="mgbt-medium">
                <p style="margin-left:20px;">Race (select all that apply, optional entry field)</p>
                <kendo-multiselect style="margin-left:20px;" [data]="nonHispanicEthnicities" [textField]="'ethnicityName'" [valueField]="'ethnicityId'" [valuePrimitive]="true" [(ngModel)]="member.nonHispanicEthnicities"></kendo-multiselect>
              </div>
              <div>
                <p style="margin-left:20px;">Hispanic Origin (select all that apply, optional entry field)</p>
              <kendo-multiselect style="margin-left:20px;" [data]="hispanicEthnicities" [textField]="'ethnicityName'" [valueField]="'ethnicityId'" [valuePrimitive]="true" [(ngModel)]="member.hispanicEthnicities"></kendo-multiselect>
              </div>
            </div>
          </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <button [disabled]="ethnicitySubmitted" type="button" class="btn btn-primary" (click)="saveAndContinue()">Proceed to dashboard</button>
        </div>
      </div>
    </div>
  </div>
</div>
