<div fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card">
      <div class="card-body page-container">
        <h1>Manage Perspay Access</h1>
        <div fxLayout="row" fxLayoutAlign="space-between end" class="mgbt-small">
          <div>
            <ul>
              <li>Manage perspay access for PEBB organizations
                <ul>
                  <li>Perspay administrative access: Ability to assign access within designated organizations.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <program-filter [(agencyFilter)]="agencyFilter"></program-filter>
        <div fxLayout="row" fxLayoutAlign="space-between end" class="mgbt-small">
          <hca-search #adminSearch class="mgbt-medium-sm" style="flex: 70;" placeholder="Search by: PEBB Org Name, First Name, Last Name, Email Address" 
          (searchSubmitted)="fetchPerspayUsersFromSearch()"></hca-search>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" class="mgbt-small">
          <div>
            <button *ngIf="isHCAAdmin" type="button" class="btn btn-primary" (click)="addPerspayUser()"><fa-icon [icon]="icons.faPlusCircle" size="1x" ></fa-icon> Add system user</button>
          </div>
        </div>
        <div class="card">
          <div class="card-body dashboard-grid-parent" style="padding:20px">
            <admin-access-grid 
              (disassociate)="disassociate($event)"  
              [isHCAAdmin]="isHCAAdmin" 
              #adminAccessGrid 
              [gridColumns]="gridColumns" 
              [gridData]="gridData"
              [lookups]="lookups" 
              (systemUserDataChange)="saveSystemUser($event)" 
              (accessDataChange)="saveRole($event)"
              (pushGetData)="updateDataset()">
            </admin-access-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>