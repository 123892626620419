import PhoneNumberType from './phoneNumberType';

export default class MemberPhoneNumber {
  memberPhoneNumberId: string;
  memberId: string;
  phoneNumberTypeId: string;
  phoneNumber: string;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  phoneNumberType: PhoneNumberType;

  constructor(memberPhoneNumber?) {
    if (memberPhoneNumber) {
      this.memberPhoneNumberId = memberPhoneNumber.memberPhoneNumberId;
      this.memberId = memberPhoneNumber.memberId;
      this.phoneNumber = memberPhoneNumber.phoneNumber || null;
      this.phoneNumberTypeId = memberPhoneNumber.phoneNumberTypeId;
      this.createdDate = memberPhoneNumber.createdDate;
      this.createdById = memberPhoneNumber.createdById;
      this.modifiedDate = memberPhoneNumber.modifiedDate;
      this.modifiedById = memberPhoneNumber.modifiedById;
      this.phoneNumberType = new PhoneNumberType(memberPhoneNumber.phoneNumberType);
      this.effectiveStartDate = memberPhoneNumber.effectiveStartDate ? new Date(memberPhoneNumber.effectiveStartDate) : null;
      this.effectiveEndDate = memberPhoneNumber.effectiveEndDate ? new Date(memberPhoneNumber.effectiveEndDate) : null;
    }
  }

}
