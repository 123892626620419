import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import SelfPayType from 'src/app/models/selfPayType';
import SelfPayForm from 'src/app/models/selfPayForm';
import { CoreService } from 'src/app/services/core.service';
import SelfPayPaymentType from 'src/app/models/selfPayPaymentType';
import SelfPay from 'src/app/models/selfPay';
import { SelfPayAddComponent } from '../add/selfPay.add.component';

@Component({
  selector: 'self-pay-reason',
  templateUrl: 'selfPay.reason.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayReasonComponent implements OnInit {
  @Output() saveSelfPayReason: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @Input() selfPay: SelfPay;
  requestType: SelfPayType;
  icons = {
    faCalendar
  };
  reasonItems;
  constructor(
    private coreService: CoreService
  ){}
  
  ngOnInit() {
    this.requestType = this.selfPay.selfPayType;
    if(this.selfPay.agency?.agencyCode === 'PEBB') {
      this.reasonItems = {
        'Applying for disability retirement': 'ADR',
        Layoff: 'L',
        Reversion: 'R',
        'Approved Leave Without Pay (LWOP)': 'ALWOP',
        'Workers\' compensation': 'WC',
        'Approved educational leave': 'AEL',
        'Faculty between periods of eligibility': 'FBPE',
        'Seasonal employee off-season': 'SEOS',
        'Employee appealing a dismissal action': 'EADA',
        'USERRA (military) leave': 'USERRA'
      }
    }else {
      this.reasonItems = {
        'Applying for disability retirement': 'ADR',
        Layoff: 'L',
        'Approved Leave Without Pay (LWOP)': 'ALWOP',
        'Workers\' compensation': 'WC',
        'Employee appealing a dismissal action': 'EADA',
        'USERRA (military) leave': 'USERRA'
      }
    }
  }

  pushContinue() {
    if (this.selfPay.formJson.selfPayReason) {
      this.saveSelfPayReason.emit(this.selfPay);
    } else {
      this.coreService.popMessage('Please select a reason.', 'error', 4000);
    }
  }

  pushCancel() {
    this.previous.emit();
  }


}
