export default class AddressType {
  addressTypeId: string;
  addressTypeCode: string;
  addressTypeName: string;
  addressTypeDescription: string;
  sortOrder: number;
  obsoleteDate: Date;
  obsoleteFlag: boolean;

  constructor(addressType?) {
    if (addressType) {
      this.addressTypeId = addressType.addressTypeId;
      this.addressTypeCode = addressType.addressTypeCode;
      this.addressTypeName = addressType.addressTypeName;
      this.addressTypeDescription = addressType.addressTypeDescription;
      this.sortOrder = addressType.sortOrder;
      this.obsoleteDate = addressType.obsoleteDate ? new Date(addressType.obsoleteDate) : null;
      this.obsoleteFlag = addressType.obsoleteFlag;
    }
  }
}
