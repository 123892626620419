import Member from './member';
import Carrier from './carrier';
import { env } from 'src/env/development';

export default class Carrier834Hold {
    carrier834HoldId: string;
    subscriberMemberId: string;
    memberId: string;
    transactionTypeCode: string;
    transactionType: string;
    sequenceId: number;
    tranId: string;
    asOfDate: Date;
    source: string;
    output: string;
    skipOutputGeneration: boolean;
    sentDate: Date;
    createdDate: Date;
    previouslySent: Date;
    subscriber: Member;
    member: Member;
    carrier: Carrier;
    resend: boolean = false;
    subscriberType: string;

    constructor(carrier834Hold?) {
      if (carrier834Hold) {
        this.carrier834HoldId = carrier834Hold.carrier834HoldId;
        this.subscriberMemberId = carrier834Hold.subscriberMemberId;
        this.memberId = carrier834Hold.memberId;
        this.transactionTypeCode = carrier834Hold.transactionTypeCode;
        this.transactionType = env.carrier834TransactionTypes.find(o=>o.Code===this.transactionTypeCode).Name;
        this.sequenceId = carrier834Hold.sequenceId;
        this.tranId = carrier834Hold.tranId;
        this.asOfDate = carrier834Hold.asOfDate ? new Date(carrier834Hold.asOfDate) : null;
        this.source = carrier834Hold.source;
        this.output = carrier834Hold.output;
        this.skipOutputGeneration = carrier834Hold.skipOutputGeneration;
        this.sentDate = carrier834Hold.sentDate ? new Date(carrier834Hold.sentDate) : null;
        this.createdDate = carrier834Hold.createdDate ? new Date(carrier834Hold.createdDate) : null;
        this.previouslySent = carrier834Hold.previouslySent ? new Date(carrier834Hold.previouslySent) : null;
        this.subscriber = new Member(carrier834Hold.subscriber);
        this.member = new Member(carrier834Hold.member);
        this.carrier = new Carrier(carrier834Hold.carrier);
        this.subscriberType = this.member.isSubscriberInd ? 'Subscriber' : 'Dependent';
      }
    }
  }
