export default class SubmissionFileResponseType {
  submissionFileResponseTypeId: string;
  submissionFileResponseTypeCode: string;
  submissionFileResponseTypeName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(submissionFileResponseType?) {
    if (submissionFileResponseType) {
      this.submissionFileResponseTypeId = submissionFileResponseType.submissionFileResponseTypeId || null;
      this.submissionFileResponseTypeCode = submissionFileResponseType.submissionFileResponseTypeCode || null;
      this.submissionFileResponseTypeName = submissionFileResponseType.submissionFileResponseTypeName || null;
      this.obsoleteFlag = submissionFileResponseType.obsoleteFlag;
      this.obsoleteDate = submissionFileResponseType.obsoleteDate
        ? new Date(submissionFileResponseType.obsoleteDate)
        : null;
    }
  }

}
