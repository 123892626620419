import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endsWith, uniq } from 'lodash';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import SubmissionFile from '../models/submissionFile';
import Document from '../models/document';
import SubmissionFileResponse from '../models/submissionFileResponse';
import UwUploadResponse from '../models/uwUploadResponse';
import UwUploadResponseEOM from '../models/uwUploadResponseEOM';
import HigherEdUploadResponse from '../models/higherEdUploadResponse';
import Gap9UploadResponse from '../models/gap9UploadResponse';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  getLatestSubmissionFileByAgency(agencyId: string, typeCode: string): Observable<SubmissionFile> {
    return this.http.get<SubmissionFile>(`${env.apiUrl}/organization/${agencyId}/eligibilityfile/${typeCode}`)
    .pipe(map(x => x && this.commonService.createObject(SubmissionFile, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createFile(agencyId: string, typeCode: string, file: SubmissionFile): Observable<SubmissionFile> {
    return this.http.post<SubmissionFile>(`${env.apiUrl}/organization/${agencyId}/eligibilityfile/${typeCode}`, file)
      .pipe(map(x => this.commonService.createObject(SubmissionFile, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createContentForFile(agencyId: string, typeCode: string, fileId: string, beginningIndex: number, rawRows: string[]): Observable<void> {
    return this.http.post(`${env.apiUrl}/organization/${agencyId}/eligibilityfile/${fileId}/${beginningIndex}`, rawRows)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadUwSubscriberSyncFile(uwLowerLimitDateOverride: Date, uwValidateOnly: boolean, rawRows: string[], dependentRawRows: string[]): Observable<UwUploadResponse> {
    const endpoint = 'UwSubscriberSyncFileImport';
    const rowData = { subscriberRawRows: rawRows, dependentRawRows: dependentRawRows};
    let extra = '';

    if (uwLowerLimitDateOverride) {
      extra = dayjs(uwLowerLimitDateOverride).format('/YYYYMMDD');
    }

    if (uwValidateOnly) {
      extra += "?validateOnly=true";
    }

    return this.http.post(`${env.apiUrl}/interface/${endpoint}${extra}`, rowData)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadUwSubscriberSyncFileEOM(rawRows: string[], dependentRawRows: string[]): Observable<UwUploadResponseEOM> {
    const endpoint = 'UwSubscriberSyncFileImportEOM';
    const rowData = { subscriberRawRows: rawRows, dependentRawRows: dependentRawRows};

    return this.http.post(`${env.apiUrl}/interface/${endpoint}`, rowData)
      .pipe(catchError(this.commonService.handleError));
  }

  generateUwSubSample(subscriberId: string): Observable<string> {
    const endpoint = `UwSubscriberSyncFileImport/Sample/Sub/${subscriberId}`;

    return this.http.get(`${env.apiUrl}/interface/${endpoint}`)
    .pipe(map(x => x[`text`]))
    .pipe(catchError(this.commonService.handleError));
  }

  generateUwDepSample(dependentId: string): Observable<string> {
    const endpoint = `UwSubscriberSyncFileImport/Sample/Dep/${dependentId}`;

    return this.http.get(`${env.apiUrl}/interface/${endpoint}`)
      .pipe(map(x => x[`text`]))
    .pipe(catchError(this.commonService.handleError));
  }

  getHigherEdFileSample(agency: string, includeGender: boolean): Observable<void> {
    const endpoint = `HigherEd/${agency}/${includeGender}`;

    return this.http.get(`${env.apiUrl}/interface/${endpoint}`, {responseType: 'blob'})
      .pipe(map((res: any) => {
        this.download(`HIGHER_ED_SAMPLE_${agency}.txt`, 'application/octet-stream', res);
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  uploadHigherEdFile(agency: string, rawRows: string[]): Observable<HigherEdUploadResponse> {
    const endpoint = `HigherEd/${agency}`;

    return this.http.post(`${env.apiUrl}/interface/${endpoint}`, rawRows)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadCmsImportFile(rawRows: any[]): Observable<void> {
    return this.http.post(`${env.apiUrl}/interface/CmsImport`, rawRows)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadWellnessFile(rawRows: any[]): Observable<void> {
    return this.http.post(`${env.apiUrl}/BatchJob/SetWellnessIndicators`, rawRows)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadNaviaFile(file: any): Observable<void> {
    const formData = new FormData();
    formData.append('file', file.rawFile);

    return this.http.post(`${env.apiUrl}/BatchJob/NaviaImport`, formData)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadWellness(file: any): Observable<void> {
    const formData = new FormData();
    formData.append('file', file.rawFile);

    return this.http.post(`${env.apiUrl}/BatchJob/NaviaImport`, formData)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadBillingFile(file: any): Observable<void> {
    const formData = new FormData();
    formData.append('file', file.rawFile);

    return this.http.post(`${env.apiUrl}/BatchJob/BillingFile`, formData)
      .pipe(catchError(this.commonService.handleError));
  }

  processPay1ResponseFile(document: Document): Observable<void> {
    const formData = new FormData();
    formData.append('file', document.documentBlob);

    return this.http.post(`${env.apiUrl}/Interface/ProcessPay1Response/${document.documentTypeId}`, formData)
      .pipe(catchError(this.commonService.handleError));
  }

  uploadGap9File(records: string[]): Observable<Gap9UploadResponse> {
    return this.http.post(`${env.apiUrl}/Interface/Gap9Complete`, records)
      .pipe(catchError(this.commonService.handleError));
  }

  batchJob(jobName: string): Observable<void> {
    return this.http.get(`${env.apiUrl}/BatchJob/${jobName}`)
      .pipe(catchError(this.commonService.handleError));
  }

  batchJobWithDate(jobName: string, date: Date): Observable<void> {
    const jobDate = date != null ? (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() : '';
    return this.http.get(`${env.apiUrl}/BatchJob/${jobName}?jobDate=${jobDate}`)
      .pipe(catchError(this.commonService.handleError));
  }

  wellnessExport(encryptWellness: boolean): Observable<void> {
    const qs = encryptWellness ? '' : '?encryptAndSign=false';

    return this.http.get(`${env.apiUrl}/Interface/WellnessExport${qs}`, {responseType: 'blob'})
      .pipe(map((res: any) => {
        this.download(`Smarthealth_elig_${dayjs().format('YYYYMMDD')}.csv`, 'application/octet-stream', res);
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  download(filename: string, type: string, content: any): void {
    // const blob = new Blob([res], {type: 'application/pdf'});
    const blob = new Blob([ content ], { type });
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  downloadFileMetadata(documentId: string): Observable<Document> {
    return this.http.get(`${env.apiUrl}/document/${documentId}/metadata`)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  downloadFile(documentId: string, filename: string): Observable<void> {
    return this.http.get(`${env.apiUrl}/document/${documentId}`, {responseType: 'blob'})
      .pipe(map((res: any) => {
        this.download(filename, 'application/octet-stream', res);
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  downloadMergedFile(documentIds: string[], filename: string): Observable<void> {
    if (!documentIds || !documentIds.length) {
      return of(null);
    }

    documentIds = uniq(documentIds.filter(id => id));

    if (!documentIds || !documentIds.length) {
      return of(null);
    }

    return this.http.post(`${env.apiUrl}/document/merge`, documentIds, {responseType: 'blob'})
      .pipe(map((res: any) => {
        this.download(filename, 'application/octet-stream', res);
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  outputFile(endpoint: string, name: string, extension: string, isBatchJob = true, date: Date = null, useAPIFileName: boolean = false): Observable<void>  {
    const controller = isBatchJob ? 'BatchJob' : 'Interface';
    const qryString = date != null ? '?jobDate=' + (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() : '';

    return this.http.get(`${env.apiUrl}/${controller}/${endpoint + qryString}`, { observe: 'response', responseType: 'blob' } )
      .pipe(map((res: any) => {
        if (useAPIFileName) {
          const contentDisposition = res.headers.get('content-disposition');
          name = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .trim();
        }
        this.download(`${name}.${extension}`, 'application/octet-stream', res.body);
      }))
      .pipe( 
        catchError(this.commonService.handleError));
  }

  generate834(carrier: string, audit834: boolean, save834Output: boolean, log834Generation: boolean): Observable<void> {
    return this.http.get(`${env.apiUrl}/interface/834/${(audit834 ? 'audit/' : '')}${carrier}/${save834Output}/${log834Generation}`, {responseType: 'blob'})
      .pipe(map((res: any) => {
        this.download(`${(audit834 ? 'audit_' : '') + carrier}_834.txt`, 'application/octet-stream', res);
      }))
      .pipe(catchError(this.commonService.handleError));

      // return this.http.get(`${env.apiUrl}/specialopenenrollment/document/${documentId}`, {responseType: 'blob'})
  }

  uploadExternalEligibilityRow(organizationId: string, record: any): Observable<void> {
    return this.http.post(`${env.apiUrl}/external/${organizationId}/subscriber`, record)
      .pipe(catchError(this.commonService.handleError));
  }

  generateError(): Observable<void> {
    return this.http.get(`${env.apiUrl}/applicationsetting/generateerror`)
    .pipe(catchError(this.commonService.handleError));
  }
}


