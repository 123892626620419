import Member from './member';
import TransactionSetType from './transactionSetType';

export default class Pay1SyncHoldOverview {
  pay1SyncHoldId: string;
  subscriberMemberId: string;
  transactionId: number;
  sequenceId: number;
  memberId: string;
  lastName: string;
  firstName: string;
  memberType: number;
  errorInfo: string[];
  transactionTypeCode: string;
  source: string[];
  sentDate: Date;
  errorDate: Date;
  createdDate: Date;

  constructor(source?) {
    if (source) {
      this.pay1SyncHoldId = source.pay1SyncHoldId;
      this.subscriberMemberId = source.subscriberMemberId;
      this.transactionId = source.transactionId;
      this.sequenceId = source.sequenceId;
      this.memberId = source.memberId;
      this.lastName = source.lastName;
      this.firstName = source.firstName;
      this.memberType = source.memberType;
      this.errorInfo = source.errorInfo;
      this.transactionTypeCode = source.transactionTypeCode;
      this.source = source.source;
      this.sentDate = new Date(source.sentDate);
      this.errorDate = new Date(source.errorDate);
      this.createdDate = new Date(source.createdDate);
    }
  }

  isBeyondAllowableLimit(): boolean {
    const matchingErrors = this.errorInfo.filter(i => i.indexOf('Record is generated beyond allowable limit.') >= 0);
    return matchingErrors.length > 0;
  }
}
