<ul fxLayoutAlign="center center" class="steps steps-5 fxGap-large">
  <li 
    [hidden]="selectedPeriod && selectedPeriod.enrollmentPeriodId !== period.enrollmentPeriodId"
    fxLayoutAlign="center center"
    (click)="updatePeriod(period, i)"
    [ngClass]="{
      'current': period.enrollmentPeriodId === selectedPeriod?.enrollmentPeriodId,
      'period-disabled': periodDisabled(isHCA, period, isPersWithEdit, newlyIncomplete, i)
    }"
    *ngFor="let period of enrollmentPeriods; let i = index"
    role="button" aria-pressed="false" tabindex="0" 
  >
    <a title="{{ period.enrollmentPeriodType.enrollmentPeriodTypeName }}">
      <em>{{ period.enrollmentPeriodType.enrollmentPeriodTypeName }}</em>
      <span>{{ period.effectiveStartDate | date: 'MM/dd/yyyy' }} - {{ period.effectiveEndDate | date: 'MM/dd/yyyy' }}</span>
      <br /><br />
      <span><b>for coverage {{ period.coverageEffectiveStartDate | date }}</b></span>
      <br />
      <span *ngIf="(newlyIncomplete && period.enrollmentPeriodType.enrollmentPeriodTypeName === 'Open Enrollment')" >* Must complete newly eligible</span>
    </a>
  </li>
</ul>
