import Member from './member';

export default class SimpleMember {
  memberId: string;
  specialOpenEnrollmentId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  birthDate: Date;
  deceasedDate: Date;
  socialSecurityNumber: string;
  emailAddress: string;
  preferredContactMethodId: string;
  nonEssentialCommunicationInd: boolean;
  emailNotificationInd: boolean;
  languageId: string;
  genderIdentityId: string;
  birthSexId: string;
  eligibleInd: boolean;
  isSubscriberInd: boolean;
  subscriberMemberId: string;
  memberTypeId: string;
  addressIsSameAsSubscriberInd: boolean;
  eligibilityDate: Date;
  terminationDate: Date;

  constructor(member?: Member) {
    if (member) {
      this.memberId = member.memberId;
      this.specialOpenEnrollmentId = member.specialOpenEnrollmentId;
      this.firstName = member.firstName;
      this.middleName = member.middleName;
      this.lastName = member.lastName;
      this.suffix = member.suffix;
      this.birthDate = member.birthDate;
      this.deceasedDate = member.deceasedDate;
      this.socialSecurityNumber = member.socialSecurityNumber;
      this.emailAddress = member.emailAddress;
      this.preferredContactMethodId = member.preferredContactMethodId;
      this.nonEssentialCommunicationInd = member.nonEssentialCommunicationInd;
      this.emailNotificationInd = member.emailNotificationInd;
      this.languageId = member.languageId;
      this.genderIdentityId = member.genderIdentityId;
      this.birthSexId = member.birthSexId;
      this.eligibleInd = member.eligibleInd;
      this.isSubscriberInd = member.isSubscriberInd;
      this.subscriberMemberId = member.subscriberMemberId;
      this.memberTypeId = member.memberTypeId;
      this.addressIsSameAsSubscriberInd = member.addressIsSameAsSubscriberInd;
      this.eligibilityDate = member.eligibilityDate;
      this.terminationDate = member.terminationDate;
    }
  }
}
