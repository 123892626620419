import { filter, some } from 'lodash';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import SelfPay from 'src/app/models/selfPay';
import Plan from 'src/app/models/plan';
import { env } from 'src/env/development';
import Agency from 'src/app/models/agency';
import * as dayjs from 'dayjs';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
@Component({
  selector: 'self-pay-elect-vision',
  templateUrl: 'selfPay.electVision.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayElectVisionComponent implements OnInit {
  @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @ViewChild('electVisionForm') public electVisionForm: NgForm;
  @Input() selfPay: SelfPay;
  @Input() availablePlans: Plan[];
  @Input() agency: Agency;
  visionPlans: Plan[];
  sebbVisionPlans: Plan[];
  env = env;
  isCobra: boolean = false;
  isPebbLwop = false;
  isPebbRetireeOE = false;
  isCobraOE = false;
  noVisionSelectionMade = false;
  isPEBBOE = false;
  ngOnInit() {
    this.isCobra = env.cobraSelfPayTypeCodes.includes(this.selfPay.selfPayType.selfPayTypeCode);
    this.isPebbLwop = this.selfPay?.formJson?.selfPayReason === 'ALWOP' || this.selfPay.selfPayType.selfPayTypeCode  === "OEU" || this.selfPay.selfPayType.selfPayTypeCode  === "SOEU" || this.selfPay.selfPayType.selfPayTypeCode  === "OEU";
    this.isPebbRetireeOE = ((this.selfPay?.selfPayType?.selfPayTypeCode === 'R' && dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(new Date('1/1/25'))) || this.selfPay?.selfPayType?.selfPayTypeCode === "OER");
    this.isCobraOE = this.selfPay?.selfPayType?.selfPayTypeCode === "OEC";
    this.isPEBBOE = this.isCobraOE || this.isPebbRetireeOE || this.isPebbLwop  
    this.noVisionSelectionMade = !this.selfPay?.formJson?.subscriber?.visionEnrollInd && !some(this.selfPay?.formJson?.dependents, (d: SelfPayFormDependent) => d.visionCoverInd);
    // filter out non pebb vision if LWOP
    if (this.isPEBBOE) {
      this.visionPlans = filter(this.availablePlans, (ap) => ap.planType?.planTypeCode === '6' && !ap.planName.includes('Default') && ap.planCode.includes('P')) ;
    } else {
      this.visionPlans = filter(this.availablePlans, (ap) => ap.planType?.planTypeCode === '6' && !ap.planName.includes('Default')) ;

    }
  }

  setPlan(plan) {
    this.selfPay?.formJson?.visionPlan == plan;
  }

  emitSelfPay(e) {
    this.saveSelfPay.emit(e);
  }

  
  continueCurrentSelected(checked) {
    if(checked) {
      this.selfPay.formJson.visionPlan = null;
    }
  }

}

