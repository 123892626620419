// ng
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

// ext
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadConfirmationComponent } from './components/confirmation/upload.confirmation.component';
import { UploadAssociationComponent } from './components/association/upload.association.component';

// local
import { UploadComponent } from 'src/app/modules/subscriber/modules/upload/upload.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    FlexLayoutModule,
    PanelBarModule,
    FontAwesomeModule,
    NgbModule,
    SharedModule
  ],
  declarations: [UploadComponent, UploadConfirmationComponent, UploadAssociationComponent],
  exports: [UploadComponent, UploadConfirmationComponent, UploadAssociationComponent],
  providers: [],
  bootstrap: []
})
export class PEBBUploadModule {}
