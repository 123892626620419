import { MessageService } from './../../../../services/message.service';
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach, filter, sortBy, maxBy, map, find, flatten, uniqBy, remove, get } from 'lodash';
import { RelationshipService } from '../../../../services/relationship.service';
import Relationship from '../../../../models/relationship';
import SystemUser from '../../../../models/user';
import { FileService } from 'src/app/services/file.service';
import SubmissionFile from 'src/app/models/submissionFile';
import OrganizationDocument from 'src/app/models/organizationDocument';
import Organization from 'src/app/models/organization';
import { AccessLevel, CoreService } from 'src/app/services/core.service';
import { SoeService } from 'src/app/services/soe.service';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { DocumentService } from 'src/app/services/document.service';
import DocumentType from 'src/app/models/documentType';
import Message from 'src/app/models/message';
import {
  faSignal,
  faHistory,
  faFileContract,
  faFileInvoice,
  faFileAlt,
  faAddressCard,
  faFileExport,
  faUsers,
  faUserShield,
  faBuilding,
  faDoorOpen,
  faExchangeAlt,
  faUserClock,
  faChartBar,
  faCheckCircle,
  faCog,
  faFileLines
} from '@fortawesome/free-solid-svg-icons';
import { State } from '@progress/kendo-data-query';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.DocumentType)
@Component({
  selector: 'perspay-dashboard',
  templateUrl: 'perspay.dashboard.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class PerspayDashboardComponent implements OnInit {
  // icons
  icons = {
    faAddressCard,
    faFileExport,
    faUsers,
    faUserShield,
    faBuilding,
    faDoorOpen,
    faExchangeAlt,
    faUserClock,
    faChartBar,
    faCheckCircle,
    faCog,
    faFileAlt,
    faFileInvoice,
    faFileContract,
    faSignal,
    faFileLines,
    faHistory
  };
  currentOrganization: Organization;
  systemUser: SystemUser;
  submissionFile: SubmissionFile;
  latestBillingFile: OrganizationDocument;
  latestSmartHealthFile: OrganizationDocument;
  organizations: { organization: string; pendingRelationships: Relationship[] }[] = [];
  totalRelationshipVerifcationsPending = 0;
  totalSpecialOpenEnrollmentRequests = 0;
  totalDocsPendingReview = 0;
  dataDepotLastUploadDate: Date;
  lastBillingFileDate: Date;
  isHCAAdmin = false;
  isOrgEdit = false;
  isOrgAdmin = false;
  dataDepotDocTypeId = '';
  lookups: {
    documentTypes: DocumentType[];
  };
  messages: Message[];
  nav: any = {};
  isSebb = false;
  organizationSelectedSubscription: any;
  billingFileDocTypeId: string="";

  constructor(
    private route: ActivatedRoute,
    private relationshipService: RelationshipService,
    private fileService: FileService,
    private coreService: CoreService,
    private soeService: SoeService,
    private router: Router,
    private documentService: DocumentService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.messageService.getMessages().pipe(untilDestroyed(this)).subscribe((messages: Message[]) => {
      this.messages = filter(messages, (message: Message) => message.isActive && message.moduleObject['Perspay Dashboard']);
    });

    this.route.data.pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.lookups = data.lookups;
        this.systemUser = data.user;
        this.dataDepotDocTypeId = filter(data.lookups.documentType, (documentType: DocumentType) => documentType.documentTypeCode === 'DD')[0].documentTypeId;
        this.isHCAAdmin = this.systemUser.userOrganizationRoles.map((r) => r.userRoleName).includes('HCA');
        this.billingFileDocTypeId = data.lookups.documentType.filter(o=>o.documentTypeCode=='BF')[0].documentTypeId;

        if ((!this.currentOrganization || !this.currentOrganization.organizationId) && !this.isHCAAdmin) {
          const org = this.coreService.getOrganizationValue();
          if (org.organizationId) {
            this.currentOrganization = org;
          } else {
            this.coreService.setOrganizationFromRole(this.systemUser.userOrganizationRoles[0]);
          }
        }
        this.setDashboardVars();

        if (this.isHCAAdmin && this.currentOrganization) {
          this.setNavLinks('hca');
        } else {
          this.setNavLinks('perspay');
        }

      },
      (err) => console.log(err)
    );

    this.organizationSelectedSubscription = this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s) => {
      if (s && (!this.currentOrganization || this.currentOrganization.organizationId !== s.organizationId)) {
        this.currentOrganization = s;
        if (this.isHCAAdmin) {
          this.setNavLinks('hca');
        }
        this.setDashboardVars();
      }
    });
  }

  setDashboardVars() {
    this.isOrgEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit);
    this.isOrgAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin);
    this.isSebb = this.currentOrganization?.agency?.agencyCode === 'SEBB';
    const currentOrDefaultOrganizationId = get(this.currentOrganization, 'organizationId', this.systemUser.userOrganizationRoles[0].organizationId);
    if (currentOrDefaultOrganizationId) {
      this.getPendingRelationships(currentOrDefaultOrganizationId);
      this.getPendingSpecialOpenEnrollments(currentOrDefaultOrganizationId);
      this.getDataDepotLastFile(currentOrDefaultOrganizationId);
      this.getBillingFile();
      // this.getPendingEnrollmentDocuments(this.currentOrganization || this.systemUser.userOrganizationRoles[0].organization);
      this.fetchFileDataForOrganization(currentOrDefaultOrganizationId);
    }
  }

  getPendingRelationships(organizationId: string): void {
    this.totalRelationshipVerifcationsPending = 0;
    const state: State = {
      skip: 0,
      take: 10,
      filter: {filters: [{filters: [{field: 'simplifiedStatus', operator: 'contains', value: 'Pending'}], logic: 'and'}], logic: 'and'},
    };
    const gridQuery = this.coreService.kendoGridStateToQueryString(state);
    this.relationshipService.getCurrentRelationshipsForAgency(organizationId, true, gridQuery).pipe(untilDestroyed(this)).subscribe((pendingRelationships) => {
      this.totalRelationshipVerifcationsPending = pendingRelationships.total;
    });
  }

  getPendingSpecialOpenEnrollments(organizationId: string): void {
    this.totalSpecialOpenEnrollmentRequests = 0;
    const state: State = {
      skip: 0,
      take: 10,
      filter: {filters: [{filters: [{field: 'specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName', operator: 'contains', value: 'Pending'}], logic: 'and'}], logic: 'and' },
    };
    const gridQuery = this.coreService.kendoGridStateToQueryString(state);
    this.soeService.getSpecialOpenEnrollmentsForOrganization(organizationId, true, gridQuery).pipe(untilDestroyed(this)).subscribe((pendingSoes) => {
      this.totalSpecialOpenEnrollmentRequests = pendingSoes.total;
    });
  }

  getPendingEnrollmentDocuments(organization: Organization): void {
    this.totalDocsPendingReview = 0;
    this.totalDocsPendingReview = filter(
      organization.organizationDocuments,
      (d) =>
        d.document.documentType.documentTypeCode !== 'SHM' &&
        d.document.documentType.documentTypeCode !== 'SHA' &&
        d.document.documentType.documentTypeCode !== 'BF' &&
        d.document.documentType.documentTypeCode !== 'FSAA' &&
        d.document.documentType.documentTypeCode !== 'FSAB' &&
        !d.document.isCompleteInd
    ).length;
  }

  getBillingFile() {
    if (this.isSebb) {
      this.documentService.getOrganizationDocuments(this.currentOrganization.organizationId,this.billingFileDocTypeId).pipe(untilDestroyed(this)).subscribe(docs=>{
        if (docs.length>0) {
          this.lastBillingFileDate = docs[0].dateReceived;
        }
      });
    }
  }

  getDataDepotLastFile(organizationId: string): void {
    this.documentService.getDataDepotLatestDocument(organizationId, this.dataDepotDocTypeId).pipe(untilDestroyed(this)).subscribe(doc => {
      if (doc) { this.dataDepotLastUploadDate = doc.createdDate; }
    });
  }

  fetchFileDataForOrganization(organizationId: string): void {
    // const billingFileType = find(this.lookups.documentTypes, ['documentTypeName', 'Billing File']);
    // const shFileType = find(this.lookups.documentTypes, ['documentTypeName', 'Smart Health Annual']);
    // this.latestBillingFile = maxBy(
    //   filter(
    //     flatten(
    //       map(
    //         filter(this.systemUser.userOrganizationRoles, (usar) => usar.organizationId === organizationId),
    //         'organization.organizationDocuments'
    //       )
    //     ),
    //     (sad) => sad && sad.document.documentTypeId === billingFileType.documentTypeId
    //   ),
    //   'fileDate'
    // );

    // this.latestSmartHealthFile = maxBy(
    //   filter(
    //     flatten(
    //       map(
    //         filter(this.systemUser.userOrganizationRoles, (usar) => usar.organizationId === organizationId),
    //         'organization.organizationDocuments'
    //       )
    //     ),
    //     (sad) => sad && sad.document.documentTypeId === shFileType.documentTypeId
    //   ),
    //   'fileDate'
    // );

    if (this.isSebb) {
      this.fileService.getLatestSubmissionFileByAgency(organizationId, 'EL').pipe(untilDestroyed(this)).subscribe(
        (response) => {
          if (response) {
            this.submissionFile = response;
          }
        }
      );
    }
  }

  setNavLinks(role: string): void {
    if (role === 'perspay') {
      this.nav = {
        subscriberManagement: `/admin/subscriberManagement/${this.systemUser.systemUserId}`,
        relationshipVerification: `/admin/relationshipVerification/perspay/${this.systemUser.systemUserId}`,
        access: `/admin/access/perspay/${this.systemUser.systemUserId}`,
        billing: `/admin/billing/${this.systemUser.systemUserId}`,
        smarthealth: `/admin/smarthealth/${this.systemUser.systemUserId}`,
        reports: `/admin/reports/${this.systemUser.systemUserId}`,
        organizationProfile: `/admin/organizationProfile/${this.systemUser.systemUserId}`,
        enrollmentDocuments: `/admin/enrollmentDocuments/${this.systemUser.systemUserId}`,
        specialOpenEnrollmentReview: `/admin/specialOpenEnrollment/review/perspay/${this.systemUser.systemUserId}`,
        fsaDcap: `/admin/fsadcap/${this.systemUser.systemUserId}`,
        dataDepot: `/admin/dataDepot/perspay/${this.systemUser.systemUserId}`,
        eligibility: `/admin/eligibilityFile/perspay/${this.systemUser.systemUserId}`,
        subscriberHistory: `/admin/subscriberHistory/${this.systemUser.systemUserId}`,
        billingFile: `/admin/billingFile/perspay/${this.systemUser.systemUserId}`,
      };
    } else if (role === 'hca') {
      this.nav = {
        subscriberManagement: `../../../admin/subscriberManagement/${this.currentOrganization.organizationId}`,
        relationshipVerification: `../../../admin/relationshipVerification/perspay/${this.currentOrganization.organizationId}`,
        access: `../../../admin/access/perspay/${this.currentOrganization.organizationId}`,
        billing: `../../../admin/billing/${this.currentOrganization.organizationId}`,
        smarthealth: `../../../admin/smarthealth/${this.currentOrganization.organizationId}`,
        reports: `../../../admin/reports/${this.currentOrganization.organizationId}`,
        organizationProfile: `../../../admin/organizationProfile/${this.currentOrganization.organizationId}`,
        enrollmentDocuments: `../../../admin/enrollmentDocuments/${this.currentOrganization.organizationId}`,
        specialOpenEnrollmentReview: `../../../admin/specialOpenEnrollment/review/perspay/${this.currentOrganization.organizationId}`,
        fsaDcap: `../../../admin/fsadcap/${this.currentOrganization.organizationId}`,
        dataDepot: `../../../admin/dataDepot/perspay/${this.currentOrganization.organizationId}`,
        eligibility: `../../../admin/eligibilityFile/perspay/${this.currentOrganization.organizationId}`,
        subscriberHistory: `../../../admin/subscriberHistory/${this.currentOrganization.organizationId}`,
        billingFile: `../../../admin/billingFile/perspay/${this.currentOrganization.organizationId}`,
      };
    }
  }
}
