// ng
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, HostListener, Output, Input, EventEmitter } from '@angular/core';

// ext
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash';

// local

@Component({
  selector: 'confirm-legal',
  templateUrl: 'confirm.legal.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmLegalComponent implements OnInit, OnDestroy {
  @Input() isSebb: boolean = false;
  @Input() isMedicalOnly: boolean = false;
  @Output() userHasReadLegal: EventEmitter<boolean> = new EventEmitter();
  @HostListener('scroll', ['$event.target']) onScroll(elem): void {
    const target = elem.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.userHasReadLegal.emit(true);
    }
  }
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
