import SOEFormDependent from 'src/app/models/soeFormDependent';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import DependentComposite from 'src/app/models/dependentComposite';

// ng
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

// ext
import { find, filter, some } from 'lodash';
import Subscriber from 'src/app/models/subscriber';
import Member from 'src/app/models/member';
import { env } from 'src/env/development';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import SelfPay from 'src/app/models/selfPay';
// local

@Component({
  selector: 'dep-initial',
  templateUrl: 'dep.initial.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentInitialComponent implements OnInit, OnDestroy {
  @Input() hasDependentsToAdd: boolean;
  @Output() dependentsQuestionAnswered: EventEmitter<boolean> = new EventEmitter();
  @Output() outputDependent: EventEmitter<SelfPayFormDependent> = new EventEmitter();
  @Input() dependents: DependentComposite[];
  @Input() isSOE = false;
  @Input() soe ?: SpecialOpenEnrollment;
  @Input() isSP = false;
  @Input() selfPay ?: SelfPay;
  @Input() subscriber: Subscriber;
  newDependents: SelfPayFormDependent[];
  env = env;
  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.selfPay?.formJson.dependents) {
      this.newDependents = filter(this.selfPay.formJson.dependents,
        (d: SelfPayFormDependent) => !d.memberId && !some(this.subscriber.members, (m: Member) => m.socialSecurityNumber === d.socialSecurityNumber));
    }
    if (this.soe && !this.soe?.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionEnrollDependentsCode)) {
      this.hasDependentsToAdd = false;
      this.dependentsQuestionAnswered.emit(this.hasDependentsToAdd);
    }
  }

  ngOnDestroy(): void {

  }

  importDependent(dep: SelfPayFormDependent): void {
    // emit both dependent and yes to question as if adding
    this.outputDependent.emit(dep);
    this.dependentsQuestionAnswered.emit(true);
  }
}
