import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pebb-spinner',
  templateUrl: './spinner.component.html',
})

export class SpinnerComponent {

  @Input() message = '';

  constructor() { }
}
