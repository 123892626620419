<!-- WIZARD/PROFILE -->

<div *ngIf="!selfPayForm" class="card-body">
  <h3>Medicare advantage plan enrollment supplemental demographic info</h3>
  <b>Providing this demographic information is optional and will not affect your enrollment in a Medicare Advantage Plan</b>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="95" fxLayout="column" class="card">
      <div class="card-body" style="max-width:100%">
        <div id="preferredLanguage">
          <b>Preferred language other than English</b>
          <checkbox name="Spanish" (change)="selectedLanguage = 'Spanish'" [checked]="selectedLanguage === 'Spanish'"> Spanish </checkbox>
          <checkbox name="Other" (change)="otherLanguageEnabled = true" [checked]="otherLanguageEnabled"> Other (please Indicate) </checkbox>
          <input [disabled]="!otherLanguageEnabled" required type="text" class="form-control" name="language" id="language" [(ngModel)]="member.languageText" />
          <checkbox name="Other" (change)="selectedLanguage = 'No preference'" [checked]="selectedLanguage == 'No preference'"> No selected preference </checkbox>
        </div>

        <div id="hispanicSection">
          <b>Are you of hispanic, latino/a, or Spanish origin?</b>
          <div>Select all that apply</div>
          <checkbox (change)="updateEthnicities(ethnicity.ethnicityId, 'ethnicity')" *ngFor="let ethnicity of hispanicEthnicities" [name]="ethnicity.ethnicityName" [checked]="selectedEthnicities.indexOf(ethnicity.ethnicityId) != -1"> {{ethnicity.ethnicityName}} </checkbox>
        </div>
        <div id="raceSection">
          <b>Which of the following best describes you?</b>
          <div>Select all that apply</div>
          <checkbox (change)="updateEthnicities(race.ethnicityId, 'race')" *ngFor="let race of races" [name]="race.ethnicityName" [checked]="selectedEthnicities.indexOf(race.ethnicityId) != -1"> {{race.ethnicityName}} </checkbox>
        </div>
        <div *ngIf="!suppressSave">
          <button class="btn btn-primary" (click)="save()">Save</button>
        </div>
      </div>
    </div>
</div>

<!-- SP FORM -->

<div *ngIf="selfPayForm" class="card-body">
  <h3>Medicare advantage plan enrollment supplemental demographic info</h3>
  <b>Providing this demographic information is optional and will not affect your enrollment in a Medicare Advantage Plan</b>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlex="45" fxLayout="column" class="card">
      <div class="card-body">
        <h3>Subscriber</h3>

        <div id="preferredLanguage">
          <b>Preferred language other than English</b>
          <checkbox name="Spanish" (change)="selectedLanguage = 'Spanish'" [checked]="selectedLanguage === 'Spanish'"> Spanish </checkbox>
          <checkbox name="Other" (change)="otherLanguageEnabledSub = true" [checked]="otherLanguageEnabledSub"> Other (please Indicate) </checkbox>
          <input [disabled]="!otherLanguageEnabledSub" required type="text" class="form-control" name="language" id="language" [(ngModel)]="selectedLanguage" />
          <checkbox name="Other" (change)="selectedLanguage = 'No selected preference'" [checked]="selectedLanguage !== 'No selected preference'"> No selected preference </checkbox>
        </div>

        <div id="hispanicSection">
          <b>Are you of hispanic, latino/a, or Spanish origin?</b>
          <span>Select all that apply</span>
          <checkbox *ngFor="let ethnicity of ethnicities" [name]="ethnicity.ethnicityName" [checked]="selectedEthnicities"> No selected preference </checkbox>
        </div>
      </div>
    </div>

    <div fxFlex="45" fxLayout="column" class="card">
      <div class="card-body">
        <h3>Spouse or SRDP</h3>
      </div>
    </div>
  </div>
</div>