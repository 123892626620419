import { MedicareComponent } from './../../../../../../../shared/components/medicare/medicare.component';
import { EndOfMonthDirective } from './../../../../../../../../directives/endOfMonth/endOfMonth.directive';
import { faCalendar, faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewChild, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChildren } from '@angular/core';
import { find, get, remove } from 'lodash';
import { ControlContainer, NgForm } from '@angular/forms';
import * as dayjs from 'dayjs';
import { Router, ActivatedRoute } from '@angular/router';
import { env } from 'src/env/development';
import { CoreService } from 'src/app/services/core.service';
import MemberAddress from 'src/app/models/memberAddress';
import SelfPayMember from 'src/app/models/selfPayMember';
import Organization from 'src/app/models/organization';
import MedicareOption from 'src/app/models/medicareOption';
import { SelfpayEnrollmentEligibilityComponent } from 'src/app/modules/adminHCA/components/selfpay/reviewGrid/components/enrollment/components/eligibility/selfpay.enrollment.eligibility.component';

@Component({
  selector: 'add-self-pay-subscriber',
  templateUrl: 'addSelfPaySubscriber.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class AddSelfPaySubscriberComponent implements OnInit {
  icons = {
    faCalendar,
    faHome,
    faEnvelope,
  };
  @Input() selfPaySubscriber: SelfPayMember;
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    phoneNumberTypes: [],
    organizations: [],
    medicareOptions: [],
    memberTypes: []
  };
  @Input() formSubmitted = false;
  @Output() saveSelfPaySubscriber: EventEmitter<SelfPayMember> = new EventEmitter();
  @Output() cancelAdd: EventEmitter<void> = new EventEmitter();
  pendingChanges = false;
  now: Date = new Date();
  minBirthDate: Date;
  mailingAddressIsSame = false;
  // because ssn is model bound - for ignoring on ssn collisions check.
  originalSSN: string;
  env = env;
  testVar: string;
  tenYearsDate: Date;
  formCSignatureMinDate: Date;
  @ViewChild('medicareComponent') public medicareComponent: MedicareComponent;
  @ViewChild('subEligibility') public subEligibility: SelfpayEnrollmentEligibilityComponent;
  
  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
    this.selfPaySubscriber.residentialAddress = new MemberAddress(this.selfPaySubscriber.residentialAddress);
    this.formCSignatureMinDate = this.selfPaySubscriber?.memberSelfPayOrigin?.agencyEffectiveStartDate ?
      dayjs(this.selfPaySubscriber.memberSelfPayOrigin.agencyEffectiveStartDate).subtract(90, 'day').toDate() :
      null;
    this.tenYearsDate = dayjs(this.now).subtract(10, 'year').toDate();
    if (!this.selfPaySubscriber.mailingAddress?.memberId) {
      this.updateMailingAddress(false);
    }

    this.minBirthDate = dayjs().subtract(110, 'year').toDate();
  }

  markAllControlsAsTouched(memberForm): void {
    this.coreService.markFormControlsAsTouched(memberForm);
  }

  submitSelfPaySubscriber(memberForm, formContainer): void {
    this.formSubmitted = true;

    //This is necessary for the SP eligibility date pickers that have conditional binding on their "required" attribute
    //without it the form controls for the date pickers return an "invalid" status even though they are valid because they are no longer required.
    //additionally the "settimeout" function below is required to set the appropriate focus on any actual invalid form controls, otherwise focus is set on the datepickers
    //an alternate solution is to remove the "form" tag from the datepicker component
    this.subEligibility.resetForFormValidation();

    // existing member
    let isValid = true;
    let messageToShow = 'Please enter all required fields and re-submit';
    this.markAllControlsAsTouched(memberForm);
    const nowMoment = dayjs(new Date());
    isValid = isValid ? memberForm.valid : false;

    setTimeout(() => {
      if (isValid) {
        // custom validation
        const medicareError = this.medicareComponent.medicareIsValid();
        
        if (this.selfPaySubscriber.birthDate > nowMoment.endOf('day').toDate() || this.selfPaySubscriber.birthDate < this.minBirthDate) {
          isValid = false;
          messageToShow = 'The birth date cannot be in the future or more than 110 years in the past.';
        }
        else if (medicareError !== '') {
          isValid = false;
          messageToShow = medicareError;
        }
      }

      if (isValid) {
        if (this.selfPaySubscriber.memberSelfPayOrigin.cobraQualifyReasonId === '')
        {
          this.selfPaySubscriber.memberSelfPayOrigin.cobraQualifyReasonId = null;
        }
        this.saveSelfPaySubscriber.emit(this.selfPaySubscriber);
      } else {
        this.coreService.popMessage(messageToShow, 'error', 4000, this.coreService.getInvalidFields(formContainer));
        this.formSubmitted = false;
      }
    }, 0);
  }

  updateMailingAddress(e: boolean): void {
    if (!e) {
      this.selfPaySubscriber.mailingAddress = new MemberAddress();
      this.selfPaySubscriber.mailingAddress.memberId = this.selfPaySubscriber.memberId;
    } else {
      delete this.selfPaySubscriber.mailingAddress;
    }
  }

//   get formCSignatureMinDate (): Date {
//     if(this._formCSignatureMinDate === undefined) {
//       this._formCSignatureMinDate = this.selfPaySubscriber?.memberSelfPayOrigin?.agencyEffectiveStartDate ?
//        dayjs(this.selfPaySubscriber.memberSelfPayOrigin.agencyEffectiveStartDate).subtract(90, 'day').toDate() : null;
//     }
//     return this._formCSignatureMinDate
//  }
}
