// ng
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import { UploadComponent } from 'src/app/modules/subscriber/modules/upload/upload.component';
import { VerificationAdminComponent } from './../modules/adminPerspay/components/verification/verification.admin.component';
import { OrganizationProfileComponent } from './../modules/adminPerspay/components/organizationProfile/organizationProfile.component';
import { AccessManagementComponent } from './../modules/adminPerspay/components/accessManagement/accessManagement.component';
import { ReportsComponent } from './../modules/reports/reports.component';
import { PerspayDashboardComponent } from './../modules/dashboard/components/perspay/perspay.dashboard.component';
import { ConfirmationComponent } from '../modules/subscriber/modules/confirmation/confirmation.component';
import { SupplementalComponent } from '../modules/subscriber/modules/supplemental/supplemental.component';
import { AttestTobaccoComponent } from '../modules/subscriber/modules/attestations/components/tobacco/tobacco.component';
import { CoverageComponent } from '../modules/subscriber/modules/coverage/coverage.component';
import { DependentsComponent } from '../modules/subscriber/modules/dependents/dependents.component';
import { DataDepotComponent } from '../modules/adminPerspay/components/dataDepot/dataDepot.component';
import { BillingFileComponent } from '../modules/adminPerspay/components/billingFile/billingFile.component';
import { EligibilityFileComponent } from '../modules/adminPerspay/components/eligibilityFile/eligibilityFile.component';
import { SubscriberHistoryComponent } from '../modules/adminHCA/components/subscriberHistory/subscriberHistory.component';
import { SoeReviewAdminComponent } from '../modules/adminPerspay/components/soeReview/soeReview.admin.component';
// guards
import { MasterGuard } from './../guards/master/master.guard';
// resolvers
import { UserResolver } from './../resolvers/user.resolver';
import { LookupsResolver } from './../resolvers/lookups.resolver';
import { EnrollmentPeriodResolver } from '../resolvers/enrollmentPeriod.resolver';
import { SubscriberResolver } from '../resolvers/subscriber.resolver';
import { TOUResolver } from '../resolvers/tou.resolver';

const routes: Routes = [
  {
    path: 'dashboard/perspay/:id',
    component: PerspayDashboardComponent,
    resolve: {
      user: UserResolver,
    },
    data: {
      guards: ['auth'],
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/reports/:id',
    component: ReportsComponent,
    canActivate: [MasterGuard],
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/access/perspay/:id',
    component: AccessManagementComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/dataDepot/perspay/:id',
    component: DataDepotComponent,
    data: {
      guards: ['auth'],
      userType: 'perspay',
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/billingFile/perspay/:id',
    component: BillingFileComponent,
    data: {
      guards: ['auth'],
      userType: 'perspay',
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/eligibilityFile/perspay/:id',
    component: EligibilityFileComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/subscriberHistory/:id',
    component: SubscriberHistoryComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/specialOpenEnrollment/review/perspay/:id',
    component: SoeReviewAdminComponent,
    data: {
      guards: ['auth'],
      userType: 'perspay',
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: ':id',
        children: [
          {
            path: 'dependents/:epid',
            component: DependentsComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'upload/:epid',
            component: UploadComponent,
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'coverage/:epid',
            component: CoverageComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'attest/:epid',
            component: AttestTobaccoComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'supplemental/:epid',
            component: SupplementalComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'confirmation/:epid',
            component: ConfirmationComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
              termsOfUse: TOUResolver,
            },
            canActivate: [MasterGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'admin/organizationProfile/:id',
    component: OrganizationProfileComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'admin/relationshipVerification/perspay/:id',
    component: VerificationAdminComponent,
    data: {
      guards: ['auth'],
      userType: 'perspay',
    },
    resolve: {
      user: UserResolver,
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
  },
];

export const PerspayGeneralRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(LookupsResolver.addToAllRoutes(routes), { onSameUrlNavigation: 'reload' });
