import { some, find, sortBy, orderBy } from 'lodash';
import SubscriberComposite from './subscriberComposite';
import Employment from './employment';
import Organization from './organization';
import Attestation from './attestation';
import Subscriber from './subscriber';
import Enrollment from './enrollment';
import MemberAddress from './memberAddress';
import * as dayjs from 'dayjs';

export default class SelfPayFormSubscriber extends SubscriberComposite {
    tobaccoUseFormInd: string; // yes, no, medicare
    actionsCode: string; // enroll, defer, enrollAfterDefer, separating
    enrollAfterDeferDateInitial: Date;
    seperatingDate: Date;
    medicarePartAInd: boolean;
    medicarePartADate: Date;
    medicarePartBInd: boolean;
    medicarePartBDate: Date;
    medicarePartDInd: boolean;
    medicarePartDDate: Date;
    medicaidMedicarePartDInd: boolean;
    medicaidMedicarePartDDate: Date;
    medicalEnrollInd: boolean;
    dentalEnrollInd: boolean;
    visionEnrollInd: boolean;    
    retireeTermLifeInd: boolean;
    deferCoverageInd: boolean;
    deferCoverageDate: Date;
    enrollAfterDeferalInd: boolean;
    enrollAfterDeferalDate: Date;
    deferQuestionOne: boolean;
    deferQuestionTwo: boolean;
    deferQuestionThree: boolean;
    deferQuestionFour: boolean;
    deferQuestionFive: boolean;
    deferQuestionSix: boolean;
    retireeTermLifeAcknowledgeInd: boolean;
    medicalContinueCoverageInd: boolean;
    medicalDoNotContinueCoverage: boolean;
    medicalTerminateCoverage: boolean;
    medicalTerminateCoverageDate: Date;
    medicalTerminateCoverageReason: string;
    dentalContinueCoverageInd: boolean;
    dentalDoNotContinueCoverage: boolean;
    dentalTerminateCoverage: boolean;
    dentalTerminateCoverageDate: Date;
    dentalTerminateCoverageReason: string;
    visionContinueCoverageInd: boolean;
    visionDoNotContinueCoverage: boolean;
    visionTerminateCoverage: boolean;
    visionTerminateCoverageDate: Date;
    visionTerminateCoverageReason: string;    
    coveredByOtherMedicalInd: boolean;
    coveredByOtherMedicalDate: Date;
    coveredByOtherVisionInd: boolean;
    coveredByOtherVisionDate: Date;    
    coveredByOtherDentalInd: boolean;
    coveredByOtherDentalDate: Date;
    coveredByOtherSSDInd: boolean;
    coveredByOtherSSDDate: Date;
    coveredByOtherPEBBAccountInd: boolean;
    lifeAndADDContinueCoverageInd: boolean;
    longTermDisabilityContinueCoverageInd: boolean;
    terminateLifeAndADDCoverageInd: boolean;
    lifeAndADDAcknowlegeInd: boolean;
    currentEnrollLTDInd: boolean;
    currentEnrollSupLTDInd: boolean;
    waitingPeriodCode: string; // 90, 180, 300, 120, 240, 360
    ltdDesiredEnrollmentCode: string // ki (keep and increase), dk (dont keep), k (keep as is)
    oeLtdCode: string // nc (no change), dbltd (disenroll basic), dsltd (disenroll supplemental)
    retirementDate: Date;
    coverageEffectiveDate: Date;
    medicareNumber: string;
    retirementPlan: string;
    memberEthnicities;
    terminateAllCoverageInd: boolean;
    formResidentialAddress: MemberAddress;
  constructor(subscriber?) {
    super(subscriber);
    if (subscriber) {
      this.residentialAddress = subscriber.residentialAddress;
      this.formResidentialAddress = subscriber.formResidentialAddress ? subscriber.formResidentialAddress : subscriber.residentialAddress;
      this.mailingAddress = subscriber.mailingAddress;
      this.tobaccoUseFormInd = subscriber.tobaccoUseFormInd || null;
      this.actionsCode = subscriber.actionsCode || null;
      this.enrollAfterDeferDateInitial = subscriber.enrollAfterDeferDateInitial ? new Date(subscriber.enrollAfterDeferDateInitial) : null;
      this.seperatingDate = subscriber.seperatingDate ? new Date(subscriber.seperatingDate) : null;
      this.medicarePartAInd = subscriber.medicarePartAInd;
      this.medicarePartADate = subscriber.medicarePartADate ? new Date(subscriber.medicarePartADate) : null;
      this.medicarePartBInd = subscriber.medicarePartBInd;
      this.medicarePartBDate = subscriber.medicarePartBDate ? new Date(subscriber.medicarePartBDate) : null;
      this.medicarePartDInd = subscriber.medicarePartDInd;
      this.medicarePartDDate = subscriber.medicarePartDDate ? new Date(subscriber.medicarePartDDate) : null;
      this.medicaidMedicarePartDInd = subscriber.medicaidMedicarePartDInd;
      this.medicaidMedicarePartDDate = subscriber.medicaidMedicarePartDDate ? new Date(subscriber.medicaidMedicarePartDDate) : null;
      this.medicalEnrollInd = subscriber.medicalEnrollInd != null ? subscriber.medicalEnrollInd : this.hasActiveCoverageForPlanType(subscriber, '1') || null;
      this.dentalEnrollInd = subscriber.dentalEnrollInd != null ? subscriber.dentalEnrollInd : this.hasActiveCoverageForPlanType(subscriber, '2') || null;
      this.visionEnrollInd = subscriber.visionEnrollInd != null ? subscriber.visionEnrollInd : this.hasActiveCoverageForPlanType(subscriber, '6') || null;
      this.retireeTermLifeInd = subscriber.retireeTermLifeInd || false;
      this.terminateAllCoverageInd = subscriber.terminateAllCoverageInd || false;
      this.deferCoverageInd = subscriber.deferCoverageInd !== null && this.deferCoverageInd !== undefined ? subscriber.deferCoverageInd : (!this.dentalEnrollInd && !this.medicalEnrollInd && !this.visionEnrollInd);
      this.deferCoverageDate = subscriber.deferCoverageDate ? new Date(subscriber.deferCoverageDate) : this.deferCoverageInd ? dayjs(this.getMostRecentMedical(subscriber)?.effectiveEndDate).add(1, 'day').toDate() : null;
      this.enrollAfterDeferalInd = subscriber.enrollAfterDeferalInd || false;
      this.enrollAfterDeferalDate = subscriber.enrollAfterDeferalDate ? new Date(subscriber.enrollAfterDeferalDate) : null;
      this.deferQuestionOne = subscriber.deferQuestionOne || false;
      this.deferQuestionTwo = subscriber.deferQuestionTwo || false;
      this.deferQuestionThree = subscriber.deferQuestionThree || false;
      this.deferQuestionFour = subscriber.deferQuestionFour || false;
      this.deferQuestionFive = subscriber.deferQuestionFive || false;
      this.deferQuestionSix = subscriber.deferQuestionSix || false;
      this.retireeTermLifeAcknowledgeInd = subscriber.retireeTermLifeAcknowledgeInd || null;
      this.medicalContinueCoverageInd = subscriber.medicalContinueCoverageInd || false;
      this.medicalDoNotContinueCoverage = subscriber.medicalDoNotContinueCoverage || false;
      this.medicalTerminateCoverage = subscriber.medicalTerminateCoverage || false;
      this.medicalTerminateCoverageDate = subscriber.medicalTerminateCoverageDate ? new Date(subscriber.medicalTerminateCoverageDate) : null;
      this.medicalTerminateCoverageReason = subscriber.medicalTerminateCoverageReason || null;
      this.dentalContinueCoverageInd = subscriber.dentalContinueCoverageInd || false;
      this.dentalDoNotContinueCoverage = subscriber.dentalDoNotContinueCoverage || false;
      this.dentalTerminateCoverage = subscriber.dentalTerminateCoverage || false;
      this.dentalTerminateCoverageDate = subscriber.dentalTerminateCoverageDate ? new Date(subscriber.dentalTerminateCoverageDate) : null;
      this.dentalTerminateCoverageReason = subscriber.dentalTerminateCoverageReason || null;
      this.visionContinueCoverageInd = subscriber.visionContinueCoverageInd || false;
      this.visionDoNotContinueCoverage = subscriber.visionDoNotContinueCoverage || false;
      this.visionTerminateCoverage = subscriber.visionTerminateCoverage || false;
      this.visionTerminateCoverageDate = subscriber.visionTerminateCoverageDate ? new Date(subscriber.visionTerminateCoverageDate) : null;
      this.visionTerminateCoverageReason = subscriber.visionTerminateCoverageReason || null;
      this.coveredByOtherMedicalInd = subscriber.coveredByOtherMedicalInd || false;
      this.coveredByOtherMedicalDate = subscriber.coveredByOtherMedicalDate ? new Date(subscriber.coveredByOtherMedicalDate) : null;
      this.coveredByOtherVisionInd = subscriber.coveredByOtherVisionInd || false;
      this.coveredByOtherVisionDate = subscriber.coveredByOtherVisionDate ? new Date(subscriber.coveredByOtherVisionDate) : null;      
      this.coveredByOtherDentalInd = subscriber.coveredByOtherDentalInd || false;
      this.coveredByOtherDentalDate = subscriber.coveredByOtherDentalDate ? new Date(subscriber.coveredByOtherDentalDate) : null;
      this.coveredByOtherSSDInd = subscriber.coveredByOtherSSDInd || false;
      this.coveredByOtherSSDDate = subscriber.coveredByOtherSSDDate ? new Date(subscriber.coveredByOtherSSDDate) : null;
      this.coveredByOtherPEBBAccountInd = subscriber.coveredByOtherPEBBAccountInd || false;
      this.lifeAndADDContinueCoverageInd = subscriber.lifeAndADDContinueCoverageInd || false;
      this.longTermDisabilityContinueCoverageInd = subscriber.longTermDisabilityContinueCoverageInd || false;
      this.terminateLifeAndADDCoverageInd = subscriber.terminateLifeAndADDCoverageInd || false;
      this.lifeAndADDAcknowlegeInd = subscriber.lifeAndADDAcknowlegeInd || false;
      this.currentEnrollLTDInd = subscriber.currentEnrollLTDInd || false; 
      this.currentEnrollSupLTDInd = subscriber.currentEnrollSupLTDInd || false;
      this.waitingPeriodCode = subscriber.waitingPeriodCode || null;
      this.ltdDesiredEnrollmentCode = subscriber.ltdDesiredEnrollmentCode || null;
      this.retirementDate = subscriber.retirementDate ? new Date(subscriber.retirementDate) : null;
      this.coverageEffectiveDate = subscriber.coverageEffectiveDate ? new Date(subscriber.coverageEffectiveDate) : null;
      this.medicareNumber = subscriber.medicareNumber ? subscriber.medicareNumber : subscriber?.memberMedicare?.medicareNumber || null;
      this.retirementPlan = subscriber.retirementPlan || null;
      this.memberEthnicities = subscriber.memberEthnicities;
    }
  }

  hasActiveCoverageForPlanType(s: Subscriber, planTypeCode: string) {
    return some(s.enrollments, (e: Enrollment) => e.isActive() && e.plan?.planType?.planTypeCode == planTypeCode);
  }

  getMostRecentMedical(s: Subscriber) {
    return find(orderBy(s?.enrollments, 'effectiveStartDate', 'desc'), (e:Enrollment) => e.plan?.planType?.planTypeCode === '1');
  }
}
