import { Component, Input } from '@angular/core';
import Subscriber from 'src/app/models/subscriber';

@Component({
  selector: 'soe-instructions',
  templateUrl: 'soe-instructions.component.html'
})
export class SOEInstructionsComponent  {
  @Input() subscriber: Subscriber;
  @Input() isSelfPay: boolean = false;

    constructor() {}
}

