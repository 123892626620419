import { Component, ViewEncapsulation, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { sortBy, filter, find, cloneDeep, every, some, remove, get, map, isEmpty } from 'lodash';
import { faCalendar, faHome, faUndo, faEnvelope, faPhone, faAt, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import { env } from 'src/env/development';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import SystemUser from 'src/app/models/user';
import Subscriber from 'src/app/models/subscriber';
import Reason from 'src/app/models/reason';
import Organization from 'src/app/models/organization';
import DualEnrollment from 'src/app/models/dualEnrollment';
import MemberAddress from 'src/app/models/memberAddress';
import SubscriberComposite from 'src/app/models/subscriberComposite';
import AddressType from 'src/app/models/addressType';
import { SubscriberService } from 'src/app/services/subscriber.service';
import TerminationComposite from 'src/app/models/terminationComposite';
import Enrollment from 'src/app/models/enrollment';
import { MemberService } from 'src/app/services/member.service';
import ExternalEnrollment from 'src/app/models/externalEnrollment';
import { NgForm } from '@angular/forms';
import SubscriberTransfer from 'src/app/models/subscriberTransfer';
import SubscriberTransferSearch from 'src/app/models/subscriberTransferSearch';
import { OrganizationService } from 'src/app/services/organization.service';
import SelfPayMember from 'src/app/models/selfPayMember';
import MemberMedicare from 'src/app/models/memberMedicare';
import SelfPayReason from 'src/app/models/selfPayReason';
import SelfPayTerminationComposite from 'src/app/models/selfPayTerminationComposite';
import { SelfPayService } from 'src/app/services/selfPay.service';
import MemberFlag from 'src/app/models/memberFlag';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import MemberType from 'src/app/models/memberType';
import { MedicareComponent } from 'src/app/modules/shared/components/medicare/medicare.component';
import BenefitSetup from 'src/app/models/benefitSetup';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LowerLimitService } from 'src/app/services/lowerLimit.service';

@UntilDestroy()
@Lookups(LookupType.Reason, LookupType.GenderIdentity, LookupType.RelationshipQualifyReason, LookupType.RelationshipVerificationStatus,
         LookupType.MemberType, LookupType.RelationshipType, LookupType.BirthSex, LookupType.County, LookupType.Country,
         LookupType.AddressType, LookupType.PhoneNumberType, LookupType.PreferredContactMethod, LookupType.Language,
         LookupType.SelfPayReason, LookupType.SelfPayPaymentType, LookupType.CobraQualifyReason, LookupType.MedicareOption)
@Component({
  selector: 'subscriber-management-employment',
  templateUrl: 'subscriberManagement.employment.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriberManagementEmploymentComponent implements OnInit, OnChanges, AfterViewChecked {
  systemUser: SystemUser;
  subscriber: Subscriber;
  subscriberComposite: SubscriberComposite;
  initialSubscriber: SubscriberComposite;
  terminationComposite: TerminationComposite;
  selfpayTerminationComposite: SelfPayTerminationComposite;

  icons = {
    faCalendar,
    faHome,
    faUndo,
    faEnvelope,
    faPhone,
    faAt,
    faMobileAlt,
    faPlus,
    faMinus
  };
  lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberTypes: [],
    phoneNumberTypes: [],
    preferredContactMethods: [],
    languages: [],
    organizations: [],
    terminationReasons: [],
    eligibilityReasons: [],
    enrollmentReasons: [],
    cobraQualifyReasons: [],
    selfPayPaymentTypes: [],
    reason: [],
    selfPayReason: [],
    selfPayTerminationReasons: [],
    selfPayEligibilityReasons: [],
    medicareOptions: []
  };
  currentOrganization: Organization;
  isHCAAdmin = false;
  isHCAEdit = false;
  isHrms = false;
  isOrgTypeToDisableEditFor = false;
  showEmailTermsAndConditions = false;
  canEdit = false;
  initialSubscriberWhole: Subscriber;
  reinstateTimedOut = false;
  isHCA = false;
  isDualEnrollOtherAgencyConflict = false;
  isDualEnrollmentCurrentAgencyDependentConflict = false;
  isDualEnrollmentCurrentAgencySubscriberConflict = false;
  initiallyDualEnrolled = false;
  // initiallyPortCommissioner = false;
  fullBenefits = false;
  env = env;
  wellnessFlag = false;
  wellnessPlanYear = '';
  ssnPattern = env.ssnItinRegex;
  initialWellnessFlag = false;
  initialWellnessFlagObject: MemberFlag;
  initialMemberMedicare: MemberMedicare;
  termReasonLocked = false;
  residentialCounties: string[] = [];
  mailingCounties: string[] = [];
  residentialStates: string[] = [];
  mailingStates: string[] = [];
  hasPreviousEnrollmentSignatureDate = false;
  isSebb = false;
  sebbMemberTypeId: string;
  sebbEmployee: MemberType;
  sebbLocallyEligible: MemberType;
  minSebbEligibilityDate: Date;
  maxSebbEligibilityDate: Date;
  dualEnrolledWac = '182-12-123';
  formSubmitted = false;
  termSubmitted = false;
  minDateLTDEligible: Date;
  maxDateLTDEligible: Date;
  lowerLimitDate: Date;
  formCSignatureMinDate: Date = null;
  isUW = false;
  transferReasonId: string;
  @ViewChild('memberForm') public memberForm: NgForm;
  @ViewChild('medicareComponent') public medicareComponent: MedicareComponent;
  @ViewChild('medicareForm') public medicareForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService,
    private router: Router,
    private subscriberService: SubscriberService,
    private spinnerService: SpinnerOverlayService,
    private memberService: MemberService,
    private organizationService: OrganizationService,
    private selfPayService: SelfPayService,
    private lowerLimitService: LowerLimitService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    const nowMoment = dayjs();
    this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
    this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.HCA);
    this.isHCAEdit = this.isHCAAdmin || this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
    const edit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay);
    const admin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.Perspay);
    this.canEdit = edit || admin || this.isHCAAdmin || this.isHCAEdit;
    this.reinstateTimedOut = dayjs().isAfter(dayjs(this.subscriber?.lossOfEligibilityDate).add(1, 'day').endOf('month'));

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
      this.subscriber = data.subscriber;
      this.subscriber.setNavigationProps();
      this.minDateLTDEligible = this.subscriber?.agencyEffectiveStartDate;
      const firstNextMonth = dayjs().add(1, 'month').startOf('month');
      this.maxDateLTDEligible = firstNextMonth.toDate();
      // Org type setup
      this.currentOrganization = this.subscriber.organization;
      if(this.currentOrganization?.organizationCode == '360') {
        this.isUW = true;
      }
      if (this.currentOrganization?.agency?.agencyCode) {
        this.lowerLimitService.getLowerLimit(null, false, this.currentOrganization.agency.agencyCode).pipe(untilDestroyed(this)).subscribe(ll=> {
          this.lowerLimitDate = ll;
        });
      }

      this.isOrgTypeToDisableEditFor = some(env.orgTypesToDisableEditFor, (o: string) => o  === this.currentOrganization.organizationTypeName && !this.currentOrganization.demographicUpdatesAllowed);
      this.isHrms = this.currentOrganization.hrmsInd;

      // load lookups
      this.lookups.county = sortBy(data.lookups.county, 'countyName');
      this.lookups.addressType = data.lookups.addressType;
      this.lookups.birthSexes = sortBy(data.lookups.birthSex, 'birthSexName');
      this.lookups.genderIdentities = sortBy(data.lookups.genderIdentity, 'genderIdentityName');
      this.lookups.preferredContactMethods = sortBy(data.lookups.preferredContactMethod, 'preferredContactMethodName');
      this.lookups.languages = sortBy(data.lookups.language, 'languageName');
      this.lookups.relationshipQualifyReasons = sortBy(data.lookups.relationshipQualifyReason, 'relationshipQualifyReasonName');
      this.lookups.memberTypes = sortBy(data.lookups.memberType, 'memberTypeName');
      this.lookups.medicareOptions = data.lookups.medicareOption;
      this.lookups.phoneNumberTypes = sortBy(
        filter(data.lookups.phoneNumberType, (p) => p.phoneNumberTypeName !== 'Cell'),
        'phoneNumberTypeName'
      );
      this.lookups.relationshipVerificationStatus = sortBy(data.lookups.relationshipVerificationStatus, 'relationshipVerificationStatusName');
      this.lookups.relationshipTypes = sortBy(data.lookups.relationshipType, 'relationshipTypeName');
      this.lookups.terminationReasons = sortBy(
        data.lookups.reason.filter((r: Reason) => {
          let isTermReason = r.terminationReasonInd && r.subscriberInd;
          if (env.termReasonsManagedByHca.indexOf(r.reasonCode) > -1) {
            // canEdit = Is HCA or Perspay; isHCAEdit = Is HCA Edit or Admin
            // HCA can always select these values; Perspay can see them, but cannot edit
            const isInitiallySet = !!this.subscriber.lossOfEligibilityReasonId;

            if (isInitiallySet) {
              // Set, lock for not HCA (but still show)
              this.termReasonLocked = !this.isHCAEdit;
            } else {
              // Not set, only HCA can set
              isTermReason = this.isHCAEdit;
            }
          }

          return isTermReason;
        }),
        'reasonName'
      );
      this.lookups.eligibilityReasons = sortBy(
        data.lookups.reason.filter((r: Reason) => r.eligibilityReasonInd),
        'reasonName'
      );
      this.lookups.reason = data.lookups.reason;
      this.lookups.selfPayTerminationReasons = sortBy(map(
        data.lookups.selfPayReason.filter((r: SelfPayReason) => r.terminationInd), 'reason'),
        'reasonName'
      );
      this.lookups.selfPayEligibilityReasons = sortBy(map(
        data.lookups.selfPayReason.filter((r: SelfPayReason) => r.eligibilityInd), 'reason' ),
        'reasonName'
      );
      this.lookups.selfPayReason = data.lookups.selfPayReason;
      this.lookups.memberTypes = sortBy(filter(data.lookups.memberType, 'managedByHCAInd'), 'memberTypeName');
      this.lookups.enrollmentReasons = sortBy(map(filter(data.lookups.selfPayReason, 'enrollmentInd'), 'reason'), 'reasonName');
      this.lookups.cobraQualifyReasons = sortBy(data.lookups.cobraQualifyReason, 'cobraQualifyReasonName');
      this.lookups.relationshipTypes = sortBy(data.lookups.relationshipType, 'relationshipTypeName');
      this.lookups.selfPayPaymentTypes = data.lookups.selfPayPaymentType;
      this.organizationService.getOrganizationsForAllAgencies().pipe(untilDestroyed(this)).subscribe((orgs: Organization[]) => {
        this.lookups.organizations = sortBy(orgs, 'organizationName');
        this.lookups = cloneDeep(this.lookups);
      });

      // SEBB checks
      if (this.currentOrganization.agency?.agencyCode === 'SEBB') {
        this.isSebb = true;
        this.dualEnrolledWac = '182-31-070';

        this.sebbMemberTypeId = this.subscriber.memberTypeId;

        this.sebbEmployee = find(data.lookups.memberType, mt => mt.memberTypeCode === 'Z');
        this.sebbLocallyEligible = find(data.lookups.memberType, mt => mt.memberTypeCode === 'W');

        this.minSebbEligibilityDate = data.subscriber.eligibilityDate;
        this.maxSebbEligibilityDate = dayjs().endOf('day').toDate();
      }

      this.setupSubscriber(data.subscriber);
      if (this.subscriber.dualEnrolledInOtherAgency === true) {
        this.initiallyDualEnrolled = true;
      }
      this.fullBenefits = this.subscriber.organization?.benefitSetup?.benefitSetupCode === 'FB';
      this.initialMemberMedicare = cloneDeep(this.subscriberComposite.memberMedicare);

      if (this.subscriberComposite?.memberSelfPayOrigin?.agencyEffectiveStartDate) {
        this.formCSignatureMinDate = dayjs(this.subscriberComposite.memberSelfPayOrigin.agencyEffectiveStartDate).subtract(90, 'day').toDate();
      }

      this.transferReasonId = this.lookups.terminationReasons.filter(r => r.reasonCode === '99')[0].reasonId;
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  setupSubscriber(subscriber: Subscriber): void {
    const previousSebbEligilityDateChanged = this.subscriberComposite?.sebbEligibilityDateChange;

    this.subscriberComposite = new SubscriberComposite(subscriber);
    this.subscriberComposite.sebbEligibilityDateChange = previousSebbEligilityDateChanged;

    if (!this.subscriberComposite.memberMedicare) {
      this.subscriberComposite.memberMedicare = new MemberMedicare();
    }
    this.terminationComposite = new TerminationComposite(subscriber);
    this.selfpayTerminationComposite = new SelfPayTerminationComposite(subscriber);
    this.subscriberComposite.residentialAddress.county = null;
    if (!this.subscriberComposite.mailingAddress || !this.subscriberComposite.mailingAddress.addressLineOne) {
      this.subscriberComposite.mailingAddress = new MemberAddress();
      this.subscriberComposite.mailingAddress.addressTypeId = find(this.lookups.addressType, (at: AddressType) => at.addressTypeCode = env.mailingAddressTypeCode).addressTypeId;
      this.subscriberComposite.mailingAddress.memberId = subscriber.memberId;
    }
    this.initialSubscriber = cloneDeep(this.subscriberComposite);
    this.hasPreviousEnrollmentSignatureDate = !!this.subscriberComposite.memberMedicare?.medicareEnrollmentSignatureDate;

    this.wellnessFlag = !!this.subscriberComposite.wellnessFlag &&
      (!this.subscriberComposite.wellnessFlag?.effectiveEndDate || dayjs(this.subscriberComposite.wellnessFlag.effectiveEndDate).isAfter(new Date()));
    this.initialWellnessFlag = cloneDeep(this.wellnessFlag);
    this.initialWellnessFlagObject = cloneDeep(this.subscriberComposite.wellnessFlag);
    if (this.wellnessFlag) {
      this.wellnessPlanYear = dayjs(this.subscriberComposite.wellnessFlag?.effectiveEndDate ?? this.subscriberComposite.wellnessFlag?.effectiveStartDate)
        .add(1, 'year')
        .format('YYYY');
    }
  }

  sebbLocallyEligibleChanged(memberTypeId: string): void {
    if (memberTypeId === this.sebbLocallyEligible.memberTypeId) {
      // Locally Eligible = YES, then LTD = NO
      this.subscriberComposite.isEligibleForLTD = false;
    } else {
      this.subscriberComposite.isEligibleForLTD = true;
    }

    if (memberTypeId === this.initialSubscriber.memberTypeId) {
      // If the Member Type ID is getting reset back to what it initially was,
      // then reset the Eligibility Date Change field.
      this.subscriberComposite.sebbEligibilityDateChange = null;
    }
  }

  emailSubscriptionChanged(e: Event): void {
    this.showEmailTermsAndConditions = this.subscriberComposite.emailNotificationInd;
  }

  submitTermsAndConditions(value: boolean): void {
    this.subscriberComposite.emailNotificationInd = value;
    this.showEmailTermsAndConditions = false;
  }

  async submitMemberForm(memberformContainer: any): Promise<void> {
    this.formSubmitted = true;
    await this.saveSubscriberChanges(memberformContainer);
    this.formSubmitted = false;
  }

  async saveSubscriberChanges(memberformContainer: any): Promise<void> {
    this.coreService.markFormControlsAsTouched(this.memberForm);
    this.coreService.markFormControlsAsTouched(this.medicareForm);
setTimeout(async () => {
    if (!this.isSebb && this.subscriberComposite.ltdEffectiveDate != null) {
        if (this.subscriberComposite.isEligibleForLTD && !dayjs(this.subscriberComposite.ltdEffectiveDate).isSame(dayjs(this.subscriberComposite.ltdEffectiveDate).startOf('month'), 'day')) {
          this.coreService.popMessage('LTD eligibility effective start date must be the first of the month.', 'error', 3000);
          return;
        }
        if (!this.subscriberComposite.isEligibleForLTD && 
            !dayjs(this.subscriberComposite.ltdEffectiveDate).isSame(dayjs(this.subscriberComposite.ltdEffectiveDate).endOf('month'), 'day') &&
            !dayjs(this.subscriberComposite.ltdEffectiveDate).isSame(dayjs(this.initialSubscriber.agencyEffectiveStartDate)) && 
            !dayjs(this.subscriberComposite.ltdEffectiveDate).isSame(dayjs(this.initialSubscriber.eligibilityDate))) {
          this.coreService.popMessage('LTD eligibility effective end date must be the last of the month.', 'error', 3000);
          return;
        }
        if (dayjs(this.subscriberComposite.ltdEffectiveDate).isBefore(dayjs(this.initialSubscriber.ltdEffectiveDate))) {
          this.coreService.popMessage('LTD eligibility effective ' + (this.subscriberComposite.isEligibleForLTD ? 'start' : 'end') + 
            ' date cannot be prior to its initial date.', 'error', 3000);
          return;
        }
    } 

    const medicareError = this.medicareComponent?.medicareIsValid();
    if (!this.memberForm.valid || (this.isHCA && !this.medicareForm.valid)) {
      this.coreService.popMessage('Fields missing or invalid', 'error', 3000, this.coreService.getInvalidFields(memberformContainer));
    } else if (this.medicareComponent && medicareError !== '') {
      this.coreService.popMessage(medicareError, 'error', 3000);
    } else if (this.initialSubscriber.terminationDate && this.initialSubscriber.terminationDate !== this.subscriberComposite.terminationDate &&
      this.initialSubscriber.terminationDate.getMonth() !== this.subscriberComposite.terminationDate.getMonth()) {
        this.coreService.popMessage('Termination date cannot be changed to a date outside of the month it was set.', 'error', 5000);
      } else if (this.initialSubscriber.terminationDate && this.initialSubscriber.terminationDate !== this.subscriberComposite.terminationDate &&
        this.initialSubscriber.terminationDate.getMonth() !== this.subscriberComposite.terminationDate.getMonth()) {
          this.coreService.popMessage('Termination date cannot be changed to a date outside of the month it was set.', 'error', 5000);
    } else {
      this.spinnerService.show();

      try {
        // wellness
        if (this.wellnessFlag !== this.initialWellnessFlag || (this.wellnessFlag && this.initialWellnessFlagObject && this.subscriberComposite.wellnessFlag.effectiveStartDate &&
          !dayjs(this.subscriberComposite.wellnessFlag.effectiveStartDate).isSame(dayjs(this.initialWellnessFlagObject.effectiveStartDate)))) {
          await lastValueFrom(this.memberService.setMemberFlagWithObject(this.subscriber.memberId, 'WELLNESS', this.wellnessFlag, this.subscriberComposite.wellnessFlag));
          this.wellnessPlanYear = dayjs(this.subscriberComposite.wellnessFlag?.effectiveEndDate ?? this.subscriberComposite.wellnessFlag?.effectiveStartDate)
            .add(1, 'year')
            .format('YYYY');
        }

        if (this.subscriberComposite.memberSelfPayOrigin) {
          const subscriberToSave: SelfPayMember = new SelfPayMember(this.subscriberComposite);
          subscriberToSave.mailingAddress = subscriberToSave.mailingAddress.addressLineOne ? subscriberToSave.mailingAddress : null;
          subscriberToSave.memberSelfPayOrigin = this.subscriberComposite.memberSelfPayOrigin;
          subscriberToSave.memberMedicare = this.subscriberComposite.memberMedicare;
          subscriberToSave.isEligibleForLTD = this.subscriberComposite.isEligibleForLTD;
          subscriberToSave.ltdEffectiveDate = this.subscriberComposite.ltdEffectiveDate;
          const updatedSubscriber = await lastValueFrom(this.subscriberService.updateSelfPaySubscriber(this.subscriberComposite.memberId, subscriberToSave));
        } else {
          const subscriberToSave: SubscriberComposite = cloneDeep(this.subscriberComposite);
          if (isEmpty(subscriberToSave.memberMedicare)) {
            subscriberToSave.memberMedicare = null;
          }
          subscriberToSave.mailingAddress = subscriberToSave.mailingAddress.addressLineOne ? subscriberToSave.mailingAddress : null;
          const updatedSubscriber = await lastValueFrom(this.subscriberService.updateSubscriber(subscriberToSave));
        }
        this.reFetchSubscriber();
        this.medicareComponent?.refreshInitial();

        this.spinnerService.hide();
        this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
      } catch (err) {
        this.spinnerService.hide();
        this.coreService.popMessage('Something went wrong updating this employee. Please try again', 'error', 5000);
        console.log(err);
      }
    }
  });
  }

  async reFetchSubscriber(): Promise<void> {
    const sub = await lastValueFrom(this.subscriberService.getSubscriberById(this.subscriber.memberId));
    this.setupSubscriber(sub);
    this.coreService.setSubscriber(sub);
    this.minSebbEligibilityDate = sub.eligibilityDate;
  }

  async termSubscriber(termRec: TerminationComposite): Promise<void> {
    this.spinnerService.show();
    try {
      const updatedSubscriber = await lastValueFrom(this.subscriberService.terminateSubscriber(termRec));
      this.setupSubscriber(updatedSubscriber);
      this.coreService.setSubscriber(updatedSubscriber);
      this.subscriber = cloneDeep(updatedSubscriber);
      this.terminationComposite = new TerminationComposite(this.subscriber);
      this.spinnerService.hide();
      this.coreService.popMessage('You have successfully updated this subscriber!', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong updating this employee. Please try again', 'error', 5000);
      console.log(err);
    }
  }

  async termSelfPaySubscriber(termRec: SelfPayTerminationComposite): Promise<void> {
    this.spinnerService.show();
    try {
      const updatedSubscriber = await lastValueFrom(this.selfPayService.terminateSelfPay(termRec));
      this.setupSubscriber(updatedSubscriber);
      this.coreService.setSubscriber(updatedSubscriber);
      this.subscriber = cloneDeep(updatedSubscriber);
      this.selfpayTerminationComposite = new SelfPayTerminationComposite(this.subscriber);
      this.spinnerService.hide();
      this.coreService.popMessage('You have successfully updated this subscriber!', 'success', 5000);
      this.termSubmitted = false;
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong updating this employee. Please try again', 'error', 5000);
      console.log(err);
      this.termSubmitted = false;
    }
  }

  undoChanges(): void {
    this.subscriberComposite = cloneDeep(this.initialSubscriber);
    this.subscriber = cloneDeep(this.initialSubscriberWhole);
  }

  async reinstateSubscriber(): Promise<void> {
    let sub: Subscriber;
    try {
      this.spinnerService.show();
      sub = await lastValueFrom(this.subscriberService.reinstateSubscriber(this.subscriber.memberId));
      if (every(sub?.enrollments, (e: Enrollment) => e.plan.planTypeDefaultPlanInd) ) {
        this.coreService.popMessage('Subscriber successfully reinstated into default plans. Subscriber will need to make new coverage elections', 'info', 5000);
      } else {
        this.coreService.popMessage('Subscriber successfully reinstated.', 'success', 5000);
      }
    } catch (err) {
      console.log(err);
      this.spinnerService.hide();
    }
    this.subscriber = cloneDeep(sub);
    this.setupSubscriber(sub);
    this.spinnerService.hide();
  }

  async checkSocialSecurityNumber(): Promise<void> {
    if (!this.isHCAEdit && this.subscriberComposite.socialSecurityNumber && this.subscriberComposite.socialSecurityNumber !== '') {
      this.spinnerService.show();
      const subscriberTransferSearch = new SubscriberTransferSearch();
      subscriberTransferSearch.socialSecurityNumber = this.subscriberComposite.socialSecurityNumber;
      subscriberTransferSearch.eligibilityDate = this.subscriberComposite.eligibilityDate;

      const promises = [];
      promises.push(lastValueFrom(this.memberService.getExternalEnrollments(this.subscriberComposite.socialSecurityNumber)));

      if (this.currentOrganization?.organizationId) {
        // Skip transfer search for Self Pay
        promises.push(lastValueFrom(this.memberService.transferMember(subscriberTransferSearch, this.currentOrganization.organizationId)));
      }

      let subscriberTransferRecs: SubscriberTransfer[] = null;
      let externalEnrollments = null;

      // Run our two API calls concurrently ... (instead of one after the other)
      await Promise.all(promises)
        .then(responses => {
          externalEnrollments = responses[0];
          subscriberTransferRecs = responses.length === 2 && responses[1].transferSearchResponses;
        });

      if (subscriberTransferRecs && subscriberTransferRecs.length > 0) {
        this.subscriberComposite.eligibilityTypeEffectiveDate = subscriberTransferRecs[0].dateUsedForCalculation;
      }

      this.subscriber.externalEnrollments = externalEnrollments;

      const dualEnrollment = new DualEnrollment(this.currentOrganization, externalEnrollments, this.subscriberComposite.eligibilityTypeEffectiveDate);

      this.isDualEnrollmentCurrentAgencySubscriberConflict = subscriberTransferRecs &&
        some(subscriberTransferRecs, (s: SubscriberTransfer) => s.memberId && s.memberId !== this.subscriber.memberId && s.isSubscriberInd);
      this.isDualEnrollOtherAgencyConflict = dualEnrollment.hasOtherAgencyAtAll;

      // check for PEBB dependents from external enrollment with the same plan type
      this.isDualEnrollmentCurrentAgencyDependentConflict = dualEnrollment.hasCurrentAgencyDependentDental || dualEnrollment.hasCurrentAgencyDependentMedical;

      if (subscriberTransferRecs && subscriberTransferRecs?.length) {
        // check for PEBB dependents from PMA enrollments with the same plan type
        const dependentSubscriberTransferEnrollments = subscriberTransferRecs
          .filter(s =>
            s.memberId &&
            s.memberId !== this.subscriber.memberId &&
            !s.isSubscriberInd &&
            s.subscriber &&
            s.subscriber.enrollments
          );

        if (dependentSubscriberTransferEnrollments) {
          dependentSubscriberTransferEnrollments.forEach(dste => {
            const dependentEnrollmentsWithSamePlanType = dste.subscriber.enrollments.filter(o => some(this.subscriber.enrollments, ee => ee.plan.planTypeId === o.plan.planTypeId));
            if (some(dependentEnrollmentsWithSamePlanType, o => !o.effectiveEndDate ||
              dayjs(this.subscriberComposite.eligibilityTypeEffectiveDate).isBetween(dayjs(o.effectiveStartDate).startOf('day'), dayjs(o.effectiveEndDate).endOf('day')))) {
                this.isDualEnrollmentCurrentAgencyDependentConflict = true;
            }
          });
        }
      }

      if (!this.isDualEnrollOtherAgencyConflict && !this.isDualEnrollmentCurrentAgencySubscriberConflict && !this.isDualEnrollmentCurrentAgencyDependentConflict) {
        this.removeError(this.memberForm.controls.socialSecurityNumber, 'dualEnrollment');
      } else {
        this.memberForm.controls.socialSecurityNumber.setErrors({dualEnrollment: true});
      }

      this.spinnerService.hide();
    }
  }

  // this function removes single error
  private removeError(control: AbstractControl, error: string): void {
    if (control) {
      const err = control.errors; // get control errors
      if (err) {
        delete err[error]; // delete your own error
        if (!Object.keys(err).length) { // if no errors left
          control.setErrors(null); // set control errors to null making it VALID
        } else {
          control.setErrors(err); // controls got other errors so set them back
        }
      }
    }
  }

  dualEnrollChecked(): void {
    // Is now false (was true), and was already set
    if (this.initiallyDualEnrolled === true && this.subscriber.dualEnrolledInOtherAgency === false) {
      this.dualEnrollSub();
    }
  }

  // portCommissionerChecked(): void {
  //   this.subscriber.isPortCommissioner = !this.subscriber.isPortCommissioner;
  //   this.setPortCommissioner();
  // }

  // async setPortCommissioner(): Promise<void> {
  //   await lastValueFrom(this.memberService.setMemberFlag(this.subscriber.memberId, 'ISPORTCOMMISSIONER', this.subscriber.isPortCommissioner));
  //   this.initiallyPortCommissioner = this.subscriber.isPortCommissioner;
  // }

  async dualEnrollSub(): Promise<void> {
    await lastValueFrom(this.memberService.setMemberFlag(this.subscriber.memberId, 'SEBBDUALOVERRIDE', this.subscriber.dualEnrolledInOtherAgency));
    this.initiallyDualEnrolled = this.subscriber.dualEnrolledInOtherAgency;
  }

  checkWellness(e): void {
    if (e && !this.subscriberComposite.wellnessFlag) {
      this.subscriberComposite.wellnessFlag = new MemberFlag();
      this.subscriberComposite.wellnessFlag.effectiveStartDate = new Date();
      this.wellnessPlanYear = dayjs(this.subscriberComposite.wellnessFlag?.effectiveStartDate).add(1, 'year').format('YYYY');
    }
  }

  async cancelTransfer(termRec: TerminationComposite): Promise<void> {
    try {
      this.spinnerService.show();
      const sub = await lastValueFrom(this.subscriberService.cancelTransfer(termRec));
      this.subscriber = cloneDeep(sub);
      this.setupSubscriber(sub);
    } catch {
      this.spinnerService.hide();
    }
    this.spinnerService.hide();
  }

  get medicarePartADateRequired(): boolean {
    if (this.subscriberComposite.memberMedicare?.medicarePartAEnrolledInd ||
      (this.initialMemberMedicare?.medicarePartAEnrolledInd &&
      !this.subscriberComposite.memberMedicare?.medicarePartAEnrolledInd)) {
      // removing medicare A
      return true;
    }
    return false;
  }

  get medicarePartBDateRequired(): boolean {
    if (this.subscriberComposite.memberMedicare?.medicarePartBEnrolledInd ||
      (this.initialMemberMedicare?.medicarePartBEnrolledInd &&
      !this.subscriberComposite.memberMedicare?.medicarePartBEnrolledInd)) {
      // removing medicare B
      return true;
    }
    return false;
  }

  clearDateIfRemovingA(e): void {
    if (this.initialMemberMedicare?.medicarePartAEnrolledInd && !e) {
        this.subscriberComposite.memberMedicare.medicarePartAEffectiveDate = null;
        if (this.subscriberComposite.memberMedicare.medicarePartBEnrolledInd) {
          this.subscriberComposite.memberMedicare.medicarePartBEnrolledInd = false;
          this.clearDateIfRemovingB(false);
        }
      }
  }

  clearDateIfRemovingB(e): void {
    if (this.initialMemberMedicare?.medicarePartBEnrolledInd && !e) {
        this.subscriberComposite.memberMedicare.medicarePartBEffectiveDate = null;
      }
  }

  async updateDeathDate(deceasedDate: Date): Promise<void> {
    this.spinnerService.show();
    try {
      await lastValueFrom(this.subscriberService.updateSubscriberDeathDate(this.subscriberComposite, deceasedDate));
      this.spinnerService.hide();
      this.reFetchSubscriber();
      this.coreService.popMessage('You have successfully updated this subscriber!', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong updating this subscriber. Please try again', 'error', 5000);
      console.log(err);
    }
  }

  public isDisabledStartEnd(date: Date): boolean {
    const startOfMonthDay = dayjs(date).startOf('month');
    const endOfMonthDay = dayjs(date).endOf('month');
    const day = dayjs(date);
    return !day.isSame(endOfMonthDay, 'day') && !day.isSame(startOfMonthDay, 'day');
  }

  representedStartDateChange(): void {
    this.subscriberComposite.representedDate = this.subscriberComposite.representedStartDate;
  }

  representedEndDateChange(): void {
    this.subscriberComposite.representedDate = this.subscriberComposite.representedEndDate;
  }

  isRepresentedIndChange(): void {
    if (this.subscriberComposite.isRepresentedInd) {
      if (!this.initialSubscriber.isRepresentedInd) {
        this.subscriberComposite.representedStartDate = null;
      }
    } else if (this.initialSubscriber.representedDate !== null) {
      this.subscriberComposite.representedStartDate = this.initialSubscriber.representedDate;
    }
  }

  salaryChange(e) {
    if(e > 99999.99) {
      Math.trunc(e)
      let eStr: string = '' + e;
      eStr = eStr.substring(0, eStr.length -2) + '.' + eStr.substring(eStr.length -2);
      this.subscriberComposite.salary = Number(eStr);
    }
  }
}
