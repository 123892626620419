export default class EnrollmentPeriodType {
    enrollmentPeriodTypeId: string;
    enrollmentPeriodTypeCode: string;
    enrollmentPeriodTypeName: string;
    obsoleteFlag: boolean;
    obsoleteDate: Date;

    constructor(enrollmentPeriodType?) {
      if (enrollmentPeriodType) {
        this.enrollmentPeriodTypeId = enrollmentPeriodType.enrollmentPeriodTypeId;
        this.enrollmentPeriodTypeCode = enrollmentPeriodType.enrollmentPeriodTypeCode;
        this.enrollmentPeriodTypeName = enrollmentPeriodType.enrollmentPeriodTypeName;
        this.obsoleteFlag = enrollmentPeriodType.obsoleteFlag;
        this.obsoleteDate = enrollmentPeriodType.obsoleteDate ? new Date(enrollmentPeriodType.obsoleteDate) : null;
      }
    }

  }
