import MemberType from 'src/app/models/memberType';
import Reason from 'src/app/models/reason';
import Organization from './organization';
export default class SelfPayOrigin {
    memberSelfPayOriginId: string;
    memberId: string;
    originatingOrganizationId: string;
    organizationId: string;
    memberTypeId: string;
    eligibilityReasonId: string;
    lossOfEligibilityReasonId: string;
    lossOfEligibilityReason: Reason;
    selfPayPaymentTypeId: string;
    cobraQualifyReasonId: string;
    cobraSelfPayEndDate: Date;
    electionPeriodEndDate: Date;
    originatingSsn: string;
    retirementDate: Date;
    agencyEffectiveStartDate: Date;
    agencyEffectiveEndDate: Date;
    memberType ?: MemberType;
    organization ?: Organization;
    constructor(selfPayOrigin?) {
        if (selfPayOrigin) {
          this.memberSelfPayOriginId = selfPayOrigin.memberSelfPayOriginId;
          this.memberId = selfPayOrigin.memberId;
          this.originatingOrganizationId = selfPayOrigin.originatingOrganizationId;
          this.organizationId = selfPayOrigin.organizationId;
          this.memberTypeId = selfPayOrigin.memberTypeId;
          this.memberType = selfPayOrigin.memberType ? new MemberType(selfPayOrigin.memberType) : null;
          this.eligibilityReasonId = selfPayOrigin.eligibilityReasonId;
          this.lossOfEligibilityReasonId = selfPayOrigin.lossOfEligibilityReasonId;
          this.lossOfEligibilityReason = selfPayOrigin.lossOfEligibilityReason? new Reason(selfPayOrigin.lossOfEligibilityReason) : null;
          this.selfPayPaymentTypeId = selfPayOrigin.selfPayPaymentTypeId;
          this.cobraQualifyReasonId = selfPayOrigin.cobraQualifyReasonId;
          this.cobraSelfPayEndDate = selfPayOrigin.cobraSelfPayEndDate ? new Date(selfPayOrigin.cobraSelfPayEndDate) : null;
          this.electionPeriodEndDate = selfPayOrigin.electionPeriodEndDate ? new Date(selfPayOrigin.electionPeriodEndDate) : null;
          this.originatingSsn = selfPayOrigin.originatingSsn;
          this.retirementDate = selfPayOrigin.retirementDate ? new Date(selfPayOrigin.retirementDate) : null;        
          this.agencyEffectiveStartDate = selfPayOrigin.agencyEffectiveStartDate ? new Date(selfPayOrigin.agencyEffectiveStartDate) : null;
          this.agencyEffectiveEndDate = selfPayOrigin.agencyEffectiveEndDate ? new Date(selfPayOrigin.agencyEffectiveEndDate) : null;
          this.organization = selfPayOrigin.memberType ? new Organization(selfPayOrigin.organization) : null;
        }
    }

}