import SystemUser from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
// ng
import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ext
import { filter, forEach, map, union } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import MemberEthnicityComposite from 'src/app/models/memberEthnicityComposite';
import Ethnicity from 'src/app/models/ethnicity';
import { MemberService } from 'src/app/services/member.service';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';

@UntilDestroy()
@Lookups(LookupType.Ethnicity)
@Component({
  selector: 'health-equity',
  templateUrl: 'healthEquity.component.html',
  styleUrls: [],
  providers: [],
})
export class HealthEquityComponent implements OnInit {
  memberEthnicityComposites: MemberEthnicityComposite[]= [];
  subscriber: Subscriber;
  systemUserId: string;
  nonHispanicEthnicities: Ethnicity[];
  hispanicEthnicities: Ethnicity;
  ethnicitySubmitted: boolean = false;
  constructor(
    private route: ActivatedRoute, 
    private memberService: MemberService,
    private spinnerService: SpinnerOverlayService, 
    private router: Router,
    private coreService: CoreService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const now = new Date();
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.subscriber = data.subscriber;
      this.memberEthnicityComposites = map(this.subscriber.members, (m) => new MemberEthnicityComposite(m));
      this.nonHispanicEthnicities = filter(data.lookups.ethnicity, ['hispanicInd', false]);
      this.nonHispanicEthnicities = filter(this.nonHispanicEthnicities, (e) => e.uhcRaceCode !== '7' && e.uhcRaceCode !== '8')
      this.hispanicEthnicities = filter(data.lookups.ethnicity, ['hispanicInd', true]);
    });
  }

  async saveAndContinue(): Promise<void> {
    this.ethnicitySubmitted = true;
    const ethnicityChanges = [];
    const allMembersWithAnswers = filter(this.memberEthnicityComposites, (me: MemberEthnicityComposite) => me.hispanicEthnicities.length > 0 || me.nonHispanicEthnicities.length > 0);
    forEach(allMembersWithAnswers, (m: MemberEthnicityComposite) => {
      ethnicityChanges.push(lastValueFrom(this.memberService.createMemberEthnicities(m.memberId, union(m.hispanicEthnicities, m.nonHispanicEthnicities))));
    });
    this.spinnerService.show();
    try {
      await Promise.all(ethnicityChanges);
      this.ethnicitySubmitted = false;
      this.coreService.popMessage('Changes saved successfully', 'success', 6000);

      //refresh user to get multiple orgs if applicable
      const thisUser: SystemUser = await lastValueFrom(this.userService.getUser().pipe(untilDestroyed(this)));
      this.coreService.setSystemUser(thisUser);

      this.router.navigate([`/subscriber/${this.subscriber.memberId}`]);
    } catch (err) {
      this.ethnicitySubmitted = false;
      console.log(err);
    }
    this.spinnerService.hide();
  }


}
