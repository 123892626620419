import { faCalendar, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CoreService } from 'src/app/services/core.service';
import { NgForm } from '@angular/forms';
import Message from 'src/app/models/message';
import Agency from 'src/app/models/agency';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ViewChild, ViewChildren, AfterViewInit, NgZone } from '@angular/core';
import { SelectableSettings, DataStateChangeEvent, PageChangeEvent, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor, orderBy, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { find, some, cloneDeep, get } from 'lodash';
import { forEach } from 'lodash';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import * as dayjs from 'dayjs';
import { take } from 'rxjs/operators';
import OpenEnrollment from 'src/app/models/openEnrollment';
import { CDK_CONNECTED_OVERLAY_SCROLL_STRATEGY_PROVIDER_FACTORY } from '@angular/cdk/overlay/overlay-directives';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'generic-grid',
  templateUrl: 'genericGrid.component.html',
  styleUrls: [],
  providers: [],
})
export class GenericGridComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(GridComponent) public kendoGrid: GridComponent;
  @Input() gridData;
  @Input() gridColumns;
  @Input() selectable?: boolean;
  @Input() selectBy?: string;
  @Input() selectedKeys?: string[] = [];
  @Input() responses?: string[];
  @Input() selectDisabled;
  @Input() showSelectionHeader = false;
  @Input() filterable = false;
  @Input() pageable = false;
  @Input() resizable = false;
  @Input() pageSize?;
  @Input() editable = false;
  @Input() exportFileName?: string;
  @Input() exportable = false;
  @Input() medicalWaived = false;
  @Input() disableAdd = false;
  @Input() minDate: Date;
  @Input() globalReadOnly = false;
  @Input() deleteColumn = false;
  @Input() checklist = [];
  @Input() defaultSortColumnIndex: number;
  @Input() disabledDatesFn: Function;
  @Input() rowClass: (context: RowClassArgs) => any = () => {};
  @Input() autoFitColumns = false;
  @Input() sort: SortDescriptor[] = [];
  @Input() agencies?: Agency[];
  clonedGridData;
  state: State = {
    skip: 0,
  };
  filter: FilterDescriptor;
  public selectableSettings: SelectableSettings;
  private editedRowIndex: number;
  private editedDataItem: any;
  isGenericMessages = false;
  icons = {
    faCalendar,
    faTrashAlt
  };
  @ViewChild('gridForm') gridForm: NgForm;
  @Output() selectedRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleObsolete: EventEmitter<any> = new EventEmitter<any>();
  @Output() handlePut: EventEmitter<any> = new EventEmitter<any>();
  @Output() handlePost: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeRecord: EventEmitter<any> = new EventEmitter<any>();
  @Output() deselectedRow: EventEmitter<any> = new EventEmitter<any>();

  constructor(public coreService: CoreService, private ngZone: NgZone) {}

  ngOnInit(): void {
    this.isGenericMessages = some(this.gridColumns, (g) => g.field === 'messageText');
    if (this.pageable) {
      this.loadItems();
    }
    if (this.selectable) {
      this.selectableSettings = {
        checkboxOnly: true,
        mode: 'single',
      };
    }
    if (!this.responses && some(this.gridColumns, ['type', 'select'])) {
      this.responses = ['Yes', 'No'];
    }
  }

  ngOnChanges(): void {
    if (this.gridData) {
      this.loadItems();
    }
  }

  public changeSelection(e): void {
    if (e.selectedRows.length) {
      this.selectedRow.emit(e.selectedRows[0].dataItem);
    } else {
      this.deselectedRow.emit(e.deselectedRows[0].dataItem);
      e.selectedRows = [];
      e.selectedRows.push(e.deselectedRows[0]);
    }
  }

  public changeSort(e: SortDescriptor[]): void {
    this.sort = e;
    this.loadItems();
  }

  public changeFilter(e: FilterDescriptor): void {
    this.filter = e;
    this.loadItems();
  }

  public changeSelect(e, fieldChanged): void {
    e.change = fieldChanged;
    this.selectedRow.emit(e);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    // this.clonedGridData = process(this.gridData, state);
    this.fitColumns();
  }

  private fitColumns() {
    if (this.autoFitColumns) {
      this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .pipe(untilDestroyed(this)).subscribe(() => {
        this.kendoGrid.autoFitColumns();
      });
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.loadItems();
  }

  private loadItems(): void {
    let data: any[] = null;
    if (Array.isArray(this.gridData)) {
      data = cloneDeep(this.gridData);

      // Loop through our column definition and see if there is a value function
      // on any of the columns.  If it is found, then run the funciton for each
      // row to translate the for display.
      for(let c = 0, col; col = this.gridColumns[c]; c++) {
        if (typeof col.value === 'function') {
          for(let r = 0, row; row = data[r]; r++) {
            const replacementKey = `_${col.field}`;
            const originalValue = row[col.field];

            const newValue = col.value(row, originalValue);
            row[replacementKey] = newValue;
          }
        }
      }
    }

    if (this.pageable) {
      if (this.sort.length === 0 && this.gridColumns.length > 0 && typeof this.defaultSortColumnIndex === 'number') {
        this.sort.push({dir: 'asc', field: this.gridColumns[this.defaultSortColumnIndex].field});
      }

      if (Array.isArray(data)) {
        this.clonedGridData = {
          data: orderBy(filterBy(data, this.filter), this.sort).slice(this.state.skip, this.state.skip + this.pageSize),
          total: data.length,
        };
      }
    } else {
      // Not pageable
      if (Array.isArray(data)) {
        this.clonedGridData = data;
      }
    }
  }

  public obsoleteHandler({ sender, rowIndex, dataItem }): void {
    sender.closeRow(rowIndex);
    dataItem.obsoleteDate = new Date();
    dataItem.obsoleteFlag = true;
    this.editedRowIndex = rowIndex;
    this.editedDataItem = Object.assign({}, dataItem);
    this.handlePut.emit(dataItem);
  }

  public editHandler({ sender, rowIndex, dataItem }): void {
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    this.editedDataItem = Object.assign({}, dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({ sender, rowIndex }): void {
    this.closeEditor(sender, rowIndex, true);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex, cancel?: boolean): void {
    grid.closeRow(rowIndex);
    if (cancel) {
      this.clonedGridData[rowIndex] = this.editedDataItem;
    }
    this.editedRowIndex = undefined;
    this.editedDataItem = undefined;
  }

  getResponse(responses: [], responseId: string): string {
    return find(responses, r => r.responseId === responseId).responseName;
  }

  checkType(e): string {
    return e instanceof Date ? 'date' : typeof e;
  }

  createNewBlankRecord(dataItem): void {
    forEach(dataItem, (v, k) => {
      dataItem[k] = null;
    });
    return dataItem;
  }

  public addHandler({ sender }): void {
    this.closeEditor(sender);
    if (this.isGenericMessages) {
      sender.addRow(new Message({}));
    } else {
      if (this.gridData.length) {
        var newItem = cloneDeep(this.gridData[0]);
        sender.addRow(this.createNewBlankRecord(newItem));
      } else {
        sender.addRow({});
      }
    }
  }

  public saveHandler({ sender, rowIndex, dataItem, isNew }, formContainer): void {
    this.coreService.markFormControlsAsTouched(this.gridForm);
    if (this.isGenericMessages && !some(dataItem.moduleObject, (m) => m === true)) {
      return this.coreService.popMessage('You must select at least one screen for this message to appear on', 'error', 5000);
    }
    if (this.isGenericMessages && dayjs(dataItem.activeDate).isAfter(dataItem.inactiveDate)) {
      return this.coreService.popMessage('End date Cannot exceed begin date', 'error', 5000);
    }
    if (this.gridForm.invalid || (this.isGenericMessages && (!some(dataItem.moduleObject, (m) => m === true) || !dataItem.activeDate))) {
      return this.coreService.popMessage('Fields missing or invalid', 'error', 5000, this.coreService.getInvalidFields(formContainer));
    }
    if (this.isGenericMessages && dataItem.inactiveDate<dataItem.activeDate && dataItem.inactiveDate!=null)
    {
        return this.coreService.popMessage('End date cannot be less than begin date.', 'error', 5000);
    }
    if (dataItem.coverageEffectiveStartDate && (
      dataItem.coverageEffectiveStartDate<dataItem.effectiveStartDate ||
      dataItem.effectiveEndDate<dataItem.effectiveStartDate ||
      dataItem.coverageEffectiveStartDate<dataItem.effectiveEndDate
    )) {
      return this.coreService.popMessage('Effective end date and coverage effective start date cannot be less than effective start date.  ' + 
        'Coverage effective start date cannot be less than effective end date.', 'error', 8000);
    }

    if (isNew) {
      if(dataItem.agencyId) {
        dataItem.agencyName = get(find(this.agencies, a => a.agencyId === dataItem.agencyId), 'agencyName');
      }
      this.clonedGridData.push(dataItem);
      // TODO - refactor to update grid only after success, add messaging.
      this.handlePost.emit(dataItem);
    } else {
      this.handlePut.emit(dataItem);
    }
    sender.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.editedDataItem = undefined;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      // @ts-ignore - this and the bind in template works just fine, ignored b/c ts yelling.
      data: process(this.gd, {}).data,
    };
    return result;
  }

  outputField(dataItem, col): any {
    const val = dataItem[`_${col.field}`] ?? dataItem[col.field];

    if (typeof col.format === 'function') {
      return col.format(val);
    }

    if (val && col.format?.date) {
      let formatString = col.format.date;

      if (formatString === 'mm/dd/yyyy') {
        formatString = 'MM/DD/YYYY';
      }

      if (col.format?.date === 'short') {
        formatString = 'MM/DD/YY';
      } else if (col.format?.date === 'mediumDate') {
        formatString = 'MM/DD/YY';
      }

      return dayjs(val).format(formatString);
    }

    return val;
  }

  public ngAfterViewInit(): void
  {
    this.fitColumns();
  }

}
