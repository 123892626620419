import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'ssn' })
export class FormatSSNPipe implements PipeTransform {
  transform(ssn: string): string {
    if (ssn && typeof ssn === 'string') {
      return ssn.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
    } else {
      return null;
    }
  }
}
