import { map } from 'lodash';
import Step from './step';
import * as dayjs from 'dayjs';
import Agency from './agency';

export default class OpenEnrollment {
  openEnrollmentId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  isActive: boolean;
  messageText: string;
  coverageEffectiveStartDate: Date;
  agency: Agency;
  agencyName: string;
  agencyId: string;
  constructor(openEnrollment?) {
    if (openEnrollment) {
      this.openEnrollmentId = openEnrollment.openEnrollmentId;
      this.effectiveStartDate = new Date(openEnrollment.effectiveStartDate);
      this.effectiveEndDate = dayjs(new Date(openEnrollment.effectiveEndDate)).endOf('day').toDate();
      this.isActive = this.effectiveStartDate.getTime() <= Date.now() && this.effectiveEndDate.getTime() >= Date.now();
      this.coverageEffectiveStartDate = new Date(openEnrollment.coverageEffectiveStartDate);
      this.agency = openEnrollment.agency ? new Agency(openEnrollment.agency) : null;
      this.agencyId = openEnrollment.agencyId;
      this.agencyName = this.agency?.agencyName;
      this.messageText = openEnrollment.messageText ||
        `Follow the steps below to begin your medical, dental, and vision plan selections for ${this.coverageEffectiveStartDate.getFullYear()}.
         Begin by selecting Step 1, when adding dependents.  Proceed to Step 3, Make attestations, if not adding dependents.`;
    }
  }
}
