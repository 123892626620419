<div class="soe-grid grid-container">
  <kendo-grid
    #soeGrid
    [selectable]="selectableSettings"
    [data]="gridData"
    [kendoGridSelectBy]="'specialOpenEnrollmentId'"
    class="k-grid-ignore-click"
    [filterable]="'menu'"
    [filter]="state.filter"
    [skip]="state.skip"
    [sortable]="{ allowUnsort: true, initialDirection: 'asc', mode: 'multiple' }"
    [resizable]="true"
    [pageable]="true"
    [pageSize]="10"
    (dataStateChange)="dataStateChange($event)"
    (edit)="editSOE.emit($event)"
    (selectionChange)="setSOESelected($event)"
  >
    <kendo-grid-checkbox-column width="45"  >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
        <input
          *ngIf="dataItem.specialOpenEnrollmentVerificationStatus?.specialOpenEnrollmentVerificationStatusName !== 'Cancelled'"
          class="k-checkbox k-checkbox-md k-rounded-md"
          [kendoGridSelectionCheckbox]="rowIndex" />
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column
      class="k-grid-ignore-click"
      [style.flex]="col.flex || 1"
      *ngFor="let col of gridColumns"
      field="{{ col.field }}"
      title="{{ col.title }}"
      [format]="col.format"
      [filter]="col.filter ? col.filter : null"
      [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
      [width]="col.width ? col.width : null"
    >
      <ng-template kendoGridFilterMenuTemplate
        let-column="col"
        let-filter="filter"
        let-filterService="filterService"
        *ngIf="col.field.endsWith('Date')"
        >
        <date-picker
          name="dateFilter"
          [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
          (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
          [label]="col.title">
        </date-picker>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Manage" width="175" [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div
          fxLayout="row"
          fxLayoutAlign="space-around"
          *ngIf="
            !isReadOnly &&
            selectedSOE?.specialOpenEnrollmentId === dataItem.specialOpenEnrollmentId &&
            !selectedSOE?.specialOpenEnrollmentVerificationStatus?.deniedInd &&
            !selectedSOE?.specialOpenEnrollmentVerificationStatus?.approvedInd && 
            (isHCAEdit || dataItem.specialOpenEnrollmentType.specialOpenEnrollmentTypeName!='HCA OE Correction')
          ">
          <button *ngIf="!subscriber?.lossOfEligibilityDate &&
          (!selectedSOE?.specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName ||
          selectedSOE?.specialOpenEnrollmentVerificationStatus?.specialOpenEnrollmentVerificationStatusName === 'Draft')" 
          kendoGridEditCommand type="button" class="btn btn-primary">Edit</button>
          <button *ngIf="!selectedSOE?.specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName || 
            selectedSOE?.specialOpenEnrollmentVerificationStatus?.specialOpenEnrollmentVerificationStatusName === 'Draft' ||
            selectedSOE?.specialOpenEnrollmentVerificationStatus?.specialOpenEnrollmentVerificationStatusName === 'Pending'" 
            (click)="cancelSOEItem(dataItem)" type="button" class="btn btn-secondary">Cancel</button>
        </div>
        <span *ngIf="
        !isReadOnly &&
        selectedSOE?.specialOpenEnrollmentId === dataItem.specialOpenEnrollmentId &&
        selectedSOE?.specialOpenEnrollmentVerificationStatus?.deniedInd &&
        selectedSOE?.specialOpenEnrollmentVerificationStatus?.approvedInd
      ">N/A</span>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-command-column title="Forms" width="160" [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div
          fxLayout="row"
          fxLayoutAlign="space-around"
          *ngIf="
            selectedSOE?.specialOpenEnrollmentId === dataItem.specialOpenEnrollmentId &&
            hasCompleteEP(dataItem)
          "
        >
          <button fxFlex="90" (click)="downloadCoveragePDF.emit(selectedSOE)" *ngIf="hasCompleteEP(selectedSOE)" type="button" class="btn btn-secondary">Elections PDF</button>
        </div>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
