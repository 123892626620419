<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid uw-sync-file">
    <div class="state">
        <div class="card">
            <div class="card-body">
                <h1 class="mobile-text-centered">HCA Admin Testing Screen</h1>

                <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">UW Sync File Upload</h2>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
                    style="border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                    <div fxFlex="100" class="form-group" fxLayout="row" style="margin-bottom: 0;">
                        <div fxLayoutAlign="left center" style="font-weight: bold; width: 100%;">
                            <date-picker name="uwLowerLimitDateOverride" [(ngModel)]="uwLowerLimitDateOverride">
                                <strong>Lower Limit Date Override</strong>
                            </date-picker>
                        </div>

                        <div fxLayoutAlign="left center" style="font-weight: bold; width: 100%;">
                            <checkbox name="uwValidateOnly" [(ngModel)]="uwValidateOnly">Only run validations</checkbox>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>Subscriber File</h3>
                        <div style="width:90%;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="uwSyncFileSelected(true, outputAdditionalUwErrorInfo, $event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>Dependent File</h3>
                        <div style="width:90%;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="uwSyncFileSelected(false, outputAdditionalUwErrorInfo, $event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="100">
                        <button class="btn btn-primary" (click)="uwSyncFileUpload()">Upload UW Sync</button>
                    </div>
                </div>


                <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">PAY1 Sync File Response
                    Upload</h2>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
                    style="border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                    <div class="form-group" fxFlex="50">
                        <h3>Success File</h3>
                        <div style="width:90%;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="[ 'CSV' ]" [multiple]="false"
                                [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="pay1ResponseFile(true, $event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>Error File</h3>
                        <div style="width:90%;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="[ 'CSV' ]" [multiple]="false"
                                [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="pay1ResponseFile(false, $event)"></generic-file-upload>
                        </div>
                    </div>
                </div>

                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Higher Ed Upload
                        </h2>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                            <div class="form-group" [class.disabled]="allCarriers">
                                <label for="higher_ed_type">Higher Ed Type</label>
                                <select class="form-control" name="higher_ed_type" id="higher_ed_type"
                                    [(ngModel)]="higherEdType">
                                    <option value=""></option>
                                    <optgroup label="Uses Additional Gender Column">
                                        <option value="CWU">Central Washington University (375)</option>
                                        <option value="tesc">The Evergreen State College (376)</option>
                                    </optgroup>
                                    <optgroup label="Does Not Use Additional Gender Column">
                                        <option value="CTC/Legacy">Community &amp; Technical Colleges - Legacy</option>
                                        <option value="wwuhr">Western Washington University (380)</option>
                                        <option value="agy900c13">King County Housing Authority (900 C13)</option>
                                        <option value="CTC">Community &amp; Technical Colleges</option>
                                    </optgroup>
                                </select>
                            </div>
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="higherEdFileSelected(higherEdType, $event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Billing File Upload
                        </h2>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="billingFile($event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Wellness Indicator
                            Upload</h2>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="wellnessFile($event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">External
                            Eligibility Upload</h2>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;"
                            fxLayout="column">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                (selectEventHandler)="externalEligibility($event)"></generic-file-upload>
                            <div class="form-group" fxFlex="100">
                                <label for="OrganizationId"><b>Select Organization</b></label>
                                <select required class="form-control" name="OrganizationId"
                                    [(ngModel)]="selectedOrganizationId">
                                    <option *ngFor="let org of lookups.organization" [ngValue]="org.organizationId">{{
                                        org.organizationName }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">GAP9 Import</h2>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="7321457280"
                                (selectEventHandler)="gap9($event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">CMS Import</h2>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="314572800"
                                (selectEventHandler)="cmsImportFileSelected($event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Navia Yearly Import
                        </h2>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                            <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedSpreadsheetFileTypes"
                                [multiple]="false" [autoUpload]="false" [maxfilesize]="36700160"
                                (selectEventHandler)="naviaImportFileSelected($event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Wellness
                            Eligibility Export</h2>
                        <div>
                            <checkbox name="encryptWellness" [(ngModel)]="encryptWellness">Encrypt and Sign?</checkbox>
                        </div>
                        <div style="width:90%;border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                            <button class="btn btn-primary" (click)="wellnessExport()">Launch</button>
                        </div>
                    </div>
                </div>
                <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Nightly Jobs</h2>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
                    <div class="form-group" fxFlex="20"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;"
                        *ngFor="let job of batchJobs | keyvalue">
                        <div style="font-weight:bold;margin-bottom:10px;">{{job.value}}</div>
                        <div style="text-align:center;">
                            <button class="btn btn-primary" (click)="initiateBatchJob(job.key)">Launch</button>
                        </div>
                    </div>
                    <div class="form-group" fxFlex="20"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;"
                        *ngFor="let job of batchJobsWithDate; let index = index">
                        <div style="font-weight:bold;margin-bottom:10px;">{{job.title}}</div>
                        <div>
                            <date-picker [name]="'jobStartDate' + index" [(ngModel)]="job.startDate"
                                (ngModelChange)="setJobOffsetDate(job.path)">
                                <strong>Job Run Date</strong>
                            </date-picker>
                        </div>
                        <div *ngIf="job?.daysOffset" class="mgbt-medium">
                            <span>Start Date - {{job.daysOffset}} days = {{ job?.offsetDate | date }}</span>
                        </div>
                        <div style="text-align:center;">
                            <button class="btn btn-primary"
                                (click)="initiateBatchJobWithDate(job.path, job.startDate)">Launch</button>
                        </div>
                    </div>
                </div>

                <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Generate Output Files</h2>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
                    <div class="form-group" fxFlex="20"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;"
                        *ngFor="let job of outputFiles | keyvalue">
                        <div style="font-weight:bold;margin-bottom:10px;">{{job.value.name}}</div>
                        <div *ngIf="job.value.useStartDate">
                            <date-picker [name]="'jobOutputFileStartDate' + index" [(ngModel)]="job.value.startDate">
                                <strong>Job Run Date</strong>
                            </date-picker>
                        </div>
                        <div style="text-align:center;">
                            <button class="btn btn-primary"
                                (click)="generateOutputFile(job.key, job.value.name, job.value.extension, job.value.isBatchJob, job.value.startDate, 
                                (!job.value.useAPIFileName ? false : job.value.useAPIFileName))">Generate</button>
                        </div>
                    </div>
                    <div class="form-group" fxFlex="45.5"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;">
                        <div style="font-weight:bold;margin-bottom:10px;">PAY1 Sync File</div>
                        <div>
                            <checkbox name="syncSub" [(ngModel)]="syncSubscriber">Run Subscriber File</checkbox>
                        </div>
                        <div>
                            <checkbox name="syncDep" [(ngModel)]="syncDependent"
                                (ngModelChange)="syncDependentChange(syncDependent)">Run Dependent File</checkbox>
                        </div>
                        <div>
                            <checkbox name="logSyncGeneration" [(ngModel)]="logSyncGeneration">Record file generation in
                                pma.external_file_generation</checkbox>
                            <div class="mgbt-medium" [class.disabled]="!logSyncGeneration">
                                <span>
                                    When checked, this resets the date we search for member changes from for the
                                    <i>next</i> sync file generation run.
                                    If you just want to check output but still want it to output on the next run, don't
                                    check this.
                                </span>
                            </div>
                        </div>
                        <div style="text-align:center;">
                            <button class="btn btn-primary" [disabled]="!(syncSubscriber || syncDependent)"
                                (click)="generateSyncFile(syncSubscriber, syncDependent, logSyncGeneration)">Generate</button>
                        </div>
                    </div>
                    <div class="form-group" fxFlex="45.5"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;">
                        <div style="font-weight:bold;margin-bottom:10px;">PAY1 Sync Error Checking</div>
                        <div>
                            <div fxFlexFill>
                                <div class="form-group required">
                                    <label for="syncErrorMonths">Sync Error Months</label>
                                    <input type="text" pattern="^\d{1,2}$" class="form-control"
                                        [(ngModel)]="syncErrorMonths" name="syncErrorMonths" />
                                </div>
                            </div>
                        </div>
                        <div style="text-align:center;">
                            <button class="btn btn-primary" (click)="setSyncErrorMonths(syncErrorMonths)">Save</button>
                        </div>
                    </div>
                    <div class="form-group" fxFlex="45.5"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;">
                        <div style="font-weight:bold;margin-bottom:10px;">Carrier 834 File</div>
                        <div>
                            <checkbox name="allCarriers" [(ngModel)]="allCarriers">All carriers</checkbox>
                        </div>
                        <div class="form-group" [class.disabled]="allCarriers">
                            <label for="carrier_834">Carrier</label>
                            <select class="form-control" name="carrier_834" id="carrier_834" [(ngModel)]="carrier"
                                [disabled]="allCarriers">
                                <option value=""></option>
                                <option *ngFor="let carrier of lookups.carriers" [value]="carrier.carrierCode">
                                    {{carrier.carrierCode}} - {{carrier.carrierName}}</option>
                            </select>
                        </div>
                        <div>
                            <checkbox name="audit834" [(ngModel)]="audit834">Run monthly audit file for this carrier
                            </checkbox>
                            <div class="mgbt-medium" *ngIf="!canAudit && audit834">
                                <span>
                                    Note that the application setting for CARRIER834AUDITIGNOREDATE is set to NO. When
                                    this is
                                    NO, the backend system will enforce that audits can only occur on the 16th of the
                                    month.
                                </span>
                            </div>
                        </div>
                        <div>
                            <checkbox name="save834Output" [(ngModel)]="save834Output"
                                (ngModelChange)="log834Generation = false">Save output to pma.export_834_transaction_set
                            </checkbox>
                        </div>
                        <div>
                            <checkbox name="log834Generation" [disabled]="!save834Output"
                                [(ngModel)]="log834Generation">Record file generation in pma.external_file_generation
                            </checkbox>
                            <div class="mgbt-medium" [class.disabled]="!log834Generation">
                                <span>
                                    When checked, this resets the date we search for member changes from for the
                                    <i>next</i> 834 file generation run.
                                    If you just want to check output but still want it to output on the next run, don't
                                    check this.
                                </span>
                            </div>
                        </div>
                        <div style="text-align:center;">
                            <button class="btn btn-primary" [disabled]="!carrier && !allCarriers"
                                (click)="generate834(allCarriers, carrier, save834Output, log834Generation, audit834)">Generate</button>
                        </div>
                    </div>
                </div>

                <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">General File Management
                </h2>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
                    style="border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                    <div class="form-group" fxFlex="50">
                        <h3>Upload File</h3>
                        <div style="width:90%;margin-bottom:10px;">
                            <div fxFlexFill>
                                <label for="documentType">Document type</label>
                                <select required class="form-control required" name="documentType"
                                    [(ngModel)]="document.documentTypeId">
                                    <option *ngFor="let docType of lookups.documentTypes"
                                        [(ngValue)]="docType.documentTypeId">{{ docType.documentTypeName }} ({{
                                        docType.documentTypeCode }})</option>
                                </select>
                            </div>
                        </div>
                        <div style="width:90%;">
                            <generic-file-upload *ngIf="isHca || true" [multiple]="true"
                                [allowedFileTypes]="[ 'txt', 'csv', 'pdf' ]" [autoUpload]="false"
                                [maxfilesize]="3145728000"
                                (selectEventHandler)="generalFileSelected($event)"></generic-file-upload>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>Download File</h3>
                        <div>
                            <div fxFlexFill>
                                <div class="form-group required">
                                    <label for="downloadDocumentId">Document ID</label>
                                    <input type="text" class="form-control" [(ngModel)]="downloadDocumentId"
                                        name="downloadDocumentId" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div fxFlexFill>
                                <div style="text-align:center;">
                                    <button class="btn btn-primary"
                                        (click)="downloadDocument(downloadDocumentId)">Download</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>Generate Higher Ed Sample File</h3>
                        <div>
                            <div fxFlexFill>
                                <div class="form-group required">
                                    <label for="higherEdSampleFileOrgCode">Organization Code to Pull Subscribers
                                        From</label>
                                    <input type="text" class="form-control" [(ngModel)]="higherEdSampleFileOrgCode"
                                        name="higherEdSampleFileOrgCode" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div fxFlexFill>
                                <div fxFlex="100" class="form-group" fxLayout="row" style="margin-bottom: 0;">
                                    <div fxLayoutAlign="center center" style="font-weight: bold; width: 100%;">
                                        <checkbox name="higherEdSampleIncludeGender"
                                            [(ngModel)]="higherEdSampleIncludeGender">Include Gender Column</checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div fxFlexFill>
                                <div style="text-align:center;">
                                    <button class="btn btn-primary"
                                        (click)="generateHigherEdSampleFile(higherEdSampleFileOrgCode, higherEdSampleIncludeGender)">Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">PGP</h2>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
                    style="border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                    <div class="form-group" fxFlex="50">
                        <h3>PGP Encrypt File</h3>
                        <div style="width:90%;">
                            <div class="form-group required">
                                <label>File to Encrypt</label>
                                <generic-file-upload [multiple]="false" [autoUpload]="false" [maxfilesize]="314572800"
                                    (selectEventHandler)="setBlob('fileToEncrypt', $event)"></generic-file-upload>
                            </div>
                        </div>
                        <div fxLayoutAlign="left center" style="font-weight: bold; width: 100%;">
                            <checkbox name="pgpSign" [(ngModel)]="pgpSign">Sign encrypted file?</checkbox>
                        </div>
                        <div>
                            <div fxFlexFill>
                                <div style="text-align:center;">
                                    <button class="btn btn-primary" (click)="encrypt()">Encrypt</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>PGP Decrypt File</h3>
                        <div style="width:90%;">
                            <div class="form-group required">
                                <label>File to Decrypt</label>
                                <generic-file-upload [multiple]="false" [autoUpload]="false" [maxfilesize]="314572800"
                                    (selectEventHandler)="setBlob('fileToEncrypt', $event)"></generic-file-upload>
                            </div>
                        </div>
                        <div>
                            <div fxFlexFill>
                                <div style="text-align:center;">
                                    <button class="btn btn-primary" (click)="decrypt()">Decrypt</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>PGP Generate Keys</h3>
                        <div>
                            <div fxFlexFill>
                                <div style="text-align:center;">
                                    <button class="btn btn-primary" (click)="generateKeys()">Generate</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="pgpKeys">
                            <div>
                                <b>Base64 Encoded Public Key</b>
                                <br />
                                {{pgpKeys.publicKey}}
                                <button (click)="saveToDisk(pgpKeys.publicKey, 'public.asc')">Save</button>
                            </div>
                            <hr />
                            <div>
                                <b>Base64 Encoded Private Key</b>
                                <br />
                                {{pgpKeys.privateKey}}
                                <button (click)="saveToDisk(pgpKeys.privateKey, 'private.asc')">Save</button>
                            </div>
                            <br />
                            <div>
                                <b>Private Key Password</b>
                                <br />
                                {{pgpKeys.password}}
                            </div>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>Error Logs</h3>
                        <div>
                            <div fxFlexFill>
                                <button class="btn btn-primary" (click)="generateError()">Generate Error</button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>UW Sync Sample Subscriber Files</h3>
                        <div>
                            <div fxFlexFill>
                                <div class="form-group required">
                                    <label for="UwSampleSubscriberId">Subscriber GUID</label>
                                    <input type="text" class="form-control" [(ngModel)]="uwSampleSubscriberId"
                                        name="UwSampleSubscriberId" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div fxFlexFill>
                                <button class="btn btn-primary"
                                    (click)="generateUwSubSample(uwSampleSubscriberId)">Generate Sample File</button>
                            </div>
                        </div>
                        <div>{{usSampleSubscriberRecord}}</div>
                    </div>

                    <div class="form-group" fxFlex="50">
                        <h3>UW Sync Sample Dependent Files</h3>
                        <div>
                            <div fxFlexFill>
                                <div class="form-group required">
                                    <label for="UwSampleDependentId">Dependent GUID</label>
                                    <input type="text" class="form-control" [(ngModel)]="uwSampleDependentId"
                                        name="UwSampleDependentId" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div fxFlexFill>
                                <button class="btn btn-primary"
                                    (click)="generateUwDepSample(uwSampleDependentId)">Generate Sample File</button>
                            </div>
                        </div>
                        <div>{{usSampleDependentRecord}}</div>
                    </div>
                </div>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
                    style="border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                    <div fxFlex="100" class="form-group" fxLayout="row" style="margin-bottom: 0;">
                        <div fxLayoutAlign="left center" style="font-weight: bold; width: 100%;">
                            <date-picker name="uwLowerLimitDateOverride" [(ngModel)]="uwLowerLimitDateOverride">
                                <strong>Lower Limit Date Override</strong>
                            </date-picker>
                        </div>

                        <div fxLayoutAlign="left center" style="font-weight: bold; width: 100%;">
                            <checkbox name="uwValidateOnly" [(ngModel)]="uwValidateOnly">Only run validations</checkbox>
                        </div>
                    </div>
                    <div fxLayout="column">

                        <h3>UW EOM FILE TESTING</h3>
                        <div>
                            <div class="form-group" fxFlex="50">
                                <h3>Subscriber File</h3>
                                <div style="width:90%;">
                                    <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                        [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                        (selectEventHandler)="uwSyncFileSelected(true, outputAdditionalUwErrorInfo, $event)"></generic-file-upload>
                                </div>
                            </div>
                            <div class="form-group" fxFlex="50">
                                <h3>Dependent File</h3>
                                <div style="width:90%;">
                                    <generic-file-upload *ngIf="isHca || true" [allowedFileTypes]="allowedFileTypes"
                                        [multiple]="false" [autoUpload]="false" [maxfilesize]="31457280"
                                        (selectEventHandler)="uwSyncFileSelected(false, outputAdditionalUwErrorInfo, $event)"></generic-file-upload>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" fxFlex="100">
                            <button class="btn btn-primary" (click)="uwSyncFileUploadEOM()">Upload UW EOM</button>
                        </div>
                    </div>
                </div>

                <h2 class="mobile-text-centered" style="margin-top:20px; margin-bottom:5px;">Feature Flags</h2>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
                    style="border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                    <h3>Sync File Feature Flags</h3>
                    <div class="form-group" fxFlex="100"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;">
                        <div class="form-group" fxFlex="100">
                            <div fxFlex="25">
                                <checkbox name="enableSyncMedicareD" [(ngModel)]="featureFlags.sync.enableSyncMedicareD">Enable Medicare D</checkbox>
                            </div>
                            <div fxFlex="25">
                                <checkbox name="enablePebbVision" [(ngModel)]="featureFlags.sync.enablePebbVision">Enable PEBB Vision</checkbox>
                            </div>
                            <div fxFlex="25">
                                <checkbox name="enableSubscriberMedicalDateCheck" [(ngModel)]="featureFlags.sync.enableSubscriberMedicalDateCheck">Check Outgoing Subscriber Medical Dates</checkbox>
                            </div>
                            <div fxFlex="25">
                                <checkbox name="enableSubscriberDentalDateCheck" [(ngModel)]="featureFlags.sync.enableSubscriberDentalDateCheck">Check Outgoing Subscriber Dental Dates</checkbox>
                            </div>
                            <div fxFlex="25">
                                <checkbox name="enableSubscriberVisionDateCheck" [(ngModel)]="featureFlags.sync.enableSubscriberVisionDateCheck">Check Outgoing Subscriber Vision Dates</checkbox>
                            </div>
                            <div fxFlex="25">
                                <checkbox name="enableSubscriberEligibilityDateCheck" [(ngModel)]="featureFlags.sync.enableSubscriberEligibilityDateCheck">Check Outgoing Subscriber Eligibility Dates</checkbox>
                            </div>
                            <div fxFlex="25">
                                <checkbox name="enableSubscriberOrganizationCodeCheck" [(ngModel)]="featureFlags.sync.enableSubscriberOrganizationCodeCheck">Check Outgoing Subscriber Organization Code</checkbox>
                            </div>
                        </div>
                    </div>
                    <div style="text-align:center;">
                        <button class="btn btn-primary" (click)="saveSyncFeatureFlags(featureFlags.sync)">Save</button>
                    </div>
                </div>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill
                    style="border: solid 1px whitesmoke;border-radius: 5px;padding: 20px;">
                    <h3>UI Feature Flags</h3>
                    <div class="form-group" fxFlex="100"
                        style="margin: 20px;border: solid 1px whitesmoke;padding: 10px;border-radius: 5px;">
                        <div class="form-group" fxFlex="100">
                            <div fxFlex="25">
                                <checkbox name="enableMedicareD" [(ngModel)]="featureFlags.ui.enableMedicareD">Enable Medicare D</checkbox>
                            </div>
                        </div>
                    </div>
                    <div style="text-align:center;">
                        <button class="btn btn-primary" (click)="saveUiFeatureFlags(featureFlags.ui)">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>