import Message from 'src/app/models/message';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, } from '@angular/router';

@Component({
  selector: 'pebb-message',
  templateUrl: 'message.component.html',
  styleUrls: [],
  providers: [],
})
export class PEBBMessagesComponent implements OnInit, OnDestroy {
  @Input() messages: Message[];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

}
