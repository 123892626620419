import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import { forEach } from 'lodash';
import Report from '../models/report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getReportResultsForHCA(reportId: string, parameters: any): Observable<any[]> {
    return this.http
      .post<any[]>(`${env.apiUrl}/report/${reportId}/results`, parameters)
      .pipe(map(x => x))
      .pipe(catchError(this.commonService.handleError));
  }

  getReportResultsForOrganization(reportId: string, parameters: any, organizationId: string): Observable<any[]> {
    return this.http
      .post<any[]>(`${env.apiUrl}/report/organization/${organizationId}/${reportId}/results`, parameters)
      .pipe(map(x => x))
      .pipe(catchError(this.commonService.handleError));
  }

  getAvailableReportsForHCA(): Observable<Report[]> {
    return this.http.get<Report[]>(`${env.apiUrl}/report`)
    .pipe(map(x => this.commonService.createObjects(Report, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getAvailableReportsForOrganization(organizationId: string): Observable<Report[]> {
    return this.http.get<Report[]>(`${env.apiUrl}/report/organization/${organizationId}`)
    .pipe(map(x => this.commonService.createObjects(Report, x)))
    .pipe(catchError(this.commonService.handleError));
  }
}
