import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { CoreService } from './../../../../services/core.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Required } from 'src/app/decorators/inputRequired.decorator';
import Milestone from 'src/app/models/milestone';
import StepConfig from 'src/app/models/stepConfig';
import { find } from 'lodash';

@Component({
  selector: 'hca-prompt',
  templateUrl: 'prompt.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class PromptComponent implements OnInit {
  // implementing this along-side so as not to nuke everything everywhere, but everything should refactor to use step configs
  @Input() conditionalNextIdx = 0;
  @Input() steps?: StepConfig[];
  @Input() icon;
  @Input() hideButtons = false;
  @Input() showCancel = false;
  @Input() milestone: Milestone;
  @Input() confirmIndex: number;
  @Input() conditionalNextMet = true;
  @Input() confirmButtonText = 'Confirm';
  @Input() alwaysShowConfirmButtonOnLastStep = false;
  @Input() hideBackToDash = false;
  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  @Output() previous: EventEmitter<number> = new EventEmitter<number>();
  @Output() putPostObject: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() hasBackToDashButton = false;
  @Input() hideButtonsOnSteps: string[] = [];
  @Input() idx = 0;
  currentStep: StepConfig;
  constructor(private route: ActivatedRoute, private router: Router, private coreService: CoreService) {}

  ngOnInit(): void {
    if (this.steps) {
      this.currentStep = find(this.steps, (s: StepConfig) => s.idx === 0);
    }
  }

  emitNext(): void {
    // has step configs
    if (this.steps) {
      if (this.currentStep.isConfirmationStep) {
        this.emitPutPostObject();
        this.currentStep = this.getNextStep(this.currentStep);
        this.next.emit(this.currentStep.idx);
      } else {
        this.currentStep = this.getNextStep(this.currentStep);
        this.next.emit(this.currentStep.idx);
      }
    } else {
      // does not have step configs
      if (this.idx === this.confirmIndex) {
        this.emitPutPostObject();
        this.idx++;
        this.next.emit(this.idx);
      } else {
        this.idx++;
        this.next.emit(this.idx);
      }
    }
  }

  emitCancel(): void {
    this.cancel.emit();
  }

  emitPrev(): void {
    // has step configs
    if (this.steps) {
      this.currentStep = this.getPreviousStep(this.currentStep);
      this.previous.emit(this.currentStep.idx);
    } else {
      // does not have step configs
      this.idx--;
      if (this.idx > -1) {
        this.previous.emit(this.idx);
      }
    }
  }

  emitPutPostObject(): void {
    this.putPostObject.emit();
    this.idx++;
  }

  navToDashboard(): void {
    // this.coreService.setEnrollmentPeriod(null);
    // const sub = this.coreService.getSubscriberValue();
    this.router.navigate([`../../`], {relativeTo: this.route});
  }

  getNextStep(step: StepConfig): StepConfig {
    return find(this.steps, (s: StepConfig) => s.idx === step.idx + 1);
  }

  getPreviousStep(step: StepConfig): StepConfig {
    return find(this.steps, (s: StepConfig) => s.idx === step.idx - 1);
  }
}
