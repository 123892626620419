import { Component, Input, OnInit } from '@angular/core';
import { SoeService } from 'src/app/services/soe.service';
import { lastValueFrom } from 'rxjs';
import SpecialOpenEnrollmentActionLog from 'src/app/models/specialOpenEnrollmentActionLog';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';

@Component({
  selector: 'soereview-summary',
  templateUrl: 'soeReview.summary.component.html',
  styleUrls: [],
  providers: []
})

export class SoeReviewSummaryComponent implements OnInit {
  @Input() soe: SpecialOpenEnrollment;
  now: Date;

  constructor(private soeService: SoeService) {}

  ngOnInit(): void {
    this.now = new Date();
  }
}
