import { find, some, sortBy, reverse, first, filter } from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import Member from 'src/app/models/member';
import Enrollment from 'src/app/models/enrollment';

@Component({
  selector: 'selfpay-summary',
  templateUrl: 'selfpay.summary.component.html',
  styleUrls: [],
  providers: []
})

export class SelfpayReviewSummaryComponent implements OnInit {
  @Input() dataItem;
  @Input() userCanEdit: boolean;
  @Input() lookups = {
    selfPayProcessStatus: []
  };
  latestEnrollment: Enrollment;
  find: find;
  now: Date;
  ngOnInit() {
    this.now = new Date();
  }

  ngOnChanges() {
    const subscriberMember: Member = find(this.dataItem?.member?.members,(m:Member)=>m.isSubscriberInd);
    if (subscriberMember.enrollments) {
      this.latestEnrollment = first(reverse(sortBy(filter(subscriberMember.enrollments, (e: Enrollment) => e.effectiveStartDate), 'effectiveStartDate')));
    }
  }

  public getPlanEffectiveEnd(m: Member, planTypeCode: string) {
    return first(reverse(sortBy(filter(m.enrollments, (e: Enrollment) => e.plan?.planType?.planTypeCode === planTypeCode && e.effectiveEndDate), 'effectiveEndDate')))?.effectiveEndDate;
  }


  public hasEnrollmentForPlanType(planTypeCode: string) {
    return some(this.dataItem.member?.enrollments, (e: Enrollment) => e.plan?.planType?.planTypeCode === planTypeCode);
  }
}
