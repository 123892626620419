import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import { CoreService } from './../../../../../../services/core.service';
import { NgForm } from '@angular/forms';
import { faCalendar, faHome } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
// ng
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild } from '@angular/core';

// ext
import { find, remove, map, filter, get, some, cloneDeep } from 'lodash';

// local
import DependentComposite from 'src/app/models/dependentComposite';
import RelationshipType from 'src/app/models/relationshipType';
import Subscriber from 'src/app/models/subscriber';

import { env } from 'src/env/development';
import Member from 'src/app/models/member';
import SelfPay from 'src/app/models/selfPay';
import MemberMedicare from 'src/app/models/memberMedicare';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { MedicareComponent } from 'src/app/modules/shared/components/medicare/medicare.component';

@Component({
  selector: 'dep-demo',
  templateUrl: 'dep.demo.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentDemoComponent implements OnInit, OnDestroy {
  @Input() dependent: DependentComposite;
  initialDependent: DependentComposite;
  @Input() selfPay: SelfPay;
  icons = {
    faCalendar,
    faHome,
  };
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    subagencies: [],
    phoneNumberTypes: [],
    eligibilityReasons: [],
    terminationReasons: [],
    planTypes: [],
    medicareOptions: []
  };
  @Input() isHcaEdit = false;
  @Input() subscriber: Subscriber;
  @Input() enrollmentPeriod: EnrollmentPeriod;
  @Input() isSebb = false;
  @Output() moveToNextSubsection: EventEmitter<number> = new EventEmitter();
  @ViewChild('memberForm') public memberForm: NgForm;
  @ViewChild('medicareComponent') public medicareComponent: MedicareComponent;
  requestRemoveInd = false;
  isSubscriber = false;
  pendingChanges = false;
  inAddState: boolean;
  inManagementState: boolean;
  showPartnershipDate = false;
  inSOEState = false;
  minBirthDate: Date;
  mailingAddressIsSame = true;
  now: Date = new Date();
  marriageDateInFuture = false;
  showMemberReactivate = false;
  noSSN = false;
  // because ssn is model bound - for ignoring on ssn collisions check.
  originalSSN: string;
  selectedRelationshipType: RelationshipType = new RelationshipType();
  spousalRelationshipTypeId: string;
  extendedOrDisabledRelationshipQualifyIds: string[];
  maxBirthdateForSpouse: Date;
  isPending = false;
  env = env;
  allowedRelationshipQualifyReasons: string[] = [];
  formCSignatureMinDate: Date;
  constructor(private coreService: CoreService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.spousalRelationshipTypeId = find(this.lookups.relationshipTypes, (r: RelationshipType) => r.relationshipTypeCode === 'S').relationshipTypeId;
    const fourteen = dayjs().subtract(14, 'year');
    this.minBirthDate = dayjs().subtract(110, 'year').toDate();
    this.maxBirthdateForSpouse = fourteen.toDate();
    this.noSSN = this.dependent.socialSecurityNumber?.startsWith('99999');
    this.selectedRelationshipType = find(this.lookups.relationshipTypes, (relationshipType: RelationshipType) => relationshipType.relationshipTypeId === this.dependent.relationshipTypeId);
    this.isPending = this.dependent.simplifiedStatus === 'Pending';
    this.extendedOrDisabledRelationshipQualifyIds = map(
      filter(this.lookups.relationshipQualifyReasons, (rqr: RelationshipQualifyReason) => rqr.relationshipQualifyReasonCode === 'A' || rqr.relationshipQualifyReasonCode === 'D'),
      'relationshipQualifyReasonId'
    );
    // imported from soe
    if (this.dependent.birthDate && !this.dependent.relationshipQualifyReasonId) {
      this.updateBirthDate(this.dependent.birthDate);
    }
    this.initialDependent = cloneDeep(this.dependent);

    if (this.dependent.memberId) {
      this.allowedRelationshipQualifyReasons = this.dependent.allowedRelationshipQualifyReasons
      (
        this.lookups.relationshipTypes,
        this.lookups.relationshipQualifyReasons,
        this.enrollmentPeriod.coverageEffectiveStartDate
      );
    }
    this.formCSignatureMinDate = dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).subtract(90, 'day').toDate();
  }

  ngOnDestroy(): void {}

  submitDependent(formContainer): void {
    // existing member
    let isValid = true;
    let messageToShow = 'Please complete or correct the field(s) indicated above';
    this.markAllControlsAsTouched();
    if (this.dependent.socialSecurityNumber) {
      if (this.ssnAlreadyExistsForAnotherDependent(this.dependent.socialSecurityNumber))  {
        return this.memberForm.controls[`socialSecurityNumber`].setErrors({ existsOnSubscriber: true });
      }
    }
    const nowMoment = dayjs();
    const minBirthDate = new Date(nowMoment.year() - 110, nowMoment.month() + 1, nowMoment.date());
    if (this.dependent.birthDate > nowMoment.endOf('day').toDate() || this.dependent.birthDate < minBirthDate) {
      messageToShow = 'The birth date cannot be in the future or more than 110 years in the past.';
    }

    // if birthdate is older than 26 at coverage eff. date
    if (this.dependent.relationshipTypeId !== this.spousalRelationshipTypeId &&
      this.extendedOrDisabledRelationshipQualifyIds.indexOf(this.dependent.relationshipQualifyReasonId) === -1 &&
      dayjs(this.dependent.birthDate).isBefore(dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).subtract(26, 'year'))) {
      messageToShow = 'Unable to enroll non-disabled dependent age 26 or older.';
      this.memberForm.controls[`birthDate`].setErrors({ minDate: true });
    }

    isValid = isValid ? this.memberForm.valid : false;

    const medicareError = this.medicareComponent?.medicareIsValid();
    if (isValid && this.medicareComponent && medicareError!="") {
      isValid = false;
      messageToShow = medicareError;
    }

    if (isValid) {
      this.moveToNextSubsection.emit();
    } else {
      this.coreService.popMessage(messageToShow, 'error', 4000, this.coreService.getInvalidFields(formContainer));
    }
  }

  handleNoSSN(e): void {
    if (e) {
      this.dependent.socialSecurityNumber = null;
    }
  }

  changeRelationshipType(id: string): void {
    this.selectedRelationshipType = find(this.lookups.relationshipTypes, (relationshipType: RelationshipType) => relationshipType.relationshipTypeId === this.dependent.relationshipTypeId);
    if (!this.dependent.memberId) {
      this.dependent.relationshipQualifyReasonId = null;
    }
    this.updateBirthDate(this.dependent.birthDate);
  }

  updateBirthDate(d: Date): void {
    if(this.dependent.relationshipTypeId !== this.spousalRelationshipTypeId){
      this.dependent.relationshipQualifyReasonId =
        this.dependent.determineRelationshipQualifyReason(this.lookups.relationshipTypes, this.lookups.relationshipQualifyReasons, this.enrollmentPeriod.coverageEffectiveStartDate);
      }
      this.allowedRelationshipQualifyReasons = this.dependent.allowedRelationshipQualifyReasons
      (
        this.lookups.relationshipTypes,
        this.lookups.relationshipQualifyReasons,
        this.enrollmentPeriod.coverageEffectiveStartDate
      );
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.memberForm);
    this.coreService.markFormControlsAsTouched(this.medicareComponent);
  }

  ssnAlreadyExistsForAnotherDependent(ssn: string): boolean {
    return some(this.subscriber.members, (m: Member) => m.socialSecurityNumber === ssn && m.memberId !== this.dependent.memberId);
  }
}
