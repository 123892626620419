<div fxFlexFill fxLayoutAlign="space-around" fxLayout="column">
    <div>
        <kendo-upload
            role="application"
            #fileUpload="ngModel"
            [saveUrl]="uploadSaveUrl"
            [removeUrl]="uploadRemoveUrl"
            [restrictions]="restrictions"
            [autoUpload]="autoUpload"
            [multiple]="multiple"
            (success)="fileUploadSuccessful($event)"
            (remove)="removeEventHandler.emit($event)"
            (select)="handleFileSelect($event)"
            [(ngModel)]="uploadModel"
            >
        </kendo-upload>
    </div>
    <div fxFlexOffset="12px">
        <label for="fileUpload">Allowed file types: <strong>{{allowedFileTypes.join(', ')}}</strong></label>
    </div>
    <div>
        <label for="fileUpload">Maximum file size: <strong>{{restrictions.maxFileSize/1048576}}mb</strong></label>
    </div>
</div>
