export default class UserRole {
  userRoleId: string;
  userRoleName: string;
  userRoleCode: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(userRole?) {
    if (userRole) {
      this.userRoleId = userRole.userRoleId;
      this.userRoleName = userRole.userRoleName;
      this.userRoleCode = userRole.userRoleCode;
      this.obsoleteFlag = userRole.obsoleteFlag;
      this.obsoleteDate = userRole.obsoleteDate ? new Date(userRole.ObsoleteDate) : null;
    }
  }
}
