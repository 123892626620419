import { ActivatedRoute, Router } from '@angular/router';
import { faCalendar, faImages } from '@fortawesome/free-solid-svg-icons';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { orderBy, sortBy, find, some, uniq, map } from 'lodash';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import * as dayjs from 'dayjs';
import { CoreService } from 'src/app/services/core.service';
// import { SOEPDFComponent } from 'src/app/modules/subscriber/modules/soe/components/pdf/soe.pdf.component';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Milestone from 'src/app/models/milestone';
import { SoeService } from 'src/app/services/soe.service';
import SpecialOpenEnrollmentActionLog from 'src/app/models/specialOpenEnrollmentActionLog';
import { lastValueFrom, Observable } from 'rxjs';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';
import Subscriber from 'src/app/models/subscriber';
import { KeyValue } from '@angular/common';
import DualEnrollment from 'src/app/models/dualEnrollment';
import { env } from 'src/env/development';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';

@UntilDestroy()
@Component({
  selector: 'soereview-form',
  templateUrl: 'soeReview.form.component.html',
  styleUrls: [],
  providers: []
})
export class SoeReviewFormComponent implements OnInit, OnChanges {
  @Input() soe: SpecialOpenEnrollment;
  @Input() verifyValues: SpecialOpenEnrollmentVerificationStatus[] = [];
  @Input() denyValues: SpecialOpenEnrollmentVerificationStatus[] = [];
  @Input() allVerificationStatus: SpecialOpenEnrollmentVerificationStatus[] = [];
  @Input() isHCAAdmin: boolean;
  @Input() hasEditAccess: boolean;
  @Input() subscriber: Subscriber;
  @Output() refreshSelectedSOE: EventEmitter<void> = new EventEmitter<void>();
  @Output() pushSaveVerificationResponse: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetData: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveAndContinueWizard: EventEmitter<void> = new EventEmitter<void>();
  @Output() downloadPDF: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Output() waiveDE: EventEmitter<void> = new EventEmitter<void>();
  @Output() continueDE: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Output() within60Days: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('verifyForm') public verifyForm: NgForm;
  // @ViewChild('pdfContent') pdfContent: SOEPDFComponent;
  canEdit = false;
  pendingChanges;
  noDocumentId: string;
  icons = {
    faCalendar,
    faImages
  };
  now = dayjs().endOf('day').toDate();
  proceedingWithWizard = false;
  soeHasBeenCompleted = false;
  coverageDateInitialNull = false;
  logs: SpecialOpenEnrollmentActionLog[];
  logsForDisplay: { [name: string]: string[] };
  showWizard = false;
  public isDualEnrolledForPeriod = false;
  dualEnrollment: DualEnrollment;
  dependentCoverageRemoved = false;
  within60DaysResponse: boolean = null;
  hasLogs: boolean = false;
  defaultPendingVerificationStatusId: string
  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => 0;

  constructor(
    private coreService: CoreService,
    private soeService: SoeService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerOverlayService,
    private router: Router) {}

  ngOnInit(): void {
    this.verifyValues = sortBy(this.verifyValues, ['specialOpenEnrollmentVerificationStatusName']);
    this.denyValues = sortBy(this.denyValues, ['specialOpenEnrollmentVerificationStatusName']);
    this.defaultPendingVerificationStatusId = find(this.allVerificationStatus,(s:SpecialOpenEnrollmentVerificationStatus) => 
      s.specialOpenEnrollmentVerificationStatusCode === env.soeVerificationStatusPendingCode).specialOpenEnrollmentVerificationStatusId;
    this.noDocumentId = find(this.denyValues, ['specialOpenEnrollmentVerificationStatusCode', 'ND']).specialOpenEnrollmentVerificationStatusId;
    this.coverageDateInitialNull = !this.soe.coverageEffectiveDate;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.subscriber) {
      this.isDualEnrolledForPeriod = this.subscriber.dualEnrolledInOtherAgency;
      if (this.isDualEnrolledForPeriod) {
        this.dualEnrollment = new DualEnrollment(this.subscriber.organization, null);
      }
    }

    if (this.soe) {
      this.canEdit = (this.isHCAAdmin || this.hasEditAccess) && this.soe.simplifiedStatus === 'Pending';
      this.soeHasBeenCompleted = some(this.soe?.member?.soeEnrollmentPeriod,
        (ep: EnrollmentPeriod) => some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && mi.isComplete));

      const removedMemberIds = await lastValueFrom(this.soeService.getRemovedDependents(this.soe));
      if (Array.isArray(removedMemberIds)) {
        this.dependentCoverageRemoved = removedMemberIds.length > 0;
      } else {
        this.dependentCoverageRemoved = false;
      }

      if (this.dependentCoverageRemoved) {
        this.within60DaysResponse = await lastValueFrom(this.soeService.getRemovedDependentsWithin60DayFlag(this.soe));
      }

      this.refreshLogs();
    }
  }

  pushSaveVerification(): void {
    this.markAllControlsAsTouched();
    if (this.verifyForm.valid) {
      if (dayjs() < (dayjs(this.soe.eventDate).startOf('month')) && this.soe.approvedInd) {
        this.coreService.popMessage('Cannot verify this Special Open Enrollment event until event date month has been reached.', 'error', 3000);
      } else {
        this.pushSaveVerificationResponse.emit();
      }
    } else {
      if (this.verifyForm.controls.verifyDate?.errors?.ngbDate?.minDate) {
        this.coreService.popMessage('Verification date cannot be prior to event submit date.', 'error', 3000);
      } else if (this.verifyForm.controls.verifyDate?.errors?.ngbDate?.maxDate) {
        this.coreService.popMessage('Verification date cannot be a future date.', 'error', 3000);
      } else {
        this.coreService.popMessage('Please enter all required fields and re-submit', 'error', 3000);
      }
    }
  }

  updatedVerificationStatus(): void {
    this.soe.specialOpenEnrollmentVerificationStatusId = null;
    this.soe.documentTypeId = null;
    if (this.soe.approvedInd) {
      //approved
      this.soe.deniedDate = null;
      this.soe.approvedDate = new Date();
    } else if (this.soe.approvedInd === false) {
      //denied
      this.soe.deniedDate = new Date();
      this.soe.approvedDate = null;
    } else {
      //pending
      this.soe.specialOpenEnrollmentVerificationStatusId = this.defaultPendingVerificationStatusId;
      this.soe.approvedDate = null;
      this.soe.deniedDate = null;
    }
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.verifyForm);
  }

  // public navigateToDependents(): void {
  //   this.router.navigate([this.soe.memberId + '/dependents/' + this.soe?.specialOpenEnrollmentId], { relativeTo: this.route });
  // }

  public navigateToWizard(): void {
    // get first step of the wizard and navigate to it
    const initialMilestone: Milestone = sortBy(this.subscriber.soeEnrollmentPeriod.milestones, 'sortOrder')[0];
    const initialRoute = this.soe.memberId + env.milestoneMap[initialMilestone.milestoneName].state + this.soe?.specialOpenEnrollmentId;
    this.router.navigate([initialRoute], { relativeTo: this.route });
  }

  closeWizardDialogue(): void {
    // refresh selected SOE with any changes from the wizard
    this.refreshSelectedSOE.emit();
    this.refreshLogs();
    this.showWizardChange(false);
  }

  showWizardChange(show: boolean): void {
    this.showWizard = show;

    if (show) {
      this.navigateToWizard();
    }
  }

  continueEnrolling(): void {
    this.isDualEnrolledForPeriod = false;
    this.navigateToWizard();
  }

  saveCoverageDate(): void {
    this.saveAndContinueWizard.emit();
    this.coverageDateInitialNull = false;
  }

  async refreshLogs() {
    this.spinnerService.show();
    this.logs = await lastValueFrom(this.soeService.getLog(this.soe.specialOpenEnrollmentId, this.soe.memberId));
    this.logsForDisplay = {};

    if (this.logs) {
      const generateName =
        log => `${log.member.firstName} ${log.member.lastName}${log.isSubscriberInd ? '' : ' (dependent)'}`;

      const sortedNames = uniq(
        map(
          orderBy(this.logs, ['isSubscriberInd', 'member.firstName', 'member.lastName'], ['desc', 'asc', 'asc']),
          log => generateName(log)
        ),
      );

      sortedNames.forEach(name => {
        this.logsForDisplay[name] =
          sortBy(uniq(this.logs
            .filter(log => generateName(log) === name)
            .map(log => log.message)
            ));
        });

      this.hasLogs = this.logsForDisplay && Object.keys(this.logsForDisplay).length>0;
    }
    this.spinnerService.hide();
  }

}
