import { Component, Input, Injector, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors, AbstractControl, NgForm } from '@angular/forms';
import { __assign } from 'tslib';
import { each, find, remove, cloneDeep, orderBy } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { IconDefinition, faHome } from '@fortawesome/free-solid-svg-icons';
import BaseAddress from 'src/app/models/baseAddress';
import BaseAddressWithCountry from 'src/app/models/baseAddressWithCountry';
import Country from 'src/app/models/country';
import County from 'src/app/models/county';
import { LookupService } from 'src/app/services/lookup.service';
import { CoreService } from 'src/app/services/core.service';
import { env } from 'src/env/development';

const innerLabels =
    {
      addressLineOne: 'Address line 1',
      addressLineTwo: 'Address line 2',
      city: 'City',
      county: 'County',
      state: 'State/Province',
      zipCode: 'Zip code',
      country: 'Country'
    };

@Component({
  selector: 'address',
  templateUrl: 'address.component.html',
  styleUrls: [],
  providers:
  [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AddressComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AddressComponent
    }
  ]
})
export class AddressComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @ViewChild('form') form: NgForm;
  @Input() name = 'address';
  @Input() headerLabel: string;
  @Input() labelIcon: IconDefinition = faHome;
  @Input() disabled: boolean | string = false;
  @Input() required: boolean | string = true;
  @Input() readOnly: boolean | string = false;
  @Input() hideCounty: boolean | string = false;
  @Input() view = 'card'; // card, panel, inline, inline-dependent
  @Input() labelPrefix: string = null;
  @Input() useCountry: boolean | string = true;
  @Input() controlView: string = 'input';// input, text

  labels: { [name: string]: string };
  innerAddress: BaseAddressWithCountry | BaseAddress = new BaseAddress();
  innerDisabled = false;
  innerReadonly = false;
  innerRequired = true;
  innerHideCounty = false;

  filteredStates: string[];
  stateCodes: string[];
  caProvinceCodes: string[];
  usStateCodes: string[];
  canada: Country;
  unitedStates: Country;
  countries: Country[];
  counties: County[];
  filteredCounties: County[];
  isUS = false;
  zipPattern: RegExp;
  zipMin: number;

  private onChange = (value: BaseAddressWithCountry | BaseAddress) => {};
  private onTouched = () => {};
  private onValidateChange = () => {};

  constructor(private coreService: CoreService, private lookupService: LookupService, private inj: Injector) {}

  ngOnInit(): void {
    this.stateCodes = env.stateCodes;
    this.caProvinceCodes = env.caProvinceCodes;
    this.usStateCodes = env.stateCodes.filter((s) => !this.caProvinceCodes.includes(s));
    this.filteredStates = this.usStateCodes;

    this.determineLabels(this.view, this.labelPrefix);

    Promise.all([
      lastValueFrom(this.lookupService.getLutValues('country', Country)),
      lastValueFrom(this.lookupService.getLutValues('county', County))
    ])
      .then(inputs => {
        const countries = inputs[0];
        const counties = inputs[1];

        const possibleUnitedStates = remove(countries, (s) => s.countryCode === 'US');
        this.canada = find(countries, (c: Country) => c.countryCode === 'CA');
        this.unitedStates = possibleUnitedStates ? possibleUnitedStates[0] : null;

        countries.splice(0, 0, this.unitedStates);
        this.countries = countries;

        this.counties = this.filteredCounties = orderBy(counties, ['countyName'], ['asc']);

        if (this.innerAddress && this.innerAddress instanceof BaseAddressWithCountry) {
          let resetState = false;
          if (!this.innerAddress.countryId) {
            this.innerAddress.countryId = this.unitedStates.countryId;
            resetState = true;
          }
          this.handleCountryChange(this.innerAddress, resetState);
        } else if (this.innerAddress) {
          this.filteredCounties = this.stateChange(this.innerAddress, this.filteredCounties);
        }
      });

    this.innerRequired = this.required === true || this.required === '' || !!this.required;
    if (this.controlView === 'text') {
      this.innerRequired = false;
    }
    this.innerHideCounty = (typeof this.hideCounty === 'boolean' && this.hideCounty) ||
      (typeof this.hideCounty === 'string' && (this.hideCounty === '' || !!this.hideCounty));
    this.innerReadonly = this.readOnly === true || this.readOnly === '';
    this.innerDisabled = this.disabled === true || this.disabled === '';

    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    const that = this;
    outerControl.markAsTouched = (...args: any) => {
        that.coreService.markFormControlsAsTouched(that.form);
        prevMarkAsTouched.bind(outerControl)(...args);
        that.onValidateChange();
      };
  }

  determineLabels(view: string, prefix: string): void {
    this.labels = cloneDeep(innerLabels);

    if (view === 'inline-dependent') {
      this.labels.addressLineOne = 'Street address (if different from subscriber\'s)';
    } else {
      if (prefix) {
        for (const key in this.labels) {
          if (this.labels[key]) {
            const l = this.labels[key];

            if (l.startsWith('Address line')) {
              this.labels[key] = `${prefix} ${l}`;
            }
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.required) {
      this.innerRequired = changes.required.currentValue === true || changes.required.currentValue === '' || !!changes.required.currentValue;
    }

    if (changes && changes.hideCounty) {
      this.innerHideCounty = (typeof changes.hideCounty.currentValue === 'boolean' && changes.hideCounty.currentValue) ||
        (typeof changes.hideCounty.currentValue === 'string' && (changes.hideCounty.currentValue === '' && !!changes.hideCounty.currentValue));
    }

    if (changes && changes.readOnly) {
      this.innerReadonly = changes.readOnly.currentValue === true || changes.readOnly.currentValue === '';
    }

    if (changes && changes.disabled) {
      this.innerDisabled = changes.disabled.currentValue === true || changes.disabled.currentValue === '';
    }

    if (changes && (changes.labelPrefix || changes.view)) {
      this.determineLabels(changes.view?.currentValue ?? this.view, changes.labelPrefix?.currentValue ?? this.labelPrefix);
    }
  }

  innerChange(value: BaseAddressWithCountry | BaseAddress): void {
    this.onChange(value);
  }

  // ControlValueAccessor - Write initial value into component.
  writeValue(value: any): void {
    if (value !== this.innerAddress) {
      this.innerAddress = value;
      this.onValidateChange();
    }
  }

  // ngModel two-way binding support
  get value(): BaseAddressWithCountry | BaseAddress {
    return this.innerAddress;
  }

  // ngModel two-way binding support
  set value(v: BaseAddressWithCountry | BaseAddress) {
    if (v !== this.innerAddress) {
      this.innerAddress = v;
      this.innerChange(v);
      this.onValidateChange();
    }
  }

  controlBlurred(): void {
    this.onTouched();
    this.onValidateChange();
  }

  // ControlValueAccessor - Parent form changes disabled state.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // ControlValueAccessor - Parent form registers on change listener (is dirty)
  registerOnChange(fn): void {
    this.onChange = fn;
  }

  // ControlValueAccessor - Parent form registers on touched listener (is touched)
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  // Validator - Parent form registers on validator change listener
  registerOnValidatorChange(fn): void {
    this.onValidateChange = fn;
  }

  // Validator - Parent form detects need to validate.
  validate(control: AbstractControl): ValidationErrors | null {
    let errors: ValidationErrors = null;

    for (const k in this.form?.controls) {
      if (this.form.controls[k]) {
        const c = this.form.controls[k];
        if (c.errors) {
          errors = errors ?? {};

          for (const ck in c.errors) {
            if (c.errors[ck]) {
              if (!errors[ck]) {
                errors[ck] = c.errors[ck];
              }
            }
          }
        }
      }
    }

    return errors;
  }

  handleCountryChange(addressInfo, resetState?: boolean): void {
    if (!!resetState) {
      addressInfo.state = null;
    }

    this.isUS = addressInfo.countryId === this.unitedStates.countryId;
    if (this.isUS) {
      this.zipPattern = /^\d{5}(?:-\d{4})?$/;
      this.zipMin = 5;
    } else {
      this.zipPattern = /^[a-zA-Z0-9]{0,10}$/;
      this.zipMin = 0;
    }

    const stateList = addressInfo.countryId === this.canada.countryId ?
      this.caProvinceCodes :
      (addressInfo.countryId === this.unitedStates.countryId ? this.usStateCodes : []);
    this.filteredCounties = this.stateChange(addressInfo, this.filteredCounties, resetState);
    this.filteredStates = stateList;
  }

  stateChange(addressInfo, countiesList, resetCounty?: boolean): County[] {
    if (!!resetCounty) {
      addressInfo.countyId = null;
    }

    if (addressInfo.state && (addressInfo.state.toLowerCase() === 'wa' || addressInfo.state.toLowerCase() === 'or')) {
      countiesList = this.counties.filter((c: County) => c.stateCode.toLowerCase() === addressInfo.state.toLowerCase());
    } else {
      countiesList = [];
      addressInfo.countyId = null;
    }

    return countiesList;
  }

  getCountyName(countyId) {
    var county: County = this.counties.find((c: County) => c.countyId === countyId);
    return county?.countyName;
  }

  getCountryName(countryId) {
    var country: Country = this.countries.find((c: Country) => c.countryId === countryId);
    return country?.countryName;
  }
}
