<ngb-alert *ngIf="readOnly && planType === 'Medical'" [dismissible]="false">
  You cannot change your medical enrollment/plan unless you have a special open enrollment event. You can find more information on special open enrollment events
  <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/change-your-coverage#Special-open-enrollment" target="_blank">here</a>.
</ngb-alert>
<h2 style="margin-left: 20px">Current {{ planType }} plan - coverage effective date {{ enrollmentPeriod.coverageEffectiveStartDate | date: 'longDate' }}</h2>
<div>
  <div style="border: 2px solid black;" class="hca-pdf-table-content mgtp-small mgbt-small">
    <h3 style="margin-left: 20px;" class="mgtp-medium-sm">
      <b>{{ selectedPlan?.planName ? resolvePlanName(selectedPlan, medicareDEnabled, isRetiree, isCOBRA) : 'Waived' }}</b>
    </h3>
  </div>
  <div *ngIf="planType == 'Medical'">
    Choose one medical plan. If you do not select a medical plan, you will be enrolled in {{ isSEBB ? 'UMP Achieve 1' : 'UMP Classic' }}. Contact the plans with questions about benefits and provider information. Before you enroll, make sure the provider you want to use accepts the specific plan you choose by calling the plan to check.
  </div>
  <br/>
  <div *ngIf="planType == 'Dental'">
    Select a dental plan. Before you enroll, make sure the provider you want to use accepts the specific plan and group you choose. If you do not select a dental plan, you will be enrolled in Uniform Dental Plan (Group #9600). 
  </div>
  <br/>  
  <div *ngIf="subscriberSummary?.deMedicalAny && planType === 'Medical'">
    <h3 fxFlexFill class="hca-error-msgbox">
      <b>Please note</b>, the subscriber already exists in the 
      {{ (subscriber?.dualEnrolledInOtherAgency ? subscriberSummary.otherAgencyCode : subscriber?.agencyCode) }} program 
      <span *ngIf="subscriberSummary?.dependentDEMedical">as a dependent </span>with medical coverage. 
      Submitting this enrollment will terminate the medical coverage<span *ngIf="subscriberSummary?.dependentDEMedical"> as a dependent</span>. This
      action is irreversible and will require a re-enrollment if the subscriber decides to continue medical coverage
      <span *ngIf="subscriberSummary?.dependentDEMedical"> as a dependent</span>. You can choose to waive medical for this subscriber or confirm
      medical coverage termination <span *ngIf="subscriberSummary?.dependentDEMedical">as a dependent </span>by selecting the check box below to navigate forward.
    </h3>
    <div class="k-form-field" style="padding-top: 2px; padding-bottom: 5px">
      <checkbox bold name="acceptDEMessage" [(ngModel)]="subscriberSummary.DEMessageAccepted">
        I have read and understand the information provided above.
      </checkbox>
    </div>
  </div>
  <div *ngIf="invalidSPPlan!=''">
    <h3 fxFlexFill class="hca-error-msgbox">Plan {{invalidSPPlan}} was selected and is outside the subscriber's coverage area.  Please review.</h3>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center" class="fxGap-medium">
    <div fxLayout="column" fxFlex="65">
      <plan-selection
        [readonly]="readOnly"
        [selectedPlan]="selectedPlan"
        [planType]="planType"
        [availablePlans]="availablePlans"
        [selectedPlanId]="selectedPlanId"
        [subscriberOrDependentsHaveMedicareB]="subscriberOrDependentsHaveMedicareB"
        [medicareDEnabled]="medicareDEnabled"
        [isRetireeOrCOBRA]="isRetiree || isCOBRA"
        (updateSelectedPlan)="planUpdated($event)"
        (removeSelectedPlan)="planRemoved($event)"
        (getPlans)="getPMTPlans($event)"
      ></plan-selection>
    </div>
    <div fxLayout="column" fxFlex="30">
      <div fxLayout="column" fxLayoutAlign="end center">
        <div *ngIf="!shouldShowPremiums" fxLayoutAlign="start start" fxLayout="column">
          You must contact your benefits office for premium information
        </div>
        <div *ngIf="!cobraRetireeTypeCodes.includes(memberType?.memberTypeCode)">
          <h3 class="h5">Helpful links:</h3>
          <div *ngIf="!subscriber.isSebb" fxLayoutAlign="start start" fxLayout="column">
            <a *ngIf="planType === 'Medical'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/compare-medical-plans">Compare medical plans</a>
            <a *ngIf="planType === 'Medical'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/medical-plans-available-county">Medical plans by county</a>
            <a *ngIf="planType === 'Dental'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/compare-dental-plans">Compare dental plans</a>
            <span *ngIf="planType === 'Vision'"><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/vision-plans-and-benefits">Compare Vision plans</a>.</span>
            <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/find-provider">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
            <a *ngIf="planType !== 'Vision'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/contact-plans">Plan contact information</a>
        </div>
          <div *ngIf="subscriber.isSebb" fxLayoutAlign="start start" fxLayout="column" class="fxGap-medium">
            <ng-container *ngIf="planType === 'Medical'">
              <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/compare-medical-plans">Compare medical plans</a>
              <span>
                <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/medical-plans-county">Medical plans by county</a>
                <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/find-provider" target="_blank">Find  your provider</a>. 
                Make sure you have the correct provider network selected prior to searching for providers.
              </span>
            </ng-container>
            <ng-container *ngIf="planType === 'Dental'">
              <span><a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/compare-dental-plans" target="_blank">Compare dental plans</a>.</span>              
              <span><a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/find-provider" target="_blank">Find a provider</a>. Make sure you have the correct provider network selected before searching for providers.</span>
              <span>Plan <a href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/contact-plans" target="_blank">contact information</a></span>
            </ng-container>
            <ng-container *ngIf="planType === 'Vision'">
              <span><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/compare-vision-plans">Compare Vision plans</a>.</span>
              <span >Ensure that your provider of choice is available in the selected plan <a  target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/find-provider">Find your provider</a>.</span>
            </ng-container>
            <ng-container *ngIf="planType === 'Medical'">
              <span>Read the latest edition of the <a href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-newsletters" target="_blank">Intercom newsletter</a>.</span>
            </ng-container>
          </div>
        </div>
        <div *ngIf="cobraRetireeTypeCodes.includes(memberType?.memberTypeCode)" fxLayoutAlign="start start" fxLayout="column">
          <h4>Subscriber Medicare enrollment status</h4>
          <h5>Medicare part A: {{ subscriber.memberMedicare?.medicarePartAEnrolledInd ? 'Enrolled' : 'Not Enrolled' }}</h5>
          <h5>Medicare part B: {{ subscriber.memberMedicare?.medicarePartBEnrolledInd ? 'Enrolled' : 'Not Enrolled' }}</h5>
          <h5>Medicare part D: {{ subscriber.memberMedicare?.medicarePartDEnrolledInd ? 'Enrolled' : 'Not Enrolled' }}</h5>
          <h5>Subscriber Medicare status is updated on the eligibility tab</h5>
        </div>
      </div>
    </div>
    </div>
    <!-- TODO - MAP ALL THESE SP INPUTS TO VALUES OR SOME LOGIC -->
    <!-- dental, to not have to faff with waivable PTs -->
    <div *ngIf="isSelfPay && planType === 'Dental'" fxLayout="row" style="margin-left: 15px; padding-bottom: 10px; padding-top: 20px">
      <div class="k-form-field">
        <checkbox
          [name]="'discontinueEnrollment' + planType"
          bold
          [(ngModel)]="selfPayWaivingDentalCoverage"
          (change)="disenrollSubscriberDental(selfPayWaivingDentalCoverage)">
          Do not continue or remove subscriber dental coverage 
        </checkbox>
        <div *ngIf="hasDependentsEnrolled && !selectedPlan">You have one or more dependents enrolled in dental benefits, please select a dental plan to associate with enrolled dependent(s).</div>
      </div>
    </div>
    <div *ngIf="isSelfPay && planType === 'Vision'" fxLayout="row" style="margin-left: 15px; padding-bottom: 10px; padding-top: 20px">
      <div class="k-form-field">
        <checkbox
          [name]="'discontinueEnrollment' + planType"
          bold
          [(ngModel)]="selfPayWaivingVisionCoverage"
          (change)="disenrollSubscriberVision(selfPayWaivingVisionCoverage)">
          Do not continue or remove subscriber vision coverage 
        </checkbox>
        <div *ngIf="hasDependentsEnrolled && !selectedPlan">You have one or more dependents enrolled in vision benefits, please select a vision plan to associate with enrolled dependent(s).</div>
      </div>
    </div>    
    <waive
      *ngIf="isWaivable && !readOnly"
      (updateWaivedPlan)="setWaivedPlan($event)"
      (subscriberIsDeferringDueToMedicaid)="handleMedicaidDefer()"
      (cobraOrUnpaidWaiving)="handleCobraOrUnpaidWaiving($event.planType, $event.waived, $event.selfPayWaivingMedicalCoverage)"
      (deferRetireeChange)="deferRetireeChangeEmit($event)"
      (enrollRTL)="enrollRTLEmit($event)"
      (enrollBL)="enrollBL.emit($event)"
      [selectedPlan]="selectedPlan"
      [subscriberSummary]="subscriberSummary"
      [planType]="planType"
      [isWaived]="!selectedPlan && (
        (planType === 'Medical' && !removeMedicalForDE) ||
        (planType === 'Dental' && !removeDentalForDE) ||
        (planType === 'Vision' && !removeVisionForDE)
        )"
      [isDeferRetiree]="isDeferRetiree"
      [isDeferMedicaid]="false"
      [isEnrolledRetireeTermLife]="subscriberSummary.retireeTermLifeEnrolledInd"
      [isEnrolledBasicLife]="subscriberSummary.basicLifeEnrolledInd"
      [isRetiree]="retireeMemberTypeCodes.includes(memberType?.memberTypeCode) && isSelfPay"
      [isCobraOrUnpaid]="cobraUnpaidTypeCodes.includes(memberType?.memberTypeCode) && isSelfPay"
      [isUnpaidMedDen]="unpaidMemberTypes.includes(memberType?.memberTypeCode)"
      [selfPayWaivingMedicalCoverage]="selfPayWaivingMedicalCoverage"
      [hasDependentsEnrolled]="hasDependentsEnrolledInMedical"
    ></waive>
    <ng-container *ngIf="isSOE && soeTypeName === 'HCA OE Correction'">
      <div class="pad-std-container">
        <div class="k-form-field" fxFlex fxLayout>
          <checkbox *ngIf="planType === 'Medical'"
            [name]="'removeMedicalForDE'"
            bold
            [(ngModel)]="removeMedicalForDE"
            (change)="removeForDEMedical(removeMedicalForDE)">
            Remove this coverage due to a dual enrollment correction. 
          </checkbox>
          <checkbox *ngIf="planType === 'Dental'"
            [name]="'removeDentalForDE'"
            bold
            [(ngModel)]="removeDentalForDE"
            (change)="removeForDEDental()">
            Remove this coverage due to a dual enrollment correction. 
          </checkbox>
          <checkbox *ngIf="planType === 'Vision'"
            [name]="'removeVisionForDE'"
            bold
            [(ngModel)]="removeVisionForDE"
            (change)="removeForDEVision()">
            Remove this coverage due to a dual enrollment correction. 
          </checkbox>
        </div>
      </div>
    </ng-container>
    <div *ngIf="selectedPlan?.planCoverageMessage">
      <h2 class="hca-red-color">
        {{selectedPlan?.planCoverageMessage}}
      </h2>
    </div>    
    <div *ngIf="isWaivable && !selectedPlan && hasDependentsEnrolled && !cobraOrUnpaidWaivingPlanType">
      <h2 class="hca-red-color">
        You have selected to waive enrollment in {{ planType | lowercase }} coverage. You cannot enroll dependents in {{ planType | lowercase }} unless you are enrolled. Your
        dependents will not be enrolled into {{ planType | lowercase }} coverage if you waive medical coverage.
      </h2>
    </div>
    <div *ngIf="(planType === 'Dental' || planType === 'Medical') && !selectedPlan && selfPayFormDependentsEnrolled">
      <h2 class="hca-red-color">
        You have one or more dependents electing into this coverage type.  Please choose the correct plan associated with this coverage or remove the dependent 
        coverage to resolve.
      </h2>
    </div>
</div>


