import Organization from './organization';
import * as dayjs from 'dayjs';
import Agency from './agency';
export default class UserOrganizationRole {
  systemUserAccessId: string;
  systemUserId: string;
  userRoleId: string;
  userRoleName: string;
  organizationId: string;
  organizationName: string;
  organization: Organization;
  agency: Agency;
  userAccessLevelId: string;
  userAccessLevelName: string;
  userAccessLevelCode?: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  isActive?: boolean;
  ospiDistrictNumber: string;
  originalEffectiveEndDate: Date;
  disallowLoginInd: boolean = false;
  constructor(userRole?) {
    if (userRole) {
      this.systemUserAccessId = userRole.systemUserAccessId || null;
      this.systemUserId = userRole.systemUserId;
      this.userRoleId = userRole.userRoleId;
      this.userRoleName = userRole.userRoleName;
      this.organizationId = userRole.organizationId;
      this.organizationName = userRole.organizationName;
      this.organization = new Organization(userRole.organization);
      this.userAccessLevelId = userRole.userAccessLevelId;
      this.userAccessLevelName = userRole.userAccessLevelName;
      this.userAccessLevelCode = userRole.userAccessLevelCode || null;
      this.effectiveStartDate = userRole.effectiveStartDate ? new Date(userRole.effectiveStartDate) : null;
      this.effectiveEndDate = userRole.effectiveEndDate ? new Date(userRole.effectiveEndDate) : null;
      this.originalEffectiveEndDate = this.effectiveEndDate;
      this.isActive = dayjs().isBetween(dayjs(this.effectiveStartDate).startOf('day'), (this.effectiveEndDate ? dayjs(this.effectiveEndDate).startOf('day') : dayjs().add(1, 'day')));
      this.agency = userRole.agency;
      this.ospiDistrictNumber = userRole.ospiDistrictNumber;
      this.disallowLoginInd = userRole.disallowLoginInd;      
    }
  }
}
