<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body" *ngIf="!showConfirmation">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
          <h2 class="mobile-text-centered mgtp-small">Document upload</h2>
        </div>
        <ngb-alert *ngIf="hasUnverifiedDisabled" type="danger" [dismissible]="false">
          The Certification of a Dependent with a Disability should not be uploaded here. Please follow the instructions on the form for where to send it.
        <br />
        For your dependent with a disability, only upload dependent verification documents (such as birth certificate) here.
        </ngb-alert>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
          <h4 class="mobile-text-centered mgtp-x-small"><b>Guidelines</b></h4>
        </div>
        <div>
          Verifying (proving) dependent eligibility helps us make sure we cover only people who qualify for health plan coverage. You provide this proof by submitting official documents. We will not enroll a dependent if we cannot prove their eligibility by the required deadline. We reserve the right to check a dependent’s eligibility at any time.
          <br/><br/>
          All documents must be submitted in English. Documents written in another language must be accompanied by a translated copy produced by a professional translator and certified with a notary public seal. You can upload your documents below or give them to your payroll or benefits office.
          <br/><br/>
          <a [href]="subscriber.isSebb ? 'https://www.hca.wa.gov/employee-retiree-benefits/school-employees/verify-and-enroll-my-dependents#valid-documents' : 
          'https://www.hca.wa.gov/employee-retiree-benefits/public-employees/verify-and-enroll-my-dependents'" target="_blank">
          Accepted dependent verification documents
        </a> by dependent type.
        </div>

        <h3>To enroll a spouse:</h3>
        <ul>
          <li>
            <b>The most recent year's federal tax return (black out financial information), either:</b>
            <ul>
              <li>A single return that lists you and your spouse, if you filed jointly.</li>
              <li>Each return for you and your spouse, if filed separately.</li>
            </ul>
          </li>
          <li>
            <b>A marriage certificate and proof that the marriage is still valid</b> (you do not have to live together), such as a utility bill, life insurance beneficiary document, or bank statement, dated within the last six months showing both your and your spouse's names (black out any financial information). If within six months of marriage, only the marriage certificate is required. . If using a utility bill, separate bills with the same address are allowed.
          </li>
          <li>
           <b>Petition for dissolution, petition for legal separation, or petition to invalidate (annul) marriage.</b>Must be filed within the last six months. 
          </li>
          <li><b>Defense Enrollment Eligibility Reporting System (DEERS) </b>registration</li>
          <li><b>Valid J-1 or J-2 visa</b> issued by the U.S. government</li>

        </ul>
        <h3>To enroll a state-registered domestic partner:</h3>
        <div>In addition to one of the following, also upload the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=50-0704&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_be">{{subscriber?.agency}} Declaration of Tax Status</a> (to indicate whether they qualify as a dependent for tax purposes). Provide a copy of (choose one):</div>
        <br />
        <ul>
          <li>
            <b>Certificate/card of a state-registered domestic partnership or a legal union and proof the partnership is still valid </b>(you do not have to live together), such as a utility bill, life insurance beneficiary document, or bank statement dated within the last six months showing both your and your partner's names (black out any financial information). If within six months of state registration, only the certificate/card is required. If using a utility bill, separate bills with the same address are allowed.
          </li>
          <li>
            <b>Petition to invalidate (annul) (recently filed, within the last six months) </b>a state-registered domestic partnership.
          </li>
        </ul>
        If you are enrolling a partner of a legal union also provide:
        <ul>
          <li>
            Proof of Washington State residency for both you and your partner.
          </li>
        </ul>
        <div>
          Additional dependent verification documents will be required within one year of the partner's enrollment for them to remain enrolled. More information can be found in <a target="_blank" href="https://www.hca.wa.gov/node/2801#dependent-eligibility">{{subscriber?.agency}} Program Administrative Policy 33-1</a>.
        </div>
        <h3>To enroll children:</h3>
        <div>
          Provide a copy of a (choose one):
        </div>
        <br/>
        <ul>
          <li>
            <b>The most recent year's federal tax return</b> that includes the child as a dependent (black out financial information) You can submit one copy of your tax return if it includes all family members that require verification.
          </li>
          <li><b>Birth certificate</b> (or hospital certificate with the child's footprints on it) showing the name of the parent who is the subscriber, the subscriber's spouse, or the subscriber's state-registered domestic partner.
          If the dependent is the subscriber's stepchild, the subscriber must also verify the spouse or state-registered domestic partner in order to enroll the child, even if not enrolling the spouse or state-registered domestic partner in PEBB insurance coverage.
          </li>
          <li><b>Certificate or decree of adoption</b> showing the name of the parent who is the subscriber, the subscriber's spouse, or the subscriber's state-registered domestic partner</li>
          <li><b>Court-ordered parenting plan</b></li>
          <li><b>National Medical Support Notice</b></li>
          <li><b>Defense Enrollment Eligibility Reporting System (DEERS)</b> registration</li>
          <li><b>Valid J-2 visa</b> issued by the U.S. government</li>
          

        </ul>
        <div *ngIf="isDeathOrDivorceSoe">
          <p>
            Requested changes must be verified based on the event selected. Please provide the proof of the event and indicate which dependent
            and event it is associated to.
          </p>
          <p>
            Acceptable document(s)
          </p>
          <ul>
            <li>Petition for Dissolution of Marriage (divorce); or</li>
            <li>Petition for Dissolution of state registered domestic partnership; or</li>
            <li>Copy of a certificate of death.</li>
          </ul>
          <p>
            These documents can also be provided to your benefits office if you are an employee.
          </p>
        </div>
        <br />
        <br />
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="mgbt-small">
          <h4 class="mobile-text-centered mgtp-small"><b>Upload eligibility documents and indicate applicable dependents: </b></h4>
        </div>
        <generic-file-upload
          #fileUpload
          [autoUpload]="false"
          [type]="'document'"
          [allowedFileTypes]="allowedFileTypes"
          [systemUser]="systemUser"
          (selectEventHandler)="handleFilesSelected($event)"
          (removeEventHandler)="handleFilesRemoved($event)"
        >
        </generic-file-upload>
        <br/>
        <div fxLayout="column" style="height: 100%" class="fxGap-medium-lg">
            <upload-association 
              *ngIf="documents.length" 
              #association 
              [specialOpenEnrollments]="subscriber.specialOpenEnrollments" 
              [documents]="documents" 
              [currentSOE]="currentSOE"
              [isSOE]="isSOE"
              [subscriber]="subscriber"
              [members]="members">
            </upload-association>
            <div [hidden]="inAdminState && !coreService.systemUserHasAccess('edit')" fxLayout="row" fxLayoutAlign="space-around">
                <button [hidden]="!pendingChanges" style="width: 30%;" class="btn btn-light" (click)="clearSelections()" >Clear</button>
                <button [disabled]="submitted" style="width: 30%;" class="btn btn-primary" (click)="submitFileChanges()" >{{submitLabel(pendingChanges, enrollmentPeriod, isDeathOrDivorceSoe)}}</button>
            </div>
        </div>
      </div>
      <div class="card-body" *ngIf="showConfirmation">
        <upload-confirmation (confirmation)="confirmUpload($event)" [label]="submitLabel(pendingChanges, enrollmentPeriod, isDeathOrDivorceSoe)"></upload-confirmation>
      </div>
    </div>
    <div *ngIf="!isSOE" fxFlexFill fxLayout="row" fxLayoutAlign="center center" class="mgbt-medium-lg">
      <button (click)="navToDashboard()" class="btn btn-secondary mgtp-medium-lg">Back to dashboard</button>
    </div>
  </div>
</div>
