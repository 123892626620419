<div fxLayoutAlign="center center" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body" fxLayout="column">
        <h2 style="text-align: center;" class="mobile-text-centered mgtp-small">Benefits 24/7 Terms of Use:</h2>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div (scroll)="onscroll($event)" #termsofusebody id="termsofusebody" [innerHTML]="tou?.termsOfUseText"></div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" class="mgbt-medium">
          <button [disabled]="!userHasReadIt || touSubmitted" type="button" class="btn btn-primary" style="margin-right: 20px;" (click)="acceptTOU()">
            Accept
          </button>
          <button type="button" class="btn" (click)="logOut()">Decline</button>
        </div>
      </div>
    </div>
  </div>
</div>
