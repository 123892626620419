// ng
import { Component, ViewEncapsulation, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

// ext
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { forEach, some } from 'lodash';
import ReportDisplayField from 'src/app/models/reportDisplayField';

// local

@Component({
  selector: 'report-display',
  templateUrl: 'report.display.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class ReportDisplayComponent implements OnInit, OnChanges {
  icons = {
    faClipboardCheck,
  };
  @Input() lookups;
  @Input() reportColumnsToInclude: [] = [];
  @Input() gridData: [] = [];
  @Input() activeTab: number;
  @Output() okToSendToNext: EventEmitter<void> = new EventEmitter<void>();
  @Output() autoMoveToNext: EventEmitter<void> = new EventEmitter<void>();
  selectAllCheck = true;

  constructor() {}

  ngOnInit(): void {
    this.selectAll(true);

    if (this.reportColumnsToInclude.length <= 2) {
      setTimeout(() => this.autoMoveToNext.emit(), 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.activeTab && changes.activeTab.currentValue === 1 && changes.reportColumnsToInclude) {
      this.selectAllCheck = true;
    }
  }

  selectAll(val): void {
    this.selectAllCheck = val;

    forEach(this.reportColumnsToInclude, (rct: ReportDisplayField) => {
      rct.includeInResults = val;
    });
  }

  updatedValueManually(): void {
    if (some(this.reportColumnsToInclude, ['includeInResults', false])) {
      this.selectAllCheck = false;
    }
  }

  continueToNext(): void {
    this.okToSendToNext.emit();
  }
}
