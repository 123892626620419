/**
 * Parent component for orchestrating API calls for HCA Account Correction edits.
 */

// ng
import { Component, ViewEncapsulation, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';

// ext
import { sortBy, forEach, find, uniqBy} from 'lodash';
import { NgbPanelChangeEvent, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';

// local
import SubscriberAccountCorrection from 'src/app/models/subscriberAccountCorrection';
import MemberAccountCorrection from 'src/app/models/memberAccountCorrection';
import Employment from 'src/app/models/employment';
import SystemUser from 'src/app/models/user';
import MemberNote from 'src/app/models/memberNote';
import MemberMemberType from 'src/app/models/memberMemberType';
import MemberWaiver from 'src/app/models/memberWaiver';
import Plan from 'src/app/models/plan';
import MemberAddress from 'src/app/models/memberAddress';
import MemberMaritalStatus from 'src/app/models/memberMaritalStatus';
import Relationship from 'src/app/models/relationship';
import Attestation from 'src/app/models/attestation';
import OpenEnrollment from 'src/app/models/openEnrollment';
import { AdminService } from 'src/app/services/admin.service';
import { EmploymentService } from 'src/app/services/employment.service';
import { MemberService } from 'src/app/services/member.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { AddressService } from 'src/app/services/address.service';
import { CoreService } from 'src/app/services/core.service';
import { MemberMaritalStatusService } from 'src/app/services/memberMaritalStatus.service';
import { AccountCorrectionEditNotesComponent } from './components/notes/edit.notes.component';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import PlanType from 'src/app/models/planType';
import Enrollment from 'src/app/models/enrollment';
import { lastValueFrom } from 'rxjs';
import { AccountCorrectionsService } from 'src/app/services/accountCorrections.service';
import SimpleSubscriber from 'src/app/models/simpleSubscriber';
import { env } from 'src/env/development';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { SubscriberService } from 'src/app/services/subscriber.service';

@Component({
  selector: 'account-correction-edit',
  templateUrl: 'accountCorrection.edit.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class AccountCorrectionEditComponent implements OnChanges {
  @Input() subscriber: SubscriberAccountCorrection;
  @Input() systemUser: SystemUser;
  @Input() editable: boolean;
  @Input() enrollments: Enrollment[];
  @Input() enrollmentPeriods: EnrollmentPeriod[];
  @Input() lookups: {
    subscriberEnrollmentReasons: [],
    dependentEnrollmentReasons: [],
    subscriberTerminationReasons: [],
    dependentTermintationReasons: [],
    subscriberEligibilityReasons: [],
    subagencies: [],
    memberTypes: [],
    planTypes: [],
    addressTypes: [],
    counties: [],
    countries: [],
    verificationStatuses: [],
    qualifyReasons: [],
    relationshipTypes: [],
    responses: [],
    attestationTypes: [],
    agencies: []
  };
  @Input() availablePlans: {
    Health: [],
    Dental: [],
    Vision: []
  };
  @Input() supplementalPlan: Plan;
  @Input() lifePlan: Plan;
  @Input() basicPlan: Plan;
  @Input() adPlan: Plan;
  @Input() plans: Plan[];

  @Output() pushSaveNote: EventEmitter<any> = new EventEmitter<any>();
  @Output() refetchMember: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('editNote') public editNote: AccountCorrectionEditNotesComponent;
  icons = {
    faPlus,
    faMinus,
  };
  members: MemberAccountCorrection[];
  dependentCollapsed = {};
  currentEmployment: Employment;
  activePanels = [];
  activeCoveragePanels = [];
  activeTab;
  errorsList = [];

  constructor(
    private adminService: AdminService,
    private employmentService: EmploymentService,
    private addressService: AddressService,
    private marriageService: MemberMaritalStatusService,
    private memberService: MemberService,
    private spinnerService: SpinnerOverlayService,
    private coreService: CoreService,
    private accountCorrectionService: AccountCorrectionsService,
    private subscriberService: SubscriberService
  ) {}

  ngOnChanges() {
    if (this.subscriber) {
      this.loadDataForSubscriber(this.subscriber);
      this.currentEmployment = this.calcCurrentEmployment(this.subscriber.employments);
    }
  }

  loadDataForSubscriber(subscriber: SubscriberAccountCorrection) {
    this.members = sortBy(subscriber.members, 'sortOrder');
    forEach(this.members, (member: MemberAccountCorrection, index: number) => {
      this.dependentCollapsed[index] = true;
    });
  }

  calcCurrentEmployment(employments): Employment {
    return find(employments, (e) => {
      return dayjs().isBetween(e.effectiveStartDate, e.effectiveEndDate || dayjs(), 'days', '[]');
    });
  }

  async saveNote(note: {note: MemberNote, index: number}) {
    this.spinnerService.show();
    try {
      this.subscriber.memberNotes.push(await lastValueFrom(this.subscriberService.createSubscriberNote(note.note)));
      this.subscriber.memberNotes = this.subscriber.memberNotes.slice();
    } catch (err) {
      this.spinnerService.hide();
      console.log(err);
    }
    this.spinnerService.hide();
    this.editNote.newMessage = null;
  }

  async saveEnrollmentChanges(changes: {planType: string, enrollments: any}) {
    const allUpdates: Promise<any>[] = [];
    try {
      
      this.spinnerService.show();
      // need to run deletes after updates to prevent last enrollment err.
      await lastValueFrom(this.accountCorrectionService.save(this.subscriber.memberId, {enrollments: changes.enrollments}));
      this.spinnerService.hide();
      this.coreService.popMessage('Account corrections saved', 'success', 2000);
    } catch (err) {
      this.spinnerService.hide();
      if (err.error) {
        this.errorsList = err.error.errors;
      }
      else {
        this.coreService.popMessage('There was a problem updating the enrollment information.', 'error', 2000);
      }
    }
  }

  async saveAllEnrollmentChanges() {
    try {
      this.spinnerService.show();
      var allEnrollments = [];
      this.members.forEach((m)=> {
        //update plan object for new plans/changed plans
        m.enrollments.forEach((en)=>{
          if (en.enrollmentId.startsWith("NEW")) {
            en.enrollmentId = env.emptyGUIDString;
          }
          if (!en.plan || en.plan.planId != en.planId) {
            en.plan = find(this.plans, p => p.planId == en.planId)
          }
        });

        //combine all member enrollments into one list
        if (allEnrollments.length == 0 && m.enrollments.length>0) {
          allEnrollments = m.enrollments;
        }
        else if (m.enrollments.length>0) {
          allEnrollments = allEnrollments.concat(m.enrollments);
        }
      });

      // need to run deletes after updates to prevent last enrollment err.
      await lastValueFrom(this.accountCorrectionService.save(this.subscriber.memberId, {enrollments: allEnrollments}));
      this.spinnerService.hide();
      this.coreService.popMessage('Account corrections saved', 'success', 2000);
      this.refetchMember.emit(new SimpleSubscriber(this.subscriber));
    } catch (err) {
      this.spinnerService.hide();
      if (err?.error?.errors && err.status == 409) {
        this.errorsList = uniqBy(err.error.errors);
      }
      else {
        this.coreService.popMessage('There was a problem updating the enrollment information.', 'error', 2000);
      }
    }
  }

  async deactivateAccount() {
    try {
      this.spinnerService.show();
      await this.adminService.deactivateSubscriber(this.subscriber.memberId).toPromise();
      this.spinnerService.hide();
      this.coreService.popMessage('This subscriber has been successfully deactivated.', 'success', 2000);
      this.refetchMember.emit(this.subscriber.memberId);
    } catch (err) {
      this.spinnerService.hide();
      console.log(err);
      this.coreService.popMessage('There was a problem deactivating this account.', 'error', 2000);
    }
  }

  async removeEnrollment(enrollment, index: number) {
    try {
      if (enrollment.memberWaiverId) {
        this.spinnerService.show();
        await this.adminService.deleteWaiver(enrollment.memberId, enrollment.memberWaiverId).toPromise();
      } else if (enrollment.enrollmentId) {
        this.spinnerService.show();
        await this.adminService.deleteEnrollment(enrollment.memberId, enrollment.enrollmentId).toPromise();
      }
      this.spinnerService.hide();
      this.coreService.popMessage('Your enrollment record has been successfully deleted.', 'success', 2000);
    } catch (err) {
      this.spinnerService.hide();
      console.log(err);
      this.coreService.popMessage('There was a problem deleting your enrollment record', 'error', 2000);
    }
  }

  async updateEmployment(employment: Employment) {
    try {
      this.spinnerService.show();
      let updatedEmployment: Employment;
      if (employment.employmentId) {
        updatedEmployment = await this.employmentService.updateEmployment(employment).toPromise();
      } else {
        updatedEmployment = await this.employmentService.createEmployment(employment).toPromise();
      }
      const updatedSub = await this.adminService.getAccountCorrectionForSubscriber(this.subscriber.memberId).toPromise();
      this.subscriber = updatedSub;
      this.loadDataForSubscriber(this.subscriber);
      this.spinnerService.hide();
      this.coreService.popMessage('Your employment information has been saved.', 'success', 2000);
      this.refetchMember.emit(this.subscriber.memberId);
    } catch (err) {
      this.spinnerService.hide();
      console.log(err);
      this.coreService.popMessage('There was a problem updating the employment information.', 'error', 2000);
    }
  }


  async updateEligibility(eligibility: MemberMemberType) {
    try {
      if (eligibility.memberMemberTypeId) {
        this.spinnerService.show();
        await this.adminService.updateMemberMemberType(eligibility).toPromise();
      } else {
        this.spinnerService.show();
        await this.adminService.createMemberMemberType(eligibility).toPromise();
      }
      this.spinnerService.hide();
      this.coreService.popMessage('Your eligibility information has been saved.', 'success', 2000);
      this.refetchMember.emit(this.subscriber.memberId);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('There was a problem updating the eligibility information.', 'error', 2000);
    }
  }

  async removeEligibility(eligibility: MemberMemberType) {
    try {
      this.spinnerService.show();
      await this.adminService.deleteMemberMemberType(eligibility).toPromise();
      this.spinnerService.hide();
      this.coreService.popMessage('Your eligibility information has been successfully deleted.', 'success', 2000);
      this.refetchMember.emit(this.subscriber.memberId);
    } catch (e) {
      this.spinnerService.hide();
      this.coreService.popMessage('There was a problem deleting the eligibility record.', 'error', 2000);
    }
  }

  async updateAddresses(memberAddresses: MemberAddress[]) {
    const allUpdates: Promise<any>[] = [];
    try {
      forEach(memberAddresses, (ma) => {
        if (ma.memberAddressId) {
          allUpdates.push(this.addressService.updateMemberAddress(ma).toPromise());
        } else {
          allUpdates.push(this.addressService.createMemberAddress(ma).toPromise());
        }
      });
      this.spinnerService.show();
      Promise.all(allUpdates).then(() => {
        this.spinnerService.hide();
        this.coreService.popMessage('Your address information has been saved.', 'success', 2000);
        this.refetchMember.emit(this.subscriber.memberId);
      });
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('There was a problem updating the address record.', 'error', 2000);
    }
  }

  async removeAddress(memberAddress: MemberAddress) {
    try {
      this.spinnerService.show();
      await this.addressService.deleteMemberAddress(memberAddress).toPromise();
      this.spinnerService.hide();
      this.coreService.popMessage('The address information has been successfully deleted', 'success', 2000);
      this.refetchMember.emit(this.subscriber.memberId);
    } catch (e) {
      this.spinnerService.hide();
      this.coreService.popMessage('There was a problem deleting the address information.', 'error', 2000);
    }
  }

  updateMarriages(marriages: MemberMaritalStatus[]) {
    const allUpdates: Promise<any>[] = [];
    try {
      forEach(marriages, (ma) => {
        if (ma.memberMaritalStatusId) {
          allUpdates.push(this.marriageService.updateMemberMaritalStatus(ma).toPromise());
        } else {
          allUpdates.push(this.marriageService.createMemberMaritalStatus(ma).toPromise());
        }
      });
      this.spinnerService.show();
      Promise.all(allUpdates).then(() => {
        this.spinnerService.hide();
        this.coreService.popMessage('Your marriage information has been saved.', 'success', 2000);
        this.refetchMember.emit(this.subscriber.memberId);
      });
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('There was a problem updating the marriage information.', 'error', 2000);
    }
  }

  async removeMarriage(marriage: MemberMaritalStatus) {
    try {
      this.spinnerService.show();
      await this.marriageService.deleteMemberMaritalStatus(marriage).toPromise();
      this.spinnerService.hide();
      this.coreService.popMessage('Your marriage record has been successfully deleted.', 'success', 2000);
      this.refetchMember.emit(this.subscriber.memberId);
    } catch (e) {
      this.spinnerService.hide();
      this.coreService.popMessage('There was a problem deleting the marriage record.', 'error', 2000);
    }
  }

// RELATIONSHIPS

async updateRelationship(relationship: Relationship) {
  try {
    if (relationship.relationshipId) {
      this.spinnerService.show();
      await this.adminService.updateRelationship(relationship).toPromise();
    }
    this.spinnerService.hide();
    this.coreService.popMessage('Your relationship information has been saved.', 'success', 2000);
    this.refetchMember.emit(this.subscriber.memberId);
  } catch (err) {
    this.spinnerService.hide();
    this.coreService.popMessage('There was a problem updating the relationship information.', 'error', 2000);
  }
}

async removeRelationship(relationship: Relationship) {
  try {
    this.spinnerService.show();
    await this.adminService.deleteRelationship(relationship).toPromise();
    this.spinnerService.hide();
    this.coreService.popMessage('There was a problem updating the relationship information.', 'error', 2000);
    this.refetchMember.emit(this.subscriber.memberId);
  } catch (e) {
    this.spinnerService.hide();
    this.coreService.popMessage('There was a problem updating the relationship information.', 'error', 2000);
  }
}

// Attestations

updateAttestations(attestations: Attestation[]) {
  const allUpdates: Promise<any>[] = [];
  try {
    forEach(attestations, (a: Attestation) => {
      if (a.attestationId) {
        allUpdates.push(this.adminService.updateAttestation(a).toPromise());
      } else {
        allUpdates.push(this.adminService.createAttestation(a).toPromise());
      }
    });
    this.spinnerService.show();
    Promise.all(allUpdates).then(() => {
      this.spinnerService.hide();
      this.coreService.popMessage('Your attestation information has been saved.', 'success', 2000);
      this.refetchMember.emit(this.subscriber.memberId);
    });
  } catch (err) {
    this.spinnerService.hide();
    this.coreService.popMessage('There was a problem updating the attestation information.', 'error', 2000);
  }
}

async removeAttestation(attestation: Attestation) {
  try {
    this.spinnerService.show();
    await this.adminService.deleteAttestation(attestation).toPromise();
    this.spinnerService.hide();
    this.coreService.popMessage('Your attestation record has been successfully deleted.', 'success', 2000);
    this.refetchMember.emit(this.subscriber.memberId);
  } catch (e) {
    this.spinnerService.hide();
    this.coreService.popMessage('There was a problem deleting the attestation record', 'error', 2000);
  }
}


updatePanelsExpanded(e: NgbPanelChangeEvent) {
 if (e.nextState) {
  this.activePanels.push(e.panelId);
 } else {
   const idx = this.activePanels.indexOf(e.panelId);
   this.activePanels.splice(idx, 1);
 }
}

updateCoveragePanelsExpanded(e: NgbPanelChangeEvent) {
  if (e.nextState) {
   this.activeCoveragePanels.push(e.panelId);
  } else {
    const idx = this.activePanels.indexOf(e.panelId);
    this.activeCoveragePanels.splice(idx, 1);
  }
 }

  setTab(e) {
    this.activeTab = e.nextId;
  }

  closeACErrors() {
    this.errorsList = [];
  }

}
