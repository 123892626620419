import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core';
import { DataStateChangeEvent, DetailExpandEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { cloneDeep, filter, forEach, findIndex, find, sortBy } from 'lodash';
import { GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DocumentService } from 'src/app/services/document.service';
import Relationship from 'src/app/models/relationship';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import Document from 'src/app/models/document';
import SystemUser from 'src/app/models/user';
import RelationshipCertification from 'src/app/models/relationshipCertification';
import { CoreService } from 'src/app/services/core.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LowerLimitService } from 'src/app/services/lowerLimit.service';
import { lastValueFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'verification-admin-grid',
  templateUrl: 'verification.admin.grid.component.html',
  styleUrls: [],
  providers: []
})
export class VerificationAdminGridComponent implements OnInit, OnChanges {
  @Input() gridData: GridDataResult;
  @Input() gridColumns;
  @Input() selectable?: boolean;
  @Input() filterable = false;
  @Input() systemUser: SystemUser;
  @Input() lookups = {
    relationshipVerificationStatus: [],
    documentTypes: [],
    certificationTypes: [],
    relationshipCertificationStatus: []
  };
  @Input() isHCAAdmin: boolean;
  @Input() inHCAAdminState: boolean;
  @Input() state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  @Input() pageSize;
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;
  @Output() pushSaveRelationship: EventEmitter<Relationship> = new EventEmitter<Relationship>();
  @Output() pushSaveRelationshipCertification: EventEmitter<RelationshipCertification> = new EventEmitter<RelationshipCertification>();
  @Output() pushSaveCertification: EventEmitter<RelationshipCertification> = new EventEmitter<RelationshipCertification>();
  @Output() pushDeleteCertification: EventEmitter<RelationshipCertification> = new EventEmitter<RelationshipCertification>();
  @Output() pushSaveCertificationStatus: EventEmitter<Relationship> = new EventEmitter<Relationship>();
  @Output() pushGetData: EventEmitter<State> = new EventEmitter<State>();
  verifyValues: RelationshipVerificationStatus[] = [];
  denyValues: RelationshipVerificationStatus[] = [];
  pageable = true;
  lowerLimitDate: Date;

  constructor(
    private documentService: DocumentService,
    private lowerLimitService: LowerLimitService,
    public coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.lookups.relationshipVerificationStatus = sortBy(
    this.lookups.relationshipVerificationStatus, ['relationshipVerificationStatusName']);
    this.lookups.documentTypes = sortBy(this.lookups.documentTypes, ['documentTypeName']);
    this.lookups.certificationTypes = sortBy(this.lookups.certificationTypes, ['certificationTypeName']);
  }

  ngOnChanges(): void {
    if (this.lookups.relationshipVerificationStatus.length > 0) {
      this.verifyValues = filter(this.lookups.relationshipVerificationStatus, (rvs) => rvs.approvedInd);
      this.denyValues = filter(this.lookups.relationshipVerificationStatus, (rvs) => rvs.deniedInd);
    }
  }

  reSetDataForGrid(): void {
    this.pushGetData.emit(this.state);
  }

  getRowDocuments(event: DetailExpandEvent): void {
    this.spinnerService.show();
    const documents: Document[] = event.dataItem.documents || [];
    this.lowerLimitService.getLowerLimit(null, false, event.dataItem.subscriberMember.agencyCode).pipe(untilDestroyed(this)).subscribe(ll=> {
      this.lowerLimitDate = ll;
      this.spinnerService.hide();
    });

    forEach(documents, (document: Document) => {
      this.documentService.getMemberDocumentById(event.dataItem.subscriberMemberId, document.documentId, document).pipe(untilDestroyed(this)).subscribe((doc) => {
        document = doc;
        const originalRecIndex = findIndex(this.gridData.data, (gd: Relationship) => {
          return !!find(gd.documents, (d) => d.documentId === document.documentId);
        });
        const docIndex = findIndex(this.gridData?.data[originalRecIndex].documents, (d) => d.documentId === document.documentId);
        this.gridData.data[originalRecIndex].documents[docIndex] = doc;
      });
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.pushGetData.emit(this.state);
  }

  pushSaveVerification(dataItem: Relationship, index): void {
    this.kendoGrid.collapseRow(index);
    this.pushSaveRelationship.emit(dataItem);
  }

  closeDetail(rowIndex: number): void {
    this.kendoGrid.collapseRow(rowIndex);
  }

  saveCertification(index: number, certification: RelationshipCertification): void {
    this.kendoGrid.collapseRow(index);
    this.pushSaveCertification.emit(certification);
  }

  deleteCertification(index: number, certification: RelationshipCertification): void {
    this.kendoGrid.collapseRow(index);
    this.pushDeleteCertification.emit(certification);
  }

  saveCertificationStatus(index: number, relationship: Relationship): void {
    this.kendoGrid.collapseRow(index);
    this.pushSaveCertificationStatus.emit(relationship);
  }
}
