import Member from './member';
import TransactionSetType from './transactionSetType';

export default class Pay1SyncHold {
  pay1SyncHoldId: string;
  subscriberMemberId: string;
  memberId: string;
  transactionTypeCode: string;
  sequenceId: number;
  tranId: number;
  asOfDate: Date;
  sources: string[];
  output: string;
  enrollmentPeriodIds: string[];
  sentToPay1Date: Date;
  processedByPay1Date: Date;
  pay1ErrorDate: Date;
  pay1ErrorInfo: string[];
  createdDate: Date;

  constructor(source?) {
    if (source) {
      this.pay1SyncHoldId = source.pay1SyncHoldId;
      this.subscriberMemberId = source.subscriberMemberId;
      this.memberId = source.memberId;
      this.transactionTypeCode = source.transactionType;
      this.sequenceId = source.sequenceId;
      this.tranId = source.tranId;
      this.asOfDate = source.asOfDate ? new Date(source.asOfDate) : null;
      this.sources = source.source;
      this.output = source.output;
      this.enrollmentPeriodIds = source.enrollmentPeriodIds;
      this.sentToPay1Date = source.sentToPay1Date ? new Date(source.sentToPay1Date) : null;
      this.processedByPay1Date = source.processedByPay1Date ? new Date(source.processedByPay1Date) : null;
      this.pay1ErrorDate = source.pay1ErrorDate ? new Date(source.pay1ErrorDate) : null;
      this.pay1ErrorInfo = source.pay1ErrorInfo;
      this.createdDate = new Date(source.createdDate);
    }
  }
}
