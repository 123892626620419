import SystemUser from 'src/app/models/user';
/**
 * The root component. All other states are injected based on routing in this components
 * router-outlet. Global componenets (agency select, header, footer) declared here.
 */

// ng
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// ext
import { Subscription } from 'rxjs';

// local
import { env } from '../env/development';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SpinnerOverlayService } from './services/spinnerOverlay.service';
import { CoreService } from './services/core.service';
import { UserService } from './services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'PEBB My Account';
  version: string;
  apiVersion = '';
  isAuthorizedSubscription: Subscription;
  selfPayOnly = false;
  systemUser: SystemUser;
  constructor(
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private spinnerService: SpinnerOverlayService,
    private coreService: CoreService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
    this.spinnerService.hide();
    this.spinnerService.show();
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((q) => {
      if (q.selfPayOnly) {
        this.selfPayOnly = q.selfPayOnly;
      }
      if (!q.selfPayOnly){
        this.selfPayOnly = false;
      }
    });

    window.addEventListener('unload',  () => {});
    this.version = env.version;
    this.apiVersion = this.coreService.getAPIVersion();
    this.coreService.getSystemUser().pipe(untilDestroyed(this)).subscribe(u => {
      this.systemUser = u;
    });
    // This line is necessary for the silent renew to function for all paths.  This is only needed when the path is
    // accessed directly after login or the page is refreshed in the browser
    // this.oidcSecurityService.checkAuth().pipe(untilDestroyed(this)).subscribe(() => {});
    this.isAuthorizedSubscription = this.oidcSecurityService.checkAuth().pipe(untilDestroyed(this)).subscribe(({ isAuthenticated, userData, accessToken, idToken }) => {
      if (isAuthenticated) {
        this.userService.getUser().pipe(untilDestroyed(this)).subscribe(systemUser => {
          this.coreService.setSystemUser(systemUser);
          this.systemUser = systemUser;
          // necessary here? ruins refresh everywhere b/c app component always mounts.
          // this.coreService.initialAuthNavigate(systemUser);
        }, (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.router.navigate(['/claim']);
          } else {
            console.log(err);
          }
        });
      }
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.spinnerService.hide();
      this.spinnerService.show();
    }
    if (event instanceof NavigationEnd) {
      if (!event.url.includes('admin') && !event.url.includes('hca') && !event.url.includes('perspay')){
        window.scrollTo(0, 0);
      }
      this.spinnerService.hide();
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.spinnerService.hide();
    }
  }
}
