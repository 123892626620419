import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import * as dayjs from 'dayjs';
import { CommonService } from './common.service';
import Member from '../models/member';
import TransferSearch from '../models/transferSearch';
import SubscriberTransfer from '../models/subscriberTransfer';
import SubscriberTransferSearch from '../models/subscriberTransferSearch';
import SimpleMember from '../models/simpleMember';
import MemberMemberType from '../models/memberMemberType';
import SelfPayFormSubscriber from '../models/selfPayFormSubscriber';
import Subscriber from '../models/subscriber';
import TransferSearchResult from '../models/transferSearchResult';
import MemberNote from '../models/memberNote';
import ExternalEnrollment from '../models/externalEnrollment';
import MemberFlag from 'src/app/models/memberFlag';
import MemberEthnicity from '../models/memberEthnicity';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getMemberById(memberId: string): Observable<Member> {
    return this.http
      .get<Member>(`${env.apiUrl}/Member/${memberId}`)
      .pipe(map((x) => this.commonService.createObject(Member, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createMember(member: Member, openEnrollmentId: string): Observable<Member> {
    return this.http
      .post<Member>(`${env.apiUrl}/Member?openEnrollmentId=${openEnrollmentId}`, member)
      .pipe(map((x) => this.commonService.createObject(Member, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMember(member: Member): Observable<Member> {
    const simpleMember = new SimpleMember(member);
    return this.http
      .put<Member>(`${env.apiUrl}/Member/${member?.memberId}`, simpleMember)
      .pipe(map((x) => this.commonService.createObject(Member, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMemberWithoutCoverage(member: Member): Observable<Member> {
    const simpleMember = new SimpleMember(member);
    return this.http
      .put<Member>(`${env.apiUrl}/Member/${member.memberId}/noCoverage`, simpleMember)
      .pipe(map((x) => this.commonService.createObject(Member, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  transferMember(subscriberTransferSearch: SubscriberTransferSearch, organizationId: string): Observable<TransferSearchResult> {
    return this.http
      .post<Member>(`${env.apiUrl}/Member/${organizationId}/transferSearch`, subscriberTransferSearch)
      .pipe(map((x) => this.commonService.createObject(TransferSearchResult, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // memberMemberType

  getMemberTypesByMemberId(memberId: string): Observable<MemberMemberType[]> {
    return this.http
      .get<MemberMemberType>(`${env.apiUrl}/Members/${memberId}/memberTypes`)
      .pipe(map((x) => this.commonService.createObjects(MemberMemberType, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createMemberTypeForMember(memberId: string, memberMemberType: MemberMemberType): Observable<MemberMemberType> {
    return this.http
      .post<MemberMemberType>(`${env.apiUrl}/Members/${memberId}/MemberTypes`, memberMemberType)
      .pipe(map((x) => this.commonService.createObject(MemberMemberType, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMemberTypeForMemberEligibility(memberId: string, memberMemberType: MemberMemberType): Observable<MemberMemberType> {
    return this.http
      .put<Member>(`${env.apiUrl}/Members/${memberId}/MemberTypes/${memberMemberType.memberMemberTypeId}/eligibility`, memberMemberType)
      .pipe(map((x) => this.commonService.createObject(MemberMemberType, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMemberTypeForMemberLossOfEligibility(memberId: string, memberMemberType: MemberMemberType): Observable<MemberMemberType> {
    return this.http
      .put<Member>(`${env.apiUrl}/Members/${memberId}/MemberTypes/${memberMemberType.memberMemberTypeId}/lossofeligibility`, memberMemberType)
      .pipe(map((x) => this.commonService.createObject(MemberMemberType, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getSelfPayMember(memberId: string): Observable<SelfPayFormSubscriber> {
    return this.http
      .get<SelfPayFormSubscriber>(`${env.apiUrl}/SelfPay/${memberId}`)
      .pipe(map((x) => this.commonService.createObject(SelfPayFormSubscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // handles claiming terms which should have been transfers, sub is transferred to new org with existing enrolments.
  claimTermTransfer(transferRec: SubscriberTransfer, organizationId: string): Observable<SubscriberTransfer> {
    return this.http
      .post<SubscriberTransfer>(`${env.apiUrl}/Subscriber/${transferRec.memberId}/transfertermed/${organizationId}`, transferRec)
      .pipe(map((x) => this.commonService.createObject(SubscriberTransfer, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getExternalEnrollments(ssn: string, memberId?: string, subscriberMemberId?: string): Observable<ExternalEnrollment[]> {
    var transferSearch = new TransferSearch();
    transferSearch.socialSecurityNumber = ssn;
    transferSearch.memberId = memberId;
    transferSearch.subscriberMemberId = subscriberMemberId;

    return this.http
      .post<ExternalEnrollment>(`${env.apiUrl}/dualEnrollment`, transferSearch)
      .pipe(map((x) => this.commonService.createObjects(ExternalEnrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createMemberEthnicities(memberId: string, ethnicities: string[]): Observable<MemberEthnicity> {
    return this.http.post(`${env.apiUrl}/member/${memberId}/ethnicity`, ethnicities)
      .pipe(res => res)
      .pipe(catchError(this.commonService.handleError));
  }

  searchExistingSubscriberRecords(subscriberTransferSearch: SubscriberTransferSearch): Observable<SubscriberTransfer[]> {
    return this.http
    .post<Member>(`${env.apiUrl}/selfpay/search`, subscriberTransferSearch)
    .pipe(map((x) => this.commonService.createObjects(SubscriberTransfer, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  setMemberFlag(memberId: string, flagTypeCode: string, flagged: boolean, effectiveDate?: Date): Observable<MemberFlag> {
    const dateAppend = effectiveDate ? `/${dayjs(effectiveDate).format('YYYYMMDD')}` : '';

    return this.http.put(`${env.apiUrl}/member/${memberId}/flag/${encodeURIComponent(flagTypeCode)}/${flagged}${dateAppend}`, null)
      .pipe(res => res)
      .pipe(catchError(this.commonService.handleError));
  }

  setMemberFlagWithObject(memberId: string, flagTypeCode: string, flagged: boolean, flag: MemberFlag): Observable<MemberFlag> {
    return this.http.put(`${env.apiUrl}/member/${memberId}/flag/${encodeURIComponent(flagTypeCode)}/${flagged}/dto`, flag)
      .pipe(res => res)
      .pipe(catchError(this.commonService.handleError));
  }
}
