import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import SelfPay from 'src/app/models/selfPay';
import { Event } from '@angular/router';
import { filter, find, get } from 'lodash';
import SelfPayType from 'src/app/models/selfPayType';
import SelfPayVerificationStatus from 'src/app/models/selfPayVerificationStatus';
import Subscriber from 'src/app/models/subscriber';
import SelfPayForm from 'src/app/models/selfPayForm';
import { env } from 'src/env/development';

@Component({
  selector: 'self-pay-add',
  templateUrl: 'selfPay.add.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayAddComponent implements OnInit {
  @Input() selfPayTypes: SelfPayType[];
  @Input() selfPayVerificationStatus: SelfPayVerificationStatus[];
  @Input() canAdd = true;
  @Input() isSOE = false;
  @Input() subscriber: Subscriber;
  @Input() currentOrganizationAgencyCode: string;
  @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @ViewChild('selfPayAddForm') public memberForm: NgForm;
  selfPay: SelfPay;
  icons = {
      faCalendar
  };

  ngOnInit(): void {
      this.filterSPTypes();
      this.selfPay = new SelfPay();
      this.selfPay.selfPayVerificationStatusId =
        get(find(this.selfPayVerificationStatus, (vs: SelfPayVerificationStatus) => vs.selfPayVerificationStatusName === 'Draft'), 'selfPayVerificationStatusId');
  }


  emitSelfPay(e): void {
    this.saveSelfPay.emit(e);
  }

  filterSPTypes(): void {
    this.selfPayTypes = this.selfPayTypes.filter(o => this.isSOE === env.soeSelfPayTypeCodes.includes(o.selfPayTypeCode) || this.isSOE === env.oeSelfpayTypeCodes.includes(o.selfPayTypeCode));
  }

  resetSPTypes(spTypes): void {
    this.selfPayTypes = spTypes;
    this.filterSPTypes();
  }
}
