import { DualEnrollmentWizardComponent } from './components/dualEnrollment/wiz.dualEnrollment.component';
import { ReattestBannerComponent } from './components/reattestBanner/reattest.banner.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { EnrollmentPeriodsComponent } from './components/enrollmentPeriods/enrollmentPeriods.component';

import { SummaryCoverageComponent } from './components/summary/components/coverage/summary.coverage.component';
import { SummaryOverviewComponent } from './components/summary/components/overview/summary.overview.component';
import { SubscriberSummaryComponent } from './components/summary/subscriber.summary.component';
// ng
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// ext
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SubscriberDashboardComponent } from './subscriber.component';

// local

@NgModule({
  declarations: [
    SubscriberDashboardComponent,
    SubscriberSummaryComponent,
    SummaryCoverageComponent,
    SummaryOverviewComponent,
    EnrollmentPeriodsComponent,
    WizardComponent,
    ReattestBannerComponent,
    DualEnrollmentWizardComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    LayoutModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    RouterModule,
    NgbModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    PDFExportModule,
    ProgressBarModule,
    UploadModule,
    OverlayModule,
    FontAwesomeModule

  ],
  exports: [WizardComponent, DualEnrollmentWizardComponent],
  providers: [],
  bootstrap: [],
})
export class SubscriberModule {}
