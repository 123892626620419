
export default class LoginHistory {
  loginHistoryId: string;
  systemUserId: string;
  loginDate: Date;

  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;

  // Used to communicate data for 834 transmission only (given order of operations for DB save)
  divorceWithin60Days: boolean = null;
  terminationReasonId: string = null;

  constructor(instance?) {
    if (instance) {
      for (const k in instance) {
        if (k.endsWith('Date')) {
          this[k] = new Date(instance[k]);
        } else {
          this[k] = instance[k];
        }
      }
      // this.loginHistoryId = instance.loginHistoryId;

      // this.createdDate = instance.createdDate || null;
      // this.createdById = instance.createdById || null;
      // this.modifiedDate = instance.modifiedDate || null;
      // this.modifiedById = instance.modifiedById || null;
    }
  }
}
