import Member from './member';
import TransactionSetType from './transactionSetType';

export default class Pay1SyncHoldErrorOverview {
  memberId: string;
  lastName: string;
  firstName: string;
  errorDate: Date;
  program: string;
  transactionType: string;
  agency: string;

  constructor(source?) {
    if (source) {
      this.memberId = source.memberId;
      this.lastName = source.lastName;
      this.firstName = source.firstName;
      this.errorDate = new Date(source.errorDate);
      this.program = source.program;
      this.transactionType = source.transactionType;
      this.agency = source.agency;
    }
  }
}
