import { Component, ViewEncapsulation, OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { env } from 'src/env/development';

@Component({
  selector: 'pdf',
  templateUrl: 'pdf.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class PDFComponent implements OnInit {
  @ViewChild('pdfElement') pdfElement: PDFExportComponent;
  healthCareAuthorityImage = '../assets/benefits247_logo.png';
  currentDate = new Date();
  @Input() documentTitleText = '';
  @Input() documentHeaderText = '';
  @Input() enrolledHeader = '';
  @Input() documentFooterText = '';
  @Input() visible = false;
  @Input() margin = '2cm';
  @Input() showHeader = true;
  @Input() showFooter = true;
  @Input() paperSize = 'Letter';
  @Input() landscape = false;
  @Input() containerClass: string = "";

  ngOnInit(): void{
  }

  style(visible: boolean): any {
    if (!visible) {
      return {
        position: 'absolute',
        left: '-4000px',
        top: '-4000px'
      };
    }
    return {};
  }
}
