import { Component } from '@angular/core';

@Component({
  selector: 'notManagedInSystem',
  templateUrl: 'notManagedInSystem.component.html',
  styleUrls: [],
  providers: []
})
export class NotManagedInSystemComponent {
  constructor() {}

}
