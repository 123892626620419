<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <div class="state">
      <div class="card">
        <div class="card-body page-container" #formContainer>
          <h1>Reports Tool</h1>
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs hca-tab-nav nav-tab-pills">
            <li [ngbNavItem]="0" [disabled]="activeTab !== 0" [ngClass]="{'hca-background-light-blue': activeTab === 0}">
              <a ngbNavLink aria-controls="search" aria-selected="true"><b>Search</b></a>
              <ng-template ngbNavContent>
                <report-search 
                  #reportSearch
                  [showParameters]="showParameters" 
                  [availableReports]="availableReports" 
                  [(selectedReport)]="selectedReport"
                  [(reportParameters)]="reportParameters"
                  [reportTypes]="reportTypes"
                  [lookups]="lookups" 
                  [reportFilters]="reportFilters"
                  [showAgencyPicker]="isHCA"
                  [selfPayOnly]="selfPayOnly"
                  [currentAgency]="initialAgencyId"
                  (updateReportSelected)="updateSelectedReport($event)"
                  (agencySelected)="agencySelected($event)"
                ></report-search>
              </ng-template>
            </li>
            <li [ngbNavItem]="1" [disabled]="activeTab !== 1" [ngClass]="{'hca-background-light-blue': activeTab === 1}">
              <a ngbNavLink aria-controls="display" aria-selected="true"><b>Display</b></a>
              <ng-template ngbNavContent>
                <report-display
                  [reportColumnsToInclude]="reportColumnsToInclude"
                  [gridData]="gridData"
                  [lookups]="lookups"
                  [activeTab]="activeTab"
                  (autoMoveToNext)="moveToNextTab(formContainer)"
                ></report-display>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" [disabled]="activeTab !== 2" [ngClass]="{'hca-background-light-blue': activeTab === 2}">
              <a ngbNavLink aria-controls="results" aria-selected="true"><b>Results</b></a>
              <ng-template ngbNavContent>
                <report-results
                  [selectedReport]="selectedReport"
                  [gridColumns]="gridColumns"
                  [gridData]="gridData"
                ></report-results>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
          <div fxLayout="row" style="margin-left:20px;" class="fxGap-medium-lg mgtp-medium">
            <button class="btn btn-primary" *ngIf="activeTab !== 0" (click)="moveToPreviousTab()">Previous</button>
            <button class="btn btn-primary" [disabled]="nextButtonDisabled(activeTab, reportColumnsToInclude)" (click)="moveToNextTab(formContainer)">{{ activeTab === 2 ? 'Back to Search' : 'Next'}}</button>
          </div>
        </div>
      </div>
    </div>
</div>