<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <form #districtForm="ngForm">
      <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
        <div class="form-group" fxFlex="40">
          <div class="mgbt-medium" style="font-weight: bold;">
            <checkbox name="disallowLoginInd" [disabled]="!isHCAAdmin || organization?.organizationTypeId === sebbOrgTypeId || organization?.organizationTypeId === sebbOrgEGTypeId" 
              [(ngModel)]="organization.disallowLoginInd">
              Subscribers are unable to make elections within My Account
            </checkbox>
          </div>
          <div class="mgbt-medium" style="font-weight: bold;">
            <checkbox name="localEligibileBenefits" [disabled]="!isHCAAdmin || organization?.organizationTypeId !== sebbOrgTypeId"
              [(ngModel)]="organization.localEligibleBenefits">
              Organization provides Locally Eligible benefits
            </checkbox>
          </div>
          <div class="mgbt-medium" style="font-weight: bold;">
            <checkbox name="disableOptionalLTD" [disabled]="!isHCAAdmin || organization.disallowLoginInd"
              [(ngModel)]="organization.disableOptionalLTD">
              Employee cannot make optional LTD elections in my account
            </checkbox>
          </div> 
          <div class="mgbt-medium" style="font-weight: bold;" *ngIf="isOrgTypeToDisableEditFor">
            <checkbox name="demographicUpdatesAllowed" [disabled]="!isHCAAdmin"
              [(ngModel)]="organization.demographicUpdatesAllowed">
              Demographic updates allowed
            </checkbox>
          </div>
        </div>
        <div class="form-group" fxFlex="25">
          <label for="federalTaxId">Federal tax ID number</label>
          <input maxlength="20" [disabled]="!isHCAAdmin" pattern="[a-zA-Z0-9 -]*" required type="text" class="form-control" 
            id="federalTaxId" name="federalTaxId" [(ngModel)]="organization.federalTaxIdNumber" />
        </div>
        <div class="form-group required" fxFlex="25">
          <label for="hcaCode">HCA code</label>
          <input 
            maxlength="7" 
            [disabled]="!isHCAAdmin" 
            pattern="[a-zA-Z0-9 -]*" 
            required 
            type="text" 
            class="form-control" 
            name="hcaCode" 
            id="hcaCode" 
            [(ngModel)]="organization.organizationCode"/>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
        <div class="form-group" fxFlex="100">
          <label for="organizationName">Organization name</label>
          <input [disabled]="!isHCAAdmin" required type="text" pattern="[a-zA-Z0-9 \-#.\(\),']*" class="form-control" 
            name="organizationName" id="organizationName" [(ngModel)]="organization.organizationName" />
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column">
        <div class="form-group" fxFlex="30">
          <date-picker
            #effectiveDate="ngModel"
            name="effectiveDate"
            label="Effective date"
            required
            [disabled]="!!organization.organizationId && !isHCASystemAdmin"
            [(ngModel)]="organization.effectiveDate"></date-picker>
        </div>
        <div class="form-group" fxFlex="30">
          <date-picker
            #terminationDate="ngModel"
            name="terminationDate"
            label="Termination date"
            [disabled]="!isHCASystemAdmin"
            [(ngModel)]="organization.terminationDate"></date-picker>
        </div>
        <div class="form-group required" fxFlex="30" *ngIf="organization?.organizationTypeId !== sebbOrgTypeId">
          <label for="benefitSetupId">Benefit setup</label>
          <select required 
            #benefitSetupId="ngModel"
            class="form-control" 
            name="benefitSetupId"
            id="benefitSetupId"
            [(ngModel)]="organization.benefitSetupId"
            [disabled]="organization.organizationId && !isHCASystemAdmin"
          >
            <option></option>
            <option *ngFor="let benefitSetup of lookups.benefitSetups" [ngValue]="benefitSetup.benefitSetupId">{{ benefitSetup.benefitSetupName }}</option>
          </select>
        </div>
        <div class="form-group required" fxFlex="30" *ngIf="organization?.organizationTypeId === sebbOrgTypeId || organization.organizationTypeId === sebbOrgEGTypeId">
          <label for="ospiCode">OSPI code</label>
          <input 
            maxlength="6" 
            [disabled]="!isHCAAdmin" 
            required 
            type="text" 
            class="form-control" 
            name="ospiCode" 
            id="ospiCode" 
            [(ngModel)]="organization.ospiDistrictNumber"/>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" *ngIf="organization?.organizationTypeId !== sebbOrgTypeId">
        <div class="form-group" fxFlex="30">
          <label for="contractNumber">Contract number</label>
          <input [disabled]="!isHCAAdmin" pattern="[a-zA-Z0-9 -]*" type="text" class="form-control" 
            id="contractNumber" name="contractNumber" [(ngModel)]="organization.contractNumber" />
        </div>
        <div class="form-group required" fxFlex="30" *ngIf="organization?.organizationTypeId !== sebbOrgEGTypeId">
          <label for="rateStructureId">Rate structure</label>
          <select required 
            #rateStructureId="ngModel"
            class="form-control" 
            name="rateStructureId"
            id="rateStructureId"
            [(ngModel)]="organization.rateStructureId"
            [disabled]="organization.organizationId && !isHCASystemAdmin"
          >
            <option></option>
            <option *ngFor="let rateStructure of lookups.rateStructures" [ngValue]="rateStructure.rateStructureId">{{ rateStructure.rateStructureName }}</option>
          </select>
        </div>
        <div class="form-group required" fxFlex="30" *ngIf="organization?.organizationTypeId !== sebbOrgTypeId">
          <label for="rateSurchargeInd">Employer group rate surcharge</label>
          <select required 
            #benefitSetupId="ngModel"
            class="form-control" 
            name="rateSurchargeInd"
            id="rateSurchargeInd"
            [(ngModel)]="organization.rateSurchargeInd"
            [disabled]="organization.organizationId && !isHCASystemAdmin"
          >
            <option></option>
            <option *ngFor="let surcharge of [{v:'Yes', d:true}, {v:'No', d:false}]" [ngValue]="surcharge.d">{{ surcharge.v }}</option>
          </select>
        </div>
      </div>
      <div class="form-row" fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-md="column" *ngIf="organization?.organizationTypeId !== sebbOrgTypeId && organization?.organizationTypeId !== sebbOrgEGTypeId">
        <div class="form-group required" fxFlex="100">
          <label for="hrmsInd">State payroll system (HRMS)</label>
          <select required 
            #benefitSetupId="ngModel"
            class="form-control" 
            name="hrmsInd"
            id="hrmsInd"
            [(ngModel)]="organization.hrmsInd"
            [disabled]="!isHCAAdmin"
          >
            <option></option>
            <option *ngFor="let hrms of [{v:'Yes', d:true}, {v:'No', d:false}]" [ngValue]="hrms.d">{{ hrms.v }}</option>
          </select>
        </div>
      </div>
    </form>
</div>


