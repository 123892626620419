<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" *ngIf="subscriber">
      <div class="card-body">
        <h2 class="mobile-text-centered mgtp-x-small mgbt-medium-sm">Notes</h2>
        <div fxLayout="column" class="card-body dashboard-grid-parent" style="padding: 20px">
          <generic-grid
            [selectBy]="'memberId'"
            [selectable]="false"
            [selectedKeys]=""
            [pageable]="true"
            [pageSize]="20"
            [gridData]="gridData"
            [gridColumns]="gridColumns"
            [filterable]="true"
          ></generic-grid>
          <div *ngIf="canEdit" fxFlex="row" fxFlexFill fxLayoutAlign="start" style="padding-bottom: 20px; padding-top: 10px">
            <button style="padding-left: 20px; padding-right: 20px" (click)="addingNote = true" class="btn btn-primary">Add Note</button>
          </div>
        </div>
      </div>
      <form (ngSubmit)="saveNote()" #addNoteForm="ngForm" *ngIf="addingNote">
        <div fxLayout="column">
          <h3 style="margin-left: 20px" id="NoteLbl">Note:</h3>
          <div class="from-row" fxFlexFill style="padding: 20px 10px 20px 0px">
            <div style="margin-left: 20px" class="form-group" fxLayout="column" >
              <textarea style="height: 400px;" resizable="false" #note="ngModel" 
                aria-labelledby="NoteLbl"
                [required]="true" fxFlex="80" fxFlex.lt-md="100" class="form-control" 
                maxlength="250" name="noteText" [(ngModel)]="newNote.noteText">
              </textarea>
              <div class="form-text" style="text-align: right">
                <b>Characters remaining {{ note.value ? note.value.length : 0 }}/250</b>
              </div>
            </div>
          </div>
          <div fxFlex="row" fxFlexFill fxLayoutAlign="space-around" style="padding-bottom: 20px">
            <button style="padding-left: 20px; padding-right: 20px" (click)="cancelNote()" class="btn btn-secondary">Cancel</button>
            <button style="padding-left: 20px; padding-right: 20px" type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
