import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);
import { map, find } from 'lodash';
import Salary from './salary';
import Organization from './organization';
import EmployeeRepresentedStatus from './employeeRepresentedStatus';

export default class Employment {
  employmentId: string;
  memberId: string;
  organizationId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  over630HoursWorkedInd: boolean;
  salaries: Salary[];
  currentSalary: Salary;
  employeeRepresentedStatuses: EmployeeRepresentedStatus[];
  employeeRepresentedStatus: EmployeeRepresentedStatus;
  organization: Organization;
  transferInd: boolean;

  constructor(employment?) {
    if (employment) {
      this.employmentId = employment.employmentId;
      this.memberId = employment.memberId;
      this.organizationId = employment.organizationId;
      this.effectiveStartDate = employment.effectiveStartDate ? new Date (employment.effectiveStartDate) : null;
      this.effectiveEndDate = employment.effectiveEndDate ? new Date (employment.effectiveEndDate) : null;
      this.over630HoursWorkedInd = employment.over630HoursWorkedInd;
      this.salaries = map(employment.salaries, (s) => new Salary(s));
      this.organization = employment.organization ? new Organization(employment.organization) : null;
      this.transferInd = employment.transferInd;
      this.employeeRepresentedStatuses = map(employment.employeeRepresentedStatuses, (er) => new EmployeeRepresentedStatus(er));
    }
    this.employeeRepresentedStatus = this.getMostRecentEmployeeRepresentedStatus();
    this.currentSalary = this.getMostRecentSalaryOrCreateNew();
  }

  getMostRecentSalaryOrCreateNew(): Salary {
    const existingRec = find(this.salaries, salary => {
      return !salary.effectiveEndDate;
    });
    if (existingRec === undefined) {
      const newRec = new Salary();
      return newRec;
    } else {
      return existingRec;
    }
  }

  getMostRecentEmployeeRepresentedStatus(): EmployeeRepresentedStatus {
    const existingRec = find(this.employeeRepresentedStatuses, erep => {
      return (
        dayjs().isBetween(erep.effectiveStartDate, erep.effectiveEndDate || dayjs(), 'day', '[]')
      );
    });
    if (existingRec === undefined) {
      const newRec = new EmployeeRepresentedStatus();
      return newRec;
    } else {
      return existingRec;
    }
  }
}
