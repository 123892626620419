import Subscriber  from 'src/app/models/subscriber';
/**
 * Responsible for the global selection of a Organization for perspay users with multiple
 * associated subagencies. Organization is set via coreService. This value is bubbled
 * down to subscribed components.
 */

// ng
import { Component, OnInit, OnDestroy } from '@angular/core';

// ext
import { sortBy, remove, uniqBy, filter, map, get, find } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import Organization from 'src/app/models/organization';
import SystemUser from 'src/app/models/user';
import UserOrganizationRole from 'src/app/models/userOrganizationRole';
import { CoreService } from 'src/app/services/core.service';
import { Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import Member from 'src/app/models/member';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'agency-select',
  templateUrl: 'agencySelect.component.html',
  styleUrls: [],
})
export class AgencySelectComponent implements OnInit {
  systemUser: SystemUser;
  assignedOrgs: Organization[];
  currentOrganization: Organization;
  isMultiEligibleMember = false;
  constructor(private coreService: CoreService, private router: Router ) {}

  ngOnInit(): void {
    this.coreService.getSystemUser().pipe(untilDestroyed(this)).subscribe((systemUser: SystemUser) => {
      if (systemUser.systemUserId && (!this.systemUser || this.systemUser.members!=systemUser.members)) {
        this.systemUser = systemUser;
        this.assignedOrgs = remove(
          uniqBy(
            filter(this.systemUser.userOrganizationRoles, (r: UserOrganizationRole) => r.isActive),
            'organizationName'
          ),
          (org: Organization) => org.organizationId !== undefined
        );
        // not perspay w/ multiple accesses, try and map subscriber w/ multiple
        if(!this.assignedOrgs || !this.assignedOrgs.length) {
          this.assignedOrgs = map(this.systemUser.members, (m: Subscriber) => m.organization);
          this.isMultiEligibleMember = true;
        }
        //this.assignedOrgs = sortBy(this.assignedOrgs, (o: Organization) => o.organizationName)
        if (this.currentOrganization) {
          this.updateOrganization(this.currentOrganization);
        } else {
          this.currentOrganization = this.assignedOrgs[0];
          this.updateOrganization(this.currentOrganization);
        }
      }
      return this.coreService.getOrganization();
    });
  }

  roleHasRequiredData(usr: UserOrganizationRole): boolean {
    return usr.organizationId && usr.organization.organizationAddresses && !!usr.organization.organizationAddresses.length;
  }

  updateOrganization(s: Organization): void {
    if (s) {
      this.coreService.setOrganization(s);
    }
    if(this.isMultiEligibleMember) {
      const associatedMemberId = get(find(this.systemUser.members, (sub: Subscriber) => sub.organization.organizationId === s.organizationId), 'memberId');
      if (associatedMemberId) {
        this.router.navigate([`/subscriber/${associatedMemberId}`]);
      }
    }
  }

  get moreThanOneOrganization(): boolean {
    return this.assignedOrgs.length > 1;
  }
}
