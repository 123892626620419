/*
* Mounts when no component is found in router module for a given route.
*/


import { Component } from '@angular/core';

@Component({
  selector: 'page-not-found',
  templateUrl: 'pageNotFound.component.html',
  styleUrls: [],
  providers: []
})

export class PageNotFoundComponent {}
