import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import PlanType from './planType';
import Agency from './agency';
dayjs.extend(isBetween);

export default class ExternalEnrollment {
  externalEnrollmentId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  planTypeId: string;
  planType: PlanType;
  isSubscriberInd: boolean;
  isCurrentlyActive: boolean;
  agency: Agency;
  constructor(externalEnrollment?) {
    if (externalEnrollment) {
      this.externalEnrollmentId = externalEnrollment.externalEnrollmentId;
      this.effectiveStartDate = externalEnrollment.effectiveStartDate ? new Date(externalEnrollment.effectiveStartDate) : null;
      this.effectiveEndDate = externalEnrollment.effectiveEndDate ? new Date(externalEnrollment.effectiveEndDate) : null;
      this.planTypeId = externalEnrollment.planTypeId;
      this.planType = externalEnrollment.planType ? new PlanType(externalEnrollment.planType) : null;
      this.isSubscriberInd = externalEnrollment.isSubscriberInd;
      this.agency = externalEnrollment.agency ? new Agency(externalEnrollment.agency) : null;
      this.isCurrentlyActive = dayjs().isBetween(dayjs(this.effectiveStartDate).startOf('day'), dayjs(this.effectiveEndDate).endOf('day'));
    }
  }

  // Dual enrollment general methods (not specific to sub or dep)
  public externalEnrollmentConflictsForDate(d: Date): boolean {
    return (
      !this.effectiveEndDate ||
      dayjs(d).isBetween(dayjs(this.effectiveStartDate).startOf('day'), dayjs(this.effectiveEndDate).endOf('day'))
    );
  }

  public representsExternalEnrollmentForAgency(d: Date, agency: string): boolean {
    return (
      this.externalEnrollmentConflictsForDate(d) &&
      this.agency?.agencyCode === agency
    );
  }

  public representsExternalEnrollmentForAgencyAndType(d: Date, agency: string, planTypeCode: string): boolean {
    return (
      this.representsExternalEnrollmentForAgency(d, agency) &&
      this.planType.planTypeCode === planTypeCode
    );
  }

  // Subscriber methods
  public representsExternalEnrollmentAsSubscriberAndForAnyAgency(d: Date): boolean {
    return (
      this.externalEnrollmentConflictsForDate(d) &&
      this.isSubscriberInd
    );
  }

  public representsExternalEnrollmentAsSubscriberAndForAgency(d: Date, agency: string): boolean {
    return (
      this.representsExternalEnrollmentForAgency(d, agency) &&
      this.isSubscriberInd
    );
  }

  public representsExternalEnrollmentAsSubscriberForTypeAndForAgency(d: Date, agency: string, planTypeCode: string): boolean {
    return (
      this.representsExternalEnrollmentForAgencyAndType(d, agency, planTypeCode) &&
      this.isSubscriberInd
    );
  }

  // Dependent methods
  public representsExternalEnrollmentAsDependentAndForAnyAgency(d: Date): boolean {
    return (
      this.externalEnrollmentConflictsForDate(d) &&
      !this.isSubscriberInd
    );
  }

  public representsExternalEnrollmentAsDependentAndForAgency(d: Date, agency: string): boolean {
    return (
      this.representsExternalEnrollmentForAgency(d, agency) &&
      !this.isSubscriberInd
    );
  }

  public representsExternalEnrollmentAsDependentForTypeAndForAgency(d: Date, agency: string, planTypeCode: string): boolean {
    return (
      this.representsExternalEnrollmentForAgencyAndType(d, agency, planTypeCode) &&
      !this.isSubscriberInd
    );
  }
}
