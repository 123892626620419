import { DatePickerComponent } from 'src/app/modules/shared/components/datePicker/datePicker.component';
import { CoreService } from './../../../../../../../../services/core.service';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import SubscriberTransferSearch from 'src/app/models/subscriberTransferSearch';
import * as dayjs from 'dayjs';
import { lastValueFrom } from 'rxjs';
import { env } from 'src/env/development';
import { LowerLimitService } from 'src/app/services/lowerLimit.service';

@Component({
  selector: 'subscriber-management-add-search',
  templateUrl: 'subscriberManagement.add.search.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class SubscriberManagementAddSearchComponent implements OnInit {
  subscriberTransferSearch = new SubscriberTransferSearch();
  @Output() checkForExistingRecord: EventEmitter<SubscriberTransferSearch> = new EventEmitter<SubscriberTransferSearch>();
  @Output() searchForExistingMemberForSelfPay: EventEmitter<SubscriberTransferSearch> = new EventEmitter<SubscriberTransferSearch>();
  @Output() cancelAdd: EventEmitter<void> = new EventEmitter<void>();
  @Input() isHCAAdmin?: boolean;
  @Input() isSEBBOrg = false;
  @Input() agencyCode: string;
  @Input() isSelfPayAdd = false;
  @ViewChild('subscriberExistingSearch') public subscriberExistingSearchForm: NgForm;
  @ViewChild('eligibilityDatePicker') public eligibilityDatePicker: DatePickerComponent;
  icons = {
    faCalendar
  };
  env = env;
  dayjs = dayjs;
  showFirstDayOfSchoolCheckbox = false;

  constructor(
    private notificationService: NotificationService,
    private coreService: CoreService,
    private lowerLimitService: LowerLimitService
  ) {}

  ngOnInit(): void {

  }

  pushCancelAdd(): void {
    this.cancelAdd.emit();
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.subscriberExistingSearchForm);
  }

  async pushCheckForExistingRecord(formContainer): Promise<void> {
  if (this.isSEBBOrg) {
      const sebbFdsDateValid = await this.validateEligibilityDate(this.subscriberTransferSearch.eligibilityDate, this.subscriberTransferSearch.sebbIsNewlyEligibleBeforeFirstDayOfSchool);
      if (!sebbFdsDateValid) {
        this.coreService.popMessage('Please complete the required fields before continuing.', 'error', 3000, this.coreService.getInvalidFields(formContainer));
        return;
      }
    }

    this.markAllControlsAsTouched();
    if (this.eligibilityDatePicker.value < this.eligibilityDatePicker.picker.min) {
      this.coreService.popMessage('Please complete the required fields before continuing.', 'error', 3000, this.coreService.getInvalidFields(formContainer));
      return;
    }
    if (this.subscriberExistingSearchForm.valid) {
      if (this.isSelfPayAdd) {
        this.searchForExistingMemberForSelfPay.emit(this.subscriberTransferSearch);
      } else {
        this.checkForExistingRecord.emit(this.subscriberTransferSearch);
      }
    } else {
      this.coreService.popMessage('Please complete the required fields before continuing.', 'error', 3000, this.coreService.getInvalidFields(formContainer));

    }
  }

  async determineFirstDayVisibility(eligibilityDate: Date): Promise<void> {
    if (!this.isSelfPayAdd && this.isSEBBOrg && eligibilityDate) {
      this.showFirstDayOfSchoolCheckbox = eligibilityDate && dayjs(eligibilityDate).month() === 8; // Is in September
    } else {
      // Never show for PEBB or Self Pay
      this.showFirstDayOfSchoolCheckbox = false;
    }

    if (!this.showFirstDayOfSchoolCheckbox) {
      const eligibilityDateControl = this.subscriberExistingSearchForm.controls.eligibilityDate;
      this.removeMinDateError(eligibilityDateControl);
    }
  }

  removeMinDateError(eligibilityDateControl?: AbstractControl): void {
    eligibilityDateControl = eligibilityDateControl ?? this.subscriberExistingSearchForm.controls.eligibilityDate;
    const err = eligibilityDateControl.errors ?? {};

    if (err.ngbDate?.minDate) {
      delete err.ngbDate;
      if (!Object.keys(err).length) { // if no errors left
        eligibilityDateControl.setErrors(null); // set control errors to null making it VALID
      } else {
        eligibilityDateControl.setErrors(err); // controls got other errors so set them back
      }
    }
  }

  async validateEligibilityDate(eligibilityDate: Date, sebbIsNewlyEligibleBeforeFirstDayOfSchool: boolean): Promise<boolean> {
    const eligibilityDateControl = this.subscriberExistingSearchForm.controls.eligibilityDate;

    if (!this.isSelfPayAdd) {
      const isHcaAdmin = this.isHCAAdmin;
      const showFirstDayOfSchoolCheckbox = eligibilityDate && dayjs(eligibilityDate).month() === 8; // Is in September

      // If this is a SEBB org adding a date in September, see if the first day of school checkbox is checked (only applies to BAs)
      if (this.isSEBBOrg && eligibilityDate && !isHcaAdmin && showFirstDayOfSchoolCheckbox && sebbIsNewlyEligibleBeforeFirstDayOfSchool) {
        const withinLowerLimit = 
          await lastValueFrom(this.lowerLimitService.coverageEffectiveDateWithinLowerLimit(eligibilityDate, env.sebbCode, true));

        if (!withinLowerLimit) {
          eligibilityDateControl.setErrors({ ngbDate: { minDate: true }});
          return false;
        }
    
        this.removeMinDateError(eligibilityDateControl);
      }

      return true;
    }

    const err = eligibilityDateControl.errors ?? {};

    if (eligibilityDate) {
      const baseDateRegex = /^\d{1,2}\/\d{1,2}\/(?:\d{2}|\d{4})$/;
      const firstOnlyDateRegex = /^\d{1,2}\/(?:1|01)\/\d{4}$/;
      const formattedDate = dayjs(eligibilityDate).format('MM/DD/YYYY');

      if (formattedDate.match(baseDateRegex) && !formattedDate.match(firstOnlyDateRegex)) {
        if (!err.notFirstOfMonth) {
          eligibilityDateControl.setErrors({notFirstOfMonth: true});
        }

        return;
      }

      if (err.notFirstOfMonth) {
        delete err.notFirstOfMonth;
        if (!Object.keys(err).length) { // if no errors left
          eligibilityDateControl.setErrors(null); // set control errors to null making it VALID
        } else {
          eligibilityDateControl.setErrors(err); // controls got other errors so set them back
        }
      }
    }
  }
}
