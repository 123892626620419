// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';

// ext
import { Subscription } from 'rxjs';

// local
import { env } from 'src/env/development';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import { faTimes, faEquals, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { find, cloneDeep } from 'lodash';
import Plan from 'src/app/models/plan';
import { NgForm } from '@angular/forms';
import * as dayjs from 'dayjs';
import Enrollment from 'src/app/models/enrollment';

@Component({
  selector: 'supplemental-calc',
  templateUrl: 'supplementalCalculator.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class SupplementalCalculatorComponent implements OnInit, OnDestroy {
  icons = {
    faTimes,
    faEquals,
    faCalendar
  };
  @Input() isEnrolled = false;
  @Input() isSEBB = false;
  @Input() supplementalPlans;
  @Input() isActiveEmployeeType = false;
  @Input() subscriber: Subscriber;
  @Output() electSupplemental: EventEmitter<any> = new EventEmitter();
  @Input() selectedPlan: Plan;
  @Input() isAdmin = false;
  @Input() inManagementState = false;
  @Input() initialSelectedPlanId: string;
  @Input() selectedSupplementalEnrollment: Enrollment;
  @Input() inWizard = false;
  @ViewChild('calcForm') calcForm: NgForm;
  selectedPlanId: string;
  sltdSixtyPlan: Plan;
  sltdFiftyPlan: Plan;
  sltdWaivePlan: Plan;
  basicLTDPlan: Plan;
  minDateOverride: Date;
  coverageEffectiveDate: Date;

  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
    this.selectedPlanId = this.selectedPlan?.planId || null;
    this.initialSelectedPlanId = this.selectedPlan?.planId;
    this.sltdSixtyPlan = find(this.supplementalPlans, (p) => p.planCode === 'EE60');
    this.sltdFiftyPlan = find(this.supplementalPlans, (p) => p.planCode === 'EE50');
    this.sltdWaivePlan = find(this.supplementalPlans, (p) => p.planCode === 'EEW');
  }

  updatePlan(planId: string): void {
    this.selectedPlanId = planId;
    this.selectedPlan = planId ? find(this.supplementalPlans, (p) => p.planId === planId) : null;
  }

  ngOnDestroy(): void {}

  disenroll(): void {
    this.selectedPlan = null;
    this.selectedPlanId = null;
    this.electSupplemental.emit(null);
  }

  enrollOrUpdate(planId: string, formContainer): void {
    this.coreService.markFormControlsAsTouched(this.calcForm);
    if (!this.calcForm?.valid) {
      this.coreService.popMessage('Fields missing or invalid', 'error', 3000, this.coreService.getInvalidFields(formContainer));
    }
    else {
      if (!this.inWizard && !this.isAdmin && (
        (this.initialSelectedPlanId !== this.sltdSixtyPlan.planId && this.selectedPlanId === this.sltdSixtyPlan.planId) ||
        (this.initialSelectedPlanId !== this.sltdFiftyPlan.planId && this.initialSelectedPlanId !== this.sltdSixtyPlan.planId && this.selectedPlanId === this.sltdFiftyPlan.planId)
      )) {
        this.coreService.popMessage('You must complete the Long Term Disability (LTD) Evidence of Insurability Form and submit it for approval.', 'error', 3000);
      } else {
        this.selectedPlan = planId ? find(this.supplementalPlans, (p) => p.planId === planId) : null;
        this.electSupplemental.emit({plan: this.selectedPlan, effectiveDate: this.coverageEffectiveDate});
        this.minDateOverride = cloneDeep(this.coverageEffectiveDate);
        this.initialSelectedPlanId = this.selectedPlan?.planId;
      }
    }
  }
}
