// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Action from 'src/app/models/action';

@Injectable({
  providedIn: 'root'
})

export class ActionService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createAction(action: Action): Observable<Action> {
    return this.http
      .post<Action>(`${env.apiUrl}/Member/${action.memberId}/actionLog`, action)
      .pipe(map(x => this.commonService.createObject(Action, x)))
      .pipe(catchError(this.commonService.handleError));
  }

}
