export default class TransactionSetType {
  transactionSetTypeId: string;
  transactionSetTypeCode: string;
  transactionSetTypeName: string;
  obsoleteDate: Date;
  obsoleteFlag: boolean;

  constructor(transactionSetType?) {
    if (transactionSetType) {
      this.transactionSetTypeId = transactionSetType.transactionSetTypeId;
      this.transactionSetTypeCode = transactionSetType.transactionSetTypeCode;
      this.transactionSetTypeName = transactionSetType.transactionSetTypeName;
      this.obsoleteDate = transactionSetType.obsoleteDate ? new Date(transactionSetType.obsoleteDate) : null;
      this.obsoleteFlag = transactionSetType.obsoleteFlag;
    }
  }

}
