import Ethnicity from 'src/app/models/ethnicity';
import { ViewChildren } from '@angular/core';
// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// ext
import { faUser, faMinus, faPlus, faUserCheck, faTimesCircle, faUserClock, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { find, isEmpty, sortBy, filter, pickBy, keys, cloneDeep, maxBy, forEach, map, indexOf, some, findIndex, get } from 'lodash';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
// local
import { env } from 'src/env/development';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService, AccessLevel, UserTypeCode } from 'src/app/services/core.service';
import { DependentService } from 'src/app/services/dependent.service';
import { RelationshipCertificationService } from 'src/app/services/relationshipCertification.service';
import { RelationshipService } from 'src/app/services/relationship.service';
import DependentComposite from 'src/app/models/dependentComposite';
import Member from 'src/app/models/member';
import Subscriber from 'src/app/models/subscriber';
import RelationshipCertification from 'src/app/models/relationshipCertification';
import Relationship from 'src/app/models/relationship';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { DependentDetailsComponent } from './components/details/dependent.details.component';
import MemberMedicare from 'src/app/models/memberMedicare';
import Reason from 'src/app/models/reason';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Enrollment from 'src/app/models/enrollment';
import { MedicareComponent } from 'src/app/modules/shared/components/medicare/medicare.component';

@UntilDestroy()
@Lookups(LookupType.AddressType, LookupType.County, LookupType.Country, LookupType.BirthSex, LookupType.GenderIdentity,
         LookupType.PreferredContactMethod, LookupType.Language, LookupType.RelationshipQualifyReason, LookupType.RelationshipType,
         LookupType.RelationshipVerificationStatus, LookupType.CertificationType, LookupType.RelationshipCertificationStatus,
         LookupType.Reason, LookupType.MedicareOption, LookupType.Ethnicity, LookupType.ApplicationSetting)
@Component({
  selector: 'dependent-management',
  templateUrl: 'dependentManagement.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentManagementComponent implements OnInit, OnDestroy {
  @ViewChild('medicareComponent') public medicareComponent: MedicareComponent;
  subscriber: Subscriber;
  dependents: DependentComposite[];
  isHCAWithEdit = false;
  isPerspayWithEdit = false;
  isReadOnly = true;
  isSubscriber = false;
  icons = {
    faUser,
    faMinus,
    faPlus,
    faUserCheck,
    faUserClock,
    faTimesCircle,
    faCalendar
  };
  lookups = {
    addressType: [],
    certificationTypes: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    preferredContactMethods: [],
    languages: [],
    relationshipQualifyReasons: [],
    relationshipTypes: [],
    relationshipVerificationStatus: [],
    relationshipCertificationStatus: [],
    terminationReasons: [],
    medicareOptions: [],
    applicationSetting: [],
  };
  ethnicities: Ethnicity[];
  displayRaceEthnicity = false;

  constructor(
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private dependentService: DependentService,
    private subscriberService: SubscriberService,
    private relationshipCertificationService: RelationshipCertificationService,
    private relationshipService: RelationshipService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.subscriber = data.subscriber;
      this.isPerspayWithEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay);
      this.isHCAWithEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      this.isSubscriber = this.coreService.systemUserIsSubscriber(this.subscriber.memberId);
      this.isReadOnly = !this.isPerspayWithEdit && !this.isHCAWithEdit && !this.isSubscriber;
      this.lookups.addressType = data.lookups.addressType;
      this.lookups.certificationTypes = data.lookups.certificationType;
      this.lookups.county = sortBy(data.lookups.county, 'countyName');
      this.lookups.country = sortBy(data.lookups.country, 'countryName');
      this.lookups.birthSexes = sortBy(data.lookups.birthSex, 'birthSexName');
      this.lookups.genderIdentities = sortBy(data.lookups.genderIdentity, 'genderIdentityName');
      this.lookups.preferredContactMethods = sortBy(data.lookups.preferredContactMethod, 'preferredContactMethodName');
      this.lookups.languages = sortBy(data.lookups.language, 'languageName');
      this.lookups.relationshipQualifyReasons = sortBy(data.lookups.relationshipQualifyReason, 'relationshipQualifyReasonName');
      this.lookups.relationshipTypes = sortBy(data.lookups.relationshipType, 'relationshipTypeName');
      this.lookups.relationshipVerificationStatus = sortBy(data.lookups.relationshipVerificationStatus, 'relationshipVerificationStatusName');
      this.lookups.relationshipCertificationStatus = sortBy(data.lookups.relationshipCertificationStatus, 'relationshipCertificationStatusName');
      this.lookups.medicareOptions = data.lookups.medicareOption;
      this.lookups.applicationSetting = data.lookups.applicationSetting;
      this.ethnicities = data.lookups.ethnicity;
      this.displayRaceEthnicity = this.subscriberOrDependentsAreMedicareEnrolled() && some(this.subscriber.enrollments, (e: Enrollment) => env.medicareAdvantagePlanCodes.includes(e.plan?.planCode)) && some(this.lookups?.applicationSetting, (as) => as.applicationSettingCode === 'MAPD' && as.response?.responseCode == 'Y');
      this.dependents = map(
        filter(this.subscriber.members, (m: Member) => !m.isSubscriberInd),
        (m: Member) => {
          const newDep = new DependentComposite(m);
          newDep.memberMedicare = newDep.memberMedicare || new MemberMedicare();
          return newDep;
        }
      );
    });
  }

  ngOnDestroy(): void {}

  async saveDependentChanges(index: number, dependent: DependentComposite, medicareFormContainer): Promise<void> {
    this.markAllControlsAsTouched(this.medicareComponent);
    this.markAllControlsAsTouched(medicareFormContainer);

    if (!this.isHCAWithEdit || medicareFormContainer.valid) {
      this.spinnerService.show();
      try {
        const dependentClone = cloneDeep(dependent);
        if (isEmpty(dependentClone.memberMedicare)) {
          dependentClone.memberMedicare = null;
        }
        await lastValueFrom(this.dependentService.updateDemographics(dependentClone));
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
        this.medicareComponent.refreshInitial();
        this.spinnerService.hide();
        this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
      } catch (err) {
        this.spinnerService.hide();
        this.coreService.popMessage('Something went wrong updating this dependent! Please try again', 'error', 5000);
        console.log(err);
      }
    } else {
      this.coreService.popMessage('Fields missing or invalid', 'error', 3000, this.coreService.getInvalidFields(medicareFormContainer));      
    }
  }

  markAllControlsAsTouched(selForm): void {
    this.coreService.markFormControlsAsTouched(selForm);
  }

  cancelDependentChanges(index): void {
    const originalDependent = cloneDeep(find(this.subscriber.members, (m: Member) => m.memberId === this.dependents[index].memberId));
    this.dependents[index] = new DependentComposite(originalDependent);
  }

  async saveCertification(index: number, certification: RelationshipCertification): Promise<void> {
    this.spinnerService.show();
    try {
      const newCertification = !certification.relationshipCertificationId
        ? await lastValueFrom(this.relationshipCertificationService.createRelationshipCertification(this.subscriber.memberId, certification))
        : await lastValueFrom(this.relationshipCertificationService.updateRelationshipCertification(this.subscriber.memberId, certification));
      if (certification.relationshipCertificationId) {
        const certificationIndex = findIndex(
        this.dependents[index].relationshipCertifications,
        (c) => c.relationshipCertificationId === certification.relationshipCertificationId
        );
        this.dependents[index].relationshipCertifications.splice(certificationIndex, 1, newCertification);
      } else {
        this.dependents[index].relationshipCertifications.push(newCertification);
      }

      this.dependents = cloneDeep(this.dependents);

      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong creating the certfications.', 'error', 5000);
    }
  }

  async deleteCertification(index: number, certification: RelationshipCertification): Promise<void> {
    this.spinnerService.show();
    try {
      await lastValueFrom(this.relationshipCertificationService.removeRelationshipCertification(this.subscriber.memberId, certification.relationshipId, certification.relationshipCertificationId));
      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong creating the certfications.', 'error', 5000);
    }
  }

  async saveCertificationStatus(index: number, relationship: Relationship): Promise<void> {
    this.spinnerService.show();
    try {
      const updatedRelationship = await lastValueFrom(this.relationshipService.updateRelationship(relationship));
      this.dependents[index].relationship = updatedRelationship;
      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong creating the certfications.', 'error', 5000);
    }

    this.spinnerService.hide();
  }

  async deleteDependent(dependent: DependentComposite): Promise<void> {
    this.spinnerService.show();

    try {
      await lastValueFrom(this.relationshipService.deleteRelationship(dependent.member.relationshipToSubscriber));

      const indexToRemove = findIndex(this.dependents, dep => dep.memberId === dependent.memberId);
      this.dependents.splice(indexToRemove, 1);

      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong deleting the dependent.', 'error', 5000);
    }
  }

  // set dependent enrollments' divorceWithin60Days indicator equal to the dependent's initial enrollment indicator
  setDivorceWithin60DaysEnrollments(dependentEnrollments, initialEnrollment): void {
    dependentEnrollments.forEach(de => {
      if (de.enrollmentId !== initialEnrollment.enrollmentId) {
        de.divorceWithin60Days = initialEnrollment.divorceWithin60Days;
      }
    });
  }

  clearDateIfRemovingA(e, dependent): void {
    if (dependent.originalMemberMedicare?.medicarePartAEnrolledInd && !e) {
        dependent.memberMedicare.medicarePartAEffectiveDate = null;
        if (dependent.memberMedicare.medicarePartBEnrolledInd) {
          dependent.memberMedicare.medicarePartBEnrolledInd = false;
          this.clearDateIfRemovingB(false, dependent);
        }
      }
  }

  clearDateIfRemovingB(e, dependent): void {
    if (dependent.originalMemberMedicare?.medicarePartBEnrolledInd && !e) {
        dependent.memberMedicare.medicarePartBEffectiveDate = null;
      }
  }

  isEnrolledInMedicareAndMAPlan() {
    return true;
  }

  subscriberOrDependentsAreMedicareEnrolled(): boolean {
    return this.subscriber?.memberMedicare?.medicarePartAEffectiveDate != null || some(this.subscriber.members, (d: Member) => d.memberMedicare?.medicarePartAEffectiveDate);
  }
}
