import { map } from 'lodash';
import DocumentType from './documentType';

export default class RelationshipCertificationStatus {
  relationshipCertificationStatusId: string;
  relationshipCertificationStatusCode: string;
  relationshipCertificationStatusName: string;
  approvedInd: boolean;
  deniedInd: boolean;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(relationshipCertificationStatus?) {
    if (relationshipCertificationStatus) {
      this.relationshipCertificationStatusId = relationshipCertificationStatus.relationshipCertificationStatusId;
      this.relationshipCertificationStatusCode = relationshipCertificationStatus.relationshipCertificationStatusCode;
      this.relationshipCertificationStatusName = relationshipCertificationStatus.relationshipCertificationStatusName;
      this.approvedInd = relationshipCertificationStatus.approvedInd;
      this.deniedInd = relationshipCertificationStatus.deniedInd;
      this.obsoleteFlag = relationshipCertificationStatus.obsoleteFlag;
      this.obsoleteDate = relationshipCertificationStatus.obsoleteDate ? new Date(relationshipCertificationStatus.obsoleteDate) : null;
    }
  }
}
