export default class SelfPayGrid {
  selfPayId: string;
  memberId: string;
  submittedDate: Date;
  coverageEffectiveDate: Date;
  subscriberName: string;
  simplifiedStatus: string;
  reviewDate: Date;
  selfPayProcessStatusName: string;
  hasDocuments: boolean;
  selfPayTypeName: string;
  program: string;
  assignedTo: string;
  assignedToId: string;
  assignedById: string;
  assignedDate: Date;
  constructor(selfPay?) {
    if (selfPay) {
      this.selfPayId = selfPay.selfPayId;
      this.memberId = selfPay.memberId;
      this.submittedDate = selfPay.submittedDate;
      this.coverageEffectiveDate = selfPay.coverageEffectiveDate;
      this.subscriberName = selfPay.subscriberName;
      this.simplifiedStatus = selfPay.simplifiedStatus;
      this.reviewDate = selfPay.reviewDate;
      this.selfPayProcessStatusName = selfPay.selfPayProcessStatusName;
      this.hasDocuments = selfPay.hasDocuments;
      this.selfPayTypeName = selfPay.selfPayTypeName;
      this.program = selfPay.program;
      this.assignedTo = selfPay.assignedTo;
      this.assignedToId = selfPay.assignedToId;
      this.assignedById = selfPay.assignedById;
      this.assignedDate = selfPay.assignedDate ? new Date(selfPay.assignedDate) : null;
    }
  }
}
