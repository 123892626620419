export default class TerminationComposite {
  memberId: string;
  lossOfEligibilityDate: Date;
  lossOfEligibilityReasonId: string;
  terminationDate: Date;
  eligibilityDate: Date;
  birthDate: Date;

  constructor(member?) {
    if (member) {
      this.birthDate = member.birthDate;
      this.memberId = member.memberId;
      this.lossOfEligibilityDate = member.lossOfEligibilityDate ? new Date(member.lossOfEligibilityDate) : null;
      this.lossOfEligibilityReasonId = member.lossOfEligibilityReasonId || null;
      this.terminationDate = member.terminationDate || member.agencyEffectiveEndDate ? new Date(member.terminationDate || member.agencyEffectiveEndDate) : null;
      this.eligibilityDate = member.eligibilityDate ? new Date(member.eligibilityDate) : null;
    }
  }
}
