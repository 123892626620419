import { Component, ViewEncapsulation, OnChanges, Input, Output, EventEmitter, ViewChild, ViewChildren, OnInit } from '@angular/core';
import { cloneDeep, sortBy, forEach, find, includes, some, get, filter, remove, reverse } from 'lodash';
import * as dayjs from 'dayjs';
import Enrollment from 'src/app/models/enrollment';
import MemberWaiver from 'src/app/models/memberWaiver';
import Plan from 'src/app/models/plan';
import { NgForm } from '@angular/forms';
import SubscriberAccountCorrection from 'src/app/models/subscriberAccountCorrection';
import OpenEnrollment from 'src/app/models/openEnrollment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { isBetween } from 'src/app/models/member';
import { CoreService } from 'src/app/services/core.service';
import { faMinus, faPlus, faTrashAlt, faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { env } from 'src/env/development';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';

@Component({
  selector: 'account-correction-edit-enrollment',
  templateUrl: 'edit.enrollment.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class AccountCorrectionEditEnrollmentComponent implements OnChanges, OnInit {
  @Input() memberId: string;
  @Input() subscriberId: string;
  @Input() isSubscriber = false;
  @Input() enrollments: Enrollment[];
  @Input() enrollmentPeriods: EnrollmentPeriod[];
  @Input() index: number;
  @Input() subscriber: SubscriberAccountCorrection;
  @Output() pushSaveEnrollments: EventEmitter<any> = new EventEmitter<any>();
  @Output() pushRemoveRecord: EventEmitter<any> = new EventEmitter<any>();
  @Input() lookups: {
    subscriberEnrollmentReasons: [],
    dependentEnrollmentReasons: [],
    subscriberTerminationReasons: [],
    dependentTermintationReasons: [],
    eligibilityReasons: [],
    agencies: []
  };
  @Input() availablePlans: {
    Health: [],
    Dental: [],
    Vision: []
  };
  @Input() supplementalPlan: Plan;
  @Input() lifePlan: Plan;
  @Input() basicPlan: Plan;
  @Input() adPlan: Plan;
  opened = true;
  memberEnrollments = {
    Medical: [],
    Dental: [],
    Vision: [],
    'Basic Life and AD&D': [],
    'AD&D': [],
    'Supplemental LTD': [],
    'Basic LTD': [],
    'Retiree Life': []
  };
  planTypes = ['Medical', 'Dental', 'Vision'];
  @Input() activePanels = [];
  @Input() editable: boolean;
  @Output() updatePanelsExpanded: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('editCoverageForm') public editCoverageForm: NgForm[];
  maximumEffectiveDate: NgbDate;
  icons = {
    faPlus,
    faMinus,
    faTrashAlt,
    faPlusCircle,
    faSave
  };
  env = env;

  constructor(private coreService: CoreService) {}

  ngOnInit() {
    const now = new Date();
    const firstOfNextMonth = dayjs().add(1, 'months').startOf('month');
    this.maximumEffectiveDate = new NgbDate(firstOfNextMonth.year(), firstOfNextMonth.month() + 1, firstOfNextMonth.day());
  
  }

  ngOnChanges() {
    if (this.isSubscriber && this.planTypes.length === 3) {
      this.planTypes.push('Basic LTD', 'Basic Life and AD&D', 'Supplemental LTD', 'Retiree Life');
    }
    forEach(this.enrollments, (e: Enrollment) => {
      const alreadyExists = find(this.memberEnrollments[e.plan.planType.planTypeName], (ep: Enrollment) => ep.enrollmentId === e.enrollmentId);
      if (!alreadyExists && includes(this.planTypes, e.plan.planType.planTypeName)) {
        this.memberEnrollments[e.plan.planType.planTypeName].push(e);
      }
    });
    this.planTypes.forEach(pt=> {
      this.memberEnrollments[pt] = sortBy(this.memberEnrollments[pt],'effectiveStartDate');
    });
    sortBy(this.memberEnrollments, 'effectiveStartDate');
  }

  addEnrollmentRec(planType) {
    const newEnrollment = new Enrollment();
    newEnrollment.memberId = this.memberId;
    newEnrollment.enrollmentId = 'NEW' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    newEnrollment.subscriberMemberId = this.subscriberId;
    newEnrollment.plan = get(find(this.subscriber.enrollments, (e: Enrollment) => {
      return e.plan && e.plan.planType && e.plan.planType.planTypeName === planType;
    }), 'Plan');
    if (planType === 'Life') {
      newEnrollment.planId = this.lifePlan.planId;
    } else if (planType === 'LTD') {
      newEnrollment.planId = this.supplementalPlan.planId;
    } else if (planType === 'BasicLTD') {
      newEnrollment.planId = this.basicPlan.planId;
    }
    else if (planType === 'AD&D')
    {
      newEnrollment.planId = this.adPlan.planId;
    }
    this.memberEnrollments[planType].push(newEnrollment);
    this.enrollments.push(newEnrollment);
  }

  saveChanges(planType) {
    for (const coverageForm of Array.from(this.editCoverageForm)) {
      for (const i in coverageForm.controls) {
        if (coverageForm.controls[i] && coverageForm.name === planType) {
          coverageForm.controls[i].markAsTouched();
        }
      }
    }
    if (some(Array.from(this.editCoverageForm), (f: NgForm) => f.invalid && f.name === planType)) {
      return this.coreService.popMessage('Fields missing or invalid - please try again.', 'error', 4000);
    }
    if (!this.subscriberHasActiveCoveragePeriodForTypeAndDate(this.memberEnrollments[planType])) {
      return this.coreService.popMessage(`Subscriber does not have a coverage period for the enrollment date(s) entered.
      Please update subscriber enrollment and try again.`, 'error', 4000);
    }
    if (this.memberHasOverlappingCoveragePeriod(this.memberEnrollments[planType])) {
      return this.coreService.popMessage('Member has overlapping coverage - please try again.', 'error', 4000);
    } else {
      this.pushSaveEnrollments.emit({planType: planType, enrollments: this.memberEnrollments[planType]});
    }
  }

  removeRecord(enrollment, planType, index) {
    // if (enrollment.enrollmentId && !enrollment.enrollmentId.includes('NEW') && filter(this.memberEnrollments[planType], e => (e.memberWaiverId) || (e.enrollmentId && !e.enrollmentId.includes('NEW'))).length === 1 && this.isSubscriber) {
    //     return this.coreService.popMessage('This member must have at least one enrollment record by type.', 'error', 4000);
    //   } 
    //else {
      if (enrollment.memberWaiverId || (enrollment.enrollmentId && !enrollment.enrollmentId.includes('NEW'))) {
        this.enrollments.splice(this.enrollments.indexOf(find(this.enrollments,en=>en.enrollmentId==enrollment.enrollmentId)),1);
        this.memberEnrollments[planType].splice(index, 1);
        //remove(this.enrollments,en=> en.enrollmentId == enrollment.enrollment_id);
      } 
      else {
        this.enrollments.splice(this.enrollments.indexOf(find(this.enrollments,en=>en.enrollmentId==enrollment.enrollmentId)),1);
        this.memberEnrollments[planType].splice(index, 1);
        //remove(this.enrollments,en=> en.enrollmentId == enrollment.enrollment_id);
      }
    //}
  }

  subscriberHasActiveCoveragePeriodForTypeAndDate(enrollments: any): Boolean {
    let activeEnrollmentForType;
    let activeMemberWaiverForType;
    if (this.isSubscriber) {
      return true;
    } else {
      enrollments.forEach(enrollment => {
        activeEnrollmentForType = find(this.subscriber.enrollments, (e: Enrollment) => {
           return ((enrollment.planTypeId && enrollment.planTypeId === e.plan.planTypeId) ||
            (enrollment.Plan && enrollment.Plan.planTypeId === e.plan.planTypeId)) &&
             (dayjs(enrollment.effectiveStartDate).isSameOrAfter(dayjs(e.effectiveStartDate).startOf('day')) && !e.effectiveEndDate) ||
             (e.effectiveEndDate && dayjs(enrollment.effectiveStartDate).isBetween(dayjs(e.effectiveStartDate).startOf('day'), dayjs(e.effectiveEndDate).endOf('day')));
        });
        activeMemberWaiverForType = find(this.subscriber.waivers, (w: MemberWaiver) => {
         return w.planTypeId === enrollment.planTypeId &&
         (dayjs(enrollment.effectiveStartDate).isSameOrAfter(dayjs(w.effectiveStartDate).startOf('day')) && !w.effectiveEndDate) ||
         (w.effectiveEndDate && dayjs(enrollment.effectiveStartDate).isBetween(dayjs(w.effectiveStartDate).startOf('day'), dayjs(w.effectiveEndDate).endOf('day')));
        });
      });
      return activeEnrollmentForType || activeMemberWaiverForType;
    }
  }

  memberHasOverlappingCoveragePeriod(enrollments): Boolean {
    let hasOverlappingEnrollment = false;
    if (enrollments.length > 1) {
      enrollments.forEach(enrollment => {
        const enrollmentsWithSamePlan = enrollments
          .filter(item => {
            // Skip the same record
            if (item === enrollment) {
              return false;
            }

            // Make sure it has the same plan type
            if ((item.Plan?.planTypeId ?? item.planTypeId) === (enrollment.Plan?.planTypeId ?? enrollment.planTypeId)) {
              return true;
            }

            return false;
          });

        if (find(enrollmentsWithSamePlan, (e) => {
            // Both records don't have an end date (are active)
            if (!e.effectiveEndDate && !enrollment.effectiveEndDate) {
              return true;
            }

            // The record we're checking is active, so we just need to make sure the other record is expired and end date is before the start
            if (!e.effectiveEndDate && isBetween(e.effectiveStartDate, e.effectiveEndDate, enrollment.effectiveStartDate)) {
              return true;
            }

            // Both records are expired at this point, make sure neither overlap each other.
            if (isBetween(e.effectiveStartDate, e.effectiveEndDate, enrollment.effectiveStartDate) ||
                  isBetween(e.effectiveStartDate, e.effectiveEndDate, enrollment.effectiveEndDate)) {
              return true;
            }

            return false;
        })) {
          hasOverlappingEnrollment = true;
        }
      });

      return hasOverlappingEnrollment;
    } else {
      return false;
    }
  }
}
