import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
// ng
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';

// ext
import * as dayjs from 'dayjs';
import { faFileMedical, faStaffSnake } from '@fortawesome/free-solid-svg-icons';
import { find, filter, toLower, sortBy, forEach, maxBy, get, map, cloneDeep } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
import SubscriberCoverage from 'src/app/models/subscriberCoverage';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import Plan from 'src/app/models/plan';
import Milestone from 'src/app/models/milestone';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Subscriber from 'src/app/models/subscriber';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import Enrollment from 'src/app/models/enrollment';
import PlanType from 'src/app/models/planType';
import Reason from 'src/app/models/reason';
import { env } from 'src/env/development';
import { SupplementalCalculatorComponent } from './components/supplementalCalculator/supplementalCalculator.component';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import MemberType from 'src/app/models/memberType';
import { LookupService } from 'src/app/services/lookup.service';
import SupplementalLTDBand from 'src/app/models/supplementalLTDBands';
import SupplementalLTDBandDisplay from 'src/app/models/supplementalLTDBandsDisplay';
import { SubscriberService } from 'src/app/services/subscriber.service';

@UntilDestroy()
@Lookups(LookupType.BenefitSetup, LookupType.PlanType, LookupType.Reason, LookupType.MemberType)
@Component({
    selector: 'supplemental',
    templateUrl: 'supplemental.component.html',
    styleUrls: [],
    providers: [],
    encapsulation: ViewEncapsulation.None,
})
export class SupplementalComponent implements OnInit, OnDestroy {
    subscriber: Subscriber;
    enrollmentPeriod: EnrollmentPeriod;
    supplementalMilestone: Milestone;
    changesHaveBeenSaved = false;
    isEnrolled = false;
    step = 0;
    icons = {
        faStaffSnake,
    };
    supplementalPlans: Plan[];
    planTypes: PlanType[];
    selectedPlan = {
        rates: [
            {
                rateAmt: 0,
            },
        ]
    };
    isAdmin = false;
    confirmButtonText = 'Continue and review';
    isSOE = false;
    isSP = false;
    isOutsideEP = false;
    suppCalcSaved = false;
    accessedFromAdminTab = false;
    legacyLtd = false;
    legacyEnroll = false;
    isHCA = false;
    initialSelectedPlanId: string;
    selectedSupplementalEnrollment: Enrollment;
    isActiveEmployeeType = false;
    env = env;
    isSEBB = false;
    isPEBB = false;
    ltdDisabled = false;
    supplementalLTDBands: SupplementalLTDBand[];
    currentSOE: SpecialOpenEnrollment;
    inWizard = false;
    supplementalCalcOutput;
    subscriberIneligibile = false;
    @ViewChild('suppCalc') suppCalc: SupplementalCalculatorComponent;

    constructor(
        private spinnerService: SpinnerOverlayService,
        private coreService: CoreService,
        private route: ActivatedRoute,
        private router: Router,
        private enrollmentService: EnrollmentService,
        private enrollmentPeriodService: EnrollmentPeriodService,
        private lookupService: LookupService,
        private subscriberService: SubscriberService
    ) { }

    ngOnInit(): void {
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
            this.accessedFromAdminTab = params.tabmgmt;
        });
        this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
            this.subscriber = data.subscriber;
            this.subscriberIneligibile = this.subscriber.agencyEffectiveEndDate && new Date() > this.subscriber.agencyEffectiveEndDate;
            this.isSEBB = this.subscriber.organization?.agency?.agencyCode === 'SEBB';

            if (this.isSEBB) {
                this.lookupService.getLutValues('supplementalLtdBand', SupplementalLTDBand).pipe(untilDestroyed(this)).subscribe(v => {
                    this.supplementalLTDBands = map(v, (val) => new SupplementalLTDBandDisplay(val));
                });
            }
            if (!this.isSEBB) {
                this.isPEBB = true;
            }
            if (this.subscriber.hasSupplementalLTD || !this.accessedFromAdminTab) {
                this.planTypes = data.lookups.planType;
                this.isAdmin = this.coreService.systemUserHasAccess(AccessLevel.Edit);
                this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
                this.ltdDisabled = this.subscriber.organization?.disableOptionalLTD && !this.isAdmin;
                this.enrollmentPeriod = data.enrollmentPeriod;
                this.supplementalMilestone = find(this.enrollmentPeriod?.milestones ?? [], (mi: Milestone) => mi.milestoneName === 'Supplemental Benefits');
                let supplementalEnrollment = null;

                if (!this.enrollmentPeriod) {
                    // editing through supplemental tile/tab
                    const firstOfNextMonth = dayjs().add(1, 'month').startOf('month');
                    supplementalEnrollment = find(
                        this.subscriber.enrollments,
                        (e: Enrollment) =>
                            e.plan.planType.planTypeCode === '5' &&
                            (
                                e.effectiveStartDate &&
                                dayjs(e.effectiveStartDate).isSame(firstOfNextMonth)
                            )
                    );

                    if (!supplementalEnrollment) {
                        supplementalEnrollment = find(
                            this.subscriber.enrollments,
                            (e: Enrollment) =>
                                e.plan.planType.planTypeCode === '5' &&
                                (
                                    e.effectiveStartDate &&
                                    ((e.effectiveEndDate && dayjs(dayjs()).isBetween(e.effectiveStartDate, e.effectiveEndDate)) ||
                                        (!e.effectiveEndDate && dayjs(dayjs()).isSameOrAfter(e.effectiveStartDate)))
                                )
                        );
                    }

                    this.enrollmentPeriod = maxBy(filter(this.subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) =>
                        (ep.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'NE')), 'coverageEffectiveStartDate');
                    this.isOutsideEP = true;

                    if (!this.enrollmentPeriod) {
                        // get OE or SOE if current date falls within that period
                        this.enrollmentPeriod = maxBy(filter(this.subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) =>
                        (
                            ep.effectiveStartDate &&
                            ((ep.effectiveEndDate && dayjs(dayjs()).isBetween(ep.effectiveStartDate, ep.effectiveEndDate)) ||
                                (!ep.effectiveEndDate && dayjs(dayjs()).isSameOrAfter(ep.effectiveStartDate)))
                        )), 'coverageEffectiveStartDate');
                        if (!this.enrollmentPeriod) {
                            // no NE or no enrollment where current date is within enrollment period, get enrollment period  with the latest coverageEffectiveStartDate
                            this.enrollmentPeriod = maxBy(this.subscriber.enrollmentPeriods, 'coverageEffectiveStartDate');
                        }
                    }
                } else {
                    supplementalEnrollment = this.subscriber.getSupplementalEnrollment(this.enrollmentPeriod, true, this.supplementalMilestone);
                    this.inWizard = true;
                }

                this.subscriber.memberType = data.lookups.memberType.find((mt: MemberType) => mt.memberTypeId === this.subscriber.memberTypeId);
                if (this.isOutsideEP) {
                    if (this.subscriber.isSebb) {
                        // sebb LTD eligibility is based on member type which has to be set above before calling this method
                        this.subscriber.setIsEligibleForLTD(null);
                    }
                }
                const start = dayjs(this.enrollmentPeriod.coverageEffectiveStartDate);
                if (!this.isOutsideEP && start) {
                    // check for LTD inelible flag and set whether or not LTD is eligibile based on this enrollment period's coverage effective start date
                    // instead of the current date
                    this.subscriber.setIsEligibleForLTD(start);
                }

                const newLtdStart = dayjs(new Date(2022, 0, 1)); // 1/1/2022
                if (start.isBefore(newLtdStart)) {
                    this.legacyLtd = true;
                } else {
                    this.legacyLtd = false;
                }

                if (this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SOE' && !this.isOutsideEP) {
                    this.isSOE = true;
                } else if (this.enrollmentPeriod?.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE') {
                    this.isSP = true;
                }
                if (this.isSOE) {
                    this.currentSOE = find(this.subscriber.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => soe.specialOpenEnrollmentId === this.enrollmentPeriod.enrollmentPeriodId);
                }

                const employeeMemberTypes = data.lookups.memberType.filter((mt: MemberType) => env.sebbMemberTypeCodes.find(smt => smt === mt.memberTypeCode) || env.pebbEligbileMemberTypes.find(pmt => pmt === mt.memberTypeCode));
                if (!this.isSP && !this.subscriber.isLWOP && employeeMemberTypes.find((mt: MemberType) => mt.memberTypeId === this.subscriber.memberTypeId)) {
                    this.isActiveEmployeeType = true;
                }

                this.spinnerService.show();
                if (this.isSP) {
                    this.enrollmentService.getSelfPayAvailablePlans().pipe(untilDestroyed(this)).subscribe((availablePlans: Plan[]) => {
                        this.supplementalPlans = filter(availablePlans, (ap: Plan) => ap.planType.planTypeCode === '5');
                        this.setSelectedLTD(supplementalEnrollment);
                        this.spinnerService.hide();
                    });
                } else {
                    this.enrollmentService.getAvailablePlansForMember(this.subscriber.memberId, this.enrollmentPeriod.enrollmentPeriodId).pipe(untilDestroyed(this)).subscribe((availablePlans) => {
                        this.supplementalPlans = sortBy(filter(availablePlans, (ap: Plan) => ap.planType.planTypeCode === '5'), 'planCode');
                        this.setSelectedLTD(supplementalEnrollment);
                        if(this.isSEBB && this.legacyLtd){
                            this.supplementalPlans = sortBy(filter(availablePlans, (ap: Plan) => ap.planCode === '090'), 'planCode');
                        }
                        this.spinnerService.hide();
                    });
                }
            }
        });
    }

    setSelectedLTD(supplementalEnrollment): void {
        this.selectedSupplementalEnrollment = supplementalEnrollment;
        if (supplementalEnrollment) {
            this.isEnrolled = true;
            if (this.legacyLtd) {
                this.legacyEnroll = true;
                this.selectedPlan = this.supplementalPlans.find(o => o.planId === supplementalEnrollment.plan.planId);
            }
            else if (this.supplementalPlans.find(o => o.planId === supplementalEnrollment.plan.planId))
            {
                this.selectedPlan = supplementalEnrollment.plan;
            }
        }

        if (!this.legacyLtd && !this.isOutsideEP && (!supplementalEnrollment || !this.supplementalPlans.find(o => o.planId === supplementalEnrollment.plan.planId))) {
            // when using the wizard and not legacy ltd, set default plan to 60% if no plan is selected
            this.selectedPlan = this.supplementalPlans.find(o => o.planCode === 'EE60');
        }
    }

    ngOnDestroy(): void { }

    updateStep(s: number): void {

    }

    legacyEnrollChanged(legacyEnroll: boolean): void {
        if (!legacyEnroll) {
            this.selectedPlan = null;
        }
    }

    legacyPlanChanged(plan: string): void {
        if (!plan) {
            this.legacyEnroll = false;
        }
    }

    async saveElections(selectedPlanResult, submitting = false): Promise<void> {
        // short save if in wizard, handle on page submit
        if (this.inWizard && !submitting) {
            return this.supplementalCalcOutput = selectedPlanResult;
        }

        if (this.legacyLtd && this.legacyEnroll && !selectedPlanResult?.plan) {
            return this.coreService.popMessage('Please select the enroll reason.', 'error', 3000);
        }

        if (selectedPlanResult?.plan){
            (this.subscriber as any).selectedPlan = selectedPlanResult.plan;
        }
        const coverageElections = new SubscriberCoverage();
        coverageElections.enrollmentPeriodId = this.enrollmentPeriod.enrollmentPeriodId;
        coverageElections.subscriberMemberId = this.subscriber.subscriberMemberId;
        coverageElections.electedPlans = [];
        coverageElections.waivedPlanTypes = [];
        coverageElections.planTypeIds = [];
        coverageElections.isTransferEvent = this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === env.newFullBenefitsEnrollmentPeriodType;

        const supplementalPlanType = find(this.planTypes, (pt: PlanType) => pt.planTypeCode === '5');

        if (selectedPlanResult?.effectiveDate) {
            coverageElections.effectiveStartDate = cloneDeep(selectedPlanResult.effectiveDate);
        }

        if (selectedPlanResult?.plan) {
            coverageElections.electedPlans.push(selectedPlanResult.plan);
            coverageElections.planTypeIds.push(supplementalPlanType.planTypeId);
        } else {
            coverageElections.waivedPlanTypes.push(supplementalPlanType.planTypeId);
        }

        this.spinnerService.show();
        this.suppCalcSaved = true;

        return new Promise((resolve, reject) => {
            const handleResponse = s => {
                this.spinnerService.hide();

                if (selectedPlanResult?.plan) {
                    this.coreService.popMessage('Supplemental election successfully saved!', 'success', 10000);
                    this.isEnrolled = true;
                }
                else {
                    this.coreService.popMessage('Supplemental disenrollment successfully saved!', 'success', 10000);
                    this.isEnrolled = false;
                }

                if (this.isOutsideEP && selectedPlanResult?.effectiveDate) {
                    this.initialSelectedPlanId = selectedPlanResult?.plan?.planId;
                    if (this.selectedSupplementalEnrollment?.effectiveStartDate) {
                        this.selectedSupplementalEnrollment.effectiveStartDate = cloneDeep(selectedPlanResult?.effectiveDate);
                    }
                }
                this.reFetchSubscriber(selectedPlanResult?.effectiveDate);
                resolve(s);
            };
            if (this.isOutsideEP) {
                this.enrollmentService.createSupplementalEnrollmentsOutsideEP(this.subscriber.subscriberMemberId, coverageElections).pipe(untilDestroyed(this)).subscribe(handleResponse);
            } else {
                this.enrollmentService.createSubscriberEnrollments(this.subscriber.subscriberMemberId, this.enrollmentPeriod.enrollmentPeriodId, coverageElections)
                    .pipe(untilDestroyed(this)).subscribe(handleResponse);
            }
        });
    }

    async reFetchSubscriber(effectiveDate): Promise<void> {
        this.spinnerService.show();
        const sub = await lastValueFrom(this.subscriberService.getSubscriberById(this.subscriber.memberId));
        this.subscriber = sub;
        this.coreService.setSubscriber(cloneDeep(sub));
        if (effectiveDate) {
            this.suppCalc.coverageEffectiveDate = effectiveDate;
        }
        this.spinnerService.hide();
    }

    async confirm(): Promise<void> {
        if ((!this.legacyLtd && this.suppCalc && !this.suppCalcSaved && this.selectedPlan[`planId`] !== this.suppCalc?.selectedPlanId) || !this.subscriber.isEligibleForLTD) {
            let selectedPlan = this.subscriber.isEligibleForLTD && this.suppCalc?.selectedPlanId ?
                find(this.supplementalPlans, (p) => p.planId === this.suppCalc?.selectedPlanId) : null;
                await this.saveElections({plan: selectedPlan }, true);
            }
        else if(this.legacyLtd && this.selectedPlan) {
            let selectedPlan = this.selectedPlan;
                await this.saveElections({plan: selectedPlan }, true);
        }
        else if (this.supplementalCalcOutput) {
            await this.saveElections(this.supplementalCalcOutput, true);
        }
        if (this.supplementalMilestone) {
            this.spinnerService.show();
            this.enrollmentPeriodService
                .createMilestoneCompletion(this.subscriber.memberId, this.supplementalMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId)
                .pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
                    this.enrollmentPeriod = e;
                    this.coreService.popMessage(`Great! One last step - let's review your elections`, 'success', 8000);
                    this.router.navigate([`../../confirmation/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
                    this.spinnerService.hide();
                });
        } else {
            this.coreService.popMessage(`Great! One last step - let's review your elections`, 'success', 8000);
            this.router.navigate([`../../confirmation/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
        }
    }

    navToDashboard(): void {
        this.router.navigate([`../../subscriber/${this.subscriber.memberId}`], { relativeTo: this.route });
    }
}
