export default class TermsOfUseActionType {
  termsOfUseActionTypeId: string;
  termsOfUseActionCode: string;
  termsOfUseActionName: string;

  constructor(termsOfUseAction?) {
    if (termsOfUseAction) {
      this.termsOfUseActionTypeId = termsOfUseAction.termsOfUseActionTypeId;
      this.termsOfUseActionCode = termsOfUseAction.termsOfUseActionCode;
      this.termsOfUseActionName = termsOfUseAction.termsOfUseActionName;
    }
  }
}
