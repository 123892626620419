import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import { map, filter, some, groupBy, omit, sortBy, find, remove, orderBy, Pairs, toPairs, forEach, every } from 'lodash';
import { faCalendar, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import SelfPay from 'src/app/models/selfPay';
import Plan from 'src/app/models/plan';
import Agency from 'src/app/models/agency';
import { env } from 'src/env/development';
import Ethnicity from 'src/app/models/ethnicity';
import Carrier from 'src/app/models/carrier';
import * as dayjs from 'dayjs';
@Component({
  selector: 'self-pay-elect-medical',
  templateUrl: 'selfPay.electMedical.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayElectMedicalComponent implements OnInit {
  @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @ViewChild('electMedicalForm') public electMedicalForm: NgForm;
  @Input() selfPay: SelfPay;
  @Input() availablePlans: Plan[];
  @Input() agency: Agency;
  @Input() ethnicities: Ethnicity[];
  @Input() lookups;
  medicalPlans: Plan[];
  carrierMedicalPlans: Carrier[] = [];
  selectedPlanIds: string[] = [];
  totalPlansAllowed = 2;
  icons = {
      faCalendar,
      faExclamationCircle
  }
  groupedMedicalPlans: {[k:string]: Plan[]};
  medicarePlanCodes = ['F', 'G', 'CMED', 'MD', 'MB', 'MC'];
  splitMedicarePlans = [
    'Kaiser Foundation Health Plan of the Northwest Classic',
    'Kaiser Foundation Health Plan of Washington Classic',
    'Kaiser Foundation Health Plan Washington SoundChoice',
    'Kaiser Foundation Health Plan of Washington Value',
    'Premera Medicare Supplement Plan G',
    'UMP Classic',
    'UnitedHealthcare PEBB Balance',
    'UnitedHealthcare PEBB Complete'
  ];
  fullMedicarePlans = [
      'Kaiser Foundation Health Plan of the Northwest Senior Advantage',
      'Kaiser Foundation Health Plan of Washington Medicare Plan',
      'Premera Medicare Supplement Plan G',
      'UMP Classic',
      'UnitedHealthcare PEBB Balance',
      'UnitedHealthcare PEBB Complete'
  ];
  medicarePlanIds = [];
  displayRaceEthnicity = false;
  selectedLanguage = '';
  otherLanguageEnabledSub = false;
  otherLanguageEnabledDep = false;
  isRetiree = false;
  isUnpaid = false;
  selectedHispanicCodesSub = [];
  selectedHispanicCodesDEp = [];
  isCobra: boolean = false;
  noMedicalSelectionMade = false;
  isPebbRetireeOE = false;
  isCobraOE = false;
  accountMedicareStatus = null;
  ngOnInit() {
    this.isRetiree = this.selfPay?.selfPayType?.selfPayTypeCode === 'R';
    this.isUnpaid = this.selfPay?.selfPayType?.selfPayTypeCode === 'U';
    this.isCobra = env.cobraSelfPayTypeCodes.includes(this.selfPay.selfPayType.selfPayTypeCode);
    this.medicalPlans = sortBy(filter(this.availablePlans, (ap: Plan) => ap.planType?.planTypeCode === '1' && ap.agencyId === this.agency.agencyId && ap.carrier!=null && ap.planCode!='F' && (ap.planName?.includes('Senior') ? !this.isUnpaid : true)),'sortOrder');
    
    if(env.lwopSelfPayTypeCodes.includes(this.selfPay?.selfPayType?.selfPayTypeCode)) {
      //LWOP
      this.totalPlansAllowed = 1;
      this.medicalPlans = filter(this.medicalPlans, (p: Plan) => !this.medicarePlanCodes.includes(p?.planCode));
    }
    this.medicarePlanIds = map(filter(this.availablePlans, ap => this.medicarePlanCodes.includes(ap.planCode)), 'planId');
    this.noMedicalSelectionMade = !this.selfPay?.formJson?.subscriber?.medicalEnrollInd && !some(this.selfPay?.formJson?.dependents, (d: SelfPayFormDependent) => d.medicalCoverInd);
    this.isPebbRetireeOE = ((this.selfPay?.selfPayType?.selfPayTypeCode === 'R' && dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(new Date('1/1/25'))) || this.selfPay?.selfPayType?.selfPayTypeCode === "OER");
    this.isCobraOE = this.selfPay?.selfPayType?.selfPayTypeCode === 'OEC';
    this.accountMedicareStatus = this.getAccountMedicareStatus();
    if(this.isPebbRetireeOE || this.isCobraOE) {
      if(this.accountMedicareStatus === 'N') {
        this.medicalPlans = this.medicalPlans.filter(p => !this.medicarePlanCodes.includes(p.planCode) && !p.planName.includes('Senior'));
      }else if (this.accountMedicareStatus == 'F'){
        this.medicalPlans = this.medicalPlans.filter(p => this.fullMedicarePlans.includes(p.planName));
        this.medicalPlans.forEach(p => {
          if(p.planName === 'UMP Classic'){
            p.planName = 'UMP Classic Medicare with Part D (PDP)';
          }
        })
      }else if(this.accountMedicareStatus == 'S') {
        this.medicalPlans = this.medicalPlans.filter(p => this.splitMedicarePlans.includes(p.planName));
        this.medicalPlans.forEach(p => {
          if(p.planName === 'UMP Classic'){
            p.planName = 'UMP Classic Medicare with Part D (PDP)';
          }
        })
      }
    }

    // the only medical plan without a carrier is the default placeholder plan. This removes that plan from list.
    this.groupedMedicalPlans = groupBy(this.medicalPlans, 'carrier.carrierName');
    toPairs(this.groupedMedicalPlans).forEach( mp=> {
      var c = new Carrier();
      c.carrierName = mp[0];
      c.plans = mp[1];
      this.carrierMedicalPlans.push(c);
    });
    
    this.carrierMedicalPlans = orderBy(this.carrierMedicalPlans, [mp=> mp.carrierName.toLowerCase()], ["asc"]);

    if (!this.selfPay.formJson.medicalPlans) {
      this.selfPay.formJson.medicalPlans = [];
    }
    else {
      this.selectedPlanIds = map(this.selfPay.formJson.medicalPlans,'planId');
    }
  }

  setPlan(planId) {
    if (this.selectedPlanIds.indexOf(planId) > -1)
    {
      //removing selected plan
      remove(this.selfPay.formJson.medicalPlans,(p)=>p.planId == planId);
      remove(this.selectedPlanIds,(p)=>p == planId);
    }
    else {
      //adding new plan, allow only 2 max plans selected for COBRA/Retiree
      if (this.selectedPlanIds.length>this.totalPlansAllowed-1) {
        this.selectedPlanIds.splice(0,1);
        this.selfPay.formJson.medicalPlans.splice(0,1);
      }
      this.selectedPlanIds.push(planId);
      this.selfPay.formJson.medicalPlans.push(find(this.availablePlans,(p: Plan) => p.planId == planId));
    }

    if (this.totalPlansAllowed==1) {
      //set single selected medical plan to the first in the list if it exists
      if (this.selfPay.formJson.medicalPlans.length>0) {
        this.selfPay.formJson.medicalPlan = this.selfPay.formJson.medicalPlans[0];
      }
      else {
        this.selfPay.formJson.medicalPlan = null;
      }
    }
  }

  emitSelfPay(e) {
    this.saveSelfPay.emit(e);
  }

  subscriberOrDependentsAreMedicareEnrolled(): boolean {
    return this.selfPay.formJson?.subscriber?.medicarePartAInd || some(this.selfPay.formJson?.dependents, (d: SelfPayFormDependent) => d?.memberMedicare?.medicarePartAEnrolledInd);
  }

  continueOrDisplayRE(selfPay) {
    if(some(this.selectedPlanIds, s => this.medicarePlanIds.includes(s)) && (this.selfPay.selfPayType?.selfPayTypeCode.includes('R') || this.selfPay.selfPayType?.selfPayTypeCode.includes('C')) && this.subscriberOrDependentsAreMedicareEnrolled() && some(this.lookups?.applicationSetting, (as) => as.applicationSettingCode === 'MAPD' && as.response?.responseCode === 'Y')) {
      this.displayRaceEthnicity = true;
    }else {
      this.saveSelfPay.emit(selfPay);
    }
  }

  updateFormJsonSub(e) {
    this.selfPay.formJson.subscriber = e;
  }

  updateFormJsonSpouse(e) {
    this.selfPay.formJson.dependents[0] = e;
  }

  continueCurrentSelected(checked) {
    if(checked) {
      this.selectedPlanIds = [];
    }
  }

  getAccountMedicareStatus(): string {
    if(this.selfPay?.formJson?.subscriber?.medicarePartBInd && every(this.selfPay?.formJson?.dependents, d => d?.medicarePartBInd)) {
      return 'F';
    }else if (!this.selfPay?.formJson?.subscriber?.medicarePartBInd && some(this.selfPay?.formJson?.dependents, d => d?.medicarePartBInd) || this.selfPay?.formJson?.subscriber?.medicarePartBInd && some(this.selfPay?.formJson?.dependents, d => !d?.medicarePartBInd) ){
      return 'S'
    }else if (!this.selfPay?.formJson?.subscriber?.medicarePartBInd && every(this.selfPay?.formJson?.dependents, d => !d?.medicarePartBInd)) {
      return 'N';
    }
  }
}
