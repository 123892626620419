<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid ie-height-mobile-l">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body page-container">
        <h1>HCA Administrative Dashboard</h1>
        <h2 class="h4">Welcome{{systemUser.firstName ? ', ' + systemUser.firstName : ''}} {{systemUser.lastName}}!</h2>
        <div class="ie-height-mobile-l hca-dashboard-card-container" fxLayout="row wrap">
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/subscriberManagement/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUser" size="2x"></fa-icon></span>
                  <h3>Manage subscribers</h3>
                  <h4 class="h6">Search, view, add, or update subscribers</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/hca/access/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserShield" size="2x"></fa-icon></span>
                  <h3>Manage access</h3>
                  <h4 class="h6">Administer admin access for perspay staff</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/organizationManagement/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faBuilding" size="2x"></fa-icon></span>
                  <h3>Manage organizations</h3>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/hca/thirdPartyAccess/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faDoorOpen" size="2x"></fa-icon></span>
                  <h3>Manage 3rd party access</h3>
                  <h6>Administer access for 3rd party dependent verification</h6>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/hca/apiAccess/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faExchangeAlt" size="2x"></fa-icon></span>
                  <h3>Manage API access</h3>
                  <h6>Administer API users</h6>
                </div>
              </div>
            </div>
          </div>             -->
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/relationshipVerification/hca/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Dependent Verification</h3>
                  <h3>You have {{totalRelationshipVerifcationsPending}} verification request(s)</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/specialOpenEnrollment/review/hca/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Special Enrollment Event Verification</h3>
                  <h4>You have {{totalSpecialOpenEnrollmentPending}} Special enrollment event request(s)</h4>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/selfpay/review/hca/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Continuation coverage/retiree requests</h3>
                  <h4 class="h6">Manage self-pay/retiree enrollment requests</h4>
                  <h4 class="h5">You have {{totalSelfpayPending}} pending request(s)</h4>
                </div>
              </div>
            </div>
          </div>             -->
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/reports/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faChartBar" size="2x"></fa-icon></span>
                  <h3>Reports</h3>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/hca/accountCorrection/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faCheckCircle" size="2x"></fa-icon></span>
                  <h3>Account correction</h3>
                  <h6>Plans, coverage, dates</h6>
                </div>
              </div>
            </div>
          </div> -->
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/selfpaydashboard/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                    <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                    <h3>Selfpay Dashboard</h3>
                    <h6>Launch the dashboard to manage selfpay/continuation coverage</h6>
                </div>
              </div>
            </div>
          </div>    
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/appsettings/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faCog" size="2x"></fa-icon></span>
                  <h3>Application settings</h3>
                  <h4 class="h6">Lookup tables, open enrollment, system messaging</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/hcaAdminRoot/' + systemUser.systemUserId + '/syncFileErrors'">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faCheckDouble" size="2x"></fa-icon></span>
                  <h3>Error Handling</h3>
                  <h4 class="h6">View/Resolve interface errors</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="'/admin/subscriberHistory/' + systemUser.systemUserId">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                    <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faHistory" size="2x"></fa-icon></span>
                    <h3>Subscriber Enrollment History</h3>
                    <h6>View login, enrollment, change history</h6>
                </div>
              </div>
            </div>
          </div>              
        </div>
      </div>
    </div>
  </div>
</div>