import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import LowerLimit from '../models/lowerLimitCurrentProcessMonth';
import LowerLimitEligibilityDateCheck from '../models/lowerLimitEligibilityDateCheck';
import { AccessLevel, CoreService, UserTypeCode } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class LowerLimitService {
  private cache: { [k: string]: LowerLimit } = {};

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private coreService: CoreService
  ) {}

  getLowerLimit(effectiveDate?: Date, enforceHcaAdminRules = false, agencyCode: string = env.pebbCode, lowerLimitOffset: boolean = false): Observable<Date> {
    if (enforceHcaAdminRules === true) {
      const isHCA = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA) || this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      if (isHCA) {
        return of(null);
      }
    }

    agencyCode = agencyCode ?? env.pebbCode;
    effectiveDate = effectiveDate ?? new Date();

    const key = `${dayjs(effectiveDate).startOf('day').format('YYYYMMDD')}_${agencyCode}`;
    if (this.cache[key]) {
      if (lowerLimitOffset) {
        return of(this.cache[key].lowerLimitMinEligibilityDate);
      }

      return of(this.cache[key].lowerLimitDate);
    }

    return this.http.post<LowerLimit>(`${env.apiUrl}/lowerlimit/${agencyCode}`, effectiveDate)
      .pipe(map(x => this.commonService.createObject(LowerLimit, x)))
      .pipe(map((lowerLimit: LowerLimit) => {
        if (!this.cache[key]) {
          this.cache[key] = lowerLimit;
        }

        if (lowerLimitOffset) {
          return lowerLimit.lowerLimitMinEligibilityDate;
        }

        return lowerLimit.lowerLimitDate;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  coverageEffectiveDateWithinLowerLimit(eligibilityDate: Date, agencyCode: string = env.pebbCode, sebbIsOnOrBeforeFirstDayOfSchool: boolean = null, enforceHcaAdminRules = false): Observable<boolean> {
    if (enforceHcaAdminRules === true) {
      const isHCA = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA) || this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      if (isHCA) {
        return of(null);
      }
    }

    agencyCode = agencyCode ?? env.pebbCode;

    const dto: LowerLimitEligibilityDateCheck =
    {
      eligibilityDate: eligibilityDate,
      agencyCode: agencyCode,
      sebbIsOnOrBeforeFirstDayOfSchool: sebbIsOnOrBeforeFirstDayOfSchool
    };

    return this.http.post<LowerLimit>(`${env.apiUrl}/lowerLimit/eligibilityWithinLowerLimit`, dto)
      .pipe(map(x => this.commonService.createObject(LowerLimitEligibilityDateCheck, x)))
      .pipe(map((lowerLimit: LowerLimitEligibilityDateCheck) => {
        return lowerLimit.isWithinLowerLimit;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  getUpperLimit(effectiveDate?: Date, agencyCode: string = env.pebbCode): Observable<Date> {
    effectiveDate = effectiveDate ?? new Date();
    agencyCode = agencyCode ?? env.pebbCode;

    const key = `${dayjs(effectiveDate).startOf('day').format('YYYYMMDD')}_${agencyCode}`;
    if (this.cache[key]) {
      return of(this.cache[key].upperLimitEffectiveDate);
    }

    return this.http.post<LowerLimit>(`${env.apiUrl}/lowerlimit/${agencyCode}`, effectiveDate)
      .pipe(map(x => this.commonService.createObject(LowerLimit, x)))
      .pipe(map((lowerLimit: LowerLimit) => {
        if (!this.cache[key]) {
          this.cache[key] = lowerLimit;
        }

        return lowerLimit.upperLimitEffectiveDate;
      }))
      .pipe(catchError(this.commonService.handleError));
  }
}
