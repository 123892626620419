import SubmissionFileResponseType from './submissionFileResponseType';

export default class SubmissionFileResponse {
  submissionFileResponseId: string;
  submissionFileId: string;
  submissionFileResponseTypeId: string;
  rowIdentifier: string;
  rowNumber: string;
  columnName: string;
  columnValue: string;
  responseMessage: string;
  submissionFileResponseType?: SubmissionFileResponseType;
  uploadDate?: Date;
  firstName?: string;
  lastName?: string;
  ssn?: string;

  constructor(fileResponse?) {
    if (fileResponse) {
      const memberInfo = fileResponse.rowIdentifier ? fileResponse.rowIdentifier.split(',') : null;
      this.ssn = memberInfo ? `${memberInfo[0]}` : null;
      const memberName = memberInfo && memberInfo[1] ? memberInfo[1].split(' ') : null;
      this.firstName = memberName ? memberName[1] : null;
      this.lastName = memberName ? memberName[2] : null;
      this.submissionFileResponseId = fileResponse.submissionfileResponseId;
      this.submissionFileId = fileResponse.submissionFileId;
      this.submissionFileResponseTypeId = fileResponse.submissionFileResponseTypeId;
      this.rowIdentifier = fileResponse.rowIdentifier;
      this.rowNumber = fileResponse.rowNumber;
      this.columnName = fileResponse.columnName;
      this.columnValue = fileResponse.columnValue;
      this.responseMessage = fileResponse.responseMessage;
      this.submissionFileResponseType = fileResponse.submissionFileResponseType
        ? new SubmissionFileResponseType(fileResponse.submissionFileResponseType)
        : null;
    }
  }
}
