<nav fxFlexFill role="navigation" aria-label="PEBB Header" fxLayout.lt-sm="column"  [ngStyle.lt-sm]="{'align-items': 'center', 'justify-content': 'center'}" fxLayoutAlign="space-between center" fxFlexLayout="row" class="navbar navbar-expand-md">
  <a *ngIf="displayLogo" style="border: none;" class="navbar-brand" target="_blank" href="https://www.hca.wa.gov">
    <img type="image/svg+xml" src="assets/benefits247_logo.svg" width="240" height="90" alt="Washington State Health Care Authority" />
  </a>
  <div [class.linksMobile]="!displayLogo" id="navExternalLinks">
    <ul [class.rowMobile]="!displayLogo" [class.ml-auto]="displayLogo" fxLayout="row" class="navbar-nav">
      <li class="nav-item" *ngIf="isPersPay"><a class="nav-link" target="_blank" href="https://support.hca.wa.gov/hcasupport">HCA Support</a></li>
      <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits">PEBB Home</a></li>
      <li *ngIf="displayLogo || router.url === '/auth'" class="nav-item"><a class="nav-link" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/vision-plans-and-benefits">SEBB Home</a></li>
      <li class="nav-item"><a class="nav-link" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/contact-us">Contact PEBB/SEBB</a></li>
      <li *ngIf="!router.url.includes('auth')" tabindex="0" class="nav-item">
        <a class="nav-link" style="cursor: pointer;" (click)="logOut()">Sign Out</a>
      </li>
    </ul>
  </div>
</nav>
