<form nativeControlElement *ngIf="subscriberComposite" #memberForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill #memberformContainer>
  <div fxLayoutAlign="center center" fxFlexFill fxLayoutAlign="space-around" fxLayout="column" class="container-fluid">
    <div class="state">
      <div class="card" class="fxGap-medium-sm">
        <div class="card-body" fxLayout="column" fxLayout.sm="column">
          <h2 class="mobile-text-centered mgtp-x-small mgbt-medium-sm">Manage eligibility information</h2>
          <div fxLayout="column" class="mgbt-medium-sm">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
              <div class="form-group required" fxFlex="30">
                <label for="lastName">Last name</label>
                <input
                  minlength="1"
                  maxlength="20"
                  pattern="^(?!\s)(?!.*\s$)([a-zA-Z \-]){1,}$"
                  [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                  required
                  type="text"
                  class="form-control"
                  name="lastName"
                  id="lastName"
                  [(ngModel)]="subscriberComposite.lastName"
                />
              </div>
              <div class="form-group required" fxFlex="30">
                <label for="firstName">First name</label>
                <input
                  minlength="1"
                  maxlength="15"
                  pattern="^(?!\s)([a-zA-Z \-]){1,}$"
                  [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                  required
                  type="text"
                  class="form-control"
                  name="firstName"
                  id="firstName"
                  [(ngModel)]="subscriberComposite.firstName"
                />
              </div>
              <div class="form-group" fxFlex="15">
                <label for="middleName">Middle name</label>
                <input
                  maxlength="15"
                  pattern="^(?!\s)([a-zA-Z \-]){1,}$"
                  [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                  type="text"
                  class="form-control"
                  name="middleName"
                  id="middleName"
                  [(ngModel)]="subscriberComposite.middleName"
                />
              </div>
              <div class="form-group required" fxFlex="20">
                <label for="socialSecurityNumber" id="ssnLabel">SSN</label>
                <kendo-maskedtextbox
                  [mask]="env.ssnMask"
                  [pattern]="isUW ? null : env.ssnItinRegex"
                  [(ngModel)]="subscriberComposite.socialSecurityNumber"
                  [maskOnFocus]="true"
                  required
                  name="socialSecurityNumber"
                  id="socialSecurityNumber"
                  aria-labelledby="ssnLabel"
                  #ssn="ngModel"
                  class="form-control"
                  [forbiddenValues]="env.invalidSSNValues"
                  (blur)="checkSocialSecurityNumber()"
                ></kendo-maskedtextbox>
              </div>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlex="100">
              <div class="form-group" fxFlex="10">
                <label for="suffix">Suffix</label>
                <input
                  [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                  minlength="0"
                  maxlength="3"
                  type="text"
                  class="form-control"
                  name="suffix"
                  id="suffix"
                  [(ngModel)]="subscriberComposite.suffix"
                  placeholder="JR, SR"
                />
              </div>
              <div class="form-group" fxFlex="15">
                <date-picker
                  #birthDate="ngModel"
                  name="birthDate"
                  label="Birth date"
                  required
                  [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                  [(ngModel)]="subscriberComposite.birthDate"></date-picker>
              </div>
              <div class="form-group required" fxFlex="20">
                <label for="birthSexId">Sex assigned at birth</label>
                <select [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                  required
                  class="form-control" 
                  name="birthSexId"
                  id="birthSexId"
                  [(ngModel)]="subscriberComposite.birthSexId">
                  <option></option>
                  <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
                </select>
              </div>
              <div class="form-group required" fxFlex="20">
                <label for="genderIdentityId">Gender Identity</label>
                <select [disabled]="(isHrms || !canEdit) && !isHCAEdit"
                  class="form-control" 
                  required
                  name="genderIdentityId" 
                  id="genderIdentityId" 
                  [(ngModel)]="subscriberComposite.genderIdentityId">
                  <option></option>
                  <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
                </select>
              </div>
              <div class="form-group" fxFlex="25">
                <span>
                  Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law. To learn more, visit HCA's website at
                  <a target="_blank" href="https://www.hca.wa.gov/about-hca/gender-identity-information">hca.wa.gov/gender-x.</a>
                </span>
              </div>
            </div>
            <div class="fxGap-medium" fxFlexFill fxLayout="row" fxLayoutAlign="start start" fxLayout.lt-md="column" fxFlexFill>
              <div class="form-group required" fxFlex="40" fxFlex.lt-md="95">
                <label for="eligibilityReason">Eligibility reason</label>
                <select [disabled]="true" required class="form-control" 
                  name="eligibilityReason" 
                  id="eligibilityReason" 
                  [(ngModel)]="subscriberComposite.eligibilityReasonId">
                  <option *ngFor="let reason of lookups.eligibilityReasons" [ngValue]="reason.reasonId">{{ reason.reasonName }}</option>
                </select>
              </div>
              <div class="form-group" fxFlex="40" fxFlex.lt-md="95">
                <date-picker
                  #eligibilityDate="ngModel"
                  name="eligibilityDate"
                  label="Date of Eligibility"
                  inputClasses="form-control"
                  required
                  disabled
                  enforceLowerLimit
                  [(ngModel)]="subscriberComposite.eligibilityDate"></date-picker>
                <div *ngIf="memberForm.controls.eligibilityDate?.errors?.ngbDate?.maxDate" class="alert alert-danger">
                  Cannot enter an eligibility date that is greater than one month in the future.
                </div>
                <div *ngIf="eligibilityDate.invalid && eligibilityDate.dirty && eligibilityDate.errors?.ngbDate?.minDate" class="alert alert-danger">
                  The date entered is less than the current lower limit date. Contact Outreach and Training for assistance.
                </div>
              </div>
              <div class="form-group" fxFlex="40" fxFlex.lt-md="95" *ngIf="!isSebb">
                <label for="mailStop">Mail-stop (region)</label>
                <input
                  maxlength="6"
                  type="text"
                  class="form-control"
                  name="mailStop"
                  id="mailStop"
                  [(ngModel)]="subscriberComposite.mailStop"
                />
              </div>
            </div>
            <div class="fxGap-medium" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxFlexFill>
              <div class="form-group" [class.required]="!isSebb && fullBenefits" fxFlex="35" fxFlex.lt-md="95">
                <label id="monthlySalaryLbl" for="monthlySalary">Employee monthly gross salary</label>
                {{currentOrganization.benefitSetupId}}
                <kendo-numerictextbox
                  format="c2"
                  [spinners]="false"
                  [(ngModel)]="subscriberComposite.salary"
                  (blur)="salaryChange(subscriberComposite.salary)"
                  [required]="!isSebb && fullBenefits"
                  name="monthlySalary"
                  id="monthlySalary"
                  aria-labelledby="monthlySalaryLbl"
                  [disabled]="!canEdit || (subscriberComposite.memberSelfPayOrigin && subscriber.isSelfPay)"
                  [decimals]="2"
                  [maxlength]="7"
                  class="kendo-fld-required"
                  [min]="0">
                </kendo-numerictextbox>
              </div>
              <div fxLayout="column" fxFlex="30" class="fxGap-medium-sm mgbt-small">
                <div fxLayout="row" fxLayoutAlign="space-between" >
                  <span fxFlex="40"><label for="wellnessInd">Wellness participant:</label></span>
                  <select fxFlex="50" [disabled]="!isHCAEdit" (change)="checkWellness($event)" class="form-control" 
                    name="wellnessInd" 
                    id="wellnessInd" 
                    [(ngModel)]="wellnessFlag">
                    <option [ngValue]="true">Yes</option>
                    <option [ngValue]="false">No</option>
                  </select>
                </div>
                <div *ngIf="wellnessFlag" class="date-picker-no-margin">
                  <date-picker
                    #wellnessDate="ngModel"
                    name="wellnessDate"
                    label="Wellness Effective Date"
                    labelColon
                    labelFlex="40"
                    inputFlex="50"
                    required
                    inputClasses="form-control"
                    [canBeInFuture]="false"
                    [disabled]="!isHCAEdit"
                    [(ngModel)]="subscriberComposite.wellnessFlag.effectiveStartDate">
                  </date-picker>
                </div>
                <div fxLayoutAlign="space-between" *ngIf="subscriberComposite.wellnessFlag?.effectiveStartDate" fxLayout="row" >
                  <span for="wellnessPlanYear" fxFlex="40">Plan Year:</span>
                  <input
                    fxFlex="50"
                    [disabled]="true"
                    class="form-control"
                    placeholder="mm/dd/yyyy"
                    required
                    name="wellnessPlanYear" 
                    id="wellnessPlanYear" 
                    [(ngModel)]="wellnessPlanYear"
                  />
                </div>
              </div>              
            </div>
            <div class="fxGap-medium" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxFlexFill>
              <div class="form-group required" fxFlex="20" *ngIf="!subscriber.isSelfPay">
                <label for="representedInd">Is this employee represented?</label>
                <select [disabled]="isHrms || isOrgTypeToDisableEditFor || !canEdit" required class="form-control" 
                  name="representedInd" id="representedInd" [(ngModel)]="subscriberComposite.isRepresentedInd" (ngModelChange)="isRepresentedIndChange()">
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </div>
              <div class="form-group" *ngIf="!subscriber.isSelfPay && (subscriberComposite.isRepresentedInd === true || this.initialSubscriber.isRepresentedInd === true 
                || subscriberComposite.isRepresentedInd !== initialSubscriber.isRepresentedInd)" fxFlex="20">
                <date-picker
                  #effectiveStartDate="ngModel"
                  name="effectiveStartDate"
                  label="Effective start date"
                  required
                  inputClasses="form-control"
                  [canBeInFuture]="false"
                  [disabled]="isHrms || isOrgTypeToDisableEditFor || !canEdit || !subscriberComposite.isRepresentedInd"
                  [(ngModel)]="subscriberComposite.representedStartDate"
                  (ngModelChange)="representedStartDateChange()"></date-picker>
              </div>
              <div class="form-group" *ngIf="!subscriber.isSelfPay && subscriberComposite.isRepresentedInd !== initialSubscriber.isRepresentedInd && 
                initialSubscriber.representedDate && !subscriberComposite.isRepresentedInd" fxFlex="20">
                <date-picker
                  #effectiveStartDate="ngModel"
                  name="effectiveEndDate"
                  label="Effective end date"
                  required
                  inputClasses="form-control"
                  [canBeInFuture]="false"
                  [min]="subscriberComposite.representedStartDate"
                  [disabled]="isHrms || isOrgTypeToDisableEditFor || !canEdit"
                  [(ngModel)]="subscriberComposite.representedEndDate"
                  (ngModelChange)="representedEndDateChange()"></date-picker>
              </div>              
              <div class="form-group required" fxFlex="20" *ngIf="isSebb">
                <yes-no
                  name="sebbLocallyEligible"
                  [disabled]="!currentOrganization.localEligibleBenefits"
                  label="Is this employee locally eligible?"
                  [stretch]="false"
                  [(ngModel)]="subscriberComposite.memberTypeId"
                  [yesValue]="sebbLocallyEligible?.memberTypeId"
                  [noValue]="sebbEmployee?.memberTypeId"
                  (ngModelChange)="sebbLocallyEligibleChanged($event)"
                  >
                </yes-no>
              </div>
              <div class="form-group" *ngIf="isSebb && (initialSubscriber.sebbEligibilityDateChange || subscriberComposite.memberTypeId !== initialSubscriber.memberTypeId)" fxFlex="20">
                <date-picker
                  #ltdEffectiveStartDate="ngModel"
                  name="locallyEligibleChangeDate"
                  label="Date of Eligibility Change"
                  required
                  enforceLowerLimit
                  [min]="minSebbEligibilityDate"
                  [max]="maxDateLTDEligible"
                  [(ngModel)]="subscriberComposite.sebbEligibilityDateChange"></date-picker>
              </div>
              <div class="form-group required" fxFlex="20" *ngIf="!isSebb">
                <label for="LTDEligible">Employee eligible for LTD</label>
                <select required class="form-control" 
                  name="LTDEligible" 
                  id="LTDEligible" 
                  [(ngModel)]="subscriberComposite.isEligibleForLTD">
                  <option [ngValue]="true">Yes</option>
                  <option [ngValue]="false">No</option>
                </select>
              </div>
              <div class="form-group" *ngIf="!isSebb && (subscriberComposite.isEligibleForLTD !== initialSubscriber.isEligibleForLTD || initialSubscriber.ltdEffectiveDate)" fxFlex="20">
                <date-picker
                  #ltdEffectiveStartDate="ngModel"
                  name="ltdEffectiveStartDate"
                  [label]="'LTD eligibility effective ' + (subscriberComposite.isEligibleForLTD ? 'start' : 'end') + ' date'"
                  [required]="subscriberComposite.isEligibleForLTD !== initialSubscriber.isEligibleForLTD"
                  [disabledDates]="isDisabledStartEnd"
                  [min]="minDateLTDEligible"
                  [max]="maxDateLTDEligible"
                  [disabled]="isHrms || isOrgTypeToDisableEditFor || !canEdit"
                  [(ngModel)]="subscriberComposite.ltdEffectiveDate"></date-picker>
              </div>
            </div>
          </div>
          <div *ngIf="canEdit && (isSebb || fullBenefits)" class="mgbt-medium-lg">
            <div fxLayout="row" style="width: 100%;">
              <checkbox
                name="chkEligibleForSebb"
                bold
                [(ngModel)]="subscriber.dualEnrolledInOtherAgency"
                (ngModelChange)="dualEnrollChecked()">
                Eligible for {{isSebb ? 'PEBB' : 'SEBB'}} Benefits
              </checkbox>
            </div>
            <div fxLayout="row wrap" *ngIf="initiallyDualEnrolled === false && subscriber.dualEnrolledInOtherAgency === true" style="margin:10px 0;">
              <div class="card alert alert-danger">
                <span>
                  By selecting this option, the ability for subscriber to waive {{isSebb ? 'SEBB' : 'PEBB'}} medical/vision benefits to
                  enroll in {{isSebb ? 'PEBB' : 'SEBB'}} benefits will be enabled.  Dual enrollment between programs is not allowed per
                  <a href="https://apps.leg.wa.gov/WAC/default.aspx?cite={{dualEnrolledWac}}">WAC {{dualEnrolledWac}}</a>.
                </span>
              </div>
              <div fxLayout="row" class="mgtp-medium mgbt-medium" style="width: 100%;" fxLayoutAlign="center">
                <button type="button" class="btn hca-green-background" style="margin-right: 20px;" (click)="dualEnrollSub()">
                  Accept
                </button>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" class="mgbt-medium">
            <employment-term
              *ngIf="!subscriberComposite.memberSelfPayOrigin || !subscriber.isSelfPay"
              fxFlex="60"
              [isHCA]="isHCA"
              [lookups]="lookups"
              [terminationComposite]="terminationComposite"
              [disabled]="((termReasonLocked || !canEdit || !!subscriber.lossOfEligibilityDate) && !isHCA) || ((!!subscriber.lossOfEligibilityReasonId && subscriber.lossOfEligibilityReasonId !== transferReasonId) && !isHCA)"
              [subscriberComposite]="subscriberComposite"
              (updateSubscriberDeathDate)="updateDeathDate($event)"
              (submitTermination)="termSubscriber($event)"
              (cancelTransfer)="cancelTransfer($event)"
              [isSebb]="subscriber.isSebb"
              [agencyCode]="this.currentOrganization.agency?.agencyCode"
            ></employment-term>
            <!-- Reinstate -->
            <div
              class="card-body"
              fxLayoutAlign="center center"
              fxFlex="40"
              fxLayout="column"
              *ngIf="
                (subscriber?.lossOfEligibilityDate || subscriber?.agencyEffectiveEndDate) &&
                subscriber?.organization?.organizationId === currentOrganization?.organizationId &&
                !reinstateTimedOut &&
                (isHCA || subscriber.lossOfEligibilityDate > lowerLimitDate)
              "
            >
              <!-- <button (click)="reinstateSubscriber()" class="btn btn-primary">Reinstate account</button>
              <span style="max-width: 150px; text-align: center">Reinstate the benefits on this account for the subscriber and all dependents</span> -->
              <span>
                If this subscriber/account was terminated in error and needs to be re-enrolled without a break in benefits, add the subscriber again using an eligibility date that is the first of the month following the benefit end date.
              </span>
            </div>
          </div>
          <div>
            <ngb-accordion [destroyOnHide]="false" #contact="ngbAccordion">
              <!-- Self pay eligibility -->
              <ngb-panel *ngIf="isHCA && subscriberComposite.memberSelfPayOrigin && (subscriber.isSelfPay || !this.subscriberComposite.memberSelfPayOrigin.agencyEffectiveEndDate)">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Self-pay eligibility
                    <span class="float-right"> </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div fxLayout="column">
                    <selfpay-enrollment-eligibility
                      *ngIf="lookups?.organizations?.length"
                      #subEligibility
                      [(selfPayOrigin)]="subscriberComposite.memberSelfPayOrigin"
                      [lookups]="lookups"
                      [disabled]="subscriberComposite?.memberSelfPayOrigin?.lossOfEligibilityReasonId && subscriberComposite?.memberSelfPayOrigin?.agencyEffectiveEndDate"
                    ></selfpay-enrollment-eligibility>
                    <selfpay-enrollment-term
                      fxFlex="60"
                      [isHCA]="isHCA"
                      [lookups]="lookups"
                      [memberSelfPayOrigin]="subscriber?.memberSelfPayOrigin"
                      [terminationComposite]="selfpayTerminationComposite"
                      [disabled]="!canEdit || (selfpayTerminationComposite?.lossOfEligibilityReasonId && !isHCA)"
                      [termSubmitted]="termSubmitted"
                      (submitSelfPayTermination)="termSelfPaySubscriber($event)"
                    ></selfpay-enrollment-term>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- Medicare -->
              <ngb-panel>
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Medicare enrollment information
                    <span class="float-right"> </span>
                  </button>
                </ng-template>
                <!-- MEDICARE -->
                <ng-template ngbPanelContent>
                  <form #medicareForm="ngForm" #medicareFormContainer class="mgtp-medium">
                    <medicare #medicareComponent fxFlexFill [medicareOptions]="lookups.medicareOptions" [readOnly]="!isHCA"
                      [simpleMember]="subscriberComposite" [memberMedicare]="subscriberComposite.memberMedicare" [enrollmentSignatureMinDate]="formCSignatureMinDate">
                    </medicare>
                  </form>
                </ng-template>
              </ngb-panel>
              <!-- Contact Information -->
              <ngb-panel>
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Contact information
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px" [icon]="icons.faAt" size="2x"></fa-icon>
                      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px" [icon]="icons.faPhone" size="2x"></fa-icon>
                      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px" [icon]="icons.faMobileAlt" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div fxFlex="0" fxFlex.gt.sm="10"></div>
                  <div fxFlexLayout="column" fxFlex="100" fxFlex.gt-sm="75" fxFlexLayoutAlign="center center">
                    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly">
                      <div class="form-group" fxFlexFill>
                        <h6><b>Phone numbers are used by HCA and health plan carriers to contact subscribers to resolve issues and provide customer support.</b></h6>
                      </div>
                      <div class="form-group" fxFlex="45" fxFlex.lt-md="95">
                        <label for="emailAddress">Email</label>
                        <input
                          [disabled]="!canEdit"
                          pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                          type="email"
                          maxlength="50"
                          class="form-control"
                          name="emailAddress"
                          id="emailAddress"
                          [(ngModel)]="subscriberComposite.emailAddress"
                          [required]="subscriberComposite.emailNotificationInd"
                          placeholder="Email"
                        />
                      </div>
                      <div fxFlex="45" class="form-group">
                        <label for="cellPhoneNumber">Cell phone number</label>
                        <input
                          [disabled]="!canEdit"
                          pattern="[0-9]*"
                          minlength="10"
                          maxlength="10"
                          type="text"
                          class="form-control"
                          name="cellPhoneNumber"
                          id="cellPhoneNumber"
                          [(ngModel)]="subscriberComposite.cellPhoneNumber"
                        />
                      </div>
                      <div fxFlex="45" class="form-group">
                        <label for="homePhoneNumber">Home phone number</label>
                        <input
                          [disabled]="!canEdit"
                          pattern="[0-9]*"
                          minlength="10"
                          maxlength="10"
                          type="text"
                          class="form-control"
                          name="homePhoneNumber"
                          id="homePhoneNumber"
                          [(ngModel)]="subscriberComposite.homePhoneNumber"
                        />
                      </div>
                      <div fxFlex="45" class="form-group">
                        <label for="workPhoneNumber">Work phone number</label>
                        <input
                          [disabled]="!canEdit"
                          pattern="[0-9]*"
                          minlength="10"
                          maxlength="10"
                          type="text"
                          class="form-control"
                          name="workPhoneNumber"
                          id="workPhoneNumber"
                          [(ngModel)]="subscriberComposite.workPhoneNumber"
                        />
                      </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.lt-md="column">
                      <div class="form-group" fxFlexFill>
                        <div class="k-form-field bigger">
                          <checkbox
                            name="emailNotificationInd"
                            [disabled]="!canEdit"
                            #emailNotificationInd="ngModel"
                            [(ngModel)]="subscriberComposite.emailNotificationInd"
                            (change)="emailSubscriptionChanged()">
                            Opt-in to receive email notifications from PEBB for newsletters, general communications, and any urgent/critical communications related to emergency situations (fires,
                            pandemics, etc.).
                        </checkbox>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="showEmailTermsAndConditions" fxLayoutAlign="space-evenly" fxLayout="column" class="hca-member-warning-card fxGap-medium-lg mgbt-medium">
                      <h3 class="card-title" style="text-align: center">Terms and conditions</h3>
                      <span fxFlexFill>
                        <ol class="hca-termscondition-list">
                          <li>
                            <b>General description</b><br /><br />
                            This agreement is between: <br />
                            The Health Care Authority (HCA), the School Employees Benefits Board (PEBB) Program, the State of Washington, and its officials, agents, and employees <br /><br />
                            <div fxLayout="row" fxLayoutAlign="center">
                              <span><b>AND</b></span>
                            </div>
                            Users of the '"Benefits 24/7"' system.<br /><br />
                            This agreement controls when the programs may use and share an email address registered with the '"Benefits 24/7"' system with the program's contracted business partners. READ THIS USER AGREEMENT CAREFULLY BEFORE CHECKING EITHER BUTTON BELOW.
This agreement does not cover instances in which you give your email address (or other information) directly to our contracted business partners. In such instances, the contracted business partner's terms of use will govern. Please check the terms of use and privacy policies of other entities before providing any information to them through their websites.

                          </li>
                          <li>
                            <b>How the program will use your email address</b><br/><br/>
                            If you provide an email address for the email subscription service, you will receive up-to-date benefit information by email. This is faster and less costly than direct mail. 
                            HCA will not send account-specific communications by email.<br/><br/>
                            Unless required by law, the program will not disclose your personal email address in public record disclosure requests.
                          </li>
                          <li>
                            <b>Keeping your email address up-to-date</b><br /><br />
                            When you provide an email address for the email subscription service, we will assume that is the most accurate and up-to-date way to reach you. 
                            If your email address is incorrect or changes, you will be responsible for updating it in the '"Benefits 24/7"' system. 
                            Neither the program nor your employer can do this for you.<br/>
                            To sign up for the program's email subscription service, select the My Medical/Dental Coverage page in Benefits 24/7. 
                            Choose Subscribe/Unsubscribe to Email Service and enter your email address (we recommend using your personal email address).

                          </li>
                          <li>
                            <b>You will still receive some program communications by regular mail</b><br /><br />
                            The program may continue to send some communications to you by mail, including those required by rules or laws.
                          </li>
                          <li>
                            <b>How to unsubscribe from the program's email subscription service</b><br /><br />
                            You can unsubscribe from the program's email subscription service at any time through the '"Benefits 24/7"' system at no charge. To do so, go to the Current Coverage page in Benefits 24/7, 
                            and select Subscribe/Unsubscribe to Email Service. Check the box 'Unsubscribe and remove my email address from Benefits 24/7.' All requests to unsubscribe must be made 
                            through the '"Benefits 24/7"' system. If you unsubscribe, the program will remove your email address from the email subscription service and mail printed communications to your mailing address on file.

                          </li>
                          <li>
                            <b>Security guidelines</b><br /><br />
                            If we find you have entered profanity into our system, we may delete your registration, and you will have to re-register before you may view your account information online
                            again.
                          </li>
                          <li>
                            <b>Cookies</b><br /><br />
                            Some websites transfer small files, also called 'cookies,' to your computer to collect certain non-personal information. The HCA's website does not transfer cookies to a user's computer. 
                            However, some contracted business partners's websites may transfer cookies to your computer, even if you access their websites through a link posted on the HCA's website. 
                            Please check the privacy policies of the program's contracted business partners to see what policies they have on cookies and similar programs.
                          </li>
                          <li>
                            <b>Governing law</b><br /><br />
                            The laws of the State of Washington govern the terms of this agreement.
                          </li>
                          <li>
                            <b>Limit of liability and indemnification</b><br /><br />
                            To the fullest extent permitted by law, by agreeing to this agreement, you also agree to indemnify and hold harmless the HCA, the program, 
                            the State of Washington, its agencies, officials, agents, and employees, from and against all claims arising out of or resulting from this agreement. 
                            Claims means any financial loss, claim, suit, action, damage, or expense, including but not limited to attorney's fees, 
                            attributable for bodily injury sickness, disease, death, injury, or destruction of tangible property including loss of use.<br/>
                            You expressly agree to indemnify and hold harmless the HCA, the program, the State of Washington, its agencies, officials, agents, and employees, 
                            for any claim arising out of or incident to this agreement. Your obligation to indemnify and hold harmless will not be eliminated by any actual or alleged concurrent 
                            negligence of HCA, the program, the State of Washington, its agencies, officials, agents, and employees.
                          </li>
                          <li>
                            <b>No waiver</b><br /><br />
                            The failure of the HCA, the program, the State of Washington, its agencies, officials, agents, and employees to enforce their rights under this agreement will not be deemed a waiver by that party as to subsequent enforcement of rights.
                          </li>
                          <li>
                            <b>Severance</b><br /><br />
                            If any part of this agreement is declared void by any court of appropriate jurisdiction, such declaration will have no effect on the remaining parts.                          </li>
                          <li>
                            <b>Termination and amendment of agreement</b><br /><br />
                            This agreement is effective until amended or terminated by the HCA, the program, the State of Washington, its officials, agents, and employees. 
                            The HCA, the program, the State of Washington, its officials, agents, and employees may amend or terminate all or part of this agreement at any time without notice to you. 
                            The most current version of this agreement can be found on the Current Coverage page in the "Benefits 24/7" system by selecting 
                            the Subscribe/Unsubscribe to Email Service link, and on the program’s website.
                          </li>
                          <li>
                            <b>Permission</b><br /><br />
                            By selecting '"Accept"' below, you are granting the program permission to use and share your email address under the terms of this agreement. 
                            You understand that if you feel you have received an email that violates the terms of this agreement, you may contact the program to report it.
                            If you select '"Decline,"' you understand that the program will not use or share your email address under the terms of this agreement.<br/>
                            This agreement replaces any previous versions of the program's Terms of Use for its '"Benefits 24/7"' system.
                          </li>
                        </ol>
                      </span>
                      <div fxLayout="row" fxLayoutAlign="space-around" class="mgbt-medium" style="padding-bottom: 20px;">
                        <button [disabled]="!canEdit" type="button" class="btn btn-primary" (click)="submitTermsAndConditions(true)">Accept</button>
                        <button [disabled]="!canEdit" type="button" class="btn" (click)="submitTermsAndConditions(false)">Decline</button>
                      </div>
                    </div>
                  </div>
                  <div fxFlex="0" fxFlex.gt.sm="10"></div>
                </ng-template>
              </ngb-panel>
              <!--Residential Address-->
              <ngb-panel>
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Residential address
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px" [icon]="icons.faHome" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <address
                    #residentialAddress
                    name="residentialAddress"
                    view="panel"
                    [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                    [(ngModel)]="subscriberComposite.residentialAddress"
                  ></address>
                </ng-template>
              </ngb-panel>
              <!-- Mailing Address -->
              <ngb-panel>
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Mailing address
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px" [icon]="icons.faHome" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <address
                    #mailingAddress
                    name="mailingAddress"
                    view="panel"
                    [required]="(subscriberComposite.mailingAddress.memberAddressId || subscriberComposite.mailingAddress.addressLineOne || subscriberComposite.mailingAddress.city)"
                    [disabled]="(isHrms || isOrgTypeToDisableEditFor || !canEdit) && !isHCAEdit"
                    [(ngModel)]="subscriberComposite.mailingAddress"
                  ></address>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
          <div fxFlexFill fxLayout="column" style="padding-top: 20px">
            <div fxFlexFill class="alert alert-danger" *ngIf="memberForm.controls.socialSecurityNumber?.errors?.dualEnrollment">
              <p>
                SSN change cannot be saved. This subscriber has a dual enrollment conflict in PEBB or SEBB. Please contact Outreach and Training for assistance via
                <a target="_blank" href="https://www.fuzeqna.com/perspay/consumer/question.asp">FUZE</a> or by calling 1-800-700-1555.
              </p>
            </div>
            <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between" style="padding-top: 20px">
              <button [disabled]="!canEdit || formSubmitted" fxFlex="20" id="sub-add-submit-btn" 
                (click)="submitMemberForm(memberformContainer)" 
                class="btn btn-primary">Submit</button>
              <button [disabled]="!canEdit" fxFlex="20" id="sub-add-cancel-btn" (click)="undoChanges()" class="btn btn-light">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
