import { lastValueFrom } from 'rxjs';
// ng
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

// ext
import { get, sortBy, find, some } from 'lodash';

import Subscriber from 'src/app/models/subscriber';
import DualEnrollment from 'src/app/models/dualEnrollment';
import Action from 'src/app/models/action';
import ActionType from 'src/app/models/actionType';
import { ActionService } from 'src/app/services/action.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'wiz-dual-enroll',
  templateUrl: 'wiz.dualEnrollment.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DualEnrollmentWizardComponent implements OnInit, OnDestroy {
  @Input() subscriber: Subscriber;
  @Input() hideBackToDash = false;
  @Input() dualEnrollment: DualEnrollment;
  @Output() waive: EventEmitter<void> = new EventEmitter<void>();
  @Output() continue: EventEmitter<void> = new EventEmitter<void>();
  @Output() backToDash: EventEmitter<void> = new EventEmitter<void>();
  isFullBenefits = false;

  constructor(
    private route: ActivatedRoute,
    private actionService: ActionService,
    private lookupService: LookupService
  ) {}

  ngOnInit(): void {
    this.isFullBenefits = this.subscriber.organization?.benefitSetup?.benefitSetupCode === 'FB' || !!this.dualEnrollment?.isSebb;
  }

  ngOnDestroy(): void {

  }

  waiveEnrollment(): void {

  }

  async continueEnrollment(): Promise<void> {
    const action = new Action();
    action.actionDate = new Date();
    const actionTypes = await lastValueFrom(this.lookupService.getLutValues('actionType', ActionType));
    action.actionTypeId = get(find(actionTypes, (at: ActionType) =>  at.actionTypeCode === 'EDEM'), 'actionTypeId');
    action.memberId = this.subscriber.memberId;
    await lastValueFrom(this.actionService.createAction(action));

    this.continue.emit();
  }
}
