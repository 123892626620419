<div *ngIf="!isRetiree && !isCobraOrUnpaid" class="pad-std-container">
  <div class="k-form-field" fxFlex fxLayout>
    <checkbox
      [name]="'waiver' + planType"
      bold
      #waiveInd="ngModel"
      [(ngModel)]="isWaived"
      (change)="onWaiveSelected(isWaived)">
      Waive {{ (planType | lowercase) }} coverage. Waiving coverage means {{ inAdminState ? 'subscriber and their' : 'you and your' }} dependents will not have {{ (planType | lowercase) }} coverage.
      {{ inAdminState ? 'Subscriber' : 'You' }} cannot enroll in {{ (planType | lowercase) }} coverage until the next open enrollment period, or
      until {{ inAdminState ? 'they' : 'you' }} experience a qualifying life event that creates a special open enrollment.
    </checkbox>
  </div>
</div>
<div *ngIf="isRetiree" fxLayout="row"style="margin-left: 15px; padding-bottom: 10px;padding-top:20px;" class="fxGap-medium-sm">
  <div class="k-form-field">
    <checkbox
      [name]="'deferRetiree' + planType"
      bold
      [(ngModel)]="isDeferRetiree"
      (change)="deferRetiree(isDeferRetiree)">
      Defer retiree medical coverage
    </checkbox>
  </div>
  <div class="k-form-field" style="margin-left: 5px;">
    <checkbox
      [name]="'deferMedicaid' + planType"
      bold
      [(ngModel)]="selfPayWaivingMedicalCoverage"
      (change)="deferMedicaid(selfPayWaivingMedicalCoverage)">
      Defer due to Medicaid
    </checkbox>
  </div>
  <br />
  <div style="margin-left: 5px;" class="k-form-field">
    <checkbox
      [name]="'enrollRTL' + planType"
      bold
      [(ngModel)]="isEnrolledRetireeTermLife"
      (change)="enrollRTL.emit(isEnrolledRetireeTermLife)">
      Retiree term life
    </checkbox>
  </div>
</div>

<div *ngIf="isCobraOrUnpaid" fxLayout="row"style="margin-left: 15px; padding-bottom: 10px;padding-top:20px;">
  <div class="k-form-field">
    <checkbox
      [name]="'discontinueEnrollment' + planType"
      bold
      [(ngModel)]="selfPayWaivingMedicalCoverage"
      (change)="disenroll(selfPayWaivingMedicalCoverage)">
      Do not continue or remove subscriber medical coverage
    </checkbox>
  </div>
</div>

<div style="margin-left: 15px;" *ngIf="isUnpaidMedDen">
  <div class="k-form-field">
    <checkbox
      [name]="'enrollBL' + planType"
      bold
      [(ngModel)]="isEnrolledBasicLife"
      (change)="enrollBL.emit(isEnrolledBasicLife)">
      Continue/Enroll Basic Life AD&D
    </checkbox>
  </div>
</div>

