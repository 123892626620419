export default class SelfPayVerificationStatus {
    selfPayVerificationStatusId: string;
    selfPayVerificationStatusCode: string;
    selfPayVerificationStatusName: string;
    approvedInd: boolean;
    deniedInd: boolean;
    pendingInd: boolean;
    approvedPendingInd: boolean;
    soeInd: boolean;
    obsoleteFlag: boolean;
    obsoleteDate: Date;
  
    constructor(selfPayVerificationStatus?) {
      if (selfPayVerificationStatus) {
        this.selfPayVerificationStatusId = selfPayVerificationStatus.selfPayVerificationStatusId;
        this.selfPayVerificationStatusCode = selfPayVerificationStatus.selfPayVerificationStatusCode;
        this.selfPayVerificationStatusName = selfPayVerificationStatus.selfPayVerificationStatusName;
        this.approvedInd = selfPayVerificationStatus.approvedInd;
        this.deniedInd = selfPayVerificationStatus.deniedInd;
        this.pendingInd = selfPayVerificationStatus.pendingInd;
        this.approvedPendingInd = selfPayVerificationStatus.approvedPendingInd;
        this.soeInd = selfPayVerificationStatus.soeInd;
        this.obsoleteFlag = selfPayVerificationStatus.obsoleteFlag;
        this.obsoleteDate = selfPayVerificationStatus.obsoleteDate ? new Date(selfPayVerificationStatus.ObsoleteDate) : null;
      }
    }
  
  
  }
  