<div fxFlexFill fxLayoutAlign="center center" class="card state">
  <div class="card-body">
    <div fxLayout="column" class="card-title">
      <fa-icon class="hca-fontawesome-icon" [icon]="icons.faTasks" size="3x"></fa-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div *ngIf="" fxLayoutAlign="center center">
        <h2>Spouse/State-registerd domestic partner attestation premium surcharge</h2>
      </div>
    </div>
    <ngb-alert class="alert alert-danger" *ngIf="subscriber.lossOfEligibilityDate">Changes to enrollments or eligibility are not permitted on a terminated account.</ngb-alert>
    <div *ngIf="!subscriber?.lossOfEligibilityDate" fxLayout="column">
      <dep-spousal
        (saveReattest)="saveAttestation()"
        [reattesting]="true"
        [effectiveYear]="effectiveYear"
        (updateSpousalAttest)="updateSpousalAttest($event)"
        [lookups]="lookups"
        [dependent]="currentSpouse"
        [enrollmentPeriod]="enrollmentPeriod"
      ></dep-spousal>
    </div>
  </div>
</div>
