import SelfPayOrigin from 'src/app/models/selfPayOrigin';
import MemberAddress from './memberAddress';
import MemberMedicare from './memberMedicare';
import BaseAddressWithCountry from './baseAddressWithCountry';
import MemberFlag from 'src/app/models/memberFlag';

export default class SelfPayMember extends BaseAddressWithCountry {
  memberId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  birthDate: Date;
  socialSecurityNumber: string;
  genderIdentityId: string;
  birthSexId: string;
  emailAddress: string;
  emailNotificationInd: boolean;
  homePhoneNumber: string;
  workPhoneNumber: string;
  cellPhoneNumber: string;
  residentialAddress: MemberAddress;
  mailingAddress: MemberAddress;
  memberSelfPayOrigin: SelfPayOrigin;
  memberMedicare: MemberMedicare;
  isEligibleForLTD = true;
  ltdEffectiveDate: Date;
  ineligibleForLTDFlag: MemberFlag;
  constructor(member?) {
    super(member);

    if (member) {
      this.memberId = member.memberId;
      this.firstName = member.firstName;
      this.middleName = member.middleName;
      this.lastName = member.lastName;
      this.suffix = member.suffix;
      this.birthDate = member.birthDate ? new Date(member.birthDate) : null;
      this.socialSecurityNumber = member.socialSecurityNumber;
      this.genderIdentityId = member.genderIdentityId;
      this.birthSexId = member.birthSexId;
      this.emailAddress = member.emailAddress;
      this.emailNotificationInd = member.emailNotificationInd;
      this.homePhoneNumber = member.homePhoneNumber;
      this.workPhoneNumber = member.workPhoneNumber;
      this.cellPhoneNumber = member.cellPhoneNumber;
      this.residentialAddress = member.residentialAddress ? new MemberAddress(member.residentialAddress) : null;
      this.mailingAddress = member.mailingAddress ? new MemberAddress(member.mailingAddress) : null;
      this.memberSelfPayOrigin = new SelfPayOrigin(member.selfPayOrigin);
      this.memberMedicare = new MemberMedicare(member.selfPayOrigin);
      this.ineligibleForLTDFlag = member.ineligibleForLTDFlag;
      if (this.ineligibleForLTDFlag) {
        this.isEligibleForLTD = !!this.ineligibleForLTDFlag.effectiveEndDate
        this.ltdEffectiveDate = this.ineligibleForLTDFlag.effectiveEndDate || this.ineligibleForLTDFlag.effectiveStartDate || null;
      }
    }
  }
}
