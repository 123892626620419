<div fxFlex fxFlexFill fxFlexLayout="column" class="ie-height-mobile-l container-fluid">
  <div class="state">
    <div class="card" *ngIf="systemUser && currentOrganization">
      <div class="card-body">
        <h1 class="mobile-text-centered">{{currentOrganization.organizationName}}</h1>
        <div class="card mgtp-small">
          <div class="card-body">
            <!-- ACTIVE -->
            <ngb-accordion [destroyOnHide]="false" #accessAccordion="ngbAccordion" activeIds="applicationAccessPanel">
              <ngb-panel id="applicationAccessPanel">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Active Users
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faUsers" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div fxFlexLayout="column" fxFlex="100">
                    <div fxFlexLayout="row" fxLayoutAlign="space-between end">
                      <ul style="font-size: 1rem">
                        <li>Admin
                          <ul>
                            <li>Assign perspay user roles</li>
                            <li>Update organization profile information/contacts</li>
                          </ul>
                        </li>
                        <li>Edit
                          <ul>
                            <li>Add eligible subscribers</li>
                            <li>Assist subscribers with benefit enrollment</li>
                            <li>Approve/Deny life event requests (eg. approve the addition of a dependent)</li>
                            <li>Update/Change enrollment data on behalf of subscriber</li>
                            <li>Terminate coverage</li>
                            <li>Access to reports</li>
                          </ul>
                        </li>
                        <li>Read only
                          <ul>
                            <li>View enrollment and eligibility data for subscribers</li>
                            <li>Access to reports</li>
                          </ul>
                        </li>
                      </ul>
                      <button *ngIf="isHCAAdmin || isPerspayAdmin" type="button" class="btn btn-primary" (click)="addNewSystemUser()"><fa-icon [icon]="icons.faPlusCircle" size="1x"></fa-icon> Add system user</button>
                    </div>
                    <div class="card mgtp-small">
                      <div class="card-body dashboard-grid-parent" style="padding:20px">
                        <access-management-grid 
                          #accessGrid 
                          [gridColumns]="gridColumns" 
                          [gridData]="gridData" 
                          [isHCAAdmin]="isHCAAdmin"
                          [roles]="lookups.userAccessLevels"
                          [isPerspayAdmin]="isPerspayAdmin"
                          [isPerspayEdit]="isPerspayEdit"
                          (disassociate)="disassociate($event)"
                          (accessDataChange)="updateAccess($event)"
                        ></access-management-grid>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="applicationAccessPanelInactive">
                <ng-template ngbPanelHeader let-opened="openedInactive">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!openedInactive" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="openedInactive" [icon]="icons.faMinus"></fa-icon>
                    Inactive Users
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faUsers" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div fxFlexLayout="column" fxFlex="100">
                    <div fxFlexLayout="row" fxLayoutAlign="space-between end">
                      <ul style="font-size: 1rem">
                        <li>Remove the access end date to restore access to a user</li>
                        <li>A user can only be removed if the record has never been claimed</li>  
                      </ul>
                    </div>
                    <div class="card mgtp-small">
                      <div class="card-body dashboard-grid-parent" style="padding:20px">
                        <div *ngIf="!gridDataInactive?.length">No inactive users exist for this organization.</div>
                        <access-management-grid 
                          #inactiveUsersGrid 
                          [gridColumns]="gridColumns" 
                          [gridData]="gridDataInactive" 
                          [isHCAAdmin]="isHCAAdmin"
                          [roles]="lookups.userAccessLevels"
                          [isPerspayAdmin]="isPerspayAdmin"
                          [isInactiveGrid]="true"
                          (disassociate)="disassociate($event)"
                          (remove)="deleteSystemUser($event)"
                          (accessDataChange)="updateAccess($event)"
                        ></access-management-grid>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
            <kendo-dialog
              title="Existing user found with this email address"
              *ngIf="alreadyExistingSystemUser"
              (close)="alreadyExistingSystemUser = null"
              [minWidth]="400"
              width="45vw"
              minHeight="350px"
              maxHeight="45vh"
              class="date-picker-info"
            >
            <div fxFill fxLayout="column">
              <div><b>First Name: </b>{{alreadyExistingSystemUser.firstName}}</div>
              <div><b>Last Name: </b>{{alreadyExistingSystemUser.lastName}}</div>
              <div><b>Email: </b>{{alreadyExistingSystemUser.emailAddress}}</div>
              <div><b>Organization: </b>{{alreadyExistingSystemUser?.userOrganizationRoles[0]?.organizationName}}</div>
              <div>
                <p style="margin: 30px; text-align: center;">
                  Would you like to associate this role with this existing user?
                </p>
              </div>
            </div>
            <kendo-dialog-actions>
              <button kendoButton (click)="alreadyExistingSystemUser = null">No</button>
              <button kendoButton (click)="updateAccess(pendingUserAdd, true)" themeColor="primary">
                Yes
              </button>
            </kendo-dialog-actions>
          </kendo-dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>