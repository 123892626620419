import SOEFormDependent from 'src/app/models/soeFormDependent';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { Event } from '@angular/router';
import { filter, find, get, forEach, map, some } from 'lodash';
import SpecialOpenEnrollmentType from 'src/app/models/specialOpenEnrollmentType';
import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import SOEForm from 'src/app/models/soeForm';
import { Subscription } from 'rxjs';
import { env } from 'src/env/development';
@Component({
  selector: 'soe-add',
  templateUrl: 'soe.add.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SOEAddComponent implements OnInit, OnDestroy {
  @Output() saveSOE: EventEmitter<SpecialOpenEnrollment> = new EventEmitter<SpecialOpenEnrollment>();
  @Input() specialOpenEnrollmentTypes: SpecialOpenEnrollmentType[];
  @Input() soeVerificationStatus: SpecialOpenEnrollmentVerificationStatus[];
  @Input() subscriber: Subscriber;
  @Input() dateMessageMap: { [key: string]: string };
  @Input() canOverride = false;
  @Input() isHCA: boolean = false;
  @ViewChild('soeAddForm') public soeAddForm: NgForm;
  showCustomSOEDescription: boolean = false;
  showCustomSOEDocTypes: boolean = false;
  soe: SpecialOpenEnrollment;
  icons = {
      faCalendar
  };
  selectedSOEType: SpecialOpenEnrollmentType;
  dateMessage: string;

  constructor(private coreService: CoreService) {

  }

  ngOnInit(): void {
    this.soe = new SpecialOpenEnrollment();
    this.soe.specialOpenEnrollmentVerificationStatusId =
      get(find(this.soeVerificationStatus, (vs: SpecialOpenEnrollmentVerificationStatus) => vs.specialOpenEnrollmentVerificationStatusName === 'Draft'), 'specialOpenEnrollmentVerificationStatusId');
  }

  ngOnDestroy(): void {}

  emitSOE(e, formContainer): void {
    this.coreService.markFormControlsAsTouched(this.soeAddForm);
    if (!this.soeAddForm.valid) {
      return this.coreService.popMessage('Fields missing or invalid, please correct and re-submit.', 'error', 2000, this.coreService.getInvalidFields(formContainer));
    } else if (this.subscriber.agencyEffectiveStartDate>this.soe.coverageEffectiveDateOverride) {

      return this.coreService.popMessage((this.isHCA ? "Coverage effective date entered is prior to the agency effective date for this organization." : 
        "Coverage effective date entered is prior to the agency effective date with your organization.  Please contact HCA to resolve this issue."), 'error', 5000);
    } else {
      this.saveSOE.emit(e);
    }
  }

  updateSelectedSOEType(e): void {
    this.selectedSOEType = find(this.specialOpenEnrollmentTypes, (soet) => soet.specialOpenEnrollmentTypeId === e);
    this.showCustomSOEDescription = env.soeTypesWithCustomDescriptions.includes(this.selectedSOEType.specialOpenEnrollmentTypeCode);
    this.showCustomSOEDocTypes = env.soeTypesWithCustomDocTypeInstructions.includes(this.selectedSOEType.specialOpenEnrollmentTypeCode);

    if (this.selectedSOEType && this.dateMessageMap) {
      this.dateMessage = this.dateMessageMap[this.selectedSOEType.specialOpenEnrollmentTypeName];
    } else {
      this.dateMessage = null;
    }
  }
}
