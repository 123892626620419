
// SUBSCRIBER
// /dashboard/sub/:subid
// /attest/tobacco/:subid
// /attest/spousal/:subid/:epid
// /dependents/:subid/:epid
//  /dependents/:subid/epid -- management state
//    /dependents/:subid/:epid/init
//    /dependents/:subid/:epid/type
//    /dependents/:subid/:epid/add
//    /dependents/:subid/:epid/attest
//    /dependents/:subid/:epid/enroll
//    /dependents/:subid/:epid/confirm
// /coverage/:subid/:epid
// /coverage/:subid/:epid/confirm
// /supplemental/:subid/:epid
// /confirm/:subid/:epid

// ng
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import { UploadComponent } from './../modules/subscriber/modules/upload/upload.component';
import { AttestTobaccoComponent } from './../modules/subscriber/modules/attestations/components/tobacco/tobacco.component';
import { SubscriberDashboardComponent } from './../modules/subscriber/subscriber.component';
import { SupplementalComponent } from './../modules/subscriber/modules/supplemental/supplemental.component';
import { CoverageComponent } from './../modules/subscriber/modules/coverage/coverage.component';
import { DependentsComponent } from './../modules/subscriber/modules/dependents/dependents.component';
import { ConfirmationComponent } from '../modules/subscriber/modules/confirmation/confirmation.component';
// guards
import { MasterGuard } from './../guards/master/master.guard';
import { PendingChangesGuard } from './../guards/pendingChanges/pendingChanges.guard';
// resolvers
import { EnrollmentPeriodResolver } from 'src/app/resolvers/enrollmentPeriod.resolver';
import { SubscriberResolver } from './../resolvers/subscriber.resolver';
import { LookupsResolver } from '../resolvers/lookups.resolver';
import { TOUResolver } from '../resolvers/tou.resolver';

const routes: Routes = [
  {
    path: 'subscriber/:id',
    component: SubscriberDashboardComponent,
    resolve: {
      subscriber: SubscriberResolver,
    },
    data: {
      guards: ['auth'],
    },
    canActivate: [MasterGuard],
    runGuardsAndResolvers: 'always',
    canDeactivate: [PendingChangesGuard],
        children: [
          {
            path: 'dependents/:epid',
            component: DependentsComponent,
            canDeactivate: [PendingChangesGuard],
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'upload/:epid',
            component: UploadComponent,
            canDeactivate: [PendingChangesGuard],
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'coverage/:epid',
            component: CoverageComponent,
            canDeactivate: [PendingChangesGuard],
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'attest/:epid',
            component: AttestTobaccoComponent,
            canDeactivate: [PendingChangesGuard],
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'supplemental/:epid',
            component: SupplementalComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'confirmation/:epid',
            component: ConfirmationComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
              termsOfUse: TOUResolver,
            },
            canActivate: [MasterGuard],
          },
        ],
  },
];

export const SubscriberWizardRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(LookupsResolver.addToAllRoutes(routes), {onSameUrlNavigation: 'reload', enableTracing: true});
