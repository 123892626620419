<form #form="ngForm">
  <ng-template #componentTemplate let-item="item" let-index="i">
    <checkbox
      *ngIf="type === 'checkbox' && multi"
      [id]="name + index"
      [name]="name + index"
      [value]="item.key"
      [label]="item.value"
      [disabled]="innerDisabled"
      [required]="innerRequired"
      [readOnly]="innerReadonly"
      [checked]="model.indexOf(item.key) > -1"
      [partOfList]="!(isYesNo && label)"
      (change)="innerChange(item.key)"
      ></checkbox>
    <radio
      *ngIf="type === 'radio' || !multi"
      [name]="name + index"
      [id]="name + index"
      [value]="item.key"
      [label]="!isTobaccoAttestation ? item.value : null"
      [disabled]="innerDisabled"
      [required]="innerRequired"
      [readOnly]="innerReadonly"
      [radioAsCheckbox]="type !== 'radio'"
      [partOfList]="!(isYesNo && label)"
      [(ngModel)]="model"
      (ngModelChange)="innerChange()"
      >
      <ng-container *ngIf="isTobaccoAttestation">
        <ng-container *ngIf="isTobaccoAttestationSubscriber">
          <label class="k-radio-label" *ngIf="item.value === 'Yes'" [for]="name + index + '-chk'"><span>Yes, I am subject to the $25 premium surcharge. I have used tobacco products in the past two months.</span></label>
          <label class="k-radio-label" *ngIf="item.value === 'No'"  [for]="name + index + '-chk'">
            <span *ngIf="isSebb">
              No, I am not subject to the $25 premium surcharge. I have not used tobacco in the past two months or am enrolled in my <a href="https://www.hca.wa.gov/employee-retiree-benefits/living-tobacco-free-sebb" target="_blank">SEBB medical plan's tobacco cessation program</a> (if age 18 or older).
            </span>
            <span *ngIf="!isSebb">
              No, I am not subject to the $25 premium surcharge. I have not used tobacco in the past two months or am enrolled in my <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/living-tobacco-free-pebb">PEBB medical plan's tobacco cessation program</a> (if age 18 or older).
            </span>
          </label>
        </ng-container>
        <ng-container *ngIf="!isTobaccoAttestationSubscriber">
          <label class="k-radio-label" *ngIf="item.value === 'Yes'" [for]="name + index + '-chk'"><span>Yes, I am subject to the $25 premium surcharge. This dependent has used tobacco products in the past two months. </span></label>
          <label class="k-radio-label" *ngIf="item.value === 'No'"  [for]="name + index + '-chk'">
            <span *ngIf="isSebb">
              No, I am not subject to the $25 premium surcharge. This dependent has not used tobacco in the past two months or has enrolled in their 
              <a href="https://www.hca.wa.gov/employee-retiree-benefits/living-tobacco-free-sebb" target="_blank">SEBB medical plan's tobacco cessation program</a> 
              (if age 18 or older), or has accessed information or resources on the 
              <a href="https://teen.smokefree.gov/" target="_blank">Smokefree Teen</a> website (If age 13 to 17).
            </span>
            <span *ngIf="!isSebb">
              No, I am not subject to the $25 premium surcharge. This dependent has not used tobacco in the past two months or has enrolled in their 
              <a href="https://www.hca.wa.gov/employee-retiree-benefits/living-tobacco-free-sebb" target="_blank">PEBB medical plan's tobacco cessation program</a> 
              (if age 18 or older), or has accessed information or resources on the 
              <a href="https://teen.smokefree.gov/" target="_blank">Smokefree Teen</a> website (If age 13 to 17).
            </span>
          </label>
        </ng-container>
      </ng-container>
    </radio>
  </ng-template>

  <label *ngIf="isYesNo && label" class="k-label">{{label}}</label>

  <ul
    *ngIf="orientation === 'horizontal-list' || orientation === 'vertical-list'"
    class="k-radio-list"
    [class.k-list-horizontal]="orientation === 'horizontal-list'"
    [class.k-list-vertical]="orientation === 'vertical-list'"
    style="flex-wrap: wrap;"
    [fxLayoutAlign]="innerStretch && orientation === 'horizontal-list' ? 'space-between' : undefined">
    <li class="k-radio-item" *ngFor="let item of innerItems; let i = index">
      <ng-container *ngTemplateOutlet="componentTemplate; context: { item, i }">

      </ng-container>
    </li>
  </ul>

  <div
    *ngIf="orientation === 'vertical' || orientation === 'horizontal'"
    [class.hca-horizontal]="!innerStretch && orientation === 'horizontal'"
    [fxLayoutAlign]="innerStretch && orientation === 'horizontal' ? 'space-between' : undefined">
    <div
      class="form-group"
      [class.hca-multi-line-checkbox]="orientation === 'vertical'"
      *ngFor="let item of innerItems; let i = index">
      <ng-container *ngTemplateOutlet="componentTemplate; context: { item, i }"></ng-container>
    </div>
  </div>
</form>