<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state" role="group">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="mgbt-small">
          <h2 roll="heading" class="mobile-text-centered mgtp-x-small mgbt-medium-sm">Members associated with this account</h2>
        </div>
        <ngb-accordion [destroyOnHide]="false" #memberAccord="ngbAccordion">
          <ngb-panel *ngFor="let dependent of dependents; let index = index">
            <ng-template ngbPanelHeader let-opened="opened">
              <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                <span *ngIf="dependent.lastName && dependent.firstName">{{ dependent.lastName }}, {{ dependent.firstName }}</span>
                <span *ngIf="!dependent.lastName || !dependent.firstName" style="font-style: italic">--New--</span>
                <span class="float-right">
                  {{ dependent.simplifiedStatus }}
                  <span *ngIf="dependent.simplifiedStatus === 'Pending'"> verification</span>
                  <fa-icon
                    [ngClass]="{
                      'status-pending': dependent.simplifiedStatus === 'Pending',
                      'status-denied': dependent.simplifiedStatus === 'Denied',
                      'status-verified': dependent.simplifiedStatus === 'Verified'
                    }"
                    class="hca-fontawesome-icon"
                    style="margin-left: 15px"
                    [icon]="dependent.simplifiedStatus === 'Verified' ? icons.faUserCheck : dependent.simplifiedStatus === 'Denied' ? icons.faTimesCircle : icons.faUserClock"
                    size="2x"
                  >
                  </fa-icon>
                </span>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
                <div fxLayout="column" class="mgbt-medium">
                  <dependent-details
                    [isSubscriber]="isSubscriber"
                    [isHCAWithEdit]="isHCAWithEdit"
                    [isPerspayWithEdit]="isPerspayWithEdit"
                    [isReadOnly]="isReadOnly"
                    [dependent]="dependent"
                    [lookups]="lookups"
                    [index]="index"
                    [subscriber]="subscriber"
                    (setDivorceWithin60DaysEnrollments)="setDivorceWithin60DaysEnrollments($event.enrollments, $event.enrollment)"
                    (pushSaveDependent)="saveDependentChanges(index, $event, medicareForm, medicareFormContainer)"
                    (pushCancelDependent)="cancelDependentChanges(index)"
                    (pushDeleteDependent)="deleteDependent($event)"
                  >
                </dependent-details>
              </div>
              <div fxLayout="column" *ngIf="dependent.needsCertification">
                <certification
                  [(certifications)]="dependent.relationshipCertifications"
                  [relationship]="dependent.relationship"
                  [certificationTypes]="lookups.certificationTypes"
                  [certificationStatuses]="lookups.relationshipCertificationStatus"
                  [isHCA]="isHCAWithEdit"
                  [isPerspay]="isPerspayWithEdit"
                  [isSubscriber]="isSubscriber"
                  [isReadOnly]="isReadOnly"
                  (pushSaveCertification)="saveCertification(index, $event)"
                  (pushSaveCertificationStatus)="saveCertificationStatus(index, $event)"
                  (pushDeleteCertification)="deleteCertification(index, $event)"
                ></certification>
              </div>
              <form #medicareForm="ngForm" #medicareFormContainer class="mgtp-medium">
                <div fxLayout="row" fxLayoutAlign="start stretch">
                  <div fxLayout="column" fxFlex="60">
                    <span class="soe-form-small-header">Medicare information</span>
                    <medicare #medicareComponent fxFlexFill 
                      [medicareOptions]="lookups.medicareOptions" 
                      [readOnly]="!isHCAWithEdit"
                      [simpleMember]="dependent"
                      [memberMedicare]="dependent.memberMedicare"></medicare>
                  </div>
                  <div fxFlex="35">
                    <div *ngIf="!isHCAWithEdit" class="pad-std-container">
                      <p>
                        To update the Medicare status of your dependent please contact HCA by calling 1-800-200-1004 (toll-free) Monday through Friday, 8 a.m. to 4:30 p.m. or complete and send the
                        appropriate change form to HCA.
                      </p>
                      <br />
                      <a
                        href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=form+e&field_customer_type_tid=All&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC"
                        >Retiree Change Form</a
                      >
                      <a
                        href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=50-0136&field_customer_type_tid=All&field_pebb_document_type_value_selective=Form&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC"
                        >COBRA Change Form</a
                      >
                    </div>
                  </div>
                </div>
                <div *ngIf="isSubscriber && displayRaceEthnicity">
                  <race-ethnicity (saveMemberEthnicities)="saveMemberEthnicities($event)" [ethnicities]="ethnicities" [member]="dependent"></race-ethnicity>
                </div>
              </form>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>
