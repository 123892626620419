<div class="card" fxLayout="column" *ngIf="currentTermRecord">
  <form #termForm="ngForm">
    <div class="card-body fxGap-medium" fxLayout="column">
      <h5>Terminate/Transfer subscriber:</h5>
      <div fxLayout="row" fxLayout.sm ="column" fxLayoutAlign="start start" class="fxGap-medium">
        <div class="k-form-field align-bottom required" fxFlex="30">
          <label for="terminationReason">Termination/Transfer Reason </label>
          <select required [disabled]="disabled" class="form-control" name="terminationReason" (ngModelChange)="termReasonUpdate($event)" [(ngModel)]="currentTermRecord.lossOfEligibilityReasonId" [required]="currentTermRecord.lossOfEligibilityDate">
            <option></option>
            <option *ngFor="let reason of lookups.selfPayTerminationReasons" [ngValue]="reason.reasonId">{{ reason.reasonName }}</option>
          </select>
        </div>
        <div class="k-form-field align-bottom" fxFlex="30" *ngIf="currentTermRecord.lossOfEligibilityReasonId && currentTermRecord.lossOfEligibilityReasonId !== reasonNeverEffective">
          <date-picker
            #terminationDateInput="ngModel"
            name="terminationDateInput"
            [label]="currentTermRecord.lossOfEligibilityReasonId === reasonDeath ? 'Deceased Date' : (currentTermRecord.lossOfEligibilityReasonId === reasonTransfer ? 'Transfer Date' : 'Termination effective date')"
            required
            [disabled]="termDateDisabled(currentTermRecord.lossOfEligibilityReasonId, isHCA, disabled)"
            [max]="deathMonthEnd ?? maxDate"
            enforceLowerLimit
            [(ngModel)]="currentTermRecord.terminationDate"></date-picker>
          <!-- <div *ngIf="terminationDateInput.invalid && (terminationDateInput.dirty) && terminationDateInput.errors?.minError" class="alert alert-danger">
            Cannot terminate coverage prior to the most recent agency effective date.
          </div> -->
          <div *ngIf="terminationDateInput.invalid && (terminationDateInput.dirty) && terminationDateInput.errors?.maxError" class="alert alert-danger">
            Cannot process this future termination date at this time.
          </div>
        </div>
        <div class="k-form-field align-bottom" fxFlex="30" *ngIf="currentTermRecord.lossOfEligibilityDate">
          <label for="benefitEndDate">Benefit End Date</label>
          <div class="input-group">
            <input type="text" readonly class="form-control" name="benefitEndDate" value="{{currentTermRecord.lossOfEligibilityDate | date: 'MM/dd/yyyy'}}">
          </div>
        </div>
      </div>
      <span style="text-align: center;" *ngIf="currentTermRecord.lossOfEligibilityReasonId && currentTermRecord.terminationDate && currentTermRecord.lossOfEligibilityReasonId !== reasonDeath && currentTermRecord.lossOfEligibilityReasonId !== reasonTransfer">
          Are you sure you want to terminate coverage for this member? If this member is the primary subscriber on an account
          their dependent's coverage will be terminated as well.
      </span>
      <span style="text-align: center;" *ngIf="!currentTermRecord.lossOfEligibilityDate && currentTermRecord.lossOfEligibilityReasonId && (currentTermRecord.lossOfEligibilityReasonId === reasonDeath)">
        Are you sure you want to report the death of this subscriber?
      </span>
      <span style="text-align: center;" *ngIf="currentTermRecord.lossOfEligibilityReasonId && currentTermRecord.terminationDate && (currentTermRecord.lossOfEligibilityReasonId === reasonTransfer)">
        Are you sure you want to transfer benefits for this subscriber?
      </span>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="center center" style="padding-top: 20px">
        <button
          *ngIf="currentTermRecord.lossOfEligibilityReasonId && (currentTermRecord.terminationDate || currentTermRecord.lossOfEligibilityReasonId === reasonNeverEffective)"
          (click)="onTerminateMemberCoverage()"
          class="btn btn-primary"
          [disabled]="termDateDisabled(currentTermRecord.lossOfEligibilityReasonId, isHCA, disabled) || termSubmitted"
        >
          Submit changes
        </button>
      </div>
    </div>
  </form>
</div>
