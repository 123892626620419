<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body page-container">
        <h1 *ngIf="showSearchResults">Manage Organizations</h1>
        <div class="dashboard-grid-parent" *ngIf="showSearchResults">
          <program-filter [(agencyFilter)]="agencyFilter"></program-filter>
          <div fxLayout.xs="column" fxLayout="row" class="fxGap-medium" fxLayoutAlign="space-between" style="padding-bottom: 10px; height: 50px;">
            <organization-search style="flex: 70;" (searchSubmitted)="onSearchSubmitted($event)"></organization-search>
            <div class="fxGap-x-small" fxLayoutAlign="flex-end center" aria-live="assertive">
              <button *ngIf="coreService.systemUserHasAccess('admin')" (click)="addOrganization()" class="btn btn-secondary">Add new organization</button>
              <button *ngIf="selectedOrganization" (click)="manageOrganization()" class="btn btn-primary">
                Manage organization
              </button>
            </div>
          </div>
          <generic-grid
            (selectedRow)="onOrganizationSelected($event)"
            [selectBy]="'organizationId'"
            [selectable]="true"
            [selectedKeys]=""
            [pageable]="true"
            [pageSize]="20"
            [gridData]="gridData"
            [gridColumns]="gridColumns"
            [filterable]="true"
          ></generic-grid>
        </div>
        <div *ngIf="organizationSelectedForManagement || addingOrganization">
          <div 
          fxLayout.xs="column"
          fxLayout="row"
          class="fxGap-medium"
          fxLayoutAlign="flex-start center"
          style="padding-bottom: 10px; height: 50px;"
          >
            <button (click)="backToSearchResults()" class="btn btn-primary"><fa-icon [icon]="icons.faArrowLeft" size="1x"></fa-icon> Back to search results</button>
          </div>
          <router-outlet (deactivate)="deactivate($event)" id="userManagementOutlet">
            <organization-management-nav *ngIf="!addingOrganization" [user]="systemUser" [organization]="organizationSelectedForManagement">
            </organization-management-nav>
          </router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
