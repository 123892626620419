export default class PreferredContactMethod {
  preferredContactMethodId: string;
  preferredContactMethodCode: string;
  preferredContactMethodName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(preferredContactMethod?) {
    if (preferredContactMethod) {
      this.preferredContactMethodId = preferredContactMethod.preferredContactMethodId;
      this.preferredContactMethodCode = preferredContactMethod.preferredContactMethodCode;
      this.preferredContactMethodName = preferredContactMethod.preferredContactMethodName;
      this.obsoleteFlag = preferredContactMethod.obsoleteFlag;
      this.obsoleteDate = preferredContactMethod.obsoleteDate ? new Date(preferredContactMethod.ObsoleteDate) : null;
    }
  }

}
