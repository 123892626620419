import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { endsWith } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Document from '../models/document';
import Member from '../models/member';
import PgpKeys from '../models/pgpKeys';

@Injectable({
  providedIn: 'root'
})

export class DocumentService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private sanitizer: DomSanitizer
  ) {}

  getMemberDocumentById(memberId: string, documentId: string, document: Document): Observable<any> {
    return this.http.get(`${env.apiUrl}/member/${memberId}/document/${documentId}`, {responseType: 'blob'})
      .pipe( map((res: any) => {
        const urlCreator = window.URL;
        const blob = new Blob([res], {type: 'application/pdf'});
        let base64String: any;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          base64String = fileReader.result;
          document.documentBlob = new Uint8Array(base64String);
          document.documentImageObject.src = this.sanitizer.bypassSecurityTrustResourceUrl((urlCreator.createObjectURL(blob)));
        };
        if (endsWith(document.documentName, 'pdf')) {
          fileReader.readAsArrayBuffer(res);
        } else {
          fileReader.readAsDataURL(res);
        }
        document.documentContentType = endsWith(document.documentName, 'pdf') ? 'pdf' : 'img';
        document.documentImageObject = {src: this.sanitizer.bypassSecurityTrustResourceUrl((urlCreator.createObjectURL(blob))), alt: document?.documentType?.documentTypeName};
        return document;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  getSOEDocumentById(documentId: string, document: Document): Observable<any> {
    return this.http.get(`${env.apiUrl}/specialopenenrollment/document/${documentId}`, {responseType: 'blob'})
      .pipe( map((res: any) => {
        const urlCreator = window.URL;
        const blob = new Blob([res], {type: 'application/pdf'});
        let imageURL;
        if (endsWith(document.documentName), 'pdf') {
          imageURL = urlCreator.createObjectURL(blob);
        } else {
          imageURL = urlCreator.createObjectURL(res);
        }
        const sanitizedUrl: any = this.sanitizer.bypassSecurityTrustResourceUrl(imageURL);

        let arrayBuffer: any;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          arrayBuffer = fileReader.result;
          document.documentBlob =  new Uint8Array(arrayBuffer);
        };
        fileReader.readAsArrayBuffer(res);
        document.documentContentType = endsWith(document.documentName, 'pdf') ? 'pdf' : 'img';
        document.documentImageObject = {src: sanitizedUrl, alt: document.documentType.documentTypeName};

        return document;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  // relationship document
  // api/member/{memberId}/relationship/{relationshipId}/document/{documentTypeId}
  // "api/member/{memberId}/relationship/{relationshipId}/document/{documentTypeId}
  createDocumentForRelationship(memberId: string, relationshipId: string, documentTypeId: any, document: Document, specialOpenEnrollmentId: string): Observable<Document> {
    const formData = new FormData();
    formData.append('file', document.documentBlob);
    return this.http.post<Document>(`${env.apiUrl}/member/${memberId}/relationship/${relationshipId}/document/${documentTypeId}${this.getSOEPath(specialOpenEnrollmentId)}`, formData)
      .pipe(map(x => this.commonService.createObjects(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // api/member/{memberId}/document/{documentId}/relationship/{relationshipId}
  addDocumentToMember(memberId: string, relationshipId: string, document: Document, enrollmentPeriodId: string, specialOpenEnrollmentId: string): Observable<Document> {
    return this.http.post<Document>(`${env.apiUrl}/member/${memberId}/document/${document.documentId}/relationship/${relationshipId}/enrollmentPeriod/${enrollmentPeriodId}${this.getSOEPath(specialOpenEnrollmentId)}`, document)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // api/document/{documentTypeId}
  addDocumentAdmin(document: Document): Observable<Document[]> {
    const formData = new FormData();
    formData.append('file', document.documentBlob);

    return this.http.post<Document>(`${env.apiUrl}/document/${document.documentTypeId}`, formData)
      .pipe(map(x => this.commonService.createObjects(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }
  
  convertFileToBase64(blob: any): Promise<string> {
    const promise = new Promise<string>((resolve) => {
      const reader: FileReader = new FileReader();
      let keyAsString: string | ArrayBuffer;
  
      reader.onload = function (e: ProgressEvent<FileReader>) {
        const asString = e.target.result as string;
        keyAsString = asString.substr(asString.indexOf('base64,') + 7);

        resolve(keyAsString);
      };

      reader.readAsDataURL(blob);
    });

    return promise;
  }

  // api/document/pgpEncrypt
  async pgpEncrypt(fileBlob: any, sign = false): Promise<Observable<any>> {
    const fileAsString: string = await this.convertFileToBase64(fileBlob.rawFile);
    // const keyAsString: string = await this.convertFileToBase64(keyFileBlob.rawFile);
    const data = { 
      file: fileAsString,
      sign
      //base64EncodedPublicKey: keyAsString
     };

    return this.http.post(`${env.apiUrl}/document/pgpEncrypt`, data, {responseType: 'blob'})
    .pipe( map((res: any) => this.downloadDocument(res, `${fileBlob.name}.pgp`)))
    .pipe(catchError(this.commonService.handleError));
  }

  // api/document/pgpDecrypt
  async pgpDecrypt(fileBlob: any): Promise<Observable<any>> {
    const fileAsString: string = await this.convertFileToBase64(fileBlob.rawFile);
    // const keyAsString: string = await this.convertFileToBase64(keyFileBlob.rawFile);

    const data = {
      file: fileAsString
      // base64EncodedPrivateKey: keyAsString,
      // password: password
    };

    return this.http.post(`${env.apiUrl}/document/pgpDecrypt`, data, {responseType: 'blob'})
      .pipe( map((res: any) => this.downloadDocument(res, `${fileBlob.name}.pgp`)))
      .pipe(catchError(this.commonService.handleError));
  }

  // api/document/pgpSign
  async pgpGenerateKeys(): Promise<Observable<PgpKeys>> {
    return this.http.get(`${env.apiUrl}/document/pgpGenerateKeys`)
      .pipe( map((res: any) => new PgpKeys(res)))
      .pipe(catchError(this.commonService.handleError));
  }

  // api/document/pgpSign
  async pgpSign(fileBlob: any): Promise<Observable<any>> {
    const fileAsString: string = await this.convertFileToBase64(fileBlob.rawFile);
    // const keyAsString: string = await this.convertFileToBase64(keyFileBlob.rawFile);

    const data = {
      file: fileAsString,
      // base64EncodedPrivateKey: keyAsString,
      // password: password
    };

    return this.http.post(`${env.apiUrl}/document/pgpSign`, data, {responseType: 'blob'})
      .pipe( map((res: any) => this.downloadDocument(res, `${fileBlob.name}.pgp`)))
      .pipe(catchError(this.commonService.handleError));
  }

  // api/member/{memberId}/relationship/{relationshipId}/document/{documentId}
  // /api/member/{memberId}/relationship/{relationshipId}/document/{documentId}"
  removeDocumentFromMember(memberId: string, relationshipId: string, document: Document, specialOpenEnrollmentId: string): Observable<Document> {
    return this.http.delete<Document>(`${env.apiUrl}/member/${memberId}/relationship/${relationshipId}/document/${document.documentId}${this.getSOEPath(specialOpenEnrollmentId)}`)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // "/api/member/{memberId}/document/{documentId}/documentType/{documentTypeId}"
  updateRelationshipDocumentType(memberId: string, relationshipId: string, document: Document): Observable<Document> {
    return this.http.put<Document>(`${env.apiUrl}/member/${memberId}/document/${document.documentId}/documentType/${document.documentTypeId}`, document)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getParseableDocumentById(documentId: string): Observable<any> {
    return this.http.get(`${env.apiUrl}/document/${documentId}`, {responseType: 'blob'})
      .pipe( map((res: any) => {
        return res;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  //delete document related record
  deleteDocumentAndRelatedRecords(memberId: string, documentId: string, specialOpenEnrollmentId: string): Observable<Document> {
    return this.http.delete<Document>(`${env.apiUrl}/member/${memberId}/document/deleteall/${documentId}${this.getSOEPath(specialOpenEnrollmentId)}`)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // SOE DOCS
  createDocumentForSOE(soeId: string, memberId: string, documentTypeId: any, document: Document): Observable<Document> {
    const formData = new FormData();
    formData.append('file', document.documentBlob);
    return this.http.post<Document>(`${env.apiUrl}/member/${memberId}/specialOpenEnrollment/${soeId}/document/${documentTypeId}`, formData)
      .pipe(map(x => this.commonService.createObjects(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getSpecialOpenEnrollmentDocuments(soeId: string, memberId: string): Observable<Document[]> {
    return this.http.get(`${env.apiUrl}/member/${memberId}/specialOpenEnrollmentDocuments/${soeId}`)
      .pipe(map(x => this.commonService.createObjects(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createSOEDocument(soeId: string, memberId: string, document: Document): Observable<any> {
    return this.http.post<any>(`${env.apiUrl}/member/${memberId}/specialOpenEnrollmentDocument/${soeId}/${document.documentId}`, null)
      .pipe((x) => x)
      .pipe(catchError(this.commonService.handleError));
  }

  removeDocumentFromSOE(memberId: string, soeId: string, document: Document): Observable<Document> {
    return this.http.delete<Document>(`${env.apiUrl}/member/${memberId}/specialopenenrollment/${soeId}/document/${document.documentId}`)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSOEDocumentType(soeId: string, memberId: string, document: Document): Observable<Document> {
    return this.http.put<Document>(`${env.apiUrl}/member/${memberId}/document/${document.documentId}/documentType/${document.documentTypeId}`, document)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // SelfPay DOCS
  createDocumentForSelfPay(selfPayId: string, memberId: string, documentTypeId: any, document: Document): Observable<Document> {
    const formData = new FormData();
    formData.append('file', document.documentBlob);
    return this.http.post<Document>(`${env.apiUrl}/member/${memberId}/selfPay/${selfPayId}/document/${documentTypeId}`, formData)
      .pipe(map(x => this.commonService.createObjects(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  removeDocumentFromSelfPay(selfPayId: string, document: Document): Observable<Document> {
    return this.http.delete<Document>(`${env.apiUrl}/selfPay/${selfPayId}/document/${document.documentId}`)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSelfPayDocumentType(selfPayId: string, memberId: string, document: Document): Observable<Document> {
    return this.http.put<Document>(`${env.apiUrl}/member/${memberId}/document/${document.documentId}/documentType/${document.documentTypeId}`, document)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // enrollment docs

  updateDocument(documentId: string, document: Document): Observable<Document> {
    return this.http.put<Document>(`${env.apiUrl}/document/${documentId}`, document)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // Data Depot
  createDataDepotDoc(organizationId: string, documentTypeId: string, document: Document): Observable<Document> {
    const formData = new FormData();
    formData.append('file', document.documentBlob);
    return this.http.post<Document>(`${env.apiUrl}/document/datadepot/${organizationId}/${documentTypeId}`, formData)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  removeDataDepotDoc(organizationId: string, document: Document): Observable<Document> {
    return this.http.delete<Document>(`${env.apiUrl}/document/datadepot/${organizationId}/${document.documentId}`)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateDataDepotDoc(organizationId: string, document: Document): Observable<any> {
    return this.http.put<Document>(`${env.apiUrl}/document/datadepot/${organizationId}`, document)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getDataDepotLatestDocument(organizationId: string, documentTypeId: string): Observable<Document> {
    return this.http.get(`${env.apiUrl}/document/datadepot/latest/${organizationId}/${documentTypeId}`)
      .pipe(map((x) => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // Documents specific to organization by document type
  getOrganizationDocuments(organizationId: string, documentTypeId: string): Observable<any> {
    return this.http.get(`${env.apiUrl}/document/organizationdocuments/${organizationId}/${documentTypeId}`)
      .pipe(map((x) => this.commonService.createObjects(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  viewOrganizationDocument(organizationid: string, documentId: string): Observable<any> {
    return this.http.get(`${env.apiUrl}/document/organizationdocument/view/${organizationid}/${documentId}`, {responseType: 'blob'})
      .pipe(res => res)
      .pipe(catchError(this.commonService.handleError));
  }

  // FSA / DCAP
  createDocumentForOrganization(organizationId: string, documentTypeId: any, document: Document): Observable<Document> {
    const formData = new FormData();
    formData.append('file', document.documentBlob);
    return this.http.post<Document>(`${env.apiUrl}/organizations/${organizationId}/document/${documentTypeId}`, formData)
      .pipe(map(x => this.commonService.createObjects(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  removeDocumentFromOrganization(organizationId: string, document: Document): Observable<Document> {
    return this.http.delete<Document>(`${env.apiUrl}/organizations/${organizationId}/document/${document.documentId}`)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  addDocumentToOrganization(organizationId: string, document: Document): Observable<Document> {
    return this.http.post<Document>(`${env.apiUrl}/organizations/${organizationId}/document`, document)
      .pipe(map(x => this.commonService.createObject(Document, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteDocument(documentId: string): Observable<Document> {
    return this.http.delete<Document>(`${env.apiUrl}/document/${documentId}`)
      .pipe(res => res)
      .pipe(catchError(this.commonService.handleError));
  }

  downloadDocument(blobData: any, documentName: string): any {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // this is only for IE, may not be necessary if we are not supporting IE
      (window.navigator as any).msSaveOrOpenBlob(blobData, documentName);
    } else {
      const blob = new Blob([blobData], {type: 'application/octet-stream'});
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      a.href = objectUrl;
      a.download = documentName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    }
  }

  private getSOEPath(specialOpenEnrollmentId): string {
    return specialOpenEnrollmentId ? `/soe/${specialOpenEnrollmentId}` : '';
  }
}
