<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid ie-height-mobile-l">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body">
        <pebb-message [messages]="messages"></pebb-message>
        <h2 class="mobile-text-centered mgtp-small mgbt-medium-sm">{{currentOrganization.organizationName}} Administrative Dashboard</h2>
        <h3 class="h4">Welcome{{systemUser.firstName ? ', ' + systemUser.firstName : ''}} {{systemUser.lastName}}!</h3>
        <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-l hca-dashboard-card-container">
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.subscriberManagement">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUsers" size="2x"></fa-icon></span>
                  <h3>Manage subscribers</h3>
                  <h4 class="h6">Search, view, add or update subscribers</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.access">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxFlexLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserShield" size="2x"></fa-icon></span>
                  <h3>Manage access</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.organizationProfile">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxFlexLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faAddressCard" size="2x"></fa-icon></span>
                  <h3>Organization profile</h3>
                </div>
              </div>
            </div>
          </div>                    
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.relationshipVerification">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxFlexLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Dependent Verification</h3>
                  <h4 class="h6">You have {{totalRelationshipVerifcationsPending}} verification request(s)</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.specialOpenEnrollmentReview">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxFlexLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faUserClock" size="2x"></fa-icon></span>
                  <h3>Special Enrollment Event Verification</h3>
                  <h4 class="h6">You have {{totalSpecialOpenEnrollmentRequests}} special enrollment event request(s)</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.eligibility" *ngIf="isSebb">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon"  [icon]="icons.faFileExport" size="2x"></fa-icon></span>
                  <h3>Eligibility File</h3>
                  <h6>Last eligibility file uploaded <strong>{{submissionFile ? (submissionFile.uploadDate | date) : 'N/A'}}</strong></h6>
                  <h6>The file contained <strong>{{submissionFile ? (submissionFile.submissionFileResponses?.length) : 'N/A' }}</strong> errors</h6>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.billing">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faFileInvoice" size="2x"></fa-icon></span>
                  <h3>Billing file</h3>
                  <h6>Last billing file: <strong>{{latestBillingFile ?  (latestBillingFile.fileDate | date) : 'N/A'}}</strong></h6>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.smarthealth">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faFileAlt" size="2x"></fa-icon></span>
                  <h3>SmartHealth file</h3>
                  <h6>Last SmartHealth file: <strong>{{ latestSmartHealthFile ? (latestSmartHealthFile.fileDate | date) : 'N/A'}}</strong></h6>
                </div>
              </div>
            </div>
          </div> -->
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.reports">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faChartBar" size="2x"></fa-icon></span>
                  <h3 >Reports</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.dataDepot">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faFileLines" size="2x"></fa-icon></span>
                  <h3 >Data Depot</h3>
                  <h4 class="h6" *ngIf="dataDepotLastUploadDate">Last Data Depot File: <strong>{{dataDepotLastUploadDate | date}}</strong></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.billingFile" *ngIf="isSebb">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faFileLines" size="2x"></fa-icon></span>
                  <h3>Billing file</h3>
                  <h4 class="h6" *ngIf="lastBillingFileDate">Last billing file: <strong>{{lastBillingFileDate | date}}</strong></h4>
                </div>
              </div>
            </div>
          </div>          
          <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.subscriberHistory">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faHistory" size="2x"></fa-icon></span>
                  <h3>Subscriber Enrollment History</h3>
                  <h6>View login, enrollment, change history</h6>
                </div>
              </div>
            </div>
          </div>  
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.enrollmentDocuments">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faFileContract" size="2x"></fa-icon></span>
                  <h3>{{totalDocsPendingReview}} enrollment doc(s) awaiting review</h3>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="hca-dashboard-card" fxFlex.xs="100%" [routerLink]="nav.fsaDcap">
            <div class="card-body">
              <div fxLayout="row wrap" fxLayout.xs="column" class="ie-height-mobile-xs">
                
                <div fxLayout="column" fxLayoutAlign.xs="end end">
                  <span><fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faSignal" size="2x"></fa-icon></span>
                  <h3>Medical FSA / DCAP</h3>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>