import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import SelfPayType from 'src/app/models/selfPayType';
import SelfPayForm from 'src/app/models/selfPayForm';
import { CoreService } from 'src/app/services/core.service';
import SelfPayPaymentType from 'src/app/models/selfPayPaymentType';
import { env } from 'src/env/development';
import SelfPay from 'src/app/models/selfPay';
import SelfPayFormSubscriber from 'src/app/models/selfPayFormSubscriber';
import Subscriber from 'src/app/models/subscriber';
@Component({
  selector: 'self-pay-payment',
  templateUrl: 'selfPay.payment.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayPaymentComponent implements OnInit, OnChanges {
  @Output() saveSelfPayPaymentOption: EventEmitter<string> = new EventEmitter<string>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @Input() requestType: SelfPayType;
  @Input() selfPayPaymentType: SelfPayPaymentType;
  @Input() agency: string;
  @Input() selectedSelfPay: SelfPay;
  @Input() subscriber: Subscriber;
  isSEBB: boolean = false;
  paymentOption: string = null; // sending the code will set Id on parent.
  oeSelfPayTypes: string[] = ['OEC', 'OER', 'OEU'];
  constructor(
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.paymentOption = this.selfPayPaymentType?.pmaPaymentTypeCode;
    this.setInit();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    this.setInit();
  }

  setInit() {
    if (env.sebbCode === this.agency) {
      this.isSEBB = true;
    }
  }

  pushContinue(): void {
    if (this.paymentOption || this.paymentOption === '' || this.selectedSelfPay.formJson.deferPaymentOption || this.selectedSelfPay.formJson.continueCurrentPaymentOption) {
      this.saveSelfPayPaymentOption.emit(!this.paymentOption && this.selectedSelfPay.formJson.deferPaymentOption ? 'defer' : this.paymentOption);
    } else {
      this.coreService.popMessage('Please select an option.', 'error', 4000);
    }
  }

  deferPaymentOptionChange() {
    if (!this.selectedSelfPay.formJson?.subscriber?.memberId) {
      this.selectedSelfPay.formJson.subscriber = new SelfPayFormSubscriber(this.subscriber);
    }
    if (this.selectedSelfPay.formJson.deferPaymentOption) {
      this.selectedSelfPay.formJson.subscriber.actionsCode = "deferring";
      this.selectedSelfPay.formJson.subscriber.deferCoverageInd = true;
    }
    else {
      this.selectedSelfPay.formJson.subscriber.actionsCode = "enrolling";
      this.selectedSelfPay.formJson.subscriber.deferCoverageInd = false;
    }
  }  

  pushCancel(): void {
    this.previous.emit();
  }
}
