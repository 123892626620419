import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import OpenEnrollment from '../models/openEnrollment';
import { map, catchError } from 'rxjs/operators';
import { env } from 'src/env/development';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OEService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getOpenEnrollments(): Observable<OpenEnrollment[]> {
    return this.http
      .get<OpenEnrollment[]>(`${env.apiUrl}/openenrollment`)
      .pipe(map(x => this.commonService.createObjects(OpenEnrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getOpenEnrollmentStepsForSubscriber(subscriberId): Observable<OpenEnrollment> {
    return this.http.get<OpenEnrollment>(`${env.apiUrl}/openenrollment/subscriber/${subscriberId}`)
      .pipe(map(x => this.commonService.createObject(OpenEnrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

}
