import { Component, ViewEncapsulation, OnInit, OnChanges, Input, ViewChild, HostListener, SimpleChange, SimpleChanges } from '@angular/core';
import * as dayjs from 'dayjs';
import {some, filter} from 'lodash';
import Enrollment from 'src/app/models/enrollment';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import PlanType from 'src/app/models/planType';
import Subscriber from 'src/app/models/subscriber';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
import { env } from 'src/env/development';

@Component({
  selector: 'coverage-summary',
  templateUrl: 'coverageSummary.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class CoverageSummaryComponent implements OnInit, OnChanges {
  @ViewChild('summary') summary;
  @Input() subscriber: Subscriber;
  @Input() summaryObject: SubscriberEffectiveSummary;
  @Input() enrolled: boolean;
  @Input() step = 0;
  @Input() pageOneStep: number;
  @Input() pageTwoStep: number;
  @Input() enrollmentPeriod: EnrollmentPeriod;
  currentDate = new Date();
  isCobra = false;
  isUnpaid = false;
  isRetiree = false;
  sebbLocallyEligible = false;
  @Input() documentTitleText = '';
  @Input() documentHeaderText = '';
  @Input() enrolledHeader = '';
  @Input() documentFooterText = '';
  @Input() forPdf = false;
  userHasRead = false;
  electablePlanTypes: PlanType[];
  shouldShowPremiums = true;
  shouldShowMedicalCoverageDetail = false;
  shouldShowDentalCoverageDetail = false;
  shouldShowVisionCoverageDetail = false;
  hasSecondPage = false;
  @HostListener('scroll', ['$event.target']) onscroll(elem): void {
    const target = elem.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.userHasRead = true;
    }
  }
  ngOnInit(): void{
    this.electablePlanTypes = filter(this.summaryObject?.electablePlanTypes, (ept: PlanType) => some(env.planTypesToDisplay, (pt: string) => ept.planTypeCode === pt));
    this.shouldShowMedicalCoverageDetail = some(this.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === env.medicalPlanTypeCode);
    this.shouldShowDentalCoverageDetail = some(this.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === env.dentalPlanTypeCode);
    this.shouldShowVisionCoverageDetail = some(this.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === env.visionPlanTypeCode);
    this.shouldShowPremiums = !some(env.memberTypesToHidePremiumsFor, (memberType: string) => memberType === this.summaryObject?.memberType?.memberTypeCode);
    this.isRetiree = env.retireeMemberTypes.includes(this.subscriber?.memberType?.memberTypeCode);
    this.isUnpaid = env.unpaidLeaveMemberTypes.includes(this.subscriber?.memberType?.memberTypeCode);
    this.isCobra = env.cobraMemberTypes.includes(this.subscriber?.memberType?.memberTypeCode);
    this.hasSecondPage = some(this.summaryObject?.electablePlanTypes, (ept: PlanType) => some(env.codesOnSecondPage, (code: string) => code === ept.planTypeCode));
    this.sebbLocallyEligible = this.subscriber?.memberType?.memberTypeCode === 'W';
  }

  ngOnChanges(): void {
    this.electablePlanTypes = filter(this.summaryObject?.electablePlanTypes, (ept) => some(env.planTypesToDisplay, (pt: string) => ept.planTypeCode === pt));
    this.shouldShowMedicalCoverageDetail = some(this.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === env.medicalPlanTypeCode);
    this.shouldShowDentalCoverageDetail = some(this.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === env.dentalPlanTypeCode);
    this.shouldShowVisionCoverageDetail = some(this.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === env.visionPlanTypeCode);
    this.shouldShowPremiums = !some(env.memberTypesToHidePremiumsFor, (memberType: string) => memberType === this.summaryObject?.memberType?.memberTypeCode);
    this.isRetiree = env.retireeMemberTypes.includes(this.subscriber?.memberType?.memberTypeCode);
    this.isUnpaid = env.unpaidLeaveMemberTypes.includes(this.subscriber?.memberType?.memberTypeCode);
    this.isCobra = env.cobraMemberTypes.includes(this.subscriber?.memberType?.memberTypeCode);
    this.sebbLocallyEligible = this.subscriber?.memberType?.memberTypeCode === 'W';
  }
}
