// ng
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import { SelfPayComponent } from 'src/app/modules/subscriber/modules/selfpay/selfpay.component';
import { ProfileComponent } from './../modules/subscriber/modules/profile/profile.component';
import { UploadComponent } from 'src/app/modules/subscriber/modules/upload/upload.component';
import { SubscriberSummaryComponent } from './../modules/subscriber/components/summary/subscriber.summary.component';
import { SubscriberManagementEmploymentComponent } from './../modules/adminPerspay/components/subscriberManagement/components/employment/subscriberManagement.employment.component';
import { SubscriberManagementNotesComponent } from './../modules/adminPerspay/components/subscriberManagement/components/notes/subscriberManagement.notes.component';
import { ConfirmationComponent } from './../modules/subscriber/modules/confirmation/confirmation.component';
import { SupplementalComponent } from './../modules/subscriber/modules/supplemental/supplemental.component';
import { AttestTobaccoComponent } from './../modules/subscriber/modules/attestations/components/tobacco/tobacco.component';
import { CoverageComponent } from './../modules/subscriber/modules/coverage/coverage.component';
import { DependentsComponent } from './../modules/subscriber/modules/dependents/dependents.component';
import { SubscriberDashboardComponent } from './../modules/subscriber/subscriber.component';
import { SubscriberManagementComponent } from 'src/app/modules/adminPerspay/components/subscriberManagement/subscriberManagement.component';
import { SubscriberManagementAddComponent } from '../modules/adminPerspay/components/subscriberManagement/components/add/subscriberManagement.add.component';
import { DependentManagementComponent } from '../modules/subscriber/modules/dependentManagement/dependentManagement.component';
import { AttestTobaccoOutsideComponent } from '../modules/subscriber/modules/attestations/components/tobacco/outsideEnrollmentPeriod/tobacco.outsideEP.component';
import { SOEComponent } from '../modules/subscriber/modules/soe/soe.component';
import { SubscriberManagementSpousalComponent } from '../modules/adminPerspay/components/subscriberManagement/components/spousal/subscriberManagement.spousal.component';
// guards
import { MasterGuard } from './../guards/master/master.guard';
// resolvers
import { EnrollmentPeriodResolver } from './../resolvers/enrollmentPeriod.resolver';
import { SubscriberResolver } from './../resolvers/subscriber.resolver';
import { UserResolver } from './../resolvers/user.resolver';
import { LookupsResolver } from './../resolvers/lookups.resolver';
import { TOUResolver } from '../resolvers/tou.resolver';

const routes: Routes = [
  //   Perspay or HCA as subscriber
  {
    path: 'admin/subscriberManagement/:id',
    component: SubscriberManagementComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      user: UserResolver,
    },
    runGuardsAndResolvers: 'always',
    canActivate: [MasterGuard],
    children: [
      {
        path: 'add',
        component: SubscriberManagementAddComponent,
        resolve: {
          user: UserResolver,
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'summary/:id',
        component: SubscriberSummaryComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
          termsOfUse: TOUResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'profile/:id',
        component: ProfileComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
          termsOfUse: TOUResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'dependents/:id',
        component: DependentManagementComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'soe/:id',
        component: SOEComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
        },
        runGuardsAndResolvers: 'always',
        canActivate: [MasterGuard],
        children: [
          {
            path: 'dependents/:epid',
            component: DependentsComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'upload/:epid',
            component: UploadComponent,
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'coverage/:epid',
            component: CoverageComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'attest/:epid',
            component: AttestTobaccoComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'supplemental/:epid',
            component: SupplementalComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'confirmation/:epid',
            component: ConfirmationComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
              termsOfUse: TOUResolver,
            },
            canActivate: [MasterGuard],
          },
        ],
      },
      {
        path: 'supplemental/:id',
        component: SupplementalComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'eligibility/:id',
        component: SubscriberManagementEmploymentComponent,
        resolve: {
          subscriber: SubscriberResolver,
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'spousal/:id',
        component: SubscriberManagementSpousalComponent,
        resolve: {
          subscriber: SubscriberResolver,
        },
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'notes/:id',
        component: SubscriberManagementNotesComponent,
        resolve: {
          subscriber: SubscriberResolver,
        },
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'dependents/:id',
        component: DependentManagementComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
        },
      },
      {
        path: 'attest/tobacco/:id',
        component: AttestTobaccoOutsideComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'subscriber/:id',
        component: SubscriberDashboardComponent,
        resolve: {
          subscriber: SubscriberResolver,
        },
        data: {
          guards: ['auth'],
        },
        runGuardsAndResolvers: 'always',
        canActivate: [MasterGuard],
        children: [
          {
            path: 'dependents/:epid',
            component: DependentsComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'upload/:epid',
            component: UploadComponent,
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'coverage/:epid',
            component: CoverageComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'attest/:epid',
            component: AttestTobaccoComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'supplemental/:epid',
            component: SupplementalComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
            },
            canActivate: [MasterGuard],
          },
          {
            path: 'confirmation/:epid',
            component: ConfirmationComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              subscriber: SubscriberResolver,
              enrollmentPeriod: EnrollmentPeriodResolver,
              termsOfUse: TOUResolver,
            },
            canActivate: [MasterGuard],
          },
        ],
      },
      {
        path: 'selfPay/:id',
        component: SelfPayComponent,
        data: {
          guards: ['auth']
        },
        resolve: {
          subscriber: SubscriberResolver
        }
      },
      {
        path: 'selfPay/:id/:soe',
        component: SelfPayComponent,
        data: {
          guards: ['auth']
        },
        resolve: {
          subscriber: SubscriberResolver
        }
      }
    ],
  },
];

export const PerspaySubscriberRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(LookupsResolver.addToAllRoutes(routes), { onSameUrlNavigation: 'reload' });
