/**
 * responsible for adding auth headers to ajax calls after user is authenticated.
 * also handles silent renewing a token if a call is more than two minutes since the last time a refresh was called.
 */

// ng
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

// ext
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

// local
import { env } from 'src/env/development';
import { CoreService } from 'src/app/services/core.service';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private oidcSecurityService: OidcSecurityService;
  private coreService: CoreService;
  private authService: AuthService;
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;
    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }
    if (this.coreService === undefined) {
      this.coreService = this.injector.get(CoreService);
    }
    if (this.authService === undefined) {
      this.authService = this.injector.get(AuthService);
    }
    if (this.oidcSecurityService.getConfigurations().length === 0) {
      // oidc not configured yet, most likely this is the request to get the config
      return next.handle(requestToForward);
    } else {
      if (this.oidcSecurityService !== undefined && this.coreService !== undefined) {
        const headers: { [name: string]: string | string[] } = {};

        const token = this.oidcSecurityService.getAccessToken();
        if (req.url.startsWith(env.apiUrl) && token) {
          const lastRefreshed = this.coreService.getTokenLastRefreshed();
          const now = Date.now();
          // if greater than 20m - kick out
          if (now - lastRefreshed > 1200000 && env.disableLogoutTimeout == 'false') {
            console.log(`idle logout.  LastRefreshed: ${lastRefreshed} now: ${now} now - lastRefreshed: ${(now - lastRefreshed)} > 1200000`);
            this.authService.logout();
            return;
          } else if (now - lastRefreshed > 120000) {
            // else it has been 2 mins since it was refreshed - so we're not on every call.
            this.coreService.setTokenLastRefreshed(now);
          }
        }
        if (token !== '') {
          const tokenValue = 'Bearer ' + token;
          headers.Authorization = tokenValue,

          requestToForward = req.clone({ setHeaders: headers });
        }
        return next.handle(requestToForward);
      }
    }
  }
}
