export default class MemberFlag {
    memberFlagId: string;
    memberId: string;
    effectiveStartDate: Date;
    effectiveEndDate: Date;
  
    constructor(memberFlag?) {
      if (memberFlag) {
        this.memberFlagId = memberFlag.memberFlagId;
        this.memberId = memberFlag.memberId;
        this.effectiveStartDate = memberFlag.effectiveStartDate ? new Date(memberFlag.effectiveStartDate) : null;
        this.effectiveEndDate = memberFlag.effectiveEndDate ? new Date(memberFlag.effectiveEndDate) : null;
      }
    }
  
  }
  