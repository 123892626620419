import * as dayjs from 'dayjs';
import AttestationType from './attestationType';
import Response from './response';
import Answer from './answer';
import {map} from 'lodash';

export default class Attestation {
  attestationId: string;
  memberId: string;
  memberName ?: string;
  attestationTypeId: string;
  responseId: string;
  attestationDate: Date;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  attestationType: AttestationType;
  response: Response;
  specialOpenEnrollmentId: string;
  answers: Answer[];

  // Used to communicate data for 834 transmission only (given order of operations for DB save)
  divorceWithin60Days: boolean = null;
  terminationReasonId: string = null;

  constructor(attestation?) {
    if (attestation) {
      this.attestationId = attestation.attestationId || null;
      this.memberId = attestation.memberId || null;
      this.attestationTypeId = attestation.attestationTypeId || null;
      this.responseId = attestation.responseId || null;
      this.attestationDate = attestation.attestationDate ? new Date(attestation.attestationDate) : null;
      this.effectiveStartDate = attestation.effectiveStartDate ? new Date(attestation.effectiveStartDate) : null;
      this.effectiveEndDate = attestation.effectiveEndDate ? new Date(attestation.effectiveEndDate) : null;
      this.createdDate = attestation.createdDate || null;
      this.createdById = attestation.createdById || null;
      this.modifiedDate = attestation.modifiedDate || null;
      this.modifiedById = attestation.modifiedById || null;
      if (attestation.memberName) {
        this.memberName = attestation.memberName;
      }
      this.attestationType = new AttestationType(attestation.attestationType);
      this.response = new Response(attestation.response);
      this.specialOpenEnrollmentId = attestation.specialOpenEnrollmentId || null;
      this.answers = map(attestation.answers, (m) => new Answer(m)) || [];
    }
  }

  isActive(activeForDate?: Date): boolean {
    // If not supplied, use current date
    activeForDate = activeForDate ?? new Date();

    return (dayjs(activeForDate).isSameOrAfter(dayjs(this.effectiveStartDate).startOf('day')) && !this.effectiveEndDate) ||
      (this.effectiveEndDate && dayjs(activeForDate).isBetween(dayjs(this.effectiveStartDate).startOf('day'), dayjs(this.effectiveEndDate).endOf('day')));
  }
}
