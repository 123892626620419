import { OnChanges, SimpleChanges, Injector, } from '@angular/core';
/**
 * Used to display/edit medicare fields for subscribers and dependents
 */

import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgControl } from '@angular/forms';
import { find, filter, cloneDeep } from 'lodash';
import * as dayjs from 'dayjs';

import MedicareOption from 'src/app/models/medicareOption';
import MemberMedicare from 'src/app/models/memberMedicare';
import { DatePickerComponent } from 'src/app/modules/shared/components/datePicker/datePicker.component';
import SimpleMember from 'src/app/models/simpleMember';
import { LowerLimitService } from 'src/app/services/lowerLimit.service';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';

@Component({
  selector: 'medicare',
  templateUrl: 'medicare.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class MedicareComponent implements OnInit, OnChanges {
  @Input() memberMedicare: MemberMedicare;
  @Input() medicareOptions: MedicareOption[] = [];
  @Input() readOnly = false;
  @Input() enrollmentSignatureMinDate: Date;
  @Input() simpleMember: SimpleMember;
  @ViewChild('partAEffectiveDateDatePicker') public partAEffectiveDateDatePicker: DatePickerComponent;
  @ViewChild('partAEndDateDatePicker') public partAEndDateDatePicker: DatePickerComponent;
  @ViewChild('partBEffectiveDateDatePicker') public partBEffectiveDateDatePicker: DatePickerComponent;
  @ViewChild('partBEndDateDatePicker') public partBEndDateDatePicker: DatePickerComponent;
  @ViewChild('partDEffectiveDateDatePicker') public partDEffectiveDateDatePicker: DatePickerComponent;
  @ViewChild('partDEndDateDatePicker') public partDEndDateDatePicker: DatePickerComponent;
  medicareNotEnrolledOptionId: string;
  medicareEnrolledOptionId: string;
  medicareNeverEligibileId: string;
  originalMemberMedicare: MemberMedicare;
  originalPartBEnrolled = false;
  originalPartAEnrolled = false;
  originalPartDEnrolled = false;
  filteredMedicareOptions: MedicareOption[] = [];
  enrollmentSignatureMaxDate: Date;
  actualEnrollmentSignatureMinDate: Date;
  disenrollmentSignatureMaxDate: Date;
  disenrollmentSignatureMinDate: Date;
  medicareDEnabled = false;
  isHCA = false;

  constructor(private inj: Injector, private lowerLimitService: LowerLimitService, private adminService: AdminService, private coreService: CoreService) { }

  async ngOnInit(): Promise<void> {
    // Bug 38617
    this.disenrollmentSignatureMaxDate = dayjs().add(60, 'days').toDate();
    this.enrollmentSignatureMaxDate = dayjs().add(60, 'days').toDate();

    this.medicareNotEnrolledOptionId = find(this.medicareOptions,(o: MedicareOption)=>o.medicareOptionName==='Not Enrolled').medicareOptionId;
    this.medicareNeverEligibileId = find(this.medicareOptions,(o: MedicareOption)=>o.medicareOptionName==='Never Eligible').medicareOptionId;
    this.medicareEnrolledOptionId = find(this.medicareOptions,(o: MedicareOption)=>o.medicareOptionName==='Enrolled').medicareOptionId;
    this.filteredMedicareOptions = filter(this.medicareOptions,(mo: MedicareOption) => mo.medicareOptionName!='Never Eligible');
    if (!this.memberMedicare.medicarePartAMedicareOptionId) {
      // new member medicare record
      this.memberMedicare.medicarePartAMedicareOptionId = this.medicareNotEnrolledOptionId;
    } else {
      this.refreshInitial();
    }

    if (!this.memberMedicare.medicarePartBMedicareOptionId) { 
      this.memberMedicare.medicarePartBMedicareOptionId = this.medicareNotEnrolledOptionId;
    }

    if (!this.memberMedicare.medicarePartDMedicareOptionId) { 
      this.memberMedicare.medicarePartDMedicareOptionId = this.medicareNotEnrolledOptionId;
    }

    this.medicareDEnabled = await lastValueFrom(this.adminService.getFeatureFlag('FEATURE_ENABLE_MEDICARE_D'));

    this.partDEffectiveDateChanged(this.memberMedicare.medicarePartDEffectiveDate, this.memberMedicare.medicarePartDEndDate);

    this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.memberMedicare.medicarePartAMedicareOptionId) {
      //new member medicare record
      this.memberMedicare.medicarePartAMedicareOptionId = this.medicareNotEnrolledOptionId;
    }

    if (!this.memberMedicare.medicarePartBMedicareOptionId) { 
      this.memberMedicare.medicarePartBMedicareOptionId = this.medicareNotEnrolledOptionId;
    }

    if (!this.memberMedicare.medicarePartDMedicareOptionId) { 
      this.memberMedicare.medicarePartDMedicareOptionId = this.medicareNotEnrolledOptionId;
    }
  }

  public refreshInitial(): void {
    this.originalMemberMedicare = cloneDeep(this.memberMedicare);
    this.originalPartBEnrolled = this.originalMemberMedicare?.medicarePartBMedicareOptionId === this.medicareEnrolledOptionId;
    this.originalPartAEnrolled = this.originalMemberMedicare?.medicarePartAMedicareOptionId === this.medicareEnrolledOptionId;
    this.originalPartDEnrolled = this.originalMemberMedicare?.medicarePartDMedicareOptionId === this.medicareEnrolledOptionId;
  }

  // Bug 38617
  // determineDisenrollmentSignatureDateBoundaries(partDEffectiveDate: Date, partDEndDate: Date): void {
  //   if (!!partDEndDate) {
  //     // If no Medicare D end date is present the disenrollment signature date can be entered and must be within 90 days of the Medicare D start date (prior to that start date). 

  //     // EXAMPLE:  
  //     // Medicare D Start Date = 6/1/2024
  //     // Medicare D End Date = blank/not present
  //     // Disenrollment Signature Date minimum allowable date = 6/1/2024 - 90 days = 3/3/2024
  //     // Disenrollment Signature Date maximum allowable date = 6/1/2024 - 1 day = 5/31/2024
  //     this.disenrollmentSignatureMinDate = dayjs(partDEndDate).subtract(90, 'days').toDate();
  //     this.disenrollmentSignatureMaxDate = dayjs(partDEndDate).subtract(1, 'day').toDate();
  //   } else if(!!partDEffectiveDate) {
  //     // When Medicare D end date is present the disenrollment signature date is required and must be within 90 days of the Medicare D end date and must be prior to that end date

  //     // Medicare D Start Date = 6/1/2024
  //     // Medicare D End Date = 6/30/2024
  //     // Disenrollment Signature Date minimum allowable date = 6/30/2024 - 90 days = 4/1/2024
  //     // Disenrollment Signature Date maximum allowable date = 6/30/2024 - 1 day = 6/29/2024
  //     this.disenrollmentSignatureMinDate = dayjs(partDEffectiveDate).subtract(90, 'days').toDate();
  //     this.disenrollmentSignatureMaxDate = partDEffectiveDate;
  //   } else {
  //     this.disenrollmentSignatureMinDate = null;
  //     this.disenrollmentSignatureMaxDate = null;
  //   }
  // }

  partDEndDateChanged(partDEffectiveDate: Date, partDEndDate: Date): void {
    // Bug 38617
    // this.determineDisenrollmentSignatureDateBoundaries(partDEffectiveDate, partDEndDate);
  }

  partDEffectiveDateChanged(partDEffectiveDate: Date, partDEndDate: Date): void {
    // Bug 38617
    // this.determineDisenrollmentSignatureDateBoundaries(partDEffectiveDate, partDEndDate);

    // if (!partDEffectiveDate)
    //   return;
    
    // const startDate = dayjs(partDEffectiveDate);

    // if (partDEffectiveDate)
    //   this.actualEnrollmentSignatureMinDate = startDate.subtract(90, 'days').toDate();
    // else
    //   this.actualEnrollmentSignatureMinDate = this.enrollmentSignatureMinDate;

    // this.enrollmentSignatureMaxDate = startDate.subtract(1, 'day').toDate();
  }

  getMedicareOptions(partEnrolled, originalEffectiveDate) {
    if (!partEnrolled && !originalEffectiveDate) {
      return this.filteredMedicareOptions;
    }
    else {
      return this.medicareOptions;
    }
  }

  disableAllButLastOfMonth(date: Date) {
    const d = dayjs(date);
    const lastday = d.daysInMonth();
    return d.date() != lastday;
  }

  disableAllButFirstOfMonth(date: Date) {
    const d = dayjs(date);
    return d.date() != 1;
  }

  medicareIsValid() {
    if (
      (this.memberMedicare.medicarePartAEffectiveDate && (
        dayjs(this.memberMedicare.medicarePartAEffectiveDate).date()!=1 || 
        this.memberMedicare.medicarePartAEffectiveDate < this.simpleMember.birthDate
      ) ||
      this.memberMedicare.medicarePartAEffectiveDate == null && this.memberMedicare.medicarePartAMedicareOptionId === this.medicareEnrolledOptionId
    )) {
      return "Medicare Part A start date is not valid";
    }

    if ((this.memberMedicare.medicarePartBEffectiveDate && (
      dayjs(this.memberMedicare.medicarePartBEffectiveDate).date()!=1 || 
      this.memberMedicare.medicarePartBEffectiveDate < this.simpleMember.birthDate
      ) ||
      this.memberMedicare.medicarePartBEffectiveDate == null && this.memberMedicare.medicarePartBMedicareOptionId === this.medicareEnrolledOptionId
    )) {
      return "Medicare Part B start date is not valid";
    }

    if (this.memberMedicare.medicarePartAEndDate && dayjs(this.memberMedicare.medicarePartAEndDate).date()!=dayjs(this.memberMedicare.medicarePartAEndDate).daysInMonth()) {
      return "Medicare Part A end date must be the last day of the month";
    }

    if (this.memberMedicare.medicarePartBEndDate && dayjs(this.memberMedicare.medicarePartBEndDate).date()!=dayjs(this.memberMedicare.medicarePartBEndDate).daysInMonth()) {
      return "Medicare Part B end date must be the last day of the month";
    }

    if (this.memberMedicare.medicarePartAEndDate && this.memberMedicare.medicarePartAEffectiveDate>this.memberMedicare.medicarePartAEndDate &&
        (this.memberMedicare.medicarePartAMedicareOptionId !== this.medicareNeverEligibileId || 
          (
            this.memberMedicare.medicarePartAMedicareOptionId === this.medicareNeverEligibileId && 
            !dayjs(this.memberMedicare.medicarePartAEffectiveDate).add(-1).isSame(dayjs(this.memberMedicare.medicarePartAEndDate),'day')
          )
        )
      )
      {
      this.partAEffectiveDateDatePicker.picker.focus();
      return "Medicare Part A start date cannot be greater than its end date.";
    }
    if (this.memberMedicare.medicarePartBEndDate && this.memberMedicare.medicarePartBEffectiveDate>this.memberMedicare.medicarePartBEndDate &&
        (this.memberMedicare.medicarePartBMedicareOptionId !== this.medicareNeverEligibileId || 
          (
            this.memberMedicare.medicarePartBMedicareOptionId === this.medicareNeverEligibileId && 
            !dayjs(this.memberMedicare.medicarePartBEffectiveDate).add(-1).isSame(dayjs(this.memberMedicare.medicarePartBEndDate),'day')
          )  
        )
      ) {
      this.partBEffectiveDateDatePicker.picker.focus();
      return "Medicare Part B start date cannot be greater than its end date.";
    }
    if (this.memberMedicare.medicarePartBEffectiveDate && 
        (this.memberMedicare.medicarePartBEffectiveDate<this.memberMedicare.medicarePartAEffectiveDate || !this.memberMedicare.medicarePartAEffectiveDate)
      ) {
      this.partBEffectiveDateDatePicker.picker.focus();
      return "Medicare Part B start date must be equal to or greater than Medicare part A start date";
    }
    return "";
  }

  medicarePartAOptionChange() {
    if (this.memberMedicare.medicarePartAMedicareOptionId === this.medicareNotEnrolledOptionId && !this.originalMemberMedicare?.medicarePartAEffectiveDate) {
      this.memberMedicare.medicarePartAEffectiveDate = null;
      this.memberMedicare.medicarePartAEndDate = null;
    }
    else if (this.originalMemberMedicare?.medicarePartAEffectiveDate && this.memberMedicare.medicarePartAMedicareOptionId === this.medicareNeverEligibileId &&
        this.memberMedicare.medicarePartAEffectiveDate) {
      //previous medicare exists and changing to never eligible, set end date to start date
      const aEndDate = dayjs(this.memberMedicare.medicarePartAEffectiveDate);
      this.memberMedicare.medicarePartAEndDate = new Date(aEndDate.year(), aEndDate.month(), aEndDate.date()-1);
    }
    else if (this.memberMedicare.medicarePartAMedicareOptionId === this.medicareEnrolledOptionId) {
      this.memberMedicare.medicarePartAEndDate = null;
    }

    this.memberMedicare.medicarePartAEnrolledInd = this.memberMedicare.medicarePartAMedicareOptionId === this.medicareEnrolledOptionId;
  }

  medicarePartBOptionChange() {
    if (this.memberMedicare.medicarePartBMedicareOptionId === this.medicareNotEnrolledOptionId && !this.originalMemberMedicare?.medicarePartBEffectiveDate) {
      this.memberMedicare.medicarePartBEffectiveDate = null;
      this.memberMedicare.medicarePartBEndDate = null;
    }
    else if ((this.originalPartBEnrolled || this.originalMemberMedicare?.medicarePartBMedicareOptionId === this.medicareNeverEligibileId) && this.memberMedicare.medicarePartBMedicareOptionId === this.medicareNeverEligibileId &&
      this.memberMedicare.medicarePartBEffectiveDate) {
      //previous medicare exists and changing to never eligible, set end date to start date
      const bEndDate = dayjs(this.memberMedicare.medicarePartBEffectiveDate);
      this.memberMedicare.medicarePartBEndDate = new Date(bEndDate.year(), bEndDate.month(), bEndDate.date()-1);
    }
    else if (this.memberMedicare.medicarePartBMedicareOptionId === this.medicareEnrolledOptionId) {
      this.memberMedicare.medicarePartBEndDate = null;
    }

    this.memberMedicare.medicarePartBEnrolledInd = this.memberMedicare.medicarePartBMedicareOptionId === this.medicareEnrolledOptionId;
  }

  medicarePartDOptionChange() {
    if (this.memberMedicare.medicarePartDMedicareOptionId === this.medicareNotEnrolledOptionId && !this.originalMemberMedicare?.medicarePartDEffectiveDate) {
      this.memberMedicare.medicarePartDEffectiveDate = null;
      this.memberMedicare.medicarePartDEndDate = null;
    } else if ((this.originalPartDEnrolled || this.originalMemberMedicare?.medicarePartDMedicareOptionId === this.medicareNeverEligibileId) && this.memberMedicare.medicarePartDMedicareOptionId === this.medicareNeverEligibileId &&
      this.memberMedicare.medicarePartDEffectiveDate) {
      //previous medicare exists and changing to never eligible, set end date to start date
      const startDate = dayjs(this.memberMedicare.medicarePartDEffectiveDate);
      this.memberMedicare.medicarePartDEndDate = startDate.subtract(1, 'day').toDate();
    } else if (this.memberMedicare.medicarePartDMedicareOptionId === this.medicareEnrolledOptionId) {
      this.memberMedicare.medicarePartDEndDate = null;
    }

    this.memberMedicare.medicarePartDEnrolledInd = this.memberMedicare.medicarePartDMedicareOptionId === this.medicareEnrolledOptionId;
  }
}
