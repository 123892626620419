import LtdEnrollmentType from './ltdEnrollmentType';

export default class SupplementalLTDBand {
  supplementalLTDBandId: string;
  ageUpperLimit: number;
  ageLowerLimit: number;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  rate50: number;
  rate60: number;
  ltdEnrollmentType: LtdEnrollmentType;

  constructor(supplementalLTDBand?) {
    if (supplementalLTDBand) {
      this.supplementalLTDBandId = supplementalLTDBand.supplementalLtdBandId;
      this.rate50 = supplementalLTDBand.rate_50;
      this.rate60 = supplementalLTDBand.rate_60;
      this.ageLowerLimit = supplementalLTDBand.ageLowerLimit;
      this.ageUpperLimit = supplementalLTDBand.ageUpperLimit;
      this.effectiveStartDate = supplementalLTDBand.EffectiveStartDate ? new Date(supplementalLTDBand.effectiveStartDate) : null;
      this.effectiveEndDate = supplementalLTDBand.EffectiveEndDate ? new Date(supplementalLTDBand.effectiveEndDate) : null;
      this.ltdEnrollmentType = new LtdEnrollmentType(supplementalLTDBand.ltdEnrollmentType);
    }
  }
}
