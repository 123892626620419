import { CoreService } from './../../../../../../services/core.service';
import { Component, ViewEncapsulation, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileService } from 'src/app/services/file.service';
import { faPlus, faMinus, faBan } from '@fortawesome/free-solid-svg-icons';
import SystemUser from 'src/app/models/user';
import Organization from 'src/app/models/organization';
import { sortBy, remove, uniqBy, map, upperFirst, startCase, filter, some, findIndex } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import OrganizationContact from 'src/app/models/organizationContact';
import { NgForm } from '@angular/forms';
import OrganizationAddress from 'src/app/models/organizationAddress';
import { env } from '../../../../../../../env/development';
import ContactType from 'src/app/models/contactType';

@Component({
  selector: 'organization-contact',
  templateUrl: 'organization.contact.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationContactComponent implements OnInit {
  icons = {
    faPlus,
    faMinus,
    faBan
  };
  @Input() isHCAAdmin: boolean;
  @Input() organization: Organization;
  @Input() lookups;
  @Input() contact: OrganizationContact;
  @Input() contactTypes: ContactType[];
  @ViewChild('contactForm') public contactForm: NgForm;
  @Input() agencyPhysicalAddress: OrganizationAddress;
  @Output() removeContact: EventEmitter<OrganizationContact> = new EventEmitter<OrganizationContact>();
  isCollapsed = true;
  selectedContactTypeIds: string[];

  constructor(
    private route: ActivatedRoute,
    private fileService: FileService,
    private notificationService: NotificationService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    if (this.contact.addressIsSameAsPhysicalAddressInd) {
      this.updateAddressToMatchOrganization(this.contact.addressIsSameAsPhysicalAddressInd);
    }

    this.selectedContactTypeIds = this.contact.contactTypes.map((c: ContactType) => c.contactTypeId);
  }

  switchCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  markAllControlsAsTouched(): void {
    if (this.contactForm) {
      this.coreService.markFormControlsAsTouched(this.contactForm);
    }
  }

  updateAddressToMatchOrganization(e?): void {
    if (e) {
      this.contact.city = this.agencyPhysicalAddress.city;
      this.contact.countyId = this.agencyPhysicalAddress.countyId;
      this.contact.addressLineOne = this.agencyPhysicalAddress.addressLineOne;
      this.contact.addressLineTwo = this.agencyPhysicalAddress.addressLineTwo;
      this.contact.state = this.agencyPhysicalAddress.state;
      this.contact.zipcodeNumber = this.agencyPhysicalAddress.zipcodeNumber;
      this.contact.countyId = this.agencyPhysicalAddress.countyId;
    } else {
      this.contact.city = '';
      this.contact.countyId = '';
      this.contact.addressLineOne = '';
      this.contact.addressLineTwo = '';
      this.contact.state = '';
      this.contact.zipcodeNumber = '';
      this.contact.countyId = '';
    }
  }

  contactTypesUpdated(selectedTypes: string[]): void {
    this.contact.contactTypes = this.contactTypes.filter((c: ContactType) => selectedTypes.indexOf(c.contactTypeId) > -1);
  }
}
