import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ViewChild, SimpleChanges } from '@angular/core';
import { FileRestrictions, SuccessEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { env } from 'src/env/development';
import SystemUser from 'src/app/models/user';
import { CommonService } from 'src/app/services/common.service';
import SubmissionFile from 'src/app/models/submissionFile';
import Organization from 'src/app/models/organization';
import { map, some } from 'lodash';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'generic-file-upload',
  templateUrl: 'genericFileUpload.component.html',
  styleUrls: [],
  providers: []
})

export class GenericFileUploadComponent implements OnInit, OnChanges {
  @Input() allowedFileTypes: string[] = [];
  @Input() systemUser: SystemUser;
  @Input() currentOrganization: Organization;
  @Input() type: string;
  @Input() autoUpload = true;
  @Input() multiple = true;
  @Input() maxfilesize = 10485760;
  @Output() successfulFileUploadEvent: EventEmitter<SubmissionFile[]> = new EventEmitter<SubmissionFile[]>();
  @Output() selectEventHandler: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeEventHandler: EventEmitter<any> = new EventEmitter<any>();
  public uploadSaveUrl: string;
  public uploadRemoveUrl = 'ANOTHER API ENDPOINT HERE...';
  public restrictions: FileRestrictions;
  // this is just to get validation to behave.
  public uploadModel: any = {};
  @ViewChild('fileUpload') private fileUpload: any;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.restrictions = {
      allowedExtensions: this.allowedFileTypes
    };
    if (this.type === 'document') {
      this.autoUpload = false;
      this.restrictions.maxFileSize = this.maxfilesize;
      this.uploadSaveUrl = `${env.apiUrl}/relationships/`;
    } else {
      this.restrictions.maxFileSize = this.maxfilesize;
      this.uploadSaveUrl = `${env.apiUrl}/subagencies/${this.currentOrganization?.organizationId}/${this.type}`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.restrictions = {
      allowedExtensions: this.allowedFileTypes
    };
    if (this.type === 'document') {
      this.autoUpload = false;
      this.restrictions.maxFileSize = this.maxfilesize;
      this.uploadSaveUrl = `${env.apiUrl}/relationships/`;
    } else {
      this.restrictions.maxFileSize = this.maxfilesize;
      this.uploadSaveUrl = `${env.apiUrl}/subagencies/${this.currentOrganization?.organizationId}/${this.type}`;
    }
  }

  fileUploadSuccessful(e: SuccessEvent): void {
    const responses = this.commonService.createObjects(SubmissionFile, e.response.body);
    this.successfulFileUploadEvent.emit(responses);
  }

  handleFileSelect(e): void {
    if (e.files && some(e.files, f => f.validationErrors)) {
      if (some(e.files, f => f.validationErrors.length)) {
        return;
      }
    }
    this.selectEventHandler.emit(e);
  }

  clearSelections(documents): void {
    if (this.fileUpload.fileList && this.fileUpload.fileList.files) {
      this.removeEventHandler.emit(new RemoveEvent(map(this.fileUpload.fileList.files, f => f[0]), null));
    }
    const el: any = document.getElementsByClassName('k-clear-selected')[0];
    if (el) {
      el.click();
    }
  }
}
