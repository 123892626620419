// ng
import { Component, ViewEncapsulation, OnInit, Input, ViewChild } from '@angular/core';

// ext
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { find, cloneDeep } from 'lodash';

// local
import Report from 'src/app/models/report';
import { GenericGridComponent } from 'src/app/modules/shared/components/genericGrid/genericGrid.component';

@Component({
  selector: 'report-results',
  templateUrl: 'report.results.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class ReportResultsComponent implements OnInit {
  icons = {
    faClipboardCheck,
  };
  @Input() gridColumns: { [k: string]: string | {} }[] = [];
  @Input() gridData = [];
  @Input() selectedReport: Report;
  @ViewChild('genericGrid') genericGrid: GenericGridComponent;
  
  constructor() {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.selectedReport) {
      //reset paging
      this.genericGrid.state.skip = 0;
    }
  }
}
