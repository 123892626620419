<div fxLayout="column" class="pad-std-container">
  <div fxLayoutAlign="space-between" class="fxGap-medium" fxLayout="column">
    <generic-grid
            [selectBy]="'memberId'"
            [selectable]="false"
            [selectedKeys]=""
            [pageable]="true"
            [pageSize]="20"
            [gridData]="memberNotes"
            [gridColumns]="gridColumns"
            [filterable]="true"
    ></generic-grid>  
    <div *ngIf="newMessage && editable">
      <textarea class="form-control hca-text-box" [(ngModel)]="newMessage.noteText"></textarea>
    </div>
    <div *ngIf="editable">
      <button type="button" class="btn btn-primary" (click)="addNewNote()" *ngIf="!newMessage"><fa-icon [icon]="icons.faPlus" size="1x"></fa-icon> Add</button>
      <button type="button" class="btn btn-primary" (click)="onNoteUpdate(newMessage)" *ngIf="newMessage"><fa-icon [icon]="icons.faSave" size="1x"></fa-icon> Save</button>
    </div>
  </div>
</div>
