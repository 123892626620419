
<nav #parent fxLayout="column" class="navbar navbar-expand-sm mb-3">
  <pebb-header [displayLogo]="true" style="width: 100%" fxFlexFill [isPersPay]="isPersPay"></pebb-header>

  <button *ngIf="!router.url.includes('auth') && router.url !== '/' && !router.url.includes('claim') && !router.url.includes('termsofuse') && !router.url.includes('forbidden')" class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed">&#9776;</button>

  <div fxFlexFill *ngIf="!router.url.includes('auth') && router.url !== '/' && !router.url.includes('claim') && !router.url.includes('termsofuse') && !router.url.includes('forbidden')" [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse">
    <ul fxFlexFill fxLayoutAlign="space-around" class="navbar-nav bg-primary">
      <li class="nav-item main-nav-item fxGap-medium" fxLayoutAlign="center" *ngFor="let item of navItems">
        <a routerLinkActive="active-link" routerLink="{{ item.state }}" class="nav-link" [attr.aria-label]="item.label!=='' ? item.label : item.title ">
          {{ item.title }}
        </a>
      </li>
    </ul>
  </div>
</nav>
