import { Component, Input } from '@angular/core';

@Component({
  selector: 'pebb-footer',
  templateUrl: 'footer.component.html',
  styleUrls: [],
  providers: []
})
export class FooterComponent {
  @Input() version: string;
  @Input() apiVersion: string;
}
