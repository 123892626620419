import Member from './member';
import TransactionSetType from './transactionSetType';

export default class Pay1SyncLock {
  pay1SyncLockId: string;
  subscriberMemberId: string;
  memberId: string;
  subscriberSsn: string;
  subscriberName: string;
  memberSsn: string;
  memberName: string;
  isSubscriberLevel: boolean;
  effectiveStartDate?: Date;
  effectiveEndDate?: Date;
  accountLevel: boolean;

  constructor(source?) {
    if (source) {
      this.pay1SyncLockId = source.pay1SyncLockId;
      this.subscriberMemberId = source.subscriberMemberId;
      this.memberId = source.memberId;
      this.subscriberSsn = source.subscriberSSN;
      this.subscriberName = source.subscriberName;
      this.memberSsn = source.memberSSN;
      this.memberName = source.memberName;
      this.isSubscriberLevel = source.isSubscriberLevel;
      this.effectiveStartDate = source.effectiveStartDate ? new Date(source.effectiveStartDate) : undefined;
      this.effectiveEndDate = source.effectiveEndDate ? new Date(source.effectiveEndDate) : undefined;
      this.accountLevel = !!source.accountLevel;

      if (this.accountLevel) {
        this.memberSsn = null;
        this.memberName = "ACCOUNT LEVEL";
      }
    }
  }
}
