<div style="padding:15px">
  <generic-grid 
    [gridData]="gridData" 
    [gridColumns]="gridColumns"
    [exportable]="true"
    [filterable]="true"
    [pageable]="true"
    [pageSize]="20"
    [selectable]="false"
    [editable]="false"
    [defaultSortColumnIndex]="0"
    [exportFileName]="selectedReport?.reportName"
    #genericGrid
  ></generic-grid>
</div>