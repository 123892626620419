import { env } from './../../env/development';
import { map, find, maxBy, filter, some, each } from 'lodash';
import * as dayjs from 'dayjs';
import Attestation from './attestation';
import Relationship from './relationship';
import Enrollment from './enrollment';
import MemberAddress from './memberAddress';
import MemberWaiver from './memberWaiver';
import PlanType from './planType';
import SelfPay from './selfPay';
import Answer from './answer';
import Employment from './employment';
import MemberPhoneNumber from './memberPhoneNumber';
import MemberMaritalStatus from './memberMaritalStatus';
import MemberType from './memberType';
import SpecialOpenEnrollment from './specialOpenEnrollment';
import BirthSex from './birthSex';
import GenderIdentity from './genderIdentity';
import EnrollmentPeriod from './enrollmentPeriod';
import Reason from './reason';
import ExternalEnrollment from './externalEnrollment';
import MemberEthnicity from './memberEthnicity';
import MemberMedicare from './memberMedicare';
import BaseDependent from './baseDependent';

export function isBetween(start, end, now = null): boolean {
  now = (now && dayjs(now)) || dayjs();

  return now.isSameOrAfter(start) && (!end || now.isSameOrBefore(end));
}



export default class Member extends BaseDependent {
  specialOpenEnrollmentId: string;
  adoptionDate: Date;
  deceasedDate: Date;
  emailAddress: string;
  preferredContactMethodId: string;
  nonEssentialCommunicationInd: boolean;
  emailNotificationInd: boolean;
  languageId: string;
  genderIdentityId: string;
  birthSexId: string;
  birthSex: BirthSex;
  genderIdentity: GenderIdentity;
  eligibleInd: boolean;
  isSubscriberInd: boolean;
  subscriberMemberId: string;
  memberTypeId: string;
  memberType: MemberType;
  eligibilityReasonId?: string;
  lossOfEligibilityReasonId?: string;
  lossOfEligibilityReason?: Reason;
  lossOfEligibilityDate?: Date;
  agencyEffectiveEndDate?: Date;
  agencyEffectiveStartDate?: Date;
  addressIsSameAsSubscriberInd: boolean;
  attestations: Attestation[];
  primaryRelationships: Relationship[];
  secondaryRelationships: Relationship[];
  enrollments: Enrollment[];
  addresses: MemberAddress[];
  memberWaivers: MemberWaiver[];
  waivablePlanTypes: PlanType[];
  answers: Answer[];
  relationshipToSubscriber: Relationship;
  isVerified: boolean;
  fullName: string;
  phoneNumbers: MemberPhoneNumber[];
  eligibilityDate: Date;
  terminationDate: Date;
  memberMaritalStatuses: MemberMaritalStatus[];
  specialOpenEnrollments: SpecialOpenEnrollment[];
  residentialAddress: MemberAddress;
  mailingAddress: MemberAddress;
  mostRecentTobaccoAttestation: Attestation;
  partnershipStartDate?: Date;
  partnershipEndDate?: Date;
  soeEnrollmentPeriod ?: EnrollmentPeriod;
  externalEnrollments: ExternalEnrollment[];
  memberEthnicities: MemberEthnicity[];
  memberMedicare: MemberMedicare;
  isMedicalOnlyOrganization: boolean;
  agencyCode: string;
  languageText: string;
  createdDate: Date;
  isSebb = false;
  tobaccoAttestationInd: boolean;

  constructor(member?) {
    super(member);

    if (member) {
      this.specialOpenEnrollmentId = member.specialOpenEnrollmentId || null;
      this.languageText = member.languageText;
      this.adoptionDate = member.adoptionDate ? new Date(member.adoptionDate) : null;
      this.deceasedDate = member.deceasedDate ? new Date(member.deceasedDate) : null;
      this.socialSecurityNumber = member.socialSecurityNumber || null;
      this.emailAddress = member.emailAddress || null;
      this.preferredContactMethodId = member.preferredContactMethodId || null;
      this.nonEssentialCommunicationInd = member.nonEssentialCommunicationInd ? member.nonEssentialCommunicationInd : false;
      this.addressIsSameAsSubscriberInd = member.addressIsSameAsSubscriberInd;
      this.emailNotificationInd = member.emailNotificationInd ? member.emailNotificationInd : false;
      this.languageId = member.languageId || null;
      this.genderIdentityId = member.genderIdentityId || null;
      this.birthSexId = member.birthSexId || null;
      this.birthSex = member.birthSex ? new BirthSex(member.birthSex) : null;
      this.genderIdentity = member.genderIdentity ? new GenderIdentity(member.genderIdentity) : null;
      this.eligibleInd = member.eligibleInd ? member.eligibleInd : false;
      this.isSubscriberInd = member.isSubscriberInd ? member.isSubscriberInd : false;
      this.subscriberMemberId = member.subscriberMemberId;
      this.memberType = member.memberType ? new MemberType(member.memberType) : null;
      this.memberTypeId = member.memberType ? member.memberType.memberTypeId : null;
      this.attestations = map(member.attestations, (a) => new Attestation(a));
      this.primaryRelationships = map(member.primaryRelationships, (pr) => new Relationship(pr));
      this.secondaryRelationships = map(member.secondaryRelationships, (sr) => new Relationship(sr));
      this.enrollments = map(member.enrollments, (e) => new Enrollment(e));
      this.addresses = map(member.memberAddresses, (ma) => new MemberAddress(ma));
      this.residentialAddress = new MemberAddress(member.residentialAddress);
      this.mailingAddress = new MemberAddress(member.mailingAddress);
      this.phoneNumbers = map(member.memberPhoneNumbers, (mp) => new MemberPhoneNumber(mp)) || [];
      this.memberWaivers = map(member.memberWaivers, (mw) => new MemberWaiver(mw));
      this.waivablePlanTypes = map(member.waivablePlanTypes, (wpt) => new PlanType(wpt));
      this.answers = map(member.answers, (an) => new Answer(an));
      this.memberMaritalStatuses = map(member.memberMaritalStatuses, (mms) => new MemberMaritalStatus(mms));
      this.fullName = member.lastName ? member.lastName + ', ' + member.firstName : null;
      this.eligibilityDate = member.eligibilityDate ? new Date(member.eligibilityDate) : null;
      this.terminationDate = member.terminationDate ? new Date(member.terminationDate) : null;
      this.createdDate = member.createdDate ? new Date(member.createdDate) : null;
      // isMedicalOnlyOrganization needs to happen before SOEs populated
      this.isMedicalOnlyOrganization = member.organization ? member.organization?.benefitSetup?.benefitSetupCode === 'MO' : null;
      // Sync up soe members with medical only flag
      each(member.specialOpenEnrollments, soe => soe.member.isMedicalOnlyOrganization = this.isMedicalOnlyOrganization);
      // map agency code for ease
      this.agencyCode = member.organization ? member.organization?.agency?.agencyCode : 'PEBB';
      if (this.agencyCode === 'SEBB') {
        this.isSebb = true;
      }
      const subscriber = member.isSubscriberInd ? member : null;
      this.specialOpenEnrollments = filter(map(member.specialOpenEnrollments, (soe) => new SpecialOpenEnrollment(soe, subscriber)), s => s.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName !== 'Supplemental' && s.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName !== 'API Import');
      this.relationshipToSubscriber = member.relationshipToSubscriber ? new Relationship(member.relationshipToSubscriber) : new Relationship();
      this.memberMedicare = member.memberMedicare ? new MemberMedicare(member.memberMedicare) : null;

      if (member.eligibilityReasonId) {
        this.eligibilityReasonId = member.eligibilityReasonId;
      }
      if (member.lossOfEligibilityReasonId) {
        this.lossOfEligibilityReasonId = member.lossOfEligibilityReasonId;
      }
      if (member.lossOfEligibilityReason) {
        this.lossOfEligibilityReason = new Reason(member.lossOfEligibilityReason);
      }
      if (member.lossOfEligibilityDate) {
        this.lossOfEligibilityDate = new Date(member.lossOfEligibilityDate);
      }
      if (member.partnershipStartDate) {
        this.partnershipStartDate = new Date(member.partnershipStartDate);
      }
      if (member.partnershipEndDate) {
        this.partnershipEndDate = new Date(member.partnershipEndDate);
      }
      if (member.agencyEffectiveEndDate) {
        this.agencyEffectiveEndDate = new Date(member.agencyEffectiveEndDate);
      }
      if (member.agencyEffectiveStartDate) {
        this.agencyEffectiveStartDate = new Date(member.agencyEffectiveStartDate);
      }
      this.mostRecentTobaccoAttestation = this.getMostRecentTobaccoAttestation(this);
      if (member.externalEnrollments) {
        this.externalEnrollments = map(member.externalEnrollments, (e) => new ExternalEnrollment(e));
      }
      this.memberEthnicities = map(member.memberEthnicities, (me) => new MemberEthnicity(me));
    }
  }

  get isUnderThirteen(): boolean {
    return super.dependentIsUnderThirteen(this.birthDate);
  }

  getPrimaryRelationship(): Relationship {
    const existingRec = find(this.primaryRelationships, (pr) => {
      return pr.relationshipType.relationshipTypeCode !== 'SF';
    });
    if (existingRec === undefined) {
      const newRec = new Relationship();
      return newRec;
    } else {
      return existingRec;
    }
  }

  getMostRecentTobaccoAttestation(m: Member): Attestation {
    const existingRec = maxBy(
      filter(m.attestations, (at: Attestation) => at.attestationType.attestationTypeCode === 'TS'),
      'effectiveStartDate'
    );
    if (existingRec === undefined) {
      const newRec = new Attestation();
      return newRec;
    } else {
      return existingRec;
    }
  }

  getAttestationByTypeAndDate(date: Date, typeCode: string, useLatestIfNotFound = true): Attestation {
    const effectiveAttestation = find(
      this.attestations,
      (a: Attestation) => dayjs(a.effectiveStartDate).isSameOrBefore(dayjs(date), 'day') && a.attestationType.attestationTypeCode === typeCode &&
      (!a.effectiveEndDate || dayjs(a.effectiveEndDate).isAfter(dayjs(date), 'day')));

    if (!effectiveAttestation || !useLatestIfNotFound) {
      return effectiveAttestation;
    } else {
      return  maxBy(filter(this.attestations, (a => a.attestationType.attestationTypeCode === typeCode)), 'effectiveStartDate');
    }
  }

  usesTobaccoForEffectiveDate(coverageEffectiveStartDate: Date, useLatestIfNotFound = true): boolean {
    const hasMedical = this.getCoverageByPlanTypeAndDate('Medical', coverageEffectiveStartDate);
    const canHaveSurcharge =  (this.isSubscriberInd || this.relationshipToSubscriber.simplifiedStatus !== 'Denied');
    const tobaccoAttestation = this.getAttestationByTypeAndDate(coverageEffectiveStartDate, 'TS', useLatestIfNotFound);
    const doesntHaveAttestation = !tobaccoAttestation;
    const defaults = doesntHaveAttestation && !this.isUnderThirteen;
    const answeredYes = tobaccoAttestation && tobaccoAttestation.response && tobaccoAttestation.response.responseCode === 'Y';
    return (hasMedical && canHaveSurcharge && (defaults || answeredYes));
      // either - has some attestation before the period of the right type and indicated 'YES'
  }

  getCoverageByPlanTypeAndDate(planTypeName: string, coverageEffectiveDate: Date): Enrollment {
    return (
      find(
        this.enrollments,
        (e: Enrollment) =>
          e.plan.planType.planTypeName === planTypeName &&
          dayjs(new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1)).isSameOrBefore(dayjs(coverageEffectiveDate), 'day') &&
          (!e.effectiveEndDate || dayjs(e.effectiveEndDate).isAfter(dayjs(coverageEffectiveDate), 'day')) &&
          e.plan?.planCode !== env.dentalWaivePlanCode
      ) || null
    );
  }

  getAllCoverageByDate(coverageEffectiveDate: Date): Enrollment[] {
    return (
      filter(
        this.enrollments,
        (e: Enrollment) =>
          dayjs(new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1)).isSameOrBefore(dayjs(coverageEffectiveDate), 'day') &&
          (!e.effectiveEndDate || dayjs(e.effectiveEndDate).isAfter(dayjs(coverageEffectiveDate), 'day'))
      ) || []
    );
  }

  isExternalEnrolledForSamePlanType(coverageEffectiveDate: Date): boolean {
    // checks to see if there are external enrollments that have the same plan type as one of the subscriber's enrollments and
    // if so, whether or not the external enrollment's  falls within the
    const externalEnrollmentsWithSamePlanType = this.externalEnrollments.filter(o => some(this.enrollments, ee => ee.plan.planTypeId === o.planTypeId));
    return some(externalEnrollmentsWithSamePlanType, (e: ExternalEnrollment) => e.externalEnrollmentConflictsForDate(coverageEffectiveDate));
  }

  isExternalEnrolledForAnyPlanType(coverageEffectiveDate: Date): boolean {
    return some(this.externalEnrollments, (e: ExternalEnrollment) => e.externalEnrollmentConflictsForDate(coverageEffectiveDate));
  }

  isExternalEnrolledForAnyPlanTypeByAgency(coverageEffectiveDate: Date, agencyName: string): boolean {
    return some(this.externalEnrollments, (e: ExternalEnrollment) => e.externalEnrollmentConflictsForDate(coverageEffectiveDate) && e.agency?.agencyName === agencyName);
  }

  isExternalEnrolledAsSubscriberForAnyPlanTypeByAgency(coverageEffectiveDate: Date, agencyName: string): boolean {
    return some(this.externalEnrollments, (e: ExternalEnrollment) => e.externalEnrollmentConflictsForDate(coverageEffectiveDate) && e.agency?.agencyName === agencyName && e.isSubscriberInd);
  }

  isExternalEnrolledForSpecificPlanType(coverageEffectiveDate: Date, planTypeName: 'medical' | 'dental'): boolean {
    let planTypeCode;
    if (planTypeName === 'medical') {
      planTypeCode = env.medicalPlanTypeCode;
    } else if (planTypeName === 'dental') {
      planTypeCode = env.dentalPlanTypeCode;
    }
    return some(this.externalEnrollments, (e: ExternalEnrollment) => e.planType?.planTypeCode === planTypeCode && e.externalEnrollmentConflictsForDate(coverageEffectiveDate));
  }

  isExternalEnrolledForSpecificPlanTypeByAgency(coverageEffectiveDate: Date, planTypeName: 'medical' | 'dental', agencyName: string): boolean {
    let planTypeCode;
    if (planTypeName === 'medical') {
      planTypeCode = env.medicalPlanTypeCode;
    } else if (planTypeName === 'dental') {
      planTypeCode = env.dentalPlanTypeCode;
    }
    return some(this.externalEnrollments, (e: ExternalEnrollment) =>
      e.planType?.planTypeCode === planTypeCode && e.externalEnrollmentConflictsForDate(coverageEffectiveDate) && e.agency?.agencyName === agencyName);
  }
}
