<form #calcForm="ngForm" fxLayout="column" fxLayoutAlign="start start" class="fxGap-small" fxFlexFill #formContainer>
  <!-- 60 PLAN -->
  <h3 *ngIf="!isSEBB">Employee Paid (60-percent plan)</h3>
  <div fxLayout="row" fxFill fxLayoutAlign="space-between center">
    <radio (change)="inWizard ? enrollOrUpdate(selectedPlanId, formContainer) : null" *ngIf="!isSEBB" name="sltdSixtyPlan" style="width: 2em;" [value]="sltdSixtyPlan?.planId" [(ngModel)]="selectedPlanId"></radio>
    <radio (change)="inWizard ? enrollOrUpdate(selectedPlanId, formContainer) : null" fxFill *ngIf="isSEBB" name="sltdSixtyPlan" style="width: 2em;" [value]="sltdSixtyPlan?.planId" [(ngModel)]="selectedPlanId">
      <h3 style="margin-top: 0; margin-bottom: 0;">Employee Paid (60% Default Plan)</h3>
    </radio>
    <ng-container *ngIf="!isSEBB">
      <div class="form-group">
        <label for="grossSalary">Predisability monthly earnings</label>
        <input readonly type="text" class="form-control" name="grossSalary" [(ngModel)]="subscriber.salary" />
      </div>
      <span fxLayoutAlign="center center"><fa-icon class="hca-fontawesome-icon" [icon]="icons.faTimes" size="2x"></fa-icon></span>
      <div class="form-group">
        <label for="percentageRateSixty">Percentage rate</label>
        <input *ngIf="sltdSixtyPlan.rates[0]" readonly type="text" class="form-control" name="percentageRateSixty" [(ngModel)]="sltdSixtyPlan?.rates[0].rateAmt" />
      </div>
      <span fxLayoutAlign="center center"><fa-icon class="hca-fontawesome-icon" [icon]="icons.faEquals" size="2x"></fa-icon></span>
      <div class="form-group">
        <label for="monthlyPremiumSixty">Monthly premium</label>
        <input *ngIf="sltdSixtyPlan.rates[0]" readonly type="text" class="form-control" name="monthlyPremiumSixty" [ngModel]="(((sltdSixtyPlan?.rates[0].rateAmt) / 100) * subscriber.salary).toFixed(2)" />
      </div>
      <div class="form-group">
        <label for="monthlyBenefitAmountSixty">Monthly benefit amount</label>
        <input readonly type="text" class="form-control" name="monthlyBenefitAmountSixty" [ngModel]="(.6 * subscriber.salary).toFixed(2)" />
      </div>
    </ng-container>
  </div>
  <!-- 50 PLAN -->
  <h3 *ngIf="!isSEBB">Employee Paid (50-percent plan)</h3>
  <div fxLayout="row" fxFill fxLayoutAlign="space-between center">
    <radio (change)="inWizard ? enrollOrUpdate(selectedPlanId, formContainer) : null" *ngIf="!isSEBB" name="sltdFiftyPlan" style="width: 2em;" [value]="sltdFiftyPlan?.planId" [(ngModel)]="selectedPlanId"></radio>
    <radio (change)="inWizard ? enrollOrUpdate(selectedPlanId, formContainer) : null" *ngIf="isSEBB" name="sltdFiftyPlan" [value]="sltdFiftyPlan?.planId" [(ngModel)]="selectedPlanId">
      <h3 style="margin-top: 0; margin-bottom: 0;">Employee Paid (50% Buy Down Plan)</h3>
    </radio>
    <ng-container *ngIf="!isSEBB">

    <div class="form-group">
      <label for="grossSalary">Predisability monthly earnings</label>
      <input readonly type="text" class="form-control" name="grossSalary" [(ngModel)]="subscriber.salary" />
    </div>
    <span fxLayoutAlign="center center"><fa-icon class="hca-fontawesome-icon" [icon]="icons.faTimes" size="2x"></fa-icon></span>
    <div class="form-group">
      <label for="percentageRateFifty">Percentage rate</label>
      <input *ngIf="sltdFiftyPlan.rates[0]" readonly type="text" class="form-control" name="percentageRateFifty" [(ngModel)]="sltdFiftyPlan?.rates[0].rateAmt" />
    </div>
    <span fxLayoutAlign="center center"><fa-icon class="hca-fontawesome-icon" [icon]="icons.faEquals" size="2x"></fa-icon></span>
    <div class="form-group">
      <label for="monthlyPremiumFifty">Monthly premium</label>
      <input *ngIf="sltdFiftyPlan.rates[0]" readonly type="text" class="form-control" name="monthlyPremiumFifty" [ngModel]="(((sltdFiftyPlan?.rates[0].rateAmt) / 100) * subscriber.salary).toFixed(2)" />
    </div>
    <div class="form-group">
      <label for="monthlyBenefitAmountFifty">Monthly benefit amount</label>
      <input readonly type="text" class="form-control" name="monthlyBenefitAmountFifty" [ngModel]="(.5 * subscriber.salary).toFixed(2)" />
    </div>
  </ng-container>
  </div>
  <div fxLayout="row" fxFill fxLayoutAlign="space-between center">
    <radio (change)="inWizard ? enrollOrUpdate(selectedPlanId, formContainer) : null" name="basicPlan" [value]="null" [(ngModel)]="selectedPlanId">
      <h3 style="margin-top: 0; margin-bottom: 0;">Decline employee-Paid LTD</h3>
    </radio>
  </div>

  <div *ngIf="isActiveEmployeeType && inManagementState && (isAdmin || selectedPlanId == sltdWaivePlan?.planId)" fxLayout="row" fxFill>
     <div fxFill fxLayout="column">
      <radio (change)="inWizard ? enrollOrUpdate(selectedPlanId, formContainer) : null" name="tempWaiver" [disabled]="!isAdmin" [value]="sltdWaivePlan?.planId" [(ngModel)]="selectedPlanId">
        <h3 style="margin-top: 0; margin-bottom: 0;">Temporary Waiver</h3>
      </radio>
      <span *ngIf="selectedPlanId===sltdWaivePlan?.planId && initialSelectedPlanId===sltdWaivePlan?.planId" style="padding-left: 50px">Effective Date: {{ (selectedSupplementalEnrollment?.effectiveStartDate ? (selectedSupplementalEnrollment?.effectiveStartDate | date) : (coverageEffectiveDate | date)) }}</span>
     </div>
  </div>
  <div *ngIf="isActiveEmployeeType && selectedPlanId === sltdWaivePlan?.planId && initialSelectedPlanId !== selectedPlanId">
    Maximum waiver period is 90 days for employees on leave of absence.  Employer must update the employee-paid election by the 91st day, return to this supplemental section to report an update to the effective date along with the employee's previous election.
  </div>  
  <div *ngIf="isActiveEmployeeType && inManagementState && isAdmin && initialSelectedPlanId !== selectedPlanId 
    && !(initialSelectedPlanId==null && selectedPlanId==null)" class="form-group" fxFlex="40">
    <date-picker
      #effectiveDate="ngModel"
      name="effectiveDate"
      label="Effective Date"
      required
      onlyAllowFirstOfMonth
      [disabled]="isReadOnly || isPerspayWithEdit || isSubscriber"
      enforceLowerLimit
      [min]="inManagementState && (minDateOverride ?? selectedSupplementalEnrollment?.effectiveStartDate ?? subscriber.eligibilityDate)"
      [(ngModel)]="coverageEffectiveDate"></date-picker>
  </div>
  <div *ngIf="!inWizard" fxFlexFill fxLayout="row" fxLayoutAlign="start">
    <button style="margin: 30px" (click)="enrollOrUpdate(selectedPlanId, formContainer)" class="btn btn-primary">Submit Changes</button>
  </div>
</form>