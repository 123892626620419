import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forEach, flatMap, join, sortBy, find, findIndex, filter, map, some, values, pick } from 'lodash';
import { UserService } from 'src/app/services/user.service';
import SystemUser from 'src/app/models/user';
import UserOrganizationRole from 'src/app/models/userOrganizationRole';
import { AdminAccessGridComponent } from './components/grid/adminAccess.grid.component';
import * as dayjs from 'dayjs';
import { SearchComponent } from 'src/app/modules/shared/components/search/search.component';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import UserType from 'src/app/models/userType';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { env } from 'src/env/development';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccessLevel, UserTypeCode, CoreService } from 'src/app/services/core.service';
import { GridDataResult } from '@progress/kendo-angular-grid';

@UntilDestroy()
@Lookups(LookupType.UserAccessLevel, LookupType.UserRole, LookupType.Organization, LookupType.UserType)
@Component({
  selector: 'admin-access',
  templateUrl: 'adminAccess.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class AdminAccessComponent implements OnInit {
  icons = {
    faPlusCircle
  };
  systemUser: SystemUser;
  lookups = {
    organizations: [],
    userRoles: [],
    userAccessLevels: [],
    userTypes: []
  };
  displayNonActiveUsers = true;
  gridColumns = [{
    field: 'organizationOrganizationString',
    title: 'Organizations',
    format: 'string',
    sortable: false
  }, {
    field: 'firstName',
    title: 'First name',
    format: 'string'
  }, {
    field: 'lastName',
    title: 'Last name',
    format: 'string'
  }, {
    field: 'emailAddress',
    title: 'Email',
    format: 'string'
  }];
  isHCAAdmin: boolean;
  perspayRoleId: string;
  userAccesslevelAdminId: string;
  perspayUserType: UserType;
  agencyFilter = '';
  env = env;
  gridData: GridDataResult;
  @ViewChild('adminAccessGrid') public adminAccessGrid: AdminAccessGridComponent;
  @ViewChild('adminSearch') public adminSearch: SearchComponent;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService
  ) {}

  ngOnInit(): void {
    this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);

    this.route.data.pipe(untilDestroyed(this)).subscribe(
      data => {
        this.systemUser = data.user;
        this.lookups.organizations = sortBy(data.lookups.organization, 'organizationName');
        this.lookups.userRoles = data.lookups.userRole;
        this.lookups.userAccessLevels = filter(data.lookups.userAccessLevel, al => al.userAccessLevelCode !== 'SA');
        this.lookups.userTypes = data.lookups.userType;
        this.perspayUserType = find(this.lookups.userTypes, u => u.userTypeCode === 'LA');
      },
      err => console.log(err)
    );
  }

  updateDataset(): void {
    this.spinnerService.hide();
    this.spinnerService.show();
    const gridQuery = this.coreService.kendoGridStateToQueryString(this.adminAccessGrid.state);
    this.userService.getSystemUsersByUserType(this.perspayUserType.userTypeId, this.agencyFilter, this.adminSearch.queryString, gridQuery).pipe(untilDestroyed(this)).subscribe((users) => {
      this.gridData = users;
      this.gridData.data = this.formatDataForGrid(this.gridData.data);
      this.spinnerService.hide();
    });
  }

  formatDataForGrid(systemUsers: SystemUser[]): SystemUser[] {
    forEach(systemUsers, (su: SystemUser) => {
      const perspayAdminRoles: UserOrganizationRole[] = [];
      forEach(su.userOrganizationRoles, (usar: UserOrganizationRole, index) => {
       if (usar.userRoleName === 'Perspay') {
        perspayAdminRoles.push(usar);
       }
      });
      su.perspayAdminRoles = perspayAdminRoles;
      const currentAdminRoles = filter(perspayAdminRoles, (par: UserOrganizationRole) => dayjs().isBetween(par.effectiveStartDate, par.effectiveEndDate || dayjs().add(1, 'd')));
      su.organizationOrganizationString = join(flatMap(currentAdminRoles, 'organizationName'), ', ');
    });
    return systemUsers;
  }

  fetchPerspayUsersFromSearch(): void {
    this.updateDataset();
  }

  addPerspayUser(): void {
    const localAdminId = find(this.lookups.userTypes, (ut) => ut.userTypeCode === 'LA').userTypeId;
    const newSystemUser = new SystemUser();
    newSystemUser.userTypeId = localAdminId;
    newSystemUser.systemUserId = null;
    newSystemUser.emailAddress = null;
    newSystemUser.firstName = '';
    newSystemUser.lastName = '';
    if (!this.gridData) {
      this.gridData = { data: [], total: 0 };
    }
    this.gridData.data.unshift(newSystemUser);
  }

  async saveRole(updateObject: {role: UserOrganizationRole, userIndex: number, userRoleIndex: number}): Promise<void> {
    const roleRec = updateObject.role;
    let updatedRole: UserOrganizationRole;

    const parentRecIndex = findIndex(this.gridData.data, (psu: SystemUser) => psu.systemUserId === roleRec.systemUserId);
    this.spinnerService.show();
    if (roleRec.systemUserAccessId) {
      updatedRole = await lastValueFrom(this.userService.updateUserRole(roleRec.systemUserId, roleRec));
      this.coreService.popMessage('System user role updated.', 'success', 4000);
      // back end issue, eff end null on return but saving ok.
      // this.perspaySystemUsers[parentRecIndex].userOrganizationRoles[updateObject.userRoleIndex] = updatedRole;
    } else {
      delete roleRec.systemUserAccessId;
      updatedRole = await lastValueFrom(this.userService.createUserRole(roleRec.systemUserId, roleRec));
      this.gridData.data[parentRecIndex].userOrganizationRoles.push(updatedRole);
      this.coreService.popMessage('System user role added.', 'success', 4000);
    }
    this.spinnerService.hide();
    this.gridData.data = this.formatDataForGrid(this.gridData.data);
  }

  async saveSystemUser(systemUser: SystemUser): Promise<void> {
    const matchingSystemUsers = await lastValueFrom(this.userService.validateEmail(systemUser.emailAddress));
    if(matchingSystemUsers.length && some(matchingSystemUsers, (su: SystemUser) => some(su.userOrganizationRoles, (or) => or?.userRoleName !== 'HCA'))) {
      return this.coreService.popMessage('A system user already exists with this email address.', 'error', 4000);
    }
    let updatedSystemUser: SystemUser;
    this.spinnerService.show();
    let created;
    if (systemUser.systemUserId) {
      updatedSystemUser = await lastValueFrom(this.userService.updateUser(systemUser));
      this.coreService.popMessage('System user updated.', 'success', 4000);
    } else {
      delete systemUser.systemUserId;
      updatedSystemUser = await lastValueFrom(this.userService.createUser(systemUser));
      this.coreService.popMessage('System user added.', 'success', 4000);
      created = true;
    }
    this.spinnerService.hide();
    if (created) {
      const parentRecIndexFiltered = findIndex(this.gridData.data, (psu: SystemUser) => psu.systemUserId === systemUser.systemUserId);
      this.gridData.data[parentRecIndexFiltered] = updatedSystemUser;
    }
    this.gridData.data = this.formatDataForGrid(this.gridData.data);
  }

  async disassociate(s: SystemUser): Promise<void> {
    let releasedUser;
    try {
      this.spinnerService.show();
      releasedUser = await lastValueFrom(this.userService.disassociateAdminUser(s.systemUserId, s.userOrganizationRoles[1]?.organizationId));
      const indexPers = findIndex(this.gridData.data, gd => gd.systemUserId === releasedUser.systemUserId);
      const indexFiltered = findIndex(this.gridData.data, gd => gd.systemUserId === releasedUser.systemUserId);
      this.gridData.data.splice(indexPers, 1, releasedUser);
      this.adminAccessGrid.reSetDataForGrid();
      this.spinnerService.hide();
    } catch (err) {
      this.spinnerService.hide();
      console.log('error', err);
    }
  }
}
