import SelfPayFormDependent from './selfPayFormDependent';
import SelfPayFormSpouse from './selfPayFormSpouse';
import { map, cloneDeep } from 'lodash';
import SelfPayFormSubscriber from './selfPayFormSubscriber';
import SelfPayOrigin from './selfPayOrigin';
import Plan from './plan';



export default class SelfPayForm {
  requestedChanges = {
    'Add Dependents': false,
    'Remove Dependents': false,
    'Change Medical Plan': false,
    'Change Dental Plan': false,
    'Enroll After Waiving Medical': false,
    'Waive Medical Due to enrollment in other employer-based group medical, a TRICARE plan, or Medicare': false
  };
  paymentOptions = {
    pensionDeduction: false,
    automaticWithdrawl: false,
    checkOrMoneyOrder: false,
    defferedEnrollment: false
  };
  ltdPlanName: string;
  subscriber: SelfPayFormSubscriber;
  subscriberMedicalCovered: boolean;
  subscriberDentalCovered: boolean;
  subscriberTobaccoUseInd: boolean;
  selfPayReason: string;
  dateOfService: Date;
  spouse: SelfPayFormSpouse;
  dependents: SelfPayFormDependent[];
  origin?: SelfPayOrigin;
  medicalPlan: Plan;
  medicalPlans: Plan[];
  dentalPlan: Plan;
  visionPlan: Plan;
  continueCurrentSelectedVision = false;
  continueCurrentSelectedDental = false;
  continueCurrentSelectedMedical = false;
  continueCurrentPaymentOption = false;
  mailingAddressSameAsResidential: boolean;
  deferPaymentOption: boolean = false;

  constructor(selfPayForm?) {      
      this.requestedChanges = selfPayForm?.requestedChanges ? selfPayForm.requestedChanges : {
        'Add Dependents': false,
        'Remove Dependents': false,
        'Change Medical Plan': false,
        'Change Dental Plan': false,
        'Enroll After Waiving Medical': false,
        'Waive Medical Due to enrollment in other employer-based group medical, a TRICARE plan, or Medicare': false
      };
      this.paymentOptions = selfPayForm?.paymentOptions ? selfPayForm.paymentOptions : {
        pensionDeduction: false,
        automaticWithdrawl: false,
        checkOrMoneyOrder: false,
        defferedEnrollment: false
      };
      this.subscriber = selfPayForm?.subscriber ? new SelfPayFormSubscriber(selfPayForm?.subscriber) : new SelfPayFormSubscriber();
      if (selfPayForm?.subscriber?.residentialAddress) {
        //set residential address
        selfPayForm.subscriber.residentialAddress.addressLineOne = selfPayForm?.subscriber.addressLineOne;
        selfPayForm.subscriber.residentialAddress.addressLineTwo = selfPayForm?.subscriber.addressLineTwo;
        selfPayForm.subscriber.residentialAddress.city = selfPayForm?.subscriber.city;
        selfPayForm.subscriber.residentialAddress.state = selfPayForm?.subscriber.state;
        selfPayForm.subscriber.residentialAddress.zipcodeNumber= selfPayForm?.subscriber.zipcodeNumber;
        selfPayForm.subscriber.residentialAddress.countyId = selfPayForm?.subscriber.countyId;
        selfPayForm.subscriber.residentialAddress.countryId = selfPayForm?.subscriber.countryId;
      }
      this.subscriberMedicalCovered = selfPayForm?.subscriberMedicalCovered;
      this.subscriberDentalCovered = selfPayForm?.subscriberDentalCovered;
      this.subscriberTobaccoUseInd = selfPayForm?.subscriberDentalCovered;
      this.spouse = selfPayForm?.spouse ? new SelfPayFormSpouse(selfPayForm.spouse) : new SelfPayFormSpouse({});
      this.dependents = selfPayForm?.dependents ? map(selfPayForm?.dependents, (d) => new SelfPayFormDependent(d)) : null;
      this.selfPayReason = selfPayForm?.selfPayReason;
      this.dateOfService = selfPayForm?.dateOfService ? new Date(selfPayForm?.dateOfService) : null;
      this.origin = selfPayForm?.orgin ? new SelfPayOrigin(selfPayForm.orgin) : null;
      this.ltdPlanName = selfPayForm?.ltdPlanName;
      this.medicalPlan = selfPayForm?.medicalPlan || new Plan();
      this.dentalPlan = selfPayForm?.dentalPlan || new Plan();
      this.visionPlan = selfPayForm?.visionPlan || new Plan();
      this.medicalPlans = selfPayForm?.medicalPlans || [];
      this.mailingAddressSameAsResidential = selfPayForm?.mailingAddressSameAsResidential;
      this.deferPaymentOption = selfPayForm?.deferPaymentOption;
      this.continueCurrentSelectedVision = selfPayForm?.continueCurrentSelectedVision;
      this.continueCurrentSelectedDental = selfPayForm?.continueCurrentSelectedDental;
      this.continueCurrentSelectedMedical = selfPayForm?.continueCurrentSelectedMedical;
      this.continueCurrentPaymentOption = selfPayForm?.continueCurrentPaymentOption;
  }
}

