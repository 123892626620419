<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
  <div class="card-body">
    <form #soeAddForm="ngForm" (ngSubmit)="emitSOE(soe,formContainer)" #formContainer>
      <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
        <div class="form-group required" fxFlex="35">
          <label for="soeType">Select the applicable special open enrollment event</label>
          <select [disabled]="subscriber?.lossOfEligibilityDate"  [(ngModel)]="soe.specialOpenEnrollmentTypeId" (ngModelChange)="updateSelectedSOEType($event)" required class="form-control" 
            name="soeType" id="soeType">
            <option></option>
            <option *ngFor="let soeType of specialOpenEnrollmentTypes | isObsolete" [ngValue]="soeType.specialOpenEnrollmentTypeId">{{ soeType.specialOpenEnrollmentTypeName }}</option>
          </select>
        </div>
        <div class="form-group" fxFlex="25">
          <date-picker
            #dateOfService="ngModel"
            name="dateOfService"
            label="Event Date"
            required
            [enforceLowerLimit]="true"
            [lowerLimitOffset]="true"
            [disabled]="subscriber?.lossOfEligibilityDate"
            [extraMessage]="dateMessage"
            [(ngModel)]="soe.eventDate"></date-picker>
        </div>
        <div class="form-group" fxFlex="25" *ngIf="canOverride && selectedSOEType?.specialOpenEnrollmentTypeName !== 'Birth or adoption'">
          <date-picker
            #coverageEffectiveDateOverride="ngModel"
            name="coverageEffectiveDateOverride"
            label="Coverage Effective Date Override"
            [enforceLowerLimit]="true"
            [agencyCode]="subscriber?.organization?.agency?.agencyCode"
            [coverageEffectiveDate]="soe.eventDate"
            [onlyAllowFirstOfMonth]="true"
            [disabled]="subscriber?.lossOfEligibilityDate"
            [extraMessage]="'Use this field to override effective date calculation if necessary.'"
            [(ngModel)]="soe.coverageEffectiveDateOverride"></date-picker>
        </div>
        <div fxLayout="column" class="form-group">
          <div class="mgtp-medium">
            <button [disabled]="subscriber?.lossOfEligibilityDate" type="submit" class="btn btn-primary">Add new event</button>
          </div>
        </div>
      </div>
      <h4>
        <ng-container *ngIf="showCustomSOEDescription">
          <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'COC'">
            You or your dependent experience a disruption of care for active and ongoing treatment that could function as a reduction in benefits. 
            Requires approval by the {{subscriber?.agencyCode}} Program.
          </ng-container>
          <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'MDP'">
            As defined by <a href="https://apps.leg.wa.gov/wac/default.aspx?cite=182-31-020" target="_blank">Washington Administrative Code (WAC) 182-30-020</a>.
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!showCustomSOEDescription">
          {{selectedSOEType?.specialOpenEnrollmentTypeDescription}}
        </ng-container>
      </h4>
      <div *ngIf="selectedSOEType">
        <b>Documents to submit</b>
        <br/>
        <ul>
          <ng-container *ngIf="showCustomSOEDocTypes">
            <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'GLEMC'">
              <li>Enrollment or termination letter from Medicaid or CHIP reflecting the date the employee or employee's dependent enrolled in Medicaid or CHIP or the date 
                at which they lost eligibility for Medicaid or CHIP</li>
            </ng-container>
            <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'MDP'">
              <li>Marriage certificate</li>
              <li>Certificate of state-registered domestic partnership or legal union and the 
                <a *ngIf="subscriber?.agencyCode === 'SEBB'" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=Declaration+of+Tax+Status&field_customer_type_tid=19011&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC" target="_blank">SEBB Declaration of Tax Status</a>
                <a *ngIf="subscriber?.agencyCode !== 'SEBB'" href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=Declaration+of+Tax+Status&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=17561&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_bef_combine=name_ASC" target="_blank">PEBB Declaration of Tax Status</a>
              </li>
            </ng-container>
            <ng-container *ngIf="selectedSOEType.specialOpenEnrollmentTypeCode === 'NEED'">
              <li>Valid court order showing legal custody, guardianship, or temporary guardianship, signed by a judge or officer of the court, a signed 
                <a href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=extended+dependent+certification&field_customer_type_tid=19011&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC" target="_blank">{{subscriber?.agencyCode || 'PEBB'}} Extended Dependent Certification</a>, 
                and <a href="https://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=Declaration+of+Tax+Status&field_customer_type_tid=19011&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort=filename+ASC" target="_blank">{{subscriber?.agencyCode || 'PEBB'}} Declaration of Tax Status</a>.
              </li>
            </ng-container>            
          </ng-container>
          <ng-container *ngIf="!showCustomSOEDocTypes">
            <li *ngFor="let docType of selectedSOEType.documentTypes">{{docType.documentTypeName}}</li>
          </ng-container>
        </ul>
        <div *ngIf="selectedSOEType.specialOpenEnrollmentTypeName == 'Birth or adoption'">All valid documents for proof of this event must show the name of the parent who is the subscriber, subscriber's spouse, or the subscriber's state-registered domestic partner.</div>
      </div>
    </form>
    <ngb-alert class="alert alert-danger" [dismissible]="false" *ngIf="subscriber?.lossOfEligibilityDate">Submitting new SOEs is not permitted on accounts in terminated status.</ngb-alert>
  </div>
</div>
