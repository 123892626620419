export default class UserAccessLevel {
  userAccessLevelId: string;
  userAccessLevelName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;
  userAccessLevelCode: string;

  constructor(userAccessLevel?) {
    if (userAccessLevel) {
      this.userAccessLevelId = userAccessLevel.userAccessLevelId;
      this.userAccessLevelName = userAccessLevel.userAccessLevelName;
      this.userAccessLevelCode = userAccessLevel.userAccessLevelCode;
      this.obsoleteFlag = userAccessLevel.obsoleteFlag;
      this.obsoleteDate = userAccessLevel.obsoleteDate ? new Date(userAccessLevel.ObsoleteDate) : null;
    }
  }
}
