import SOEForm from './soeForm';
import Member from './member';
import SelfPayType from './selfPayType';
import Document from 'src/app/models/document';
import SelfPayVerificationStatus from './selfPayVerificationStatus';
import EnrollmentPeriod from './enrollmentPeriod';
import Subscriber from './subscriber';
import SelfPayProcessStatus from './selfPayProcessStatus';
import { map, orderBy, filter, find } from 'lodash';
import SelfPayPaymentType from './selfPayPaymentType';
import SelfPayForm from './selfPayForm';
import * as dayjs from 'dayjs';
import SelfPayElection from './selfPayElections';
import SpecialOpenEnrollmentType from './specialOpenEnrollmentType';
import Agency from './agency';
import SystemUser from './user';

export default class SelfPay {
  selfPayId: string;
  selfPayTypeId: string;
  selfPayType: SelfPayType;
  selfPayElection: SelfPayElection;
  memberId: string;
  member?: Subscriber;
  submittedDate: Date;
  coverageEffectiveDate: Date;
  approvedPendingDate: Date;
  approvedPendingById: string;
  approvedDate: Date;
  approvedById: string;
  deniedDate: Date;
  deniedById: string;
  simplifiedStatus: string;
  sortStatus: string;
  selfPayProcessStatusId: string;
  selfPayProcessStatus: SelfPayProcessStatus;
  lossOfEligibilityDate: Date;
  lossOfEligibilityReasonName: string;
  formJson: SelfPayForm;
  selfPayVerificationStatusId: string;
  selfPayVerificationStatus: SelfPayVerificationStatus;
  approvedInd: boolean;
  enrollmentPeriod: EnrollmentPeriod;
  documents: Document[];
  selfPayPaymentType: SelfPayPaymentType;
  selfPayPaymentTypeId: string;
  hasDocuments: boolean;
  reviewDate?: string;
  currentStateOfVerify: string;
  processDate: Date;
  specialOpenEnrollmentTypeId: string;
  specialOpenEnrollmentType: SpecialOpenEnrollmentType;
  selfPayTypeName?: string;
  expirationDate?: Date;
  eventDate?: Date;
  subscriberName?: string;
  agencyId?: string;
  agency: Agency;
  assignedById: string;
  assignedToId: string;
  assignedDate: Date;
  assignedTo: SystemUser;
  assignedBy: SystemUser;
  selfPayEnrollmentPeriod: EnrollmentPeriod;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;

  constructor(selfPay?) {
    if (selfPay) {
      this.selfPayId = selfPay.selfPayId;
      this.selfPayTypeId = selfPay.selfPayTypeId;
      this.selfPayType = new SelfPayType(selfPay.selfPayType);
      this.selfPayElection = selfPay.selfPayElections ? new SelfPayElection(selfPay.selfPayElections) : new SelfPayElection(selfPay.member);
      this.selfPayVerificationStatusId = selfPay.selfPayVerificationStatusId;
      this.selfPayPaymentTypeId = selfPay.selfPayPaymentTypeId;
      this.selfPayPaymentType = selfPay.selfPayPaymentType ? new SelfPayPaymentType(selfPay.selfPayPaymentType) : null;

      this.selfPayVerificationStatus = new SelfPayVerificationStatus(selfPay.selfPayVerificationStatus);
      this.memberId = selfPay.memberId;
      this.submittedDate = selfPay.submittedDate ? new Date(selfPay.submittedDate) : null;
      this.approvedDate = selfPay.approvedDate ? new Date(selfPay.approvedDate) : null;
      this.approvedPendingDate = selfPay.approvedPendingDate ? new Date(selfPay.approvedPendingDate) : null;
      this.deniedDate = selfPay.deniedDate ? new Date(selfPay.deniedDate) : null;
      this.approvedById = selfPay.approvedById;
      this.approvedPendingById = selfPay.approvedPendingById;
      this.deniedById = selfPay.deniedById;
      this.assignedDate = selfPay.assignedDate ? new Date(selfPay.assignedDate) : null;
      this.simplifiedStatus = selfPay.selfPayVerificationStatus?.selfPayVerificationStatusName;
      this.sortStatus = selfPay.selfPayVerificationStatus?.approvedInd ? 'Approved' :
        (selfPay.selfPayVerificationStatus?.deniedInd ? 'Denied' : selfPay.selfPayVerificationStatus?.selfPayVerificationStatusName);
      this.selfPayProcessStatusId = selfPay.selfPayProcessStatusId;
      this.selfPayProcessStatus = new SelfPayProcessStatus(selfPay.selfPayProcessStatus);
      this.lossOfEligibilityDate = selfPay.lossOfEligibilityDate ? new Date(selfPay.lossOfEligibilityDate) : null;
      this.lossOfEligibilityReasonName = selfPay.lossOfEligibilityReason ? selfPay.lossOfEligibilityReason.reasonName : null;

      this.specialOpenEnrollmentTypeId = selfPay.specialOpenEnrollmentTypeId;
      this.specialOpenEnrollmentType = new SpecialOpenEnrollmentType(selfPay.specialOpenEnrollmentType);

      const selfPayDatesObj = [
        { date: selfPay.submittedDate, val: 'pending'},
        { date: selfPay.approvedDate, val: 'approved'},
        { date: selfPay.approvedPendingDate, val: 'approvedPending'},
        { date: selfPay.deniedDate, val: 'denied'}
      ];
      const current = filter(orderBy(selfPayDatesObj, ['date', 'val'], ['desc','asc']), (v) => v.date !== null)[0];
      this.currentStateOfVerify = current?.val || 'pending';
      this.processDate = this.currentStateOfVerify  === 'pending' ? null : new Date (current.date);
      this.reviewDate = this.processDate ? dayjs(this.processDate).format('MM/DD/YYYY') : 'N/A';

      this.documents = map(selfPay.documents, (d) => new Document(d));
      this.coverageEffectiveDate = selfPay.coverageEffectiveDate ? new Date(selfPay.coverageEffectiveDate) : null;
      this.formJson = new SelfPayForm(JSON.parse(selfPay.formJson));
      if(selfPay.agency) {
        this.agency = new Agency(selfPay.agency);
      }

      if (selfPay.member) {
        const spEP = find(selfPay.member.enrollmentPeriods, (ep: EnrollmentPeriod) => ep.enrollmentPeriodId === this.selfPayId);
        this.selfPayEnrollmentPeriod = (spEP ? new EnrollmentPeriod(spEP) : null);
        this.member = new Subscriber(selfPay.member);
      }
      if (selfPay.enrollmentPeriod) {
        this.enrollmentPeriod = new EnrollmentPeriod(selfPay.enrollmentPeriod);
      }
      this.hasDocuments = selfPay.documents && selfPay.documents.length > 0;
      this.expirationDate = this.eventDate ? dayjs(this.eventDate).add(60, 'day').toDate() : null;
      this.assignedBy = selfPay.assignedBy ? new SystemUser(this.assignedBy) : null;
      this.assignedTo = selfPay.assignedTo ? new SystemUser(this.assignedTo) : null;
      this.assignedById = selfPay.assignedById;
      this.assignedToId = selfPay.assignedToId;
      this.createdDate = selfPay.createdDate;
      this.createdById = selfPay.createdById;
      this.modifiedDate = selfPay.modifiedDate;
      this.modifiedById = selfPay.modifiedById;
    }
  }
}
