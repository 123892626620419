export default class LtdEnrollmentType {
  ltdEnrollmentTypeId: string;
  name: string;
  code: string;
  sortOrder: number;
  effectiveStartDate: Date;
  effectiveEndDate: Date;

  constructor(ltdEnrollmentType) {
    if (ltdEnrollmentType) {
      this.ltdEnrollmentTypeId = ltdEnrollmentType.LtdEnrollmentTypeId;
      this.name = ltdEnrollmentType.LtdEnrollmentTypeName;
      this.code = ltdEnrollmentType.LtdEnrollmentTypeCode;
      this.sortOrder = ltdEnrollmentType.SortOrder;
      this.effectiveStartDate = ltdEnrollmentType.EffectiveStartDate ? new Date(ltdEnrollmentType.EffectiveStartDate) : null;
      this.effectiveEndDate = ltdEnrollmentType.EffectiveEndDate ? new Date(ltdEnrollmentType.EffectiveEndDate) : null;
    }
  }
}
