import BaseAddressWithCountry from './baseAddressWithCountry';

export default class MemberAddress extends BaseAddressWithCountry {
  memberAddressId: string;
  memberId: string;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;

  constructor(memberAddress?) {
    super(memberAddress);

    if (memberAddress) {
      this.memberAddressId = memberAddress.memberAddressId;
      this.memberId = memberAddress.memberId;
      this.createdDate = memberAddress.createdDate;
      this.createdById = memberAddress.createdById;
      this.modifiedDate = memberAddress.modifiedDate;
      this.modifiedById = memberAddress.modifiedById;
      this.effectiveStartDate = memberAddress.effectiveStartDate ? new Date(memberAddress.effectiveStartDate) : null;
      this.effectiveEndDate = memberAddress.effectiveEndDate ? new Date(memberAddress.effectiveEndDate) : null;
    }
  }
}
