import { map, find } from 'lodash';
import Response from './response';

export default class ApplicationSetting {
    applicationSettingId: string;
    applicationSettingCode: string;
    applicationSettingName: string;
    responseId: string;
    responses: Response[];
    modifiedDate: Date;
    response?: Response;
    constructor(appSetting?) {
      if (appSetting) {
        this.applicationSettingId = appSetting.applicationSettingId;
        this.applicationSettingName = appSetting.applicationSettingName;
        this.applicationSettingCode = appSetting.applicationSettingCode;
        this.responseId = appSetting.responseId;
        this.responses = map(appSetting.responses, r => new Response(r));
        if(this.responseId && this.responses){
          this.response = find(this.responses, r => r.responseId == this.responseId);
        }
        this.modifiedDate = new Date(appSetting.modifiedDate) || null;
      }
    }
  }
