<form fxFlexFill (ngSubmit)="searchPerspayAdmins()" #searchForm="ngForm" fxFlexLayout="column">
  <div fxFlexFill class="input-group mb-3">
    <input
      style="height: 40px;"
      [(ngModel)]="queryString"
      type="text"
      class="form-control"
      placeholder="Search by: PEBB Org Name, First Name, Last Name, Email Address"
      aria-label="Search by: PEBB Org Name, First Name, Last Name, Email Address"
      aria-describedby="search"
      name="search"
    />
    <div class="input-group-append"><button class="btn btn-outline-secondary" type="submit">Search</button></div>
  </div>
</form>
