import Organization from 'src/app/models/organization';
import { map } from 'lodash';
import Member from './member';
import UserOrganizationRole from './userOrganizationRole';
import UserType from './userType';
import Subscriber from './subscriber';
export default class SystemUser {
  systemUserId: string;
  memberId: string;
  member?: Member;
  members?: Subscriber[];
  userOrganizationRoles: UserOrganizationRole[];
  userTypeId: string;
  userType: UserType;
  firstName: string;
  lastName: string;
  fullName: string;
  emailAddress: string;
  authSystemIdentifier: string;
  organizationOrganizationString?: string;
  perspayAdminRoles?: UserOrganizationRole[];
  termsOfUseAcceptedInd?: boolean;
  verifierRole?: UserOrganizationRole;
  apiUserRole?: UserOrganizationRole;
  accessLevels?: string[];
  userTypeCode?: string;
  isClaimed?: boolean;
  currentOrganization?: Organization;
  constructor(user?) {
    if (user) {
      this.systemUserId = user.systemUserId;
      this.memberId = user.memberId;
      this.member = new Member(user.member);
      this.userOrganizationRoles = map(user.userOrganizationRoles, (r) => new UserOrganizationRole(r));
      this.userTypeId = user.userTypeId;
      this.userType = new UserType(user.type);
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.fullName = user.firstName ? user.firstName + ' ' + user.lastName : null;
      this.emailAddress = user.emailAddress;
      this.authSystemIdentifier = user.authSystemIdentifier;
      this.termsOfUseAcceptedInd = user.termsOfUseAcceptedInd;
      if (user.isClaimed) {
        this.isClaimed = user.isClaimed;
      }
      if (this.userOrganizationRoles && this.userOrganizationRoles.length) {
        this.accessLevels = map(this.userOrganizationRoles, (u: UserOrganizationRole) => u.userAccessLevelName);
        this.userTypeCode = map(this.userOrganizationRoles, (u: UserOrganizationRole) => u.userRoleName)[0];
      }
      if (user.currentOrganization) {
        this.currentOrganization = new Organization(user.currentOrganization);
      }
      if (user.members && user.members.length > 1) {
        this.members = map(user.members, (m) => new Subscriber(m));
      }
    }
  }
}
