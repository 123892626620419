import { GenericGridComponent } from './../../../shared/components/genericGrid/genericGrid.component';
import { Carrier834HoldService } from 'src/app/services/carrier834Hold.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { faMinus, faPlus, faRotateRight, faCheck, faCheckDouble, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import SimpleSubscriber from 'src/app/models/simpleSubscriber';
import Carrier834Hold from 'src/app/models/carrier834Hold';
import SystemUser from 'src/app/models/user';
import { State, process, filterBy, SortDescriptor, FilterDescriptor, orderBy } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { cloneDeep } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'carrier-transactions',
  templateUrl: 'carrier.transactions.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class CarrierTransactionsComponent implements OnInit {
  icons = {
    faPlus,
    faMinus,
    faRotateRight,
    faCheck,
    faCheckDouble,
    faEllipsis
  };
  systemUser: SystemUser;
  isHcaSysAdmin = false;
  subscriberSearchResults: SimpleSubscriber[] = [];
  selectedSubscriber: SimpleSubscriber;
  carrier834Transactions: Carrier834Hold[] = [];
  clonedGridData;
  searchResultColumns: { [k: string]: string | {} }[] = [
    { field: 'firstName', title: 'First name', format: 'string' },
    { field: 'middleName', title: 'Middle name', format: 'string' },
    { field: 'lastName', title: 'Last name', format: 'string' },
    { field: 'SSN', title: 'SSN', format: 'string' },
    { field: 'birthDate', title: 'Birth date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'memberType', title: 'Member type', format: 'string' },
    { field: 'employerName', title: 'Employer name', format: 'string' },
  ];

  state: State = {
    skip: 0,
  };
  skip = 0;
  sort: SortDescriptor[] = [];
  filter: FilterDescriptor;
  selectedCarrier834Hold: Carrier834Hold;
  carrier834TransactionColumns: { [k: string]: string | {} }[] = [
    { field: 'subscriberType', title: 'Subscriber/Dependent', format: 'string' },
    { field: 'member.lastName', title: 'Last name', format: 'string' },
    { field: 'member.firstName', title: 'First name', format: 'string' },
    { field: 'carrier.carrierName', title: 'Carrier', format: 'string' },
    { field: 'transactionType', title: 'Transaction Type', format: 'string' },
    { field: 'output', title: 'Transaction Text', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'sentDate', title: 'Sent Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'previouslySent', title: 'Previously Sent Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'tranId', title: 'Tran Id', format: 'string', width: '100px'}
  ];
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;
  showModalText: string = null;
  
  constructor(
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService,
    private coreService: CoreService,
    private adminService: AdminService,
    private subscriberService: SubscriberService,
    private carrier834HoldService: Carrier834HoldService
  ) {}

  ngOnInit(): void {

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
      this.isHcaSysAdmin = this.coreService.systemUserHasAccess(AccessLevel.SystemAdmin, UserTypeCode.HCA);
    });
  }

  onSearchSubmitted(queryString: string): void {
    this.spinnerOverlayService.show();
    this.adminService.searchSubscribers(queryString, true, null, null, false, true).pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.spinnerOverlayService.hide();
        this.subscriberSearchResults = [];
        this.subscriberSearchResults = data;
      },
      (err) => {
        console.log(err);
        this.spinnerOverlayService.hide();
      }
    );
  }

  async onSubscriberSelected(e: SimpleSubscriber): Promise<void> {
    if (this.selectedSubscriber) {
      this.onSubscriberDeselected();
    }
    this.spinnerOverlayService.show();
    if (!e.isSubscriberInd) {
      //this is a dependent.  Get their subscriber's record
      const sub = await lastValueFrom(this.subscriberService.getSubscriberById(e.subscriberMemberId));
      e = new SimpleSubscriber(sub);
      e.SSN = 'XXX-XX-' + sub.socialSecurityNumber.substring(sub.socialSecurityNumber.length-4,sub.socialSecurityNumber.length);
    }
    this.carrier834Transactions = await lastValueFrom(this.carrier834HoldService.getCarrier834HoldForSubscriber(e.memberId));
    this.loadItems();
    this.selectedSubscriber = e;
    this.spinnerOverlayService.hide();
  }

  onSubscriberDeselected(): void {
    this.selectedSubscriber = null;
  }  

  public dataStateChange(state: DataStateChangeEvent | State): void {
    this.state = state;
  }

  async resendTransactions() {
    this.spinnerOverlayService.show();
    const totalResent = this.clonedGridData.data.filter(o=>o.resend).length;
    this.carrier834HoldService.resendTransactions(this.clonedGridData.data,this.selectedSubscriber.memberId).pipe(untilDestroyed(this)).subscribe(result => {
      this.carrier834Transactions = result;
      this.loadItems();
      this.spinnerOverlayService.hide();
      this.coreService.popMessage(totalResent + ' transaction(s) set to be resent', 'success', 3000);
    });
  }

  checkResend(dataItem: Carrier834Hold) {
    if (dataItem.resend) {
      //check all transactions below this one as well
      this.clonedGridData.data.filter(o=>o.tranId>dataItem.tranId).forEach(c=>{
        c.resend = true;
      });
    }
  }
  
  public changeSort(e: SortDescriptor[]): void {
    this.sort = e;
    this.loadItems();
  }

  public changeFilter(e: FilterDescriptor): void {
    this.filter = e;
    this.loadItems();
  }

  private loadItems(): void {
    if (Array.isArray(this.carrier834Transactions)) {
      this.clonedGridData = {
        data: orderBy(filterBy(this.carrier834Transactions, this.filter), this.sort),
        total: this.carrier834Transactions.length,
      };
    }
  }

}
