<div fxLayoutAlign="center center" fxFlexFill fxLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body fxGap-medium" fxLayout="column" fxLayout.sm="column">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between none" class="fxGap-medium">
          <div fxFlex="1 1 30%">
            <h3 style="margin-top: 0px;">Add subscriber<span *ngIf="isSelfPayAdd"> for Self-pay</span></h3>
          </div>
          <div *ngIf="isSelfPayAdd">
            Add a subscriber who doesn't currently exist in My Account for the purpose of enrollment in self-pay (Continuation) coverage or activate/enroll a subscriber 
            that does exist, but is currently not active/enrolled
          </div>
        </div>
        <subscriber-management-add-search
          fxFlex="50"
          *ngIf="displayAddSearch"
          (checkForExistingRecord)="checkForExistingRecord($event)"
          (searchForExistingMemberForSelfPay)="searchForExistingMemberForSelfPay($event)"
          (cancelAdd)="cancelAddMember()"
          [isHCAAdmin]="isHCAAdmin"
          [isSelfPayAdd]="isHCAWithEdit && coreService.systemUserHasAccess('edit') && !currentOrganization"
          [isSEBBOrg]="currentOrganization?.agency?.agencyCode === 'SEBB'"
          [agencyCode]="currentOrganization?.agency?.agencyCode"
        >
        </subscriber-management-add-search>
        <!-- HIT VALID TRANSFER -->
        <transfer-found
          *ngIf="transferFoundShow && !displaySubscriberAdd && !displayAddSearch && !displaySelfPayAdd"
          [subscriberTransferRec]="subscriberTransferRec"
          [noFlags]="transferHasNoFlags"
          [isHCA]="isHCA"
          [organization]="currentOrganization"
          [medicalOnlyBenefitSetupId]="medOnlySetupId"
          (cancelAdd)="cancelAddMember()"
          (claimSubscriber)="claimSubscriber($event)"
        >
        </transfer-found>
        <needs-to-be-adjusted *ngIf="displayNeedsAdjustment" [subscriberTransferRec]="subscriberTransferRec" (cancelAdd)="cancelAddMember()"> </needs-to-be-adjusted>
        <add-subscriber
          *ngIf="displaySubscriberAdd && this.currentOrganization != null && !displayAddSearch"
          [organization]="currentOrganization"
          (saveSubscriber)="saveSubscriber($event)"
          (cancelAdd)="cancelAddMember()"
          [lookups]="lookups"
          [formSubmitted]="formSubmitted"
          [subscriber]="subscriber"
          [isTransfer]="isTransfer"
          [subscriberTransferSearch]="subscriberTransferSearch"
          [isHCAWithEdit]="isHCA"
          [transferMemberResult]="transferMemberResult"
          [showWarningMessage]="showWarningMessage"
        ></add-subscriber>
        <add-self-pay-subscriber
          *ngIf="displaySelfPayAdd && !displayAddSearch"
          [selfPaySubscriber]="selfPaySubscriber"
          [lookups]="lookups"
          [formSubmitted]="formSubmitted"
          (saveSelfPaySubscriber)="saveSelfPaySubscriber($event)"
          (cancelAdd)="cancelAddMember()"
        ></add-self-pay-subscriber>
      </div>
    </div>
  </div>
</div>
