import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Relationship from '../models/relationship';
import RelationshipCertification from '../models/relationshipCertification';

@Injectable({
  providedIn: 'root'
})
export class RelationshipCertificationService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  createRelationshipCertification(subscriberId: string, relationshipCertification: RelationshipCertification): Observable<RelationshipCertification> {
    return this.http.post<RelationshipCertification>(`${env.apiUrl}/Member/${subscriberId}/Relationship/${relationshipCertification.relationshipId}/certification`, relationshipCertification)
    .pipe(map(x => this.commonService.createObject(RelationshipCertification, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateRelationshipCertification(subscriberId: string, relationshipCertification: RelationshipCertification): Observable<RelationshipCertification> {
    return this.http.put<RelationshipCertification>(`${env.apiUrl}/Member/${subscriberId}/Relationship/${relationshipCertification.relationshipId}/certification/${relationshipCertification.relationshipCertificationId}`, relationshipCertification)
    .pipe(map(x => this.commonService.createObject(RelationshipCertification, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  removeRelationshipCertification(subscriberId: string, relationshipId: string, relationshipCertificationId: string): Observable<void> {
    return this.http.delete<RelationshipCertification>(`${env.apiUrl}/Member/${subscriberId}/Relationship/${relationshipId}/certification/${relationshipCertificationId}`)
    .pipe(res => res)
    .pipe(catchError(this.commonService.handleError));
  }

  async upsertCertification(subscriberId: string, certification: RelationshipCertification): Promise<RelationshipCertification> {
    const newCertification = !certification.relationshipCertificationId
      ? await lastValueFrom(this.createRelationshipCertification(subscriberId, certification))
      : await lastValueFrom(this.updateRelationshipCertification(subscriberId, certification));

    return newCertification;
  }
}
