export default class SelfPayPaymentType {
  selfPayPaymentTypeId: string;
  selfPayPaymentTypeName: string;
  selfPayPaymentTypeCode: string;
  pmaPaymentTypeCode: string;
  

  constructor(selfPayPaymentType?) {
    if (selfPayPaymentType) {
      this.selfPayPaymentTypeId = selfPayPaymentType.selfPayPaymentTypeId;
      this.selfPayPaymentTypeName = selfPayPaymentType.selfPayPaymentTypeName;
      this.selfPayPaymentTypeCode = selfPayPaymentType.selfPayPaymentTypeCode;  
      this.pmaPaymentTypeCode = selfPayPaymentType.pmaPaymentTypeCode;
    }
  }


}
