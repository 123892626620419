export default class SpecialOpenEnrollmentVerificationStatus {
  specialOpenEnrollmentVerificationStatusId: string;
  specialOpenEnrollmentVerificationStatusCode: string;
  specialOpenEnrollmentVerificationStatusName: string;
  approvedInd: boolean;
  deniedInd: boolean;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(specialOpenEnrollmentVerificationStatus?) {
    if (specialOpenEnrollmentVerificationStatus) {
      this.specialOpenEnrollmentVerificationStatusId = specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusId;
      this.specialOpenEnrollmentVerificationStatusCode = specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusCode;
      this.specialOpenEnrollmentVerificationStatusName = specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName;
      this.approvedInd = specialOpenEnrollmentVerificationStatus.approvedInd;
      this.deniedInd = specialOpenEnrollmentVerificationStatus.deniedInd;
      this.obsoleteFlag = specialOpenEnrollmentVerificationStatus.obsoleteFlag;
      this.obsoleteDate = specialOpenEnrollmentVerificationStatus.obsoleteDate ? new Date(specialOpenEnrollmentVerificationStatus.ObsoleteDate) : null;
    }
  }


}
