import { ReportService } from './../../services/report.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { ReportDisplayComponent } from './components/display/report.display.component';
import { ReportResultsComponent } from './components/results/report.results.component';
import { ReportSearchComponent } from './components/search/report.search.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    FlexLayoutModule,
    NgbModule,
    NgbNavModule,
    FontAwesomeModule,
    InputsModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    ReportsComponent,
    ReportDisplayComponent,
    ReportResultsComponent,
    ReportSearchComponent
  ],
  exports: [],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}, ReportService],
  bootstrap: []
})
export class ReportsModule {}
