<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body page-container">
        <h1 *ngIf="currentOrganization && !inHCAAdminState" >{{currentOrganization.organizationName}}</h1>
        <h1 *ngIf="inHCAAdminState">HCA Admin</h1>
        <h2 class="mobile-text-centered mgbt-x-small mgtp-small">Verify {{selfPayOnly? 'Self-pay' : ''}} dependents</h2>
        <div fxFlexLayout="column" class="fxGap-medium-lg mgbt-medium">
          <h3 class="h5 mgtp-x-small">
            Use this section to review status of dependent verification requests for your organization. 
            After review of documentation provided, either within PEBB My Account or in person, record verification results. 
          </h3>
          <h5><a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/dependent-verification" target="_blank">Accepted documents for proof of eligibility</a></h5>
        </div>
        <program-filter *ngIf="!currentOrganization && isHCAAdmin && !selfPayOnly" [(agencyFilter)]="agencyFilter" (refreshSearch)="refreshSearch($event)"></program-filter>
        <form *ngIf="inVerifierState" fxFlexFill (ngSubmit)="searchDV(queryString, onlyShowPendingRecords)" #searchForm="ngForm" fxFlexLayout="column">
          <div fxFlexFill class="input-group mb-3" style="padding-bottom: 20px;">
            <input
              style="height: 50px;"
              [(ngModel)]="queryString"
              type="text"
              class="form-control"
              placeholder="Search Dependent Verification Requests"
              aria-label="Search Dependent Verification Requests"
              aria-describedby="search"
              name="search"
            />
            <div class="input-group-append"><button [disabled]="queryString?.length < 3" class="btn btn-outline-secondary" type="submit">Search</button></div>
          </div>
        </form>        
        <div fxFlexLayout="row">
          <div class="form-group">
            <checkbox name="showPendingOnly" bold [(ngModel)]="onlyShowPendingRecords" (change)="refreshSearch()">
              Show only pending requests
            </checkbox>
        </div>
        <div *ngIf="gridData" class="dashboard-grid-parent mgbt-medium mgtp-medium">
          <verification-admin-grid 
            [isHCAAdmin]="isHCAAdmin" 
            [inHCAAdminState]="inHCAAdminState"
            #verificationAdminGrid
            [gridData]="gridData"
            [gridColumns]="gridColumns"
            [filterable]="true"
            [lookups]="lookups"
            [state]="state"
            [pageSize]="pageSize"
            (pushGetData)="updateDataset($event)"
            (pushSaveRelationship)="updateRelationship($event)"
            (pushSaveRelationshipCertification)="updateRelationshipCertification($event)"
            (pushSaveCertification)="saveCertification(index, $event)"
            (pushSaveCertificationStatus)="saveCertificationStatus($event)"
            (pushDeleteCertification)="deleteCertification(index, $event)"
          ></verification-admin-grid>
        </div>
      </div>
    </div>
  </div>
</div>