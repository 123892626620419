// Handles the main dashboard routes for subscribers (anything outside of the wizard);

// ng
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import { AttestTobaccoOutsideComponent } from '../modules/subscriber/modules/attestations/components/tobacco/outsideEnrollmentPeriod/tobacco.outsideEP.component';
import { ProfileComponent } from '../modules/subscriber/modules/profile/profile.component';
import { AttestSpousalComponent } from '../modules/subscriber/modules/attestations/components/spousal/spousal.component';
import { SubscriberSummaryComponent } from '../modules/subscriber/components/summary/subscriber.summary.component';
import { DependentManagementComponent } from '../modules/subscriber/modules/dependentManagement/dependentManagement.component';
import { SOEComponent } from '../modules/subscriber/modules/soe/soe.component';
import { SelfPayComponent } from '../modules/subscriber/modules/selfpay/selfpay.component';
import { HealthEquityComponent } from '../components/healthEquity/healthEquity.component';
import { SupplementalComponent } from './../modules/subscriber/modules/supplemental/supplemental.component';
// guards
import { MasterGuard } from './../guards/master/master.guard';
// resolvers
import { LookupsResolver } from './../resolvers/lookups.resolver';
import { SubscriberResolver } from './../resolvers/subscriber.resolver';
import { TOUResolver } from '../resolvers/tou.resolver';
import { DependentsComponent } from '../modules/subscriber/modules/dependents/dependents.component';
import { PendingChangesGuard } from '../guards/pendingChanges/pendingChanges.guard';
import { EnrollmentPeriodResolver } from '../resolvers/enrollmentPeriod.resolver';
import { UploadComponent } from '../modules/subscriber/modules/upload/upload.component';
import { CoverageComponent } from '../modules/subscriber/modules/coverage/coverage.component';
import { AttestTobaccoComponent } from '../modules/subscriber/modules/attestations/components/tobacco/tobacco.component';
import { ConfirmationComponent } from '../modules/subscriber/modules/confirmation/confirmation.component';

const routes: Routes = [
  {
    path: 'attest/tobacco/:id',
    component: AttestTobaccoOutsideComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
    },
    canActivate: [MasterGuard],
  },
  {
    path: 'attest/spousal/:id',
    component: AttestSpousalComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
    },
    canActivate: [MasterGuard],
  },
  {
    path: 'profile/:id',
    component: ProfileComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
      termsOfUse: TOUResolver,
    },
    canActivate: [MasterGuard],
  },
  {
    path: 'summary/:id',
    component: SubscriberSummaryComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
      termsOfUse: TOUResolver,
    },
    canActivate: [MasterGuard],
  },
  {
    path: 'dependents/:id',
    component: DependentManagementComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
    },
  },
  {
    path: 'soe/:id',
    component: SOEComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
    },
    runGuardsAndResolvers: 'always',
    canActivate: [MasterGuard],
    children: [
      {
        path: 'dependents/:epid',
        component: DependentsComponent,
        canDeactivate: [PendingChangesGuard],
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
          enrollmentPeriod: EnrollmentPeriodResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'upload/:epid',
        component: UploadComponent,
        canDeactivate: [PendingChangesGuard],
        resolve: {
          subscriber: SubscriberResolver,
          enrollmentPeriod: EnrollmentPeriodResolver,
        },
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'coverage/:epid',
        component: CoverageComponent,
        canDeactivate: [PendingChangesGuard],
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
          enrollmentPeriod: EnrollmentPeriodResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'attest/:epid',
        component: AttestTobaccoComponent,
        canDeactivate: [PendingChangesGuard],
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
          enrollmentPeriod: EnrollmentPeriodResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'supplemental/:epid',
        component: SupplementalComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
          enrollmentPeriod: EnrollmentPeriodResolver,
        },
        canActivate: [MasterGuard],
      },
      {
        path: 'confirmation/:epid',
        component: ConfirmationComponent,
        data: {
          guards: ['auth'],
        },
        resolve: {
          subscriber: SubscriberResolver,
          enrollmentPeriod: EnrollmentPeriodResolver,
          termsOfUse: TOUResolver,
        },
        canActivate: [MasterGuard],
      },
    ],
  },
  {
    path: 'selfPay/:id',
    component: SelfPayComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
    },
  },
  {
    path: 'selfPay/:id/:soe',
    component: SelfPayComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
    },
  },
  {
    path: 'supplemental/:id',
    component: SupplementalComponent,
    data: {
      guards: ['auth'],
    },
    resolve: {
      subscriber: SubscriberResolver,
    },
    canActivate: [MasterGuard],
  },
  {
    path: 'healthEquity/:id',
    component: HealthEquityComponent,
    resolve: {
      subscriber: SubscriberResolver,
    },
    data: {
      guards: ['auth'],
    },
    canActivate: [MasterGuard],
  },
];

export const SubscriberGeneralRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(LookupsResolver.addToAllRoutes(routes), { onSameUrlNavigation: 'reload' });
