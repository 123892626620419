<form (ngSubmit)="submitDependent()" *ngIf="dependent" #memberForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
  <div fxFlexFill class="fxGap-large" fxLayout="column">
    <h2 style="text-align: center">Tobacco use premium surcharge attestation</h2>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div class="h4">
        <span *ngIf="!isSebb">
          Attest to whether the surcharge below applies to this dependent by checking the appropriate box. Then, select the button below to continue.
          <br />
          <br />
          <br />
          <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/surcharges" target="_blank">Learn about this surcharge</a> before you make your attestation.
          <br />
          <br />
          The PEBB Program requires a $25-per-account premium surcharge in addition to your monthly medical premium if you or an enrolled dependent (age 13 or older) uses a tobacco product. Tobacco use is defined as any use of tobacco products within the past two months except for religious or ceremonial use.
          <br/><br/>
          Tobacco products means any product made with or derived from tobacco that is intended for human consumption, including any component, part, or accessory of a tobacco product. This includes, but it not limited to, cigars, cigarettes, pipe tobacco, chewing tobacco, snuff, and other tobacco products. Tobacco products do not include e-cigarettes or United States Food and Drug Administration (FDA) approved quit aids, such as, over-the-counter nicotine replacement products recommended by a doctor, or prescription nicotine replacement products.
          <br/><br/>
          If a provider finds that ending tobacco use or participating in your medical plan’s tobacco cessation program will negatively affect your or your dependent’s health, see more information in <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-rules-and-policies">PEBB Program Policy 91-1</a>.
          <br><br/>
          If you check Yes in this section, you will be charged the $25 premium surcharge. 
          <br />
        </span>
        <span *ngIf="isSebb">
          Attest to whether the surcharge below applies to this dependent by checking the appropriate box.  Then select the button below to continue.
          <br />
          <br />          
          <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/surcharges" target="_basic">Learn about this surcharge before you make your attestation</a>. 
          The SEBB Program requires a $25-per-account premium surcharge in addition to your monthly medical premium if you or an enrolled dependent (age 13 or older) uses a 
          tobacco product. Tobacco use is defined as any use of tobacco products within the past two months except for religious or ceremonial use.
          <br/>
          Tobacco products means any product made with or derived from tobacco that is intended for human consumption, including any component, part, or accessory of a tobacco product. This includes, but it not limited to, cigars, cigarettes, pipe tobacco, chewing tobacco, snuff, and other tobacco products. Tobacco products do not include e-cigarettes or United States Food and Drug Administration (FDA) approved quit aids, such as, over-the-counter nicotine replacement products recommended by a doctor, or prescription nicotine replacement products. 
          <br/>
          If a provider finds that 
          ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information 
          in the <a href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-rules-and-policies" target="_blank">SEBB Program Administrative Policy 91-1</a>.
          <br />
          If you check Yes in this section, you will be charged the $25 premium surcharge.
        </span>
      </div>
    </div>
    <div  fxLayoutAlign="space-around" fxLayout="row">
      <div fxLayout="row" style="width: 400px">
        <div class="h4 no-mg">
          Does the tobacco use premium surcharge apply to this dependent? <span *ngIf="isSebb">Select Yes or No</span>
        </div>
      </div>
      <yes-no
        name="hasTobacco"
        [isTobaccoAttestation]="true"
        [isTobaccoAttestationSubscriber]="false"
        [isSebb] = "isSebb"
        [orientation]="'vertical-list'"
        yesLabel="Yes, I am subject to the $25 premium surcharge. This dependent has used tobacco products in the past two months."
        [noLabel]="'No, I am not subject to the $25 premium surcharge. This dependent has not used tobacco in the past two months, has enrolled in their ' +
           (isSebb ? 'SEBB': 'PEBB') + ' medical plan\'s tobacco cessation program (if age 18 or older), or has accessed information or resources on the Smokefree Teen ' + 
           'website (if age 13 to 17).'"
        style="padding-left: 75px; padding-right: 75px; margin-bottom: 30px;"
        [(ngModel)]="yesTobacco"
        [required]="true"
        (change)="updateTobacco()"
        ></yes-no>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="end end">
      <button [disabled]="this.yesTobacco === undefined || this.yesTobacco === null" fxFlex="30" id="sub-add-submit-btn" type="submit" class="btn btn-primary" *ngIf="dependent.partnershipStartDate">Continue to the  Spouse or state-registered domestic partner coverage surcharge attestation</button>
      <button [disabled]="this.yesTobacco === undefined || this.yesTobacco === null" fxFlex="30" id="sub-add-submit-btn" type="submit" class="btn btn-primary" *ngIf="!dependent.partnershipStartDate" >Proceed to dependent review</button>
      <!-- <button fxFlex="30" id="sub-add-cancel-btn" (click)="cancelAdd.emit()" class="btn btn-light">Cancel</button> -->
    </div>
  </div>
</form>
