import { get, cloneDeep } from 'lodash';
// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

// ext
import { faUser, faMinus, faPlus, faCalendar, faHome } from '@fortawesome/free-solid-svg-icons';
import { find, remove, filter, map, every } from 'lodash';
import * as dayjs from 'dayjs';

// local
import { some } from 'lodash';
import { env } from 'src/env/development';
import DependentComposite from 'src/app/models/dependentComposite';
import RelationshipType from 'src/app/models/relationshipType';
import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import { CoreService } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import Member from 'src/app/models/member';
import { MemberService } from 'src/app/services/member.service';
import ExternalEnrollment from 'src/app/models/externalEnrollment';
import Reason from 'src/app/models/reason';
import { lastValueFrom } from 'rxjs';
import Enrollment from 'src/app/models/enrollment';

@Component({
  selector: 'dependent-details',
  templateUrl: 'dependent.details.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentDetailsComponent implements OnInit, OnDestroy {
  @Input() dependent: DependentComposite;
  initialDependent: DependentComposite;
  @Input() isHCAWithEdit = false;
  @Input() isPerspayWithEdit = false;
  @Input() isReadOnly = true;
  @Input() isSubscriber = false;
  @Input() index;
  @Input() subscriber: Subscriber;
  @Output() pushDeleteDependent: EventEmitter<DependentComposite> = new EventEmitter();
  @Output() pushSaveDependent: EventEmitter<DependentComposite> = new EventEmitter();
  @Output() pushCancelDependent: EventEmitter<void> = new EventEmitter();
  @Output() setDivorceWithin60DaysEnrollments: EventEmitter<{enrollments: Enrollment[], enrollment: Enrollment}> = new EventEmitter();
  @ViewChild('memberForm') public memberForm: NgForm;
  icons = {
    faUser,
    faMinus,
    faPlus,
    faCalendar,
    faHome,
  };
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    phoneNumberTypes: [],
    preferredContactMethods: [],
    languages: [],
    organizations: [],
    terminationReasons: [],
  };
  showPartnershipDate = false;
  marriageDateInFuture = false;
  now: Date = new Date();
  minBirthDate: Date;
  twentySixDate: Date;
  rtToQrMap: any;
  rtToQrMap26: any;
  // because ssn is model bound - for ignoring on ssn collisions check.
  originalSSN: string;
  noSSN = false;
  isSubscriberAndDependentIsWithin30 = false;
  selectedRelationshipType: RelationshipType = new RelationshipType();
  spousalRelationshipTypeId: string;
  extendedOrDisabledRelationshipQualifyIds: string[];
  disabledRelationshipQualifyId: string;
  maxBirthDateForSpouse: Date;
  dependentAgedOut = false;
  env = env;
  showPseudoSSN: boolean = false;
  constructor(private coreService: CoreService, private memberService: MemberService) {}

  ngOnInit(): void {
    const currentCoverages = this.dependent.member.getAllCoverageByDate(new Date());
    if (this.isHCAWithEdit && currentCoverages.length) {
      const currentCoverageEffectiveDate = currentCoverages[0].effectiveStartDate;
    } 
    this.twentySixDate = dayjs().subtract(26, 'year').add(30, 'day').toDate();

    this.spousalRelationshipTypeId = find(this.lookups.relationshipTypes, (r: RelationshipType) => r.relationshipTypeCode === 'S').relationshipTypeId;
    this.minBirthDate = dayjs().subtract(110, 'year').toDate();
    this.maxBirthDateForSpouse = dayjs().subtract(14, 'year').toDate();
    this.rtToQrMap = env.rtToQrMap;
    this.rtToQrMap26 = env.rtToQrMap26;
    this.noSSN = this.dependent.socialSecurityNumber?.startsWith('99999');
    this.extendedOrDisabledRelationshipQualifyIds = map(
      filter(this.lookups.relationshipQualifyReasons, (rqr: RelationshipQualifyReason) => rqr.relationshipQualifyReasonCode === 'A' || rqr.relationshipQualifyReasonCode === 'D'),
      'relationshipQualifyReasonId'
    );
    this.initialDependent = cloneDeep(this.dependent);
    this.disabledRelationshipQualifyId = find(this.lookups.relationshipQualifyReasons,
      (rqr: RelationshipQualifyReason) => rqr.relationshipQualifyReasonCode === 'A').relationshipQualifyReasonId;
  }

  ngOnDestroy(): void {}

  updateBirthDate(d: Date): void {
    const rtCode = get(
      find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt.relationshipTypeId === this.dependent.relationshipTypeId),
      'relationshipTypeCode'
    );
    if (rtCode) {
      if (rtCode !== 'S') {
        if (d < this.twentySixDate) {
          this.dependent.relationshipQualifyReasonId = get(
            find(this.lookups.relationshipQualifyReasons, (rq: RelationshipQualifyReason) => env.rtToQrMap26[rtCode].includes(rq.relationshipQualifyReasonCode)),
            'relationshipQualifyReasonId'
          );
        } else {
          this.dependent.relationshipQualifyReasonId = get(
            find(this.lookups.relationshipQualifyReasons, (rq: RelationshipQualifyReason) => env.rtToQrMap[rtCode].includes(rq.relationshipQualifyReasonCode)),
            'relationshipQualifyReasonId'
          );
        }
      }
    }
  }

  relationshipTypeCode(relationshipTypeId: string): string {
    const selectedRelationshipType: RelationshipType = find(this.lookups.relationshipTypes,
      (relationshipType: RelationshipType) => relationshipType.relationshipTypeId === relationshipTypeId);
    return selectedRelationshipType?.relationshipTypeCode;
  }

  isSpouse(relationshipTypeId: string): boolean {
    return this.relationshipTypeCode(relationshipTypeId) === 'S';
  }

  changeRelationshipType(): void {
    this.selectedRelationshipType = find(this.lookups.relationshipTypes, (relationshipType: RelationshipType) => relationshipType.relationshipTypeId === this.dependent.relationshipTypeId);

    if (!this.isSpouse(this.dependent.relationshipTypeId)) {
      this.updateBirthDate(this.dependent.birthDate);
    }
  }

  handleNoSSN(e): void {
    if (!this.isHCAWithEdit) {
      this.dependent.socialSecurityNumber = null;  
    }
    else {
      if (!this.noSSN && this.dependent.socialSecurityNumber?.startsWith('99999')) {
        this.showPseudoSSN = true;
      }
      else {
        this.showPseudoSSN = false;
        if (this.initialDependent.socialSecurityNumber?.startsWith('99999')) {
          this.dependent.socialSecurityNumber = this.initialDependent.socialSecurityNumber;
        }
        else {
          this.dependent.socialSecurityNumber = null;  
        }
      }
    }
  }

  handleCountryChange(): void {
    this.dependent.state = null;
  }

  async submitDependent(formContainer): Promise<void> {
    let isValid = true;
    let messageToShow = 'Please complete or correct the field(s) indicated above';
    this.markAllControlsAsTouched();
    const nowMoment = dayjs();

    // handling DE call here as save happens per dep. otherwise iterating over viewchidlren and its messy
    if(!this.isHCAWithEdit){
      if (this.dependent.socialSecurityNumber == null || this.dependent.socialSecurityNumber?.startsWith('99999')) {
        this.coreService.popMessage("Updates cannot be made to dependents without an SSN", 'error', 4000, this.coreService.getInvalidFields(formContainer));
        return;
      }

      if (this.dependent.socialSecurityNumber && (this.dependent.socialSecurityNumber != this.initialDependent.socialSecurityNumber)) {
        if (this.ssnAlreadyExistsForAnotherDependent(this.dependent.socialSecurityNumber))  {
          return this.memberForm.controls[`socialSecurityNumber${this.index}`].setErrors({existsOnSubscriber: true});
        }

        const now = new Date();
        const enrolledPlanTypeIds: string[] = this.dependent.memberEnrollments.filter(e => e.isActive(now)).map(e => e.planTypeId);
        const externalEnrollments = await lastValueFrom(this.memberService.getExternalEnrollments(this.dependent.socialSecurityNumber, this.dependent.memberId, 
            this.subscriber.memberId));

        if (some(externalEnrollments, (e: ExternalEnrollment) => e.externalEnrollmentConflictsForDate(now) &&
          e.agency?.agencyCode === 'SEBB' &&
          enrolledPlanTypeIds.indexOf(e.planTypeId) > -1
          )) {
          return this.memberForm.controls[`socialSecurityNumber${this.index}`].setErrors({existsExternallySEBB: true});
        } else if (some(externalEnrollments, (e: ExternalEnrollment) => e.externalEnrollmentConflictsForDate(now) &&
          e.agency?.agencyCode === 'PEBB' &&
          enrolledPlanTypeIds.indexOf(e.planTypeId) > -1
          )) {
          return this.memberForm.controls[`socialSecurityNumber${this.index}`].setErrors({existsExternallyPEBB: true});
        }
      }
    } 
    const minBirthDate = new Date(nowMoment.year() - 110, nowMoment.month() + 1, nowMoment.date());
    if (this.dependent.birthDate > nowMoment.endOf('day').toDate() || this.dependent.birthDate < minBirthDate) {
      messageToShow = 'The birth date cannot be in the future or more than 110 years in the past.';
    }
    isValid = isValid ? this.memberForm.valid : false;
    if (isValid) {
      this.pushSaveDependent.emit(this.dependent);
    } else {
      this.coreService.popMessage(messageToShow, 'error', 4000, this.coreService.getInvalidFields(formContainer));
    }
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.memberForm);
  }

  cancelChanges(): void {
    this.pushCancelDependent.emit();
  }

  ssnAlreadyExistsForAnotherDependent(ssn: string): boolean {
    return some(this.subscriber.members, (m: Member) => m.socialSecurityNumber === ssn && m.memberId !== this.dependent.memberId);
  }

  canDelete(dependent: DependentComposite): boolean {
    // The dependent can be deleted only if he/she has been removed from coverage AND the Coverage End Date is prior to today’s date
    const currentCoverages = dependent.member.getAllCoverageByDate(new Date());

    return currentCoverages.length === 0;
  }

  deleteDependent(): void {
    if (this.dependent.simplifiedStatus === 'Denied' || this.canDelete(this.dependent)) {
      this.pushDeleteDependent.emit(this.dependent);
    } else {
      const message = 'The dependent you are trying to delete is currently enrolled in benefits. You must remove coverage before deleting dependent from your account.';
      this.coreService.popMessage(message, 'error', 4000);
    }
  }
}
