<div class="card" style="margin-bottom:20px;">
  <div class="card-body" style="margin-left: 12px">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div class="flex-item" fxFlex="50">
        <h3>Requested changes</h3>
      </div>
      <div class="flex-item" fxFlex="50" fxLayoutAlign="end" *ngIf="this.canEdit">
        <button class="btn btn-link" (click)="showWizardChange(!showWizard)">Click here to edit request</button>
      </div>
      <div class="flex-item" fxFlex="50" fxLayoutAlign="end" *ngIf="this.soe.simplifiedStatus === 'Draft'">
        This subscriber has not submitted their SOE event. Please work with the subscriber to complete and submit their SOE request.
      </div>
    </div>
    <div fxLayout="row">
      <ul style="list-style:none;">
        <li *ngFor="let log of logsForDisplay | keyvalue: originalOrder">
          <h4>{{log.key}}</h4>
          <ul>
            <li *ngFor="let message of log.value">
              {{message}}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div fxLayout="row" *ngIf="!hasLogs">
      This subscriber has not made any new elections as a result of this SOE event.  Please work with the subscriber to
      complete elections for the SOE event or deny the event as applicable.
    </div>
  </div>
</div>
<form [hidden]="soe.specialOpenEnrollmentType.managedByHCAInd && !isHCAAdmin"#verifyForm="ngForm">
  <div class="card">
    <div class="card-body" style="margin-left: 12px">
      <div fxLayout="row wrap">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="flex-end" *ngIf="isDualEnrolledForPeriod">
          <ngb-alert type="danger" [dismissible]="false">
            This subscriber has enrollment(s) in the {{dualEnrollment?.otherAgency === 'SEBB' ? 'School' : 'Public'}} Employees Benefit Program.  
            Dual enrollment between programs is not allowed.  Subscriber has waived coverage benefits in {{dualEnrollment?.currentAgency}} to remain 
            in {{dualEnrollment?.otherAgency}} or they have enrolled in {{dualEnrollment?.currentAgency}} benefits and their {{dualEnrollment?.otherAgency}} benefits will be ended.
          </ngb-alert>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="flex-end" class="mgbt-medium mgtp-medium">
          <checkbox-list
            name="approveDeny"
            [disabled]="!canEdit"
            [multi]="false"
            orientation="horizontal"
            [(ngModel)]="soe.approvedInd"
            (ngModelChange)="updatedVerificationStatus()"
            [items]="{
                'Verify': true,
                'Deny': false,
                'Pending': null
              }"
            ></checkbox-list>
        </div>

        <div fxLayout="row" fxFlex="100" *ngIf="soe.approvedInd !== null">
          <div class="form-group col-md-6" *ngIf="soe.approvedInd === true" required>
            <label for="specialOpenEnrollmentVerificationStatusId">Verification status<span class="k-required">*</span></label>
            <select required [disabled]="!canEdit" class="form-control" id="specialOpenEnrollmentVerificationStatusId" name="specialOpenEnrollmentVerificationStatusId" [(ngModel)]="soe.specialOpenEnrollmentVerificationStatusId">
              <option [ngValue]="null"></option>
              <option *ngFor="let verificationStatus of verifyValues | isObsolete" [ngValue]="verificationStatus.specialOpenEnrollmentVerificationStatusId">
                {{ verificationStatus.specialOpenEnrollmentVerificationStatusName }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6" *ngIf="soe.approvedInd === false" required>
            <label for="specialOpenEnrollmentVerificationStatusId">Verification status<span class="k-required">*</span></label>
            <select required [disabled]="!canEdit" class="form-control" id="specialOpenEnrollmentVerificationStatusId" name="specialOpenEnrollmentVerificationStatusId" [(ngModel)]="soe.specialOpenEnrollmentVerificationStatusId">
              <option [ngValue]="null"></option>
              <option *ngFor="let verificationStatus of denyValues | isObsolete" [ngValue]="verificationStatus.specialOpenEnrollmentVerificationStatusId">
                {{ verificationStatus.specialOpenEnrollmentVerificationStatusName }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6" *ngIf="soe.approvedInd === true">
            <date-picker
              #verifyDate="ngModel"
              name="verifyDate"
              label="Verify date"
              [disabled]="!canEdit"
              required
              enforceLowerLimit
              [agencyCode]="subscriber?.organization?.agency?.agencyCode"
              [min]="soe.requestReceivedDate"
              [max]="now"
              [(ngModel)]="soe.approvedDate"></date-picker>
            <div *ngIf="verifyForm.controls.verifyDate?.errors?.ngbDate?.minDate" class="alert alert-danger">Verification date cannot be prior to event submit date.</div>
            <div *ngIf="verifyForm.controls.verifyDate?.errors?.ngbDate?.maxDate" class="alert alert-danger">Verification date cannot be a future date.</div>
          </div>
          <div class="form-group col-md-6" *ngIf="soe.approvedInd === false">
            <date-picker
              #denyDate="ngModel"
              name="denyDate"
              label="Denied date"
              [disabled]="!canEdit"
              required
              enforceLowerLimit
              [agencyCode]="subscriber?.organization?.agency?.agencyCode"
              [min]="soe.requestReceivedDate"
              [max]="now"
              [(ngModel)]="soe.deniedDate"></date-picker>
            <div *ngIf="verifyForm.controls.verifyDate?.errors?.ngbDate?.minDate" class="alert alert-danger">Verification date cannot be prior to event submit date.</div>
            <div *ngIf="verifyForm.controls.verifyDate?.errors?.ngbDate?.maxDate" class="alert alert-danger">Verification date cannot be a future date.</div>
          </div>
        </div>
        <div class="mgtp-small" fxLayout="row" fxLayoutAlign="space-between" fxFlex="100" *ngIf="soe.approvedInd !== null && soe.specialOpenEnrollmentVerificationStatusId !== noDocumentId">
          <div fxFlex="45" class="form-group col-md-6" required>
            <label for="documentTypeId">Document type<span class="k-required">*</span></label>
            <select [disabled]="!canEdit" required class="form-control" id="documentTypeId" name="documentTypeId" [(ngModel)]="soe.documentTypeId">
              <option [ngValue]="null"></option>
              <option *ngFor="let documentType of soe.specialOpenEnrollmentType.documentTypes | isObsolete" [ngValue]="documentType.documentTypeId">
                {{ documentType.documentTypeName }}
              </option>
            </select>
          </div>
          <div fxFlex="45" *ngIf="dependentCoverageRemoved && canEdit">
            <div class="h4">Notification was provided no later than 60 days from the end of the month in which the event occurred?</div>
            <yes-no name="60DayInd" [disabled]="!canEdit" [(ngModel)]="within60DaysResponse" (ngModelChange)="within60Days.emit($event)"></yes-no>
          </div>
        </div>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="flex-end" *ngIf="isDualEnrolledForPeriod && soe.approvedInd === true" class="mgtp-medium" >
          <ngb-alert type="danger" [dismissible]="false">
            By verifying this event, the subscriber and any enrolled dependents will have coverages ended in the other program, 
            effective the last day of the month prior to the new program coverage beginning.
          </ngb-alert>
        </div>        
        <div class="form-row mgtp-medium mgbt-medium" style="width: 100%;" fxLayoutAlign="center">
          <button
            type="button"
            [disabled]="!canEdit || !verifyForm.touched || (verifyForm.touched && verifyForm.invalid)"
            class="btn btn-primary"
            style="margin-right: 20px"
            (click)="pushSaveVerification()"
          >
            Submit changes
          </button>
          <button type="button" [disabled]="!canEdit" class="btn" (click)="pendingChanges = false">Cancel</button>
        </div>
        <div fxFill fxLayout="column" *ngIf="soe.specialOpenEnrollmentVerificationStatus?.approvedInd && soeHasBeenCompleted" fxLayoutAlign="center center">
          <h5>Changes have been processed and confirmed. if a correction is needed, please contact contact Outreach and Training by phone at 1-800-700-1555 or bu submitting a request via <a target="_blank" href="https://www.fuzeqna.com/perspay/ext/kbsearch.aspx">FUZE</a>.</h5>
        </div>
        <!-- <div class="form-row" *ngIf="!canEdit" style="width: 100%; margin-top: 20px; margin-bottom: 20px" fxLayoutAlign="center">
          <span>
            If you need to change the status of a Special Open Enrollment event that has been verified or denied, please contact Outreach and Training via
            <a href="https://www.fuzeqna.com/sebbperspay/consumer/question.asp" target="_blank"><b> FUZE </b></a>
            or by calling 1-800-700-1555
          </span>
        </div> -->
      </div>
    </div>
  </div>
</form>

<!-- Wizzybiz -->
<kendo-dialog
  title="Review or Edit Special Open Enrollment Request"
  *ngIf="!(soe.specialOpenEnrollmentVerificationStatus?.approvedInd || soe.specialOpenEnrollmentVerificationStatus.deniedInd) && (!soe.specialOpenEnrollmentType.managedByHCAInd || isHCAAdmin) && showWizard"
  (close)="closeWizardDialogue()"
  [minWidth]="700"
  width="95vw"
  minHeight="90vh"
  height="90vh"
>
  <div fxLayout="column" class="hca-standard-font">
    <wiz-dual-enroll *ngIf="isDualEnrolledForPeriod" [hideBackToDash]="true" [subscriber]="soe?.member" (waive)="waiveDE.emit()" (continue)="continueEnrolling()" ></wiz-dual-enroll>
    <soe-effective-date *ngIf="(!soe?.coverageEffectiveDate || coverageDateInitialNull) && !soe?.member?.soeEnrollmentPeriod" [soe]="soe" (previous)="showWizard = false" (saveSOE)="saveCoverageDate()"></soe-effective-date>
    <router-outlet *ngIf="!isDualEnrolledForPeriod && subscriber?.soeEnrollmentPeriod" style="margin-bottom: 25px" fxLayoutAlign="center center" #wizardOutlet id="wizardOutlet">
      <pebb-wizard [isSOE]="true" *ngIf="soe?.coverageEffectiveDate && subscriber?.soeEnrollmentPeriod" [subscriber]="subscriber" [enrollmentPeriod]="subscriber?.soeEnrollmentPeriod" ></pebb-wizard>
    </router-outlet>
  </div>
  <!-- <kendo-dialog-actions>
    <button kendoButton (click)="showWizardChange(false)" themeColor="primary">Close</button>
  </kendo-dialog-actions> -->
</kendo-dialog>

<!-- Perspay still see form -->
<div *ngIf="soe.specialOpenEnrollmentType.managedByHCAInd && !isHCAAdmin" fxLayout="column" fxFlex="40">
  <h5>Special Open enrollment form review</h5>
  <!-- <div class="hca-document-placeholder" (click)="downloadPDF.emit(soe)" *ngIf="soe">
    <fa-icon class="hca-fontawesome-icon hca-document-icon" [icon]="icons.faImages" size="3x"></fa-icon>
  </div> -->
  <div *ngIf="soe" fxLayout="column" class="ie-height-xs" fxLayoutAlign="start start">
    <span><b>Type:</b> {{ soe?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName }}</span>
    <span><b>On:</b> {{ soe.requestReceivedDate | date: 'MM/dd/yyyy' }}</span>
  </div>
</div>
