export default class CobraQualifyReason {
  cobraQualifyReasonId: string;
  cobraQualifyReasonCode: string;
  cobraQualifyReasonName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(cobraQualifyReason?) {
    if (cobraQualifyReason) {
      this.cobraQualifyReasonId = cobraQualifyReason.cobraQualifyReasonId;
      this.cobraQualifyReasonCode = cobraQualifyReason.cobraQualifyReasonCode;
      this.cobraQualifyReasonName = cobraQualifyReason.cobraQualifyReasonName;
      this.obsoleteFlag = cobraQualifyReason.obsoleteFlag;
      this.obsoleteDate = cobraQualifyReason.obsoleteDate ? new Date(cobraQualifyReason.obsoleteDate) : null;
    }
  }

}
