import { Component, ViewEncapsulation, OnInit, OnChanges, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import SubmissionFileResponse from 'src/app/models/submissionFileResponse';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'error-review',
  templateUrl: 'errorReview.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class EligibilityFileErrorReviewComponent implements OnInit {
  @Input() errorResponses: SubmissionFileResponse[] = [];
  @Input() uploadDate: Date;
  gridColumns: { [k: string]: string | {} }[] = [
    {
      field: 'uploadDate',
      title: 'Upload date',
      format: { datetime: 'full' }
    },
    {
      field: 'firstName',
      title: 'First name',
      format: 'string'
    },
    {
      field: 'lastName',
      title: 'Last name',
      format: 'string'
    },
    {
      field: 'ssn',
      title: 'SSN',
      format: 'string'
    },
    {
      field: 'submissionFileResponseType.submissionFileResponseTypeName',
      title: 'Response',
      format: 'string'
    }, {
      field: 'columnName',
      title: 'Column',
      format: 'string'
    }, {
      field: 'rowNumber',
      title: 'Row',
      format: 'string'
    }, {
      field: 'responseMessage',
      title: 'Message',
      format: 'string',
      flex: '4'
    }
  ];

  constructor(private route: ActivatedRoute, private adminService: AdminService, private router: Router) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {});
  }
}
