export default class DependentDisplay {
  firstName: string;
  lastName: string;
  birthDate: Date;
  relationshipTypeName: string;
  enrolledInMedical: boolean;
  enrolledInDental: boolean;
  enrolledInVision: boolean;
  tobaccoUse: boolean;
  spousalSurcharge: boolean;
  memberId?: string;
  medicarePartAEnrolled: boolean;
  medicarePartBEnrolled: boolean;
  isSpouseInd: boolean;
  isStepChild: boolean;
  lossOfEligibilityReasonId: string;
  relationshipEffectiveStartDate: Date;
}
