import { CheckboxRadioListComponent } from './../../../../../../shared/components/checkbox-radio-list/checkbox-radio-list.component';
import Milestone from 'src/app/models/milestone';
import SelfPay from 'src/app/models/selfPay';
import { ActivatedRoute, Router } from '@angular/router';
import { faCalendar, faImages } from '@fortawesome/free-solid-svg-icons';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { sortBy, first, filter, find, cloneDeep } from 'lodash';
import * as dayjs from 'dayjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreService, AccessLevel, UserTypeCode } from 'src/app/services/core.service';
import SelfPayVerificationStatus from 'src/app/models/selfPayVerificationStatus';
import GenderIdentity from 'src/app/models/genderIdentity';
import BirthSex from 'src/app/models/birthSex';
import SelfPayPaymentType from 'src/app/models/selfPayPaymentType';
import Plan from 'src/app/models/plan';
import { PDFComponent } from 'src/app/modules/shared/components/pdf/pdf.component';
import SelfPayElection from 'src/app/models/selfPayElections';
import SystemUser from 'src/app/models/user';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import SelfpayVerificationStatus from 'src/app/models/selfPayVerificationStatus';
import SelfPayProcessStatus from 'src/app/models/selfPayProcessStatus';
import { env } from 'src/env/development';

@Component({
  selector: 'selfpay-form',
  templateUrl: 'selfpay.form.component.html',
  styleUrls: [],
  providers: []
})
export class SelfpayFormComponent implements OnInit, OnChanges {
  @Input() dataItem: SelfPay;
  @Input() systemUser: SystemUser;
  @Input() rowIndex;
  @Input() userCanEdit: boolean;
  @Input() genderIdentities: GenderIdentity[];
  @Input() birthSexes: BirthSex[];
  @Input() lookups = {
    selfPayVerificationStatus: [],
    documentTypes: [],
    selfPayProcessStatus: [],
    selfPayPaymentTypes: [],
    genderIdentities: [],
    birthSexes: [],
    county: [],
    country: [],
    addressType: [],
    homeAgencies: [],
    memberTypes: [],
    eligibililtyReasons: [],
    enrollmentReasons: [],
    originalAgencies: [],
    cobraQualifyReasons: [],
    relationshipTypes: [],
    relationshipQualifyReasons: [],
    reason: [],
    responses: [],
    questions: [],
    attestationsTypes: []
  };
  @Input() availablePlans: Plan[];
  @Input() supplementalPlans: Plan[];
  @Input() selfPayPaymentTypes: SelfPayPaymentType[];
  @Input() currentSelfpayEnrollment: SelfPayElection;
  requestStatusList: SelfpayVerificationStatus[] = [];
  selfPayProcessStatusList: SelfPayProcessStatus[] = [];
  isSOESPType: boolean = false;
  canEdit = false;
  pendingChanges;
  noDocumentId: string;
  icons = {
    faCalendar,
    faImages
  };
  proceedingWithWizard = false;
  showWizard = false;
  @Output() pushSaveVerificationResponse: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetData: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveAndContinue: EventEmitter<void> = new EventEmitter<void>();
  @Output() downloadPDF: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() saveSelfPayElections: EventEmitter<SelfPayElection> = new EventEmitter<SelfPayElection>();
  @ViewChild('verifyForm') public verifyForm: NgForm;
  @ViewChild('spVerificationCBList') public spVerificationCBList: CheckboxRadioListComponent;
  maxProcessDate: Date;
  minProcessDate: Date;
  now = new Date();
  coverageDateInitialNull = false;
  approved = false;
  step = 0;
  enrollmentPeriod: EnrollmentPeriod;
  milestoneCompleted: boolean = false;
  lastDataItemId: string;
  spVerificationListAll = [{Name: 'Approved', Id:'approved'},{Name:'Approved - pending payment', Id:'approvedPending'},
    {Name:'Deny', Id:'denied'},{Name:'Pending', Id:'pending'}];
  spVerificationList = [];
  isHCAEdit: boolean = false;
  displayCoverageDate: boolean = false;
  soeCustomForm: boolean = false;
  env = env;

  constructor(private coreService: CoreService, private modalService: NgbModal, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.isHCAEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
    this.setStatusLists();
    this.setCustomForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (this.dataItem) {
      this.milestoneCompleted = false;
      if (this.dataItem.selfPayEnrollmentPeriod) {
        if (find(this.dataItem.selfPayEnrollmentPeriod.milestones,(epm: Milestone)=> epm.isComplete) && this.dataItem.selfPayVerificationStatus?.selfPayVerificationStatusCode !== env.selfPayApprovedPendingFirstPaymentCode) {
          this.milestoneCompleted = true;
        }
      }

      this.maxProcessDate = new Date();
      this.minProcessDate = this.dataItem.submittedDate;

      if (this.lastDataItemId !== this.dataItem.selfPayId && changes.dataItem && changes.dataItem.currentValue && !changes.dataItem.previousValue) {
        this.coverageDateInitialNull = !this.dataItem.coverageEffectiveDate;
        this.lastDataItemId = this.dataItem.selfPayId;
      }

      this.enrollmentPeriod = this.dataItem?.member?.soeEnrollmentPeriod ??
      first(sortBy(this.dataItem?.member?.getEffectiveEnrollmentPeriods(this.dataItem?.member?.allEnrollmentPeriods), 'effectiveStartDate').reverse());

      if (this.dataItem.member && !this.dataItem.member.soeEnrollmentPeriod && this.enrollmentPeriod) {
        this.dataItem.member.soeEnrollmentPeriod = this.enrollmentPeriod;
      }

      this.setStatusLists();
      this.spVerificationList = this.spVerificationListAll;
      if (this.isSOESPType) {
        this.spVerificationList = filter(this.spVerificationListAll,(spv) => spv.Id !== 'approvedPending');
      }
        
      this.canEdit = this.userCanEdit;
      this.approved = this.dataItem.selfPayVerificationStatus.approvedInd;
      this.setCustomForm();
    }
  }

  setStatusLists() {
    this.isSOESPType = env.soeSelfPayTypeCodes.includes(this.dataItem.selfPayType.selfPayTypeCode);
    this.setRequestStatusList();
    this.selfPayProcessStatusList = sortBy(
      filter(this.lookups.selfPayProcessStatus, (sps: SelfPayProcessStatus) => (this.isSOESPType == false || sps.soeInd)),['selfPayProcessStatusName']);
  }

  setRequestStatusList() {
    switch(this.dataItem.currentStateOfVerify) {
      case "approved":
        this.requestStatusList = filter(this.lookups.selfPayVerificationStatus, (spvs: SelfpayVerificationStatus) => spvs.approvedInd 
          && (this.isSOESPType == false || spvs.soeInd));
        break;
      case "approvedPending":
        this.requestStatusList = filter(this.lookups.selfPayVerificationStatus, (spvs: SelfpayVerificationStatus) => spvs.approvedPendingInd 
          && (this.isSOESPType == false || spvs.soeInd));
        break;
      case "denied":
        this.requestStatusList = filter(this.lookups.selfPayVerificationStatus, (spvs: SelfpayVerificationStatus) => spvs.deniedInd 
          && (this.isSOESPType == false || spvs.soeInd));
        break;
      case "pending":
        this.requestStatusList = filter(this.lookups.selfPayVerificationStatus, (spvs: SelfpayVerificationStatus) => spvs.pendingInd 
          && (this.isSOESPType == false || spvs.soeInd));
        break;
    }
    
    this.requestStatusList = sortBy(this.requestStatusList, ['selfPayVerificationStatusName']);
  }

  pushSaveVerification(formContainer): void {
    this.markAllControlsAsTouched();
    if (this.verifyForm.valid) {
        this.setProcessValues();
        this.pushSaveVerificationResponse.emit();
    } else {
      if (this.verifyForm.controls.verifyDate?.errors?.ngbDate?.minDate) {
        this.coreService.popMessage('Verification date cannot be prior to request submit date.', 'error', 3000);
      } else if (this.verifyForm.controls.verifyDate?.errors?.ngbDate?.maxDate) {
        this.coreService.popMessage('Verification date cannot be a future date.', 'error', 3000);
      } else {
        this.coreService.popMessage('Please enter all required fields and re-submit', 'error', 3000, this.coreService.getInvalidFields(formContainer));
      }
    }
  }

  setProcessValues(): void {
    switch (this.dataItem.currentStateOfVerify) {
      case 'approved':
        this.dataItem.approvedDate = this.dataItem.processDate;
        this.dataItem.approvedPendingDate = null;
        this.dataItem.deniedDate = null;
        break;
      case 'approvedPending':
        this.dataItem.approvedPendingDate = this.dataItem.processDate;
        this.dataItem.approvedDate = null;
        this.dataItem.deniedDate = null;
        break;
      case 'denied':
        this.dataItem.deniedDate = this.dataItem.processDate;
        this.dataItem.approvedDate = null;
        this.dataItem.approvedPendingDate = null;
        break;
      default:
        this.dataItem.deniedDate = null;
        this.dataItem.approvedDate = null;
        this.dataItem.approvedPendingDate = null;
    }
  }

  updateProcessStatus(): void {
    this.setRequestStatusList();
    if (this.dataItem.currentStateOfVerify === 'approved' || this.dataItem.currentStateOfVerify === 'denied') { 
      this.dataItem.selfPayProcessStatusId = find(this.lookups.selfPayProcessStatus, (sps: SelfPayProcessStatus) => sps.selfPayProcessStatusCode === 'C' ).selfPayProcessStatusId;
    }
    if (this.dataItem.currentStateOfVerify !== 'pending') { 
      this.dataItem.processDate = new Date(this.now.getFullYear() + "-" + (this.now.getMonth()+1) + "-" + this.now.getDate() + " 00:00 am");
    }
    if (this.dataItem.currentStateOfVerify === 'approvedPending') {
      this.dataItem.selfPayProcessStatusId = find(this.lookups.selfPayProcessStatus, (sps: SelfPayProcessStatus) => sps.selfPayProcessStatusCode === 'PP' ).selfPayProcessStatusId;
      this.dataItem.selfPayVerificationStatusId = find(this.lookups.selfPayVerificationStatus, (spvs: SelfPayVerificationStatus) => spvs.selfPayVerificationStatusCode === env.selfPayApprovedPendingFirstPaymentCode ).selfPayVerificationStatusId;
    }
    else {
      this.dataItem.selfPayVerificationStatusId = this.requestStatusList[0].selfPayVerificationStatusId;
    }
  }

  reSetDataForGrid(): void {
    this.resetData.emit();
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.verifyForm);
  }

  public navigateToDependents(): void {
    this.router.navigate([this.dataItem.memberId + '/coverage/' + this.dataItem?.selfPayId], { relativeTo: this.route });
  }

  saveCoverageDate(): void {
    this.showWizard = true;
    this.saveAndContinue.emit();
    this.coverageDateInitialNull = false;
    this.displayCoverageDate = false;
    this.step = 2;
  }

  continueToEnrollments(): void {
    if (this.dataItem.coverageEffectiveDate) {
      this.step = 2;
      this.navigateToDependents();
    } else {
      this.step = 1;
    }
    this.currentSelfpayEnrollment = this.dataItem.selfPayElection;
    this.currentSelfpayEnrollment.selfPayId = this.dataItem.selfPayId;
  }

  proceedWithWizard(e: boolean): void {
    this.proceedingWithWizard = e;
    if (e && this.dataItem.coverageEffectiveDate) {
      this.showWizard = true;
      this.navigateToDependents();
    }
    else {
      this.displayCoverageDate = true;
    }
  }

  downloadSelfPayPDF(): void {
    this.downloadPDF.emit(this.dataItem);
  }

  pushSaveSelfPayElections(e: SelfPayElection, formContainer): void {
    this.coreService.markFormControlsAsTouched(this.verifyForm);
    if (this.verifyForm.invalid) {
      this.coreService.popMessage('Field validation errors. Please correct and try again.', 'error', 4000, this.coreService.getInvalidFields(formContainer));
    }
    else {
      this.saveSelfPayElections.emit(e);
      this.step = 3;
    }
  }

  setCustomForm() {
    if (env.soeSelfPayTypeCodesFormRequired.includes(this.dataItem.selfPayType.selfPayTypeCode)) {
      this.soeCustomForm = true;
    }
  }
}
