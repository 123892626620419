import AddressType from './addressType';
import County from './county';

export default class BaseAddress {
  addressTypeId: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  county: County;
  countyId: string;
  zipcodeNumber: string;
  addressType: AddressType;

  constructor(address?) {
    if (address) {
      this.addressTypeId = address.addressTypeId;
      this.addressLineOne = address.addressLineOne;
      this.addressLineTwo = address.addressLineTwo;
      this.city = address.city;
      this.state = address.state;
      this.addressType = address.addressType ? new AddressType(address.addressType) : null;
      this.county = address.county ? new County(address.county) : null;
      this.countyId = address.countyId;
      this.zipcodeNumber = address.zipcodeNumber;
    }
  }

  resetAddress() {
    this.addressLineOne = null;
    this.addressLineTwo = null;
    this.city = null;
    this.county = null;
    this.countyId = null;
    this.state = null;
    this.zipcodeNumber = null;
  }
}
