import { HCAErrorHandlingComponent } from './components/errorHandling/error.handling.component';
import { HCASyncErrorHandlingComponent } from './components/syncErrorHandling/sync.error.handling.component';
import { HCAAdminRootComponent } from './components/hcaAdminRoot/hca.admin.root.component';
import { CarrierTransactionsComponent } from './components/carrierTransactions/carrier.transactions.component';

// ng
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';

// ext
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { LabelModule } from '@progress/kendo-angular-label';

// local
import { RelationshipService } from '../../services/relationship.service';
import { UserService } from '../../services/user.service';
import { SharedModule } from '../shared/shared.module';

// app settings
import { LookupsComponent } from './components/appSettings/components/lookups/lookups.component';
import { LookupsSearchComponent } from './components/appSettings/components/lookups/components/search/lookups.search.component';
import { AppSettingsComponent } from './components/appSettings/app.settings.component';
import { LookupsGridComponent } from './components/appSettings/components/lookups/components/grid/lookups.grid.component';

// organization management
import { OrganizationManagementComponent } from './components/organizationManagement/organizationManagement.component';
import { OrganizationManagementNavComponent } from './components/organizationManagement/components/nav/organization.nav.component';
import { OrganizationManagementSearchComponent } from './components/organizationManagement/components/search/organization.search.component';

// managing perspay admin users
import { AdminAccessComponent } from './components/adminAccess/adminAccess.component';
import { AdminAccessGridComponent } from './components/adminAccess/components/grid/adminAccess.grid.component';
import { AdminAccessSearchComponent } from './components/adminAccess/components/search/adminAccess.search.component';

// subscriber/audit history
import { SubscriberHistoryComponent } from './components/subscriberHistory/subscriberHistory.component';

// account corrections
import { AccountCorrectionsComponent } from './components/accountCorrections/account.corrections.component';
import { AccountCorrectionEditEnrollmentComponent } from './components/accountCorrections/components/enrollments/edit.enrollment.component';
import { AccountCorrectionEditNotesComponent } from './components/accountCorrections/components/notes/edit.notes.component';
import { AccountCorrectionEditComponent} from './components/accountCorrections/accountCorrection.edit.component';
// self pay
import { SelfpayAdminComponent } from './components/selfpay/selfpay.admin.component';
import { SelfpayAdminGridComponent } from './components/selfpay/reviewGrid/selfpay.admin.grid.component';
import { SelfpayFormComponent } from './components/selfpay/reviewGrid/components/reviewForm/selfpay.form.component';
import { SelfpayReviewSummaryComponent } from './components/selfpay/reviewGrid/components/reviewSummary/selfpay.summary.component';
import { SelfpayEffectiveDateComponent } from './components/selfpay/reviewGrid/components/effectiveDate/selfpay.effectiveDate.component';
import { SelfpayEnrollmentTermComponent } from './components/selfpay/reviewGrid/components/enrollment/components/term/selfpay.enrollment.term.component';
import { SelfpayEnrollmentEligibilityComponent } from './components/selfpay/reviewGrid/components/enrollment/components/eligibility/selfpay.enrollment.eligibility.component';
import { SubscriberModule } from '../subscriber/subscriber.module';

// UW Sync Upload
import { UwSyncFileComponent } from './components/uwSyncFile/uwSyncFile.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    FormsModule,
    DialogsModule,
    InputsModule,
    FlexLayoutModule,
    FontAwesomeModule,
    LabelModule,
    GridModule,
    ExcelModule,
    SharedModule,
    NgbModule,
    SwitchModule,
    FormsModule,
    SubscriberModule,
    DropDownsModule
  ],
  declarations: [
    LookupsComponent,
    LookupsGridComponent,
    LookupsSearchComponent,
    AppSettingsComponent,
    OrganizationManagementComponent,
    OrganizationManagementNavComponent,
    OrganizationManagementSearchComponent,
    AdminAccessComponent,
    AdminAccessGridComponent,
    AdminAccessSearchComponent,
    SelfpayAdminComponent,
    SelfpayEffectiveDateComponent,
    SelfpayEnrollmentTermComponent,
    SelfpayEnrollmentEligibilityComponent,
    SelfpayReviewSummaryComponent,
    SelfpayFormComponent,
    SelfpayAdminGridComponent,
    HCAErrorHandlingComponent,
    HCASyncErrorHandlingComponent,
    HCAAdminRootComponent,
    CarrierTransactionsComponent,
    SubscriberHistoryComponent,
    UwSyncFileComponent,
    AccountCorrectionsComponent,
    AccountCorrectionEditComponent,
    AccountCorrectionEditEnrollmentComponent,
    AccountCorrectionEditNotesComponent

  ],
  providers: [RelationshipService, UserService, {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
  exports: [
    LookupsComponent,
    LookupsGridComponent,
    LookupsSearchComponent,
    AppSettingsComponent,
    OrganizationManagementComponent,
    OrganizationManagementNavComponent,
    OrganizationManagementSearchComponent,
    AdminAccessComponent,
    AdminAccessGridComponent,
    AdminAccessSearchComponent,
    SelfpayEnrollmentEligibilityComponent,
    SelfpayEnrollmentTermComponent
  ],
  bootstrap: [
    LookupsComponent,
    LookupsGridComponent,
    LookupsSearchComponent,
    AppSettingsComponent,
    OrganizationManagementComponent,
    OrganizationManagementNavComponent,
    OrganizationManagementSearchComponent,
    AdminAccessComponent,
    AdminAccessGridComponent,
    AdminAccessSearchComponent,
  ]
})
export class AdminHCAModule {}
