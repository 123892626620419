// handles redirecting users on 403 to systemusers and 401s on response.

// ng
import { Router } from '@angular/router';
import { Injector, Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';

// local
import { AuthService } from 'src/app/services/auth.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { env } from 'src/env/development';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  public authService: AuthService;
  private router: Router;
  constructor(private injector: Injector) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService === undefined) {
      this.authService = this.injector.get(AuthService);
    }
    if (this.router === undefined) {
      this.router = this.injector.get(Router);
    }
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do nothing
        return of(event);
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          setTimeout(() => this.authService.logout(), 4000);
          this.router.navigate(['/unauthorized']);
        } else if (err.status === 403 && request.url === `${env.apiUrl}/systemUsers`) {
          this.router.navigate(['/forbidden']);
        }
      }
      return of(err);
    }));
  }
}
