export default class Country {
    countryId: string;
    countryCode: string;
    countryName: string;
    obsoleteDate: Date;
    obsoleteFlag: boolean;

    constructor(country?) {
      if (country) {
        this.countryId = country.countryId;
        this.countryCode = country.countryCode;
        this.countryName = country.countryName;
        this.obsoleteDate = country.obsoleteDate ? new Date(country.ObsoleteDate) : null;
        this.obsoleteFlag = country.obsoleteFlag;
      }
    }

  }
