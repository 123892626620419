<div fxFlex fxFlexFill fxFlexLayout="column" class="ie-height-xl container-fluid">
  <div class="state">
    <div class="card">
      <div class="card-body" class="ie-height-l">
        <h2 class="mobile-text-centered mgtp-small mgbt-medium-sm" style="padding-left: 15px; padding-top: 5px;">Verification</h2>
        <div style="padding-bottom: 10px; padding-left: 15px;">
          Thank you for logging in to Benefits 24/7. Please provide the following information so we can first verify that we have you in our subscriber records. If you need help accessing Benefits 24/7:
          <ul>
            <li>Visit the <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/help-benefits-24/7">Help with Benefits 24/7</a> webpage.</li>
            <li>Employees: Contact your payroll or benefits office.</li>
            <li>Retirees and continuation coverage subscribers: Contact us through <a href="https://support.hca.wa.gov" target="_blank">HCA Support.</a></li>
          </ul> 
        </div>
        <nav class="navbar navbar-light mgbt-small" style="background-color: #efefef;">
          <span class="navbar-brand mb-0" role="heading" aria-level="3">Subscriber verification - Step {{ step }} of 2</span>
        </nav>
        <form *ngIf="step === '1'" #accessForm="ngForm">
          <div fxLayout="column" class="ie-height-xs">
            <div class="form-group col-md-4 required">
              <label for="lastName"><strong>Subscriber last name</strong></label>
              <input pattern="[a-zA-Z0-9 -]*" required type="text" class="form-control" name="lastName" [(ngModel)]="claim.lastName" />
            </div>
            <div class="form-group col-md-4">
              <date-picker
                #d="ngModel"
                name="dateOfBirth"
                [required]="true"
                [(ngModel)]="claim.dateOfBirth"
                >
                <strong>Subscriber date of birth</strong>
              </date-picker>
            </div>
            <div class="form-group col-md-4 required" required>
              <label for="lastFourSSN"><strong>Last 4 digits of subscriber's Social Security number</strong></label>
              <input minlength="4" maxlength="4" placeholder="XXXX" required type="text" pattern="[0-9]*" class="form-control" name="lastFourSSN" [(ngModel)]="claim.lastFourOfSocial" />
            </div>
            <div fxFlex="row" fxFlexFill fxLayoutAlign="center center" style="padding-bottom: 20px;">
              <button [disabled]="claimFormSubmitted" style="padding-left: 20px; padding-right: 20px;" (click)="submitClaimInformation()" type="submit" class="btn btn-primary">Verify my information</button>
            </div>
          </div>
        </form>
        <div *ngIf="step === '2'" class="ie-height-xs fxGap-medium pad-std-container" fxLayout="column">
          <div *ngIf="memberInfo && memberInfo.length >= 1">
            <div class="mgbt-x-small"><b style="padding-left: 5px;">We found the following record(s) matching the information you provided:</b></div>
            <div class="card pad-std-container" fxLayout.xs="column" fxLayout="row">
              <div fxFlex="100" fxLayout="column" style="height: 100%;" class="ie-height-xs" fxLayoutAlign="space-between">
                <div *ngFor="let memberRecord of memberInfo">
                  <div>
                    <span class="pad-std-label"><strong>Name: </strong></span>
                    <span>{{ memberRecord.firstName }} {{ memberRecord.lastName }}</span>
                  </div>
                  <div style="padding-bottom: 10px;">
                    <span class="pad-std-label"><strong>Employer: </strong></span>
                    <span>{{ memberRecord.employerName }}</span>
                  </div>
                </div>
                <h5 *ngIf="!memberInfo[0].systemUserId" class="mobile-text-centered">
                  <strong>Please select three security questions and enter your answers - these questions will be used if you need to recover your account in the future.</strong>
                </h5>
                <h5 *ngIf="memberInfo[0].systemUserId" class="mobile-text-centered"><strong>Please answer these security questions to reclaim this account:</strong></h5>
                <br />
                <div fxLayout="column">
                  <div fxLayout.xs="column" class="fxGap-medium" fxLayout="row" fxLayoutAlign="space-between">
                    <div style="flex: 50;" class="form-group required">
                      <ng-template #answerTooltip> <span>Must be between 4 and 60 characters long</span><br /><br /> </ng-template>
                      <label for="pebbClaimSecurityQuestion1">Security question 1</label>
                      <select
                        [disabled]="memberInfo[0].systemUserId"
                        id="pebbClaimSecurityQuestion1"
                        placeholder="Enter a Security question"
                        required
                        class="form-control"
                        name="pebbClaimSecurityQuestion1"
                        [(ngModel)]="memberInfo[0].securityQuestions[0].questionId"
                      >
                        <option></option>
                        <option *ngFor="let question of filterQuestions(securityQuestions, memberInfo[0].securityQuestions[0].questionId)" [ngValue]="question.questionId">{{
                          question.question
                        }}</option>
                      </select>
                    </div>
                    <div style="flex: 50;" class="form-group required">
                      <label for="pebbClaimSecurityQuestion1Answer">Security question 1 answer</label>
                      <input
                        name="pebbClaimSecurityQuestion1Answer"
                        [(ngModel)]="memberInfo[0].securityQuestions[0].response"
                        required
                        minlength="4" 
                        maxlength="60" 
                        type="text"
                        class="form-control"
                        id="pebbClaimSecurityQuestion1Answer"
                        placeholder="Enter an answer for this question"
                        placement="bottom"
                        [ngbTooltip]="answerTooltip"
                      />
                    </div>
                  </div>
                  <div fxLayout.xs="column" class="fxGap-medium" fxLayout="row" fxLayoutAlign="space-between">
                    <div style="flex: 50;" class="form-group required">
                      <label for="pebbClaimSecurityQuestion2">Security question 2</label>
                      <select
                        [disabled]="memberInfo[0].systemUserId"
                        id="pebbClaimSecurityQuestion2"
                        placeholder="Enter a Security question"
                        required
                        class="form-control"
                        name="pebbClaimSecurityQuestion2"
                        [(ngModel)]="memberInfo[0].securityQuestions[1].questionId"
                      >
                        <option></option>
                        <option *ngFor="let question of filterQuestions(securityQuestions, memberInfo[0].securityQuestions[1].questionId)" [ngValue]="question.questionId">{{
                          question.question
                        }}</option>
                      </select>
                    </div>
                    <div style="flex: 50;" class="form-group required">
                      <label for="pebbClaimSecurityQuestion2Answer">Security question 2 answer</label>
                      <input
                        name="pebbClaimSecurityQuestion2Answer"
                        [(ngModel)]="memberInfo[0].securityQuestions[1].response"
                        required
                        minlength="4" 
                        maxlength="60" 
                        type="text"
                        class="form-control"
                        id="pebbClaimSecurityQuestion2Answer"
                        placeholder="Enter an answer for this question"
                        placement="bottom"
                        [ngbTooltip]="answerTooltip"
                      />
                    </div>
                  </div>
                  <div fxLayout.xs="column" class="fxGap-medium" fxLayout="row" fxLayoutAlign="space-between">
                    <div style="flex: 50;" class="form-group required">
                      <label for="pebbClaimSecurityQuestion3">Security question 3</label>
                      <select
                        [disabled]="memberInfo[0].systemUserId"
                        id="pebbClaimSecurityQuestion3"
                        placeholder="Enter a Security question"
                        required
                        class="form-control"
                        name="pebbClaimSecurityQuestion3"
                        [(ngModel)]="memberInfo[0].securityQuestions[2].questionId"
                      >
                        <option></option>
                        <option *ngFor="let question of filterQuestions(securityQuestions, memberInfo[0].securityQuestions[2].questionId)" [ngValue]="question.questionId">{{
                          question.question
                        }}</option>
                      </select>
                    </div>
                    <div style="flex: 50;" class="form-group required">
                      <label for="pebbClaimSecurityQuestion3Answer">Security question 3 answer</label>
                      <input
                        name="pebbClaimSecurityQuestion3Answer"
                        [(ngModel)]="memberInfo[0].securityQuestions[2].response"
                        required
                        minlength="4" 
                        maxlength="60" 
                        type="text"
                        class="form-control"
                        id="pebbClaimSecurityQuestion3Answer"
                        placeholder="Enter an answer for this question"
                        placement="bottom"
                        [ngbTooltip]="answerTooltip"
                      />
                    </div>
                  </div>
                </div>
                <div fxLayoutAlign="center center" fxFlex="100">
                  <button [disabled]="claimAcccountFormSubmitted" (click)="submitClaimSubscriber(memberInfo[0])" class="btn btn-primary">Claim this account</button>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="row" fxFlexFill fxLayoutAlign="space-around center" class="mgbt-medium">
            <button (click)="step = '1'" class="btn btn-light">Back</button>
          </div>
        </div>
        <div *ngIf="step === '3'" fxLayout="column" class="ie-height-xs">
          <span>Congratulations! You have successfully claimed your account.</span>
          <div fxFlex="row" fxFlexFill fxLayoutAlign="space-around center">
            <button class="btn btn-primary">
              <a style="color: #ffff; text-decoration: none;" [routerLink]="'/subscriber/' + claimedSubscriber.memberId">Go to my dashboard</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
