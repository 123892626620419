import SubscriberComposite from 'src/app/models/subscriberComposite';
import { Component, ViewEncapsulation, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep, find, filter } from 'lodash';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import Subscriber from 'src/app/models/subscriber';
import TerminationComposite from 'src/app/models/terminationComposite';
import Reason from 'src/app/models/reason';
import { CoreService } from 'src/app/services/core.service';
import { env } from 'src/env/development';
import { LowerLimitService } from 'src/app/services/lowerLimit.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'employment-term',
  templateUrl: 'subscriberManagement.employment.term.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class SubscriberManagementEmploymentTermComponent implements OnChanges {
  @Input() lookups = {
    memberTypes: [],
    organizations: [],
    terminationReasons: [],
    eligibilityReasons: [],
  };
  icons = {
    faCalendar
  };
  @Input() disabled = true;
  @Input() terminationComposite: TerminationComposite;
  @Input() subscriberComposite: SubscriberComposite;
  @Input() isHCA = false;
  @Input() isSebb = false;
  @Input() agencyCode: string;
  deathMonthStart: Date;
  deathMonthEnd: Date;
  reasonDeath: string;
  reasonTransfer: string;
  reasonNotEligible: string;
  currentTermRecord: TerminationComposite;
  initialTransferExists = false;
  existingTermMonthEnd: Date;
  @Output() submitTermination: EventEmitter<TerminationComposite> = new EventEmitter<TerminationComposite>();
  @Output() cancelTransfer: EventEmitter<TerminationComposite> = new EventEmitter<TerminationComposite>();
  @Output() updateSubscriberDeathDate: EventEmitter<Date> = new EventEmitter<Date>();

  @ViewChild('termForm') public termForm: NgForm;
  constructor(
    private coreService: CoreService,
    private lowerLimitService: LowerLimitService
  ) {}

  // cannot be > the last day of next month
  // cannot be < eligibility date
  async ngOnChanges(): Promise<void> {
    const today = dayjs();
    if (this.terminationComposite) {
      const death = find(this.lookups.terminationReasons, (tr: Reason) => tr.reasonName === 'Death');
      const transfer = find(this.lookups.terminationReasons, (tr: Reason) => tr.reasonName === 'Transfer');
      const notEligible = find(this.lookups.terminationReasons, (tr: Reason) => tr.reasonCode === 'NE');
      const eligibilityDate = this.terminationComposite?.eligibilityDate ?
        dayjs(this.terminationComposite.eligibilityDate) :
        null;
      const lowerLimitDate = await lastValueFrom(this.lowerLimitService.getLowerLimit(null, false, this.isSebb ? env.sebbCode : env.pebbCode));

      if (!this.isHCA && eligibilityDate && eligibilityDate.isBefore(lowerLimitDate)) {
        this.lookups.terminationReasons = this.lookups.terminationReasons.filter((r) => r.reasonCode !== 'NE');
      }

      if (this.isSebb) {
        // include only sebb term reasons
        this.lookups.terminationReasons = this.lookups.terminationReasons.filter((r) => env.termSebbReasonCodes.includes(r.reasonCode));
      }

      this.reasonDeath = death ? death.reasonId : null;
      this.reasonTransfer  = transfer ? transfer.reasonId : null;
      this.currentTermRecord = cloneDeep(this.terminationComposite);
      this.reasonNotEligible = notEligible ? notEligible.reasonId : null;

      if (this.terminationComposite.lossOfEligibilityReasonId === this.reasonDeath && !env.allowFutureDeceasedDates){
        const deceasedDateStart = dayjs(this.subscriberComposite.deceasedDate).startOf('month');
        let deceasedDateEnd = dayjs(this.subscriberComposite.deceasedDate).endOf('month');
        if (dayjs().isBefore(deceasedDateEnd)) {
          deceasedDateEnd = dayjs();
        }
        this.deathMonthEnd = deceasedDateEnd.toDate();
        this.deathMonthStart = deceasedDateStart.toDate();
      }

      if (!this.deathMonthEnd) {
        this.deathMonthEnd = new Date();
      }
      if(this.terminationComposite?.lossOfEligibilityReasonId) {
        this.existingTermMonthEnd = dayjs(this.subscriberComposite.terminationDate).endOf('month').toDate();
      }
    }
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.termForm);
  }

  onTerminateMemberCoverage(formContainer): void {
    this.markAllControlsAsTouched();
    if (this.termForm.valid) {
      const notEligible = find(this.lookups.terminationReasons, (tr: Reason) => tr.reasonCode === 'NE');
      if (this.currentTermRecord.lossOfEligibilityReasonId && this.currentTermRecord.lossOfEligibilityReasonId != notEligible?.reasonId 
          && this.currentTermRecord.terminationDate < this.subscriberComposite.agencyEffectiveStartDate) {
        this.coreService.popMessage('Termination date cannot be less than effective start date.', 'error', 4000, this.coreService.getInvalidFields(formContainer));  
        return;
      }

      this.submitTermination.emit(this.currentTermRecord);
    } else {
      this.coreService.popMessage('Field validation errors. Please correct and try again.', 'error', 4000, this.coreService.getInvalidFields(formContainer));
    }
  }

  terminationDateChange(): void {
    this.subscriberComposite.terminationDate = this.currentTermRecord.terminationDate;
  }

  onUpdateSubscriberDeathDate(terminationDate, formContainer): void {
    this.markAllControlsAsTouched();
    if (this.termForm.valid) {
      this.updateSubscriberDeathDate.emit(terminationDate);
    } else {
      this.coreService.popMessage('Field validation errors. Please correct and try again.', 'error', 4000, this.coreService.getInvalidFields(formContainer));
    }
  }

  getOptions() {
    // get available selection options for termination
    const options = this.lookups.terminationReasons;
    if (this.terminationComposite?.lossOfEligibilityReasonId && this.terminationComposite?.lossOfEligibilityReasonId !== this.reasonTransfer) {
      return options.filter(v => v.reasonName !== 'Transfer');
    } else {
      return options;
    }
  }
}
