<form fxFlexFill (ngSubmit)="search()" #searchForm="ngForm" fxFlexLayout="column">
    <div fxFlexFill class="input-group mb-3">
      <input
        style="height: 50px;"
        [(ngModel)]="queryString"
        type="text"
        class="form-control"
        [placeholder]="placeholder"
        
        aria-describedby="search"
        name="search"
      />
      <div class="input-group-append"><button [disabled]="!queryString" class="btn btn-outline-secondary" type="submit">Search</button></div>
    </div>
  </form>