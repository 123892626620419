<div class="container-fluid">
  <div fxFlexFill FxLayout="column" class="card">
    <div class="card-body">
      <h3 class="mobile-text-centered mgtp-small">Forbidden</h3>
      <h5>
        Your associated user does not have the correct permissions or your permissions have expired. If you believe you
        have reached this message in error, please contact your district or HCA contact.
      </h5>
    </div>
  </div>
</div>
