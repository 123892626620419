/**
 * User is routed here from auth component if the returned systemUser record indicates
 * they have not accepted the current TOU. On Accept - User is re-routed to auth which
 * calls again for systemUser and routes them to dashboard. On decline user is logged out.
 */

// ng
import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ext
import { find } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import ApplicationSetting from 'src/app/models/applicationSettting';
import TermsOfUse from 'src/app/models/termsOfUse';
import TermsOfUseAction from 'src/app/models/termsOfUseAction';
import TermsOfUseActionType from 'src/app/models/termsOfUseActionType';
import SystemUser from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { TOUService } from 'src/app/services/tou.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService } from 'src/app/services/core.service';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';

@UntilDestroy()
@Lookups(LookupType.ApplicationSetting, LookupType.TermsOfUseActionType)
@Component({
  selector: 'terms-of-use',
  templateUrl: 'termsOfUse.component.html',
  styleUrls: [],
  providers: [],
})
export class TermsOfUseComponent implements OnInit {
  tou: TermsOfUse;
  systemUser: SystemUser;
  systemUserId: string;
  touActionTypes: TermsOfUseActionType[];
  userHasReadIt = false;
  healthEquityEnabled = true;
  touSubmitted: boolean = false;
  @HostListener('scroll', ['$event.target']) onscroll(elem): void {
    const target = elem.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.userHasReadIt = true;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private termsOfUseService: TOUService,
    private spinnerService: SpinnerOverlayService,
    private router: Router,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.systemUserId = this.route.snapshot.paramMap.get('id');
    const now = new Date();
    this.route.data.pipe(untilDestroyed(this)).subscribe((d) => {
      this.coreService.getSystemUser().pipe(untilDestroyed(this)).subscribe((s) => {
        this.systemUser = s;
      });

      this.touActionTypes = d.lookups.termsOfUseActionType;
      this.tou = find(d.termsOfUse, (t) => t.effectiveStartDate?.getTime() <= now.getTime() && (!t.effectiveEndDate || t.effectiveEndDate.getTime() >= now.getTime())) || d.termsOfUse[0];

      const settings: ApplicationSetting[] = d.lookups.applicationSettings;
      if (settings?.length) {
        const healthEquity = find(settings, as => as.applicationSettingCode === 'HEALTHEQUITY');
        if (healthEquity) {
          const noResponse = find(healthEquity.responses, r => r.responseCode === 'N')?.responseId;

          if (noResponse) {
            this.healthEquityEnabled = healthEquity.responseId !== noResponse;
          }
        }
      }
    });
  }

  async acceptTOU(): Promise<void> {
    this.touSubmitted = true;
    const touAction = new TermsOfUseAction();
    touAction.termsOfUseId = this.tou.termsOfUseId;
    touAction.termsOfUseActionTypeId = find(this.touActionTypes, (t) => t.termsOfUseActionCode === 'A').termsOfUseActionTypeId;
    try {
      this.spinnerService.show();
      await lastValueFrom(this.termsOfUseService.createTermsOfUseAction(touAction, this.systemUserId));
    } catch (err) {
      this.spinnerService.hide();
      this.touSubmitted = false;
      return;
    }
    if (this.systemUser.memberId && this.healthEquityEnabled) {
      this.router.navigate([`/healthEquity/${this.systemUser.memberId}`]);
    } else {
      this.router.navigate(['/auth']);
    }

    this.spinnerService.hide();
  }

  logOut(): void {
    this.authService.logout();
  }
}
