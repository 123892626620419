<div class="card-body">
  <h5 class="mobile-text-centered">Coverage information</h5>
  <span>Note: Newly added dependents will not appear here unless they will receive coverage for the current year based on an event that creates a special open enrollment.</span>
  <div class="dashboard-grid-parent" style="padding:20px">
    <generic-grid [gridData]="memberSummary" [gridColumns]="coverageOverviewColumns"></generic-grid>
  </div>
  <h5 class="mobile-text-centered">Attestations</h5>
  <div *ngIf="!inAdminState" style="padding-bottom:10px;">
    For details on how to reattest (change your response), go to the <strong><em>Attestations</em></strong> tab.
  </div>
  <h5>Tobacco use premium surcharge:</h5>
  <div>
    <div>
      If {{inAdminState ? 'subscriber' : 'you'}} responded NO for any of the dependents below, they either have not used tobacco products in the last two months, or are currently
      enrolled in {{inAdminState ? 'subscriber\'s' : 'your'}} medical plan's tobacco cessation program (if ages 18 or older) or have accessed information and resources at 
      <a href="https://teen.smokefree.gov/" target="_blank">Smokefree Teen</a> (if ages 13-17). Enrolled dependents ages 12 and younger are automatically defaulted to NO.
      If {{inAdminState ? 'the employee' : 'you'}} responded YES for {{inAdminState ? 'themselves' : 'yourself'}}  or any of the dependents listed below, {{inAdminState ? 'they' : 'you'}} 
      will be charged the monthly $25 per-account premium surcharge in addition to {{inAdminState ? 'their' : 'your'}} monthly medical premiums.
    </div>
  </div>
  <div class="dashboard-grid-parent" style="padding:20px">
    <generic-grid [gridData]="memberSummary" [gridColumns]="tobaccoColumns"></generic-grid>
  </div>
  <div>
    <b>Spouse or state-registered domestic partner (SRDP) coverage premium surcharge</b>
    <div style="padding-bottom: 20px;">
      <strong> {{inAdminState ? 'Subscriber' : 'You'}} will pay the spouse or SRDP coverage premium surcharge: </strong>  {{spousalCoverageSurcharge ? 'Yes' : 'No'}}
    </div>
  </div>
</div>
