import { ComponentCanDeactivate } from './../../../../guards/pendingChanges/pendingChanges.guard';
import { lastValueFrom, Observable } from 'rxjs';
import { EnrollmentPeriodService } from './../../../../services/enrollmentPeriod.service';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Milestone from 'src/app/models/milestone';
// ng
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router, CanDeactivate } from '@angular/router';

// ext
import { cloneDeep, sortBy, map, filter, some, includes, flatten, find, uniqBy, forEach, groupBy, isEqual, merge, keyBy, unionBy } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import { DocumentService } from 'src/app/services/document.service';
import { GenericFileUploadComponent } from 'src/app/modules/shared/components/genericFileUpload/genericFileUpload.component';
import { UploadAssociationComponent } from './components/association/upload.association.component';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { RelationshipService } from 'src/app/services/relationship.service';
import { CoreService } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import SystemUser from 'src/app/models/user';
import Subscriber from 'src/app/models/subscriber';
import Document from 'src/app/models/document';
import DocumentType from 'src/app/models/documentType';
import Member from 'src/app/models/member';
import Relationship from 'src/app/models/relationship';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import OpenEnrollment from 'src/app/models/openEnrollment';
import Enrollment from 'src/app/models/enrollment';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';

@UntilDestroy()
@Lookups(LookupType.DocumentType, LookupType.RelationshipVerificationStatus)
@Component({
  selector: 'upload',
  templateUrl: 'upload.component.html',
})
export class UploadComponent implements OnInit, ComponentCanDeactivate {
  inAdminState: boolean;
  allowedFileTypes: string[] = ['pdf', 'jpg', 'jpeg', 'png'];
  systemUser: SystemUser;
  subscriber: Subscriber;
  initialSubscriber: Subscriber;
  documentTypes: DocumentType[];
  documents: Document[] = [];
  members: Member[];
  initialMembers: Member[];
  initialSOEDocuments: Document[];
  activeOE = false;
  submitted = false;
  relationshipVerificationStatuses: RelationshipVerificationStatus[];
  isFirefox = false;
  openEnrollment: OpenEnrollment;
  uploadMilestone: Milestone;
  enrollmentPeriod: EnrollmentPeriod;
  changesHaveBeenSaved = false;
  hasUnverifiedDisabled = false;
  @ViewChild('fileUpload') fileUpload: GenericFileUploadComponent;
  @ViewChild('association') associationComponent: UploadAssociationComponent;
  public showConfirmation = false;
  currentSOE: SpecialOpenEnrollment;
  isSOE = false;
  isDeathOrDivorceSoe = false;

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private documentService: DocumentService,
    private subscriberService: SubscriberService,
    private relationshipService: RelationshipService,
    public coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private enrollmentPeriodService: EnrollmentPeriodService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      (data) => {
        // messaging around an upload bug with firefox.
        this.isFirefox = navigator.userAgent && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        this.systemUser = data.user;
        this.subscriber = data.subscriber;
        this.relationshipVerificationStatuses = data.lookups.relationshipVerificationStatus;
        this.documentTypes = data.lookups.documentType;
        this.members = this.filterMembers(this.subscriber);
        // removed soe docs from this call - can add back if they decide they want to associate later
        this.initialSubscriber = cloneDeep(this.subscriber);
        this.enrollmentPeriod = data.enrollmentPeriod;
        if (this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SOE') {
          this.isSOE = true;
        }
        if (this.isSOE) {
          this.setCurrentSOE();
        }
        this.hasUnverifiedDisabled = some(this.subscriber.members, (m: Member) => m.relationshipToSubscriber?.relationshipQualifyReason?.relationshipQualifyReasonCode === 'A' && 
          m.relationshipToSubscriber?.relationshipVerificationStatus?.relationshipVerificationStatusCode === 'R' &&
          m.getCoverageByPlanTypeAndDate('Medical', this.enrollmentPeriod.coverageEffectiveStartDate)
        )
        this.documents = this.getFlatDocuments(this.members);
        this.initialMembers = cloneDeep(this.members);
        this.uploadMilestone = find(this.enrollmentPeriod.milestones, (mi: Milestone) => mi.milestoneName === 'Upload');
        forEach(this.documents, (document: Document) => {
          // fetch document content
          this.documentService.getMemberDocumentById(this.subscriber.memberId, document.documentId, document).pipe(untilDestroyed(this)).subscribe((doc) => {
            document = doc;
          });
        });
        if (this.router.url.includes('admin')) {
          this.inAdminState = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleFilesSelected(e): void {
    e.files.forEach((file) => {
      const document = new Document();
      document.documentName = file.name;
      document.documentBlob = file.rawFile;
      document.documentId = 'NEW' + file.name;
      document.documentTypeId = null;
      this.documents.push(document);
      this.documents = cloneDeep(this.documents);
    });
  }

  submitLabel(pendingChanges: boolean, enrollmentPeriod: EnrollmentPeriod, isDeathOrDivorceSoe: boolean): string {
    if (isDeathOrDivorceSoe) {
      return 'Proceed to confirmation';
    }

    if (pendingChanges) {
      return 'Upload / confirm changes';
    }

    if (enrollmentPeriod.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE') {
      return 'Proceed to attestations';
    }

    return 'Proceed to elect coverage';
  }

  handleFilesRemoved(e): void {
    const removedFileNames = map(e.files, 'name');
    this.documents = filter(this.documents, (d) => !removedFileNames.includes(d.documentName));
  }

  filterMembers(subscriber: Subscriber): Member[] {
    const allMembers: Member[] = cloneDeep(subscriber.members);
    this.subscriber = subscriber;
    this.members = filter(allMembers, (m) => {
      return !m.isSubscriberInd;
    });

    return sortBy(
      this.members,
      (m) => {
        if (m.relationshipToSubscriber && m.relationshipToSubscriber.relationshipType) {
          return m.relationshipToSubscriber.relationshipType.sortOrder;
        } else {
          return 0;
        }
      },
      ['asc']
    );
  }

  async submitFileChanges(): Promise<void> {
    // Relationship
    this.submitted = true;
    if (this.associationComponent) {
      this.associationComponent.markAllControlsAsTouched();
    }
    const allInitialRelationshipDocuments = flatten(map(this.initialMembers, 'relationshipToSubscriber.documents'));
    const allModifiedRelationshipDocuments = flatten(map(this.members, 'relationshipToSubscriber.documents'));
    // newly uploaded (POST multipart content)
    const newRelDocuments = filter(allModifiedRelationshipDocuments, (d) => d.documentId.includes('NEW'));
    const groupedNewRelDocuments = groupBy(newRelDocuments, 'documentName');

    // doctype change (PUT to docID)
    const docTypeChanges = filter(allModifiedRelationshipDocuments, (d) => some(allInitialRelationshipDocuments, (id) => id.documentId === d.documentId && id.documentTypeId !== d.documentTypeId));
    // member added (POST w/o payload)
    const memberChanges = filter(
      allModifiedRelationshipDocuments,
      (doc) => !doc.documentId.includes('NEW') && !some(allInitialRelationshipDocuments, (md) => md.relationshipId === doc.relationshipId && md.documentId === doc.documentId)
    );
    // member removed (DELETE w/ relationshipId & documentId)
    const docsRemoved = filter(allInitialRelationshipDocuments, (doc) => !some(allModifiedRelationshipDocuments, (md) => md.relationshipId === doc.relationshipId && md.documentId === doc.documentId));

    const allInitialSOEDocuments = flatten(this.initialSOEDocuments);
    const allModifiedSOEDocuments = flatten(this.currentSOE?.documents);
    const newSOEDocuments = filter(allModifiedSOEDocuments, (d) => d.documentId.includes('NEW'));
    const soeDocsRemoved = filter(allInitialSOEDocuments, (doc) => !some(allModifiedSOEDocuments, (md) => md.documentId === doc.documentId));
    const soeChanges = filter(
      allModifiedSOEDocuments,
      (doc) => !doc.documentId.includes('NEW') && !some(allInitialSOEDocuments, (md) => md.documentId === doc.documentId)
    );

    // set if documents were added, changed or deleted
    const someRelChange = newRelDocuments.length || docTypeChanges.length || memberChanges.length || docsRemoved.length
      || newSOEDocuments.length || soeDocsRemoved.length || soeChanges.length;

    // will need to change when bootstrapping for SOE
    if (someRelChange) {
      this.spinnerService.show();
      try {
        const newDocsAdded: Document[] = [];
        const newDocPromises: Promise<Document>[] = [];
        const memberChangePromises: Promise<Document>[] = [];
        const docTypePromises: Promise<Document>[] = [];
        const deleteMemberPromises: Promise<Document>[] = [];
        const deleteSOEDocumentPromises: Promise<Document>[] = [];
        const newSOEDocumentPromises: Promise<Document>[] = [];
        const relationshipPromises: Promise<Relationship>[] = [];
        for (const k in groupedNewRelDocuments) {
          if (groupedNewRelDocuments.hasOwnProperty(k)) {
            const newDoc = await lastValueFrom(this.documentService
              .createDocumentForRelationship(this.subscriber.memberId, groupedNewRelDocuments[k][0].relationshipId, groupedNewRelDocuments[k][0].documentTypeId,
                groupedNewRelDocuments[k][0], this.currentSOE?.specialOpenEnrollmentId)
              );
            newDocsAdded.push(newDoc[0]);
            // if (groupedNewRelDocuments[k].length > 1) {
            //   groupedNewRelDocuments[k].shift();
            // }
            for (const doc of groupedNewRelDocuments[k]) {
              const associatedRelationship: Relationship = map(
                filter(this.members, (m) => m.relationshipToSubscriber.relationshipId === doc.relationshipId),
                'relationshipToSubscriber'
              )[0];
              // if relationship denied, also put relationship changing to not denied.
              if (associatedRelationship.simplifiedStatus === 'Denied' && (this.subscriber.activeSOE || this.activeOE)) {
                const receivedStatusId = find(this.relationshipVerificationStatuses, (rvs) => rvs.relationshipVerificationStatusName === 'Received').relationshipVerificationStatusId;
                associatedRelationship.relationshipVerificationStatusId = receivedStatusId;
                associatedRelationship.deniedDate = null;
                const relationshipReturned = await lastValueFrom(this.relationshipService.updateRelationship(associatedRelationship));
                const secondaryAssociatedRelationship: Relationship = relationshipReturned.secondaryRelationships[0];
                secondaryAssociatedRelationship.deniedDate = null;
                secondaryAssociatedRelationship.relationshipVerificationStatusId = receivedStatusId;
                relationshipPromises.push(lastValueFrom(this.relationshipService.updateRelationship(secondaryAssociatedRelationship)));
              }
              newDocPromises.push(lastValueFrom(this.documentService.addDocumentToMember(this.subscriber.memberId, doc.relationshipId, newDoc[0],
                this.enrollmentPeriod.enrollmentPeriodId, this.currentSOE?.specialOpenEnrollmentId)));
            }

          }
        }
        await Promise.all(newDocPromises);
        for (const doc of memberChanges) {
          // grab associated relationship
          const associatedRelationship: Relationship = map(
            filter(this.members, (m) => m.relationshipToSubscriber.relationshipId === doc.relationshipId),
            'relationshipToSubscriber'
          )[0];
          // if relationship denied, also put relationship changing to not denied.
          if (associatedRelationship.simplifiedStatus === 'Denied' && (this.subscriber.activeSOE || this.activeOE)) {
            const receivedStatusId = find(this.relationshipVerificationStatuses, (rvs) => rvs.relationshipVerificationStatusName === 'Received').relationshipVerificationStatusId;
            associatedRelationship.relationshipVerificationStatusId = receivedStatusId;
            associatedRelationship.deniedDate = null;
            const relationshipReturned = await lastValueFrom(this.relationshipService.updateRelationship(associatedRelationship));
          }
          memberChangePromises.push(lastValueFrom(this.documentService.addDocumentToMember(this.subscriber.memberId, doc.relationshipId, doc,
            this.enrollmentPeriod.enrollmentPeriodId, this.currentSOE?.specialOpenEnrollmentId)));
        }
        for (const doc of docTypeChanges) {
          docTypePromises.push(lastValueFrom(this.documentService.updateRelationshipDocumentType(this.subscriber.memberId, doc.relationshipId, doc)));
        }
        for (const doc of docsRemoved) {
          deleteMemberPromises.push(lastValueFrom(this.documentService.removeDocumentFromMember(this.subscriber.memberId, doc.relationshipId,
            doc, this.currentSOE?.specialOpenEnrollmentId)));
        }
        for (const doc of soeDocsRemoved) {
          deleteSOEDocumentPromises.push(lastValueFrom(this.documentService.removeDocumentFromSOE(this.subscriber.memberId, this.currentSOE?.specialOpenEnrollmentId, doc)));
        }
        for (const doc of soeChanges) {
          newSOEDocumentPromises.push(lastValueFrom(this.documentService.createSOEDocument(doc.specialOpenEnrollmentId, this.subscriber.memberId, doc)));
        }
        for (const doc of newSOEDocuments) {
          const newSOEDoc = newDocsAdded.find(o => o.documentName === doc.documentName);
          if (!newSOEDoc) {
            // new document for SOE was not added by relation to dependents, add the new document
            newSOEDocumentPromises.push(lastValueFrom(this.documentService.createDocumentForSOE(doc.specialOpenEnrollmentId, this.subscriber.memberId, doc.documentTypeId, doc)));
          } else {
            newSOEDocumentPromises.push(lastValueFrom(this.documentService.createSOEDocument(doc.specialOpenEnrollmentId, this.subscriber.memberId, newSOEDoc)));
          }
        }

        await Promise.all(memberChangePromises);
        await Promise.all(docTypePromises);
        await Promise.all(deleteMemberPromises);
        await Promise.all(relationshipPromises);
        await Promise.all(deleteSOEDocumentPromises);
        await Promise.all(newSOEDocumentPromises);
        this.coreService.popMessage(`You have successfully uploaded your document(s).`, 'success', 5000);
        this.spinnerService.hide();
        this.showConfirmation = true;
        this.submitted = false;
        this.spinnerService.hide();
        this.changesHaveBeenSaved = true;
        this.reFetchSubscriber();
      } catch (err) {
        this.submitted = false;
        this.spinnerService.hide();
        console.log(err);
        this.coreService.popMessage(`${err.error}`, 'error');
      }
    }
    if (!someRelChange) {
      // save upload milestone completed
      this.spinnerService.show();
      this.enrollmentPeriodService
        .createMilestoneCompletion(this.subscriber.memberId, this.uploadMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId)
        .pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
          this.subscriber.refetch = true;
          this.coreService.setSubscriber(this.subscriber);
          this.enrollmentPeriod = e;
          this.spinnerService.hide();
      });

      if (this.isDeathOrDivorceSoe) {
        this.router.navigate([`../../confirmation/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
      } else if (this.enrollmentPeriod.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE') {
        this.router.navigate([`../../attest/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
      } else {
        this.router.navigate([`../../coverage/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
      }
    }
  }

  getFlatDocuments(member: Member[]): Document[] {
    let soes: SpecialOpenEnrollment[] = [];
    if (this.currentSOE) {
      soes = [this.currentSOE];
      this.currentSOE.documents.forEach((d) => {
          d.specialOpenEnrollmentId = this.currentSOE.specialOpenEnrollmentId;
          d.isSOEDocument = true;
      });
    }
    member.forEach((m) => {
      m.relationshipToSubscriber.documents.forEach((d) => {
        d.relationshipId = m.relationshipToSubscriber.relationshipId;
        if (this.currentSOE && this.currentSOE.documents.find(o => o.documentId === d.documentId)) {
            d.specialOpenEnrollmentId = this.currentSOE.specialOpenEnrollmentId;
            d.isSOEDocument = true;
        }
      });
    });
    const mergedDocs = unionBy(flatten(map(member, 'relationshipToSubscriber.documents')), flatten(map(soes, 'documents')), 'documentId');
    return mergedDocs;
  }

  async reFetchSubscriber(): Promise<void> {
    let sub;
    if (!this.isSOE) {
      sub = await lastValueFrom(this.subscriberService.getSubscriberById(this.subscriber.memberId));
    } else {
      sub = await lastValueFrom(this.subscriberService.getSubscriberWithSOE(this.subscriber.memberId, this.currentSOE.specialOpenEnrollmentId));
    }
    if (this.subscriber.activeSOE) {
      sub.activeSOE = cloneDeep(this.subscriber.activeSOE);
    }
    this.subscriber = sub;
    this.coreService.setSubscriber(cloneDeep(sub));
    this.members = this.filterMembers(sub);
    this.setCurrentSOE();
    this.documents = this.getFlatDocuments(this.members);
    this.initialMembers = cloneDeep(this.members);
    forEach(this.documents, (document: Document) => {
      // fetch document content
      this.documentService.getMemberDocumentById(this.subscriber.memberId, document.documentId, document).pipe(untilDestroyed(this)).subscribe((doc) => {
        document = doc;
      });
    });
  }

  async confirmUpload(moveToCoverage: boolean): Promise<void> {
    if (moveToCoverage) {
      this.spinnerService.show();
      this.enrollmentPeriodService.createMilestoneCompletion(this.subscriber.memberId, this.uploadMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId).pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
        if (this.isDeathOrDivorceSoe) {
          this.router.navigate([`../../confirmation/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
        } else if (this.enrollmentPeriod.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE') {
          this.router.navigate([`../../attest/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
        } else {
          this.router.navigate([`../../coverage/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
        }
        this.spinnerService.hide();
      });
    } else {
      this.showConfirmation = false;
    }
  }

  navToDashboard(): void {
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  clearSelections(): void {
    this.fileUpload.clearSelections(this.documents);
    this.subscriber = cloneDeep(this.initialSubscriber);
    this.members = this.filterMembers(this.subscriber);
    this.documents = this.getFlatDocuments(this.members);
    forEach(this.documents, (document: Document) => {
      this.documentService.getMemberDocumentById(this.subscriber.memberId, document.documentId, document).pipe(untilDestroyed(this)).subscribe((doc) => {
        document = doc;
      });
    });
  }

  get pendingChanges(): boolean {
    const allInitialRelationshipDocuments = flatten(map(this.initialMembers, 'relationshipToSubscriber.documents'));
    const allModifiedRelationshipDocuments = flatten(map(this.members, 'relationshipToSubscriber.documents'));
    const newRelDocuments = filter(allModifiedRelationshipDocuments, (d) => d.documentId.includes('NEW'));
    const docTypeChanges = filter(allModifiedRelationshipDocuments, (d) => some(allInitialRelationshipDocuments, (id) => id.documentId === d.documentId && id.documentTypeId !== d.documentTypeId));
    const memberChanges = filter(
      allModifiedRelationshipDocuments,
      (doc) => !doc.documentId.includes('NEW') && !some(allInitialRelationshipDocuments, (md) => md.relationshipId === doc.relationshipId && md.documentId === doc.documentId)
    );
    const docsRemoved = filter(allInitialRelationshipDocuments, (doc) => !some(allModifiedRelationshipDocuments, (md) => md.relationshipId === doc.relationshipId && md.documentId === doc.documentId));


    const allInitialSOEDocuments = flatten(this.initialSOEDocuments);
    const allModifiedSOEDocuments = flatten(this.currentSOE?.documents);
    const newSOEDocuments = filter(allModifiedSOEDocuments, (d) => d.documentId.includes('NEW'));
    const soeDocsRemoved = filter(allInitialSOEDocuments, (doc) => !some(allModifiedSOEDocuments, (md) => md.documentId === doc.documentId));
    const soeChanges = filter(
      allModifiedSOEDocuments,
      (doc) => !doc.documentId.includes('NEW') && !some(allInitialSOEDocuments, (md) => md.documentId === doc.documentId)
    );

    return newRelDocuments.length || docTypeChanges.length || memberChanges.length || docsRemoved.length || newSOEDocuments.length || soeDocsRemoved.length || soeChanges.length;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.changesHaveBeenSaved || !this.pendingChanges;
  }

  setCurrentSOE(): void {
    this.currentSOE = find(this.subscriber.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => soe.specialOpenEnrollmentId === this.enrollmentPeriod.enrollmentPeriodId);
    this.initialSOEDocuments = cloneDeep(this.currentSOE?.documents);

    if (this.currentSOE) {
      this.isDeathOrDivorceSoe = this.currentSOE.specialOpenEnrollmentType.specialOpenEnrollmentTypeName === 'Death or Divorce';
    } else {
      this.isDeathOrDivorceSoe = false;
    }
  }
}
