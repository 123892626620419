import { map, sortBy } from 'lodash';
import DocumentType from './documentType';
import AvailableEnrollmentAction from './availableEnrollmentAction';
export default class SpecialOpenEnrollmentType {
  specialOpenEnrollmentTypeId: string;
  specialOpenEnrollmentTypeName: string;
  lengthOfEventInDays: number;
  numberOfDaysFromEvent: number;
  managedByHCAInd: boolean;
  documentTypes: DocumentType[];
  availableActions: AvailableEnrollmentAction[];
  agencyId: string;
  specialOpenEnrollmentTypeDescription: string;
  flatAvailableActions: string[];
  displayName: string;
  otherEventInd: boolean;
  specialOpenEnrollmentTypeCode: string;
  obsoleteFlag: boolean;

  constructor(specialOpenEnrollmentType?) {
    if (specialOpenEnrollmentType) {
      this.specialOpenEnrollmentTypeId = specialOpenEnrollmentType.specialOpenEnrollmentTypeId;
      this.specialOpenEnrollmentTypeName = specialOpenEnrollmentType.specialOpenEnrollmentTypeName;
      this.lengthOfEventInDays = specialOpenEnrollmentType.lengthOfEventInDays;
      this.numberOfDaysFromEvent = specialOpenEnrollmentType.numberOfDaysFromEvent;
      this.managedByHCAInd = specialOpenEnrollmentType.managedByHcaInd;
      this.otherEventInd = specialOpenEnrollmentType.otherEventInd;
      this.documentTypes = sortBy(map(specialOpenEnrollmentType.documentTypes, (dt) => new DocumentType(dt)), 'documentTypeName');
      this.availableActions = sortBy(map(specialOpenEnrollmentType.availableActions, (a) => new AvailableEnrollmentAction(a)), 'availableEnrollmentActionName');
      this.agencyId = specialOpenEnrollmentType.agencyId;
      this.specialOpenEnrollmentTypeDescription = specialOpenEnrollmentType.specialOpenEnrollmentTypeDescription;
      this.flatAvailableActions = map(this.availableActions, (aa: AvailableEnrollmentAction) => aa.availableEnrollmentActionCode);
      this.displayName = specialOpenEnrollmentType.displayName;
      this.specialOpenEnrollmentTypeCode = specialOpenEnrollmentType.specialOpenEnrollmentTypeCode;
      this.obsoleteFlag = specialOpenEnrollmentType.obsoleteFlag;
    }
  }


}
