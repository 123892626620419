import * as dayjs from 'dayjs';
import Plan from './plan';
import Reason from './reason';

export default class Eligibility {
  eligibilityId: string;

  memberTypeId: string;
  eligibilityTypeEffectiveDate: Date;
  eligibilityDate: Date;
  eligibilityReasonId: string;
  lossOfEligibilityDate: Date;
  lossOfEligibilityReasonId: string;
  memberId: string;
  agencyEffectiveStartDate: Date;
  agencyEffectiveEndDate: Date;
  organizationId: string;

  createdDate: Date;
  createdById: string;
  modifiedById: string;
  modifiedDate: Date;

  constructor(instance?) {
    if (instance) {
      this.eligibilityId = instance.eligibilityId;

      this.memberId = instance.memberId;
      this.memberTypeId = instance.memberTypeId;
      this.eligibilityTypeEffectiveDate = instance.eligibilityTypeEffectiveDate;
      this.eligibilityDate = instance.eligibilityDate;
      this.eligibilityReasonId = instance.eligibilityReasonId;
      this.lossOfEligibilityDate = instance.lossOfEligibilityDate;
      this.lossOfEligibilityReasonId = instance.lossOfEligibilityReasonId;
      this.agencyEffectiveStartDate = instance.agencyEffectiveStartDate;
      this.agencyEffectiveEndDate = instance.agencyEffectiveEndDate;
      this.organizationId = instance.organizationId;

      this.createdDate = instance.createdDate || null;
      this.createdById = instance.createdById || null;
      this.modifiedDate = instance.modifiedDate || null;
      this.modifiedById = instance.modifiedById || null;
    }
  }
}
