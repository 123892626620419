// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import SpecialOpenEnrollment from '../models/specialOpenEnrollment';
import SelfPay from '../models/selfPay';
import AuditHistoryWrapper from '../models/AuditHistoryWrapper';
import { CommonService } from './common.service';
import { env } from '../../env/development';
import MemberAddress from '../models/memberAddress';
import Enrollment from '../models/enrollment';
import Attestation from '../models/attestation';
import LoginHistory from '../models/loginHistory';
import MemberNote from '../models/memberNote';
import MemberMaritalStatus from '../models/memberMaritalStatus';
import Document from '../models/document';
import DependentComposite from '../models/dependentComposite';
import SystemUser from '../models/user';
import Plan from '../models/plan';
import Eligibility from '../models/eligibility';
import Organization from '../models/organization';

@Injectable({
  providedIn: 'root',
})

export class AuditHistoryService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getSoes(memberId: string): Observable<AuditHistoryWrapper<SpecialOpenEnrollment>[]> {
    return this.http
      .get<SpecialOpenEnrollment[]>(`${env.apiUrl}/history/${memberId}/soe`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<SpecialOpenEnrollment>(SpecialOpenEnrollment, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getSelfPays(memberId: string): Observable<AuditHistoryWrapper<SelfPay>[]> {
    return this.http
      .get<SelfPay[]>(`${env.apiUrl}/history/${memberId}/selfpay`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<SelfPay>(SelfPay, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getAddresses(memberId: string): Observable<AuditHistoryWrapper<MemberAddress>[]> {
    return this.http
      .get<MemberAddress[]>(`${env.apiUrl}/history/${memberId}/address`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<MemberAddress>(MemberAddress, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getEnrollments(memberId: string): Observable<AuditHistoryWrapper<Enrollment>[]> {
    return this.http
      .get<Enrollment[]>(`${env.apiUrl}/history/${memberId}/enrollment`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<Enrollment>(Enrollment, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getAttestations(memberId: string): Observable<AuditHistoryWrapper<Attestation>[]> {
    return this.http
      .get<Attestation[]>(`${env.apiUrl}/history/${memberId}/attestation`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<Attestation>(Attestation, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getLogins(memberId: string): Observable<AuditHistoryWrapper<LoginHistory>[]> {
    return this.http
      .get<LoginHistory[]>(`${env.apiUrl}/history/${memberId}/login`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<LoginHistory>(LoginHistory, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getNotes(memberId: string): Observable<AuditHistoryWrapper<MemberNote>[]> {
    return this.http
      .get<MemberNote[]>(`${env.apiUrl}/history/${memberId}/note`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<MemberNote>(MemberNote, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getMaritalStatuses(memberId: string): Observable<AuditHistoryWrapper<MemberMaritalStatus>[]> {
    return this.http
      .get<MemberMaritalStatus[]>(`${env.apiUrl}/history/${memberId}/maritalStatus`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<MemberMaritalStatus>(MemberMaritalStatus, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getDependents(memberId: string): Observable<AuditHistoryWrapper<DependentComposite>[]> {
    return this.http
      .get<DependentComposite[]>(`${env.apiUrl}/history/${memberId}/dependent`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<DependentComposite>(DependentComposite, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getUsersByIdList(userIds: string[]): Observable<SystemUser[]> {
    return this.http
      .post<SystemUser[]>(`${env.apiUrl}/history/lookup/userList`, userIds)
      .pipe(map(x => x.map(y => new SystemUser(y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getPlansByIdList(planIds: string[]): Observable<Plan[]> {
    return this.http
      .post<Plan[]>(`${env.apiUrl}/history/lookup/planList`, planIds)
      .pipe(map(x => x.map(y => new Plan(y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getOrganizationsByIdList(organizationIds: string[]): Observable<Organization[]> {
    return this.http
      .post<Organization[]>(`${env.apiUrl}/history/lookup/orgList`, organizationIds)
      .pipe(map(x => x.map(y => new Organization(y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getCurrentIdsByIdList(set: string, ids: string[]): Observable<string[]> {
    const post = {
      setTypeName: set,
      ids
    };

    return this.http
      .post<string[]>(`${env.apiUrl}/history/lookup/ids`, post)
      .pipe(catchError(this.commonService.handleError));
  }

  getDocuments(memberId: string): Observable<AuditHistoryWrapper<Document>[]> {
    return this.http
      .get<Document[]>(`${env.apiUrl}/history/${memberId}/document`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<Document>(Document, y))))
      .pipe(catchError(this.commonService.handleError));
  }

  getEligibilities(memberId: string): Observable<AuditHistoryWrapper<Eligibility>[]> {
    return this.http
      .get<Eligibility[]>(`${env.apiUrl}/history/${memberId}/eligibility`)
      .pipe(map(x => x.map(y => new AuditHistoryWrapper<Eligibility>(Eligibility, y))))
      .pipe(catchError(this.commonService.handleError));
  }
}
