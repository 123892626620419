<div fxLayout="row" fxLayoutAlign="center center">
  <hca-prompt
    fxFlex="80"
    [conditionalNextMet]="(
      (
        hasCompletedThisStep || 
        (isSOE && !isSOEAndAllowsCDP && currentPlanType === 'Dental') || 
        (isSOE && !isSOEAndAllowsCVP && currentPlanType === 'Vision') || 
        (subscriberIsNewlyFull && currentPlanType === 'Medical')
      ) && 
      !((currentSubscriberSummary?.deMedicalAny && currentPlanType === 'Medical') && !currentSubscriberSummary.DEMessageAccepted))"
    [conditionalNextIdx]="conditionalNextIdx"
    [confirmIndex]="steps.length-1"
    [icon]="icons.faUmbrella"
    (previous)="updateStep($event)"
    (next)="updateStep($event)"
    [milestone]="coverageMilestone"
    [confirmButtonText]="confirmButtonText"
    [hideBackToDash]="isSOE"
    (putPostObject)="saveElections()"
  >
    <div fxLayout="column" *ngIf="currentPlanType == 'Medical'">
      <plan-type-management
        [subscriber]="subscriber"
        [memberType]="subscriber?.memberSelfPayOrigin?.memberType"
        [subscriberSummary]="currentSubscriberSummary"
        [readOnly]="subscriberIsNewlyFull || (isSOE && !isSOEAndAllowsCMP && !isSOEandAllowsReturn)"
        [availablePlans]="medicalAvailablePlans"
        [selectedPlan]="medicalSelectedPlan"
        [planType]="currentPlanType"
        [isWaivable]="isSOE? isSOEandAllowsWaive || isSOEandAllowsReturn: true"
        [enrollmentPeriod]="enrollmentPeriod"
        [hasDependentsEnrolled]="hasDependentsEnrolledInMedical"
        [isSelfPay]="isSP"
        [isSOE]="isSOE"
        [soeTypeName]="soeTypeName"
        [invalidSPPlan]="spInvalidMedicalPlan"
        (planSelected)="planUpdated($event)"
        (planDeSelected)="planRemoved($event)"
        (cobraOrUnpaidWaiving)="cobraOrUnpaidWaiving($event)"
        (enrollRTL)="enrollRTL($event)"
        (enrollBL)="enrollBL($event)"
        (deferRetireeChange)="deferRetireeChange($event)"
        [(selfPayWaivingDentalCoverage)]="selfPayWaivingDentalCoverage"
        [selfPayWaivingMedicalCoverage]="selfPayWaivingMedicalCoverage"
        [(removeMedicalForDE)]="removeMedicalForDE"
        (getPlans)="getPlans($event)"
        [selfPayFormDependentsEnrolled]="selfPayFormDependentsMedicalEnrolled"
        [isSEBB]="isSEBB"
      ></plan-type-management>
    </div>
    <div fxLayout="column" *ngIf="currentPlanType == 'Dental'">
      <plan-type-management
        [subscriber]="subscriber"
        [availablePlans]="dentalAvailablePlans"
        [selectedPlan]="dentalSelectedPlan"
        [subscriberSummary]="currentSubscriberSummary"
        (cobraOrUnpaidWaiving)="cobraOrUnpaidWaiving($event)"
        [planType]="currentPlanType"
        [enrollmentPeriod]="enrollmentPeriod"
        [hasDependentsEnrolled]="hasDependentsEnrolledInDental"
        [isSelfPay]="isSP"
        [isSOE]="isSOE"
        [soeTypeName]="soeTypeName"
        [invalidSPPlan]="spInvalidDentalPlan"
        [readOnly]="(isSOE && !isSOEAndAllowsCDP)"
        (planSelected)="planUpdated($event)"
        [(selfPayWaivingDentalCoverage)]="selfPayWaivingDentalCoverage"
        [selfPayWaivingMedicalCoverage]="selfPayWaivingMedicalCoverage"
        (planDeSelected)="planRemoved($event)"
        [selfPayFormDependentsEnrolled]="selfPayFormDependentsDentalEnrolled"
        [(removeDentalForDE)]="removeDentalForDE">
      </plan-type-management>
    </div>
    <div fxLayout="column" *ngIf="currentPlanType == 'Vision'">
      <plan-type-management
        [subscriber]="subscriber"
        [availablePlans]="visionAvailablePlans"
        [selectedPlan]="visionSelectedPlan"
        [subscriberSummary]="currentSubscriberSummary"
        (cobraOrUnpaidWaiving)="cobraOrUnpaidWaiving($event)"
        [planType]="currentPlanType"
        [enrollmentPeriod]="enrollmentPeriod"
        [hasDependentsEnrolled]="hasDependentsEnrolledInVision"
        [isSelfPay]="isSP"
        [isSOE]="isSOE"
        [soeTypeName]="soeTypeName"
        [invalidSPPlan]="spInvalidVisionPlan"
        [readOnly]="(isSOE && !isSOEAndAllowsCVP)"
        [(selfPayWaivingVisionCoverage)]="selfPayWaivingVisionCoverage"
        (planSelected)="planUpdated($event)"
        (planDeSelected)="planRemoved($event)"
        [(removeVisionForDE)]="removeVisionForDE"
        ></plan-type-management>
    </div>
    <div fxLayout="column" *ngIf="currentPlanType == 'Confirm'">
      <coverage-review
        [dentalChange]="dentalSelectedPlan"
        [visionChange]="visionSelectedPlan"
        [medicalChange]="medicalSelectedPlan"
        [subscriberSummary]="currentSubscriberSummary"
        [enrollmentPeriod]="enrollmentPeriod"
        [waiverMessages]="waivedPlanTypes"
        [hasMedical]="hasMedical"
        [hasDental]="hasDental"
        [hasVision]="hasVision"
        [isSebb]="subscriber.isSebb"
        [selfPayWaivingMedicalCoverage]="selfPayWaivingMedicalCoverage"
        [selfPayWaivingDentalCoverage]="selfPayWaivingDentalCoverage"
        [isSelfPay]="isSP"
        [isLWOP]="isLWOP"
      ></coverage-review>
    </div>
    <div *ngIf="currentPlanType === 'Medical' && medicalSelectedPlan?.carrierId !== initialSelectedPlans?.medical?.carrierId && hasDisabledChild" class="alert alert-danger">
      You must certify your child with a disability with your new provider. 
    </div>
  </hca-prompt>
</div>
