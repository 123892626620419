import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import SubscriberTransfer from 'src/app/models/subscriberTransfer';

@Component({
  selector: 'needs-to-be-adjusted',
  templateUrl: 'needsToBeAdjusted.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class NeedsToBeAdjustedComponent implements OnInit {
  @Input() subscriberTransferRec: SubscriberTransfer;
  @Output() cancelAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() claim: EventEmitter<void> = new EventEmitter<void>();
  transferTermed = false;
  icons = {
    faExclamationTriangle
  };

  constructor() {}

  ngOnInit(): void { }
}
