import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { sortBy, filter, findIndex, uniqBy, get, find, cloneDeep } from 'lodash';
import { RelationshipService } from 'src/app/services/relationship.service';
import Relationship from 'src/app/models/relationship';
import SystemUser from 'src/app/models/user';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import { VerificationAdminGridComponent } from './components/adminGrid/verification.admin.grid.component';
import Organization from 'src/app/models/organization';
import { CoreService } from 'src/app/services/core.service';
import RelationshipCertification from 'src/app/models/relationshipCertification';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { OEService } from 'src/app/services/oe.service';
import OpenEnrollment from 'src/app/models/openEnrollment';
import { AdminService } from 'src/app/services/admin.service';
import * as dayjs from 'dayjs';
import { RelationshipCertificationService } from 'src/app/services/relationshipCertification.service';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { env } from 'src/env/development';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Subscriber from 'src/app/models/subscriber';
import { lastValueFrom } from 'rxjs';

@UntilDestroy()
@Lookups(LookupType.DocumentType, LookupType.RelationshipVerificationStatus, LookupType.CertificationType,
         LookupType.RelationshipCertificationStatus)
@Component({
  selector: 'verification-admin',
  templateUrl: 'verification.admin.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class VerificationAdminComponent implements OnInit {
  systemUser: SystemUser;
  assignedOrganizations: Organization[];
  currentOrganization: Organization;
  gridColumns: { [k: string]: string | {} }[] = [
    { field: 'subscriberMember.fullName', title: 'Subscriber', format: 'string', sortable: true },
    { field: 'dependentMember.fullName', title: 'Dependent', format: 'string', sortable: true },
    { field: 'relationshipQualifyReason.relationshipQualifyReasonName', title: 'Reason', format: 'string', sortable: true },
    { field: 'simplifiedStatus', title: 'Status', format: 'string', sortable: false },
    { field: 'effectiveStartDate', title: 'Request Date', format: { date: 'mm/dd/yyyy' }, filter: 'date', sortable: true },
    { field: 'approvalDate', title: 'Verified Date', format: { date: 'mm/dd/yyyy' }, filter: 'date', sortable: true },
    { field: 'hasDocuments', title: 'Has Documents', format: 'boolean', filter: 'boolean', sortable: false },
  ];
  @ViewChild('verificationAdminGrid') public verificationAdminGrid: VerificationAdminGridComponent;
  onlyShowPendingRecords = true;
  queryString: string;
  lookups = {
    relationshipVerificationStatus: [],
    documentTypes: [],
    certificationTypes: [],
    relationshipCertificationStatus: [],
  };
  verifyValues: RelationshipVerificationStatus[] = [];
  denyValues: RelationshipVerificationStatus[] = [];
  isHCAAdmin = false;
  inHCAAdminState = false;
  inVerifierState = false;
  openEnrollment: OpenEnrollment;
  initOrganizationId: string;
  public gridData: GridDataResult;
  state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: [ 
      { field: "relationshipVerificationStatus.relationshipVerificationStatusName", dir: "asc"}, 
      { field: "effectiveStartDate", dir: "asc" }
    ]
  };
  pageSize = 10;
  agencyFilter: string = "";
  env = env;
  selfPayOnly = false;
  constructor(
    private route: ActivatedRoute,
    private relationshipService: RelationshipService,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private oeService: OEService,
    private adminService: AdminService,
    private relationshipCertificationService: RelationshipCertificationService
  ) {}

  ngOnInit(): void {
    this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s) => {
      this.currentOrganization = s;
      if (this.currentOrganization && this.currentOrganization.organizationId) {
        if (this.currentOrganization && !this.inHCAAdminState && !this.inVerifierState) {
          this.initOrganizationId = this.currentOrganization.organizationId;
          this.getRelationships(this.currentOrganization.organizationId, this.onlyShowPendingRecords);
          if (this.verificationAdminGrid) {
            this.verificationAdminGrid.reSetDataForGrid();
          }
        }
      }
    });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((q) => {
        this.selfPayOnly = q.selfPayOnly;
    });
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.systemUser = data.user;
        this.isHCAAdmin = this.systemUser.userOrganizationRoles.map((r) => r.userRoleName).includes('HCA');
        this.inHCAAdminState = data.userType === 'hca';
        this.inVerifierState = data.userType === 'verifier';
        this.lookups.relationshipVerificationStatus = sortBy(data.lookups.relationshipVerificationStatus, ['relationshipVerificationStatusName']);
        if (!this.isHCAAdmin) {
          this.lookups.relationshipVerificationStatus = filter(this.lookups.relationshipVerificationStatus, d => d?.relationshipVerificationStatusCode !== 'SP');
        }
        this.lookups.documentTypes = sortBy(data.lookups.documentType, ['documentTypeName']);
        this.lookups.certificationTypes = data.lookups.certificationType;
        this.lookups.relationshipCertificationStatus = sortBy(data.lookups.relationshipCertificationStatus, 'relationshipCertificationStatusName');
        if (this.inHCAAdminState) {
          this.getRelationships(null, this.onlyShowPendingRecords);
        } else if (this.systemUser.userOrganizationRoles[0] && this.systemUser.userOrganizationRoles[0].organizationId && !this.initOrganizationId) {
          this.getRelationships(this.systemUser.userOrganizationRoles[0].organizationId, this.onlyShowPendingRecords);
        }
      },
      (err) => console.log(err)
    );
  }

  getRelationships(organizationId: string, pending: boolean): void {
    this.spinnerService.hide();
    this.spinnerService.show();
    var gridQuery = this.coreService.kendoGridStateToQueryString(this.state);
    if (!organizationId && this.isHCAAdmin) {
      this.relationshipService.getCurrentRelationshipsForHCA(pending, gridQuery,this.agencyFilter, this.selfPayOnly).pipe(untilDestroyed(this)).subscribe((gridDataResult) => {
        this.gridData = gridDataResult;
        this.spinnerService.hide();
      });
    } else {
      this.relationshipService.getCurrentRelationshipsForAgency(organizationId, pending, gridQuery).pipe(untilDestroyed(this)).subscribe((gridDataResult) => {
        this.gridData = gridDataResult;
        //doesn't seem to be a 'verifier' userType in the routes, if there is, this filter would need to be moved to the server side
        // if (this.inVerifierState) {
        //   this.gridData.data = this.gridData.data.filter((r) => r.documents.length && r.effectiveStartDate >= this.lowerLimitDate);
        // }
        this.spinnerService.hide();
      });
    }
  }

  refreshSearch() {
    this.gridData?.data?.forEach((gd, idx) => this.verificationAdminGrid.kendoGrid.collapseRow(idx));
    this.state.skip = 0;
    this.updateDataset(null);
  }

  updateDataset(newState): void {
    if (newState) {
      this.state = newState;
    }
    const currentOrDefaultorganizationId =
      this.inHCAAdminState || this.inVerifierState ? null : get(this.currentOrganization, 'organizationId', this.systemUser.userOrganizationRoles[0].organizationId);
    if (this.inVerifierState) {
      this.searchDV(this.queryString, this.onlyShowPendingRecords);
    } else {
      this.getRelationships(currentOrDefaultorganizationId, this.onlyShowPendingRecords);
    }
  }

  async updateRelationship(relationshipRec: Relationship): Promise<void> {
    const updatedRecIndex = findIndex(this.gridData.data, (gd) => gd.relationshipId === relationshipRec.relationshipId);
    this.spinnerService.show();
    const dependentRelationshipRec = await this.relationshipService.upsertRelationship(relationshipRec);
    this.gridData.data[updatedRecIndex] = dependentRelationshipRec;
    this.verificationAdminGrid.reSetDataForGrid();
    this.spinnerService.hide();

    //will need to refetch the current cached subscriber if it is the same as this one
    this.coreService.checkRefetchCurrentSubscriber(dependentRelationshipRec.subscriberMemberId);
  }

  async updateRelationshipCertification(relationshipCertification: RelationshipCertification): Promise<void> {
    const relationshipToUpdateIndex = findIndex(this.gridData.data, (gd) => gd.relationshipId === relationshipCertification.relationshipId);
    this.spinnerService.show();

    const recordFromServer: RelationshipCertification = await this.relationshipService.upsertRelationshipCertification(relationshipCertification);

    if (relationshipCertification.relationshipCertificationId) {
      // Update
      const certificationIndex = findIndex(
        this.gridData.data[relationshipToUpdateIndex].relationshipCertifications,
        (c) => c.relationshipCertificationId === relationshipCertification.relationshipCertificationId
      );

      // update or delete
      if (relationshipCertification.effectiveStartDate > new Date() && !relationshipCertification.approvedInd) {
        this.gridData.data[relationshipToUpdateIndex].relationshipCertifications.splice(certificationIndex, 1);
      } else {
        this.gridData.data[relationshipToUpdateIndex].relationshipCertifications[certificationIndex] = recordFromServer;
      }
    } else {
      this.gridData.data[relationshipToUpdateIndex].relationshipCertifications.push(recordFromServer);
      this.gridData.data[relationshipToUpdateIndex] = cloneDeep(this.gridData.data[relationshipToUpdateIndex]);
    }

    this.spinnerService.hide();
  }

  searchDV(queryString: string, onlyShowPending: boolean): void {
    this.spinnerService.show();
    this.adminService.searchDV(queryString, onlyShowPending).pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.spinnerService.hide();
        this.gridData.data = [];
        this.gridData.data = data;
      },
      (err) => {
        console.log(err);
        this.spinnerService.hide();
      }
    );
  }

  async saveCertification(index: number, certification: RelationshipCertification): Promise<void> {
    this.spinnerService.show();
    const relationship = find(this.gridData.data, (gd) => gd.relationshipId === certification.relationshipId);
    const relationshipToUpdateIndex = findIndex(this.gridData.data, (gd) => gd.relationshipId === certification.relationshipId);
    try {
      const newCertification = await this.relationshipCertificationService.upsertCertification(relationship.subscriberMemberId, certification);

      if (certification.relationshipCertificationId) {
        const certificationIndex = findIndex(
          this.gridData.data[relationshipToUpdateIndex].relationshipCertifications,
          (c) => c.relationshipCertificationId === certification.relationshipCertificationId
          );

        this.gridData.data[relationshipToUpdateIndex].relationshipCertifications.splice(certificationIndex, 1, newCertification);
      } else {
        this.gridData.data[relationshipToUpdateIndex].relationshipCertifications.push(newCertification);
      }

      this.gridData.data = cloneDeep(this.gridData.data);
      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      console.log(err);
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong creating the certfications.', 'error', 5000);
    }
  }

  async deleteCertification(index: number, certification: RelationshipCertification): Promise<void> {
    const relationship = find(this.gridData.data, (gd) => gd.relationshipId === certification.relationshipId);
    this.spinnerService.show();
    try {
      await lastValueFrom(
        this.relationshipCertificationService.removeRelationshipCertification(relationship.subscriberMemberId, certification.relationshipId, certification.relationshipCertificationId)
        );
      this.spinnerService.hide();
      this.coreService.popMessage('Your changes have been saved.', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong creating the certfications.', 'error', 5000);
    }
  }

  async saveCertificationStatus(relationship: Relationship): Promise<void> {
    const relationshipToUpdateIndex = findIndex(this.gridData.data, (gd) => gd.relationshipId === relationship.relationshipId);
    this.spinnerService.show();
    // update dependent relationship only
    const dependentRelationshipRec = await this.relationshipService.upsertRelationship(relationship);
    this.gridData.data[relationshipToUpdateIndex] = dependentRelationshipRec;
    this.verificationAdminGrid.reSetDataForGrid();
    this.spinnerService.hide();
  }

}
