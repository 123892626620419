export default class ReportParameter {
  parameterId: string;
  parameterName: string;
  parameterField: string;
  lookupTableName: string;
  lookupField: string;
  dataType: string;
  sortOrderNumber: number;
  required: boolean;

  constructor(reportParameter?) {
    if (reportParameter) {
      this.parameterId = reportParameter.parameterId;
      this.parameterName = reportParameter.parameterName;
      this.parameterField = reportParameter.parameterField;
      this.lookupTableName = reportParameter.lookupTableName || null;
      this.lookupField = reportParameter.lookupField;
      this.dataType = reportParameter.dataType;
      this.sortOrderNumber = reportParameter.sortOrderNumber;
      this.required = reportParameter.required;
    }
  }
}
