<div *ngIf="systemUser && moreThanOneOrganization" fxLayout="column" style="padding-bottom: 20px;">
  <div *ngIf="isMultiEligibleMember" id="newly-eligible-banner" class="card bg-primary mgbt-small" style="padding: 15px">
    <div fxLayout="column" fxLayoutAlign="center center" class="fxGap-medium">
      <h2 class="h5 card-title" style="padding: 0px; margin: 0px; color: white;">Select between organizations below to manage benefits in SEBB and PEBB programs.</h2>
    </div>
  </div>
  <div class="form-group" fxFlex="100">
    <label for="OrganizationId"><b>Select Organization</b></label>
    <select required class="form-control" name="OrganizationId" [(ngModel)]="currentOrganization" (ngModelChange)="updateOrganization($event)">
      <option *ngFor="let organization of assignedOrgs" [ngValue]="organization">{{ (organization?.agency?.agencyCode ? organization.agency.agencyCode + ' - ' : '') + organization.organizationName }}</option>
    </select>
  </div>
</div>
