import { EnrollmentPeriodService } from './../services/enrollmentPeriod.service';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { cloneDeep} from 'lodash';
import { CoreService } from '../services/core.service';
import EnrollmentPeriod from '../models/enrollmentPeriod';

@Injectable({
  providedIn: 'root'
})

export class EnrollmentPeriodResolver {
  constructor(
    private enrollmentPeriodService: EnrollmentPeriodService,
    private coreService: CoreService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EnrollmentPeriod | Observable<never>> {
    let epid = route.paramMap.get('epid');
    let subid = route.paramMap.get('id');
    if (!subid) {
      subid = route.parent.paramMap.get('id');
    }
    if (!epid) {
      epid = route.parent.paramMap.get('epid');
    }
    return this.coreService.getEnrollmentPeriod().pipe(
      take(1),
      mergeMap((ep: EnrollmentPeriod) => {

        if (ep?.enrollmentPeriodId && ep?.enrollmentPeriodId === epid) {
          const enrollmentPeriodClone = cloneDeep(ep);
          return of(enrollmentPeriodClone);
        } else {
          return this.enrollmentPeriodService.getEnrollmentPeriodById(subid, epid).pipe(
            take(1),
            map((fetchedEp: EnrollmentPeriod) => {
              if (fetchedEp) {
                this.coreService.setEnrollmentPeriod(fetchedEp);
                return fetchedEp;
              } else {
                return EMPTY;
              }
            })
          );
        }
      })
    );
  }
}
