/**
 * Handles top-level navigation for subscribers/perspay/HCA users.
 * State is injected into the parent router-outlet which exists in app component.
 * Mobile nav applied on small screens or on appropriate width on window resize.
 */

// ng
import { Component, HostListener, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';

// ext
import { map, some, find, remove } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import NavItem from '../../models/navItem';
import SystemUser from 'src/app/models/user';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { env } from 'src/env/development';
import { CoreService } from 'src/app/services/core.service';
import { AuthService } from 'src/app/services/auth.service';
import Subscriber from 'src/app/models/subscriber';
import OpenEnrollment from 'src/app/models/openEnrollment';
import Milestone from 'src/app/models/milestone';
import * as dayjs from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'top-nav',
  templateUrl: 'topnav.component.html',
  styleUrls: [],
  providers: [],
  animations: [
    trigger('collapse', [
      state(
        'open',
        style({
          opacity: '1',
          height: '100%',
        })
      ),
      state(
        'closed',
        style({
          opacity: '0',
          height: '0%',
        })
      ),
      transition('open => closed', [animate('3s')]),
      transition('closed => open', [animate('3s')]),
    ]),
  ],
})
export class TopNavComponent implements OnInit, AfterViewInit {
  subscriber: Subscriber;
  states: NavItem[] = [];
  navItems: NavItem[] = this.states.map((stateItem) => new NavItem(stateItem.title, stateItem.state, stateItem.availableTo));
  smallScreens = false;
  isNavbarCollapsed = true;
  isNavbarCollapsedAnim = 'closed';
  systemUser: SystemUser;
  sticky = false;
  newlyIncomplete = false;
  navPosition: number;
  navWidth: number;
  currentlyNewlyEligible = false;
  isMemberNav: boolean = false;
  isPersPay: boolean = false;
  isInOrgWithNoElections : boolean = false;
  // @ViewChild('topnav') topnav: ElementRef;
  @ViewChild('parent') parentContainer: ElementRef;

  constructor(public router: Router, authService: AuthService, private coreService: CoreService) {
  }

  ngOnInit(): void {
    // needs refactoring to flatten observables and unsubscribe.
    this.coreService.getSystemUser().pipe(untilDestroyed(this)).subscribe((systemUser) => {
      this.systemUser = systemUser;
      if (this.systemUser && this.systemUser.systemUserId && this.systemUser.userOrganizationRoles) {
        const roles = map(this.systemUser.userOrganizationRoles, 'userRoleName');
        if (roles.includes('HCA')) {
          this.navItems = this.setStates('hca');
        } else if (roles.includes('Perspay')) {
          this.navItems = this.setStates('perspay');
          this.isPersPay = true;
        } else if (roles.includes('Verifier')) {
          this.navItems = this.setStates('verifier');
        } else {
          this.coreService.getSubscriber().pipe(untilDestroyed(this)).subscribe((s) => {
            this.isMemberNav = true;
            if (s.memberId) {
              this.subscriber = s;
              const approvedLWOP = this.subscriber.lossOfEligibilityReason?.reasonName == "Approved LWOP" && dayjs(new Date()).isAfter(dayjs(this.subscriber.lossOfEligibilityDate));
              this.isInOrgWithNoElections = this.subscriber.organization.disallowLoginInd && !approvedLWOP;
              this.currentlyNewlyEligible = some(
                this.subscriber.enrollmentPeriods,
                (ep: EnrollmentPeriod) => ep.isCurrentlyActive && ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible'
              );
              this.newlyIncomplete = some(
                this.subscriber.enrollmentPeriods,
                (ep: EnrollmentPeriod) =>
                  ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible' && some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && !mi.isComplete)
              );

              if (this.isInOrgWithNoElections && some(this.navItems, (navItem: NavItem) => navItem.title === 'Profile')) {
                remove(this.navItems, (n) => n.title === 'Profile');
              }
              if (!this.navItems.length) {
                this.navItems = this.setStates('member');
                if (this.currentlyNewlyEligible && some(this.navItems, (navItem: NavItem) => navItem.title === 'Tobacco Attestations')) {
                  remove(this.navItems, (n) => n.title === 'Tobacco Attestations');
                }
                if (this.currentlyNewlyEligible && this.newlyIncomplete && some(this.navItems, (navItem: NavItem) => navItem.title === 'Special Open Enrollment')) {
                  remove(this.navItems, (n) => n.title === 'Special Open Enrollment');
                }
                if (this.currentlyNewlyEligible && this.newlyIncomplete && some(this.navItems, (navItem: NavItem) => navItem.title === 'Manage Dependents')) {
                  remove(this.navItems, (n) => n.title === 'Manage Dependents');
                }
              }
            }
          });
        }
      } else {
        // this means userResolver never ran and gave us a valid systemuser, which means subscriber resolver should have run and at least set subscriber.
        this.coreService.getSubscriber().pipe(untilDestroyed(this)).subscribe((s) => {
          if (s.memberId) {
            this.subscriber = s;
            const prevIncomplete = this.newlyIncomplete;
            this.newlyIncomplete = some(
              this.subscriber.enrollmentPeriods,
              (ep: EnrollmentPeriod) =>
                ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible' && some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && !mi.isComplete)
            );
            
            this.currentlyNewlyEligible = some(
              this.subscriber.enrollmentPeriods,
              (ep: EnrollmentPeriod) => ep.isCurrentlyActive && ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible'
            );

            if ((this.newlyIncomplete !== prevIncomplete || !this.navItems.length || (this.navItems.length && this.navItems[0].state !== '/subscriber/' + this.subscriber.memberId)) && this.isMemberNav) {
              this.navItems = this.setStates('member');
              const itemsToRemove = [];
              const selfPayMemberTypeCodes = env.unpaidLeaveMemberTypes.concat(env.retireeMemberTypes).concat(env.cobraMemberTypes);
              const isSelfPayMember = selfPayMemberTypeCodes.indexOf(s.memberType?.memberTypeCode) > -1;

              if (this.currentlyNewlyEligible && some(this.navItems, (navItem: NavItem) => navItem.title === 'Tobacco Attestations')) {
                itemsToRemove.push('Tobacco Attestations');
              }
              if (this.currentlyNewlyEligible && this.newlyIncomplete && some(this.navItems, (navItem: NavItem) => navItem.title === 'Special Open Enrollment')) {
                itemsToRemove.push('Special Open Enrollment');
              }
              if (this.currentlyNewlyEligible && this.newlyIncomplete && some(this.navItems, (navItem: NavItem) => navItem.title === 'Manage Dependents')) {
                itemsToRemove.push('Manage Dependents');
              }
              if (isSelfPayMember) {
                itemsToRemove.push('Special Open Enrollment');
                itemsToRemove.push('Manage Dependents');
              }

              remove(this.navItems, (n) => itemsToRemove.indexOf(n.title) > -1);
            }
          }
        });
      }
    });
    this.resize(window);
  }

  ngAfterViewInit(): void {
    // this.navPosition = this.topnav.nativeElement.offsetTop;
    // wait to avoid one-time change digest err in dev-mode
    if (this.parentContainer) {
      setTimeout((_) => (this.navWidth = this.parentContainer.nativeElement.clientWidth));
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.navPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  @HostListener('window:resize', ['$event.target'])
  resize(event): void {
    if (event.innerWidth >= 768) {
      this.smallScreens = false;
      this.isNavbarCollapsedAnim = 'open';
      this.isNavbarCollapsed = false;
    } else {
      this.smallScreens = true;
    }
  }

  toggleNavbar(): void {
    if (this.isNavbarCollapsed) {
      this.isNavbarCollapsedAnim = 'open';
      this.isNavbarCollapsed = false;
    } else {
      this.isNavbarCollapsedAnim = 'closed';
      this.isNavbarCollapsed = true;
    }
  }

  setStates(userType: string): NavItem[] {
    let states: NavItem[] = [];
    if (userType === 'hca') {
      states = [
        { title: 'Admin Dashboard', state: `/dashboard/hca/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Subscribers', state: `/admin/subscriberManagement/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Access', state: `/admin/hca/access/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: '3rd Party Access', state: `/admin/hca/thirdPartyAccess/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: 'API Access', state: `/admin/hca/apiAccess/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Dependent Verification', state: `/admin/relationshipVerification/hca/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Special Enrollment Verification', state: `/admin/specialOpenEnrollment/review/hca/${this.systemUser.systemUserId}`, availableTo: [], label: 'S O E Verification' },
        { title: 'Self Pay Dashboard', state: `/admin/selfpaydashboard/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Organizations', state: `/admin/organizationManagement/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Reports', state: `/admin/reports/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: 'Account Correction', state: `/admin/hca/accountCorrection/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Settings', state: `/admin/appsettings/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'History', state: `/admin/subscriberHistory/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
      ];
    } else if (userType === 'perspay') {
      states = [
        { title: 'Admin Dashboard', state: `/dashboard/perspay/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Dependent Verification', state: `/admin/relationshipVerification/perspay/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'SOE Verification', state: `/admin/specialOpenEnrollment/review/perspay/${this.systemUser.systemUserId}`, availableTo: [], label: 'S O E Verification' },
        { title: 'Subscribers', state: `/admin/subscriberManagement/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Access', state: `/admin/access/perspay/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: 'Eligibility', state: `/admin/eligibility/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: 'Billing', state: `/admin/billing/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: 'SmartHealth', state: `/admin/smarthealth/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Reports', state: `/admin/reports/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'Profile', state: `/admin/organizationProfile/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: 'Enrollment Docs', state: `/admin/enrollmentDocuments/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        // { title: 'FSA / DCAP', state: `/admin/fsadcap/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
        { title: 'History', state: `/admin/subscriberHistory/${this.systemUser.systemUserId}`, availableTo: [], label: '' },
      ];
      // no mention of 3rd party verification this time around
      // } else if (userType === 'verifier') {
      //   states = [{ title: 'Dependent Verification', state: `/relationshipVerification/verifier/${this.systemUser.systemUserId}`, availableTo: [] }];
    } else if (userType === 'member') {
      states = [
        { title: 'Dashboard', state: `/subscriber/${this.subscriber.memberId}`, availableTo: [], label: '' },
        { title: 'Manage Dependents', state: `/dependents/${this.subscriber.memberId}`, availableTo: [], label: '' },
        { title: 'Special Open Enrollment', state: `/soe/${this.subscriber.memberId}`, availableTo: [], label: '' },
        { title: 'Profile', state: `/profile/${this.subscriber.memberId}`, availableTo: [], label: '' },
        // { title: 'Document Upload', state: `/upload/${this.subscriber.memberId}`, availableTo: [], label: '' },
        { title: 'Tobacco Attestations', state: `/attest/tobacco/${this.subscriber.memberId}`, availableTo: [], label: '' },
        { title: 'Supplemental Coverage', state: `/supplemental/${this.subscriber.memberId}`, availableTo: [], label: '' },
        { title: 'Current Coverage', state: `/summary/${this.subscriber.memberId}`, availableTo: [], label: '' },
        { title: 'Continuation Coverage', state: `/selfPay/${this.subscriber.memberId}`, availableTo: [], label: '' }
      ];
    }
    if(userType === 'member') {
      this.subscriber.setNavigationProps();
      if(this.subscriber.newlyIncomplete){
        remove(states, (s) => s.title === 'Special Open Enrollment');
        remove(states, (s) => s.title === 'Supplemental Coverage');
        remove(states, (s) => s.title === 'Continuation Coverage');      
      }
  
      if (!this.subscriber.hasSupplementalLTD) {
        remove(states, (s) => s.title === 'Supplemental Coverage');
      }
      if(this.subscriber?.organization?.disallowLoginInd){
        states = [
          { title: 'Dashboard', state: `/subscriber/${this.subscriber.memberId}`, availableTo: [], label: '' },
          { title: 'Current Coverage', state: `/summary/${this.subscriber.memberId}`, availableTo: [], label: '' },
          { title: 'Continuation Coverage', state: `/selfPay/${this.subscriber.memberId}`, availableTo: [], label: '' }
        ];
      }
    }
    

    return states.map((stateItem) => new NavItem(stateItem.title, stateItem.state, stateItem.availableTo, stateItem.label));
  }
}
