<div class="container-fluid">
  <div fxFlexFill FxLayout="column">
    <div class="card">
      <div *ngIf="!successfulSubmit" class="card-body">
        <h3 class="mobile-text-centered"><strong>New User Registration</strong></h3>
        <form (ngSubmit)="submitRegistration()" #registrationForm="ngForm" class="fxGap-medium-lg">
          <br />
          <h5 class="mobile-text-centered"><strong>Please enter a little information about yourself.</strong></h5>
          <br />
          <div fxLayout.xs="column" fxLayout="row" class="fxGap-medium" fxLayoutAlign="space-between">
            <div style="flex: 50;" fxLayout="row" class="fxGap-x-small">
              <div style="flex: 50;" fxclass="form-group required">
                    <ng-template #emailTooltip>
                         <span>Must be a valid email address</span>
                      </ng-template>
                <label for="pebbRegistrationFirstName">First Name</label>
                <input
                  name="firstName"
                  [(ngModel)]="registration.firstName"
                  required
                  type="text"
                  class="form-control"
                  id="pebbRegistrationFirstName"
                  placeholder="First Name"
                  minlength="1"
                  maxlength="50"
                />
              </div>
              <div style="flex: 50;" class="form-group required">
                <label for="pebbRegistrationLastName">Last Name</label>
                <input
                  name="lastName"
                  [(ngModel)]="registration.lastName"
                  required
                  type="text"
                  class="form-control"
                  id="pebbRegistrationLastName"
                  placeholder="Last Name"
                  minlength="1"
                  maxlength="50"
                />
              </div>
            </div>
            <div style="flex: 50;" class="fxGap-x-small" fxLayout="row">
              <div style="flex: 50;" class="form-group required">
                <label for="pebbRegistrationSsn">Last 4 of SSN</label>
                <input
                  name="pebbRegistrationSsn"
                  [(ngModel)]="registration.ssn"
                  required
                  type="text"
                  class="form-control"
                  id="pebbRegistrationSsn"
                  placeholder="Last 4 digits of your SSN"
                  minlength="4"
                  maxlength="4"
                  (keypress)="onlyNumbers($event)"
                />
              </div>
              <div style="flex: 50;" fxLayout="column" class="form-group">
                <date-picker
                  #birthDate="ngModel"
                  name="birthDate"
                  label="Date of Birth"
                  required
                  [(ngModel)]="registration.birthDate"></date-picker>
              </div>
            </div>
          </div>
          <br />
          <h5 class="mobile-text-centered"><strong>Please enter some account related information.</strong></h5>
          <br />
          <div fxLayout.xs="column" fxLayout="row" class="fxGap-medium" fxLayoutAlign="space-between">
            <div style="flex: 50" class="form-group required">
              <label for="pebbEmailInput">Email address</label>
              <input
                name="email"
                email
                [(ngModel)]="registration.email"
                required
                type="email"
                class="form-control"
                id="pebbEmailInput"
                placeholder="Enter email"
                placement="bottom"
                [ngbTooltip]="emailTooltip"
              />
            </div>
            <div style="flex: 50" class="form-group required">
              <ng-template #userIdTooltip>
                    <span>must be between 4 and 32 characters long.</span><br/><br/>
                    <span>may have letters (a-z, A-Z) and numbers (0-9).</span><br/><br/>
                    <span>may have any of the following four special characters: ‘.’, ‘-‘, ‘_’ and ‘@’.</span><br/><br/>
              </ng-template>
              <label for="pebbRegistrationUserId">User Name</label>
              <input
                #pebbRegistrationUserId="ngModel"
                name="pebbRegistrationUserId"
                [(ngModel)]="registration.userId"
                required
                type="text"
                minlength="4"
                maxlength="32"
                class="form-control"
                id="pebbRegistrationUserId"
                placeholder="Enter a User Name"
                placement="bottom"
                [ngbTooltip]="userIdTooltip"
                pattern="[a-zA-Z0-9._@-]*"
              />
              <div *ngIf="pebbRegistrationUserId.invalid && (pebbRegistrationUserId.dirty || pebbRegistrationUserId.touched) && !!pebbRegistrationUserId.errors.pattern" class="alert alert-danger">
                    User Id must contain only letters, numbers or the following characters (@, -, _, .)
              </div>
            </div>
          </div>
          <div fxLayout.xs="column" class="fxGap-medium" fxLayout="row" fxLayoutAlign="space-between">
            <div style="flex: 50;" class="form-group required">
                <ng-template #passwordTooltip>
                        <span>Must be between 10 and 64 characters long</span><br/><br/>
                        <span>Must contain at least three of the following: uppercase letters, lowercase letters, numerals, and special characters.</span><br/><br/>
                        <span>Must Not contain your name or username</span><br/><br/>
                        <span>Cannot be a single dictionary word.</span><br/><br/>
                    </ng-template>
              <label for="pass1">Password</label>
              <input
                #pass1="ngModel"
                name="pass1"
                [(ngModel)]="registration.password"
                required
                type="password"
                class="form-control"
                id="pass1"
                placeholder="Password"
                minlength="10"
                maxlength="64"
                placement="bottom" 
                [ngbTooltip]="passwordTooltip"
                [forbiddenValues]="[registration.userId, registration.firstName, registration.lastName]"
                [mustContainSome]="['number', 'upper', 'special', 'lower']"
                [ngModelOptions]="{updateOn: 'blur'}"
              />
              <div *ngIf="pass1.invalid && (pass1.dirty || pass1.touched) && !!pass1.errors.forbiddenValues" class="alert alert-danger">
                    Password Cannot contain your name or username
              </div>
              <div *ngIf="pass1.invalid && (pass1.dirty || pass1.touched) && !!pass1.errors.mustContainSome" class="alert alert-danger">
                    Password must contain at least 3 of: Uppercase Letter, Lowercase Letter, Special Character, Number.
              </div>
              <div *ngIf="pass1.invalid && (pass1.dirty || pass1.touched) && !!pass1.errors.minlength" class="alert alert-danger">
                    Password must be at least 10 characters.
              </div>
            </div>
            <div style="flex: 50;" class="form-group required">
              <label for="pass2">Re-Enter Password</label>
              <input
                #pass2="ngModel"
                name="pass2"
                [(ngModel)]="passwordConfirmation"
                required
                type="password"
                class="form-control"
                id="pass2"
                placeholder="Password"
                minlength="10"
                maxlength="64"
                placement="bottom" 
                [ngbTooltip]="passwordTooltip"
              />
              <div *ngIf="(pass1.dirty || pass1.touched) && passwordConfirmation !== registration.password" class="alert alert-danger">
                    Passwords must match
              </div>
            </div>
          </div>
          <br />
          <h5 class="mobile-text-centered"><strong>Please enter a few secret questions and answers - these questions will be used for password recovery.</strong></h5>
          <br />
          <div fxLayout.xs="column" class="fxGap-medium" fxLayout="row" fxLayoutAlign="space-between">
            <div style="flex: 50" class="form-group required">
                <ng-template #answerTooltip>
                        <span>Must be between 4 and 60 characters long</span><br/><br/>
                </ng-template>
               <label for="pebbRegistrationSecretQuestion1">Secret Question 1</label>
                <select id="pebbRegistrationSecretQuestion1" placeholder="Enter a secret question" required class="form-control" name="pebbRegistrationSecretQuestion1" [(ngModel)]="registration.secretQuestions[0].question">
                    <option></option>
                    <option *ngFor="let question of filterQuestions(waTechQuestions, 1)" [ngValue]="question">{{ question }}</option>
                </select>
            </div>
            <div style="flex: 50" class="form-group required">
              <label for="pebbRegistrationSecretQuestion1Answer">Secret Question 1 Answer</label>
              <input
                name="pebbRegistrationSecretQuestion1Answer"
                [(ngModel)]="registration.secretQuestions[0].answer"
                required
                type="text"
                class="form-control"
                id="pebbRegistrationSecretQuestion1Answer"
                placeholder="Enter an answer for this question"
                placement="bottom" 
                [ngbTooltip]="answerTooltip"
              />
            </div>
          </div>
          <div fxLayout.xs="column" class="fxGap-medium" fxLayout="row" fxLayoutAlign="space-between">
            <div style="flex: 50" class="form-group required">
              <label for="pebbRegistrationSecretQuestion2">Secret Question 2</label>
              <select id="pebbRegistrationSecretQuestion2" placeholder="Enter a secret question" required class="form-control" name="pebbRegistrationSecretQuestion2" [(ngModel)]="registration.secretQuestions[1].question">
                  <option></option>
                  <option *ngFor="let question of filterQuestions(waTechQuestions, 0)" [ngValue]="question">{{ question }}</option>
              </select>
            </div>
            <div style="flex: 50" class="form-group required">
              <label for="pebbRegistrationSecretQuestion2Answer">Secret Question 2 Answer</label>
              <input
                name="pebbRegistrationSecretQuestion2Answer"
                [(ngModel)]="registration.secretQuestions[1].answer"
                required
                type="text"
                class="form-control"
                id="pebbRegistrationSecretQuestion2Answer"
                placeholder="Enter an answer for this question"
                placement="bottom" 
                [ngbTooltip]="answerTooltip"
              />
            </div>
          </div>
          <div fxFlexFill fxLayout="column" fxLayoutAlign="center center">
              <button style="width: 30%" fxLayoutAlign="center center" id="login-btn" type="submit" class="btn btn-primary">Submit</button>
          </div>
        </form>
        <div fxLayout="column">
          <h4 class="mobile-text-centered" style="font-weight: 600;">Already have an account? <a routerLink="/auth" href="">Back to Login</a></h4>
        </div>
      </div>

      <div fxLayoutAlign="center center" fxLayout="column" *ngIf="successfulSubmit" class="card-body">
        <h1 class="mobile-text-centered">Thank You!</h1>
        <h3 class="mobile-text-centered">
          Your registration information has been received and an account will be created for you with Secure Access
          Washington (SAW). 
          <br />
          <br />
          You should receive an email shortly with instructions for logging in to the pebb My Account system.
        </h3>
        <div fxLayout="column">
          <h4 style="font-weight: 600;"><a routerLink="/auth" href="">Back to Login</a></h4>
        </div>
      </div>
    </div>
  </div>
</div>




<ng-template #notificationTemplate class="pebb-notification" >
    <h4 style="text-align: center;">
        Information missing or invalid:
    </h4>
    <span style="text-align: center;">
      Please review invalid fields and re-submit.
    </span>
    <br/>
    <br/>    
</ng-template>