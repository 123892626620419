<div class="container-fluid" fxFlexFill fxLayout="column" *ngIf="!alwaysExpanded">
  <lookups-search *ngIf="!oe && !appSettings && !genericMessages" [(filterString)]="filterString"> </lookups-search>
  <div fxLayout="column" class="fxGap-tiny" *ngIf="lookups">
    <ngb-accordion [destroyOnHide]="false" #lookupAccord="ngbAccordion">
      <ngb-panel *ngFor="let lutName of objectKeys(lookups)" [hidden]="!lutName.toLowerCase().includes(filterString.toLowerCase())">
        <ng-template ngbPanelHeader let-opened="opened">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
            <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
            {{ prettyUpHeader(lutName) }}
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <lookups-grid 
            [appSettings]="appSettings"
            [oe]="oe"
            [genericMessages]="genericMessages" 
            [tableName]="lutName" 
            [lookup]="lookups[lutName]"
            [checklist]="checklist"
            [agencies]="agencies"
            (handlePost)="handlePost.emit($event)" 
            (handlePut)="handlePut.emit($event)"
            [disabledDatesFn]="disabledDatesFn"
            [readonly]="readonly"
          ></lookups-grid>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</div>

<div class="container-fluid" fxFlexFill fxLayout="column" *ngIf="alwaysExpanded">
  <lookups-search *ngIf="!oe && !appSettings && !genericMessages" [(filterString)]="filterString"> </lookups-search>
  <lookups-grid 
    [appSettings]="appSettings"
    [oe]="oe"
    [genericMessages]="genericMessages" 
    [tableName]="lutName" 
    [lookup]="lookups[lutName]"
    [checklist]="checklist"
    (handlePost)="handlePost.emit($event)" 
    (handlePut)="handlePut.emit($event)"
    [readonly]="readonly"
  ></lookups-grid>
</div>
