export default class ActionType {
    actionTypeId: string;
    actionTypeCode: string;
    actionTypeName: string;
    obsoleteDate: Date;
    obsoleteFlag: boolean;
  
    constructor(actionType?) {
      if (actionType) {
        this.actionTypeId = actionType.actionTypeId;
        this.actionTypeCode = actionType.actionTypeCode;
        this.actionTypeName = actionType.actionTypeName;
        this.obsoleteDate = actionType.obsoleteDate ? new Date(actionType.obsoleteDate) : null;
        this.obsoleteFlag = actionType.obsoleteFlag;
      }
    }
  }
  