import BaseAddress from './baseAddress';
import Country from './country';

export default class BaseAddressWithCountry extends BaseAddress {
  countryId: string;
  country: Country;

  constructor(address?) {
    super(address);

    if (address) {
      this.countryId = address.countryId;
      this.country = new Country(address.country);
    }
  }
}
