import Member from './member';
import TransactionSetType from './transactionSetType';

export default class Export834TransactionSet {
  memberId: string;
  export834TransactionSetId: string;
  referenceNumber: string;
  transactionSetObject: string;
  sentInd: boolean;
  receivedInd: boolean;
  processedInd: boolean;
  errorInd: boolean;
  errorObject: string;
  member?: Member;
  organizationCode?: string;
  createdDate: Date;
  transactionSetType: TransactionSetType;
  dependentTransactionInd = false;

  constructor(export834TransactionSet?) {
    if (export834TransactionSet) {
      this.export834TransactionSetId = export834TransactionSet.export834TransactionSetId;
      this.memberId = export834TransactionSet.memberId;
      this.referenceNumber = export834TransactionSet.referenceNumber;
      this.transactionSetObject = export834TransactionSet.transactionSetObject;
      this.sentInd = export834TransactionSet.sentInd;
      this.receivedInd = export834TransactionSet.receivedInd;
      this.processedInd = export834TransactionSet.processedInd;
      this.errorInd = export834TransactionSet.errorInd;
      this.errorObject = export834TransactionSet.errorObject ? JSON.stringify(export834TransactionSet.errorObject) : null;
      this.createdDate = export834TransactionSet.createdDate ? new Date(export834TransactionSet.createdDate) : null;
      this.member = export834TransactionSet.member ? new Member(export834TransactionSet.member) : null;
      this.transactionSetType = export834TransactionSet.transactionSetType ? new TransactionSetType(export834TransactionSet.transactionSetType) : null;
      this.organizationCode = export834TransactionSet.member?.organization?.organizationCode || 'SelfPay';
      this.dependentTransactionInd = this.errorObject && this.errorObject.includes('RETURNING RCD DUE TO PRIOR FAILED');
    }
  }
}
