import PlanType from './planType';

export default class MemberWaiver {
  memberWaiverId: string;
  memberId: string;
  memberName ?: String;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  planTypeId: string;
  planType: PlanType;
  specialOpenEnrollmentId: string;
  waiveType?: string;
  constructor(memberWaiver?) {
    if (memberWaiver) {
      this.memberWaiverId = memberWaiver.MemberWaiverId;
      this.memberId = memberWaiver.MemberId;
      this.effectiveStartDate = memberWaiver.EffectiveStartDate ? new Date(memberWaiver.EffectiveStartDate) : null;
      this.effectiveEndDate = memberWaiver.EffectiveEndDate ? new Date(memberWaiver.EffectiveEndDate) : null;
      this.planTypeId = memberWaiver.PlanTypeId;
      this.planType = new PlanType(memberWaiver.PlanType);
      if (memberWaiver.memberName) {
        this.memberName = memberWaiver.memberName;
      }
      this.specialOpenEnrollmentId = memberWaiver.SpecialOpenEnrollmentId || null;
    }
  }

}
