import { some } from 'lodash';
// ng
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

// ext
import { find, filter } from 'lodash';

// local
import DependentComposite from 'src/app/models/dependentComposite';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import SOEFormDependent from 'src/app/models/soeFormDependent';
import Subscriber from 'src/app/models/subscriber';
import Member from 'src/app/models/member';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import SelfPay from 'src/app/models/selfPay';

@Component({
  selector: 'dep-additional',
  templateUrl: 'dep.additional.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentAdditionalComponent implements OnInit, OnDestroy {
  @Output() additionalQuestionAnswered: EventEmitter<boolean> = new EventEmitter();
  @Output() dependentsQuestionAnswered: EventEmitter<boolean> = new EventEmitter();
  @Output() outputDependent: EventEmitter<SelfPayFormDependent> = new EventEmitter();
  @Input() dependent: DependentComposite;
  @Input() isSOE = false;
  @Input() isSelfPay = false;
  @Input() selfPay ?: SelfPay;
  @Input() soe?: SpecialOpenEnrollment;
  @Input() subscriber: Subscriber;
  addAnother: boolean;
  newDependents: SelfPayFormDependent[];
  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.selfPay?.formJson.dependents) {
      this.newDependents = filter(this.selfPay.formJson.dependents,
        (d: SelfPayFormDependent) => !d.memberId && !some(this.subscriber.members, (m: Member) => m.socialSecurityNumber === d.socialSecurityNumber));
    }
  }

  ngOnDestroy(): void {

  }

  importDependent(dep: SelfPayFormDependent): void {
    // emit both dependent and yes to question as if adding
    this.outputDependent.emit(dep);
    this.additionalQuestionAnswered.emit(true);
  }
}
