import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { NgForm } from '@angular/forms';
import { CoreService } from 'src/app/services/core.service'

@Component({
  selector: 'soe-effective-date',
  templateUrl: 'soe.effectiveDate.component.html',
  styleUrls: [],
  providers: []
})

export class SoeEffectiveDateComponent implements OnInit {
  @Input() soe: SpecialOpenEnrollment;
  @Output() saveSOE: EventEmitter<void> = new EventEmitter<void>();
  @Output() previous: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('coverageDateForm') coverageDateForm: NgForm;
  icons = {
    faCalendar
  };
  constructor(private coreService: CoreService) {

  }

  ngOnInit(): void {
  }

  submitEffectiveDate(): void {
    if (!dayjs(this.soe.coverageEffectiveDate).isSame(dayjs(this.soe.coverageEffectiveDate).startOf('month'), 'day')) {
      // this.coreService.popMessage('Effective date must be the first of a month.', 'error', 3000);
      this.coverageDateForm.controls.coverageEffectiveDate.setErrors({firstOfMonth: true});
      return;
    }
    if (!this.coverageDateForm.controls.coverageEffectiveDate.valid) {
      return;
    }
    this.saveSOE.emit();
  }
}
