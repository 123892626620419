import { env } from './../../../../../env/development';
import { SoeReviewFormComponent } from './components/reviewGrid/components/reviewForm/soeReview.form.component';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Document from 'src/app/models/document';
import { DocumentService } from 'src/app/services/document.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { CoreService, AccessLevel, UserTypeCode } from './../../../../services/core.service';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sortBy, forEach, filter, findIndex, uniqBy, get, cloneDeep, find, some } from 'lodash';
import { first } from 'rxjs/operators';
import SystemUser from 'src/app/models/user';
import { SoeService } from 'src/app/services/soe.service';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import { SoeReviewAdminGridComponent } from './components/reviewGrid/soeReview.admin.grid.component';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import Organization from 'src/app/models/organization';
import * as dayjs from 'dayjs';
import SubscriberCoverage from 'src/app/models/subscriberCoverage';
import PlanType from 'src/app/models/planType';
import Subscriber from 'src/app/models/subscriber';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import Plan from 'src/app/models/plan';
import { lastValueFrom } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import SpecialOpenEnrollmentActionLog from 'src/app/models/specialOpenEnrollmentActionLog';
import { DependentService } from 'src/app/services/dependent.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.PlanType, LookupType.SpecialOpenEnrollmentVerificationStatus)
@Component({
  selector: 'soereview-admin',
  templateUrl: 'soeReview.admin.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SoeReviewAdminComponent implements OnInit {
  @ViewChild('soeReviewGrid') public soeReviewGrid: SoeReviewAdminGridComponent;
  @ViewChild('review') public soeReviewFormComponent: SoeReviewFormComponent;
  systemUser: SystemUser;
  totalSpecialOpenEnrollmentRequests = 0;
  onlyShowPendingRecords = true;
  currentOrganization: Organization;
  gridColumns: { [k: string]: string | {} }[] = [
    { field: 'member.fullName', title: 'Subscriber', format: 'string' },
    { field: 'specialOpenEnrollmentType.specialOpenEnrollmentTypeName', title: 'Event', format: 'string' },
    { field: 'specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName', title: 'Status', format: 'string' },
    { field: 'coverageEffectiveDate', title: 'Effective Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'eventDate', title: 'Event Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'requestReceivedDate', title: 'Submit Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'expirationDate', title: 'Expiration Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'approvedDate', title: 'Verify Date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'hasDocumentsText', title: 'Has Documents', format: 'string', sortable: false },
  ];
  public gridData: GridDataResult;
  state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  lookups = {
    specialOpenEnrollmentVerificationStatus: [],
    documentTypes: [],
  };
  isHCAAdmin = false;
  inHCAAdminState = false;
  isHCA = false;
  dentalPlanType: string;
  medicalPlanType: string;
  dentalWaivePlan: Plan;
  hasEditAccess = false;
  selectedSoe: SpecialOpenEnrollment;
  verifyValues: SpecialOpenEnrollmentVerificationStatus[] = [];
  denyValues: SpecialOpenEnrollmentVerificationStatus[] = [];
  subscriber: Subscriber;
  selfPayOnly = false;
  within60Days: boolean = null;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private soeService: SoeService,
    private spinnerService: SpinnerOverlayService,
    private enrollmentService: EnrollmentService,
    private subscriberService: SubscriberService,
    private documentService: DocumentService,
    private dependentService: DependentService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((q) => {
      if (q.selfPayOnly) {
        this.selfPayOnly = q.selfPayOnly;
      }
      // because child router mounts and has queryparam = null, so we don't need to change everything eveywhere
      if (!this.selectedSoe && !q.selfPayOnly) {
        this.selfPayOnly = false;
      }
    });

    this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe(async (s) => {
      if (s != null) {
        this.selectedSoe = null;
        this.currentOrganization = s;
        if (this.currentOrganization && !this.inHCAAdminState) {
          this.getSpecialOpenEnrollments(this.currentOrganization.organizationId, this.onlyShowPendingRecords);
          if (this.soeReviewGrid) {
            this.soeReviewGrid.reSetDataForGrid();
          }
        }
      }
    });

    this.coreService.refreshForced
    .pipe(untilDestroyed(this))
    .subscribe(() => {
      if (this.currentOrganization?.organizationId) {
        this.getSpecialOpenEnrollments(this.currentOrganization.organizationId, this.onlyShowPendingRecords);
        if (this.soeReviewFormComponent && this.soeReviewGrid) {
          this.soeReviewFormComponent.closeWizardDialogue();
          this.soeReviewGrid.collapseAllRows();
        }
      }
  });

    this.hasEditAccess = this.coreService.systemUserHasAccess(AccessLevel.Edit);

    const data =
    {
      user: this.route.snapshot.data.user,
      userType: this.route.snapshot.data.userType,
      lookups: this.route.snapshot.data.lookups
    };

    this.systemUser = data.user;
    this.isHCAAdmin = this.systemUser.userOrganizationRoles.map((r) => r.userRoleName).includes('HCA');
    this.inHCAAdminState = data.userType === 'hca';
    this.lookups.specialOpenEnrollmentVerificationStatus = sortBy(data.lookups.specialOpenEnrollmentVerificationStatus, ['specialOpenEnrollmentVerificationStatusName']);
    this.lookups.documentTypes = sortBy(data.lookups.documentTypes, ['documentTypeName']);
    this.dentalPlanType = get(
      find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.dentalPlanTypeCode),
      'planTypeId'
    );
    this.medicalPlanType = get(
      find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.medicalPlanTypeCode),
      'planTypeId'
    );
    this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA) || this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);

    if (this.inHCAAdminState && !this.gridData?.data?.length) {
      this.getSpecialOpenEnrollments(null, this.onlyShowPendingRecords);
    }
    this.enrollmentService.getAllPlans().pipe(first()).pipe(untilDestroyed(this)).subscribe((plans) => {
      this.dentalWaivePlan = find(plans, (p: Plan) => p.planCode === env.dentalWaivePlanCode);
    });

    if (this.lookups.specialOpenEnrollmentVerificationStatus.length > 0) {
      this.verifyValues = filter(this.lookups.specialOpenEnrollmentVerificationStatus, (soevs) => soevs.approvedInd);
      this.denyValues = filter(this.lookups.specialOpenEnrollmentVerificationStatus, (soevs) => soevs.deniedInd);
    }
  }

  async getSpecialOpenEnrollments(organizationId: string, pending: boolean): Promise<void> {
    this.spinnerService.hide();
    this.spinnerService.show();
    const gridQuery = this.coreService.kendoGridStateToQueryString(this.state);
    if (organizationId === null) {
      this.soeService.getSpecialOpenEnrollmentsForHCA(pending, gridQuery, this.selfPayOnly).pipe(first()).pipe(untilDestroyed(this)).subscribe(results => {
        this.gridData = results;
        this.spinnerService.hide();
      });
    } else {
      this.soeService.getSpecialOpenEnrollmentsForOrganization(organizationId, pending, gridQuery).pipe(first()).pipe(untilDestroyed(this)).subscribe(results => {
        this.gridData = results;
        this.spinnerService.hide();
      });
    }
  }

  async soeSelected(soe: SpecialOpenEnrollment): Promise<void> {
    this.selectedSoe = soe;

    if (soe && soe.specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusCode !== 'C') {
      // get the documents for this SOE (includes dependent verification documents)
      await this.setSOEDocs();

      // also fetch subscriber and overwrite the member object on the soe with that
      this.spinnerService.show();
      await this.fetchSubscriberAndSetEP(soe).then(() => {
        this.spinnerService.hide();
      });
    }
  }

  public async fetchSubscriberAndSetEP(soe: SpecialOpenEnrollment): Promise<void> {
    const fullSubscriber = await lastValueFrom(this.subscriberService.getSubscriberWithSOE(soe?.memberId, soe.specialOpenEnrollmentId));
    fullSubscriber.soeEnrollmentPeriod = find(fullSubscriber.allEnrollmentPeriods, (ep: EnrollmentPeriod) => ep.enrollmentPeriodId === soe?.specialOpenEnrollmentId);
    this.subscriber = fullSubscriber;
    this.coreService.setSubscriber(fullSubscriber);
    this.coreService.setEnrollmentPeriod(fullSubscriber.soeEnrollmentPeriod);
    const index  = findIndex(this.gridData.data, (gd) => {
      return gd.specialOpenEnrollmentId === soe.specialOpenEnrollmentId;
    });
    this.gridData.data[index].member = fullSubscriber;
  }

  async showPendingChange(): Promise<void> {
    this.gridData?.data?.forEach((gd, idx) => this.soeReviewGrid.kendoGrid.collapseRow(idx));
    await this.updateDataset(null);
    if (this.selectedSoe && this.selectedSoe.simplifiedStatus !== 'Draft' && this.onlyShowPendingRecords){
      this.soeSelected(null);
      this.soeReviewGrid.selectedKeys = [];
    }
  }

  async updateDataset(newState): Promise<void> {
    if (newState) {
      this.state = newState;
    }
    const currentOrDefaultOrganizationId = this.inHCAAdminState ? null : get(this.currentOrganization, 'organizationId', this.systemUser.userOrganizationRoles[0].organizationId);
    await this.getSpecialOpenEnrollments(currentOrDefaultOrganizationId, this.onlyShowPendingRecords);
  }

  async updateSpecialOpenEnrollment(soeRec: SpecialOpenEnrollment, generateEP?: boolean): Promise<void> {
    const updatedRecIndex = findIndex(this.gridData.data, (gd) => gd.specialOpenEnrollmentId === soeRec.specialOpenEnrollmentId);
    this.spinnerService.show();
    const clonedRec = cloneDeep(soeRec);
    if(clonedRec?.documents){
      delete clonedRec.documents;
    }
    // delete clonedRec.formJson;
    delete clonedRec.member;
    try {
      if (typeof this.within60Days === 'boolean') {
        await lastValueFrom(this.dependentService
          .updateDeathOrDivorce60DayIndicator(this.subscriber.subscriberMemberId, this.currentOrganization?.organizationId, this.selectedSoe?.specialOpenEnrollmentId, this.within60Days));
      }
      const updatedRec = await lastValueFrom(this.soeService.updateSpecialOpenEnrollment(soeRec.memberId, clonedRec));
      if (this.gridData.data[updatedRecIndex]?.documents) {
        // preserve documents that were fetched previously
        updatedRec.documents = cloneDeep(this.gridData.data[updatedRecIndex].documents);
      }
      this.gridData.data[updatedRecIndex] = updatedRec;
      this.selectedSoe = updatedRec;
      this.subscriber.refetch = true;
      this.coreService.setSubscriber(this.subscriber);
      // allow change detection to run after grid updates
      setTimeout(() => this.soeReviewGrid.kendoGrid.expandRow(updatedRecIndex));
      if (generateEP) {
        // refetch sub like on expand
        this.fetchSubscriberAndSetEP(updatedRec);
        this.soeReviewFormComponent.navigateToWizard();
      }
      this.coreService.popMessage('Special Open Enrollment updated successfully.', 'success', 3000);
    } catch (err) {
      console.log('err', err);
    }
    this.spinnerService.hide();
  }

  async waivePlansDueToDualEnrollment(soe: SpecialOpenEnrollment): Promise<void> {
    const coverageElections = new SubscriberCoverage();
    coverageElections.enrollmentPeriodId = soe.specialOpenEnrollmentId;
    coverageElections.subscriberMemberId = soe.memberId;
    coverageElections.electedPlans = [];
    coverageElections.waivedPlanTypes = [];
    coverageElections.planTypeIds = [];
    const fullBenefits = (soe.member as Subscriber).organization?.benefitSetup?.benefitSetupCode === 'FB';
    if (fullBenefits) {
      coverageElections.electedPlans.push(this.dentalWaivePlan);
      coverageElections.waivedPlanTypes.push(this.medicalPlanType);
      coverageElections.planTypeIds.push(this.dentalPlanType);
    } else {
      coverageElections.waivedPlanTypes.push(this.medicalPlanType);
    }
    try {
      this.spinnerService.show();
      const updatedElections = await lastValueFrom(this.enrollmentService.createSubscriberEnrollments(soe.memberId, soe.specialOpenEnrollmentId, coverageElections));
      this.spinnerService.hide();
      if (fullBenefits) {
        this.router.navigate([soe.memberId + '/supplemental/' + soe.specialOpenEnrollmentId], { relativeTo: this.route });
      } else {
        this.router.navigate([soe.memberId + '/confirmation/' + soe.specialOpenEnrollmentId], { relativeTo: this.route });
      }
    } catch (err) {
      this.spinnerService.hide();
      console.log('err', err);
    }
  }

  generateEnrollmentPeriodFromSOE(soe: SpecialOpenEnrollment): EnrollmentPeriod {
    const soeEP = new EnrollmentPeriod();
    return soeEP;
  }

  refreshSelectedSOE(): void {
    this.setSOEDocs();
  }

  async setSOEDocs(): Promise<void> {
    this.spinnerService.show();
    const documents: Document[] = await lastValueFrom(this.documentService.getSpecialOpenEnrollmentDocuments(this.selectedSoe.specialOpenEnrollmentId, this.selectedSoe.memberId)) || [];
    this.selectedSoe.documents = documents;
    forEach(this.selectedSoe.documents, (document: Document) => {
      this.documentService.getMemberDocumentById(this.selectedSoe.memberId, document.documentId, document).pipe(untilDestroyed(this)).subscribe((doc) => {
        document = doc;
      });
    });
    this.spinnerService.hide();
  }
}
