import { ComponentCanDeactivate } from './../../../../../../guards/pendingChanges/pendingChanges.guard';
import { HostListener } from '@angular/core';
// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';

// ext
import { Subscription, Observable } from 'rxjs';
import { some, map, union, filter, every, isEqual, cloneDeep } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService } from 'src/app/services/core.service';
import { AttestationService } from 'src/app/services/attestation.service';
import Subscriber from 'src/app/models/subscriber';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Milestone from 'src/app/models/milestone';
import Attestation from 'src/app/models/attestation';
import Response from 'src/app/models/response';
import { ActivatedRoute, Router } from '@angular/router';
import { find } from 'lodash';
import AttestationType from 'src/app/models/attestationType';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { faTasks, faLungs } from '@fortawesome/free-solid-svg-icons';
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
import PlanType from 'src/app/models/planType';
import Member from 'src/app/models/member';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
import { env } from 'src/env/development';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';

@UntilDestroy()
@Lookups(LookupType.Response, LookupType.AttestationType)
@Component({
  selector: 'attest-tobacco',
  templateUrl: 'tobacco.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class AttestTobaccoComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  subscriber: Subscriber;
  enrollmentPeriod: EnrollmentPeriod;
  attestationMilestone: Milestone;
  responses: Response[];
  attestationTypes: AttestationType[];
  tobaccoAttestationType: AttestationType;
  currentAttestation: Attestation;
  step = 0;
  usesTobacco = true;
  initialUsesTobacco = true;
  membersUseTobacco = false;
  isSEBB = false;
  icons = {
    faTasks,
    faLungs
  };
  changesHaveBeenSaved = false;
  confirmButtonText = `Confirm and let's view supplemental coverage options`;
  isSOE = false;
  isSelfPay = false;
  currentSOE: SpecialOpenEnrollment;
  constructor(
    private spinnerService: SpinnerOverlayService,
    private attestationService: AttestationService,
    private enrollmentPeriodService: EnrollmentPeriodService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.subscriber = data.subscriber;
      this.enrollmentPeriod = data.enrollmentPeriod;
      if (this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SOE') {
        this.isSOE = true;
      }
      if (this.isSOE) {
        this.currentSOE = find(this.subscriber.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => soe.specialOpenEnrollmentId === this.enrollmentPeriod.enrollmentPeriodId);
      }
      this.isSEBB = this.subscriber.organization?.agency?.agencyCode === 'SEBB';
      const subscriberCoverageSummary = new SubscriberEffectiveSummary(this.subscriber, this.enrollmentPeriod);
      this.attestationMilestone = find(this.enrollmentPeriod.milestones, (mi: Milestone) => mi.milestoneName === 'Attestations');
      this.responses = data.lookups.response;
      this.attestationTypes = data.lookups.attestationType;
      this.tobaccoAttestationType = find(this.attestationTypes, (at: AttestationType) => at.attestationTypeCode === 'TS');
      this.currentAttestation = find(
        this.subscriber.attestations,
        (a: Attestation) =>
          a.attestationTypeId === this.tobaccoAttestationType.attestationTypeId &&
          dayjs(a.effectiveStartDate).isSameOrBefore(this.enrollmentPeriod.coverageEffectiveStartDate) &&
          (!a.effectiveEndDate || dayjs(a.effectiveEndDate).isSameOrAfter(this.enrollmentPeriod.coverageEffectiveStartDate))
      );
      this.membersUseTobacco = subscriberCoverageSummary.anyDependentsUseTobacco();
      this.usesTobacco = this.currentAttestation ? this.currentAttestation.responseId === find(this.responses, (r: Response) => r.responseName === 'Yes').responseId : true;
      this.initialUsesTobacco = cloneDeep(this.usesTobacco);
      const selfPayMemberTypeCodes = env.unpaidLeaveMemberTypes.concat(env.retireeMemberTypes).concat(env.cobraMemberTypes);
      this.isSelfPay = selfPayMemberTypeCodes.indexOf(this.subscriber.memberType?.memberTypeCode) > -1;
      if (this.isSelfPay && !this.attestationMilestone.completedDate) {
        var selectedSP = this.subscriber.selfPays.find(o=>o.selfPayId == this.enrollmentPeriod.enrollmentPeriodId);
        this.usesTobacco = !!selectedSP.formJson.subscriber.tobaccoUseFormInd ? (selectedSP.formJson.subscriber.tobaccoUseFormInd === "No" ? false : true) : this.usesTobacco;
      }
    });
  }
  ngOnDestroy(): void {}

  skipSuppLtd(subscriber: Subscriber): boolean {
    const isLWOP = env.unpaidLeaveMemberTypes.indexOf(this.subscriber.memberType?.memberTypeCode) > -1;

    // Retired and COBRA skip ...
    return this.isSelfPay && !isLWOP;
  }

  updateStep(s: number): void {
    const skipSelfPay = this.skipSuppLtd(this.subscriber);

    this.step = s;
    if (!skipSelfPay && some(this.enrollmentPeriod.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === '5')) {
      this.confirmButtonText = `Confirm and let's view supplemental coverage options`;
    } else {
      this.confirmButtonText = `Confirm and let's review`;
    }
  }

  saveAttestation(): void {
    const skipSelfPay = this.skipSuppLtd(this.subscriber);

    const appropriateResponse = this.usesTobacco ? 'Yes' : 'No';
    const attestation = new Attestation();
    attestation.memberId = this.subscriber.memberId;
    attestation.effectiveStartDate = this.enrollmentPeriod.effectiveStartDate;
    attestation.attestationTypeId = this.tobaccoAttestationType.attestationTypeId;
    attestation.responseId = find(this.responses, (r: Response) => r.responseName === appropriateResponse).responseId;
    attestation.attestationDate = new Date();
    this.spinnerService.show();
    if(this.isSOE){
      this.attestationService.createTobaccoActionForSOE(attestation, this.enrollmentPeriod.enrollmentPeriodId, null, this.currentSOE?.specialOpenEnrollmentId).pipe(untilDestroyed(this)).subscribe((a: Attestation) => {
        this.currentAttestation = a;
  
        this.enrollmentPeriodService
          .createMilestoneCompletion(this.subscriber.memberId, this.attestationMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId)
          .pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
            this.changesHaveBeenSaved = true;
            this.enrollmentPeriod = e;
            if ((!skipSelfPay && some(this.enrollmentPeriod.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === '5'))) {
              this.coreService.popMessage('Great! We have your tobacco attestation, time to check out some supplemental coverage options.', 'success', 6000);
              this.router.navigate([`../../supplemental/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            } else {
              this.coreService.popMessage(`Great! One last step - let's review your elections`, 'success', 8000);
              this.router.navigate([`../../confirmation/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            }
  
            this.spinnerService.hide();
          });
  
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
      });
    }else {
      this.attestationService.createTobaccoAction(attestation, this.enrollmentPeriod.enrollmentPeriodId).pipe(untilDestroyed(this)).subscribe((a: Attestation) => {
        this.currentAttestation = a;
  
        this.enrollmentPeriodService
          .createMilestoneCompletion(this.subscriber.memberId, this.attestationMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId)
          .pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
            this.changesHaveBeenSaved = true;
            this.enrollmentPeriod = e;
            if ((!skipSelfPay && some(this.enrollmentPeriod.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === '5') && !(this.isSEBB && this.isSelfPay))) {
              this.coreService.popMessage('Great! We have your tobacco attestation, time to check out some supplemental coverage options.', 'success', 6000);
              this.router.navigate([`../../supplemental/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            } else {
              this.coreService.popMessage(`Great! One last step - let's review your elections`, 'success', 8000);
              this.router.navigate([`../../confirmation/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            }
  
            this.spinnerService.hide();
          });
  
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
      });
    }

  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.changesHaveBeenSaved || this.usesTobacco === this.initialUsesTobacco;
  }
}
