import SelfPayFormSpouse from './selfPayFormSpouse';
import BaseAddress from './baseAddress';
import MemberMedicare from './memberMedicare';
import MemberAddress from './memberAddress';
import BirthSex from './birthSex';
import GenderIdentity from './genderIdentity';

export default class SelfPayFormDependent extends BaseAddress {
  memberId: string | null;
  subscriberMemberId: string;
  relationshipTypeName: string; // combo of relationship type and relationship qualify reason
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  birthDate: Date;
  birthSex: string;
  genderIdentity: string;
  medicalCoverInd: boolean;
  dentalCoverInd: boolean;
  visionCoverInd: boolean;
  terminateAllCoverageInd: boolean
  medicalContinueCoverageInd: boolean;
  medicalDoNotContinueCoverage: boolean;
  medicalTerminateCoverage: boolean;
  medicalTerminateCoverageDate: Date;
  medicalTerminateCoverageReason: string;
  dentalContinueCoverageInd: boolean;
  dentalDoNotContinueCoverage: boolean;
  dentalTerminateCoverage: boolean;
  dentalTerminateCoverageDate: Date;
  dentalTerminateCoverageReason: string;
  visionContinueCoverageInd: boolean;
  visionDoNotContinueCoverage: boolean;
  visionTerminateCoverage: boolean;
  visionTerminateCoverageDate: Date;
  visionTerminateCoverageReason: string;
  coveredByOtherMedicalInd: boolean;
  coveredByOtherVisionDate: Date;
  coveredByOtherVisionInd: boolean;
  coveredByOtherMedicalDate: Date;
  coveredByOtherDentalInd: boolean;
  coveredByOtherDentalDate: Date;
  coveredByOtherSSDInd: boolean;
  coveredByOtherSSDDate: Date;
  coveredByOtherPEBBAccountInd: boolean;
  tobaccoUseInd: boolean;
  spouseForm: SelfPayFormSpouse;
  surchargeNA: boolean;
  partnershipStartDate ?: Date;
  memberMedicare: MemberMedicare;
  isSpouse: boolean = false;
  spouseSurchargeTriState: string;
  addressSameAsSubscriber: boolean = false;
  memberEthnicities;
  spouseAttestQ1: boolean = false;
  spouseAttestQ2: boolean = false;
  spouseAttestQ3: boolean = false;
  spouseAttestQ4: boolean = false;
  spouseAttestQ5: boolean = false;
  spouseAttestQ6: boolean = false;
  residentialAddress: BaseAddress;
  medicareNumber: string;
  medicarePartAInd: boolean = false;
  medicarePartBInd: boolean = false;
  medicarePartADate: Date;
  medicarePartBDate: Date;
  confirmed = false;
  constructor(dependent?: any) {
    super(dependent);
    this.confirmed = dependent.confirmed || false;
    this.memberId = dependent.memberId || null;
    this.subscriberMemberId = dependent.subscriberMemberId || null;
    this.relationshipTypeName = dependent.relationshipTypeName || dependent.relationshipToSubscriber?.relationshipType?.relationshipTypeName || null;
    this.socialSecurityNumber = dependent.socialSecurityNumber || null;
    this.firstName = dependent.firstName || null;
    this.middleName = dependent.middleName || null;
    this.lastName = dependent.lastName || null;
    this.suffix = dependent.suffix || null;
    this.birthDate = dependent.birthDate ? new Date(dependent.birthDate) : null;
    if (dependent.memberId) {
      this.birthSex = !(dependent.birthSex instanceof BirthSex) ? dependent.birthSex : dependent.birthSex?.birthSexCode;
      this.genderIdentity = !(dependent.genderIdentity instanceof GenderIdentity) ? dependent.genderIdentity : dependent.genderIdentity?.genderIdentityCode;
    } else {
      this.birthSex = dependent.birthSex || null;
      this.genderIdentity = dependent.genderIdentity || null;
    }
    this.medicalCoverInd = dependent.medicalCoverInd || undefined;
    this.dentalCoverInd = dependent.dentalCoverInd || undefined;
    this.visionCoverInd = dependent.visionCoverInd || undefined;
    this.terminateAllCoverageInd = dependent.terminateAllCoverageInd;
    this.medicalContinueCoverageInd = dependent.medicalContinueCoverageInd || false;
    this.medicalDoNotContinueCoverage = dependent.medicalDoNotContinueCoverage || false;
    this.medicalTerminateCoverage = dependent.medicalTerminateCoverage || false;
    this.medicalTerminateCoverageDate = dependent.medicalTerminateCoverageDate ? new Date(dependent.medicalTerminateCoverageDate) : null;
    this.medicalTerminateCoverageReason = dependent.medicalTerminateCoverageReason || null;
    this.dentalContinueCoverageInd = dependent.dentalContinueCoverageInd || false;
    this.dentalDoNotContinueCoverage = dependent.dentalDoNotContinueCoverage || false;
    this.dentalTerminateCoverage = dependent.dentalTerminateCoverage || false;
    this.dentalTerminateCoverageDate = dependent.dentalTerminateCoverageDate ? new Date(dependent.dentalTerminateCoverageDate) : null;
    this.dentalTerminateCoverageReason = dependent.dentalTerminateCoverageReason || null;
    this.visionContinueCoverageInd = dependent.visionContinueCoverageInd || false;
    this.visionDoNotContinueCoverage = dependent.visionDoNotContinueCoverage || false;
    this.visionTerminateCoverage = dependent.visionTerminateCoverage || false;
    this.visionTerminateCoverageDate = dependent.visionTerminateCoverageDate ? new Date(dependent.visionTerminateCoverageDate) : null;
    this.visionTerminateCoverageReason = dependent.visionTerminateCoverageReason || null;
    this.coveredByOtherMedicalInd = dependent.coveredByOtherMedicalInd || false;
    this.coveredByOtherMedicalDate = dependent.coveredByOtherMedicalDate ? new Date(dependent.coveredByOtherMedicalDate) : null;
    this.coveredByOtherVisionInd = dependent.coveredByOtherVisionInd || false;
    this.coveredByOtherVisionDate = dependent.coveredByOtherVisionDate ? new Date(dependent.coveredByOtherMedicalDate) : null;    
    this.coveredByOtherDentalInd = dependent.coveredByOtherDentalInd || false;
    this.coveredByOtherDentalDate = dependent.coveredByOtherDentalDate ? new Date(dependent.coveredByOtherDentalDate) : null;
    this.coveredByOtherSSDInd = dependent.coveredByOtherSSDInd || false;
    this.coveredByOtherSSDDate = dependent.coveredByOtherSSDDate ? new Date(dependent.coveredByOtherSSDDate) : null;
    this.coveredByOtherPEBBAccountInd = dependent.coveredByOtherPEBBAccountInd || false;
    this.tobaccoUseInd = dependent.tobaccoUseInd || undefined;
    this.spouseForm = dependent.spouseForm ? new SelfPayFormSpouse(dependent.spouseForm) : new SelfPayFormSpouse({});
    this.memberMedicare = dependent.memberMedicare || new MemberMedicare({});
    this.surchargeNA = (dependent.memberMedicare?.medicarePartAEnrolledInd && dependent.memberMedicare?.medicarePartBEnrolledInd) || dependent.surchargeNA;
    this.partnershipStartDate = dependent.partnershipStartDate;
    this.isSpouse = this.relationshipTypeName?.indexOf("Spouse")>-1;
    this.spouseSurchargeTriState = dependent.spouseSurchargeTriState;
    this.addressSameAsSubscriber = dependent.addressSameAsSubscriber;
    this.memberEthnicities = dependent.memberEthnicities;
    this.spouseAttestQ1 = dependent.spouseAttestQ1;
    this.spouseAttestQ2 = dependent.spouseAttestQ2;
    this.spouseAttestQ3 = dependent.spouseAttestQ3;
    this.spouseAttestQ4 = dependent.spouseAttestQ4;
    this.spouseAttestQ5 = dependent.spouseAttestQ5;
    this.spouseAttestQ6 = dependent.spouseAttestQ6;
    this.residentialAddress = dependent.residentialAddress ? new MemberAddress(dependent.residentialAddress) : null;
    this.medicareNumber = dependent.medicareNumber ? dependent.medicareNumber : dependent?.memberMedicare?.medicareNumber || null;
    this.medicarePartAInd = dependent.medicarePartAInd;
    this.medicarePartBInd = dependent.medicarePartBInd;
    this.medicarePartBDate = dependent.medicarePartBDate;
    this.medicarePartADate = dependent.medicarePartADate;
  }
}
