import { faCalendar, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ControlContainer, NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { filter, cloneDeep } from 'lodash';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import SelfPayType from 'src/app/models/selfPayType';
import { env } from 'src/env/development';
import SelfPay from 'src/app/models/selfPay';
import * as dayjs from 'dayjs';

@Component({
  selector: 'self-pay-new-dependent',
  templateUrl: 'selfPay.new.dependent.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SelfPayNewDependentComponent implements OnChanges {
  @Input() selfPayDependent: SelfPayFormDependent;
  @Input() pebbVisionDate: Date;
  @Input() idx: number;
  @Input() selfPay: SelfPay;
  @Input() agency: string;
  @Input() requestType: SelfPayType;
  @Output() submitDependent: EventEmitter<SelfPayFormDependent> = new EventEmitter<SelfPayFormDependent>();
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  currentDependent: SelfPayFormDependent;
  currentMaxDate: Date;
  electMedicalDisabled = false;
  electDentalDisabled = false;
  icons = {
      faCalendar,
      faExclamationCircle,
      faInfoCircle
  };
  env = env;
  isCobra: boolean = false;
  isPebbLwopVision: boolean = false;
  isPebbRetireeVision: boolean = false;
  isCobraOE = false;
  thirteenYearsDate: Date;
  disableFormForNoCoverage = false;
  ngOnChanges() { 
    this.currentMaxDate = new Date();
    this.thirteenYearsDate = dayjs().subtract(13, 'year').toDate();
    this.isCobra = env.cobraSelfPayTypeCodes.includes(this.requestType.selfPayTypeCode);
    if (this.selfPayDependent) {
      this.currentDependent = cloneDeep(this.selfPayDependent);
    }
    if (this.requestType.selfPayTypeCode === 'R'  || this.requestType.selfPayTypeCode === 'OER') {
      this.electMedicalDisabled = true;
      this.electDentalDisabled = true;
      if((this.selfPay?.formJson?.subscriber?.medicalEnrollInd || this.selfPay?.formJson?.subscriber?.dentalEnrollInd) && !this.isPebbRetireeVision && !this.isCobraOE) {
        //dental enrolled means both dental and medical now
        this.currentDependent.medicalCoverInd = true;
      }
      if(this.selfPay?.formJson?.subscriber?.dentalEnrollInd && !this.isPebbRetireeVision) {
        this.currentDependent.dentalCoverInd = true;
      }

      if (this.requestType.selfPayTypeCode === 'R' && this.selfPay.formJson?.subscriber?.actionsCode === 'deferring' &&
          this.selfPay.formJson?.subscriber?.deferQuestionFour) {
          //initial retiree request is deferring due to medicaid, enable dependent coverage for medical only
          this.electMedicalDisabled = false;
          this.electDentalDisabled = true;
      }
    }
    this.isPebbLwopVision = this.agency === 'PEBB' && (((this.requestType.selfPayTypeCode === 'ALWOP' || this.requestType.selfPayTypeCode === "OEU") &&  this.selfPay?.coverageEffectiveDate ? dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(this.pebbVisionDate) : dayjs(new Date()).isSameOrAfter(this.pebbVisionDate)) || this.requestType.selfPayTypeCode === "OEU");
    this.isPebbRetireeVision = this.agency === 'PEBB' && ((this.requestType.selfPayTypeCode === 'R' &&  (this.selfPay?.coverageEffectiveDate ? dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(this.pebbVisionDate) : dayjs(new Date()).isSameOrAfter(this.pebbVisionDate))) || this.requestType.selfPayTypeCode === "OER");
    this.isCobraOE = this.requestType.selfPayTypeCode === "OEC";
    if(this.isPebbRetireeVision){
      this.disableFormForNoCoverage = !this.currentDependent.medicalCoverInd && !this.currentDependent.dentalCoverInd && !this.currentDependent.visionCoverInd
    }
  }

  emitSubmitDependent() {
    this.submitDependent.emit(this.currentDependent);
  }

  cancelChanges() {
    this.currentDependent = cloneDeep(this.selfPayDependent);
    this.closePanel.emit();
  }

  updateTobacco() {
    this.currentDependent.surchargeNA = !this.currentDependent.surchargeNA;
    if (this.currentDependent.surchargeNA) {
      this.currentDependent.tobaccoUseInd = null;
    }
  }

  defaultPremiumSurcharge() {
    if(this.currentDependent?.memberMedicare?.medicarePartAEnrolledInd && this.currentDependent?.memberMedicare?.medicarePartBEnrolledInd) {
      this.currentDependent.surchargeNA = true;
    }else {
      this.currentDependent.surchargeNA = false;
    }
  }

  updateMedicarePartA(yes: boolean) {
    if(!yes) {
      this.currentDependent.medicarePartADate = null;
    }
  }

  updateMedicarePartB(yes: boolean) {
    if(!yes) {
      this.currentDependent.medicarePartBDate = null;
    }
    if(yes) {
      this.currentDependent.visionCoverInd = false;
    }
  }

  handleCoverageChange(e) {
    if(e) {
      this.disableFormForNoCoverage = false;
    }else if(!this.currentDependent.medicalCoverInd && !this.currentDependent.dentalCoverInd && !this.currentDependent.visionCoverInd) {
      this.disableFormForNoCoverage = true;
      this.resetFormValues();
    }
  }

  resetFormValues() {
    this.currentDependent.medicarePartAInd =
    this.currentDependent.medicarePartBInd =
    this.currentDependent.spouseSurchargeTriState =
    this.currentDependent.spouseAttestQ1 =
    this.currentDependent.spouseAttestQ2 =
    this.currentDependent.spouseAttestQ3 =
    this.currentDependent.spouseAttestQ4 =
    this.currentDependent.spouseAttestQ5 =
    this.currentDependent.spouseAttestQ6 =
    this.currentDependent.medicareNumber =
    this.currentDependent.medicarePartADate =
    this.currentDependent.medicarePartBDate =
    this.currentDependent.tobaccoUseInd = null
  }

}
