import { RaceEthnicityComponent } from './components/race-ethnicity/race-ethnicity.component';
import { DemographicsComponent } from './components/demographics/demo.component';
import { FormatSSNPipe } from './pipes/formatSSN.pipe';
import { PEBBMessagesComponent } from './components/message/message.component';
import { NgxViewerModule } from 'ngx-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GenericFileUploadComponent } from './components/genericFileUpload/genericFileUpload.component';
import { GenericFileDownloadComponent } from './components/genericFileDownload/genericFileDownload.component';
import { GenericDocumentViewerComponent } from './components/genericDocumentViewer/genericDocumentViewer.component';
import { NavWarningComponent } from './components/navWarning/navWarning.component';
import { QuestionComponent } from './components/question/question.component';
import { PDFComponent } from './components/pdf/pdf.component';
import { PromptComponent } from './components/prompt/prompt.component';
import { CoverageSummaryComponent } from './components/coverageSummary/coverageSummary.component';
import { CertificationComponent } from './components/certification/certification.component';
import { MustContainSomeDirective } from './components/directives/mustContainSome.validator';
import { ForbiddenValidatorDirective } from './components/directives/forbidden.validator';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { InputsModule, MaskedTextBoxModule } from '@progress/kendo-angular-inputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GenericGridComponent } from './components/genericGrid/genericGrid.component';
import { DatePickerComponent } from './components/datePicker/datePicker.component';
import { CheckboxRadioComponent } from './components/checkbox-radio/checkbox-radio.component';
import { CheckboxRadioListComponent } from './components/checkbox-radio-list/checkbox-radio-list.component';
import { AddressComponent } from './components/address/address.component';
import { TermsAndConditionsComponent } from './components/termsAndConditions/termsAndConditions.component'
import { SOEInstructionsComponent } from './components/language/SOEInstructions/soe-instructions.component'
import { ProgramFilterComponent } from './components/programFilter/programFilter.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VerificationSummaryComponent } from 'src/app/modules/adminPerspay/components/verification/components/adminGrid/components/verificationSummary/verification.summary.component';
import { VerificationFormComponent } from 'src/app/modules/adminPerspay/components/verification/components/adminGrid/components/verificationForm/verification.form.component';
import { SubscriberManagementSearchComponent } from 'src/app/modules/adminPerspay/components/subscriberManagement/components/search/subscriberManagement.search.component';
import { MedicareComponent} from './components/medicare/medicare.component'

// import { EditService } from '@progress/kendo-angular-grid/dist/es/editing/edit.service';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { UploadModule} from '@progress/kendo-angular-upload';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgbModule, NgbTooltipModule, NgbDateAdapter, NgbDateNativeAdapter, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DateFormatPipe } from './pipes/date.pipe';
import { HasCountryPipe } from './pipes/hasCountry.pipe';
import { SearchComponent } from './components/search/search.component';
import { RouterModule } from '@angular/router';
import { IsObsoletePipe } from './pipes/isObsolete.pipe';
import { AllowedValuesPipe } from './pipes/allowedValues.pipe';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SelfPayPDFComponent } from './components/pdf/selfPay/pdf/selfPay.pdf.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { SelfPayCustomFormComponent } from './components/pdf/selfPay/customForm/selfPay.customForm.component';
import { SelfPaySOEChangeOfAddressPdfComponent } from './components/pdf/selfPay/customForm/changeOfAddress/selfPay.soe.changeOfAddress.pdf';
import { NativeControlElementDirective } from 'src/app/directives/nativeControlElement/nativeControlElement.directive';

@NgModule({
  imports: [
    BrowserModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule,
    GridModule,
    DialogsModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    FlexLayoutModule,
    UploadModule,
    ExcelModule,
    DragDropModule,
    NgbTooltipModule,
    PDFExportModule,
    NgbNavModule,
    NgbModule,
    MaskedTextBoxModule,
    DateInputsModule,
    LayoutModule,
    PdfViewerModule,
    NgxViewerModule
  ],
  declarations: [
    GenericGridComponent,
    DatePickerComponent,
    CheckboxRadioComponent,
    CheckboxRadioListComponent,
    AddressComponent,
    GenericDocumentViewerComponent,
    GenericFileDownloadComponent,
    GenericFileUploadComponent,
    SpinnerComponent,
    DateFormatPipe,
    HasCountryPipe,
    SearchComponent,
    IsObsoletePipe,
    AllowedValuesPipe,
    ForbiddenValidatorDirective,
    MustContainSomeDirective,
    CoverageSummaryComponent,
    CertificationComponent,
    PromptComponent,
    PDFComponent,
    QuestionComponent,
    NavWarningComponent,
    PEBBMessagesComponent,
    SelfPayPDFComponent,
    FormatSSNPipe,
    VerificationSummaryComponent,
    VerificationFormComponent,
    SubscriberManagementSearchComponent,
    DemographicsComponent,
    TermsAndConditionsComponent,
    SOEInstructionsComponent,
    ProgramFilterComponent,
    MedicareComponent,
    RaceEthnicityComponent,
    SelfPayCustomFormComponent,
    SelfPaySOEChangeOfAddressPdfComponent,
    NativeControlElementDirective
  ],
  exports: [
    GenericGridComponent,
    DatePickerComponent,
    CheckboxRadioComponent,
    CheckboxRadioListComponent,
    AddressComponent,
    GenericDocumentViewerComponent,
    GenericFileDownloadComponent,
    GenericFileUploadComponent,
    SpinnerComponent,
    DateFormatPipe,
    HasCountryPipe,
    SearchComponent,
    IsObsoletePipe,
    AllowedValuesPipe,
    ForbiddenValidatorDirective,
    MustContainSomeDirective,
    CoverageSummaryComponent,
    CertificationComponent,
    PromptComponent,
    PDFComponent,
    QuestionComponent,
    NavWarningComponent,
    PEBBMessagesComponent,
    SelfPayPDFComponent,
    FormatSSNPipe,
    VerificationSummaryComponent,
    VerificationFormComponent,
    SubscriberManagementSearchComponent,
    DemographicsComponent,
    TermsAndConditionsComponent,
    SOEInstructionsComponent,
    ProgramFilterComponent,
    MedicareComponent,
    RaceEthnicityComponent,
    SelfPayCustomFormComponent,
    SelfPaySOEChangeOfAddressPdfComponent,
    NativeControlElementDirective
  ],
  entryComponents: [SpinnerComponent],
  bootstrap: [],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class SharedModule {}
