import { map } from 'lodash';
import BaseAddress from './baseAddress';
import ContactType from './contactType';

export default class OrganizationContact extends BaseAddress {
  organizationContactId: string;
  organizationId: string;
  contactTypeId: string;
  firstName: string;
  middleInitial: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  faxNumber: string;
  contactTypes: ContactType[] = [];
  addressIsSameAsPhysicalAddressInd: boolean;

  constructor(organizationContact?) {
    super(organizationContact);

    if (organizationContact) {
      this.organizationContactId = organizationContact.organizationContactId;
      this.organizationId = organizationContact.organizationId;
      this.contactTypeId = organizationContact.contactTypeId;
      this.firstName = organizationContact.firstName;
      this.middleInitial = organizationContact.middleInitial;
      this.lastName = organizationContact.lastName;
      this.emailAddress = organizationContact.emailAddress;
      this.phoneNumber = organizationContact.phoneNumber;
      this.faxNumber = organizationContact.faxNumber;
      this.contactTypeId = organizationContact.contactTypeId;
      this.contactTypes = map(organizationContact.contactTypes, c => new ContactType(c));
      this.addressIsSameAsPhysicalAddressInd = organizationContact.addressIsSameAsPhysicalAddressInd;
    }
  }
}
