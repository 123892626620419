import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, } from '@angular/core';
import Relationship from 'src/app/models/relationship';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import { NgForm } from '@angular/forms';
import { NotificationService } from '@progress/kendo-angular-notification';
import { sortBy, filter, find } from 'lodash';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'verification-form',
  templateUrl: 'verification.form.component.html',
  styleUrls: [],
  providers: []
})

export class VerificationFormComponent implements OnInit, OnChanges {
@Input() isDisabled = false;
@Input() dataItem: Relationship;
@Input() rowIndex;
@Input() verifyValues: RelationshipVerificationStatus[] = [];
@Input() denyValues: RelationshipVerificationStatus[] = [];
@Output() pushSaveVerificationResponse: EventEmitter<void> = new EventEmitter<void>();
@Output() resetData: EventEmitter<void> = new EventEmitter<void>();
@Output() closeDetail: EventEmitter<number> = new EventEmitter<number>();
@ViewChild('verifyForm') public verifyForm: NgForm;
pendingChanges;
noDocumentId: string;
needsCertification = false;
icons = {
  faCalendar
};
constructor(
  private notificationService: NotificationService,
  private coreService: CoreService
) {}

  ngOnInit(): void {
    this.dataItem.relationshipQualifyReason.documentTypes = sortBy(this.dataItem.relationshipQualifyReason.documentTypes, ['documentTypeName']);
    this.verifyValues = sortBy(
    this.verifyValues, ['relationshipVerificationStatusName']);
    this.denyValues = sortBy(this.denyValues, ['relationshipVerificationStatusName']);
    this.noDocumentId = find(this.denyValues, ['relationshipVerificationStatusCode', 'NR'])?.relationshipVerificationStatusId;
    if (this.dataItem.subscriberMember &&
      this.dataItem.dependentMember &&
      (Date.now() - (new Date(this.dataItem.subscriberMember.birthDate)).getTime() > 1955232000000
      || Date.now() - (new Date(this.dataItem.dependentMember.birthDate)).getTime() > 1955232000000)) {
        this.verifyValues = filter(this.verifyValues, v => v.relationshipVerificationStatusCode !== 'VW');
    }
  }

  ngOnChanges(): void {
    if (this.dataItem && this.dataItem.relationshipQualifyReason
      && this.dataItem.relationshipQualifyReason.needsCertificationInd) {
      this.needsCertification = true;
    }
  }


  pushSaveVerification(formContainer): void {
    this.markAllControlsAsTouched();
    if (this.verifyForm.valid) {
      this.pushSaveVerificationResponse.emit();
    } else {
      this.coreService.popMessage('Please enter all required fields and re-submit', 'error', 3000, this.coreService.getInvalidFields(formContainer));
    }
  }

  reSetDataForGrid(): void {
    this.resetData.emit();
  }

  updatedVerificationStatus(): void {
    if (this.dataItem.approvedInd) {
      this.dataItem.deniedDate = null;
      this.dataItem.approvalDate = new Date();
    } else if (this.dataItem.approvedInd === false) {
      this.dataItem.deniedDate = new Date();
      this.dataItem.approvalDate = null;
    } else {
      this.dataItem.approvalDate = null;
      this.dataItem.deniedDate = null;
    }
    this.dataItem.relationshipVerificationStatusId = null;
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.verifyForm);
  }

  handleCancel(): void {
    this.pendingChanges = false;
    this.closeDetail.emit(this.rowIndex);
  }
}
