import { map } from 'lodash';
import RelationshipType from './relationshipType';
import RelationshipVerificationStatus from './relationshipVerificationStatus';
import Member from './member';
import RelationshipQualifyReason from './relationshipQualifyReason';
import DocumentType from './documentType';
import Document from './document';
import RelationshipCertification from './relationshipCertification';
import RelationshipCertificationStatus from './relationshipCertificationStatus';

export default class Relationship {
  relationshipId: string;
  subscriberMemberId: string;
  dependentMemberId: string;
  relationshipTypeId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  certificationTypeId: string;
  relationshipQualifyReasonId: string;
  relationshipVerificationStatusId: string;
  approvalDate: Date;
  approvedById: string;
  deniedDate: Date;
  deniedById: string;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  relationshipType: RelationshipType;
  relationshipVerificationStatus: RelationshipVerificationStatus;
  relationshipQualifyReason: RelationshipQualifyReason;
  subscriberMember: Member;
  dependentMember: Member;
  simplifiedStatus: string;
  documentTypeId: string;
  documentType: DocumentType;
  documents: Document[];
  approvedInd?: boolean;
  secondaryRelationships: Relationship[];
  relationshipCertifications: RelationshipCertification[];
  relationshipCertificationStatus: RelationshipCertificationStatus;
  relationshipCertificationStatusId: string;
  relationshipCertificationStatusDate: Date;
  expirationDate ?: Date;
  hasDocuments ?: boolean;
  isPendingRelationship?: boolean;
  constructor(relationship?) {
    if (relationship) {
      this.relationshipId = relationship.relationshipId;
      this.subscriberMemberId = relationship.subscriberMemberId;
      this.dependentMemberId = relationship.dependentMemberId;
      this.relationshipTypeId = relationship.relationshipTypeId;
      this.effectiveStartDate = relationship.effectiveStartDate ? new Date(relationship.effectiveStartDate) : null;
      this.effectiveEndDate = relationship.effectiveEndDate ? new Date(relationship.effectiveEndDate) : null;
      this.certificationTypeId = relationship.certificationTypeId;
      this.relationshipQualifyReasonId = relationship.relationshipQualifyReasonId;
      this.relationshipVerificationStatusId = relationship.relationshipVerificationStatusId;
      this.approvalDate = relationship.approvalDate ? new Date(relationship.approvalDate) : null;
      this.approvedById = relationship.approvedById;
      this.deniedDate = relationship.deniedDate ? new Date(relationship.deniedDate) : null;
      this.deniedById = relationship.deniedById;
      this.createdDate = relationship.createdDate ? new Date(relationship.createdDate) : null;
      this.createdById = relationship.createdById;
      this.modifiedDate = relationship.modifiedDate;
      this.modifiedById = relationship.modifiedById;
      this.relationshipType = new RelationshipType(relationship.relationshipType);
      this.relationshipVerificationStatus = new RelationshipVerificationStatus(relationship.relationshipVerificationStatus);
      this.relationshipQualifyReason = new RelationshipQualifyReason(relationship.relationshipQualifyReason);
      this.subscriberMember = new Member(relationship.subscriberMember);
      this.dependentMember = new Member(relationship.dependentMember);
      this.documentTypeId = relationship.documentTypeId;
      this.documentType = relationship.documentType ? new DocumentType(relationship.documentType) : null;
      this.documents = map(relationship.documents, (rd) => new Document(rd));
      this.relationshipCertifications = map(relationship.relationshipCertifications, (rc) => new RelationshipCertification(rc));
      this.relationshipCertificationStatus = relationship.relationshipCertificationStatus ? new RelationshipCertificationStatus(relationship.relationshipCertificationStatus) : null;
      this.relationshipCertificationStatusId = relationship.relationshipCertificationStatusId;
      this.relationshipCertificationStatusDate = relationship.relationshipCertificationStatusDate ? new Date(relationship.relationshipCertificationStatusDate) : null;
      this.hasDocuments = relationship.hasDocuments;
      this.isPendingRelationship = relationship.isPendingRelationship;
      const needsCert = this.relationshipQualifyReason.needsCertificationInd;
      const needsVerification = !this.relationshipQualifyReason.managedByHCAInd;
      const approvedVerification = this.relationshipVerificationStatus.approvedInd;
      const deniedVerification = this.relationshipVerificationStatus.deniedInd;

      const certificationStatus = this.relationshipCertificationStatus?.relationshipCertificationStatusName;
      const hasCert = this.relationshipCertificationStatus?.approvedInd;
      const certDenied = this.relationshipCertificationStatus?.deniedInd;
      
      if (needsCert && !needsVerification) {
        if (hasCert) {
          this.simplifiedStatus = 'Verified';
        } else if (certDenied) {
          this.simplifiedStatus = 'Denied'
        } else {
          this.simplifiedStatus = 'Pending certification'
        }
      } else if (needsCert && needsVerification) {
        if (approvedVerification && hasCert) {
          this.simplifiedStatus = 'Verified'
        } else if (certDenied || deniedVerification) {
          this.simplifiedStatus = 'Denied'
        } else if (hasCert && !approvedVerification && !deniedVerification) {
          this.simplifiedStatus = 'Pending Verification'
        } else {
          this.simplifiedStatus = 'Pending certification';
        }
      } else {
        if (approvedVerification) {
          this.simplifiedStatus = 'Verified';
        }
        else if (deniedVerification) {
          this.simplifiedStatus = 'Denied'
        } else {
          this.simplifiedStatus = 'Pending'
        }
      }

    this.approvedInd = approvedVerification || deniedVerification ? approvedVerification : null;
    this.secondaryRelationships = map(relationship.secondaryRelationships, (r) => new Relationship(r));

    }
  }
}
