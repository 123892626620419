<div fxLayout="row" fxLayoutAlign="center center">
  <pdf *ngIf="step === getDisplayStep(2)" #pdf [documentTitleText]="documentTitleText" [documentHeaderText]="documentHeaderText">
    <coverage-summary 
      [forPdf]="true"
      [documentTitleText]="documentTitleText"
      [documentHeaderText]="documentHeaderText"
      [enrolledHeader]="enrolledHeader"
      [subscriber]="subscriber"
      [summaryObject]="subscriberSummary"
    ></coverage-summary>
  </pdf>
  <hca-prompt
    [conditionalNextIdx]="getDisplayStep(1)"
    [conditionalNextMet]="userHasReadLegal"
    [hasBackToDashButton]="hasBackToDashButton"
    (putPostObject)="confirm()"
    fxFlex="90"
    [confirmIndex]="getDisplayStep(1)"
    [icon]="icons.faCheck"
    (previous)="updateStep($event)"
    (next)="updateStep($event)"
    [idx]="step"
    [milestone]="confirmationMilestone"
    [hideBackToDash]="isSOE"
  >
    <div fxLayoutAlign="center center" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
      <coverage-summary
        *ngIf="step !== undefined"
        [step]="step"
        [pageOneStep]="0"
        [pageTwoStep]="1"
        [documentTitleText]="documentTitleText"
        [documentHeaderText]="documentHeaderText"
        [enrolledHeader]="enrolledHeader"
        documentFooterText="documentFooterText"
        [subscriber]="subscriber"
        [summaryObject]="subscriberSummary"
      ></coverage-summary>
      <div *ngIf="displayRaceEthnicity && step === getDisplayStep(0)" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <div [fxFlex]="this.subscriber.members?.length > 1 ? 45 : 95" fxLayout="column" class="card">
            <div class="card-body">
              <h3>Subscriber</h3>
              <race-ethnicity (updateMemberObject)="updateSubForEthnicities($event)" [suppressSave]="true" [ethnicities]="ethnicities" [member]="subscriber"></race-ethnicity>
            </div>
          </div>
          <div *ngIf="this.subscriber.members?.length > 1" fxFlex="45" fxLayout="column" class="card">
            <div class="card-body">
              <h3>Spouse or SRDP</h3>
              <race-ethnicity (updateMemberObject)="updateDepForEthnicities($event)" [suppressSave]="true" [ethnicities]="ethnicities" [member]="this.subscriber.members[1]"></race-ethnicity>
            </div>
          </div>
        </div>
      </div>
      <confirm-legal [isSebb]="subscriber.isSebb" [isMedicalOnly]="subscriber.isMedicalOnlyOrganization" (userHasReadLegal)="userHasReadLegal = true" *ngIf="!isReadOnly && step === getDisplayStep(1)"></confirm-legal>
      <confirm-finish
        [isReadOnly]="isReadOnly"
        [subscriber]="subscriber"
        [isSOE]="isSOE"
        [isHcaAdmin]="isHcaAdmin"
        [soeSubmitted]="soeSubmitted"
        [isDeathOrDivorceSoe]="isDeathOrDivorceSoe"
        *ngIf="step === getDisplayStep(2)"
        (navToDashboard)="navToDashboard()"
        (downloadCoveragePDF)="downloadCoveragePDF()"
        (emailSubscriptionUpdated)="putEmailAddress($event)"
        (submitSOERequest)="submitSOERequest()"
        (within60Days)="within60Days = $event"
      ></confirm-finish>
    </div>
  </hca-prompt>
</div>
