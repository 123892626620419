<div class="card" fxLayout="column" *ngIf="currentTermRecord">
  <form #termForm="ngForm" #formContainer>
    <div class="card-body fxGap-medium" fxLayout="column">
      <h5>Terminate/Transfer subscriber:</h5>
      <div fxLayout="row" fxLayout.sm ="column" fxLayoutAlign="start start" class="fxGap-medium">
        <div class="k-form-field align-bottom required" fxFlex="30">
          <label for="terminationReason">Termination/Transfer Reason </label>
          <select required [disabled]="disabled" class="form-control" 
            name="terminationReason" 
            id="terminationReason"
            [(ngModel)]="currentTermRecord.lossOfEligibilityReasonId" [required]="currentTermRecord.lossOfEligibilityDate">
            <option></option>
            <option *ngFor="let reason of getOptions()" 
            [ngValue]="reason.reasonId">{{ reason.reasonName }}</option>
          </select>
        </div>
        <div class="k-form-field align-bottom" fxFlex="30" *ngIf="!(disabled && currentTermRecord.lossOfEligibilityReasonId === reasonDeath) && 
          currentTermRecord.lossOfEligibilityReasonId && 
          currentTermRecord.lossOfEligibilityReasonId !== reasonNotEligible
          ">
          <date-picker
            #terminationDateInput="ngModel"
            name="terminationDateInput"
            required
            [disabled]="disabled"
            [lockToMonth]="currentTermRecord.lossOfEligibilityDate && currentTermRecord.terminationDate && !isHCA"
            [max]="existingTermMonthEnd"
            [min]="subscriberComposite.agencyEffectiveStartDate"
            enforceLowerLimit
            [agencyCode]="agencyCode"
            [(ngModel)]="currentTermRecord.terminationDate"
            (ngModelChange)="terminationDateChange()">
            {{currentTermRecord.lossOfEligibilityReasonId === reasonDeath ? 'Deceased Date' : (currentTermRecord.lossOfEligibilityReasonId === reasonTransfer ? 'Transfer Date' : 'Termination effective date')}}
          </date-picker>
          <div *ngIf="terminationDateInput.invalid && (terminationDateInput.dirty) && terminationDateInput.errors?.ngbDate?.minDate" class="alert alert-danger">
            The date entered is less than the current lower limit date.  Do not proceed with terminating/transferring this subscriber using an alternate date and contact Outreach and Training for assistance.
          </div>
          <div *ngIf="terminationDateInput.invalid && (terminationDateInput.dirty) && terminationDateInput.errors?.ngbDate?.maxDate" class="alert alert-danger">
            The future date entered is not allowed based on the current invoicing schedule.
          </div>
        </div>
        <div class="k-form-field align-bottom" fxFlex="30" *ngIf="disabled && currentTermRecord.lossOfEligibilityReasonId === reasonDeath">
          <date-picker
            #terminationDateInput="ngModel"
            name="terminationDateInput"
            required
            [min]="deathMonthStart"
            [max]="deathMonthEnd"
            enforceLowerLimit
            [agencyCode]="agencyCode"
            [(ngModel)]="currentTermRecord.terminationDate"
            (ngModelChange)="terminationDateChange()">
            {{currentTermRecord.lossOfEligibilityReasonId === reasonDeath ? 'Deceased Date' : (currentTermRecord.lossOfEligibilityReasonId === reasonTransfer ? 'Transfer Date' : 'Termination effective date')}}
          </date-picker>
          <div *ngIf="terminationDateInput.invalid && (terminationDateInput.dirty) && terminationDateInput.errors?.ngbDate?.minDate" class="alert alert-danger">
            The date entered is less than the current lower limit date.  Do not proceed with terminating/transferring this subscriber using an alternate date and contact Outreach and Training for assistance.
          </div>
          <div *ngIf="terminationDateInput.invalid && (terminationDateInput.dirty) && terminationDateInput.errors?.ngbDate?.maxDate" class="alert alert-danger">
            The future date entered is not allowed based on the current invoicing schedule.
          </div>
        </div>        
        <div class="k-form-field align-bottom" fxFlex="30" *ngIf="currentTermRecord.lossOfEligibilityDate">
          <label for="benefitEndDate">Benefit End Date</label>
          <div class="input-group">
            <input type="text" readonly class="form-control" name="benefitEndDate" value="{{currentTermRecord.lossOfEligibilityDate | date: 'MM/dd/yyyy'}}">
          </div>
        </div>
      </div>
      <span style="text-align: center;" *ngIf="currentTermRecord.lossOfEligibilityReasonId && currentTermRecord.terminationDate && currentTermRecord.lossOfEligibilityReasonId !== reasonDeath && currentTermRecord.lossOfEligibilityReasonId !== reasonTransfer">
          Are you sure you want to terminate coverage for this member? If this member is the primary subscriber on an account
          their dependent's coverage will be terminated as well.
      </span>
      <span style="text-align: center;" *ngIf="!disabled && currentTermRecord.lossOfEligibilityReasonId && currentTermRecord.terminationDate && (currentTermRecord.lossOfEligibilityReasonId === reasonDeath)">
        Are you sure you want to report the death of this subscriber?
      </span>
      <!-- <span style="text-align: center;" *ngIf="currentTermRecord.lossOfEligibilityReasonId && currentTermRecord.terminationDate && (currentTermRecord.lossOfEligibilityReasonId === reasonTransfer)">
        Are you sure you want to transfer benefits for this subscriber?
      </span> -->
      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center" style="padding-top: 20px">
        <button
          *ngIf="!disabled &&currentTermRecord.lossOfEligibilityReasonId && (currentTermRecord.terminationDate || currentTermRecord.lossOfEligibilityReasonId === reasonNotEligible)"
          (click)="onTerminateMemberCoverage(formContainer)"
          class="btn btn-primary"
          [disabled]="disabled"
        >
          Submit changes
        </button>
        <button
          *ngIf="disabled && currentTermRecord.lossOfEligibilityReasonId === reasonDeath"
          (click)="onUpdateSubscriberDeathDate(currentTermRecord.terminationDate,formContainer)"
          class="btn btn-primary"
        >
          Submit changes
        </button>        
        <button
          *ngIf="terminationComposite.lossOfEligibilityReasonId && currentTermRecord.lossOfEligibilityReasonId === reasonTransfer"
          (click)="cancelTransfer.emit(currentTermRecord)"
          class="btn btn-secondary"
          [disabled]="disabled"
        >
          Cancel transfer
        </button>
      </div>
    </div>
  </form>
</div>
