import MemberFlag from 'src/app/models/memberFlag';
import MemberAddress from './memberAddress';
import Member from './member';
import MemberMedicare from './memberMedicare';
import SelfPayOrigin from './selfPayOrigin';
import BaseAddressWithCountry from './baseAddressWithCountry';
import * as dayjs from 'dayjs';

// Flat model for new calls to create subscriber
export default class SubscriberComposite extends BaseAddressWithCountry {
  memberId: string;
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  birthDate: Date;
  birthSexId: string;
  genderIdentityId: string;
  emailAddress: string;
  emailNotificationInd: boolean;
  memberTypeId: string;
  eligibilityDate: Date;
  eligibilityReasonId: string;
  eligibilityTypeEffectiveDate: Date;
  organizationId: string;
  isRepresentedInd: boolean;
  representedDate: Date;
  representedStartDate: Date;
  representedEndDate: Date;
  salary: number;
  zipcodeNumber: string;
  homePhoneNumber: string;
  workPhoneNumber: string;
  cellPhoneNumber: string;
  residentialAddress: MemberAddress;
  mailingAddress: MemberAddress;
  memberMedicare?: MemberMedicare;
  memberSelfPayOrigin?: SelfPayOrigin;
  isMedicalOnlyOrganization: boolean;
  isEligibleForLTD = true;
  ltdEffectiveDate: Date;
  isPortCommissioner = false;
  wellnessFlag: MemberFlag;
  ineligibleForLTDFlag: MemberFlag;
  deceasedDate: Date;
  sebbIsNewlyEligibleBeforeFirstDayOfSchool = false;
  mailStop: string;
  sebbEligibilityDateChange: Date;
  terminationDate: Date;
  agencyEffectiveStartDate: Date;
  constructor(member?) {
    super(member);

    if (member) {
      this.memberId = member.memberId;
      this.socialSecurityNumber = member.socialSecurityNumber;
      this.firstName = member.firstName;
      this.lastName = member.lastName;
      this.middleName = member.middleName;
      this.suffix = member.suffix;
      this.birthDate = member.birthDate ? new Date(member.birthDate) : null;
      this.birthSexId = member.birthSexId;
      this.genderIdentityId = member.genderIdentityId;
      this.emailAddress = member.emailAddress;
      this.emailNotificationInd = member.emailNotificationInd;
      this.memberTypeId = member.memberTypeId;
      this.eligibilityDate = member.eligibilityDate ? new Date(member.eligibilityDate) : null;
      this.eligibilityTypeEffectiveDate = member.eligibilityTypeEffectiveDate ? new Date(member.eligibilityTypeEffectiveDate) : null;
      this.eligibilityReasonId = member.eligibilityReasonId;
      this.organizationId = member.organization ? member.organization.organizationId : null;
      this.isMedicalOnlyOrganization = member.isMedicalOnlyOrganization;
      this.isRepresentedInd = member.isRepresentedInd;
      this.representedDate = member.representedDate ? new Date(member.representedDate) : null;
      this.representedStartDate = member.representedDate ? new Date(member.representedDate) : null;
      this.salary = member.salary;
      this.homePhoneNumber = member?.homePhoneNumber?.phoneNumber ? member.homePhoneNumber.phoneNumber : member.homePhoneNumber;
      this.workPhoneNumber = member?.workPhoneNumber?.phoneNumber ? member.workPhoneNumber.phoneNumber : member.workPhoneNumber;
      this.cellPhoneNumber = member?.cellPhoneNumber?.phoneNumber ? member.cellPhoneNumber.phoneNumber : member.cellPhoneNumber;
      this.residentialAddress = member.residentialAddress ? new MemberAddress(member.residentialAddress) : null;
      this.mailingAddress = member.mailingAddress ? new MemberAddress(member.mailingAddress) : null;
      this.memberMedicare = member.memberMedicare ? new MemberMedicare(member.memberMedicare) : null;
      this.memberSelfPayOrigin = member.memberSelfPayOrigin ? new SelfPayOrigin(member.memberSelfPayOrigin) : null;
      this.wellnessFlag = member.wellnessFlag;
      this.ineligibleForLTDFlag = member.ineligibleForLTDFlag;
      this.mailStop = member.mailStop;
      this.terminationDate = member.terminationDate;
      this.agencyEffectiveStartDate = member.agencyEffectiveStartDate;
      if (this.ineligibleForLTDFlag) {
        this.isEligibleForLTD = !!this.ineligibleForLTDFlag.effectiveEndDate;
        this.ltdEffectiveDate = this.ineligibleForLTDFlag.effectiveEndDate || this.ineligibleForLTDFlag.effectiveStartDate || null;
      }
      if (member.deceasedDate) {
        this.deceasedDate = new Date(member.deceasedDate);
      }
      // mapping from returned transfer obj.
      if (member.residentialAddress) {
        this.addressLineOne = member.residentialAddress.addressLineOne;
        this.addressLineTwo = member.residentialAddress.addressLineTwo;
        this.city = member.residentialAddress.city;
        this.countryId = member.residentialAddress.countryId;
        this.countyId = member.residentialAddress.countyId;
        this.state = member.residentialAddress.state;
        this.zipcodeNumber = member.residentialAddress.zipcodeNumber;
      }
      this.sebbIsNewlyEligibleBeforeFirstDayOfSchool = member.sebbIsNewlyEligibleBeforeFirstDayOfSchool;
    }
  }
}
