<div class="grid-container">
  <form #gridForm="ngForm" #formContainer>
    <kendo-grid
      #genericGrid
      [selectable]="selectableSettings"
      [data]="clonedGridData"
      [kendoGridSelectBy]="selectBy"
      [selectedKeys]="selectedKeys"
      class="k-grid-ignore-click"
      [filterable]="filterable ? 'menu' : false"
      [filter]="state.filter"
      [skip]="state.skip"
      [sortable]="filterable ? { allowUnsort: true, initialDirection: 'asc', mode: 'multiple' } : false"
      [resizable]="true"
      [pageable]="pageable"
      [pageSize]="pageSize"
      [rowClass]="rowClass"
      [sort]="sort"
      (pageChange)="pageChange($event)"
      (dataStateChange)="dataStateChange($event)"
      (selectionChange)="changeSelection($event)"
      (sortChange)="changeSort($event)"
      (filterChange)="changeFilter($event)"
      (remove)="obsoleteHandler($event)"
      (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)"
      (add)="addHandler($event)"
      (save)="saveHandler($event, formContainer)"
    >
      <ng-template *ngIf="editable && !disableAdd && !globalReadOnly" kendoGridToolbarTemplate>
        <button kendoGridAddCommand type="button">Add new</button>
      </ng-template>
      <ng-template *ngIf="exportable" kendoGridToolbarTemplate>
        <button type="button" kendoGridExcelCommand icon="file-excel">Download</button>
      </ng-template>
      <kendo-grid-checkbox-column width="80" *ngIf="selectable">
        <ng-template *ngIf="showSelectionHeader" kendoGridHeaderTemplate>
          <b>Selection</b>
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column
        class="k-grid-ignore-click"
        [style.flex]="col.flex || 1"
        *ngFor="let col of gridColumns"
        field="{{ col.field }}"
        title="{{ col.title }}"
        [format]="col.format"
        [filter]="col.filter ? col.filter : null"
        [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
        [width]="col.width ? col.width : null"
      >
        <ng-template kendoGridFilterMenuTemplate
          let-column="col"
          let-filter="filter"
          let-filterService="filterService"
          *ngIf="col.field.endsWith('Date') || col.title.startsWith('Date ')"
          >
          <date-picker
            name="dateFilter"
            [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
            (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
            [label]="col.title">
          </date-picker>
        </ng-template>
        <ng-template *ngIf="col.type === 'select'" kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
          <select
            [style.border]="dataItem.conflicts && dataItem.conflicts.includes(col.field) ? '2px solid #bb6b69' : ''"
            [disabled]="globalReadOnly || (dataItem.isSubscriberInd && (col.field === 'Dental' || col.field === 'Vision')) || (col.field === 'Health' && medicalWaived && !dataItem.isSubscriberInd)"
            (change)="changeSelect(gridData[rowIndex], col.field)"
            class="form-control k-grid-ignore-click"
            name="responseName"
            [(ngModel)]="gridData[rowIndex][col.field]"
          >
            <option [disabled]="col.allows && !col.allows.includes(response)" *ngFor="let response of responses" [ngValue]="response">{{ response }}</option>
          </select>
        </ng-template>
        <ng-template *ngIf="col.field === 'modules'" kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
          <span *ngFor="let module of dataItem[col.field]; let index = index">{{ dataItem[col.field] && index === dataItem[col.field].length - 1 ? module.moduleName : module.moduleName + ', ' }} </span>
        </ng-template>
        <ng-template *ngIf="col.displayOnly !== true && col.field === 'responseId'" kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
          {{getResponse(dataItem.responses, dataItem[col.field])}}
        </ng-template>
        <ng-template *ngIf="col.hidden !== true && col.type !== 'select' && (col.displayOnly === true || ['modules', 'responseId'].indexOf(col.field) === -1)" kendoGridCellTemplate let-dataItem>
          {{outputField(dataItem, col)}}
        </ng-template>
        <ng-template id="editTemplate" kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <input
            [required]="col.required"
            [disabled]="globalReadOnly || col.disableEdit"
            *ngIf="
              col.field !== 'modules' &&
              col.field !== 'responseId' &&
              !(
                checkType(dataItem[col.field]) === 'date' ||
                checkType(dataItem[col.field]) === 'boolean' ||
                col.field.includes('Date') ||
                col.field.includes('Flag') ||
                col.field.includes('Ind') ||
                col.field.includes('isActive') ||
                col.field === 'agencyName'
              )
            "
            [(ngModel)]="dataItem[col.field]"
            kendoGridFocusable
            [name]="col.field"
            class="k-textbox form-control"
            type="text"
          />
          <select
            *ngIf="checkType(dataItem[col.field]) === 'boolean' || col.field.includes('Flag') || col.field.includes('Ind') || col.field.includes('isActive')"
            class="form-control k-grid-ignore-click"
            name="responseName"
            [(ngModel)]="dataItem[col.field]"
          >
            <option [ngValue]="true">True</option>
            <option [ngValue]="false">False</option>
          </select>
          <select
          *ngIf="col.field === 'agencyName'"
          class="form-control k-grid-ignore-click"
          name="agencyName"
          [(ngModel)]="dataItem.agencyId"
        >
          <option *ngFor="let agency of agencies" [ngValue]="agency.agencyId">{{agency.agencyCode}}</option>
          
        </select>
          <select
            [disabled]="globalReadOnly"
            *ngIf="col.field === 'responseId'"
            (change)="changeSelect(dataItem, col.field)"
            class="form-control k-grid-ignore-click"
            name="responseId"
            [(ngModel)]="dataItem[col.field]"
          >
            <option *ngFor="let response of dataItem.responses" [ngValue]="response.responseId">{{ response.responseName }}</option>
          </select>
          <div class="input-group" *ngIf="(checkType(dataItem[col.field]) === 'date' || col.field.includes('Date')) && !isGenericMessages">
            <date-picker
              #dateField="ngModel"
              [name]="col.field + rowIndex"
              [required]="col.required"
              [disabled]="globalReadOnly || col.disableEdit"
              [min]="minDate"
              [(ngModel)]="dataItem[col.field]"></date-picker>
          </div>
          
          <div class="input-group" *ngIf="(checkType(dataItem[col.field]) === 'date' || col.field.includes('Date')) && isGenericMessages">
            <date-picker
              #dateField="ngModel"
              useTime
              readOnlyInput
              [name]="col.field + rowIndex"
              [required]="col.required"
              [disabled]="globalReadOnly || col.disableEdit"
              [disabledDates]="disabledDatesFn.bind(dataItem)"
              [min]="minDate"
              [(ngModel)]="dataItem[col.field]"></date-picker>
          </div>
          <div class="input-group" *ngIf="col.field === 'modules' && isGenericMessages">
            <div class="mgbt-x-small">*Appears on these pages (must select at least one)</div>
            <div *ngFor="let checkbox of checklist" fxLayout="row" fxLayoutAlign="space-between" class="fxGap-tiny">
              <checkbox [name]="checkbox.moduleName" [(ngModel)]="dataItem.moduleObject[checkbox.moduleName]" [label]="checkbox.moduleName"></checkbox>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column *ngIf="deleteColumn" title="Manage" width="150" [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex>
          <div *ngIf="dataItem?.simplifiedStatus === 'Pending'" class="form-row hca-row-margin" flex fxLayoutAlign="center flex-end">
            <button type="button" class="btn hca-red-background float-right" (click)="removeRecord.emit(dataItem)"><fa-icon [icon]="icons.faTrashAlt" size="1x"></fa-icon> Delete</button>
          </div>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-command-column *ngIf="!globalReadOnly && editable" title="Manage" width="250"       [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex">
          <button kendoGridEditCommand type="button" [primary]="true">Edit</button>
          <button *ngIf="gridData[0] && gridData[0].obsoleteFlag !== undefined" kendoGridRemoveCommand type="button">Obsolete</button>
          <button kendoGridSaveCommand type="button">{{ isNew ? 'Add' : 'Update' }}</button>
          <button kendoGridCancelCommand type="button">{{ isNew ? 'Discard changes' : 'Cancel' }}</button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-excel *ngIf="exportable" [fetchData]="allData.bind({ gd: gridData })" fileName="{{ exportFileName }}.xlsx"></kendo-grid-excel>
    </kendo-grid>
  </form>
</div>
