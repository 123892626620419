export default class MemberType {
  memberTypeId: string;
  memberTypeCode: string;
  memberTypeName: string;
  managedByHCAInd: boolean;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(memberType?) {
    if (memberType) {
      this.memberTypeId = memberType.memberTypeId;
      this.memberTypeCode = memberType.memberTypeCode;
      this.memberTypeName = memberType.memberTypeName;
      this.managedByHCAInd = typeof memberType.managedByHcaInd === 'boolean' ? memberType.managedByHcaInd : false;
      this.obsoleteFlag = memberType.obsoleteFlag || null;
      this.obsoleteDate = memberType.obsoleteDate ? new Date(memberType.obsoleteDate) : null;
    }
  }

}
