import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import SelfPay from '../models/selfPay';
import SelfPayGrid from '../models/selfPayGrid';
import SelfPayElection from '../models/selfPayElections';
import MemberAddress from '../models/memberAddress';
import TerminationComposite from '../models/terminationComposite';
import Subscriber from '../models/subscriber';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root'
})
export class SelfPayService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  getSelfPayById(selfPayId: string, memberId: string): Observable<SelfPay> {
    return this.http.get<SelfPay>(`${env.apiUrl}/Member/${memberId}/SelfPay/${selfPayId}`)
    .pipe(map(x => this.commonService.createObject(SelfPay, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  logSelfPayView(selfPayId: string, memberId: string) {
    return this.http.get(`${env.apiUrl}/Member/${memberId}/SelfPay/logview/${selfPayId}`)
    .pipe(catchError(this.commonService.handleError));
  }  

  createSelfPay(memberId: string, selfPay: SelfPay): Observable<SelfPay> {
    return this.http.post<SelfPay>(`${env.apiUrl}/Member/${memberId}/SelfPay`, selfPay)
    .pipe(map(x => this.commonService.createObject(SelfPay, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateSelfPay(memberId: string, selfPay: SelfPay): Observable<SelfPay> {
    return this.http.put<SelfPay>(`${env.apiUrl}/Member/${memberId}/SelfPay/${selfPay.selfPayId}`, selfPay)
    .pipe(map(x => this.commonService.createObject(SelfPay, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  submitChangeOfAddress(memberId: string, memberAddresses: MemberAddress[]): Observable<SelfPay> {
    return this.http.put<SelfPay>(`${env.apiUrl}/Member/${memberId}/submitchangeofaddress`, memberAddresses)
    .pipe(catchError(this.commonService.handleError));
  }

  updateSelfPayForm(memberId: string, selfPay: SelfPay): Observable<SelfPay> {
    return this.http.put<SelfPay>(`${env.apiUrl}/Member/${memberId}/SelfPay/${selfPay.selfPayId}/form`, selfPay.formJson)
    .pipe(map(x => this.commonService.createObject(SelfPay, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  terminateSelfPay(terminateComposite: TerminationComposite): Observable<Subscriber> {
    return this.http
    .post<TerminationComposite>(`${env.apiUrl}/selfpay/${terminateComposite.memberId}/terminate`, terminateComposite)
    .pipe(map(x => this.commonService.createObject(Subscriber, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getSelfPayForHCA(selfPayId: string): Observable<SelfPayGrid> {
    return this.http.get<SelfPayGrid>(`${env.apiUrl}/hca/selfpay/${selfPayId}`)
    .pipe(map(x => this.commonService.createObject(SelfPayGrid, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getSelfPaysForHCA(onlyShowPending: boolean, onlyShowAssigned: boolean, gridQuery: string): Observable<GridDataResult> {
    var queryString = '?' + gridQuery + '&pending=' + onlyShowPending + '&assigned=' + onlyShowAssigned;
    return this.http.get<GridDataResult>(`${env.apiUrl}/hca/selfpay${queryString}`)
    .pipe(map(x => {
      x.data = this.commonService.createObjects(SelfPayGrid, x.data);
      return x;
    }))

    .pipe(catchError(this.commonService.handleError));
  }

  removeSelfPay(soe: SelfPay): Observable<SelfPay[]> {
    return this.http.delete<SelfPay[]>(`${env.apiUrl}/Member/${soe.memberId}/SelfPay/${soe.selfPayId}`)
    .pipe(catchError(this.commonService.handleError));
  }

  saveSelfPayElections(memberId: string, selfPayId: string, selfPayElection: SelfPayElection): Observable<SelfPayElection> {
    return this.http.post<SelfPayElection>(`${env.apiUrl}/hca/SelfPay/${memberId}/elections/${selfPayId}`, selfPayElection)
    .pipe(map(x => this.commonService.createObject(SelfPayElection, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  saveAssignmentChanges(selfpays: SelfPayGrid[]): Observable<SelfPay> {
    return this.http.put<SelfPay>(`${env.apiUrl}/selfpay/assign`, selfpays)
    .pipe(map(x => this.commonService.createObject(SelfPay, x)))
    .pipe(catchError(this.commonService.handleError));
  }
}
