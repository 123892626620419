/**
 * Used to filter results in grid for all programs or a specific program (PEBB/SEBB)
 */

// ng
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

//local
import { env } from 'src/env/development';

@Component({
  selector: 'program-filter',
  templateUrl: 'programFilter.component.html',
  styleUrls: [],
  providers: [],
})
export class ProgramFilterComponent implements OnInit {
  @Input() public agencyFilter: string = "";
  env = env;
  filterItems: any[] = [
    {"text": "Show only PEBB","value":false,"id":"pebbFilter","code": env.pebbCode},
    {"text": "Show only SEBB","value":false,"id":"sebbFilter","code": env.sebbCode}
  ];
  @Output() refreshSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() agencyFilterChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {

  }

  agencySearchChange() {
    this.agencyFilterChange.emit(this.agencyFilter);
    if (this.refreshSearch.observed) {
        this.refreshSearch.emit();
    }
  }

  toggleItemFilter(item) {
    this.filterItems.find(o=>o.id===item.id).value = !this.filterItems.find(o=>o.id===item.id).value;
    this.filterItemChange(this.filterItems.find(o=>o.id===item.id));
  }

  filterItemChange(item) {
    this.filterItems.find(o=>o.id!==item.id).value = false;
    const selItem = this.filterItems.find(o=>o.id===item.id);
    if (selItem.value) {
      this.agencyFilter=selItem.code;
    }
    else {
      this.agencyFilter="";
    }
    this.agencySearchChange();
  }
}
