import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<any> {
    return lastValueFrom(this.userService.getUser()
    )
    .then(systemUser => {
      return new Promise((resolve, reject) => {
        if (systemUser.userOrganizationRoles.some(r => r.userRoleName === 'Perspay' || r.userRoleName === 'HCA')) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  }
}
