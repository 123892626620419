export default class Action {
    actionLogId: string;
    actionTypeId: string;
    actionDate: Date;
    memberId: string;
    createdDate: Date;
    createdById: Date;
    modifiedDate: Date;
    modifiedById: Date;

    constructor(action?) {
      if (action) {
        this.actionLogId = action.actionLogId;
        this.actionTypeId = action.actionTypeId;
        this.actionDate = action.actionDate ? new Date(action.actionDate) : null;
        this.memberId = action.memberId;
        this.createdDate = action.createdDate ? new Date(action.createdDate) : null;
        this.createdById = action.createdById;
        this.modifiedDate = action.modifiedDate ? new Date(action.modifiedDate) : null;
        this.modifiedById = action.modifiedById;
      }
    }
  }
