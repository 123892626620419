import BaseAddress from "./baseAddress";
import BirthSex from "./birthSex";
import GenderIdentity from "./genderIdentity";
import MemberAddress from "./memberAddress";
import MemberMedicare from "./memberMedicare";

export default class SelfPayFormSpouse extends BaseAddress {
    spouseInd: boolean;
    dateOfMarriage?: Date;
    dateOfRegistered?: Date;
    spouseSurchargeTriState: string; // Either 'Y', 'N', 'H'
    questionTwoInd: boolean;
    questionThreeInd: boolean;
    questionFourInd: boolean;
    questionFiveInd: boolean;
    questionSixInd: boolean;
    medicareNumber: boolean;
    memberId: string | null;
    subscriberMemberId: string;
    relationshipTypeName: string; // combo of relationship type and relationship qualify reason
    socialSecurityNumber: string;
    firstName: string;
    lastName: string;
    middleName: string;
    suffix: string;
    birthDate: Date;
    birthSex: string;
    genderIdentity: string;
    medicalCoverInd: boolean;
    dentalCoverInd: boolean;
    visionCoverInd: boolean;
    terminateAllCoverageInd: boolean
    medicalContinueCoverageInd: boolean;
    medicalDoNotContinueCoverage: boolean;
    medicalTerminateCoverage: boolean;
    medicalTerminateCoverageDate: Date;
    medicalTerminateCoverageReason: string;
    dentalContinueCoverageInd: boolean;
    dentalDoNotContinueCoverage: boolean;
    dentalTerminateCoverage: boolean;
    dentalTerminateCoverageDate: Date;
    dentalTerminateCoverageReason: string;
    visionContinueCoverageInd: boolean;
    visionDoNotContinueCoverage: boolean;
    visionTerminateCoverage: boolean;
    visionTerminateCoverageDate: Date;
    visionTerminateCoverageReason: string;
    coveredByOtherMedicalInd: boolean;
    coveredByOtherVisionDate: Date;
    coveredByOtherVisionInd: boolean;
    coveredByOtherMedicalDate: Date;
    coveredByOtherDentalInd: boolean;
    coveredByOtherDentalDate: Date;
    coveredByOtherSSDInd: boolean;
    coveredByOtherSSDDate: Date;
    coveredByOtherPEBBAccountInd: boolean;
    tobaccoUseInd: boolean;
    spouseForm: SelfPayFormSpouse;
    surchargeNA: boolean;
    partnershipStartDate ?: Date;
    memberMedicare: MemberMedicare;
    isSpouse: boolean = false;
    addressSameAsSubscriber: boolean = false;
    memberEthnicities;
    spouseAttestQ1: boolean = false;
    spouseAttestQ2: boolean = false;
    spouseAttestQ3: boolean = false;
    spouseAttestQ4: boolean = false;
    spouseAttestQ5: boolean = false;
    spouseAttestQ6: boolean = false;
    residentialAddress: BaseAddress;
    medicarePartAInd: boolean = false;
    medicarePartBInd: boolean = false;
    medicarePartADate: Date;
    medicarePartBDate: Date;
    confirmed = false;
    constructor(selfPayFormSpouse?: any) {
      super(selfPayFormSpouse);
      this.confirmed = selfPayFormSpouse.confirmed || false;
      this.spouseInd = selfPayFormSpouse.spouseInd || undefined;
      this.dateOfMarriage = selfPayFormSpouse.dateOfMarriage ? new Date(selfPayFormSpouse.dateOfMarriage) : null;
      this.dateOfRegistered = selfPayFormSpouse.dateOfRegistered ? new Date(selfPayFormSpouse.dateOfRegistered) : null;
      this.spouseSurchargeTriState = selfPayFormSpouse.spouseSurchargeTriState || null;
      this.questionTwoInd = selfPayFormSpouse.questionTwoInd || undefined;
      this.questionThreeInd = selfPayFormSpouse.questionThreeInd || undefined;
      this.questionFourInd = selfPayFormSpouse.questionFourInd || undefined;
      this.questionFiveInd = selfPayFormSpouse.questionFiveInd || undefined;
      this.questionSixInd = selfPayFormSpouse.questionSixInd || undefined;
      this.medicareNumber = selfPayFormSpouse.medicareNumber ? selfPayFormSpouse.medicareNumber : selfPayFormSpouse?.memberMedicare?.medicareNumber || null;
      this.memberId = selfPayFormSpouse.memberId || null;
      this.subscriberMemberId = selfPayFormSpouse.subscriberMemberId || null;
      this.relationshipTypeName = selfPayFormSpouse.relationshipTypeName || selfPayFormSpouse.relationshipToSubscriber?.relationshipType?.relationshipTypeName || null;
      this.socialSecurityNumber = selfPayFormSpouse.socialSecurityNumber || null;
      this.firstName = selfPayFormSpouse.firstName || null;
      this.middleName = selfPayFormSpouse.middleName || null;
      this.lastName = selfPayFormSpouse.lastName || null;
      this.suffix = selfPayFormSpouse.suffix || null;
      this.birthDate = selfPayFormSpouse.birthDate ? new Date(selfPayFormSpouse.birthDate) : null;
      if (selfPayFormSpouse.memberId) {
        this.birthSex = !(selfPayFormSpouse.birthSex instanceof BirthSex) ? selfPayFormSpouse.birthSex : selfPayFormSpouse.birthSex?.birthSexCode;
        this.genderIdentity = !(selfPayFormSpouse.genderIdentity instanceof GenderIdentity) ? selfPayFormSpouse.genderIdentity : selfPayFormSpouse.genderIdentity?.genderIdentityCode;
      } else {
        this.birthSex = selfPayFormSpouse.birthSex || null;
        this.genderIdentity = selfPayFormSpouse.genderIdentity || null;
      }
      this.medicalCoverInd = selfPayFormSpouse.medicalCoverInd || undefined;
      this.dentalCoverInd = selfPayFormSpouse.dentalCoverInd || undefined;
      this.visionCoverInd = selfPayFormSpouse.visionCoverInd || undefined;
      this.terminateAllCoverageInd = selfPayFormSpouse.terminateAllCoverageInd;
      this.medicalContinueCoverageInd = selfPayFormSpouse.medicalContinueCoverageInd || false;
      this.medicalDoNotContinueCoverage = selfPayFormSpouse.medicalDoNotContinueCoverage || false;
      this.medicalTerminateCoverage = selfPayFormSpouse.medicalTerminateCoverage || false;
      this.medicalTerminateCoverageDate = selfPayFormSpouse.medicalTerminateCoverageDate ? new Date(selfPayFormSpouse.medicalTerminateCoverageDate) : null;
      this.medicalTerminateCoverageReason = selfPayFormSpouse.medicalTerminateCoverageReason || null;
      this.dentalContinueCoverageInd = selfPayFormSpouse.dentalContinueCoverageInd || false;
      this.dentalDoNotContinueCoverage = selfPayFormSpouse.dentalDoNotContinueCoverage || false;
      this.dentalTerminateCoverage = selfPayFormSpouse.dentalTerminateCoverage || false;
      this.dentalTerminateCoverageDate = selfPayFormSpouse.dentalTerminateCoverageDate ? new Date(selfPayFormSpouse.dentalTerminateCoverageDate) : null;
      this.dentalTerminateCoverageReason = selfPayFormSpouse.dentalTerminateCoverageReason || null;
      this.visionContinueCoverageInd = selfPayFormSpouse.visionContinueCoverageInd || false;
      this.visionDoNotContinueCoverage = selfPayFormSpouse.visionDoNotContinueCoverage || false;
      this.visionTerminateCoverage = selfPayFormSpouse.visionTerminateCoverage || false;
      this.visionTerminateCoverageDate = selfPayFormSpouse.visionTerminateCoverageDate ? new Date(selfPayFormSpouse.visionTerminateCoverageDate) : null;
      this.visionTerminateCoverageReason = selfPayFormSpouse.visionTerminateCoverageReason || null;
      this.coveredByOtherMedicalInd = selfPayFormSpouse.coveredByOtherMedicalInd || false;
      this.coveredByOtherMedicalDate = selfPayFormSpouse.coveredByOtherMedicalDate ? new Date(selfPayFormSpouse.coveredByOtherMedicalDate) : null;
      this.coveredByOtherVisionInd = selfPayFormSpouse.coveredByOtherVisionInd || false;
      this.coveredByOtherVisionDate = selfPayFormSpouse.coveredByOtherVisionDate ? new Date(selfPayFormSpouse.coveredByOtherMedicalDate) : null;    
      this.coveredByOtherDentalInd = selfPayFormSpouse.coveredByOtherDentalInd || false;
      this.coveredByOtherDentalDate = selfPayFormSpouse.coveredByOtherDentalDate ? new Date(selfPayFormSpouse.coveredByOtherDentalDate) : null;
      this.coveredByOtherSSDInd = selfPayFormSpouse.coveredByOtherSSDInd || false;
      this.coveredByOtherSSDDate = selfPayFormSpouse.coveredByOtherSSDDate ? new Date(selfPayFormSpouse.coveredByOtherSSDDate) : null;
      this.coveredByOtherPEBBAccountInd = selfPayFormSpouse.coveredByOtherPEBBAccountInd || false;
      this.tobaccoUseInd = selfPayFormSpouse.tobaccoUseInd || undefined;
      this.memberMedicare = selfPayFormSpouse.memberMedicare || new MemberMedicare({});
      this.surchargeNA = (selfPayFormSpouse.memberMedicare?.medicarePartAEnrolledInd && selfPayFormSpouse.memberMedicare?.medicarePartBEnrolledInd) || selfPayFormSpouse.surchargeNA;
      this.partnershipStartDate = selfPayFormSpouse.partnershipStartDate;
      this.isSpouse = this.relationshipTypeName?.indexOf("Spouse")>-1;
      this.spouseSurchargeTriState = selfPayFormSpouse.spouseSurchargeTriState;
      this.addressSameAsSubscriber = selfPayFormSpouse.addressSameAsSubscriber;
      this.memberEthnicities = selfPayFormSpouse.memberEthnicities;
      this.spouseAttestQ1 = selfPayFormSpouse.spouseAttestQ1;
      this.spouseAttestQ2 = selfPayFormSpouse.spouseAttestQ2;
      this.spouseAttestQ3 = selfPayFormSpouse.spouseAttestQ3;
      this.spouseAttestQ4 = selfPayFormSpouse.spouseAttestQ4;
      this.spouseAttestQ5 = selfPayFormSpouse.spouseAttestQ5;
      this.spouseAttestQ6 = selfPayFormSpouse.spouseAttestQ6;
      this.residentialAddress = selfPayFormSpouse.residentialAddress ? new MemberAddress(selfPayFormSpouse.residentialAddress) : null;
      this.medicareNumber = selfPayFormSpouse.medicareNumber ? selfPayFormSpouse.medicareNumber : selfPayFormSpouse?.memberMedicare?.medicareNumber || null;
      this.medicarePartAInd = selfPayFormSpouse.medicarePartAInd;
      this.medicarePartBInd = selfPayFormSpouse.medicarePartBInd;
      this.medicarePartBDate = selfPayFormSpouse.medicarePartBDate;
      this.medicarePartADate = selfPayFormSpouse.medicarePartADate;
    }
  }
  
  