import { map } from 'lodash';
import DocumentType from './documentType';

export default class RelationshipQualifyReason {
  relationshipQualifyReasonId: string;
  relationshipQualifyReasonCode: string;
  relationshipQualifyReasonName: string;
  managedByHCAInd: boolean;
  needsCertificationInd: boolean;
  documentTypes: DocumentType[];
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(relationshipQualifyReason?) {
    if (relationshipQualifyReason) {
      this.relationshipQualifyReasonId = relationshipQualifyReason.relationshipQualifyReasonId;
      this.relationshipQualifyReasonCode = relationshipQualifyReason.relationshipQualifyReasonCode;
      this.relationshipQualifyReasonName = relationshipQualifyReason.relationshipQualifyReasonName;
      this.managedByHCAInd = relationshipQualifyReason.managedByHcaInd;
      this.needsCertificationInd = relationshipQualifyReason.needsCertificationInd;
      this.documentTypes = map(relationshipQualifyReason.documentTypes, (dt) => new DocumentType(dt));
      this.obsoleteFlag = relationshipQualifyReason.obsoleteFlag;
      this.obsoleteDate = relationshipQualifyReason.obsoleteDate ? new Date(relationshipQualifyReason.obsoleteDate) : null;
    }
  }
}
