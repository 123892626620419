<form #verifyForm="ngForm" #formContainer>
  <div>
    <div style="margin-left: 12px;">
      <div fxLayout="row wrap">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="flex-end" class="mgbt-medium mgtp-medium">
          <checkbox-list
            [name]="'approveDeny' + rowIndex"
            [disabled]="isDisabled"
            [multi]="false"
            orientation="horizontal"
            style="width: 85%;"
            [(ngModel)]="dataItem.approvedInd"
            (ngModelChange)="updatedVerificationStatus()"
            [items]="{
                'Verify': true,
                'Deny': false,
                'Pending': null
              }"
            ></checkbox-list>
        </div>

        <div fxLayout="row" fxFlex="100" *ngIf="!isDisabled && dataItem.approvedInd !== null">
          <div class="form-group col-md-6" *ngIf="dataItem.approvedInd === true" required>
            <label for="relationshipVerificationStatusId">Verification status<span class="k-required">*</span></label>
            <select
              required
              class="form-control"
              name="relationshipVerificationStatusId"
              id="relationshipVerificationStatusId"
              [(ngModel)]="dataItem.relationshipVerificationStatusId"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let verificationStatus of (verifyValues | isObsolete)"
                [ngValue]="verificationStatus.relationshipVerificationStatusId"
              >
                {{ verificationStatus.relationshipVerificationStatusName }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6" *ngIf="dataItem.approvedInd === false" required>
            <label for="relationshipVerificationStatusId">Verification status<span class="k-required">*</span></label>
            <select
              required
              class="form-control"
              name="relationshipVerificationStatusId"
              id="relationshipVerificationStatusId"
              [(ngModel)]="dataItem.relationshipVerificationStatusId"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let verificationStatus of (denyValues | isObsolete)"
                [ngValue]="verificationStatus.relationshipVerificationStatusId"
              >
                {{ verificationStatus.relationshipVerificationStatusName }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-6" *ngIf="dataItem.approvedInd === true">
            <date-picker
              #verifyDate="ngModel"
              name="verifyDate"
              label="Verify date"
              [canBeInFuture]="false"
              enforceLowerLimit
              [agencyCode]="dataItem?.subscriberMember?.agencyCode"
              required
              [(ngModel)]="dataItem.approvalDate"></date-picker>
          </div>
          <div class="form-group col-md-6" *ngIf="dataItem.approvedInd === false">
            <date-picker
              #denyDate="ngModel"
              name="denyDate"
              label="Denied date"
              [canBeInFuture]="false"
              enforceLowerLimit
              [agencyCode]="dataItem?.subscriberMember?.agencyCode"
              required
              [(ngModel)]="dataItem.deniedDate"></date-picker>
          </div>
        </div>
        <div class="mgtp-small" fxLayout="row" fxLayoutAlign="center" fxFlex="100" *ngIf="!isDisabled && dataItem.approvedInd !== null && dataItem.relationshipVerificationStatusId !== noDocumentId;">
          <div class="form-group col-md-6" required>
            <label for="documentTypeId">Document type<span class="k-required">*</span></label>
            <select required class="form-control" id="documentTypeId" name="documentTypeId" [(ngModel)]="dataItem.documentTypeId">
              <option [ngValue]="null"></option>
              <option
                *ngFor="let documentType of (dataItem.relationshipQualifyReason.documentTypes | isObsolete)"
                [ngValue]="documentType.documentTypeId"
              >
                {{ documentType.documentTypeName }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="!isDisabled" class="form-row mgtp-medium mgbt-medium" style="width:100%;" fxLayoutAlign="center">
          <button
            type="button"
            class="btn hca-green-background"
            style="margin-right:20px;"
            (click)="pushSaveVerification(formContainer)"
          >
            Submit changes
          </button>
          <button type="button" class="btn" (click)="handleCancel()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
