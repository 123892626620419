<div fxFlexFill fxLayoutAlign="center center" class="card state">
  <div class="card-body">
    <div fxLayout="column" class="card-title">
      <fa-icon class="hca-fontawesome-icon" [icon]="icons.faTasks" size="3x"></fa-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <div *ngIf="step === 0" fxLayoutAlign="center center">
        <h2>Tobacco use premium surcharge</h2>
      </div>
      <div *ngIf="step === 1" fxLayoutAlign="center center">
        <h2>Legal notice</h2>
      </div>
      <div fxLayout="column" *ngIf="step === 0">
        <h3 class="h4">
          Attest to whether the tobacco use premium surcharge applies to you by checking the appropriate box. Then, select Confirm and Next buttons to submit.
        </h3>
        <div fxLayout="column" class="fxGap-large info-container">
          <div class="mgbt-medium-lg" fxLayout="row" fxLayoutAlign="space-between">
            <div class="h5">
              <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/surcharges">Learn about this surcharge</a> before you make your attestation.
              <br />
              <br />
              PEBB Program requires a $25-per-account premium surcharge in addition to your monthly medical premium if you or an enrolled dependent (age 13 or older) uses a tobacco product. Tobacco use is defined as any use of tobacco products within the past two months. It does not include the religious or ceremonial use of tobacco. If you check Yes in this section you will be charged the $25 premium surcharge.    
              <br />
              <br />
              If a provider finds that ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information in the <a href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-rules-and-policies" target="_blank" >PEBB Program Administrative Policy 91-1</a>.
              <br />
              <br />
              Note: Enrolled dependents age 12 and younger are automatically defaulted to NO. You do not need to attest when the dependent turns age 13 unless the dependent uses, or starts using, tobacco products.
            </div>
          </div>
          <div class="mgbt-medium-lg" fxLayout="column" fxLayoutAlign="space-between">
            <h4>
              How to report a change in tobacco use
            </h4>
            <div>
              You can report a change in tobacco use anytime if: 
            </div>
            <ul>
              <li>You or any dependent age 13 and older starts using tobacco products.</li>
              <li>You or your enrolled dependent have not used tobacco products within the past two months.</li>
              <li>You or your enrolled dependent who is age 18 or older and uses tobacco products enrolls in the free tobacco cessation program through your PEBB medical plan. </li>
              <li>Your enrolled dependent who is age 13 to 17 and uses tobacco products accesses the tobacco cessation resources on the <a target="_blank" href="https://teen.smokefree.gov/">SmokefreeTeen website</a>.</li>
            </ul>
          </div>
        </div>
        <!-- accordion -->
        <div *ngIf="step === 0" fxLayoutAlign="center center">
          <h2>Select the member to update</h2>
        </div>
        <form #attestationForm="ngForm">
          <ngb-accordion (panelChange)="panelChange($event)" fxFlex="79" activeIds="hca0" [destroyOnHide]="false" [closeOthers]="true" 
            class="mgtp-medium-lg" #acc="ngbAccordion">
            <ngb-panel [id]="'hca' + index" *ngFor="let member of filteredMembers; let index = index">
              <ng-template ngbPanelHeader let-opened="opened">
                <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0" fxLayout="row">
                  <fa-icon class="hca-not-complete" style="margin-right: 15px" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                  <fa-icon class="hca-complete" style="margin-right: 15px" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                  <div fxLayout="row" fxFlex="90" fxLayoutAlign="space-between">
                    <span>
                      {{ member.fullName }}
                    </span>
                    <span> Tobacco use: {{ getAttestationResponseText(member) }} </span>
                  </div>
                </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div fxLayoutAlign="space-around" fxLayout="row">
                  <div fxLayout="row" class="info-container" style="width: 400px;">
                    <div class="h4 no-mg">
                      Does the tobacco use premium surcharge apply to this 
                      <ng-container *ngIf="member.isSubscriberInd">subscriber</ng-container>
                      <ng-container *ngIf="!member.isSubscriberInd">dependent</ng-container>? 
                    </div>
                  </div>
                  <div fxFlex="40" fxLayout="column" fxLayoutAlign="center space-around">
                    <yes-no
                      [name]="'usesTobacco' + index"
                      style="padding-left: 75px; padding-right: 75px; margin-bottom: 30px;"
                      [disabled]="isReadOnly"
                      [isTobaccoAttestation]="true"
                      [isTobaccoAttestationSubscriber]="member.isSubscriberInd"
                      [isSebb] = "subscriber.isSebb"
                      [orientation]="'vertical-list'"
                      yesLabel="Yes, I am subject to the $25 premium surcharge. "
                      noLabel="No, I am not subject to the $25 premium surcharge. This dependent has not used tobacco in the past two months or has enrolled in their PEBB medical plan’s tobacco cessation program (if age 18 or older), or has accessed information or resources on the SmokefreeTeen website (if under age 18)."
                      [(ngModel)]="member.tobaccoAttestationInd"
                      (ngModelChange)="updateTobacco($event, member.memberId)"
                      ></yes-no>
                    <div class="form-group" fxFlex="25">
                      <date-picker
                        #attDate="ngModel"
                        [name]="'attestationDate' + member.memberId"
                        label="Date of Change"
                        required
                        [disabled]="isReadOnly"
                        [canBeInFuture]="false"
                        (ngModelChange)="showNextButton = true"
                        [(ngModel)]="member.mostRecentTobaccoAttestation.attestationDate"></date-picker>
                      <div *ngIf="attDate.invalid && (attDate.touched || attDate.dirty)" class="alert alert-danger">Date of Change is required and cannot be in the future.</div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </form>
      </div>
      <div *ngIf="step === 1">
        <div fxLayout="column" class="fxGap-large">
          <div class="card">
            <div class="card-body info-container mgbt-medium">
              <ul>
                <li>
                  I declare that the information I have provided is true, complete, and correct If it isn't, or if I do not provide timely, updated information, the subscriber will owe surcharges to
                  the PEBB Program.
                </li>
                <li>
                  I declare that one (or more) of the attestation event(s) said prior occurred that requires an attestation change to the premium surcharges, and that I am reporting it within the PEBB
                  Program’s deadlines.
                </li>
                <li>I am replacing all PEBB Premium Surcharge Attestation Change Forms, enrollment form attestations, and electronic attestations previously submitted.</li>
                <li>
                  I understand that changes that result in a premium surcharge will begin the first day of the month after the status change. If that day is the first of the month, the change to the
                  surcharge begins on that day.
                </li>
                <li>
                  I understand that changes that result in removing a premium surcharge will begin the first day of the month after receipt of the attestation. If that day is the first of the month,
                  the change to the surcharge begins on that day.
                </li>
                <li>
                  If the subscriber pays the monthly premiums by pension deduction or electronic debit service, I authorize the Department of Retirement Systems or Health Care Authority to deduct any
                  premium surcharges owed from these accounts.
                </li>
              </ul>
              <p>
                HCA's privacy notice: We will keep your information private as allowed by law. See Our
                <a target="_blank" href="https://www.hca.wa.gov/about-hca/notice-privacy-practices">privacy notice</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="step === 1" class="info-container">
        <div>
          <div fxLayout="column" class="fxGap-large">
            <p>Based on current attestations, the subscriber {{ usesTobacco ? 'WILL' : 'WILL NOT' }} pay the $25 tobacco use surcharge each month in addition to subscriber's premium.</p>
          </div>
        </div>
        <div *ngIf="step === 1">
          <div fxLayout="row" class="fxGap-large">
            <div class="mgbt-medium-lg" fxLayout="column" fxLayoutAlign="space-between">
              <p>
                Generally, changes which result in adding or removing a surcharge will take effective the month following the status change. Changes received on the first day of the month will be
                effective that month. Changes made during annual open enrollment will be effective January 1 of the following plan year.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mgtp-large" fxLayout="row" [fxLayoutAlign]="step > 0 ? 'space-between' : 'flex-end'">
      <button *ngIf="step === 1" (click)="step = 0" class="btn btn-primary">Previous</button>
      <button [disabled]="isReadOnly" *ngIf="showNextButton && step !== 1 && (!subscriber?.lossOfEligibilityDate || isActiveSelfPay)" (click)="next()" class="btn btn-primary">Next</button>
      <button *ngIf="step === 1" (click)="saveAttestations()" class="btn btn-primary">Submit and return to dashboard</button>
    </div>
    <ngb-alert role="alert" class="alert alert-danger" *ngIf="showNextButton && (subscriber.lossOfEligibilityDate && !isActiveSelfPay)">Changes to enrollments or eligibility are not permitted on a terminated account.</ngb-alert>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
      <button (click)="navToDashboard()" class="btn btn-secondary mgtp-medium-lg">Back to dashboard</button>
    </div>
  </div>
</div>
