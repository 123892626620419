import { DatePickerComponent } from 'src/app/modules/shared/components/datePicker/datePicker.component';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as dayjs from 'dayjs';
import { filter, map, get, find, cloneDeep, sortBy, some } from 'lodash';
import { ControlContainer, NgForm } from '@angular/forms';
import { CoreService } from 'src/app/services/core.service';
import SelfPayOrigin from 'src/app/models/selfPayOrigin';
import Country from 'src/app/models/country';
import Reason from 'src/app/models/reason';
import SelfPay from 'src/app/models/selfPay';
import SelfPayPaymentType from 'src/app/models/selfPayPaymentType';
import Organization from 'src/app/models/organization';
import MemberType from 'src/app/models/memberType';
import { env } from 'src/env/development';
import CobraQualifyReason from 'src/app/models/cobraQualifyReason';

@Component({
  selector: 'selfpay-enrollment-eligibility',
  templateUrl: 'selfpay.enrollment.eligibility.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class SelfpayEnrollmentEligibilityComponent implements OnInit {
  @Input() selfPayOrigin: SelfPayOrigin;
  @Input() disabled = false;
  @Input() lookups = {
    selfPayVerificationStatus: [],
    documentTypes: [],
    selfPayProcessStatus: [],
    selfPayPaymentTypes: [],
    genderIdentities: [],
    birthSexes: [],
    county: [],
    country: [],
    addressType: [],
    homeAgencies: [],
    memberTypes: [],
    eligibililtyReasons: [],
    enrollmentReasons: [],
    originalAgencies: [],
    cobraQualifyReasons: [],
    reason: [],
    organizations: []
  };
  homeAgencyOrganizations: Organization[];
  selectableMemberTypes: MemberType[];
  selfPayMemberTypes: MemberType[];
  eligibilityReasons: Reason[];
  icons = {
    faCalendar
  };
  env = env;
  cobraMemberTypeIds: string[] = [];
  minElectionPeriodDate: Date;
  maxElectionPeriodDate: Date;
  payMethodDId: string;
  retirementIds: string[] = [];
  allSelfPayTypeIds: string[] = [];
  homeAgencies: Organization[];
  activeEmpRetireeMemberTypeCodes: string[] = ['R', 'K'];
  electionPeriodEndEnabled = false;
  retirementEndRequired = false;
  cobraLWOPTypesEnabled = true;
  selectedHomeAgency: Organization;

  @ViewChild('selfpayEligForm') public selfPayEligForm: NgForm;
  @ViewChild('cobraSelfPayEndDate') public cobraSelfPayEndDate: DatePickerComponent;
  @ViewChild('retirementDate') public retirementDate: DatePickerComponent;
  
  constructor(private coreService: CoreService) {
  }

  ngOnInit(): void {
    this.lookups.selfPayPaymentTypes = this.lookups.selfPayPaymentTypes.filter(o=>o.pmaPaymentTypeCode!=='U');
    this.lookups.organizations = sortBy(this.lookups.organizations, (o: Organization) => o.organizationCode);
    this.lookups.cobraQualifyReasons = sortBy(this.lookups.cobraQualifyReasons, (o: CobraQualifyReason) => o.cobraQualifyReasonCode);
    this.homeAgencies = filter(this.lookups.organizations, (o) => o.homeAgencyInd && o.organizationCode!='107 DEP' && o.organizationCode!='107 CLM');
    const dayjsStartDate = dayjs(this.selfPayOrigin.agencyEffectiveStartDate);
    const dayjsEndDate = dayjs().add(180, 'day');
    this.payMethodDId = get(find(this.lookups.selfPayPaymentTypes, (pt: SelfPayPaymentType) => pt.selfPayPaymentTypeCode === 'D'), 'selfPayPaymentTypeId');
    this.minElectionPeriodDate = dayjsStartDate.toDate();
    this.maxElectionPeriodDate = dayjsEndDate.toDate();
    this.cobraMemberTypeIds = map(filter(this.lookups.memberTypes, (mt) => mt.memberTypeName.includes('COBRA')), 'memberTypeId');
    this.eligibilityReasons = this.lookups.reason.filter((r) => r.eligibilityReasonInd || r.reasonCode === '10');
    this.retirementIds = map(filter(this.lookups.memberTypes, (mt) => env.retireeMemberTypes.includes(mt.memberTypeCode)), 'memberTypeId');
    this.selfPayMemberTypes = this.lookups.memberTypes;
    this.allSelfPayTypeIds = map(this.lookups.memberTypes, 'memberTypeId');
    this.selectableMemberTypes = cloneDeep(this.selfPayMemberTypes);
    if (this.selfPayOrigin.organizationId) {
      this.agencyChange(false);
      this.eligibilityTypeChange();
    }
  }

  resetForFormValidation() {
    this.cobraSelfPayEndDate.resetValue();
    this.retirementDate.resetValue();
  }

  public markAllControlsAsTouched(): void {
    for (const i in this.selfPayEligForm.controls) {
      if (this.selfPayEligForm.controls[i]) {
        this.selfPayEligForm.controls[i].markAsTouched();
      }
    }
  }

  public eligibilityTypeChange() {
    if (this.selfPayOrigin.organizationId) {
      // this.electionPeriodEndEnabled = this.activeEmpRetireeMemberTypeCodes
      //   .indexOf(this.lookups.memberTypes.find(o=>o.memberTypeId==this.selfPayOrigin.memberTypeId).memberTypeCode) === -1;
        
      const selAgency = this.homeAgencies.find(o => o.organizationId === this.selfPayOrigin.organizationId);
      if (selAgency.organizationCode.startsWith("124")) {
        this.selfPayOrigin.selfPayPaymentTypeId = this.payMethodDId;
        this.electionPeriodEndEnabled = false;
      } else {
        this.electionPeriodEndEnabled = true;
      }
    } else {
      this.electionPeriodEndEnabled = false;
    }

    if (!this.electionPeriodEndEnabled){
      this.selfPayOrigin.electionPeriodEndDate = null;
    }

    if(this.selfPayOrigin.selfPayPaymentTypeId === this.payMethodDId &&
      !this.retirementIds.includes(this.selfPayOrigin.memberTypeId)) {
        this.selfPayOrigin.selfPayPaymentTypeId = null;
    }
  }

  public agencyChange(resetOrganization): void {
    this.retirementEndRequired = false;
    const selAgency = this.homeAgencies.find(o => o.organizationId === this.selfPayOrigin.organizationId);
    this.selectedHomeAgency = new Organization(selAgency);
    if (selAgency?.organizationMemberTypes && selAgency.organizationMemberTypes.length > 0) {
      const selAgencyMemberTypes = map(selAgency.organizationMemberTypes, 'memberTypeCode');
      this.selectableMemberTypes = cloneDeep(this.selfPayMemberTypes).filter(o => selAgencyMemberTypes.some(a => a === o.memberTypeCode));

      if (this.selfPayOrigin.selfPayPaymentTypeId) {
        const selectedPaymentTypeCode: SelfPayPaymentType = find(this.lookups.selfPayPaymentTypes,(spt:SelfPayPaymentType)=>spt.selfPayPaymentTypeId === this.selfPayOrigin.selfPayPaymentTypeId);
        if (selectedPaymentTypeCode.pmaPaymentTypeCode === 'P' &&  !selAgency.organizationCode.startsWith("124")) { 
          //switching to a home agency other than 124 and pension deduction payment method is selected, reset payment method
          this.selfPayOrigin.selfPayPaymentTypeId = null;
        }
      }
    } else {
      this.selectableMemberTypes = cloneDeep(this.selfPayMemberTypes).filter((m: MemberType) => !env.sebbMemberTypeCodes.includes(m.memberTypeCode));
    }

    if (some(this.selectableMemberTypes, (m: MemberType) => this.retirementIds.some(r=>r === m.memberTypeId))) {
      this.retirementEndRequired = true;
    }

    if (this.selectableMemberTypes.find(o => o.memberTypeId === this.selfPayOrigin.memberTypeId) === null) {
      this.selfPayOrigin.memberTypeId = null;
    }

    if (resetOrganization) { this.selfPayOrigin.originatingOrganizationId = null; }
    this.homeAgencyOrganizations = sortBy(filter(this.lookups.organizations, (o: Organization) => (selAgency?.organizationSubAgencies.some(a=>a.agency?.agencyCode === o.agency?.agencyCode) 
      || o.organizationCode === '107') && o.organizationCode!='107 DEP' && !o.homeAgencyInd), 'organizationCode');

    this.eligibilityTypeChange();
  }
}
