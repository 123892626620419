// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import SimpleSubscriber from '../models/simpleSubscriber';
import SubscriberAccountCorrection from '../models/subscriberAccountCorrection';
import Enrollment from '../models/enrollment';
import MemberWaiver from '../models/memberWaiver';
import MemberNote from '../models/memberNote';
import Relationship from '../models/relationship';
import MemberMemberType from '../models/memberMemberType';
import Attestation from '../models/attestation';
import { CommonService } from './common.service';
import { CoreService } from './core.service';
import { env } from '../../env/development';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  searchSubscribers(query: string, includeDeactivated: boolean = false, organizationId?: string, agencyFilter?: string,
                    selfPayOnly?: boolean, includeDependents: boolean = false): Observable<SimpleSubscriber[]> {
    const search = { QueryString: query, IncludeDeactivated: includeDeactivated, IncludeDependents: includeDependents };
    if (organizationId) {
      return this.http
        .post<SimpleSubscriber[]>(`${env.apiUrl}/Member/search/${organizationId}`, search)
        .pipe(map((x) => this.commonService.createObjects(SimpleSubscriber, x)))
        .pipe(catchError(this.commonService.handleError));
    } else {
      return this.http
        .post<SimpleSubscriber[]>(`${env.apiUrl}/Member/search?agencyFilter=${agencyFilter ?? ''}${selfPayOnly ? '&selfPayOnly=true' : ''}`, search)
        .pipe(map((x) => this.commonService.createObjects(SimpleSubscriber, x)))
        .pipe(catchError(this.commonService.handleError));
    }
  }

  updateLiveWork(b: boolean): Observable<boolean> {
    return this.http
      .put<boolean>(`${env.apiUrl}/admin/liveWork`, b)
      .pipe(map((x) => this.commonService.createObjects(Boolean, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getAccountCorrectionForSubscriber(subscriberId: string): Observable<SubscriberAccountCorrection> {
    return this.http
      .get<SubscriberAccountCorrection>(`${env.apiUrl}/accountcorrection/${subscriberId}`)
      .pipe(map((x) => this.commonService.createObject(SubscriberAccountCorrection, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deactivateSubscriber(subscriberId: string): Observable<SubscriberAccountCorrection> {
    return this.http
      .put(`${env.apiUrl}/accountcorrection/${subscriberId}/deactivate`, {})
      .pipe(map((x) => this.commonService.createObject(SubscriberAccountCorrection, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createEnrollment(memberId: string, enrollment: Enrollment): Observable<Enrollment> {
    return this.http
      .post<Enrollment>(`${env.apiUrl}/accountcorrection/${memberId}/enrollments`, enrollment)
      .pipe(map((x) => this.commonService.createObject(Enrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateEnrollment(memberId: string, enrollment: Enrollment): Observable<Enrollment> {
    return this.http
      .put<Enrollment>(`${env.apiUrl}/accountcorrection/${memberId}/enrollments/${enrollment.enrollmentId}`, enrollment)
      .pipe(map((x) => this.commonService.createObject(Enrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteEnrollment(memberId: string, enrollmentId: string): Observable<Enrollment> {
    return this.http
      .delete<Enrollment>(`${env.apiUrl}/accountcorrection/${memberId}/enrollments/${enrollmentId}`)
      .pipe((res) => res)
      .pipe(catchError(this.commonService.handleError));
  }

  createMemberWaiver(memberId: string, memberWaiver: MemberWaiver): Observable<MemberWaiver> {
    return this.http
      .post<MemberWaiver>(`${env.apiUrl}/accountcorrection/${memberId}/waivers`, memberWaiver)
      .pipe(map((x) => this.commonService.createObject(MemberWaiver, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMemberWaiver(memberId: string, memberWaiver: MemberWaiver): Observable<MemberWaiver> {
    return this.http
      .put<MemberWaiver>(`${env.apiUrl}/accountcorrection/${memberId}/waivers/${memberWaiver.memberWaiverId}`, memberWaiver)
      .pipe(map((x) => this.commonService.createObject(MemberWaiver, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteWaiver(memberId: string, memberWaiverId: string): Observable<MemberWaiver> {
    return this.http
      .delete<MemberWaiver>(`${env.apiUrl}/accountcorrection/${memberId}/waivers/${memberWaiverId}`)
      .pipe((res) => res)
      .pipe(catchError(this.commonService.handleError));
  }

  createMemberMemberType(memberMemberType: MemberMemberType): Observable<MemberMemberType> {
    return this.http
      .post<MemberMemberType>(`${env.apiUrl}/accountcorrection/${memberMemberType.memberId}/memberMemberType`, memberMemberType)
      .pipe(map((x) => this.commonService.createObject(MemberMemberType, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMemberMemberType(memberMemberType: MemberMemberType): Observable<MemberMemberType> {
    return this.http
      .put<MemberMemberType>(`${env.apiUrl}/accountcorrection/${memberMemberType.memberId}/memberMemberType/${memberMemberType.memberMemberTypeId}`, memberMemberType)
      .pipe(map((x) => this.commonService.createObject(MemberMemberType, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteMemberMemberType(memberMemberType: MemberMemberType): Observable<MemberMemberType> {
    return this.http
      .delete<MemberMemberType>(`${env.apiUrl}/accountcorrection/${memberMemberType.memberId}/memberMemberType/${memberMemberType.memberMemberTypeId}`)
      .pipe((res) => res)
      .pipe(catchError(this.commonService.handleError));
  }

  createMemberNote(memberNote: MemberNote): Observable<MemberNote> {
    return this.http
      .post<MemberNote>(`${env.apiUrl}/members/${memberNote.memberId}/note`, memberNote)
      .pipe(map((x) => this.commonService.createObject(MemberNote, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateRelationship(relationship: Relationship): Observable<Relationship> {
    return this.http
      .put<Relationship>(`${env.apiUrl}/accountcorrection/${relationship.subscriberMemberId}/relationship/${relationship.relationshipId}`, relationship)
      .pipe(map((x) => this.commonService.createObject(Relationship, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteRelationship(relationship: Relationship): Observable<Relationship> {
    return this.http
      .delete<Relationship>(`${env.apiUrl}/accountcorrection/${relationship.subscriberMemberId}/relationship/${relationship.relationshipId}`)
      .pipe((res) => res)
      .pipe(catchError(this.commonService.handleError));
  }

  createAttestation(attestation: Attestation): Observable<Attestation> {
    return this.http
      .post<Attestation>(`${env.apiUrl}/accountcorrection/${attestation.memberId}/Attestation`, attestation)
      .pipe(map((x) => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateAttestation(attestation: Attestation): Observable<Attestation> {
    return this.http
      .put<Attestation>(`${env.apiUrl}/accountcorrection/${attestation.memberId}/Attestation/${attestation.attestationId}`, attestation)
      .pipe(map((x) => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteAttestation(attestation: Attestation): Observable<Attestation> {
    return this.http
      .delete<Attestation>(`${env.apiUrl}/accountcorrection/${attestation.memberId}/Attestation/${attestation.attestationId}`)
      .pipe((res) => res)
      .pipe(catchError(this.commonService.handleError));
  }

  searchDV(query: string, onlyShowPending: boolean): Observable<Relationship[]> {
    const search = { QueryString: query };
    return this.http
      .post<Relationship[]>(`${env.apiUrl}/Relationship/search?pending=${onlyShowPending}`, search)
      .pipe(map((x) => this.commonService.createObjects(Relationship, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getSyncErrorMonths(): Observable<number> {
    return this.http.get(`${env.apiUrl}/pay1sync/setting/errorMonths`)
    .pipe(catchError(this.commonService.handleError));
  }

  setSyncErrorMonths(value: number): Observable<void> {
    return this.http.post(`${env.apiUrl}/pay1sync/setting/errorMonths/${value}`, null)
    .pipe(catchError(this.commonService.handleError));
  }

  getFeatureFlag(name: string): Observable<boolean> {
    return this.http.get(`${env.apiUrl}/featureFlag/${name}`)
      .pipe(catchError(this.commonService.handleError));
  }

  getFeatureFlagEffectiveDate(name: string): Observable<Date> {
    return this.http.get(`${env.apiUrl}/featureFlag/${name}/date`)
      .pipe(catchError(this.commonService.handleError));
  }

  setFeatureFlag(name: string, value: boolean): Observable<void> {
    return this.http.post(`${env.apiUrl}/featureFlag/${name}/${value}`, null)
      .pipe(catchError(this.commonService.handleError));
  }
}
