import { ReportDisplayComponent } from './../../../reports/components/display/report.display.component';
import { faUsers, faHeart, faClipboard, faPlus, faCheck, faUpload, faUmbrella, faStaffSnake, faLungs } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { OnChanges } from '@angular/core';
// ng
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';

// ext
import { map, maxBy, filter, get, find, cloneDeep } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import SystemUser from 'src/app/models/user';
import { AccessLevel, CoreService } from 'src/app/services/core.service';
import { AuthService } from 'src/app/services/auth.service';
import Subscriber from 'src/app/models/subscriber';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Milestone from '../../../../models/milestone';
import { sortBy } from 'lodash';
import { env } from 'src/env/development';

@UntilDestroy()
@Component({
  selector: 'pebb-wizard',
  templateUrl: 'wizard.component.html',
  styles: [

  ],
  providers: [],
})
export class WizardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() subscriber: Subscriber;
  @Input() isCompleteOrOptedOut ? = false;
  @Input() isSOE = false;
  @Input() enrollmentPeriod: EnrollmentPeriod;
  @Input() modifiedMilestoneNameOrder: string[] = null;
  @Input() enabledMilestoneSteps: string[] = null;
  authService: AuthService;
  systemUser: SystemUser;
  selectedEnrollmentPeriod: EnrollmentPeriod;
  formattedMilestones = [];
  current = 0;
  maxMilestoneComplete;
  isReadOnly = false;
  wizardRoutingSubscription: Subscription;
  icons = {
    faUsers,
    faHeart,
    faClipboard,
    faCheck,
    faPlus,
    faUpload,
    faUmbrella,
    faStaffSnake,
    faLungs
  };
  constructor(private coreService: CoreService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.wizardRoutingSubscription = this.router.events.pipe(untilDestroyed(this)).subscribe((e: RouterEvent) => {
      if (e instanceof NavigationEnd) {
        this.setFormattedMilestones();
      }
    });
    this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);

    this.setFormattedMilestones();
  }

  ngOnChanges(): void {
    // This will trigger most likely due to a change to the subscriber object
    // this.setFormattedMilestones();
  }

  ngOnDestroy(): void {}

  activateStep(e): void {
    this.setFormattedMilestones();
  }

  getMaxMilestoneCompleted(milestones: Milestone[]): Milestone {
    return (
      maxBy(
        filter(milestones, (mi: Milestone) => mi.isComplete),
        'sortOrder'
      ) || sortBy(this.selectedEnrollmentPeriod.milestones, 'sortOrder')[0]
    );
  }

  optOut(bool: boolean): void {
    this.isCompleteOrOptedOut = bool || this.getMaxMilestoneCompleted(this.selectedEnrollmentPeriod.milestones).milestoneName === 'Confirmation';
  }

  setFormattedMilestones(): void {
    // this.selectedEnrollmentPeriod should be based on the subscriber's enrollmentPeriods since these will change when there are changes to milestone completions
    // this.enrollmentPeriod will not since it is a copy of one of the subscriber's enrollment periods.
    this.selectedEnrollmentPeriod = cloneDeep(this.subscriber.enrollmentPeriods.find(o => o.enrollmentPeriodId === this.enrollmentPeriod.enrollmentPeriodId));
    if (!this.selectedEnrollmentPeriod) {
      this.selectedEnrollmentPeriod = this.enrollmentPeriod;
    }

    if (this.modifiedMilestoneNameOrder) {
      this.selectedEnrollmentPeriod.milestones.forEach((m: Milestone) => m.sortOrder = this.modifiedMilestoneNameOrder.indexOf(m.milestoneName));
    }

    this.maxMilestoneComplete = this.getMaxMilestoneCompleted(this.selectedEnrollmentPeriod.milestones);
    this.formattedMilestones = map(sortBy(this.selectedEnrollmentPeriod.milestones, 'sortOrder'), (mi: Milestone) => ({
      label: mi.milestoneName,
      icon: env.milestoneMap[mi.milestoneName].icon,
      state: env.milestoneMap[mi.milestoneName].state + this.selectedEnrollmentPeriod.enrollmentPeriodId,
      disabled: ((mi.sortOrder > this.maxMilestoneComplete.sortOrder && !mi.completedDate) || this.isReadOnly),
      sort: mi.sortOrder,
    }));
    this.isCompleteOrOptedOut = this.maxMilestoneComplete.milestoneName === 'Confirmation';

    const selStep = this.formattedMilestones.find(o => this.router.url.indexOf(o.state) > -1);
    if (selStep) {
      this.current = this.formattedMilestones.indexOf(selStep);
      if (!this.isReadOnly) {
        // enable the current step and any previous steps
        selStep.disabled = false;
        this.formattedMilestones.filter(o => o.sort < selStep.sort).forEach(step => {
          step.disabled = false;
        });
      }
    }

    if (this.enabledMilestoneSteps?.length && this.enabledMilestoneSteps[0]!='All') {
      this.formattedMilestones.forEach(m => {
        if (!m.disabled && this.enabledMilestoneSteps.indexOf(m.milestoneName) === -1) {
           m.disabled = true;
        }
      });
    }
  }
}
