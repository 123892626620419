
export default class AuditHistoryWrapper<T> {
  timestamp: Date;
  attributes: any = {};
  wrappedEntity: T;

  constructor(constructorObject: { new (...args: any[]): T }, instance?) {
    if (instance) {
      this.timestamp = new Date(instance.timestamp);
      this.attributes = instance.attributes;
      this.wrappedEntity = this.activator(constructorObject, instance.wrappedEntity);
    }
  }

  private activator<T>(constructorObject: { new (...args: any[]): T }, responseData: any): T {
    return new constructorObject(responseData);
  }
}
