<div fxFlex fxFlexFill fxLayout="column" class="container-fluid">
  <div class="state" role="group">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body">
        <h2 class="mobile-text-centered mgtp-x-small mgbt-medium-sm" role="heading">Manage your account information</h2>
        <ngb-alert [dismissible]="false" [type]="'warning'">
          Please contact your personnel, payroll, or benefits office if you wish to change any non-editable information.
        </ngb-alert>
        <div class="card">
          <div class="card-body">
            <ngb-accordion [destroyOnHide]="false" #contact="ngbAccordion">
              <ngb-panel>
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span *ngIf="subscriber?.lastName && subscriber?.firstName">{{ subscriber.lastName }}, {{ subscriber.firstName }} (Self)</span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div fxLayout="column">
                    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
                      <div class="form-group required" fxFlex="30">
                        <label for="lastName">Last name</label>
                        <input [disabled]="true" required type="text" class="form-control" name="lastName" id="lastName" [ngModel]="subscriber.lastName" />
                      </div>
                      <div class="form-group required" fxFlex="30">
                        <label for="firstName">First name</label>
                        <input [disabled]="true" required type="text" class="form-control" name="firstName" id="firstName" [ngModel]="subscriber.firstName" />
                      </div>
                      <div class="form-group" fxFlex="15">
                        <label for="middleName">Middle name</label>
                        <input [disabled]="true" type="text" class="form-control" name="middleName" id="middleName" [ngModel]="subscriber.middleName" />
                      </div>
                      <div class="form-group required" fxFlex="20">
                        <label for="socialSecurityNumber">SSN</label>
                        <input
                          [disabled]="true"
                          required
                          type="text"
                          class="form-control"
                          name="socialSecurityNumber"
                          id="socialSecurityNumber"
                          [ngModel]="subscriber.socialSecurityNumber"
                        />
                      </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlex="100">
                      <div class="form-group" fxFlex="10">
                        <label for="suffix">Suffix</label>
                        <input [disabled]="true" type="text" class="form-control" id="suffix" name="suffix" [ngModel]="subscriber.suffix" placeholder="JR, SR" />
                      </div>
                      <div class="form-group required" fxFlex="15">
                        <date-picker
                          #birthDate="ngModel"
                          name="birthDate"
                          label="Birth date"
                          required
                          disabled
                          hideIcon
                          [(ngModel)]="subscriber.birthDate"></date-picker>
                      </div>
                      <div class="form-group required" fxFlex="20">
                        <label for="birthSexId">Sex assigned at birth</label>
                        <select [disabled]="true" required class="form-control" id="birthSexId" name="birthSexId" [ngModel]="subscriber.birthSexId">
                          <option></option>
                          <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
                        </select>
                      </div>
                      <div class="form-group required" fxFlex="20">
                        <label for="genderIdentityId">Gender Identity</label>
                        <select [disabled]="true" required class="form-control" id="genderIdentityId" name="genderIdentityId" [ngModel]="subscriber.genderIdentityId">
                          <option></option>
                          <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
                        </select>
                      </div>
                      <div class="form-group" fxFlex="25">
                        <span>
                          Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law. To learn more, visit HCA's website at
                          <a target="_blank" href="https://www.hca.wa.gov/about-hca/gender-identity-information">hca.wa.gov/gender-x.</a>
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!-- Contact Information -->
              <ngb-panel>
                <ng-template ngbPanelHeader let-opened="opened">
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                      <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                      <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                      Contact information
                      <span class="float-right">
                        <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faAt" size="2x"></fa-icon>
                        <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faPhone" size="2x"></fa-icon>
                        <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faMobileAlt" size="2x"></fa-icon>    
                      </span>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div fxFlex="0" fxFlex.gt.sm="10"></div>
                  <div fxFlexLayout="column" fxFlex="100" fxFlex.gt-sm="75" fxFlexLayoutAlign="center center">
                    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly">
                      <div class="form-group" fxFlexFill>
                        <h6><b>Phone numbers are used by HCA and health plan carriers to contact subscribers to resolve issues and provide customer support.</b></h6>
                      </div>
                      <div class="form-group" fxFlex="45" fxFlex.lt-md="95">
                        <label for="email">Email</label>
                        <input
                          [disabled]="isRead"
                          pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                          type="email"
                          maxlength="50"
                          class="form-control"
                          id="emailAddress"
                          name="emailAddress"
                          [(ngModel)]="subscriberContact.emailAddress"
                          [required]="optedInToEmail"
                          placeholder="Email"
                        />
                      </div>
                      <div fxFlex="45" class="form-group">
                        <label for="cellPhoneNumber">Cell phone number</label>
                        <input [disabled]="isRead" pattern="[0-9]*" minlength="10" maxlength="10" type="text" class="form-control" 
                        name="cellPhoneNumber" id="cellPhoneNumber" [(ngModel)]="subscriberContact.cellPhoneNumber" />
                      </div>
                      <div fxFlex="45" class="form-group">
                        <label for="homePhoneNumber">Home phone number</label>
                        <input [disabled]="isRead" pattern="[0-9]*" minlength="10" maxlength="10" type="text" class="form-control" 
                        name="homePhoneNumber" id="homePhoneNumber" [(ngModel)]="subscriberContact.homePhoneNumber" />
                      </div>
                      <div fxFlex="45" class="form-group">
                        <label for="workPhoneNumber">Work phone number</label>
                        <input [disabled]="isRead" pattern="[0-9]*" minlength="10" maxlength="10" type="text" class="form-control" 
                        name="workPhoneNumber" id="workPhoneNumber" [(ngModel)]="subscriberContact.workPhoneNumber" />
                      </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.lt-md="column">
                      <div class="form-group" fxFlexFill>
                        <div class="k-form-field bigger">
                          <checkbox
                            #emailNotificationInd="ngModel"
                            name="emailNotificationInd"
                            [disabled]="isRead"
                            [(ngModel)]="optedInToEmail"
                            (change)="emailSubscriptionChanged()">
                            {{optInLabel}}
                          </checkbox>
                        </div>
                      </div>
                    </div>
                      <terms-and-conditions *ngIf="showTermsAndConditions" [agencyCode]="subscriber.agencyCode" [submitTermsTrueValue]="true" 
                        (submitTerms)="respondToTermsAndConditions($event)"></terms-and-conditions>
                      <div class="form-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.lt-md="column" class="mgtp-medium-lg">
                        <button [disabled]="showTermsAndConditions" (click)="submitSubscriberContact()" fxFlex="30" id="sub-add-submit-btn" type="submit" class="btn btn-primary">Submit</button>
                        <button fxFlex="30" id="sub-add-cancel-btn" (click)="refreshSubscriberContact()" class="btn btn-light">
                          <fa-icon class="hca-fontawesome-icon hca-dashboard-icon" [icon]="icons.faUndo"></fa-icon>Clear changes
                        </button>
                      </div>
                  </div>
                  <div fxFlex="0" fxFlex.gt.sm="10"></div>
                </ng-template>
              </ngb-panel>
              <!--Residential Address-->
              <ngb-panel>
                <ng-template ngbPanelHeader  let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Residential address
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faHome" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <address [readOnly]="true" #residentialAddress view="panel" name="residentialAddress" label="Residential address" [(ngModel)]="subscriber.residentialAddress"></address>
                </ng-template>
              </ngb-panel>
              <!-- Mailing Address -->
              <ngb-panel>
                <ng-template ngbPanelHeader  let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Mailing address
                    <span class="float-right">
                      <fa-icon class="hca-fontawesome-icon" style="margin-right:15px;" [icon]="icons.faHome" size="2x"></fa-icon>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <address [readOnly]="true" #residentialAddress view="panel" name="residentialAddress" label="Residential address" [(ngModel)]="subscriber.mailingAddress"></address>
                </ng-template>
              </ngb-panel>
              <ngb-panel *ngIf="isSubscriber && displayRaceEthnicity">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                    <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    Medicare advantage plan supplemental demographic information
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <race-ethnicity (saveMemberEthnicities)="saveMemberEthnicities($event)" [ethnicities]="ethnicities" [member]="subscriber"></race-ethnicity>
                </ng-template>
              </ngb-panel>              
            </ngb-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
