import Rate from './rate';

export default class Response {
  responseId: string;
  responseCode: string;
  responseName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;
  rate?: Rate;

  constructor(response?) {
    if (response) {
      this.responseId = response.responseId || null;
      this.responseCode = response.responseCode || null;
      this.responseName = response.responseName || null;
      this.obsoleteFlag = response.obsoleteFlag;
      this.obsoleteDate = response.obsoleteDate ? new Date(response.ObsoleteDate) : null;
      if (response.rate) {
      this.rate = new Rate({
        rateId: response.rate.rateId || null,
        rateAmt: response.rate.rateAmt || null
      });
    }
    }
  }
}
