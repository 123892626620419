<div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
    <ul fxFlexLayout="row" style="margin-left: 15px;" class="nav nav-tabs admin-tab-group">
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'dashboard/perspay/' + organization.organizationId">Admin Dashboard</strong></li>
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/relationshipVerification/perspay/' + organization.organizationId">Dependent Verification</strong></li>
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/specialOpenEnrollment/review/perspay/' + organization.organizationId">Special Enrollment Verification</strong></li>
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/subscriberManagement/' + organization.organizationId">Subscribers</strong></li>
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/access/perspay/' + organization.organizationId">Access</strong></li>
        <!-- <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/eligibility/' + organization.organizationId">Eligibility</strong></li> -->
        <!-- <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/billing/' + organization.organizationId">Billing</strong></li>
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/smarthealth/' + organization.organizationId">Smart Health</strong></li> -->
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/reports/' + organization.organizationId">Reports</strong></li>                                                
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/organizationProfile/' + organization.organizationId">Profile</strong></li>
        <!-- <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/enrollmentDocuments/' + organization.organizationId">Enrollment Docs</strong></li>
        <li class="nav-item"><strong style="cursor: pointer" class="nav-link" routerLinkActive="active" [routerLink]="'admin/fsadcap/' + organization.organizationId">FSA / DCAP</strong></li>                                                                 -->
    </ul>
    <span style="margin-right: 30px;">
        <div><strong>Currently managing:</strong></div> {{organization.organizationName}}
    </span>
</div>