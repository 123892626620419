export default class Reason {
  reasonId: string;
  reasonCode: string;
  reasonName: string;
  obsoleteFlag: string;
  obsoleteDate: Date;
  dependentInd: boolean;
  subscriberInd: boolean;
  eligibilityReasonInd: boolean;
  enrollmentReasonInd: boolean;
  terminationReasonInd: boolean;
  transferReasonInd: boolean;

  constructor(reason) {
    this.reasonId = reason.reasonId || null;
    this.reasonCode = reason.reasonCode || null;
    this.reasonName = reason.reasonName || null;
    this.obsoleteFlag = reason.obsoleteFlag;
    this.obsoleteDate = reason.obsoleteDate ? new Date(reason.obsoleteDate) : null;
    this.dependentInd = reason.dependentInd;
    this.subscriberInd = reason.subscriberInd;
    this.eligibilityReasonInd = reason.eligibilityReasonInd;
    this.enrollmentReasonInd = reason.enrollmentReasonInd;
    this.terminationReasonInd = reason.terminationReasonInd;
    this.transferReasonInd = reason.transferReasonInd;
  }

}
