import Ethnicity from './ethnicity';
import { map, filter } from 'lodash';

export default class MemberEthnicityComposite {
  memberId: string;
  firstName: string;
  lastName: string;
  hispanicEthnicities: string[];
  nonHispanicEthnicities: string[];
  

  constructor(member) {
    if (member) {
      this.memberId = member.memberId;
      this.firstName = member.firstName;
      this.lastName = member.lastName;
      this.hispanicEthnicities = map(filter(member.memberEthnicities, (me) => me.ethnicity.hispanicInd), 'ethnicityId');
      this.nonHispanicEthnicities = map(filter(member.memberEthnicities, (me) => !me.ethnicity.hispanicInd), 'ethnicityId');
    }
  }

}
