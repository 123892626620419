<div class="hca-document-header" *ngIf="documents" class="ie-height-s" fxLayout="column">
  <div fxLayout="row" fxFlex="100" *ngFor="let document of documents">
    <!-- Images -->
    <img
      *ngIf="document.documentImageObject && document.documentContentType !== 'pdf'"
      class="hca-document-image"
      ngxViewer
      #hcaViewer
      [viewerOptions]="viewerOptions"
      [src]="document.documentImageObject.src"
      [alt]="document.documentImageObject.alt"
      crossorigin="anonymous"
    />
    <fa-icon
      *ngIf="document.documentImageObject && document.documentContentType !== 'pdf'"
      class="hca-fontawesome-icon hca-document-icon"
      [icon]="icons.faImages"
      size="3x"
      id="ieVerificationIcon"
    ></fa-icon>
    <!-- Loading -->
    <span *ngIf="!document.documentImageObject" class="hca-document-placeholder">
      <div class="spinner-wrapper" style="position:inherit; display:flex">
        <div class="spinner-border" role="status" style="width: 2rem; height: 2rem;"><span class="sr-only"></span></div>
      </div>
    </span>
    <!-- PDFs -->
    <ng-template cdkPortal #pdfTemplateOverlay let-document="document">
      <div class="hca-document-overlay" style="height: 100%;">
        <div>
          <button type="button" class="btn hca-document-close" (click)="closeDoc()">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faTimesCircle"></fa-icon>
          </button>
        </div>
        <div
          class="cdk-overlay-pane hca-drag-doc"
          *ngIf="document.documentImageObject && document.documentContentType == 'pdf'"
        >
          <pdf-viewer
            cdkDrag
            cdkDragRootElement=".cdk-overlay-pane"
            [ngClass]="{
              'hca-document-placeholder': !document.showDocument,
              'hca-document-pdf-show': document.showDocument
            }"
            [src]="document.documentBlob"
            [show-all]="false"
            [page]="page"
            [zoom]="zoom"
            [rotation]="rotation"
          ></pdf-viewer>
        </div>
        <div class="hca-document-footer">
          <button type="button" class="btn hca-document-button" (click)="zoomDoc('add')">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faSearchPlus"></fa-icon>
          </button>
          <button type="button" class="btn hca-document-button" (click)="zoomDoc('minus')">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faSearchMinus"></fa-icon>
          </button>
          <button type="button" class="btn hca-document-button" (click)="resetDoc()">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faUndoAlt"></fa-icon>
          </button>
          <button type="button" class="btn hca-document-button" (click)="rotateDoc('left')">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faReply"></fa-icon>
          </button>
          <button type="button" class="btn hca-document-button" (click)="rotateDoc('right')">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faShare"></fa-icon>
          </button>
          <button type="button" class="btn hca-document-button" (click)="pagePrevious()">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faCaretLeft"></fa-icon>
          </button>          
          <button type="button" class="btn hca-document-button" (click)="pageNext()">
            <fa-icon class="hca-fontawesome-icon" [icon]="icons.faCaretRight"></fa-icon>
          </button>
        </div>
      </div>
    </ng-template>
    <fa-icon
      *ngIf="document.documentImageObject && document.documentContentType == 'pdf'"
      class="hca-fontawesome-icon hca-document-icon"
      [icon]="icons.faImages"
      size="3x"
    ></fa-icon>
    <span
      class="hca-document-placeholder"
      (click)="showDocument(document)"
      *ngIf="document.documentImageObject && document.documentContentType == 'pdf'"
    ></span>

    <div *ngIf="document.dateReceived && document.documentType" fxLayout="column" class="ie-height-xs" fxLayoutAlign="start start">
      <span><b>Type:</b> {{ document.documentType ? document.documentType.documentTypeName : '' }}</span>
      <span *ngIf="document.createdBy?.fullName"><b>Uploaded by:</b> {{ document.createdBy.fullName }}</span>
      <span><b>On:</b> {{ document.dateReceived | date: 'MM/dd/yyyy' }}</span>
      <ng-container *ngIf="associatedRelationship">
        <span><b>Status:</b> {{ associatedRelationship.simplifiedStatus }}</span>
        <span><b>On:</b> {{ (associatedRelationship.simplifiedStatus === 'Denied' ? associatedRelationship.deniedDate : associatedRelationship.approvalDate) | date: 'MM/dd/yyyy' }}</span>
        <span *ngIf="associatedRelationship.simplifiedStatus === 'Denied'"><b>Reason:</b> {{associatedRelationship.relationshipVerificationStatus.relationshipVerificationStatusName}}</span>
      </ng-container>
    </div>
    <div fxFlex fxLayoutAlign="end center" *ngIf="document.documentType && document.canDelete && canDeleteDocuments">
      <button type="button" (click)="confirmDeleteDoc(document.documentId)" alt="Delete Document" aria-label="Delete Document" title="Delete Document"
      class="k-button k-grid-edit-command k-button-md k-rounded-md k-button-solid-base k-button-solid" style="margin-right: 5px">
        <span class="pma-icon-button"><fa-icon [icon]="icons.faXmark"></fa-icon></span>
      </button>      
    </div>
  </div>
  <div style="padding-top: 10px;" *ngIf="associatedRelationship && associatedRelationship.simplifiedStatus === 'Denied'">
    <strong *ngIf="openEnrollment && openEnrollment?.openEnrollmentId && now >= openEnrollment?.effectiveStartDate && now <= openEnrollment?.effectiveEndDate">Please upload another valid document to provide proof of eligibility within the PEBB eligibility timelines.</strong>
    <strong *ngIf="openEnrollment && openEnrollment?.openEnrollmentId && (now <= openEnrollment?.effectiveStartDate || now >= openEnrollment?.effectiveEndDate)">Open Enrollment has ended. Please visit <a target="_blank" href="https://hca.wa.gov/about-hca/file-appeal-pebb" >https://hca.wa.gov/about-hca/file-appeal-pebb</a> to file an appeal</strong>
  </div>
</div>

<kendo-dialog
    title="Delete document?"
    *ngIf="deleteDocumentId"
    (close)="deleteDocumentId = null"
    [minWidth]="200"
    width="45vw"
    minHeight="200px"
    maxHeight="45vh"
  >
  <div fxFill fxLayout="column">
    This will delete the document.  Are you sure you want to do this?
  </div>
  <kendo-dialog-actions>
    <button class="btn btn-light" (click)="deleteDocumentId = null">Cancel</button>
    <button class="btn btn-primary" (click)="deleteDoc()" themeColor="primary">
      Delete Document
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
