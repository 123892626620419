import { CoreService } from './../services/core.service';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import SystemUser from '../models/user';
import { some } from 'lodash';
import UserOrganizationRole from '../models/userOrganizationRole';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<SystemUser> {
  constructor(private userService: UserService, private router: Router, private coreService: CoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SystemUser> {
    let id = route.paramMap.get('id');
    if (!id) {
      id = route.parent.paramMap.get('id');
    }
    return this.userService.getUserById(id).pipe(
      take(1),
      mergeMap(sub => {
        if (sub) {
          this.coreService.setSystemUser(sub);
          if (some(sub.userOrganizationRoles, (s: UserOrganizationRole) => s.userRoleName === 'HCA') && !this.router.url.includes('add') && !this.router.url.includes('subscriberManagement')) {
            this.coreService.setOrganization(null);
          }else if (some(sub.userOrganizationRoles, (s: UserOrganizationRole) => s.userRoleName === 'Perspay')) {
            const orgValue = this.coreService.getOrganizationValue();
            if (!orgValue || !orgValue.organizationId) {
              this.coreService.setOrganizationFromRole(sub.userOrganizationRoles[0]);
            }
          }
          return of(sub);
        } else {
          this.router.navigate(['/']);
          return EMPTY;
        }
      })
    );
  }

}
