import { map } from 'lodash';

export default class SelfPayType {
  selfPayTypeId: string;
  selfPayTypeName: string;
  selfPayTypeCode: string;

  managedByHCAInd: boolean;

  constructor(selfPayType?) {
    if (selfPayType) {
      this.selfPayTypeId = selfPayType.selfPayTypeId;
      this.selfPayTypeName = selfPayType.selfPayTypeName;
      this.selfPayTypeCode = selfPayType.selfPayTypeCode;  
    }
  }


}
