<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="mgbt-small">
    <h2 role="heading" class="mobile-text-centered mgtp-x-small mgbt-medium-sm">Special open enrollment events</h2>
</div>
<div>
<p>
    Certain life events let you change your benefits outside of annual open enrollment. For example, you move to a new county, get married, or have a child.
    We call these “special open enrollment” events. See 
    <span *ngIf="isSelfPay">
        <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/what-special-open-enrollment">What is special open enrollment?</a>
    </span>
    <span *ngIf="!isSelfPay">
        <a *ngIf="subscriber?.agencyCode === 'SEBB'" target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/special-open-enrollment">What is special open enrollment?</a>
        <a *ngIf="subscriber?.agencyCode !== 'SEBB'"target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/what-special-open-enrollment">What is special open enrollment?</a>
    </span>
    for more information. 
    <br />
    <br />
    The <span *ngIf="!isSelfPay">{{subscriber?.agencyCode}}</span><span *ngIf="isSelfPay">PEBB</span> Program only allows changes outside of annual open enrollment when an event creates a special open enrollment. The change must be allowed under the Internal Revenue Code and Treasury regulations and correspond to and be consistent with a special open enrollment event for you, your dependent, or both. 
    <br />
    <br />
    <span *ngIf="!isSelfPay">
        When enrolling a newly eligible state-registered domestic partner, children of the state-registered domestic partner, a newly eligible extended dependent, or other non-qualified tax dependent, you must submit a SEBB Declaration of Tax Status. You may not change your health outside of the annual open enrollment if your state- registered domestic partner or their child is not a tax dependent.
        <br />
        <br />
        You are required to provide proof of the event. The {{subscriber?.agencyCode}} Program must receive this request and proof of the event no later than 60 days after the event.
    </span>
    <span *ngIf="isSelfPay">
        You are required to provide proof of the event. The PEBB Program must receive this request and proof of the event no later than 60 days after the event.
        To disenroll from a Medicare Advantage plan or Medicare Advantage Prescription Drug (MAPD) plan, you must also submit the PEBB Medicare Advantage Disenrollment Form (form D).
        <br/><br/>
        The PEBB Program must receive your request and proof of the event no later than 60 days after the event occurs. Exceptions apply for new enrollment in a PEBB Medicare Supplement plan or Medicare Advantage plan.         
    </span>
</p>
</div>

