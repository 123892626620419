
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';
import { FileService } from 'src/app/services/file.service';
import SystemUser from 'src/app/models/user';
import SubmissionFileResponse from 'src/app/models/submissionFileResponse';
import SubmissionFile from 'src/app/models/submissionFile';
import Organization from 'src/app/models/organization';
import { maxBy, sortBy, chunk, find } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import Document from 'src/app/models/document';
import DocumentType from 'src/app/models/documentType';
import Carrier from 'src/app/models/carrier';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { lastValueFrom } from 'rxjs';
import { isConditionalTypeNode } from 'typescript';
import * as dayjs from 'dayjs';
import { DocumentService } from 'src/app/services/document.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { AccountCorrectionsService } from 'src/app/services/accountCorrections.service';
import UwUploadResponse from 'src/app/models/uwUploadResponse';
import HigherEdUploadResponse from 'src/app/models/higherEdUploadResponse';
import PgpKeys from 'src/app/models/pgpKeys';

@Lookups(LookupType.DocumentType, LookupType.Carrier834Carrier, LookupType.ApplicationSetting, LookupType.Response, LookupType.Organization)
@Component({
  selector: 'uw-sync-file',
  templateUrl: 'uwSyncFile.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class UwSyncFileComponent implements OnInit {
  allowedFileTypes: string[] = [ 'txt', 'csv' ];
  allowedSpreadsheetFileTypes: string[] = [ 'xls', 'csv', 'xlsx' ];
  isHca = false;
  batchJobs =
  {
    EndUnverifiedRelationships: 'End Unverified Relationships',
    TermCoverageExpiredCerts: 'Terminate Coverage for Dependents with Expired Certs',
    TermCoverageOver26: 'Terminate Coverage for Dependents Over 26 (Aged Out)',
    DefaultCoverage: 'Default Coverage',
    DefaultSpousalAttestations: 'Default Spousal Attestations',
    OrganizationDataDepotRetention: 'Data Depot Retention',
    AutoTerminateSelfPayAccounts: 'Auto Terminate Self Pay Accounts',
    TerminateSelfPayNoPayment: 'Term Self Pay No Payment',
    OpenEnrollmentSetup: 'Open Enrollment Setup',
    DownloadDailySmartHealthFile: 'Download Daily SmartHealth File'
  };
  batchJobsWithDate = [{
    path: 'DualEnrollment',
    title: 'NE Dual Enrollment',
    startDate: null,
    daysOffset: 32,
    offsetDate: null
  }];
  outputFiles =
  {
    PEBBLTDEligibility: { name: 'PEBB LTD Eligibility', extension: 'txt', isBatchJob: true, startDate: null, useStartDate: true, useAPIFileName: true },
    SEBBLTDEligibility: { name: 'SEBB LTD Eligibility', extension: 'txt', isBatchJob: true, startDate: null, useStartDate: true, useAPIFileName: true },
    'GenerateMetLifeFile/true': { name: 'PEBB MetLife Eligibility File', extension: 'txt', isBatchJob: false },
    'GenerateMetLifeFile/false': { name: 'SEBB MetLife Eligibility File', extension: 'txt', isBatchJob: false },
  };
  lookups: any;
  document: Document = new Document();
  downloadDocumentId: string;
  allCarriers = false;
  carrier: string;
  save834Output = false;
  audit834 = false;
  log834Generation = false;
  canAudit = false;
  syncSubscriber = true;
  syncDependent = false;
  logSyncGeneration = false;
  selectedOrganizationId = '';
  higherEdType: string;
  higherEdSampleFileOrgCode: string;
  higherEdSampleIncludeGender = false;
  uwSyncSelectedSubscriberFile: any;
  uwSyncSelectedDependentFile: any;
  uwLowerLimitDateOverride: Date;
  uwValidateOnly = false;
  fileToEncrypt;
  // keyFile;
  // privateKeyPassword;
  encryptWellness = true;
  pgpKeys: PgpKeys;
  pgpSign = true;
  uwSampleSubscriberId: string;
  usSampleSubscriberRecord: string;
  uwSampleDependentId: string;
  usSampleDependentRecord: string;
  syncErrorMonths: number;
  featureFlags: {[key: string]: {[key: string]: boolean}} = {
    sync: {
      enablePebbVision: false,
      enableSyncMedicareD: false,
      enableSubscriberMedicalDateCheck: false,
      enableSubscriberDentalDateCheck: false,
      enableSubscriberVisionDateCheck: false,
      enableSubscriberEligibilityDateCheck: false,
      enableSubscriberOrganizationCodeCheck: false,
    },
    ui: {
      enableMedicareD: false
    }
  }
  featureFlagFriendlyNames: {[key: string]: string} = {
    enableMedicareD: 'FEATURE_ENABLE_MEDICARE_D',
    enableSyncMedicareD: 'FEATURE_SYNC_ENABLE_MEDICARE_D',
    enablePebbVision: 'FEATURE_SYNC_ENABLE_PEBB_VISION',
    enableSubscriberMedicalDateCheck: 'FEATURE_SYNC_CHECK_SUBSCRIBER_MEDICAL_DATE',
    enableSubscriberDentalDateCheck: 'FEATURE_SYNC_CHECK_SUBSCRIBER_DENTAL_DATE',
    enableSubscriberVisionDateCheck: 'FEATURE_SYNC_CHECK_SUBSCRIBER_VISION_DATE',
    enableSubscriberEligibilityDateCheck: 'FEATURE_SYNC_CHECK_ELIGIBILITY_DATE',
    enableSubscriberOrganizationCodeCheck: 'FEATURE_SYNC_CHECK_ORGANIZATION_CODE',      
  }

  constructor(
    private route: ActivatedRoute,
    private fileService: FileService,
    private adminService: AdminService,
    private notificationService: NotificationService,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private documentService: DocumentService,
    private accountCorrectionsService: AccountCorrectionsService
  ) {
    // lastValueFrom(this.accountCorrectionsService.getAccount('36b5a690-5643-484f-bc6e-4d1504161212'))
    // .then((a: any) => {
    //   a.enrollments[0].effectiveStartDate = '2023-12-01T00:00:00';
    //   // a.enrollments[1].effectiveEndDate = '2023-10-30T00:00:00';
    //   // a.enrollments.pop();

    //   lastValueFrom(this.accountCorrectionsService.save('36b5a690-5643-484f-bc6e-4d1504161212', a));
    // });
  }

  async ngOnInit(): Promise<void> {
    this.isHca = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);

    this.lookups = this.route.snapshot.data.lookups;
    this.lookups.carrier = this.lookups.carriers = this.lookups.carrier834Carrier;
    this.lookups.documentTypes = sortBy(this.lookups.documentTypes, [ 'documentTypeName' ]);
    this.lookups.organization = sortBy(this.lookups.organizations, o => o.organizationName);
    this.batchJobsWithDate.forEach(j => this.setJobOffsetDate(j.path));

    const yesResponseId = this.lookups.responses.filter(r => r.responseCode === 'Y')[0].responseId;
    this.canAudit = this.lookups.applicationSettings
      .filter(a =>
        a.applicationSettingCode === 'CARRIER834AUDITIGNOREDATE' &&
        a.responseId === yesResponseId
      ).length > 0;

    this.syncErrorMonths = await lastValueFrom(this.adminService.getSyncErrorMonths());

    this.featureFlags.ui.enableMedicareD = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enableMedicareD));
    this.featureFlags.sync.enableSyncMedicareD = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enableSyncMedicareD));
    this.featureFlags.sync.enablePebbVision = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enablePebbVision));
    this.featureFlags.sync.enableSubscriberDentalDateCheck = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberDentalDateCheck));
    this.featureFlags.sync.enableSubscriberEligibilityDateCheck = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberEligibilityDateCheck));
    this.featureFlags.sync.enableSubscriberMedicalDateCheck = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberMedicalDateCheck));
    this.featureFlags.sync.enableSubscriberOrganizationCodeCheck = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberOrganizationCodeCheck));
    this.featureFlags.sync.enableSubscriberVisionDateCheck = await lastValueFrom(this.adminService.getFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberVisionDateCheck));
  }

  async downloadDocument(documentId: string): Promise<void> {
    const metadata = await lastValueFrom(this.fileService.downloadFileMetadata(documentId));
    await lastValueFrom(this.fileService.downloadFile(documentId, metadata.documentName));
  }

  setBlob(propertyName: string, e): void {
    this[propertyName] = e.files[0];
  }

  async encrypt(): Promise<void> {
    if (!this.fileToEncrypt) {
      this.coreService.popMessage("Select file to encrypt", 'error', 2000);
      return;
    }

    // if (!this.keyFile) {
    //   this.coreService.popMessage("Select public key file", 'error', 2000);
    //   return;
    // }

    await lastValueFrom(await this.documentService.pgpEncrypt(this.fileToEncrypt, this.pgpSign));
    this.coreService.popMessage(`You have successfully encrypted your file.`, 'success', 5000);
  }

  async decrypt(): Promise<void> {
    if (!this.fileToEncrypt) {
      this.coreService.popMessage("Select file to decrypt", 'error', 2000);
      return;
    }

    // if (!this.keyFile) {
    //   this.coreService.popMessage("Select private key file", 'error', 2000);
    //   return;
    // }

    // if (!this.privateKeyPassword) {
    //   this.coreService.popMessage("Provide private key password", 'error', 2000);
    //   return;
    // }

    await lastValueFrom(await this.documentService.pgpDecrypt(this.fileToEncrypt));
    this.coreService.popMessage(`You have successfully decrypted your file.`, 'success', 5000);
  }

  async generateKeys(): Promise<void> {
    this.pgpKeys = await lastValueFrom(await this.documentService.pgpGenerateKeys());
  }

  // async sign(): Promise<void> {
  //   if (!this.fileToEncrypt) {
  //     this.coreService.popMessage("Select file to sign", 'error', 2000);
  //     return;
  //   }

  //   if (!this.keyFile) {
  //     this.coreService.popMessage("Select private key file", 'error', 2000);
  //     return;
  //   }

  //   if (!this.privateKeyPassword) {
  //     this.coreService.popMessage("Provide private key password", 'error', 2000);
  //     return;
  //   }

  //   await lastValueFrom(await this.documentService.pgpSign(this.fileToEncrypt, this.keyFile, this.privateKeyPassword));
  //   this.coreService.popMessage(`You have successfully signed your file.`, 'success', 5000);
  // }

  async generalFileSelected(e): Promise<void> {
    const file = e.files[0];

    this.document.documentBlob = file.rawFile;

    const uploadedDocuments = await lastValueFrom(this.documentService.addDocumentAdmin(this.document));

    uploadedDocuments.forEach(u => {
      this.coreService.popMessage(`You have successfully uploaded your document (document_id = '${u.documentId}').`, 'success', 5000);
      console.log('Uploaded document with document_id', u.documentId);
    });

    this.document = new Document();
  }

  async pay1ResponseFile(isSuccessFile: boolean, e): Promise<void> {
    const documentTypeCode = isSuccessFile ? 'PAY1_SYNC_SUCC' : 'PAY1_SYNC_ERR';

    const file = e.files[0];

    this.document.documentTypeId = find(this.lookups.documentType, d => d.documentTypeCode === documentTypeCode).documentTypeId;
    this.document.documentBlob = file.rawFile;

    await lastValueFrom(this.fileService.processPay1ResponseFile(this.document));
  }

  async uwSyncFileUpload() {
    this.spinnerService.show();
    const subscriberFileContents = this.uwSyncSelectedSubscriberFile.rawFile;
    const subscriberReader = new FileReader();
    subscriberReader.readAsText(subscriberFileContents);
    subscriberReader.onloadend = async () => {
      const susbscriberResultsAsArray = subscriberReader.result.toString().split('\n');

      const dependentFileContents = this.uwSyncSelectedDependentFile.rawFile;
      const dependentReader = new FileReader();
      dependentReader.readAsText(dependentFileContents);
      dependentReader.onloadend = async () => {
        const dependentResultsAsArray= dependentReader.result.toString().split('\n');
        const response = await lastValueFrom(this.fileService.uploadUwSubscriberSyncFile(this.uwLowerLimitDateOverride, this.uwValidateOnly, susbscriberResultsAsArray, dependentResultsAsArray));

        if (response.errorFileId) {
          await lastValueFrom(this.fileService.downloadMergedFile(response.errorFileId, 'UW_ERROR.csv'));
        }

        if (response.auditFileId) {
          await lastValueFrom(this.fileService.downloadMergedFile(response.auditFileId, 'UW_AUDIT.csv'));
        }

        this.spinnerService.hide();
        this.coreService.popMessage(`UW file uploaded (error file should have downloaded, too).`, 'success', 5000);
      };
    };
  }

  async uwSyncFileUploadEOM() {
    this.spinnerService.show();
    const subscriberFileContents = this.uwSyncSelectedSubscriberFile.rawFile;
    const subscriberReader = new FileReader();
    subscriberReader.readAsText(subscriberFileContents);
    subscriberReader.onloadend = async () => {
      const susbscriberResultsAsArray = subscriberReader.result.toString().split('\n');

      const dependentFileContents = this.uwSyncSelectedDependentFile.rawFile;
      const dependentReader = new FileReader();
      dependentReader.readAsText(dependentFileContents);
      dependentReader.onloadend = async () => {
        const dependentResultsAsArray= dependentReader.result.toString().split('\n');
        const response = await lastValueFrom(this.fileService.uploadUwSubscriberSyncFileEOM(susbscriberResultsAsArray, dependentResultsAsArray));

        if (response.existingFileId) {
          await lastValueFrom(this.fileService.downloadMergedFile(response.existingFileId, 'UW_RECON_EXISTING.csv'));
        }

        if (response.inboundFileId) {
          await lastValueFrom(this.fileService.downloadMergedFile(response.inboundFileId, 'UW_RECON_INBOUND.csv'));
        }

        this.spinnerService.hide();
        this.coreService.popMessage(`UW file uploaded (error file should have downloaded, too).`, 'success', 5000);
      };
    };
  }


  async uwSyncFileSelected(isSubscriber: boolean, outputAdditionalUwErrorInfo: boolean, e): Promise<boolean> {
    if (isSubscriber) { this.uwSyncSelectedSubscriberFile = e.files[0];}
    else {this.uwSyncSelectedDependentFile = e.files[0];}
    return true;
  }

  async generateHigherEdSampleFile(agency: string, includeGender: boolean): Promise<void> {
    await lastValueFrom(this.fileService.getHigherEdFileSample(agency, includeGender));
  }

  async higherEdFileSelected(agency: string, e): Promise<boolean> {
    if (!agency || agency.length === 0) {
      this.coreService.popMessage('Select an agency.', 'error', 5000);
      return;
    }

    this.spinnerService.show();

    const file = e.files[0];
    const fileContents = file.rawFile;
    const reader = new FileReader();

    reader.readAsText(file.rawFile);
    reader.onloadend = async () => {
      const resultsAsArray = reader.result.toString().split('\n');
      const chunkSize = 33000;
      const chunks = chunk(resultsAsArray, chunkSize);
      const promises: Promise<HigherEdUploadResponse>[] = [];

      chunks.forEach((setOfRows, i) => {
        const beginningIndex = chunkSize * i;
        const promise = lastValueFrom(this.fileService.uploadHigherEdFile(agency, setOfRows));
        promises.push(promise);
      });

      await Promise.all(promises)
        .then(async responses =>
        {
          const errorGuids: string[] = responses.map(r => r.errorFileId);
          await lastValueFrom(this.fileService.downloadMergedFile(errorGuids, 'HIGHER_ED_ERROR.TXT'));

          const auditGuids: string[] = responses.map(r => r.auditFileId);
          await lastValueFrom(this.fileService.downloadMergedFile(auditGuids, 'HIGHER_ED_AUDIT.TXT'));

          const countGuids: string[] = responses.map(r => r.auditCountsFileId);
          await lastValueFrom(this.fileService.downloadMergedFile(countGuids, 'HIGHER_ED_COUNT.TXT'));

          const dupsGuids: string[] = responses.map(r => r.duplicatesFileId);
          await lastValueFrom(this.fileService.downloadMergedFile(dupsGuids, 'HIGHER_ED_DUPS.TXT'));
        });

      this.spinnerService.hide();
      this.coreService.popMessage(`Higher Ed file uploaded (error file should have downloaded, too).`, 'success', 5000);
    };

    return true;
  }

  async gap9(e): Promise<boolean> {
    this.spinnerService.show();

    const file = e.files[0];
    const fileContents = file.rawFile;
    const reader = new FileReader();

    reader.readAsText(file.rawFile);
    reader.onloadend = async () => {
      const records = reader.result.toString().split('\n');

      const response = await lastValueFrom(this.fileService.uploadGap9File(records));
      await lastValueFrom(this.fileService.downloadFile(response.extractFileId, 'ExtractGap9.TXT'));
      if(response.errorFileId) {
        await lastValueFrom(this.fileService.downloadFile(response.errorFileId, 'Gap9_error.csv'));
      }
      this.spinnerService.hide();
      this.coreService.popMessage('GAP9 file uploaded.', 'success', 5000);
    };

    return true;
  }

  // async higherEdFileSelected(agency: string, e): Promise<boolean> {
  //   if (!agency || agency.length === 0) {
  //     this.coreService.popMessage('Select an agency.', 'error', 5000);
  //     return;
  //   }

  //   this.spinnerService.show();

  //   const file = e.files[0];
  //   const fileContents = file.rawFile;
  //   const reader = new FileReader();

  //   reader.readAsText(file.rawFile);
  //   reader.onloadend = async () => {
  //     const resultsAsArray = reader.result.toString().split('\n');
  //     const chunkSize = 200;
  //     const chunks = chunk(resultsAsArray, chunkSize);
  //     const promises: Promise<HigherEdUploadResponse>[] = [];

  //     chunks.forEach((setOfRows, i) => {
  //       const beginningIndex = chunkSize * i;
  //       const promise = lastValueFrom(this.fileService.uploadHigherEdFile(agency, setOfRows));
  //       promises.push(promise);
  //     });

  //     await Promise.all(promises)
  //       .then(async responses =>
  //       {
  //         const errorGuids: string[] = responses.map(r => r.errorFileId);
  //         await lastValueFrom(this.fileService.downloadMergedFile(errorGuids, 'HIGHER_ED_ERROR.TXT'));

  //         const auditGuids: string[] = responses.map(r => r.auditFileId);
  //         await lastValueFrom(this.fileService.downloadMergedFile(auditGuids, 'HIGHER_ED_AUDIT.TXT'));

  //         const countGuids: string[] = responses.map(r => r.auditCountsFileId);
  //         await lastValueFrom(this.fileService.downloadMergedFile(countGuids, 'HIGHER_ED_COUNT.TXT'));

  //         const dupsGuids: string[] = responses.map(r => r.duplicatesFileId);
  //         await lastValueFrom(this.fileService.downloadMergedFile(dupsGuids, 'HIGHER_ED_DUPS.TXT'));
  //       });

  //     this.spinnerService.hide();
  //     this.coreService.popMessage(`Higher Ed file uploaded (error file should have downloaded, too).`, 'success', 5000);
  //   };

  //   return true;
  // }


  async naviaImportFileSelected(e): Promise<boolean> {
    this.spinnerService.show();

    const file = e.files[0];
    const fileContents = file.rawFile;

    await lastValueFrom(this.fileService.uploadNaviaFile(file));

    this.spinnerService.hide();
    this.coreService.popMessage('Navia file imported.', 'success', 5000);

    return true;
  }

  async wellnessExport(): Promise<boolean> {
    this.spinnerService.show();
    await lastValueFrom(this.fileService.wellnessExport(this.encryptWellness));

    this.spinnerService.hide();
    this.coreService.popMessage('Wellness file exported.', 'success', 5000);

    return true;
  }

  async cmsImportFileSelected(e): Promise<boolean> {
    this.spinnerService.show();

    const file = e.files[0];
    const fileContents = file.rawFile;
    const reader = new FileReader();

    reader.readAsText(file.rawFile);
    reader.onloadend = async () => {
      const resultsAsArray = reader.result.toString().split(/\r\n|\n\r|\r|\n/);
      const chunkSize = 200;
      const chunks = chunk(resultsAsArray, chunkSize);
      const records = [];

      chunks.forEach((setOfRows, i) => {
        setOfRows.forEach((r: string) => {
          if (r.trim() === '') {
            return;
          }

          // Check first char for header/trailer records; skip those
          const firstChar = r.substring(0, 1);
          if (firstChar === 'H' || firstChar === 'T') {
            return;
          }

          const hicn = r.substring(4, 16).trim();

          if (hicn) {
            const subscriberSsn = r.substring(226, 235);
            const dependentSsn = r.substring(174, 183);

            records.push({ subscriberSsn, dependentSsn, hicn });
          }
        });
      });

      await lastValueFrom(this.fileService.uploadCmsImportFile(records));

      this.spinnerService.hide();
      this.coreService.popMessage('CMS file imported.', 'success', 5000);
    };

    return true;
  }

  async billingFile(e): Promise<boolean> {
    this.spinnerService.show();

    const file = e.files[0];
    const fileContents = file.rawFile;

    await lastValueFrom(this.fileService.uploadBillingFile(file));

    this.spinnerService.hide();
    this.coreService.popMessage('Billing file uploaded.', 'success', 5000);

    return true;
  }

  async wellnessFile(e): Promise<boolean> {
    this.spinnerService.show();

    const file = e.files[0];
    const fileContents = file.rawFile;
    const reader = new FileReader();

    reader.readAsText(file.rawFile);
    reader.onloadend = async () => {
      const resultsAsArray = reader.result.toString().split('\n');
      const chunkSize = 200;
      const chunks = chunk(resultsAsArray, chunkSize);
      const records = [];

      chunks.forEach((setOfRows, i) => {
        setOfRows.forEach((r: string, index) => {
          if (index > 0) { // skip header row
            const values = r.split(',');

            const ssn = values[0];
            const groupNameAchieved = values[1];
            const achievedDate = dayjs(values[2]).toDate();
            const groupNameEOY = values[3];

            records.push({ SSN: ssn, GroupNameAchieved: groupNameAchieved, AchievedDate: achievedDate, GroupNameEOY: groupNameEOY });
          }
        });
      });

      await lastValueFrom(this.fileService.uploadWellnessFile(records));

      this.spinnerService.hide();
      this.coreService.popMessage('Wellness file uploaded.', 'success', 5000);
    };

    return true;
  }

  async initiateBatchJob(job: string): Promise<void> {
    this.spinnerService.show();

    await lastValueFrom(this.fileService.batchJob(job));

    this.spinnerService.hide();
    this.coreService.popMessage(`Batch job ran (${job}).`, 'success', 5000);
  }

  async initiateBatchJobWithDate(job: string, date): Promise<void> {
    this.spinnerService.show();
    await lastValueFrom(this.fileService.batchJobWithDate(job, date));
    this.spinnerService.hide();
    this.coreService.popMessage(`Batch job ran (${job}).`, 'success', 5000);
  }

  async generateOutputFile(endpoint: string, name: string, extension: string, isBatchJob: boolean, date = null, useAPIFileName: boolean = false): Promise<void> {
    this.spinnerService.show();

    await lastValueFrom(this.fileService.outputFile(endpoint, name, extension, isBatchJob, date, useAPIFileName));

    this.spinnerService.hide();
    this.coreService.popMessage('File generated.', 'success', 5000);
  }

  async saveToDisk(base64Data: string, name: string): Promise<void> {
    this.documentService.downloadDocument(atob(base64Data), name);
  }

  setJobOffsetDate(jobPath: string): void {
    const thisJob = this.batchJobsWithDate.find(o => o.path === jobPath);
    if (thisJob.daysOffset) {
      thisJob.offsetDate = thisJob.startDate !== null ? new Date(thisJob.startDate) : new Date();
      thisJob.offsetDate.setDate(thisJob.offsetDate.getDate() - thisJob.daysOffset);
    }
  }

  async generate834(allCarriers: boolean, carrier: string, save834Output: boolean, log834Generation: boolean, audit834: boolean): Promise<void> {
    this.spinnerService.show();

    const carriers = allCarriers ? this.lookups.carriers.map(c => c.carrierCode) : [ carrier ];

    for (let i = 0, carrierToProcess; carrierToProcess = carriers[i]; i++) {
      await lastValueFrom(this.fileService.generate834(carrierToProcess, audit834, save834Output, log834Generation));
    }

    this.spinnerService.hide();
    this.coreService.popMessage('File generated.', 'success', 5000);
  }

  async generateSyncFile(generateSubscriberFile: boolean, generateDependentFile: boolean, logSyncGeneration: boolean): Promise<void> {
    this.spinnerService.show();

    const endpointBase = 'SyncFileNoRecord';

    if (generateSubscriberFile) {
      const endpoint = `${endpointBase}/true${(logSyncGeneration ? '/true' : '')}`;
      await lastValueFrom(this.fileService.outputFile(endpoint, 'Subscriber PAY1 Sync File', 'txt', false, null));
    }

    if (generateDependentFile) {
      const endpoint = `${endpointBase}/false${(logSyncGeneration ? '/true' : '')}`;
      await lastValueFrom(this.fileService.outputFile(endpoint, 'Dependent PAY1 Sync File', 'txt', false, null));
    }

    this.spinnerService.hide();
    this.coreService.popMessage('File generated.', 'success', 5000);
  }

  syncDependentChange(syncDependent: boolean): void {
    if (syncDependent) {
      this.syncSubscriber = true;
    }
  }

  async externalEligibility(e): Promise<boolean> {
    this.spinnerService.show();

    const file = e.files[0];
    const reader = new FileReader();

    reader.readAsText(file.rawFile);
    reader.onloadend = async () => {
      const results = reader.result.toString();

      const obj = {
        record: results
      };

      try {
        await lastValueFrom(this.fileService.uploadExternalEligibilityRow(this.selectedOrganizationId, obj));
        this.coreService.popMessage('External Eligibility file uploaded.', 'success', 5000);
      } catch (err) {
        this.spinnerService.hide();
        this.coreService.popMessage('an error occurred', 'error', 4000);
      }
      this.spinnerService.hide();
    };

    return true;
  }

  async generateError(): Promise<void> {
    await lastValueFrom(this.fileService.generateError());
  }

  async generateUwSubSample(subscriberId: string): Promise<void> {
    this.usSampleSubscriberRecord = await lastValueFrom(this.fileService.generateUwSubSample(subscriberId));
  }

  async generateUwDepSample(dependentId: string): Promise<void> {
    this.usSampleDependentRecord = await lastValueFrom(this.fileService.generateUwDepSample(dependentId));
  }

  async setSyncErrorMonths(value: number): Promise<void> {
    await lastValueFrom(this.adminService.setSyncErrorMonths(value));
  }

  async saveSyncFeatureFlags(syncFlags: any): Promise<void> {
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enableSyncMedicareD, syncFlags.enableSyncMedicareD));
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enablePebbVision, syncFlags.enablePebbVision));
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberDentalDateCheck, syncFlags.enableSubscriberDentalDateCheck));
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberEligibilityDateCheck, syncFlags.enableSubscriberEligibilityDateCheck));
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberMedicalDateCheck, syncFlags.enableSubscriberMedicalDateCheck));
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberOrganizationCodeCheck, syncFlags.enableSubscriberOrganizationCodeCheck));
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enableSubscriberVisionDateCheck, syncFlags.enableSubscriberVisionDateCheck));
  }

  async saveUiFeatureFlags(uiFlags: any): Promise<void> {
    await lastValueFrom(this.adminService.setFeatureFlag(this.featureFlagFriendlyNames.enableMedicareD, uiFlags.enableMedicareD));
  }
}
