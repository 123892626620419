import SupplementalLTDBand from './supplementalLTDBands';

export default class SupplementalLTDBandDisplay {
  ageRange: string;
  percent50: string;
  amountPerThousand50: number;
  percent60: string;
  amountPerThousand60: number;
  ltdType: string;
  ltdTypeId: string;
  ltdTypeSortOrder = 0;

  constructor(supplementalLTDBand?: SupplementalLTDBand) {
    if (supplementalLTDBand) {
      this.ageRange = `${supplementalLTDBand.ageLowerLimit} ${supplementalLTDBand.ageUpperLimit === 200 ? 'and older' : 'to ' + supplementalLTDBand.ageUpperLimit}`;
      this.percent50 = `${(supplementalLTDBand.rate50 / 100).toFixed(4)}`;
      this.amountPerThousand60 = supplementalLTDBand.rate60 * 10;
      this.percent60 = `${(supplementalLTDBand.rate60 / 100).toFixed(4)}`;
      this.amountPerThousand50 = supplementalLTDBand.rate50 * 10;
      this.ltdType = supplementalLTDBand.ltdEnrollmentType.name;
      this.ltdTypeId = supplementalLTDBand.ltdEnrollmentType.ltdEnrollmentTypeId;
      this.ltdTypeSortOrder = supplementalLTDBand.ltdEnrollmentType.sortOrder;
    }
  }
}
