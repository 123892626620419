import SelfPay from 'src/app/models/selfPay';
import { faCalendar, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ControlContainer, NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { filter, cloneDeep } from 'lodash';
import SelfPayFormSpouse from 'src/app/models/selfPayFormSpouse';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import SelfPayType from 'src/app/models/selfPayType';
import { env } from 'src/env/development';
import * as dayjs from 'dayjs';

@Component({
  selector: 'self-pay-add-spouse',
  templateUrl: 'selfPay.addSpouse.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SelfPayAddSpouseComponent implements OnChanges, OnInit {
  @Input() selfPaySpouse: SelfPayFormDependent;
  @Input() pebbVisionDate: Date;
  @Input() idx: number;
  @Input() requestType: SelfPayType;
  @Input() selfPay: SelfPay;
  @Input() agency: string;
  @Output() submitDependent: EventEmitter<SelfPayFormDependent> = new EventEmitter<SelfPayFormDependent>();
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  currentMaxDate: Date;
  currentSpouse: SelfPayFormDependent;
  electMedicalDisabled = false;
  electDentalDisabled = false;
  icons = {
      faCalendar,
      faExclamationCircle
  };
  env = env;
  isCobra: boolean = false;
  isPebbLwopVision: boolean = false;
  isPebbRetireeVision: boolean = false;
  isCobraOE: boolean = false;
  thirteenYearsDate: Date;
  disableFormForNoCoverage = false;
  ngOnInit(): void {
    this.thirteenYearsDate = dayjs().subtract(13, 'year').toDate();
    this.currentMaxDate = new Date();
    this.isPebbLwopVision = this.agency === 'PEBB' && (((this.requestType.selfPayTypeCode === 'ALWOP' || this.requestType.selfPayTypeCode === 'SOEU') &&  this.selfPay?.coverageEffectiveDate ? dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(this.pebbVisionDate) : dayjs(new Date()).isSameOrAfter(this.pebbVisionDate)) || this.requestType.selfPayTypeCode === "OEU");
  }

  ngOnChanges() {
    this.isCobra = env.cobraSelfPayTypeCodes.includes(this.requestType.selfPayTypeCode);
    if (this.selfPaySpouse) {
      this.currentSpouse = cloneDeep(this.selfPaySpouse);
    }
    if (this.requestType.selfPayTypeCode === 'R'  || this.requestType.selfPayTypeCode === 'OER') {
      this.electMedicalDisabled = true;
      this.electDentalDisabled = true;
      if(this.selfPay?.formJson?.subscriber?.medicalEnrollInd || this.selfPay?.formJson?.subscriber?.dentalEnrollInd && !this.isPebbRetireeVision && !this.isCobraOE) {
        //dental enrolled means both dental and medical now
        this.currentSpouse.medicalCoverInd = true;
      }
      if(this.selfPay?.formJson?.subscriber?.dentalEnrollInd && !this.isPebbRetireeVision && !this.isCobraOE) {
        this.currentSpouse.dentalCoverInd = true;
      }

      if (this.requestType.selfPayTypeCode === 'R' && this.selfPay.formJson?.subscriber?.actionsCode === 'deferring' &&
          this.selfPay.formJson?.subscriber?.deferQuestionFour) {
          //initial retiree request is deferring due to medicaid, enable dependent coverage for medical only
          this.electMedicalDisabled = false;
          this.electDentalDisabled = true;
      }
    }
    this.isPebbLwopVision = this.agency === 'PEBB' && (((this.requestType.selfPayTypeCode === 'ALWOP' || this.requestType.selfPayTypeCode === 'SOEU')  &&  this.selfPay?.coverageEffectiveDate ? dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(this.pebbVisionDate) : dayjs(new Date()).isSameOrAfter(this.pebbVisionDate)) || this.requestType.selfPayTypeCode === "OEU");
    this.isPebbRetireeVision = this.agency === 'PEBB' && ((this.requestType.selfPayTypeCode === 'R' &&  (this.selfPay?.coverageEffectiveDate ? dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(this.pebbVisionDate) : dayjs(new Date()).isSameOrAfter(this.pebbVisionDate))) || this.requestType.selfPayTypeCode === "OER");
    this.isCobraOE = this.requestType.selfPayTypeCode === "OEC";
    if(this.isPebbRetireeVision){
      this.disableFormForNoCoverage = !this.currentSpouse.medicalCoverInd && !this.currentSpouse.dentalCoverInd && !this.currentSpouse.visionCoverInd
    }
  }

  emitSubmitDependent() {
    this.submitDependent.emit(this.currentSpouse);
  }

  cancelChanges() {
    this.currentSpouse = cloneDeep(this.selfPaySpouse);
    this.closePanel.emit();
  }

  updateTobacco() {
    if (this.currentSpouse.surchargeNA) {
      this.currentSpouse.tobaccoUseInd = null;
      this.currentSpouse.spouseForm.spouseSurchargeTriState = "N";
      this.currentSpouse.spouseForm.questionTwoInd = false;
      this.currentSpouse.spouseForm.questionThreeInd = false;
      this.currentSpouse.spouseForm.questionFourInd = false;
      this.currentSpouse.spouseForm.questionFiveInd = false;
      this.currentSpouse.spouseForm.questionSixInd = false;
    }
  }

  updateMedicarePartA(yes: boolean) {
    if(!yes) {
      this.currentSpouse.medicarePartADate = null;
    }
  }

  updateMedicarePartB(yes: boolean) {
    if(!yes) {
      this.currentSpouse.medicarePartBDate = null;
    }
    if(yes) {
      this.currentSpouse.visionCoverInd = false;
    }
  }

  handleCoverageChange(e) {
    if(e) {
      this.disableFormForNoCoverage = false;
    }else if(!this.currentSpouse.medicalCoverInd && !this.currentSpouse.dentalCoverInd && !this.currentSpouse.visionCoverInd) {
      this.disableFormForNoCoverage = true;
      this.resetFormValues();
    }
  }

  resetFormValues() {
    this.currentSpouse.medicarePartAInd =
    this.currentSpouse.medicarePartBInd =
    this.currentSpouse.spouseSurchargeTriState =
    this.currentSpouse.spouseAttestQ1 =
    this.currentSpouse.spouseAttestQ2 =
    this.currentSpouse.spouseAttestQ3 =
    this.currentSpouse.spouseAttestQ4 =
    this.currentSpouse.spouseAttestQ5 =
    this.currentSpouse.spouseAttestQ6 =
    this.currentSpouse.medicareNumber =
    this.currentSpouse.medicarePartADate =
    this.currentSpouse.medicarePartBDate =
    this.currentSpouse.tobaccoUseInd = null
  }

}
