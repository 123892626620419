<form #form="ngForm">
  <ng-template #labelTemplate>
    <label
      #labelEl
      *ngIf="labelVisible"
      [id]="name + '-label'"
      [for]="name + '-chk'"
      [style.fontWeight]="innerBold ? 'bold' : undefined"
      [class.disabled]="innerDisabled"
      [class.k-radio-label]="!innerRadioAsCheckbox && type === 'radio'"
      [class.k-checkbox-label]="innerRadioAsCheckbox || type === 'checkbox'"
      [class.colon]="labelColon === true || labelColon === ''"><ng-content></ng-content>{{label}}</label>
  </ng-template>

  <ng-template #componentTemplate>
    <input
      *ngIf="!innerRadioAsCheckbox && type === 'checkbox'"
      [id]="name + '-chk'"
      [name]="name + '-chk'"
      type="checkbox"
      kendoCheckBox
      class="k-checkbox"
      [required]="innerRequired"
      [checked]="checked"
      [readonly]="innerReadonly"
      [disabled]="innerDisabled"
      [attr.aria-labelledby]="name + '-label'"
      [value]="value"
      [(ngModel)]="model"
      (ngModelChange)="innerChange()"
      (blur)="blurred()"
    />
    <input
      *ngIf="innerRadioAsCheckbox || type === 'radio'"
      [id]="name + '-chk'"
      [name]="name + '-chk'"
      type="radio"
      kendoRadioButton
      [class.k-radio]="!innerRadioAsCheckbox"
      [class.k-checkbox]="innerRadioAsCheckbox"
      [required]="innerRequired"
      [readonly]="innerReadonly"
      [disabled]="innerDisabled"
      [attr.aria-labelledby]="name + '-label'"
      [value]="value"
      [(ngModel)]="model"
      (ngModelChange)="innerChange()"
      (blur)="blurred()"
      (click)="clicked()"
    />
  </ng-template>

  <div *ngIf="!labelFirst"
    class="form-group k-form-field"
    style="display:block;"
    [class.required]="innerRequired && !innerPartOfList"
    [fxLayoutAlign]="containerfxLayoutAlign ? containerfxLayoutAlign : inputFlex && labelFlex ? 'space-between' : undefined"
    [fxLayout]="containerFxLayout">
      <ng-container *ngTemplateOutlet="componentTemplate"></ng-container>
      <div [class.inheritMaxWidth]="labelInheritMaxWidth">
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
      </div>
  </div>

  <div *ngIf="labelFirst"
    class="form-group k-form-field"
    style="display:block;"
    [class.required]="innerRequired && !innerPartOfList"
    [fxLayoutAlign]="containerfxLayoutAlign ? containerfxLayoutAlign : inputFlex && labelFlex ? 'space-between' : undefined"
    [fxLayout]="containerFxLayout">
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="componentTemplate"></ng-container>
  </div>
</form>