import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import SimpleSubscriber from 'src/app/models/simpleSubscriber';
import { CoreService } from 'src/app/services/core.service';
import { env } from 'src/env/development';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'subscriber-management-nav',
  templateUrl: 'subscriberManagement.nav.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class SubscriberManagementNavComponent implements OnInit {
  @Input() member: SimpleSubscriber;
  @Input() isOE = false;
  @Input() showLOEState = false;
  @Input() isHCA = false;
  @Input() isSelfPay = false;
  soeLink: string = "";
  spPeriodComplete: boolean = false;
  public navMember;
  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private coreService: CoreService,
  ) {}

  ngOnInit(): void {
    this.coreService.getSubscriber().pipe(untilDestroyed(this)).subscribe(s => {
      if (s) {
        this.navMember = s
      } else {
        this.navMember = this.member;
      }
      this.spPeriodComplete = s.hasSelfPayPeriod();
      this.soeLink = (this.isSelfPay ? "selfPay/" : "soe/") + this.navMember.memberId;
    });
  }
}
