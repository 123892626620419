import { Component } from '@angular/core';

@Component({
  selector: 'forbidden',
  templateUrl: 'forbidden.component.html',
  styleUrls: [],
  providers: []
})
export class ForbiddenComponent {
  constructor() {}

}
