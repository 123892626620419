<div *ngIf="!isPebbRetireeOE && !isCobraOE" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body" fxFlexFill>
    <h2>Dental plan selection</h2>
    <span>Choose one dental plan. Before you enroll, make sure the provider you want to use accepts the specific plan and group you choose.</span>
    <br />
    <div *ngIf="isRetiree" fxLayoutAlign="space-between center" fxFlexFill fxLayout="column" class="h4">
      <a href="https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2024.pdf" target="_blank" class="">Click here to view the 2024 premiums</a>
    </div>
    <br/>
    <form fxLayoutAlign="space-between" fxFlexFill fxLayout="row" #electDentalForm="ngForm" (ngSubmit)="emitSelfPay(selfPay)" class="fxGap-medium">
      <div *ngIf="agency?.agencyCode === 'PEBB'" fxLayout="column" fxFlex="50">
        <div fxLayout="column">
          <h3 class="selfPay-form-small-header"><b>Preferred Provider Organization (PPO):</b></h3>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of groupedDentalPlans['Preferred Provider Organization (PPO)']; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'dentalPlan' + plan.planId" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selectedPlanId">
                  {{ plan.planName }}<br />
                  <span>
                    <span> You can choose any dental provider and change providers at any time. </span>
                    </span>
                </radio>
              </li>
            </ul>
          </div>
        </div>
        <div fxLayout="column">
          <h3 class="selfPay-form-small-header"><b>Managed-care plans (limited network):</b></h3>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of groupedDentalPlans['Managed-care plans (limited network)']; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'dentalPlan' + plan.planId" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selectedPlanId">
                  {{ plan.planName }}<br />
                  <span *ngIf="plan?.planCode == '4' || plan?.planCode == '4S'">
                    <span> You will select and receive services from a provider in the DeltaCare network.  </span>
                  </span>
                  <span *ngIf="plan?.planCode != '4' && plan?.planCode != '4S'">
                    <span> You will select and receive services from a provider in the Willamette Dental Group network. </span>
                  </span>
                </radio>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div fxFlex="45">
        <h3 class="h5">Helpful links:</h3>
        <div *ngIf="agency.agencyCode === 'PEBB'" fxLayoutAlign="start start" fxLayout="column">
          <a target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/compare-dental-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/compare-dental-plans'">Compare dental plans</a>
          <span ><a target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/find-pebb-plan-provider' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/find-pebb-plan-provider'">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
          <a target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/contact-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/contact-plans'">Plan contact information</a>
        </div>
        <div *ngIf="agency.agencyCode === 'SEBB'" fxLayoutAlign="start start" fxLayout="column">
          <a target="_blank" [href]="!isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/compare-dental-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/compare-dental-plans'">Compare dental plans</a>
          <span ><a target="_blank" [href]="!isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/find-pebb-plan-provider' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/find-sebb-plan-provider'">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
          <a target="_blank" [href]="!isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/contact-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/contact-plans'">Plan contact information</a>
        </div>
      </div>
      <div *ngIf="agency?.agencyCode === 'SEBB'" fxLayout="column" fxFlex="100">
        <div fxLayout="column">
          <h3 class="selfPay-form-small-header"><b>Dental Plans:</b></h3>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of sebbDentalPlans; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'dentalPlan' + plan.planId" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selectedPlanId">
                  {{ plan.planName }} 
                  <span *ngIf="plan.planName === 'Uniform Dental Plan'">(Group #09600)</span>
                  <span *ngIf="plan.planName === 'DeltaCare'">(Group #09601)</span>
                  <span *ngIf="plan.planName === 'Willamette Dental Group of Washington'">(Group WA 733)</span>
                </radio>
              </li>
            </ul>
          </div>
        </div>
      </div>      
    </form>
    <div *ngIf="this.selfPay?.formJson?.dentalPlan.planCoverageMessage" class="mgbt-medium">
      <h2 class="hca-red-color">
        {{this.selfPay?.formJson?.dentalPlan.planCoverageMessage}}
      </h2>
    </div> 
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>


<!-- RETIREE OE NO SELECTIONS MADE -->

<div *ngIf="noDentalSelectionMade && (isPebbRetireeOE || isCobraOE)" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body">
    <h2>Dental plan selection</h2>
    <div class="h5 mgbt-medium">
      Dental coverage has not been selected for any members on this account so no Dental plan election applies.
      If that is incorrect, please go back and select Dental coverage. Otherwise you can proceed to defer your coverage
      and submit your request.
      <br/>
      <br/>
      If you have questions or need assistance you can contact The Health Care Authority (HCA) by calling 1-800-200-1004 (toll-free)
      Monday through Friday 8a.m. to 4:30p.m.
      <br/>
      <br/>
      Contact infomration for The Health Care Authority can be found here: <a targe="_blank" href="https://www.hca.wa.gov/about-hca/contact-hca#retirees">Contact HCA</a>     
    </div>
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>



<!-- RETIREE OE -->
<div *ngIf="(isPebbRetireeOE || isCobraOE) && !noDentalSelectionMade" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body" fxFlexFill>
    <h2>Dental plan selection</h2>
    <span>Choose one dental plan. Before you enroll, make sure the provider you want to use accepts the specific plan and group you choose.</span>
    <br />
    <div *ngIf="isRetiree" fxLayoutAlign="space-between center" fxFlexFill fxLayout="column" class="h4">
      <a href="https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2024.pdf" target="_blank" class="">Click here to view the 2024 premiums</a>
    </div>
    <br/>
    <div>
      <div class="k-form-field bigger" fxFlex="100">
        <checkbox name="visionEnrollInd" [(ngModel)]="selfPay.formJson.continueCurrentSelectedDental" (ngModelChange)="continueCurrentSelected($event)">Continue with my current dental plan</checkbox>
      </div>
    </div>
    <br />
    <form fxLayoutAlign="space-between" fxFlexFill fxLayout="row" #electDentalForm="ngForm" (ngSubmit)="emitSelfPay(selfPay)" class="fxGap-medium">
      <div *ngIf="agency?.agencyCode === 'PEBB'" fxLayout="column" fxFlex="50">
        <div fxLayout="column">
          <h3 class="selfPay-form-small-header"><b>Preferred Provider Organization (PPO):</b></h3>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of groupedDentalPlans['Preferred Provider Organization (PPO)']; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'dentalPlan' + plan.planId" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selectedPlanId">
                  {{ plan.planName }}<br />
                  <span>
                    <span> You can choose any dental provider and change providers at any time. </span>
                    </span>
                </radio>
              </li>
            </ul>
          </div>
        </div>
        <div fxLayout="column">
          <h3 class="selfPay-form-small-header"><b>Managed-care plans (limited network):</b></h3>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of groupedDentalPlans['Managed-care plans (limited network)']; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'dentalPlan' + plan.planId" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selectedPlanId">
                  {{ plan.planName }}<br />
                  <span *ngIf="plan?.planCode == '4' || plan?.planCode == '4S'">
                    <span> You will select and receive services from a provider in the DeltaCare network.  </span>
                  </span>
                  <span *ngIf="plan?.planCode != '4' && plan?.planCode != '4S'">
                    <span> You will select and receive services from a provider in the Willamette Dental Group network. </span>
                  </span>
                </radio>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div fxFlex="45">
        <h3 class="h5">Helpful links:</h3>
        <div *ngIf="agency.agencyCode === 'PEBB'" fxLayoutAlign="start start" fxLayout="column">
          <a target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/compare-dental-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/compare-dental-plans'">Compare dental plans</a>
          <span ><a target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/find-pebb-plan-provider' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/find-pebb-plan-provider'">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
          <a target="_blank" [href]="isRetiree ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/contact-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/contact-plans'">Plan contact information</a>
        </div>
        <div *ngIf="agency.agencyCode === 'SEBB'" fxLayoutAlign="start start" fxLayout="column">
          <a target="_blank" [href]="!isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/compare-dental-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/compare-dental-plans'">Compare dental plans</a>
          <span ><a target="_blank" [href]="!isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/find-pebb-plan-provider' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/find-sebb-plan-provider'">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
          <a target="_blank" [href]="!isCobra ? 'https://www.hca.wa.gov/employee-retiree-benefits/retirees/contact-plans' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/contact-plans'">Plan contact information</a>
        </div>
      </div>
      <div *ngIf="agency?.agencyCode === 'SEBB'" fxLayout="column" fxFlex="100">
        <div fxLayout="column">
          <h3 class="selfPay-form-small-header"><b>Dental Plans:</b></h3>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of sebbDentalPlans; let index = index" class="k-radio-item bigger" fxLayout="row">
                <radio [name]="'dentalPlan' + plan.planId" [value]="plan.planId" (change)="setPlan(plan.planId)" [(ngModel)]="selectedPlanId">
                  {{ plan.planName }} 
                  <span *ngIf="plan.planName === 'Uniform Dental Plan'">(Group #09600)</span>
                  <span *ngIf="plan.planName === 'DeltaCare'">(Group #09601)</span>
                  <span *ngIf="plan.planName === 'Willamette Dental Group of Washington'">(Group WA 733)</span>
                </radio>
              </li>
            </ul>
          </div>
        </div>
      </div>      
    </form>
    <div *ngIf="this.selfPay?.formJson?.dentalPlan.planCoverageMessage" class="mgbt-medium">
      <h2 class="hca-red-color">
        {{this.selfPay?.formJson?.dentalPlan.planCoverageMessage}}
      </h2>
    </div> 
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>