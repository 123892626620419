import Agency from './agency';

export default class OrganizationSubAgency {
  organizationSubAgencyId: string;
  organizationId: string;
  agencyId: string;
  agency: Agency;

  constructor(organizationSubAgency?) {
    if (organizationSubAgency) {
      this.organizationId = organizationSubAgency.organizationId;
      this.agencyId = organizationSubAgency.agencyId;
      this.agency = organizationSubAgency.agency ? organizationSubAgency.agency : null;
    }
  }
}
