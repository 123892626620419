import { Component, ViewEncapsulation, OnInit, ViewChild, ViewChildren, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlusCircle, faPlus, faMinus, faHome, faFileInvoiceDollar, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Organization from 'src/app/models/organization';
import { sortBy, remove, uniqBy, map, each, filter, find, cloneDeep, isEqual, differenceWith, some, findIndex, get } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import SystemUser from 'src/app/models/user';
import { OrganizationAddressService } from 'src/app/services/organizationAddress.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { OrganizationContactService } from 'src/app/services/organizationContact.service';
import OrganizationAddress from 'src/app/models/organizationAddress';
import * as dayjs from 'dayjs';
import OrganizationContact from 'src/app/models/organizationContact';
import AddressType from 'src/app/models/addressType';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { UserService } from 'src/app/services/user.service';
import { OrganizationContactComponent } from './components/contact/organization.contact.component';
import { OrganizationDetailsComponent } from './components/details/organization.details.component';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import UserOrganizationRole from 'src/app/models/userOrganizationRole';
import RateStructure from 'src/app/models/rateStructure';
import MemberType from 'src/app/models/memberType';
import BenefitSetup from 'src/app/models/benefitSetup';
import { env } from 'src/env/development';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import UserAccessLevel from 'src/app/models/userAccessLevel';
import { lastValueFrom } from 'rxjs';

@UntilDestroy()
@Lookups(LookupType.County, LookupType.MemberType, LookupType.Country, LookupType.AddressType, LookupType.PhoneNumberType,
         LookupType.ContactType, LookupType.BenefitSetup, LookupType.OrganizationType, LookupType.RateStructure)
@Component({
  selector: 'organization-profile',
  templateUrl: 'organizationProfile.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationProfileComponent implements OnInit {
  icons = {
    faPlusCircle,
    faPlus,
    faMinus,
    faHome,
    faFileInvoiceDollar,
    faEnvelope,
  };
  systemUser: SystemUser;
  systemUserClone: SystemUser;
  assignedOrganizations: Organization[] = [];
  currentOrganization: Organization;
  initialOrganization: Organization;
  lookups: any = {};
  extraAddresses: OrganizationAddress[] = [];
  isCollapsed = { 0: false, 1: true, 2: true };
  isHCAAdmin = false;
  hasEditAccess = false;
  physicalAddress: OrganizationAddress;
  addingNewOrganization: boolean;
  resetParent = false;
  compositeRateId: string;
  tieredRateId: string;
  isReadOnly = false;
  sebbOrgTypeId = '';
  sebbOrgEGTypeId = '';
  orgSubscription: any;
  orgTypeToDisableEditFor: Organization[] = [];
  isOrgTypeToDisableEditFor: boolean;
  env = env;
  isHCASystemAdmin = false;
  @ViewChildren('organizationContactsComponent') public organizationContactsComponents: OrganizationContactComponent[];
  @ViewChild('organizationDetailsComponent') public organizationDetailsComponent: OrganizationDetailsComponent;
  @ViewChild('form') public form: NgForm;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private organizationAddressService: OrganizationAddressService,
    private organizationService: OrganizationService,
    private organizationContactService: OrganizationContactService,
    private spinnerOverlayService: SpinnerOverlayService,
    private userService: UserService,
    public coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.addingNewOrganization = data.add;
      this.systemUser = data.user;
      this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
      this.isHCASystemAdmin = this.coreService.systemUserHasAccess(AccessLevel.SystemAdmin, UserTypeCode.HCA);
      this.hasEditAccess = this.coreService.systemUserHasAccess(AccessLevel.Edit);
      this.systemUserClone = cloneDeep(this.systemUser);
      this.lookups.addressTypes = data.lookups.addressType;
      this.lookups.contactTypes = sortBy(data.lookups.contactType, 'contactTypeName');
      this.lookups.benefitSetups = filter(data.lookups.benefitSetup, (bs: BenefitSetup) => env.availableBSCodes.includes(bs.benefitSetupCode));
      this.lookups.rateStructures = data.lookups.rateStructure;
      this.lookups.organizationTypes = data.lookups.organizationType;
      this.lookups.memberTypes = data.lookups.memberType;
      this.sebbOrgTypeId = find(this.lookups.organizationTypes, (t) => t.organizationTypeCode === 'SEBB')?.organizationTypeId;
      this.sebbOrgEGTypeId = find(this.lookups.organizationTypes, (t) => t.organizationTypeCode === 'SEG')?.organizationTypeId;
      this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);
      this.orgTypeToDisableEditFor = filter(data.lookups.organizationType, (lo:Organization) => some(env.orgTypesToDisableEditFor, (o: string) =>  o  === lo.organizationTypeName));
      this.lookups.filteredAddressTypes = sortBy(
        filter(data.lookups.addressType, (at: AddressType ) => [env.mailingAddressTypeCode, env.billingAddressTypeCode, env.shippingAddressTypeCode].includes(at.addressTypeCode)),
        'addressTypeName'
      );
      this.compositeRateId = get(find(this.lookups.rateStructures, (rs: RateStructure) => rs.rateStructureCode === 'CR'), 'rateStructureId');
      this.tieredRateId = get(find(this.lookups.rateStructures, (rs: RateStructure) => rs.rateStructureCode === 'TR'), 'rateStructureId');
      this.orgSubscription = this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((organization: Organization) => {
        if (organization && organization.organizationId && !organization.organizationTypeId) {
          this.organizationService.getOrganizationById(organization.organizationId).pipe(untilDestroyed(this)).subscribe((fullOrg: Organization) => {
            this.coreService.setOrganization(fullOrg);
            this.setOrgTypeInit();
          });
        } else {
          this.currentOrganization = organization;
          if (organization || this.addingNewOrganization) {
            this.updateDataForCurrentOrganization(organization);
            if (!this.addingNewOrganization) {
              this.setOrgTypeInit();
            }
          }
        }
      });
    });
  }

  updateDataForCurrentOrganization(organization: Organization): void {
    if (this.addingNewOrganization) {
      this.currentOrganization = new Organization();
      this.currentOrganization.organizationContacts = [];
    }
    this.initialOrganization = cloneDeep(this.currentOrganization);
    this.extraAddresses = this.lookups.filteredAddressTypes.map(fat => this.getOrganizationAddressForType(fat));
    const physicalAddressType = find(this.lookups.addressTypes, ['addressTypeCode', env.physicalAddressTypeCode]);
    this.physicalAddress = this.getOrganizationAddressForType(physicalAddressType);
    this.initialOrganization = cloneDeep(this.currentOrganization);
  }

  getOrganizationAddressForType(addressType: AddressType): OrganizationAddress {
    const existingRec = find(this.currentOrganization.organizationAddresses, (address) => {
      // Add effective start and end date logic to select the correct address for type
      return address.addressTypeId === addressType.addressTypeId;
    });
    if (existingRec === undefined) {
      const newRec = new OrganizationAddress();
      newRec.addressTypeId = addressType.addressTypeId;
      newRec.addressType = addressType;
      newRec.organizationId = this.currentOrganization.organizationId;
      return newRec;
    } else {
      return existingRec;
    }
  }

  async saveOrganizationInfo(formContainer): Promise<void> {
    // check form validity
    this.coreService.markFormControlsAsTouched(this.form);
    let isValid = this.form.valid;

    this.organizationDetailsComponent.markAllControlsAsTouched();
    if (this.isHCAAdmin) {
      isValid = isValid && this.organizationDetailsComponent.districtForm.valid;
    }

    // Perspay & HCA can edit contacts; check for all users
    if (this.organizationContactsComponents) {
      Array.from(this.organizationContactsComponents).forEach((c) => {
        c.markAllControlsAsTouched();
        isValid = isValid && c.contactForm && c.contactForm.valid;
      });
    }

    if (isValid) {
      this.spinnerOverlayService.show();
      let newOrganization;
      // const actuallyTouchedContacts = filter(Array.from(this.organizationContactsComponents), c => some(c.contactForm.controls, cc => cc.dirty || cc.touched));

      if (!this.currentOrganization.organizationAddresses) {
        this.currentOrganization.organizationAddresses = [];
      }

      const physicalAddressType = find(this.lookups.addressTypes, ['addressTypeCode', env.physicalAddressTypeCode]);
      const physicalIndex = findIndex(this.currentOrganization.organizationAddresses, oa => oa.addressTypeId === physicalAddressType.addressTypeId);
      if (physicalIndex === -1) {
        this.currentOrganization.organizationAddresses.push(this.physicalAddress);
      } else {
        this.currentOrganization.organizationAddresses[physicalIndex] = this.physicalAddress;
      }

      this.extraAddresses.forEach(a => {
        if (!a.organizationAddressId) {
          // add new addresses
          const newAddress = new OrganizationAddress(a);
          this.currentOrganization.organizationAddresses.push(newAddress);
        }
      });

      const currentOrganizationCopy = cloneDeep(this.currentOrganization);
      const saContactDifferences: OrganizationContact[] = differenceWith(currentOrganizationCopy.organizationContacts, this.initialOrganization.organizationContacts, isEqual);
      if (saContactDifferences.length && some(saContactDifferences, (c: OrganizationContact) => !c.contactTypes.length)) {
        this.spinnerOverlayService.hide();
        this.coreService.popMessage('You must provide at least one contact type for each contact entered.', 'error', 2000);
        return;
      }

      if (!this.currentOrganization.addressIsSameAsPhysicalInd) {
        this.currentOrganization.organizationAddresses = this.currentOrganization.organizationAddresses.concat(this.extraAddresses);
      }
      const saAddressDifferences: OrganizationAddress[] = differenceWith(currentOrganizationCopy.organizationAddresses, this.initialOrganization.organizationAddresses, isEqual);

      delete currentOrganizationCopy.organizationAddresses;
      delete currentOrganizationCopy.organizationContacts;
      const initialCopy = cloneDeep(this.initialOrganization);
      delete initialCopy.organizationAddresses;
      delete initialCopy.organizationContacts;
      const saDetailDifferences: Organization[] = differenceWith([currentOrganizationCopy], [initialCopy], isEqual);
      if (saDetailDifferences.length) {
        if (!saDetailDifferences[0].memberTypeId) {
          if (saDetailDifferences[0].rateStructureId === this.compositeRateId) {
            saDetailDifferences[0].memberTypeId = saDetailDifferences[0].memberTypeId = get(find(this.lookups.memberTypes, (mt: MemberType) => mt.memberTypeCode === 'Y'), 'memberTypeId');
          } else {
            saDetailDifferences[0].memberTypeId = saDetailDifferences[0].memberTypeId = get(find(this.lookups.memberTypes, (mt: MemberType) => mt.memberTypeCode === 'X'), 'memberTypeId');
          }
        }
          if(!saDetailDifferences[0].organizationId){
            newOrganization = await lastValueFrom(this.organizationService.createOrganization(saDetailDifferences[0]));
          }  else {
            await lastValueFrom(this.organizationService.updateOrganization(saDetailDifferences[0]));
        }
      }

      if (saAddressDifferences.length) {
        for (const ad of saAddressDifferences) {
          // HCA Admins can edit all addresses; BAs cannot edit physical
          if (this.isHCAAdmin || ad.addressType.addressTypeCode !== env.physicalAddressTypeCode) {
            if (newOrganization) {
              ad.organizationId = newOrganization.organizationId;
            }
            if (ad.addressType.addressTypeCode === env.physicalAddressTypeCode || 
              (
                !this.currentOrganization.addressIsSameAsPhysicalInd &&   
                (!this.currentOrganization.mailingIsSameAsPhysicalInd && ad.addressType.addressTypeCode===env.mailingAddressTypeCode) || 
                (!this.currentOrganization.billingIsSameAsPhysicalInd && ad.addressType.addressTypeCode===env.billingAddressTypeCode) || 
                (!this.currentOrganization.shippingIsSameAsPhysicalInd && ad.addressType.addressTypeCode===env.shippingAddressTypeCode)
              )
            )
            if (ad.organizationAddressId) {
              await lastValueFrom(this.organizationAddressService.updateOrganizationAddress(ad));
            } else {
              await lastValueFrom(this.organizationAddressService.createOrganizationAddress(ad));
            }
          }
        }
      }

      if (saContactDifferences.length) {
        for (const cd of saContactDifferences) {
          const initialContactTypes = get(
            find(this.initialOrganization.organizationContacts, (c) => c.organizationContactId === cd.organizationContactId),
            'contactTypes'
          );
          const deletes = differenceWith(initialContactTypes, cd.contactTypes, isEqual);
          const adds = differenceWith(cd.contactTypes, initialContactTypes, isEqual);
          if (newOrganization) {
            cd.organizationId = newOrganization.organizationId;
          }
          if (cd.organizationContactId.includes('NEW')) {
            delete cd.organizationContactId;
            const newlyCreatedContact = await lastValueFrom(this.organizationContactService.createOrganizationContact(cd));
            cd.organizationContactId = newlyCreatedContact.organizationContactId;
          } else {
            await lastValueFrom(this.organizationContactService.updateOrganizationContact(cd));
          }
          for (const contactType of adds) {
            contactType.organizationContactId = cd.organizationContactId;
            await lastValueFrom(this.organizationContactService.createContactTypeForContact(cd, contactType));
          }
          for (const contactType of deletes) {
            contactType.organizationContactId = cd.organizationContactId;
            await lastValueFrom(this.organizationContactService.deleteOrganizationContactType(cd, contactType.contactTypeId));
          }
        }
      }

      // delete extra addresses if global indicator is set, or if individual indicators are set
      for (const ad of this.extraAddresses) {
        if (ad.organizationAddressId && 
          (
            this.currentOrganization.addressIsSameAsPhysicalInd || 
            (this.currentOrganization.mailingIsSameAsPhysicalInd && ad.addressType.addressTypeCode===env.mailingAddressTypeCode) || 
            (this.currentOrganization.billingIsSameAsPhysicalInd && ad.addressType.addressTypeCode===env.billingAddressTypeCode) || 
            (this.currentOrganization.shippingIsSameAsPhysicalInd && ad.addressType.addressTypeCode===env.shippingAddressTypeCode)
          )) {
          await lastValueFrom(this.organizationAddressService.deleteOrganizationAddressForAgency(ad));
        }
      }

      this.resetParent = true;
      if (this.addingNewOrganization) {
        this.coreService.setOrganization(this.currentOrganization);
        this.spinnerOverlayService.hide();
        this.coreService.popMessage('Organization added successfully.', 'success', 2000);
        this.router.navigate([`../dashboard/perspay/${newOrganization.organizationId}`], { relativeTo: this.route });
      } else {
        this.organizationService.getOrganizationById(this.currentOrganization.organizationId).pipe(untilDestroyed(this)).subscribe((fullOrg: Organization) => {
          this.coreService.setOrganization(fullOrg);
          this.coreService.popMessage('Records successfully saved.', 'success', 2000);
          this.spinnerOverlayService.hide();
        });
        
      }
    } else {
      this.spinnerOverlayService.hide();
      this.coreService.popMessage('Fields either missing or invalid, please review your information and try again.', 'error', 3000, this.coreService.getInvalidFields(formContainer));
    }
  }

  refreshOrganizationInformation(): void {
    if (this.addingNewOrganization) {
      this.currentOrganization = new Organization();
    } else {
      this.currentOrganization = this.currentOrganization;
    }
    this.initialOrganization = cloneDeep(this.currentOrganization);
    this.extraAddresses = this.lookups.filteredAddressTypes.map(fat => this.getOrganizationAddressForType(fat));
    this.updateDataForCurrentOrganization(this.currentOrganization);
  }

  orgTypeChange(event): void {
    // reset org type specific fields that are not used
    if (this.currentOrganization.organizationTypeId === this.sebbOrgTypeId || this.currentOrganization.organizationTypeId === this.sebbOrgEGTypeId) {
      this.currentOrganization.disallowLoginInd = false;
      this.currentOrganization.benefitSetupId = null;
      this.currentOrganization.contractNumber = null;
      this.currentOrganization.rateStructureId = null;
      this.currentOrganization.rateSurchargeInd = null;
      if (this.currentOrganization.organizationTypeId === this.sebbOrgEGTypeId) {
        this.currentOrganization.rateStructureId = this.tieredRateId;
      }
    }
    else {
      this.currentOrganization.localEligibleBenefits = false;
      this.currentOrganization.ospiDistrictNumber = null;
    }

    this.setOrgTypeInit();
  }

  setOrgTypeInit() {
    this.isOrgTypeToDisableEditFor = some(this.orgTypeToDisableEditFor, (o: Organization) => o.organizationTypeId  === this.currentOrganization.organizationTypeId);  
  }

  // need to set current organization same instead of 0.
  clearChanges(): void {
    this.systemUser = cloneDeep(this.systemUserClone);
    this.currentOrganization = cloneDeep(this.initialOrganization);
    this.refreshOrganizationInformation();
  }

  addContact(): void {
    const contact = new OrganizationContact();
    contact.organizationId = this.currentOrganization.organizationId;
    contact.organizationContactId = 'NEW' + this.currentOrganization.organizationContacts.length + 1;
    this.currentOrganization.organizationContacts.push(contact);
  }

  async onRemoveContact(c: OrganizationContact): Promise<void> {
    const contactIdx = findIndex(this.currentOrganization.organizationContacts, (contact: OrganizationContact) => contact.organizationContactId === c.organizationContactId);
    if (c.organizationContactId.includes('NEW')) {
      this.currentOrganization.organizationContacts.splice(contactIdx, 1);
    } else {
      this.spinnerOverlayService.show();
      await lastValueFrom(this.organizationContactService.deleteOrganizationContact(c));
      this.currentOrganization.organizationContacts.splice(contactIdx, 1);
      this.spinnerOverlayService.hide();
    }
  }

  addressSameAsPhsyicalChange(addressIndex) {
    this.extraAddresses[addressIndex].resetAddress();
  }

  addressIsSameAsPhysicalIndChange() {
    if (this.currentOrganization.addressIsSameAsPhysicalInd) {
      this.currentOrganization.shippingIsSameAsPhysicalInd = true;
      this.currentOrganization.billingIsSameAsPhysicalInd = true;
      this.currentOrganization.mailingIsSameAsPhysicalInd = true;
    }
  }
}
