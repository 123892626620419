<div fxLayout="row" fxLayoutAlign="center center">
  <hca-prompt
    (putPostObject)="confirm()"
    fxFlex="100"
    [confirmButtonText]="confirmButtonText"
    [confirmIndex]="0"
    [icon]="icons.faStaffSnake"
    (previous)="updateStep($event)"
    (next)="updateStep($event)"
    [milestone]="supplementalMilestone"
    [hideBackToDash]="isSOE || isOutsideEP"
    [hideButtons]="isOutsideEP || (!subscriber.hasSupplementalLTD && accessedFromAdminTab)"
  >
  <div *ngIf="subscriberIneligibile">
    Supplemental elections are not available for terminated subscribers.
  </div>
  <div *ngIf="!subscriberIneligibile">
    <div *ngIf="isPEBB" fxLayoutAlign="center center" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
      <div>
        <div class="fxGap-medium-sm">
          <div class="card-body" fxLayout="column">
            <h2 class="mobile-text-centered mgtp-small">Your supplemental coverage options</h2>
            <div *ngIf="subscriber.hasSupplementalLTD || !accessedFromAdminTab">
              <div class="supplemental-section container-fluid">
                <h3 class="mobile-text-centered">Long-term disability (LTD) insurance</h3>
                <ng-container *ngIf="!subscriber.isEligibleForLTD">
                  Based on your eligibility, LTD options are unavailable to you. For more information please contact your benefits administrator. 
                  <br/><br/>
                  For additional information please visit 
                  <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/long-term-disability-insurance" target="_blank">
                    https://www.hca.wa.gov/employee-retiree-benefits/public-employees/long-term-disability-insurance
                  </a>
                </ng-container>
                <ng-container *ngIf="subscriber.isEligibleForLTD">
                  <p>
                    The {{subscriber.organization?.agency?.agencyCode}} Program offers employer-paid and employee-paid LTD insurance.
                  </p>
                  <br />
                  <br />
                  <p>LTD insurance protects a portion of your salary if you are unable to work due to serious injury or illness. LTD coverage pays you a percentage of your monthly predisability earnings if you become disabled. </p>
                  <br />
                  <h5>Employer-paid LTD</h5>
                  <p>You are automatically enrolled in employer-paid LTD insurance, even if you waive medical coverage. You do not need to provide evidence of insurability (proof of good health). </p>
                  <ul>
                    <li>Benefit: 60 percent of the first $400 of your predisability earnings. </li>
                    <li>Minimum: $100 or 10 percent of the LTD benefit before deductible income (whichever is greater)</li>
                    <li>Maximum: $240 per month</li>
                  </ul>
                  <p>Employer-paid LTD is included in your benefits at no cost to you.</p>
                  <br />
                  <br />
                  <h5>Employee-paid LTD</h5>
                  <p>
                    You are automatically enrolled in a plan that covers up to 60 percent of the first $16,667 of your monthly predisability earnings. You do not need evidence of insurability. The minimum benefit is $100. The maximum benefit is $10,000 per month for the 60-percent coverage and $8,333 per month for the 50-percent coverage.
                    <br/>
                    <b>At any time</b>, you can reduce to a lower-cost 50-percent coverage level or decline the coverage. 
                  </p>
                  <br />
                  <br />
                  <p>If you later decide to enroll in or increase coverage, you will have to provide evidence of insurability and be approved by the insurer.</p>
                  <br />
                  <br />
                  <p>An increase or enrollment in coverage takes effect the first day of the month following the date evidence of insurability is approved.</p>
                  <br />
                  <br />
                  <p>Employee-paid LTD cost</p>
                  <table fxFill class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Coverage level</th>
                        <th scope="col">Higher-education employees retirement plan</th>
                        <th scope="col">TRS, PERS, and other retirement plans</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>60 percent</td>
                        <td>0.0059</td>
                        <td>0.0047</td>
                      </tr>
                      <tr>
                        <td>50 percent</td>
                        <td>0.0035</td>
                        <td>0.0028</td>
                      </tr>                    
                    </tbody>
                  </table>
                  <div *ngIf="!ltdDisabled">The monthly employee-paid premium displayed is based on the predisability monthly earnings provided by your employer.</div>                    
                  <div *ngIf="legacyLtd && !ltdDisabled">
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                      <div class="k-form-field" style="padding-top: 2px; padding-bottom: 5px;">
                        <checkbox
                          name="legacyLtdEnroll"
                          bold
                          (ngModelChange)="legacyEnrollChanged($event)"
                          [(ngModel)]="legacyEnroll">I want to enroll in supplemental LTD</checkbox>
                      </div>              
                    </div>
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                      <div class="form-group required" fxFlex="25">
                        <label for="ltdWaitingPeriod" style="font-weight: bold;">Enroll reason:</label>
                        <select required class="form-control" [disabled]="!legacyEnroll" name="ltdWaitingPeriod" id="ltdWaitingPeriod"
                          [(ngModel)]="selectedPlan"
                          (ngModelChange)="legacyPlanChanged($event)">
                          <option></option>
                          <option *ngFor="let plan of supplementalPlans" [ngValue]="plan">
                            {{plan.planName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                      <button style="margin: 30px" (click)="saveElections(selectedPlan)" class="btn btn-primary">Submit Changes</button>
                    </div>                
                  </div>
                  <supplemental-calc
                    #suppCalc
                    *ngIf="!legacyLtd && supplementalPlans && !isSOE && !ltdDisabled"
                    (electSupplemental)="saveElections($event)"
                    [selectedPlan]="selectedPlan"
                    [subscriber]="subscriber"
                    [supplementalPlans]="supplementalPlans"
                    [isEnrolled]="isEnrolled"
                    [isAdmin]="isAdmin"
                    [inManagementState]="isOutsideEP"
                    [initialSelectedPlanId]="initialSelectedPlanId"
                    [isActiveEmployeeType]="isActiveEmployeeType" 
                    [selectedSupplementalEnrollment]="selectedSupplementalEnrollment"
                    [isSEBB]="isSEBB"
                    [inWizard]="inWizard"
                  ></supplemental-calc>
                  <div *ngIf="ltdDisabled">Employee paid LTD enrollment is managed by your employer.  Please contact your benefits office for employee paid LTD questions and changes.</div>
                </ng-container>
              </div>
              <div class="supplemental-section container-fluid">
                <h3 class="mobile-text-centered"><a target="_blank" href="https://www.metlife.com/wshca/">Life and accidental death & dismemberment (AD&D) insurance</a></h3>
                <h5>Basic life and AD&D insurance</h5>
                <p>The PEBB Program provides basic life and basic AD&D insurance at no cost to you (paid by your employer). You will be automatically enrolled in these benefits, even if you waive medical coverage. You do not need to provide evidence of insurability (proof of good health). Learn more about <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/life-insurance" >life and AD&D insurance.</a> </p>
                <br />
                <br />
                <p>As an employee, basic life insurance covers you and pays your designated beneficiaries in the event of your death. Basic AD&D insurance provides extra benefits for certain injuries or death resulting from a covered accident. </p>
                <br />
                <br />
                <p>These benefits provide:</p>
                <ul>
                  <li>$35,000 for basic life insurance</li>
                  <li>$5,000 for basic AD&D insurance</li>
                </ul>
                <br />
                <p><a target="_blank" href="https://mybenefits.metlife.com/wapebb">Name your beneficiaries</a> for your basic life and basic AD&D insurance.</p>
                <h5>Supplemental life and AD&D insurance</h5>
                <p>You may enroll in supplemental life and supplemental AD&D insurance for yourself and your dependents. To enroll your dependents in supplemental life and AD&D insurance, you must enroll in supplemental life insurance for yourself. </p>
                <br />
                <br />
                <p>To enroll in supplemental life insurance, create an account through <a href="https://www.metlife.com/wshca/" target="_blank">Metlife MyBenefits Portal.</a></p>
              </div>
              <div class="supplemental-section container-fluid">
                <h3 class="mobile-text-centered">
                  <a target="_blank" href="https://pebb.naviabenefits.com/">Flexible spending arrangements (FSAs) and Dependent Care Assistance Program (DCAP)</a>
                </h3>
                <h4>FSAs</h4>
                <p>
                  FSAs allow you to set aside pretax money from your paycheck to pay for out-of-pocket health care costs. <a href="https://pebb.naviabenefits.com/" target="_blank">Navia Benefit Solutions</a> processes claims and provides customer service for the PEBB Program.
                </p>
                <br />
                <br />
                <p>You must enroll in an FSA each year you want to participate. Enrollment does not automatically continue from plan year to plan year.</p>
                <br />
                <br />
                <p>The PEBB Program offers a Medical FSA and a Limited Purpose FSA. You cannot have both a Limited Purpose FSA and a Medical FSA. <a href="https://pebb.naviabenefits.com/" target="_blank"></a>Learn more about FSAs and DCAP.</p>
                <h5>Medical FSA</h5>
                <p>The Medical FSA allows you to pay for out-of-pocket health care costs like deductibles, copays, coinsurance, medications, menstrual care products, dental care, vision services, and more (see <a target="_blank" href="https://pebb.naviabenefits.com/benefits/expenses/">eligible expenses</a>). You cannot have a Medical FSA and be enrolled in a consumer-directed health plan (CDHP) with a health savings account (HSA).</p>
                <br />
                <br />
                <h5>Limited Purpose FSA</h5>
                <p>The Limited Purpose FSA covers only dental and vision expenses. It is intended for employees enrolled in a consumer-directed health plan (CDHP) with a health savings account. It allows you to pay for out-of-pocket dental and vision costs like glasses, contact lenses and solution, dentures, dental copays, orthodontia, and more (see <a target="_blank" href="https://pebb.naviabenefits.com/benefits/expenses/">eligible expenses</a>).</p>
                <br />
                <br />
                <h5>DCAP</h5>
                <p>The DCAP allows you to set aside pretax money from your paycheck to help pay for qualifying child care or elder care expenses. <a target="_blank" href="https://pebb.naviabenefits.com/">Learn more about DCAP and enroll.</a></p>
                <br />
                <br />
                <p>You can set up an FSA or DCAP account: </p>   
                <br>
                <ul>
                  <li>During the PEBB Program’s annual open enrollment. </li>
                  <li>No later than 31 days after you become eligible for PEBB benefits.</li>
                  <li>No later than 60 days after you or an eligible family member has a qualifying event that creates a special open enrollment. </li>                  
                </ul>             
              </div>
              <div class="supplemental-section container-fluid">
                <h3 class="mobile-text-centered">
                  Health savings account (HSA)
                </h3>
                <p>When you enroll in a consumer-directed health plan, you are also enrolled in a health savings account (HSA) through HealthEquity. Your HSA is a tax-advantaged spending and savings account that can be used to pay for qualified medical expenses. Your HSA is funded by pretax contributions from your employer. You can choose to make additional contributions to your HSA. Contact your payroll or benefits office to see if you can arrange automatic payroll deductions to your HSA. <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/health-savings-accounts-hsas" target="_blank">Learn more about health plans with HSAs</a>.</p>
                <br />
                <br />
                <p>For a list of items and services you can pay for with your HSA funds and to see the maximum annual contribution limits to your HSA, visit <a target="_blank" href="https://learn2.healthequity.com/pebb/hsa/">HealthEquity’s website</a>.</p>
              </div>
              <div class="supplemental-section container-fluid">
                <img style="width: 35%" src="assets/smarthealth.png" />
                <br />
                <br />
                <p>
                  SmartHealth is Washington State's voluntary wellness program that supports you on your journey toward living well. Join activities that support all of you, including managing stress, building resiliency, and adapting to change. As you progress on your wellness journey, you may also qualify for the SmartHealth wellness incentive. <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/smarthealth-pebb">Learn more about SmartHealth.</a>
                </p>
              </div>
              <div class="supplemental-section container-fluid">
                <h3 class="mobile-text-centered">
                  Auto and home insurance
                </h3>
                <p>PEBB members may receive a discount of up to 12 percent off Liberty Mutual’s auto insurance rates and up to 5 percent off Liberty Mutual’s home insurance rates. You can enroll in auto and home insurance coverage at any time.</p>
                <br />
                <br />
                <p>
                  To request a quote for auto and home insurance, you can visit <a target="_blank" href="https://www.libertymutual.com/pebbsae">Liberty Mutual’s website</a> or call Liberty Mutual at 1-800-706-5525. Mention you are a State of Washington PEBB member (client #8246). 
                </p>
                <br/>
                <br/>
                <p>
                Liberty Mutual does not guarantee the lowest rate to all PEBB members; rates are based on underwriting for each individual, and not all applicants will qualify. Discounts and savings are available where state laws and regulations allow and may vary by state. 
                </p>
              </div>              
            </div>
            <div class="supplemental-section container-fluid" *ngIf="!subscriber.hasSupplementalLTD && accessedFromAdminTab">
              Supplemental benefits are not applicable.
            </div>
          </div>
        </div>
        <div *ngIf="isOutsideEP" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
          <button style="margin: 30px" (click)="navToDashboard()" class="btn btn-secondary">Back to dashboard</button>
        </div>
      </div>
    </div>
    <!-- SEBB -->
    <div *ngIf="isSEBB">
      <div fxLayoutAlign="center center" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
        <div [class]="outerContainerClass">
          <div [class]="innerContainerClass" fxLayoutGap="15px">
            <div class="card-body" fxLayout="column">
              <div *ngIf="!subscriber.hasSupplementalLTD && accessedFromAdminTab">
                <h2 class="mobile-text-centered mgtp-small">Your supplemental coverage options</h2>
                <div class="supplemental-section container-fluid">
                  Supplemental benefits are not applicable.
                </div>
              </div>
              <div *ngIf="subscriber.hasSupplementalLTD || !accessedFromAdminTab">
                <div *ngIf="!legacyLtd" class="supplemental-section container-fluid">
                  <h3 class="mobile-text-centered">Employee-paid long-term disability (LTD) insurance</h3>
                  <ng-container *ngIf="!subscriber.isEligibleForLTD">
                    <p>
                      Based on your eligibility, LTD options are unavailable to you. For more information please contact your benefits administrator. 
                      <br/><br/>
                      For additional information please visit 
                      <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/long-term-disability-insurance" target="_blank">
                        https://www.hca.wa.gov/employee-retiree-benefits/school-employees/long-term-disability-insurance
                      </a>
                    </p>
                  </ng-container>
                  <p>
                    The {{subscriber.organization?.agency?.agencyCode}} Program offers employer-paid and employee-paid LTD insurance.
                  </p>
                  <p>LTD insurance protects a portion of your salary if you are unable to work due to serious injury or illness. When you enroll in LTD coverage, it pays you a percentage of your monthly predisability earnings if you become disabled. </p>
                  <p>
                    To learn more about LTD benefits, visit the
                    <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/long-term-disability-insurance" target="_blank">Long-term disability webpage</a>.
                  </p>
                  <br/>
                  <h5>Employer-paid LTD insurance</h5>
                  <p>You are automatically enrolled in employer-paid LTD insurance, even if you waive medical coverage. You do not need to provide evidence of insurability (proof of good health). </p>
                  <ul>
                    <li>Benefit: 60 percent of the first $667 of your predisability earnings. </li>
                    <li>Minimum: $100 or 10 percent of the LTD benefit before deductible income (whichever is greater)</li>
                    <li>Maximum: $400 per month</li>
                  </ul>
                  <p>Employer-paid LTD is included in your benefits at no cost to you.</p>
                  <br />
                  <h5>Employee-paid LTD</h5>
                  <p>
                    You are automatically enrolled in a plan that covers up to 60 percent of the first $16,667 of your monthly predisability earnings. You do not need evidence of insurability.     The minimum benefit is $100. The maximum benefit is $10,000 per month for the 60-percent coverage and $8,333 per month for the 50-percent coverage.
                  </p>
                  <p>
                    At any time, you can reduce to a lower-cost 50-percent coverage level or decline the employee-paid coverage.
                  </p>
                  <p>If you later decide to enroll in or increase coverage, you will have to provide evidence of insurability and be approved by the insurer.</p>
                  <p>An increase in coverage takes effect the first day of the month following the date evidence of insurability is approved.</p>
                  <br />
                  <h5>Employee-paid LTD cost</h5>
                  <p>
                    <b>These rates are based on the employee's age on January 1, of the current year.</b>
                  </p>
                  <p>
                    <b>To use the monthly premium calculator, visit
                      <a target="_blank" href="https://www.standard.com/mybenefits/sebb/premium-ltd.html">The Standard's Calculator Tool</a>. 
                      You can also <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/long-term-disability-insurance-premiums#employee-paid-ltd-costl">view employee-paid LTD premiums</a>.
                    </b>
                  </p>
                  <h3>Select your LTD coverage</h3>
                  <supplemental-calc
                    #suppCalc
                    *ngIf="!legacyLtd && supplementalPlans && subscriber.isEligibleForLTD && !ltdDisabled"
                    (electSupplemental)="saveElections($event)"
                    [selectedPlan]="selectedPlan"
                    [subscriber]="subscriber"
                    [supplementalPlans]="supplementalPlans"
                    [isEnrolled]="isEnrolled"
                    [isAdmin]="isAdmin"
                    [inManagementState]="isOutsideEP"
                    [isReadOnly]="isReadOnly"
                    [initialSelectedPlanId]="initialSelectedPlanId"
                    [isActiveEmployeeType]="isActiveEmployeeType" 
                    [selectedSupplementalEnrollment]="selectedSupplementalEnrollment"
                    [isSEBB]="isSEBB"
                    [inWizard]="inWizard">
                  </supplemental-calc>
                    <p>
                      To calculate your monthly LTD premium yourself, use the formula below.
                    </p>
                    <p>
                      Your monthly predisability earnings (not to exceed $16,667) x age rate = monthly premium.
                    </p>
                    <h3>Employee-paid LTD rates</h3>
                    <p>
                      These rates are based on the employee's age on January 1 of the current year, except for employees who become newly eligible.  Newly eligible employees 
                      will be based on age as of their enrollment date for the first calendar year of coverage.
                    </p>
                    <ltd-grid *ngIf="supplementalLTDBands && !legacyLtd" [ltdBands]="supplementalLTDBands"></ltd-grid>

                </div>
                <div *ngIf="legacyLtd" class="supplemental-section container-fluid">
                  <div>
                    The SEBB Program provides LTD insurance up to $400 per month as a basic benefit for eligible employees. 
                    Eligible employees can purchase supplemental LTD insurance to protect more of their income in the event of a disability. 
                    Enrolling is simple; select the checkbox to enroll and click submit.
                  </div>
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                      <div class="k-form-field" style="padding-top: 2px; padding-bottom: 5px;">
                        <checkbox
                          name="legacyLtdEnroll"
                          bold
                          (ngModelChange)="legacyEnrollChanged($event)"
                          [(ngModel)]="legacyEnroll">I want to enroll in supplemental LTD</checkbox>
                      </div>              
                    </div>
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                      <div class="form-group required" fxFlex="25">
                        <label for="ltdWaitingPeriod" style="font-weight: bold;">Enroll reason:</label>
                        <select required class="form-control" [disabled]="!legacyEnroll" name="ltdWaitingPeriod" id="ltdWaitingPeriod"
                          [(ngModel)]="selectedPlan"
                          (ngModelChange)="legacyPlanChanged($event)">
                          <option></option>
                          <option *ngFor="let plan of supplementalPlans" [ngValue]="plan">
                            {{plan.planCode}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- <div fxFlexFill fxLayout="row" fxLayoutAlign="start">
                      <button style="margin: 30px" (click)="saveElections(selectedPlan)" class="btn btn-primary">Submit Changes</button>
                    </div>                 -->
                </div>
                <div class="supplemental-section container-fluid">
                  <h3 class="mobile-text-centered"><a target="_blank" href="https://mybenefits.metlife.com/wasebb">Life and accidental death & dismemberment (AD&D) insurance</a></h3>
                  <h5>Basic life and AD&D insurance</h5>
                  <p>The SEBB Program provides basic life and basic AD&D insurance at no cost to you. You will be automatically enrolled in these benefits, even if you waive medical coverage. You do not need to provide evidence of insurability (proof of good health). Learn more about <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/life-insurance" >life and AD&D insurance.</a> </p>
                  <p>As an employee, basic life insurance covers you and pays your designated beneficiaries in the event of your death. Basic AD&D insurance provides extra benefits for certain injuries or death resulting from a covered accident. </p>
                  <p>These benefits provide:</p>
                  <ul>
                    <li>$35,000 for basic life insurance</li>
                    <li>$5,000 for basic AD&D insurance</li>
                  </ul>
                  <br />
                  <p><a target="_blank" href="https://mybenefits.metlife.com/wasebb">Name your beneficiaries</a> for your basic life and basic AD&D insurance.</p>
                  <h5>Supplemental life and AD&D insurance</h5>
                  <p>You may enroll in supplemental life and supplemental AD&D insurance for yourself and your dependents. To enroll your dependents in supplemental life and AD&D insurance, you must enroll in supplemental life insurance for yourself. 
                    To enroll in supplemental life insurance, create an account through <a href="https://mybenefits.metlife.com/wasebb" target="_blank">Metlife MyBenefits Portal.</a>
                  </p>
                </div>
                <div class="supplemental-section container-fluid">
                  <h3 class="mobile-text-centered">
                    <a target="_blank" href="http://sebb.naviabenefits.com/">Flexible spending arrangements (FSAs) and Dependent Care Assistance Program (DCAP)</a>
                  </h3>
                  <h4>FSAs</h4>
                  <p>
                    FSAs allow you to set aside pretax money from your paycheck to pay for out-of-pocket health care costs. Navia Benefit Solutions processes claims and provides customer service for the SEBB Program.
                  </p>
                  <p>You must enroll in an FSA each year you want to participate. Enrollment does not automatically continue from plan year to plan year.</p>
                  <p>The SEBB Program offers a Medical FSA and a Limited Purpose FSA. You cannot have both a Limited Purpose FSA and a Medical FSA. <a href="http://sebb.naviabenefits.com/" target="_blank"></a>Learn more about FSAs and DCAP.</p>
                  <h5>Medical FSA</h5>
                  <p>You cannot have a Medical FSA and be enrolled in a consumer-directed health plan (CDHP) with a health savings account (HSA). The Medical FSA allows you to pay for out-of-pocket health care costs like deductibles, copays, coinsurance, medications, menstrual care products, dental care, vision services, and more (see <a target="_blank" href="http://sebb.naviabenefits.com/benefits/expenses/">eligible expenses</a>).</p>
                  <h5>Limited Purpose FSA</h5>
                  <p>The Limited Purpose FSA covers only dental and vision expenses. It is intended for employees enrolled in a consumer-directed health plan (CDHP) with a health savings account. It allows you to pay for out-of-pocket dental and vision costs like glasses, contact lenses and solution, dentures, dental copays, orthodontia, and more (see <a target="_blank" href="http://sebb.naviabenefits.com/benefits/expenses/">eligible expenses</a>).</p>
                  <h5>DCAP</h5>
                  <p>The DCAP allows you to set aside pretax money from your paycheck to help pay for qualifying child care or elder care expenses. <a target="_blank" href="http://sebb.naviabenefits.com/">Learn more about DCAP and enroll.</a></p>
                  <p>You can set up an FSA or DCAP account: </p>   
                  <br>
                  <ul>
                    <li>During the SEBB Program's annual open enrollment. </li>
                    <li>No later than 31 days after you become eligible for SEBB benefits.</li>
                    <li>No later than 60 days after you or an eligible family member has a qualifying event that creates a special open enrollment. </li>                  
                  </ul>             
                </div>
                <div class="supplemental-section container-fluid">
                  <h3 class="mobile-text-centered">
                    Health savings account (HSA)
                  </h3>
                  <p>When you enroll in a consumer-directed health plan, you are also enrolled in a health savings account (HSA) through HealthEquity. Your HSA is a tax-advantaged spending and savings account that can be used to pay for qualified medical expenses. Your HSA is funded by pretax contributions from your employer. You can choose to make additional contributions to your HSA. Contact your payroll or benefits office to see if you can arrange automatic payroll deductions to your HSA. <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/health-savings-accounts-hsas" target="_blank">Learn more about health plans with HSAs</a>.</p>
                  <p>For a list of items and services you can pay for with your HSA funds and to see the maximum annual contribution limits to your HSA, visit <a target="_blank" href="https://learn.healthequity.com/sebb/hsa/">HealthEquity's website</a> 
                    or call 1-844-351-6853.
                  </p>
                </div>
                <div class="supplemental-section container-fluid">
                  <img style="width: 35%" src="assets/smarthealth.png" />
                  <p>
                    SmartHealth is Washington State's voluntary wellness program that supports you on your journey toward living well. Join activities that support all of you, including managing stress, building resiliency, and adapting to change. As you progress on your wellness journey, you may also qualify for the SmartHealth wellness incentive. <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-smarthealth">Learn more about SmartHealth.</a>
                  </p>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </hca-prompt>
</div>
