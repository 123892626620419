<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
    <div class="card-body" fxLayout="column">
      <h2 style="margin-top: 8px;" class="mobile-text-centered">Dependents</h2>
      <div class="container-fluid">
        <p>
          Expand the information menu for any dependents you wish to enroll in {{agency}} coverage.
        </p>
        <p>
          Dependents are your family members and include your:
        </p>
        <ul>
          <li>Legal spouse.</li>
          <li>State-registered domestic partner.
            <ul>
              <li>This includes substantially equivalent legal unions from other jurisdictions.</li>
              <li>Strict requirements apply to these partnerships, including that one partner is age 62 or older and you live in the same residence.</li>
            </ul>
          </li>
          <li>Children through the last day of the month in which they turn age 26, regardless of marital status, student status, or eligibility for coverage under another plan. This also includes:
            <ul>
              <li>Stepchildren</li>
              <li>Children age 26 or older with a disability</li>
              <li>Extended dependents</li>
            </ul>
          </li>
        </ul>
        <span *ngIf="selfPay.selfPayType.selfPayTypeCode === 'C'">
          <p>To add new dependents to coverage (not continuing existing coverage) you will need a qualifying event. 
            <a [href]="agency === 'PEBB' ? 'https://www.hca.wa.gov/employee-retiree-benefits/public-employees/change-your-coverage' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/what-special-open-enrollment '" target="_blank">Learn about special open enrollments.</a></p>
          <p>You may enroll your legal spouse, state-registered domestic partner, or your children. State-registered domestic partner is defined in WAC 182-31-020. Individuals in state-registered domestic partnerships are treated the same as legal spouses except when in conflict with federal law.</p>
          <p>Children must be eligible under {{agency}} Program rules. This includes children through the month of their 26th birthday regardless of marital status, student status, or eligibility for coverage under another plan, and children age 26 or older with a disability. Learn more about 
            <a [href]="agency === 'PEBB' ? 'https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/are-my-dependents-eligible' : 'https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/are-my-dependents-eligible'" target="_blank">eligible dependents.</a></p>
        </span>
        <span *ngIf="canAddDependent(selfPay.selfPayType.selfPayTypeCode)">
          <p>To add new dependents click on "add dependent" and input the required information.</p>
        </span>
        <div fxLayout="row" fxLayoutAlign="start-center" *ngIf="canAddDependent(selfPay.selfPayType.selfPayTypeCode)">
          <button class="btn btn-primary" (click)="addNewDependent()">Add dependent</button>
        </div>
        <div id="accordTitle" fxLayout="row" fxLayoutAlign="start-center">
          <h3>Current dependents</h3> <b style="margin-left: 50px;">You must review existing dependent information and coverage options before you can continue.</b>
        </div>
        <div *ngIf="selfPay.formJson?.dependents.length === 0 || !selfPay.formJson">
          <p>No dependent currently on account</p>
        </div>
        <ngb-accordion [destroyOnHide]="false" [closeOthers]="(isRetireeOE || isCobraOE) ? false : true" [activeIds]="(isRetireeOE || isCobraOE)? activeIds : []" #dependentAccord="ngbAccordion">
          <ngb-panel  [id]="dep.socialSecurityNumber" *ngFor="let dep of selfPay.formJson?.dependents; let index = index">
            <ng-template ngbPanelHeader let-opened="opened">
              <button *ngIf="(!isRetireeOE && !isCobraOE) || dep.confirmed" ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                <span *ngIf="dep.lastName && dep.firstName">{{ dep.lastName }}, {{ dep.firstName }}</span>
                <span id="newDep" *ngIf="!dep.lastName || !dep.firstName" style="font-style: italic;">--New--</span>
              </button>
              <button *ngIf="(isRetireeOE || isCobraOE) && !dep.confirmed" class="btn btn-link container-fluid text-left pl-0">
                <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                <span *ngIf="dep.lastName && dep.firstName">{{ dep.lastName }}, {{ dep.firstName }}</span>
                <span id="newDep" *ngIf="!dep.lastName || !dep.firstName" style="font-style: italic;">--New--</span>
              </button>
            </ng-template>
            
            <ng-template ngbPanelContent>
              <form nativeControlElement #selfpayAddDependentForm="ngForm" #formContainer>
                <self-pay-existing-dependent *ngIf="dep?.memberId" [agency]="agency" [selfPay]="selfPay" [requestType]="selfPay.selfPayType" [idx]="index" [selfPayDependent]="dep" (submitDependent)="pushDependent($event, index, dep, selfpayAddDependentForm, formContainer)" (closePanel)="dependentAccord.collapseAll()">
                </self-pay-existing-dependent>
                <!-- new as far as this selfPay -->
                <div *ngIf="!dep.memberId" role="radiogroup" [attr.aria-labelledby]="'relationshipType' + index + '_label'">
                  <h3 class="mobile-text-centered" [id]="'relationshipType' + index + '_label'">Relationship to subscriber</h3>
                  <div class="form-row fxGap-medium-lg" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" style="padding-bottom: 20px;"  fxFlexFill>
                    <div fxFlex="45">
                      <radio-list orientation="vertical" [name]="'relationshipType' + index" [(ngModel)]="dep.relationshipTypeName" required [items]="selfPayRelationshipTypes" keyProperty="name"></radio-list>
                    </div>
                  </div>
                  <self-pay-new-dependent 
                    [selfPay]="selfPay"
                    [agency]="agency"
                    [pebbVisionDate]="pebbVisionDate"
                    [idx]="index"
                    *ngIf="dep.relationshipTypeName !== 'Spouse/state-registered domestic partner'" 
                    [requestType]="selfPay.selfPayType" [idx]="index" [selfPayDependent]="dep" 
                    (submitDependent)="pushDependent($event, index, dep, selfpayAddDependentForm, formContainer)" 
                    (closePanel)="collapseAccordAndRemoveDep()"></self-pay-new-dependent>
                  <self-pay-add-spouse 
                    [selfPay]="selfPay"
                    [agency]="agency"
                    [pebbVisionDate]="pebbVisionDate"
                    [idx]="index"
                    *ngIf="dep.relationshipTypeName === 'Spouse/state-registered domestic partner'" 
                    [requestType]="selfPay.selfPayType" [idx]="index" [selfPaySpouse]="dep" 
                    (submitDependent)="pushDependent($event, index, dep, selfpayAddDependentForm, formContainer)" 
                    (closePanel)="dependentAccord.collapseAll()"></self-pay-add-spouse>
                </div>  
              </form>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div fxFlex="100" style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
        <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
        <button [disabled]="dependentAccord.activeIds.length > 0 && selfPay.formJson?.dependents?.length > 0" (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
      </div>
    </div>
  </div>
  