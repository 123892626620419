import PlanType from './planType';
import Rate from './rate';
import Carrier from './carrier';
import * as dayjs from 'dayjs';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
import {map, find} from 'lodash';

export default class Plan {
  planId: string;
  rateId: string;
  planTypeId: string;
  addressTypeId: string;
  planCode: string;
  planName: string;
  planMessage: string;
  planMessageSelfPay: string;
  planCoverageMessage: string;
  planType: PlanType;
  rates: Rate[];
  benefitAmt: number;
  rateAmt: number;
  planTypeDefaultPlanInd: boolean;
  carrier: Carrier;
  carrierId: string;
  agencyId?: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  sortOrder: number;
  medicareDPlanName: string;


  constructor(plan?) {
    if (plan) {
      this.planId = plan.planId;
      this.rateId = plan.rateId;
      this.planTypeId = plan.planTypeId;
      this.addressTypeId = plan.addressTypeId;
      this.planCode = plan.planCode;
      this.planName = plan.planName;
      this.planMessage = plan.planMessage;
      this.planMessageSelfPay = plan.planMessageSelfPay;
      this.planCoverageMessage = plan.planCoverageMessage;
      this.planType = new PlanType(plan.planType);
      this.rates = plan.rates ? map(plan.rates, r => new Rate(r)) : null;
      this.benefitAmt = plan.benefitAmount;
      this.planTypeDefaultPlanInd = plan.planTypeDefaultPlanInd;
      this.carrier = plan.carrier ? new Carrier(plan.carrier) : null;
      this.carrierId = plan.carrierId;
      if (plan.agencyId) {
        this.agencyId = plan.agencyId;
      }
      this.effectiveStartDate = plan.effectiveStartDate ? new Date(plan.effectiveStartDate) : null;
      this.effectiveEndDate = plan.effectiveEndDate ? new Date(plan.effectiveEndDate) : null;
      this.sortOrder = plan.sortOrder;
      this.medicareDPlanName = plan.medicareDPlanName;
    }
  }

  rateForSpecificDate(coverageDate: Date, familyCompositionCode: string): number {
    const openEndedRate = find
      (this.rates,
        r => dayjs(coverageDate).isSameOrAfter(r.effectiveStartDate) &&
          (!r.effectiveEndDate || dayjs(coverageDate).isSameOrBefore(r.effectiveEndDate)) &&
          (r.familyCompositionCode === familyCompositionCode || r.familyCompositionCode === 'A')
      );
    if (openEndedRate) {
      return openEndedRate.rateAmt || 0.0;
    } else {
      const closeEndedRate = find(this.rates, r =>  dayjs(coverageDate).isBetween(r.effectiveStartDate, r.effectiveEndDate, 'day', '[]')
        && (r.familyCompositionCode === familyCompositionCode || r.familyCompositionCode === 'A'));
      return closeEndedRate ? closeEndedRate.rateAmt : 0.00;
    }
  }

  isActive(activeForDate?: Date): boolean {
    // If not supplied, use current date
    activeForDate = activeForDate ?? new Date();
    return (dayjs(activeForDate).isSameOrAfter(dayjs(this.effectiveStartDate).startOf('day')) && 
      (!this.effectiveEndDate  || 
        (this.effectiveEndDate && dayjs(activeForDate).isBetween(dayjs(this.effectiveStartDate).startOf('day'), dayjs(this.effectiveEndDate).endOf('day')))));
  }
}
