// ng
import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation, OnChanges, OnDestroy, Input, EventEmitter, Output, HostListener } from '@angular/core';

// ext
import { find, includes, map } from 'lodash';

// local
import DependentComposite from 'src/app/models/dependentComposite';
import RelationshipType from 'src/app/models/relationshipType';
import DependentDisplay from 'src/app/models/dependentDisplay';
import PlanType from 'src/app/models/planType';
import Response from 'src/app/models/response';
import { env } from 'src/env/development';
import Subscriber from 'src/app/models/subscriber';
@Component({
  selector: 'dep-review',
  templateUrl: 'dep.review.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentReviewComponent implements OnChanges, OnDestroy {
  icons = {
  };
  @Output() okayToContinue: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveAndContinue: EventEmitter<DependentComposite> = new EventEmitter<DependentComposite>();
  @Input() dependent: DependentComposite;
  @Input() selectedPlanIds: string[] = [];
  @Input() isSP = false;
  @Input() isSebb = false;
  @Input() electablePlanTypes: PlanType[];
  @Input() saveDependentSubmitted: boolean = false;
  @Input() subscriber: Subscriber;
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    subagencies: [],
    phoneNumberTypes: [],
    eligibilityReasons: [],
    terminationReasons: [],
    planTypes: [],
    responses: []
  };
  dependentDisplay: DependentDisplay = new DependentDisplay();
  ableToHaveMedical = false;
  ableToHaveDental = false;
  ableToHaveVision = false;
  @HostListener('scroll', ['$event.target']) onscroll(elem): void {
    const target = elem.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.okayToContinue.emit();
    }
  }
  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnChanges(): void {
    if (this.dependent) {
      const availablePlans = map(this.electablePlanTypes, 'planTypeCode');
      this.ableToHaveDental = availablePlans.includes(env.dentalPlanTypeCode);
      this.ableToHaveMedical = availablePlans.includes(env.medicalPlanTypeCode);
      this.ableToHaveVision = availablePlans.includes(env.visionPlanTypeCode);
      const relationshipType = find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt.relationshipTypeId === this.dependent.relationshipTypeId);
      this.dependentDisplay.firstName = this.dependent.firstName;
      this.dependentDisplay.lastName = this.dependent.lastName;
      this.dependentDisplay.birthDate = this.dependent.birthDate;
      this.dependentDisplay.relationshipTypeName = relationshipType ? relationshipType.relationshipTypeName : '';
      this.dependentDisplay.enrolledInMedical = this.selectedPlanIds.includes(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.medicalPlanTypeCode).planTypeId);
      this.dependentDisplay.enrolledInDental = this.selectedPlanIds.includes(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.dentalPlanTypeCode).planTypeId);
      this.dependentDisplay.enrolledInVision = this.selectedPlanIds.includes(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.visionPlanTypeCode).planTypeId);
      this.dependentDisplay.tobaccoUse = this.dependent.tobaccoResponseId === find(this.lookups.responses, (r: Response) => r.responseCode === 'Y').responseId;
      this.dependentDisplay.spousalSurcharge = this.dependent.spousalResponseId === find(this.lookups.responses, (r: Response) => r.responseCode === 'Y').responseId;
      this.dependentDisplay.isSpouseInd = relationshipType ? relationshipType.relationshipTypeCode === 'S' : false ;
      this.dependentDisplay.medicarePartAEnrolled = this.dependent.memberMedicare?.medicarePartAEnrolledInd;
      this.dependentDisplay.medicarePartBEnrolled = this.dependent.memberMedicare?.medicarePartBEnrolledInd;
    }
  }

  ngOnDestroy(): void {

  }

  confirmChanges(): void{
    this.saveAndContinue.emit();
  }

}
