export default class DocumentType {
  documentTypeId: string;
  documentTypeCode: string;
  documentTypeName: string;
  specialOpenEnrollmentInd: boolean;
  dependentVerificationInd: boolean;
  obsoleteDate: Date;
  obsoleteFlag: boolean;

  constructor(documentType?) {
    if (documentType) {
      this.documentTypeId = documentType.documentTypeId;
      this.documentTypeCode = documentType.documentTypeCode;
      this.documentTypeName = documentType.documentTypeName;
      this.specialOpenEnrollmentInd = documentType.specialOpenEnrollmentInd;
      this.dependentVerificationInd = documentType.dependentVerificationInd;
      this.obsoleteDate = documentType.obsoleteDate ? new Date(documentType.obsoleteDate) : null;
      this.obsoleteFlag = documentType.obsoleteFlag;
    }
  }

}
