<form nativeControlElement *ngIf="dependent" #memberForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill #formContainer>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group required" fxFlex="35">
      <label for="relationshipTypeId{{index}}">Relation</label>
      <select [disabled]="!isHCAWithEdit" [(ngModel)]="dependent.relationshipTypeId" (change)="changeRelationshipType()" required class="form-control" 
        name="relationshipTypeId{{index}}"
        id="relationshipTypeId{{index}}">
        <option></option>
        <option *ngFor="let rt of lookups.relationshipTypes | isObsolete" [ngValue]="rt.relationshipTypeId">{{ rt.relationshipTypeName }}</option>
      </select>
    </div>
    <div class="form-group required" fxFlex="35">
      <label [for]="'relationshipQualifyReasonId' + index">Qualify reason</label>
      <select [disabled]="isReadOnly || 
          (
            !isHCAWithEdit &&   
            (!dependent.relationshipTypeId || !isSpouse(dependent.relationshipTypeId)) && 
            !(dependent.birthDate < twentySixDate 
              && spousalRelationshipTypeId !== dependent.relationshipTypeId)
          )
          " required 
        class="form-control" #relationshipQualifyReason="ngModel" [name]="'relationshipQualifyReasonId' + index"
        [id]="'relationshipQualifyReasonId' + index"
        [(ngModel)]="dependent.relationshipQualifyReasonId">
        <option></option>
        <option                                                                                                                                                                                                                                             
          *ngFor="                      
            let qr of lookups.relationshipQualifyReasons
              | isObsolete
              | allowedValues
                : (dependent.birthDate < twentySixDate ? rtToQrMap26[relationshipTypeCode(dependent.relationshipTypeId)] : rtToQrMap[relationshipTypeCode(dependent.relationshipTypeId)])
                : 'relationshipQualifyReasonCode'
          "
          [ngValue]="qr.relationshipQualifyReasonId"                                         
        >
          {{ qr.relationshipQualifyReasonName }}
        </option>
      </select>
      <div *ngIf="relationshipQualifyReason.invalid && (relationshipQualifyReason.touched || relationshipQualifyReason.dirty)" class="alert alert-danger">
          Qualify reason missing or may be invalid based on relationship type and/or birthdate entered, please re-enter a valid qualify reason.
      </div>
    </div>
    <div class="form-group required" fxFlex="20">
      <label [for]="'socialSecurityNumber' + index" [id]="'ssnLabel' + index">SSN</label>
      <kendo-maskedtextbox
      [mask]="env.ssnMask"
      [pattern]="env.ssnItinRegex"
      [ngModel]="(!noSSN || showPseudoSSN ? dependent.socialSecurityNumber : null)"
      [maskOnFocus]="true"
      [required]="!isHCAWithEdit || !noSSN"
      [name]="'socialSecurityNumber' + index"
      [id]="'socialSecurityNumber' + index"
      [attr.aria-labelledby]="'ssnLabel' + index"
      #ssn="ngModel"
      class="form-control"
      [forbiddenValues]="env.invalidSSNValues"
      (ngModelChange)="dependent.socialSecurityNumber = $event"
      [disabled]="!isHCAWithEdit && (noSSN || isReadOnly || subscriber?.lossOfEligibilityDate)"
    ></kendo-maskedtextbox>
      <div *ngIf="ssn.invalid && ssn.touched && (!!ssn.errors.forbiddenValues || !!ssn.errors.pattern)" class="alert alert-danger">This SSN is invalid.</div>
      <div *ngIf="ssn?.errors?.existsOnSubscriber" class="alert alert-danger">The dependent SSN matches an SSN already associated to this subscriber account. Therefore, the SSN changes cannot be processed. Submit your changes again after corrections.</div>
      <div *ngIf="ssn?.errors?.existsExternallySEBB" class="alert alert-danger">The dependent changes matches an enrollee with coverage in our School Employees Benefit (SEBB) program. Dual enrollment between programs is not allowed. Therefore, the SSN changes cannot be processed. Submit your changes again after corrections or contact Washington Health Care Authority for assistance.</div> 
      <div *ngIf="ssn?.errors?.existsExternallyPEBB" class="alert alert-danger">The dependent changes matches an existing subscriber or dependent with overlapping coverage in our Public Employees Benefit Board (PEBB) program. Therefore, the SSN changes cannot be processed. Submit your changes again after corrections or contact Washington Health Care Authority for assistance.</div> 
      <div class="k-form-field mgtp-small">
        <checkbox [disabled]="isReadOnly || subscriber?.lossOfEligibilityDate" (change)="handleNoSSN($event)" bold name="noSSN{{index}}" [(ngModel)]="noSSN">
          This person currently has no social security number
        </checkbox>
      </div>
      <div *ngIf="noSSN" class="form-group" fxFill="40">
        <br />
        <strong>You will be required to update this information once available.</strong>
      </div>
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group required" fxFlex="30">
      <label [for]="'firstName' + index">First name</label>
      <input [disabled]="isReadOnly" maxlength="15" pattern="^(?!\s)([a-zA-Z \-]){1,}$" required type="text" class="form-control" 
        [name]="'firstName' + index" [id]="'firstName' + index" [(ngModel)]="dependent.firstName" />
    </div>
    <div class="form-group required" fxFlex="30">
      <label [for]="'lastName' + index">Last name</label>
      <input [disabled]="isReadOnly" maxlength="20" pattern="^(?!\s)([a-zA-Z \-]){1,}$" required type="text" class="form-control" 
        [name]="'lastName' + index" [id]="'lastName' + index" [(ngModel)]="dependent.lastName" />
    </div>
    <div class="form-group" fxFlex="15">
      <label for="middleName{{index}}">Middle name</label>
      <input [disabled]="isReadOnly" type="text" pattern="^(?!\s)([a-zA-Z \-]){1,}$" maxLength="15" class="form-control" 
        name="middleName{{index}}" id="middleName{{index}}" [(ngModel)]="dependent.middleName" />
    </div>
    <div class="form-group" fxFlex="15">
      <label for="suffix{{index}}">Suffix</label>
      <input [disabled]="isReadOnly" pattern="[a-zA-Z]*" maxlength="3" type="text" class="form-control" 
        name="suffix{{index}}" 
        id="suffix{{index}}" 
        [(ngModel)]="dependent.suffix" placeholder="JR, SR" />
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlex="70">
    <div class="form-group" fxFlex="25">
      <date-picker
        #birthDate="ngModel"
        [name]="'birthDate' + index"
        [id]="'birthDate' + index"
        label="Birth date"
        [disabled]="isReadOnly || (dependent.simplifiedStatus !== 'Pending' && (isPerspayWithEdit || isSubscriber))"
        required
        [min]="minBirthDate"
        [max]="dependent.relationshipTypeId === spousalRelationshipTypeId ? maxBirthDateForSpouse : now"
        [(ngModel)]="dependent.birthDate"
        (ngModelChange)="updateBirthDate($event)"></date-picker>
      <div *ngIf="birthDate.invalid && (birthDate.touched || birthDate.dirty)" class="alert alert-danger">
        Birth date is required and cannot be in the future or more than 110 years in the past.
      </div>
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="birthSexId{{index}}">Sex assigned at birth</label>
      <select [disabled]="isReadOnly" required class="form-control" 
        name="birthSexId{{index}}" 
        id="birthSexId{{index}}" 
        [(ngModel)]="dependent.birthSexId">
        <option></option>
        <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
      </select>
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="genderIdentityId{{index}}">Gender Identity</label>
      <select [disabled]="isReadOnly" class="form-control" 
        name="genderIdentityId{{index}}" 
        id="genderIdentityId{{index}}" 
        [(ngModel)]="dependent.genderIdentityId"
        required>
        <option></option>
        <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
      </select>
      <div class="mgtp-small">
        Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law. To learn more, visit HCA's website at
        <a target="_blank" href="https://www.hca.wa.gov/about-hca/gender-identity-information">hca.wa.gov/gender-x.</a>
      </div>
    </div>
    <div *ngIf="dependent.relationshipTypeId && dependent.relationshipTypeId === spousalRelationshipTypeId" class="form-group" fxFlex="20">
      <date-picker
        #psd="ngModel"
        [name]="'psd' + index" 
        [id]="'psd' + index" 
        label="Partnership start date"
        [disabled]="isReadOnly || isPerspayWithEdit || isSubscriber"
        required
        [min]="dependent.birthDate"
        [canBeInFuture]="false"
        [(ngModel)]="dependent.partnershipStartDate"></date-picker>
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <checkbox [disabled]="isReadOnly" bold name="addressIsSameAsSubscriberInd{{index}}" [(ngModel)]="dependent.addressIsSameAsSubscriberInd">
      Residential address is the same as subscriber
    </checkbox>
    <div *ngIf="dependent.deceasedDate" class="form-group">
      <date-picker
        #deathDate="ngModel"
        [name]="'deathDate' + index"
        label="Deceased date"
        [disabled]="isReadOnly || isPerspayWithEdit || isSubscriber"
        required
        [lockToMonth]="this.dependent.partnershipEndDate ?? this.dependent.deceasedDate"
        [(ngModel)]="dependent.deceasedDate"></date-picker>
    </div>
    <div *ngIf="dependent.partnershipEndDate" class="form-group">
      <date-picker
        #divorceDate="ngModel"
        [name]="'divorceDate' + index"
        label="Divorce date"
        [disabled]="isReadOnly || isPerspayWithEdit || isSubscriber"
        required
        [lockToMonth]="this.dependent.partnershipEndDate ?? this.dependent.deceasedDate"
        [(ngModel)]="dependent.partnershipEndDate"></date-picker>
    </div>
    <div *ngIf="((isHCAWithEdit || isPerspayWithEdit) && dependent.hasDivorceIndicator)">
      <div class="form-group">
          <label [for]="'divorceWithin60DaysInd' + index">60 day indicator</label>
          <select class="form-control" [name]="'divorceWithin60DaysInd' + index" [id]="'divorceWithin60DaysInd' + index" [(ngModel)]="dependent.enrollments[0].divorceWithin60Days"
            [disabled]="!isHCAWithEdit"
            (change)="setDivorceWithin60DaysEnrollments.emit({enrollments: dependent.enrollments, enrollment: dependent.enrollments[0]})">
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
    </div>    
  </div>
  <!-- ADDRESS -->
  <div fxFlexFill *ngIf="!dependent.addressIsSameAsSubscriberInd">
    <address #residentialAddress name="residentialAddress" label="Residential address" hideCounty [(ngModel)]="dependent"></address>
  </div>
  <br />
  <br />
  <div class="mgbt-medium" *ngIf="dependent.relationshipQualifyReasonId && extendedOrDisabledRelationshipQualifyIds.includes(dependent.relationshipQualifyReasonId)">
    <span *ngIf="!subscriber.isSebb">
        If enrolling a dependent child with a disability age 26 or older, you must also complete the <a href="https://www.hca.wa.gov/assets/pebb/50-0142-pebb-certification-child-with-disability.pdf" target="_blank">PEBB Certification of a Dependent with a Disability</a> 
        and return it as instructed on the form or your child will not be enrolled in coverage. Refer to the <a href="hhttps://www.hca.wa.gov/employee-retiree-benefits/forms-and-publications?combine=50-0100&field_pebb_document_type_value_selective=All&field_peb_topic_tid=All&field_customer_type_tid=All&field_year_tid_selective=All&field_erb_plan_tid_selective=All&sort_bef_combine=name_ASC" target="_blank">
          PEBB Employee Enrollment Guide</a> for eligibility information. 
        <br/><br/>
        If enrolling an extended dependent that is also a child with a disability who is age 26 or older you must submit both a PEBB Certification of a Child with a Disability and a PEBB Extended Dependent Certification.
        <br/><br/>
        If enrolling an extended dependent also upload a <a href="https://www.hca.wa.gov/assets/pebb/50-0500-pebb-extended-dependent-certification.pdf" target="_blank">PEBB Extended Dependent Certification Form</a>.
    </span>
    <span *ngIf="subscriber.isSebb">
      If enrolling a dependent child with a disability age 26 or older, also complete the SEBB certification of a dependent with a disability form and return as instructed on the form. Refer to the 2024 SEBB Employee Enrollment Guide for eligibility information. 
      <br />
      <br />
      <a href="https://www.hca.wa.gov/assets/pebb/20-0061-sebb-certification-child-with-disability.pdf" target="_blank">SEBB Certification of a dependent child with a disability form</a>
      <br />
      <br />
      <a href="https://www.hca.wa.gov/assets/pebb/20-0049-school-employee-enrollment-guide-2024.pdf" target="_blank">2024 School Employee Enrollment Guide</a>
      <br />
      <br />
      If enrolling an extended dependent also upload a SEBB Extended Dependent Certification form.
      <br />
      <br />
      <a href="https://www.hca.wa.gov/assets/pebb/20-0084-sebb-extended-dependent-certification.pdf" target="_blank">SEBB Extended Dependent Certification form</a>
    </span>
  </div>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
    <button [disabled]="isReadOnly" fxFlex="30" id="sub-add-submit-btn{{index}}" (click)="submitDependent(formContainer)" class="btn btn-primary">Submit Changes</button>
    <button [hidden]="dependent.member.isSubscriberInd" [disabled]="isReadOnly" fxFlex="30" id="sub-delete-dep-btn{{index}}" (click)="deleteDependent()" class="btn btn-light">Delete Dependent from Account</button>
    <button [disabled]="isReadOnly" fxFlex="30" id="sub-add-cancel-btn{{index}}" (click)="cancelChanges()" class="btn btn-light">Cancel Changes</button>
  </div>
</form>
