import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import SystemUser from '../../../../../../models/user';
import { upperFirst, startCase } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Agency from 'src/app/models/agency';

@UntilDestroy()
@Component({
  selector: 'lookups',
  templateUrl: 'lookups.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class LookupsComponent implements OnInit {
  icons = {
    faPlus,
    faMinus
  };
  objectKeys = Object.keys;
  systemUser: SystemUser;
  filterString = '';
  lutCollapsed: any = {};
  lutName: string;
  @Input() readonly = false;
  @Input() lookups: any;
  @Input() alwaysExpanded = false;
  @Input() oe = false;
  @Input() genericMessages = false;
  @Input() appSettings = false;
  @Input() checklist = [];
  @Input() disabledDatesFn: Function;
  @Input() agencies: Agency[];
  @Output() handlePut: EventEmitter<any> = new EventEmitter<any>();
  @Output() handlePost: EventEmitter<any> = new EventEmitter<any>();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.systemUser = data.user;
      },
      (err) => console.log(err)
    );

    if (this.appSettings) {
      this.lutName = 'applicationSetting';
    }
  }

  handleFilterChange(e): void {
    this.filterString = e;
  }

  prettyUpHeader(str): string {
    return upperFirst(startCase(str));
  }
}
