import { RoleGuard } from './../role/role.guard';
/**
 * Acts as an orchestrator for implementing N guards on any given route.
 */

 // ng
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

// ext
import { lastValueFrom, Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

// local
import { AuthGuard } from 'src/app/guards/auth/auth.guard';
import { UserService } from 'src/app/services/user.service';


@Injectable()
export class MasterGuard implements CanActivate {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private userService: UserService,
  ) {}

  private route: ActivatedRouteSnapshot;
  private state: RouterStateSnapshot;

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.route = route;
    this.state = state;

    if (!route.data) {
      return Promise.resolve(true);
    }

    if (!this.route.data.guards || !this.route.data.guards.length) {
      return Promise.resolve(true);
    }
    return Promise.resolve(this.executeGuards());
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

  // Execute the guards sent in the route data
  public executeGuards(guardIndex: number = 0): Promise<boolean> {
   return lastValueFrom(this.activateGuard(this.route.data.guards[guardIndex])
      )
      .then(() => {
          if (guardIndex < this.route.data.guards.length - 1) {
            return this.executeGuards(guardIndex + 1);
          } else {
            return true;
          }
      })
      .catch(() => {
        return false;
      });
  }

  // Create an instance of the guard and fire canActivate method returning observable for boolean;
  public activateGuard(guardKey: string): Observable<boolean> {
    let guard: any;

    switch (guardKey) {
      case 'auth':
        guard = new AuthGuard(this.oidcSecurityService, this.router);
        break;
      case 'role':
        guard = new RoleGuard(this.userService, this.router);
        break;
      default:
        break;
    }
    return guard.canActivate(this.route, this.state);
  }
}
