<div class="card-body" style="margin-left:15px;">
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="subscriberName" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Subscriber name:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="subscriberName" value="{{subscriber.firstName}} {{subscriber.lastName}}">
  </div>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="countyOfResidence" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">County of residence:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="countyOfResidence" value="{{subscriber.countyOfResidence}}">
  </div>
  <div class="row" fxLayoutAlign="start center">
    <label for="emailaddress" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Email address:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="emailaddress" value="{{subscriber.emailAddress}}">
  </div>
  <div class="row hca-dashboard-row mgbt-x-small" fxLayoutAlign="start center">
    <div style="padding:0px;">
      <div class="k-form-field bigger">
          <checkbox 
            name="emailNotificationInd" 
            [disabled]="(inAdminState && !coreService.systemUserHasAccess('edit'))" 
            bold
            #emailNotificationInd="ngModel"  
            [(ngModel)]="subscriberContact.emailNotificationInd"
            (change)="emailSubscriptionChanged()">
          {{inAdminState ? 'Subscriber wishes' : 'You wish'}} to receive email notifications from the {{subscriber?.agencyCode}} program
        </checkbox>
      </div>
    </div>
  </div>
  <div class="row" style="padding-top: 15px;" *ngIf="showEmailTermsAndConditions">
    <terms-and-conditions [agencyCode]="subscriber.agencyCode" [submitTermsTrueValue]="true" (submitTerms)="submitEmailSubscriptionChange($event)"></terms-and-conditions>
  </div>
  <div *ngIf="isRetiree" fxLayoutAlign="space-between center" fxFlexFill fxLayout="column" class="h4">
    <a href="https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2024.pdf" target="_blank" class="">Click here to view the 2024 premiums</a>
  </div>
  <div class="row" style="padding: 15px 15px 15px 0px;">
    <hr fxFill>
  </div>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="effectiveMedicalPlan" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Current medical plan:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="effectiveMedicalPlan" value="{{subscriber.effectiveMedicalPlan}}">
  </div>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="medicalPremium" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Medical premium:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="70" id="medicalPremium" [value]="isRetiree ? '' : shouldShowPremiums ? (subscriber.medicalPremium | currency) : 'You must contact your benefits office for premium information'">
  </div>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="tobaccoUseSurcharge" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Tobacco use surcharge*:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="tobaccoUseSurcharge" [value]="isRetiree ? '' : subscriber.tobaccoUseSurcharge | currency">
  </div>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="spousalCoverageSurcharge" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Spousal coverage surcharge*:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="spousalCoverageSurcharge" [value]="isRetiree ? '' : subscriber.spousalCoverageSurcharge | currency">
  </div>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="totalCost" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Total:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="totalCost" [value]="isRetiree ? '' : subscriber.totalCost | currency">
  </div>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <span fxFlex="100" fxFlex.gt-sm="30"></span>
    <span class="hca-warn-text float-right" fxFlex="100" fxFlex.gt-sm="50" id="surchargeExplaination">*Surcharges are in addition to the monthly medical premium.</span>
  </div>
  <div class="row" style="padding: 15px 15px 15px 0px;">
    <hr fxFill>
  </div>
  <div *ngIf="shouldShowDentalCoverageDetail" class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="currentDentalPlan" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Current dental plan:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="currentDentalPlan" value="{{subscriber.effectiveDentalPlan}}">
  </div>
  <div *ngIf="shouldShowDentalCoverageDetail" class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="dentalPremium" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Dental premium:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="70" id="dentalPremium" [value]="isRetiree ? '' : shouldShowPremiums ? (subscriber.dentalPremium | currency) : 'You must contact your benefits office for premium information'">
  </div>
  <div *ngIf="isVisionEligible" class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="currentVisionPlan" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Current vision plan:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="30" id="currentVisionPlan" value="{{subscriber.effectiveVisionPlan}}">
  </div>
  <div *ngIf="isVisionEligible" class="row hca-dashboard-row" fxLayoutAlign="start center">
    <label for="visionPremium" fxFlex="100" fxFlex.gt-sm="30" style="font-weight: bold;">Vision premium:</label>
    <input type="text" readonly class="form-control-plaintext" fxFlex="100" fxFlex.gt-sm="70" id="visionPremium" [value]="shouldShowPremiums ? (subscriber.visionPremium | currency) : 'You must contact your benefits office for premium information'">
  </div>  
</div>
