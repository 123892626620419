import Subscriber from 'src/app/models/subscriber';
import { Component, ViewEncapsulation, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { some, filter } from 'lodash';
import SubscriberContact from 'src/app/models/subscriberContact';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
import { CoreService } from 'src/app/services/core.service';
import { env } from 'src/env/development';
import PlanType from 'src/app/models/planType';
import { AdminService } from 'src/app/services/admin.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import * as dayjs from 'dayjs';

@Component({
  selector: 'summary-overview',
  templateUrl: 'summary.overview.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SummaryOverviewComponent implements OnInit, OnChanges {
  @Input() subscriber: SubscriberEffectiveSummary;
  @Input() subscriberContact: SubscriberContact;
  @Input() inAdminState: boolean;
  @Input() isFullBenefits: boolean;
  @Input() subscriberModel: Subscriber;
  @Output() emailSubscriptionUpdated: EventEmitter<boolean> = new EventEmitter();
  showEmailTermsAndConditions = false;
  shouldShowPremiums = true;
  isRetiree = false;
  isVisionEligible = false;
  electablePlanTypes: PlanType[];
  shouldShowDentalCoverageDetail = false;
  
  constructor(public coreService: CoreService, private adminService: AdminService) {}

  async ngOnInit(): Promise<void> {
    const pebbVisionDate = await lastValueFrom(this.adminService.getFeatureFlagEffectiveDate('FEATURE_SYNC_ENABLE_PEBB_VISION'));
    const startDate = this.subscriber?.currentlyEffectiveEnrollmentPeriod?.coverageEffectiveStartDate;
    this.shouldShowPremiums = !some(env.memberTypesToHidePremiumsFor, (memberType: string) => memberType === this.subscriber.memberType?.memberTypeCode);
    this.isVisionEligible = this.subscriber.agencyCode === 'SEBB' || (this.subscriber.agencyCode === 'PEBB' && startDate && dayjs(startDate).isSameOrAfter(pebbVisionDate));
    this.refreshCoverageVars();  
  }

  ngOnChanges(): void {
    this.refreshCoverageVars();  
  }

  refreshCoverageVars() {
    this.isRetiree = env.retireeMemberTypes.includes(this.subscriberModel?.memberType?.memberTypeCode);
    this.electablePlanTypes = filter(this.subscriber?.electablePlanTypes, (ept: PlanType) => some(env.planTypesToDisplay, (pt: string) => ept.planTypeCode === pt));
    this.shouldShowDentalCoverageDetail = some(this.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === env.dentalPlanTypeCode);
  }

  emailSubscriptionChanged(): void {
    const updatedValue = this.subscriberContact.emailNotificationInd;
    this.showEmailTermsAndConditions = updatedValue;
    if (!updatedValue) {
      this.submitEmailSubscriptionChange(updatedValue);
    }
  }

  submitEmailSubscriptionChange(value): void {
    this.subscriberContact.emailNotificationInd = value;
    this.showEmailTermsAndConditions = false;
    this.emailSubscriptionUpdated.emit(value);
  }
}
