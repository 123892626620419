<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <div class="state">
      <div class="card">
        <div class="card-body">
            <h1 class="mobile-text-centered">{{ currentOrganization?.organizationName }}</h1>
            <h2 class="mobile-text-centered" style="margin-top:8px; margin-bottom:15px;">Eligibility File Upload</h2>
            <div fxLayout="row">
            <div class="form-group col-md-5">
                <div class="k-form-field">
                    <input class="k-checkbox" type="checkbox" id="showDependent" name="showDependent"  [(ngModel)]="showDependentEligibility" (change)="updateDataForCurrentOrganization()">
                    <label class="k-checkbox-label" for="showDependent" style="font-weight: bold;">Manage dependent eligibility uploads</label>
                </div>
                </div>
            </div>
            <div *ngIf="errorResponses">
                <div><strong>Last eligibility file uploaded:</strong><span>&nbsp;{{submissionFile?.uploadDate | date}}</span></div>
                <br>
                <br>
                <h3>The file contained <strong>{{errorResponses.length}}</strong> errors or warnings.</h3>
            </div>
            <generic-file-upload
                *ngIf="currentOrganization && (isPerspayEdit || isHca)"
                [currentOrganization]="currentOrganization"
                [allowedFileTypes]="allowedFileTypes"
                [systemUser]="systemUser"
                [multiple]="false"
                [autoUpload]="false"
                [type]="documentType"
                [maxfilesize]="31457280"
                (selectEventHandler)="handleFilesSelected($event)"
            ></generic-file-upload>
            <error-review [uploadDate]="uploadDate" [errorResponses]="errorResponses"></error-review>
        </div>
      </div>
    </div>
</div>