<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body page-container">
        <div *ngIf="currentOrganization">
          <h1 *ngIf="currentOrganization">{{ currentOrganization.organizationName }}</h1>
          <h2 class="mgtp-small">Manage Subscribers</h2>
        </div>
        <div *ngIf="!currentOrganization">
          <h1>Manage {{selfPayOnly? 'Self-pay' : ''}} Subscribers</h1>
        </div>        
        <div class="dashboard-grid-parent mgbt-medium">
          <div fxLayout="column" class="fxGap-medium" fxLayoutAlign="space-between" class="mgbt-small">
            <p>Use this section to perform the following actions for subscribers (employees):</p>
            <ul>
              <li>Review your subscriber’s current account information and coverage selections.</li>
              <li>View and/or print your subscriber’s Statement of Insurance.</li>
              <li>Review your subscriber’s enrollment, dependents and benefit elections.</li>
            </ul>
          </div>
          <program-filter [(agencyFilter)]="agencyFilter" *ngIf="!currentOrganization && isHCA && !selfPayOnly"></program-filter>
          <div
            fxLayout="row"
            class="fxGap-medium"
            fxLayoutAlign="space-between"
            style="padding-bottom: 10px; height: 60px;"
          >
            <subscriber-search style="flex: 70;" (searchSubmitted)="onSearchSubmitted($event)"></subscriber-search>
            <div style="display: block;" class="fxGap-x-small" fxLayoutAlign="flex-end" aria-live="assertive">
              <div *ngIf="(isHcaEditOrAdmin && !currentOrganization)" fxLayout="column">
                <button (click)="addSubscriber()"class="btn btn-light" style="height:100%;">Add self-pay subscriber</button>
              </div>
              
              <button *ngIf="currentOrganization && coreService.systemUserHasAccess('edit')" (click)="addSubscriber()" class="btn btn-light" >Add new subscriber</button>
              <button *ngIf="selectedSubscriber" (click)="manageMember()" class="btn btn-primary">
                Manage associated subscriber
              </button>
            </div>
          </div>
          <!-- <div style="text-align: center" *ngIf="!enrollmentsForTerminate.length && terminateCoverageOpen">
            This member's coverage has either already been terminated or they have no active/pending enrollments. To
            re-enroll this member, please select manage subscriber and navigate to coverage below.
          </div> -->
          <generic-grid
            (selectedRow)="onSubscriberSelected($event)"
            [selectBy]="'memberId'"
            [selectable]="true"
            [selectedKeys]=""
            [pageable]="true"
            [pageSize]="20"
            [gridData]="gridData"
            [gridColumns]="gridColumns"
            [filterable]="true"
          ></generic-grid>
        </div>
        <div *ngIf="memberSelectedForManagement && memberSelectedForManagement?.memberId === selectedSubscriber?.memberId && selectedSubscriber?.systemUserId && 
          (isPersPayEdit || isAccessManager || isHcaEditOrAdmin || (isReadOnly && currentOrganization?.disallowLoginInd))"
          fxLayout="row" fxLayoutAlign="space-around" style="padding-bottom: 20px;">
            <button fxFlex="40" (click)="disassociateAccount(selectedSubscriber)" class="btn btn-light">
                Release/unlock subscriber account?
            </button>
            <b fxFlex="40" style="align-self: center;">The member will be prompted to re-claim their account upon next login.</b>
        </div>
        <div [hidden]="!memberSelectedForManagement && !addingSubscriber" fxLayout="column">
          <router-outlet
              #subscriberManagementOutlet
              id="userManagementOutlet"
              (deactivate)="roDeactivated($event)"
          >
            <subscriber-management-nav
              [isSelfPay]="selectedSubscriber?.isSelfPay"
              [isHCA]="isHCA"
              [showLOEState]="showLOEState"
              [isOE]="isOE" 
              *ngIf="!addingSubscriber && memberSelectedForManagement"
              [member]="memberSelectedForManagement"
            >
            </subscriber-management-nav>
          </router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
