import { sortBy } from 'lodash';
import Response from './response';

export default class Question {
  questionId: string;
  questionName: string;
  question: string;
  questionCode: string;
  obsoleteFlag?: boolean;
  obsoleteDate?: Date;
  responses?: Response[];
  sortOrder: number;

  constructor(question?) {
    if (question) {
      this.questionId = question.questionId;
      this.questionName = question.questionName;
      this.question = question.questionText;
      this.obsoleteFlag = question.obsoleteFlag;
      this.obsoleteDate = question.obsoleteDate ? new Date(question.obsoleteDate) : null;
      this.sortOrder = question.sortOrder;
      this.questionCode = question.questionCode;
      if (question.responses) {
        this.responses = sortBy(question.responses.map(r => new Response(r)), 'responseName');
      }
    }
  }

}
