import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
/**
 * The root component. All other states are injected based on routing in this components
 * router-outlet. Global componenets (agency select, header, footer) declared here.
 */

// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

// ext
import { Subscription } from 'rxjs';
import { upperFirst, some } from 'lodash';

// local
import { env } from 'src/env/development';
import { CoreService } from 'src/app/services/core.service';
import MemberSummary from 'src/app/models/memberSummary';
import Plan from 'src/app/models/plan';

@Component({
  selector: 'waive',
  templateUrl: 'waive.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class WaiveComponent implements OnInit, OnChanges {
  @Input() planType: string;
  @Input() isWaived = false;
  @Input() isDeferRetiree = false;
  @Input() isDeferMedicaid = false;
  @Input() isEnrolledRetireeTermLife = false;
  @Input() isEnrolledBasicLife = false;
  @Input() isRetiree = false;
  @Input() isCobraOrUnpaid = false;
  @Input() isUnpaidMedDen = false;
  @Input() inAdminState = false;
  @Input() selectedPlan: Plan;
  @Input() selfPayWaivingMedicalCoverage: boolean;
  @Input() hasDependentsEnrolled: boolean;
  @Input() subscriberSummary: SubscriberEffectiveSummary;
  @Output() updateWaivedPlan: EventEmitter<{ planType: string; waived: boolean }> = new EventEmitter<{ planType: string; waived: boolean }>();
  @Output() subscriberIsDeferringDueToMedicaid: EventEmitter<void> = new EventEmitter();
  @Output() cobraOrUnpaidWaiving: EventEmitter<{ planType: string; waived: boolean, selfPayWaivingMedicalCoverage: boolean }> = new EventEmitter();
  @Output() enrollRTL: EventEmitter<boolean> = new EventEmitter();
  @Output() enrollBL: EventEmitter<boolean> = new EventEmitter();
  @Output() deferRetireeChange: EventEmitter<boolean> = new EventEmitter();
  dependentIsEnrolledMedical = false;
  availablePlanColumns = [];
  selectedPlanId;
  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.planType === env.medicalPlanTypeName && changes.selectedPlan && !changes.selectedPlan.firstChange && changes.selectedPlan.currentValue) {
      this.isDeferRetiree = false;
      this.isWaived = false;
    }
  }

  onWaiveSelected(value): void {
    this.updateWaivedPlan.emit({ planType: this.planType, waived: value });
  }

  enrollRetireeTermLife(): void {
    if (this.isEnrolledRetireeTermLife) {
      // enrolling
      this.enrollRTL.emit(true);
    } else {
      // disenrolling
      this.enrollRTL.emit(false);
    }
  }

  deferRetiree(e): void {
    // only one defer may be selected at a time
    // works the same as waiving for pebb elig.
    if (e && this.selfPayWaivingMedicalCoverage) {
      this.selfPayWaivingMedicalCoverage = false;
    }
    this.deferRetireeChange.emit(e);
    this.onWaiveSelected(e);
  }

  deferMedicaid(e): void {
    // only one defer may be selected at a time
    if (e && this.isDeferRetiree) {
      this.isDeferRetiree = false;
    }
    this.cobraOrUnpaidWaiving.emit({ planType: this.planType, waived: e, selfPayWaivingMedicalCoverage: e });
  }

  disenroll(e): void {
    // send removal if removing existing coverage. (45)
    // plan choice required for dep before moving on if at least one dep has medical coverage still for period.+ show message
    var waived = false;
    if (e && some(this.subscriberSummary.memberSummary, (m: MemberSummary) => m.effectiveEnrollmentsByPlanType['1'])) {
      this.dependentIsEnrolledMedical = true;
      waived = true;
    } else {
      this.dependentIsEnrolledMedical = false;
      waived = false;
    }
    this.cobraOrUnpaidWaiving.emit({ planType: this.planType, waived: waived, selfPayWaivingMedicalCoverage: e });
  }
}
