// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Pay1SyncHoldOverview from '../models/pay1SyncHoldOverview';
import Pay1SyncHoldErrorOverview from '../models/pay1SyncHoldErrorOverview';
import Pay1SyncHold from '../models/pay1SyncHold';
import Pay1SyncHoldDetail from '../models/pay1SyncHoldDetail';
import Pay1SyncLock from '../models/pay1SyncLock';
import Member from '../models/member';

@Injectable({
  providedIn: 'root'
})
export class Pay1SyncService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  fetchErroredTransactions(): Observable<Pay1SyncHoldErrorOverview[]> {
    return this.http
      .get<Pay1SyncHoldErrorOverview>(`${env.apiUrl}/pay1Sync`)
      .pipe(map(x => this.commonService.createObjects(Pay1SyncHoldErrorOverview, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  fetchErrorsForSubscriber(subscriberId: string): Observable<Pay1SyncHoldOverview[]> {
    return this.http
      .get<Pay1SyncHoldOverview>(`${env.apiUrl}/pay1Sync/${subscriberId}`)
      .pipe(map(x => this.commonService.createObjects(Pay1SyncHoldOverview, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  fetchRecord(subscriberId: string, pay1SyncHoldId: string): Observable<Pay1SyncHold[]> {
    return this.http
      .get<Pay1SyncHold>(`${env.apiUrl}/pay1Sync/${subscriberId}/${pay1SyncHoldId}/overview`)
      .pipe(map(x => this.commonService.createObjects(Pay1SyncHold, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  fetchDetail(subscriberId: string, pay1SyncHoldId: string): Observable<any[]> {
    return this.http
      .get<Pay1SyncHoldDetail>(`${env.apiUrl}/pay1Sync/${subscriberId}/${pay1SyncHoldId}/detail`)
      .pipe(catchError(this.commonService.handleError));
  }
  
  allowSync(subscriberId: string, pay1SyncHoldId: string): Observable<void> {
    return this.http
      .post(`${env.apiUrl}/pay1Sync/${subscriberId}/allowSync/${pay1SyncHoldId}`, null)
      .pipe(catchError(this.commonService.handleError));
  }

  markAsProcessed(subscriberId: string, pay1SyncHoldId: string): Observable<void> {
    return this.http
      .post(`${env.apiUrl}/pay1Sync/${subscriberId}/processed/${pay1SyncHoldId}`, null)
      .pipe(catchError(this.commonService.handleError));
  }

  resendRecords(subscriberId: string, ids: string[]): Observable<void> {
    return this.http
      .post(`${env.apiUrl}/pay1Sync/${subscriberId}/resend`, ids)
      .pipe(catchError(this.commonService.handleError));
  }

  updateOutput(subscriberId: string, pay1SyncHoldId: string, output: any): Observable<void> {
    if (output && !Array.isArray(output)) {
      output = [ output ];
    }

    return this.http
      .put(`${env.apiUrl}/pay1Sync/${subscriberId}/${pay1SyncHoldId}`, output)
      .pipe(catchError(this.commonService.handleError));
  }

  getLocks(activeOnly: boolean = true): Observable<Pay1SyncLock[]> {
    return this.http
      .get<Pay1SyncLock>(`${env.apiUrl}/pay1Sync/lock/${activeOnly}`)
      .pipe(map(x => this.commonService.createObjects(Pay1SyncLock, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  searchLock(addLockMemberIdOrSsn: string): Observable<Member[]> {
    return this.http
      .get<Member>(`${env.apiUrl}/pay1Sync/lock/search/${addLockMemberIdOrSsn}`)
      .pipe(map(x => this.commonService.createObjects(Member, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  addLock(member: Member, lockAccountLevel: boolean): Observable<void> {
    const lock = new Pay1SyncLock();
    lock.subscriberMemberId = member.subscriberMemberId;
    lock.memberId = member.memberId;
    lock.accountLevel = lockAccountLevel;

    return this.http
      .post(`${env.apiUrl}/pay1Sync/lock`, lock)
      .pipe(catchError(this.commonService.handleError));
  }

  unlock(pay1SyncLockId: string): Observable<void> {
    return this.http
      .put(`${env.apiUrl}/pay1Sync/lock/${pay1SyncLockId}/unlock`, null)
      .pipe(catchError(this.commonService.handleError));
  }
}
