<div class="k-form-field">
  <div>{{question.question}}</div>
  <div fxFlexLayout="row" fxLayoutAlign="space-around center">
    <span *ngFor="let response of responses">
      <radio
        [name]="'spouseQuestionResponse_' + question?.questionId + '_' + response.responseId"
        radioAsCheckbox
        [disabled]="disabled"
        [value]="response.responseId"
        [label]="response.responseName"
        [(ngModel)]="responseId"
        (change)="updateAnswer()"
      ></radio>
    </span>
  </div>
</div>