import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { pdf, exportPDF } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import { Observable, of, from, firstValueFrom, lastValueFrom } from 'rxjs';
import { orderBy, uniq } from 'lodash';

import { AdminService } from 'src/app/services/admin.service';
import { AuditHistoryService } from 'src/app/services/auditHistory.service';
import { LookupService } from 'src/app/services/lookup.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';

import SimpleSubscriber from 'src/app/models/simpleSubscriber';
import SystemUser from 'src/app/models/user';
import Eligibility from 'src/app/models/eligibility';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import MemberAddress from 'src/app/models/memberAddress';
import Enrollment from 'src/app/models/enrollment';
import AuditHistoryWrapper from 'src/app/models/AuditHistoryWrapper';

import SpecialOpenEnrollmentType from 'src/app/models/specialOpenEnrollmentType';
import AddressType from 'src/app/models/addressType';
import Country from 'src/app/models/country';
import County from 'src/app/models/county';
import Reason from 'src/app/models/reason';
import Attestation from 'src/app/models/attestation';
import AttestationType from 'src/app/models/attestationType';
import Document from 'src/app/models/document';
import Response from 'src/app/models/response';
import DocumentType from 'src/app/models/documentType';
import LoginHistory from 'src/app/models/loginHistory';
import MemberNote from 'src/app/models/memberNote';
import MemberMaritalStatus from 'src/app/models/memberMaritalStatus';
import RelationshipType from 'src/app/models/relationshipType';
import RelationshipQualifyReason from 'src/app/models/relationshipQualifyReason';
import BirthSex from 'src/app/models/birthSex';
import GenderIdentity from 'src/app/models/genderIdentity';
import RelationshipVerificationStatus from 'src/app/models/relationshipVerificationStatus';
import DependentComposite from 'src/app/models/dependentComposite';
import MemberType from 'src/app/models/memberType';
import Plan from 'src/app/models/plan';
import { PDFComponent } from 'src/app/modules/shared/components/pdf/pdf.component';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import Subscriber from 'src/app/models/subscriber';
import { SubscriberService } from 'src/app/services/subscriber.service';
import SelfPay from 'src/app/models/selfPay';

@UntilDestroy()
@Component({
  selector: 'subscriber-history',
  templateUrl: 'subscriberHistory.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriberHistoryComponent implements OnInit {
  @ViewChild('pdf') pdf: PDFComponent;
  @ViewChild('accordion') accordion: NgbAccordion;
  subscriberSearchResults: SimpleSubscriber[] = [];
  selectedSubscriber: SimpleSubscriber;
  searchResultColumns: { [k: string]: string | {} }[] = [
    { field: 'firstName', title: 'First name', format: 'string' },
    { field: 'middleName', title: 'Middle name', format: 'string' },
    { field: 'lastName', title: 'Last name', format: 'string' },
    { field: 'SSN', title: 'SSN', format: 'string' },
    { field: 'birthDate', title: 'Birth date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'memberType', title: 'Member type', format: 'string' },
    { field: 'employerName', title: 'Employer name', format: 'string' },
  ];
  activeCoveragePanels = [];
  activeTab;
  icons = {
    faPlus,
    faMinus,
  };
  systemUser: SystemUser;
  debounceTimer = {};
  lookups: { [k: string]: any[] } = {};
  loaded = false;
  printing = false;
  isHCA = false;

  fullMember: Promise<Subscriber> = null;

  // SOEs
  soes: Promise<AuditHistoryWrapper<SpecialOpenEnrollment>[]> = null;
  soeColumns: { [k: string]: string | {} }[] = [
    { field: 'specialOpenEnrollmentTypeId', lookupName: 'specialOpenEnrollmentType', lookupType: SpecialOpenEnrollmentType, title: 'Type', format: 'string' },
    { field: 'requestReceivedDate', title: 'Request Received Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'eventDate', title: 'Event Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'coverageEffectiveDate', title: 'Coverage Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'soeEndDate', display: r => dayjs(r.createdDate).add(30, 'day').toDate(), title: 'SOE Ended Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'verification', display: r => r.specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName, title: 'Verification', format: 'string' },
    { field: 'verificationDate', display: r => r.approvedDate ?? r.deniedDate, title: 'Verification Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'verifiedBy', display: r => r.approvedByID ?? r.deniedByID, title: 'Verified By', format: 'string' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // SelfPays
  selfpays: Promise<AuditHistoryWrapper<SelfPay>[]> = null;
  selfpayColumns: { [k: string]: string | {} }[] = [
    { field: 'selfPayType', display: (r: SelfPay) => r.selfPayType.selfPayTypeName, title: 'Type', format: 'string' },
    { field: 'submittedDate', title: 'Submit Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'coverageEffectiveDate', title: 'Coverage Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'selfPayVerificationStatus', display: (r: SelfPay) => r.selfPayVerificationStatus.selfPayVerificationStatusName, title: 'Status', format: 'string' },
    { field: 'verificationDate', display: r => (r.approvedDate ? r.approvedDate : (r.approvedPendingDate ? r.approvedPendingDate : r.deniedDate)), title: 'Verification Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'verifiedBy', display: r => this.getUser(r.approvedById ? r.approvedById : (r.approvedPendingById ? r.approvedPendingById : r.deniedById)), title: 'Verified By', format: 'string' },
    { field: 'selfPayProcessStatus', display: (r: SelfPay) => r.selfPayProcessStatus.selfPayProcessStatusName, title: 'Processing Status', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'modifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // Eligibility & Employment
  eligibilities: Promise<AuditHistoryWrapper<Eligibility>[]> = null;
  eligibilityColumns: { [k: string]: string | {} }[] = [
    { field: 'organizationId', display: r => this.getOrg(r.organizationId), title: 'Agency/Subagency', format: 'string' },
    { field: 'agencyEffectiveStartDate', title: 'Agency Elig Eff Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'agencyEffectiveEndDate', title: 'Agency Elig Eff End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'memberTypeId', lookupName: 'memberType', lookupType: MemberType, displayColumn: () => 'memberTypeCode', title: 'Eligibility Type', format: 'string' },
    { field: 'eligibilityReasonId', lookupName: 'reason', lookupType: Reason, displayColumn: () => 'reasonCode', title: 'Eligibility Reason', format: 'string' },
    { field: 'createdDate', title: 'Eligibility Created Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // Addresses
  addresses: Promise<AuditHistoryWrapper<MemberAddress>[]> = null;
  addressColumns: { [k: string]: string | {} }[] = [
    { field: 'addressTypeId', lookupName: 'addressType', lookupType: AddressType, title: 'Address Type', format: 'string' },
    { field: 'addressLineOne', title: 'Address Line 1', format: 'string' },
    { field: 'addressLineTwo', title: 'Address Line 2', format: 'string' },
    { field: 'city', title: 'City', format: 'string' },
    { field: 'state', title: 'State', format: 'string' },
    { field: 'zipcodeNumber', title: 'Postal Code', format: 'string' },
    { field: 'countryId', lookupName: 'country', lookupType: Country, title: 'Country', format: 'string' },
    { field: 'countyId', lookupName: 'county', lookupType: County, title: 'County', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'Address End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // Enrollment
  subscriberEnrollments: Promise<AuditHistoryWrapper<Enrollment>[]> = null;
  subscriberEnrollmentColumns: { [k: string]: string | {} }[] = [
    { field: 'planName', display: e => this.getPlan(e.planId), title: 'Plan Name', format: 'string' },
    { field: 'effectiveStartDate', title: 'Coverage Eff Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'Coverage Eff End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'enrollmentReasonId', lookupName: 'reason', lookupType: Reason, title: 'Enrollment Reason', format: 'string' },
    { field: 'terminationReasonId', lookupName: 'reason', lookupType: Reason, title: 'Termination reason', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];
  dependentEnrollments: Promise<AuditHistoryWrapper<Enrollment>[]> = null;
  dependentEnrollmentColumns: { [k: string]: string | {} }[] = [
    { field: 'name', display: e => this.getDependentName(e.memberId), title: 'Dependent First Name Last Name', format: 'string' },
    { field: 'planName', display: e => this.getPlan(e.planId), title: 'Plan Name', format: 'string' },
    { field: 'effectiveStartDate', title: 'Coverage Eff Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'Coverage Eff End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'enrollmentReasonId', lookupName: 'reason', lookupType: Reason, title: 'Enrollment Reason', format: 'string' },
    { field: 'terminationReasonId', lookupName: 'reason', lookupType: Reason, title: 'Termination reason', format: 'string' },
    { field: 'createdDate', title: 'Created Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // Attestations
  subscriberAttestations: Promise<AuditHistoryWrapper<Attestation>[]> = null;
  subscriberAttestationColumns: { [k: string]: string | {} }[] = [
    { field: 'attestationTypeId', lookupName: 'attestationType', lookupType: AttestationType, title: 'Type', format: 'string' },
    { displayOnly: true, field: 'responseId', lookupName: 'response', lookupType: Response, title: 'Response', format: 'string' },
    { field: 'effectiveStartDate', title: 'Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'attestationDate', title: 'Date Attestation Made', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];
  dependentAttestations: Promise<AuditHistoryWrapper<Attestation>[]> = null;
  dependentAttestationColumns: { [k: string]: string | {} }[] = [
    { field: 'name', display: e => this.getDependentName(e.memberId), title: 'Dependent First Name Last Name', format: 'string' },
    { displayOnly: true, field: 'responseId', lookupName: 'response', lookupType: Response, title: 'Response', format: 'string' },
    { field: 'effectiveStartDate', title: 'Effective Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'effectiveEndDate', title: 'End Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'attestationDate', title: 'Date Attestation Made', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'modifiedDate', title: 'Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdModifiedBy', display: r => this.getUser(r.modifiedById ?? r.createdById), title: 'Created/Modified By', format: 'string' }
  ];

  // Login History
  loginHistory: Promise<AuditHistoryWrapper<LoginHistory>[]> = null;
  loginHistoryColumns: { [k: string]: string | {} }[] = [
    { field: 'loginDate', title: 'Log in Date & Time', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' }
  ];

  // Notes
  notes: Promise<AuditHistoryWrapper<MemberNote>[]> = null;
  noteColumns: { [k: string]: string | {} }[] = [
    { field: 'createdDate', title: 'Date & Time of note entry', format: { date: 'MM/DD/YYYY h:mm:ss a' }, filter: 'date' },
    { field: 'noteText', title: 'Note', format: 'string' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' }
  ];

  // Marital Status Records
  maritalStatuses: Promise<AuditHistoryWrapper<MemberMaritalStatus>[]> = null;
  maritalStatusColumns: { [k: string]: string | {} }[] = [
    { field: 'startDate', title: 'Marriage Start Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'endDate', title: 'Marriage end Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' },
    { field: 'createdModifiedDate', display: r => r.modifiedDate ?? r.createdDate, title: 'Created by/modified by Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' }
  ];

  // Dependents
  dependents: Promise<AuditHistoryWrapper<DependentComposite>[]> = null;
  dependentColumns: { [k: string]: string | {} }[] = [
    { field: 'relationshipTypeId', lookupName: 'relationshipType', lookupType: RelationshipType, title: 'Relationship', format: 'string' },
    { field: 'relationshipQualifyReasonId', lookupName: 'relationshipQualifyReason', lookupType: RelationshipQualifyReason, title: 'Qualify Reason', format: 'string' },
    { field: 'firstName', title: 'First Name', format: 'string' },
    { field: 'lastName', title: 'Last Name', format: 'string' },
    { field: 'middleName', title: 'Middle Name', format: 'string' },
    { field: 'socialSecurityNumber', title: 'SSN', format: 'string' },
    { field: 'birthDate', title: 'DOB', format: { date: 'MM/DD/YYYY' }, filter: 'date' },
    { field: 'birthSexId', lookupName: 'birthSex', lookupType: BirthSex, title: 'Birth Sex', format: 'string' },
    { field: 'genderIdentityId', lookupName: 'genderIdentity', lookupType: GenderIdentity, title: 'Gender', format: 'string' },
    { field: 'relationshipVerificationStatusId', lookupName: 'relationshipVerificationStatus', lookupType: RelationshipVerificationStatus, title: 'Verification Status', format: 'string' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' },
    { field: 'createdModifiedDate', display: r => r.modifiedDate ?? r.createdDate, title: 'Created/Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' }
  ];

  // Documents
  documents: Promise<AuditHistoryWrapper<Document>[]> = null;
  documentColumns: { [k: string]: string | {} }[] = [
    { field: 'attributedTo', title: 'Document Attributed To', format: 'string' },
    { field: 'documentTypeId', lookupName: 'documentType', lookupType: DocumentType, title: 'Document Type', format: 'string' },
    { field: 'documentName', title: 'Document title', format: 'string' },
    { field: 'createdBy', display: r => this.getUser(r.createdById), title: 'Created By', format: 'string' },
    { field: 'createdModifiedDate', display: r => r.modifiedDate ?? r.createdDate, title: 'Created/Modified Date', format: { date: 'MM/DD/YYYY' }, filter: 'date' }
  ];

  constructor(
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService,
    private adminService: AdminService,
    private lookupService: LookupService,
    private auditHistoryService: AuditHistoryService,
    private subscriberService: SubscriberService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
    });

    [
      this.soeColumns, this.addressColumns, this.subscriberEnrollmentColumns, this.subscriberAttestationColumns, this.loginHistoryColumns,
      this.noteColumns, this.maritalStatusColumns, this.dependentColumns, this.documentColumns, this.dependentEnrollmentColumns,
      this.dependentAttestationColumns, this.eligibilityColumns
    ].forEach(colDef => this.preloadLookups(colDef));

    this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.HCA);
    const idKey = 'id';
    const sortColumnsKey = 'sortColumns';
    const sortOrdersKey = 'sortOrders';

    this.soeColumns[idKey] = 'specialOpenEnrollmentId';
    this.selfpayColumns[idKey] = 'selfPayId';
    this.addressColumns[idKey] = 'memberAddressId';
    this.subscriberEnrollmentColumns[idKey] = 'enrollmentId';
    this.subscriberAttestationColumns[idKey] = 'attestationId';
    this.loginHistoryColumns[idKey] = 'loginHistoryId';
    this.noteColumns[idKey] = 'memberNoteId';
    this.maritalStatusColumns[idKey] = 'memberMaritalStatusId';
    this.dependentColumns[idKey] = 'memberId';
    this.documentColumns[idKey] = 'documentId';
    this.dependentEnrollmentColumns[idKey] = 'enrollmentId';
    this.dependentAttestationColumns[idKey] = 'attestationId';
    this.eligibilityColumns[idKey] = 'eligibilityId';

    this.dependentColumns[sortColumnsKey] = [ 'lastName', 'firstName', 'id', 'timestamp' ];
    this.dependentColumns[sortOrdersKey] = [ 'asc', 'asc', 'asc', 'desc' ];

    this.dependentEnrollmentColumns[sortColumnsKey] = [ 'name', 'primarySort', 'id', 'timestamp' ];
    this.dependentEnrollmentColumns[sortOrdersKey] = [ 'asc', 'asc', 'asc', 'desc' ];
  }

  rowClass(context: RowClassArgs): any {
    const result = {
      deleted: !context.dataItem.current
    };

    return result;
  }

  getRowCount(items: any[]): string {
    if (Array.isArray(items) && items.length) {
      if (items.length === 1) {
        return '1 Record';
      }

      return `${items.length} Records`;
    }

    return '0 Records';
  }

  getDebouncedList(listKey: string, id: string, getData: (ids: string[]) => Observable<any[]>, callback: any): Promise<any> {
    this.debounceTimer[listKey] = this.debounceTimer[listKey] ?? { timer: null, resolvers: [] };

    clearTimeout(this.debounceTimer[listKey].timer);

    this.debounceTimer[listKey].timer =
      setTimeout
      (
        () => {
          const timer = this.debounceTimer[listKey];
          delete this.debounceTimer[listKey];

          const ids = timer.resolvers.map(r => r.id);
          return firstValueFrom(getData(ids))
            .then(users => {
              const resolvers = timer.resolvers;

              while (resolvers.length) {
                const resolver = resolvers.pop();
                callback(users, resolver.id, resolver.resolve);
              }
            });
        },
        100
      );

    return new Promise(resolve => {
      this.debounceTimer[listKey].resolvers.push({ resolve, id });
    });
  }

  getUser(userId: string): Promise<string> {
    if (userId) {
      return this.getDebouncedList(
        'getUser',
        userId,
        this.auditHistoryService.getUsersByIdList.bind(this.auditHistoryService),
        (users, id, resolve) => {
          const user = users.filter(u => u.systemUserId === id)[0];
          const name = user?.emailAddress ?? user?.firstName;

          resolve(name);
        });
    }
    else {
      return null;
    }
  }

  getOrg(organizationId: string): Promise<string> {
    if (!organizationId) {
      return Promise.resolve(null);
    }

    return this.getDebouncedList(
      'getOrg',
      organizationId,
      this.auditHistoryService.getOrganizationsByIdList.bind(this.auditHistoryService),
      (orgs, id, resolve) => {
        const org = orgs.filter(u => u.organizationId === id)[0];
        const name = org.organizationName;

        resolve(name);
      });
  }

  isTransfer(lossOfEligibilityReasonId: string): Promise<string> {
    if (!lossOfEligibilityReasonId) {
      return Promise.resolve('False');
    }

    return this.lookup<Reason>(lossOfEligibilityReasonId, 'reason', Reason, (l) => `${l}Code`)
      .then(code => {
        return code === '99';
      })
      .then((result: boolean) => result ? 'True' : 'False');
  }

  getPlan(planId: string, selector: (p: Plan) => string | Plan = (p: Plan) => p?.planName): Promise<string | Plan> {
    return this.getDebouncedList(
      'getPlan',
      planId,
      this.auditHistoryService.getPlansByIdList.bind(this.auditHistoryService),
      (plans, id, resolve) => {
        const plan = plans.filter(p => p.planId === id)[0];
        const name = selector(plan);

        resolve(name);
      });
  }

  get filename(): string {
    const now = dayjs().format('MMDDYYYY');
    return `History_${this.selectedSubscriber.firstName}${this.selectedSubscriber.lastName}_Download_Date${now}.pdf`;
  }

  download(): void {
    const panelIds = this.accordion.activeIds.slice();

    this.spinnerOverlayService.show();
    this.printing = true;
    this.accordion.expandAll();

    setTimeout(() => {
      this.pdf.pdfElement.export().then(group => {
        const options = {  } as pdf.PDFOptions;
        exportPDF(group, options).then(data => {
          saveAs(data, this.filename);

          setTimeout(() => {
            this.accordion.collapseAll();
            (panelIds as string[]).forEach(id => this.accordion.expand(id));
            this.printing = false;
            this.spinnerOverlayService.hide();
          }, 1000);
        });
      });
    }, 0);
  }

  getDependentName(memberId: string): Promise<any> {
    return Promise.resolve(this.dependents)
      .then(deps => {
        deps = deps.filter(d => d.wrappedEntity.memberId === memberId);

        if (deps.length) {
          const dep = deps[0];
          return dep.wrappedEntity.firstName + ' ' + dep.wrappedEntity.lastName;
        }
      })
      .then(name => name ?? 'Account Level');
  }

  preloadLookups(colDef: { [k: string]: string | {} }[]): void {
    const cols = colDef.filter(col => col.lookupName);
    Promise
      .all(cols.map(col => this.lookupDebounce(this.getLookups, 100, col.lookupName as string, col.lookupType as any)))
      .then(lookups => {
        for (let c = 0; c < cols.length; c++) {
          this.lookups[cols[c].lookupName as string] = lookups[c];
        }
      });
  }

  // tslint:disable-next-line: callable-types
  lookupDebounce<T>(f, interval, lutName: string, type: { new (...args: any[]): T }): Promise<any> {
    this.debounceTimer[lutName] = this.debounceTimer[lutName] ?? { timer: null, resolvers: [] };

    clearTimeout(this.debounceTimer[lutName].timer);

    this.debounceTimer[lutName].timer =
      setTimeout
      (
        () => {
          f.bind(this)(lutName, type)
            .then(lookups => {
              while (this.debounceTimer[lutName].resolvers.length) {
                this.debounceTimer[lutName].resolvers.pop()(lookups);
              }

              delete this.debounceTimer[lutName];
            });
        },
        interval
      );

    return new Promise(resolve => {
      this.debounceTimer[lutName].resolvers.push(resolve);
    });
  }

  // tslint:disable-next-line: callable-types
  getLookups<T>(lutName: string, type: { new (...args: any[]): T }): Promise<T[]> {
    return firstValueFrom(this.lookupService.getLutValues(lutName, type));
  }

  lookup<T>(
    value: any,
    lutName: string,
    // tslint:disable-next-line: callable-types
    type: { new (...args: any[]): T },
    displayColumn: (lut: string) => string = l => `${l}Name`): Promise<string> {
    if (!value) {
      return Promise.resolve(value);
    }

    const valuePromise = Promise.resolve(value);
    const lookupsPromise = Promise
      .resolve(this.lookups[lutName])
      .then(lookups => {
        if (lookups) {
          return lookups;
        }

        return this.lookupDebounce(this.getLookups, 100, lutName, type);
      });

    const promise = Promise.all([ valuePromise, lookupsPromise ])
      .then((incoming: any[]) => {
        const incomingValue = incoming[0];
        const incomingLookups = incoming[1];

        if (!this.lookups[lutName]) {
          this.lookups[lutName] = incomingLookups;
        }

        const foundLookups = incomingLookups.filter(l => l[`${lutName}Id`] === incomingValue);

        if (foundLookups.length) {
          return foundLookups[0][displayColumn(lutName)];
        }

        return incomingValue;
      });

    return promise;
  }

  onSearchSubmitted(queryString: string): void {
    this.spinnerOverlayService.show();
    this.adminService.searchSubscribers(queryString, true, this.coreService.getOrganizationValue()?.organizationId, null, false, true).pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.spinnerOverlayService.hide();
        this.subscriberSearchResults = [];
        this.subscriberSearchResults = data;
      },
      (err) => {
        console.log(err);
        this.spinnerOverlayService.hide();
      }
    );
  }

  async onSubscriberSelected(e: SimpleSubscriber): Promise<void> {
    if (this.selectedSubscriber) {
      this.onSubscriberDeselected();
    }

    if (!e.isSubscriberInd) {
      // this is a dependent.  Get their subscriber's record
      this.spinnerOverlayService.show();
      e = new SimpleSubscriber(await lastValueFrom(this.subscriberService.getSubscriberById(e.subscriberMemberId)));
      this.spinnerOverlayService.hide();
    }
    this.selectedSubscriber = e;

    Promise.all([
      this.fullMember = firstValueFrom(this.subscriberService.getSubscriberById(e.memberId)),
      this.soes = this.processGridResults(this.auditHistoryService.getSoes(e.memberId), this.soeColumns),
      this.selfpays = this.processGridResults(this.auditHistoryService.getSelfPays(e.memberId), this.selfpayColumns),
      this.addresses = this.processGridResults(this.auditHistoryService.getAddresses(e.memberId), this.addressColumns),
      this.loginHistory = this.processGridResults(this.auditHistoryService.getLogins(e.memberId), this.loginHistoryColumns),
      this.notes = this.processGridResults(this.auditHistoryService.getNotes(e.memberId), this.noteColumns),
      this.maritalStatuses = this.processGridResults(this.auditHistoryService.getMaritalStatuses(e.memberId), this.maritalStatusColumns),
      this.documents = this.processGridResults(this.auditHistoryService.getDocuments(e.memberId), this.documentColumns),
      this.eligibilities = this.processGridResults(this.auditHistoryService.getEligibilities(e.memberId), this.eligibilityColumns),

      firstValueFrom(this.auditHistoryService.getDependents(e.memberId))
        .then(dependents => {
          return Promise.all(dependents.map(dependent => {
            return this.lookup(dependent.wrappedEntity.relationshipTypeId, 'relationshipType', RelationshipType, d => `${d}Code`)
              .then(relationshipTypeCode => {
                const key = 'primarySort';
                dependent[key] = relationshipTypeCode === 'S' ? 1 : 2;

                return dependent;
              });
          }));
        })
        .then(dependents => {
          return this.dependents = this.processGridResults(of(dependents), this.dependentColumns);
        })
        .then(() => {
          return Promise.all([
            firstValueFrom(this.auditHistoryService.getEnrollments(e.memberId))
              .then(allEnrollments => {
                return Promise.all(allEnrollments.map(enrollment => {
                  const key = 'primarySort';

                  if (enrollment.wrappedEntity.plan) {
                    enrollment[key] = enrollment.wrappedEntity.planSortOrder;

                    return enrollment;
                  }

                  return this.getPlan(enrollment.wrappedEntity.planId, p => p)
                    .then((plan: Plan) => {
                      enrollment.wrappedEntity.plan = plan;
                      enrollment[key] = enrollment.wrappedEntity.planSortOrder;

                      return enrollment;
                    });
                }));
              })
              .then(allEnrollments => {
                return Promise.all([
                  this.subscriberEnrollments = this.processGridResults(of(allEnrollments.filter(rec => rec.wrappedEntity.memberId === e.memberId)), this.subscriberEnrollmentColumns),
                  this.dependentEnrollments = this.processGridResults(of(allEnrollments.filter(rec => rec.wrappedEntity.memberId !== e.memberId)), this.dependentEnrollmentColumns)
                ]);
              }),

            firstValueFrom(this.auditHistoryService.getAttestations(e.memberId))
              .then(allAttestations => {
                return Promise.all([
                  this.subscriberAttestations = this.processGridResults(of(allAttestations.filter(rec => rec.wrappedEntity.memberId === e.memberId)), this.subscriberAttestationColumns),
                  this.dependentAttestations = this.processGridResults(of(allAttestations.filter(rec => rec.wrappedEntity.memberId !== e.memberId)), this.dependentAttestationColumns)
                ]);
              })
          ]);
        })
    ])
    .then(() => this.loaded = true);
  }

  onSubscriberDeselected(): void {
    this.selectedSubscriber =

      this.soes =
      this.selfpayColumns =
      this.addresses =
      this.loginHistory =
      this.subscriberEnrollments =
      this.subscriberAttestations =
      this.notes =
      this.maritalStatuses =
      this.dependents =
      this.documents =
      this.dependentEnrollments =
      this.dependentAttestations =
      this.eligibilities =

      null;

    this.loaded = false;
  }

  processGridResults<T>(results: Observable<AuditHistoryWrapper<T>[]>, columns: { [k: string]: string | {} }[]): Promise<AuditHistoryWrapper<T>[]> {
    return lastValueFrom(results)
      .then(r => r || [])
      .then((rows: AuditHistoryWrapper<T>[]) =>
        {
          if(!rows) rows = [];
          if(!columns) columns = [];
          return Promise.all(rows.map(row => {
            return Promise.all(columns.map(col => {
              const field: string = col.field as string;

              if (col.display || col.lookupName) {
                return this.processDisplay(row, col);
              }

              return row.wrappedEntity[field];
            }))
            .then(columnValues => {
              for (let c = 0; c < columns.length; c++) {
                const col = columns[c];
                const val = columnValues[c];
                const field: string = col.field as string;

                row[field] = val;
              }

              return row;
            });
          }))
          .then(records => {
            const attributesKey = 'attributes';
            records.forEach(rec => {
              if (rec[attributesKey]) {
                // tslint:disable-next-line: forin
                for (const k in rec[attributesKey]) {
                  rec[k] = rec[attributesKey][k];
                }
              }
            });

            return records;
          })
          .then(records => {
            const idKey = 'id';

            if (records.length && columns[idKey]) {
              const activeKey = 'current';
              const idColumn = columns[idKey];
              const staticIdColumn = 'id';
              const ids = uniq(records.map(rec => {
                rec[staticIdColumn] = rec.wrappedEntity[idColumn];
                return rec[staticIdColumn];
              }));

              // Upper first letter
              const re = /(\b[a-z](?!\s))/g;
              // Convert ID column to Type name. E.g., specialOpenEnrollmentId => SpecialOpenEnrollment
              const setType = idColumn.replace(/Id$/, '').replace(re, x => x.toUpperCase());

              return firstValueFrom(this.auditHistoryService.getCurrentIdsByIdList(setType, ids))
                .then(currentIds => {
                  if (Array.isArray(currentIds)) {
                    records.forEach(rec => {
                      rec[activeKey] = currentIds.indexOf(rec.wrappedEntity[idColumn]) > -1;
                    });
                  }

                  return records;
                });
            }

            return records;
          })
          .then(records => {
            const sortColumnsKey = 'sortColumns';
            const sortOrdersKey = 'sortOrders';
            let cols = [];
            let orders = [];
            if(columns) {
              cols = columns[sortColumnsKey] ?? [ 'id', 'timestamp' ];
              const orders = columns[sortOrdersKey] ?? [ 'asc', 'desc' ];
            }
            const primarySortKey = 'primarySort';
            if (records.length && records[0][primarySortKey] && cols.indexOf(primarySortKey) === -1) {
              cols.splice(0, 0, primarySortKey);
              orders.splice(0, 0, 'asc');
            }

            return orderBy(records, cols, orders);
          });
        });
  }

  processDisplay(instance: any, col: any): Promise<any> {
    return Promise.resolve()
      .then(() => {
        const display: (i: any) => Promise<any> = col.display as ((i: any) => Promise<any>);

        if (display) {
          return display(instance.wrappedEntity);
        }

        return instance.wrappedEntity[col.field];
      })
      .then(result => {
        if (col.lookupName) {
          return this.lookup(result, col.lookupName, col.lookupType, col.displayColumn);
        }

        return result;
      });
  }
}
