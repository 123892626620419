import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NgForm } from '@angular/forms';
import { find } from 'lodash';
import { env } from '../../../../env/development';
import { AuthService } from 'src/app/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: [],
})
export class LoginComponent {
  router: Router;
  emailAddress;
  password;
  @ViewChild('notificationTemplate', { read: TemplateRef, static: true }) public notificationTemplate: TemplateRef<any>;
  @ViewChild('loginForm') public loginForm: NgForm;
  constructor(private notificationService: NotificationService, private authService: AuthService) {
  }

  login(): void {
      this.authService.login();

  }

  testAuth(): void {
    this.authService.testEndpoint().pipe(untilDestroyed(this)).subscribe(d => console.log(d), e => console.log(e));
  }

  adminLogin(): void {
    this.authService.adminLogin();
  }

  hcaLogin(): void {
    this.authService.hcaLogin();
  }
}
