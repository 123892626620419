import { EnrollmentService } from './../../services/enrollment.service';
import Message from 'src/app/models/message';
import { MessageService } from './../../services/message.service';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from './../../guards/pendingChanges/pendingChanges.guard';
import Milestone from 'src/app/models/milestone';
import Attestation from 'src/app/models/attestation';
import SystemUser from 'src/app/models/user';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { Component, ViewEncapsulation, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SubmissionFile from 'src/app/models/submissionFile';
import OrganizationDocument from 'src/app/models/organizationDocument';
import Subscriber from 'src/app/models/subscriber';
import OpenEnrollment from 'src/app/models/openEnrollment';
import * as dayjs from 'dayjs';
import { env } from 'src/env/development';
import { faBriefcaseMedical, faAddressCard, faFile, faShapes, faUsers, faTasks, faUmbrella, faSmoking, faCircleUser, faLungs, faStaffSnake } from '@fortawesome/free-solid-svg-icons';
import { some, cloneDeep, find, filter, get } from 'lodash';
import { WizardComponent } from './components/wizard/wizard.component';
import Member from 'src/app/models/member';
import Enrollment from 'src/app/models/enrollment';
import SubscriberCoverage from 'src/app/models/subscriberCoverage';
import PlanType from 'src/app/models/planType';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import Plan from 'src/app/models/plan';
import DualEnrollment from 'src/app/models/dualEnrollment';
import { OEService } from 'src/app/services/oe.service';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.PlanType)
@Component({
  selector: 'subscriber-dashboard',
  templateUrl: 'subscriber.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriberDashboardComponent implements OnInit, ComponentCanDeactivate {
  openEnrollment: OpenEnrollment;
  subscriber: Subscriber;
  submissionFile: SubmissionFile;
  latestBillingFile: OrganizationDocument;
  latestSmartHealthFile: OrganizationDocument;
  isOpenEnrollment = false;
  selectedEnrollmentPeriod: EnrollmentPeriod;
  isReadOnly = false;
  isInOrgWithNoElections = false;
  // icons
  icons = {
    faAddressCard,
    faBriefcaseMedical,
    faFile,
    faShapes,
    faUsers,
    faTasks,
    faUmbrella,
    faSmoking,
    faCircleUser,
    faStaffSnake,
    faLungs
  };
  mostRecentOECompleted = false;
  user: SystemUser;
  isAdmin = false;
  isHCA = false;
  isPerspay = false;
  mustReattest = false;
  subscriberIsNewlyFull = false;
  initial: 0;
  messages: Message[];
  neFullCode: string;
  transferReasonId: string;
  deathReasonId: string;
  selfPayWindowHasPassed = false;
  isDualEnrolledForPeriod;
  dentalPlanType;
  medicalPlanType;
  visionPlanType;
  dentalWaivePlan: Plan;
  enabledMilestoneSteps: string[];
  dualEnrollment: DualEnrollment;
  soeLink: string = "";
  continuationLink: string = "";
  visionAvailable = false;
  @ViewChild(WizardComponent) wiz: WizardComponent;

  constructor(
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private enrollmentService: EnrollmentService,
    private spinnerService: SpinnerOverlayService,
    private openEnrollmentService: OEService
    ) { }

  ngOnInit(): void {
    this.neFullCode = env.newFullBenefitsEnrollmentPeriodType;
    this.messageService.getMessages().pipe(untilDestroyed(this)).subscribe((messages: Message[]) => {
      this.messages = filter(messages, (message: Message) => message.isActive && message.moduleObject['Subscriber Dashboard']);
    });

    this.coreService.getEnrollmentPeriod().pipe(untilDestroyed(this)).subscribe((ep: EnrollmentPeriod) => {
      if ((ep && ep.enrollmentPeriodId) || ep === null) {
        this.selectedEnrollmentPeriod = cloneDeep(ep);
      }
    });

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.isAdmin = this.coreService.systemUserHasAccess(AccessLevel.Edit);
      this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.HCA);
      if (!this.isHCA && (
        this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.Perspay) || 
        this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay) || 
        this.coreService.systemUserHasAccess(AccessLevel.SystemAdmin, UserTypeCode.Perspay)
      )) {
        this.isPerspay = true;
      }

      this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);

      if (data.subscriber) {
        if (this.route.firstChild == null || !this.route.firstChild.component) {
          this.coreService.setEnrollmentPeriod(null);
        }
        this.subscriber = cloneDeep(data.subscriber);
        this.isInOrgWithNoElections = !this.isAdmin && !this.isPerspay && this.subscriber.organization.disallowLoginInd;
        this.isOpenEnrollment = some(this.subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) => ep.isCurrentlyActive && ep.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'OE');
        this.openEnrollmentService.getOpenEnrollments().pipe(untilDestroyed(this)).subscribe((oes: OpenEnrollment[]) => {
          this.isOpenEnrollment = some(oes, (oe: OpenEnrollment) => oe.isActive);
        });

        this.dentalPlanType = find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.dentalPlanTypeCode);
        this.medicalPlanType = find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.medicalPlanTypeCode);
        this.visionPlanType = find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.visionPlanTypeCode);
  
        // filter out SOEs
        this.subscriber.enrollmentPeriods = filter(this.subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) => ep?.enrollmentPeriodType.enrollmentPeriodTypeCode !== 'SOE');

        const transferEventEnrollmentPeriods = filter(this.subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) => ep.isCurrentlyActive && ep.enrollmentPeriodType?.enrollmentPeriodTypeCode === this.neFullCode);
        this.subscriberIsNewlyFull = transferEventEnrollmentPeriods.length > 0;

        if (this.subscriberIsNewlyFull) {
          const transferEventEnrollmentPeriod = transferEventEnrollmentPeriods[0];
          const electablePlanTypes = filter(transferEventEnrollmentPeriod.electablePlanTypes, (p: PlanType) => some(env.planTypesToDisplay, (pt: string) => p.planTypeCode === pt));
          this.visionAvailable = !!electablePlanTypes.filter(pt => pt.planTypeId === this.visionPlanType.planTypeId).length;
        }

        this.mustReattest = this.subscriberMustReattestSpousal(this.subscriber);
        this.mostRecentOECompleted = some(
          this.subscriber.enrollmentPeriods,
          (ep: EnrollmentPeriod) =>
            (ep.isCurrentlyActive || (dayjs(ep.coverageEffectiveStartDate).isAfter(dayjs()) && dayjs().isAfter(ep.effectiveStartDate))) &&
            ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Open Enrollment' &&
            some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && mi.isComplete)
        );

        // self pay banner
        if (this.subscriber.lossOfEligibilityDate && dayjs(new Date()).isAfter(dayjs(this.subscriber.lossOfEligibilityDate).add(60, 'day'))) {
          this.selfPayWindowHasPassed = true;
        }
        this.setNavigation();
      }
    });

    this.coreService.getSubscriber().pipe(untilDestroyed(this)).subscribe((s: Subscriber) => {
      if (s.memberId) {
        this.subscriber = cloneDeep(s);
        // is the subscriber in an org that does not allow elections, and they are currently not active in LWOP
        const approvedLWOP = this.subscriber.lossOfEligibilityReason?.reasonName == "Approved LWOP" && dayjs(new Date()).isAfter(dayjs(this.subscriber.lossOfEligibilityDate));
        this.isInOrgWithNoElections = !this.isAdmin && !this.isPerspay && (this.subscriber.organization.disallowLoginInd);
        this.setNavigation();
      }
    });


      
  }

  setNavigation() {
    this.continuationLink = '../../selfPay/' + this.subscriber.memberId;
    this.soeLink = this.subscriber.isSelfPay ? this.continuationLink + '/soe' : "../../soe/" + this.subscriber.memberId;
    this.subscriber.setNavigationProps();
  }

  enrollmentPeriodSelected(ep: EnrollmentPeriod): void {
    this.dualEnrollment = new DualEnrollment(this.subscriber.organization, this.subscriber.externalEnrollments, ep.coverageEffectiveStartDate);

    // user is read only
    this.isDualEnrolledForPeriod = this.dualEnrollment.hasOtherAgencyAtAll || this.subscriber.dualEnrolledInOtherAgency;
    this.selectedEnrollmentPeriod = ep;
    if (this.isDualEnrolledForPeriod) {
      // get plans for dummy dental
      this.enrollmentService.getAllPlans().pipe(untilDestroyed(this)).subscribe((plans) => {
        this.dentalWaivePlan = find(plans, (p: Plan) => p.planCode === env.dentalWaivePlanCode);
      });
    } else {
      if (this.isReadOnly) {
        this.router.navigate(['confirmation/' + ep.enrollmentPeriodId], { relativeTo: this.route });
      } else {
        this.router.navigate(['dependents/' + ep.enrollmentPeriodId], { relativeTo: this.route });
      }
    }
  }

  async waivePlansDueToDualEnrollment(): Promise<void> {
    if (this.isDualEnrolledForPeriod) {
      const coverageElections = new SubscriberCoverage();
      coverageElections.enrollmentPeriodId = this.selectedEnrollmentPeriod.enrollmentPeriodId;
      coverageElections.subscriberMemberId = this.subscriber.subscriberMemberId;
      coverageElections.electedPlans = [];
      coverageElections.waivedPlanTypes = [];
      coverageElections.planTypeIds = [];

      coverageElections.waivedPlanTypes.push(this.medicalPlanType.planTypeId);
      const fullBenefits = this.subscriber.organization?.benefitSetup?.benefitSetupCode === 'FB';
      if (fullBenefits) {
        coverageElections.waivedPlanTypes.push(this.dentalPlanType.planTypeId);
        if (this.subscriber.isSebb || some(this.selectedEnrollmentPeriod?.electablePlanTypes, (pt: PlanType)=> pt.planTypeCode == env.visionPlanTypeCode)) {
          coverageElections.waivedPlanTypes.push(this.visionPlanType.planTypeId);
        }
      }

      try {
        this.spinnerService.show();
        const updatedElections = await lastValueFrom(this.enrollmentService.createSubscriberEnrollments(this.subscriber.memberId, this.selectedEnrollmentPeriod.enrollmentPeriodId, coverageElections));
        this.spinnerService.hide();
        this.coreService.popMessage('Successfully waived PEBB medical and dental elections.', 'success', 3000);
        this.isDualEnrolledForPeriod = false;

        this.enabledMilestoneSteps = [ 'Confirmation' ];

        if (fullBenefits) {
          this.enabledMilestoneSteps.push('Supplemental Benefits');
          this.router.navigate(['supplemental/' + this.selectedEnrollmentPeriod.enrollmentPeriodId], { relativeTo: this.route });
        } else {
          this.router.navigate(['confirmation/' + this.selectedEnrollmentPeriod.enrollmentPeriodId], { relativeTo: this.route });
        }
      } catch (err) {
        this.coreService.popMessage('Something went wrong waiving your PEBB elections, please contact your benefits administrator.', 'error', 3000);
        this.spinnerService.hide();
        console.log('err', err);
      }
    }
  }

  continueDualEnroll(): void {
    if (this.isReadOnly) {
      this.router.navigate(['confirmation/' + this.selectedEnrollmentPeriod.enrollmentPeriodId], { relativeTo: this.route });
    } else {
      this.router.navigate(['dependents/' + this.selectedEnrollmentPeriod.enrollmentPeriodId], { relativeTo: this.route });
    }
    this.isDualEnrolledForPeriod = false;
  }

  subscriberMustReattestSpousal(sub: Subscriber): boolean {
    // has a spouse
    // spouse is enrolled in medical
    // spouse has a spousal attestation that is termed before coverage start.
    const currentOE = find(
      this.subscriber.enrollmentPeriods,
      (oe: EnrollmentPeriod) =>
        oe.enrollmentPeriodType.enrollmentPeriodTypeName === 'Open Enrollment' && dayjs().isSameOrAfter(oe.effectiveStartDate) && dayjs().isBefore(oe.coverageEffectiveStartDate)
    );

    // Only applicable during OE, so if current OE not found, just drop out as not needing to re-attest
    if (!currentOE) {
      return false;
    }

    const subscriberCurrentSpouse = find(
      sub.members,
      (m: Member) =>
        m.relationshipToSubscriber &&
        m.relationshipToSubscriber.relationshipType &&
        m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S' &&
        (!m.relationshipToSubscriber.effectiveEndDate || (m.relationshipToSubscriber.effectiveEndDate && m.relationshipToSubscriber.effectiveEndDate > currentOE.coverageEffectiveStartDate))
    );
    if (subscriberCurrentSpouse) {
      const spouseEnrolledMedical = some(subscriberCurrentSpouse.enrollments, (e: Enrollment) => e.plan.planType.planTypeCode === '1' && !e.effectiveEndDate);
      const spouseAttestation = find(subscriberCurrentSpouse.attestations, (at: Attestation) => at.attestationType.attestationTypeCode === 'SS');
      return spouseEnrolledMedical && ((spouseAttestation?.effectiveEndDate && dayjs(spouseAttestation.effectiveEndDate).isBefore(currentOE.coverageEffectiveStartDate)) || !spouseAttestation);
    } else {
      return false;
    }
  }

  cancelDE(): void {
    this.isDualEnrolledForPeriod = false;
    this.selectedEnrollmentPeriod = null;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }
}
