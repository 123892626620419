// import { SOEPDFComponent } from './components/pdf/soe.pdf.component';
// import { SOEConfirmComponent } from './components/confirm/soe.confirm.component';
// ng
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// ext
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// local
// import { SOEAddSpouseComponent } from './components/dependent/components/addSpouse/soe.addSpouse.component';
// import { SOEDependentComponent } from './components/dependent/soe.dependent.component';
// import { SOEExistingDependentComponent } from './components/dependent/components/existingDependent/soe.existing.dependent.component';
// import { SOENewDependentComponent } from './components/dependent/components/newDependent/soe.new.dependent.component';
import { SOEGridComponent } from './components/grid/soe.grid.component';
import { SOEAddComponent } from './components/add/soe.add.component';
// import { SOEChangeTypeComponent } from './components/changeType/soe.changeType.component';
// import { SOEElectMedicalComponent } from './components/electMedical/soe.electMedical.component';
// import { SOEElectDentalComponent } from './components/electDental/soe.electDental.component';
// import { SOEUploadComponent } from './components/upload/soe.upload.component';
import { SOEComponent } from './soe.component';
// import { SOESubscriberComponent} from './components/subscriber/soe.subscriber.component';
import { SubscriberModule } from '../../subscriber.module';


@NgModule({
  declarations: [
  // SOEChangeTypeComponent,
  // SOESubscriberComponent,
  SOEComponent,
  SOEGridComponent,
  SOEAddComponent,
  // SOEAddSpouseComponent,
  // SOEDependentComponent,
  // SOENewDependentComponent,
  // SOEExistingDependentComponent,
  // SOEElectMedicalComponent,
  // SOEElectDentalComponent,
  // SOEUploadComponent,
  // SOEConfirmComponent,
  // SOEPDFComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    NgbModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    PDFExportModule,
    ProgressBarModule,
    UploadModule,
    OverlayModule,
    FontAwesomeModule,
    SubscriberModule
  ],
  providers: [],
  bootstrap: [],
  exports: []
})
export class SOEModule {}
