<form fxFlexFill #searchForm="ngForm" fxFlexLayout="column">
  <div fxFlexFill class="input-group mb-3">
    <input
      style="height: 40px;"
      [ngModel]="filterString"
      type="text"
      class="form-control"
      placeholder="Filter Lookups"
      aria-label="Filter Lookups"
      aria-describedby="search"
      name="search"
      (ngModelChange)="change($event)"
    />
  </div>
</form>
