<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card">
      <div class="card-body" >
        <ngb-alert [dismissible]="false" *ngIf="currentSubscriber.lossOfEligibilityDate" class="alert alert-danger">
          Changes to enrollments or eligibility are not permitted on a terminated account.
        </ngb-alert>
        <h2 class="mobile-text-centered mgtp-x-small mgbt-medium-sm">Spousal Attestation Correction</h2>
        <div [hidden]="!currentSubscriber" fxLayout="column" class="kg-container-noscroll">         
          <div class="grid-container" >
            <kendo-grid
              #kendoGrid
              [data]="spousalAttestations"
              class="k-grid-ignore-click"
              [filterable]="'menu'"
              [filter]="state.filter"
              [sortable]="true"
              [pageable]="true"
              [pageSize]="20"
              [skip]="state.skip"
              (add)="addHandler($event)"
            >
              <!-- begin expand template -->
              <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
                <div fxFill class="card">
                  <div class="card-body" style="margin-left: 12px">
                    <form #attestationForm="ngForm">
                      <!-- <select  *ngIf="col.field === 'responseId'" class="form-control k-grid-ignore-click" name="responseId" [(ngModel)]="dataItem[col.field]">
                        <option *ngFor="let res of lookups.responses" [ngValue]="res.responseId">{{ res.responseName }}</option>
                      </select> -->
                      <div fxLayout="row" fxLayoutAlign="space-between">
                        <fieldset [disabled]="dataItem.attestationId" fxLayout="row" fxLayoutAlign="space-between" fxFlex="75">
                          <date-picker
                          #ad="ngModel"
                          label="Attestation Date"
                          required
                          [name]="'attestationDate' + rowIndex"
                          [(ngModel)]="dataItem['attestationDate']"></date-picker>
                          <date-picker
                          #sd="ngModel"
                          label="Start Date"
                          required
                          [name]="'effectiveStartDate' + rowIndex"
                          [(ngModel)]="dataItem['effectiveStartDate']"></date-picker>
                          <date-picker
                          label="End Date"
                          #ed="ngModel"
                          [name]="'effectiveEndDate' + rowIndex"
                          [(ngModel)]="dataItem['effectiveEndDate']"></date-picker>
                        </fieldset>
                        <div *ngIf="dataItem.attestationId" fxLayout="row" fxLayoutAlign="center flex-end" fxFlex="25" class="fxGap-large">
                          <button style="max-height: 48px" type="button" class="btn hca-red-background" (click)="removeRecord(dataItem, rowIndex)"><fa-icon [icon]="icons.faTrashAlt" size="1x" class="hca-icon-text-button"></fa-icon>Delete</button>
                        </div>
                      </div>
                    </form>
                    <dep-spousal
                      #spousal
                      [disabled]="dataItem.attestationId"
                      [effectiveYear]="dataItem.attestationDate?.getFullYear()"
                      (updateSpousalAttest)="updateSpousalAttest($event)"
                      (moveToNextSubsection)="moveToNextSubsection(subsection.idx)"
                      [spousalOutsideWizard]="true"
                      [spousalAttestation]="dataItem"
                      [lookups]="lookups"
                      [enrollmentPeriod]="enrollmentPeriod"
                      [isSebb]="currentSubscriber.isSebb"
                      [dependent]="newAttestationSpouse"
                    ></dep-spousal>
                  </div>
                </div>
              </ng-template>
              <!-- end expand template -->
              <kendo-grid-column
                [style.flex]="1"
                *ngFor="let col of gridColumns"
                field="{{col.field}}"
                title="{{col.title}}"
                [headerStyle]="{ 'font-weight': 'bold' }"
              >
                <!-- <ng-template kendoGridFilterMenuTemplate
                  let-column="col"
                  let-filter="filter"
                  let-filterService="filterService"
                  let-dataItem="dataItem"
                  >
                </ng-template> -->
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <b>{{dataItem[col.field] | date: 'MM/dd/yyyy'}}</b>
                </ng-template>
              </kendo-grid-column>
              <ng-template kendoGridToolbarTemplate *ngIf="!currentSubscriber.lossOfEligibilityDate">
                <button kendoGridAddCommand type="button">Add new</button>
              </ng-template>  
            </kendo-grid>
          </div>
          <div *ngIf="!currentSubscriber.lossOfEligibilityDate" class="form-row hca-row-margin mgtp-medium-sm" fxLayoutAlign="space-between">
            <button type="button" class="btn btn-primary" (click)="saveChanges()"><fa-icon [icon]="icons.faSave" size="1x"></fa-icon>  Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
