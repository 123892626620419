import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import User from '../models/user';
import { CoreService } from '../services/core.service';
import { sortBy, uniqBy, map, remove, some, cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserOrganizationResolver implements Resolve<User> {
  constructor(
    private userService: UserService,
    private router: Router,
    private coreService: CoreService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    const organizationId = route.paramMap.get('organizationid');
    let systemUserId = route.paramMap.get('id');
    if (!systemUserId) {
      systemUserId = route.parent.paramMap.get('id');
    }
    return this.coreService.getSystemUser().pipe(
      take(1),
      mergeMap(s => {
        if (s.systemUserId && some(s.userOrganizationRoles, r => r.organizationId === organizationId)) {
          const systemUserClone = cloneDeep(s);
          return of(systemUserClone);
        } else {
          return this.userService.getSystemUserWithOrganization(organizationId, systemUserId).pipe(
            take(1),
            mergeMap(usr => {
              if (usr) {
                this.coreService.setSystemUser(usr);
                this.coreService.setOrganization(usr.currentOrganization);
                return of(usr);
              } else {
                this.router.navigate(['/']);
                return EMPTY;
              }
            })
          );
        }
      })
    );
  }
}
