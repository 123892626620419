import * as dayjs from 'dayjs';

export default class LowerLimitEligibilityDateCheck {
  eligibilityDate: Date;
  agencyCode: string;
  sebbIsOnOrBeforeFirstDayOfSchool: boolean;
  lowerLimitDate?: Date;
  coverageEffectiveDate?: Date;
  isSebb?: boolean;
  isWithinLowerLimit?: boolean;

  constructor(lowerLimit?) {
    if (lowerLimit) {
      this.eligibilityDate = lowerLimit.eligibilityDate ? new Date(lowerLimit.eligibilityDate) : null;
      this.lowerLimitDate = lowerLimit.lowerLimitDate ? new Date(lowerLimit.lowerLimitDate) : null;
      this.coverageEffectiveDate = lowerLimit.coverageEffectiveDate ? new Date(lowerLimit.coverageEffectiveDate) : null;
      this.agencyCode = lowerLimit.agencyCode;
      this.sebbIsOnOrBeforeFirstDayOfSchool = lowerLimit.sebbIsOnOrBeforeFirstDayOfSchool;
      this.isSebb = lowerLimit.isSebb;
      this.isWithinLowerLimit = lowerLimit.isWithinLowerLimit;
    }
  }
}
