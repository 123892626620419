import { map } from 'lodash';
import SubmissionFileType from './submissionFileType';
import Organization from './organization';
import SubmissionFileResponse from './submissionFileResponse';

export default class SubmissionFile {
  submissionFileId: string;
  submissionFileTypeId: string;
  submissionFileName: string;
  organizationId: string;
  uploadDate: Date;
  uploadUser: string;
  submissionFileType?: SubmissionFileType;
  organization?: Organization;
  submissionFileResponses?: SubmissionFileResponse[];

  constructor(file?) {
    if (file) {
      this.submissionFileId = file.submissionFileId;
      this.submissionFileTypeId = file.submissionFileTypeId;
      this.submissionFileName = file.submissionFileName;
      this.organizationId = file.organizationId;
      this.uploadDate = new Date(file.uploadDate) || null;
      this.uploadUser = file.uploadUser;
      this.submissionFileType = file.submissionFileType ? file.submissionFileType : null;
      this.organization = file.organization ? file.organization : null;
      this.submissionFileResponses = file.submissionFileResponses ? map(file.submissionFileResponses, f => {
        const fileResponse = new SubmissionFileResponse(f);
        fileResponse.uploadDate = this.uploadDate;
        return fileResponse;
      }) : [];
    }
  }

}
