import { sortBy } from 'lodash';
import Response from './response';
import Question from './question';

export default class AttestationType {
  attestationTypeId: string;
  attestationTypeCode: string;
  attestationTypeName: string;
  openMonth: number;
  openDay: number;
  closeMonth: number;
  closeDay: number;
  obsoleteFlag: boolean;
  obsoleteDate: Date;
  responses ?: Response[];
  questions ?: Question[];

    constructor(attestationType?) {
      if (attestationType) {
        this.attestationTypeId = attestationType.attestationTypeId || null;
        this.attestationTypeCode = attestationType.attestationTypeCode || null;
        this.attestationTypeName = attestationType.attestationTypeName || null;
        this.openMonth = attestationType.openMonth;
        this.openDay = attestationType.openDay;
        this.closeMonth = attestationType.closeMonth;
        this.closeDay = attestationType.closeDay;
        this.obsoleteFlag = attestationType.obsoleteFlag;
        this.obsoleteDate = attestationType.obsoleteDate ? new Date(attestationType.obsoleteDate) : null;
        if (attestationType.responses) {
          this.responses = [];
          attestationType.responses.forEach(r => {
              this.responses.push(new Response({
                ResponseId: r.responseId,
                ResponseCode: r.responseCode,
                ResponseName: r.responseName,
                ObsoleteFlag: r.obsoleteFlag,
                ObsoleteDate: r.obsoleteDate,
                Rate: r.rate,
              })
            );
          });
        }
        if (attestationType.questions) {
          this.questions = [];
          attestationType.questions.forEach(q => {
              this.questions.push(new Question({
                QuestionId: q.questionId,
                QuestionName: q.questionName,
                QuestionText: q.questionText,
                ObsoleteFlag: q.obsoleteFlag,
                ObsoleteDate: q.obsoleteDate ? new Date(q.ObsoleteDate) : null,
                Responses: q.responses,
                SortOrder: q.sortOrder
              })
            );
          });
          this.questions = sortBy(this.questions, 'sortOrder');
        }
      }

    }
}
