import Plan from "./plan";

export default class Carrier {
  carrierId: string;
  carrierCode: string;
  carrierName: string;
  plans: Plan[];

  constructor(carrier?) {
    if (carrier) {
      this.carrierId = carrier.carrierId;
      this.carrierCode = carrier.carrierCode;
      this.carrierName = carrier.carrierName;
    }
  }

}
