<div [class]="forPdf ? 'for-pdf' : ''" fxLayout="column" fxFlexFill>
  <div *ngIf="!forPdf && (step == pageOneStep || (step == pageTwoStep && hasSecondPage))" fxLayout="column" fxLayoutAlign="center start" class="card-title">
    <div fxFlex="noshrink">
      <h3>{{ documentTitleText }}</h3>
    </div>
    <div class="hca-pdf-table-subheader pad-sm-container" fxFlex="80">
      {{ documentHeaderText }}
    </div>
  </div>
  <ng-container *ngIf="forPdf || !step || (step && step == pageOneStep)">
    <div *ngIf="forPdf" fxLayout="row" fxLayoutAlign="end end" fxFlexFill>
      <div fxFlex="40" fxLayoutAlign="end start" style="margin-right: 20px; text-justify: right">Print date: {{ currentDate | date: 'MM/dd/yyyy' }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end" fxFlexFill>
      <div fxFlex="40" fxLayoutAlign="end start" style="margin-right: 20px; text-justify: right">
        <span style="margin-right: 20px; text-justify: right"><b>Employer:&nbsp;</b></span>
        <span style="margin-right: 20px; text-justify: right"> {{ subscriber?.organization?.organizationName }}</span>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <div>{{ subscriber?.firstName}} {{ subscriber?.lastName}}</div>
      <div>{{ subscriber?.residentialAddress.addressLineOne}}</div>
      <div>{{ subscriber?.residentialAddress.addressLineTwo}}</div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <div>
          {{ subscriber?.residentialAddress.city}}, {{ subscriber?.residentialAddress.state }}
          {{ subscriber?.residentialAddress.zipcodeNumber}}
        </div>
      </div>
    </div>
    <div class="hca-pdf-table-header mgtp-medium" fxLayout="row" fxLayoutAlign="center end">
      <div aria-level="4" role="heading">{{ enrolled ? 'Enrollment information' : 'Coverage elections information' }}</div>
    </div>
    <div style="border: 1px solid black;" class="hca-pdf-table-content mgtp-medium-sm mgbt-medium-sm">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="hca-pdf-table-subheader">
        <div fxFlexOffset="5" fxFlex="20"><h5 aria-level="5">Member name</h5></div>
        <div [fxFlex]="60 / this.electablePlanTypes.length" *ngFor="let planType of this.electablePlanTypes">
          <h5 aria-level="5" style="text-align: center">{{ planType.planTypeName  }} coverage {{ enrolled ? 'Enrolled' : 'Effective' }} date</h5>
        </div>
      </div>
      <div *ngFor="let member of summaryObject?.memberSummary" fxLayout="row" fxLayoutAlign="space-between end">
        <div fxFlexOffset="5" fxFlex="20" fxLayoutAlign="start start">
          <h5 aria-level="6">{{ member.lastName }}, {{ member.firstName }}</h5>
        </div>
        <div [fxFlex]="60 / this.electablePlanTypes.length" *ngFor="let planType of this.electablePlanTypes">
          <h5 aria-level="6" style="text-align: center">
            {{ member.effectiveEnrollmentsByPlanType[planType.planTypeCode] ? 
                (member.effectiveEnrollmentsByPlanType[planType.planTypeCode].effectiveStartDate | date: 'MM/dd/yyyy') + (
                  member.effectiveEnrollmentsByPlanType[planType.planTypeCode].effectiveEndDate ? ' - ' + 
                  (member.effectiveEnrollmentsByPlanType[planType.planTypeCode].effectiveEndDate | date: 'MM/dd/yyyy') : ''
              ) : 'NOT ENROLLED' }}
          </h5>
        </div>
      </div>
    </div>
    <div class="hca-pdf-table-header mgtp-medium" fxLayout="row" fxLayoutAlign="center end">
      <div aria-level="4" role="heading">HCA-sponsored coverage</div>
    </div>
    <div *ngIf="shouldShowMedicalCoverageDetail" fxLayout="row" class="hca-pdf-table-content mgtp-small" fxLayoutAlign="space-between start" fxFlexFill>
      <div fxFlex="33"><h5>Medical coverage provided by:</h5></div>
      <div fxFlex="33">
        <h5>{{ enrolled ? (summaryObject.effectiveMedicalPlan) : (summaryObject.effectiveMedicalPlan) }}</h5>
      </div>
      <div fxFlex></div>
    </div>
    <div fxLayout="row" class="hca-pdf-table-content" fxLayoutAlign="end end" fxFlexFill>
      <div *ngIf="shouldShowMedicalCoverageDetail" fxLayout="column" [fxFlex]="shouldShowPremiums && !isRetiree ? 80 : 60" fxLayoutAlign="end end">
        <div fxFlexFill fxLayoutAlign="end end"><h5 fxFlexFill fxLayoutAlign="end end">Medical premium:</h5></div>
        <div fxFlexFill fxLayoutAlign="end end"><h5 fxFlexFill fxLayoutAlign="end end">Tobacco surcharge:</h5></div>
        <div fxFlexFill fxLayoutAlign="end end"><h5 fxFlexFill fxLayoutAlign="end end">Spousal/state-registered domestic partner surcharge:</h5></div>
      </div>
      <div *ngIf="shouldShowMedicalCoverageDetail" fxLayout="column" fxLayoutAlign="end end" [fxFlex]="shouldShowPremiums ? 10 : 40">
        <div>
          <h5 *ngIf="!isRetiree">{{ shouldShowPremiums ? (summaryObject.medicalPremium | currency) : 'Contact your personnel, payroll, or benefits office' }}</h5>
        </div>
        <div>
          <h5 *ngIf="!isRetiree">{{ summaryObject.tobaccoUseSurcharge | currency }}</h5>
        </div>
        <div>
          <h5 *ngIf="!isRetiree">{{ summaryObject.spousalCoverageSurcharge | currency }}</h5>
        </div>
      </div>
    </div>
    <div *ngIf="shouldShowDentalCoverageDetail" fxLayout="row" class="hca-pdf-table-content" fxLayoutAlign="space-between start" fxFlexFill>
      <div fxFlex="33"><h5>Dental coverage provided by:</h5></div>
      <div fxFlex="33">
        <h5>{{ enrolled ? (summaryObject.effectiveDentalPlan ) : (summaryObject.effectiveDentalPlan ) }}</h5>
      </div>
      <div fxFlex></div>
    </div>
    <div *ngIf="shouldShowDentalCoverageDetail" fxLayout="row" class="hca-pdf-table-content" fxLayoutAlign="end end">
      <div fxLayout="column" fxLayoutAlign="end end">
        <div><h5>Dental premium:</h5></div>
      </div>
      <div fxLayout="column" fxLayoutAlign="end end" [fxFlex]="shouldShowPremiums && !isRetiree ? 10 : 40">
        <div>
          <h5 *ngIf="!isRetiree">{{ shouldShowPremiums ? (summaryObject.dentalPremium | currency) : 'Contact your personnel, payroll, or benefits office' }}</h5>
        </div>
      </div>
    </div>
    <div *ngIf="shouldShowVisionCoverageDetail" fxLayout="row" class="hca-pdf-table-content" fxLayoutAlign="space-between start" fxFlexFill>
      <div fxFlex="33"><h5>Vision coverage provided by:</h5></div>
      <div fxFlex="33">
        <h5>{{ enrolled ? (summaryObject.effectiveVisionPlan ) : (summaryObject.effectiveVisionPlan ) }}</h5>
      </div>
      <div fxFlex></div>
    </div>
    <div *ngIf="shouldShowVisionCoverageDetail" fxLayout="row" class="hca-pdf-table-content" fxLayoutAlign="end end">
      <div fxLayout="column" fxLayoutAlign="end end">
        <div><h5>Vision premium:</h5></div>
      </div>
      <div fxLayout="column" fxLayoutAlign="end end" [fxFlex]="shouldShowPremiums ? 10 : 40">
        <div>
          <h5>{{ shouldShowPremiums ? (summaryObject.visionPremium | currency) : 'Contact your personnel, payroll, or benefits office' }}</h5>
        </div>
      </div>
    </div>    
    <div fxLayout="row" class="hca-pdf-table-content" fxLayoutAlign="end end">
      <div class="hca-pdf-top-border" fxLayout="column" fxLayoutAlign="end end" fxFlexFill>
        <div fxLayout="row" class="hca-pdf-table-content" fxLayoutAlign="end end" fxFlexFill>
          <div fxLayout="column" fxLayoutAlign="end end">
            <div [fxFlex]="shouldShowPremiums && !isRetiree ? 80 : 60" fxLayoutAlign="end end"><h5>Total monthly premium:</h5></div>
          </div>
          <div fxLayout="column" fxLayoutAlign="end end" [fxFlex]="shouldShowPremiums && !isRetiree ? 10 : 40">
            <div>
              <h5 *ngIf="!isRetiree">
               {{ shouldShowPremiums ? (summaryObject?.totalCost | currency) : 'Contact your personnel, payroll, or benefits office' }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="forPdf && isRetiree" class="mgtp-large h4 card pad-std-container" fxLayoutAlign="space-between center" fxFlexFill fxLayout="column">
        Refer to the following link for the monthly premiums: <br/><a target="_blank" href="https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2024.pdf">https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2024.pdf</a>
    </div>
    <div fxFlexFill *ngIf="!forPdf" class="mgtp-large">
      <h5>
        Please review the enrollment information above for accuracy. If the information is correct, select next to proceed. If you need to make a correction to any section, select
        the section at the top of the page.
      </h5>
    </div>
  </ng-container>
  <div *ngIf="forPdf && hasSecondPage" class="page-break"></div>
  <ng-container *ngIf="hasSecondPage && (forPdf || (step && step === pageTwoStep && step !== pageOneStep))">
    <div class="hca-pdf-table-header mgtp-medium mgbt-small" fxLayout="row" fxLayoutAlign="center end">
      <div aria-level="4" role="heading">{{ isCobra || isRetiree || isUnpaid ? '' : 'Life insurance coverage' }}</div>
    </div>
    <div class="pdf-soi-content">
      {{ isCobra || isRetiree || isUnpaid ? '' : 'All life insurance is administered by MetLife. If you have questions about your coverage, call MetLife at 1-833-854-9624. ' }}
    </div>
    <div fxLayout="row" fxLayoutAlign="start end" fxFlexOffset="2"  *ngIf="!isUnpaid && !isCobra && !isRetiree">
      <div class="hca-pdf-bold">Employer-paid coverages</div>
    </div>
    <div fxLayout="row" *ngIf="!isCobra && !isRetiree && !isUnpaid" class="hca-pdf-table-content" fxLayoutAlign="space-between end">
      <div fxFlex="15"></div>
      <div fxFlex="10">
        <h5>{{ summaryObject.lifeBenefitAmount | currency }}</h5>
      </div>
      <div fxFlex="33">
        <h5>{{ summaryObject.effectiveLifePlan }}</h5>
      </div>
      <div fxFlex></div>
    </div>
    <div fxLayout="row" *ngIf="!isCobra && !isRetiree && !isUnpaid" class="hca-pdf-table-content" fxLayoutAlign="space-between end">
      <div fxFlex="15"></div>
      <div fxFlex="10">
        <h5>{{ summaryObject.addBenefitAmount | currency }}</h5>
      </div>
      <div fxFlex="33">
        <h5>{{ summaryObject.effectiveADDPlan }}</h5>
      </div>
      <div fxFlex></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start end" fxFlexOffset="2">
      <div>
        <span class="hca-pdf-bold">Supplemental coverages</span>
      </div>
    </div>
    <div>
      Please visit MetLife to view your optional insurance elections, or call MetLife at
      1-833-854-9624.
    </div>
    <div *ngIf="!isCobra && !isRetiree && (!isUnpaid || (isUnpaid && summaryObject?.effectiveSupplementalLTDPlan))" class="hca-pdf-table-header mgtp-medium mgbt-small" fxLayout="row" fxLayoutAlign="center end">
      <div aria-level="4" role="heading">Long-term disability insurance coverage</div>
    </div>
    <div *ngIf="!isCobra && !isRetiree && !isUnpaid && !sebbLocallyEligible">Employer-paid LTD with 90-day waiting period</div>
    <div *ngIf="!isCobra && !isRetiree && summaryObject?.effectiveSupplementalLTDPlan" [ngClass]="{'body-container': subscriber.organization?.disableOptionalLTD}">
      <span *ngIf="subscriber.organization?.disableOptionalLTD">*</span>LTD coverage:
      {{ enrolled ? (summaryObject.effectiveSupplementalLTDPlan ) : summaryObject.effectiveSupplementalLTDPlan ? (summaryObject.effectiveSupplementalLTDPlan ) : 'NOT ENROLLED' }}
    </div>
    <div *ngIf="isRetiree">
      <span *ngIf="isRetiree">Retiree </span>term life coverage:
      {{ summaryObject.retireeTermLifeEnrolledInd? 'Enrolled' : 'Not enrolled' }}
    </div>
    <div *ngIf="isUnpaid" fxFlexOffset="2">
      Term life coverage:
      {{ summaryObject.basicLifeEnrolledInd? 'Enrolled' : 'Not enrolled' }}
    </div>
    <div *ngIf="subscriber.organization?.disableOptionalLTD" class="mgtp-medium pdf-soi-content">Enrollment in supplemental ltd is managed by your employer.  This may not reflect your actual enrollment 
      in supplemental ltd.  Please contact your employer for questions or changes.</div>    
  </ng-container>
</div>
