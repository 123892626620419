export default class RelationshipType {
  relationshipTypeId: string;
  relationshipTypeCode: string;
  relationshipTypeName: string;
  sortOrder: number;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(relationshipType?) {
    if (relationshipType) {
      this.relationshipTypeId = relationshipType.relationshipTypeId;
      this.relationshipTypeCode = relationshipType.relationshipTypeCode;
      this.relationshipTypeName = relationshipType.relationshipTypeName;
      this.sortOrder = relationshipType.sortOrder;
      this.obsoleteFlag = relationshipType.obsoleteFlag;
      this.obsoleteDate = relationshipType.obsoleteDate ? new Date(relationshipType.ObsoleteDate) : null;
    }
  }
}
