const transformCurrency = (value: string): number => {
  const modifier = value.endsWith('-') ? -1 : 1;
  return Number(value.replace(/[,-]/g, '')) * modifier;
};
const displayCurrency = (value: number): string => {
  if (!isNaN(value)) {
    const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', currency: 'USD' });
    return formatter.format(value);
  }

  return null;
};

export const billingSpecification = [
  {
    name: 'AgencyCode',
    width: 6,
    start: 1,
    type: 'string'
  },
  {
    name: 'LastName',
    width: 20,
    start: 7,
    type: 'string'
  },
  {
    name: 'FirstName',
    width: 20,
    start: 27,
    type: 'string'
  },
  {
    name: 'MI',
    width: 1,
    start: 47,
    type: 'string'
  },
  {
    name: 'SSN',
    width: 9,
    start: 48,
    type: 'string'
  },
  {
    name: 'TransactionDate',
    width: 8,
    start: 57,
    type: 'string'
  },
  {
    name: 'TransactionType',
    width: 1,
    start: 65,
    type: 'string'
  },
  {
    name: 'CoveragePeriod',
    width: 6,
    start: 66,
    type: 'string'
  },
  {
    name: 'BatchNumber',
    width: 8,
    start: 72,
    type: 'string'
  },
  {
    name: 'HealthCarrierCode',
    width: 4,
    start: 80,
    type: 'string'
  },
  {
    name: 'HealthFamilyComposition',
    width: 15,
    start: 84,
    type: 'string'
  },
  {
    name: 'DentalCarrierCode',
    width: 4,
    start: 99,
    type: 'string'
  },
  {
    name: 'DentalFamilyComposition',
    width: 15,
    start: 103,
    type: 'string'
  },
  {
    name: 'VisionCarrierCode',
    width: 4,
    start: 118,
    type: 'string'
  },
  {
    name: 'VisionFamilyComposition',
    width: 15,
    start: 122,
    type: 'string'
  },
  {
    name: 'TransactionAmount',
    width: 10,
    start: 137,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  },
  {
    name: 'EmployeeHealthPremiumAmt',
    width: 10,
    start: 147,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  },
  {
    name: 'TobaccoUseSurcharge',
    width: 10,
    start: 157,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  },
  {
    name: 'SpousalSurcharge',
    width: 10,
    start: 167,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  },
  {
    name: 'LTDSupplementalPercentageRate',
    width: 6,
    start: 177,
    type: 'numeric'
  },
  {
    name: 'LTDEnrollment',
    width: 1,
    start: 183,
    type: 'numeric'
  },
  {
    name: 'EmployerContribution',
    width: 10,
    start: 184,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  },
  {
    name: 'CurrentMonthInvoiceSEBBOrg',
    width: 14,
    start: 194,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  },
  {
    name: 'BalanceForwardAmountSEBBOrg',
    width: 14,
    start: 208,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  },
  {
    name: 'TotalAmountDueSEBBOrg',
    width: 13,
    start: 222,
    type: 'numeric',
    transform: transformCurrency,
    format: displayCurrency,
    gridFormat: '{0:c2}'
  }
];
