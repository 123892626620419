<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <div class="state">
      <div class="card">
        <div class="card-body page-container">
          <h1>Application Settings</h1>
          <div class="card">
            <div class="card-body">
              <ngb-accordion [destroyOnHide]="false" #settingAccord="ngbAccordion">
                <!-- <ngb-panel>
                  <ng-template ngbPanelHeader let-opened="opened">
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                      <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                      <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                      Manage lookups
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <lookups (handlePut)="updateTableValue($event)" (handlePost)="createTableValue($event)" [lookups]="lookups"></lookups>
                  </ng-template>
                </ngb-panel> -->
                <!-- this is currently in a future sprint -->
                <ngb-panel>
                  <ng-template ngbPanelHeader let-opened="opened">
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                      <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                      <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                      Manage open enrollment
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <lookups [readonly]="!isHcaSysAdmin" [agencies]="lookups.agencies" (handlePut)="updateTableValue($event)" (handlePost)="createTableValue($event)" [oe]="true" [lookups]="openEnrollments"></lookups>
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelHeader let-opened="opened">
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                      <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                      <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                      Manage system messages
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <lookups [readonly]="!isHcaSysAdmin" [disabledDatesFn]="disableInvalidMessageDates" (handlePut)="updateTableValue($event)" (handlePost)="createTableValue($event)" [genericMessages]="true" [lookups]="messages" [checklist]="lookups['modules']"></lookups>
                  </ng-template>
                </ngb-panel>
                <ngb-panel>
                  <ng-template ngbPanelHeader let-opened="opened">
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                      <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                      <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                      Manage system-wide application settings
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <lookups [readonly]="!isHcaSysAdmin" [appSettings]="true" [alwaysExpanded]="true" (handlePut)="updateTableValue($event)" (handlePost)="createTableValue($event)" [lookups]="applicationSettings"></lookups>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>                             
          </div>

          <div class="card" *ngIf="isHcaSysAdmin">
            <div class="card-body">
              <button class="btn btn-primary" (click)="gotoTestingPage()">Testing Page</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>