<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid error-handling-main">
  <div class="state">
    <div class="card">
      <div class="card-body">
        <h2 class="mobile-text-centered" style="margin-top: 8px; margin-bottom: 15px">HCA Error Handling</h2>
        <h5>
          Pay1 rejected transactions from PEBB My Account. Correction to PEBB My Account record may be required prior to re-sending.
          <br />
          Making corrections from Error Handling does not update origin record.
        </h5>
        <div class="grid-container">
          <kendo-grid
            #kendoGrid
            [data]="clonedGridData"
            class="k-grid-ignore-click"
            [filterable]="'menu'"
            [filter]="state.filter"
            [skip]="state.skip"
            [sortable]="true"
            [pageable]="true"
            [pageSize]="20"
            
            (dataStateChange)="dataStateChange($event)"
            (detailExpand)="getRowDetails($event)"
            (pageChange)="pageChange($event)"
          >
            <ng-template kendoGridToolbarTemplate>
              <button type="button" kendoGridExcelCommand icon="file-excel">Download</button>
            </ng-template>
            <kendo-grid-column
              class="k-grid-ignore-click"
              [style.flex]="col.flex || 1"
              *ngFor="let col of gridColumns"
              field="{{ col.field }}"
              title="{{ col.title }}"
              width="{{ col.width }}"
              [format]="col.format"
              [filter]="col.filter ? col.filter : null"
              [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
            >
              <ng-template kendoGridFilterMenuTemplate
                let-column="col"
                let-filter="filter"
                let-filterService="filterService"
                *ngIf="col.field.endsWith('Date')"
                >
                <date-picker
                  name="dateFilter"
                  [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
                  (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
                  [label]="col.title">
                </date-picker>
              </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
              <div fxFill class="card">
                <div class="card-body" style="margin-left: 12px">
                  <form  #transactionForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
                    <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
                      <div class="form-group" fxFlex="20">
                        <label for="lastName">Last name</label>
                        <input readOnly type="text" class="form-control" name="lastName" [(ngModel)]="dataItem.member.lastName" />
                      </div>
                      <div class="form-group" fxFlex="20">
                        <label for="firstName">First name</label>
                        <input readOnly type="text" class="form-control" name="firstName" [(ngModel)]="dataItem.member.firstName" />
                      </div>
                      <div class="form-group" fxFlex="15">
                        <label for="socialSecurityNumber">SSN</label>
                        <input
                          #ssn="ngModel"
                          readOnly
                          type="text"
                          class="form-control"
                          minlength="11"
                          maxlength="11"
                          [ngModel]="dataItem.member.socialSecurityNumber | ssn"
                          name="socialSecurityNumber"
                        />
                      </div>
                      <div class="form-group" fxFlex="25">
                        <label for="socialSecurityNumber">Subscriber GUID</label>
                        <input readOnly type="text" class="form-control" [ngModel]="dataItem.member.subscriberMemberId" name="subGUID" />
                      </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                      <div class="form-group" fxFlex="20">
                        <label for="receivedInd">Received</label>
                        <select disabled readOnly class="form-control" name="receivedInd" [(ngModel)]="dataItem.receivedInd">
                          <option [ngValue]="true">True</option>
                          <option [ngValue]="false">False</option>
                        </select>
                      </div>
                      <div  class="form-group" fxFlex="20">
                        <label for="processedInd">Processed</label>
                        <select disabled readOnly class="form-control" name="processedInd" [(ngModel)]="dataItem.processedInd">
                          <option [ngValue]="true">True</option>
                          <option [ngValue]="false">False</option>
                        </select>
                      </div>
                      <div class="form-group" fxFlex="20">
                        <label for="errInd">Errored</label>
                        <select disabled readOnly class="form-control" name="errInd" [(ngModel)]="dataItem.errorInd">
                          <option [ngValue]="true">True</option>
                          <option [ngValue]="false">False</option>
                        </select>
                      </div>
                      <div class="form-group" fxFlex="20">
                        <label for="depTransInd">Depends on another transaction</label>
                        <select disabled readOnly class="form-control" name="depTransInd" [(ngModel)]="dataItem.depTransInd">
                          <option [ngValue]="true">True</option>
                          <option [ngValue]="false">False</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                      <div class="form-group" fxFlex="20">
                        <date-picker
                          #createdDate="ngModel"
                          [name]="'createdDate' + rowIndex"
                          label="Processed date"
                          readOnly
                          [(ngModel)]="dataItem.createdDate"></date-picker>
                      </div>
                      <div fxFlex="20" class="form-group" fxLayout="row">
                        <div fxLayoutAlign="center center" style="font-weight: bold;">
                          <checkbox name="retriggerAllInd" [(ngModel)]="retrigger" (change)="retriggerChecked()">Retrigger all subsequent transactions</checkbox>
                        </div>
                      </div>
                      <div fxFlex="30" class="form-group" fxLayout="row" fxLayoutAlign="center center">
                        <button *ngIf="!showReallyDelete" id="delete-transaction-btn" (click)="showReallyDelete = true" class="btn btn-danger">Delete Transaction</button>
                        <div *ngIf="showReallyDelete" >
                          <div>
                            Are you sure you would like to delete this transaction? This cannot be undone.
                          </div>
                          <div fxLayoutAlign="space-between">
                            <button id="transaction-cancel-btn" (click)="showReallyDelete = false" class="btn btn-light">Cancel</button>
                            <button id="delete-transaction-btn-rly" (click)="deleteTransaction(dataItem)" class="btn btn-danger">Yes, Delete This Transaction</button>
                          </div>
                        </div>
                      </div>                      
                    </div>
                    <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                      <div class="form-group" fxFlex="45">
                        <label for="transactionSetObj">Transaction Set Object</label>
                        <textarea name="transactionSetObj" [(ngModel)]="dataItem.transactionSetObject" class="form-control" id="transactionSetObj" rows="6"></textarea>
                      </div>          
                      <div class="form-group" fxFlex="45">
                        <label for="errorObject">Error Response</label>
                        <textarea readOnly name="errorObject" [(ngModel)]="dataItem.errorObject" class="form-control" id="errorObject" rows="6"></textarea>
                      </div>                                               
                    </div>
                    <br />
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
                      <button fxFlex="30" id="transaction-submit-btn" (click)="saveTransaction(dataItem)" class="btn btn-primary">Submit</button>
                      <button fxFlex="30" id="transaction-cancel-btn" (click)="reSetDataForGrid()" class="btn btn-light">Cancel</button>
                    </div>
                  </form>
                </div>
              </div>
            </ng-template>
            <kendo-grid-excel [fetchData]="allData.bind({ gd: transactions })" fileName="error_handling_{{dayjs().format('MMDDYYYY')}}.xlsx"></kendo-grid-excel>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
</div>
