<div fxFill fxLayout="column">
  <div fxLayout.lt-sm="column" fxLayout="row" >
    <form  fxLayoutAlign="space-between" #coverageDateForm="ngForm">
      <div fxFlex="30" fxLayout="column">
        <date-picker
          #coverageEffectiveDate="ngModel"
          name="coverageEffectiveDate"
          label="Coverage Effective Date"
          required
          onlyAllowFirstOfMonth
          [min]="selfpay.member?.memberSelfPayOrigin?.agencyEffectiveStartDate"
          [(ngModel)]="selfpay.coverageEffectiveDate"></date-picker>
        <div *ngIf="coverageDateForm.controls.coverageEffectiveDate?.errors?.ngbDate?.maxDate" class="alert alert-danger">The future date entered is not allowed based on the current invoicing schedule.</div>
        <div *ngIf="coverageDateForm.controls.coverageEffectiveDate?.errors?.ngbDate?.minDate" class="alert alert-danger">Coverage effective date must be equal to or greater than the self-pay agency effective date.</div>
        <div *ngIf="coverageDateForm.controls.coverageEffectiveDate?.errors?.firstOfMonth" class="alert alert-danger">Effective date must be the first of a month.</div>
      </div>
      <div fxFlex="65">
        <span>
          Input the effective date for enrollment associated with COBRA, unpaid leave, or retiree coverage. All enrollments/adjustments made to coverage(s) in this
          request will be effective on the date entered. Coverages that are removed/terminated will end on the last day of the prior month.
        </span>
      </div>
    </form>
  </div>

  <div class="mgtp-small" fxLayout="row" fxLayoutAlign="space-around">
    <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
    <button [disabled]="!selfpay?.coverageEffectiveDate" (click)="submitEffectiveDate()" class="btn btn-primary">Next</button>
  </div>
</div>
