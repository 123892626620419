
<div class="sync-error">
    <div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
        <div class="state">
            <div class="card">
                <div class="card-body">
                    <h2 class="mobile-text-centered" style="margin-top: 8px; margin-bottom: 15px">834 Carrier transaction management</h2>
                    <h5>
                    This section displays the list of 834 transactions associated with this account.  834 transactions can be edited, resent, or marked to not send.
                    </h5>
                    <div class="grid-container">
                        <subscriber-search
                        organizationId
                        style="flex: 70;"
                        (searchSubmitted)="onSearchSubmitted($event)"></subscriber-search>

                        <generic-grid
                            (selectedRow)="onSubscriberSelected($event)"
                            (deselectedRow)="onSubscriberDeselected()"
                            [selectBy]="'memberId'"
                            [selectable]="true"
                            [selectedKeys]=""
                            [pageable]="true"
                            [pageSize]="20"
                            [gridData]="subscriberSearchResults"
                            [gridColumns]="searchResultColumns"
                            [filterable]="true"
                            >
                        </generic-grid>
                    </div>
                    <div *ngIf="selectedSubscriber" class="subscriber-history mgtp-medium" [class]="{ printing: printing }">
                        <h2>Subscriber Details</h2>
                        <h3>
                          <div>{{selectedSubscriber.firstName}} {{selectedSubscriber.lastName}}</div>
                          <div>{{selectedSubscriber.SSN | ssn}} {{selectedSubscriber.birthDate | date: 'MM/dd/yyyy'}}</div>
                        </h3>

                        <div class="grid-container">
                            <kendo-grid
                              #kendoGrid
                              [data]="clonedGridData"
                              class="k-grid-ignore-click"
                              [filterable]="'menu'"
                              [filter]="state.filter"
                              [skip]="state.skip"
                              [sortable]="true"
                              [sort]="sort"
                              (sortChange)="changeSort($event)"
                              (filterChange)="changeFilter($event)"
                              [pageable]="false"
                              (dataStateChange)="dataStateChange($event)"
                            >
                                <kendo-grid-column
                                    class="k-grid-ignore-click"
                                    [style.flex]="col.flex || 1"
                                    *ngFor="let col of carrier834TransactionColumns"
                                    field="{{ col.field }}"
                                    title="{{ col.title }}"
                                    width="{{ col.width }}"
                                    [format]="col.format"
                                    [filter]="col.filter ? col.filter : null"
                                    [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
                                >
                                    <ng-template kendoGridFilterMenuTemplate
                                    let-column="col"
                                    let-filter="filter"
                                    let-filterService="filterService"
                                    *ngIf="col.field.endsWith('Date')"
                                    >
                                    <date-picker
                                        name="dateFilter"
                                        [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
                                        (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
                                        [label]="col.title">
                                    </date-picker>
                                    </ng-template>
                                    <ng-template kendoGridCellTemplate *ngIf="col.field==='output'" let-dataItem>
                                        <div (click)="selectedCarrier834Hold=dataItem" fxFill fxLayoutAlign="center center" style="cursor: pointer" *ngIf="dataItem.output">
                                            <span class="hca-fontawesome-icon" aria-label="view transaction text"><fa-icon [icon]="icons.faEllipsis"></fa-icon></span>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                    class="k-grid-ignore-click"
                                    title="Status"
                                    width="75px"
                                    [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
                                    >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div fxLayoutAlign="center center">
                                            <span class="hca-fontawesome-icon" *ngIf="!dataItem.previouslySent && dataItem.sentDate" alt="sent" title="sent"><fa-icon [icon]="icons.faCheck" size="1x"></fa-icon></span>
                                            <span class="hca-fontawesome-icon-info" *ngIf="!dataItem.sentDate" alt="sending" title="sending"><fa-icon [icon]="icons.faRotateRight" size="1x" ></fa-icon></span>
                                            <span class="hca-fontawesome-icon" *ngIf="dataItem.previouslySent && dataItem.sentDate" alt="resent" title="resent"><fa-icon [icon]="icons.faCheckDouble" size="1x" ></fa-icon></span>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column
                                    class="k-grid-ignore-click"
                                    width="80px"
                                    title="Resend"
                                    [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
                                    >
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <checkbox name="resend" [(ngModel)]="dataItem.resend" (ngModelChange)="checkResend(dataItem)" [containerfxLayoutAlign]="'center center'"></checkbox>
                                    </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                          </div>
                          <div fxFlexLayout="Column" class="fxGap-medium mgtp-large">
                            <div>
                                If a transaction is selected to resend, all subsequent transactions that were sent on different days due to sequencing or adjustments on a 
                                later day will be selected and set in the order they appear.  You can unselect any transactions that should not be resent.
                            </div>
                            <div fxFlex="20" fxLayoutAlign="end">
                                <button type="button" class="btn btn-primary" (click)="resendTransactions()">Resend Transactions</button>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kendo-dialog
    [title]="'Carrier 834 output for transaction ID ' + selectedCarrier834Hold?.tranId"
    *ngIf="selectedCarrier834Hold"
    (close)="selectedCarrier834Hold = null"
    [minWidth]="400"
    [maxWidth]="700"
    [maxHeight]="700"
  ><div class="pad-std-container"><pre>{{selectedCarrier834Hold.output}}</pre></div>
  </kendo-dialog>
