import Question from 'src/app/models/question';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTrashAlt, faSave, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { some, find, startsWith, cloneDeep, forEach, filter, flatMap } from 'lodash';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State, FilterDescriptor } from '@progress/kendo-data-query';
import { CoreService } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import Subscriber from 'src/app/models/subscriber';
import SystemUser from 'src/app/models/user';
import Member from 'src/app/models/member';
import Attestation from 'src/app/models/attestation';
import { AttestationService } from 'src/app/services/attestation.service';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import DependentComposite from 'src/app/models/dependentComposite';

@UntilDestroy()
@Lookups(LookupType.AttestationType, LookupType.Response, LookupType.Question, LookupType.PlanType)
@Component({
  selector: 'spousal-correction',
  templateUrl: 'subscriberManagement.spousal.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class SubscriberManagementSpousalComponent implements OnInit {
  icons = {
    faTrashAlt: faTrashAlt,
    faSave: faSave,
    faCalendar: faCalendar
  };
  lookups = {
   responses: [],
   attestationTypes: [],
   questions: [],
   planTypes: []
  };
  systemUser: SystemUser;
  currentSubscriber: Subscriber;
  spousalAttestations: Attestation[] = [];
  gridColumns = [
    {field: 'attestationDate', title: 'Attestation date', format: { date: 'mm/dd/yyyy' }, filter: 'date'},

  ];
  clonedGridData;
  state: State = {
    skip: 0
  };
  pageable = true;
  pageSize = 10;
  skip = 0;
  filter: FilterDescriptor;
  newAttestationSpouse: DependentComposite;

  constructor(
    private route: ActivatedRoute,
    private spinnerService: SpinnerOverlayService,
    private attestationService: AttestationService,
    private subscriberService: SubscriberService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.systemUser = data.user;
      this.currentSubscriber = data.subscriber;

      const spouses: Member[] = filter(this.currentSubscriber.members, (m: Member) => {
        return !m.isSubscriberInd && m.relationshipToSubscriber && m.relationshipToSubscriber.relationshipType && m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S';
      });
      this.spousalAttestations = flatMap(spouses, (s: Member) => {return filter(s.attestations, (a: Attestation) => a.attestationType.attestationTypeCode === 'SS')});
      
      this.lookups.responses = data.lookups.response;
      this.lookups.attestationTypes = data.lookups.attestationType;
      this.lookups.questions = filter(data.lookups.questions, (q: Question) => q.questionName === 'Spouse or Domestic Partner');
      this.lookups.planTypes = data.lookups.planTypes;

    }, (err) => {
      console.log(err);
    });
  }
  
  async removeRecord(rec: Attestation, index: number): Promise<void> {
    this.spousalAttestations.splice(index, 1);
    if (rec.attestationId) {
      try {
        this.spinnerService.show();
        await lastValueFrom(this.attestationService.removeSpousalAction(rec));
        this.spinnerService.hide();
        this.coreService.popMessage('Your attestation record has been successfully deleted.', 'success', 2000);
      } catch (e) {
        this.spinnerService.hide();
        this.coreService.popMessage('There was a problem deleting the attestation record', 'error', 2000);
      }
    } 
    this.coreService.checkRefetchCurrentSubscriber(this.currentSubscriber.memberId);
    this.reSetDataForGrid();
  }

  async saveChanges() {
    try {
      const newAttestation = find(this.spousalAttestations, (a: Attestation) => !a.attestationId);
      if(newAttestation) {
        //update attestations w/o effective end date
        this.spousalAttestations.forEach((a: Attestation) => {
          if(a.attestationId && !a.effectiveEndDate) {
            a.effectiveEndDate = dayjs(newAttestation.effectiveStartDate).subtract(1, 'day').toDate();
          }
        })
      }

      //check if attestation start date is valid
      var attestationEffectiveStartDatesValid = true;
      for (var a of this.spousalAttestations)  {
        const checkAttestationEffectiveStartDate = await lastValueFrom(this.attestationService.checkAttestationStart(a, this.currentSubscriber.memberId));
        if (!checkAttestationEffectiveStartDate) {
          attestationEffectiveStartDatesValid = false;
        }
      }

      if (!attestationEffectiveStartDatesValid) {
        this.coreService.popMessage('Attestation start dates must be the first day of the month or the first working day of the month.', 'error', 5000);
        return;
      }

      this.spinnerService.show();
      for (var a of this.spousalAttestations) {
        if (a.attestationId) {
          await lastValueFrom(this.attestationService.updateSpousalActionNoEP(a));
        } else {
          // Need to find spouse for this timeframe 
          const spouses: Member[] = filter(this.currentSubscriber.members, (m: Member) => {
            return !m.isSubscriberInd && m.relationshipToSubscriber && m.relationshipToSubscriber.relationshipType && m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S';
          });
          const spouse: Member = find(spouses, (s: Member) => { 
            return dayjs(s.relationshipToSubscriber.effectiveStartDate).isSameOrBefore(a.effectiveStartDate, 'day') &&
            (!s.relationshipToSubscriber.effectiveEndDate || dayjs(s.relationshipToSubscriber.effectiveEndDate).isSameOrAfter(a.effectiveEndDate))
          })
          if (spouse) {
            a.memberId = spouse.memberId;
            const newAttestation = await lastValueFrom(this.attestationService.createSpousalActionNoEP(a))
            a.attestationId = newAttestation.attestationId;
          } else {
            this.coreService.popMessage('This subscriber does not have a spouse in the system for the time selected.', 'error', 5000);
            throw Error;
          }
        }
      };
      this.coreService.checkRefetchCurrentSubscriber(this.currentSubscriber.memberId);
      this.spinnerService.hide();
      this.coreService.popMessage('Your attestation information has been saved.', 'success', 2000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('There was a problem updating the attestation information.', 'error', 2000);
    }
  }

  public addHandler({ sender }): void {
      const newAttestation = new Attestation();
      newAttestation.attestationTypeId = find(this.lookups.attestationTypes, (at) => startsWith(at.attestationTypeName, 'Spousal')).attestationTypeId;
      newAttestation.answers = [];
      this.spousalAttestations.push(newAttestation);
      this.newAttestationSpouse = new DependentComposite();
      this.reSetDataForGrid();
  }

  reSetDataForGrid(): void {
    this.clonedGridData = cloneDeep(this.spousalAttestations);
  }

  private loadItems(): void {
    this.clonedGridData = {
      data: this.spousalAttestations.slice(this.skip, this.skip + this.pageSize),
      total: this.spousalAttestations.length
    };
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.clonedGridData = process(this.spousalAttestations, state);
  }


}
