// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import DependentComposite from 'src/app/models/dependentComposite';
import DependentCoverageElection from 'src/app/models/dependentCoverageElection';


@Injectable({
  providedIn: 'root'
})

export class DependentService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createDependent(dependent: DependentComposite, enrollmentPeriodId: string): Observable<DependentComposite> {
    return this.http
      .post<DependentComposite>(`${env.apiUrl}/Member/${dependent.subscriberMemberId}/dependent/${enrollmentPeriodId}`, dependent)
      .pipe(map(x => this.commonService.createObject(DependentComposite, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createDependentForSOE(dependent: DependentComposite, enrollmentPeriodId: string, specialOpenEnrollmentId: string): Observable<DependentComposite> {
    return this.http
      .post<DependentComposite>(`${env.apiUrl}/Member/${dependent.subscriberMemberId}/dependent/${enrollmentPeriodId}/soe/${specialOpenEnrollmentId}`, dependent)
      .pipe(map(x => this.commonService.createObject(DependentComposite, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateDemographics(dependent: DependentComposite, enrollmentPeriodId?: string): Observable<DependentComposite> {
    return this.http
      .put<DependentComposite>(`${env.apiUrl}/Member/${dependent.subscriberMemberId}/dependent/${dependent.memberId}`, dependent)
      .pipe(map(x => this.commonService.createObject(DependentComposite, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateDemographicsForSOE(dependent: DependentComposite, enrollmentPeriodId?: string, specialOpenEnrollmentId?: string): Observable<DependentComposite> {
    return this.http
      .put<DependentComposite>(`${env.apiUrl}/Member/${dependent.subscriberMemberId}/dependent/${dependent.memberId}/soe/${specialOpenEnrollmentId}`, dependent)
      .pipe(map(x => this.commonService.createObject(DependentComposite, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateEnrollments(dependent: DependentCoverageElection, enrollmentPeriodId: string): Observable<DependentComposite> {
    return this.http
      .put<DependentCoverageElection>(`${env.apiUrl}/member/${dependent.subscriberMemberId}/enrollment/${enrollmentPeriodId}/dependent/${dependent.memberId}`, dependent)
      .pipe(map(x => this.commonService.createObject(DependentCoverageElection, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateEnrollmentsForSOE(dependent: DependentCoverageElection, enrollmentPeriodId: string, specialOpenEnrollmentId: string): Observable<DependentComposite> {
    return this.http
      .put<DependentCoverageElection>(`${env.apiUrl}/member/${dependent.subscriberMemberId}/enrollment/${enrollmentPeriodId}/dependent/${dependent.memberId}/soe/${specialOpenEnrollmentId}`, dependent)
      .pipe(map(x => this.commonService.createObject(DependentCoverageElection, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  removeDependentForDeathOrDivorce(organizationId: string, dependent: DependentComposite, specialOpenEnrollmentId: string, terminationReasonId: string): Observable<void> {
    return this.http
      .delete(`${env.apiUrl}/subscriber/${organizationId}/${dependent.subscriberMemberId}/${specialOpenEnrollmentId}/${dependent.memberId}/${terminationReasonId}`)
      .pipe(catchError(this.commonService.handleError));
  }

  updateDeathOrDivorce60DayIndicator(subscriberId: string, organizationId: string, specialOpenEnrollmentId: string, within60Days: boolean): Observable<void> {
    return this.http
      .put(`${env.apiUrl}/subscriber/${subscriberId}/${(organizationId ? organizationId + '/' : '')}${specialOpenEnrollmentId}/60days/${within60Days}`, null)
      .pipe(catchError(this.commonService.handleError));
  }
}
