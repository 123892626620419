<div *ngIf="!isSOE && !isSelfPay" fxLayout="column" class="fxGap-large">
    <h2 class="prompt-question">You have successfully {{ dependent?.memberId? 'updated' : 'added'}} {{dependent.firstName}} {{dependent.lastName}}. Do you have additional dependents to add?</h2>
    <yes-no
      name="addAnother"
      style="padding-left: 75px; padding-right: 75px; margin-bottom: 30px;"
      orientation="horizontal"
      [(ngModel)]="addAnother"
      (ngModelChange)="additionalQuestionAnswered.emit($event)"
      ></yes-no>
  </div>
<!-- SOE -->
<div *ngIf="isSOE || isSelfPay" fxLayout="column" class="fxGap-large">
  <h2 *ngIf="!newDependents?.length">No additional dependents added</h2>
  <h2 *ngIf="newDependents?.length">Dependents added on enrollment change form:</h2>
  <div *ngIf="newDependents?.length" class="fxGap-small" fxLayout="column">
    <div  *ngFor="let dep of newDependents" fxLayoutAlign="space-around center" class="card" style="height: 50px; border: 1px solid black;">
      <b>{{dep.lastName}}, {{dep.firstName}}</b>
      <span>{{dep.relationshipTypeName}}</span>
      <button (click)="importDependent(dep)" class="btn btn-secondary">Import this dependent</button>
    </div>
  </div>
  <h3 class="prompt-question">Add a dependent not on electronic form?</h3>
  <yes-no
    name="addAnother"
    style="margin-bottom: 30px; padding: 0 75px;"
    [(ngModel)]="addAnother"
    (ngModelChange)="additionalQuestionAnswered.emit($event)"
    ></yes-no>
</div>
  