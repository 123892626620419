import { GenericGridComponent } from './../../../shared/components/genericGrid/genericGrid.component';
import { DocumentService } from './../../../../services/document.service';
import { OnInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CoreService } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import Organization from 'src/app/models/organization';
import SystemUser from 'src/app/models/user';
import { filter, some, remove, map, upperFirst, startCase } from 'lodash';
import Document from 'src/app/models/document';
import { faFileLines, faPencil, faFileExcel, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { billingSpecification } from './billing.specification';
import { saveAs } from '@progress/kendo-file-saver';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.DocumentType)
@Component({
    selector: 'billing-file',
    templateUrl: 'billingFile.component.html',
    styleUrls: []
  })

export class BillingFileComponent implements OnInit, OnDestroy {
  @ViewChild('billingGrid') public billingGrid: GenericGridComponent;
  systemUser: SystemUser;
  isHCA = false;
  currentOrganization: Organization;
  currentDocContent;
  orgSubscription: any;
  routeSubscription: any;
  documents: Document[] = [];
  selectedDocument: Document = new Document();
  billingFileDocTypeId = '';
  gridColumns: { [k: string]: string | {} }[] = [];
  exportFileName: string;
  gridData: any[];
  gridSkip = 0;
  icons = {
    faFileLines,
    faPencil,
    faFileExcel,
    faFileCsv
  };
  billingFileLastUploadDate: Date;

  constructor(
    private route: ActivatedRoute,
    private spinnerService: SpinnerOverlayService,
    private coreService: CoreService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
      this.currentOrganization = this.coreService.getOrganizationValue();
      this.isHCA = some(this.systemUser.userOrganizationRoles, r => r.userRoleName === 'HCA');
      this.billingFileDocTypeId = data.lookups.documentType.filter(o => o.documentTypeCode === 'BF')[0].documentTypeId;

      if (this.currentOrganization) {
        this.getBillingFileList();
      }

      if (!this.isHCA) {
        // add subscripbion for when the selected org changes for non-admins who have access to multiple organizations
        this.orgSubscription = this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s) => {
          if (s && (!this.currentOrganization || this.currentOrganization.organizationId !== s.organizationId))
          {
            this.currentOrganization = s;
            if (this.currentOrganization && !this.isHCA) {
              this.getBillingFileList();
            }
          }
        });
      }

      this.gridColumns = map(billingSpecification, spec => ({
        field: spec.name,
        title: upperFirst(startCase(spec.name)),
        format: (spec.gridFormat ? spec.gridFormt : (spec.format || (spec.name.includes('Date') ? { date: 'short' } : 'text')))
      }));
    });
  }

  ngOnDestroy(): void {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getBillingFileList(): void {
    this.spinnerService.show();
    this.documentService.getOrganizationDocuments(this.currentOrganization.organizationId, this.billingFileDocTypeId)
      .pipe(untilDestroyed(this))
      .subscribe(docs => {
        this.documents = docs;
        if (this.documents.length > 0) {
          this.selectedDocument = this.documents[0];
          this.exportFileName = this.selectedDocument.documentName.replace('.txt', '');
          this.getBillingFileData();
        }
        else {
          this.selectedDocument = null;
          this.spinnerService.hide();
        }
      });
  }

  getBillingFileData(): void {
    this.spinnerService.show();
    this.documentService.viewOrganizationDocument(this.currentOrganization.organizationId, this.selectedDocument.documentId)
      .pipe(untilDestroyed(this))
      .subscribe(docContent => {
      this.currentDocContent = docContent;
      const fileReader = new FileReader();
      let documentText;
      const parsedBillingfile = [];
      fileReader.onloadend = () => {
        documentText = fileReader.result;

        const lines = documentText.split('\n');
        for (let r = 0, row; row = lines[r]; r++) {
          const obj = {};

          for (let c = 0, col; col = billingSpecification[c]; c++) {
            let value = row.substring(col.start - 1, col.start + col.width - 1).trim();

            if (col.type === 'number') {
              value = parseInt(value);
            }

            obj[col.name] = value;
          }

          parsedBillingfile.push(obj);
        }
        const exportValues = [];

        for (let i = 0, row; row = parsedBillingfile[i]; i++) {
          const exportRow = {};
          exportValues.push(exportRow);

          for (let c = 0, colDef; colDef = billingSpecification[c]; c++) {
            if (colDef.transform) {
              row[colDef.name] = colDef.transform(row[colDef.name]);
            }

            exportRow[colDef.name] = row[colDef.name];

            if (colDef.format) {
              row[colDef.name] = colDef.format(row[colDef.name]);
            }
          }
        }
        if (this.billingGrid?.state.skip > 0) {
          // reset paging for the grid
          this.billingGrid.state.skip = 0;
        }
        this.gridData = parsedBillingfile;
      };

      // Start reading the blob as text.
      fileReader.readAsText(docContent);
      this.spinnerService.hide();
    });
  }

  documentChange(): void {
    this.getBillingFileData();
    this.exportFileName = this.selectedDocument.documentName.replace('.txt', '');
  }

  downloadFlatFile(): void {
    saveAs(this.currentDocContent, this.selectedDocument.documentName);
  }
}
