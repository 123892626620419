import { catchError, map } from 'rxjs/operators';

// ng
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { NotificationService, NotificationSettings, NotificationRef } from '@progress/kendo-angular-notification';

// ext
import { throwError, Observable } from 'rxjs';
import { env } from '../../env/development';
import * as dayjs from 'dayjs';
import { NotificationComponent } from '../components/notification/notification.component';
import { replace } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    @Inject('constructorObject') @Optional() private constructorObject: any,
    @Inject('baseEndpoint') @Optional() private baseEndpoint: string
    ) {}

  public handleErrorEx(sentObj: any): (error: HttpErrorResponse | Error | TypeError, sentObj: any) => Observable<any> {
    return (error: HttpErrorResponse | Error | TypeError): Observable<any> => {
      return this.handleError(error, sentObj);
    };
  }

  private showError(message: string): void {
    // Unfortunately we cannot just bring in the core.service and use the showError there, as it cause a circular reference,
    // so this is copied from that ......
    const notification: NotificationSettings = {
      content: NotificationComponent,
      animation: { type: 'slide', duration: 300 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: { style: 'error', icon: true },
    };
    if (2000) {
      notification.hideAfter = 2000;
    } else {
      notification.closable = true;
    }
    const notificationRef: NotificationRef = this.notificationService.show(notification);
    if (notificationRef) {
      notificationRef.content.instance.message = message;
    }
  }

  public handleError(error: HttpErrorResponse | Error | TypeError, sentObj?: any): Observable<any> {
    if (error instanceof HttpErrorResponse && error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else if (error instanceof HttpErrorResponse) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
      if (sentObj && error.error.errors) {
        for (const k in error.error.errors) {
          let val = sentObj[k];
          if (val) {
            if (typeof val === 'object' && val.getDate) {
              val = dayjs(val).format('MM/DD/YYYY');
            }

            const warning = `The server had an issue processing a value sent to it: ${k} = ${val}`;
            this.showError(warning);

            // const e = new Error(warning);
            // e['show'] = true;
            // const newError = throwError(() => e);
            // return newError;
          }

          return throwError(() => null);
        }
      }
    } else {
      console.error(`some non-http error occured:`, error);
    }
    return throwError(error);
  }

  public createObject<T>(ConstructorObject: { new (...args: any[]): T }, responseData: any): T {
    const newObject = new ConstructorObject(responseData) as T;
    return newObject;
  }

  public createObjects<T>(ConstructorObject: { new (...args: any[]): T }, responseData: any): T[] {
    return responseData.map(obj => new ConstructorObject(obj));
  }

  public getAll<T>(): Observable<T[]> {
    return this.http.get<T[]>(`${env.apiUrl}/${this.baseEndpoint}`)
    .pipe(map(x => this.createObjects(this.constructorObject, x)))
    .pipe(catchError(this.handleError));
  }

  public get<T>(id: string): Observable<T[]> {
    return this.http.get<T[]>(`${env.apiUrl}/${this.baseEndpoint}/${id}`)
    .pipe(map(x => this.createObjects(this.constructorObject, x)))
    .pipe(catchError(this.handleError));
  }

  public put<T>(id: string, data: T): Observable<T[]> {
    return this.http.put<T[]>(`${env.apiUrl}/${this.baseEndpoint}/${id}`, data)
    .pipe(map(x => this.createObjects(this.constructorObject, x)))
    .pipe(catchError(this.handleError));
  }

  public post<T>(data: T): Observable<T[]> {
    return this.http.get<T[]>(`${env.apiUrl}/${this.baseEndpoint}`, data)
    .pipe(map(x => this.createObjects(this.constructorObject, x)))
    .pipe(catchError(this.handleError));
  }

  public delete<T>(id: string): Observable<T[]> {
    return this.http.get<T[]>(`${env.apiUrl}/${this.baseEndpoint}/${id}`)
    .pipe(map(x => this.createObjects(this.constructorObject, x)))
    .pipe(catchError(this.handleError));
  }
  
  formatMemberFullNameGridQuery(nameProperty: string, gridQuery: string) {
    //sort replacement
    var filterNameProperty = nameProperty.substring(0,1).toUpperCase() + nameProperty.substring(1,nameProperty.length);
    gridQuery = gridQuery.replace(nameProperty + ".fullName-asc",nameProperty + ".lastName-asc~" + nameProperty + ".firstName-asc");
    gridQuery = gridQuery.replace(nameProperty + ".fullName-desc",nameProperty + ".lastName-desc~" + nameProperty + ".firstName-desc");

    //full name
    const nameFilterStart = gridQuery.indexOf(filterNameProperty + '.FullName');
    var replaceExp = new RegExp(filterNameProperty + ".FullName", 'g')
    if (nameFilterStart>0) {
      const nameMultiFilterStart = gridQuery.indexOf('(' + filterNameProperty + '.FullName');
      var nameFilter = "";
      if (nameMultiFilterStart>0) {
        const nameFilterEnd = gridQuery.indexOf(")",nameMultiFilterStart);
        nameFilter = gridQuery.substring(nameMultiFilterStart,nameFilterEnd+1);
        if (nameFilter.indexOf('~and~')>0) {
          var nameFilters = nameFilter.replace("(","").replace(")","").split('~and~');
          var combinedNameFilter = "";
          for (var i=0;i<nameFilters.length;i++) {
            const firstNameFilter = replace(nameFilters[i], replaceExp, nameProperty + ".firstName");
            const lastNameFilter = replace(nameFilters[i], replaceExp, nameProperty + ".lastName");
            combinedNameFilter += "(" + firstNameFilter + '~or~' + lastNameFilter + ")";
            combinedNameFilter += i+1 == nameFilters.length ? "" : "~and~";
          }
          gridQuery = replace(gridQuery, nameFilter, combinedNameFilter);
        }
        else {
          const firstNameFilter = replace(nameFilter, replaceExp, nameProperty + ".firstName");
          const lastNameFilter = replace(nameFilter, replaceExp, nameProperty + ".lastName");
          const combinedNameFilter = "(" + firstNameFilter + '~or~' + lastNameFilter + ")";
          gridQuery = replace(gridQuery, nameFilter, combinedNameFilter);
        }
      }
      else {
        //single query
        const nameFilterFirst = gridQuery.indexOf("'",nameFilterStart);
        const nameFilterEnd = gridQuery.indexOf("'",nameFilterFirst+1);
        nameFilter = gridQuery.substring(nameFilterStart,nameFilterEnd+1);
        const firstNameFilter = replace(nameFilter, replaceExp, nameProperty + ".firstName");
        const lastNameFilter = replace(nameFilter, replaceExp, nameProperty + ".lastName");
        const combinedNameFilter = "(" + firstNameFilter + '~or~' + lastNameFilter + ")";
        gridQuery = replace(gridQuery, nameFilter, combinedNameFilter);
      }
    }
    return gridQuery;
  }
}
