<div class="card">
  <div class="card-header" fxLayoutAlign="center">
    <h4 *ngIf="!subscriberTransferRec.isEligibileForRetireeTransfer">
      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px;" [icon]="icons.faExclamationTriangle"
        size="2x"></fa-icon>
      <span *ngIf="subscriberTransferRec?.organization?.organizationId !== organization?.organizationId" role="alert">{{(noFlags || isHCA) ? 'Previous subscriber' : 'Transfer'}} record found</span>
      <span *ngIf="canBeReenrolledWithoutBreak(organization, subscriberTransferRec)">
        Record found that can be re-enrolled without a break in coverage
      </span>
      <span *ngIf="canBeReenrolledWithBreak(organization, subscriberTransferRec)">
        Record found that can be re-enrolled
      </span>
    </h4>
    <h4 *ngIf="subscriberTransferRec.isEligibileForRetireeTransfer">
      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px;" [icon]="icons.faExclamationTriangle"
        size="2x"></fa-icon>
      <span role="alert">Record found with retiree coverage</span>
    </h4>
  </div>
  <div class="card-body">
    <b *ngIf="subscriberTransferRec && organization?.benefitSetupId === medicalOnlyBenefitSetupId && !noFlags && !subscriberTransferRec.isEligibileForRetireeTransfer">The subscriber is enrolled in benefits that are not part of the benefit package offered by this organization. The subscriber
      and any applicable dependents will only remain enrolled in medical coverage after this transfer. Do you want to claim this record?
    </b>
    <span *ngIf="subscriberTransferRec.isEligibileForRetireeTransfer">
      This subscriber currently has retiree coverage. Adding this subscriber into PEBB eligible employee coverage will automatically defer their retiree coverage. 
      The subscriber will be exempt from the deferral form requirement
    </span>
    <div role="alert" style="padding:15px" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
          {{subscriberTransferRec.lastName}}, {{subscriberTransferRec.firstName}}, {{subscriberTransferRec.socialSecurityNumber}}
        </div>
      </div>
    </div>
    <div style="padding-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button class="btn btn-primary" (click)="pushClaimSubscriber()">
        <span class="k-icon k-i-arrow-60-right"></span> Claim
      </button>
      <button (click)="pushCancelAdd()"class="btn btn-light">
        <span class="k-icon k-i-x"></span> Cancel
      </button>
    </div>
  </div>
</div>