import { DependentReviewAllComponent } from './components/reviewAll/dep.reviewAll.component';
import { DependentWizardComponent } from './components/wizard/dep.wizard.component';
import { DependentDemoComponent } from './components/demo/dep.demo.component';
import { DependentReviewComponent } from './components/review/dep.review.component';
import { DependentCoverageComponent } from './components/coverage/dep.coverage.component';
import { DependentSpousalComponent } from './components/spousal/dep.spousal.component';
import { DependentTobaccoComponent } from './components/tobacco/dep.tobacco.component';
import { DependentAdditionalComponent } from './components/addAdditional/dep.additional.component';
// ng
import { FlexLayoutModule } from '@angular/flex-layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// ext
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule, RadioButtonModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// local
import { DependentsComponent } from './dependents.component';
import { DependentInitialComponent } from './components/initial/dep.initial.component';

@NgModule({
  declarations: [
    DependentsComponent,
    DependentInitialComponent,
    DependentDemoComponent,
    DependentCoverageComponent,
    DependentTobaccoComponent,
    DependentSpousalComponent,
    DependentReviewComponent,
    DependentWizardComponent,
    DependentAdditionalComponent,
    DependentReviewAllComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    RouterModule,
    NgbModule,
    LayoutModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    DialogsModule,
    ButtonsModule,
    GridModule,
    InputsModule,
    LabelModule,
    NotificationModule,
    PDFExportModule,
    ProgressBarModule,
    UploadModule,
    OverlayModule,
    FontAwesomeModule,
    RadioButtonModule
  ],
  exports: [DependentSpousalComponent],
  providers: [],
  bootstrap: [],
})
export class DependentsModule {}
