import SecurityQuestionAnswer from './securityQuestionAnswer';

export default class Claim {
    lastName: string;
    dateOfBirth: string;
    lastFourOfSocial: string;
    securityQuestionResponses?: SecurityQuestionAnswer[];
    questionAnswers?: {};
    memberId?: string;
    memberIds?: string[];
    constructor(claim?) {
      if (claim) {
        this.lastName = claim.lastName;
        this.dateOfBirth = claim.dateOfBirth;
        this.lastFourOfSocial = claim.lastFourOfSocial;
        this.securityQuestionResponses = claim.securityQuestionResponses;
        if (claim.memberId) {
          this.memberId = claim.memberId;
        }
      }
    }

  }
