import { Component, ViewEncapsulation, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { cloneDeep, sortBy, maxBy, filter, some, map } from 'lodash';
import MemberSummary from 'src/app/models/memberSummary';
import OpenEnrollment from 'src/app/models/openEnrollment';
import { SubscriberService } from 'src/app/services/subscriber.service';
import Subscriber from 'src/app/models/subscriber';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import { PDFComponent } from './../../../shared/components/pdf/pdf.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService} from 'src/app/services/core.service';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
import SubscriberContact from 'src/app/models/subscriberContact';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { lastValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import PlanType from 'src/app/models/planType';
import { env } from 'src/env/development';
import { AdminService } from 'src/app/services/admin.service';

@UntilDestroy()
@Component({
  selector: 'subscriber-summary',
  templateUrl: 'subscriber.summary.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class SubscriberSummaryComponent implements OnInit {
  @ViewChild('pdf') coveragePDF: PDFComponent;
  subscriberModel: Subscriber;
  subscriber: SubscriberEffectiveSummary;
  subscriberContact = new SubscriberContact();
  openEnrollment: OpenEnrollment;
  electablePlanTypesForCurrentPeriod: [];
  inAdminState = false;
  isFullBenefits = false;
  medicareDEnabled = false;
  documentTitleText = 'STATEMENT OF INSURANCE';
  documentHeaderText =
    `THIS STATEMENT SUMMARIZES YOUR INSURANCE COVERAGES WITH THE HEALTH CARE AUTHORITY. IF THIS STATEMENT ` +
    `DISAGREES WITH YOUR RECORDS, PLEASE CONTACT YOUR EMPLOYER'S PERSONNEL, PAYROLL, OR BENEFITS OFFICE (IF YOU ` +
    `ARE AN EMPLOYEE) OR PEBB BENEFITS SERVICES AT 1-800-200-1004 (IF YOU ARE A RETIREE, COBRA, OR LEAVE ` +
    `WITHOUT PAY SUBSCRIBER).`;
  documentFooterText =
    `THIS STATEMENT IS NOT A GUARANTEE OF INSURANCE. IT IS INTENEDED TO BE A STATEMENT OF RECORD OF YOUR ` +
    `ENROLLMENT. THE INSURANCE COVERAGE IS GOVERNED BY THE INSURANCE CONTRACT OR CERTIFICATE OF COVERAGE.`;
  enrolledHeader = 'ENROLLMENT INFORMATION';
  
  constructor(
    private subscriberService: SubscriberService,
    private route: ActivatedRoute,
    private router: Router,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private adminService: AdminService
    ) {}

  async ngOnInit(): Promise<void> {
    this.medicareDEnabled = await lastValueFrom(this.adminService.getFeatureFlag('FEATURE_ENABLE_MEDICARE_D'));

    if (this.router.url.includes('admin')) {
      this.inAdminState = true;
    }

    const data = this.route.snapshot.data;

    this.spinnerService.show();
    this.subscriberService.getSubscriberById(data.subscriber.memberId).pipe(untilDestroyed(this))
      .subscribe(subscriber =>
        {
          this.subscriberModel = subscriber;

          this.subscriber = new SubscriberEffectiveSummary(this.subscriberModel, null, true, undefined, undefined, false, this.medicareDEnabled);
          this.subscriber.memberSummary =
            this.subscriber.memberSummary.filter(ms => ms.dentalEffectiveDates || ms.medicalEffectiveDates);
          this.subscriber.memberSummary = sortBy(this.subscriber.memberSummary, (m: MemberSummary) => m.sortOrder);
          this.subscriber.memberSummary = this.subscriber.memberSummary.filter((ms) => ms.isVerified);
          this.subscriberContact.memberId = subscriber.memberId;
          this.subscriberContact.emailNotificationInd = subscriber.emailNotificationInd;
          this.subscriberContact.emailAddress = subscriber.emailAddress;
          this.subscriberContact.homePhoneNumber = subscriber.homePhoneNumber;
          this.subscriberContact.workPhoneNumber = subscriber.workPhoneNumber;
          this.subscriberContact.cellPhoneNumber = subscriber.cellPhoneNumber;
          this.isFullBenefits = this.subscriberModel.organization?.benefitSetup?.benefitSetupCode === 'FB';

          //get electable coverage types for the current period (medical, dental and vision only)
          var planTypeCodesToInclude = [env.medicalPlanTypeCode, env.dentalPlanTypeCode, env.visionPlanTypeCode];
          this.electablePlanTypesForCurrentPeriod = map(filter(this.subscriber.currentlyEffectiveEnrollmentPeriod.electablePlanTypes,
            (o:PlanType)=> some(planTypeCodesToInclude, (pt) => pt == o.planTypeCode)),'planTypeName');
          this.spinnerService.hide();
        });
  }

  exportStatementOfInsurance(): void {
    this.coveragePDF.pdfElement.saveAs('StatementOfInsurance.pdf');
  }

  async updateEmailIndicator(): Promise<void> {
    this.spinnerService.show();
    try {
      this.subscriberContact = await lastValueFrom(this.subscriberService.updateSubscriberContact(this.subscriberContact));
      this.subscriberModel.emailNotificationInd = this.subscriberContact.emailNotificationInd;
      this.subscriber.emailNotificationsInd = this.subscriberContact.emailNotificationInd;
      this.coreService.setSubscriber(this.subscriberModel);
      this.spinnerService.hide();
      this.coreService.popMessage('You have successfully updated your contact information!', 'success', 5000);
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong updating your contact information! Please try again', 'error', 5000);
      console.log(err);
    }
  }

  navToCoverage(e): void {
     e.preventDefault();
  }
}
