import Ethnicity from 'src/app/models/ethnicity';
import { map, filter, cloneDeep, some } from 'lodash';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import SpecialOpenEnrollment from 'src/app/models/selfPay';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import SelfPayFormSubscriber from 'src/app/models/selfPayFormSubscriber';
import SelfPayPaymentType from 'src/app/models/selfPayPaymentType';
import GenderIdentity from 'src/app/models/genderIdentity';
import BirthSex from 'src/app/models/birthSex';
import SelfPay from 'src/app/models/selfPay';
import Plan from 'src/app/models/plan';
import { env } from 'src/env/development';
import * as dayjs from 'dayjs';

@Component({
  selector: 'self-pay-pdf',
  templateUrl: 'selfPay.pdf.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayPDFComponent implements OnChanges {
  @Input() selfPay: SelfPay;
  @Input() pebbVisionDate: Date;
  @Input() selfPayPaymentTypes: SelfPayPaymentType[];
  @Input() birthSexes: BirthSex[];
  @Input() genderIdentities: GenderIdentity[];
  @Input() availablePlans: Plan[];
  @Input() ethnicities: Ethnicity[];
  selectedMedicalPlan: Plan;
  selectedDentalPlan: Plan;
  selectedVisionPlan: Plan;
  selectedMedicalPlans: string;
  isPebbLwopVision: boolean = false;

  existingDependents: SelfPayFormDependent[];
  newDependents: SelfPayFormDependent[];
  birthSex: BirthSex;
  genderIdentity: GenderIdentity;
  displayRaceEthnicity = false;
  isOE: boolean;
  ngOnChanges() {
    this.isOE = this.selfPay?.selfPayType?.selfPayTypeCode && ['OE', 'OER', 'OEC', 'OEU'].includes(this.selfPay?.selfPayType?.selfPayTypeCode);
    this.isPebbLwopVision = this.selfPay?.agency?.agencyCode === 'PEBB' && (((this.selfPay?.formJson?.selfPayReason === 'ALWOP' || this.selfPay?.selfPayType.selfPayTypeCode === 'SOEU') && this.selfPay?.coverageEffectiveDate ? dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(this.pebbVisionDate) : dayjs(new Date()).isSameOrAfter(this.pebbVisionDate)) ||  this.selfPay?.selfPayType.selfPayTypeCode === "OEU");
    this.existingDependents = filter(this.selfPay.formJson.dependents, (d: SelfPayFormDependent) => d.memberId);
    this.newDependents = filter(this.selfPay.formJson.dependents, (d: SelfPayFormDependent) => !d.memberId);
    this.selectedMedicalPlan = cloneDeep(this.availablePlans.find(o=>o.planId===this.selfPay.formJson.medicalPlan.planId));
    this.selectedDentalPlan = cloneDeep(this.availablePlans.find(o=>o.planId===this.selfPay.formJson.dentalPlan.planId));
    this.selectedVisionPlan = cloneDeep(this.availablePlans.find(o=>o.planId===this.selfPay.formJson.visionPlan.planId));
    this.selectedMedicalPlans = map(this.selfPay.formJson.medicalPlans,'planName').join(" and ");
    this.setSubscriberSexAndGender();
    this.displayRaceEthnicity = this.subscriberOrDependentsAreMedicareEnrolled() && some(this.selfPay.formJson.medicalPlans, (p: Plan) => env.medicareAdvantagePlanCodes.includes(p.planCode)) && (this.selfPay?.selfPayType.selfPayTypeCode.includes('R') || this.selfPay?.selfPayType.selfPayTypeCode.includes('C'));
  }

  setSubscriberSexAndGender() {
    this.birthSex = this.birthSexes.find((r) => r.birthSexId === this.selfPay.formJson.subscriber.birthSexId);
    if (this.selfPay.formJson.subscriber.genderIdentityId) {
      this.genderIdentity = this.genderIdentities.find((r) => r.genderIdentityId === this.selfPay.formJson.subscriber.genderIdentityId);
    }
  }

  getBirthSexName(birthSex: string) {
    return this.birthSexes.find((r) => r.birthSexCode === birthSex)?.birthSexName;
  }

  getGenderIdentityName(genderIdentity: string) {
    if (genderIdentity?.length === 1) {
      return this.genderIdentities.find((r) => r.genderIdentityCode === genderIdentity)?.genderIdentityName;
    } else {
      return this.genderIdentities.find((r) => r.genderIdentityId === genderIdentity)?.genderIdentityName;
    }
  }

  subscriberOrDependentsAreMedicareEnrolled(): boolean {
    return this.selfPay.formJson?.subscriber?.medicarePartAInd || some(this.selfPay.formJson?.dependents, (d: SelfPayFormDependent) => d?.memberMedicare?.medicarePartAEnrolledInd);
  }
}
