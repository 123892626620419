<div class="grid-container">
  <kendo-grid
    #kendoGrid
    [data]="gridData"
    class="k-grid-ignore-click"
    [filterable]="filterable ? 'menu' : false"
    [filter]="state.filter"
    [skip]="state.skip"
    [sortable]="filterable"
    [pageable]="true"
    [sort]="state.sort"
    [pageSize]="state.take"
    (dataStateChange)="dataStateChange($event)"
    [selectable]="{ checkboxOnly: true, mode: 'single' }"
    [kendoGridSelectBy]="'specialOpenEnrollmentId'"
    [selectedKeys]="selectedKeys"
    (selectionChange)="changeSelection($event)"
  >
    <kendo-grid-checkbox-column width="45"> </kendo-grid-checkbox-column>
    <kendo-grid-column
      class="k-grid-ignore-click"
      [style.flex]="col.flex || 1"
      *ngFor="let col of gridColumns"
      field="{{ col.field }}"
      title="{{ col.title }}"
      width="{{ col.width }}"
      [sortable]="col.sortable==false ? false : true"
      [format]="col.format"
      [filter]="col.filter ? col.filter : null"
      [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
    >
      <ng-template kendoGridFilterMenuTemplate
        let-column="col"
        let-filter="filter"
        let-filterService="filterService"
        *ngIf="col.field.endsWith('Date')"
        >
        <date-picker
          name="dateFilter"
          [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
          (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
          [label]="col.title">
        </date-picker>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<!-- PDF -->
<!-- <pdf #soePDF *ngIf="soeForPdf?.formJson" [documentTitleText]="' PEBB Employee Enrollment/Change Request'" [documentHeaderText]="''">
  <soe-pdf #pdfContent [soe]="soeForPdf"></soe-pdf>
</pdf> -->
