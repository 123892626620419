<div fxLayout="row" fxLayoutAlign="center center">
  <hca-prompt
    fxFlex="60"
    [confirmIndex]="3"
    [icon]="icons.faLungs"
    (previous)="updateStep($event)"
    (next)="updateStep($event)"
    (putPostObject)="saveAttestation()"
    [milestone]="attestationMilestone"
    [confirmButtonText]="confirmButtonText"
    [hideBackToDash]="isSOE"
  >
    <div *ngIf="step != 4 && step != 2" fxLayoutAlign="center center">
      <h2>Tobacco use premium surcharge</h2>
    </div>
    <div *ngIf="step == 4" fxLayoutAlign="center center">
      <h2>Thank you</h2>
    </div>
    <div *ngIf="step == 2" fxLayoutAlign="center center">
        <h2>Legal notice</h2>
    </div>
    <div *ngIf="step == 0">
      <div>
        <div fxLayout="column" *ngIf="subscriber.isSebb">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <p>
              <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/surcharges" target="_blank">Learn about this surcharge</a>. 
              The SEBB Program requires a $25 per account premium surcharge in addition to your monthly medical premium if you or an enrolled dependent (age 13 or older) uses a tobacco product. Tobacco use is defined as any use of tobacco products within the past two months except for religious or ceremonial use.
            <br/>
            Tobacco products means any product made with or derived from tobacco that is intended for human consumption, including any component, part, or accessory of a tobacco product. This includes, but it not limited to, cigars, cigarettes, pipe tobacco, chewing tobacco, snuff, and other tobacco products. Tobacco products do not include e-cigarettes or United States Food and Drug Administration (FDA) approved quit aids, such as, over-the-counter nicotine replacement products recommended by a doctor, and prescription nicotine replacement products. 
            <br/>
            If a provider finds that ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information in the <a href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-rules-and-policies" target="_blank" >{{subscriber?.agency}} Program Administrative Policy 91-1</a>.
            </p>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <p>
              Events that require a change: Subscriber must change your attestation when subscriber or subscriber's enrolled dependents' (age 13 or older) tobacco use status changes.
            </p>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <p>
              Note: Enrolled dependents ages 12 and younger are automatically defaulted to NO. Subscriber does not need to attest when the dependent turns age 13 unless they use, or begin using, tobacco products.
            </p>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between">
            <p><a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/tobacco-use-surcharge" target="_blank">Additional information on surcharges</a></p>
          </div>
        </div>
      </div>
      <div fxLayout="column" *ngIf="!subscriber.isSebb">
        <div fxLayout="row" fxLayoutAlign="space-between">
          <p>
            The PEBB Program requires a $25 per account premium surcharge in addition to your monthly medical premium if you or an enrolled dependent (age 13 or older) uses a tobacco product. Tobacco use is defined as
            any use of tobacco products within the past two months except for religious or ceremonial use.
            <br/>
            <br/>
            Tobacco products means any product made with or derived from tobacco that is intended for human consumption, including any component, part, or accessory of a tobacco product. This includes, but is not limited to, cigars, cigarettes, pipe tobacco, chewing tobacco, snuff, and other tobacco products. Tobacco products do not include e-cigarettes or United States Food and Drug Administration (FDA) approved quit aids, such as, over-the-counter nicotine replacement products recommended by a doctor, and prescription nicotine replacement products.<br/><br/>
            If a provider finds that ending tobacco use or participating in your medical plan's tobacco cessation program will negatively affect your or your dependent's health, see more information in the <a href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-rules-and-policies" target="_blank" >PEBB Program Administrative Policy 91-1</a>.<br/><br/>
            Events that require a change: You must change your attestation when you or your enrolled dependents' (age 13 or older) tobacco use status changes. 

          </p>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <p>
            Note: Enrolled dependents ages 12 and younger are automatically defaulted to No. You do not need to attest when they turn age 13 unless they use, or begin using, tobacco products.
          </p>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <p><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/tobacco-use-surcharge">Additional information on surcharges</a></p>
        </div>
      </div>
    </div>
    <div *ngIf="step == 1">
      <div fxLayout="column" class="fxGap-large">
        <h2 class="prompt-question">Does the tobacco use premium surcharge apply to you?</h2>
        <br/>
        <p>If you check Yes, you will be charged the $25 premium surcharge.</p>
        <br/>
        <yes-no
          name="usesTobacco"
          [orientation]="'vertical-list'"
          [isTobaccoAttestation]="true"
          [isTobaccoAttestationSubscriber]="true"
          [isSebb]="subscriber.isSebb"
          style="padding-left: 75px; padding-right: 75px; margin-bottom: 30px;"
          [(ngModel)]="usesTobacco"
          ></yes-no>
      </div>
    </div>
    <div *ngIf="step == 2">
      <div fxLayout="column" class="fxGap-large">
        <div class="card">
          <div class="card-body">
            <div *ngIf="!subscriber.isSebb">
              <p>By selecting the Next button below:</p>
              <ul>
                  <li>I declare that the information I have provided is true, complete, and correct. If it isn't, or if I do not provide timely, updated information, I will owe surcharges to the PEBB Program.</li>
                  <li>I declare that one (or more) of the attestation events  requires an attestation change to the premium surcharges, and that I am reporting it within the PEBB Program's deadlines.</li>
                  <li>I am replacing all PEBB Premium Surcharge Attestation Change forms, enrollment form attestations, and electronic attestations previously submitted.</li>
                  <li>I understand that changes that result in a premium surcharge will begin the first day of the month following the status change (i.e. the date you or your dependents started using tobacco products). If that day is the first of the month, the change to the surcharge begins on that day. </li>
                  <li>I understand that changes that result in removing the premium surcharge (you or your dependents stopped using tobacco products, enrolled in your PEBB medical plan’s tobacco cessation program, or accessed information and resources at Smokefree Teen) will begin the first day of the month following receipt of the attestation. If that day is the first of the month, the change to the surcharge begins on that day.</li>
              </ul>
              <br/>
              <p>Exception: If you are required to attest to the premium surcharges during the PEBB Program’s annual open enrollment, any changes will become effective January 1, of the following year.</p><br/>
            </div>
            <div *ngIf="subscriber.isSebb">
              <p>By selecting the Next button below:</p>
              <ul>
                <li>I declare that the information I have provided is true, complete, and correct. If it isn't, or if I do not provide timely, updated information, I will owe surcharges to the SEBB Program.</li>
                <li>I declare that one (or more) of the events requires an attestation change to the premium surcharges, and that I'm reporting it within the SEBB Program's deadlines.</li>
                <li>I am replacing all SEBB Premium Surcharge Attestation Change forms, enrollment form attestations, and electronic surcharge attestations previously submitted.</li>
                <li>I understand that changes that result in a premium surcharge will begin the first day of the month after the status change. If that day is the first of the month, the change to the surcharge begins on that day.</li>
                <li>I understand that changes that result in removing the premium surcharge will begin the first day of the month after receipt of the attestation. If that day is the first day of the month, the change to the surcharge begins on that day.</li>
              </ul>
              <br/>
              <p>Exception:  If you are required to attest to the premium surcharges during the SEBB Program’s annual open enrollment, any changes will become effective January 1 of the following year.</p>
              <br/>
              <p>HCA's privacy notice: We will keep your information private as allowed by law. See our 
                <a href="https://www.hca.wa.gov/about-hca/notice-privacy-practices" target="_blank">privacy notice</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="step == 3">
      <div fxLayout="column" class="fxGap-large">
        <p>You {{ usesTobacco || membersUseTobacco ? 'will' : 'will not' }} be charged the $25 tobacco use surcharge in addition to your monthly medical premium.</p>
        <br>
        <p>Select Confirm to continue. Select Previous to change your response.</p>
      </div>
    </div>
  </hca-prompt>
</div>
