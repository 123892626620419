import SelfPayPaymentType from 'src/app/models/selfPayPaymentType';
import SubscriberComposite from 'src/app/models/subscriberComposite';
import { AddressService } from 'src/app/services/address.service';
import { PhoneNumberService } from './../../../../../../services/phonenumber.service';
import { EmploymentService } from 'src/app/services/employment.service';
// ng
import { Component, ViewEncapsulation, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ext
import { NotificationService } from '@progress/kendo-angular-notification';
import { sortBy, filter, find, cloneDeep, isEqual, get, flatMap, some, map, isEmpty } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import ActionType from 'src/app/models/actionType';
import SystemUser from 'src/app/models/user';
import Member from 'src/app/models/member';
import Subscriber from 'src/app/models/subscriber';
import MemberPhoneNumber from 'src/app/models/memberPhoneNumber';
import Employment from 'src/app/models/employment';
import SubscriberTransferSearch from 'src/app/models/subscriberTransferSearch';
import SubscriberTransfer from 'src/app/models/subscriberTransfer';
import MemberType from 'src/app/models/memberType';
import EmployeeRepresentedStatus from 'src/app/models/employeeRepresentedStatus';
import Reason from 'src/app/models/reason';
import Organization from 'src/app/models/organization';
import { MemberService } from 'src/app/services/member.service';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import MemberMemberType from 'src/app/models/memberMemberType';
import { OrganizationService } from 'src/app/services/organization.service';
import BenefitSetup from 'src/app/models/benefitSetup';
import * as dayjs from 'dayjs';
import { AdminService } from 'src/app/services/admin.service';
import SelfPayMember from 'src/app/models/selfPayMember';
import ExternalEnrollment from 'src/app/models/externalEnrollment';
import { ActionService } from 'src/app/services/action.service';
import Action from 'src/app/models/action';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { env } from 'src/env/development';
import TransferSearchResult from 'src/app/models/transferSearchResult';
import MedicareOption from 'src/app/models/medicareOption';

@UntilDestroy()
@Lookups(LookupType.GenderIdentity, LookupType.BenefitSetup, LookupType.MemberType, LookupType.RelationshipType, LookupType.BirthSex,
         LookupType.County, LookupType.Country, LookupType.AddressType, LookupType.PhoneNumberType, LookupType.PreferredContactMethod,
         LookupType.Language, LookupType.Reason, LookupType.ActionType, LookupType.SelfPayPaymentType,
         LookupType.CobraQualifyReason, LookupType.MedicareOption)
@Component({
  selector: 'subscriber-management-add',
  templateUrl: 'subscriberManagement.add.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriberManagementAddComponent implements OnInit {
  systemUser: SystemUser;
  subscriber: SubscriberComposite | SubscriberTransfer;
  lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    benefitSetup: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    phoneNumberTypes: [],
    preferredContactMethods: [],
    languages: [],
    organizations: [],
    eligibilityReasons: [],
    terminationReasons: [],
    reason: [],
    selfPayPaymentTypes: [],
    memberTypes: [],
    enrollmentReasons: [],
    cobraQualifyReasons: [],
    actionTypes: [],
    medicareOptions: []
  };
  openEnrollmentId: string;
  dependentCollapsed = {};
  subscriberForRelationshipTypeId: string;
  inAdminState: boolean;
  transferFoundShow = false;
  transferFoundWithCurrentEnrollmentShow = false;
  displaySubscriberAdd = false;
  transferHasNoFlags = false;
  showSubscriberEnrolledAsDependent = false;
  subscriberTransferRec: SubscriberTransfer;
  currentOrganization: Organization;
  subscriberTransferSearch: SubscriberTransferSearch;
  claimingTermTransfer = false;
  isHCAAdmin = false;
  isHCA = false;
  isHCAWithEdit = false;
  isSelfPayAdd = false;
  displaySelfPayAdd = false;
  selfPaySubscriber: SelfPayMember;
  displayAddSearch = true;
  displayNeedsAdjustment = false;
  medOnlySetupId: string;
  isTransfer = false;
  showWarningMessage = false;
  formSubmitted = false;
  transferMemberResult: TransferSearchResult;

  constructor(
    private route: ActivatedRoute,
    private memberService: MemberService,
    private subscriberService: SubscriberService,
    private coreService: CoreService,
    private organizationService: OrganizationService,
    private router: Router,
    private spinnerService: SpinnerOverlayService,
    private adminService: AdminService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    this.subscriber = new SubscriberComposite();
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      if (this.router.url.includes('admin')) {
        this.inAdminState = true;
      }
      this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
      this.isHCAWithEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe(async (s) => {
        if (s != null) {
          const newOrganization = await lastValueFrom(this.organizationService.getOrganizationById(s.organizationId));
          this.currentOrganization = newOrganization;
        }
        this.isSelfPayAdd = !this.currentOrganization && (this.isHCAWithEdit || this.isHCAAdmin);
        this.displayAddSearch = true;
      });
      this.displayAddSearch = true;
      this.transferFoundShow = false;
      this.transferFoundWithCurrentEnrollmentShow = false;
      this.displaySubscriberAdd = false;
      this.transferHasNoFlags = false;
      this.showSubscriberEnrolledAsDependent = false;

      this.systemUser = data.user;
        // this.openEnrollmentId = data.openEnrollment[0] ? data.openEnrollment[0].openEnrollmentId : null;
      this.lookups.county = sortBy(data.lookups.county, 'countyName');
      this.lookups.reason = data.lookups.reason;
      this.lookups.addressType = data.lookups.addressType;
      this.lookups.country = sortBy(data.lookups.country, 'countryName');
      this.lookups.birthSexes = sortBy(data.lookups.birthSex, 'birthSexName');
      this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA) || this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      this.lookups.genderIdentities = sortBy(data.lookups.genderIdentity, 'genderIdentityName');
      this.lookups.preferredContactMethods = sortBy(data.lookups.preferredContactMethod, 'preferredContactMethodName');
      this.lookups.languages = sortBy(data.lookups.language, 'languageName');
      this.lookups.medicareOptions = data.lookups.medicareOption;

      if (this.isHCA) {
        this.organizationService.getOrganizationsForAllAgencies().pipe(untilDestroyed(this)).subscribe((orgs: Organization[]) => {
          this.lookups.organizations = sortBy(orgs, 'organizationName');
          this.lookups = cloneDeep(this.lookups);
        });
      }

      this.lookups.benefitSetup = data.lookups.benefitSetup;
      this.medOnlySetupId = get(find(this.lookups.benefitSetup, (bs: BenefitSetup) => bs.benefitSetupCode === 'MO'), 'benefitSetupId');
      this.lookups.relationshipQualifyReasons = sortBy(data.lookups.relationshipQualifyReason, 'relationshipQualifyReasonName');
      this.lookups.memberType = sortBy(data.lookups.memberType, 'memberTypeName');
      this.lookups.phoneNumberTypes = sortBy(
        filter(data.lookups.phoneNumberTypes, (p) => p.phoneNumberTypeName !== 'Cell'),
        'phoneNumberTypeName'
      );
      this.lookups.relationshipVerificationStatus = sortBy(data.lookups.relationshipVerificationStatus, 'relationshipVerificationStatusName');
      this.lookups.relationshipTypes = sortBy(
        filter(data.lookups.relationshipTypes, (r) => r.relationshipTypeName !== 'SubscriberFor'),
        'relationshipTypeName'
      );
      this.lookups.eligibilityReasons = sortBy(
        data.lookups.reason.filter(r => r.eligibilityReasonInd),
        'reasonCode'
      );
      this.lookups.reason = data.lookups.reason;
      this.lookups.genderIdentities = data.lookups.genderIdentity;
      this.lookups.birthSexes = data.lookups.birthSex;
      this.lookups.county = sortBy(data.lookups.county, 'countyName');
      this.lookups.country = sortBy(data.lookups.country, 'countryName');
      this.lookups.addressType = data.lookups.addressType;
      this.lookups.memberTypes = sortBy(filter(data.lookups.memberType, 'managedByHCAInd'), 'memberTypeName');
      this.lookups.enrollmentReasons = sortBy(map(filter(data.lookups.selfPayReason, 'enrollmentInd'), 'reason'), 'reasonName');
      this.lookups.cobraQualifyReasons = sortBy(data.lookups.cobraQualifyReason, 'cobraQualifyReasonName');
      this.lookups.relationshipTypes = sortBy(data.lookups.relationshipType, 'relationshipTypeName');
      this.lookups.selfPayPaymentTypes = data.lookups.selfPayPaymentType;
      this.lookups.reason = data.lookups.reason;
      this.lookups.actionTypes = data.lookups.actionType;
      this.lookups.relationshipQualifyReasons = sortBy(data.lookups.relationshipQualifyReason, 'relationshipQualifyReasonName');
      this.displayAddSearch = true;
      const now = new Date();
    });

    // end data resolve
    this.coreService.getOrganization().pipe(untilDestroyed(this)).subscribe(async (organization) => {
      if (organization && organization.organizationId) {
        const newOrganization = await lastValueFrom(this.organizationService.getOrganizationById(organization.organizationId));
        this.currentOrganization = newOrganization;
      }
    });
  }

  async checkForExistingRecord(subscriberTransferSearch: SubscriberTransferSearch): Promise<void> {
    this.subscriberTransferSearch = subscriberTransferSearch;
    try {
      this.transferMemberResult = await lastValueFrom(this.memberService.transferMember(subscriberTransferSearch, this.currentOrganization.organizationId));
      const subscriberTransferRecs: SubscriberTransfer[] =
        !!this.currentOrganization ? this.transferMemberResult.transferSearchResponses : [];
      const existingSubscriber = find(subscriberTransferRecs, (r: SubscriberTransfer) => r.memberId && r.isSubscriberInd);
      this.subscriberTransferRec =
        some(subscriberTransferRecs, (r: SubscriberTransfer) => r.memberId && r.isSubscriberInd) ?
          find(subscriberTransferRecs, (r: SubscriberTransfer) => r.memberId && r.isSubscriberInd) :
          find(subscriberTransferRecs, (r: SubscriberTransfer) => r.memberId && !r.isSubscriberInd);
      const allExternalEnrollments: ExternalEnrollment[] = flatMap(subscriberTransferRecs, (r: SubscriberTransfer) => r.externalEnrollments);
      this.displayAddSearch = false;

      if (this.subscriberTransferRec) {
        // We found a potential match for this SSN in the system (sub or dependent).
        const hasPebbDualEnrollment = some(allExternalEnrollments, (r: ExternalEnrollment) => r.representsExternalEnrollmentAsSubscriberAndForAgency(this.subscriberTransferRec.dateUsedForCalculation, 'PEBB'));

        if (!this.subscriberTransferRec.isSubscriberInd) {
          // This is a dependent match (no potential subscriber match for SSN)
          this.initNewSubscriberComposite();
          this.subscriber.socialSecurityNumber = subscriberTransferSearch.socialSecurityNumber;
          this.subscriber.eligibilityDate = subscriberTransferSearch.eligibilityDate;
          this.displaySubscriberAdd = true;
        } else if (this.subscriberTransferRec.isEligibleForAutomaticTransfer) {
          // This is a potential subscriber match, and the subscriber is
          // eligible for an automatic transfer to the target organization (same agency).

          // Is eligible for an automatic transfer when the previous enrollment ends the day before the
          // new enrollment being requested to add starts.  E.g., based on the incoming eligibility date,
          // coverage would start on 4/1, and previous enrollment ended 3/31, then they are eligible
          // for an automatic transfer.
          const existingSubscriberComp = new SubscriberComposite(this.subscriberTransferRec.subscriber);
          existingSubscriberComp.eligibilityDate = this.subscriberTransferRec?.subscriber?.eligibilityDate;
          this.initNewSubscriberComposite(existingSubscriberComp);
          this.transferFoundShow = true;
          this.isTransfer = true;
        } else if (
              this.subscriberTransferRec.needsToBeAdjustedForTransfer ||
              this.subscriberTransferRec.hasExistingCoverageForThisDate ||
              this.subscriberTransferRec.hasExistingCoverage ||
              this.subscriberTransferRec.isEligibileForRetireeTransfer
          ) {
          // This is a potential subscriber match, and the subscriber is
          // NOT eligible for an automatic transfer to the target organization
          // AND the subscriber either has CURRENT coverage or has FUTURE coverage
          // that is active as of the requested eligibility date, or has an
          // enrollment in a different agency.

          // They cannot be transferred and should be shown messaging indicating
          // how they can resolve the problem (e.g., contact agency where sub is
          // currently enrolled to initiate a transfer).
          this.subscriberTransferRec = this.subscriberTransferRec;
          this.displayNeedsAdjustment = true;
        } else if (this.subscriberTransferRec && (
            (
              !this.subscriberTransferRec.hasExistingCoverageForThisDate &&
              !this.subscriberTransferRec.needsToBeAdjustedForTransfer &&
              !this.subscriberTransferRec.isEligibleForAutomaticTransfer
            ) || 
            !this.subscriberTransferRec.isEligibileForRetireeTransfer
          )) {
          // This is a potential subscriber match, and the subscriber is
          // NOT eligible for an automatic transfer to the target organization
          // AND does not have any enrollments active during the requested eligibility date.
          const existingSubscriberComp = new SubscriberComposite(this.subscriberTransferRec.subscriber);
          existingSubscriberComp.eligibilityDate = subscriberTransferSearch.eligibilityDate;
          existingSubscriberComp.eligibilityReasonId = null;
          //30649
          //existingSubscriberComp.salary = null;
          this.initNewSubscriberComposite(existingSubscriberComp);
          this.transferFoundShow = true;
          this.transferHasNoFlags = true;
          this.isTransfer = false;
        }
      } else {
        // No potential match found for this SSN, okay to add.
        this.initNewSubscriberComposite();
        this.subscriber.socialSecurityNumber = subscriberTransferSearch.socialSecurityNumber;
        this.subscriber.eligibilityDate = subscriberTransferSearch.eligibilityDate;
        this.displaySubscriberAdd = true;
      }
      if (subscriberTransferSearch.sebbIsNewlyEligibleBeforeFirstDayOfSchool) {
        this.subscriber.sebbIsNewlyEligibleBeforeFirstDayOfSchool = subscriberTransferSearch.sebbIsNewlyEligibleBeforeFirstDayOfSchool;
      }
    } catch (err) {
      console.log(err);
      this.coreService.popMessage('An error has occured submitting this subscriber, please review your information and try again', 'error', 2000);
    }
  }

  async searchForExistingMemberForSelfPay(subscriberTransferSearch: SubscriberTransferSearch): Promise<void> {
    try {
      let latestIsTermAsNeverEligible = false;

      const subscriberTransferRecs = map((await lastValueFrom(this.memberService.searchExistingSubscriberRecords(subscriberTransferSearch))), (str) => {
        const transfer = str.subscriber;
        transfer.lossOfEligibilityDate = str.lossOfEligibilityDate;

        latestIsTermAsNeverEligible = str.latestIsTermAsNeverEligible;

        return transfer;
      });

      this.subscriberTransferRec =
        some(subscriberTransferRecs, (r: Subscriber) => r.memberId && r.isSubscriberInd) ?
          find(subscriberTransferRecs, (r: Subscriber) => r.memberId && r.isSubscriberInd) :
          find(subscriberTransferRecs, (r: Subscriber) => r.memberId && !r.isSubscriberInd);

      // elig date === agency eff date here
      const isSubscriberWithActiveEligibility = this.subscriberTransferRec?.isSubscriberInd && !this.subscriberTransferRec?.lossOfEligibilityDate;
      const lostEligibilityDateInAfterEnteredEligDate = this.subscriberTransferRec?.lossOfEligibilityDate && dayjs(this.subscriberTransferRec?.lossOfEligibilityDate).isAfter(subscriberTransferSearch.eligibilityDate);
      const lostEligibilityDateIsBeforeEnteredEligDate = this.subscriberTransferRec?.lossOfEligibilityDate && !(this.subscriberTransferRec?.lossOfEligibilityDate < this.subscriberTransferRec.eligibilityDate);
      if (isSubscriberWithActiveEligibility || (!latestIsTermAsNeverEligible && lostEligibilityDateInAfterEnteredEligDate && lostEligibilityDateIsBeforeEnteredEligDate)) {
        this.coreService.popMessage('This subscriber SSN is currently enrolled in ' + this.subscriberTransferRec.agencyCode + 
          ' eligible coverage and cannot be claimed to enroll as self-pay/continuation coverage.  '  + this.subscriberTransferRec.agencyCode + 
          ' benefits eligibility must be terminated prior to self-pay enrollment.', 'error', 4000);
      } 
      else if (this.subscriberTransferRec?.memberSelfPayOrigin && (!this.subscriberTransferRec?.memberSelfPayOrigin?.agencyEffectiveEndDate || 
        (
          dayjs(dayjs(this.subscriberTransferRec?.memberSelfPayOrigin?.agencyEffectiveEndDate).endOf('month').toDate()).isAfter(subscriberTransferSearch.eligibilityDate) 
          && this.subscriberTransferRec?.memberSelfPayOrigin?.agencyEffectiveEndDate>this.subscriberTransferRec?.memberSelfPayOrigin?.agencyEffectiveStartDate
        )
        )) {
        //active self pay origin exists
        this.coreService.popMessage('This subscriber SSN has an existing self pay origin record for this eligibility date.', 'error', 4000);
      } else {
        if (this.subscriberTransferRec && this.subscriberTransferRec?.isSubscriberInd) {
          this.selfPaySubscriber = new SelfPayMember(this.subscriberTransferRec);
          this.selfPaySubscriber.memberSelfPayOrigin.agencyEffectiveStartDate = subscriberTransferSearch.eligibilityDate;
          this.transferFoundShow = true;
          this.displayAddSearch = false;
        } else {
          this.selfPaySubscriber = new SelfPayMember({});
          this.selfPaySubscriber.socialSecurityNumber = subscriberTransferSearch.socialSecurityNumber;
          this.selfPaySubscriber.memberSelfPayOrigin.agencyEffectiveStartDate = subscriberTransferSearch.eligibilityDate;
          this.displaySelfPayAdd = true;
          this.displayAddSearch = false;
        }
      }
    } catch (err) {
      console.log(err);
      this.coreService.popMessage('An error has occured while searching, please review your information and try again', 'error', 2000);
    }
  }

  claimSubscriber(): void {
    // is hca adding selfpay
    if (this.isHCAWithEdit && !this.currentOrganization) {
      this.transferFoundShow = false;
      this.displaySelfPayAdd = true;
    } else {
      this.displaySubscriberAdd = true;
      this.transferFoundShow = false;
    }
  }

  async saveSubscriber(e: { subscriber: SubscriberComposite, isDEAndAccepted: boolean, isPortCommissioner: boolean}): Promise<void> {
      e = cloneDeep(e);
      e.subscriber.memberTypeId = this.currentOrganization.agency.agencyCode === env.sebbCode && e.subscriber.memberTypeId && !this.currentOrganization.memberTypeId ? e.subscriber.memberTypeId : this.currentOrganization.memberTypeId;
      this.spinnerService.show();
      let sub;
      // Set port commissioner flag on composite for initial defaulting
      if (e.isPortCommissioner) {
        e.subscriber.isPortCommissioner = true;
      }
      if (isEmpty(e.subscriber.memberMedicare)) {
        e.subscriber.memberMedicare = null;
      }
      try {
        if (this.subscriberTransferRec && this.subscriberTransferRec.isSubscriberInd && (this.isTransfer || this.subscriberTransferRec.isEligibileForRetireeTransfer)) {
          sub = await lastValueFrom(this.subscriberService.transferSubscriber(e.subscriber, this.currentOrganization.organizationId));
        } else if (this.subscriberTransferRec && this.subscriberTransferRec.isSubscriberInd) {
          sub = await lastValueFrom(this.subscriberService.claimSubscriber(e.subscriber, this.currentOrganization.organizationId));
        } else {
          sub = await lastValueFrom(this.subscriberService.createSubscriber(e.subscriber, this.currentOrganization.organizationId));
        }

        const memberId = sub?.memberId ?? this.subscriberTransferRec?.subscriber.memberId;

        if (e.isDEAndAccepted) {
          const action = new Action();
          action.actionDate = new Date();
          action.actionTypeId = get(find(this.lookups.actionTypes, (at: ActionType) =>  at.actionTypeCode === 'ADEM'), 'actionTypeId');
          action.memberId = memberId;
          await lastValueFrom(this.actionService.createAction(action)).then(() => {
            this.formSubmitted = false;
          });
        }

        if (e.isPortCommissioner) {
          await lastValueFrom(this.memberService.setMemberFlag(memberId, 'ISPORTCOMMISSIONER', e.isPortCommissioner)).then(() => {
            this.formSubmitted = false;
          });
        }
        if (!e.subscriber.isEligibleForLTD) {
          await lastValueFrom(this.memberService.setMemberFlag(memberId, 'LTDINELIGIBLE', !e.subscriber.isEligibleForLTD, sub.agencyEffectiveStartDate)).then(() => {
            this.formSubmitted = false;
          });
        }
        this.coreService.popMessage('Subscriber Successfully added!', 'success', 2000);
        this.router.navigate(['..'], { relativeTo: this.route });
      } catch (err) {
        this.spinnerService.hide();

        if (err) {
          // 400s are handled by the service, but still bounce as an error so we can stop navigation
          console.log(err);
          this.coreService.popMessage('An error has occured submitting this subscriber, please review your information and try again', 'error', 2000);
          this.formSubmitted = false;
          this.router.navigate(['..'], { relativeTo: this.route });
        }
      }
      this.spinnerService.hide();
  }

  async saveSelfPaySubscriber(selfPayMember: SelfPayMember): Promise<void> {
    this.spinnerService.show();
    const spMemberClone = cloneDeep(selfPayMember);
    try {
      if (isEmpty(spMemberClone.memberMedicare)) {
        spMemberClone.memberMedicare = null;
      }
      if (this.subscriberTransferRec) {
        await lastValueFrom(this.subscriberService.claimSelfPaySubscriber(spMemberClone)).then(() => {
          this.formSubmitted = false;
        });

      } else {
        await lastValueFrom(this.subscriberService.createSelfPaySubscriber(spMemberClone)).then(() => {
          this.formSubmitted = false;
        });
      }
      this.coreService.popMessage('Subscriber Successfully added!', 'success', 2000);
      this.router.navigate(['..'], { relativeTo: this.route });
    } catch (err) {
      this.spinnerService.hide();
      console.log(err);
      this.coreService.popMessage('An error has occured submitting this subscriber, please review your information and try again', 'error', 2000);
      this.router.navigate(['..'], { relativeTo: this.route });
      this.formSubmitted = false;
    }
    this.spinnerService.hide();
  }

  cancelAddMember(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private initNewSubscriberComposite(existingSubscriber?: SubscriberComposite): void {
    if (existingSubscriber) {
      this.subscriber = cloneDeep(existingSubscriber);
    } else {
      this.subscriber = new SubscriberComposite();
    }
  }


}

