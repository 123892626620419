<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <div class="state">
        <div class="card">
            <div class="card-body">
                <h1>{{currentOrganization?.organizationName}}</h1>
                <h2 class="mobile-text-centered subhead-mg">Billing files</h2>
                <div class="mgbt-medium" *ngIf="documents.length>0">
                    This billing data is available so you can reconcile your records to HCA billing information.  The billing file contains detail by subscriber for the coverage 
                    month.  It includes all current and retroactive transactions.
                </div>
                <div class="mgbt-medium" *ngIf="documents.length==0">
                    No billing file data available.
                </div>
                <div fxLayout="row" class="fxGap-medium" fxLayoutAlign="space-between center" *ngIf="documents.length>0">
                    <div class="form-group" fxFlex="100" fxFlex.gt-sm="50">
                        <label for="billingFileDocs">Selected billing file</label>
                        <select class="form-control" id="billingFileDocs" name="billingFileDocs" [(ngModel)]="selectedDocument" (change)="documentChange()"
                            style="max-width: 400px">
                            <option *ngFor="let doc of documents" [ngValue]="doc">
                                {{ doc.documentName }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <button class="btn btn-primary" (click)="downloadFlatFile()">Download Flat File</button>
                    </div>
                </div>
                <div id="billingGridParent" *ngIf="selectedDocument && gridData">
                    <generic-grid
                      #billingGrid
                      [pageable]="true"
                      [pageSize]="20"
                      [gridData]="gridData"
                      [gridColumns]="gridColumns"
                      [editable]="false"
                      [exportable]="true"
                      [exportFileName]="exportFileName"
                      [filterable]="true"
                    >
                    </generic-grid>
                </div>
            </div>
        </div>
    </div>
</div>