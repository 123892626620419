import { HCASelfPayDashboardComponent } from './components/hca/components/selfpay/hca.selfpay.dashboard.component';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { LookupService } from './../../services/lookup.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { RelationshipService } from '../../services/relationship.service';
// subscriber dash.

// perspay dash
import { PerspayDashboardComponent } from './components/perspay/perspay.dashboard.component';
import { HCADashboardComponent } from './components/hca/hca.dashboard.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    FormsModule,
    InputsModule,
    PDFExportModule,
    FlexLayoutModule,
    FontAwesomeModule,
    SharedModule,
    NgbAlertModule,
    CommonModule
  ],
  declarations: [
    PerspayDashboardComponent,
    HCADashboardComponent,
    HCASelfPayDashboardComponent,
  ],
  providers: [SubscriberService, LookupService, RelationshipService],
  exports: [ PerspayDashboardComponent, HCADashboardComponent],
  bootstrap: [  PerspayDashboardComponent, HCADashboardComponent]
})
export class DashboardModule { }
