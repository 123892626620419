import { Component, ViewEncapsulation, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'admin-access-search',
  templateUrl: 'adminAccess.search.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class AdminAccessSearchComponent implements OnInit {
  public queryString: string;
  @Output() searchSubmitted: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
  }

  searchPerspayAdmins() {
    this.searchSubmitted.emit(this.queryString);
  }
}
