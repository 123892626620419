<div fxLayout="column" class="fxGap-large">
  <h3 class="prompt-question">
    The subscriber is currently enrolled in our {{dualEnrollment?.otherAgency === 'SEBB' ? 'School' : 'Public'}} Employees Benefit ({{dualEnrollment?.otherAgency ?? 'SEBB'}})
    program. Dual enrollment between programs is not allowed. You can choose to keep the {{dualEnrollment?.otherAgency ?? 'SEBB'}} enrollments and waive the medical
    {{isFullBenefits ? 'and dental' : ''}}  enrollments in {{dualEnrollment?.currentAgency ?? 'PEBB'}} for yourself and associated dependents Or continue enrolling in
    {{dualEnrollment?.currentAgency ?? 'PEBB'}} knowing that dual enrollment conflicts will be terminated by Washington Healthcare Authority.
  </h3>
  <div class="mgbt-medium-lg" style="padding-left: 75px; padding-right: 75px" fxLayout="row" fxLayoutAlign="space-between">
    <div fxFlexFill class="mgtp-small" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="waive.emit()" class="btn btn-secondary">Waive Enrollment</button>
      <button (click)="continueEnrollment()" class="btn btn-primary">Continue Enrolling</button>
    </div>
  </div>
  <div *ngIf="!hideBackToDash" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <button (click)="backToDash.emit()" class="btn btn-secondary mgtp-medium-lg">Back to dashboard</button>
  </div>
</div>
