import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, uniqBy } from 'lodash';
import { RelationshipService } from '../../../../services/relationship.service';
import Relationship from '../../../../models/relationship';
import SystemUser from '../../../../models/user';
import { SoeService } from 'src/app/services/soe.service';
import { faUser, faUserShield, faBuilding, faDoorOpen, faExchangeAlt, faUserClock, faChartBar, faCheckCircle, faCog, faCheckDouble, faHistory } from '@fortawesome/free-solid-svg-icons';
import { SelfPayService } from 'src/app/services/selfPay.service';
import { State } from '@progress/kendo-data-query';
import { CoreService } from 'src/app/services/core.service';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.DocumentType)
@Component({
  selector: 'hca-dashboard',
  templateUrl: 'hca.dashboard.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class HCADashboardComponent implements OnInit {
  // icons
  icons = {
  faUser,
  faUserShield,
  faBuilding ,
  faDoorOpen ,
  faExchangeAlt,
  faUserClock,
  faChartBar,
  faCheckCircle,
  faCog,
  faCheckDouble,
  faHistory
  };

  systemUser: SystemUser;
  organizations: {organization: string, pendingRelationships: Relationship[]}[] = [];
  totalRelationshipVerifcationsPending = 0;
  totalSpecialOpenEnrollmentPending = 0;
  totalSelfpayPending = 0;
  constructor(
    private route: ActivatedRoute,
    private relationshipService: RelationshipService,
    private specialOpenEnrollmentService: SoeService,
    private selfpayService: SelfPayService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      data => {
        if (data.user) {
        this.systemUser = data.user;
        this.getPendingRelationships(this.systemUser);
        this.getPendingSoes(this.systemUser);
        this.getPendingSelfpay(this.systemUser);
        }
        }, (err) => {
          console.log(err);
        });
  }

  getPendingRelationships(user): void {
    this.totalRelationshipVerifcationsPending = 0;
    var state: State = {
      skip: 0,
      take: 10
    };
    var gridQuery = this.coreService.kendoGridStateToQueryString(state);
    this.relationshipService.getCurrentRelationshipsForHCA(true,gridQuery,"", null).pipe(untilDestroyed(this)).subscribe((pendingRelationships) => {
      this.totalRelationshipVerifcationsPending = pendingRelationships.total;
    });
  }

  getPendingSoes(user): void {
    this.totalSpecialOpenEnrollmentPending = 0;
    var state: State = {
      skip: 0,
      take: 10,
      filter: {filters: [{filters: [{field: "specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusName",operator: "contains",value: "Pending"}],logic: "and"}],logic: "and"},
    };
    var gridQuery = this.coreService.kendoGridStateToQueryString(state);
    this.specialOpenEnrollmentService.getSpecialOpenEnrollmentsForHCA(true, gridQuery).pipe(untilDestroyed(this)).subscribe((pendingSOEs) => {
      this.totalSpecialOpenEnrollmentPending = pendingSOEs.total;
    });
  }

  getPendingSelfpay(user): void {
    this.totalSelfpayPending = 0;
    var state: State = {
      skip: 0,
      take: 10,
      filter: {filters: [{filters: [{field: "simplifiedStatus",operator: "contains",value: "Pending"}],logic: "and"}],logic: "and"},
    };    
    var gridQuery = this.coreService.kendoGridStateToQueryString(state);
    this.selfpayService.getSelfPaysForHCA(true, false, gridQuery).pipe(untilDestroyed(this)).subscribe((pendingSPs) => {
      this.totalSelfpayPending = pendingSPs.total;
    });
  }
}

function Unsubscribe() {
  throw new Error('Function not implemented.');
}

