<generic-grid
    [gridData]="lookup"
    [gridColumns]="gridColumns"
    [editable]="!readonly"
    [disableAdd]="readonly"
    [filterable]="false"
    (handlePost)="post($event)"
    (handlePut)="put($event)"
    [disableAdd]="readonly || appSettings"
    [globalReadOnly]="readonly || !coreService.systemUserHasAccess('admin')"
    [checklist]="checklist"
    [disabledDatesFn]="disabledDatesFn"
    [agencies]="agencies"
    >
    </generic-grid>