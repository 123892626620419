import { remove, map } from 'lodash';
import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import MemberSummary from 'src/app/models/memberSummary';

@Component({
  selector: 'summary-coverage',
  templateUrl: 'summary.coverage.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SummaryCoverageComponent implements OnInit {
  @Input() memberSummary: MemberSummary[];
  @Input() spousalCoverageSurcharge: boolean;
  @Input() inAdminState: boolean;
  @Input() isFullBenefits: boolean;
  @Input() electablePlanTypesForCurrentPeriod: [];
  coverageOverviewColumns: { [k: string]: string | {} }[] = [
    {
      field: 'memberName',
      title: 'Member name',
      format: 'text'
    }
  ];
  tobaccoColumns = [
    {
      field: 'memberName',
      title: 'Member name',
      format: 'text'
    },
    {
      field: 'tobaccoUseInd',
      title: 'Tobacco use',
      format: 'text'
    }
  ];

  ngOnInit() {
    this.coverageOverviewColumns = this.coverageOverviewColumns.concat(map(this.electablePlanTypesForCurrentPeriod, (b) => {
      return {
        field: `${b.toLowerCase()}EffectiveDates`,
        title: `${b} effective dates`,
        format: 'text'
      }
    }));
  }
}

