<form nativeControlElement (ngSubmit)="submitSelfPaySubscriber(memberForm,formContainer)" *ngIf="selfPaySubscriber" #memberForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill #formContainer>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
    <div class="form-group required" fxFlex="30">
      <label for="lastName">Last name</label>
      <input 
        minlength="1"
        maxlength="20"
        pattern="^(?!\s)(?!.*\s$)([a-zA-Z \-]){1,}$" 
        required type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="selfPaySubscriber.lastName" />
    </div>
    <div class="form-group required" fxFlex="30">
      <label for="firstName">First name</label>
      <input 
        minlength="1"
        maxlength="15"
        pattern="^(?!\s)([a-zA-Z \-]){1,}$" 
        required type="text" class="form-control" id="firstName" name="firstName" [(ngModel)]="selfPaySubscriber.firstName" />
    </div>
    <div class="form-group" fxFlex="15">
      <label for="middleName">Middle name</label>
      <input type="text" pattern="^(?!\s)([a-zA-Z \-]){1,}$" class="form-control" maxLength="15" id="middleName" name="middleName" [(ngModel)]="selfPaySubscriber.middleName" />
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="socialSecurityNumber" id="ssnLabel">SSN</label>
      <kendo-maskedtextbox
        [mask]="env.ssnMask"
        [pattern]="env.ssnItinRegex"
        [(ngModel)]="selfPaySubscriber.socialSecurityNumber"
        [maskOnFocus]="true"
        required
        [disabled]="true"
        name="socialSecurityNumber"
        aria-labelledby="ssnLabel"
        #ssn="ngModel"
        class="form-control"
        [forbiddenValues]="env.invalidSSNValues"
      ></kendo-maskedtextbox>
      <div *ngIf="ssn.invalid && ssn.touched && (!!ssn.errors.forbiddenValues || !!ssn.errors?.pattern)" class="alert alert-danger">This SSN is invalid.</div>
    </div>
  </div>
  <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlex="100">
    <div class="form-group" fxFlex="10">
      <label for="suffix">Suffix</label>
      <input pattern="[a-zA-Z]*" type="text" class="form-control" id="suffix" name="suffix" [(ngModel)]="selfPaySubscriber.suffix" placeholder="JR, SR" />
    </div>
    <div class="form-group" fxFlex="15">
      <date-picker
        #birthDate="ngModel"
        id="birthDate"
        name="birthDate"
        label="Birth date"
        required
        [min]="minBirthDate"
        [canBeInFuture]="false"
        [(ngModel)]="selfPaySubscriber.birthDate"></date-picker>
        <div *ngIf="birthDate?.errors?.ngbDate?.minDate" class="alert alert-danger">Subscriber's date of birth is older than 110 years.</div>
        <div *ngIf="birthDate.value > tenYearsDate" class="alert alert-warning">Warning - Birthdate may be in error. Please verify</div>
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="birthSexId">Sex assigned at birth</label>
      <select required class="form-control" id="birthSexId" name="birthSexId" [(ngModel)]="selfPaySubscriber.birthSexId">
        <option></option>
        <option *ngFor="let birthSex of lookups.birthSexes | isObsolete" [ngValue]="birthSex.birthSexId">{{ birthSex.birthSexName }}</option>
      </select>
    </div>
    <div class="form-group required" fxFlex="20">
      <label for="genderIdentityId">Gender Identity</label>
      <select required class="form-control" id="genderIdentityId" name="genderIdentityId" [(ngModel)]="selfPaySubscriber.genderIdentityId">
        <option></option>
        <option *ngFor="let genderIdentity of lookups.genderIdentities | isObsolete" [ngValue]="genderIdentity.genderIdentityId">{{ genderIdentity.genderIdentityName }}</option>
      </select>
    </div>
    <div class="form-group" fxFlex="25">
      <span>
        Gender X means a gender that is not exclusively male or female. This field will be kept private to the extent allowable by law.
      </span>
    </div>
  </div>
  <!-- PHONE -->
  <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between" fxLayout.lt-md="column">
    <div fxFlexFill><b>Phone numbers are used by HCA and health plan carriers to contact subscribers to resolve issues and provide customer support.</b></div>
    <br />
    <br />
    <div fxFlex="40" class="form-group">
      <label for="homePhoneNumber">Home phone number</label>
      <input pattern="[0-9]*" minlength="10" maxlength="10" type="text" class="form-control" id="homePhoneNumber" name="homePhoneNumber" [(ngModel)]="selfPaySubscriber.homePhoneNumber" />
    </div>
    <div fxFlex="40" class="form-group">
      <label for="workPhoneNumber">Work phone number</label>
      <input pattern="[0-9]*" minlength="10" maxlength="10" type="text" class="form-control" id="workPhoneNumber" name="workPhoneNumber" [(ngModel)]="selfPaySubscriber.workPhoneNumber" />
    </div>
  </div>
  <!-- REPRESENTED STATUS  -->
  <!-- <div class="form-row" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
    <div class="form-group required" fxFlex="20">
      <label for="representedInd">Is this employee represented?</label>
      <select required class="form-control" id="representedInd" name="representedInd" [(ngModel)]="selfPaySubscriber.isRepresentedInd">
        <option [ngValue]="true">Yes</option>
        <option [ngValue]="false">No</option>
      </select>
    </div>
    <div class="form-group" *ngIf="selfPaySubscriber.isRepresentedInd === true" fxFlex="20">
      <date-picker
        #effectiveStartDate="ngModel"
        name="effectiveStartDate"
        label="Effective start date"
        required
        [max]="maxDate"
        [(ngModel)]="selfPaySubscriber.representedDate"></date-picker>
    </div>
  </div> -->
  <!-- ELIGIBILITY -->
  <div fxLayout="row" fxFlexFill>
    <div fxFlex="100">
      <selfpay-enrollment-eligibility 
        *ngIf="lookups?.organizations?.length"
        #subEligibility
        [(selfPayOrigin)]="selfPaySubscriber.memberSelfPayOrigin"
        [lookups]="lookups"
      ></selfpay-enrollment-eligibility>
    </div>
  </div>
  <h3 class="selfPay-form-small-header">Medicare information</h3>
  <medicare #medicareComponent fxFlexFill [medicareOptions]="lookups.medicareOptions" [simpleMember]="selfPaySubscriber" [memberMedicare]="selfPaySubscriber.memberMedicare" [enrollmentSignatureMinDate]="formCSignatureMinDate">
  </medicare>
  <!-- RESIDENTIAL ADDRESS -->
  <address #residentialAddress name="residentialAddress" label="Residential address" [(ngModel)]="selfPaySubscriber.residentialAddress"></address>

  <div class="k-form-field mgbt-medium mgtp-x-small" style="width: 100%;">
    <checkbox
      name="mailingAddressIsSame"
      bold
      (ngModelChange)="updateMailingAddress($event)" 
      [(ngModel)]="mailingAddressIsSame"
    >
      Same mailing address
  </checkbox>
  </div>
  <!-- MAILING ADDRESS -->
  <address #mailingAddress *ngIf="selfPaySubscriber.mailingAddress" name="mailingAddress" label="Mailing address" [labelIcon]="icons.faEnvelope" [(ngModel)]="selfPaySubscriber.mailingAddress"></address>

  <br/>
  <br/>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
    <button fxFlex="30" id="sub-add-submit-btn" type="button" [disabled]="formSubmitted" (click)="submitSelfPaySubscriber(memberForm,formContainer)" class="btn btn-primary">Submit</button>
    <button fxFlex="30" id="sub-add-cancel-btn" type="button" (click)="cancelAdd.emit()" class="btn btn-light">Cancel</button>
  </div>
</form>
