<div class="grid-container">
  <kendo-grid
    #kendoGrid
    [data]="gridData"
    class="k-grid-ignore-click"
    [filterable]="'menu'"
    [filter]="state.filter"
    [skip]="state.skip"
    [sortable]="true"
    [sort]="state.sort"
    [pageable]="true"
    [pageSize]="state.take"
    [selectable]="selectableSettings"
    (dataStateChange)="dataStateChange($event)"
    (detailExpand)="getRowDetails($event)"
    (excelExport)="onExcelExport($event)"
    (selectionChange)="selectionChange($event)"
  >
    <ng-template kendoGridToolbarTemplate >
      <div fxLayout="row" fxLayoutAlign="space-between" fxFlexFill>
        <button type="button" kendoGridExcelCommand icon="file-excel">Download list shown</button>
        <div fxLayout="row" fxFlex="40" fxLayoutAlign="space-between">
          <button *ngIf="selectedRows?.length && !selectedRowsContainsAssigned(systemUser?.systemUserId)" type="button" class="btn btn-primary" (click)="assignSelectedRowsToSelf()">Assign to me</button>
          <button *ngIf="selectedRows?.length && selectedRowsContainsAssigned()" class="btn btn-primary" (click)="unAssignSelectedRows()" type="button">Unassign</button>
          <button *ngIf="selectedRows?.length" type="button" class="btn btn-secondary" (click)="showAssignToPanel = true" >Assign To:</button>
          <button *ngIf="showSaveChanges" class="btn btn-primary" type="button" (click)="saveAllAssignmentChanges()" >Save Changes</button>
        </div>
      </div>
    </ng-template>
    <kendo-grid-checkbox-column [showSelectAll]="true" > </kendo-grid-checkbox-column>
    <kendo-grid-column
      class="k-grid-ignore-click"
      [style.flex]="col.flex || 1"
      *ngFor="let col of gridColumns"
      field="{{ col.field }}"
      title="{{ col.title }}"
      width="{{ col.width }}"
      [format]="col.format"
      [filter]="col.filter ? col.filter : null"
      [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
    >
      <ng-template kendoGridFilterMenuTemplate
        let-column="col"
        let-filter="filter"
        let-filterService="filterService"
        *ngIf="col.field.endsWith('Date')"
        >
        <date-picker
          name="dateFilter"
          [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
          (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
          [label]="col.title">
        </date-picker>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{outputField(dataItem, col)}}
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
      <div fxFill class="card">
        <div class="card-body" style="margin-left: 12px">
          <selfpay-summary *ngIf="selectedDataItem" [dataItem]="selectedDataItem" [lookups]="lookups" [userCanEdit]="userCanEdit"></selfpay-summary>
          <selfpay-form
            #review
            *ngIf="selectedDataItem"
            [dataItem]="selectedDataItem"
            [systemUser]="systemUser"
            [userCanEdit]="userCanEdit"
            [rowIndex]="rowIndex"
            [birthSexes]="lookups.birthSexes"
            [genderIdentities]="lookups.genderIdentities"
            [selfPayPaymentTypes]="lookups.selfPayPaymentTypes"
            [lookups]="lookups"
            [availablePlans]="availablePlans"
            [supplementalPlans]="supplementalPlans"
            (resetData)="reSetDataForGrid(true)"
            (pushSaveVerificationResponse)="pushSaveVerification(selectedDataItem, rowIndex)"
            (saveAndContinue)="saveAndContinue.emit(selectedDataItem)"
            (saveSelfPayElections)="saveSelfPayElections.emit($event)"
            (downloadPDF)="downloadSelfpayPDF($event)"
          ></selfpay-form>
        </div>
      </div>
    </ng-template>
    <kendo-grid-excel [fileName]="'self_pay_requests_' + dayjs().format('MM/DD/YYYY') + '.xlsx'"></kendo-grid-excel>
  </kendo-grid>
<kendo-dialog
  title="Assign to user"
  *ngIf="showAssignToPanel"
  (close)="showAssignToPanel = false;"
  [minWidth]="400"
  width="45vw"
  minHeight="350px"
  maxHeight="45vh"
  class="date-picker-info"
>
<div fxFill fxLayout="column">
  <label for="assignee">Users</label>
  <!-- <input type="text" list="assignee" [(ngModel)]="assignToUser">
  <datalist required (ngModelChange)="setAssignedToUser($event)" required class="form-control" id="assignee">
    <select>
      <option *ngFor="let user of hcaUsers" [value]="user.systemUserId">
        {{ user.firstName }} {{ user.lastName }}
      </option>
    </select>
  </datalist> -->
  <kendo-combobox name="asignee" (ngModelChange)="setAssignedToUser($event)" [data]="userData" textField="fullName" valueField="systemUserId" [(ngModel)]="assignToUser" [filterable]="true" (filterChange)="handleFilter($event)">
  </kendo-combobox>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="showAssignToPanel = false">Cancel</button>
</kendo-dialog-actions>
</kendo-dialog>
</div>

<pdf #selfPayPDF *ngIf="this.downloading && selectedDataItem" [documentTitleText]="selectedDataItem?.agency?.agencyCode + ' ' + formTitle" 
  [documentHeaderText]="formHeaderText"
  [containerClass]="pdfContainerClass">
  <self-pay-pdf 
    *ngIf="!soeCustomForm"
    [selfPay]="selectedDataItem" 
    [selfPayPaymentTypes]="lookups.selfPayPaymentTypes" 
    [birthSexes]="lookups.birthSexes" 
    [availablePlans]="availablePlans"
    [genderIdentities]="lookups.genderIdentities"></self-pay-pdf>

  <self-pay-custom-form-pdf
    *ngIf="soeCustomForm"
    [selfPay]="selectedDataItem">
  </self-pay-custom-form-pdf>
</pdf>

<!-- PDF -->
<!-- <pdf #selfpayPDF *ngIf="soeForPdf?.formJson" [documentTitleText]="' PEBB Employee Enrollment/Change Request'" [documentHeaderText]="''">
  <selfpay-pdf #pdfContent [selfpay]="selfpayForPdf"></selfpay-pdf>
</pdf> -->
