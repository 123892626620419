import { Component, ViewEncapsulation, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { sortBy, reverse } from 'lodash';
import MemberNote from 'src/app/models/memberNote';
import SystemUser from 'src/app/models/user';
import { faMinus, faPlus, faTrashAlt, faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import SubscriberAccountCorrection from 'src/app/models/subscriberAccountCorrection';

@Component({
  selector: 'account-correction-edit-notes',
  templateUrl: 'edit.notes.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class AccountCorrectionEditNotesComponent implements OnInit, OnChanges {
  @Input() subscriber: SubscriberAccountCorrection;
  @Input() memberId: string;
  @Input() systemUser: SystemUser;
  @Input() memberNotes: MemberNote[];
  @Input() index: number;
  @Input() editable: boolean;
  @Output() noteSave: EventEmitter<any> = new EventEmitter<any>();
  messages: MemberNote[];
  user: SystemUser;
  newMessage: MemberNote;
  icons = {
    faPlus,
    faMinus,
    faTrashAlt,
    faPlusCircle,
    faSave
  };
  gridColumns: { [k: string]: string | {} }[] = [
    { field: 'createdDate', title: 'Created On', format: { date: 'mm/dd/yyyy' }, width: 200, filter: 'date' },
    { field: 'createdByName', title: 'Created By', format: 'string', width: 200 },
    { field: 'noteText', title: 'Note', format: 'string' },
  ];
  
  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.memberNotes = reverse(sortBy(this.memberNotes, 'createdDate'));
  }

  addNewNote() {
    const newNote = new MemberNote();
    newNote.memberId = this.subscriber.memberId;
    newNote.createdDate = new Date();
    this.newMessage = newNote;
  }

  onNoteUpdate(note) {
    this.noteSave.emit({note: note, index: this.index});
    this.newMessage = null;
  }

}
