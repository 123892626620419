import { Component } from '@angular/core';

@Component({
  selector: 'unauthorized',
  templateUrl: 'unauthorized.component.html',
  styleUrls: [],
  providers: []
})
export class UnauthorizedComponent {
  constructor() {}

}
