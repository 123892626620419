<div fxLayout="row wrap">
  <div fxLayout.gt-sm="column" class="ie-height-xs" fxLayout="row wrap" fxFlex.gt-sm="45" fxFlex="45" style="margin-bottom:15px;" >
    <h3 class="h4"><span class="pad-std-label">Request type:</span><span>{{dataItem.selfPayType.selfPayTypeName}}</span></h3>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="subscriberName" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">Subscriber:</label>
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberName" value="{{dataItem.member.fullName}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="subscriberSSN" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label" >SSN:</label>  
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberSSN" value="{{dataItem.member.socialSecurityNumber}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="subscriberBirthDate" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">DOB:</label>
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberBirthDate" value="{{dataItem.member.birthDate | date: 'MM/dd/yyyy'}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100" *ngIf="dataItem.specialOpenEnrollmentTypeId !== null">
      <label for="subscriberSoeType" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">SOE type:</label>
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberSoeType" value="{{dataItem.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName}}">
    </div>
  </div>
  <div fxLayout.gt-sm="column" class="ie-height-xs" fxLayout="row wrap" fxFlex.gt-sm="45" fxFlex="45" style="margin-bottom:15px;" >
    <h3 class="h4">Account status</h3>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="eligibility" fxFlex.gt-sm="40" fxFlex="100" class="col-form-label hca-control-label">Eligibility:</label>
      <input type="text" readonly fxFlex.gt-sm="60" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="eligibility" value="{{dataItem.lossOfEligibilityDate ? 'Terminated' : 'Active'}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="lossOfEligibilityDate" fxFlex.gt-sm="40" fxFlex="100" class="col-form-label hca-control-label" >Loss of eligibility date:</label>  
      <input type="text" readonly fxFlex.gt-sm="60" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="lossOfEligibilityDate" value="{{dataItem.lossOfEligibilityDate ? (dataItem.lossOfEligibilityDate | date: 'MM/dd/yyyy') : 'N/A'}}">
    </div> 
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="termReason" fxFlex.gt-sm="40" fxFlex="100" class="col-form-label hca-control-label" >Loss of eligibility reason:</label>  
      <input type="text" readonly fxFlex.gt-sm="60" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="lossOfEligibilityReasonName" value="{{dataItem.lossOfEligibilityReasonName  || 'N/A'}}">
    </div>
    <!-- has organization -->
    <div *ngIf="dataItem.member?.organization?.organizationName" fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="termReason" fxFlex.gt-sm="40" fxFlex="100" class="col-form-label hca-control-label" >Employer:</label>  
      <input type="text" readonly fxFlex.gt-sm="60" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="employerName" value="{{dataItem.member?.organization?.organizationName?.includes('107') ? 'Health Care Authority' : dataItem.member?.organization?.organizationCode + ' - ' + dataItem.member?.organization?.organizationName  || 'N/A'}}">
    </div>
    <div *ngIf="dataItem.member?.organization?.organizationName" fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="termReason" fxFlex.gt-sm="40" fxFlex="100" class="col-form-label hca-control-label" >Benefits Setup:</label>  
      <input type="text" readonly fxFlex.gt-sm="60" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="benefitsSetup" value="{{dataItem.member?.organization?.benefitSetup?.benefitSetupName  || 'N/A'}}">
    </div> 
    <!-- only has member SPO-->
    <div *ngIf="!dataItem.member?.organization?.organizationName" fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="termReason" fxFlex.gt-sm="40" fxFlex="100" class="col-form-label hca-control-label" >Employer:</label>  
      <input type="text" readonly fxFlex.gt-sm="60" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="employerName" value="{{dataItem.member?.memberSelfPayOrigin?.organization?.organizationName?.includes('107') ? 'Health Care Authority' : dataItem.member?.memberSelfPayOrigin?.organization?.organizationCode + ' - ' + dataItem.member?.memberSelfPayOrigin?.organization?.organizationName  || 'N/A'}}">
    </div>
    <div *ngIf="!dataItem.member?.organization?.organizationName" fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="termReason" fxFlex.gt-sm="40" fxFlex="100" class="col-form-label hca-control-label" >Benefits Setup:</label>  
      <input type="text" readonly fxFlex.gt-sm="60" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="benefitsSetup" value="{{dataItem.member?.memberselfPayOrigin?.organization?.benefitSetup?.benefitSetupName  || 'N/A'}}">
    </div>       
  </div>
</div>
<ng-container *ngIf="!latestEnrollment?.selfPayId">
  <h3>Coverage End Dates</h3>
  <div class="card mgbt-medium">
    <div fxFill class="card-body" style="max-height: 500px; overflow: auto;">
      <div fxFill fxLayout="column" class="fxGap-medium">
        <div *ngFor="let member of dataItem?.member?.members" fxFill fxLayout="row" fxLayoutAlign="space-between">
          <div fxFlex="100" fxLayout="column">
            <div fxLayout="row" class="fxGap-medium" fxFlex="100">
              <div fxFlex.gt-sm="30" fxFlex="100"><b>{{member.isSubscriberInd ? 'Subscriber:' : 'Dependent:'}}</b></div>
              <div fxFlex.gt-sm="70" fxFlex="100">{{member.fullName}}</div>
            </div>
          </div>
          <div fxFlex="100" fxLayout="column">
            <div fxLayout="row" class="fxGap-medium" fxFlex="100">
              <div fxFlex.gt-sm="30" fxFlex="100"><b>Medical: </b> </div>
              <div fxFlex.gt-sm="70" fxFlex="100">{{(getPlanEffectiveEnd(member, '1') | date: 'MM/dd/yyyy') || (latestEnrollment ? 'Not Enrolled' : 'null')}}</div>
            </div>
            <div *ngIf="hasEnrollmentForPlanType('2')" fxLayout="row" class="fxGap-medium" fxFlex="100">
              <div fxFlex.gt-sm="30" fxFlex="100"><b>Dental: </b></div>
              <div fxFlex.gt-sm="70" fxFlex="100">{{getPlanEffectiveEnd(member, '2') | date: 'MM/dd/yyyy' || (latestEnrollment ? 'Not Enrolled' : 'null')}}</div>
            </div>
            <div *ngIf="hasEnrollmentForPlanType('6')" fxLayout="row" class="fxGap-medium" fxFlex="100">
              <div fxFlex.gt-sm="30" fxFlex="100"><b>Vision: </b></div>
              <div fxFlex.gt-sm="70" fxFlex="100">{{getPlanEffectiveEnd(member, '6') | date: 'MM/dd/yyyy' || (latestEnrollment ? 'Not Enrolled' : 'null')}}</div>
            </div>          
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div fxLayout="row wrap" *ngIf="dataItem.documents.length > 0">
  <div fxLayout.gt-sm="column" class="ie-width-s hca-document-list-container" fxLayout="row wrap" fxFlex.gt-sm="50" style="width: 100%" fxFlex="100" style="margin-bottom:15px;">
    <h5 style="align-self:center" class="mobile-text-centered mgtp-small">Related documents</h5>
    <generic-document-viewer fxLayout.gt-sm="column" fxLayout="row wrap" fxFlex="100" [(documents)]="dataItem.documents"></generic-document-viewer>
  </div>
</div>