import Enrollment from './enrollment';
import EnrollmentPeriod from './enrollmentPeriod';

export default class Action {
    enrollments: Enrollment[];
    enrollmentPeriods: EnrollmentPeriod[];

    constructor(accountCorrection) {
        this.enrollments = accountCorrection.enrollments ? accountCorrection.enrollments.map(e => new Enrollment(e)) : null;
        this.enrollmentPeriods = accountCorrection.enrollmentPeriods ? accountCorrection.enrollmentPeriods.map(e => new EnrollmentPeriod(e)) : null;
    }
  }