<div class="card" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.lt-sm="column">
  <div class="card-body">
    <h3>Paying for benefits</h3>
    <span>In most cases, you must make your first payment by check before we can enroll you.</span>
    <div *ngIf="requestType.selfPayTypeCode !== 'OEU'">
      <div *ngIf="requestType.selfPayTypeCode === 'R'" class="container-fluid">
        <p>You have three payment options:</p>
          <ul>
            <li>Pension deduction</li>
            <li>Invoicing</li>
            <li>Electronic Debit Service (EDS)</li>
          </ul>
          <br/>
        <p>View premiums for <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/medicare-plan-premiums"> Medicare</a> and <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/non-medicare-plan-premiums">non-Medicare</a> to see what your medical and dental plans cost, based on family size and Medicare enrollment.  </p>
      </div>
      <h4>How do I make my first payment?</h4>
      <div *ngIf="requestType.selfPayTypeCode === 'R'" class="container-fluid">
        <p>
          We will not enroll you in coverage until we receive your first payment. If you miss this deadline, you may lose your right to enroll in {{agency}} retiree insurance 
          coverage. An exception applies if you select pension deduction as your payment option. 
          <br/>
          <br/>
          You cannot have a gap in coverage. Premiums are due back to the first of the month in which your PEBB retiree insurance coverage was effective. Premiums and applicable premium surcharges are for a full month of coverage and cannot be prorated for a partial month. Payments are processed immediately as required by state law.
        </p>
        <p>Make checks payable to Health Care Authority and send to:</p>
        <br />
        <p>Washington State Health Care Authority</p>
        <p>PO Box 42691</p>
        <p>Olympia, WA 98504-2691</p>
        <br />
      </div>
      <div *ngIf="requestType.selfPayTypeCode !== 'R'" class="container-fluid">
        <p>
          Make sure the amount of your first payment is correct by calling the {{agency}} Program at 1-800-200-1004 and selecting the option to speak with Accounting. You must mail or bring in your first
          premium payment. Make checks payable to the Health Care Authority and send to:
        </p>
        <div class="hca-address-block">
          <p>Washington State Health Care Authority</p>
          <p>{{agency}} Program</p>
          <p>PO Box 42691</p>
          <p>Olympia, WA 98504-2691</p>
        </div>
        <p>Or bring it to:</p>
        <div class="hca-address-block">
          <p>Health Care Authority</p>
          <p>626 8th Avenue SE</p>
          <p>Olympia, WA 98501</p>
          <p>(8 a.m. to 4:30 p.m. Monday to Friday)</p>
        </div>
      </div>
      <checkbox *ngIf="requestType.selfPayTypeCode === 'OER' || requestType.selfPayTypeCode === 'OEC'" name="continueCurrentPayment" [(ngModel)]="selectedSelfPay.formJson.continueCurrentPaymentOption"
      label="Stay with my current payment option"></checkbox>
      <h4>What are my payment options?</h4>
      <div *ngIf="requestType.selfPayTypeCode === 'R'" class="container-fluid">
        <p>
          After we receive your first full payment and any applicable premium surcharges, you must pay all future {{agency}} retiree insurance coverage premiums and any applicable premium surcharges as they become
          due. You must make sure the amount of your first premium payment is correct.
          <a href="https://www.hca.wa.gov/employee-retiree-benefits/contact-us" target="_blank">Contact the PEBB program</a>
          and select the menu option to speak with PEBB Accounting to confirm the amount due. Here are your payment options (please select one):
        </p>
      </div>
      <div *ngIf="requestType.selfPayTypeCode !== 'R'" class="container-fluid">
        <p>
          After we receive your first full payment and any applicable premium surcharges, you must pay all future continuation coverage premiums and any applicable premium surcharges as they become
          due. Here are your payment options (please select one):
        </p>
      </div>
    </div>

    <form nativeControlElement #paymentForm="ngForm" name="paymentForm" class="form-row" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start" class="fxGap-medium-lg" fxFlexFill>
      <div class="form-group hca-multi-line-checkbox" *ngIf="requestType.selfPayTypeCode === 'R'">
        <radio radioAsCheckbox name="pensionDeduction" value="P" [(ngModel)]="paymentOption" label="Pension deduction"
        [canDeselectRadio] = "requestType.selfPayTypeCode === 'R'"></radio>
        <p>
          Your payments are taken from your end-of-the-month pension through the Department of Retirement Systems (DRS). For example, if your coverage takes effect January 1, your January 31 pension will show your deductions for January. 
          <br/><br/>
          <b>Important!</b> Due to timing issues with DRS, you may receive an invoice for any premiums and applicable premium surcharges not deducted from your pension when you first enrolled. We will send you an invoice if a first payment is needed. If you receive an invoice, your payment is due by the deadline listed on it. 
          <br/><br/>
          I authorize the Department of Retirement Systems to deduct medical and dental premiums (if elected), retiree term life insurance (if elected), and applicable premium surcharges I am required
          to pay from my retirement pension. Deductions are taken at the end of the month that you receive coverage.
        </p>
      </div>
      <div class="form-group hca-multi-line-checkbox">
        <radio radioAsCheckbox name="automatic" value="PS" [(ngModel)]="paymentOption" label="Electronic debit service (EDS) (Automatic bank account withdrawals)" 
        [canDeselectRadio] = "requestType.selfPayTypeCode === 'R'"></radio>
        <p *ngIf="requestType.selfPayTypeCode === 'R'">
          You must make your first payment by check. Your first payment and applicable premium surcharges are due no later than 45 days after your 60-day election period ends. <b>You cannot make your first payment through EDS because approval takes six to eight weeks</b>.
          <br/><br/>
          I will pay my monthly medical and dental premiums (if elected) and applicable premium surcharges by EDS. I will submit the PEBB Electronic Debit Service Agreement . I understand I must pay
          by check until I am notified of my EDS effective date, and that I must make my first payment before I will be enrolled. I understand I will receive a separate bill from MetLife for my
          retiree term life insurance, if elected. To pay by EDS for your retiree term life insurance, call MetLife at 1-866-548-7139.
          <br />
          <br />
          Send the <a href="https://www.hca.wa.gov/assets/pebb/20-0045-sebb-electronic-debit-service.pdf" target="_blank">Electronic debit service agreement</a>
          to the address shown on the form with a voided check or deposit slip.
        </p>
        <p *ngIf="requestType.selfPayTypeCode !== 'R'">
          Submit an
          <a [href]="!isSEBB ? 'https://www.hca.wa.gov/assets/pebb/42-0450-pebb-electronic-debit-service.pdf' : 'https://www.hca.wa.gov/assets/pebb/20-0045-sebb-electronic-debit-service.pdf'" target="_blank">Electronic Debit Service Agreement</a>
          to the HCA with a voided check. Approval takes six to eight weeks, so you must continue to pay the total due each month until you receive a letter from the Health Care Authority with your electronic debit start
          date.
        </p>
      </div>
      <div class="form-group hca-multi-line-checkbox">
        <radio radioAsCheckbox name="check" value="S" [(ngModel)]="paymentOption" label="Monthly invoice"
        [canDeselectRadio] = "requestType.selfPayTypeCode === 'R'"></radio>
        <p *ngIf="requestType.selfPayTypeCode === 'R'">
          You will receive a monthly invoice after your first premium payment is made. Payments are due on the 15th of each month for that month of coverage. 
          <br/><br/>
          I will pay my medical and dental premiums (if elected) and applicable premium surcharges monthly by check. I understand I will receive a separate bill from MetLife for my retiree term life
          insurance, if elected. I understand that I must make my first payment before I will be enrolled. Send your payment to the address listed on the invoice.
        </p>
        <p *ngIf="requestType.selfPayTypeCode !== 'R'">Please make your check payable to Health Care Authority and bring it to the address listed above or mail to:</p>
        <div class="hca-address-block" *ngIf="requestType.selfPayTypeCode !== 'R'">
          <p>Washington State Health Care Authority</p>
          <p>Accounting</p>
          <p>PO Box 42691</p>
          <p>Olympia, WA 98504</p>
        </div> 
      </div>
      <div *ngIf="requestType.selfPayTypeCode === 'OEC'" class="form-group hca-multi-line-checkbox" fxFlex="100">
        <radio radioAsCheckbox name="unchanged" value="U" [(ngModel)]="paymentOption" label="I do not wish to change my current pay method"></radio>
      </div>
      <div *ngIf="requestType.selfPayTypeCode === 'R'" class="form-group">
        <checkbox name="defferedEnrollment" [(ngModel)]="selectedSelfPay.formJson.deferPaymentOption"
          (ngModelChange)="deferPaymentOptionChange()"
          label="I will be deferring retiree medical and dental coverage."></checkbox>
        <div *ngIf="selectedSelfPay?.formJson?.deferPaymentOption">
          (If you will be enrolling in retiree term life please select one of the payment methods listed above)
        </div>
      </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="pushCancel()" class="btn btn-secondary">Previous</button>
      <button (click)="pushContinue()" class="btn btn-primary">Proceed to enrollment {{ requestType.selfPayTypeCode === 'R' || requestType.selfPayTypeCode === 'OER' ? '/deferral' : '' }} form</button>
    </div>
  </div>
</div>
