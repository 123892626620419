import { Component, ElementRef, Input, Injector, OnInit, ViewChild, OnChanges, SimpleChanges, Host, SkipSelf, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, ValidationErrors, AbstractControl, NgForm, ControlContainer } from '@angular/forms';
import { __assign } from 'tslib';
import { CoreService } from 'src/app/services/core.service';
import { keyValuesToMap } from '@angular/flex-layout/extended/style/style-transforms';
import { textChangeRangeIsUnchanged } from 'typescript';

@Component({
  selector: 'checkbox-list, radio-list, yes-no, yes-no-checkbox',
  templateUrl: 'checkbox-radio-list.component.html',
  styleUrls: [],
  providers:
  [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxRadioListComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CheckboxRadioListComponent
    }
  ]
})
export class CheckboxRadioListComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @ViewChild('form') form: NgForm;
  @Input() name: string;
  @Input() items: any[] | { [key: string]: any };
  @Input() required: boolean | string = false;
  @Input() readOnly: boolean | string = false;
  @Input() disabled: boolean | string = false;
  @Input() orientation = 'horizontal-list';
  @Input() multi = true;
  @Input() keyProperty: string;
  @Input() valueProperty: string;
  @Input() yesValue = true;
  @Input() noValue = false;
  @Input() yesLabel = 'Yes';
  @Input() noLabel = 'No';
  @Input() label: string;
  @Input() stretch: boolean | string = false;
  @Input() bindToObject: boolean | string = false;
  @Input() refreshAllItemChanges: boolean = false;
  @Input() isTobaccoAttestation = false;
  @Input() isTobaccoAttestationSubscriber = true;
  @Input() isSebb: boolean = false;
  type: string;
  model: string | string[];
  labelVisible = true;
  innerRequired: boolean;
  innerReadonly: boolean;
  innerDisabled: boolean;
  innerItems: any[];
  innerStretch = false;
  isYesNo = false;
  private onChange = (value: string | string[]) => {};
  private onTouched = () => {};
  private onValidateChange = () => {};

  constructor(
    private coreService: CoreService,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    elem: ElementRef) {
    if (elem.nativeElement.tagName.toLowerCase() === 'checkbox-list') {
      this.type = this.name = 'checkbox';
      this.model = [];
    } else {
      this.type = this.name = 'radio';
    }

    if (elem.nativeElement.tagName.toLowerCase() === 'yes-no' ||
      elem.nativeElement.tagName.toLowerCase() === 'yes-no-checkbox') {
      this.orientation = 'horizontal';
      this.stretch = true;
      this.isYesNo = true;

      if (!this.items) {
        this.items = {
          [this.yesLabel]: this.yesValue,
          [this.noLabel]: this.noValue
        };
      }

      if (elem.nativeElement.tagName.toLowerCase() === 'yes-no-checkbox') {
        this.type = this.name = 'checkbox';
        this.multi = false;
      }
    }
  }

  ngOnInit(): void {
    this.innerRequired = this.required === true || this.required === '';
    this.innerReadonly = this.readOnly === true || this.readOnly === '';
    this.innerDisabled = this.disabled === true || this.disabled === '';
    this.innerStretch = this.stretch === true || this.stretch === '';

    this.translateItems();

    if (this.controlContainer?.control) {
      const prevMarkAsTouched = this.controlContainer.control.markAsTouched;
      const that = this;
      this.controlContainer.control.markAsTouched = (...args: any) => {
        that.coreService.markFormControlsAsTouched(that.form);
        prevMarkAsTouched.bind(this.controlContainer.control)(...args);
        that.onValidateChange();
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.required) {
      this.innerRequired = changes.required.currentValue === true || changes.required.currentValue === '';
    }

    if (changes && changes.readOnly) {
      this.innerReadonly = changes.readOnly.currentValue === true || changes.readOnly.currentValue === '';
    }

    if (changes && changes.disabled) {
      this.innerDisabled = changes.disabled.currentValue === true || changes.disabled.currentValue === '';
    }

    if (changes && changes.stretch) {
      this.innerStretch = changes.stretch.currentValue === true || changes.stretch.currentValue === '';
    }

    if (changes && changes.items && (!changes.items.isFirstChange || this.refreshAllItemChanges)) {
      this.translateItems();
    }

    if (this.isYesNo && changes && (changes.yesValue || changes.noValue)) {
      this.items = {
        [this.yesLabel]: this.yesValue,
        [this.noLabel]: this.noValue
      };
    }
  }

  determineKeyProperty(): string {
    if (this.keyProperty) {
      return this.keyProperty;
    }

    if (Array.isArray(this.items) && this.items.length) {
      const first = this.items[0];
      for (const key in first) {
        if (first[key]) {
          if (key === 'id' || key.endsWith('Id')) {
            return key;
          }
        }
      }
    }

    return 'id';
  }

  determineValueProperty(): string {
    if (this.valueProperty) {
      return this.valueProperty;
    }

    if (Array.isArray(this.items) && this.items.length) {
      const first = this.items[0];
      for (const key in first) {
        if (first[key]) {
          if (key === 'name' || key.endsWith('Name')) {
            return key;
          }
        }
      }
    }

    return 'name';
  }

  translateItems(): void {
    this.innerItems = [];

    if (this.items && Array.isArray(this.items)) {
      const keyProperty = this.determineKeyProperty();
      const valueProperty = this.determineValueProperty();
      const bindToObject = this.bindToObject === true || this.bindToObject === '';

      for (const item of this.items) {
        if (typeof item === 'string') {
          const translate = {
            key: item,
            value: item
          };
          this.innerItems.push(translate);
        } else {
          const translate = {
            key: bindToObject ? item : item[keyProperty],
            value: item[valueProperty]
          };
          this.innerItems.push(translate);
        }
      }

      return;
    }

    if (this.items) {
      // tslint:disable-next-line: forin
      for (const key in this.items) {
        const translate = {
          key: this.items[key],
          value: key
        };
        this.innerItems.push(translate);
      }

      return;
    }
  }

  innerChange(item): void {
    if (this.type === 'checkbox' && this.multi && item && Array.isArray(this.model)) {
      const index = this.model.indexOf(item);
      if (index === -1) {
        this.model.push(item);
      } else {
        this.model.splice(index, 1);
      }
    }

    this.onChange(this.model);
  }

  // ControlValueAccessor - Write initial value into component.
  writeValue(value: any): void {
    if (value !== this.model) {
      this.model = value;
      this.onValidateChange();
    }
  }

  blurred(): void {
    this.onTouched();
    this.onValidateChange();
  }

  // ControlValueAccessor - Parent form changes disabled state.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // ControlValueAccessor - Parent form registers on change listener (is dirty)
  registerOnChange(fn): void {
    this.onChange = fn;
  }

  // ControlValueAccessor - Parent form registers on touched listener (is touched)
  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  // Validator - Parent form registers on validator change listener
  registerOnValidatorChange(fn): void {
    this.onValidateChange = fn;
  }

  // Validator - Parent form detects need to validate.
  validate(control: AbstractControl): ValidationErrors | null {
    let errors: ValidationErrors = null;

    for (const k in this.form?.controls) {
      if (this.form.controls[k]) {
        const c = this.form.controls[k];
        if (c.errors) {
          errors = errors ?? {};

          for (const ck in c.errors) {
            if (c.errors[ck]) {
              if (!errors[ck]) {
                errors[ck] = c.errors[ck];
              }
            }
          }
        }
      }
    }

    return errors;
  }
}
