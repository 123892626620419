<div fxFill fxLayout="column">
  <span>
    Please review the details of the special open enrollment request and input the appropriate effective date for changes. For assistance in determining what date to use for this special open
    enrollment event, refer to the special open enrollment matrix
    <a target="_blank" href="https://www.hca.wa.gov/assets/pebb/pebb-policy-addendum-45-2A-soe-matrix-2021.pdf">(addendum 45-2a)</a>.
    <br />
    <br />
    For additional questions contact Outreach and Training by phone at 1-800-700-1555 or via <a target="_blank" href="https://www.fuzeqna.com/perspay/ext/kbsearch.aspx">FUZE</a>.
    <br />
    <br />
</span>
  <div fxLayout.lt-sm="column" fxLayout="row" >
    <form  fxLayoutAlign="space-between" #coverageDateForm="ngForm">
      <div fxFlex="30" fxLayout="column">
        <date-picker
          #coverageEffectiveDate="ngModel"
          name="coverageEffectiveDate"
          label="Coverage Effective Date"
          required
          enforceLowerLimit
          onlyAllowFirstOfMonth
          [(ngModel)]="soe.coverageEffectiveDate"></date-picker>
        <div *ngIf="coverageDateForm.controls.coverageEffectiveDate?.errors?.firstOfMonth" class="alert alert-danger">Effective date must be the first of a month.</div>
        <div *ngIf="coverageDateForm.controls.coverageEffectiveDate?.invalid && (coverageDateForm.controls.coverageEffectiveDate.dirty) && coverageDateForm.controls.coverageEffectiveDate.errors?.ngbDate?.minDate" class="alert alert-danger">
          The date entered is less than the current lower limit date.  Do not proceed with making changes with an alternative date and contact Outreach and Training for assistance.
        </div>
        <div *ngIf="coverageDateForm.invalid && (coverageDateForm.dirty) && coverageDateForm.errors?.ngbDate?.maxDate" class="alert alert-danger">
          The future date entered is not allowed based on the current invoicing schedule.
        </div>
      </div>
      <div fxFlex="65">
        <span>
          For any event other than newborn/adoption, enter the first day of the month following the later of: The date of the SOE event or the date the SOE request was submitted/received. IF the later
          of the two dates is the first day of the month, enter that date. If adding a newborn or newly adopted child, enter the first day of the month of the event.
          <br />
          Note: If coverages are being removed due to this event, coverage will end the last day of the previous month.
        </span>
      </div>
    </form>
  </div>

  <div class="mgtp-small" fxLayout="row" fxLayoutAlign="space-around">
    <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
    <button [disabled]="!soe?.coverageEffectiveDate" (click)="submitEffectiveDate()" class="btn btn-primary">Continue</button>
  </div>
</div>
