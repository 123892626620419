// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Export834TransactionSet from '../models/export834TransactionSet';


@Injectable({
  providedIn: 'root'
})
export class Export834TransactionSetService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  fetchErroredTransactions(): Observable<Export834TransactionSet[]> {
    return this.http
      .get<Export834TransactionSet>(`${env.apiUrl}/export834TransactionSet`)
      .pipe(map(x => this.commonService.createObjects(Export834TransactionSet, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  // not now but may be a need later
  createTransaction(transaction: Export834TransactionSet): Observable<Export834TransactionSet> {
    return this.http
      .post<Export834TransactionSet>(`${env.apiUrl}/export834TransactionSet`, transaction)
      .pipe(map(x => this.commonService.createObject(Export834TransactionSet, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateTransaction(transaction: Export834TransactionSet, includeDepTransactions: boolean = false): Observable<Export834TransactionSet[]> {
    transaction.errorObject = null;
    return this.http
      .put<Export834TransactionSet>(`${env.apiUrl}/export834TransactionSet/${transaction.export834TransactionSetId}?includeDepTransactions=${includeDepTransactions}`, transaction)
      .pipe(map(x => this.commonService.createObjects(Export834TransactionSet, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteTransaction(transaction: Export834TransactionSet): Observable<Export834TransactionSet> {
    return this.http
      .delete<Export834TransactionSet>(`${env.apiUrl}/export834TransactionSet/${transaction.export834TransactionSetId}`)
      .pipe(map(x => this.commonService.createObject(Export834TransactionSet, x)))
      .pipe(catchError(this.commonService.handleError));
  }

}
