export default class PlanType {
  planTypeId: string;
  planTypeCode: string;
  planTypeName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(planType?) {
    if (planType) {
      this.planTypeId = planType.planTypeId;
      this.planTypeCode = planType.planTypeCode;
      this.planTypeName = planType.planTypeName;
      this.obsoleteFlag = planType.obsoleteFlag;
      this.obsoleteDate = planType.obsoleteDate ? new Date(planType.ObsoleteDate) : null;
    }
  }

}
