export default class Salary {
  salaryId: string;
  employmentId: string;
  monthlySalary: number;
  effectiveStartDate: Date;
  effectiveEndDate: Date;

  constructor(salary?) {
    if (salary) {
      this.salaryId = salary.salaryId;
      this.employmentId = salary.employmentId;
      this.monthlySalary = salary.monthlySalary;
      this.effectiveStartDate = salary.effectiveStartDate;
      this.effectiveEndDate = salary.effectiveEndDate;
    }
  }
}
