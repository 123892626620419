export default class MedicareOption {
    medicareOptionId: string;
    medicareOptionName: string;
    obsoleteFlag: boolean;
    obsoleteDate: Date;

    constructor(medicareOption?) {
        if (medicareOption) {
            this.medicareOptionId = medicareOption.medicareOptionId;
            this.medicareOptionName = medicareOption.medicareOptionName;
            this.obsoleteFlag = medicareOption.obsoleteFlag;
            this.obsoleteDate = medicareOption.obsoleteDate ? new Date(medicareOption.obsoleteDate) : null;
        }
    }
}