<div class="modal-header">
  <h4 class="modal-title">Unsaved Changes</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>You have unsaved changes. Are you sure you want to leave this page?</p>
</div>
<div fxLayoutAlign="space-between" class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(true)">Leave Page</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">Stay On Page</button>
</div>
