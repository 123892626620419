import MemberMedicare from 'src/app/models/memberMedicare';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
import { PDFComponent } from './../../../shared/components/pdf/pdf.component';
import { SpinnerOverlayService } from './../../../../services/spinnerOverlay.service';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
// ng
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';

// ext
import { faClipboardCheck, faCheck } from '@fortawesome/free-solid-svg-icons';
import { find, cloneDeep, map, some, get } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import Milestone from 'src/app/models/milestone';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Subscriber from 'src/app/models/subscriber';
import EmailUpdate from 'src/app/models/emailUpdate';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { SoeService } from 'src/app/services/soe.service';
import StepConfig from 'src/app/models/stepConfig';
import { env } from 'src/env/development';
import PlanType from 'src/app/models/planType';
import { lastValueFrom } from 'rxjs';
import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { DependentService } from 'src/app/services/dependent.service';
import UserType from 'src/app/models/userType';
import Member from 'src/app/models/member';
import Ethnicity from 'src/app/models/ethnicity';
import Enrollment from 'src/app/models/enrollment';
import { MemberService } from 'src/app/services/member.service';
import ApplicationSetting from 'src/app/models/applicationSettting';
import { AdminService } from 'src/app/services/admin.service';

@UntilDestroy()
@Lookups(LookupType.SpecialOpenEnrollmentVerificationStatus, LookupType.Ethnicity, LookupType.ApplicationSetting)
@Component({
  selector: 'confirmation',
  templateUrl: 'confirmation.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @ViewChild('pdf') coveragePDF: PDFComponent;
  subscriber: Subscriber;
  enrollmentPeriod: EnrollmentPeriod;
  confirmationMilestone: Milestone;
  subscriberSummary: SubscriberEffectiveSummary;
  documentTitleText = 'Summary of coverage elections';
  documentHeaderText =
    'This is a summary of your coverage elections with the Health Care Authority. This is not a statement of insurance. Changes to elections can be made through Benefits 24/7 during open enrollment or special open enrollment. '
  enrolledHeader = '';
  step = 0;
  icons = {
    faCheck,
  };
  confirmButtonText = 'Back to dashboard';
  hasBackToDashButton = false;
  userHasReadLegal = true;
  steps: StepConfig[];
  stepData = [];
  hasAdditionalBenefits = false;
  isReadOnly = false;
  isSOE = false;
  currentSOE: SpecialOpenEnrollment;
  soeSubmitted = false;
  specialOpenEnrollmentVerificationStatuses: SpecialOpenEnrollmentVerificationStatus[];
  applicationSettings: ApplicationSetting[];
  isDeathOrDivorceSoe = false;
  isHcaAdmin = false;
  within60Days: boolean = null;
  displayRaceEthnicity = false;
  ethnicities: Ethnicity[];
  subscriberEth: string[];
  dependentEth: string[];
  medicareDEnabled = false;

  constructor(
    private subscriberService: SubscriberService,
    private enrollmentPeriodService: EnrollmentPeriodService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerOverlayService,
    private soeService: SoeService,
    private dependentService: DependentService,
    private memberService: MemberService,
    private adminService: AdminService
  ) {}

  async ngOnInit(): Promise<void> {
    this.medicareDEnabled = await lastValueFrom(this.adminService.getFeatureFlag('FEATURE_ENABLE_MEDICARE_D'));

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.isHcaAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
      this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);
      // if any sort of admin, skip requirement to scroll legalese.
      this.subscriber = data.subscriber;
      if (this.subscriber.isSebb) {
        this.documentHeaderText =
          'This is a summary of your coverage elections with the Health Care Authority. This is not a statement of insurance. Changes to elections can be made through Benefits 24/7 during open enrollment or special open enrollment.';
      }
      this.enrollmentPeriod = data.enrollmentPeriod;
      if (this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SOE') {
        this.isSOE = true;
      }
      if (this.isSOE) {
        this.currentSOE = find(this.subscriber.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => soe.specialOpenEnrollmentId === this.enrollmentPeriod.enrollmentPeriodId);
        this.isDeathOrDivorceSoe = this.currentSOE.specialOpenEnrollmentType.specialOpenEnrollmentTypeName === 'Death or Divorce';
      }
      this.confirmationMilestone = find(this.enrollmentPeriod.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation');

      this.subscriberSummary = new SubscriberEffectiveSummary(this.subscriber, this.enrollmentPeriod, false, this.isSOE, this.currentSOE?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName, null, this.medicareDEnabled);
      this.hasAdditionalBenefits = some(this.enrollmentPeriod.electablePlanTypes, (ept: PlanType) => some(env.codesOnSecondPage, (code: string) => code === ept.planTypeCode));
      this.refreshSubscriber(this.subscriberSummary.memberId);
      this.ethnicities = data.lookups.ethnicities;
      this.applicationSettings = data.lookups.applicationSetting;
      if (this.isSOE) {
        this.specialOpenEnrollmentVerificationStatuses = data.lookups.specialOpenEnrollmentVerificationStatus;

        if (this.isDeathOrDivorceSoe) {
          // hasAdditionalBenefits ? !isReadOnly && step === 2 : !isReadOnly && step === 1
          this.step = this.hasAdditionalBenefits ? 2 : 1;
        }
      }
      this.displayRaceEthnicity = this.subscriberOrDependentsAreMedicareEnrolled() && some(this.subscriber.enrollments, (e: Enrollment) => env.medicareAdvantagePlanCodes.includes(e.plan?.planCode)) && some(this.applicationSettings, (as) => as.applicationSettingCode === 'MAPD' && as.response?.responseCode == 'Y');
  
    });
  }

  ngOnDestroy(): void {}

  updateStep(s: number): void {
    if (this.isDeathOrDivorceSoe && s === 1) {
      this.router.navigate([`../../upload/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
    } else {
      this.step = s;
    }
    // this.router.navigate([`../../confirm/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
  }

  navToDashboard(): void {
    this.router.navigate([`../../`], { relativeTo: this.route });
  }

  downloadCoveragePDF(): void {
    this.coveragePDF.pdfElement.saveAs('CoverageElections.pdf');
  }

  

  async putEmailAddress(e: EmailUpdate): Promise<void> {
    this.spinnerService.show();
    try {
      const updatedEmail = await lastValueFrom(this.subscriberService.updateSubscriberEmail(e));
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong updating your email preferences! please try again', 'error', 5000);
      console.log(err);
    }
    this.spinnerService.hide();
    this.coreService.popMessage('You have successfully updated your email preferences!', 'success', 5000);
  }

  confirm(): void {
    this.spinnerService.show();
    if(this.subscriberEth) {
        this.memberService.createMemberEthnicities(this.subscriber.memberId, this.subscriberEth)
        .pipe(untilDestroyed(this)).subscribe();
    }
    if(this.dependentEth) {
      this.memberService.createMemberEthnicities(this.subscriber.members[1]?.memberId, this.dependentEth)
      .pipe(untilDestroyed(this)).subscribe();
    }
    this.enrollmentPeriodService
      .createMilestoneCompletion(this.subscriber.memberId, this.confirmationMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId)
      .pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
        this.enrollmentPeriod = e;
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
        this.spinnerService.hide();
        this.step++;

        if (!this.isDeathOrDivorceSoe) {
          this.coreService.popMessage(`You have successfully completed your ${this.enrollmentPeriod.enrollmentPeriodType.enrollmentPeriodTypeName} elections!`, 'success', 8000);
        }
      });
    this.subscriber.refetch = true;
    this.coreService.setSubscriber(this.subscriber);
  }

  async refreshSubscriber(memberId: string): Promise<Subscriber> {
    this.spinnerService.show();
    let sub;
    if (!this.isSOE){
      sub = await lastValueFrom(this.subscriberService.getSubscriberById(memberId));
    } else {
      sub = await lastValueFrom(this.subscriberService.getSubscriberWithSOE(memberId, this.currentSOE.specialOpenEnrollmentId));
    }
    this.subscriber = cloneDeep(sub);
    this.coreService.setSubscriber(this.subscriber);
    this.subscriberSummary = new SubscriberEffectiveSummary(this.subscriber, this.enrollmentPeriod, false, this.isSOE, this.currentSOE?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName, null, this.medicareDEnabled);
    this.spinnerService.hide();
    return sub;
  }

  mapConfigsToSteps(steps): StepConfig[] {
    return map(steps, (s) => new StepConfig(s));
  }

  async submitSOERequest(): Promise<any> {
    this.currentSOE.specialOpenEnrollmentVerificationStatusId =
        get(find(this.specialOpenEnrollmentVerificationStatuses, (s: SpecialOpenEnrollmentVerificationStatus) => s.specialOpenEnrollmentVerificationStatusCode === 'R'), 'specialOpenEnrollmentVerificationStatusId');
    const soe = this.currentSOE;
    soe.member = null;
    // delete soe.formJson;
    delete soe.documents;
    try {
      if (this.isDeathOrDivorceSoe && this.isHcaAdmin) {
        await lastValueFrom(this.dependentService
          .updateDeathOrDivorce60DayIndicator(this.subscriber.subscriberMemberId, this.subscriber.organization.organizationId, this.currentSOE.specialOpenEnrollmentId, this.within60Days));
      }

      const submittedSOE = await lastValueFrom(this.soeService.updateSpecialOpenEnrollment(this.subscriber.memberId, soe));
      this.coreService.forceDataRefresh();
      this.soeService.submitSOE();
      this.soeSubmitted = true;
    } catch (err) {
      console.log('err', err);
    }
  }

  subscriberOrDependentsAreMedicareEnrolled(): boolean {
    return this.subscriber?.memberMedicare?.medicarePartAEffectiveDate != null || some(this.subscriber.members, (d: Member) => d.memberMedicare?.medicarePartAEffectiveDate);
  }


  updateSubForEthnicities(e) {
    this.subscriberEth = e;

  }

  updateDepForEthnicities(e) {
    this.dependentEth = e;
  }

  getDisplayStep(base) {
    if(this.hasAdditionalBenefits || this.isReadOnly) {
      base +=1;
    }
    if(this.displayRaceEthnicity) {
      base +=1
    }
    return base
  }
}
