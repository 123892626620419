<div fxFlexFill fxLayoutAlign="center center" class="card state">
  <div class="card-body">
    <div fxLayout="column" class="card-title fxGap-tiny">
      <fa-icon class="hca-fontawesome-icon" [icon]="icon" size="3x"></fa-icon>
      <b *ngIf="milestone">Step {{ milestone.sortOrder }} - {{ milestone.milestoneName }} </b>
    </div>
    <div fxFlexFill>
      <ng-content> </ng-content>
    </div>
    <div *ngIf="!hideButtons && !hideButtonsOnSteps.includes(idx.toString())" class="mgtp-large" fxLayout="row" 
      [fxLayoutAlign]="idx > 0 ? 'space-between' : 'flex-end'">
      <button *ngIf="showCancel" (click)="emitCancel()" class="btn btn-primary">Cancel</button>
      <button *ngIf="!showCancel && idx > 0" (click)="emitPrev()" class="btn btn-primary">Previous</button>
      <button *ngIf="idx < confirmIndex" [disabled]="!conditionalNextMet && conditionalNextIdx === idx" (click)="emitNext()" class="btn btn-primary">Next</button>
      <button *ngIf="idx === confirmIndex && (!hasBackToDashButton || alwaysShowConfirmButtonOnLastStep)" [disabled]="!conditionalNextMet && conditionalNextIdx === idx" (click)="emitPutPostObject()" class="btn btn-primary">{{confirmButtonText}}</button>
    </div>
  </div>
</div>
<div *ngIf="!hideBackToDash" fxFlexFill fxLayout="row" fxLayoutAlign="center center">
  <button (click)="navToDashboard()" class="btn btn-secondary mgtp-medium-lg">Back to dashboard</button>
</div>

<!-- <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
  <button style="margin-top: 30px" (click)="navToDashboard()" class="btn btn-secondary">Back to dashboard</button>
</div> -->

<!-- WITH STEP CONFIG -->

<!-- <div *ngIf="steps" fxFlexFill fxLayoutAlign="center center" class="card state">
  <div class="card-body">
    <div fxLayout="column" class="card-title">
      <fa-icon class="hca-fontawesome-icon" [icon]="icon" size="3x"></fa-icon>
      <b *ngIf="milestone">Step {{ milestone.sortOrder }} - {{ milestone.milestoneName }} </b>
    </div>
    <div fxFlexFill>
      <ng-content> </ng-content>
    </div>
    <div *ngIf="!currentStep.hideButtons" style="margin-top: 40px" fxLayout="row" [fxLayoutAlign]="currentStep.idx > 0 && ((!currentStep.isConfirmationStep && currentStep.conditionalNextMet) || currentStep.isConfirmationStep) ? 'space-between' : 'flex-end'">
      <button *ngIf="currentStep.idx > 0" (click)="emitPrev()" class="btn btn-primary">{{currentStep.peviousButtonText}}</button>
      <button *ngIf="currentStep.idx !== confirmIndex && conditionalNextMet" (click)="emitNext()" class="btn btn-primary">{{currentStep.nextButtonText}}</button>
      <button *ngIf="currentStep.isConfirmationStep && hasBackToDashButton" (click)="navToDashboard()" class="btn btn-primary">I'm done, take me back to the dashboard</button>
      <button *ngIf="currentStep.isConfirmationStep && !hasBackToDashButton" (click)="emitPutPostObject()" class="btn btn-primary">{{currentStep.nextButtonText}}</button>
    </div>
  </div>
</div>
<div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
  <button style="margin-top: 30px" (click)="navToDashboard()" class="btn btn-secondary">Back to dashboard</button>
</div> -->