import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Employment from '../models/employment';
import Salary from '../models/salary';
import EmployeeRepresentedStatus from '../models/employeeRepresentedStatus';

@Injectable({
  providedIn: 'root'
})
export class EmploymentService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  getEmploymentById(employmentId: string, memberId: string): Observable<Employment> {
    return this.http.get<Employment>(`${env.apiUrl}/Member/${memberId}/employment/${employmentId}`)
    .pipe(map(x => this.commonService.createObject(Employment, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createEmployment(employment: Employment): Observable<Employment> {
    return this.http.post<Employment>(`${env.apiUrl}/Member/${employment?.memberId}/employment`, employment)
    .pipe(map(x => this.commonService.createObject(Employment, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateEmployment(employment: Employment): Observable<Employment> {
    return this.http.put<Employment>(
      `${env.apiUrl}/Member/${employment?.memberId}/employment/${employment?.employmentId}`, employment)
    .pipe(map(x => this.commonService.createObject(Employment, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createSalary(employment, salary): Observable<Salary> {
    return this.http.post<Salary>(
      `${env.apiUrl}/Member/${employment?.memberId}/employment/${employment?.employmentId}/Salaries`, salary)
      .pipe(map(x => this.commonService.createObject(Salary, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSalary(employment, salary): Observable<Salary> {
    return this.http.put<Salary>(
      `${env.apiUrl}/Member/${employment?.memberId}/employment/${employment?.employmentId}/Salaries/${salary.salaryId}`, salary)
      .pipe(map(x => this.commonService.createObject(Salary, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createEmployeeRepresentedStatus(employment, employeeRepresentedStatus): Observable<EmployeeRepresentedStatus> {
    return this.http.post<EmployeeRepresentedStatus>(
      `${env.apiUrl}/Member/${employment?.memberId}/employment/${employment?.employmentId}/representedStatus`, employeeRepresentedStatus)
      .pipe(map(x => this.commonService.createObject(EmployeeRepresentedStatus, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateEmployeeRepresentedStatus(employment, employeeRepresentedStatus): Observable<EmployeeRepresentedStatus> {
    return this.http.put<EmployeeRepresentedStatus>(
      `${env.apiUrl}/Member/${employment?.memberId}/employment/${employment?.employmentId}/representedStatus/
        ${employeeRepresentedStatus?.employeeRepresentedStatusId}`, employeeRepresentedStatus)
      .pipe(map(x => this.commonService.createObject(EmployeeRepresentedStatus, x)))
      .pipe(catchError(this.commonService.handleError));
  }

}
