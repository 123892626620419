<router-outlet (deactivate)="deactivate($event)" id="hcaAdminOutlet">
    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
        <ul fxFlexLayout="row" style="margin-left: 15px;" class="nav nav-tabs admin-tab-group">
            <li class="nav-item">
              <strong style="cursor: pointer" class="nav-link" routerLinkActive="active" routerLink="syncFileErrors/">
              Sync file errors</strong>
            </li>
            <li class="nav-item">
              <strong style="cursor: pointer" class="nav-link" routerLinkActive="active" routerLink="carrierTransactions/">
                834 transactions</strong>
            </li>
            <li class="nav-item" *ngIf="acAvailableToUser()">
              <strong style="cursor: pointer" class="nav-link" routerLinkActive="active" routerLink="accountCorrections/">
                Account Corrections</strong>
            </li>
        </ul>
    </div>
</router-outlet>




