/**
 * Handles the initial claim for subscriber accounts. Users are routed here from auth
 * component if initial call to /systemUser returns a 404.
 */

// ng
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ext
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { filter, forEach, some, cloneDeep, includes, sortBy, any } from 'lodash';
import * as dayjs from 'dayjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import Claim from 'src/app/models/claim';
import SystemUser from 'src/app/models/user';
import Question from 'src/app/models/question';
import SimpleSubscriber from 'src/app/models/simpleSubscriber';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';

import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import SecurityQuestionAnswer from 'src/app/models/securityQuestionAnswer';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';

@UntilDestroy()
@Lookups(LookupType.Question)
@Component({
  selector: 'pebb-claim',
  templateUrl: 'claim.component.html',
  styleUrls: [],
  providers: [],
})
export class ClaimComponent implements OnInit {
  icons = {
    faCalendar
  };
  claim: Claim = new Claim();
  step = '1';
  memberInfo: SimpleSubscriber[];
  claimedSystemUser: SystemUser;
  claimedSubscriber: SimpleSubscriber;
  securityQuestions: Question[];
  claimFormSubmitted = false;
  claimAcccountFormSubmitted = false;

  constructor(private authService: AuthService, private spinnerService: SpinnerOverlayService, private route: ActivatedRoute, private router: Router, private coreService: CoreService) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe((d) => {
      if (d && d.lookups) {
        this.securityQuestions = filter(d.lookups.question, ['questionName', 'Security']);
      }
    });
  }

  async submitClaimInformation(): Promise<void> {
    this.claimFormSubmitted = true;
    let foundMemberInformation: SimpleSubscriber[];
    this.spinnerService.show();
    const claimClone = cloneDeep(this.claim);
    claimClone.dateOfBirth = dayjs(claimClone.dateOfBirth).format('YYYY-MM-DD');
    try {
      foundMemberInformation = await lastValueFrom(this.authService.submitClaim(claimClone));
    } catch (err) {
      this.spinnerService.hide();
      this.coreService.popMessage('Something went wrong processing your request, please re-submit your information, or contact your district administrator for assistance.', 'error', 10000);
    }
    this.spinnerService.hide();
    if (foundMemberInformation && foundMemberInformation.length >= 1) {
      // more than one record returned and full ssns do not match exactly, error out.
      if(foundMemberInformation?.length > 1 && some(foundMemberInformation, (s: SimpleSubscriber) => some(foundMemberInformation, (s2: SimpleSubscriber) => s2.fullSocial != s.fullSocial))) {
        this.coreService.popMessage('More than one record found with matching information, contact your benefits administrator.', 'error', 10000);
      }else {
        this.step = '2';
        this.memberInfo = sortBy(foundMemberInformation, (m: SimpleSubscriber) => m.systemUserId);
      }
    } else if (foundMemberInformation && foundMemberInformation.length === 0) {
      this.coreService.popMessage('No account found matching the information provided, please contact your personnel, payroll, or benefit office.', 'error', 10000);
    }
    this.claimFormSubmitted = false;
  }

  async submitClaimSubscriber(s: SimpleSubscriber): Promise<void> {
    this.claimAcccountFormSubmitted = true;
    await this.claimSubscriberRecord(s);
    this.claimAcccountFormSubmitted = false;
  }

  async claimSubscriberRecord(s: SimpleSubscriber): Promise<void> {
    if (some(this.memberInfo[0].securityQuestions, (sq) => !sq.response)) {
      this.coreService.popMessage(`Please answer all security questions and re-submit.`, 'error', 5000);
      return;
    } else if (some(this.memberInfo[0].securityQuestions, (sq) => sq.response.length < 4 || sq.response.length > 60)) {
      this.coreService.popMessage(`Security question answers must be between 4 and 60 characters long.`, 'error', 5000);
      return;
    } else {
      this.claimedSubscriber = s;
      this.spinnerService.show();
      let systemUser: SystemUser;
      try {
        // systemuser already exists so call to reclaim with questions/answers
        this.claim.memberIds = this.memberInfo.map(m => m.memberId);
        this.claim.securityQuestionResponses = [];
        forEach(this.memberInfo[0].securityQuestions, (q) => {
          const answeredQuestion = new SecurityQuestionAnswer();
          answeredQuestion.memberId = s.memberId;
          answeredQuestion.questionId = q.questionId;
          answeredQuestion.response = q.response;
          this.claim.securityQuestionResponses.push(answeredQuestion);
          this.claim.securityQuestionResponses[q.questionId] = q.response;
          this.claim.memberId = s.memberId;
        });
        if (this.memberInfo[0].systemUserId) {
          this.claim.questionAnswers = {};
          forEach(this.memberInfo[0].securityQuestions, (q) => {
            this.claim.questionAnswers[q.questionId] = q.response;
            this.claim.memberId = s.memberId;
          });
          systemUser = await lastValueFrom(this.authService.reClaimRecord(s.memberId, this.claim));
        } else {
          // systemuser does not exist, just call claim but also call to save security questions
          systemUser = await lastValueFrom(this.authService.claimRecord(s.memberId, this.claim));
        }
        this.coreService.setSystemUser(systemUser);
      } catch (err) {
        console.log(err);
        this.spinnerService.hide();
        if (err.error && includes(err.error, 'incorrect')) {
          this.coreService.popMessage(`Responses to the indicated security questions were incorrect, please try again.`, 'error', 5000);
        } else if (err.error) {
          this.coreService.popMessage(`${err.error}`, 'error', 5000);
        }
      }
      this.spinnerService.hide();
      if (systemUser && systemUser.systemUserId) {
        this.claimedSystemUser = systemUser;
        this.router.navigate([`/termsofuse/${this.claimedSystemUser.systemUserId}`]);
      }
    }
  }

  filterQuestions(questions: Question[], selectedQuestionId: string): Question[] {
    return questions.filter((q) => !some(this.memberInfo[0].securityQuestions, ['questionId', q.questionId]) || q.questionId === selectedQuestionId);
  }
}
