import SubscriberComposite from './subscriberComposite';
import Employment from './employment';
import Organization from './organization';
import Subscriber from './subscriber';
import ExternalEnrollment from './externalEnrollment';
import SelfPayOrigin from 'src/app/models/selfPayOrigin';
export default class SubscriberTransfer {
  memberId: string;
  socialSecurityNumber: string;
  eligibilityDate: Date;
  mostRecentEmployment: Employment;
  isEligibleForAutomaticTransfer: boolean;
  needsToBeAdjustedForTransfer: boolean;
  hasExistingCoverageForThisDate: boolean;
  hasExistingCoverage: boolean;
  hasExistingDependentCoverageForThisDate: boolean;
  agencyName: string;
  agencyCode: string;
  isSubscriberInd: boolean;
  organization ?: Organization;
  lossOfEligibilityDate ?: Date;
  lastName: string;
  firstName: string;
  dateUsedForCalculation ?: Date;
  subscriber: Subscriber;
  externalEnrollments: ExternalEnrollment[];
  planTypeIds: string[];
  sebbIsNewlyEligibleBeforeFirstDayOfSchool? = false;
  memberSelfPayOrigin: SelfPayOrigin;
  isEligibileForRetireeTransfer: boolean = false;
  latestIsTermAsNeverEligible = false;

  constructor(subscriber?) {
    if (subscriber) {
      this.isSubscriberInd = subscriber.isSubscriberInd;
      this.memberId = subscriber.memberId;
      this.eligibilityDate = subscriber.eligibilityDate;
      this.lossOfEligibilityDate = subscriber.lossOfEligibilityDate ? new Date(subscriber.lossOfEligibilityDate) : null;
      this.mostRecentEmployment = subscriber.mostRecentEmployment ? new Employment(subscriber.mostRecentEmployment) : null;
      this.isEligibleForAutomaticTransfer = subscriber.isEligibleForAutomaticTransfer;
      this.needsToBeAdjustedForTransfer = subscriber.needsToBeAdjustedForTransfer;
      this.hasExistingCoverageForThisDate = subscriber.hasExistingCoverageForThisDate;
      this.hasExistingCoverage = subscriber.hasExistingCoverage;
      this.hasExistingDependentCoverageForThisDate = subscriber.hasExistingDependentCoverageForThisDate;
      this.agencyName = subscriber.agencyName;
      this.agencyCode = subscriber.agencyCode;
      this.organization = subscriber.organization ? new Organization(subscriber.organization) : null;
      this.firstName = subscriber.firstName;
      this.lastName = subscriber.lastName;
      this.socialSecurityNumber = subscriber.socialSecurityNumber;
      this.dateUsedForCalculation = subscriber.dateUsedForCalculation;
      this.subscriber = subscriber.subscriber ? new Subscriber(subscriber.subscriber) : null;
      this.externalEnrollments = subscriber.externalEnrollments ? subscriber.externalEnrollments.map((r) => new ExternalEnrollment(r)) : [];
      this.memberSelfPayOrigin = subscriber.memberSelfPayOrigin;
      this.isEligibileForRetireeTransfer = subscriber.isEligibileForRetireeTransfer;
      this.latestIsTermAsNeverEligible = subscriber.latestIsTermAsNeverEligible ?? false;
    }
  }
}
