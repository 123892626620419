import { Component, ViewEncapsulation, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'hca-search',
  templateUrl: 'search.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class SearchComponent implements OnInit {
  @Input() placeholder: string;
  public queryString = '';
  @Output() searchSubmitted: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
  }


  search() {
    this.searchSubmitted.emit(this.queryString);
  }
}
