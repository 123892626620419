import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet, ActivationStart } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SystemUser from '../../../../models/user';
import Organization from 'src/app/models/organization';
import { CoreService } from 'src/app/services/core.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { sortBy } from 'lodash';
import Subscriber from 'src/app/models/subscriber';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { resolveTripleslashReference } from 'typescript';
import { env } from 'src/env/development';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.County, LookupType.Country, LookupType.AddressType, LookupType.PhoneNumberType, LookupType.ContactType)
@Component({
  selector: 'organization-management',
  templateUrl: 'organizationManagement.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class OrganizationManagementComponent implements OnInit {
  icons = {
    faArrowLeft
  };
  systemUser: SystemUser;
  gridData: Organization[] = [];
  organizations: Organization[] = [];
  selectedOrganization: Organization;
  organizationSelectedForManagement: Organization;
  addingOrganization = false;
  showSearchResults = true;
  agencyFilter: string = "";
  env = env;
  gridColumns: { [k: string]: string | {} }[] = [
    {field: 'organizationName', title: 'Organization name', format: 'string'},
    {field: 'organizationCode', title: 'HCA agency #', format: 'string'},
    {field: 'organizationTypeName', title: 'Organization type', format: 'string'},
    {field: 'effectiveDate', title: 'Effective date', format: { date: 'mm/dd/yyyy' }, filter: 'date'}
  ];
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public coreService: CoreService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      data => {
        this.organizations = data.organizations;
        this.gridData = sortBy(this.organizations, 'organizationName');
        this.systemUser = data.user;
      },
      err => console.log(err)
      );
  }

  onSearchSubmitted(queryString: string): void {
    this.gridData = this.organizations.filter(s =>
      (
        !queryString || 
        (s.organizationName && s.organizationName.toLowerCase().includes(queryString.toLowerCase())) ||
        (s.organizationCode && s.organizationCode.toLowerCase().includes(queryString.toLowerCase())) ||
        (s.organizationTypeName && s.organizationTypeName.toLowerCase().includes(queryString.toLowerCase()))
      ) &&
      (this.agencyFilter=="" || s.agency.agencyCode==this.agencyFilter)
    );
  }

  onOrganizationSelected(e: Organization): void {
    this.selectedOrganization = e;
  }

  manageOrganization(): void {
    this.coreService.setSubscriber(new Subscriber());

    this.organizationSelectedForManagement = this.selectedOrganization;
    this.addingOrganization = false;
    this.showSearchResults = false;
    this.router.navigate([`dashboard/perspay/${this.organizationSelectedForManagement.organizationId}`], {relativeTo: this.route});
  }

  addOrganization(): void {
    this.addingOrganization = true;
    this.showSearchResults = false;
    this.router.navigate([`add`], {relativeTo: this.route});
  }

  async backToSearchResults(): Promise<void> {
    this.showSearchResults = true;
    this.addingOrganization = false;
    this.organizationSelectedForManagement = null;

    // router nav needs to be added this.router.navigate(['..'], {relativeTo: this.route});
    this.organizations = await lastValueFrom(this.organizationService.getOrganizations());
    this.gridData = sortBy(this.organizations, 'organizationName');

    window.scroll(0, 0);
  }

  deactivate(e): void {
    if (e.resetParent) {
      this.backToSearchResults();
    }
  }
}
