import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import Selfpay from 'src/app/models/selfPay';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { NgForm } from '@angular/forms';
import { CoreService } from 'src/app/services/core.service';
import { env } from 'src/env/development';


@Component({
  selector: 'selfpay-effective-date',
  templateUrl: 'selfpay.effectiveDate.component.html',
  styleUrls: [],
  providers: [],
})
export class SelfpayEffectiveDateComponent implements OnInit {
  @Input() selfpay: Selfpay;
  @Output() saveSelfpay: EventEmitter<void> = new EventEmitter<void>();
  @Output() previous: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('coverageDateForm') coverageDateForm: NgForm;
  icons = {
    faCalendar,
  };
  constructor(private coreService: CoreService) {}
  ngOnInit(): void {
    if ((env.cobraSelfPayTypeCodes.find(o=>o === this.selfpay.selfPayType.selfPayTypeCode) || env.lwopSelfPayTypeCodes.find(o=>o === this.selfpay.selfPayType.selfPayTypeCode)) &&
      this.selfpay.lossOfEligibilityDate && !this.selfpay.coverageEffectiveDate) {
        // COBRA or LWOP with loss of eligibility date set.  Set coverage effective date equal to 1st of next month
        this.selfpay.coverageEffectiveDate = new Date(this.selfpay.lossOfEligibilityDate.getFullYear(),this.selfpay.lossOfEligibilityDate.getMonth()+1,1);
    }
  }

  submitEffectiveDate(): void {
    if(this.selfpay.coverageEffectiveDate < this.selfpay.member?.memberSelfPayOrigin?.agencyEffectiveStartDate){
      this.coverageDateForm.controls[`coverageEffectiveDate`].setErrors({ ngbDate: {minDate: true }});
      return;
    }
    if (!dayjs(this.selfpay.coverageEffectiveDate).isSame(dayjs(this.selfpay.coverageEffectiveDate).startOf('month'), 'day')) {
      this.coverageDateForm.controls.coverageEffectiveDate.setErrors({ firstOfMonth: true });
      return;
    }
    if (!this.coverageDateForm.controls.coverageEffectiveDate.valid) {
      return;
    }
    this.saveSelfpay.emit();
  }

  getMaxDate() {
    // get date
    const now = dayjs();
    const date23 = dayjs().startOf('month').add(22, 'day');
    let previousFriday;
    // if less than 23rd
    if (now.isSameOrBefore(date23, 'day')) {
      // is 23rd sat/sun
      if (date23.day() === 6) {
        // yes - get prior friday
        previousFriday = date23.subtract(1, 'day');
      } else if (date23.day() === 0) {
        previousFriday = date23.subtract(2, 'day');
      }
      if (previousFriday) {
        // date is before friday?
        if (now.isSameOrBefore(previousFriday, 'day')) {
          // upper limit is first next month
          return now.add(1, 'month').startOf('month').toDate();
        } else if (now.isAfter(previousFriday)) {
          // upper limit is first next next month
          return now.add(2, 'month').startOf('month').toDate();
        }
        // not dictated by previous friday, just vanilla before 23
      } else {
        return now.add(1, 'month').startOf('month').toDate();
      }
    } else {
      return now.add(2, 'month').startOf('month').toDate();
    }
  }

  public markAllControlsAsTouched(): void {
    for (const i in this.coverageDateForm.controls) {
      if (this.coverageDateForm.controls[i]) {
        this.coverageDateForm.controls[i].markAsTouched();
      }
    }
  }

}
