import OrganizationDocument from './organizationDocument';
import OrganizationAddress from './organizationAddress';
import OrganizationContact from './organizationContact';
import OrganizationType from './organizationType';
import BenefitSetup from './benefitSetup';
import Agency from './agency';
import OrganizationMemberType from './organizationMemberType';
import OrganizationSubAgency from './organizationSubAgency';

export default class Organization {
  organizationId: string;
  agencyId: string;
  agency?: Agency;
  organizationCode: string;
  organizationName: string;
  organizationTypeId: string;
  organizationTypeName: string;
  memberTypeId: string;
  benefitSetupId: string;
  benefitSetup: BenefitSetup;
  hrmsInd: boolean;
  rateStructureId: string;
  rateSurchargeInd: string;
  federalTaxIdNumber: string;
  disallowLoginInd: boolean;
  contractNumber: string;
  addressIsSameAsPhysicalInd: boolean;
  organizationDocuments: OrganizationDocument[];
  organizationAddresses: OrganizationAddress[];
  organizationContacts: OrganizationContact[];
  effectiveDate: Date;
  terminationDate: Date;
  notes: string;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  organizationMemberTypes: OrganizationMemberType[];
  ospiDistrictNumber: string;
  localEligibleBenefits: boolean;
  disableOptionalLTD: boolean;
  organizationSubAgencies: OrganizationSubAgency[];
  mailingIsSameAsPhysicalInd: boolean;
  billingIsSameAsPhysicalInd: boolean;
  shippingIsSameAsPhysicalInd: boolean;
  homeAgencyInd: boolean;
  demographicUpdatesAllowed: boolean;

  constructor(organization?) {
    if (organization) {
      this.agencyId = organization.agencyId;
      this.disallowLoginInd = organization.disallowLoginInd;
      this.organizationId = organization.organizationId;
      this.organizationCode = organization.organizationCode;
      this.organizationName = organization.organizationName;
      this.federalTaxIdNumber = organization.federalTaxIdNumber;
      this.effectiveDate = organization.effectiveDate ? new Date(organization.effectiveDate) : null;
      this.terminationDate = organization.terminationDate ? new Date(organization.terminationDate) : null;
      this.createdDate = organization.createdDate;
      this.createdById = organization.createdById;
      this.notes = organization.notes;
      this.contractNumber = organization.contractNumber;
      this.addressIsSameAsPhysicalInd = organization.addressIsSameAsPhysicalInd;
      this.organizationDocuments = organization.organizationDocuments ? organization.organizationDocuments.map(sad => new OrganizationDocument(sad)) : [];
      this.organizationAddresses = organization.organizationAddresses ? organization.organizationAddresses.map(sa => new OrganizationAddress(sa)) : [];
      this.organizationContacts = organization.organizationContacts ? organization.organizationContacts.map(sc => new OrganizationContact(sc)) : [];
      this.organizationSubAgencies = organization.organizationSubAgencies ? organization.organizationSubAgencies.map(osa => new OrganizationSubAgency(osa)) : [];
      this.benefitSetup = organization.benefitSetup ? new BenefitSetup(organization.benefitSetup) : null;
      this.organizationTypeName = organization.organizationTypeName;
      this.organizationTypeId = organization.organizationTypeId;
      this.memberTypeId = organization.memberTypeId;
      this.benefitSetupId = organization.benefitSetupId;
      this.hrmsInd = organization.hrmsInd;
      this.rateStructureId = organization.rateStructureId;
      this.rateSurchargeInd = organization.rateSurchargeInd;
      this.agency = organization.agency ? new Agency(organization.agency) : null;
      this.organizationMemberTypes = organization.organizationMemberTypes;
      this.ospiDistrictNumber = organization.ospiDistrictNumber;
      this.localEligibleBenefits = organization.localEligibleBenefits;
      this.disableOptionalLTD = organization.disableOptionalLTD;
      this.mailingIsSameAsPhysicalInd = organization.mailingIsSameAsPhysicalInd;
      this.billingIsSameAsPhysicalInd = organization.billingIsSameAsPhysicalInd;
      this.shippingIsSameAsPhysicalInd = organization.shippingIsSameAsPhysicalInd;
      this.homeAgencyInd = organization.homeAgencyInd;
      this.demographicUpdatesAllowed = organization.demographicUpdatesAllowed;
    }
  }
}
