import { map } from 'lodash';
import SecurityQuestionAnswer from './securityQuestionAnswer';
import MemberType from './memberType';

export default class SimpleSubscriber {
  memberId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  birthDate: Date;
  deceasedDate: Date;
  SSN: string;
  emailAddress: string;
  languageId: string;
  fullName: string;
  employerName: string;
  memberType: string;
  fullMemberType: MemberType;
  subscriberMemberId: string;
  securityQuestions?: SecurityQuestionAnswer[];
  systemUserId?: string;
  isSubscriberInd: boolean;	
  isSelfPay: boolean;
  fullSocial: string;
  constructor(member?) {
    if (member) {
      this.memberId = member.memberId || null;
      this.firstName = member.firstName || null;
      this.middleName = member.middleName || null;
      this.lastName = member.lastName || null;
      this.suffix = member.suffix || null;
      this.birthDate = member.birthDate ? new Date(member.birthDate) : null;
      this.deceasedDate = member.deceasedDate ? new Date(member.deceasedDate) : null;
      this.SSN = 'XXX-XX-' + member.ssn?.slice(-4) || null;
      this.fullSocial = member.ssn;
      this.fullName = member.lastName ? member.lastName + ', ' + member.firstName : null;
      this.employerName = member.employerName;
      this.memberType = member.isSubscriberInd ? 'Subscriber' : 'Member';
      this.subscriberMemberId = member.subscriberMemberId || null;
      this.securityQuestions = map(member.questions, q => new SecurityQuestionAnswer(q));
      if (!this.securityQuestions.length) {
        for (let i = 0; i < 3; i++) {
          this.securityQuestions.push(new SecurityQuestionAnswer());
        }
      }
      this.fullMemberType = new MemberType(member.memberType);
      this.systemUserId = member.systemUserId ? member.systemUserId : null;
      this.isSubscriberInd = member.isSubscriberInd;
      this.isSelfPay = this.employerName?.startsWith("Self-Pay");
    }
  }
}
