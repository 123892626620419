<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card">
      <div class="card-body">
        <h2 class="mobile-text-centered mgtp-small mgbt-medium-sm">Subscriber History</h2>
        <div
            fxLayout="row"
            class="fxGap-medium"
            fxLayoutAlign="space-between"
            style="padding-bottom: 10px; height: 60px;"
          >
          <subscriber-search
            organizationId
            style="flex: 70;"
            (searchSubmitted)="onSearchSubmitted($event)"></subscriber-search>
        </div>
        <generic-grid
          (selectedRow)="onSubscriberSelected($event)"
          (deselectedRow)="onSubscriberDeselected()"
          [selectBy]="'memberId'"
          [selectable]="true"
          [selectedKeys]=""
          [pageable]="true"
          [pageSize]="20"
          [gridData]="subscriberSearchResults"
          [gridColumns]="searchResultColumns"
          [filterable]="true"
          >
        </generic-grid>

        <div *ngIf="selectedSubscriber" class="subscriber-history mgtp-medium" [class]="{ printing: printing }">
          <h2>Subscriber Details</h2>
          <h3>
            <div>{{selectedSubscriber.firstName}} {{selectedSubscriber.lastName}}</div>
            <div *ngIf="fullMember | async as fullMember">{{fullMember.socialSecurityNumber | ssn}} {{fullMember.birthDate | date: 'MM/dd/yyyy'}}</div>
          </h3>

          <pdf #pdf
            [documentTitleText]="filename"
            [visible]="true"
            [showFooter]="false"
            [landscape]="true"
            >
            <ngb-accordion [destroyOnHide]="false" #accordion fxFlex>

              <ngb-panel id="panel-1">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0 page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Subscriber Eligibility
                      <span *ngIf="eligibilities | async as eligibilities; else loadingHeader" class="record-count float-right">{{getRowCount(eligibilities)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="eligibilities | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="eligibilityColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading eligibility...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-2">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Subscriber Enrollment
                      <span *ngIf="subscriberEnrollments | async as subscriberEnrollments; else loadingHeader" class="record-count float-right">{{getRowCount(subscriberEnrollments)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="subscriberEnrollments | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="subscriberEnrollmentColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading enrollments...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-3">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Subscriber Special Open Enrollments
                      <span *ngIf="soes | async as soes; else loadingHeader" class="record-count float-right">{{getRowCount(soes)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="soes | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="soeColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading SOEs...</ng-template>
                </ng-template>
              </ngb-panel>

              

              <ngb-panel id="panel-4">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Subscriber Addresses
                      <span *ngIf="addresses | async as addresses; else loadingHeader" class="record-count float-right">{{getRowCount(addresses)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="addresses | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="addressColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading addresses...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-5">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Subscriber Attestations
                      <span *ngIf="subscriberAttestations | async as subscriberAttestations; else loadingHeader" class="record-count float-right">{{getRowCount(subscriberAttestations)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="subscriberAttestations | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="subscriberAttestationColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading attestations...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-6">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Subscriber Login History
                      <span *ngIf="loginHistory | async as loginHistory; else loadingHeader" class="record-count float-right">{{getRowCount(loginHistory)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="loginHistory | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="loginHistoryColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading login history...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-7">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      HCA Administrative Notes
                      <span *ngIf="notes | async as notes; else loadingHeader" class="record-count float-right">{{getRowCount(notes)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="notes | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="noteColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading HCA administrative notes...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-8">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Subscriber Marital Status
                      <span *ngIf="maritalStatuses | async as maritalStatuses; else loadingHeader" class="record-count float-right">{{getRowCount(maritalStatuses)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="maritalStatuses | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="maritalStatusColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading marital status records...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-9">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Dependents
                      <span *ngIf="dependents | async as dependents; else loadingHeader" class="record-count float-right">{{getRowCount(dependents)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="dependents | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="dependentColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading dependents...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-10">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Documents
                      <span *ngIf="documents | async as documents; else loadingHeader" class="record-count float-right">{{getRowCount(documents)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="documents | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="documentColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading documents...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-11">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Requested Elections for Dependent Enrollments
                      <span *ngIf="dependentEnrollments | async as dependentEnrollments; else loadingHeader" class="record-count float-right">{{getRowCount(dependentEnrollments)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="dependentEnrollments | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="dependentEnrollmentColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading enrollments...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-12">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Dependent Tobacco Attestations
                      <span *ngIf="dependentAttestations | async as dependentAttestations; else loadingHeader" class="record-count float-right">{{getRowCount(dependentAttestations)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="dependentAttestations | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="dependentAttestationColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading attestations...</ng-template>
                </ng-template>
              </ngb-panel>

              <ngb-panel id="panel-13">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0  page-break">
                    <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
                    <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
                    <span>
                      Self Pays
                      <span *ngIf="selfpays | async as selfpays; else loadingHeader" class="record-count float-right">{{getRowCount(selfpays)}}</span>
                      <ng-template #loadingHeader>
                        <div class="d-flex spinner-wrapper float-right" style="position:relative;height:fit-content;width:fit-content;display:inline-block!important;">
                          <div class="spinner-border" role="status" style="width: 20px; height: 20px;">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      </ng-template>
                    </span>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div *ngIf="selfpays | async as records; else loadingGrid">
                    <generic-grid
                      *ngIf="records.length"
                      [selectable]="false"
                      [pageable]="!printing"
                      [pageSize]="20"
                      [gridData]="records"
                      [gridColumns]="selfpayColumns"
                      [filterable]="!printing"
                      [rowClass]="rowClass"
                      >
                    </generic-grid>

                    <div *ngIf="!records.length">
                      No records found
                    </div>
                  </div>
                  <ng-template #loadingGrid>Loading Self Pays...</ng-template>
                </ng-template>
              </ngb-panel>
              
            </ngb-accordion>
          </pdf>

          <button type="button" class="btn btn-primary float-right" [disabled]="!loaded" (click)="download()">Download</button>
        </div>
      </div>
    </div>
  </div>
</div>