import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import MemberMaritalStatus from '../models/memberMaritalStatus';

@Injectable({
  providedIn: 'root'
})
export class MemberMaritalStatusService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createMemberMaritalStatus(maritalStatus: MemberMaritalStatus): Observable<MemberMaritalStatus> {
    return this.http
      .post<MemberMaritalStatus>(`${env.apiUrl}/Member/${maritalStatus?.memberId}/maritalStatus`, maritalStatus)
      .pipe(map(x => this.commonService.createObject(MemberMaritalStatus, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMemberMaritalStatus(maritalStatus: MemberMaritalStatus): Observable<MemberMaritalStatus> {
    return this.http
      .put<MemberMaritalStatus>(`${env.apiUrl}/Member/${maritalStatus?.memberId}/maritalStatus/${maritalStatus?.memberMaritalStatusId}`, maritalStatus)
      .pipe(map(x => this.commonService.createObject(MemberMaritalStatus, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteMemberMaritalStatus(maritalStatus: MemberMaritalStatus): Observable<MemberMaritalStatus> {
    return this.http
      .delete<MemberMaritalStatus>(`${env.apiUrl}/Member/${maritalStatus?.memberId}/maritalStatus/${maritalStatus?.memberMaritalStatusId}`)
      .pipe(res => res)
      .pipe(catchError(this.commonService.handleError));
  }
}
