export default class Registration {
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  secretQuestions: { question: string; answer: string }[] = [];
  password: string;
  ssn: string;
  birthDate: Date;

  constructor(registration?) {
    if (registration) {
      this.firstName = registration.firstName;
      this.lastName = registration.lastName;
      this.email = registration.email;
      this.userId = registration.userId;
      this.secretQuestions = registration.secretQuestions.map(a => ({ question: a.question, answer: a.answer }));
      this.password = registration.password;
      this.ssn = registration.ssn;
      this.birthDate = new Date(registration.birthDate) || null;
    }
    if (!this.secretQuestions.length) {
      this.secretQuestions.push({ question: null, answer: null });
      this.secretQuestions.push({ question: null, answer: null });
    }
  }

}
