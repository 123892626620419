import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sortBy, filter, find, cloneDeep, every, some, remove, get } from 'lodash';
import { faCalendar, faHome, faUndo, faEnvelope, faPhone, faAt, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import SystemUser from 'src/app/models/user';
import Subscriber from 'src/app/models/subscriber';
import SubscriberComposite from 'src/app/models/subscriberComposite';
import { SubscriberService } from 'src/app/services/subscriber.service';
import MemberNote from 'src/app/models/memberNote';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.Reason)
@Component({
  selector: 'subscriber-management-notes',
  templateUrl: 'subscriberManagement.notes.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriberManagementNotesComponent implements OnInit {
  systemUser: SystemUser;
  subscriber: Subscriber;
  subscriberComposite: SubscriberComposite;
  initialSubscriber: SubscriberComposite;
  icons = {
    faCalendar,
    faHome,
    faUndo,
    faEnvelope,
    faPhone,
    faAt,
    faMobileAlt,
    faPlus,
    faMinus
  };
  lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberTypes: [],
    phoneNumberTypes: [],
    preferredContactMethods: [],
    languages: [],
    organizations: [],
    terminationReasons: [],
    eligibilityReasons: [],
  };
  gridColumns: { [k: string]: string | {} }[] = [
    { field: 'createdDate', title: 'Created On', format: { date: 'mm/dd/yyyy' }, width: 200, filter: 'date' },
    { field: 'createdByName', title: 'Created By', format: 'string', width: 200 },
    { field: 'noteText', title: 'Note', format: 'string' },
  ];
  gridData = [];
  canEdit: boolean;
  isHCAAdmin = false;
  isReadOnly
  newNote: MemberNote;
  addingNote = false;
  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService,
    private router: Router,
    private subscriberService: SubscriberService,
    private spinnerService: SpinnerOverlayService,
  ) {}

  ngOnInit(): void {
    const nowMoment = dayjs();
    const lastNextMonth = dayjs().add(1, 'month').endOf('month');
    this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
    const editHCA = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
    this.canEdit = this.isHCAAdmin || editHCA;
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.subscriber = data.subscriber;
      this.subscriber.memberNotes.forEach((note) => {
        note.createdByName = this.concatName(note.createdByName);
      });
      this.gridData = this.subscriber.memberNotes;
      this.newNote = new MemberNote();
      this.newNote.memberId = this.subscriber.memberId;
    });
  }

  async saveNote() {
    try {
     this.spinnerService.show();
     const addedNote = await lastValueFrom(this.subscriberService.createSubscriberNote(this.newNote));
     addedNote.createdByName = this.concatName(addedNote.createdByName);
     this.gridData.push(addedNote);
     this.gridData = cloneDeep(this.gridData);
     this.spinnerService.hide();
     this.cancelNote();
    }catch (err) {
      console.log(err);
      this.spinnerService.hide();
    }
  }

  concatName(createdByName : string) {
    if (!createdByName) return '';
    const shortenedName = createdByName.split(' ');
    return shortenedName.length > 1 ? shortenedName[0] + ' ' + shortenedName[1].charAt(0) + '.' : shortenedName[0];
  }

  cancelNote() {
    this.newNote = new MemberNote()
    this.newNote.memberId = this.subscriber.memberId;
    this.addingNote = false;
  }
}
