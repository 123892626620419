<div fxLayout="column" class="fxGap-x-large mgbt-medium" *ngIf="!isDeathOrDivorceSoe">
  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <h1 *ngIf="isSOE">You’re all done! Please click below to submit your special open enrollment request for review. Once submitted, you cannot edit this request and must remove it to make corrections.</h1>
    <h1 *ngIf="!isSOE">You're all done! You can download a summary of your elections below.</h1>
  </div>
  <div *ngIf="isSOE && !soeSubmitted" fxFlexFill fxLayout="row" fxLayoutAlign="space-around">
    <button type="submit" (click)="submitSOERequest.emit()" class="btn btn-primary">Submit Request</button>
  </div>
  <div *ngIf="isSOE && soeSubmitted" fxFlexFill fxLayout="row" fxLayoutAlign="space-around">
    <div><h4>Request submitted for verification</h4></div>
  </div>  
  <div fxFlexFill fxLayout="row" fxLayoutAlign="space-around">
    <button type="submit" (click)="downloadCoveragePDF.emit()" class="btn btn-primary">Download elections</button>
  </div>
  <form #emailForm="ngForm">
    <div style="padding-left: 30px" class="form-row">
      <div class="form-group required col-md-4">
        <label for="pebbEmailInput">Email address</label>
        <input [disabled]="!subscriber.emailNotificationInd" name="email" email [(ngModel)]="subscriber.emailAddress" required maxlength="50" type="email" class="form-control" id="pebbEmailInput" placeholder="Email Address" />
      </div>
    </div>
  </form>
  <div class="row hca-dashboard-row" fxLayoutAlign="start center">
    <div style="padding-left: 50px" fxFlex="85">
      <div class="k-form-field bigger" fxFlex fxLayout>
        <checkbox
          [disabled]="isReadOnly"
          bold
          name="emailNotificationInd"
          #emailNotificationInd="ngModel"
          [(ngModel)]="subscriber.emailNotificationInd"
          (change)="submitEmailSubscriptionChange(subscriber.emailNotificationInd)">
          {{optInLabel}}
      </checkbox>
      </div>
    </div>
  </div>
  <div class="row" style="padding-top: 15px" *ngIf="showEmailTermsAndConditions">
    <terms-and-conditions [agencyCode]="subscriber.agencyCode" [submitTermsTrueValue]="'submit'" (submitTerms)="submitEmailSubscriptionChange($event)"></terms-and-conditions>
  </div>
</div>
<div fxLayout="column" class="fxGap-x-large mgbt-medium" *ngIf="isDeathOrDivorceSoe">
  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <h1>You're all finished. Please click below to submit the event request for verification. Once submitted, you cannot edit this request and must remove it to make changes.</h1>
  </div>
  <div *ngIf="!soeSubmitted && isHcaAdmin" fxFlexFill fxLayout="row" fxLayoutAlign="space-around">
    <div style="max-width:60%">
      <div class="h4">Notification was provided no later than 60 days from the end of the month in which the event occurred?</div>
      <yes-no [(ngModel)]="within60DaysResponse" (ngModelChange)="within60Days.emit($event)"></yes-no>
    </div>
  </div>
  <div *ngIf="!soeSubmitted" fxFlexFill fxLayout="row" fxLayoutAlign="space-around">
    <button type="submit" (click)="submitSOERequest.emit()" class="btn btn-primary">Submit Request</button>
  </div>
  <div *ngIf="soeSubmitted" fxFlexFill fxLayout="row" fxLayoutAlign="space-around">
    <div><h4>Request submitted for verification</h4></div>
  </div>
</div>
