/**
 * Handles catching / showing err messages globally on ajax calls that aren't handled elsewhere.
 */

// ng
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

// ext
import { NotificationService } from '@progress/kendo-angular-notification';

// local
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService } from 'src/app/services/core.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private coreService: CoreService) {}

  handleError(error): void {
    const notificationService = this.injector.get(NotificationService);
    const message = this.getErrorMessage(error);
    const spinnerOverlayService = this.injector.get(SpinnerOverlayService);
    // Only do something if err with an actual message from backend
    if (message && message.length) {
      spinnerOverlayService.hide();
      this.coreService.popMessage(message, 'error');
    }
    console.log(error);
  }

  getErrorMessage(error): string {
    if (!error || !(error instanceof HttpErrorResponse)) {
      return '';
    } else if (!error.message) {
      return '';
    } else {
      return error.message;
    }
  }
}
