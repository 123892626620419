import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { cloneDeep, some, get, find, pull, forEach, filter, sortBy } from 'lodash';
// ng
import { ActivatedRoute } from '@angular/router';
import { OnChanges, Component, ViewEncapsulation, OnInit, OnDestroy, Input, EventEmitter, Output, ViewChild, SimpleChanges } from '@angular/core';
import { env } from 'src/env/development';
// ext

// local
import DependentComposite from 'src/app/models/dependentComposite';
import remove from 'lodash';
import * as dayjs from 'dayjs';
import PlanType from 'src/app/models/planType';
import Reason from 'src/app/models/reason';
import RelationshipType from 'src/app/models/relationshipType';
import { CoreService } from 'src/app/services/core.service';
import { MemberService } from 'src/app/services/member.service';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Subscriber from 'src/app/models/subscriber';
import Member from 'src/app/models/member';
import DualEnrollment from 'src/app/models/dualEnrollment';
import SelfPay from 'src/app/models/selfPay';
import { NgForm } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'dep-coverage',
  templateUrl: 'dep.coverage.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class DependentCoverageComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dependent: DependentComposite;
  @Input() thirteenDate: Date;
  @Input() dentalOpportunityOnly = false;
  @Output() moveToNextSubsection: EventEmitter<number> = new EventEmitter();
  @Output() updateSelectedPlans: EventEmitter<{}> = new EventEmitter();
  @Output() notifiedBeforeSOEEnded: EventEmitter<{}> = new EventEmitter();
  @Output() cancelDependentAdd: EventEmitter<void> = new EventEmitter();
  @Input() lookups = {
    addressType: [],
    county: [],
    country: [],
    birthSexes: [],
    genderIdentities: [],
    relationshipQualifyReasons: [],
    relationshipVerificationStatus: [],
    relationshipTypes: [],
    memberType: [],
    subagencies: [],
    phoneNumberTypes: [],
    eligibilityReasons: [],
    terminationReasons: [],
    planTypes: [],
    removalReasons: [],
    allReasons: []
  };
  @Input() electablePlanTypes: PlanType[];
  @Input() enrollmentPeriod: EnrollmentPeriod;
  @Input() subscriber: Subscriber;
  @Input() soe ?: SpecialOpenEnrollment;
  @Input() isSelfPay ? = false;
  @Input() currentSP: SelfPay;
  @ViewChild('adoptionDateForm') adoptionDateForm: NgForm;
  planTypesSelected = {};
  initialPlanTypesSelected = {};
  nextText = 'Next';
  effectiveEnd: Date;
  divorceId: string;
  depLosesEligId: string;
  deathId: string;
  notifiedBeforeSOEEnd;
  soeWindowPassed = false;
  isAdopted = false;
  isNewborn = false;
  hasMed = false;
  hasDental = false;
  hasVision = false;
  hasAccountLevelMed = false;
  hasAccountLevelDental = false;
  hasAccountLevelVision = false;
  spousalRelationshipTypeId: string;
  childRelationshipTypeId: string;
  icons = {
    faCalendar
  };
  // DE flags
  DEOther = false;
  DECurrentMed = false;
  DECurrentDen = false;
  DECurrentMedDen = false;
  DEPEBBMedMedOnly = false;
  currentAgency: string;
  otherAgency: string;
  dentalPlanTypeId: string;
  medicalPlanTypeId: string;
  visionPlanTypeId: string;
  subscriberCurrentlyWaivedMedical = false;
  subscriberCurrentlyWaivedDental = false;
  subscriberCurrentlyWaivedVision = false;
  selfPayMissingCoverage = false;
  isSPSOE: boolean = false;
  allPlanTypesDisabled: boolean = false;
  visionAvailable = false;
  
  constructor(private route: ActivatedRoute, private coreService: CoreService, private memberService: MemberService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.spousalRelationshipTypeId = get(find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt?.relationshipTypeCode === 'S'), 'relationshipTypeId');
    this.childRelationshipTypeId = get(find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt?.relationshipTypeCode === 'C'), 'relationshipTypeId');
    if (this.dependent.relationshipTypeId !== this.spousalRelationshipTypeId) {
      this.lookups.terminationReasons = filter(this.lookups.terminationReasons, (r: Reason) => r.reasonCode !== '42');
    }
  }

  ngOnInit(): void {
    forEach(this.dependent.planTypeIds, (planTypeId: string) => {
      this.planTypesSelected[planTypeId] = find(this.lookups.planTypes, (planType: PlanType) => planType.planTypeId === planTypeId);
    });
    this.initialPlanTypesSelected = cloneDeep(this.planTypesSelected);
    if (this.soe) {
      this.effectiveEnd = dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).subtract(1, 'day').toDate();
    }
    else if (this.isSelfPay) {
      this.effectiveEnd = dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).subtract(1, 'day').toDate();
      this.isSPSOE = this.currentSP.selfPayType.selfPayTypeCode.startsWith("SOE");
    } else if ( this.enrollmentPeriod.coverageEffectiveStartDate) {
      this.effectiveEnd = dayjs(this.enrollmentPeriod.coverageEffectiveStartDate).subtract(1, 'day').toDate();
    }
    this.spousalRelationshipTypeId = get(find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt?.relationshipTypeCode === 'S'), 'relationshipTypeId');
    this.childRelationshipTypeId = get(find(this.lookups.relationshipTypes, (rt: RelationshipType) => rt?.relationshipTypeCode === 'C'), 'relationshipTypeId');
    this.deathId = get(find(this.lookups.terminationReasons, (r: Reason) => r.reasonName === 'Death'), 'reasonId');
    this.divorceId = get(find(this.lookups.terminationReasons, (r: Reason) => r.reasonName === 'Divorce/Dissolution'), 'reasonId');
    this.depLosesEligId = get(find(this.lookups.terminationReasons, (r: Reason) => r.reasonName === 'Dependent Loses Eligibility'), 'reasonId');
    if (this.soe) {
      this.soeWindowPassed = dayjs(this.soe.requestReceivedDate).isAfter(dayjs(this.soe.eventDate).add(60, 'day'));
    }
    if (this.isSelfPay) {
      const eventStart = dayjs(this.effectiveEnd).startOf('month');
      const eventEnd = dayjs(this.effectiveEnd).endOf('month');

      if (this.dependent.relationshipTypeId !== this.spousalRelationshipTypeId) {
        this.lookups.terminationReasons = filter(this.lookups.terminationReasons, (r: Reason) => r.reasonCode !== '42');
      }

      const spRemovalReasonCodes = ['35','37','39','41','44','48'];
      if (this.dependent.relationshipTypeId === this.spousalRelationshipTypeId) {
        spRemovalReasonCodes.push('42');
      }
      this.lookups.removalReasons = sortBy(
        this.lookups.allReasons.filter((r: Reason) => some(spRemovalReasonCodes, rc => r.reasonCode === rc && r.reasonName!='Never effective')),
        'reasonName'
      );
    }

    this.medicalPlanTypeId = get(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.medicalPlanTypeCode), 'planTypeId');
    this.dentalPlanTypeId = get(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.dentalPlanTypeCode), 'planTypeId');
    this.visionPlanTypeId = get(find(this.lookups.planTypes, (pt: PlanType) => pt.planTypeCode === env.visionPlanTypeCode), 'planTypeId');
    this.subscriberCurrentlyWaivedMedical = !this.subscriber.getCoverageByPlanTypeAndDate('Medical', this.enrollmentPeriod.coverageEffectiveStartDate);
    this.subscriberCurrentlyWaivedDental = !this.subscriber.getCoverageByPlanTypeAndDate('Dental', this.enrollmentPeriod.coverageEffectiveStartDate);
    this.subscriberCurrentlyWaivedVision = !this.subscriber.getCoverageByPlanTypeAndDate('Vision', this.enrollmentPeriod.coverageEffectiveStartDate);
    this.setDependentCoverageVars();
    this.electablePlanTypes = sortBy(this.electablePlanTypes, (p: PlanType) => p.planTypeCode)
    this.visionAvailable = !!this.electablePlanTypes.filter(pt => pt.planTypeId === this.visionPlanTypeId).length;
  }

  ngOnDestroy(): void {}

  updatePlanTypes(id): void {
    if (this.planTypesSelected[id]) {
      if (!this.dependent.planTypeIds.includes(id)) {
        this.dependent.planTypeIds.push(id);
      }
    } else {
      pull(this.dependent.planTypeIds, id);
    }

    this.setDependentCoverageVars();
    const underThirteen = this.dependent.isUnderThirteen;
    const isSpouse = this.dependent.relationshipTypeId === find(this.lookups.relationshipTypes, (rqr) => rqr.relationshipTypeCode === 'S').relationshipTypeId;
    if (!this.hasMed || underThirteen) {
      this.nextText = 'Proceed to dependent review';
    } else if (!underThirteen && this.hasMed) {
      this.nextText = `Let's make tobacco attestations for this dependent`;
    } else if (isSpouse) {
      this.nextText = `Let's make attestations for your spouse/State-registered partner`;
    }
  }

  setDependentCoverageVars(): void {
    this.hasMed = this.dependent.planTypeIds.includes(this.medicalPlanTypeId);
    this.hasDental = this.dependent.planTypeIds.includes(this.dentalPlanTypeId);
    this.hasVision = this.dependent.planTypeIds.includes(this.visionPlanTypeId);
    this.selfPayMissingCoverage = false;
    this.hasAccountLevelMed = (this.subscriber.getAccountLevelCoverageByPlanTypeAndDate('Medical', this.enrollmentPeriod.coverageEffectiveStartDate)!=null);
    this.hasAccountLevelDental = (this.subscriber.getAccountLevelCoverageByPlanTypeAndDate('Dental', this.enrollmentPeriod.coverageEffectiveStartDate)!=null);
    this.hasAccountLevelVision = (this.subscriber.getAccountLevelCoverageByPlanTypeAndDate('Vision', this.enrollmentPeriod.coverageEffectiveStartDate)!=null);

    if (this.isSelfPay && ((this.hasMed && this.subscriberCurrentlyWaivedMedical && !this.hasAccountLevelMed) ||
      (this.hasDental && this.subscriberCurrentlyWaivedDental && !this.hasAccountLevelDental) ||
      (this.hasVision && this.subscriberCurrentlyWaivedVision && !this.hasAccountLevelVision))) {
      this.selfPayMissingCoverage = true;
    }
  }

  async submitDependent(): Promise<any> {
    // call DE if new
    this.resetDEFlags();
    if (this.dependent.socialSecurityNumber) {
      if (!this.dependent.memberId) {
        this.dependent.member = new Member();
      }

      this.dependent.member.externalEnrollments = await lastValueFrom(this.memberService.getExternalEnrollments(this.dependent.socialSecurityNumber, 
        !this.dependent.memberId ? null: this.dependent.memberId, this.subscriber.memberId));      

      const dualEnrollment = new DualEnrollment(this.subscriber.organization, this.dependent.member.externalEnrollments, this.enrollmentPeriod.coverageEffectiveStartDate);
      this.currentAgency = dualEnrollment.currentAgency;
      this.otherAgency = dualEnrollment.otherAgency;

      // If this is a transfer event, this.dentalOpportunityOnly=true
      if (this.dependent.planTypeIds.length>0) {
        if (dualEnrollment.hasOtherAgencyAtAll) {
          return this.DEOther = true;
        } else if (dualEnrollment.hasCurrentAgencyDental && dualEnrollment.hasCurrentAgencyMedical && !this.dentalOpportunityOnly) {
          return this.DECurrentMedDen = true;
        } else if (!dualEnrollment.isSebb && this.subscriber.organization?.benefitSetup?.benefitSetupCode === 'MO' && dualEnrollment.hasCurrentAgencyMedical) {
          return this.DEPEBBMedMedOnly = true;
        } else if (dualEnrollment.hasCurrentAgencyMedical && this.planTypesSelected[this.medicalPlanTypeId] && !this.dentalOpportunityOnly) {
          return this.DECurrentMed = true;
        } else if (dualEnrollment.hasCurrentAgencyDental && this.planTypesSelected[this.dentalPlanTypeId] && !this.dentalOpportunityOnly) {
          return this.DECurrentDen = true;
        }
      }
    }
    // new dep and nothing selected
    if (!this.dependent.memberId && !some(this.planTypesSelected, (val, key) => val)) {
      return this.coreService.popMessage('You must make a coverage election for a newly added dependent.', 'error', 3000);
    }
    // coverage eff = adoption date = first month event date
    if (this.isAdopted) {
      this.dependent.adoptionDate = (this.soe?.eventDate && this.soe?.eventDate > this.enrollmentPeriod.coverageEffectiveStartDate ? this.soe?.eventDate : this.enrollmentPeriod.coverageEffectiveStartDate);
    }
  
    if (this.enrollRemoved && this.effectiveEnd && this.dependent.memberId && 
        this.soe?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName === 'HCA OE Correction' &&
        !this.dependent.terminationReasonId) {
      return this.coreService.popMessage('Removal reason is required.', 'error', 3000);
    }

    this.updateSelectedPlans.emit(this.planTypesSelected);
    this.moveToNextSubsection.emit();
  }

  // getters need a refactor eventually, perf bad
  get enrollAdded(): boolean {
    return some(this.planTypesSelected, (val, key) => val && (!this.dependent.memberId || !this.initialPlanTypesSelected[key]));
  }

  get enrollRemoved(): boolean {
    return some(this.planTypesSelected, (val, key) => !val && this.initialPlanTypesSelected[key]);
  }

  resetDEFlags(): void {
    this.DECurrentDen = false;
    this.DECurrentMed = false;
    this.DECurrentMedDen = false;
    this.DEPEBBMedMedOnly = false;
    this.DEOther = false;
  }

  removalReasonChange() {
    const selReason:Reason = find(this.lookups.removalReasons, (r:Reason)=> r.reasonId ===this.dependent.terminationReasonId);
    if (selReason.reasonCode === '35' || selReason.reasonCode === '42') {
      //death/divorce - uncheck and disable enrollment types
      this.dependent.planTypeIds.forEach( ptId => {
        this.planTypesSelected[ptId] = false;
        pull(this.dependent.planTypeIds, ptId);
      });
      this.setDependentCoverageVars();
      this.allPlanTypesDisabled = true;
    }
    else {
      this.allPlanTypesDisabled = false;
    }
  }
}
