import { map } from 'lodash';
import CertificationType from './certificationType';

export default class RelationshipCertification {
  relationshipCertificationId: string;
  relationshipId: string;
  certificationTypeId: string;
  certificationType: CertificationType;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  approvalDate: Date;
  approvedById: string;
  deniedDate: Date;
  deniedById: string;
  approvedInd?: boolean;
  simplifiedStatus: string;

  constructor(relationshipCertification?) {
    if (relationshipCertification) {
      this.relationshipCertificationId = relationshipCertification.relationshipCertificationId;
      this.relationshipId = relationshipCertification.relationshipId;
      this.certificationTypeId = relationshipCertification.certificationTypeId;
      this.certificationType = new CertificationType(relationshipCertification.certificationType);
      this.effectiveStartDate = relationshipCertification.effectiveStartDate ? new Date(relationshipCertification.effectiveStartDate) : null;
      this.effectiveEndDate = relationshipCertification.effectiveEndDate ? new Date(relationshipCertification.effectiveEndDate) : null;
      this.approvalDate = relationshipCertification.approvalDate ? new Date(relationshipCertification.approvalDate) : null;
      this.approvedById = relationshipCertification.approvedById;
      this.deniedDate = relationshipCertification.deniedDate ? new Date(relationshipCertification.deniedDate) : null;
      this.deniedById = relationshipCertification.deniedById;
      this.approvedInd = relationshipCertification.approvalDate !== null &&
        (relationshipCertification.DeniedDate === null || relationshipCertification.deniedDate < relationshipCertification.approvalDate) ? true :
        (relationshipCertification.DeniedDate !== null ? false : null);
      this.simplifiedStatus = relationshipCertification.approvalDate !== null ? 'Certified' : (relationshipCertification.deniedDate !== null ? 'Denied' : 'Pending');
    }
  }
}
