<div *ngIf="!displayRaceEthnicity && !isPebbRetireeOE && !isCobraOE" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body">
    <h2>Medical plan selection</h2>
    <div class="h5 mgbt-medium">
      <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/contact-plans-" *ngIf="isCobra && agency.agencyCode === 'SEBB'">Call the plans</a>
      <span *ngIf="!isCobra || agency.agencyCode != 'SEBB'">Call the plans</span> with questions about benefits and providers. If you move out of the 
      <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/medical-plans-available-county" *ngIf="isCobra && agency.agencyCode === 'SEBB'">medical plan's service area</a>
      <span *ngIf="!isCobra || agency.agencyCode != 'SEBB'">medical plan's service area</span> and your plan is no longer available, you must change plans. 
      If you do not, the {{agency?.agencyCode}} Program will enroll you in one.  You must report your new address address and any request to change your medical plan to the {{agency?.agencyCode}} Program no later 
      than 60 days after you move by calling 1-800-200-1004 (TRS: 711) or submitting a change of address request. 
    </div>
    <div *ngIf="isRetiree" fxLayoutAlign="space-between center" fxFlexFill fxLayout="column" class="h4">
      <a href="https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2024.pdf" target="_blank" class="">Click here to view the 2024 premiums</a>
    </div>
    <form fxLayoutAlign="space-between" fxFlexFill fxLayout="row" #electMedicalForm="ngForm" (ngSubmit)="emitSelfPay(selfPay)">
      <div fxLayout="column" fxFlex="50">
        <div fxLayout="column" *ngFor="let carrier of carrierMedicalPlans; let bIndex = index">
          <h3 class="selfPay-form-small-header">
            <b>{{ carrier.carrierName }}</b>
          </h3>
          <div></div>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of carrier.plans; let index = index" class="k-radio-item bigger" fxLayout="row">
                <checkbox
                  [name]="'medicalPlan_' + bIndex + '_' + index"
                  [disabled]="plan.planCode === 'F'"
                  [value]="plan.planId"
                  (change)="setPlan(plan.planId)"
                  [checked]="selectedPlanIds.length > 0 && selectedPlanIds.indexOf(plan.planId) > -1"
                  >{{ plan.planName }}
                </checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="agency?.agencyCode === 'PEBB'" fxFlex="45">
        <div>
          <h3 class="h5">Helpful links:</h3>
          <div *ngIf="isRetiree" fxLayoutAlign="start start" fxLayout="column">
            <a target="_blank" href="https://fortress.wa.gov/hca/pebbhealthplan/compare.aspx">Compare medical plans</a>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/medical-plans-available-county">Medical plans by county</a>
            <span ><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/find-pebb-plan-provider">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/contact-plans">Plan contact information</a>
          </div>
          <div *ngIf="!isRetiree" fxLayoutAlign="start start" fxLayout="column">
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/compare-medical-plans">Compare medical plans</a>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/medical-plans-available-county">Medical plans by county</a>
            <span ><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/find-pebb-plan-provider">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/contact-plans">Plan contact information</a>
          </div>
        </div>
      </div>
    </form>
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="continueOrDisplayRE(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>

<!-- Race/ethnicity section lwop/cobra -->

<div *ngIf="displayRaceEthnicity" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div fxLayout="row" fxLayoutAlign="space-between">
    <div [fxFlex]="this.selfPay?.formJson?.dependents?.length ? 45 : 95" fxLayout="column" class="card">
      <div class="card-body">
        <h3>Subscriber</h3>
        <race-ethnicity (updateMemberObject)="updateFormJsonSub($event)" [suppressSave]="true" [ethnicities]="ethnicities" [member]="this.selfPay.formJson?.subscriber"></race-ethnicity>
      </div>
    </div>
    <div *ngIf="this.selfPay?.formJson?.dependents?.length" fxFlex="45" fxLayout="column" class="card">
      <div class="card-body">
        <h3>Spouse or SRDP</h3>
        <race-ethnicity (updateMemberObject)="updateFormJsonSpouse($event)" [suppressSave]="true" [ethnicities]="ethnicities" [member]="this.selfPay.formJson?.dependents[0]"></race-ethnicity>
      </div>
    </div>
  </div>
  <div fxFlexFill style="margin-top: 20px" fxLayout="row" fxLayoutAlign="space-around center">
    <button (click)="displayRaceEthnicity = false" class="btn btn-secondary">Previous</button>
    <button (click)="saveSelfPay.emit(selfPay)" class="btn btn-primary">Continue</button>
    <div></div>
  </div>
</div>


<!-- RETIREE OE -->

<div *ngIf="!displayRaceEthnicity && !noMedicalSelectionMade && (isPebbRetireeOE || isCobraOE)" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body">
    <h2>Medical plan selection</h2>
    <div class="h5 mgbt-medium">
      <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/contact-plans-" *ngIf="isCobra && agency.agencyCode === 'SEBB'">Call the plans</a>
      <span *ngIf="!isCobra || agency.agencyCode != 'SEBB'">Call the plans</span> with questions about benefits and providers. If you move out of the 
      <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/sebb-continuation-coverage/medical-plans-available-county" *ngIf="isCobra && agency.agencyCode === 'SEBB'">medical plan's service area</a>
      <span *ngIf="!isCobra || agency.agencyCode != 'SEBB'">medical plan's service area</span> and your plan is no longer available, you must change plans. 
      If you do not, the {{agency?.agencyCode}} Program will enroll you in one.  You must report your new address address and any request to change your medical plan to the {{agency?.agencyCode}} Program no later 
      than 60 days after you move by calling 1-800-200-1004 (TRS: 711) or submitting a change of address request. 
    </div>
    <div *ngIf="isRetiree" fxLayoutAlign="space-between center" fxFlexFill fxLayout="column" class="h4">
      <a href="https://www.hca.wa.gov/assets/pebb/51-0275-retiree-premiums-2024.pdf" target="_blank" class="">Click here to view premium amounts</a>
    </div>
    <div>
      <div class="k-form-field bigger" fxFlex="100">
        <checkbox name="visionEnrollInd" [(ngModel)]="selfPay.formJson.continueCurrentSelectedMedical" (ngModelChange)="continueCurrentSelected($event)">Continue with my current medical plan</checkbox>
      </div>
    </div>
    <form fxLayoutAlign="space-between" fxFlexFill fxLayout="row" #electMedicalForm="ngForm" (ngSubmit)="emitSelfPay(selfPay)">
      <div fxLayout="column" fxFlex="50">
        <div fxLayout="column" *ngFor="let carrier of carrierMedicalPlans; let bIndex = index">
          <h3 class="selfPay-form-small-header">
            <b>{{ carrier.carrierName }}</b>
          </h3>
          <div></div>
          <div class="form-group">
            <ul class="k-radio-list">
              <li *ngFor="let plan of carrier.plans; let index = index" class="k-radio-item bigger" fxLayout="row">
                <checkbox
                  [name]="'medicalPlan_' + bIndex + '_' + index"
                  [disabled]="plan.planCode === 'F' || con"
                  [value]="plan.planId"
                  (change)="setPlan(plan.planId)"
                  [checked]="selectedPlanIds.length > 0 && selectedPlanIds.indexOf(plan.planId) > -1"
                  >{{ plan.planName }}
                </checkbox>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="agency?.agencyCode === 'PEBB'" fxFlex="45">
        <div>
          <h3 class="h5">Helpful links:</h3>
          <div *ngIf="isRetiree" fxLayoutAlign="start start" fxLayout="column">
            <a target="_blank" href="https://fortress.wa.gov/hca/pebbhealthplan/compare.aspx">Compare medical plans</a>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/medical-plans-available-county">Medical plans by county</a>
            <span ><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/find-pebb-plan-provider">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/retirees/contact-plans">Plan contact information</a>
          </div>
          <div *ngIf="!isRetiree" fxLayoutAlign="start start" fxLayout="column">
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/compare-medical-plans">Compare medical plans</a>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/medical-plans-available-county">Medical plans by county</a>
            <span ><a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/find-pebb-plan-provider">Find a provider</a> Make sure you have the correct provider network selected before searching for providers.</span>
            <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage/contact-plans">Plan contact information</a>
          </div>
        </div>
        <span>
          Plan selections are based on your Residential address/coverage area and reported Medicare enrollment statuses for subscriber and dependents.
          If your Medicare enrollment statuses or residential address is not correct, please correct that information and return to the medical elections page.
          <br />
          <br />
          <b *ngIf="accountMedicareStatus === 'F'">Premera Medicare Supplement Plan G enrollment form is required to enroll in Plan G</b>
        </span>
        
      </div>
    </form>
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="continueOrDisplayRE(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>

<!-- RETIREE OE NO SELECTIONS MADE -->

<div *ngIf="!displayRaceEthnicity && noMedicalSelectionMade && (isPebbRetireeOE || isCobraOE)" class="card" fxLayoutAlign="space-between center" fxLayout="column" fxLayout.lt-sm="column">
  <div class="card-body">
    <h2>Medical plan selection</h2>
    <div class="h5 mgbt-medium">
      Medical coverage has not been selected for any members on this account so no Medical plan election applies.
      If that is incorrect, please go back and select Medical coverage. Otherwise you can proceed to defer your coverage
      and submit your request.
      <br/>
      <br/>
      If you have questions or need assistance you can contact The Health Care Authority (HCA) by calling 1-800-200-1004 (toll-free)
      Monday through Friday 8a.m. to 4:30p.m.
      <br/>
      <br/>
      Contact infomration for The Health Care Authority can be found here: <a targe="_blank" href="https://www.hca.wa.gov/about-hca/contact-hca#retirees">Contact HCA</a>     
    </div>
    <div fxFlexFill style="margin-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="previous.emit()" class="btn btn-secondary">Previous</button>
      <button (click)="continueOrDisplayRE(selfPay)" class="btn btn-primary">Continue</button>
    </div>
  </div>
</div>