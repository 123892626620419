import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import TermsOfUse from '../models/termsOfUse';
import { TOUService } from '../services/tou.service';

@Injectable({
  providedIn: 'root'
})
export class TOUResolver implements Resolve<TermsOfUse[]> {
  constructor(private touService: TOUService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TermsOfUse[]> {
    return this.touService.getTermsOfUse().pipe(
      take(1),
      mergeMap(sub => {
        if (sub) {
          return of(sub);
        } else {
          this.router.navigate(['/']);
          return EMPTY;
        }
      })
    );
  }
}
