export default class County {
  countyId: string;
  countyCode: string;
  countyName: string;
  obsoleteDate: Date;
  obsoleteFlag: boolean;
  stateCode: string;

  constructor(county?) {
    if (county) {
      this.countyId = county.countyId;
      this.countyCode = county.countyCode;
      this.countyName = county.countyName;
      this.obsoleteDate = county.obsoleteDate ? new Date(county.ObsoleteDate) : null;
      this.obsoleteFlag = county.obsoleteFlag;
      this.stateCode = county.stateCode;
    }
  }

}
