<form (ngSubmit)="submitDependent()" *ngIf="dependent && !spousalOutsideWizard" #memberForm="ngForm" fxLayout="column" fxLayoutAlign="start start" fxFlexFill>
  <div fxFlexFill fxLayout="column" class="fxGap-medium">
    <h2 style="text-align: center">Spouse or state-registered domestic partner (SRDP) coverage premium surcharge</h2>
    <div [hidden]="displayConfirmation" fxLayout="row" fxLayoutAlign="space-between">
      <div>
        <span *ngIf="!isSebb">
          <span>A $50 premium surcharge may apply if you have a spouse or SRDP enrolled on your medical coverage. <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/spousal-coverage-surcharge" target="_blank">Learn about this surcharge</a> before you make your attestation.
          </span></span>
          <br />
          <br />
        <span *ngIf="isSebb">
          <span>A $50 premium surcharge may apply if you have a spouse or SRDP enrolled on your medical coverage. </span>
        <br/>
        <span>Answer Yes or No to Questions 2 through 6 below. </span>
          <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/surcharges" target="_blank">Learn about this surcharge</a> before you make your attestation.
        </span>
      </div>
    </div>
    <div fxLayout="column" [hidden]="displayConfirmation">
      <ol class="hca-ordered-list">
        <li [hidden]="question.sortOrder >= responseAffirmativeIndex + 1" *ngFor="let question of questions">
          <question
            [disabled]="question.sortOrder === 0 || question.sortOrder < responseAffirmativeIndex - 1"
            [responses]="question.responses"
            [responseId]="spousalQuestionResponses[question.questionId]"
            [question]="question"
            (answer)="onSpousalQuestionAnswered($event)"
          ></question>
        </li>
      </ol>
      <div style="border: 1px solid black;" fxFill fxLayoutAlign="center center" *ngIf="dependent.spousalResponseId === negativeResponseId && !this.medicalEnrolled">
        <p class="mgtp-tiny" style="text-align: center;">You have not elected to enroll this dependent in medical coverage. No spouse or state-registerd domestic partner coverage premium will apply.</p>
      </div>
      <span *ngIf="showCalculator">
        <strong>{{ inAdminState ? 'Subscriber' : 'You' }} may have to pay the spouse or state-registered domestic partner coverage surcharge in {{ effectiveYear }}.&nbsp;</strong>
        <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/spousal-plan-calculator"> Go to the {{ effectiveYear }} spousal plan calculator </a>
        &nbsp;to determine.
        <br />
      </span>
      <div *ngIf="showCalculator">
        <br />
        <span>
          After completing the {{ effectiveYear }} spousal plan calculator, did the calculator indicate the spouse or state-registered domestic surcharge coverage applies to
          {{ inAdminState ? 'Subscriber' : 'you' }} in {{ effectiveYear }}?
        </span>
        <br />
      </div>
    </div>
    <div> 
      <div fxLayout="row" [hidden]="displayConfirmation">
        <radio
          name="yes"
          [disabled]="someNegativeResponse"
          [value]="affirmativeResponseId"
          [(ngModel)]="dependent.spousalResponseId"
          (change)="updateSpousal()">
          Yes, {{ inAdminState ? 'subscriber' : 'I' }} will pay the $50-per-month spouse or state-registered domestic partner coverage surcharge in
          {{ enrollmentPeriod.coverageEffectiveStartDate | date: 'yyyy' }}
        </radio>
      </div>
      <div fxLayout="row" [hidden]="displayConfirmation">
        <radio
          name="no"
          [disabled]="someNegativeResponse"
          [value]="negativeResponseId"
          [(ngModel)]="dependent.spousalResponseId"
          (change)="updateSpousal()">
          No, the spouse or state-registered domestic partner coverage surcharge does not apply in {{ enrollmentPeriod.coverageEffectiveStartDate | date: 'yyyy' }}.
        </radio>
      </div>
      <br/>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="end end">
      <button [hidden]="displayConfirmation" type="button" [disabled]="this.yesSpousal === undefined" fxFlex="30" (click)="displayConfirmation = true" class="btn btn-primary">Next</button>
      <!-- <button fxFlex="30" id="sub-add-cancel-btn" (click)="cancelAdd.emit()" class="btn btn-light">Cancel</button> -->
    </div>
    <div [hidden]="!displayConfirmation" fxLayout="column" fxLayoutAlign="center center">
      <p class="mgbt-large">
        Based on your current attestations, you {{ dependent.spousalResponseId === affirmativeResponseId ? 'will' : 'will not' }} be charged the $50 spouse/state-registered domestic partner surcharge each month in addition to your premium.
      </p>
      <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between">
        <button fxFlex="30" id="sub-add-cancel-btn" (click)="displayConfirmation = false" class="btn btn-secondary">Back</button>
        <button *ngIf="!reattesting" [disabled]="this.yesSpousal === undefined" fxFlex="30" id="sub-add-submit-btn" type="submit" class="btn btn-primary">{{proceedLabelOverride || 'Proceed to dependent review'}}</button>
        <button *ngIf="reattesting" (click)="saveReattest.emit()" class="btn btn-primary">Submit and return to dashboard</button>
      </div>
    </div>
  </div>
</form>
  <!-- outside wiz (management) -->
<form *ngIf="spousalOutsideWizard">
  <fieldset [disabled]="disabled">
  <div fxFlexFill class="fxGap-large" fxLayout="column">
    <br />
    <div [hidden]="displayConfirmation" fxLayout="row" fxLayoutAlign="space-between">
      <div class="h3">
        Verify that the surcharges below apply to this dependent by checking the appropriate box then click the button below to proceed.
        <span *ngIf="!isSebb">
          <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/surcharges">Additional information on surcharges.</a>
          <br />
          <br />
          <a href="https://www.hca.wa.gov/employee-retiree-benefits/public-employees/spousal-coverage-surcharge" target="_blank">Learn about this surcharge</a> before you make your attestation.
        </span>
        <span *ngIf="isSebb">
          <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/tobacco-use-surcharge">Additional information on surcharges.</a>
          <br />
          <br />
          <a href="https://www.hca.wa.gov/employee-retiree-benefits/school-employees/surcharges" target="_blank">Learn about this surcharge</a> before you make your attestation.
        </span>
        <br />
        <br />
      </div>
    </div>
    <div fxLayout="column" [hidden]="displayConfirmation">
      <ol class="hca-ordered-list">
        <li [hidden]="question.sortOrder >= responseAffirmativeIndex + 1" *ngFor="let question of questions">
          <question
            [disabled]="question.sortOrder === 0 || question.sortOrder < responseAffirmativeIndex - 1"
            [responses]="question.responses"
            [responseId]="spousalQuestionResponses[question.questionId]"
            [question]="question"
            (answer)="onSpousalQuestionAnswered($event)"
          ></question>
        </li>
      </ol>
      <div style="border: 1px solid black;" fxFill fxLayoutAlign="center center" *ngIf="spousalAttestation.responseId === negativeResponseId && !this.medicalEnrolled">
        <p class="mgtp-tiny" style="text-align: center;">You have not elected to enroll this dependent in medical coverage. No spouse or state-registerd domestic partner coverage premium will apply.</p>
      </div>
      <span *ngIf="showCalculator">
        <strong>{{ inAdminState ? 'Subscriber' : 'You' }} may have to pay the spouse or state-registered domestic partner coverage surcharge in {{ effectiveYear }}.&nbsp;</strong>
        <a target="_blank" href="https://www.hca.wa.gov/employee-retiree-benefits/spousal-plan-calculator"> Go to the Spousal Plan Calculator </a>
        &nbsp;to determine.
        <br />
        <br />
        <ol>
          <li>Ask your spouse or SRDP for a Summary of Benefits and Coverage (SBC) for every medical plan that serves their county of residence and has a monthly premium of less than $137.76 per month for the employee.</li>
          <li>Use the SBC information to answer the questions in the Spousal Plan Calculator.
            <ul>
              <li>You will get a Yes or No response to whether the premium surcharge applies to you. </li>
              <li>Enter the response below.</li> 
            </ul>
          </li>
        </ol>
      </span>
      <br />
      <div>
        <span *ngIf="showCalculator">
          After completing the {{ effectiveYear }} Spousal Plan Calculator, did the calculator indicate the spouse or state-registered domestic surcharge coverage applies to
          {{ inAdminState ? 'Subscriber' : 'you' }} in {{ effectiveYear }}?
        </span>
      </div>
    </div>
    <br />

    <div fxLayout="row" [hidden]="displayConfirmation">
      <radio
        name="yes"
        [disabled]="someNegativeResponse"
        [value]="affirmativeResponseId"
        [(ngModel)]="spousalAttestation.responseId"
        (change)="updateSpousal()">
        Yes, {{ inAdminState ? 'subscriber is' : 'I am' }} subject to the spouse or state-registered domestic partner coverage surcharge in
        {{ effectiveYear }}
      </radio>
    </div>
    <div fxLayout="row" [hidden]="displayConfirmation">
      <radio
        name="no"
        [disabled]="someNegativeResponse"
        [value]="negativeResponseId"
        [(ngModel)]="spousalAttestation.responseId"
        (change)="updateSpousal()">
        No, the spouse or state-registered domestic partner coverage surcharge does not apply in {{ effectiveYear }}.
      </radio>
    </div>
    <!-- <div fxFlexFill fxLayout="row" fxLayoutAlign="end end">
      <button [hidden]="displayConfirmation" type="button" [disabled]="this.yesSpousal === undefined" fxFlex="30" (click)="displayConfirmation = true" class="btn btn-primary">Next</button> -->
      <!-- <button fxFlex="30" id="sub-add-cancel-btn" (click)="cancelAdd.emit()" class="btn btn-light">Cancel</button> -->
    <!-- </div> -->
    <div [hidden]="!displayConfirmation" fxLayout="column" fxLayoutAlign="center center">
      <p class="mgbt-large">
        Based on current attestations, you {{ spousalAttestation.responseId === affirmativeResponseId ? 'will' : 'will not' }} be charged the $50 spouse/state-registered domestic partner surcharge in addition to your monthly medical premium.
      </p>
    </div>
  </div>
  </fieldset>
</form>
