/**
 * The root component. All other states are injected based on routing in this components
 * router-outlet. Global componenets (agency select, header, footer) declared here.
 */

// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

// ext
import { Subscription } from 'rxjs';
import { upperFirst } from 'lodash';

// local
import { env } from 'src/env/development';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService, AccessLevel, UserTypeCode } from 'src/app/services/core.service';
import Plan from 'src/app/models/plan';

@Component({
  selector: 'plan-selection',
  templateUrl: 'planSelection.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class PlanSelectionComponent implements OnInit, OnDestroy {
  @Input() availablePlans: Plan[];
  @Input() planType: string;
  @Input() selectedPlan: Plan;
  @Input() isWaived = false;
  @Input() readonly = false;
  @Input() selectedPlanId: string[] = [];
  @Input() subscriberOrDependentsHaveMedicareB = false;
  @Input() memberTypeCode: string;
  @Input() medicareDEnabled = false;
  @Input() isRetireeOrCOBRA = false;
  @Output() updateSelectedPlan: EventEmitter<Plan> = new EventEmitter<Plan>();
  @Output() removeSelectedPlan: EventEmitter<Plan> = new EventEmitter<Plan>();
  @Output() getPlans: EventEmitter<boolean> = new EventEmitter<boolean>();
  availablePlanColumns = [];
  showAll = false;
  isHCAEditOrAdmin = false;

  constructor(
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.isHCAEditOrAdmin = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
    this.availablePlanColumns = [
      {
        field: 'planName',
        title: upperFirst(this.planType) + ' plan',
        format: 'text',
        // value: (row, originalValue) => ((this.medicareDEnabled && this.subscriberOrDependentsHaveMedicareB) ? row.medicareDPlanName : originalValue) ?? originalValue
        value: (row, originalValue) => (this.medicareDEnabled && this.isRetireeOrCOBRA && row.medicareDPlanName) ? `${originalValue} or ${row.medicareDPlanName}` : originalValue
      },
      {
        field: 'rateAmt',
        title: 'Premium',
        format: 'c0'
      }
    ];
  }

  ngOnDestroy(): void {
  }

  onPlanSelected(selectedPlan: Plan): void {
    this.updateSelectedPlan.emit(selectedPlan);
  }

  showAllChange(): void {
    this.getPlans.emit(this.showAll);
  }

  onPlanDeSelected(selectedPlan: Plan): void {
    this.removeSelectedPlan.emit(selectedPlan);
  }
}