import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Message from '../models/message';

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getMessages(): Observable<Message[]> {
    return this.http
    .get<Message[]>(`${env.apiUrl}/genericMessage`)
    .pipe(map(x => this.commonService.createObjects(Message, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getMessageById(messageId: string): Observable<Message> {
    return this.http
      .get<Message>(`${env.apiUrl}/genericMessage/${messageId}`)
      .pipe(map(x => this.commonService.createObject(Message, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createMessage(message: Message): Observable<Message> {
    return this.http
      .post<Message>(`${env.apiUrl}/genericMessage`, message)
      .pipe(map(x => this.commonService.createObject(Message, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateMessage(message: Message): Observable<Message> {
    return this.http
      .put<Message>(`${env.apiUrl}/genericMessage`, message)
      .pipe(map(x => this.commonService.createObject(Message, x)))
      .pipe(catchError(this.commonService.handleError));
  }
}