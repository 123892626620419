import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import SubscriberTransfer from 'src/app/models/subscriberTransfer';
import Organization from 'src/app/models/organization';

@Component({
  selector: 'transfer-found',
  templateUrl: 'transferFound.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class TransferFoundComponent implements OnInit {
  @Input() subscriberTransferRec: SubscriberTransfer;
  @Input() organization: Organization;
  @Input() noFlags = false;
  @Input() isHCA = false;
  @Input() medicalOnlyBenefitSetupId: string;
  @Output() cancelAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() claimSubscriber: EventEmitter<void> = new EventEmitter<void>();
  icons = {
    faExclamationTriangle
  };

  constructor() {}

  ngOnInit(): void {}

  pushCancelAdd(): void {
    this.cancelAdd.emit();
  }

  pushClaimSubscriber(): void {
    this.claimSubscriber.emit();
  }

  canBeReenrolledWithoutBreak(organization, transferRecord): boolean {
    if (transferRecord && transferRecord.organization?.organizationId === organization?.organizationId) {
      if (transferRecord.hasExistingCoverageForThisDate) {
        return true;
      }
    }

    return false;
  }

  canBeReenrolledWithBreak(organization, transferRecord): boolean {
    if (this.canBeReenrolledWithoutBreak(organization, transferRecord)) {
      return false;
    }

    if (transferRecord && transferRecord.organization?.organizationId === organization?.organizationId) {
        return true;
    }

    return false;
  }
}
