<ngb-accordion [destroyOnHide]="false" #certificationAccord="ngbAccordion">
  <ngb-panel> 
    <ng-template ngbPanelHeader let-opened="opened">
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
        <fa-icon class="hca-not-complete" style="margin-right: 15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
        <fa-icon class="hca-complete" style="margin-right: 15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
        <span>Certification</span>
        <span class="float-right">
          <fa-icon
            class="hca-fontawesome-icon"
            style="margin-left: 15px;"
            [icon]="icons.faClipboard"
            size="2x"
          >
          </fa-icon>
        </span>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
        <li *ngIf="isHCA" [ngbNavItem]="0">
          <a ngbNavLink aria-controls="certify" aria-selected="true"><b>Certify</b></a>
          <ng-template ngbNavContent>
            <form #employmentForm="ngForm" #formContainer>
            <div class="grid-container" style="padding:20px;">
              <kendo-grid
                #kendoGrid
                [data]="clonedGridData"
                class="k-grid-ignore-click"
                [filterable]="'menu'"
                [filter]="state.filter"
                [sortable]="true"
                [pageable]="true"
                [pageSize]="20"
                [skip]="state.skip"
                [sortable]="filterable"
                (add)="addHandler($event)"
              >
                <ng-template kendoGridToolbarTemplate>
                  <button kendoGridAddCommand type="button">Add new</button>
                </ng-template>    
                <kendo-grid-column
                  [style.flex]="1"
                  *ngFor="let col of gridColumns"
                  title="{{col.title}}"
                  field="{{col.field}}"
                  [headerStyle]="{ 'font-weight': 'bold' }"
                >
                  <ng-template kendoGridFilterMenuTemplate
                    let-column="col"
                    let-filter="filter"
                    let-filterService="filterService"
                    *ngIf="col.field.endsWith('Date')"
                    >
                    <date-picker
                      name="dateFilter"
                      [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
                      (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
                      [label]="col.title">
                    </date-picker>
                  </ng-template>
                  <ng-template kendoGridEditTemplate kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <select [disabled]="dataItem.relationshipCertificationId" *ngIf="col.field === 'certificationTypeId'" class="form-control k-grid-ignore-click" [id]="'certificationTypeId' + rowIndex" [name]="'certificationTypeId' + rowIndex" [(ngModel)]="dataItem[col.field]">
                        <option *ngFor="let ct of certificationTypes" [ngValue]="ct.certificationTypeId">{{ ct.certificationTypeName }}</option>
                      </select>
                      <div fxLayout="row" *ngIf="col.field === 'effectiveStartDate'">
                        <date-picker
                          [name]="'effectiveStartDate' + rowIndex"
                          required
                          [disabled]="dataItem.relationshipCertificationId != null && dataItem.certificationTypeId === tempCertTypeId"
                          [min]="minEffectiveStartDate[rowIndex]"
                          onlyAllowFirstOfMonth
                          [(ngModel)]="dataItem[col.field]"></date-picker>
                      </div>
                      <div fxLayout="row" *ngIf="col.field === 'effectiveEndDate'">
                        <date-picker
                          [name]="'effectiveEndDate' + rowIndex"
                          [required]="dataItem.certificationTypeId === tempCertTypeId"
                          [min]="dataItem.effectiveStartDate"
                          [disabled]="dataItem.relationshipCertificationId != null && dataItem.certificationTypeId === tempCertTypeId"
                          onlyAllowLastOfMonth
                          [(ngModel)]="dataItem[col.field]"></date-picker>
                      </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-command-column title="Manage" [headerStyle]="{ 'font-weight': 'bold' }" width="250">
                  <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <div fxLayoutAlign="space-around">
                      <button class="btn btn-primary" (click)="saveCertification(dataItem, rowIndex, formContainer, employmentForm)" class="btn btn-primary" type="button">Save</button>
                      <button class="btn hca-remove-button" (click)="deleteCertification(dataItem, rowIndex)" class="btn btn-primary" type="button">Delete</button>
                    </div>
                  </ng-template>
                </kendo-grid-command-column>
              </kendo-grid>
            </div>
          </form>
          </ng-template>
        </li>
        <li [ngbNavItem]="1">
          <a ngbNavLink aria-controls="status" aria-selected="true"><b>Status</b></a>
          <ng-template ngbNavContent kendoGridCellTemplate let-dataItem="dataItem">
            <div fxLayout="column" fxFlexFill style="padding:20px;">
              <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill>
                <div class="form-group required" fxFlex="35">
                  <label for="certStatus">Status</label>
                  <select [disabled]="!(isHCA && !isReadOnly)" required class="form-control" id="relationshipCertificationStatusId" name="relationshipCertificationStatusId" [(ngModel)]="relationshipClone.relationshipCertificationStatusId">
                    <option *ngFor="let cs of certificationStatuses" [ngValue]="cs.relationshipCertificationStatusId">{{ cs.relationshipCertificationStatusName }}</option>
                  </select>
                </div>
                <div class="form-group" fxFlex="20">
                  <date-picker
                    #reviewDate="ngModel"
                    name="reviewDate"
                    label="Review Date"
                    [disabled]="!(isHCA && !isReadOnly)"
                    [placeholder]="isReadOnly ? 'N/A' : 'mm/dd/yyyy'"
                    [(ngModel)]="relationshipClone.relationshipCertificationStatusDate"></date-picker>
                </div>
                <div class="form-group" fxFlex="20">
                  <date-picker
                    #certificationStart="ngModel"
                    name="certificationStart"
                    label="Certification Start Date"
                    disabled
                    placeholder="N/A"
                    [(ngModel)]="mostRecentCertification.effectiveStartDate"></date-picker>
                </div>
                <div class="form-group" fxFlex="20">
                  <date-picker
                    #certificationEnd="ngModel"
                    name="certificationEnd"
                    label="Certification End Date"
                    disabled
                    placeholder="N/A"
                    [(ngModel)]="mostRecentCertification.effectiveEndDate"></date-picker>
                </div>
              </div>
              <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlexFill style="padding:20px">
                <span *ngIf="isSubscriber">
                  For questions regarding certification status, please contact your payroll/benefits office.
                </span>
                <span *ngIf="isPerspay">
                  For questions regarding certification status, contact Outreach and Training via 
                  <a target="_blank" href="https://www.fuzeqna.com/perspay/ext/kbsearch.aspx">FUZE</a>
                  or by calling (800)700-1555.
                </span>
                <span *ngIf="isHCA">
                  Certification status, review date, certification start date, and certification end date for this dependent 
                  will be viewable by perspay users and the subscriber.
                </span>
              </div>
              <div *ngIf="isHCA" fxLayout="row" fxLayoutAlign="space-between" style="padding:20px;">
                <button [disabled]="isReadOnly" fxFlex="30" (click)="submitCertificationStatus()" class="btn btn-primary">Submit Changes</button>
                <button [disabled]="isReadOnly" fxFlex="30" (click)="cancelCertificationStatusChanges()" class="btn btn-light">Cancel Changes</button>
              </div>
            </div>
          </ng-template>
        </li>
     </ul>
     <div [ngbNavOutlet]="nav"></div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>