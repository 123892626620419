import { filter, groupBy, omit, toPairs, fromPairs, some, find } from 'lodash';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import SelfPay from 'src/app/models/selfPay';
import Plan from 'src/app/models/plan';
import { env } from 'src/env/development';
import Agency from 'src/app/models/agency';
import * as dayjs from 'dayjs';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
@Component({
  selector: 'self-pay-elect-dental',
  templateUrl: 'selfPay.electDental.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayElectDentalComponent implements OnInit {
  @Output() saveSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  @ViewChild('electDentalForm') public electDentalForm: NgForm;
  @Input() selfPay: SelfPay;
  @Input() availablePlans: Plan[];
  @Input() agency: Agency;
  dentalPlans: Plan[];
  groupedDentalPlans: {[k: string]: Plan[]};
  sebbDentalPlans: Plan[];
  selectedPlanId: string;
  env = env;
  isCobra: boolean = false;
  isRetiree = false;
  noDentalSelectionMade = false;
  isPebbRetireeOE = false;
  isCobraOE = false;
  ngOnInit(): void {
    this.isRetiree = this.selfPay?.selfPayType?.selfPayTypeCode === 'R';
    this.isCobra = env.cobraSelfPayTypeCodes.includes(this.selfPay.selfPayType.selfPayTypeCode);
    this.dentalPlans = filter(this.availablePlans, (ap) => ap.planType?.planTypeCode === '2');
    this.isPebbRetireeOE = ((this.selfPay?.selfPayType?.selfPayTypeCode === 'R' && dayjs(this.selfPay?.coverageEffectiveDate).isSameOrAfter(new Date('1/1/25'))) || this.selfPay?.selfPayType?.selfPayTypeCode === "OER");
    this.isCobraOE = this.selfPay?.selfPayType?.selfPayTypeCode === 'OEC';
    this.noDentalSelectionMade = !this.selfPay?.formJson?.subscriber?.dentalEnrollInd && !some(this.selfPay?.formJson?.dependents, (d: SelfPayFormDependent) => d.dentalCoverInd);
    // the only dental plan without a carrier is the default placeholder plan. This removes that plan from list.
    this.groupedDentalPlans = (omit(groupBy(this.dentalPlans, 'carrier.carrierName'), 'undefined'));
    this.groupedDentalPlans = {
      'Preferred Provider Organization (PPO)' : this.groupedDentalPlans[`Preferred Provider Organization (PPO)`],
      'Managed-care plans (limited network)' : this.groupedDentalPlans[`Managed-care plans (limited network)`]
    };
    this.isCobraOE = this.selfPay?.selfPayType?.selfPayTypeCode === 'OEC';
    if (this.agency.agencyCode === 'SEBB') {
      this.sebbDentalPlans = filter(this.dentalPlans, (p: Plan) => p.agencyId === this.agency.agencyId && !p.planName.includes('Default'));
    }
    if (this.selfPay.formJson.dentalPlan) {
      this.selectedPlanId = this.selfPay.formJson.dentalPlan.planId;
    }
  }

  setPlan(planId): void {
    this.selfPay.formJson.dentalPlan = find(this.availablePlans, (p: Plan) => p.planId === planId);
  }

  emitSelfPay(e): void {
    this.saveSelfPay.emit(e);
  }

  continueCurrentSelected(checked) {
    if(checked) {
      this.selfPay.formJson.dentalPlan = null;
    }
  }

}
