<ngb-accordion (panelChange)="updatePanelsExpanded.emit($event)" [activeIds]="activePanels" [destroyOnHide]="false" #coverageAccord="ngbAccordion" fxFlex>
  <ngb-panel [id]="memberId + plan" *ngFor="let plan of planTypes">
    <ng-template ngbPanelHeader let-opened="opened">
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
        <fa-icon class="hca-not-complete" style="margin-right:15px;" *ngIf="!opened" [icon]="icons.faPlus"></fa-icon>
        <fa-icon class="hca-complete" style="margin-right:15px;" *ngIf="opened" [icon]="icons.faMinus"></fa-icon>
        <span>{{plan === 'Health' ? 'Medical' : (plan === 'LTD' ? 'Supplemental ' + plan : plan)}}</span>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <div fxLayout="column">
        <div class="form-row hca-row-margin" fxLayoutAlign="space-between center" *ngFor="let enrollment of memberEnrollments[plan]; let enrollIndex = index">
          <form #editCoverageForm="ngForm" [name]="plan" fxLayout="column" fxLayoutAlign="start start"> 
            <div class="form-row hca-row-margin" fxLayout="row" fxLayoutAlign="space-between">
              <div class="form-group required " *ngIf="plan !== 'LTD' && plan !== 'BasicLTD' && plan !== 'Life' && plan !== 'AD&D' " fxFlex="25">
                <label for="{{ plan + 'Plan' + enrollIndex}}" style="font-weight: bold;">Plan:</label>
                <select required [disabled]="!editable" class="form-control" name="{{ plan + 'Plan' + enrollIndex}}" [(ngModel)]="enrollment.planId">
                  <option ></option>
                  <option *ngFor="let ap of availablePlans[plan]" [ngValue]="ap.planId">
                    {{ap.planName}}
                  </option>
                </select>
              </div>
              <div class="form-group required" *ngIf="enrollment.planId !== 'Waived'" fxFlex="25">
                <label for="{{plan + 'EnrollReason' + enrollIndex}}" style="font-weight: bold;">Enroll reason:</label>
                <select required class="form-control" [disabled]="!editable" name="{{plan + 'EnrollReason' + enrollIndex}}" [(ngModel)]="enrollment.enrollmentReasonId">
                  <option></option>
                  <option *ngFor="let er of isSubscriber ? lookups.subscriberEnrollmentReasons : lookups.dependentEnrollmentReasons" [ngValue]="er.reasonId">
                    {{er.reasonName}}
                  </option>
                </select>
              </div>
              <div class="form-group" *ngIf="enrollment.planId !== 'Waived'" fxFlex="25">
                <label for="{{plan + 'TermReason' + enrollIndex}}" style="font-weight: bold;">Term reason:</label>
                <select  [required]="enrollment.effectiveEndDate" class="form-control" [disabled]="!editable" name="{{plan + 'TermReason' + enrollIndex}}" [(ngModel)]="enrollment.terminationReasonId">
                  <option [value]="env.emptyGUIDString"></option>
                  <option *ngFor="let er of isSubscriber ? lookups.subscriberTerminationReasons : lookups.dependentTermintationReasons" [ngValue]="er.reasonId">
                    {{er.reasonName}}
                  </option>
                </select>
              </div>
              <div class="form-group" fxFlex="15">
                <button type="button" *ngIf="editable" style="margin-top:25px;" class="btn hca-red-background float-right" (click)="removeRecord(enrollment, plan, enrollIndex)"><fa-icon [icon]="icons.faTrashAlt" size="1x"></fa-icon></button>
              </div>
            </div>
            <div class="form-row hca-row-margin" fxLayout="row" fxLayoutAlign="space-between" fxFlex="40">
              <div class="form-group required" fxFlex="25">
                <div class="input-group">
                  <date-picker
                    #planstart="ngModel"
                    [name]="'planstart_' + enrollIndex"
                    label="Coverage begin"
                    required
                    [(ngModel)]="enrollment.effectiveStartDate">
                  </date-picker>
                </div>
              </div>
              <div class="form-group" fxFlex="25">
                <div class="input-group">
                  <date-picker
                    #planend="ngModel"
                    [name]="'planend_' + enrollIndex"
                    label="Coverage end"
                    [required] = "enrollment.terminationReasonId && enrollment.terminationReasonId != env.emptyGUIDString"
                    [(ngModel)]="enrollment.effectiveEndDate">
                  </date-picker>
                </div>
              </div>  
              <div class="form-group required " fxFlex="35">
                <label style="font-weight: bold;">Enrollment Period:</label>
                <div>
                  {{ enrollment.enrollmentPeriod?.enrollmentPeriodType.enrollmentPeriodTypeName }}
                  ({{enrollment?.enrollmentPeriod?.effectiveStartDate | date}}
                  {{(enrollment?.enrollmentPeriod?.effectiveEndDate ? ' - ' + (enrollment?.enrollmentPeriod?.effectiveEndDate | date) : '')}})
                </div>
              </div>
            </div>
          </form>
          <div class="form-row hca-row-margin" fxLayoutAlign="space-between center" fxFlex="50" *ngIf="enrollment.createDate || enrollment.modifiedDate" fxLayout="row" fxLayoutAlign="space-between">
            <div>
              <b>Create Date:</b> {{enrollment.createDate | date}}
            </div>
            <div>
              <b>Modified Date:</b> {{enrollment.modifiedDate | date}}
            </div>
          </div>   
        </div>
      </div>
      <div class="form-row hca-row-margin" *ngIf="editable" fxLayoutAlign="space-between">
        <button type="button" class="btn btn-primary"  (click)="addEnrollmentRec(plan)"><fa-icon [icon]="icons.faPlusCircle" size="1x"></fa-icon>  Add new enrollment (previous should first be termed)</button>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>