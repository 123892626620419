import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { env } from '../env/development';
import { CoreService } from './services/core.service';

export const httpLoaderFactory = (httpClient: HttpClient, coreService: CoreService) => {
  const config$ = httpClient.get<any>(`${env.apiUrl}/ClientAppSettings`).pipe(
    map((customConfig: any) => {
        var authConfig: OpenIdConfiguration = 
        {
            authority: customConfig.stsServer,
            redirectUrl: customConfig.redirect_url,
            clientId: customConfig.client_id,
            responseType: customConfig.response_type,
            scope: customConfig.scope,
            postLogoutRedirectUri: customConfig.post_logout_redirect_uri,
            startCheckSession: customConfig.start_checksession,
            silentRenew: customConfig.silent_renew,
            silentRenewUrl: customConfig.silent_renew_url,
            postLoginRoute: customConfig.startup_route,
            forbiddenRoute: customConfig.forbidden_route,
            unauthorizedRoute: customConfig.unauthorized_route,
            logLevel: customConfig.log_level ? LogLevel.Debug : LogLevel.None, //debug log level will indicate when silent renew will trigger in the console logs
            maxIdTokenIatOffsetAllowedInSeconds: customConfig.max_id_token_iat_offset_allowed_in_seconds,
            historyCleanupOff: true,
            //if access/id token expires in 20 min and this value is 5 min, silent renew will trigger after 15 min. 
            renewTimeBeforeTokenExpiresInSeconds: customConfig.silent_renew_time_before_token_expires_in_seconds 
        };

        if (customConfig.v) {
          coreService.setAPIVersion(customConfig.v);
        }
      return authConfig;
    })
  );

  return new StsConfigHttpLoader(config$);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, CoreService],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}