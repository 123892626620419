import MedicareOption from './medicareOption';

export default class MemberMedicare {
    memberId: string;
    medicarePartAEffectiveDate: Date;
    medicarePartAEndDate: Date;
    medicarePartBEffectiveDate: Date;
    medicarePartBEndDate: Date;
    medicareNumber: string;
    medicareEnrollmentSignatureDate: Date;
    medicarePartDEnrolledInd: boolean;
    medicarePartDEffectiveDate: Date;
    medicaidMedicarePartDEnrolledInd: boolean;
    medicaidMedicarePartDEffectiveDate: Date;    
    medicarePartAMedicareOption: MedicareOption;
    medicarePartBMedicareOption: MedicareOption;
    medicarePartAEnrolledInd: boolean;
    medicarePartBEnrolledInd: boolean;
    medicarePartAMedicareOptionId: string;
    medicarePartBMedicareOptionId: string;
    medicarePartDMedicareOption: MedicareOption;
    medicarePartDMedicareOptionId: string;
    medicaidMedicarePartDOption: MedicareOption;
    medicaidMedicarePartDOptionId: string;
    medicarePartDEndDate: Date;
    medicareDisenrollmentSignatureDate: Date;

    constructor(memberMedicare?) {
        if (memberMedicare) {
            this.memberId = memberMedicare.memberId;
            this.medicarePartAMedicareOption = new MedicareOption(memberMedicare.medicarePartAMedicareOption);
            this.medicarePartAEffectiveDate = memberMedicare.medicarePartAEffectiveDate ? new Date(memberMedicare.medicarePartAEffectiveDate) : null;
            this.medicarePartAEndDate = memberMedicare.medicarePartAEndDate ? new Date(memberMedicare.medicarePartAEndDate) : null;
            this.medicarePartBMedicareOption = new MedicareOption(memberMedicare.medicarePartBMedicareOption);
            this.medicarePartBEffectiveDate = memberMedicare.medicarePartBEffectiveDate ? new Date(memberMedicare.medicarePartBEffectiveDate) : null;
            this.medicarePartBEndDate = memberMedicare.medicarePartBEndDate ? new Date(memberMedicare.medicarePartBEndDate) : null;
            this.medicareNumber = memberMedicare.medicareNumber;
            this.medicareEnrollmentSignatureDate = memberMedicare.medicareEnrollmentSignatureDate ? new Date(memberMedicare.medicareEnrollmentSignatureDate) : null;
            this.medicarePartDEnrolledInd = memberMedicare.medicarePartDEnrolledInd;
            this.medicarePartDEffectiveDate = memberMedicare.medicarePartDEffectiveDate ? new Date(memberMedicare.medicarePartDEffectiveDate) : null;
            this.medicaidMedicarePartDEnrolledInd = memberMedicare.medicaidMedicarePartDEnrolledInd;
            this.medicaidMedicarePartDEffectiveDate = memberMedicare.medicaidMedicarePartDEffectiveDate ? new Date(memberMedicare.medicaidMedicarePartDEffectiveDate) : null;
            this.medicarePartAMedicareOptionId = memberMedicare.medicarePartAMedicareOptionId;
            this.medicarePartBMedicareOptionId = memberMedicare.medicarePartBMedicareOptionId;
            this.medicarePartAEnrolledInd = memberMedicare.medicarePartAEnrolledInd;
            this.medicarePartBEnrolledInd = memberMedicare.medicarePartBEnrolledInd;
            this.medicarePartDMedicareOption =  new MedicareOption(memberMedicare.medicarePartDMedicareOption);
            this.medicarePartDMedicareOptionId = memberMedicare.medicarePartDMedicareOptionId;
            this.medicaidMedicarePartDOption =  new MedicareOption(memberMedicare.medicaidMedicarePartDOption);
            this.medicaidMedicarePartDOptionId = memberMedicare.medicaidMedicarePartDOptionId;
            this.medicareDisenrollmentSignatureDate = memberMedicare.medicareDisenrollmentSignatureDate ? new Date(memberMedicare.medicareDisenrollmentSignatureDate) : null;
            this.medicarePartDEndDate = memberMedicare.medicarePartDEndDate ? new Date(memberMedicare.medicarePartDEndDate) : null;
         }
    }
}