export default class Milestone {
    milestoneId: string;
    milestoneName: string;
    effectiveStartDate: Date;
    effectiveEndDate: Date;
    sortOrder: number;
    isComplete: boolean;
    completedDate: Date;
    constructor(milestone?: any) {
      if (milestone) {
        this.milestoneId = milestone.milestoneId;
        this.effectiveStartDate = milestone.effectiveStartDate ? new Date(milestone.effectiveStartDate) : null;
        this.effectiveEndDate = milestone.effectiveEndDate ? new Date(milestone.effectiveEndDate) : null;
        this.milestoneName = milestone.milestoneName;
        this.sortOrder = milestone.sortOrder;
        this.isComplete = milestone.isComplete;
        this.completedDate = milestone.completedDate ? new Date(milestone.completedDate) : null;
      }
    }
  }
