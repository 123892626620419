<div fxFlex fxFlexFill fxLayout="column" class="container-fluid" *ngIf="!isPerspayAny">
  <div class="state" role="group">
    <div class="card" class="fxGap-medium-sm">
      <div class="card-body" fxLayout="column" *ngIf="step === 0">
        <soe-instructions *ngIf="isSOE" isSelfPay="true"></soe-instructions>
        <ng-container *ngIf="!isSOE">
          <div class="ie-height-mobile-l hca-dashboard-card-container" fxLayout="row wrap">
            <div class="hca-dashboard-card" fxFlex.xs="100%">
              <div class="card-body">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <h2 role="heading" class="mobile-text-centered mgtp-small">PEBB Retiree insurance coverage</h2>
                </div>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
                  <div *ngIf="continuationCoverageAvailable" fxLayout="column" [fxFlex]="hasApprovedRequest ? 100 : 75"
                    class="mgbt-small">
                    <ul>
                      <li>
                        As a retiring employee, if you meet procedural and eligibility requirements, you can enroll in
                        {{currentOrganizationAgencyCode}}
                        retiree insurance coverage, which offers a range of medical and dental plans to choose from and
                        retiree term life insurance.
                      </li>
                      <li>
                        If you meet the eligibility requirements and you’re not ready to enroll in
                        {{currentOrganizationAgencyCode}} retiree insurance
                        coverage because you have other qualified medical coverage, such as employer health coverage
                        through your spouse or state-registered
                        domestic partner, you may choose to defer (postpone) enrollment. When you defer, you are
                        postponing enrollment in both medical and
                        dental coverage. Deferring your enrollment will retain your eligibility in case you want to
                        enroll later.
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="!isSOE && canAdd && hasRetiree && !hasApprovedRequest" fxLayout="column"
                    class="form-group" fxFlex="25" fxLayoutAlign="top center">
                    <button type="submit" class="btn btn-primary" (click)="addRetiree()">
                      Create Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ie-height-mobile-l hca-dashboard-card-container" fxLayout="row wrap">
            <div class="hca-dashboard-card" fxFlex.xs="100%">
              <div class="card-body">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <h2 role="heading" class="mobile-text-centered mgtp-small">{{currentOrganizationAgencyCode}}
                    Continuation Coverage (COBRA)</h2>
                </div>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
                  <div *ngIf="continuationCoverageAvailable" fxLayout="column" [fxFlex]="hasApprovedRequest ? 100 : 75"
                    class="mgbt-small">
                    <ul>
                      <li>
                        {{currentOrganizationAgencyCode}} Continuation Coverage (COBRA) is a temporary extension of
                        {{currentOrganizationAgencyCode}} health plan coverage
                        available to {{currentOrganizationAgencyCode}} members who are qualified beneficiaries under
                        federal Consolidated Omnibus
                        Budget Reconciliation Act (COBRA) rules.
                      </li>
                      <li>
                        HCA also extends {{currentOrganizationAgencyCode}} Continuation Coverage (COBRA) to
                        state-registered domestic partners and their children, based on RCW 26.60.015 and
                        {{currentOrganizationAgencyCode}}
                        policy resolution that extends {{currentOrganizationAgencyCode}} coverage for dependents not
                        otherwise eligible for COBRA. Coverage may be temporarily
                        extended only if the {{currentOrganizationAgencyCode}} member experiences a qualifying event.
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="!isSOE && canAdd && hasCobra && !hasApprovedRequest" fxLayout="column" class="form-group"
                    fxFlex="25" fxLayoutAlign="top center">
                    <button type="submit" class="btn btn-primary" (click)="addCobra()">
                      Create Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ie-height-mobile-l hca-dashboard-card-container" fxLayout="row wrap">
            <div class="hca-dashboard-card" fxFlex.xs="100%">
              <div class="card-body">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <h2 role="heading" class="mobile-text-centered mgtp-small">{{currentOrganizationAgencyCode}}
                    Continuation Coverage (Unpaid Leave)</h2>
                </div>
                <div class="form-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between">
                  <div *ngIf="continuationCoverageAvailable" fxLayout="column" [fxFlex]="hasApprovedRequest ? 100 : 75"
                    class="mgbt-small">
                    <ul>
                      <li>
                        {{currentOrganizationAgencyCode}} Continuation Coverage (Unpaid Leave) is a temporary extension
                        of {{currentOrganizationAgencyCode}} insurance coverage for employees who lose eligibility for
                        the employer contribution toward {{currentOrganizationAgencyCode}} benefits due to specific
                        types of leave.
                      </li>
                    </ul>
                    <a *ngIf="isPEBB" href="https://www.hca.wa.gov/employee-retiree-benefits/pebb-continuation-coverage"
                      target="_blank">Learn more about PEBB Continuation Coverage</a>
                    <a *ngIf="!isPEBB"
                      href="https://www.hca.wa.gov/employee-retiree-benefits/continuation-coverage/sebb-continuation-coverage-premiums"
                      target="_blank">Learn more about SEBB continuation coverage</a>
                  </div>
                  <div *ngIf="!isSOE && canAdd && hasLwop && !hasApprovedRequest" fxLayout="column" class="form-group"
                    fxFlex="25" fxLayoutAlign="top center">
                    <button type="submit" class="btn btn-primary" (click)="addLwop()">
                      Create Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="step === 0 && (isSOE || (subscriber?.selfPays && subscriber.selfPays.length))" fxLayout="column"
        fxLayoutAlign="center center">
        <h3 class="h4">Select the check-box to the left to manage the
          <span class="h4" *ngIf="isSOE">event and/or</span>
          <span class="h4" *ngIf="!isSOE">coverage request and</span> view related forms
        </h3>
        <div class="pad-std-container">
          <self-pay-grid #selfPayGridControl (downloadCoveragePDF)="downloadCoveragePDF($event)"
            (downloadSelfPayPDF)="downloadSelfPayPDF($event)" (removeSelfPay)="deleteSelfPay($event)"
            (editSelfPay)="editSelfPay($event)" [selfPays]="subscriber?.selfPays" [isReadOnly]="isReadOnly"
            [isPerspay]="isPerspay" [isHcaEdit]="isHcaEdit" [isSOE]="isSOE"></self-pay-grid>
        </div>
        <div class="pad-std-container" fxFlexFill>
          <self-pay-add #selfPayAdd *ngIf="!isReadOnly && (isSOE || hasApprovedRequest)"
            [selfPayVerificationStatus]="lookups.selfPayVerificationStatus"
            [currentOrganizationAgencyCode]="currentOrganizationAgencyCode" [canAdd]="canAdd" [isSOE]="isSOE"
            [subscriber]="subscriber" (saveSelfPay)="saveSelfPay($event)" class="mgtp-medium mgbt-medium"
            [selfPayTypes]="selfPayTypeList"></self-pay-add>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center">
        <ng-container *ngIf="soeCustomForm && selectedSelfPay && step === 1">
          <self-pay-change-of-address *ngIf="this.selectedSelfPay?.selfPayType?.selfPayTypeCode=='SOEA'" fxFlexFill
            [subscriber]="subscriber" [selectedSelfPay]="selectedSelfPay" [currentDependents]="currentDependents"
            (saveSelfPay)="saveSelfPay($event,true)" (previous)="previous()">
          </self-pay-change-of-address>
        </ng-container>
        <ng-container
          *ngIf="!soeCustomForm && selectedSelfPay?.selfPayType?.selfPayTypeCode !== 'OER' && selectedSelfPay?.selfPayType?.selfPayTypeCode !== 'OEC' ">
          <div *ngIf="step === 1 && showAdminPrompt" fxLayout="column" class="fxGap-large"
            style="padding-bottom: 100px">
            <h2 class="prompt-question">Would you like to opt-out of completing the {{
              selectedSelfPay?.selfPayType?.selfPayTypeName }} form?</h2>
            <div fxLayout="row" fxLayoutAlign="space-between">
              <yes-no name="optOut" style="margin-bottom: 30px; padding: 0 75px;" [(ngModel)]="proceedThroughWiz"
                (change)="proceed(proceedThroughWiz)"></yes-no>
            </div>
          </div>
          <self-pay-info *ngIf="step === 1 && !showAdminPrompt" [agency]="selectedSelfPay?.agency?.agencyCode"
            [requestType]="selectedSelfPay.selfPayType"
            [specialOpenEnrollmentTypes]="lookups.specialOpenEnrollmentTypes"
            [selectedSpecialOpenEnrollmentTypeId]="selectedSelfPay.specialOpenEnrollmentTypeId"
            (selectedSpecialOpenEnrollmentType)="selectedSelfPay.specialOpenEnrollmentTypeId = $event?.specialOpenEnrollmentTypeId"
            (continue)="continue()" (previous)="previous()"></self-pay-info>
          <self-pay-payment *ngIf="step === 2" [agency]="selectedSelfPay?.agency?.agencyCode"
            [requestType]="selectedSelfPay.selfPayType" [selfPayPaymentType]="selectedSelfPay.selfPayPaymentType"
            [selectedSelfPay]="selectedSelfPay" [subscriber]="subscriber"
            (saveSelfPayPaymentOption)="updatePaymentOption($event)" (previous)="previous()"></self-pay-payment>
          <self-pay-reason *ngIf="step === 3 && selectedSelfPay.selfPayType.selfPayTypeCode === 'U'"
            [selfPay]="selectedSelfPay" (saveSelfPayReason)="saveSelfPay($event)"
            (previous)="previous()"></self-pay-reason>
          <self-pay-subscriber *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 4 : step === 3"
            [agency]="selectedSelfPay?.agency?.agencyCode" [selfPay]="selectedSelfPay" [subscriber]="subscriber"
            [lookups]="lookups" [isPerspay]="isPerspay" (saveSelfPay)="saveSelfPay($event)" (previous)="previous()"
            [ltdAvailablePlans]="activePlans" [pebbVisionDate]="pebbVisionDate"></self-pay-subscriber>

          <self-pay-dependent *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 5 : step === 4"
            [agency]="selectedSelfPay?.agency?.agencyCode" (saveSelfPay)="saveSelfPay($event)" (previous)="previous()"
            [selfPay]="selectedSelfPay" [currentDependents]="currentDependents" [lookups]="lookups"
            [pebbVisionDate]="pebbVisionDate"></self-pay-dependent>
          <self-pay-elect-medical fxFill [agency]="selectedSelfPay?.agency" [ethnicities]="ethnicities"
            *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 6 : step === 5"
            (saveSelfPay)="saveSelfPay($event)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [availablePlans]="activePlans" [lookups]="lookups"></self-pay-elect-medical>

          <self-pay-elect-dental fxFill [agency]="selectedSelfPay?.agency"
            *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 7 : step === 6"
            (saveSelfPay)="saveSelfPay($event)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [availablePlans]="activePlans"></self-pay-elect-dental>
          <self-pay-elect-vision fxFill [agency]="selectedSelfPay?.agency"
            *ngIf="(selectedSelfPay?.agency?.agencyCode === 'SEBB' && (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 8 : step === 7))"
            (saveSelfPay)="saveSelfPay($event)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [availablePlans]="activePlans"></self-pay-elect-vision>
          <self-pay-elect-vision fxFill [agency]="selectedSelfPay?.agency" *ngIf="(isPebbLwop && showVision)"
            (saveSelfPay)="saveSelfPay($event)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [availablePlans]="activePlans"></self-pay-elect-vision>
          <self-pay-upload fxFill *ngIf="selectedSelfPay?.agency?.agencyCode === 'PEBB' ? 
              (isPebbLwop ? showUpload : (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 8 : step === 7)) : 
              (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 9 : step === 8)"
            (refetchSelfPay)="refetchSelfPay($event)" (saveSelfPay)="saveSelfPay($event)" (previous)="previous()"
            [selfPay]="selectedSelfPay" [agency]="selectedSelfPay?.agency?.agencyCode"
            [documentTypes]="lookups.documentType" [isRetiree]="hasRetiree" [subscriber]="subscriber"></self-pay-upload>
          <self-pay-confirm (downloadPDF)="downloadSelfPayPDF()" *ngIf="selectedSelfPay?.agency?.agencyCode === 'PEBB' ? 
            (isPebbLwop ? showConfirm :(selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 9 : step === 8)) :
            (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 10 : step === 9)"
            (saveSelfPay)="saveSelfPay($event, true)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [agency]="selectedSelfPay?.agency?.agencyCode"
            [requestType]="selectedSelfPay.selfPayType"></self-pay-confirm>
        </ng-container>

        <!-- OE MED D VISION -->
        <ng-container
          *ngIf="!soeCustomForm && (selectedSelfPay?.selfPayType?.selfPayTypeCode === 'OER' || selectedSelfPay?.selfPayType?.selfPayTypeCode === 'OEC')">
          <div *ngIf="step === 1 && showAdminPrompt" fxLayout="column" class="fxGap-large"
            style="padding-bottom: 100px">
            <h2 class="prompt-question">Would you like to opt-out of completing the {{
              selectedSelfPay?.selfPayType?.selfPayTypeName }} form?</h2>
            <div fxLayout="row" fxLayoutAlign="space-between">
              <yes-no name="optOut" style="margin-bottom: 30px; padding: 0 75px;" [(ngModel)]="proceedThroughWiz"
                (change)="proceed(proceedThroughWiz)"></yes-no>
            </div>
          </div>
          <self-pay-info *ngIf="step === 1 && !showAdminPrompt" [agency]="selectedSelfPay?.agency?.agencyCode"
            [requestType]="selectedSelfPay.selfPayType"
            [specialOpenEnrollmentTypes]="lookups.specialOpenEnrollmentTypes"
            [selectedSpecialOpenEnrollmentTypeId]="selectedSelfPay.specialOpenEnrollmentTypeId"
            (selectedSpecialOpenEnrollmentType)="selectedSelfPay.specialOpenEnrollmentTypeId = $event?.specialOpenEnrollmentTypeId"
            (continue)="continue()" (previous)="previous()"></self-pay-info>
          <self-pay-reason *ngIf="step === 2 && selectedSelfPay.selfPayType.selfPayTypeCode === 'U'"
            [selfPay]="selectedSelfPay" (saveSelfPayReason)="saveSelfPay($event)"
            (previous)="previous()"></self-pay-reason>
          <self-pay-subscriber *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 3 : step === 2"
            [agency]="selectedSelfPay?.agency?.agencyCode" [selfPay]="selectedSelfPay" [subscriber]="subscriber"
            [lookups]="lookups" [isPerspay]="isPerspay" (saveSelfPay)="saveSelfPay($event)" (previous)="previous()"
            [ltdAvailablePlans]="activePlans" [isRetireeOE]="selectedSelfPay?.selfPayType?.selfPayTypeCode == 'OER'"
            [isCobraOE]="selectedSelfPay?.selfPayType?.selfPayTypeCode == 'OEC'"
            [pebbVisionDate]="pebbVisionDate"></self-pay-subscriber>

          <self-pay-dependent *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 4 : step === 3"
            [agency]="selectedSelfPay?.agency?.agencyCode" (saveSelfPay)="saveSelfPay($event)" (previous)="previous()"
            [selfPay]="selectedSelfPay" [currentDependents]="currentDependents" [lookups]="lookups"
            [pebbVisionDate]="pebbVisionDate"></self-pay-dependent>
          <self-pay-elect-medical fxFill [agency]="selectedSelfPay?.agency" [ethnicities]="ethnicities"
            *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 5 : step === 4"
            (saveSelfPay)="saveSelfPay($event)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [availablePlans]="activePlans" [lookups]="lookups"></self-pay-elect-medical>

          <self-pay-elect-dental fxFill [agency]="selectedSelfPay?.agency"
            *ngIf="selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 6 : step === 5"
            (saveSelfPay)="saveSelfPay($event)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [availablePlans]="activePlans"></self-pay-elect-dental>
          <self-pay-elect-vision fxFill [agency]="selectedSelfPay?.agency"
            *ngIf="(selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 7 : step === 6)"
            (saveSelfPay)="saveSelfPay($event)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [availablePlans]="activePlans"></self-pay-elect-vision>
          <self-pay-upload fxFill
            *ngIf="selectedSelfPay?.agency?.agencyCode === 'PEBB' ? (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 8 : step === 7) : (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 9 : step === 8)"
            (refetchSelfPay)="refetchSelfPay($event)" (saveSelfPay)="saveSelfPay($event)" (previous)="previous()"
            [selfPay]="selectedSelfPay" [agency]="selectedSelfPay?.agency?.agencyCode"
            [documentTypes]="lookups.documentType" [isRetiree]="hasRetiree" [subscriber]="subscriber"></self-pay-upload>
          <self-pay-payment
            *ngIf="selectedSelfPay?.agency?.agencyCode === 'PEBB' ? (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 9 : step === 8) : (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 10 : step === 9)"
            [agency]="selectedSelfPay?.agency?.agencyCode" [requestType]="selectedSelfPay.selfPayType"
            [selfPayPaymentType]="selectedSelfPay.selfPayPaymentType" [selectedSelfPay]="selectedSelfPay"
            [subscriber]="subscriber" (saveSelfPayPaymentOption)="updatePaymentOption($event)"
            (previous)="previous()"></self-pay-payment>
          <self-pay-confirm (downloadPDF)="downloadSelfPayPDF()"
            *ngIf="selectedSelfPay?.agency?.agencyCode === 'PEBB' ? (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 10 : step === 9) : (selectedSelfPay?.selfPayType.selfPayTypeCode === 'U' ? step === 11 : step === 10)"
            (saveSelfPay)="saveSelfPay($event, true)" (previous)="previous()" [selfPay]="selectedSelfPay"
            [agency]="selectedSelfPay?.agency?.agencyCode"
            [requestType]="selectedSelfPay.selfPayType"></self-pay-confirm>
        </ng-container>

      </div>
    </div>
    <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
      <button style="margin: 30px" (click)="navToDashboard()" class="btn btn-secondary">Back to dashboard</button>
    </div>
    <div>
      <pdf #selfPayPDF *ngIf="selectedSelfPay"
        [documentTitleText]="selectedSelfPay?.agency?.agencyCode + ' ' + capitalize(selectedSelfPay.selfPayType?.selfPayTypeName)"
        [documentHeaderText]="formHeaderText" [containerClass]="pdfContainerClass" aria-hidden="true">
        <self-pay-pdf *ngIf="!soeCustomForm" [selfPay]="selectedSelfPay"
          [selfPayPaymentTypes]="lookups.selfPayPaymentType" [birthSexes]="lookups.birthSexes"
          [genderIdentities]="lookups.genderIdentities" [availablePlans]="availablePlans" [ethnicities]="ethnicities"
          [pebbVisionDate]="pebbVisionDate">
        </self-pay-pdf>
        <self-pay-custom-form-pdf *ngIf="soeCustomForm" [selfPay]="selectedSelfPay">
        </self-pay-custom-form-pdf>
      </pdf>
    </div>
  </div>
</div>