import { faPlus, faMinus, faCalendar } from '@fortawesome/free-solid-svg-icons';
// ng
import { ViewChild, Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';

// ext
import { lastValueFrom, Subscription } from 'rxjs';
import { some, forEach, filter, cloneDeep } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { AccessLevel, CoreService } from 'src/app/services/core.service';
import { AttestationService } from 'src/app/services/attestation.service';
import Subscriber from 'src/app/models/subscriber';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import Milestone from 'src/app/models/milestone';
import Attestation from 'src/app/models/attestation';
import Response from 'src/app/models/response';
import { ActivatedRoute, Router } from '@angular/router';
import { find } from 'lodash';
import AttestationType from 'src/app/models/attestationType';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
import PlanType from 'src/app/models/planType';
import Member from 'src/app/models/member';
import Enrollment from 'src/app/models/enrollment';
import { NgForm } from '@angular/forms';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';

@UntilDestroy()
@Lookups(LookupType.AttestationType, LookupType.Response)
@Component({
  selector: 'attest-tobacco-outside',
  templateUrl: 'tobacco.outsideEP.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class AttestTobaccoOutsideComponent implements OnInit, OnDestroy {
  subscriber: Subscriber;
  responses: Response[];
  attestationTypes: AttestationType[];
  tobaccoAttestationType: AttestationType;
  step = 0;
  icons = {
    faTasks,
    faPlus,
    faMinus,
    faCalendar,
  };
  showNextButton = false;
  isActiveSelfPay = false;
  affirmativeResponseId: string;
  negativeResponseId: string;
  initialFilteredMembers: Member[];
  filteredMembers: Member[];
  now = new Date();
  isReadOnly = false;
  @ViewChild('attestationForm') attestationForm: NgForm;

  constructor(
    private spinnerService: SpinnerOverlayService,
    private attestationService: AttestationService,
    private enrollmentPeriodService: EnrollmentPeriodService,
    private coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.subscriber = data.subscriber;
      this.filteredMembers = this.filterOutNAMembers(this.subscriber.members);
      this.initialFilteredMembers = cloneDeep(this.filteredMembers);
      this.responses = data.lookups.response;
      this.affirmativeResponseId = find(data.lookups.response, (r: Response) => r.responseCode === 'Y').responseId;
      this.negativeResponseId = find(data.lookups.response, (r: Response) => r.responseCode === 'N').responseId;
      this.attestationTypes = data.lookups.attestationType;
      this.tobaccoAttestationType = find(this.attestationTypes, (at: AttestationType) => at.attestationTypeCode === 'TS');
      this.filteredMembers.forEach((m: Member) => {
        if (m.mostRecentTobaccoAttestation.responseId) {
          m.tobaccoAttestationInd = m.mostRecentTobaccoAttestation.responseId === this.affirmativeResponseId;
        }
      });
      this.isActiveSelfPay =  this.subscriber.memberSelfPayOrigin && this.subscriber.memberSelfPayOrigin.agencyEffectiveEndDate == null || dayjs(this.subscriber.memberSelfPayOrigin.agencyEffectiveEndDate).isBefore(dayjs());
    });
  }

  ngOnDestroy(): void {}

  async saveAttestations(): Promise<void> {
    const attestationChanges = [];
    // O^n^2 again - refactor later
    this.filteredMembers.forEach((m: Member) => {
      const initialMemberRecord = find(this.initialFilteredMembers, (im) => im.memberId === m.memberId); 
      if((m.mostRecentTobaccoAttestation.responseId !== initialMemberRecord.mostRecentTobaccoAttestation.responseId || !dayjs(m.mostRecentTobaccoAttestation.attestationDate).isSame(initialMemberRecord.mostRecentTobaccoAttestation.attestationDate), 'day')) {

          var dateChanged = !dayjs(m.mostRecentTobaccoAttestation.attestationDate).isSame(initialMemberRecord.mostRecentTobaccoAttestation.attestationDate, 'day');
          var responseChanged = m.mostRecentTobaccoAttestation.responseId !== initialMemberRecord.mostRecentTobaccoAttestation.responseId
          if(dateChanged || responseChanged) {
            const attestation = new Attestation();
            attestation.memberId = m.memberId;
            attestation.effectiveStartDate = dayjs().isSame(dayjs().startOf('month'), 'day') ? dayjs().startOf('month').toDate() : dayjs().add(1, 'month').startOf('month').toDate();
            attestation.attestationTypeId = this.tobaccoAttestationType.attestationTypeId;
            attestation.responseId = m.mostRecentTobaccoAttestation.responseId;
            attestation.attestationDate = m.mostRecentTobaccoAttestation.attestationDate;
            attestationChanges.push(lastValueFrom(this.attestationService.createTobaccoActionNoEP(attestation)));
          }
        }
      });

    this.spinnerService.show();
    try {
      await Promise.all(attestationChanges);
    } catch (err) {
      console.log(err);
    }

    this.subscriber.refetch = true;
    this.coreService.setSubscriber(this.subscriber);
    this.coreService.popMessage('Your tobacco attestations have been updated', 'success', 8000);
    this.spinnerService.hide();
    this.navToDashboard();
  }

  getAttestationResponseText(member: Member): string {
    if (member.mostRecentTobaccoAttestation?.responseId) {
      return member.mostRecentTobaccoAttestation.responseId === this.affirmativeResponseId ? 'Yes' : 'No';
    }

    // Not set, default to Yes, unless under 13
    if (member.isUnderThirteen) {
      return 'No';
    }

    return 'Yes';
  }

  updateStep(s: number): void {
    this.step = s;
  }

  panelChange(): void {}

  updateTobacco(e, memberId): void {
    var tobaccoAttestationResponseId = e == true ? this.affirmativeResponseId : this.negativeResponseId;
    this.filteredMembers.forEach((m: Member) => {
      if ((m.memberId == memberId)) {
        m.mostRecentTobaccoAttestation.responseId = tobaccoAttestationResponseId;
      }
    });
    this.showNextButton = true;
  }

  next(): void {
    const isValid = this.attestationForm.valid;

    if (!isValid) {
      this.coreService.popMessage('Fields missing or invalid, please review and resubmit.', 'error', 3000);
    } else {
      this.step = 1;
    }
  }

  navToDashboard(): void {
    this.coreService.setEnrollmentPeriod(null);
    const sub = this.coreService.getSubscriberValue();
    this.router.navigate([`../../../subscriber/${sub.memberId}`], { relativeTo: this.route });
  }

  get usesTobacco(): boolean {
    // Any filtered member who has answered YES to tobacco attestation *OR* has not provided a response (default = YES).
    return some(this.filteredMembers, (m: Member) => !m.mostRecentTobaccoAttestation?.responseId || m.mostRecentTobaccoAttestation.responseId === this.affirmativeResponseId);
  }

  filterOutNAMembers(members: Member[]): Member[] {
    // get only those over 13 who are electing into medical.
    return filter(members, (m: Member) => !m.isUnderThirteen && some(m.enrollments, (e: Enrollment) => e.plan.planType.planTypeCode === '1' && e.isActive()));
  }
}
