import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import OrganizationContact from '../models/organizationContact';
import ContactType from '../models/contactType';
@Injectable({
  providedIn: 'root'
})
export class OrganizationContactService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  getOrganizationContactById(organizationContactId: string, organizationId: string): Observable<OrganizationContact> {
    return this.http.get<OrganizationContact>(`${env.apiUrl}/organization/${organizationId}/contact/${organizationContactId}`)
    .pipe(map(x => this.commonService.createObject(OrganizationContact, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createOrganizationContact(organizationContact: OrganizationContact): Observable<OrganizationContact> {
    return this.http.post<OrganizationContact>(`${env.apiUrl}/organization/${organizationContact?.organizationId}/contact`, organizationContact)
    .pipe(map(x => this.commonService.createObject(OrganizationContact, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateOrganizationContact(organizationContact: OrganizationContact): Observable<OrganizationContact> {
    return this.http.put<OrganizationContact>(
      `${env.apiUrl}/organization/${organizationContact?.organizationId}/contact/${organizationContact?.organizationContactId}`, organizationContact)
    .pipe(map(x => this.commonService.createObject(OrganizationContact, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  deleteOrganizationContact(organizationContact: OrganizationContact): Observable<void> {
    return this.http.delete(
     `${env.apiUrl}/organization/${organizationContact?.organizationId}/contact/${organizationContact?.organizationContactId}`)
    .pipe(catchError(this.commonService.handleError));
  }

  getOrganizationContactsForAgency(organizationId: string): Observable<OrganizationContact[]> {
    return this.http.get<OrganizationContact[]>(
      `${env.apiUrl}/organization/${organizationId}/contact`)
      .pipe(map(x => this.commonService.createObjects(OrganizationContact, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createContactTypeForContact(contact: OrganizationContact, contactType: ContactType): Observable<void> {
    return this.http.post<void>(`${env.apiUrl}/organization/${contact?.organizationId}/contact/${contact?.organizationContactId}/contactType/${contactType?.contactTypeId}`, {})
    .pipe(catchError(this.commonService.handleError));
  }

  deleteOrganizationContactType(organizationContact: OrganizationContact, contactTypeId: string): Observable<void> {
    return this.http.delete(
     `${env.apiUrl}/organization/${organizationContact?.organizationId}/organizationcontact/${organizationContact?.organizationContactId}/contacttype/${contactTypeId}`)
    .pipe(catchError(this.commonService.handleError));
  }
}
