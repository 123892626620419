// ng
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ext
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// local
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Attestation from 'src/app/models/attestation';

@Injectable({
  providedIn: 'root'
})

export class AttestationService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createAction(attestation: Attestation, enrollmentPeriodId: string): Observable<Attestation> {
    return this.http
      .post<Attestation>(`${env.apiUrl}/Member/${attestation.memberId}/attestation/${enrollmentPeriodId}`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createTobaccoAction(attestation: Attestation, enrollmentPeriodId: string, subscriberId?: string): Observable<Attestation> {
    return this.http
      .post<Attestation>(`${env.apiUrl}/Member/${subscriberId ?? attestation.memberId}/attestation/tobacco/${enrollmentPeriodId}`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createTobaccoActionForSOE(attestation: Attestation, enrollmentPeriodId: string, subscriberId?: string, specialOpenEnrollmentId?: string): Observable<Attestation> {
    return this.http
      .post<Attestation>(`${env.apiUrl}/Member/${subscriberId ?? attestation.memberId}/attestation/tobacco/${enrollmentPeriodId}/soe/${specialOpenEnrollmentId}`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateTobaccoAction(attestation: Attestation, subscriberId?: string): Observable<Attestation> {
    return this.http
      .put<Attestation>(`${env.apiUrl}/Member/${subscriberId ?? attestation.memberId}/attestation/tobacco/${attestation.attestationId}`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createSpousalAction(attestation: Attestation, enrollmentPeriodId: string, subscriberId?: string, specialOpenEnrollmentId?: string): Observable<Attestation> {
    return this.http
      .post<Attestation>(`${env.apiUrl}/Member/${subscriberId ?? attestation.memberId}/attestation/spousal/${enrollmentPeriodId}/soe/${specialOpenEnrollmentId}`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }


  createTobaccoActionNoEP(attestation: Attestation): Observable<Attestation> {
    return this.http
      .post<Attestation>(`${env.apiUrl}/Member/${attestation.memberId}/attestation/tobacco`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createSpousalActionNoEP(attestation: Attestation): Observable<Attestation> {
    return this.http
      .post<Attestation>(`${env.apiUrl}/Member/${attestation.memberId}/attestation/spousal`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSpousalActionNoEP(attestation: Attestation): Observable<Attestation> {
    return this.http
      .put<Attestation>(`${env.apiUrl}/Member/${attestation.memberId}/attestation/spousal/${attestation.attestationId}`, attestation)
      .pipe(map(x => this.commonService.createObject(Attestation, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  removeSpousalAction(attestation: Attestation): Observable<Attestation> {
    return this.http
      .delete<Attestation>(`${env.apiUrl}/Member/${attestation.memberId}/attestation/${attestation.attestationId}`)
      .pipe((res) => res)
      .pipe(catchError(this.commonService.handleError));
  }

  checkAttestationStart(attestation: Attestation, memberId: string): Observable<boolean> {
    return this.http
      .post<boolean>(`${env.apiUrl}/Member/${memberId}/attestation/checkattestationstart`, attestation)
      .pipe(catchError(this.commonService.handleError));
  }
}
