export default class SelfPayProcessStatus {
  selfPayProcessStatusId: string;
  selfPayProcessStatusName: string;
  selfPayProcessStatusCode: string;
  soeInd: boolean;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(selfPayProcessStatus?) {
    if (selfPayProcessStatus) {
      this.selfPayProcessStatusId = selfPayProcessStatus.selfPayProcessStatusId;
      this.selfPayProcessStatusName = selfPayProcessStatus.selfPayProcessStatusName;
      this.selfPayProcessStatusCode = selfPayProcessStatus.selfPayProcessStatusCode;  
      this.soeInd = selfPayProcessStatus.soeInd;
      this.obsoleteFlag = selfPayProcessStatus.obsoleteFlag;
      this.obsoleteDate = selfPayProcessStatus.obsoleteDate
        ? new Date(selfPayProcessStatus.obsoleteDate)
        : null;
    }
  }


}
