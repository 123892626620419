import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'verification-summary',
  templateUrl: 'verification.summary.component.html',
  styleUrls: [],
  providers: []
})

export class VerificationSummaryComponent implements OnInit {
  @Input() dataItem;
  now: Date;
  ngOnInit() {
    this.now = new Date();
  }

}
