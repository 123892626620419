export default class Agency {
    agencyId: string;
    agencyCode: string;
    agencyName: string;

    constructor(agency?) {
      if (agency) {
        this.agencyId = agency.agencyId;
        this.agencyCode = agency.agencyCode;
        this.agencyName = agency.agencyName;
      }
    }
  }
