import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import OrganizationAddress from '../models/organizationAddress';
@Injectable({
  providedIn: 'root'
})
export class OrganizationAddressService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  getOrganizationAddressById(organizationAddressId: string, organizationId: string): Observable<OrganizationAddress> {
    return this.http.get<OrganizationAddress>(`${env.apiUrl}/organization/${organizationId}/address/${organizationAddressId}`)
    .pipe(map(x => this.commonService.createObject(OrganizationAddress, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createOrganizationAddress(organizationAddress: OrganizationAddress): Observable<OrganizationAddress> {
    return this.http.post<OrganizationAddress>(`${env.apiUrl}/organization/${organizationAddress?.organizationId}/address`, organizationAddress)
    .pipe(map(x => this.commonService.createObject(OrganizationAddress, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateOrganizationAddress(organizationAddress: OrganizationAddress): Observable<OrganizationAddress> {
    return this.http.put<OrganizationAddress>(
      `${env.apiUrl}/organization/${organizationAddress?.organizationId}/address/${organizationAddress?.organizationAddressId}`, organizationAddress)
    .pipe(map(x => this.commonService.createObject(OrganizationAddress, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  getOrganizationAddresssForAgency(organizationId: string): Observable<OrganizationAddress[]> {
    return this.http.get<OrganizationAddress[]>(
      `${env.apiUrl}/organization/${organizationId}/address`)
      .pipe(map(x => this.commonService.createObjects(OrganizationAddress, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  deleteOrganizationAddressForAgency(organizationAddress: OrganizationAddress): Observable<void> {
    return this.http.delete<OrganizationAddress[]>(
      `${env.apiUrl}/organization/${organizationAddress?.organizationId}/address/${organizationAddress?.organizationAddressId}`)
      .pipe(catchError(this.commonService.handleError));
  }
}
