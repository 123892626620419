import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ng-modal-content',
  templateUrl: 'pendingModal.component.html'
})
export class PendingModalComponent {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) {}
}
