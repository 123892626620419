import * as dayjs from 'dayjs';
import Country from 'src/app/models/country';
import MemberAddress from 'src/app/models/memberAddress';
import Attestation from 'src/app/models/attestation';
// Flat model for new calls to create member
import Answer from './answer';
import Member from './member';
import { cloneDeep, map, find, get } from 'lodash';
import Enrollment from './enrollment';
import EnrollmentPeriod from './enrollmentPeriod';
import { env } from 'src/env/development';
import RelationshipCertification from './relationshipCertification';
import Relationship from './relationship';
import MemberMedicare from './memberMedicare';
import BaseDependent from './baseDependent';
import RelationshipQualifyReason from './relationshipQualifyReason';
import RelationshipType from './relationshipType';

export default class DependentComposite extends BaseDependent {
  subscriberMemberId: string;
  birthSexId: string;
  genderIdentityId: string;
  memberTypeId: string;
  eligibilityDate: Date;
  eligibilityReasonId: string;
  addressIsSameAsSubscriberInd = false;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  countryId: string;
  state: string;
  zipcodeNumber: string;
  partnershipStartDate?: Date;
  partnershipEndDate?: Date;
  deceasedDate: Date;
  planTypeIds: string[];
  tobaccoResponseId: string;
  spousalAnswers: Answer[] = [];
  spousalResponseQuestions = {};
  spousalResponseId: string;
  simplifiedStatus: string;
  spousal: Attestation;
  member: Member;
  memberMedicare: MemberMedicare;
  originalMemberMedicare: MemberMedicare;
  // just for passing to enrollment update composite in case of terming for soe - or in manage dependents
  terminationReasonId ?: string;
  terminationDate ?: Date;
  adoptionDate ?: Date;
  relationshipTypeName?: string;
  // for deletion
  isCurrentlyEnrolled = false;
  hasDivorceIndicator = false;
  memberEnrollments: Enrollment [];

  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;

  constructor(member?, enrollmentPeriod?: EnrollmentPeriod) {
    super(member, enrollmentPeriod);

    if (member) {
      this.member = member;

      this.createdDate = member.createdDate ? new Date(member.createdDate) : new Date();
      this.createdById = member.createdById;
      this.modifiedById = member.modifiedById;
      this.modifiedDate = member.modifiedDate ? new Date(member.modifiedDate) : null;

      const dateToUse = enrollmentPeriod ? enrollmentPeriod.coverageEffectiveStartDate : new Date();
      if (member.relationshipTypeName) {
        this.relationshipTypeName = member.relationshipTypeName;
      }
      Object.assign(this, member);
      if (member.relationshipToSubscriber) {
        this.simplifiedStatus = member.relationshipToSubscriber.simplifiedStatus;
      }
      if (member.enrollments) {
        this.planTypeIds = map(member.getAllCoverageByDate(dateToUse), (e: Enrollment) => e.plan.planTypeId);
      }

      if (member.attestations) {
        const spousalAttestation: Attestation = member.getAttestationByTypeAndDate(dateToUse, 'SS');
        const tobaccoAttestation: Attestation = member.getAttestationByTypeAndDate(dateToUse, 'TS');
        if (spousalAttestation) {
          this.spousalResponseId = spousalAttestation.responseId;
          this.spousal = spousalAttestation;
          this.spousalAnswers = spousalAttestation.answers;
        }
        if (tobaccoAttestation){
          this.tobaccoResponseId = tobaccoAttestation.responseId;
        }
      }
      if (!this.addressIsSameAsSubscriberInd && member.addresses && member.addresses.length) {
        const residentialAddress = find(member.addresses, (a: MemberAddress) => a.addressType && a.addressType.addressTypeCode === env.residentialAddressTypeCode);
        if (residentialAddress) {
          this.addressLineOne = residentialAddress.addressLineOne;
          this.addressLineTwo = residentialAddress.addressLineTwo;
          this.city = residentialAddress.city;
          this.zipcodeNumber = residentialAddress.zipcodeNumber;
          this.state = residentialAddress.state;
          this.countryId = residentialAddress.countryId;
        }
      }
      this.isCurrentlyEnrolled = this.planTypeIds?.length > 0;
      this.memberMedicare = member.memberMedicare ? new MemberMedicare(member.memberMedicare) : null;
      this.originalMemberMedicare = member.memberMedicare ? cloneDeep(this.memberMedicare) : null;

      if (member.enrollments && member.enrollments.length > 0) {
        this.memberEnrollments = member.enrollments;
        if (member.enrollments[0].divorceWithin60Days !== null) {
          this.hasDivorceIndicator = true;
        }

        this.memberEnrollments.forEach((e: Enrollment) => {
          if (!this.terminationReasonId && e.isActive(dateToUse) && e.terminationReasonId) {
            this.terminationReasonId = e.terminationReasonId;
          }
        });
      }
    }
  }

  get isUnderThirteen(): boolean {
    return super.dependentIsUnderThirteen(this.birthDate ?? this.member?.birthDate);
  }

  isOverTwentySix(coverageEffectiveDate?: Date): boolean {
    const twentySixDate = dayjs(coverageEffectiveDate).subtract(26, 'year').add(30, 'day').toDate();
    const birthDate = this.birthDate ?? this.member?.birthDate;

    return birthDate < twentySixDate;
  }

  allowedRelationshipQualifyReasons(relationshipTypes: RelationshipType[], relationshipQualifyReasons: RelationshipQualifyReason[], coverageEffectiveDate?: Date): string[] {
    const relationshipTypeCode = get(
      find(relationshipTypes, (rt: RelationshipType) => rt?.relationshipTypeId === this.relationshipTypeId),
      'relationshipTypeCode'
    );

    if (relationshipTypeCode) {
        if (this.isOverTwentySix(coverageEffectiveDate)) {
          return env.rtToQrMap26[relationshipTypeCode];
        } else {
          return env.rtToQrMap[relationshipTypeCode];
        }
    }
  }

  determineRelationshipQualifyReason(relationshipTypes: RelationshipType[], relationshipQualifyReasons: RelationshipQualifyReason[], coverageEffectiveDate?: Date): string {
    const relationshipTypeCode = get(
      find(relationshipTypes, (rt: RelationshipType) => rt?.relationshipTypeId === this.relationshipTypeId),
      'relationshipTypeCode'
    );

    if (relationshipTypeCode) {
        const allowedRelationshipQualifyReasons = this.allowedRelationshipQualifyReasons(relationshipTypes, relationshipQualifyReasons, coverageEffectiveDate);
        return get(
          find(relationshipQualifyReasons, (rq: RelationshipQualifyReason) => allowedRelationshipQualifyReasons.includes(rq.relationshipQualifyReasonCode)),
          'relationshipQualifyReasonId'
        );
    }

    return null;
  }

  currentRelationshipQualifyReasonValidForDate(relationshipTypes: RelationshipType[], relationshipQualifyReasons: RelationshipQualifyReason[], coverageEffectiveDate?: Date): boolean {
    const relationshipTypeCode = get(
      find(relationshipTypes, (rt: RelationshipType) => rt?.relationshipTypeId === this.relationshipTypeId),
      'relationshipTypeCode'
    );
    if(relationshipTypeCode == 'S') {
      return true;
    }
    const recalculatedRelationshipQualifyReason = this.determineRelationshipQualifyReason(relationshipTypes, relationshipQualifyReasons, coverageEffectiveDate);

    return recalculatedRelationshipQualifyReason === this.relationshipQualifyReasonId;
  }
}
