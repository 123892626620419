export default class Rate {
  rateId: string;
  rateAmt: number;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  familyCompositionCode: string;

  constructor(rate?) {
    if (rate) {
      this.rateId = rate.rateId;
      this.rateAmt = rate.rateAmt;
      this.effectiveStartDate = rate.effectiveStartDate;
      this.effectiveEndDate = rate.effectiveEndDate;
      this.familyCompositionCode = rate.familyCompositionCode;
    }
  }

}
