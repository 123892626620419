import { SpinnerOverlayService } from './../../../../../../services/spinnerOverlay.service';
import Subscriber from 'src/app/models/subscriber';
import { Component, OnChanges, Output, EventEmitter, Input, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { find, sortBy, map, includes, findIndex, cloneDeep, forEach, flatMap, uniqBy, some } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import Member from 'src/app/models/member';
import Document from 'src/app/models/document';
import DocumentType from 'src/app/models/documentType';
import Relationship from 'src/app/models/relationship';
import { NgForm } from '@angular/forms';
import { DocumentService } from 'src/app/services/document.service';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { CoreService } from 'src/app/services/core.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { remove } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'upload-association',
  templateUrl: 'upload.association.component.html',
})

export class UploadAssociationComponent implements OnChanges, OnInit {
  inAdminState: boolean;
  @Input() members: Member[];
  @Input() documents: Document[];
  documentTypes: DocumentType[];
  soeDocumentTypesOnly: DocumentType[];
  @Input() specialOpenEnrollments: SpecialOpenEnrollment[];
  @Input() currentSOE: SpecialOpenEnrollment;
  @Input() isSOE = false;
  @Input() subscriber: Subscriber;
  docHash: any = {};
  docHashDisabled: any = {};
  @ViewChild('associationForm') public associationForm: NgForm;
  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private documentService: DocumentService,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService
  ) {}

  ngOnInit(): void {
    this.determineDocumentTypes();
  }

  ngOnChanges(): void {
    this.documents.forEach(doc => {
      this.docHash[doc.documentId] = {};
      this.docHashDisabled[doc.documentId] = {};
      this.members.forEach(m => {
        this.docHashDisabled[doc.documentId][m.relationshipToSubscriber.relationshipId] = false;
        if (this.isDocAssociated(doc, m.relationshipToSubscriber)) {
          this.docHash[doc.documentId][m.relationshipToSubscriber.relationshipId] = true;
          if (m.relationshipToSubscriber.simplifiedStatus === "Verified") {
            this.docHashDisabled[doc.documentId][m.relationshipToSubscriber.relationshipId] = true;
          }
        } else {
          this.docHash[doc.documentId][m.relationshipToSubscriber.relationshipId] = false;
        }
      });
      forEach(this.specialOpenEnrollments, (soe) => {
        if (this.isDocAssociated(doc, soe)) {
          this.docHash[doc.documentId][soe.specialOpenEnrollmentId] = true;
        } else {
          this.docHash[doc.documentId][soe.specialOpenEnrollmentId] = false;
        }
      });
      this.setCanDeleteDocument(doc);
    });

    this.determineDocumentTypes();
  }

  determineDocumentTypes(): void {
    const relDocTypes = flatMap(this.members, (m: Member) => m.relationshipToSubscriber?.relationshipQualifyReason.documentTypes);
    var soeDocTypes = flatMap(this.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => soe.specialOpenEnrollmentType.documentTypes);
    var excludeDocTypes = false;
    if (this.currentSOE?.specialOpenEnrollmentId) {
      soeDocTypes = this.currentSOE.specialOpenEnrollmentType.documentTypes;
      const soeTypesWithNoRelDocTypes = ['Change in school district','Change of Address'];
      if (find(soeTypesWithNoRelDocTypes, (dt)=>dt === this.currentSOE.specialOpenEnrollmentType.specialOpenEnrollmentTypeName)) {
        excludeDocTypes = true;
      }
    }
    this.soeDocumentTypesOnly = uniqBy(soeDocTypes, 'documentTypeId');
    this.documentTypes = uniqBy(excludeDocTypes ? soeDocTypes : soeDocTypes.concat(relDocTypes), 'documentTypeId');
  }

  isDocAssociated(document: Document, association: Relationship | SpecialOpenEnrollment): boolean {
    return includes(map(association.documents, 'documentId'), document.documentId);
  }

  associateMemberOrSOE(event: Event, document: Document, relationship: Relationship, soe ?: SpecialOpenEnrollment): void {
      // event.preventDefault();
      const docClone = cloneDeep(document);
      if (soe) {
        docClone.specialOpenEnrollmentId = soe.specialOpenEnrollmentId;
        if (!this.isDocAssociated(docClone, soe)) {
          soe.documents.push(docClone);
        } else if (this.isDocAssociated(docClone, soe)) {
          const idx = findIndex(soe.documents, (d: Document) => d.documentId === document.documentId);
          soe.documents.splice(idx, 1);
      }
      } else {
        docClone.relationshipId = relationship.relationshipId;
        if (!this.isDocAssociated(docClone, relationship)) {
          relationship.documents.push(docClone);
        } else if (this.isDocAssociated(docClone, relationship)) {
          const idx = findIndex(relationship.documents, (d: Document) => d.documentId === document.documentId);
          relationship.documents.splice(idx, 1);
        }
        this.setCanDeleteDocument(document);
      }
  }

  setCanDeleteDocument(document: Document) {
    var canDelete = true;
    this.members.forEach(m => {
      if (this.docHash[document.documentId][m.relationshipToSubscriber.relationshipId] && m.relationshipToSubscriber.simplifiedStatus === "Verified") {
        canDelete = false;
      }
    });
    this.documents.find(o=>o.documentId === document.documentId).canDelete = canDelete;
  }

  public markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.associationForm);
  }

  public handleNewDocTypeChange(docTypeId: string, id: string): void {
    if (id.includes('NEW')) {
      forEach(this.members, m => {
        forEach(m.relationshipToSubscriber.documents, d => {
          if (d.documentId === id) {
            d.documentTypeId = docTypeId;
          }
        });
      });
      forEach(this.specialOpenEnrollments, s => {
        forEach(s.documents, d => {
          if (d.documentId === id) {
            d.documentTypeId = docTypeId;
          }
        });
      });
    }
  }

  deleteDocument(documentId) {
    this.spinnerService.show();
    this.documentService.deleteDocumentAndRelatedRecords(this.subscriber.memberId, documentId, this.currentSOE?.specialOpenEnrollmentId)
      .pipe(untilDestroyed(this)).subscribe(()=>{
        remove(this.documents, (d:Document) => d.documentId === documentId);
        if (this.isSOE) {
          this.coreService.refetchSubscriberWithSOE(this.subscriber.memberId, this.currentSOE.specialOpenEnrollmentId);
        }
        else {
          this.coreService.refetchSubscriber(this.subscriber.memberId);
        }
        this.spinnerService.hide();
        this.coreService.popMessage("Document deleted", "success", 3000);
    });
  }

  documentTypeIsValidForMember(documentTypeId, member) {
    return some(member.relationshipToSubscriber?.relationshipQualifyReason.documentTypes, (dt: DocumentType) => dt.documentTypeId === documentTypeId);
  }
}
