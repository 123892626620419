// ng
import { Component, ViewEncapsulation, OnInit, OnChanges, Input, Output, EventEmitter, HostListener } from '@angular/core';

// ext
import { Subscription, lastValueFrom } from 'rxjs';
import { upperCase, map, forEach, some, filter } from 'lodash';
import * as dayjs from 'dayjs';

// local
import { env } from 'src/env/development';
import SubscriberCoverage from 'src/app/models/subscriberCoverage';
import SubscriberSummary from 'src/app/models/subscriberSummary';
import Plan from 'src/app/models/plan';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import PlanType from 'src/app/models/planType';
import MemberSummary from 'src/app/models/memberSummary';
import Member from 'src/app/models/member';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'coverage-review',
  templateUrl: 'coveragereview.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class CoverageReviewComponent implements OnInit, OnChanges {
  @Output() okayToContinue: EventEmitter<void> = new EventEmitter<void>();
  @Input() subscriberSummary: SubscriberEffectiveSummary;
  @Input() medicalChange: Plan;
  @Input() dentalChange: Plan;
  @Input() visionChange: Plan;
  @Input() inAdminState = false;
  @Input() waiverMessages: PlanType[];
  @Input() enrollmentPeriod: EnrollmentPeriod;
  @Input() hasMedical = false;
  @Input() hasDental = false;
  @Input() hasVision = false;
  @Input() selfPayWaivingMedicalCoverage: boolean;
  @Input() selfPayWaivingDentalCoverage: boolean;
  @Input() isSelfPay = false;
  @Input() isLWOP = false;
  @Input() isSebb: boolean = false;
  dayjs = dayjs;
  electablePlanTypes: PlanType[];
  dentalPlanName: string;
  medicalPlanName: string;
  visionPlanName: string;
  subscriberMedicalPlanName: string;
  subscriberDentalPlanName: string;
  subscriberVisionPlanName: string;
  env = env;
  medicareDEnabled = false;

  @HostListener('scroll', ['$event.target']) onscroll(elem): void {
    const target = elem.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.okayToContinue.emit();
    }
  }

  constructor(private adminService: AdminService) {}

  async ngOnInit(): Promise<void> {
    this.medicareDEnabled = await lastValueFrom(this.adminService.getFeatureFlag('FEATURE_ENABLE_MEDICARE_D'));
    
    const subscriberOrDependentsHaveMedicareB = !!this.subscriberSummary?.memberMedicare?.medicarePartBEnrolledInd ||
    some(this.subscriberSummary?.members, (dep: Member) => {
      if (dep && !dep.isSubscriberInd) {
        return dep.memberMedicare?.medicarePartBEnrolledInd;
      }

      return false;
    });

    const notEnrolledText = (this.isSelfPay ? env.notEnrolledText : 'Waived');
    // const medicalPlan = ((this.medicareDEnabled && subscriberOrDependentsHaveMedicareB) ? this.medicalChange?.medicareDPlanName : this.medicalChange?.planName) ?? this.medicalChange?.planName;
    const medicalPlan = (this.isSelfPay && !this.isLWOP && this.medicalChange?.medicareDPlanName) ? `${this.medicalChange?.planName} or ${this.medicalChange?.medicareDPlanName}` : this.medicalChange?.planName;
    
    this.electablePlanTypes = filter(this.subscriberSummary?.electablePlanTypes, (ept: PlanType) => some(env.planTypesToDisplay, (pt: string) => ept.planTypeCode === pt));
    this.medicalPlanName = this.subscriberMedicalPlanName = medicalPlan ?? notEnrolledText;
    this.dentalPlanName = this.subscriberDentalPlanName = this.dentalChange ? this.dentalChange.planName : notEnrolledText;
    this.visionPlanName = this.subscriberVisionPlanName = this.visionChange ? this.visionChange.planName : notEnrolledText;
    this.setCOBRAUnPaidWaived();
  }

  memberMedicalPlan(member: MemberSummary): string {
    if (member.effectiveEnrollmentsByPlanType['1']) {
      if (member.subscriberInd) {
        return this.subscriberMedicalPlanName;
      }
      return this.medicalPlanName;
    }
    else {
      return env.notEnrolledText;
    }
  }

  memberDentalPlan(member: MemberSummary): string {
    if (member.effectiveEnrollmentsByPlanType['2']) {
      if (member.subscriberInd) {
        return this.subscriberDentalPlanName;
      }
      return this.dentalPlanName;
    }
    else {
      return env.notEnrolledText;
    }

  }

  memberVisionPlan(member: MemberSummary): string {
    if (member.effectiveEnrollmentsByPlanType['6']) {
      if (member.subscriberInd) {
        return this.subscriberVisionPlanName;
      }
      return this.visionPlanName;
    }
    else {
      return env.notEnrolledText;
    }

  }

  ngOnChanges(): void {
    this.setCOBRAUnPaidWaived();
  }

  setCOBRAUnPaidWaived(): void {
    if (this.isSelfPay) {
      if (this.selfPayWaivingMedicalCoverage) {
        this.subscriberMedicalPlanName = env.notEnrolledText;
      }
      if (this.selfPayWaivingDentalCoverage) {
        this.subscriberDentalPlanName = env.notEnrolledText;
      }
    }
  }

  getEffectiveCoverageDate(m: MemberSummary, i) {
    if(this.hasMedical && this.subscriberSummary.effectiveMedicalPlan == m.currentMedical?.plan?.planName) {
      return m.currentMedical?.effectiveStartDate;
    }
    if(this.hasDental && this.subscriberSummary.effectiveDentalPlan == m.currentDental?.plan?.planName) {
      return m.currentDental?.effectiveStartDate;
    }
    if(this.hasVision && this.subscriberSummary.effectiveVisionPlan == m.currentVision?.plan?.planName) {
      return m.currentVision?.effectiveStartDate;
    }
    return (m.adoptionDate || m.birthDate) > this.enrollmentPeriod.coverageEffectiveStartDate ? (m.adoptionDate || m.birthDate) : this.enrollmentPeriod.coverageEffectiveStartDate;
  }
}
