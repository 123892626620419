import { OnInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forEach, filter, isNull, startCase, cloneDeep, map, sortBy, some, remove, mapValues, keyBy, reverse, includes } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CoreService } from 'src/app/services/core.service';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { ReportService } from 'src/app/services/report.service';
import { LookupService } from 'src/app/services/lookup.service';
import Report from 'src/app/models/report';
import ReportParameter from 'src/app/models/reportParameter';
import Organization from 'src/app/models/organization';
import MemberType from 'src/app/models/memberType';
import ContactType from 'src/app/models/contactType';
import SystemUser from 'src/app/models/user';
import ReportType from 'src/app/models/reportType';
import ReportDisplayField from 'src/app/models/reportDisplayField';
import { ReportSearchComponent } from './components/search/report.search.component';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import Agency from 'src/app/models/agency';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.ReportType, LookupType.Agency)
@Component({
  selector: 'member',
  templateUrl: 'reports.component.html',
  styleUrls: [],
  providers: [NgbNavConfig],
})
export class ReportsComponent implements OnInit {
  lookupMap = {
    Organization,
    MemberType,
    ContactType,
    Agency,
  };
  systemUser: SystemUser;
  isHCA = false;
  currentOrganization: Organization;
  availableReports: Report[];
  selectedReport: Report;
  reportParameters: ReportParameter[];
  reportTypes: ReportType[];
  showParameters = false;
  lookups = [];
  reportFilters: { [k: string]: string | {} } = {};
  dataRangeParameters: ReportParameter[] = [];
  gridColumns: { [k: string]: string | {} }[] = [];
  reportColumnsToInclude: ReportDisplayField[] = [];
  gridData = [];
  tripleStateValues = ['Yes', 'No', 'Both'];
  selectAllCheck = true;
  @ViewChild('reportSearch') reportSearch: ReportSearchComponent;
  activeTab;
  reportTabs = [
    {
      name: 'search',
      index: 0,
    },
    {
      name: 'display',
      index: 1,
    },
    {
      name: 'results',
      index: 2,
    },
  ];
  agencyId: string = null;
  initialAgencyId: string;
  selfPayOnly = false;
  constructor(
    private tabConfig: NgbNavConfig,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private lookupService: LookupService,
    private coreService: CoreService,
    private notificationService: NotificationService,
    private spinnerService: SpinnerOverlayService
  ) {
    tabConfig.destroyOnHide = false;
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((q) => {
      this.selfPayOnly = q.selfPayOnly;
    });
    this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s) => {
      this.currentOrganization = s;

      if (s) {
        this.agencyId = s.agency.agencyId;
        this.initialAgencyId = this.agencyId;
      }
    });
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.reportTypes = data.lookups.reportType;
      this.systemUser = data.user;
      this.lookups = data.lookups;
      this.isHCA = some(this.systemUser.userOrganizationRoles, (r) => r.userRoleName === 'HCA');
      this.populateAvailableReports().then((reportList) => {
        this.availableReports = reportList;
      });
    });
  }

  async agencySelected(agencyId: string): Promise<void> {
    this.agencyId = agencyId;

    if (this.lookups[`organization`]) {
      let orgs = await lastValueFrom(this.lookupService.getLutValues('organization', Organization));
      if (agencyId) {
        orgs = orgs.filter((o: Organization) => o.agency.agencyId === this.agencyId);
      }

      this.lookups[`organization`] = sortBy(orgs, 'organizationName');

      this.reportFilters.organizationId = null;
    }
  }

  async populateAvailableReports(): Promise<Report[]> {
    let availableReports: Report[];
    try {
      if (this.isHCA) {
        availableReports = await lastValueFrom(this.reportService.getAvailableReportsForHCA());
      } else {
        availableReports = await lastValueFrom(this.reportService.getAvailableReportsForOrganization(this.currentOrganization.organizationId));
      }
      return availableReports;
    } catch (err) {
      console.log(err);
      this.notificationService.show({
        content: 'There was an error loading available reports.',
        animation: { type: 'slide', duration: 200 },
        position: { horizontal: 'center', vertical: 'bottom' },
        type: { style: 'error', icon: true },
        hideAfter: 2000,
      });
    }
  }

  updateSelectedReport(selectedReport: Report): void {
    this.selectedReport = selectedReport;
    this.reportParameters = sortBy(cloneDeep(selectedReport.reportParameters), 'sortOrderNumber');
    this.reportFilters = {};
    if (this.reportParameters.length > 0) {
      remove(this.reportParameters, (rp) => rp.lookupTableName === 'agency');

      if (!this.isHCA) {
        remove(this.reportParameters, (rp) => rp.lookupTableName === 'organization');
      }
      const paramsWithLookups = filter(this.reportParameters, (rp) => !isNull(rp.lookupTableName));
      this.loadLookups(paramsWithLookups).then(() => {
        this.showParameters = true;
      });
    } else {
      this.showParameters = false;
    }
  }

  async loadLookups(lookupParamaters: ReportParameter[]): Promise<void> {
    await forEach(lookupParamaters, (lp: ReportParameter) => {
      if (!(lp.lookupTableName in this.lookups)) {
        lastValueFrom(this.lookupService.getLutValues(lp.lookupTableName, this.lookupMap[lp.dataType])).then((lookupValues) => {
          if (lp.lookupTableName === 'organization' && this.agencyId) {
            lookupValues = lookupValues.filter((o) => o[`agencyId`] === this.agencyId);
          }

          this.lookups[lp.lookupTableName] = sortBy(lookupValues, lp.lookupField);
        });
      }
    });
  }

  nextButtonDisabled(activeTab, reportColumnsToInclude): boolean {
    if (activeTab === 1) {
      reportColumnsToInclude = reportColumnsToInclude?.filter((r) => r.includeInResults) ?? [];

      return reportColumnsToInclude.length === 0;
    }

    return false;
  }

  async runReport(formContainer): Promise<boolean> {
    let filters: { [k: string]: string | {} } = {};
    if (this.selectedReport) {
      const parameters = sortBy(cloneDeep(this.selectedReport.reportParameters), 'sortOrderNumber');
      if (!this.isHCA) {
        this.reportFilters.organizationId = this.currentOrganization.organizationId;
      }
      this.reportFilters.agencyId = this.agencyId !== undefined? this.agencyId : null;
      filters = reverse(mapValues(keyBy(parameters, 'parameterField'), (val, key) => this.reportFilters[key]));

      this.markAllControlsAsTouched();
      if (!this.reportSearch.reportsForm.valid) {
        this.coreService.popMessage('Please enter all required parameters and try again.', 'error', 3000, this.coreService.getInvalidFields(formContainer));
        return false;
      } else {
        this.spinnerService.show();
        let reportData;
        try {
          if (this.isHCA) {
            reportData = await lastValueFrom(this.reportService.getReportResultsForHCA(this.selectedReport.reportId, filters));
          } else {
            reportData = await lastValueFrom(this.reportService.getReportResultsForOrganization(this.selectedReport.reportId, filters, this.currentOrganization.organizationId));
          }
          this.gridData = cloneDeep(reportData);
          this.reportColumnsToInclude = [];
          const gridKeys = this.gridData[0] ? Object.keys(this.gridData[0]) : [];
          forEach(gridKeys, (gk) => {
            const newField = new ReportDisplayField();
            newField.fieldName = gk;
            newField.displayName = gk === 'subscriber/Dependent' ? 'Subscriber/Dependent' : startCase(gk);
            newField.includeInResults = true;
            this.reportColumnsToInclude.push(newField);
          });
        } catch (err) {
          this.notificationService.show({
            content: 'There was an error running this report.',
            animation: { type: 'slide', duration: 200 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 2000,
          });
          this.spinnerService.hide();
          console.log(err);
          return Promise.reject(new Error(err));
        }
        this.spinnerService.hide();
        return true;
      }
    }
  }

  displayReport(): void {
    this.gridColumns = [];
    const fieldsToInclude = filter(this.reportColumnsToInclude, (rci) => rci.includeInResults);
    forEach(fieldsToInclude, (field: ReportDisplayField) => {
      if (field.fieldName.includes('Date')) {
        map(this.gridData, (val, rd) => {
          this.gridData[rd][field.fieldName] = val[field.fieldName] && val[field.fieldName] !== '0001-01-01T00:00:00-08:00' ? new Date(val[field.fieldName]) : null;
        });
      }
      this.gridColumns.push({
        field: field.fieldName,
        title: field.displayName,
        format: field.fieldName.startsWith('date') || field.fieldName.includes('Date') ? (field.fieldName.includes('Time') ? '{0: MM/dd/yyyy HH:mm:ss}' : { date: 'mm/dd/yyyy' }) : 'string',
        filter: field.fieldName.startsWith('date') || field.fieldName.includes('Date') ? 'date' : null,
        width: 200,
      });
    });
  }

  public markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.reportSearch.reportsForm);
  }

  moveToNextTab(formContainer): void {
    const nextTab = this.activeTab + 1;
    if (nextTab === 1) {
      this.runReport(formContainer).then((canContinue) => {
        if (canContinue) {
          this.activeTab = 1;
        }
      });
    } else if (nextTab === 2) {
      this.displayReport();
      this.activeTab = 2;
    } else {
      this.clearAll();
      this.activeTab = 0;
    }
  }

  moveToPreviousTab(): void {
    this.activeTab = this.activeTab - 1;
  }

  clearAll(): void {
    this.selectedReport = null;
    this.reportParameters = null;
    this.reportFilters = {};
    this.dataRangeParameters = [];
    this.gridColumns = [];
    this.gridData = [];
    this.reportColumnsToInclude = [];
    this.showParameters = false;
    this.activeTab = 0;
  }
}
