import Document from './document';

export default class OrganizationDocument {
  organizationDocumentId: string;
  organizationId: string;
  documentId: string;
  fileDate: Date;
  document: Document;

  constructor(organizationDocument?) {
    if (organizationDocument) {
      this.organizationDocumentId = organizationDocument.organizationDocumentId;
      this.documentId = organizationDocument.documentId;
      this.organizationId = organizationDocument.organizationId;
      this.fileDate = organizationDocument.fileDate ? new Date(organizationDocument.FileDate) : null;
      this.document = new Document(organizationDocument.document);
    }
  }

}
