<div class="card">
  <div class="card-header" fxLayoutAlign="center">
    <h4>
      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px;" [icon]="icons.faExclamationTriangle"
        size="2x"></fa-icon>
      <span role="alert">Record found that cannot be automatically transferred</span>
    </h4>
  </div>
  <div class="card-body">
    <div *ngIf="!subscriberTransferRec?.needsToBeAdjustedForTransfer && subscriberTransferRec.organization && !subscriberTransferRec.isEligibileForRetireeTransfer" role="alert" style="padding:15px" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
          <span>
            This subscriber has active eligibility with an organization under the {{subscriberTransferRec.agencyName}} program.
            Please contact the benefit administrator at <b>{{subscriberTransferRec.organization.organizationName}}</b>.
          </span>
        </div>
      </div>
    </div>  
    <div *ngIf="!subscriberTransferRec?.needsToBeAdjustedForTransfer && !subscriberTransferRec.organization && !subscriberTransferRec.isEligibileForRetireeTransfer" role="alert" style="padding:15px" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
          <span>
            This subscriber has active eligibility under the {{subscriberTransferRec.agencyName}} program.
          </span>
        </div>
      </div>
    </div>  
    <div *ngIf="subscriberTransferRec?.needsToBeAdjustedForTransfer && !subscriberTransferRec?.lossOfEligibilityDate && !subscriberTransferRec.isEligibileForRetireeTransfer" role="alert" style="padding:15px" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
          <span>
            This subscriber is currently in transfer status. The eligibility date entered does not meet the definition of a transfer per WAC 182-12-111.
            See worksheet <a target="_blank" href="https://lnks.gd/l/eyJhbGciOiJIUzI1NiJ9.eyJidWxsZXRpbl9saW5rX2lkIjoxMDIsInVyaSI6ImJwMjpjbGljayIsImJ1bGxldGluX2lkIjoiMjAyMDA4MjguMjYyNDc4NjEiLCJ1cmwiOiJodHRwczovL3d3dy5oY2Eud2EuZ292L2Fzc2V0cy9wZXJzcGF5L1BFQkItYS01LTIwMjAueGxzeCJ9.-kjYRtuq6chb5FMIcC6tAQz1k3iI9a-fkXLuPFZlO94/s/958813827/br/82936997266-l">Worksheet A-5</a> for additional details.
            Please contact the benefit administrator at <b>{{subscriberTransferRec.organization.organizationName}}</b> so they can terminate the employee benefits.
          </span>          
        </div>
      </div>
    </div>
    <div *ngIf="subscriberTransferRec?.needsToBeAdjustedForTransfer && subscriberTransferRec?.lossOfEligibilityDate && !subscriberTransferRec.isEligibileForRetireeTransfer" role="alert" style="padding:15px" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
          <span>
            The eligibility date entered for this subscriber meets the definition of a transfer per WAC 182-12-111.
            See worksheet <a target="_blank" href="https://lnks.gd/l/eyJhbGciOiJIUzI1NiJ9.eyJidWxsZXRpbl9saW5rX2lkIjoxMDIsInVyaSI6ImJwMjpjbGljayIsImJ1bGxldGluX2lkIjoiMjAyMDA4MjguMjYyNDc4NjEiLCJ1cmwiOiJodHRwczovL3d3dy5oY2Eud2EuZ292L2Fzc2V0cy9wZXJzcGF5L1BFQkItYS01LTIwMjAueGxzeCJ9.-kjYRtuq6chb5FMIcC6tAQz1k3iI9a-fkXLuPFZlO94/s/958813827/br/82936997266-l">Worksheet A-5</a> for additional details.
            Please contact the benefit administrator at <b>{{subscriberTransferRec.organization.organizationName}}</b> so they can transfer the employee benefits.
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="subscriberTransferRec.isEligibileForRetireeTransfer" role="alert" style="padding:15px" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
          <span>
            This subscriber currently has self-pay coverage. To move forward with enrolling this employee as a new employee please contact Outreach & Training through HCA Support or by calling 800-700-1555.
          </span>
        </div>
      </div>
    </div>
  </div>
  <div style="padding-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
    <button (click)="cancelAdd.emit()"class="btn btn-light">
      <span class="k-icon k-i-x"></span> Cancel
    </button>
  </div>
</div>

<!-- Record is also termed -->
<div class="card" *ngIf="transferTermed">
  <div class="card-header" fxLayoutAlign="center">
    <h4>
      <fa-icon class="hca-fontawesome-icon" style="margin-right: 15px;" [icon]="['fas', 'exclamation-triangle']"
        size="2x"></fa-icon>
      <span role="alert">Record found that can be claimed as a transfer.</span>
    </h4>
  </div>
  <div class="card-body">
    <div role="alert" style="padding:15px" fxLayoutAlign="center">
      <div fxLayout="column" fxLayoutAlign="center center">
        <div><b>Benefits for this subscriber and any dependents cannot be changed unless they have a special open enrollment event.</b></div>
        <div fxLayout="row">
          {{subscriberTransferRec.lastName}}, {{subscriberTransferRec.firstName}}, {{subscriberTransferRec.socialSecurityNumber}}
        </div>
        <div fxLayout="row" class="mgtp-medium-sm">
          Former Agency: {{subscriberTransferRec.mostRecentEmployment.organization.organizationName}}
        </div>
      </div>
    </div>
    <div style="padding-top: 10px" fxLayout="row" fxLayoutAlign="space-around">
      <button (click)="claim.emit()"class="btn btn-primary">
        <span class="k-icon k-i-x"></span> Claim
      </button>
      <button (click)="cancelAdd.emit()"class="btn btn-light">
        <span class="k-icon k-i-x"></span> Cancel
      </button>      
    </div>
  </div>
</div>