// ng
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// routes
import { HcaOrgRoutes } from 'src/app/routing/hca.org.routes';
import { HcaGeneralRoutes } from 'src/app/routing/hca.general.routes';
import { MainRoutes } from 'src/app/routing/main.routes';
import { SubscriberWizardRoutes } from 'src/app/routing/subscriber.wizard.routes';
import { SubscriberGeneralRoutes } from 'src/app/routing/subscriber.general.routes';
import { PerspayGeneralRoutes } from 'src/app/routing/perspay.general.routes';
import { PerspaySubscriberRoutes } from 'src/app/routing/perspay.subscriber.routes';

// components

// guards
import { AuthGuard } from './guards/auth/auth.guard';
import { MasterGuard } from './guards/master/master.guard';
import { PendingChangesGuard } from './guards/pendingChanges/pendingChanges.guard';

@NgModule({
  imports: [
    CommonModule,
    SubscriberGeneralRoutes,
    SubscriberWizardRoutes,
    PerspayGeneralRoutes,
    PerspaySubscriberRoutes,
    HcaGeneralRoutes,
    HcaOrgRoutes,
    MainRoutes,
  ],
  exports: [RouterModule],
  providers: [AuthGuard, MasterGuard, PendingChangesGuard],
})
export class RoutingModule {}
