import { sortBy, filter } from 'lodash';
import { DataStateChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import SelfPay from 'src/app/models/selfPay';
import { CheckSessionService } from 'angular-auth-oidc-client/lib/iframe/check-session.service';
import { env } from 'src/env/development';
import { SpinnerComponent } from 'src/app/modules/shared/components/spinner/spinner.component';

@Component({
  selector: 'self-pay-grid',
  templateUrl: 'selfPay.grid.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayGridComponent implements OnInit, OnChanges {
  @Output() selfPaySelected: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() removeSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Input() selfPays: SelfPay[];
  @Input() isReadOnly = false;
  @Input() isPerspay = false;
  @Input() isHcaEdit = false;
  @Input() isSOE = false;
  @Output() editSelfPay: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  @Output() downloadSelfPayPDF: EventEmitter<SelfPay> = new EventEmitter<SelfPay>();
  gridData: any;
  state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  selectableSettings = {
    checkboxOnly: true,
    mode: 'single',
  };
  gridColumns: { [k: string]: string | {} }[] = [
    {field: 'selfPayType.selfPayTypeName', title: 'Request type', format: 'string'},
    {field: 'submittedDate', title: 'Submitted date', format: { date: 'mm/dd/yyyy' }, filter: 'date'},
    {field: 'simplifiedStatus', title: 'Status', format: 'string'},
    {field: 'reviewDate', title: 'Review Date', format: 'string'},
  ];
  selectedSelfPay: SelfPay;
  ngOnInit(): void {
    this.setGridData();
  }

  ngOnChanges(): void {
    this.setGridData();
  }

  public setGridData() {
    if (this.isSOE) {
      this.gridColumns = [
        {field: 'selfPayType.selfPayTypeName', title: 'Event', format: 'string'},
        {field: 'eventDate', title: 'Event Date', format: { date: 'mm/dd/yyyy' }, filter: 'date'},
        {field: 'simplifiedStatus', title: 'Status', format: 'string'},
        {field: 'expirationDate', title: 'Review Deadline', format: 'string'},
        {field: 'sortStatus', title: 'Verification Reason', format: 'string'},
      ];
    }
    
    //filter for SOEs
    const spItems = this.selfPays.filter(o=>this.isSOE == env.soeSelfPayTypeCodes.includes(o.selfPayType.selfPayTypeCode) || this.isSOE == env.oeSelfpayTypeCodes.includes(o.selfPayType.selfPayTypeCode));
    // because process returns a wrapper type specific to the grid
    const pending = filter(sortBy(spItems, (s) => s.reviewDate), (s) => s.sortStatus !== 'Approved' && s.sortStatus !== 'Denied');
    const approved = filter(sortBy(spItems, (s) => s.reviewDate), (s) => s.sortStatus === 'Approved');
    const denied = filter(sortBy(spItems, (s) => s.reviewDate), (s) => s.sortStatus === 'Denied');
    this.gridData = pending.concat(approved).concat(denied);
  }

  submitEdit(rec: SelfPay): void {
    this.editSelfPay.emit(rec);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.selfPays, state);
  }

  setSelfPaySelected(e: SelectionEvent): void {
    this.selectedSelfPay = e.selectedRows[0]?.dataItem;
  }
}
