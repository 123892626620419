import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';
import { FileService } from 'src/app/services/file.service';
import SystemUser from 'src/app/models/user';
import SubmissionFileResponse from 'src/app/models/submissionFileResponse';
import SubmissionFile from 'src/app/models/submissionFile';
import Organization from 'src/app/models/organization';
import { find, filter, sortBy, chunk } from 'lodash';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import Document from 'src/app/models/document';
import DocumentType from 'src/app/models/documentType';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import SubmissionFileResponseType from 'src/app/models/submissionFileResponseType';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { lastValueFrom } from 'rxjs';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';

@UntilDestroy()
@Lookups(LookupType.DocumentType, LookupType.SubmissionFileResponseType)
@Component({
  selector: 'eligibility-file',
  templateUrl: 'eligibilityFile.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class EligibilityFileComponent implements OnInit {
  allowedFileTypes: string[] = ['csv', 'txt'];
  errorResponses: SubmissionFileResponse[] = [];
  systemUser: SystemUser;
  assignedOrganizations: Organization[] = [];
  currentOrganization: Organization;
  uploadDate: Date;
  submissionFile: SubmissionFile = new SubmissionFile();
  showDependentEligibility = false;
  documentType: string;
  documentTypes: DocumentType[] = [];
  isPerspayEdit = false;
  isHca = false;
  errorResponseTypeId: string;

  constructor(
    private route: ActivatedRoute,
    private fileService: FileService,
    private notificationService: NotificationService,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService 
  ) {}

  ngOnInit(): void {
    this.documentType = this.showDependentEligibility ? 'DEL' : 'EL';
    this.currentOrganization = this.coreService.getOrganizationValue();

    this.route.data.pipe(first()).pipe(untilDestroyed(this)).subscribe(data => {
      this.systemUser = data.user;
      this.documentTypes = data.lookups.documentTypes;

      this.isPerspayEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay);
      this.isHca = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);

      this.errorResponseTypeId = find(data.lookups.submissionFileResponseTypes, (t: SubmissionFileResponseType) => t.submissionFileResponseTypeCode === 'E').submissionFileResponseTypeId;

      this.updateDataForCurrentOrganization();
    });

    this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s: Organization) => {
      if(s) {
        this.currentOrganization = s;
      }
    });
  }

  updateDataForCurrentOrganization(): void {
    if (!this.currentOrganization) {
      return;
    }

    this.documentType = this.showDependentEligibility ? 'DEL' : 'EL';
    this.fileService.getLatestSubmissionFileByAgency(this.currentOrganization.organizationId, this.documentType).pipe(first()).pipe(untilDestroyed(this)).subscribe(
      response => {
        if (response) {
          this.submissionFile = response;
          if (this.submissionFile) {
            this.submissionFile.submissionFileResponses.forEach(fr=>{fr.uploadDate = response.uploadDate;});
            this.errorResponses = sortBy(
              filter(this.submissionFile.submissionFileResponses, (r: SubmissionFileResponse) => r.submissionFileResponseTypeId === this.errorResponseTypeId),
              'rowNumber') || [];
          }
        }
      }
    );
  }

  async handleFilesSelected(e): Promise<boolean> {
    const file = e.files[0];
    const submissionFile = new SubmissionFile();
    submissionFile.submissionFileName = file.name;
    submissionFile.organizationId = this.currentOrganization.organizationId;

    //file name validation
    var fileNameInfo = file.name.split("-");
    var fileNameError = "";
    if (fileNameInfo.length<3) {
      fileNameError+="File name must be in the format 'eligibility-LEACode-YYYYMMDD.txt'. ";
    }
    else {
      if (fileNameInfo[0]!="eligibility") {
        fileNameError+="File must start with 'eligibility'. ";
      }
      if (fileNameInfo[1]!=this.currentOrganization.ospiDistrictNumber) {
        fileNameError+="LEA code '" + fileNameInfo[1] + "' is incorrect for this organization.  Should be '" + this.currentOrganization.ospiDistrictNumber + "'. "
      }

      dayjs.extend(customParseFormat); //necessary to check if date like '2022-02-31' is valid
      if (!dayjs(fileNameInfo[2].replace(".txt","").replace(".csv",""), 'YYYYMMDD',true).isValid()) {
        fileNameError+="File name date is invalid. "
      }
    }
    if (fileNameError!="") {
      this.coreService.popMessage(fileNameError, 'error', 7000);
      return;
    }

    this.spinnerService.show();
    const reader = new FileReader();
    reader.readAsText(file.rawFile);
    var formatVerificationError = "";
    reader.onloadend = async () => {
      const resultsAsArray = reader.result.toString().split('\n');
      if (resultsAsArray.length>0 && resultsAsArray[0].split('|').length != 30) {
        formatVerificationError = "Error: file does not meet specifications";
      }

      if (formatVerificationError=="") {

        this.submissionFile = await lastValueFrom(this.fileService.createFile(this.currentOrganization.organizationId, this.documentType, submissionFile));
        const fileContents = file.rawFile;
        const chunkSize = 200;
        const chunks = chunk(resultsAsArray, chunkSize);
        const promises: Promise<void>[] = [];
  
        chunks.forEach((setOfRows, i) => {
          const beginningIndex = chunkSize * i;
          const promise = this.fileService.createContentForFile(this.submissionFile.organizationId, this.documentType, this.submissionFile.submissionFileId, beginningIndex, setOfRows).toPromise();
          promises.push(promise);
        });
  
        await Promise.all(promises);

        this.spinnerService.hide();
        this.coreService.popMessage(`You have successfully uploaded your eligibility file. Results will be available within twenty four hours.`, 'success', 5000);
      }
      else {
        this.spinnerService.hide();
        this.coreService.popMessage(formatVerificationError, 'error', 5000);
      }
    };

    return true;
  }
}
