import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import * as dayjs from 'dayjs';

@Directive({
    selector: '[appEndOfMonthValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: EndOfMonthDirective, multi: true}]
  })
export class EndOfMonthDirective implements Validator {

  validator: ValidatorFn;
  constructor() {
    this.validator = this.endOfMonthValidator();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator(c);
  }

  endOfMonthValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any } | null => {
      if (control.value != null && control.value !== '') {
        const current = control.value;
        const endOfMonthDay = dayjs(`${current.month}-01-${current.year}`).endOf('month');
        const day = dayjs(`${current.month}-${current.day}-${current.year}`);
        return !day.isSame(endOfMonthDay, 'day') ? { endOfMonth: {value: control.value }} : null;
      } else {
        return null;
      }
    };
  }
}
