import SubscriberComposite from 'src/app/models/subscriberComposite';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import Subscriber from '../models/subscriber';
import Member from '../models/member';
import MemberAddress from '../models/memberAddress';
import EmailUpdate from '../models/emailUpdate';
import SubscriberContact from '../models/subscriberContact';
import TerminationComposite from '../models/terminationComposite';
import MemberNote from '../models/memberNote';
import SelfPayMember from '../models/selfPayMember';
@Injectable({
  providedIn: 'root'
})
export class SubscriberService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getSubscribers(): Observable<Subscriber[]> {
    return this.http
      .get<Subscriber[]>(`${env.apiUrl}/Subscriber`)
      .pipe(map(x => this.commonService.createObjects(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getSubscriberById(id: string): Observable<Subscriber> {
    return this.http
      .get(`${env.apiUrl}/Subscriber/${id}`)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getSubscriberWithSOE(memberId: string, soeId: string): Observable<Subscriber> {
    return this.http
      .get(`${env.apiUrl}/Subscriber/${memberId}/soe/${soeId}`)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }
  createSubscriber(subscriber: SubscriberComposite, organizationId: string): Observable<Subscriber> {
    return this.http
      .post<Subscriber>(`${env.apiUrl}/Subscriber/${organizationId}`, subscriber)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleErrorEx(subscriber)));
  }

  updateSubscriber(subscriberComposite: SubscriberComposite): Observable<Subscriber> {
    return this.http
      .put<SubscriberComposite>(`${env.apiUrl}/Subscriber/${subscriberComposite.memberId}`, subscriberComposite)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSubscriberDeathDate(subscriberComposite, deathDate: Date): Observable<Subscriber> {
    return this.http
      .put<SubscriberComposite>(`${env.apiUrl}/Subscriber/${subscriberComposite.memberId}/deathDate`, deathDate)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSubscriberEmail(e: EmailUpdate): Observable<Subscriber> {
    return this.http
      .put<EmailUpdate>(`${env.apiUrl}/Subscriber/${e.memberId}/email`, e)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSubscriberContact(c: SubscriberContact): Observable<SubscriberContact> {
    return this.http
      .put<SubscriberContact>(`${env.apiUrl}/Subscriber/${c.memberId}/contact`, c)
      .pipe(map(x => this.commonService.createObject(SubscriberContact, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  reinstateSubscriber(memberId: string): Observable<Subscriber> {
    return this.http
      .put<Subscriber>(`${env.apiUrl}/Subscriber/${memberId}/reinstate`, {})
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  transferSubscriber(subscriberComposite: SubscriberComposite, organizationId: string): Observable<Subscriber> {
    return this.http
      .post<SubscriberComposite>(`${env.apiUrl}/Subscriber/${subscriberComposite.memberId}/member/${organizationId}`, subscriberComposite)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }
  
  claimSubscriber(subscriberComposite: SubscriberComposite, organizationId: string): Observable<Subscriber> {
    return this.http
      .post<SubscriberComposite>(`${env.apiUrl}/Subscriber/${subscriberComposite.memberId}/member/${organizationId}/claim`, subscriberComposite)
      .pipe(map(x => this.commonService.createObject(Subscriber, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  terminateSubscriber(terminateComposite: TerminationComposite): Observable<Subscriber> {
    return this.http
    .put<Subscriber>(`${env.apiUrl}/Subscriber/${terminateComposite.memberId}/terminate`, terminateComposite)
    .pipe(map(x => this.commonService.createObject(Subscriber, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createSubscriberNote(note: MemberNote) {
    return this.http.post<MemberNote>(`${env.apiUrl}/Subscriber/${note.memberId}/note`, note)
    .pipe(map(x => this.commonService.createObject(MemberNote, x)))
    .pipe(catchError(this.commonService.handleError));
  }
  createSelfPaySubscriber(selfPayMember: SelfPayMember): Observable<SelfPayMember> {
    return this.http
    .post<SelfPayMember>(`${env.apiUrl}/hca/SelfPay`, selfPayMember)
    .pipe(map(x => this.commonService.createObject(SelfPayMember, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateSelfPaySubscriber(memberId: string, selfPayMember: SelfPayMember): Observable<SelfPayMember> {
    return this.http.put<SelfPayMember>(`${env.apiUrl}/hca/selfpay/${memberId}`, selfPayMember)
    .pipe(map(x => this.commonService.createObject(SelfPayMember, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  claimSelfPaySubscriber(selfPayMember: SelfPayMember): Observable<SelfPayMember> {
    return this.http
    .post<SelfPayMember>(`${env.apiUrl}/hca/SelfPay/${selfPayMember.memberId}`, selfPayMember)
    .pipe(map(x => this.commonService.createObject(SelfPayMember, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  cancelTransfer(terminationComposite: TerminationComposite): Observable<Subscriber> {
    return this.http
    .post<Subscriber>(`${env.apiUrl}/Subscriber/${terminationComposite.memberId}/cancelTransfer`, null)
    .pipe(map(x => this.commonService.createObject(Subscriber, x)))
    .pipe(catchError(this.commonService.handleError));
  }
}
