<form #accessForm="ngForm">
  <div class="grid-container">
    <kendo-grid
      #kendoGrid
      [data]="gridData"
      class="k-grid-ignore-click"
      [filterable]="'menu'"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="true"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      (dataStateChange)="dataStateChange($event)"
    >
    
    <kendo-grid-column
        [style.flex]="col.flex || 1"
        *ngFor="let col of gridColumns"
        title="{{ col.title }}"
        field="{{ col.field }}"
        [filter]="col.filter ? col.filter : null"
        [sortable]="col.sortable==false ? false : true"
        [headerStyle]="{ 'font-weight': 'bold' }"
      >
      
        <ng-template kendoGridFilterMenuTemplate
          let-column="col"
          let-filter="filter"
          let-filterService="filterService"
          *ngIf="col.field.endsWith('Date')"
          >
          <date-picker
            name="dateFilter"
            [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
            (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
            [label]="col.title">
          </date-picker>
        </ng-template>
        <ng-template kendoGridCellTemplate *ngIf="col.field !== 'organizationOrganizationString'" let-dataItem="dataItem" let-rowIndex="rowIndex">
          <input [pattern]="col.field === 'emailAddress' ? env.emailRegex : null" type="text" class="form-control" required name="{{ col.field }}{{ rowIndex }}" [disabled]="!isHCAAdmin || dataItem.authSystemIdentifier || (col.field == 'emailAddress' && dataItem.isClaimed)" [(ngModel)]="dataItem[col.field]" />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column *ngIf="isHCAAdmin" title="Manage" width="200" [headerStyle]="{ 'font-weight': 'bold' }">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <div fxFlexFill fxLayoutAlign="space-between">
            <button class="btn btn-primary" (click)="saveUserHandler(dataItem)" type="button">Save</button>
            <button *ngIf="dataItem.systemUserId && dataItem.isClaimed" class="btn btn-secondary" (click)="disassociate.emit(dataItem)" type="button">Release <br/> account</button>
          </div>
        </ng-template>
      </kendo-grid-command-column>
      <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="card" #formContainer>
          <div class="card-body" style="margin-left: 12px">
            <div *ngIf="isHCAAdmin" fxLayout="row" fxLayoutAlign="end end">
              <button type="button" class="btn btn-light hca-add-button" (click)="addAccessRole(dataItem)"><fa-icon [icon]="icons.faPlusCircle" size="1x"></fa-icon> Add Access Role</button>
            </div>
            <div class="hca-data-row" fxLayout="row wrap" fxflex fxLayoutAlign="space-between center" *ngFor="let role of dataItem.perspayAdminRoles; let userRoleIndex = index">
              <div class="form-group col-md-3 required">
                <label [for]="'orgName' + userRoleIndex">Organization</label>
                <select required [disabled]="role.systemUserAccessId !== null" required class="form-control" [id]="'orgName' + userRoleIndex" [name]="'orgName' + userRoleIndex" [(ngModel)]="role.organizationId">
                  <option *ngFor="let organization of lookups.organizations" [(ngValue)]="organization.organizationId">
                    {{ organization.organizationName }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3 required">
                <label [for]="'accessLevel' + userRoleIndex">Access Level</label>
                <select required [disabled]="role.systemUserAccessId !== null" required class="form-control" [name]="'accessLevel' + userRoleIndex" [(ngModel)]="role.userAccessLevelId">
                  <option *ngFor="let accessLevel of lookups.userAccessLevels" [(ngValue)]="accessLevel.userAccessLevelId">
                    {{ accessLevel.userAccessLevelName }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <date-picker
                  #dateAssigned="ngModel"
                  [name]="'dateAssigned' + userRoleIndex"
                  label="Date Assigned"
                  [min]="role.systemUserAccessId === null ? now : undefined"
                  [required]="true"
                  [disabled]="!!role.systemUserAccessId"
                  [(ngModel)]="role.effectiveStartDate"></date-picker>
              </div>
              <div class="form-group col-md-3" *ngIf="role.originalEffectiveEndDate !== null">
                <date-picker
                  #dateAssigned="ngModel"
                  [name]="'dateRemoved' + userRoleIndex"
                  label="Date Removed"
                  [min]="now"
                  [disabled]="!isHCAAdmin"
                  [(ngModel)]="role.effectiveEndDate"></date-picker>
              </div>
              <div fxFlex fxLayoutAlign="end center" *ngIf="isHCAAdmin">
                <button *ngIf="role.systemUserAccessId === null || role.originalEffectiveEndDate !== null" (click)="emitUpdate(role, rowIndex, userRoleIndex, formContainer)" type="button" class="btn btn-light hca-add-button">
                  <fa-icon [icon]="icons.faSave" size="1x"></fa-icon> Save Access
                </button>
                <button
                  *ngIf="role.systemUserAccessId !== null && role.originalEffectiveEndDate === null"
                  (click)="removeAccess(role, rowIndex, userRoleIndex, formContainer)"
                  type="button"
                  class="btn btn-light hca-remove-button"
                >
                  <fa-icon [icon]="icons.faBan" size="1x"></fa-icon> Remove Access
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-grid>
  </div>
</form>
