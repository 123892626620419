export default class TermsOfUseAction {
  termsOfUseActionId: string;
  termsOfUseId: string;
  termsOfUseActionTypeId: string;

  constructor(termsOfUseAction?) {
    if (termsOfUseAction) {
      this.termsOfUseActionId = termsOfUseAction.termsOfUseActionId;
      this.termsOfUseId = termsOfUseAction.termsOfUseId;
    }
  }
}
