import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SystemUser from 'src/app/models/user';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { env } from 'src/env/development';
@UntilDestroy()
@Component({
  selector: 'hca-admin-root',
  templateUrl: 'hca.admin.root.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class HCAAdminRootComponent implements OnInit {
  icons = {
    faPlus,
    faMinus,
  };
  systemUser: SystemUser;
  env = env;
  isHcaSysAdmin = false;
  
  constructor(
    private route: ActivatedRoute,
    private spinnerOverlayService: SpinnerOverlayService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.systemUser = data.user;
      this.isHcaSysAdmin = this.coreService.systemUserHasAccess(AccessLevel.SystemAdmin, UserTypeCode.HCA);
    });
  }

  deactivate(e): void {
    if (e.resetParent) {
      
    }
  }

  acAvailableToUser() {
    return env.accountCorrectionAccessList.includes(this.systemUser?.emailAddress) || env.accountCorrectionAccessList[0] == 'Any'
  }
  
}
