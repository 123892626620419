import { Pipe, PipeTransform } from '@angular/core';
import BaseAddressWithCountry from 'src/app/models/baseAddressWithCountry';

@Pipe({
  name: 'hasCountry'
})
export class HasCountryPipe implements PipeTransform {
  transform(value: any): boolean {
    return value instanceof BaseAddressWithCountry;
  }
}
