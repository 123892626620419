<div class="card">
  <div class="card-body">
    <form #subscriberExistingSearch="ngForm" #formContainer>
      <div class="form-group col-md-6 required">
        <label for="socialSecurityNumber" id="ssnLabel">SSN</label>
        <kendo-maskedtextbox
          [mask]="env.ssnMask"
          [pattern]="env.ssnItinRegex"
          [(ngModel)]="subscriberTransferSearch.socialSecurityNumber"
          [maskOnFocus]="true"
          required
          name="socialSecurityNumber"
          id="socialSecurityNumber"
          aria-labelledby="ssnLabel"
          #ssn="ngModel"
          class="form-control"
          [forbiddenValues]="env.invalidSSNValues"
        ></kendo-maskedtextbox>
        <div *ngIf="ssn.invalid && ssn.touched && ssn.errors.forbiddenValues" class="alert alert-danger">This SSN is invalid.</div>
      </div>
      <div class="form-group col-lg-3 col-md-4">
        <date-picker
          #eligibilityDatePicker
          #eligibilityDate="ngModel"
          name="eligibilityDate"
          [label]="isSelfPayAdd ? 'Agency Effective Date' : 'Date of Eligibility'"
          enforceLowerLimit
          [lowerLimitOffset]="true"
          [agencyCode]="agencyCode"
          [onlyAllowFirstOfMonth]="isSelfPayAdd"
          required
          [(ngModel)]="subscriberTransferSearch.eligibilityDate"
          (ngModelChange)="validateEligibilityDate($event, subscriberTransferSearch.sebbIsNewlyEligibleBeforeFirstDayOfSchool)"
          (ngModelChange)="determineFirstDayVisibility($event)"
          ></date-picker>
        <div *ngIf="eligibilityDate.invalid && eligibilityDate.dirty && eligibilityDate.errors?.notFirstOfMonth" class="alert alert-danger">
          The date entered must be the 1st day of the month.
        </div>
        <div *ngIf="eligibilityDate.invalid && eligibilityDate.dirty && eligibilityDate.errors?.ngbDate?.minDate" class="alert alert-danger">
          The date entered is less than the current lower limit date. Do not proceed with adding the subscriber using an alternative date and contact Outreach and Training for assistance.
        </div>
        <div *ngIf="eligibilityDate.invalid && (eligibilityDate.dirty) && eligibilityDate.errors?.ngbDate?.maxDate" class="alert alert-danger">
          The future date entered is not allowed based on the current invoicing schedule.
        </div>
      </div>
      <div *ngIf="isSEBBOrg && showFirstDayOfSchoolCheckbox" class="k-form-field mgbt-medium mgtp-x-small" style="width: 100%; margin-left: 10px;">
        <checkbox
          bold
          name="eligibleBeforeFDS"
          (ngModelChange)="removeMinDateError(); validateEligibilityDate(subscriberTransferSearch.eligibilityDate, $event)"
          [(ngModel)]="subscriberTransferSearch.sebbIsNewlyEligibleBeforeFirstDayOfSchool">
          Subscriber's first day of work is on or before the first day of school
        </checkbox>
      </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="center space-between">
      <button class="btn btn-primary" (click)="pushCheckForExistingRecord(formContainer)">Next</button>
    </div>
  </div>
</div>
