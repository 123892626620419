import { faCalendar, faExclamationCircle, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { Component, ViewEncapsulation, Input, OnInit, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { findIndex, cloneDeep, map, filter, without, merge, includes } from 'lodash';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';
import Member from 'src/app/models/member';
import SelfPay from 'src/app/models/selfPay';
import { CoreService } from 'src/app/services/core.service';
import MemberAddress from 'src/app/models/memberAddress';

@Component({
  selector: 'self-pay-dependent',
  templateUrl: 'selfPay.dependent.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class SelfPayDependentComponent implements OnChanges, OnInit {
  @Input() selfPay: SelfPay;
  @Input() currentDependents: Member[];
  @Input() agency: string;
  @Input() pebbVisionDate: Date;
  @Input() lookups = {
    selfPayTypes: [],
    documentTypes: [],
    genderIdentities: [],
    birthSexes: [],
    countries: [],
    counties: [],
  };
  @Output() saveSelfPay: EventEmitter<any> = new EventEmitter<any>();
  @Output() previous: EventEmitter<void> = new EventEmitter();
  existingDependents: Member[] = [];
  newDependents: SelfPayFormDependent[] = [];
  showSpouse = false;
  showChild = false;
  @ViewChild('dependentAccord') dependentAccord: NgbAccordion;
  icons = {
      faCalendar,
      faExclamationCircle,
      faPlus,
      faMinus
  };
  selfPayRelationshipTypes = [
    {code: 'C', name: 'Child'},
    {code: 'P', name: 'Stepchild (not legally adopted)'},
    {code: 'F', name: 'Extended dependent (attach a copy of court order)' },
    {code: 'A', name: 'Child with a disability age 26 or older'},
    {code: 'S', name: 'Spouse/state-registered domestic partner'}
  ];
  addDependentDisabledCodes = [ 'C' ]; //self pay types that don't allow adding dependents
  isRetireeOE = false;
  isCobraOE = false;
  activeIds = [];
  constructor(private coreService: CoreService) { }

  ngOnChanges(): void {
    // if initial time through
    if (!this.selfPay.formJson.dependents) {
      if (this.currentDependents.length > 0) {
          this.selfPay.formJson.dependents = map(this.currentDependents, (cd) => new SelfPayFormDependent(cd));
        } else {
          this.selfPay.formJson.dependents = [];
        }
      }
      if(this.selfPay) {
      this.isCobraOE = this.selfPay.selfPayType?.selfPayTypeCode === 'OEC';
      this.isRetireeOE = this.selfPay.selfPayType?.selfPayTypeCode === 'OER';
    }
  }

  ngOnInit(): void {
    this.activeIds = map(this.selfPay.formJson.dependents, (d: SelfPayFormDependent) => d.socialSecurityNumber);
  }

  addNewDependent(): void {
    const newDep = new SelfPayFormDependent({});
    newDep.socialSecurityNumber = null;
    newDep.residentialAddress = new MemberAddress();
    this.selfPay.formJson.dependents.push(newDep);
    this.activeIds.push(null);
    const nd = document.getElementById('newDep');
    nd.scrollIntoView();
  }

  emitSelfPay(e): void {
    this.saveSelfPay.emit(e);
  }

  pushDependent(dependent, index, dep, thisForm: NgForm, formContainer: any): void {
    this.markAllControlsAsTouched(thisForm);
    dependent.confirmed = true;
    if (thisForm.valid) {
      if (!dependent.memberId && !dependent.medicalCoverInd && !dependent.dentalCoverInd) {
        return this.coreService.popMessage('You must make a coverage election for a newly added dependent.', 'error', 3000);
      }
      if ((this.isRetireeOE || this.isCobraOE) && !dependent.medicalCoverInd && !dependent.dentalCoverInd && !dependent.visionCoverInd && !dependent.terminateAllCoverageInd) {
        return this.coreService.popMessage('You must make a coverage election or choose to terminate for this dependent.', 'error', 3000);
      }
      if (dep?.relationshipTypeName) {
        dependent.relationshipTypeName = dep.relationshipTypeName;
      }
      const dependentIndex = findIndex(this.selfPay.formJson.dependents, (d) => d.socialSecurityNumber === dependent.socialSecurityNumber);
      this.selfPay.formJson.dependents.splice(dependentIndex, 1, dependent);
      // do not advance step when saving deps
      this.saveSelfPay.emit({selfPay: this.selfPay, skipAdvance: true});
      if(!this.isCobraOE && !this.isRetireeOE) {
        this.dependentAccord.collapseAll();
      }else {
        const activeIdIdx = findIndex(this.activeIds, (d) => d == dependent.socialSecurityNumber);
        this.activeIds.splice(activeIdIdx, 1)
        const accordForm = document.getElementById('accordTitle');
        accordForm.scrollIntoView();
      }
    }
    else {
      this.coreService.popMessage('Please complete required fields.', 'error', 4000, this.coreService.getInvalidFields(formContainer));
    }
  }

  collapseAccordAndRemoveDep(): void {
    this.selfPay.formJson.dependents = filter(this.selfPay.formJson.dependents, (d) => d.memberId || d.firstName || d.lastName);
  }

  markAllControlsAsTouched(thisForm: NgForm): void {
    this.coreService.markFormControlsAsTouched(thisForm);
  }

  canAddDependent(selfPayTypeCode): boolean {
    return this.addDependentDisabledCodes.indexOf(selfPayTypeCode)==-1;
  }


}
