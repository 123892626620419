import { uniq } from 'lodash';

// NOTE: If you add an enumeration below, make sure to add it to the map in lookups.resolver.ts
export enum LookupType {
  ActionType = 'actionType',
  AddressType = 'addressType',
  Agency = 'agency',
  ApplicationSetting = 'applicationSetting',
  AttestationType = 'attestationType',
  BenefitSetup = 'benefitSetup',
  BirthSex = 'birthSex',
  Carrier = 'carrier',
  Carrier834Carrier = 'carrier834Carrier',
  CertificationType = 'certificationType',
  CobraQualifyReason = 'cobraQualifyReason',
  ContactType = 'contactType',
  Country = 'country',
  County = 'county',
  DocumentType = 'documentType',
  Ethnicity = 'ethnicity',
  GenderIdentity = 'genderIdentity',
  Language = 'language',
  LowerLimit = 'lowerLimit',
  MedicareOption = 'medicareOption',
  MemberType = 'memberType',
  Module = 'module',
  Organization = 'organization',
  OrganizationType = 'organizationType',
  PhoneNumberType = 'phoneNumberType',
  PlanType = 'planType',
  PreferredContactMethod = 'preferredContactMethod',
  Question = 'question',
  RateStructure = 'rateStructure',
  Reason = 'reason',
  RelationshipCertificationStatus = 'relationshipCertificationStatus',
  RelationshipQualifyReason = 'relationshipQualifyReason',
  RelationshipType = 'relationshipType',
  RelationshipVerificationStatus = 'relationshipVerificationStatus',
  ReportType = 'reportType',
  Response = 'response',
  SelfPayProcessStatus = 'selfPayProcessStatus',
  SelfPayReason = 'selfPayReason',
  SelfPayType = 'selfPayType',
  SelfPayPaymentType = 'selfPayPaymentType',
  SelfPayVerificationStatus = 'selfPayVerificationStatus',
  SpecialOpenEnrollmentType = 'specialOpenEnrollmentType',
  SpecialOpenEnrollmentVerificationStatus = 'specialOpenEnrollmentVerificationStatus',
  SubmissionFileResponseType = 'submissionFileResponseType',
  TermsOfUseActionType = 'termsOfUseActionType',
  UserType = 'userType',
  UserRole = 'userRole',
  UserAccessLevel = 'userAccessLevel'
}

export function Lookups(...lookups: LookupType[]): any {
  const resolvedLookups: { [key: string]: any[] } = {};
  const requestedLookups: string[] = uniq(lookups);

  return (target): any => {
    if (!target.prototype.requestedLookups) {
      Object.defineProperty(target.prototype, 'requestedLookups', {
        get: () => {
          return requestedLookups;
        }
      });
    }

    if (!target.prototype.resolvedLookups) {
      Object.defineProperty(target.prototype, 'resolvedLookups', {
        get: () => {
          return resolvedLookups;
        }
      });
    }

    return target;
  };
}
