<nav fxFlexFill role="navigation" fxFlexFill aria-label="PEBB Footer" class="footer-parent navbar-expand-lg">
  <div fxFlexFill fxLayout="row" fxLayout.xs="column">
    <div fxFlex="25" fxLayout="column" class="footer-left card" id="navExternalLinks">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item"><a class="nav-link" target="_blank" title href="https://www.hca.wa.gov/contact-hca">Contact HCA</a></li>
      </ul>
    </div>
    <div fxFlex="75" fxLayout="column" class="footer-right card" id="navExternalLinks">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item"><a class="nav-link" target="_blank" title href="https://www.hca.wa.gov/about-hca/accessibility">Accessibility</a></li>
        <li class="nav-item"><a class="nav-link" target="_blank" title href="https://www.hca.wa.gov/about-hca/language-access">Language Access</a></li>
        <li class="nav-item"><a class="nav-link" target="_blank" title href="https://www.hca.wa.gov/about-hca/non-discrimination-statement">Non-discrimination</a></li>
        <li class="nav-item"><a class="nav-link" target="_blank" title href="https://www.hca.wa.gov/about-hca/notice-privacy-practices">Privacy practices</a></li>
      </ul>
      <span id="hca-copyright">Copyright ©2020 Washington Health Care Authority</span>
      <span id="pebb-version">Benefits 24/7 v.{{ version }} / {{ apiVersion}}</span>
    </div>
  </div>
</nav>
