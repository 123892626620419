import { Component, ViewEncapsulation, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { cloneDeep, find, filter } from 'lodash';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import * as dayjs from 'dayjs';
import Subscriber from 'src/app/models/subscriber';
import TerminationComposite from 'src/app/models/terminationComposite';
import Reason from 'src/app/models/reason';
import { CoreService } from 'src/app/services/core.service';
import SelfPayTerminationComposite from 'src/app/models/selfPayTerminationComposite';
import { parseHostBindings } from '@angular/compiler';
import SelfPayOrigin from 'src/app/models/selfPayOrigin';
import { env } from 'src/env/development'

@Component({
  selector: 'selfpay-enrollment-term',
  templateUrl: 'selfpay.enrollment.term.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class SelfpayEnrollmentTermComponent implements OnChanges {
  @Input() lookups = {
    memberTypes: [],
    organizations: [],
    selfPayTerminationReasons: [],
    selfPayEligibilityReasons: [],
  };
  icons = {
    faCalendar
  };
  @Input() disabled = true;
  @Input() terminationComposite: SelfPayTerminationComposite;
  @Input() isHCA = false;
  @Input() termSubmitted = false;
  @Input() memberSelfPayOrigin: SelfPayOrigin;
  minDate: Date;
  maxDate: Date;
  futureDate: Date;
  reasonDeath: string;
  reasonTransfer: string;
  reasonNeverEffective: string;
  deathMonthStart: Date;
  deathMonthEnd: Date;
  currentTermRecord: SelfPayTerminationComposite;
  @Output() submitSelfPayTermination: EventEmitter<TerminationComposite> = new EventEmitter<TerminationComposite>();
  @ViewChild('termForm') public termForm: NgForm;
  constructor(
    private coreService: CoreService
  ) {}

  // cannot be > the last day of next month
  // cannot be < eligibility date
  ngOnChanges(): void {
    const nextMonth = dayjs().add(1, 'month');
    const today = dayjs();
    if (this.terminationComposite) {
      const death = find(this.lookups.selfPayTerminationReasons, (tr: Reason) => tr.reasonName === 'Death');
      const transfer = find(this.lookups.selfPayTerminationReasons, (tr: Reason) => tr.reasonName === 'Transfer');
      const neverEffective = find(this.lookups.selfPayTerminationReasons, (tr: Reason) => tr.reasonName === 'Never effective');

      this.reasonDeath = death ? death.reasonId : null;
      this.reasonTransfer  = transfer ? transfer.reasonId : null;
      this.currentTermRecord = cloneDeep(this.terminationComposite);
      this.reasonNeverEffective = neverEffective ? neverEffective.reasonId : null;

      if (this.currentTermRecord.lossOfEligibilityReasonId) {
        this.termReasonUpdate(this.currentTermRecord.lossOfEligibilityReasonId);
      } else {
        this.minDate = this.terminationComposite.eligibilityDate;
      }

      if (this.currentTermRecord.lossOfEligibilityReasonId) {
        this.termReasonUpdate(this.currentTermRecord.lossOfEligibilityReasonId);
      }
    }

    if (this.terminationComposite.lossOfEligibilityReasonId === this.reasonDeath && !env.allowFutureDeceasedDates){
      this.deathMonthStart = dayjs(this.currentTermRecord.terminationDate).startOf('month').toDate();
      this.deathMonthEnd = dayjs(this.currentTermRecord.terminationDate).endOf('month').toDate();
    }
  }

  termDateDisabled(lossOfEligibilityReasonId: string, isHca: boolean, parentDisabled: boolean): boolean {
    const isDeath = lossOfEligibilityReasonId === this.reasonDeath;

    if (isDeath) {
      if (isHca) {
        return false;
      }

      return parentDisabled;
    }

    return parentDisabled;
  }

  markAllControlsAsTouched(): void {
    for (const i in this.termForm.controls) {
      if (this.termForm.controls[i]) {
        this.termForm.controls[i].markAsTouched();
      }
    }
  }

  onTerminateMemberCoverage(): void {
    this.termSubmitted = true;
    this.markAllControlsAsTouched();
    if (this.termForm.valid) {
      //if term reason is not never effective, term date cannot be prior to self pay start date
      if (this.currentTermRecord.lossOfEligibilityReasonId && this.currentTermRecord.lossOfEligibilityReasonId != this.reasonNeverEffective
          && this.currentTermRecord.terminationDate < this.memberSelfPayOrigin.agencyEffectiveStartDate) {
        this.coreService.popMessage('Termination date cannot be less than effective start date.', 'error', 4000);
        this.termSubmitted = false;
        return;
      }

      this.submitSelfPayTermination.emit(this.currentTermRecord);
    } else {
      this.coreService.popMessage('Field validation errors. Please correct and try again.', 'error', 4000);
      this.termSubmitted = false;
    }
  }

  termReasonUpdate(newValue: string): void {
    if (newValue === this.reasonDeath && !env.allowFutureDeceasedDates) {
      this.minDate = this.terminationComposite.birthDate;
      this.maxDate = new Date();
    } else if (newValue === this.reasonNeverEffective) {
      const previousMonth = dayjs(this.terminationComposite.eligibilityDate).subtract(1, 'month');
      this.currentTermRecord.terminationDate = dayjs((previousMonth.month() + 1) + '/' + previousMonth.daysInMonth() + '/' + previousMonth.year()).toDate();

      this.minDate = null;
      this.maxDate = null;
    } else {
      this.minDate = this.terminationComposite.eligibilityDate;
      this.maxDate = null;
    }
  }
}
