<div fxLayout="row wrap">
  <div fxLayout.gt-sm="column" class="ie-height-xs mgbt-medium-sm" fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100">
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="subscriberName" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">Subscriber:</label>
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberName" name="subscriberName" value="{{dataItem.subscriberMember.fullName}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="subscriberSSN" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label" >SSN:</label>  
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberSSN" name="subscriberSSN" value="{{dataItem.subscriberMember.socialSecurityNumber}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="subscriberBirthDate" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">DOB:</label>
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberBirthDate" name="subscriberBirthDate" value="{{dataItem.subscriberMember.birthDate | date: 'MM/dd/yyyy'}}">
    </div>
  </div>
  <div fxLayout.gt-sm="column" class="ie-height-xs mgbt-medium-sm" fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100">
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="dependentName" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">Dependent:</label>
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="dependentName" name="dependentName" value="{{dataItem.dependentMember.fullName}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="dependentSSN" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label" >SSN:</label>  
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="dependentSSN" name="dependentSSN"value="{{dataItem.dependentMember.socialSecurityNumber}}">
    </div>
    <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
      <label for="dependentBirthDate" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">DOB:</label>
      <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="dependentBirthDate" name="dependentBirthDate" value="{{dataItem.dependentMember.birthDate | date: 'MM/dd/yyyy'}}">
    </div>
  </div>
</div>
<div fxLayout="row wrap">
  <div fxLayout.gt-sm="column" class="ie-height-xs mgbt-medium-sm" fxLayout="row wrap" fxFlex.gt-sm="50" fxFlex="100">
    <div fxLayout.gt-sm="row" fxLayoutAlign="space-between" fxLayout="column" fxFlex="100">
      <label for="relationshipType" fxFlex.gt-sm="45" fxFlex="100" class="col-form-label hca-control-label">Relationship Type:</label>
      <input type="text" readonly fxFlex.gt-sm="55" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="relationshipType" name="relationshipType" value="{{dataItem.relationshipType.relationshipTypeName}}">
    </div>    
    <div fxLayout.gt-sm="row" fxLayoutAlign="space-between" fxLayout="column" fxFlex="100">
      <label for="relationshipQualifyReason" fxFlex.gt-sm="45" fxFlex="100" class="col-form-label hca-control-label">Relationship Qualify Reason:</label>
      <input type="text" readonly fxFlex.gt-sm="55" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="relationshipQualifyReason" name="relationshipQualifyReason" value="{{dataItem.relationshipQualifyReason.relationshipQualifyReasonName}}">
    </div>
  </div>
  <div fxLayout.gt-sm="column" class="ie-width-s hca-document-list-container mgbt-medium-sm" fxLayout="row wrap" fxFlex.gt-sm="50" style="width: 100%" fxFlex="100">
    <h4 *ngIf="dataItem.documents.length > 0" style="align-self:center" class="mobile-text-centered">Verification documents</h4>
    <generic-document-viewer fxLayout.gt-sm="column" fxLayout="row wrap" fxFlex="100" [(documents)]="dataItem.documents"></generic-document-viewer>
  </div>
</div>

