import Organization from 'src/app/models/organization';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
import EnrollmentPeriodType from 'src/app/models/enrollmentPeriodType';
import Milestone from './milestone';
import { map } from 'lodash';
import PlanType from './planType';
import MemberType from './memberType';
import OrganizationType from './organizationType';
import Reason from './reason';

dayjs.extend(isBetween);

export default class EnrollmentPeriod {
  enrollmentPeriodId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  coverageEffectiveStartDate: Date;
  isCurrentlyActive: boolean;
  enrollmentPeriodType: EnrollmentPeriodType;
  memberType: MemberType;
  organizationType: OrganizationType;
  electablePlanTypes: PlanType[];
  milestones: Milestone[];
  eventDate: Date;
  noBreak: boolean;
  organization: Organization;
  lossOfEligibilityReason: Reason;

  constructor(enrollmentPeriod?) {
    if (enrollmentPeriod) {
      this.enrollmentPeriodId = enrollmentPeriod.enrollmentPeriodId;
      this.effectiveStartDate = new Date(enrollmentPeriod.effectiveStartDate);
      this.effectiveEndDate = enrollmentPeriod.effectiveEndDate ? new Date(enrollmentPeriod.effectiveEndDate) : null;
      this.eventDate = enrollmentPeriod.eventDate ? new Date(enrollmentPeriod.eventDate) : null;
      this.isCurrentlyActive = dayjs().isBetween(dayjs(this.effectiveStartDate).startOf('day'), dayjs(this.effectiveEndDate).endOf('day'));
      this.coverageEffectiveStartDate = new Date(enrollmentPeriod.coverageEffectiveStartDate);
      this.enrollmentPeriodType = new EnrollmentPeriodType(enrollmentPeriod.enrollmentPeriodType);
      this.milestones = map(enrollmentPeriod.milestones, (mi: Milestone) => new Milestone(mi));
      this.electablePlanTypes = map(enrollmentPeriod.electablePlanTypes, (planType: PlanType) => new PlanType(planType));
      this.memberType = enrollmentPeriod.memberType ? new MemberType(enrollmentPeriod.memberType) : null;
      this.organizationType = enrollmentPeriod.organizationType ? new OrganizationType(enrollmentPeriod.organizationType) : null;
      this.noBreak = enrollmentPeriod.noBreak;
      this.organization = enrollmentPeriod.organization;
      this.lossOfEligibilityReason = enrollmentPeriod.lossOfEligibilityReason;
    }
  }
}
