<!--row 1-->
<div class="form-row" fxLayout="row">
  <div class="form-group required" fxFlex="25">
    <label for="medicarePartAMedicareOptionId">Medicare Part A</label>
    <select required class="form-control" id="medicarePartAMedicareOptionId" name="medicarePartAMedicareOptionId"
      [(ngModel)]="memberMedicare.medicarePartAMedicareOptionId" (ngModelChange)="medicarePartAOptionChange()"
      [disabled]="readOnly || 
          memberMedicare.medicarePartBMedicareOptionId === medicareEnrolledOptionId || 
          (originalPartBEnrolled && !memberMedicare.medicarePartBEndDate && (
              memberMedicare.medicarePartBMedicareOptionId === medicareNotEnrolledOptionId || 
              memberMedicare.medicarePartBMedicareOptionId === medicareNeverEligibileId
            )
          )">
      <option
        *ngFor="let medicareOption of getMedicareOptions(originalPartAEnrolled,originalMemberMedicare?.medicarePartAEffectiveDate) | isObsolete"
        [ngValue]="medicareOption.medicareOptionId">{{ medicareOption.medicareOptionName }}</option>
    </select>
  </div>
  <div class="form-group" fxFlex="25" fxFlexOffset="5">
    <date-picker #partAEffectiveDateDatePicker #partAEffectiveDate="ngModel" name="partAEffectiveDate"
      [min]="simpleMember.birthDate" [disabledDates]="disableAllButFirstOfMonth" label="Start Date"
      onlyAllowFirstOfMonth [disabled]="readOnly || 
          memberMedicare.medicarePartBMedicareOptionId === medicareEnrolledOptionId || 
          memberMedicare.medicarePartAMedicareOptionId === medicareNeverEligibileId || 
          (!originalMemberMedicare && memberMedicare.medicarePartAMedicareOptionId === medicareNotEnrolledOptionId) || 
          (originalPartBEnrolled && !memberMedicare.medicarePartBEndDate && (
              memberMedicare.medicarePartBMedicareOptionId === medicareNotEnrolledOptionId
            )
          )" [required]="memberMedicare.medicarePartAMedicareOptionId === medicareEnrolledOptionId"
      [(ngModel)]="memberMedicare.medicarePartAEffectiveDate"></date-picker>
  </div>
  <div class="form-group" fxFlex="25" fxFlexOffset="5">
    <date-picker #partAEndDateDatePicker #partAEndDate="ngModel" name="partAEndDate" [min]="memberMedicare.medicarePartAEffectiveDate"
      [disabledDates]="disableAllButLastOfMonth" label="End Date"
      [required]="originalPartAEnrolled && memberMedicare.medicarePartAMedicareOptionId === medicareNotEnrolledOptionId"
      [disabled]="readOnly || 
          memberMedicare.medicarePartBMedicareOptionId === medicareEnrolledOptionId || 
          memberMedicare.medicarePartAMedicareOptionId === medicareEnrolledOptionId || 
          memberMedicare.medicarePartAMedicareOptionId === medicareNeverEligibileId || 
          (!originalPartAEnrolled && memberMedicare.medicarePartAMedicareOptionId === medicareNotEnrolledOptionId) || 
          (originalPartBEnrolled && !memberMedicare.medicarePartBEndDate && memberMedicare.medicarePartBMedicareOptionId === medicareNotEnrolledOptionId)"
      [(ngModel)]="memberMedicare.medicarePartAEndDate"></date-picker>
  </div>
</div>
<!--row 2-->
<div class="form-row" fxLayout="row">
  <div class="form-group required" fxFlex="25">
    <label for="medicarePartBMedicareOptionId">Medicare Part B</label>
    <select required class="form-control" id="medicarePartBMedicareOptionId" name="medicarePartBMedicareOptionId"
      [(ngModel)]="memberMedicare.medicarePartBMedicareOptionId" (ngModelChange)="medicarePartBOptionChange()"
      [disabled]="readOnly || memberMedicare.medicarePartDMedicareOptionId === medicareEnrolledOptionId || 
        memberMedicare.medicarePartAMedicareOptionId !== medicareEnrolledOptionId || !memberMedicare.medicarePartAEffectiveDate">
      <option
        *ngFor="let medicareOption of getMedicareOptions(originalPartBEnrolled,originalMemberMedicare?.medicarePartBEffectiveDate) | isObsolete"
        [ngValue]="medicareOption.medicareOptionId">{{ medicareOption.medicareOptionName }}</option>
    </select>
  </div>
  <div class="form-group required" fxFlex="25" fxFlexOffset="5">
    <date-picker #partBEffectiveDateDatePicker #partBEffectiveDate="ngModel" name="partBEffectiveDate"
      [min]="memberMedicare.medicarePartAEffectiveDate"
      label="Start Date"
      onlyAllowFirstOfMonth
      [required]="memberMedicare.medicarePartBMedicareOptionId === medicareEnrolledOptionId || originalPartBEnrolled"
      [disabled]="readOnly || 
        memberMedicare.medicarePartDMedicareOptionId === medicareEnrolledOptionId || 
        memberMedicare.medicarePartAMedicareOptionId != medicareEnrolledOptionId ||
        (!originalPartBEnrolled && memberMedicare.medicarePartBMedicareOptionId === medicareNotEnrolledOptionId) ||
        memberMedicare.medicarePartBMedicareOptionId === medicareNeverEligibileId"
      [(ngModel)]="memberMedicare.medicarePartBEffectiveDate"></date-picker>
  </div>
  <div class="form-group" fxFlex="25" fxFlexOffset="5">
    <date-picker #partBEndDateDatePicker #partBEndDate="ngModel" name="partBEndDate" [min]="memberMedicare.medicarePartBEffectiveDate"
      [disabledDates]="disableAllButLastOfMonth" [disabled]="readOnly || 
          memberMedicare.medicarePartAMedicareOptionId!=medicareEnrolledOptionId || 
          (!originalPartBEnrolled && memberMedicare.medicarePartBMedicareOptionId === medicareNotEnrolledOptionId) ||
          (memberMedicare.medicarePartBMedicareOptionId != medicareNotEnrolledOptionId)"
      [required]="originalPartBEnrolled && memberMedicare.medicarePartBMedicareOptionId != medicareEnrolledOptionId"
      label="End Date" [(ngModel)]="memberMedicare.medicarePartBEndDate"></date-picker>
  </div>
</div>
<!--row 3-->
<div class="form-row" fxLayout="row" *ngIf="medicareDEnabled">
  <div class="form-group required" fxFlex="25">
    <label for="medicarePartDMedicareOptionId">Medicare Part D</label>
    <select required class="form-control" id="medicarePartDMedicareOptionId" name="medicarePartDMedicareOptionId"
      [(ngModel)]="memberMedicare.medicarePartDMedicareOptionId" (ngModelChange)="medicarePartDOptionChange()"
      [disabled]="readOnly || memberMedicare.medicarePartBMedicareOptionId !== medicareEnrolledOptionId || !memberMedicare.medicarePartBEffectiveDate">
      <option
        *ngFor="let medicareOption of getMedicareOptions(originalPartDEnrolled, originalMemberMedicare?.medicarePartDEffectiveDate) | isObsolete"
        [ngValue]="medicareOption.medicareOptionId">{{ medicareOption.medicareOptionName }}</option>
    </select>
  </div>
  <div class="form-group required" fxFlex="25" fxFlexOffset="5">
    <date-picker #partDEffectiveDateDatePicker #partDEffectiveDate="ngModel" name="partDEffectiveDate"
      [min]="memberMedicare.medicarePartBEffectiveDate"
      label="Start Date"
      onlyAllowFirstOfMonth
      [disabled]="readOnly || 
        memberMedicare.medicarePartDMedicareOptionId !== medicareEnrolledOptionId ||
        (!originalPartDEnrolled && memberMedicare.medicarePartDMedicareOptionId === medicareNotEnrolledOptionId) ||
        memberMedicare.medicarePartDMedicareOptionId === medicareNeverEligibileId"
      [required]="memberMedicare.medicarePartDMedicareOptionId === medicareEnrolledOptionId || 
          originalPartDEnrolled" 
      (ngModelChange)="partDEffectiveDateChanged($event, memberMedicare.medicarePartDEndDate)"
      [(ngModel)]="memberMedicare.medicarePartDEffectiveDate"></date-picker>
  </div>
  <div class="form-group" fxFlex="25" fxFlexOffset="5">
    <date-picker #partDEndDateDatePicker #partDEndDate="ngModel" name="partDEndDate"
      [min]="memberMedicare.medicarePartDEffectiveDate"
      onlyAllowLastOfMonth
      [disabled]="readOnly ||
          memberMedicare.medicarePartDMedicareOptionId === medicareNeverEligibileId ||
          memberMedicare.medicarePartBMedicareOptionId !== medicareEnrolledOptionId || 
          (!originalPartDEnrolled && memberMedicare.medicarePartDMedicareOptionId === medicareNotEnrolledOptionId) ||
          (memberMedicare.medicarePartDMedicareOptionId !== medicareNotEnrolledOptionId)"
      [required]="originalPartDEnrolled && memberMedicare.medicarePartDMedicareOptionId != medicareEnrolledOptionId"
      label="End Date"
      (ngModelChange)="partDEndDateChanged(memberMedicare.medicarePartDEffectiveDate, $event)"
      [(ngModel)]="memberMedicare.medicarePartDEndDate"></date-picker>
  </div>
</div>
<!--row 4-->
<div class="form-row" fxLayout="row">
  <div [class.required]="memberMedicare.medicarePartAEnrolledInd || memberMedicare.medicarePartBEnrolledInd"
    class="form-group" fxFlex="25">
    <label for="medicareNumber">Medicare number (HICN)</label>
    <input pattern="[a-zA-Z0-9 -]*"
      [required]="memberMedicare.medicarePartAEnrolledInd || memberMedicare.medicarePartBEnrolledInd"
      [disabled]="readOnly" type="text" class="form-control" id="medicareNumber" name="medicareNumber"
      [(ngModel)]="memberMedicare.medicareNumber" />
  </div>
  <div class="form-group" fxFlex="25" fxFlexOffset="5">
    <date-picker #pcsd="ngModel" name="pcsd"
      [label]="medicareDEnabled ? 'Enrollment signature date' : 'Part C signature date'"
      [max]="enrollmentSignatureMaxDate"
      [disabled]="readOnly"
      [required]="!!memberMedicare.medicarePartDEffectiveDate"
      [(ngModel)]="memberMedicare.medicareEnrollmentSignatureDate"></date-picker>
  </div>
  <div class="form-group" fxFlex="25" fxFlexOffset="5" *ngIf="medicareDEnabled">
    <date-picker #disenrollmentSignatureDateDatePicker #disenrollmentSignatureDate="ngModel" name="disenrollmentSignatureDate"
      [max]="disenrollmentSignatureMaxDate"
      [disabled]="readOnly"
      [required]="!isHCA && !!memberMedicare.medicarePartDEndDate && memberMedicare.medicarePartDMedicareOptionId !== medicareNeverEligibileId"
      label="Disenrollment signature date"
      [(ngModel)]="memberMedicare.medicareDisenrollmentSignatureDate"></date-picker>
  </div>
</div>