// ng
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import { ProfileComponent } from './../modules/subscriber/modules/profile/profile.component';
import { UploadComponent } from 'src/app/modules/subscriber/modules/upload/upload.component';
import { SubscriberSummaryComponent } from './../modules/subscriber/components/summary/subscriber.summary.component';
import { VerificationAdminComponent } from './../modules/adminPerspay/components/verification/verification.admin.component';
import { SubscriberManagementEmploymentComponent } from './../modules/adminPerspay/components/subscriberManagement/components/employment/subscriberManagement.employment.component';
import { SubscriberManagementNotesComponent } from './../modules/adminPerspay/components/subscriberManagement/components/notes/subscriberManagement.notes.component';
import { ConfirmationComponent } from './../modules/subscriber/modules/confirmation/confirmation.component';
import { SubscriberManagementAddComponent } from 'src/app/modules/adminPerspay/components/subscriberManagement/components/add/subscriberManagement.add.component';
import { SubscriberManagementComponent } from 'src/app/modules/adminPerspay/components/subscriberManagement/subscriberManagement.component';
import { OrganizationManagementComponent } from 'src/app/modules/adminHCA/components/organizationManagement/organizationManagement.component';
import { OrganizationProfileComponent } from './../modules/adminPerspay/components/organizationProfile/organizationProfile.component';
import { AccessManagementComponent } from './../modules/adminPerspay/components/accessManagement/accessManagement.component';
import { ReportsComponent } from './../modules/reports/reports.component';
import { PerspayDashboardComponent } from './../modules/dashboard/components/perspay/perspay.dashboard.component';
import { AttestTobaccoComponent } from './../modules/subscriber/modules/attestations/components/tobacco/tobacco.component';
import { SubscriberDashboardComponent } from './../modules/subscriber/subscriber.component';
import { SupplementalComponent } from './../modules/subscriber/modules/supplemental/supplemental.component';
import { CoverageComponent } from './../modules/subscriber/modules/coverage/coverage.component';
import { DependentsComponent } from './../modules/subscriber/modules/dependents/dependents.component';
import { DataDepotComponent } from 'src/app/modules/adminPerspay/components/dataDepot/dataDepot.component';
import { BillingFileComponent } from 'src/app/modules/adminPerspay/components/billingFile/billingFile.component';
import { EligibilityFileComponent } from 'src/app/modules/adminPerspay/components/eligibilityFile/eligibilityFile.component';
import { DependentManagementComponent } from '../modules/subscriber/modules/dependentManagement/dependentManagement.component';
import { AttestTobaccoOutsideComponent } from '../modules/subscriber/modules/attestations/components/tobacco/outsideEnrollmentPeriod/tobacco.outsideEP.component';
import { SOEComponent } from '../modules/subscriber/modules/soe/soe.component';
import { SoeReviewAdminComponent } from '../modules/adminPerspay/components/soeReview/soeReview.admin.component';
import { SelfPayComponent } from '../modules/subscriber/modules/selfpay/selfpay.component';
import { SubscriberManagementSpousalComponent } from '../modules/adminPerspay/components/subscriberManagement/components/spousal/subscriberManagement.spousal.component';
import { SubscriberHistoryComponent } from '../modules/adminHCA/components/subscriberHistory/subscriberHistory.component';
// guards
import { MasterGuard } from './../guards/master/master.guard';
// resolvers
import { UserOrganizationResolver } from './../resolvers/userOrg.resolver';
import { OrganizationResolver } from './../resolvers/organization.resolver';
import { UserResolver } from './../resolvers/user.resolver';
import { LookupsResolver } from './../resolvers/lookups.resolver';
import { EnrollmentPeriodResolver } from './../resolvers/enrollmentPeriod.resolver';
import { SubscriberResolver } from './../resolvers/subscriber.resolver';
import { TOUResolver } from '../resolvers/tou.resolver';

const routes: Routes = [
  {
    path: 'admin/organizationManagement/:id',
    component: OrganizationManagementComponent,
    data: {
      guards: ['auth']
    },
    resolve: {
      user: UserResolver,
      organizations: OrganizationResolver,
    },
    canActivate: [MasterGuard],
    children: [
      {
        path: '',
        data: {
          guards: ['auth'],
        },
        canActivateChild: [MasterGuard],
        children: [
          {
            path: 'add',
            component: OrganizationProfileComponent,
            data: {
              add: true,
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/subscriberHistory/:organizationid',
            component: SubscriberHistoryComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/subscriberManagement/:organizationid',
            component: SubscriberManagementComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            runGuardsAndResolvers: 'always',
            canActivate: [MasterGuard],
            children: [
              {
                path: 'add',
                component: SubscriberManagementAddComponent,
                data: {
                },
                resolve: {
                  user: UserResolver,
                },
                runGuardsAndResolvers: 'always',
              },
              {
                path: 'profile/:id',
                component: ProfileComponent,
                data: {
                  guards: ['auth'],
                },
                resolve: {
                  subscriber: SubscriberResolver,
                  termsOfUse: TOUResolver,
                },
                canActivate: [MasterGuard],
              },
              {
                path: 'spousal/:id',
                component: SubscriberManagementSpousalComponent,
                data: {
                },
                resolve: {
                  subscriber: SubscriberResolver,
                },
                runGuardsAndResolvers: 'always',
              },
              {
                path: 'attest/tobacco/:id',
                component: AttestTobaccoOutsideComponent,
                data: {
                  guards: ['auth'],
                },
                resolve: {
                  subscriber: SubscriberResolver,
                },
                canActivate: [MasterGuard],
              },
              {
                path: 'dependents/:id',
                component: DependentManagementComponent,
                data: {
                  guards: ['auth'],
                },
                resolve: {
                  subscriber: SubscriberResolver,
                },
                canActivate: [MasterGuard],
              },
              {
                path: 'soe/:id',
                component: SOEComponent,
                data: {
                  guards: ['auth'],
                },
                resolve: {
                  subscriber: SubscriberResolver,
                },
                runGuardsAndResolvers: 'always',
                canActivate: [MasterGuard],
                children: [
                    {
                      path: 'dependents/:epid',
                      component: DependentsComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'upload/:epid',
                      component: UploadComponent,
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      runGuardsAndResolvers: 'always'
                    },
                    {
                      path: 'coverage/:epid',
                      component: CoverageComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'attest/:epid',
                      component: AttestTobaccoComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'supplemental/:epid',
                      component: SupplementalComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'confirmation/:epid',
                      component: ConfirmationComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                        termsOfUse: TOUResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                  ],
                },
                {
                  path: 'supplemental/:id',
                  component: SupplementalComponent,
                  data: {
                    guards: ['auth'],
                  },
                  resolve: {
                    subscriber: SubscriberResolver,
                  },
                  canActivate: [MasterGuard],
                },
                {
                  path: 'summary/:id',
                  component: SubscriberSummaryComponent,
                  data: {
                    guards: ['auth'],
                  },
                  resolve: {
                    subscriber: SubscriberResolver,
                    termsOfUse: TOUResolver,
                  },
                  canActivate: [MasterGuard],
                },
                {
                  path: 'selfPay/:id',
                  component: SelfPayComponent,
                  data: {
                    guards: ['auth'],
                  },
                  resolve: {
                    subscriber: SubscriberResolver,
                  },
                },
                {
                  path: 'selfPay/:id/:soe',
                  component: SelfPayComponent,
                  data: {
                    guards: ['auth'],
                  },
                  resolve: {
                    subscriber: SubscriberResolver,
                  },
                },
                {
                  path: 'eligibility/:id',
                  component: SubscriberManagementEmploymentComponent,
                  resolve: {
                    subscriber: SubscriberResolver,
                  },
                  runGuardsAndResolvers: 'always',
                },
                {
                  path: 'notes/:id',
                  component: SubscriberManagementNotesComponent,
                  resolve: {
                    subscriber: SubscriberResolver,
                  },
                  runGuardsAndResolvers: 'always',
                },
                {
                  path: 'dependents/:id',
                  component: DependentManagementComponent,
                  data: {
                    guards: ['auth'],
                  },
                  resolve: {
                    subscriber: SubscriberResolver,
                  },
                },
                {
                  path: 'subscriber/:id',
                  component: SubscriberDashboardComponent,
                  runGuardsAndResolvers: 'always',
                  resolve: {
                    subscriber: SubscriberResolver,
                  },
                  data: {
                    guards: ['auth'],
                  },
                  canActivate: [MasterGuard],
                  children: [
                    {
                      path: 'dependents/:epid',
                      component: DependentsComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'upload/:epid',
                      component: UploadComponent,
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      runGuardsAndResolvers: 'always',
                    },
                    {
                      path: 'coverage/:epid',
                      component: CoverageComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'attest/:epid',
                      component: AttestTobaccoComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'supplemental/:epid',
                      component: SupplementalComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                    {
                      path: 'confirmation/:epid',
                      component: ConfirmationComponent,
                      data: {
                        guards: ['auth'],
                      },
                      resolve: {
                        subscriber: SubscriberResolver,
                        enrollmentPeriod: EnrollmentPeriodResolver,
                      },
                      canActivate: [MasterGuard],
                    },
                  ],
                },
              ],
            },
          {
            path: 'dashboard/perspay/:organizationid',
            component: PerspayDashboardComponent,
            resolve: {
              user: UserOrganizationResolver,
            },
            data: {
              guards: ['auth'],
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/reports/:organizationid',
            component: ReportsComponent,
            canActivate: [MasterGuard],
            data: {
              guards: ['auth'],
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/access/perspay/:organizationid',
            component: AccessManagementComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/organizationProfile/:organizationid',
            component: OrganizationProfileComponent,
            data: {
              guards: ['auth'],
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/relationshipVerification/perspay/:organizationid',
            component: VerificationAdminComponent,
            data: {
              guards: ['auth'],
              userType: 'perspay',
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/dataDepot/perspay/:organizationid',
            component: DataDepotComponent,
            data: {
              guards: ['auth'],
              userType: 'perspay',
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/billingFile/perspay/:organizationid',
            component: BillingFileComponent,
            data: {
              guards: ['auth'],
              userType: 'perspay',
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },          
          {
            path: 'admin/eligibilityFile/perspay/:organizationid',
            component: EligibilityFileComponent,
            data: {
              guards: ['auth'],
              userType: 'perspay',
            },
            resolve: {
              user: UserOrganizationResolver
            },
            canActivate: [MasterGuard],
            runGuardsAndResolvers: 'always',
          },
          {
            path: 'admin/specialOpenEnrollment/review/perspay/:organizationid',
            component: SoeReviewAdminComponent,
            data: {
              guards: ['auth'],
              userType: 'perspay',
            },
            resolve: {
              user: UserOrganizationResolver,
            },
            canActivate: [MasterGuard],
            children: [
              {
                path: ':id',
                children: [
                  {
                    path: 'dependents/:epid',
                    component: DependentsComponent,
                    data: {
                      guards: ['auth'],
                    },
                    resolve: {
                      subscriber: SubscriberResolver,
                      enrollmentPeriod: EnrollmentPeriodResolver,
                    },
                    canActivate: [MasterGuard],
                  },
                  {
                    path: 'upload/:epid',
                    component: UploadComponent,
                    resolve: {
                      subscriber: SubscriberResolver,
                      enrollmentPeriod: EnrollmentPeriodResolver,
                    },
                  },
                  {
                    path: 'coverage/:epid',
                    component: CoverageComponent,
                    data: {
                      guards: ['auth'],
                    },
                    resolve: {
                      subscriber: SubscriberResolver,
                      enrollmentPeriod: EnrollmentPeriodResolver,
                    },
                    canActivate: [MasterGuard],
                  },
                  {
                    path: 'attest/:epid',
                    component: AttestTobaccoComponent,
                    data: {
                      guards: ['auth'],
                    },
                    resolve: {
                      subscriber: SubscriberResolver,
                      enrollmentPeriod: EnrollmentPeriodResolver,
                    },
                    canActivate: [MasterGuard],
                  },
                  {
                    path: 'supplemental/:epid',
                    component: SupplementalComponent,
                    data: {
                      guards: ['auth'],
                    },
                    resolve: {
                      subscriber: SubscriberResolver,
                      enrollmentPeriod: EnrollmentPeriodResolver,
                    },
                    canActivate: [MasterGuard],
                  },
                  {
                    path: 'confirmation/:epid',
                    component: ConfirmationComponent,
                    data: {
                      guards: ['auth'],
                    },
                    resolve: {
                      subscriber: SubscriberResolver,
                      enrollmentPeriod: EnrollmentPeriodResolver,
                      termsOfUse: TOUResolver,
                    },
                    canActivate: [MasterGuard],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const HcaOrgRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(LookupsResolver.addToAllRoutes(routes), { onSameUrlNavigation: 'reload' });
