<div fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
  <div class="state">
    <div class="card" *ngIf="systemUser">
      <div class="card-body page-container">
        <h1 *ngIf="currentOrganization && !inHCAAdminState">{{ currentOrganization.organizationName }}</h1>
        <h1 *ngIf="inHCAAdminState">HCA Admin</h1>
        <h2 class="mobile-text-centered mgbt-medium-sm mgtp-small">Verify special open enrollment requests</h2>
        <div fxLayout="row">
          <div class="form-group" fxFlex="100">
            <checkbox name="showPendingOnly" bold [(ngModel)]="onlyShowPendingRecords" (change)="showPendingChange()">Show only pending requests</checkbox>
          </div>
        </div>
        <div class="dashboard-grid-parent mgbt-medium mgtp-medium">
          <soereview-admin-grid
            [isHCAAdmin]="isHCAAdmin"
            #soeReviewGrid
            [gridData]="gridData"
            [state]="state"
            [gridColumns]="gridColumns"
            [filterable]="true"
            [lookups]="lookups"
            (pushGetData)="updateDataset($event)"
            (soeSelected)="soeSelected($event)"
          ></soereview-admin-grid>
        </div>
      </div>

      <div fxFill class="card" *ngIf="selectedSoe && selectedSoe.specialOpenEnrollmentVerificationStatus.specialOpenEnrollmentVerificationStatusCode!='C'">
        <div class="card-body" style="margin-left: 12px">
          <soereview-summary [soe]="selectedSoe"></soereview-summary>
          <soereview-form
            #review
            *ngIf="hasEditAccess"
            [soe]="selectedSoe"
            [subscriber]="subscriber"
            [isHCAAdmin]="isHCAAdmin"
            [hasEditAccess]="hasEditAccess"
            [verifyValues]="verifyValues"
            [denyValues]="denyValues"
            [allVerificationStatus]="lookups.specialOpenEnrollmentVerificationStatus"
            (resetData)="reSetDataForGrid()"
            (refreshSelectedSOE)="refreshSelectedSOE()"
            (pushSaveVerificationResponse)="updateSpecialOpenEnrollment(selectedSoe)"
            (saveAndContinueWizard)="updateSpecialOpenEnrollment(selectedSoe, true)"
            (waiveDE)="waivePlansDueToDualEnrollment(selectedSoe)"
            (downloadPDF)="downloadSOEPDF($event)"
            (within60Days)="within60Days = $event"
          ></soereview-form>
        </div>
      </div>
    </div>
  </div>
</div>
