import Reason  from 'src/app/models/reason';
import { lastValueFrom, Observable } from 'rxjs';
import { EnrollmentPeriodService } from 'src/app/services/enrollmentPeriod.service';
/**
 * The root component. All other states are injected based on routing in this components
 * router-outlet. Global componenets (agency select, header, footer) declared here.
 */

// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// ext
import { forEach, find, toLower, cloneDeep, map, some, remove, isEqual, includes, sortBy, get, filter } from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import { env } from 'src/env/development';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { CoreService } from 'src/app/services/core.service';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import Plan from 'src/app/models/plan';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import SubscriberCoverage from 'src/app/models/subscriberCoverage';
import Subscriber from 'src/app/models/subscriber';
import Enrollment from 'src/app/models/enrollment';
import Milestone from 'src/app/models/milestone';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';

import { faPlusCircle, faMinusCircle, faUserMd, faArrowAltCircleRight, faUmbrella } from '@fortawesome/free-solid-svg-icons';
import PlanType from 'src/app/models/planType';
import DependentCoverageElection from 'src/app/models/dependentCoverageElection';
import MemberSummary from 'src/app/models/memberSummary';
import Member from 'src/app/models/member';
import Action from 'src/app/models/action';
import ActionType from 'src/app/models/actionType';
import { ActionService } from 'src/app/services/action.service';
import SelfPay from 'src/app/models/selfPay';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import SelfPayFormDependent from 'src/app/models/selfPayFormDependent';

@UntilDestroy()
@Lookups(LookupType.PlanType, LookupType.ActionType, LookupType.Reason)
@Component({
  selector: 'coverage',
  templateUrl: 'coverage.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class CoverageComponent implements OnInit {
  icons = {
    faPlusCircle,
    faMinusCircle,
    faUserMd,
    faArrowAltCircleRight,
    faUmbrella
  };
  step = 0;
  changesHaveBeenSaved = false;
  subscriber: Subscriber;
  currentPlans: Plan[] = [];
  selectedPlans: { [k: string]: Plan } = { medical: null, dental: null };
  initialSelectedPlans: { [k: string]: Plan } = { medical: null, dental: null };
  availablePlans: { [k: string]: Plan[] } = {};
  currentPlanType = 'Medical';
  enrollmentPeriod: EnrollmentPeriod;
  hasCompletedThisStep = false;
  coverageMilestone: Milestone;
  medicalAvailablePlans: Plan[] = [];
  medicalSelectedPlan: Plan;
  dentalAvailablePlans: Plan[] = [];
  dentalSelectedPlan: Plan;
  visionAvailablePlans: Plan[] = [];
  visionSelectedPlan: Plan;
  initialSubscriberSummary: SubscriberEffectiveSummary;
  currentSubscriberSummary: SubscriberEffectiveSummary;
  originalPlans: { [k: string]: Plan } = { medical: null, dental: null };
  planTypes: PlanType[];
  confirmButtonText = `Confirm and let's complete tobacco attestation`;
  waivedPlanTypes: PlanType[] = [];
  medicalPlanType: PlanType;
  dentalPlanType: PlanType;
  visionPlanType: PlanType;
  hasDental = false;
  hasMedical = false;
  hasVision = false;
  hasDependentsEnrolledInMedical = false;
  hasDependentsEnrolledInDental = false;
  steps: string[] = [];
  conditionalNextIdx = 0;
  subscriberIsNewlyFull = false;
  neFullCode = env.newFullBenefitsEnrollmentPeriodType;
  isSOE = false;
  isSP = false;
  isLWOP = false;
  hasDisabledChild = false;
  actionTypes: ActionType[];
  hasWaivedMedical = false;
  selfPay?: SelfPay;
  subscriberIsMedicareABEnrolled = false;
  isCobraOrUnpaid = false;
  selfPayWaivingMedicalCoverage = false;
  selfPayWaivingDentalCoverage = false;
  selfPayWaivingVisionCoverage = false;
  isDeferRetiree: boolean = false;
  rtlPlanType: PlanType;
  rtlPlan: Plan;
  basicLifePlanType: PlanType;
  basicLifePlan: Plan;
  routeSubscription: any;
  excludeRetireePlans = true;
  isSOEAndAllowsCMP = false;
  isSOEAndAllowsCDP = false;
  isSOEAndAllowsCVP = false;
  isSOEandAllowsWaive = false;
  isSOEandAllowsReturn = false;
  soeTypeName: string = "";
  spInvalidMedicalPlan = '';
  spInvalidDentalPlan = '';
  spInvalidVisionPlan = '';
  selfPayFormDependentsMedicalEnrolled = false;
  selfPayFormDependentsDentalEnrolled = false;
  currentSOE: SpecialOpenEnrollment;
  nextMilestone: Milestone;
  removeMedicalForDE: boolean = false;
  removeDentalForDE: boolean = false;
  removeVisionForDE: boolean = false;
  reasons: Reason[];
  deReasonId: string;
  oeReasonId: string;
  neReasonId: string;
  deHCACorrectionReasonId: string;
  deferDueToMedicaidReasonId: string;
  isSEBB = false;

  constructor(
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private route: ActivatedRoute,
    private router: Router,
    private enrollmentService: EnrollmentService,
    private enrollmentPeriodService: EnrollmentPeriodService,
    private actionService: ActionService
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.reasons = data.lookups.reason;
      this.oeReasonId = get(find(this.reasons, (r:Reason) => r.reasonCode == '00'), 'reasonId');
      this.deReasonId = get(find(this.reasons, (r: Reason) => r.reasonCode == '54'), 'reasonId');
      this.neReasonId = get(find(this.reasons, (r: Reason) => r.reasonCode == '01'), 'reasonId');   
      this.deferDueToMedicaidReasonId = get(find(this.reasons, (r: Reason) => r.reasonCode == '48'), 'reasonId');   
      this.deHCACorrectionReasonId = get(find(this.reasons, (r: Reason) => r.reasonCode == '40'), 'reasonId');  
      this.planTypes = data.lookups.planType;
      this.actionTypes = data.lookups.actionType;
      this.subscriber = data.subscriber;
      this.enrollmentPeriod = data.enrollmentPeriod;
      this.subscriberIsMedicareABEnrolled = this.subscriber?.memberMedicare?.medicarePartAEnrolledInd && this.subscriber?.memberMedicare?.medicarePartBEnrolledInd;
      if (this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SOE') {
        this.isSOE = true;
        this.currentSOE = find(this.subscriber.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => soe.specialOpenEnrollmentId === this.enrollmentPeriod.enrollmentPeriodId);
        this.isSOEAndAllowsCMP = this.currentSOE.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionElectMedicalCode);
        this.isSOEAndAllowsCDP = this.currentSOE.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionElectDentalCode);
        this.isSOEAndAllowsCVP = this.currentSOE.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionElectVisionCode);
        this.isSOEandAllowsWaive = this.currentSOE.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionWaiveCode);
        this.isSOEandAllowsReturn = this.currentSOE.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionReturnFromWaivedCode);
        this.soeTypeName = this.currentSOE.specialOpenEnrollmentType.specialOpenEnrollmentTypeName;
      } else if (this.enrollmentPeriod?.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE') {
        this.isSP = true;
      }
      this.subscriberIsNewlyFull = this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === this.neFullCode;
      this.initialSubscriberSummary = new SubscriberEffectiveSummary(this.subscriber, this.enrollmentPeriod, false, this.isSOE, this.currentSOE?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName);
      this.currentSubscriberSummary = cloneDeep(this.initialSubscriberSummary);
      this.coverageMilestone = find(this.enrollmentPeriod.milestones, (mi: Milestone) => mi.milestoneName === 'Coverage');
      this.dentalPlanType = find(this.planTypes, (pt) => pt.planTypeName === 'Dental');
      this.medicalPlanType = find(this.planTypes, (pt) => pt.planTypeName === 'Medical');
      this.visionPlanType = find(this.planTypes, (pt) => pt.planTypeName === 'Vision');
      this.rtlPlanType = find(this.planTypes, (pt) => pt.planTypeCode === '10');
      this.basicLifePlanType = find(this.planTypes, (pt) => pt.planTypeCode === '3');
      this.hasMedical = some(this.enrollmentPeriod.electablePlanTypes, (ept) => ept.planTypeId === this.medicalPlanType.planTypeId);
      this.hasDental = some(this.enrollmentPeriod.electablePlanTypes, (ept) => ept.planTypeId === this.dentalPlanType.planTypeId);
      this.hasVision = some(this.enrollmentPeriod.electablePlanTypes, (ept) => ept.planTypeId === this.visionPlanType.planTypeId);
      this.setupSteps();
      this.hasDisabledChild = some(this.subscriber?.members, (m: Member) => m.relationshipToSubscriber?.relationshipQualifyReason?.relationshipQualifyReasonCode === 'A');
      const orderedMilestones = sortBy(this.enrollmentPeriod.milestones, 'sortOrder');
      this.nextMilestone = orderedMilestones[orderedMilestones.findIndex((o)=> o.milestoneId === this.coverageMilestone.milestoneId)+1];
      this.isSEBB = this.subscriber?.agency === 'SEBB' ? true : false;
      this.spinnerService.hide();
      this.spinnerService.show();
      if (!this.isSOE) {
        this.enrollmentService.getEnrollmentsbyEnrollmentPeriodId(this.subscriber.memberId, this.enrollmentPeriod.enrollmentPeriodId)
          .pipe(untilDestroyed(this))
          .subscribe((subscriberCoverage: SubscriberCoverage) => {
          this.hasDependentsEnrolledInMedical = some(subscriberCoverage.dependentCoverageElections, (dce: DependentCoverageElection) =>
            some(dce.planTypeIds, (pt: string) => pt === this.medicalPlanType.planTypeId)
          );
          this.hasDependentsEnrolledInDental = some(subscriberCoverage.dependentCoverageElections, (dce: DependentCoverageElection) =>
            some(dce.planTypeIds, (pt: string) => pt === this.dentalPlanType.planTypeId)
          );

          if (this.isSP) {
            if (
              some(subscriberCoverage.electedPlans, (p: Plan) => p.planType.planTypeName === env.medicalPlanTypeName && p.planCode !== env.defaultMedicalPlanCode) &&
              !some(subscriberCoverage.enrollments, (en: Enrollment) => en.plan.planType.planTypeName === env.medicalPlanTypeName)
            ) {
              // subscriber is waiving medical coverage but has dependents enrolled in medical
              this.selfPayWaivingMedicalCoverage = true;
              remove(subscriberCoverage.electedPlans, o => o.planCode === env.defaultDentalPlanCode); // remove the default medical plan otherwise it could be selected as the default
              this.currentSubscriberSummary.effectiveMedicalPlan = env.notEnrolledText;
            }

            if (
              some(subscriberCoverage.electedPlans, (p: Plan) => p.planType.planTypeName === env.dentalPlanTypeName && p.planCode !== env.defaultDentalPlanCode) &&
              !some(subscriberCoverage.enrollments, (en: Enrollment) => en.plan.planType.planTypeName === env.dentalPlanTypeName)
            ) {
              // subscriber is waiving dental coverage but has dependents enrolled in dental
              this.selfPayWaivingDentalCoverage = true;
              remove(subscriberCoverage.electedPlans, o => o.planCode === env.defaultDentalPlanCode); // remove the default dental plan otherwise it could be selected as the default
              this.currentSubscriberSummary.effectiveDentalPlan = env.notEnrolledText;
            }

            if (
              some(subscriberCoverage.electedPlans, (p: Plan) => p.planType.planTypeName === env.visionPlanTypeName && p.planCode !== env.defaultVisionPlanCode) &&
              !some(subscriberCoverage.enrollments, (en: Enrollment) => en.plan.planType.planTypeName === env.visionPlanTypeName)
            ) {
              // subscriber is waiving vision coverage but has dependents enrolled in vision
              this.selfPayWaivingVisionCoverage = true;
              remove(subscriberCoverage.electedPlans, o => o.planCode === env.defaultVisionPlanCode); // remove the default vision plan otherwise it could be selected as the default
              this.currentSubscriberSummary.effectiveVisionPlan = env.notEnrolledText;
            }

          }

          this.currentPlans = cloneDeep(subscriberCoverage.electedPlans);
          forEach(this.currentPlans, (p: Plan) => {
            this.selectedPlans[toLower(p.planType.planTypeName)] = cloneDeep(p);
          });
          this.initialSelectedPlans = cloneDeep(this.selectedPlans);
          this.originalPlans = cloneDeep(this.selectedPlans);
          this.medicalSelectedPlan = cloneDeep(this.selectedPlans.medical);
          this.dentalSelectedPlan = cloneDeep(this.selectedPlans.dental);
          this.visionSelectedPlan = cloneDeep(this.selectedPlans.vision);
          this.hasCompletedThisStep = !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()] || !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()].planName.startsWith('Default');

          this.currentSubscriberSummary.memberSummary.forEach((m: MemberSummary) => {
            let planTypeIds = [];
            if (m.memberId === this.subscriber.memberId) {
              planTypeIds = subscriberCoverage.planTypeIds.filter((pt) => pt === this.medicalPlanType.planTypeId || pt === this.dentalPlanType.planTypeId);
            } else {
              const depCoverage: DependentCoverageElection = find(subscriberCoverage.dependentCoverageElections, (dce: DependentCoverageElection) => dce.memberId === m.memberId);
              if (depCoverage) {
                planTypeIds = depCoverage.planTypeIds.filter((pt) => pt === this.medicalPlanType.planTypeId || pt === this.dentalPlanType.planTypeId);
              }
            }

            planTypeIds.forEach((pt) => {
              const plan = pt === this.medicalPlanType.planTypeId ? this.medicalPlanType : this.dentalPlanType;
              const selectedPlan = pt === this.medicalPlanType.planTypeId ? this.medicalSelectedPlan : this.dentalSelectedPlan;

              if (!m.effectiveEnrollmentsByPlanType[plan.planTypeCode] && selectedPlan) {
                const enrollment = new Enrollment();
                enrollment.memberId = m.memberId;
                enrollment.subscriberMemberId = this.subscriber.memberId;
                enrollment.plan = selectedPlan;
                enrollment.planId = selectedPlan.planId;
                enrollment.planTypeId = selectedPlan.planTypeId;

                m.effectiveEnrollmentsByPlanType[plan.planTypeCode] = enrollment;
              }
            });
          });
          this.getPlans(false);
        });
      } else {
        this.enrollmentService.getEnrollmentsbyEnrollmentPeriodIdForSOE(this.subscriber.memberId, this.enrollmentPeriod.enrollmentPeriodId, this.currentSOE.specialOpenEnrollmentId)
          .pipe(untilDestroyed(this))
          .subscribe((subscriberCoverage: SubscriberCoverage) => {
            if (this.soeTypeName === 'HCA OE Correction') {
              if (subscriberCoverage.removeForDEPlanTypes.find(o=>o==this.medicalPlanType.planTypeId)) {
                this.removeMedicalForDE = true;
              }
              if (subscriberCoverage.removeForDEPlanTypes.find(o=>o==this.dentalPlanType.planTypeId)) {
                this.removeDentalForDE = true;
              }
              if (subscriberCoverage.removeForDEPlanTypes.find(o=>o==this.visionPlanType.planTypeId)) {
                this.removeVisionForDE = true;
              }
            }

            this.hasDependentsEnrolledInMedical = some(subscriberCoverage.dependentCoverageElections, (dce: DependentCoverageElection) =>
              some(dce.planTypeIds, (pt: string) => pt === this.medicalPlanType.planTypeId)
            );
            this.hasDependentsEnrolledInDental = some(subscriberCoverage.dependentCoverageElections, (dce: DependentCoverageElection) =>
              some(dce.planTypeIds, (pt: string) => pt === this.dentalPlanType.planTypeId)
            );

            if (this.isSP) {
              if (
                some(subscriberCoverage.electedPlans, (p: Plan) => p.planType.planTypeName === env.medicalPlanTypeName && p.planCode !== env.defaultMedicalPlanCode) &&
                !some(subscriberCoverage.enrollments, (en: Enrollment) => en.plan.planType.planTypeName === env.medicalPlanTypeName)
              ) {
                // subscriber is waiving medical coverage but has dependents enrolled in medical
                this.selfPayWaivingMedicalCoverage = true;
                remove(subscriberCoverage.electedPlans, (o) => o.planCode === env.defaultDentalPlanCode); // remove the default medical plan otherwise it could be selected as the default
                this.currentSubscriberSummary.effectiveMedicalPlan = env.notEnrolledText;
              }

              if (
                some(subscriberCoverage.electedPlans, (p: Plan) => p.planType.planTypeName === env.dentalPlanTypeName && p.planCode !== env.defaultDentalPlanCode) &&
                !some(subscriberCoverage.enrollments, (en: Enrollment) => en.plan.planType.planTypeName === env.dentalPlanTypeName)
              ) {
                // subscriber is waiving dental coverage but has dependents enrolled in dental
                this.selfPayWaivingDentalCoverage = true;
                remove(subscriberCoverage.electedPlans, (o) => o.planCode === env.defaultDentalPlanCode); // remove the default dental plan otherwise it could be selected as the default
                this.currentSubscriberSummary.effectiveDentalPlan = env.notEnrolledText;
              }

              if (
                some(subscriberCoverage.electedPlans, (p: Plan) => p.planType.planTypeName === env.visionPlanTypeName && p.planCode !== env.defaultVisionPlanCode) &&
                !some(subscriberCoverage.enrollments, (en: Enrollment) => en.plan.planType.planTypeName === env.visionPlanTypeName)
              ) {
                // subscriber is waiving vision coverage but has dependents enrolled in vision
                this.selfPayWaivingVisionCoverage = true;
                remove(subscriberCoverage.electedPlans, (o) => o.planCode === env.defaultVisionPlanCode); // remove the default vision plan otherwise it could be selected as the default
                this.currentSubscriberSummary.effectiveVisionPlan = env.notEnrolledText;
              }
            }

            this.currentPlans = cloneDeep(subscriberCoverage.electedPlans);
            forEach(this.currentPlans, (p: Plan) => {
              this.selectedPlans[toLower(p.planType.planTypeName)] = cloneDeep(p);
            });
            this.initialSelectedPlans = cloneDeep(this.selectedPlans);
            this.originalPlans = cloneDeep(this.selectedPlans);
            this.medicalSelectedPlan = cloneDeep(this.selectedPlans.medical);
            this.dentalSelectedPlan = cloneDeep(this.selectedPlans.dental);
            this.visionSelectedPlan = cloneDeep(this.selectedPlans.vision);
            this.hasCompletedThisStep = !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()] || !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()].planName.startsWith('Default');

            this.currentSubscriberSummary.memberSummary.forEach((m: MemberSummary) => {
              let planTypeIds = [];
              if (m.memberId === this.subscriber.memberId) {
                planTypeIds = subscriberCoverage.planTypeIds.filter((pt) => pt === this.medicalPlanType.planTypeId || pt === this.dentalPlanType.planTypeId);
              } else {
                const depCoverage: DependentCoverageElection = find(subscriberCoverage.dependentCoverageElections, (dce: DependentCoverageElection) => dce.memberId === m.memberId);
                if (depCoverage) {
                  planTypeIds = depCoverage.planTypeIds.filter((pt) => pt === this.medicalPlanType.planTypeId || pt === this.dentalPlanType.planTypeId);
                }
              }

              planTypeIds.forEach((pt) => {
                const plan = pt === this.medicalPlanType.planTypeId ? this.medicalPlanType : this.dentalPlanType;
                const selectedPlan = pt === this.medicalPlanType.planTypeId ? this.medicalSelectedPlan : this.dentalSelectedPlan;

                if (!m.effectiveEnrollmentsByPlanType[plan.planTypeCode] && selectedPlan) {
                  const enrollment = new Enrollment();
                  enrollment.memberId = m.memberId;
                  enrollment.subscriberMemberId = this.subscriber.memberId;
                  enrollment.plan = selectedPlan;
                  enrollment.planId = selectedPlan.planId;
                  enrollment.planTypeId = selectedPlan.planTypeId;

                  m.effectiveEnrollmentsByPlanType[plan.planTypeCode] = enrollment;
                }
              });
            });
            this.getPlans(false);
          });
      }
    });
  }

  getPlans(showAll): void {
    this.spinnerService.hide();
    this.spinnerService.show();
    this.availablePlans = {};
    if (this.isSP) {
      const lwopCodes = env.unpaidLeaveMemberTypes;
      this.isLWOP = lwopCodes.indexOf(this.subscriber.memberType.memberTypeCode) > -1;
      const cobraCodes = env.cobraMemberTypes;
      const isCobra = cobraCodes.indexOf(this.subscriber.memberType.memberTypeCode) > -1;

      this.excludeRetireePlans = true;
      if (env.retireeMemberTypes.find((mt) => mt === this.subscriber.memberSelfPayOrigin.memberType.memberTypeCode) ||
        env.cobraMemberTypes.find((mt) => mt === this.subscriber.memberSelfPayOrigin.memberType.memberTypeCode)) {
          //"retiree plans" in this case are medicare plans. COBRA and retiree are both allowed to select Medicare plans
          this.excludeRetireePlans = false;
      }

      this.isCobraOrUnpaid = this.isLWOP || isCobra;
      const agency = this.subscriber.memberSelfPayOrigin?.organization?.agency?.agencyCode || this.subscriber.organization?.agency?.agencyCode || 'PEBB';

      this.enrollmentService
        .getMemberSelfPayAvailablePlans(this.excludeRetireePlans, this.subscriber.memberId, this.enrollmentPeriod.coverageEffectiveStartDate, showAll, agency)
        .pipe(untilDestroyed(this)).subscribe((returnedPlans: Plan[]) => {
          const planCodesToExclude = ['Z', '9'];
          if (this.isLWOP) {
            planCodesToExclude.push('MD');
            planCodesToExclude.push('MB');
            planCodesToExclude.push('MC');
          } 

          const filteredPlans = returnedPlans.filter((p: Plan) => !planCodesToExclude.includes(p.planCode));
          forEach(filteredPlans, (ap: Plan) => {
            const familyComposition = this.subscriber.getFamilyCompositionForDate(this.enrollmentPeriod.coverageEffectiveStartDate, ap.planTypeId);
            ap.rateAmt = ap.rateForSpecificDate(this.enrollmentPeriod.coverageEffectiveStartDate, familyComposition);
            if (!this.availablePlans.hasOwnProperty(toLower(ap.planType.planTypeName))) {
              this.availablePlans[toLower(ap.planType.planTypeName)] = [];
            }
            this.availablePlans[toLower(ap.planType.planTypeName)].push(ap);
            this.medicalAvailablePlans = sortBy(this.availablePlans.medical, 'planName');
            this.dentalAvailablePlans = sortBy(this.availablePlans.dental, 'planName');
            this.visionAvailablePlans = sortBy(this.availablePlans.vision, 'planName');
          });

          this.medicalAvailablePlans = sortBy(this.availablePlans.medical, 'planName');
          this.dentalAvailablePlans = sortBy(this.availablePlans.dental, 'planName');
          this.visionAvailablePlans = sortBy(this.availablePlans.vision, 'planName');
          this.rtlPlan = find(returnedPlans, (ap: Plan) => ap.planType?.planTypeCode === '10');
          this.basicLifePlan = find(returnedPlans, (ap: Plan) => ap.planType?.planTypeCode === '3');
          if (env.unpaidLeaveMemberTypes.includes(this.subscriber.memberType?.memberTypeCode)) {
            this.medicalAvailablePlans = filter(this.medicalAvailablePlans, (p: Plan) => !p.planName.toLowerCase().includes('medicare'));
          }

          if (this.coverageMilestone.completedDate === null) {
            // 1st time through SP wizard, set plans based on what was submitted
            const selectedSP = this.subscriber.selfPays.find(o => o.selfPayId === this.enrollmentPeriod.enrollmentPeriodId);
            this.medicalSelectedPlan = cloneDeep(this.medicalAvailablePlans.find(o => o.planId === selectedSP.formJson.medicalPlan.planId));
            this.dentalSelectedPlan = cloneDeep(this.dentalAvailablePlans.find(o => o.planId === selectedSP.formJson.dentalPlan.planId));
            this.visionSelectedPlan = cloneDeep(this.visionAvailablePlans.find(o => o.planId === selectedSP.formJson.visionPlan.planId));
            const subscriberMemberSummary = find(this.currentSubscriberSummary.memberSummary, (m: MemberSummary) => m.subscriberInd);

            if (this.medicalSelectedPlan) {
              this.selectedPlans[`medical`] = this.medicalSelectedPlan;
              subscriberMemberSummary.effectiveEnrollmentsByPlanType['1'] = this.selectedPlans[`medical`] = this.medicalSelectedPlan
              ? cloneDeep(this.medicalSelectedPlan)
              : null;
            }
            if (this.dentalSelectedPlan) {
              this.selectedPlans[`dental`] = this.dentalSelectedPlan;
              subscriberMemberSummary.effectiveEnrollmentsByPlanType['2'] = this.selectedPlans[`dental`] = this.dentalSelectedPlan
              ? cloneDeep(this.dentalSelectedPlan)
              : null;
            }
            if (this.visionSelectedPlan) {
              this.selectedPlans[`vision`] = this.visionSelectedPlan;
              subscriberMemberSummary.effectiveEnrollmentsByPlanType['6'] = this.selectedPlans[`vision`] = this.visionSelectedPlan
              ? cloneDeep(this.visionSelectedPlan)
              : null;
            }

            if (!this.medicalSelectedPlan) {
              if (selectedSP.formJson.medicalPlan && selectedSP.formJson.medicalPlan.planId) {
                this.spInvalidMedicalPlan = selectedSP.formJson.medicalPlan.planName;
              }
            } else {
              this.selectedPlans.medical = cloneDeep(this.medicalSelectedPlan);
            }

            if (!this.dentalSelectedPlan) {
              if (selectedSP.formJson.dentalPlan && selectedSP.formJson.dentalPlan.planId) {
                this.spInvalidDentalPlan = selectedSP.formJson.dentalPlan.planName;
              }
            } else {
              this.selectedPlans.dental = cloneDeep(this.dentalSelectedPlan);
            }

            if (!this.visionSelectedPlan) {
              if (selectedSP.formJson.visionPlan && selectedSP.formJson.visionPlan.planId) {
                this.spInvalidVisionPlan = selectedSP.formJson.visionPlan.planName;
              }
            } else {
              this.selectedPlans.vision = cloneDeep(this.visionSelectedPlan);
            }

            this.selfPayFormDependentsMedicalEnrolled = some(selectedSP.formJson.dependents, (d: SelfPayFormDependent) => d.medicalCoverInd);
            this.selfPayFormDependentsDentalEnrolled = some(selectedSP.formJson.dependents, (d: SelfPayFormDependent) => d.dentalCoverInd);
            if (selectedSP.formJson.subscriber.lifeAndADDAcknowlegeInd) {
              this.currentSubscriberSummary.basicLifeEnrolledInd = true;
            }
          }

          this.addSelectedMedical();
          this.spinnerService.hide();
      });
    } else {
      this.enrollmentService.getAvailablePlansForMember(this.subscriber.memberId, this.enrollmentPeriod.enrollmentPeriodId, showAll).pipe(untilDestroyed(this)).subscribe((availablePlans) => {
        forEach(availablePlans, (ap: Plan) => {
          const familyComposition = this.subscriber.getFamilyCompositionForDate(this.enrollmentPeriod.coverageEffectiveStartDate, ap.planTypeId);
          ap.rateAmt = ap.rateForSpecificDate(this.enrollmentPeriod.coverageEffectiveStartDate, familyComposition);
          if (!this.availablePlans.hasOwnProperty(toLower(ap.planType.planTypeName))) {
            this.availablePlans[toLower(ap.planType.planTypeName)] = [];
          }
          this.availablePlans[toLower(ap.planType.planTypeName)].push(ap);
          this.medicalAvailablePlans = sortBy(this.availablePlans.medical, 'planName');
          this.dentalAvailablePlans = sortBy(this.availablePlans.dental, 'planName');
          this.visionAvailablePlans = sortBy(this.availablePlans.vision, 'planName');
        });
        this.addSelectedMedical();
        this.spinnerService.hide();
      });
    }
  }

  addSelectedMedical(): void {
    if (this.medicalSelectedPlan && this.medicalAvailablePlans.find((o) => o.planId === this.medicalSelectedPlan.planId) == null && !this.medicalSelectedPlan.planTypeDefaultPlanInd) {
      this.availablePlans.medical.push(this.medicalSelectedPlan);
      this.medicalAvailablePlans = sortBy(this.availablePlans.medical, 'planName');
    }
  }

  cobraOrUnpaidWaiving(waive: boolean): void {
    this.selfPayWaivingMedicalCoverage = waive;
  }

  planUpdated(planObject: Plan): void {
    this.selectedPlans[this.currentPlanType.toLocaleLowerCase()] = planObject ? cloneDeep(planObject) : null;
    const planTypeCode = find(this.planTypes, (pt: PlanType) => pt.planTypeName === this.currentPlanType)?.planTypeCode;
    this.medicalSelectedPlan = this.selectedPlans.medical ? cloneDeep(this.selectedPlans.medical) : null;
    this.dentalSelectedPlan = this.selectedPlans.dental ? cloneDeep(this.selectedPlans.dental) : null;
    this.visionSelectedPlan = this.selectedPlans.vision ? cloneDeep(this.selectedPlans.vision) : null;
    const subscriberMemberSummary = find(this.currentSubscriberSummary.memberSummary, (m: MemberSummary) => m.subscriberInd);
    subscriberMemberSummary.effectiveEnrollmentsByPlanType[planObject?.planType.planTypeCode ?? planTypeCode] = this.selectedPlans[this.currentPlanType.toLocaleLowerCase()] = planObject
      ? cloneDeep(planObject)
      : null;
    if (!this.medicalSelectedPlan && !includes(this.waivedPlanTypes, this.medicalPlanType)) {
      this.hasWaivedMedical = true;
      this.waivedPlanTypes.push(this.medicalPlanType);
    } else if (this.medicalSelectedPlan && includes(this.waivedPlanTypes, this.medicalPlanType)) {
      remove(this.waivedPlanTypes, (pt: PlanType) => pt.planTypeName === 'Medical');
      this.hasWaivedMedical = false;
    }
    if (!this.dentalSelectedPlan && !includes(this.waivedPlanTypes, this.dentalPlanType)) {
      this.waivedPlanTypes.push(this.dentalPlanType);
    } else if (this.dentalSelectedPlan && includes(this.waivedPlanTypes, this.dentalPlanType)) {
      remove(this.waivedPlanTypes, (pt: PlanType) => pt.planTypeName === 'Dental');
    }
    if (!this.visionSelectedPlan && !includes(this.waivedPlanTypes, this.visionPlanType)) {
      this.waivedPlanTypes.push(this.visionPlanType);
    } else if (this.visionSelectedPlan && includes(this.waivedPlanTypes, this.visionPlanType)) {
      remove(this.waivedPlanTypes, (pt: PlanType) => pt.planTypeName === 'Vision');
    }    
    this.hasCompletedThisStep = !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()] || !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()].planName.startsWith('Default');
  }

  planRemoved(planObject: Plan): void {
    if (!this.isSP) {
      this.hasCompletedThisStep = false;
    } else {
      this.selectedPlans[this.currentPlanType.toLocaleLowerCase()] = null;
      if (this.currentPlanType.toLocaleLowerCase() === 'medical') {
        this.medicalSelectedPlan = null;
      }
      if (this.currentPlanType.toLocaleLowerCase() === 'dental') {
        this.dentalSelectedPlan = null;
      }
    }
  }

  setupSteps(): void {
    if (this.hasMedical) {
      this.steps.push('Medical');
    }
    if (this.hasDental) {
      this.steps.push('Dental');
    }
    if (this.hasVision) {
      this.steps.push('Vision');
    }
    this.steps.push('Confirm');
    this.currentPlanType = this.steps[0];
  }

  updateStep(s: number): void {
    if (s < this.steps.length && s > -1) {
      this.currentPlanType = this.steps[s];
      if (this.currentPlanType !== 'Confirm') {
        this.hasCompletedThisStep = !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()] || !this.selectedPlans[this.currentPlanType.toLocaleLowerCase()].planName.startsWith('Default');
        this.conditionalNextIdx = s;
      }
    }

    const isSelfPay = this.enrollmentPeriod.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE';

    if (isSelfPay) {
      this.confirmButtonText = `Confirm and let's manage dependents`;
    } else if (this.hasMedical && this.medicalSelectedPlan) {
      this.confirmButtonText = `Confirm and let's complete tobacco attestation`;
    } else if (some(this.enrollmentPeriod.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === '5')) {
      this.confirmButtonText = `Confirm and let's view supplemental coverage options`;
    } else {
      this.confirmButtonText = `Confirm and let's review`;
    }
    if (this.nextMilestone?.milestoneName === 'Confirmation') {
      this.confirmButtonText = `Confirm and let's review`;
    }
  }

  async saveElections(): Promise<void> {
    const coverageElections = new SubscriberCoverage();
    coverageElections.enrollmentPeriodId = this.enrollmentPeriod.enrollmentPeriodId;
    coverageElections.subscriberMemberId = this.subscriber.subscriberMemberId;
    coverageElections.electedPlans = [];
    coverageElections.waivedPlanTypes = [];
    coverageElections.removeForDEPlanTypes = [];
    coverageElections.planTypeIds = [];
    coverageElections.isTransferEvent = this.subscriberIsNewlyFull;
    coverageElections.removeMedicalForDE = this.removeMedicalForDE;
    coverageElections.removeDentalForDE = this.removeDentalForDE;
    coverageElections.removeVisionForDE = this.removeVisionForDE;
    if (this.selectedPlans.medical) {
      coverageElections.electedPlans.push(this.selectedPlans.medical);
      coverageElections.planTypeIds.push(this.medicalPlanType.planTypeId);
    } else if (this.hasMedical) {
      coverageElections.waivedPlanTypes.push(this.medicalPlanType.planTypeId);
      if (this.removeMedicalForDE) {
        coverageElections.removeForDEPlanTypes.push(this.medicalPlanType.planTypeId);
      }
    }

    if (this.selectedPlans.dental) {
      coverageElections.electedPlans.push(this.selectedPlans.dental);
      coverageElections.planTypeIds.push(this.dentalPlanType.planTypeId);
    } else if (this.hasDental) {
      coverageElections.waivedPlanTypes.push(this.dentalPlanType.planTypeId);
      if (this.removeDentalForDE) {
        coverageElections.removeForDEPlanTypes.push(this.dentalPlanType.planTypeId);
      }
    }

    if (this.selectedPlans.vision) {
      coverageElections.electedPlans.push(this.selectedPlans.vision);
      coverageElections.planTypeIds.push(this.visionPlanType.planTypeId);
    } else if (this.hasVision) {
      coverageElections.waivedPlanTypes.push(this.visionPlanType.planTypeId);
      if (this.removeVisionForDE) {
        coverageElections.removeForDEPlanTypes.push(this.visionPlanType.planTypeId);
      }
    }

    if (this.selfPayWaivingMedicalCoverage || this.isDeferRetiree) {
      coverageElections.selfPayWaivingMedicalCoverage = true;
    }

    if (this.selfPayWaivingDentalCoverage) {
      coverageElections.selfPayWaivingDentalCoverage = true;
    }

    if (this.selfPayWaivingVisionCoverage) {
      coverageElections.selfPayWaivingVisionCoverage = true;
    }

    if (this.currentSubscriberSummary.deMedicalAny && this.currentSubscriberSummary.DEMessageAccepted) {
      const action = new Action();
      action.actionDate = new Date();
      action.actionTypeId = get(
        find(this.actionTypes, (at: ActionType) => at.actionTypeCode === 'EDEM'),
        'actionTypeId'
      );
      action.memberId = this.currentSubscriberSummary.memberId;
      await lastValueFrom(this.actionService.createAction(action));

      coverageElections.terminatePreviousPebbCoverage = true;
    }

    if (this.currentSubscriberSummary.retireeTermLifeEnrolledInd && !this.initialSubscriberSummary.retireeTermLifeEnrolledInd) {
      coverageElections.planTypeIds.push(this.rtlPlanType.planTypeId);
      coverageElections.electedPlans.push(this.rtlPlan);
    }
    if (!this.currentSubscriberSummary.retireeTermLifeEnrolledInd && this.initialSubscriberSummary.retireeTermLifeEnrolledInd) {
      coverageElections.waivedPlanTypes.push(this.rtlPlanType.planTypeId);
    }

    if (this.currentSubscriberSummary.basicLifeEnrolledInd && !this.initialSubscriberSummary.basicLifeEnrolledInd) {
      coverageElections.planTypeIds.push(this.basicLifePlanType.planTypeId);
      coverageElections.electedPlans.push(this.basicLifePlan);
    }
    if (!this.currentSubscriberSummary.basicLifeEnrolledInd && this.initialSubscriberSummary.basicLifeEnrolledInd) {
      coverageElections.waivedPlanTypes.push(this.basicLifePlan.planTypeId);
    }
    if(this.enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'OE' && !coverageElections.removeForDEPlanTypes?.length &&
      (!this.initialSelectedPlans?.medical && this.selectedPlans?.medical )
    ) {
      coverageElections.changeReasonId = this.oeReasonId;
    }
    else if (coverageElections.removeForDEPlanTypes?.length) {
        coverageElections.changeReasonId = this.deHCACorrectionReasonId;
    }
    //else if (coverageElections.removeForDEPlanTypes?.length || coverageElections.terminatePreviousPebbCoverage == true) {
    //  coverageElections.changeReasonId = this.deReasonId;
    //}

    if (coverageElections.changeReasonId == null) {
      coverageElections.changeReasonId = this.neReasonId;
    }

    this.spinnerService.show();
    if (this.isSOE) {
      this.enrollmentService
        .createSubscriberEnrollmentsForSOE(this.subscriber.subscriberMemberId, this.enrollmentPeriod.enrollmentPeriodId, coverageElections, this.currentSOE.specialOpenEnrollmentId)
        .pipe(untilDestroyed(this)).subscribe((s: SubscriberCoverage) => {
          this.enrollmentPeriodService
            .createMilestoneCompletion(this.subscriber.memberId, this.coverageMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId)
            .pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
              this.subscriber.refetch = true;
              this.coreService.setSubscriber(this.subscriber);
              this.enrollmentPeriod = e;

              const isSelfPay = this.enrollmentPeriod.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE';

              if (isSelfPay) {
                this.coreService.popMessage(`You have successfully selected your plans but we aren't quite done! Please manage your dependents`, 'success', 8000);
              } else if (this.hasMedical && this.medicalSelectedPlan && !this.subscriberIsMedicareABEnrolled) {
                this.coreService.popMessage(`You have successfully selected your plans but we aren't quite done! Please complete your tobacco attestation`, 'success', 8000);
              } else if (some(this.enrollmentPeriod.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === '5')) {
                this.coreService.popMessage('Great! We have your elections, time to check out some supplemental coverage options.', 'success', 6000);
              } else {
                this.coreService.popMessage(`Great! One last step - let's review your elections`, 'success', 8000);
              }
              this.navigateToNextStep();
              this.changesHaveBeenSaved = true;
              this.spinnerService.hide();
            });
        });
    } else {
      this.enrollmentService.createSubscriberEnrollments(this.subscriber.subscriberMemberId, this.enrollmentPeriod.enrollmentPeriodId, coverageElections)
        .pipe(untilDestroyed(this))
        .subscribe((s: SubscriberCoverage) => {
        this.enrollmentPeriodService
          .createMilestoneCompletion(this.subscriber.memberId, this.coverageMilestone.milestoneId, this.enrollmentPeriod.enrollmentPeriodId)
          .pipe(untilDestroyed(this)).subscribe((e: EnrollmentPeriod) => {
            this.subscriber.refetch = true;
            this.coreService.setSubscriber(this.subscriber);
            this.enrollmentPeriod = e;

            const isSelfPay = this.enrollmentPeriod.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE';

            if (isSelfPay) {
              this.coreService.popMessage(`You have successfully selected your plans but we aren't quite done! Please manage your dependents`, 'success', 8000);
              this.router.navigate([`../../dependents/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            } else if (this.hasMedical && this.medicalSelectedPlan && !this.subscriberIsMedicareABEnrolled) {
              this.coreService.popMessage(`You have successfully selected your plans but we aren't quite done! Please complete your tobacco attestation`, 'success', 8000);
              this.router.navigate([`../../attest/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            } else if (some(this.enrollmentPeriod.electablePlanTypes, (ept: PlanType) => ept.planTypeCode === '5')) {
              this.coreService.popMessage('Great! We have your elections, time to check out some supplemental coverage options.', 'success', 6000);
              this.router.navigate([`../../supplemental/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            } else {
              this.coreService.popMessage(`Great! One last step - let's review your elections`, 'success', 8000);
              this.router.navigate([`../../confirmation/${this.enrollmentPeriod.enrollmentPeriodId}`], { relativeTo: this.route });
            }
            this.changesHaveBeenSaved = true;
            this.spinnerService.hide();
          });
      });
    }
  }

  navigateToNextStep() {
    const soeNextRoute = '../..' + env.milestoneMap[this.nextMilestone.milestoneName].state + this.enrollmentPeriod.enrollmentPeriodId;
    this.router.navigate([soeNextRoute], { relativeTo: this.route });
  }

  enrollRTL(isEnrolling: boolean): void {
    if (isEnrolling) {
      this.currentSubscriberSummary.retireeTermLifeEnrolledInd = true;
    } else {
      this.currentSubscriberSummary.retireeTermLifeEnrolledInd = false;
    }
  }

  enrollBL(isEnrolling: boolean): void {
    if (isEnrolling) {
      this.currentSubscriberSummary.basicLifeEnrolledInd = true;
    } else {
      this.currentSubscriberSummary.basicLifeEnrolledInd = false;
    }
  }

  deferRetireeChange(isDeferringRetiree) {
    this.isDeferRetiree = isDeferringRetiree;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.changesHaveBeenSaved || isEqual(this.initialSelectedPlans, this.selectedPlans);
  }
}
