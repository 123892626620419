import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import UserAccessLevel from 'src/app/models/userAccessLevel';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { cloneDeep, forEach, get, find } from 'lodash';
import UserRole from 'src/app/models/userRole';
import { CoreService } from 'src/app/services/core.service';
import * as dayjs from 'dayjs';
import RelationshipCertification from 'src/app/models/relationshipCertification';

@Component({
  selector: 'access-management-grid',
  templateUrl: 'accessManagementGrid.component.html',
  styleUrls: [],
  providers: []
})
export class AccessManagementGridComponent implements OnInit, OnChanges {

  constructor(public coreService: CoreService) {}

  @ViewChild('accessGrid') public accessGrid: GridComponent;
  @ViewChild('accessForm') public accessForm: NgForm;
  @Input() gridData;
  @Input() gridColumns;
  @Input() isHCAAdmin;
  @Input() isPerspayAdmin;
  @Input() isPerspayEdit;
  @Input() isInactiveGrid = false;
  @Input() roles: UserAccessLevel[];
  @Output() accessDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() disassociate: EventEmitter<any> = new EventEmitter<any>();
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  clonedGridData;
  canEdit = false;
  icons = {
    faCalendar
  };
  adminAccessLevelId: string;

  ngOnInit(): void {
    this.adminAccessLevelId = get(find(this.roles, (r: UserAccessLevel) => r.userAccessLevelName === 'Admin'), 'userAccessLevelId');

    this.canEdit = this.isHCAAdmin || this.isPerspayAdmin;
  }

  ngOnChanges(): void {
    if (this.gridData) {
      this.resetDataForGrid();
    }
  }

  resetDataForGrid(): void {
    this.clonedGridData = cloneDeep(this.gridData);
  }

  markAllControlsAsTouched(): void {
    this.coreService.markFormControlsAsTouched(this.accessForm);
  }

  saveHandler(dataItem, index, formContainer): void {
    this.markAllControlsAsTouched();
    if (this.accessForm.valid) {
      this.accessDataChange.emit([index, dataItem]);
    } else {
      this.coreService.popMessage('Fields missing or invalid, please correct and try again.', 'error', 2000, this.coreService.getInvalidFields(formContainer));
    }
  }

  addHandler(): void {
    const newUser = {};
    forEach(this.gridColumns, (v) => {
      newUser[v.field] = null;
    });
  }
}
