import BaseAddress from './baseAddress';

export default class OrganizationAddress extends BaseAddress {
  organizationAddressId: string;
  organizationId: string;

  constructor(organizationAddress?) {
    super(organizationAddress);

    if (organizationAddress) {
      this.organizationAddressId = organizationAddress.organizationAddressId;
      this.organizationId = organizationAddress.organizationId;
    }
  }
}
