import { find, filter, head, reverse, sortBy, map, maxBy, some } from 'lodash';
import * as dayjs from 'dayjs';
import MemberSummary from './memberSummary';
import Subscriber from './subscriber';
import Enrollment from './enrollment';
import Attestation from './attestation';
import MemberAddress from './memberAddress';
import MemberType from './memberType';
import Member from './member';
import { env } from 'src/env/development';

export default class SubscriberSummary {
  memberId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  emailNotificationsInd: boolean;
  countyOfResidence: string;
  currentMedicalPlan: string;
  medicalPremium: number;
  tobaccoUseSurcharge: number;
  spousalCoverageSurcharge: number;
  totalCost: number;
  currentDentalPlan: string;
  dentalPremium: number;
  currentVisionPlan: string;
  currentLifePlan: string;
  currentSupplementalLTDPlan: string;
  visionPremium: number;
  spousalCoverageSurchargeInd: boolean;
  employmentAddress: MemberAddress;
  residentialAddress: MemberAddress;
  currentMedical: Enrollment;
  currentDental: Enrollment;
  currentVision: Enrollment;
  currentLife: Enrollment;
  currentADD: Enrollment;
  currentSupplementalLTD: Enrollment;
  currentVisionPlanId: string;
  currentLifePlanId: string;
  currentADDPlanId: string;
  memberSummary: MemberSummary[];
  memberType: MemberType;
  familyCompositionCode: string;
  currentEmployer: string;
  lifeBenefitAmount: number;
  addBenefitAmount: number;
  members: Member[];

  constructor(sub: Subscriber) {
    // TODO - TEMPORARY - BRING BACK WHEN subscriberInd returns true.
    // sub.members = this.filterForActiveMembers(sub.members);
    sub.members = sub.members;
    this.memberId = sub.memberId;
    this.firstName = sub.firstName;
    this.middleName = sub.middleName;
    this.lastName = sub.lastName;
    this.emailAddress = sub.emailAddress;
    this.emailNotificationsInd = sub.emailNotificationInd;
    this.residentialAddress = sub.residentialAddress;
    this.countyOfResidence = this.residentialAddress.county.countyName;
    this.members = sub.members;
    this.employmentAddress = find(
      sub.addresses,
      (a) => a.addressType.addressTypeCode === env.billingAddressTypeCode && a.effectiveStartDate <= new Date() && (!a.effectiveEndDate || a.effectiveEndDate > new Date())
    );

    this.currentMedical = sub.getCoverageByPlanTypeAndDate('Medical', new Date());

    this.currentDental = sub.getCoverageByPlanTypeAndDate('Dental', new Date());

    this.currentSupplementalLTD = sub.getCoverageByPlanTypeAndDate('LTD', new Date());

    this.currentLife = sub.getCoverageByPlanTypeAndDate('Basic Life and AD&D', new Date());

    this.currentADD = sub.getCoverageByPlanTypeAndDate('Basic Life and AD&D', new Date());

    this.currentMedicalPlan = this.currentMedical ? this.currentMedical.plan.planName : 'Waived / Not Currently Enrolled';
    this.currentDentalPlan = this.currentDental ? this.currentDental.plan.planName : 'Waived / Not Currently Enrolled';
    this.currentVisionPlan = this.currentVision ? this.currentVision.plan.planName : 'Waived / Not Currently Enrolled';
    this.currentSupplementalLTDPlan = this.currentSupplementalLTD ? this.currentSupplementalLTD.plan.planName : 'Waived / Not Currently Enrolled';
    this.lifeBenefitAmount = this.currentLife ? this.currentLife.plan.benefitAmt : null;
    this.addBenefitAmount = this.currentADD ? this.currentADD.plan.benefitAmt : null;
    this.medicalPremium = this.currentMedical
      ? this.currentMedical.plan.rateForSpecificDate(new Date(), sub.getFamilyCompositionForDate(new Date(), this.currentMedical.plan.planTypeId))
      : null;
    this.dentalPremium = this.currentDental
      ? this.currentDental.plan.rateForSpecificDate(new Date(), sub.getFamilyCompositionForDate(new Date(), this.currentDental.plan.planTypeId))
      : null;
    this.visionPremium = this.currentVision
      ? this.currentVision.plan.rateForSpecificDate(new Date(), sub.getFamilyCompositionForDate(new Date(), this.currentVision.plan.planTypeId))
      : null;


    this.tobaccoUseSurcharge = this.getSubTobaccoSurcharge();
    // some(
    //   allMostRecentTobaccoAttestations,
    //   (a: Attestation) => a.response && a.response.responseName === 'Yes' && this.subHasEffectiveMedicalCoverageForNow(find(sub.subs, (m) => m.subId === a.subId))
    // ) || !(allMostRecentTobaccoAttestations && allMostRecentTobaccoAttestations.length)
    //   ? 25
    //   : 0;

    const spousalCoverageAttestations: Attestation[] = filter(sub.attestations, (a: Attestation) => a.attestationType.attestationTypeName === 'Spousal Surcharge');
    const lastSpousalAttestation: Attestation = head(reverse(sortBy(spousalCoverageAttestations, ['attestationDate'])));
    const spouse = find(this.members, (m: Member) => m.relationshipToSubscriber.relationshipType && m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S');
    const medicalForSpouse = spouse.getCoverageByPlanTypeAndDate('Medical', new Date());
    this.spousalCoverageSurchargeInd =
      lastSpousalAttestation &&
      lastSpousalAttestation.response.responseName === 'Yes' &&
      medicalForSpouse
        ? true
        : false;

    this.spousalCoverageSurcharge = this.spousalCoverageSurchargeInd ? 50 : 0;
    this.totalCost = this.medicalPremium + this.dentalPremium + this.visionPremium + this.tobaccoUseSurcharge + this.spousalCoverageSurcharge;
    this.memberType = sub.memberType;
    this.memberSummary = map(sub.members, (m) => new MemberSummary(m, this.memberId));
    this.currentEmployer = sub.organization ? sub.organization.organizationName : '';
  }


  memberHasEffectiveMedicalCoverageForNow(): boolean {
    return this.currentMedical ? true : false;
  }

  filterForActiveMembers(members: Member[]): Member[] {
    const now = new Date();
    return filter(
      members,
      (m: Member) =>
        m.isSubscriberInd ||
        (m.relationshipToSubscriber.effectiveStartDate <= now &&
          (m.relationshipToSubscriber.effectiveEndDate >= now || !m.relationshipToSubscriber.effectiveEndDate) &&
          !m.relationshipToSubscriber.deniedDate &&
          m.relationshipToSubscriber.simplifiedStatus !== 'Pending')
    );
  }

  getSubTobaccoSurcharge(): number {
    const now = new Date();
    // subscriber has medical coverage for date, first of all:
    if (this.currentMedical) {
      // if any member
      if (
        some(
          this.members,
          (m: Member) =>
            // has medical coverage for period
            m.getCoverageByPlanTypeAndDate('Medical', new Date()) &&
            // and is not denied
            m.relationshipToSubscriber.simplifiedStatus !== 'Denied' &&
            // either - has some attestation before the period of the right type and indicated 'YES'
            (some(m.attestations, (a: Attestation) => dayjs(a.effectiveStartDate).isSameOrBefore(now) && a.attestationType.attestationTypeCode === 'TS' && a.response.responseName === 'Yes') ||
              // or is over 13
              (!m.isUnderThirteen &&
                // and has no attestation before the period of the right type at all (default)
                !some(m.attestations, (a: Attestation) => dayjs(a.effectiveStartDate).isSameOrBefore(now) && a.attestationType.attestationTypeCode === 'TS')))
        )
      ) {
        return 25;
      }
    }
    return 0;
  }
}
