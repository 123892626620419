import Subscriber  from 'src/app/models/subscriber';
import SupplementalLTDBandDisplay from 'src/app/models/supplementalLTDBandsDisplay';
import { Component, OnInit, Input } from '@angular/core';

import { filter, find, sortBy, map, get, reduce, uniq, each } from 'lodash';


@Component({
  selector: 'ltd-grid',
  templateUrl: 'ltd.grid.component.html',
  styleUrls: [],
  providers: [],
})
export class LTDGridComponent implements OnInit {
  @Input() ltdBands: SupplementalLTDBandDisplay[];
  subscriber: Subscriber;
  gridData: any[] = [];
  gridColumns: { [k: string]: string | {} }[];

  constructor() {}

  ngOnInit() {
    const rateColumns = uniq(this.ltdBands.map(b => b.ltdType));
    const fields = [
        { field: 'ageRange', title: 'Age', format: 'string' },
        { field: 'percent50', title: '50-percent plan', format: 'string'},
        { field: 'percent60', title: '60-percent plan', format: 'string'},
      ];


    this.gridData = this.ltdBands;
    this.gridColumns = fields;
  }
}
