<div *ngIf="acAvailableToUser()" fxFlex fxFlexFill fxFlexLayout="column" class="container-fluid">
    <div class="state">
        <div class="card">
            <div class="card-body">
                <h2 class="mobile-text-centered mgtp-small mgbt-medium-sm">Account Corrections</h2>
                <div fxLayout="row" class="fxGap-medium" fxLayoutAlign="space-between"
                    style="padding-bottom: 10px; height: 60px;">
                    <subscriber-search organizationId style="flex: 70;"
                        (searchSubmitted)="onSearchSubmitted($event)"></subscriber-search>
                </div>
                <generic-grid (selectedRow)="onSubscriberSelected($event)" (deselectedRow)="onSubscriberDeselected()"
                    [selectBy]="'memberId'" [selectable]="true" [selectedKeys]="" [pageable]="true" [pageSize]="20"
                    [gridData]="subscriberSearchResults" [gridColumns]="searchResultColumns" [filterable]="true">
                </generic-grid>

                <div *ngIf="selectedSubscriber && fullSubscriber" class="subscriber-history mgtp-medium"
                    [class]="{ printing: printing }">
                    <!-- <h2>Subscriber Details</h2>
                    <h3>
                        <div>{{selectedSubscriber.firstName}} {{selectedSubscriber.lastName}}</div>
                        <div>{{fullSubscriber.socialSecurityNumber | ssn}} {{fullSubscriber.birthDate | date:
                            'MM/dd/yyyy'}}</div>
                    </h3> -->

                    <account-correction-edit *ngIf="fullSubscriber" [systemUser]="systemUser"
                        [subscriber]="fullSubscriber" [lookups]="lookups" [editable]="true"
                        [availablePlans]="plansByType" [supplementalPlan]="supplementalPlan"
                        [basicPlan]="basicPlan" [lifePlan]="lifePlan"
                        [adPlan]="adPlan"
                        [enrollments]="enrollments"
                        [plans]="plans"
                        [enrollmentPeriods]="enrollmentPeriods"
                        (refetchMember)="refetchMember($event)">
                    </account-correction-edit>
                </div>
            </div>
        </div>
    </div>
</div>