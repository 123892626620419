<form #verifyForm="ngForm" *ngIf="lastDataItemId" #formContainer>
  <div class="card" *ngIf="!proceedingWithWizard">
    <div class="card-body" style="margin-left: 12px">
      <div fxLayout="row wrap">
        <div fxLayout="row" fxFlex="100" class="mgbt-medium-lg mgtp-medium" *ngIf="isHCAEdit">
          <checkbox-list
            [refreshAllItemChanges]="true" 
            [disabled]="milestoneCompleted"
            [name]="'approveDeny' + rowIndex"
            [multi]="false"
            orientation="horizontal"
            [(ngModel)]="dataItem.currentStateOfVerify"
            (ngModelChange)="updateProcessStatus()"
            [items]="spVerificationList"
            ></checkbox-list>
        </div>

        <div fxLayout="row" fxFlex="100" class="fxGap-medium" *ngIf="isHCAEdit">
          <div class="form-group" fxFlex="30" required>
            <label for="selfPayVerificationStatusId">Request status<span class="k-required">*</span></label>
            <select required [disabled]="!canEdit" class="form-control" name="selfPayVerificationStatusId" [(ngModel)]="dataItem.selfPayVerificationStatusId">
              <option [ngValue]="null"></option>
              <option *ngFor="let verificationStatus of requestStatusList | isObsolete" [ngValue]="verificationStatus.selfPayVerificationStatusId">
                {{ verificationStatus.selfPayVerificationStatusName }}
              </option>
            </select>
          </div>
          <div class="form-group" required *ngIf="dataItem.currentStateOfVerify !== 'pending'">
            <date-picker
              #verifyDate="ngModel"
              name="verifyDate"
              label="Process date"
              required
              [max]="maxProcessDate"
              [min]="minProcessDate"
              [disabled]="!canEdit"
              [(ngModel)]="dataItem.processDate"></date-picker>
            <div *ngIf="dataItem.processDate && dataItem.processDate < minProcessDate" class="alert alert-danger">Process date cannot be prior to request submit date.</div>
            <div *ngIf="dataItem.processDate && dataItem.processDate > maxProcessDate" class="alert alert-danger">Process date cannot be a future date.</div>
          </div>
          <div fxLayout.gt-sm="column" class="ie-height-xs" fxLayout="row wrap" fxFlex.gt-sm="45" fxFlex="45" style="margin-bottom:15px;margin-left:15px;" >
            <div class="form-group" fxFlex="100">
              <label for="selfPayProcessStatus">Processing status</label>
              <select class="form-control" name="selfPayProcessStatus" [disabled]="!userCanEdit" [(ngModel)]="dataItem.selfPayProcessStatusId">
                <option [ngValue]="null"></option>
                <option *ngFor="let processStatus of selfPayProcessStatusList | isObsolete" [ngValue]="processStatus.selfPayProcessStatusId">
                  {{ processStatus.selfPayProcessStatusName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div fxLayout="column" class="mgtp-medium">
          <h3 class="h4">Request review/changes</h3>
          <div fxLayout="row" fxLayoutAlign="space-between" class="card pad-std-container">
            <div fxLayout="column" fxFlex="40">
              <h5>Form request review</h5>
              <div class="hca-document-placeholder" (click)="downloadSelfPayPDF()" *ngIf="dataItem?.formJson">
                <fa-icon class="hca-fontawesome-icon hca-document-icon" [icon]="icons.faImages" size="3x"></fa-icon>
              </div>
              <div *ngIf="dataItem?.formJson" fxLayout="column" class="ie-height-xs mgtp-x-small" fxLayoutAlign="start start">
                <span>
                  <span class="pad-std-label"><b>Type:</b></span><span>{{ dataItem?.selfPayType?.selfPayTypeName }}</span>
                </span>
                <span>
                  <span class="pad-std-label"><b>On:</b></span><span>{{ dataItem.submittedDate | date: 'MM/dd/yyyy' }}</span>
                </span>
              </div>
            </div>
            <div fxLayout="column" fxFlex="60">
              <span>
                Review the status of the account and the requested changes/enrollment for this request in the attached form (or from a paper form) to 
                determine if it is complete.  Request status will appear to the subscriber.  Input request status if request cannot be approved/denied 
                due to additional information/review needed. Changes/enrollments can be keyed for a request in approved status.  
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="isHCAEdit" class="form-row mgtp-medium mgbt-medium" style="width: 100%;" fxLayoutAlign="center">
          <button
            type="button"
            [disabled]="!canEdit"
            class="btn btn-primary"
            style="margin-right: 20px"
            (click)="pushSaveVerification(formContainer)"
          >
            Submit changes
          </button>
          <button type="button" [disabled]="!canEdit" class="btn" (click)="pendingChanges = false" (click)="reSetDataForGrid()">Cancel</button>
        </div>
        <div *ngIf="approved && isHCAEdit && !soeCustomForm" class="form-row" style="width: 100%;" fxLayoutAlign="center">
          <h3 class="h5 mgbt-medium" *ngIf="dataItem?.member?.memberSelfPayOrigin">You can now make the allowable changes to this account. Click the button below to begin the process.</h3>
          <h3 class="h5 mgbt-medium" *ngIf="!dataItem?.member?.memberSelfPayOrigin"><span class="hca-warn-text">To enroll or make changes to enrollments, this account must be updated to an active self-pay eligibility type. Please use the <a [routerLink]="'/admin/subscriberManagement/' + systemUser.systemUserId">Add self-pay subscriber</a> functionality to update the self-pay eligibility information. Once entered, you can return to this request to process the enrollment changes.</span></h3>
        </div>
        <div *ngIf="approved && isHCAEdit && !soeCustomForm" class="form-row" style="width: 100%;" fxLayoutAlign="center">
          <button
            [disabled]="!dataItem?.member?.memberSelfPayOrigin"
            type="button"
            class="btn btn-primary"
            (click)="proceedWithWizard(true)"
          > Process enrollment changes
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- Wizzybiz -->
<div fxLayout="column" *ngIf="proceedingWithWizard && isHCAEdit" class="hca-standard-font">
  <selfpay-effective-date 
    *ngIf="displayCoverageDate"
    [selfpay]="dataItem"
    (saveSelfpay)="saveCoverageDate()"
    (previous)="proceedingWithWizard = false"
  ></selfpay-effective-date>
  <router-outlet *ngIf="dataItem?.member?.soeEnrollmentPeriod && dataItem?.coverageEffectiveDate && showWizard" class="mgbt-medium mgtp-large" fxLayoutAlign="center center" #wizardOutlet id="wizardOutlet">
    <pebb-wizard
      [isSOE]="true"
      *ngIf="dataItem?.coverageEffectiveDate && dataItem?.member?.soeEnrollmentPeriod && !coverageDateInitialNull"
      [subscriber]="dataItem.member"
      [enrollmentPeriod]="dataItem?.member?.soeEnrollmentPeriod"
      [modifiedMilestoneNameOrder]="[ 'Coverage', 'Dependents', 'Upload', 'Attestations', 'Supplemental Benefits', 'Confirmation' ]"
      ></pebb-wizard>
  </router-outlet>
</div>