import { map } from 'lodash';
import Enrollment from './enrollment';
import Employment from './employment';
import MemberNote from './memberNote';
import MemberMemberType from './memberMemberType';
import MemberWaiver from './memberWaiver';
import MemberAddress from './memberAddress';
import MemberMaritalStatus from './memberMaritalStatus';
import SpecialOpenEnrollment from './specialOpenEnrollment';
import Relationship from './relationship';
import Attestation from './attestation';

export default class MemberAccountCorrection {
  memberId: string;
  isSubscriberInd: boolean;
  firstName: string;
  lastName: string;
  socialSecurityNumber: string;
  organizationId: string;
  enrollments: Enrollment[];
  waivers: MemberWaiver[];
  eligibilityDate: Date;
  employments: Employment[];
  memberNotes: MemberNote[];
  sortOrder: number;
  currentMemberMemberType: MemberMemberType;
  memberMemberTypes: MemberMemberType[];
  memberAddresses: MemberAddress[];
  memberMaritalStatuses: MemberMaritalStatus[];
  specialOpenEnrollments: SpecialOpenEnrollment[];
  primaryRelationships: Relationship[];
  secondaryRelationships: Relationship[];
  attestations: Attestation[];

  constructor(accountCorrection) {
    this.memberId = accountCorrection.memberId;
    this.isSubscriberInd = accountCorrection.isSubscriberInd;
    this.firstName = accountCorrection.firstName;
    this.lastName = accountCorrection.lastName;
    this.socialSecurityNumber = accountCorrection.socialSecurityNumber;
    this.organizationId = accountCorrection.organizationId;
    this.enrollments = map(accountCorrection.enrollments, e => new Enrollment(e));
    this.waivers = map(accountCorrection.memberWaivers, w => new MemberWaiver(w));
    this.employments = map(accountCorrection.employments, e => new Employment(e));
    this.eligibilityDate = accountCorrection.eligibilityDate ? new Date(accountCorrection.eligibilityDate) : null;
    this.memberNotes = map(accountCorrection.memberNotes, mn => new MemberNote(mn));
    this.currentMemberMemberType = accountCorrection.memberMemberType ? new MemberMemberType(accountCorrection.memberMemberType) : null;
    this.sortOrder = accountCorrection.sortOrder;

    this.memberMemberTypes = map(accountCorrection.memberMemberTypes, mt => new MemberMemberType(mt));
    this.memberAddresses = map(accountCorrection.memberAddresses, ma => new MemberAddress(ma));
    this.memberMaritalStatuses = map(accountCorrection.memberMaritalStatuses, mms => new MemberMaritalStatus(mms));
    this.specialOpenEnrollments = map(accountCorrection.specialOpenEnrollments, soe => new SpecialOpenEnrollment(soe));
    this.primaryRelationships = map(accountCorrection.primaryRelationships, pr => new Relationship(pr));
    this.secondaryRelationships = map(accountCorrection.secondaryRelationships, pr => new Relationship(pr));
    this.attestations = map(accountCorrection.attestations, a => new Attestation(a));
  }

}
