<div class="form-group"
  style="display:block;"
  (dblclick)="dblClick()"
  [class.required]="innerRequired"
  [class.time]="innerUseTime"
  [fxLayoutAlign]="inputFlex && labelFlex ? 'space-between' : undefined">
  <ng-template #labelTemplate>
    <label [id]="(innerUseTime ? (name + '-time') : name) + '-label'" [for]="innerUseTime ? timePicker : picker" #labelEl [class.colon]="labelColon === true || labelColon === ''"><ng-content></ng-content>{{label}}</label>
    {{determineLabelVisibility(labelEl)}}
  </ng-template>
  <div *ngIf="labelVisible" [fxFlex]="labelFlex" [class]="labelClass">
    <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
  </div>
  <div [class]="'input-group ' + (inputClass ?? '')" [fxFlex]="inputFlex">
    <form #form="ngForm">
      <div [style]="{ display: (innerUseTime ? 'none' : 'block') }">
        <kendo-datepicker
          #picker
          [name]="name"
          [attr.aria-labelledby]="(innerUseTime ? (name + '-time') : name) + '-label'"
          [calendarType]="calendarType"
          [class.hideIcon]="innerHideIcon"
          [disabled]="innerDisabled"
          [min]="innerMin"
          [max]="innerMax"
          [placeholder]="placeholder"
          [rangeValidation]="innerMin || innerMax"
          [required]="innerRequired"
          [readonly]="innerReadonly"
          [readOnlyInput]="innerReadOnlyInput"
          [disabledDates]="innerDisabledDates"
          rounded="small"
          format="MM/dd/yyyy"
          formatPlaceholder="formatPattern"
          [(ngModel)]="value"
          (ngModelChange)="dateChanged($event)"
          (blur)="dateBlurred()"></kendo-datepicker>
      </div>
      <div [style]="{ display: (innerUseTime ? 'block' : 'none') }">
        <kendo-datetimepicker
          #timePicker
          [name]="name + '-time'"
          [attr.aria-labelledby]="(innerUseTime ? (name + '-time') : name) + '-label'"
          [calendarType]="calendarType"
          [class.hideIcon]="innerHideIcon"
          [disabled]="innerDisabled"
          [min]="innerMin"
          [max]="innerMax"
          [placeholder]="placeholder"
          [rangeValidation]="innerMin || innerMax"
          [required]="innerRequired"
          [readonly]="innerReadonly"
          [readOnlyInput]="innerReadOnlyInput"
          [disabledDates]="innerDisabledDates"
          rounded="small"
          formatPlaceholder="formatPattern"
          [(ngModel)]="value"
          (ngModelChange)="dateChanged($event)"
          (blur)="dateBlurred()"></kendo-datetimepicker>
      </div>
      <div *ngIf="extraMessage" class="alert alert-warning">{{extraMessage}}</div>
    </form>
  </div>
</div>

<kendo-dialog
    title="Date Picker Debug Info"
    *ngIf="showDebugInfo && debugOpen"
    (close)="debugOpen = false"
    [minWidth]="700"
    width="95vw"
    minHeight="350px"
    maxHeight="95vh"
    class="date-picker-info"
  >
  <ul style="list-style:none;">
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>name</b></div>
        <div fxFlex="50">{{name}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>label</b></div>
        <div fxFlex="50">{{label}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>required</b></div>
        <div fxFlex="50">{{flagIsTrue(required)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>readOnly</b></div>
        <div fxFlex="50">{{flagIsTrue(readOnly)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>readOnlyInput</b></div>
        <div fxFlex="50">{{flagIsTrue(readOnlyInput)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>labelClass</b></div>
        <div fxFlex="50">{{flagIsTrue(labelClass)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>inputClass</b></div>
        <div fxFlex="50">{{flagIsTrue(inputClass)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>disabled</b></div>
        <div fxFlex="50">{{flagIsTrue(disabled)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>innerMax</b></div>
        <div fxFlex="50">
          {{innerMax}}
          <ul>
            <li *ngFor="let m of maxDateStatus">{{m}}</li>
          </ul>
        </div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>innerMin</b></div>
        <div fxFlex="50">
          {{innerMin}}
          <ul>
            <li *ngFor="let m of minDateStatus">{{m}}</li>
          </ul>
        </div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>max</b></div>
        <div fxFlex="50">{{max}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>min</b></div>
        <div fxFlex="50">{{min}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>placeholder</b></div>
        <div fxFlex="50">{{placeholder}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>markDisabled set</b></div>
        <div fxFlex="50">{{!!markDisabled}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>disabledDates set</b></div>
        <div fxFlex="50">{{!!disabledDates}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>labelColon</b></div>
        <div fxFlex="50">{{flagIsTrue(labelColon)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>labelFlex</b></div>
        <div fxFlex="50">{{labelFlex}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>inputFlex</b></div>
        <div fxFlex="50">{{inputFlex}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>hideIcon</b></div>
        <div fxFlex="50">{{flagIsTrue(hideIcon)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>useTime</b></div>
        <div fxFlex="50">{{flagIsTrue(useTime)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>calendarType</b></div>
        <div fxFlex="50">{{calendarType}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>extraMessage</b></div>
        <div fxFlex="50">{{extraMessage}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>coverageEffectiveDate</b></div>
        <div fxFlex="50">{{coverageEffectiveDate}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>enforceLowerLimit</b></div>
        <div fxFlex="50">{{flagIsTrue(enforceLowerLimit)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>onlyAllowFirstOfMonth</b></div>
        <div fxFlex="50">{{flagIsTrue(onlyAllowFirstOfMonth)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>onlyAllowLastOfMonth</b></div>
        <div fxFlex="50">{{flagIsTrue(onlyAllowLastOfMonth)}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>lockToMonth</b></div>
        <div fxFlex="50">{{lockToMonth}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>canBeInFuture</b></div>
        <div fxFlex="50">{{canBeInFuture}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>innerEnforceLowerLimit</b></div>
        <div fxFlex="50">{{innerEnforceLowerLimit}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>lowerLimitOffset</b><br /><i>When true, indicates entry of a date that is used to calculate a coverage effective date, such as eligibility date.</i></div>
        <div fxFlex="50">{{lowerLimitOffset}}</div>
      </div>
    </li>
    <li>
      <div fxLayout="row" fxFlexFill fxLayoutAlign="start end">
        <div fxFlex="50"><b>Agency (for lower limit)</b></div>
        <div fxFlex="50">{{agencyCode}}</div>
      </div>
    </li>
  </ul>
</kendo-dialog>