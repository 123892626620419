import { ElementRef, Directive, Optional, Self} from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
    selector: '[nativeControlElement]'
  })

// sets nativeElement on conrols so its grabbable later in validation from coreService
export class NativeControlElementDirective {
    constructor(private el: ElementRef, @Optional() @Self() public control: NgControl, @Optional() @Self() private model? : NgModel) {
       if (model && model?.control) {
           (<any>model.control).nativeElement = el.nativeElement;
       }
       else if (control) {
           (<any>control).nativeElement = el.nativeElement;
       }
    }
}