import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { sortBy, filter, findIndex, uniqBy, get, find, cloneDeep, mapKeys } from 'lodash';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import { LookupService } from './lookup.service';
import Relationship from '../models/relationship';
import RelationshipCertification from '../models/relationshipCertification';
import RelationshipVerificationStatus from '../models/relationshipVerificationStatus';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { __assign } from 'tslib';
import { replace } from 'lodash';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class RelationshipService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private lookupService: LookupService
  ) {}

  getRelationshipById(relationshipId: string, memberId: string): Observable<Relationship> {
    return this.http.get<Relationship>(`${env.apiUrl}/Member/${memberId}/Relationship/${relationshipId}`)
    .pipe(map(x => this.commonService.createObject(Relationship, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createRelationship(relationship: Relationship): Observable<Relationship> {
    return this.http.post<Relationship>(`${env.apiUrl}/Member/${relationship?.subscriberMemberId}/Relationship`, relationship)
    .pipe(map(x => this.commonService.createObject(Relationship, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  // for calls that switch verification status.

  createRelationshipAndUpdateCoverages(relationship: Relationship, enrollmentId: string): Observable<Relationship> {
    return this.http.post<Relationship>(`${env.apiUrl}/Member/${relationship?.subscriberMemberId}/Relationship/${enrollmentId}`, relationship)
    .pipe(map(x => this.commonService.createObject(Relationship, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateRelationship(relationship: Relationship): Observable<Relationship> {
    relationship.documents = [];
    return this.http.put<Relationship>(
      `${env.apiUrl}/Member/${relationship?.subscriberMemberId}/Relationship/${relationship?.relationshipId}`, relationship)
    .pipe(map(x => this.commonService.createObject(Relationship, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  deleteRelationship(relationship: Relationship): Observable<Relationship> {
    return this.http.delete<Relationship>(
      `${env.apiUrl}/Member/${relationship?.subscriberMemberId}/Relationship/${relationship?.relationshipId}`)
    .pipe(map(x => this.commonService.createObject(Relationship, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  endRelationship(relationship: Relationship, openEnrollmentId: string): Observable<void> {
    return this.http.delete(`${env.apiUrl}/Member/${relationship?.subscriberMemberId}/Relationship/${relationship?.dependentMemberId}/terminate/${openEnrollmentId}`)
    .pipe(res => res)
    .pipe(catchError(this.commonService.handleError));
  }

  getCurrentRelationshipsForAgency(organizationId: string, onlyShowPending: boolean, gridQuery: string): Observable<GridDataResult> {
    var queryString = '?' + gridQuery + (onlyShowPending ? '&pending=true' : '');
    return this.http.get<GridDataResult>(
      `${env.apiUrl}/organization/${organizationId}/relationship${queryString}`)
      .pipe(map(x => {
        x.data = this.commonService.createObjects(Relationship, x.data);
        return x;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  createRelationshipCertification(relationshipId, relationshipCertification: RelationshipCertification): Observable<RelationshipCertification> {
    return this.http.post<RelationshipCertification>(`${env.apiUrl}/certification/${relationshipId}/Relationship`, relationshipCertification)
    .pipe(map(x => this.commonService.createObject(RelationshipCertification, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  updateRelationshipCertification(relationshipCertification: RelationshipCertification): Observable<RelationshipCertification> {
    return this.http.put<RelationshipCertification>(`${env.apiUrl}/certification/${relationshipCertification?.relationshipCertificationId}`, relationshipCertification)
    .pipe(map(x => this.commonService.createObject(RelationshipCertification, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  removeRelationshipCertification(relationshipCertificationId: string): Observable<void> {
    return this.http.delete<RelationshipCertification>(`${env.apiUrl}/certification/${relationshipCertificationId}`)
    .pipe(res => res)
    .pipe(catchError(this.commonService.handleError));
  }

  getCurrentRelationshipsForHCA(onlyShowPending: boolean, gridQuery: string, agencyFilter: string, selfPayOnly?: boolean): Observable<GridDataResult> {
    var gridQueryResult = this.formatDepReviewGridQuery(gridQuery);
    gridQuery = gridQueryResult.gridQuery;
    
    var queryString = '?' + gridQuery + (onlyShowPending ? '&pending=true' : '') + '&agencyFilter=' + agencyFilter;
    queryString += (selfPayOnly ? '&selfPayOnly=true' :'') + gridQueryResult.hasDocuments + '&simplifiedStatus=' + gridQueryResult.simplifiedStatus;

    return this.http.get<GridDataResult>(`${env.apiUrl}/relationship/hca${queryString}`)
      .pipe(map(x => {
        x.data = this.commonService.createObjects(Relationship, x.data);
        return x;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  formatDepReviewGridQuery(gridQuery: string) {
    gridQuery = gridQuery.replace("approvalDate","approvedDate").replace("ApprovalDate","approvedDate");
    var hasDocuments = "";
    var simplifiedStatus = "";
    var hasDocTxt = "HasDocuments";
    var simplifiedStatusTxt = "SimplifiedStatus";
    if (gridQuery.indexOf(hasDocTxt)>0) { 
      var hasDocFilter;
      if (gridQuery.indexOf(hasDocTxt + "~eq~true")>0) { 
        hasDocuments = "&hasDocuments=true";
        hasDocFilter = hasDocTxt + "~eq~true";
      }
      else { 
        hasDocuments = "&hasDocuments=false";
        hasDocFilter = hasDocTxt + "~eq~false";
      }
      gridQuery = gridQuery.replace("~and~" + hasDocFilter, "");
      gridQuery = gridQuery.replace(hasDocFilter + "~and~", "");
      gridQuery = gridQuery.replace(hasDocFilter, "");
    }

    var simplifiedStatusTxtStart = gridQuery.indexOf(simplifiedStatusTxt);
    if (simplifiedStatusTxtStart>0) { 
      var simplifiedStatusFilter = "";
      if (gridQuery.indexOf("(" + simplifiedStatusTxt)>0) {
        //filtering by multiple simplified status
        const simplifiedStatusMultiFilterStart = gridQuery.indexOf('(' + simplifiedStatusTxt);
        const simplifiedStatusMultiFilterEnd = gridQuery.indexOf(")",simplifiedStatusMultiFilterStart);
        simplifiedStatusFilter = gridQuery.substring(simplifiedStatusMultiFilterStart,simplifiedStatusMultiFilterEnd + 1);
      }
      else {
        var simplifiedStatusFilterStart = gridQuery.indexOf("'",simplifiedStatusTxtStart) + 1
        var simplifiedStatusFilterEnd = gridQuery.indexOf("'",simplifiedStatusFilterStart);
        simplifiedStatusFilter= gridQuery.substring(simplifiedStatusTxtStart, simplifiedStatusFilterEnd + 1);
        simplifiedStatus = gridQuery.substring(simplifiedStatusFilterStart,simplifiedStatusFilterEnd);
      }
      gridQuery = gridQuery.replace("~and~" + simplifiedStatusFilter, "");
      gridQuery = gridQuery.replace(simplifiedStatusFilter + "~and~", "");
      gridQuery = gridQuery.replace(simplifiedStatusFilter, "");
    }

    gridQuery = this.commonService.formatMemberFullNameGridQuery("subscriberMember",gridQuery);
    gridQuery = this.commonService.formatMemberFullNameGridQuery("dependentMember",gridQuery);
    
    gridQuery = replace(gridQuery, "~~", "");
    return { gridQuery: gridQuery, hasDocuments: hasDocuments, simplifiedStatus: simplifiedStatus };
  }

  async upsertRelationship(relationshipRec: Relationship): Promise<Relationship> {
    // If not approved, default verification status to Received (pending)
    if (relationshipRec.approvedInd === null) {
      let relationshipVerificationStatuses: RelationshipVerificationStatus[];
      await lastValueFrom(this.lookupService.getLutValues('relationshipVerificationStatus', RelationshipVerificationStatus))
        .then((lookupValues) => {
          relationshipVerificationStatuses = lookupValues;
        });

      relationshipRec.relationshipVerificationStatusId = get(
        find(relationshipVerificationStatuses, (rvs) => rvs.relationshipVerificationStatusCode === 'R'),
        'relationshipVerificationStatusId'
      );
    }

    // update dependent relationship only
    const dependentRelationshipRec = await lastValueFrom(this.updateRelationship(relationshipRec));
    // doctypes on qr not returned on update so valid doc list does not populate, monkey patching for now as they can't change here anyway - may even be better avoiding the extra grab
    dependentRelationshipRec.relationshipQualifyReason.documentTypes = relationshipRec.relationshipQualifyReason.documentTypes;

    return dependentRelationshipRec;
  }

  async upsertRelationshipCertification(relationshipCertification: RelationshipCertification): Promise<RelationshipCertification> {
    let output: RelationshipCertification;
    if (relationshipCertification.relationshipCertificationId) {
      // update or delete
      if (relationshipCertification.effectiveStartDate > new Date() && !relationshipCertification.approvedInd) {
        await lastValueFrom(this.removeRelationshipCertification(relationshipCertification.relationshipCertificationId));
      } else {
        output = await lastValueFrom(this.updateRelationshipCertification(relationshipCertification));
      }
    } else {
      output = await lastValueFrom(this.createRelationshipCertification(relationshipCertification.relationshipId, relationshipCertification));
    }

    return output;
  }
}
