import EmailUpdate from 'src/app/models/emailUpdate';
import { Component, ViewEncapsulation, Input, Output, OnInit, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import Subscriber from 'src/app/models/subscriber';
import MemberSummary from 'src/app/models/subscriberSummary';
import { CoreService } from 'src/app/services/core.service';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'confirm-finish',
  templateUrl: 'finish.component.html',
  styleUrls: [],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmFinishComponent implements OnInit {
  @Input() subscriber: Subscriber;
  @Input() inAdminState = false;
  @Input() isReadOnly = false;
  @Input() isSebb = false;
  @Input() isSOE = false;
  @Input() soeSubmitted = false;
  @Input() isDeathOrDivorceSoe = false;
  @Input() isHcaAdmin = false;
  @Output() emailSubscriptionUpdated: EventEmitter<EmailUpdate> = new EventEmitter<EmailUpdate>();
  @Output() downloadCoveragePDF: EventEmitter<void> = new EventEmitter<void>();
  @Output() navToDashboard: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitSOERequest: EventEmitter<void> = new EventEmitter<void>();
  @Output() within60Days: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('emailForm') public emailForm: NgForm;
  showEmailTermsAndConditions = false;
  userHasRead = false;
  optInLabel = '';
  within60DaysResponse: boolean = null;

  @HostListener('scroll', ['$event.target']) onscroll(elem): void {
    const target = elem.target;
    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      this.userHasRead = true;
    }
  }

  constructor(public coreService: CoreService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.optInLabel = 'Sign up for email delivery. You\'ll receive the For Your ' + (this.subscriber.agencyCode === 'SEBB' ? 'Intercom' : 'Benefit') + ' newsletter and other general updates in your inbox. ';
  }

  submitEmailSubscriptionChange(value): void {
    if (value === false) {
      this.showEmailTermsAndConditions = false;
      this.subscriber.emailNotificationInd = false;
    } else if (value === true) {
      this.showEmailTermsAndConditions = true;
    } else if (value === 'submit') {
      if (this.emailForm.valid) {
        const emailUpdate = new EmailUpdate();
        emailUpdate.memberId = this.subscriber.memberId;
        emailUpdate.emailNotificationInd = this.subscriber.emailNotificationInd;
        emailUpdate.email = this.subscriber.emailAddress;
        this.showEmailTermsAndConditions = this.subscriber.emailNotificationInd;
        if (this.subscriber.emailNotificationInd) {
          this.emailSubscriptionUpdated.emit(emailUpdate);
          this.showEmailTermsAndConditions = false;
        }
      } else {
        this.notificationService.show({
          content: 'Please enter a valid email address and try again.',
          animation: { type: 'slide', duration: 200 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'error', icon: true },
          hideAfter: 2000
        });
      }
    }
  }
}
