import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import SpecialOpenEnrollmentActionLog from 'src/app/models/specialOpenEnrollmentActionLog';
import SOEForm from '../models/soeForm';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { replace } from 'lodash';
import * as dayjs from 'dayjs';
@Injectable({
  providedIn: 'root'
})
export class SoeService {
  public soeSubmitted = new BehaviorSubject<void>(null);

  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {}

  getSpecialOpenEnrollmentById(specialOpenEnrollmentId: string, memberId: string): Observable<SpecialOpenEnrollment> {
    return this.http.get<SpecialOpenEnrollment>(`${env.apiUrl}/Member/${memberId}/SpecialOpenEnrollment/${specialOpenEnrollmentId}`)
      .pipe(map(x => this.commonService.createObject(SpecialOpenEnrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getLog(specialOpenEnrollmentId: string, memberId: string): Observable<SpecialOpenEnrollmentActionLog[]> {
    return this.http.get<SpecialOpenEnrollmentActionLog[]>(`${env.apiUrl}/Member/${memberId}/SpecialOpenEnrollment/${specialOpenEnrollmentId}/log`)
      .pipe(map(x => this.commonService.createObjects(SpecialOpenEnrollmentActionLog, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  createSpecialOpenEnrollment(memberId: string, specialOpenEnrollment: SpecialOpenEnrollment): Observable<SpecialOpenEnrollment> {
    return this.http.post<SpecialOpenEnrollment>(`${env.apiUrl}/Member/${memberId}/SpecialOpenEnrollment`, specialOpenEnrollment)
      .pipe(map(x => this.commonService.createObject(SpecialOpenEnrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  updateSpecialOpenEnrollment(memberId: string, specialOpenEnrollment: SpecialOpenEnrollment): Observable<SpecialOpenEnrollment> {
    return this.http.put<SpecialOpenEnrollment>(`${env.apiUrl}/Member/${memberId}/SpecialOpenEnrollment/${specialOpenEnrollment.specialOpenEnrollmentId}`, specialOpenEnrollment)
      .pipe(map(x => this.commonService.createObject(SpecialOpenEnrollment, x)))
      .pipe(catchError(this.commonService.handleError));
  }

  getSpecialOpenEnrollmentsForOrganization(organizationId: string, onlyShowPending: boolean, gridQuery: string): Observable<GridDataResult> {
    //Replace dto properties
    const gridQueryResult = this.formatSOEGridQuery(gridQuery);
    gridQuery = gridQueryResult.gridQuery;
    const hasDocuments = gridQueryResult.hasDocuments;

    return this.http.get<GridDataResult>(`${env.apiUrl}/organization/${organizationId}/SpecialOpenEnrollment?pending=${onlyShowPending}${hasDocuments ? hasDocuments : ''}&${gridQuery}`)
      .pipe(map(x => {
        x.data = this.commonService.createObjects(SpecialOpenEnrollment, x.data);
        return x;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  getSpecialOpenEnrollmentsForHCA(onlyShowPending: boolean, gridQuery: string, selfPayOnly?: boolean): Observable<GridDataResult> {
    //Replace dto properties
    const gridQueryResult = this.formatSOEGridQuery(gridQuery);
    gridQuery = gridQueryResult.gridQuery;
    const hasDocuments = gridQueryResult.hasDocuments;

    return this.http.get<GridDataResult>(`${env.apiUrl}/hca/SpecialOpenEnrollment?pending=${onlyShowPending}${selfPayOnly ? '&selfPayOnly=true' : ''}${hasDocuments ? hasDocuments : ''}&${gridQuery}`)
      .pipe(map(x => {
        x.data = this.commonService.createObjects(SpecialOpenEnrollment, x.data);
        return x;
      }))
      .pipe(catchError(this.commonService.handleError));
  }

  formatSOEGridQuery(gridQuery: string) {
    //sort replacement
    gridQuery = gridQuery.replace("member.fullName-asc","member.lastName-asc~member.firstName-asc");
    gridQuery = gridQuery.replace("member.fullName-desc","member.lastName-desc~member.firstName-desc");
    gridQuery = replace(gridQuery, "expirationDate","eventDate");
    var hasDocuments = null;

    //filter replacement
    const expiratinDateFilter = 'ExpirationDate~eq~datetime';
    if (gridQuery.indexOf(expiratinDateFilter)>0) {
      const expDateFilterIndexStart = gridQuery.indexOf(expiratinDateFilter)+1+expiratinDateFilter.length;
      const expDateFilterIndexEnd = gridQuery.indexOf("'",expDateFilterIndexStart+1);
      const expDateFilterVal = gridQuery.substring(expDateFilterIndexStart,expDateFilterIndexEnd);
      const expDateVal = expDateFilterVal.indexOf("T")>0 ? new Date(expDateFilterVal.substring(0,expDateFilterVal.indexOf("T"))) : new Date(expDateFilterVal);
      gridQuery = gridQuery.replace(expiratinDateFilter + "'" + expDateFilterVal + "'", 
        expiratinDateFilter + "'" + dayjs(expDateVal).add(-59, 'day').format('YYYY-MM-DD') + "T00-00-00'");
    }
    gridQuery = replace(gridQuery, "ExpirationDate","eventDate");

    var hasDocTxt = "HasDocumentsText";
    if (gridQuery.indexOf(hasDocTxt)>0) { 
      if (gridQuery.indexOf(hasDocTxt + "~eq~'Yes'")>0 || gridQuery.indexOf(hasDocTxt + "~contains~'Yes'")>0) { 
        hasDocuments = "&hasDocuments=true";
      }
      if (gridQuery.indexOf(hasDocTxt + "~eq~'No'")>0 || gridQuery.indexOf(hasDocTxt + "~contains~'No'")>0) { 
        hasDocuments = "&hasDocuments=false";
      }
      const hasDocStart = gridQuery.indexOf("HasDocumentsText");
      const hasDocEnd = gridQuery.indexOf("'",gridQuery.indexOf("'",hasDocStart)+1);
      const hasDocVal = gridQuery.substring(hasDocStart,hasDocEnd+1);
      gridQuery = gridQuery.replace(hasDocVal, "");
    }

    //full name
    const nameFilterStart = gridQuery.indexOf('Member.FullName');
    if (nameFilterStart>0) {
      const nameMultiFilterStart = gridQuery.indexOf('(Member.FullName');
      var nameFilter = "";
      if (nameMultiFilterStart>0) {
        const nameFilterEnd = gridQuery.indexOf(")",nameMultiFilterStart);
        nameFilter = gridQuery.substring(nameMultiFilterStart,nameFilterEnd+1);
        if (nameFilter.indexOf('~and~')>0) {
          var nameFilters = nameFilter.replace("(","").replace(")","").split('~and~');
          var combinedNameFilter = "";
          for (var i=0;i<nameFilters.length;i++) {
            const firstNameFilter = replace(nameFilters[i], /Member.FullName/g,"member.firstName");
            const lastNameFilter = replace(nameFilters[i], /Member.FullName/g,"member.lastName");
            combinedNameFilter += "(" + firstNameFilter + '~or~' + lastNameFilter + ")";
            combinedNameFilter += i+1 == nameFilters.length ? "" : "~and~";
          }
          gridQuery = replace(gridQuery, nameFilter, combinedNameFilter);
        }
        else {
          const firstNameFilter = replace(nameFilter, /Member.FullName/g,"member.firstName");
          const lastNameFilter = replace(nameFilter, /Member.FullName/g,"member.lastName");
          const combinedNameFilter = "(" + firstNameFilter + '~or~' + lastNameFilter + ")";
          gridQuery = replace(gridQuery, nameFilter, combinedNameFilter);
        }
      }
      else {
        //single query
        const nameFilterFirst = gridQuery.indexOf("'",nameFilterStart);
        const nameFilterEnd = gridQuery.indexOf("'",nameFilterFirst+1);
        nameFilter = gridQuery.substring(nameFilterStart,nameFilterEnd+1);
        const firstNameFilter = replace(nameFilter, /Member.FullName/g,"member.firstName");
        const lastNameFilter = replace(nameFilter, /Member.FullName/g,"member.lastName");
        const combinedNameFilter = "(" + firstNameFilter + '~or~' + lastNameFilter + ")";
        gridQuery = replace(gridQuery, nameFilter, combinedNameFilter);
      }
      
    }
    gridQuery = replace(gridQuery, "~~", "");
    return { gridQuery: gridQuery, hasDocuments: hasDocuments };
  }

  cancelSpecialOpenEnrollment(soe: SpecialOpenEnrollment): Observable<SpecialOpenEnrollment[]> {
    return this.http.post<SpecialOpenEnrollment[]>(`${env.apiUrl}/Member/${soe.memberId}/SpecialOpenEnrollment/Cancel/${soe.specialOpenEnrollmentId}`, null)
      .pipe(catchError(this.commonService.handleError));
  }

  getRemovedDependents(soe: SpecialOpenEnrollment): Observable<string[]> {
    return this.http.get<string[]>(`${env.apiUrl}/Member/${soe.memberId}/SpecialOpenEnrollment/${soe.specialOpenEnrollmentId}/RemovedDeps`)
      .pipe(catchError(this.commonService.handleError));
  }

  getRemovedDependentsWithin60DayFlag(soe: SpecialOpenEnrollment): Observable<any> {
    return this.http.get<any>(`${env.apiUrl}/Member/${soe.memberId}/SpecialOpenEnrollment/${soe.specialOpenEnrollmentId}/Within60Days`)
      .pipe(catchError(this.commonService.handleError));
  }

  submitSOE() {
    this.soeSubmitted.next();
  }
}
