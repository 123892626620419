import DocumentType from './documentType';
import SystemUser from './user';

export default class Document {
  documentId: string;
  documentTypeId: string;
  documentType: DocumentType;
  documentBlob: any;
  documentName: string;
  documentDescription: string;
  createdDate: Date;
  createdBy: SystemUser;
  documentImageObject: {src: any, alt: string};
  documentContentType: string;
  showDocument: boolean;
  dateReceived: Date;
  modifiedBy: SystemUser;
  isCompleteInd ?: boolean;
  // front end only
  relationshipId ?: string;
  specialOpenEnrollmentId ?: string;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  fileExtension: string;
  isSOEDocument: boolean;
  specialOpenEnrollmentDocumentId: string;
  canDelete: boolean = true;

  constructor(document?) {
    if (document) {
      this.documentId = document.documentId;
      this.documentTypeId = document.documentTypeId;
      this.documentType = document.documentType ? new DocumentType(document.documentType) : null;
      this.documentName = document.documentName;
      this.documentDescription = document.documentDescription;
      this.documentBlob = document.documentBlob;
      this.createdDate = document.createdDate ? new Date(document.createdDate) : null;
      this.createdById = document.createdById || null;
      this.modifiedDate = document.modifiedDate || null;
      this.modifiedById = document.modifiedById || null;
      this.createdBy = document.createUser ? new SystemUser(document.createUser) : null;
      this.modifiedBy = document.modifiedBy ? new SystemUser(document.modifyUser) : null;
      this.dateReceived = document.dateReceived ? new Date(document.dateReceived) : null;
      if (document.isCompleteInd) {
        this.isCompleteInd = document.isCompleteInd;
      }
      this.showDocument = false;
      if (this.documentName && this.documentName.lastIndexOf('.')>0) {
        const extIndex = this.documentName.lastIndexOf('.') + 1;
        this.fileExtension = this.documentName.substr(extIndex, (this.documentName.length - extIndex));
      }
    }
  }
}
