import MemberMedicare from './memberMedicare';
import SelfPayMember from './selfPayMember';
import SelfPayOrigin from './selfPayOrigin';
import SelfPayDependentElections from './selfPayDependentElections'

export default class SelfPayElection extends SelfPayMember {
    selfPayId: string;
    selfPayOrigin: SelfPayOrigin;
    memberMedicare: MemberMedicare;
    tobaccoResponseId: boolean;
    tobaccoUseEffectiveDate: Date;
    medicalReasonId: string;
    dentalReasonId: string;
    dependentElections: SelfPayDependentElections[];
    medicalInd: boolean;
    dentalInd: boolean;
    ltdInd: boolean;
    basicInd: boolean;
    rlifeInd: boolean;
    medicalPlan: string;
    dentalPlan: string;
    ltdPlan: string;
    constructor(e?) {
      super(e);
      this.selfPayOrigin = e?.selfPayOrigin ? new SelfPayOrigin(e.selfPayOrigin) : new SelfPayOrigin();
      this.memberMedicare = e?.memberMedicare ? new MemberMedicare(e.memberMedicare) : new MemberMedicare();
      this.tobaccoUseEffectiveDate = e?.tobaccoUseEffectiveDate ? new Date(e.tobaccoUseEffectiveDate) : null;
      this.dependentElections = e?.dependentElections ? e.dependentElections.map((d) => new SelfPayDependentElections(d)) : [];
      if (e) {
        this.selfPayId = e.selfPayId;
        this.tobaccoResponseId = e.tobaccoResponseId;
        this.medicalInd = e.medicalInd;
        this.dentalInd = e.dentalInd;
        this.ltdInd = e.ltdInd;
        this.basicInd = e.basicInd;
        this.rlifeInd = e.rlifeInd;
        this.medicalPlan = e.medicalPlan;
        this.dentalPlan = e.dentalPlan;
        this.ltdPlan = e.ltdPlan;
        this.medicalReasonId = e.medicalReasonId;
        this.dentalReasonId = e.dentalReasonId;
      }
      
    }

}