import { CoreService } from './../../../../../../services/core.service';
import { NgForm } from '@angular/forms';
import { Component, ViewEncapsulation, OnInit, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { GridComponent, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { cloneDeep, find, orderBy } from 'lodash';
import SystemUser from 'src/app/models/user';
import UserOrganizationRole from 'src/app/models/userOrganizationRole';
import * as dayjs from 'dayjs';
import UserRole from 'src/app/models/userRole';
import UserAccessLevel from 'src/app/models/userAccessLevel';
import { faPlusCircle, faCalendar, faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { env } from 'src/env/development';
@Component({
  selector: 'admin-access-grid',
  templateUrl: 'adminAccess.grid.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})

export class AdminAccessGridComponent implements OnInit, OnChanges {
  icons = {
    faCalendar,
    faPlusCircle,
    faSave,
    faBan
  };
  @Input() perspaySystemUsers: SystemUser[] = [];
  @Input() filteredPerspay: SystemUser[] = [];
  @Input() filtered = false;
  @Input() lookups: {
    organizations: [],
    userRoles: [],
    userAccessLevels: []
  };
  @Input() gridColumns;
  @Input() selectable?: boolean;
  @Input() filterable = false;
  @Input() isHCAAdmin: boolean;
  state: State = {
    skip: 0,
    take: 20
  };
  @Input() gridData: GridDataResult;
  perspayRoleId: string;
  userAccessLevelAdminId: string;
  public now: Date = new Date(new Date().setHours(0, 0, 0, 0));
  env = env;
  @Output() pushGetData: EventEmitter<State> = new EventEmitter<State>();
  @Output() accessDataChange: EventEmitter<{role: UserOrganizationRole, userIndex: number, userRoleIndex: number}>
    = new EventEmitter<{role: UserOrganizationRole, userIndex: number, userRoleIndex: number}>();
  @Output() systemUserDataChange: EventEmitter<SystemUser> = new EventEmitter<SystemUser>();
  @ViewChild('kendoGrid') public kendoGrid: GridComponent;
  @ViewChild('accessForm') accessForm: NgForm;
  @Output() disassociate: EventEmitter<any> = new EventEmitter<any>();
  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.lookups) {
      this.perspayRoleId = find(this.lookups.userRoles, (ur: UserRole) => ur.userRoleName === 'Perspay').userRoleId;
      this.userAccessLevelAdminId = find(this.lookups.userAccessLevels, (ual: UserAccessLevel) => ual.userAccessLevelName === 'Admin').userAccessLevelId;
    }
  }

  reSetDataForGrid(): void {
    this.pushGetData.emit(this.state);
  }

  addAccessRole(dataItem: SystemUser): void {
    const newAccessRole = new UserOrganizationRole();
    newAccessRole.systemUserId = dataItem.systemUserId;
    newAccessRole.userRoleId = this.perspayRoleId;
    newAccessRole.userAccessLevelId = this.userAccessLevelAdminId;
    newAccessRole.effectiveStartDate = this.now;
    newAccessRole.effectiveEndDate = null;
    newAccessRole.systemUserAccessId = null;
    dataItem.perspayAdminRoles.push(newAccessRole);
  }

  removeAccess(role: UserOrganizationRole, userIndex: number, userRoleIndex: number, formContainer): void {
    role.effectiveEndDate = this.now;
    role.originalEffectiveEndDate = role.effectiveEndDate;
    this.emitUpdate(role, userIndex, userRoleIndex, formContainer);
  }

  emitUpdate(role: UserOrganizationRole, userIndex: number, userRoleIndex: number, formContainer): void {
    this.coreService.markFormControlsAsTouched(this.accessForm);
    if (!this.accessForm.valid) {
      return this.coreService.popMessage('Fields missing or invalid, please try again.', 'error', 2000, this.coreService.getInvalidFields(formContainer));
    }
    const updateObject = {
      role,
      userIndex,
      userRoleIndex,
    };
    this.accessDataChange.emit(updateObject);
  }

  saveUserHandler(dataItem: SystemUser): void {
    this.systemUserDataChange.emit(dataItem);
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.pushGetData.emit(this.state);
  }

}
