import MemberNote from 'src/app/models/memberNote';
import MemberType from 'src/app/models/memberType';
import Milestone from 'src/app/models/milestone';
import MemberFlag from 'src/app/models/memberFlag';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
import { map, some, every, filter, maxBy, find, minBy, sortBy, flatten, cloneDeep } from 'lodash';
import Member from './member';
import SpecialOpenEnrollment from './specialOpenEnrollment';
import Enrollment from './enrollment';
import MemberWaiver from './memberWaiver';
import EnrollmentPeriod from './enrollmentPeriod';
import Organization from './organization';
import PlanType from './planType';
import MemberAddress from './memberAddress';
import SelfPayType from './selfPayType';
import SelfPay from './selfPay';
import MemberMedicare from './memberMedicare';
import SelfPayOrigin from './selfPayOrigin';
import { env } from 'src/env/development';
export default class Subscriber extends Member {
  members: Member[];
  activeSOE: SpecialOpenEnrollment;
  subscriberMemberId: string;
  enrollmentPeriods: EnrollmentPeriod[];
  allEnrollmentPeriods: EnrollmentPeriod[];
  organization: Organization;
  salary: number;
  homePhoneNumber: string;
  workPhoneNumber: string;
  cellPhoneNumber: string;
  mailingAddress: MemberAddress;
  isRepresentedInd: boolean;
  representedDate: Date;
  memberTypeId: string;
  availableSelfPayTypes: SelfPayType[];
  memberNotes: MemberNote[];
  selfPays: SelfPay[];
  accountLevelEnrollments: Enrollment[];
  // to retrigger after changes:
  refetch = false;
  memberSelfPayOrigin?: SelfPayOrigin;
  dualEnrolledInOtherAgency = false;
  isPortCommissioner = false;
  isEligibleForLTD = true;
  wellnessFlag: MemberFlag;
  isSelfPay = false;
  isLWOP = false;
  newlyIncomplete = false;
  currentlyNewlyEligible = false;
  hasSupplementalLTD = false;
  allAvailableSelfPayTypes: SelfPayType[];
  ineligibleForLTDFlag: MemberFlag;
  mailStop: string;
  agency: string;
  isActive?: boolean;
  constructor(subscriber?) {
    super(subscriber);
    if (subscriber) {
      this.selfPays = map(subscriber.selfPays, (selfPay) => {
          selfPay.member.availableSelfPayTypes ??= subscriber.availableSelfPayTypes;
          const sp = new SelfPay(selfPay);
          return sp;
        });
      this.members = map(subscriber.members || [], (m) => new Member(m));
      this.subscriberMemberId = subscriber.isSubscriberInd ? subscriber.memberId : subscriber.subscriberMemberId;

      const enrollmentPeriods = map(subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) => new EnrollmentPeriod(ep));
      this.enrollmentPeriods = this.getEffectiveEnrollmentPeriods(enrollmentPeriods);
      this.allEnrollmentPeriods = enrollmentPeriods;

      // if they have an SOE that hasn't been effected, get all those too
      const soesWithoutChanges = find(
        enrollmentPeriods,
        (ep: EnrollmentPeriod) => ep.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SOE' && !some(ep.milestones, (mi: Milestone) => mi.isComplete)
      );
      this.enrollmentPeriods = soesWithoutChanges ? this.enrollmentPeriods.concat(soesWithoutChanges) : this.enrollmentPeriods;
      this.organization = new Organization(subscriber.organization);
      this.salary = subscriber.salary;
      this.homePhoneNumber = subscriber.homePhoneNumber?.phoneNumber;
      this.workPhoneNumber = subscriber.workPhoneNumber?.phoneNumber;
      this.cellPhoneNumber = subscriber.cellPhoneNumber?.phoneNumber;
      this.isRepresentedInd = subscriber.representedInd;
      this.representedDate = subscriber.representedDate ? new Date(subscriber.representedDate) : null;
      this.memberTypeId = subscriber.memberTypeId === env.emptyGUIDString ? null : subscriber.memberTypeId;
      this.memberNotes = subscriber.memberNotes ? map(subscriber.memberNotes, (m) => new MemberNote(m)) : [];
      this.memberMedicare = subscriber.memberMedicare ? new MemberMedicare(subscriber.memberMedicare) : null;
      this.accountLevelEnrollments = subscriber.accountLevelEnrollments ? subscriber.accountLevelEnrollments.map((e) => new Enrollment(e)) : [];
      this.dualEnrolledInOtherAgency = subscriber.dualEnrolledInOtherAgency;
      this.isPortCommissioner = subscriber.isPortCommissioner;
      this.mailStop = subscriber.mailStop;
      this.agency = subscriber?.organization?.agency?.agencyCode || 'PEBB';
      let currentSelfPayOrigin = maxBy(subscriber.memberSelfPayOrigins?.filter((spo) => !spo.agencyEffectiveEndDate || dayjs(spo.agencyEffectiveEndDate).isAfter(dayjs())), spo => spo.agencyEffectiveStartDate);
      if (!currentSelfPayOrigin) {
        currentSelfPayOrigin = maxBy(subscriber.memberSelfPayOrigins, (spo: SelfPayOrigin) => spo.agencyEffectiveEndDate);
      }
      this.memberSelfPayOrigin = currentSelfPayOrigin ? new SelfPayOrigin(currentSelfPayOrigin) : null;
      if (this.memberSelfPayOrigin && this.memberSelfPayOrigin.lossOfEligibilityReason) {
        this.lossOfEligibilityReason = this.memberSelfPayOrigin.lossOfEligibilityReason;
      }
      this.accountLevelEnrollments = subscriber.accountLevelEnrollments ? map(subscriber.accountLevelEnrollments, (ale) => new Enrollment(ale)) : [];
      if (!this.memberType || (this.lossOfEligibilityDate && this.memberSelfPayOrigin?.agencyEffectiveStartDate && dayjs(this.memberSelfPayOrigin?.agencyEffectiveStartDate).isBefore(dayjs()))) {
        this.memberType = this.memberSelfPayOrigin?.memberType ? new MemberType(this.memberSelfPayOrigin.memberType) : null;
      }
      this.availableSelfPayTypes = this.getAvailableSelfPayTypes(subscriber.availableSelfPayTypes);
      this.allAvailableSelfPayTypes = subscriber.availableSelfPayTypes;
      this.wellnessFlag = subscriber.wellnessFlag ? new MemberFlag(subscriber.wellnessFlag) : null;
      this.ineligibleForLTDFlag = subscriber.ineligibleForLTDFlag ? new MemberFlag(subscriber.ineligibleForLTDFlag) : null;
      this.setIsEligibleForLTD(dayjs());
      this.setNavigationProps();
    }
  }

  setIsEligibleForLTD(effectiveDate): void {
    if (this.isSebb && this.memberType?.memberTypeCode) {
      //SEBB ltd eligibility is based on member type
      this.isEligibleForLTD = this.memberType.memberTypeCode === 'Z';
    }
    else {
      //PEBB ltd eligibility is based on member flag
      if (this.ineligibleForLTDFlag) {
        this.isEligibleForLTD = !effectiveDate.isBetween(this.ineligibleForLTDFlag?.effectiveStartDate, this.ineligibleForLTDFlag?.effectiveEndDate || effectiveDate.add(1, 'day'), null, '[]') ||
          dayjs(this.ineligibleForLTDFlag?.effectiveStartDate).isSame(dayjs(this.ineligibleForLTDFlag?.effectiveEndDate));
      }
    }
  }

  getFamilyCompositionForDate(coverageEffectiveDate: Date, planTypeId: string): string {
    const spouses = filter(this.members, (m: Member) => {
      return !m.isSubscriberInd && m.relationshipToSubscriber && m.relationshipToSubscriber.relationshipType && m.relationshipToSubscriber.relationshipType.relationshipTypeCode === 'S';
    });
    const hasASpouseEnrolledOnThisDate = some(spouses, (m: Member) => {
      return some(m.enrollments, (e: Enrollment) => {
        return (
          e.plan.planTypeId === planTypeId &&
          dayjs(coverageEffectiveDate).isSameOrAfter(e.effectiveStartDate, 'day') &&
          (!e.effectiveEndDate || dayjs(coverageEffectiveDate).isSameOrBefore(e.effectiveEndDate, 'day'))
        );
      });
    });
    const hasASpouseWaiverForThisDate = every(spouses, (m: Member) => {
      return some(m.memberWaivers, (e: MemberWaiver) => {
        return (
          e.planTypeId === planTypeId &&
          dayjs(coverageEffectiveDate).isSameOrAfter(e.effectiveStartDate, 'day') &&
          (!e.effectiveEndDate || dayjs(coverageEffectiveDate).isSameOrBefore(e.effectiveEndDate, 'day'))
        );
      });
    });

    const spouseIsEnrolled = hasASpouseEnrolledOnThisDate && !hasASpouseWaiverForThisDate;

    const otherDependents = filter(this.members, (m: Member) => {
      return !m.isSubscriberInd && m.relationshipToSubscriber && m.relationshipToSubscriber.relationshipType && m.relationshipToSubscriber.relationshipType.relationshipTypeCode !== 'S';
    });

    const hasAnyOtherDependent = some(otherDependents, (m: Member) => {
      return some(m.enrollments, (e: Enrollment) => {
        return (
          e.plan.planTypeId === planTypeId &&
          dayjs(coverageEffectiveDate).isSameOrAfter(e.effectiveStartDate, 'day') &&
          (!e.effectiveEndDate || dayjs(coverageEffectiveDate).isSameOrBefore(e.effectiveEndDate, 'day'))
        );
      });
    });

    const hasAllDependentsWaived = every(otherDependents, (m: Member) => {
      return some(m.memberWaivers, (e: MemberWaiver) => {
        return (
          e.planTypeId === planTypeId &&
          dayjs(coverageEffectiveDate).isSameOrAfter(e.effectiveStartDate, 'day') &&
          (!e.effectiveEndDate || dayjs(coverageEffectiveDate).isSameOrBefore(e.effectiveEndDate, 'day'))
        );
      });
    });
    const otherDependentsEnrolled = hasAnyOtherDependent && !hasAllDependentsWaived;
    if (spouseIsEnrolled) {
      return otherDependentsEnrolled ? 'SSC' : 'SS';
    } else {
      return otherDependentsEnrolled ? 'SC' : 'SO';
    }
  }

  getAvailableSelfPayTypes(selfPayTypes: SelfPayType[]): SelfPayType[] {
    // OE added in component
    if (selfPayTypes) {
      let outputSelfPayTypes = [];
      // ded
      if (this.lossOfEligibilityReason?.reasonCode === '35') {
        return [];
      }
      // everyone else gets retiree
      outputSelfPayTypes.push(selfPayTypes.filter((s: SelfPayType) => s.selfPayTypeCode === 'R'));
      const isSelfPayTermed = this.memberSelfPayOrigin?.agencyEffectiveEndDate != null && this.memberSelfPayOrigin?.agencyEffectiveEndDate >= new Date();
      // if not termed yet or termed and lossOfeligibilityReason in cobraReasons, give COBRA initial
      if (((!this.lossOfEligibilityReason?.reasonCode && !this.memberSelfPayOrigin?.agencyEffectiveStartDate) 
        || env.cobraReasons.includes(this.lossOfEligibilityReason?.reasonCode)) && !isSelfPayTermed) {
        outputSelfPayTypes.push(selfPayTypes.filter((s: SelfPayType) => s.selfPayTypeCode === 'C'));
      }

      // if termed and lossOfeligibilityReason in lwopReasons, give LWOP initial
      if (((!this.lossOfEligibilityReason?.reasonCode && !this.memberSelfPayOrigin?.agencyEffectiveStartDate) 
        || env.unpaidReasons.includes(this.lossOfEligibilityReason?.reasonCode)) && !isSelfPayTermed) {
        outputSelfPayTypes.push(selfPayTypes.filter((s: SelfPayType) => s.selfPayTypeCode === 'U'));
      }

      // fresh adds have no terms, so give initial by member type
      const memberTypeCode = this.memberType?.memberTypeCode;
      if (memberTypeCode){
        if (env.cobraMemberTypes.includes(memberTypeCode)) {
          outputSelfPayTypes = selfPayTypes.filter((s: SelfPayType) => s.selfPayTypeCode === 'R' || s.selfPayTypeCode === 'C' || s.selfPayTypeCode === 'OEC' ||
            s.selfPayTypeCode === 'SOEC' || s.selfPayTypeCode === 'SOE');
        } else if (env.unpaidLeaveMemberTypes.includes(memberTypeCode)) {
          outputSelfPayTypes = selfPayTypes.filter((s: SelfPayType) => s.selfPayTypeCode === 'R' ||  s.selfPayTypeCode === 'U' || s.selfPayTypeCode === 'OEU' ||
            s.selfPayTypeCode === 'SOEU' || s.selfPayTypeCode === 'SOE' || s.selfPayTypeCode === 'C');
        } else if (env.retireeMemberTypes.includes(memberTypeCode)) {
          outputSelfPayTypes = selfPayTypes.filter((s: SelfPayType) => s.selfPayTypeCode === 'R' || s.selfPayTypeCode === 'OER' || s.selfPayTypeCode === 'SOER' ||
            s.selfPayTypeCode === 'SOE' || s.selfPayTypeCode === 'C');
        }
        outputSelfPayTypes.push(selfPayTypes.filter((s: SelfPayType) => s.selfPayTypeCode === 'SOEA')); //change of address
      }
      let spTypes = flatten(outputSelfPayTypes);
      // check for exempt term reasons
      if (env.selfpayExemptReasonCodes.includes(this.lossOfEligibilityReason?.reasonCode) 
       || (this.memberSelfPayOrigin?.cobraSelfPayEndDate && this.memberSelfPayOrigin?.cobraSelfPayEndDate <= new Date())
       ) {
        spTypes = spTypes.filter((t: SelfPayType) => t.selfPayTypeCode !== 'C');
      }
      if(!some(this.allEnrollmentPeriods, (ep : EnrollmentPeriod) => ep.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'OE' && dayjs().isBetween(dayjs(ep.effectiveStartDate).startOf('day'), dayjs(ep.effectiveEndDate).endOf('day')))){
        spTypes = spTypes.filter((sp: SelfPayType) => !env.oeSelfpayTypeCodes?.includes(sp.selfPayTypeCode));
      }
      return spTypes;
    }
  }

  private periodCompleted(ep: EnrollmentPeriod): boolean {
    return some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && mi.isComplete);
  }

  getEffectiveEnrollmentPeriods(eps: EnrollmentPeriod[]): EnrollmentPeriod[] {
    const map = {
      'NE': 1,    // Newly Eligible
      'OE': 2,    // Open Enrollment
      'SOE': 3,   // Special Open Enrollment
      'SPE': 4,   // Self Pay Enrollment
      'TE': 5     // Transfer Event
    };

    // Sort enrollment periods by the date the enrollment period starts, then by the type
    // of enrollment period if more than one start on the same day (e.g., NE and OE -- show NE first)
    const sortedEps = sortBy(eps, 
      [
        'effectiveStartDate',
        (ep: EnrollmentPeriod) => map[ep.enrollmentPeriodType.enrollmentPeriodTypeCode]
      ]);

    // Remove any EP where the previous EP is not yet completed
    // Hide any items where all previous items are not yet completed
    let myEps = sortedEps.filter((ep: EnrollmentPeriod, index) => {
      if (index === 0) {
        // First is always visible (nothing previous to check against ...)
        return true;
      }

      // In the scenario where we have NE and then OE, always show the OE (it gets disabled in the UI)
      if (sortedEps.length === 2 && ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Open Enrollment') {
        return true;
      }

      const previousEps = sortedEps.slice(0, index);
      return every(previousEps, (p) => this.periodCompleted(p));
    });

    // Remove any EP where a later EP has been worked (any milestone completed), exclude transfer events
    myEps = sortedEps.filter((ep, index, array) => {
      const laterEps = array.slice(index + 1);
      return (!some(laterEps, (item) => some(item.milestones, (m) => m.completedDate))) || ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Transfer Event';
    });

    // Remove any EP where any previous EP has been worked but not yet finished
    myEps = myEps.filter((ep: EnrollmentPeriod, index, array) => {
      // In the scenario where we have NE and then OE, always show the OE (it gets disabled in the UI)
      if (myEps.length === 2 && index === 1 && ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Open Enrollment') {
        return true;
      }

      const previousEps = array.slice(0, index);
      const previousEpsThatWereWorkedOn = filter(previousEps, (item) => some(item.milestones, (m) => m.completedDate));
      if (previousEpsThatWereWorkedOn.length) {
        return every(previousEpsThatWereWorkedOn, (item) => this.periodCompleted(item));
      }

      return true;
    });

    return myEps;

    // // the one with the most recent milestone completion by date,
    // // OR if EP has confirmation completion for milestone immediately preceeding
    // // AND there is another EP w/ coverageEffDate after, that one.
    // let maxEPByCompletedMilestone = maxBy(eps, (e: EnrollmentPeriod) => maxBy(e.milestones, (m: Milestone) => m.completedDate));

    // // if no maxEP they're brand new probably, grab nearest by coverageEffDate
    // if (!maxEPByCompletedMilestone) {
    //   maxEPByCompletedMilestone = minBy(eps, (e: EnrollmentPeriod) => e.coverageEffectiveStartDate);
    // }

    // if (maxEPByCompletedMilestone &&
    //   some(maxEPByCompletedMilestone.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && mi.isComplete) &&
    //   some(eps, (e: EnrollmentPeriod) => e.coverageEffectiveStartDate > maxEPByCompletedMilestone?.coverageEffectiveStartDate)) {
    //   const closestNextPeriod = minBy(filter(eps, (ep) => ep.coverageEffectiveStartDate > maxEPByCompletedMilestone?.coverageEffectiveStartDate), 'coverageEffectiveStartDate');

    //   if (some(closestNextPeriod.milestones, (mi: Milestone) => mi.isComplete )) {
    //     return [closestNextPeriod];
    //   } else {
    //     // still have newly as long as no completions in OE
    //     return [maxEPByCompletedMilestone, closestNextPeriod];
    //   }

    //   // return [minBy(filter(eps, (ep) => ep.coverageEffectiveStartDate > maxEPByCompletedMilestone?.coverageEffectiveStartDate), 'coverageEffectiveStartDate')]
    // } else {
    //   return maxEPByCompletedMilestone ? [maxEPByCompletedMilestone] : [];
    // }
  }

  setNavigationProps(): void {
    // set properties needed for tab/dashboard navigation
    const selfPayMemberTypeCodes = env.unpaidLeaveMemberTypes.concat(env.retireeMemberTypes).concat(env.cobraMemberTypes);
    this.isSelfPay = selfPayMemberTypeCodes.indexOf(this.memberType?.memberTypeCode) > -1;
    const lwopMemberTypeCodes = env.unpaidLeaveMemberTypes;
    this.isLWOP = lwopMemberTypeCodes.indexOf(this.memberType?.memberTypeCode) > -1;
    if (this.memberId) {
      this.newlyIncomplete = some(
        this.enrollmentPeriods,
        (ep: EnrollmentPeriod) => ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible' && some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && !mi.isComplete)
      );
    } else {
      this.newlyIncomplete = some(
        this.enrollmentPeriods,
        (ep: EnrollmentPeriod) =>
          (ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible' && some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && !mi.isComplete)) ||
          // self pay - no NE, SPE exists but no completion
          (!some(this.enrollmentPeriods, (ep1) => ep1.enrollmentPeriodType?.enrollmentPeriodTypeName === 'Newly Eligible') &&
            ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'SPE' &&
            some(ep.milestones, (mi: Milestone) => mi.milestoneName === 'Confirmation' && !mi.isComplete))
      );
    }
    this.currentlyNewlyEligible = some(this.enrollmentPeriods, (ep: EnrollmentPeriod) => ep.isCurrentlyActive && ep.enrollmentPeriodType.enrollmentPeriodTypeName === 'Newly Eligible');
    this.hasSupplementalLTD = !(this.isSelfPay && !this.isLWOP) && !this.isMedicalOnlyOrganization && (!this.newlyIncomplete || !this.currentlyNewlyEligible);
  }

  getSupplementalEnrollment(enrollmentPeriod: EnrollmentPeriod, checkMilestone: boolean, supplementalMilestone: Milestone, useCurrent: boolean = false): Enrollment {
    // find the selected effective supplemental LTD option, if the subscriber opts out this will return null
    let supplementalEnrollment: Enrollment;
    supplementalEnrollment = find(
      this.enrollments,
      (e: Enrollment) => e.plan.planType.planTypeCode === '5' &&
        (
          (useCurrent && dayjs().isBetween(e.effectiveStartDate, e.effectiveEndDate, null, '[]')) ||
          (e.specialOpenEnrollmentId ?? e.openEnrollmentId) === enrollmentPeriod.enrollmentPeriodId
        )
    );

    // Some scenarios to look for:
    // 1) If no enrollment found and milestone is NOT completed, then pull in whatever is currently effective
    // 2) If not enrollment found and mileston IS completed, this will usually indicate a "no coverage" selection
    //    and we need to leave things as null (otherwise the system defaults to 60%).  However, NE doesn't save
    //    an enrollment period ID in the DB, so we need an extra NE check for something that starts on the same coverage
    //    date as NE
    const isNEOrOE = (enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'NE' ||
      enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'OE' ||
      enrollmentPeriod?.enrollmentPeriodType?.enrollmentPeriodTypeCode === 'SPE');
    if (!supplementalEnrollment && (isNEOrOE || (!supplementalMilestone?.completedDate && checkMilestone))) {
      supplementalEnrollment = find(
        this.enrollments,
        (e: Enrollment) => e.plan.planType.planTypeCode === '5' && e.effectiveStartDate
          && dayjs(e.effectiveStartDate).isSameOrBefore(enrollmentPeriod.coverageEffectiveStartDate)
          && (e.effectiveEndDate === null || dayjs(e.effectiveEndDate).isAfter(enrollmentPeriod.coverageEffectiveStartDate))
      );
    }

    return supplementalEnrollment;
  }

  getAccountLevelCoverageByPlanTypeAndDate(planTypeName: string, coverageEffectiveDate: Date): Enrollment {
    return (
      find(
        this.accountLevelEnrollments,
        (e: Enrollment) =>
          e.plan.planType.planTypeName === planTypeName &&
          dayjs(new Date(e.effectiveStartDate.getFullYear(), e.effectiveStartDate.getMonth(), 1)).isSameOrBefore(dayjs(coverageEffectiveDate), 'day') &&
          (!e.effectiveEndDate || dayjs(e.effectiveEndDate).isAfter(dayjs(coverageEffectiveDate), 'day')) &&
          e.plan?.planCode !== env.dentalWaivePlanCode
      ) || null
    );
  }

  //returns true if there is at least one self pay period
  hasSelfPayPeriod() {
    return some(
      this.enrollmentPeriods,
      (ep: EnrollmentPeriod) => ep.enrollmentPeriodType.enrollmentPeriodTypeCode === 'SPE'
    );
  }
}
