import { PendingModalComponent } from './../../components/pendingModal/pendingModal.component';
import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private modalService: NgbModal){}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component?.canDeactivate() ?
      true :
      this.openConfirmDialog();
  }

  openConfirmDialog(): any {
    return this.modalService.open(PendingModalComponent).result.then((r: boolean) => {
      return r;
    }, (reason) => {
      return false;
    });
  }
}
