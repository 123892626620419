<div *ngIf="!isSOE && !isSP" fxLayout="column" class="fxGap-large">
  <h2 class="prompt-question">Do you have {{dependents && dependents.length >= 1 ? 'additional' : ''}} dependents to add to your account?</h2>
  <yes-no
    name="hasDependentsToAdd"
    style="padding-left: 75px; padding-right: 75px;"
    orientation="horizontal"
    [disabled]="soe && !soe?.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionEnrollDependentsCode)"
    [(ngModel)]="hasDependentsToAdd"
    (ngModelChange)="dependentsQuestionAnswered.emit(hasDependentsToAdd)"
    ></yes-no>
    <h4 *ngIf="soe && !soe?.specialOpenEnrollmentType?.flatAvailableActions?.includes(env.availableActionEnrollDependentsCode)">
      The special open enrollment event you have selected does not allow for enrolling dependents into coverage. Click “Next” to proceed. 
    </h4>
</div>
<!-- SOE -->
<div *ngIf="isSOE || isSP" fxLayout="column" class="fxGap-large">
  <h2 *ngIf="!newDependents?.length">No additional dependents added</h2>
  <h2 *ngIf="newDependents?.length">Dependents added on enrollment change form:</h2>
  <div *ngIf="newDependents?.length" class="fxGap-small" fxLayout="column">
    <div  *ngFor="let dep of newDependents" fxLayoutAlign="space-around center" class="card" style="height: 50px; border: 1px solid black;">
      <b>{{dep.lastName}}, {{dep.firstName}}</b>
      <span>{{dep.relationshipTypeName}}</span>
      <button (click)="importDependent(dep)" class="btn btn-secondary">Import this dependent</button>
    </div>
  </div>
  <h3 class="prompt-question">Add a dependent not on electronic form?</h3>
  <yes-no
    name="hasDependentsToAdd"
    style="margin-bottom: 30px; padding-left: 75px; padding-right: 75px;"
    [(ngModel)]="hasDependentsToAdd"
    (change)="dependentsQuestionAnswered.emit(hasDependentsToAdd)"
    ></yes-no>
</div>
