<div fxLayout="row wrap">
    <div fxLayout.gt-sm="column" class="ie-height-xs" fxLayout="row wrap" fxFlex.gt-sm="45" fxFlex="45" style="margin-bottom:15px;" >
      <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
        <label for="subscriberName" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">Subscriber:</label>
        <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberName" name="subscriberName" value="{{soe.member.fullName}}">
      </div>
      <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
        <label for="subscriberSSN" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label" >SSN:</label>  
        <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberSSN" name="subscriberSSN" value="{{soe.member.socialSecurityNumber}}">
      </div>
      <div fxLayout.gt-sm="row" fxLayout="column" fxFlex="100">
        <label for="subscriberBirthDate" fxFlex.gt-sm="35" fxFlex="100" class="col-form-label hca-control-label">DOB:</label>
        <input type="text" readonly fxFlex.gt-sm="65" fxFlex="100" class="form-control-plaintext" style="text-align: center;" ngStyle.gt-sm="text-align:left" id="subscriberBirthDate" name="subscriberBirthDate" value="{{soe.member.birthDate | date: 'MM/dd/yyyy'}}">
      </div>
    </div>
    <div fxLayout.gt-sm="column" class="ie-height-xs" fxLayout="row wrap" fxFlex.gt-sm="45" fxFlex="45" style="margin-bottom:15px;" >
      <h5 style="align-self:center" class="mobile-text-centered mgtp-small">Verification documents</h5>
      <div *ngIf="!soe?.documents || soe?.documents?.length==0" style="align-self:center" class="mobile-text-centered mgtp-small">
        No Documents Uploaded
      </div>
      <generic-document-viewer *ngIf="soe && soe.documents" fxLayout.gt-sm="column" fxLayout="row wrap" fxFlex="100" [(documents)]="soe.documents"></generic-document-viewer>
    </div>
  </div>