import * as dayjs from 'dayjs';
import Plan from './plan';
import Reason from './reason';
import EnrollmentPeriod from './enrollmentPeriod';

export default class Enrollment {
  private planTypeCodeMap =
  {
    1: 1,
    2: 2,
    6: 3,
    3: 4,
    10: 5,
    5: 6,
    4: 7
  };
  enrollmentId: string;
  subscriberMemberId: string;
  memberId: string;
  planId: string;
  enrollmentReasonId: string;
  terminationReasonId: string;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  preTaxInd: boolean;
  createdDate: Date;
  createdById: string;
  modifiedDate: Date;
  modifiedById: string;
  plan: Plan;
  specialOpenEnrollmentId: string;
  openEnrollmentId: string;
  enrollmentReason: Reason;
  planTypeId ?: string;
  divorceWithin60Days ?: boolean;
  selfPayId: string;
  eligibilityId: string;
  enrollmentPeriod: EnrollmentPeriod;

  constructor(enrollment?) {
    if (enrollment) {
      this.enrollmentId = enrollment.enrollmentId || null;
      this.subscriberMemberId = enrollment.subscriberMemberId;
      this.memberId = enrollment.memberId;
      this.planId = enrollment.planId;
      this.enrollmentReasonId = enrollment.enrollmentReasonId || null;
      this.enrollmentReason = enrollment && enrollment.enrollmentReason ? new Reason(enrollment.enrollmentReason) : null;
      this.terminationReasonId = enrollment.terminationReasonId || null;
      this.effectiveStartDate = enrollment.effectiveStartDate ? new Date(enrollment.effectiveStartDate) : null;
      this.effectiveEndDate = enrollment.effectiveEndDate ? new Date(enrollment.effectiveEndDate) : null;
      this.preTaxInd = enrollment.preTaxInd;
      this.createdDate = enrollment.createdDate || null;
      this.createdById = enrollment.createdById || null;
      this.modifiedDate = enrollment.modifiedDate || null;
      this.modifiedById = enrollment.modifiedById || null;
      this.specialOpenEnrollmentId = enrollment.specialOpenEnrollmentId || null;
      this.openEnrollmentId = enrollment.openEnrollmentId || null;
      this.divorceWithin60Days = enrollment.divorceWithin60Days;
      this.selfPayId = enrollment.selfPayId;
      this.eligibilityId = enrollment.eligibilityId;
      this.enrollmentPeriod = enrollment.enrollmentPeriod ? new EnrollmentPeriod(enrollment.enrollmentPeriod) : null;
    }

    this.plan = new Plan(enrollment ? enrollment.plan : null);
    this.planTypeId = this.plan?.planTypeId;
  }

  isActive(activeForDate?: Date): boolean {
    // If not supplied, use current date
    activeForDate = activeForDate ?? new Date();

    return (dayjs(activeForDate).isSameOrAfter(dayjs(this.effectiveStartDate).startOf('day')) && !this.effectiveEndDate) ||
      (this.effectiveEndDate && dayjs(activeForDate).isBetween(dayjs(this.effectiveStartDate).startOf('day'), dayjs(this.effectiveEndDate).endOf('day')));
  }

  get planSortOrder(): number {
    if (this.plan?.planType?.planTypeCode) {
      return this.planTypeCodeMap[this.plan?.planType?.planTypeCode];
    }

    return 0;
  }
}
