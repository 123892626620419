import Module from 'src/app/models/module';
import { map, forEach } from 'lodash';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
export default class Message {
  genericMessageId: string;
  messageText: string;
  activeDate: Date;
  inactiveDate: Date;
  type: string;
  modules: Module[];
  moduleObject ? = {};
  isActive ? = false;
  constructor(messageObject?: any) {
    if (messageObject) {
      this.genericMessageId = messageObject.genericMessageId;
      this.messageText = messageObject.messageText;
      this.activeDate = messageObject.activeDate ? new Date(messageObject.activeDate) : null;
      this.inactiveDate = messageObject.inactiveDate ? new Date(messageObject.inactiveDate) : null;
      this.modules = map(messageObject.modules, (m) => new Module(m));
      forEach(this.modules, (mo: Module) => {
        this.moduleObject[mo.moduleName] = true;
      });
      this.isActive = dayjs().isBetween(this.activeDate, this.inactiveDate || dayjs().add(1, 'day'));
    }
  }
}
