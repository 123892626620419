export default class RelationshipVerificationStatus {
  relationshipVerificationStatusId: string;
  relationshipVerificationStatusCode: string;
  relationshipVerificationStatusName: string;
  approvedInd: boolean;
  deniedInd: boolean;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(relationshipVerificationStatus?) {
    if (relationshipVerificationStatus) {
      this.relationshipVerificationStatusId = relationshipVerificationStatus.relationshipVerificationStatusId;
      this.relationshipVerificationStatusCode = relationshipVerificationStatus.relationshipVerificationStatusCode;
      this.relationshipVerificationStatusName = relationshipVerificationStatus.relationshipVerificationStatusName;
      this.approvedInd = relationshipVerificationStatus.approvedInd;
      this.deniedInd = relationshipVerificationStatus.deniedInd;
      this.obsoleteFlag = relationshipVerificationStatus.obsoleteFlag;
      this.obsoleteDate = relationshipVerificationStatus.obsoleteDate ? new Date(relationshipVerificationStatus.obsoleteDate) : null;
    }
  }


}
