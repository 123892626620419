export default class BenefitSetup {
  benefitSetupId: string;
  benefitSetupCode: string;
  benefitSetupName: string;
  obsoleteFlag: boolean;
  obsoleteDate: Date;

  constructor(benefitSetup?) {
    if (benefitSetup) {
      this.benefitSetupId = benefitSetup.benefitSetupId;
      this.benefitSetupCode = benefitSetup.benefitSetupCode;
      this.benefitSetupName = benefitSetup.benefitSetupName;
      this.obsoleteFlag = benefitSetup.obsoleteFlag;
      this.obsoleteDate = benefitSetup.obsoleteDate ? new Date(benefitSetup.obsoleteDate) : null;
    }
  }
}
