import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { find } from 'lodash';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import TermsOfUse from 'src/app/models/termsOfUse';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.ApplicationSetting, LookupType.TermsOfUseActionType)
@Component({
  selector: 'terms-and-conditions',
  templateUrl: 'termsAndConditions.component.html'
})
export class TermsAndConditionsComponent implements OnInit {
    @Input() agencyCode: string = "";
    @Input() submitTermsTrueValue: any;
    @Output() submitTerms: EventEmitter<boolean> = new EventEmitter();
    tou: TermsOfUse;
    schoolPublicTxt: string = "Public";

    constructor( private route: ActivatedRoute) {}

    ngOnInit(): void {
      this.route.data.pipe(untilDestroyed(this)).subscribe((d) => {
        const now = new Date();
        this.tou = find(d.termsOfUse, (t) => t.effectiveStartDate.getTime() <= now.getTime() && (!t.effectiveEndDate || t.effectiveEndDate.getTime() >= now.getTime())) || d.termsOfUse[0];
      });
    }

    submitTermsChange(submitValue) {
        this.submitTerms.emit(submitValue);
    }

}
