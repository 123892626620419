// handles enrollment periods AND milestone completion

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { env } from '../../env/development';
import { CommonService } from './common.service';
import EnrollmentPeriod from '../models/enrollmentPeriod';
import Milestone from '../models/milestone';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentPeriodService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private coreService: CoreService
  ) {}

  getEnrollmentPeriodById(memberId: string, enrollmentPeriodId: string): Observable<EnrollmentPeriod> {
    return this.http.get<EnrollmentPeriod>(`${env.apiUrl}/subscriber/${memberId}/enrollmentPeriod/${enrollmentPeriodId}`)
    .pipe(map(x => this.commonService.createObject(EnrollmentPeriod, x)))
    .pipe(catchError(this.commonService.handleError));
  }

  createMilestoneCompletion(memberId: string, milestoneId: string, enrollmentPeriodId: string): Observable<EnrollmentPeriod> {
    return this.http.post<Milestone>(`${env.apiUrl}/member/${memberId}/milestone/${milestoneId}/enrollmentPeriod/${enrollmentPeriodId}`, {})
    .pipe(map(x => {
      const ep = this.commonService.createObject(EnrollmentPeriod, x);
      this.coreService.setEnrollmentPeriod(ep); //need to update the selected enrollment period so it has the new milestone completion
      return ep;
    }))
    .pipe(catchError(this.commonService.handleError));
  }

}
