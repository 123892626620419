<div class="grid-container">
  <kendo-grid
    #selfPayGrid
    [kendoGridBinding]="gridData" 
    filterable="menu"
    [selectable]="selectableSettings"
    [kendoGridSelectBy]="'selfPayId'"
    class="k-grid-ignore-click"
    [resizable]="true"
    [pageable]="true"
    [pageSize]="10"
    (remove)="removeSelfPay.emit($event)"
    (edit)="submitEdit($event)"
    (selectionChange)="setSelfPaySelected($event)"
  >
    <kendo-grid-checkbox-column width="45"> </kendo-grid-checkbox-column>
    <kendo-grid-column
      class="k-grid-ignore-click"
      [style.flex]="col.flex || 1"
      *ngFor="let col of gridColumns"
      field="{{ col.field }}"
      title="{{ col.title }}"
      [format]="col.format"
      [filter]="col.filter ? col.filter : null"
      [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }"
      [width]="col.width ? col.width : null"
    >
      <ng-template kendoGridFilterMenuTemplate
        let-column="col"
        let-filter="filter"
        let-filterService="filterService"
        *ngIf="col.field.endsWith('Date')"
        >
        <date-picker
          name="dateFilter"
          [ngModel]="filter.filters[0] ? filter.filters[0].value : null"
          (ngModelChange)="filterService.filter({ filters: [ { field: col.field, operator: 'eq', value: $event }], logic: 'and' })"
          [label]="col.title">
        </date-picker>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Manage" width="250" [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div
          fxLayout="row"
          fxLayoutAlign="space-around"
          *ngIf="
            selectedSelfPay?.selfPayId === dataItem.selfPayId &&
            !selectedSelfPay?.selfPayVerificationStatus?.deniedInd &&
            !selectedSelfPay?.selfPayVerificationStatus?.approvedInd &&
            !isReadOnly
          "
        >
          <button kendoGridEditCommand *ngIf="isHcaEdit || selectedSelfPay?.selfPayVerificationStatus?.selfPayVerificationStatusName === 'Draft' || selectedSelfPay?.selfPayVerificationStatus?.selfPayVerificationStatusName === 'Pending'" type="button" class="btn btn-primary">Edit</button>
          <button kendoGridRemoveCommand *ngIf="isHcaEdit || selectedSelfPay?.selfPayVerificationStatus?.selfPayVerificationStatusName === 'Draft'|| selectedSelfPay?.selfPayVerificationStatus?.selfPayVerificationStatusName === 'Pending'" type="button" class="btn btn-secondary">Delete</button>
        </div>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-command-column title="Forms" width="300" [headerStyle]="{ 'font-weight': 'bold', 'white-space': 'normal', 'text-align': 'center' }">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div
          fxLayout="row"
          fxLayoutAlign="space-around"
          *ngIf="
            selectedSelfPay?.selfPayId === dataItem.selfPayId &&
            selectedSelfPay?.selfPayVerificationStatus?.selfPayVerificationStatusName !== 'Draft'
          "
        >
          <button fxFlex="48" (click)="downloadSelfPayPDF.emit(selectedSelfPay)" *ngIf="selectedSelfPay?.formJson" type="button" class="btn btn-primary">Request PDF</button>
          <!-- <button fxFlex="48" (click)="downloadSelfPayPDF.emit(selectedSOE)" *ngIf="hasCompleteEP(selectedSOE)" type="button" class="btn btn-secondary">Elections PDF</button> -->
        </div>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
