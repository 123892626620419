import { Required } from 'src/app/decorators/inputRequired.decorator';
import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { map, upperFirst, filter, forEach, sortBy } from 'lodash';
import * as dayjs from 'dayjs';
import { CoreService } from 'src/app/services/core.service';
import Agency from 'src/app/models/agency';
@Component({
  selector: 'lookups-grid',
  templateUrl: 'lookups.grid.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None
})
export class LookupsGridComponent implements OnInit {
  @Input() lookup;
  @Input() tableName: string;
  @Output() handlePut: EventEmitter<any> = new EventEmitter<any>();
  @Output() handlePost: EventEmitter<any> = new EventEmitter<any>();
  @Input() oe = false;
  @Input() appSettings = false;
  @Input() genericMessages = false;
  @Input() checklist = [];
  @Input() disabledDatesFn: Function;
  @Input() agencies: Agency[];
  @Input() readonly = false;
  gridColumns: { [k: string]: string | {} }[] = [];
  constructor(private route: ActivatedRoute, private adminService: AdminService, private router: Router, public coreService: CoreService) {}

  ngOnInit(): void {
    forEach(this.lookup[0], (val, key) => {
      if (!key.includes('Id')) {
        this.gridColumns.push({
          field: key,
          title: upperFirst(key),
          format: key.includes('Date') ? { date: 'short' } : 'text'
        });
      }
    });

    this.gridColumns = sortBy(this.gridColumns, 'field');
    if (this.oe) {
      this.gridColumns = [
          {
            field: 'agencyName',
            title: 'Program',
            format: 'text',
            required: true
          },
          {
            field: 'effectiveStartDate',
            title: 'Effective Start Date',
            format: { date: 'short' },
            required: true
          },
          {
            field: 'effectiveEndDate',
            title: 'Effective End Date',
            format: { date: 'short' },
            required: true
          },
          {
            field: 'coverageEffectiveStartDate',
            title: 'Coverage Effective Start Date',
            format: { date: 'short' },
            required: true
          }
      ];
    } else if (this.appSettings) {
      this.gridColumns = [
          {
            field: 'applicationSettingName',
            title: 'Application Setting Name',
            format: 'text',
            disableEdit: true
          },
          {
            field: 'applicationSettingCode',
            title: 'Application Setting Code',
            format: 'text',
            disableEdit: true
          },
          {
            field: 'responseId',
            title: 'Response',
            format: 'text'
          },
          {
            field: 'modifiedDate',
            title: 'Modified Date',
            format: { date: 'short' },
            disableEdit: true
          },
      ];
    } else if (this.genericMessages) {
      this.gridColumns = [
          {
            field: 'messageText',
            title: 'Message Text',
            format: 'text',
            required: true
          },
          {
            field: 'activeDate',
            title: 'Begins on',
            format: { date: 'short' },
            required: true
          },
          {
            field: 'inactiveDate',
            title: 'Ends on',
            format: { date: 'short' },
          },
          {
            field: 'modules',
            title: 'Appears On',
            format: 'checklist',
          },
      ];
    }
  }

  put(e): void {
    e.tableName = this.tableName;
    this.handlePut.emit(e);
  }

  post(e): void {
    e.tableName = this.tableName;
    this.handlePost.emit(e);
  }
}
