<div class="card">
<div class="card-body">
    <h3 style="text-align: center">Confirmation of proof of eligibility</h3>
    <span>
        Thank you for submitting proof of eligibility documents for any dependents you may have on your account. To assist in the verification process, please respond promptly to any payroll or benefits office requests for additional information or verification documents. Untimely responses may result in your dependents not being enrolled in coverage.
    </span>
    <br/><br/>
    <br/><br/>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <button (click)="confirmation.emit(false)" class="btn btn-secondary">Return to upload</button>
        <button (click)="confirmation.emit(true)" class="btn btn-primary">{{label}}</button>    
    </div>
</div>
</div>