import { Component, ViewEncapsulation, OnInit, OnChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import SystemUser from '../../../../models/user';
import SimpleSubscriber from 'src/app/models/simpleSubscriber';
import { AdminService } from 'src/app/services/admin.service';
import { MemberService } from 'src/app/services/member.service';
import Reason from 'src/app/models/reason';
import { sortBy, map, get, some, filter, find } from 'lodash';
import Enrollment from 'src/app/models/enrollment';
import { EmploymentService } from 'src/app/services/employment.service';
import Member from 'src/app/models/member';
import Employment from 'src/app/models/employment';
import { SpinnerOverlayService } from 'src/app/services/spinnerOverlay.service';
import Organization from 'src/app/models/organization';
import { AccessLevel, CoreService, UserTypeCode } from 'src/app/services/core.service';
import { UserService } from 'src/app/services/user.service';
import EnrollmentTermination from 'src/app/models/enrollmentTermination';
import * as dayjs from 'dayjs';
import { OEService } from 'src/app/services/oe.service';
import { env } from 'src/env/development';
import Subscriber from 'src/app/models/subscriber';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { lastValueFrom } from 'rxjs';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Lookups(LookupType.Reason)
@Component({
  selector: 'subscriber-management',
  templateUrl: 'subscriberManagement.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SubscriberManagementComponent implements OnInit {
  systemUser: SystemUser;
  currentOrganization: Organization;
  gridData: SimpleSubscriber[] = [];
  selectedSubscriber: SimpleSubscriber;
  memberSelectedForManagement: SimpleSubscriber;
  addingSubscriber = false;
  isHCAAdmin = false;
  isAccessManager = false;
  isPersPayEdit = false;
  showOutlet = false;
  isOE = false;
  isReadOnly = false;
  fullSubscriber: Subscriber;
  gridColumns: { [k: string]: string | {} }[] = [
    { field: 'firstName', title: 'First name', format: 'string' },
    { field: 'middleName', title: 'Middle name', format: 'string' },
    { field: 'lastName', title: 'Last name', format: 'string' },
    { field: 'SSN', title: 'SSN', format: 'string' },
    { field: 'birthDate', title: 'Birth date', format: { date: 'mm/dd/yyyy' }, filter: 'date' },
    { field: 'memberType', title: 'Member type', format: 'string' },
    { field: 'employerName', title: 'Employer name', format: 'string' },
  ];
  onlyShowPendingRecords = true;
  showLOEState = false;
  isHCA = false;
  isHcaEditOrAdmin = false;
  isOrganizationView = false;
  agencyFilter = '';
  env = env;
  selfPayOnly = false;
  @ViewChild('subscriberManagementOutlet') outlet: RouterOutlet;

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private router: Router,
    private memberService: MemberService,
    private employmentService: EmploymentService,
    private spinnerOverlayService: SpinnerOverlayService,
    public coreService: CoreService,
    private userService: UserService,
    private oeService: OEService
  ) { }

  ngOnInit(): void {
    if (this.router.url.endsWith('add')) {
      this.addingSubscriber = true;
    } else {
      this.addingSubscriber = false;
    }

    if (this.router.url.includes('/subscriber/')) {
      this.memberSelectedForManagement = new SimpleSubscriber();
    } else {
      this.memberSelectedForManagement = null;
    }

    if (this.router.url.includes('/organizationManagement/')) {
      this.isOrganizationView = true;
    }

    this.coreService.organizationSelected.pipe(untilDestroyed(this)).subscribe((s: Organization) => {
      if(s) {
        this.currentOrganization = s;
        // if(s?.organizationName !== this.memberSelectedForManagement?.employerName){
        //   this.memberSelectedForManagement = null;
        // }
        if (this.systemUser) {
          this.isPersPayEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay);
          this.isAccessManager = some(this.systemUser.userOrganizationRoles, (usr) => usr.userAccessLevelName === 'Admin' && usr.organizationId === this.currentOrganization.organizationId);
          this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.Perspay) && !this.isPersPayEdit;
        }
      }
    });
    // user refreshed and already has a sub based on params
    this.coreService.getSubscriber().pipe(untilDestroyed(this)).subscribe((s: Subscriber) => {
      if (s.memberId) {
        this.memberSelectedForManagement = new SimpleSubscriber(s);
      }
    });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((q) => {
      if(q.selfPayOnly) {
        this.selfPayOnly = q.selfPayOnly;
      }
      if(!this.memberSelectedForManagement && !q.selfPayOnly){
        this.selfPayOnly = false;
      }
    });
    this.route.data.pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.systemUser = data.user;
        this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
        this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.HCA);
        this.isHcaEditOrAdmin = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);

        if (!this.isOrganizationView && this.isHCA) {
          this.currentOrganization = null;
          this.coreService.setOrganization(null);
        }

        if (this.currentOrganization) {
          this.isAccessManager = some(
            this.systemUser.userOrganizationRoles,
            (usr) => usr.userAccessLevelName === 'Admin' && usr.organizationId === this.currentOrganization.organizationId
          );
          this.isPersPayEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.Perspay);
          this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.Perspay) && !this.isPersPayEdit;
        }
        const now = new Date();
      },
      (err) => console.log(err)
    );
  }

  ngOnChanges() {
    this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly, UserTypeCode.HCA);
    if (this.router.url.includes('/organizationManagement/')) {
      this.isOrganizationView = true;
    }

    if (!this.isOrganizationView && this.isHCA) {
      this.currentOrganization = null;
      this.coreService.setOrganization(null);
    }
  }

  onSearchSubmitted(queryString: string): void {
    this.spinnerOverlayService.show();
    this.adminService.searchSubscribers(queryString, false, this.currentOrganization ? this.currentOrganization.organizationId : null, this.agencyFilter, this.selfPayOnly).pipe(untilDestroyed(this)).subscribe(
      (data) => {
        this.spinnerOverlayService.hide();
        this.gridData = [];
        this.gridData = data;
      },
      (err) => {
        console.log(err);
        this.spinnerOverlayService.hide();
      }
    );
  }

  onSubscriberSelected(e: SimpleSubscriber): void {
    this.selectedSubscriber = e;
  }

  manageMember(): void {
    this.memberSelectedForManagement = this.selectedSubscriber;
    this.addingSubscriber = false;
    const currentSubscriber = this.coreService.getSubscriberValue();
    if (currentSubscriber.memberId === this.memberSelectedForManagement.memberId) {
      //refresh subscriber
      currentSubscriber.refetch = true;
      this.coreService.setSubscriber(currentSubscriber);
    }

    if(!this.memberSelectedForManagement.isSubscriberInd) {
      this.router.navigate([`subscriber/${this.memberSelectedForManagement.subscriberMemberId}`], { relativeTo: this.route });
    } else {
      this.router.navigate([`subscriber/${this.memberSelectedForManagement.memberId}`], { relativeTo: this.route });
    }
    window.scrollTo(0, 1000);
  }

  addSubscriber(): void {
    this.addingSubscriber = true;
    this.router.navigate([`add`], { relativeTo: this.route });
  }

  async disassociateAccount(m: SimpleSubscriber): Promise<void> {
    this.spinnerOverlayService.show();
    try {
      await lastValueFrom(this.userService.disassociateUser(m.memberId, m.systemUserId));
      this.selectedSubscriber.systemUserId = null;
      const currentSubscriber = this.coreService.getSubscriberValue();
      currentSubscriber.refetch = true;
      this.coreService.setSubscriber(currentSubscriber);
    } catch (err) {
      console.log(err);
      this.spinnerOverlayService.hide();
      this.coreService.popMessage('Something went wrong disaccociating this subscriber account, please try again or contact a system admin.', 'error', 2000);
    }
    this.spinnerOverlayService.hide();
    this.coreService.popMessage('Subscriber account successfully released', 'success', 2000);
  }

  setShowOutlet(b: boolean): void {
    this.showOutlet = b;
  }

  roDeactivated(e) {
    if(!this.outlet.isActivated) {
      this.selfPayOnly = false;
    }
    //this.memberSelectedForManagement = null;
  }
}
