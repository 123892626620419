<form #associationForm="ngForm">
  <div class="card">
    <div class="card-body fxGap-medium-sm" style="height: 100%;" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <h4 style="margin-bottom: 10px;">Associate documents</h4>
        <h4 style="margin-bottom: 0px; align-self: flex-end; padding-right: 100px;">Verification applicable to:</h4>
      </div>
      <div
        fxFlex="100"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between"
        *ngFor="let document of documents"
        style="height: 100%; padding-top: 10px; padding-bottom: 10px;"
        class="docUploadRow fxGap-medium-lg"
      >
        <div [fxFlex]="isSOE ? 25 : 35" fxLayout="column" style="height: 100%;">
          <generic-document-viewer *ngIf="document.documentId && !document.documentId.includes('NEW')" 
            fxFlex="100" 
            [documentName]="document.documentName" 
            [documents]="[document]"
            [canDeleteDocuments]="true"
            (deleteDocument)="deleteDocument($event)">
          </generic-document-viewer>
          <div fxLayout="row" fxFlex="100" class="hca-document-header">
              <h5 style="max-width: 250px;">{{document?.documentName}}</h5>
          </div>
        </div>
        <div [fxFlex]="isSOE ? 20 : 30" fxLayout="column" style="height: 100%;">
          <label [for]="'documentType' + document.documentId">Document type</label>
          <select required class="form-control" 
            [name]="'documentType' + document.documentId" 
            [id]="'documentType' + document.documentId" 
            (change)="handleNewDocTypeChange(document.documentTypeId, document.documentId)" [(ngModel)]="document.documentTypeId">
            <option *ngFor="let docType of (documentTypes | isObsolete)" [(ngValue)]="docType.documentTypeId">{{docType.documentTypeName}}</option>
          </select>
        </div>
        <div [fxFlex]="isSOE? 20 : 30" fxLayout="column" class="associationContainer">
          <div *ngFor="let member of members; let index">
            <div *ngIf="docHash[document.documentId]" class="bigger mgbt-x-small">
              <checkbox
                [disabled]="!document.documentTypeId || docHashDisabled[document.documentId][member.relationshipToSubscriber.relationshipId] == true || !documentTypeIsValidForMember(document.documentTypeId, member)"
                required bold
                [name]="member.memberId + document.documentId"
                [(ngModel)]="docHash[document.documentId][member.relationshipToSubscriber.relationshipId]"
                (ngModelChange)="associateMemberOrSOE($event, document, member.relationshipToSubscriber)">
                {{ member.firstName }} {{ member.lastName }} - {{ member.relationshipToSubscriber.simplifiedStatus }}
              </checkbox>
              <span *ngIf="document.documentTypeId && !documentTypeIsValidForMember(document.documentTypeId, member)">The document type selected is invalid for this dependent type</span>
            </div>
          </div>
        </div>
        <div [fxFlex]=" isSOE ? 20 : 30" fxLayout="column" class="associationContainer" *ngIf="isSOE">
            <checkbox
              [(ngModel)]="document.isSOEDocument"
              [name]="'soeDocument' + document.documentId"
              (ngModelChange)="associateMemberOrSOE($event, document, null, currentSOE)">
               {{(currentSOE.specialOpenEnrollmentType.displayName ? currentSOE.specialOpenEnrollmentType.displayName : 
                  currentSOE.specialOpenEnrollmentType.specialOpenEnrollmentTypeName) }}
            </checkbox>
        </div>        
      </div>
    </div>
  </div>
</form>
