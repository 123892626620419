/**
 * State-level SOE component subscriber side
 */

// ng
import { Component, ViewEncapsulation, OnInit, OnDestroy, HostListener, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RoutesRecognized, RouterOutlet } from '@angular/router';

// ext
import { lastValueFrom, Observable, Subscription, pairwise, filter as rxjsFilter } from 'rxjs';
import { cloneDeep, isEqual, some } from 'lodash';
import * as dayjs from 'dayjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// local
import SpecialOpenEnrollmentType from 'src/app/models/specialOpenEnrollmentType';
import SpecialOpenEnrollmentVerificationStatus from 'src/app/models/specialOpenEnrollmentVerificationStatus';
import { SpinnerOverlayService } from './../../../../services/spinnerOverlay.service';
import { PDFComponent } from './../../../shared/components/pdf/pdf.component';
import { AccessLevel, CoreService, UserTypeCode } from './../../../../services/core.service';
import { sortBy, filter, remove, findIndex, get, find } from 'lodash';
import SpecialOpenEnrollment from 'src/app/models/specialOpenEnrollment';
import { EnrollmentService } from 'src/app/services/enrollment.service';
import { SoeService } from 'src/app/services/soe.service';
import Subscriber from 'src/app/models/subscriber';
import Plan from 'src/app/models/plan';
import PlanType from 'src/app/models/planType';
import DocumentType from 'src/app/models/documentType';
import Member from 'src/app/models/member';
import EnrollmentPeriod from 'src/app/models/enrollmentPeriod';
import SubscriberEffectiveSummary from 'src/app/models/subscriberEffectiveSummary';
import { Lookups, LookupType } from 'src/app/decorators/lookups.decorator';
import SubscriberCoverage from 'src/app/models/subscriberCoverage';
import { env } from 'src/env/development';
import { SubscriberService } from './../../../../services/subscriber.service';
import Milestone from 'src/app/models/milestone';
import DualEnrollment from 'src/app/models/dualEnrollment';
import { AdminService } from 'src/app/services/admin.service';

@UntilDestroy()
@Lookups(LookupType.SpecialOpenEnrollmentType, LookupType.DocumentType, LookupType.GenderIdentity, LookupType.BirthSex,
         LookupType.County, LookupType.Country, LookupType.SpecialOpenEnrollmentVerificationStatus, LookupType.PlanType)
@Component({
  selector: 'soe',
  templateUrl: 'soe.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class SOEComponent implements OnInit {
  icons = {};
  step = 0;
  subscriber: Subscriber;
  currentDependents: Member[];
  initialSOEs: SpecialOpenEnrollment[];
  changesHaveBeenSaved = false;
  lookups = {
    specialOpenEnrollmentTypes: [],
    otherSpecialOpenEnrollmentTypes: [],
    documentTypes: [],
    genderIdentities: [],
    birthSexes: [],
    countries: [],
    counties: [],
    specialOpenEnrollmentVerificationStatus: []
  };
  selectedSpecialOpenEnrollment: SpecialOpenEnrollment;
  availablePlans: Plan[];
  proceedThroughWiz: boolean;
  showAdminPrompt = false;
  documentTitleText = 'Summary of coverage elections';
  documentHeaderText =
    'This is a summary of your coverage elections with the Health Care Authority. This is not a statement of insurance. Changes to elections can be made through Benefits 24/7 during open enrollment or special open enrollment.';
  enrolledHeader = '';
  subscriberSummary: SubscriberEffectiveSummary;
  isReadOnly = false;
  isMedicalOnlyOrganization = false;
  isHCAAdmin = false;
  isHCAEdit = false;
  isDualEnrolledForPeriod = false;
  dentalPlanType;
  medicalPlanType;
  visionPlanType;
  enabledMilestoneSteps: string[];
  isDeathOrDivorceSoe = false;
  subscriberHasActiveSoe = false;
  otherDateMap =
  {
    'Death or Divorce': 'Enter the date of death, or the date of divorce, for the event date.'
  };
  isPerspayAdmin = false;
  isHCA = false;
  canOverride = false;
  @ViewChild('soePDF') soePDF: PDFComponent;
  @ViewChild('coveragePDF') coveragePDF: PDFComponent;
  navigationSubscription: Subscription;
  dualEnrollment: DualEnrollment;
  medicareDEnabled = false;

  constructor(
    private route: ActivatedRoute,
    private soeService: SoeService,
    private enrollmentService: EnrollmentService,
    private router: Router,
    private coreService: CoreService,
    private spinnerService: SpinnerOverlayService,
    private subscriberService: SubscriberService,
    private adminService: AdminService
  ) {}

  async ngOnInit(): Promise<void> {
    this.medicareDEnabled = await lastValueFrom(this.adminService.getFeatureFlag('FEATURE_ENABLE_MEDICARE_D'));

    this.coreService.refreshForced
      .pipe(untilDestroyed(this))
      .subscribe(() => {
          if (this.selectedSpecialOpenEnrollment) {
            this.refetchSOE(this.selectedSpecialOpenEnrollment);
          }
      });

    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      const cachedSOEPeriod = this.subscriber?.soeEnrollmentPeriod;
      this.subscriber = data.subscriber;
      if (cachedSOEPeriod) {
        //init is being called again (probably from navigating through the wizard)
        //reset the subscriber's SOE period since it should not have changed during the wizard
        this.subscriber.soeEnrollmentPeriod = cachedSOEPeriod;
      }

      this.lookups.specialOpenEnrollmentTypes =
        sortBy(
          filter(data.lookups.specialOpenEnrollmentType, (soet: SpecialOpenEnrollmentType) =>
            soet.specialOpenEnrollmentTypeName !== 'Newly Eligible' &&
            soet.specialOpenEnrollmentTypeName !== 'Supplemental' &&
            soet.specialOpenEnrollmentTypeName !== 'API Import' &&
            !soet.otherEventInd &&
            (soet.agencyId == null || soet.agencyId === this.subscriber?.organization?.agencyId)),
          'specialOpenEnrollmentTypeName');

      this.lookups.otherSpecialOpenEnrollmentTypes =
        sortBy(
          filter(data.lookups.specialOpenEnrollmentType, (soet: SpecialOpenEnrollmentType) => soet.otherEventInd),
          'specialOpenEnrollmentTypeName');

      // Death or Divorce is only available when dependents with active enrollments exist
      if (this.subscriber.members.filter(m => !m.isSubscriberInd && m.enrollments.filter(e => !e.effectiveEndDate).length > 0).length === 0) {
        this.lookups.otherSpecialOpenEnrollmentTypes = this.lookups.otherSpecialOpenEnrollmentTypes.filter(soet => soet.specialOpenEnrollmentTypeName !== 'Death or Divorce');
      }

      this.lookups.genderIdentities = data.lookups.genderIdentity;
      this.lookups.birthSexes = data.lookups.birthSex;
      this.lookups.countries = data.lookups.country;
      this.lookups.counties = data.lookups.county;
      this.lookups.specialOpenEnrollmentVerificationStatus = data.lookups.specialOpenEnrollmentVerificationStatus;
      this.isHCAAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA);
      this.isHCAEdit = this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      if (!this.isHCAAdmin) {
        this.lookups.specialOpenEnrollmentTypes = filter(this.lookups.specialOpenEnrollmentTypes, (soet: SpecialOpenEnrollmentType) => soet.specialOpenEnrollmentTypeName !== 'HCA OE Correction');
      }
      this.isReadOnly = this.coreService.systemUserHasAccess(AccessLevel.ReadOnly) && !this.coreService.systemUserHasAccess(AccessLevel.Edit);
      this.setSOEItemStatus();
    
      this.currentDependents = filter(this.subscriber.members, (m) => !m.isSubscriberInd);
      this.lookups.documentTypes = sortBy(
        filter(data.lookups.documentType, (dt: DocumentType) => dt.specialOpenEnrollmentInd || dt.dependentVerificationInd),
        (dt: DocumentType) => dt.documentTypeName
      );
      this.initialSOEs = cloneDeep(data.subscriber.specialOpenEnrollments);
      this.enrollmentService.getAvailablePlansForMemberWithoutEP(this.subscriber.memberId).pipe(untilDestroyed(this)).subscribe((ap: Plan[]) => {
        this.availablePlans = sortBy(ap, 'planName');
      });

      this.isMedicalOnlyOrganization = this.subscriber.isMedicalOnlyOrganization;
      this.dentalPlanType = find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.dentalPlanTypeCode);
      this.medicalPlanType = find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.medicalPlanTypeCode);
      this.visionPlanType = find(data.lookups.planType, (pt: PlanType) => pt.planTypeCode === env.visionPlanTypeCode);

      const onlyOneOf = [ 'Draft', 'Pending', 'Submitted', 'Waiting for review' ];
      this.subscriberHasActiveSoe = some(this.subscriber.specialOpenEnrollments, (soe: SpecialOpenEnrollment) => onlyOneOf.indexOf(soe.simplifiedStatus) > -1);

      this.isPerspayAdmin = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.Perspay);
      this.isHCA = this.coreService.systemUserHasAccess(AccessLevel.Admin, UserTypeCode.HCA) || this.coreService.systemUserHasAccess(AccessLevel.Edit, UserTypeCode.HCA);
      this.canOverride = this.isPerspayAdmin || this.isHCA;
    });

    this.soeService.soeSubmitted.pipe(untilDestroyed(this)).subscribe(()=>{
      if (this.selectedSpecialOpenEnrollment) 
      {
        //an SOE was submitted, clear selected SOE to hide the wizard
        this.selectedSpecialOpenEnrollment = null
        if (this.subscriber.memberId) {
          //refresh subscriber to get new status for SOE that was submitted
          this.spinnerService.show();
          this.subscriberService.getSubscriberById(this.subscriber.memberId).pipe(untilDestroyed(this)).subscribe((sub)=>{
            this.subscriber = sub;
            this.setSOEItemStatus();
            this.coreService.setSubscriber(sub);
            this.spinnerService.hide();
          });
        }
      }
    });
  }

  setSOEItemStatus() {
      // coercing value for different display in sub side soe grid here, changing in model builds everything
      this.subscriber.specialOpenEnrollments.forEach((soe: SpecialOpenEnrollment) => {
        if (soe.simplifiedStatus === 'Pending') {
          soe.simplifiedStatus = 'Waiting for review';
        }
      });
  }

  // create or update SOE
  async saveSOE(soe: SpecialOpenEnrollment | any, submitting?: boolean): Promise<any> {
    this.spinnerService.show();
    const skipAdvance = soe?.skipAdvance;
    if (soe?.soe) {
      soe = soe.soe;
    }
    if (submitting) {
      soe.specialOpenEnrollmentVerificationStatusId =
        get(find(this.lookups.specialOpenEnrollmentVerificationStatus, (s: SpecialOpenEnrollmentVerificationStatus) => s.specialOpenEnrollmentVerificationStatusCode === 'R'), 'specialOpenEnrollmentVerificationStatusId');
    }
    if (!soe?.specialOpenEnrollmentId) {
      try {
        // The create soe endpoint isn't smart enough to handle an incoming JSON objet in formJson, it only can
        // handle string data.
        // const form = soe.formJson;
        // delete soe.formJson;

        const newSOE = await lastValueFrom(this.soeService.createSpecialOpenEnrollment(this.subscriber.memberId, soe));
        this.subscriber.specialOpenEnrollments.push(newSOE);
        this.selectedSpecialOpenEnrollment = newSOE;
        await this.fetchSubscriberAndSetEP(newSOE);
        // newSOE.formJson = form;
        // this.step = 1;
        // re-grab sub on nav
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
        if (this.coreService.systemUserHasAccess(AccessLevel.Edit)) {
          this.showAdminPrompt = true;
        }
      } catch (err) {
        console.log('err', err);
      }
    } else {
      try {
        soe.member = null;
        // const updatedSOE = await lastValueFrom(this.soeService.updateSpecialOpenEnrollmentForm(this.subscriber.memberId, soe));
        // re-grab sub on nav
        this.selectedSpecialOpenEnrollment = cloneDeep(soe);
        this.subscriber.refetch = true;
        this.coreService.setSubscriber(this.subscriber);
        if (submitting) {
          // delete soe.formJson;
          delete soe.documents;
          const submittedSOE = await lastValueFrom(this.soeService.updateSpecialOpenEnrollment(this.subscriber.memberId, soe));
          this.step = 0;
          await this.refetchSOE(this.selectedSpecialOpenEnrollment);
        } else {
          if (!skipAdvance) {
            if (this.step === 4 && this.subscriber.organization?.benefitSetup?.benefitSetupCode === 'MO') {
              // If this is Step 4, Medical, and organization is a Medical Only organization, skip the
              // Dental step (Step 5).
              this.step += 2;
            } else {
              this.step++;
            }
          }
        }
      } catch (err) {
        console.log('err', err);
      }
    }
  }

  async editSOE(e): Promise<void> {
    this.selectedSpecialOpenEnrollment = e.dataItem;
    await this.fetchSubscriberAndSetEP(this.selectedSpecialOpenEnrollment);
    // this.step = 1;
    this.isDeathOrDivorceSoe = this.selectedSpecialOpenEnrollment.specialOpenEnrollmentType.specialOpenEnrollmentTypeName === 'Death or Divorce';
  }

  previous(): void {
    this.step--;
  }

  navToDashboard(): void {
    this.router.navigate([`../../subscriber/${this.subscriber.memberId}`], { relativeTo: this.route });
  }

  proceed(e: boolean): void {
    if (e) {
      this.step = 0;
      // pers/hca user opts out - submit soe
      this.saveSOE(this.selectedSpecialOpenEnrollment, true);
      // reset bool
      this.proceedThroughWiz = null;
    }
    this.showAdminPrompt = false;
  }

  async downloadCoveragePDF(e: SpecialOpenEnrollment): Promise<void> {
    // If SOE has been approved, fetch subscriber, otherwise fetch subscriber for SOE
    let sub;
    if (e.specialOpenEnrollmentVerificationStatus.approvedInd) {
      sub = await lastValueFrom(this.subscriberService.getSubscriberById(this.subscriber?.memberId));
    } else {
      sub = await lastValueFrom(this.subscriberService.getSubscriberWithSOE(this.subscriber?.memberId, e.specialOpenEnrollmentId));
    }
    this.subscriber = sub;
    const soeEnrollmentPeriod = find(this.subscriber.enrollmentPeriods, (ep: EnrollmentPeriod) => ep.enrollmentPeriodId === e.specialOpenEnrollmentId);
    this.subscriberSummary =  new SubscriberEffectiveSummary(this.subscriber, soeEnrollmentPeriod, false, true, e?.specialOpenEnrollmentType?.specialOpenEnrollmentTypeName, null, this.medicareDEnabled);
    setTimeout(() => this.coveragePDF.pdfElement.saveAs('Elections.pdf'));
  }

  async cancelSOE(dataItem): Promise<void> {
    const soeToCancel = dataItem;
    try {
      this.spinnerService.show();
      await lastValueFrom(this.soeService.cancelSpecialOpenEnrollment(soeToCancel));

      if (this.subscriber.soeEnrollmentPeriod?.enrollmentPeriodId === soeToCancel.specialOpenEnrollmentId) {
        this.subscriber.soeEnrollmentPeriod = null;
      }
      if (this.selectedSpecialOpenEnrollment?.specialOpenEnrollmentId === soeToCancel.specialOpenEnrollmentId) {
        this.selectedSpecialOpenEnrollment = null;
      }

      this.subscriber = await lastValueFrom(this.subscriberService.getSubscriberById(this.subscriber.memberId));
      this.subscriber.specialOpenEnrollments.forEach((soe: SpecialOpenEnrollment) => {
        if (soe.simplifiedStatus === 'Pending') {
          soe.simplifiedStatus = 'Waiting for review';
        }
      });

      const onlyOneOf = [ 'Draft', 'Pending', 'Submitted', 'Waiting for review' ];
      this.subscriberHasActiveSoe = some(this.subscriber.specialOpenEnrollments, (s: SpecialOpenEnrollment) => onlyOneOf.indexOf(s.simplifiedStatus) > -1);

      this.subscriber.refetch = true;
      this.coreService.setSubscriber(this.subscriber);
    } catch (err) {
      this.spinnerService.hide();
      console.log(err);
    }
    this.spinnerService.hide();
  }

  async refetchSOE(s: SpecialOpenEnrollment): Promise<any> {
    let soe;
    try {
      soe = await lastValueFrom(this.soeService.getSpecialOpenEnrollmentById(s.specialOpenEnrollmentId, this.subscriber.memberId));
    } catch (err) {
      console.log(err);
    }
    if (soe.simplifiedStatus === 'Pending') {
      soe.simplifiedStatus = 'Waiting for review';
    }
    this.selectedSpecialOpenEnrollment = soe;
    const idx = findIndex(this.subscriber.specialOpenEnrollments, (osoe: SpecialOpenEnrollment) => osoe.specialOpenEnrollmentId === soe.specialOpenEnrollmentId);
    this.subscriber.specialOpenEnrollments.splice(idx, 1, soe);

    const onlyOneOf = [ 'Draft', 'Pending', 'Submitted', 'Waiting for review' ];
    this.subscriberHasActiveSoe = some(this.subscriber.specialOpenEnrollments, (subSoe: SpecialOpenEnrollment) => onlyOneOf.indexOf(subSoe.simplifiedStatus) > -1);
  }

  public async fetchSubscriberAndSetEP(soe: SpecialOpenEnrollment): Promise<void> {
    const fullSubscriber = await lastValueFrom(this.subscriberService.getSubscriberWithSOE(soe?.memberId, soe.specialOpenEnrollmentId));
    fullSubscriber.soeEnrollmentPeriod = find(fullSubscriber.allEnrollmentPeriods, (ep: EnrollmentPeriod) => ep.enrollmentPeriodId === soe?.specialOpenEnrollmentId);
    this.subscriber = fullSubscriber;
    this.coreService.setSubscriber(fullSubscriber);
    this.coreService.setEnrollmentPeriod(fullSubscriber.soeEnrollmentPeriod);
    this.isDualEnrolledForPeriod = this.subscriber.dualEnrolledInOtherAgency;
    this.dualEnrollment = new DualEnrollment(this.subscriber.organization, this.subscriber.externalEnrollments, fullSubscriber.soeEnrollmentPeriod.coverageEffectiveStartDate);
    this.navigateToWizard();

    const onlyOneOf = [ 'Draft', 'Pending', 'Submitted', 'Waiting for review' ];
    this.subscriberHasActiveSoe = some(this.subscriber.specialOpenEnrollments, (s: SpecialOpenEnrollment) => onlyOneOf.indexOf(s.simplifiedStatus) > -1);
  }

  public navigateToWizard(): void {
    // get first step of the wizard and navigate to it
    const initialMilestone: Milestone = sortBy(this.subscriber.soeEnrollmentPeriod.milestones, 'sortOrder')[0];
    const initialRoute = '.' + env.milestoneMap[initialMilestone.milestoneName].state + this.subscriber.soeEnrollmentPeriod.enrollmentPeriodId;
    this.router.navigate([initialRoute], { relativeTo: this.route });
  }

  async waivePlansDueToDualEnrollment(): Promise<void> {
    if (this.isDualEnrolledForPeriod) {
      const coverageElections = new SubscriberCoverage();
      coverageElections.enrollmentPeriodId = this.selectedSpecialOpenEnrollment.specialOpenEnrollmentId;
      coverageElections.subscriberMemberId = this.subscriber.subscriberMemberId;
      coverageElections.electedPlans = [];
      coverageElections.waivedPlanTypes = [];
      coverageElections.planTypeIds = [];

      coverageElections.waivedPlanTypes.push(this.medicalPlanType.planTypeId);
      const fullBenefits = this.subscriber.organization?.benefitSetup?.benefitSetupCode === 'FB';
      if (fullBenefits) {
        coverageElections.waivedPlanTypes.push(this.dentalPlanType.planTypeId);
        if (this.subscriber.isSebb) {
          coverageElections.waivedPlanTypes.push(this.visionPlanType.planTypeId);
        }
      }

      try {
        this.spinnerService.show();
        const updatedElections = await lastValueFrom(this.enrollmentService.createSubscriberEnrollmentsForSOE
          (
            this.subscriber.memberId,
            this.selectedSpecialOpenEnrollment.specialOpenEnrollmentId,
            coverageElections,
            this.selectedSpecialOpenEnrollment.specialOpenEnrollmentId
          ));
        this.spinnerService.hide();
        this.coreService.popMessage('Successfully waived medical and dental elections.', 'success', 3000);
        this.isDualEnrolledForPeriod = false;

        this.enabledMilestoneSteps = [ 'Confirmation' ];

        if (fullBenefits && find(this.subscriber?.soeEnrollmentPeriod?.milestones, (m: Milestone) => m.milestoneName === 'Supplemental Benefits')) {
          this.enabledMilestoneSteps.push('Supplemental Benefits');
          this.router.navigate(['./supplemental/' + this.selectedSpecialOpenEnrollment.specialOpenEnrollmentId], { relativeTo: this.route });
        } else {
          this.router.navigate(['./confirmation/' + this.selectedSpecialOpenEnrollment.specialOpenEnrollmentId], { relativeTo: this.route });
        }
      } catch (err) {
        this.coreService.popMessage('Something went wrong waiving your elections, please contact your benefits administrator.', 'error', 3000);
        this.spinnerService.hide();
        console.log('err', err);
      }
    }
  }

  continueDualEnroll(): void {
    if (this.isReadOnly) {
      this.router.navigate(['./confirmation/' + this.selectedSpecialOpenEnrollment.specialOpenEnrollmentId], { relativeTo: this.route });
    } else {
      this.enabledMilestoneSteps = [ 'All' ];
      this.navigateToWizard();
    }
    this.isDualEnrolledForPeriod = false;
  }

  clearSelectedSOE() {
    this.selectedSpecialOpenEnrollment = null;
  }
}
